import React from 'react';
import {
    IconFeedbackStyled,
    IconFeedbackOutlineStyled,
    IconWrapper,
    IconReviewStyled,
    IconReviewOutlineStyled,
    WrapperStyled,
    IconReferenceStyled,
    IconReferenceOutlineStyled,
} from './Rating.style';
import { SizeVariant, RatingVariant } from './Rating.constants';

const Rating = ({ maxStars, rating, size, variant = RatingVariant.REVIEW, className }) => {
    const starArray = [];

    for (let a = 0; a < maxStars; a += 1) {
        // We round up when displaying filled stars
        const isFilled = a < rating;

        starArray.push(
            <IconWrapper key={`${variant}-rating-${a}`} isLast={a === maxStars - 1}>
                <RatingIcon isFilled={isFilled} variant={variant} size={size} />
            </IconWrapper>
        );
    }

    return <WrapperStyled className={className}>{starArray}</WrapperStyled>;
};

const RatingIcon = ({ isFilled, size, variant }) => {
    switch (variant) {
        case 'feedback':
            if (isFilled) {
                return <IconFeedbackStyled size={size} />;
            }
            return <IconFeedbackOutlineStyled size={size} />;

        case 'review':
            if (isFilled) {
                return <IconReviewStyled size={size} />;
            }
            return <IconReviewOutlineStyled size={size} />;

        case 'reference':
            if (isFilled) {
                return <IconReferenceStyled size={size} />;
            }
            return <IconReferenceOutlineStyled size={size} />;

        default:
            return <IconReviewStyled size={size} />;
    }
};

Rating.defaultProps = {
    ratingType: 'review',
    maxStars: 5,
    rating: 0,
    size: SizeVariant.DEFAULT,
    variant: RatingVariant.REVIEW,
};

Rating.Variant = RatingVariant;

export { RatingIcon };
export default Rating;
