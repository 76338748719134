import { cloudinaryImageLoader } from 'utils/integrations/cloudinary';

// eslint-disable-next-line import/prefer-default-export
export const SITTER_STARTED_APPLICATION = {
    create({ sitterProfile, listing }) {
        const listingMainImage = listing.mainPhoto
            ? cloudinaryImageLoader({ publicId: listing.mainPhoto?.publicId })
            : null;
        const ownerPhoto = listing.ownerAvatar
            ? cloudinaryImageLoader({ publicId: listing.ownerAvatar?.publicId })
            : null;
        const pets = listing.pets
            ? listing.pets.map((pet) => ({
                  name: pet.name,
                  image: pet.photos[0]
                      ? cloudinaryImageLoader({ publicId: pet.photos[0].publicId })
                      : null,
                  type: pet.type,
              }))
            : null;
        const assignments = listing.openAssignments.map(
            ({ startDate, endDate, id, isReviewing }) => ({
                assignmentId: id,
                startDate,
                endDate,
                isReviewing,
            })
        );
        const payload = {
            sitterFirstName: sitterProfile.sitterName,
            listingId: listing.id,
            listingMainImage,
            listingLocation: listing.location,
            pets,
            assignments,
            ownerUserId: listing.ownerId,
            ownerName: listing.ownerName,
            ownerPhoto,
        };

        return {
            type: 'Application Started',
            payload,
        };
    },
};
