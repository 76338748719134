/* eslint-disable import/prefer-default-export */
import { routes } from 'utils/routes';

export const ERROR_CODES = {
    ERROR_400: 400,
    ERROR_401: 401,
    ERROR_403: 403,
    ERROR_404: 404,
    ERROR_500: 500,
};

export const MESSAGES = {
    [ERROR_CODES.ERROR_400]: {
        titleKey: 'components_error_404_title',
        bodyKey: 'components_error_404_body',
    },
    [ERROR_CODES.ERROR_401]: {
        titleKey: 'components_error_401or403_title',
        bodyKey: 'components_error_401or403_body',
    },
    [ERROR_CODES.ERROR_403]: {
        titleKey: 'components_error_401or403_title',
        bodyKey: 'components_error_401or403_body',
    },
    [ERROR_CODES.ERROR_404]: {
        titleKey: 'components_error_404_title',
        bodyKey: 'components_error_404_body',
    },
    [ERROR_CODES.ERROR_500]: {
        titleKey: 'components_error_500_title',
        bodyKey: 'components_error_500_title',
    },
};

export const AVAILABLE_LINKS = {
    [ERROR_CODES.ERROR_400]: [
        {
            i18nKey: 'navigation_home',
            url: routes.home(),
        },
        {
            i18nKey: 'navigation_help',
            url: routes.help(),
        },
    ],
    [ERROR_CODES.ERROR_403]: [],
    [ERROR_CODES.ERROR_404]: [
        {
            i18nKey: 'navigation_home',
            url: '/',
        },
        {
            i18nKey: 'navigation_searchSitters',
            url: routes.search.profiles(),
        },
        {
            i18nKey: 'navigation_searchSits',
            url: routes.search.listings(),
        },
        {
            i18nKey: 'navigation_howItWorks',
            url: routes.howItWorks.howItWorks(),
        },
        {
            i18nKey: 'navigation_login',
            url: routes.login(),
        },
    ],
    [ERROR_CODES.ERROR_401]: [
        {
            i18nKey: 'navigation_login',
            url: routes.login(),
        },
    ],
    [ERROR_CODES.ERROR_500]: [
        {
            i18nKey: 'navigation_home',
            url: routes.home(),
        },
        {
            i18nKey: 'navigation_help',
            url: routes.help(),
        },
    ],
};
