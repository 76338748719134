import styled from 'styled-components';
import { Button, ButtonIcon } from 'components/buttons';
import { MembershipType } from 'api/types';
import { h1LargeStyles, h1Styles, focalSmallStyles } from 'typography';
import { IconCheck, IconChevronRight } from 'components/Icon';

const heightBreakPoint1 = '600px';
const heightBreakPoint2 = '800px';

const Wrapper = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: 1fr 1fr minmax(min-content, 1fr);
    grid-template-columns: 1fr;
    grid-template-areas:
        'owner'
        'sitter'
        'combined';

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        height: auto;
        grid-template-rows: 1fr 1fr min-content;
    }

    // 1024px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        grid-template-rows: 1fr max-content;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'owner sitter'
            'combined combined';
    }
`;

const PanelStyled = styled.div`
    display: flex;
    background-color: white;
    grid-area: sitter;

    // 1024px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        justify-content: flex-start;
    }
`;

const OwnerPanelStyled = styled(PanelStyled)`
    grid-area: owner;
    background-color: ${({ theme }) => theme.palette.accent};
`;

const SitterPanelStyled = styled(PanelStyled)``;

const CombinedPanelStyled = styled(PanelStyled)`
    grid-area: combined;
    background-color: ${({ theme }) => theme.palette.primaryRegular};

    // 1024px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        flex-grow: 2;
        width: 100%;
    }
`;

const PanelInnerStyled = styled.div`
    margin: auto;
    padding: ${({ theme }) => `${theme.spacing[3]}`};
    width: 100%;
    max-width: 500px;

    // 720px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin: ${({ theme }) => `${theme.spacing[4]} auto ${theme.spacing[4]}`};
    }

    // 1024px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        ${({ membershipType }) =>
            membershipType === MembershipType.COMBINED
                ? `
                max-width: unset;
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: 0;
            `
                : null}
        };
    }
`;

const TitleStyled = styled.h1`
    ${h1Styles};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[0]};
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
    color: ${({ theme, membershipType }) =>
        membershipType === MembershipType.SITTER ? theme.palette.accent : theme.palette.white};

    // Viewport Height 600px
    @media screen and (min-height: ${heightBreakPoint1}) {
        margin-bottom: 0;
    }

    // 720px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.xxlarge};
        margin-bottom: ${({ theme }) => theme.spacing[3]};
    }

    // 1024px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        ${h1LargeStyles};
        font-size: 4rem;
        margin-bottom: ${({ theme }) => theme.spacing[6]};

        color: ${({ theme, membershipType }) =>
            membershipType === MembershipType.SITTER ? theme.palette.accent : theme.palette.white};
    }

    // 1280px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        font-size: 4.5rem;
    }
`;

const TitleMembership = styled.span`
    display: none;

    // 375px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumHandset}px) {
        display: inline;
    }
`;

const TitleCombinedStyled = styled(TitleStyled)`
    font-size: ${({ theme }) => theme.fontSizes.large};
    margin-bottom: ${({ theme }) => theme.spacing[2]}};

    // Viewport Height 600px
    @media screen and (min-height: ${heightBreakPoint1}) {
        display: flex;
        align-items: center;
    }

    // 720px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.xlarge};
        margin-bottom: 0;
        line-height: ${({ theme }) => theme.spacing[6]}};
    }

    // // 1024px
    // @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
    //     font-size: ${({ theme }) => theme.fontSizes.large};
    // }

    // 1280px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        font-size: ${({ theme }) => theme.fontSizes.xlarge};
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
`;

const FeaturesSummaryStyled = styled.p`
    color: ${({ theme, membershipType }) =>
        membershipType === MembershipType.SITTER ? theme.palette.accent : theme.palette.white};
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    // 720px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        font-size: ${({ theme }) => theme.fontSizes.large};
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

const FeaturesListStyled = styled.ul`
    list-style: none;
    color: ${({ theme, membershipType }) =>
        membershipType === MembershipType.SITTER ? theme.palette.accent : theme.palette.white};
    padding: 0;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    display: none;

    // 1024px
    @media screen and (min-width: ${({ theme }) =>
            theme.screenSize.mediumDesktop}px) and (min-height: ${heightBreakPoint2}) {
        display: block;
    }
`;

const FeaturesListItemStyled = styled.li`
    display: flex;
    flex-direction: row;
    padding-bottom: ${({ theme }) => theme.spacing[1]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        padding-bottom: ${({ theme }) => theme.spacing[2]};
        ${focalSmallStyles};
    }
`;

const CheckIconStyled = styled(IconCheck).attrs({
    size: 'large',
})`
    & svg {
        fill: ${({ theme, membershipType }) =>
            membershipType === MembershipType.SITTER ? theme.palette.accent : theme.palette.white};
    }

    margin-right: ${({ theme }) => theme.spacing[1]};
`;

const MembershipButtonStyled = styled(ButtonIcon).attrs(({ membershipType }) => ({
    /* We use these variants to get the correct focus and hover states */
    variant:
        membershipType === MembershipType.SITTER
            ? Button.Variant.SECONDARY
            : Button.Variant.SECONDARY_REVERSED,
    icon: IconChevronRight,
    iconRight: true,
    iconProps: {
        size: 'large',
        palette: membershipType === MembershipType.SITTER ? 'accent' : 'white',
    },
}))`
    background-color: transparent;
    color: ${({ theme, membershipType }) =>
        membershipType === MembershipType.SITTER ? theme.palette.accent : theme.palette.white};
    box-shadow: ${({ theme, membershipType }) =>
        `0 0 0 2px ${
            membershipType === MembershipType.SITTER ? theme.palette.accent : theme.palette.white
        }`};
    width: 100%;

    // 1024px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        height: 72px;
        ${focalSmallStyles};
    }
`;

const MembershipButtonCombinedStyled = styled(MembershipButtonStyled)`
    box-shadow: unset;
    padding: 0;
    text-decoration: underline;
    width: 100%;
    margin-left: 0;

    // // 720px
    // @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
    // }

    // 1024px
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        width: auto;
        margin-left: 1em;
        font-size: ${({ theme }) => theme.fontSizes.large};
        line-height: ${({ theme }) => theme.spacing[6]}};
    }
`;

export {
    Wrapper,
    PanelStyled,
    OwnerPanelStyled,
    SitterPanelStyled,
    CombinedPanelStyled,
    PanelInnerStyled,
    MembershipButtonStyled,
    MembershipButtonCombinedStyled,
    TitleStyled,
    TitleMembership,
    TitleCombinedStyled,
    FeaturesSummaryStyled,
    FeaturesListStyled,
    FeaturesListItemStyled,
    CheckIconStyled,
};
