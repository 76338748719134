import { all, put, take, call, takeEvery } from 'redux-saga/effects';
import * as pageActions from 'containers/Page/actions';
import apiActions, { settings } from 'api/actions';
import { PAGE_ID } from './ProfilePreview.constants';

export function* handleLoadReviews() {
    yield put(
        apiActions.sitter.loadReviews({
            forceReload: true,
            filters: {
                limit: 999,
            },
        })
    );

    const { status, statusCode } = yield take(settings.sitter.loadReviews.DONE);

    if (status !== settings.sitter.loadReviews.SUCCESS) {
        yield put(pageActions.error.create(PAGE_ID, statusCode));
        return false;
    }

    return true;
}

export function* handleLoadProfile() {
    yield put(
        apiActions.sitter.loadProfile({
            forceReload: true,
        })
    );

    const loadProfileResponse = yield take(settings.sitter.loadProfile.DONE);
    if (loadProfileResponse.status !== settings.sitter.loadProfile.SUCCESS) {
        // Error occurred
        yield put(pageActions.error.create(PAGE_ID, loadProfileResponse.statusCode));
        return false;
    }

    // TODO: To get the availability calendar working I think we will need:
    // - loadAvailabilities
    //     this currently only loads `availabilities` where the user
    //     has said they _are_ available. A new API or a mod to this one is
    //     needed to capture `availabilities` where the user has said they
    //     _are not_ available
    // - loadPastAssignments, loadConfirmedAssignments, loadCurrentAssignments, loadUpcomingAssignments
    //     I think all of these will be needed in conjunction with the
    //     availabilities to build the full picture for the calendar

    return true;
}

function* handlePreLoadPage() {
    yield call(handleLoadProfile);
    yield call(handleLoadReviews);

    yield put(pageActions.preloaded.create(PAGE_ID));
}

function* handleLoadPage() {
    yield call(handleLoadProfile);
    yield call(handleLoadReviews);

    yield put(pageActions.loaded.create(PAGE_ID));
}

export default function* sagas() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            handlePreLoadPage
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            handleLoadPage
        ),
    ]);
}
