import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import apiActions from 'api/actions';
import { getAccountSettings } from 'api/selectors/account';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { getRawProfile, getResponseStatus, getUpdateId } from '../selectors';
import { PAGE_ID } from './ProfileDetails.constants';

const LoadableProfileDetails = loadable(() => retry(() => import('./ProfileDetails')));

const mapStateToProps = (state) => ({
    accountSettings: getAccountSettings(state),
    profile: getRawProfile(state),
    responseStatus: getResponseStatus(state),
    updateId: getUpdateId(state),
    isViewingInAppWebView: isViewingInAppWebView(state),
});

const mapDispatchToProps = (dispatch) => ({
    saveProfile: (newProfile) => {
        dispatch(
            apiActions.sitter.updateProfile({
                forceReload: true,
                data: newProfile,
            })
        );
    },
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableProfileDetails), PAGE_ID, true, false, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
