import React, { PureComponent } from 'react';
import { array, arrayOf, bool, func, number, oneOf, string } from 'prop-types';
import Item from './DropdownMenuItem';
import { DropdownMenuStyled } from './Dropdown.style';

export default class DropdownMenu extends PureComponent {
    static propTypes = {
        /**
         * the id of the highlighted item. This provides focus information for screen readers.
         */
        ariaActivedescendant: string,
        /**
         * an id which for the dropdown control which is passed to the dropdown menu's aria labelledby
         * for screen readers
         */
        ariaLabelledBy: string,
        /**
         * the index of the highlighted item
         */
        highlightedIndex: number,
        /**
         * whether the list accepts a multiple selections
         */
        isMultiSelect: bool,
        /**
         * whether the list is visible or not
         */
        isOpen: bool,
        /**
         * the array of items to display in the dropdown menu
         */
        items: array,
        /**
         * A function which takes each option and returns the option value (string to display),
         * and key.
         * e.g.
         * const option = {title: Dogs, id: 10}
         * const itemMapToValueAndKey = (option) => ({value: option.title, key: option.id})
         */
        itemMapToValueAndKey: func,
        /**
         * a reference to the dropdown menu dom element
         */
        menuRef: func,
        /**
         * a mouse over handler that moves the highlighted index to item with mouse over
         */
        onItemsMouseOverHandler: func,
        /**
         * a handler that is called when key down event is recieved
         */
        onKeyDown: func,
        /**
         * a function that is called with the selected item
         */
        onSelectHandler: func,
        /**
         * array of selected items indexes that refer to their index within the Dropdown component's props.items
         */
        selectedItemsIndexes: arrayOf(number),
        /**
         * the type of control presented to the user
         */
        type: oneOf(['checkbox', 'simple', 'switch', 'radio']),
    };

    isSelectedItem(itemIndex) {
        return this.props.selectedItemsIndexes.indexOf(itemIndex) > -1;
    }

    render() {
        const {
            ariaActivedescendant,
            highlightedIndex,
            menuRef,
            onItemsMouseOverHandler,
            onKeyDown,
            onSelectHandler,
            type,
            ...props
        } = this.props;

        return (
            <DropdownMenuStyled
                {...props}
                aria-activedescendant={ariaActivedescendant}
                aria-hidden={!props.isOpen}
                aria-labelledby={props.ariaLabelledBy}
                ref={menuRef}
                role="menu"
                tabIndex="-1"
                onKeyDown={onKeyDown}
                onMouseOver={onItemsMouseOverHandler}
            >
                {props.items.map((option, index) => {
                    const { key, value } = props.itemMapToValueAndKey(option);

                    const isSelected = this.isSelectedItem(index);

                    return (
                        <Item
                            key={`${key}` || `${index}`}
                            data-index={`${index}`}
                            highlighted={highlightedIndex === index}
                            id={`${props.ariaLabelledBy}-${index}`}
                            isSelected={isSelected}
                            onSelectHandler={onSelectHandler}
                            type={type}
                        >
                            {value}
                        </Item>
                    );
                })}
            </DropdownMenuStyled>
        );
    }
}
