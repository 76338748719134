import React, { useCallback, useState, useEffect } from 'react';
import Button from 'components/buttons/Button';
import { IconClose } from 'components/Icon';
import InfoText from '../Field/components/InfoText';
import { isErrored } from '../Field/helper';
import {
    ContainerStyled,
    TextContainerStyled,
    TextBoxStyled,
    ChipsContainerStyled,
    ChipStyled,
    ChipLabelStyled,
    ChipDeleteButtonStyled,
} from './Chips.style';

const ChipsTestIds = {
    chipItem: 'Chips_chipItem',
    removeChipItem: 'Chips_removeChipItem',
    chipsContainer: 'Chips_chipsContainer',
    textBoxInput: 'Chips_textBoxInput',
};

const ChipsContainer = ({ chips, onRemove }) => (
    <ChipsContainerStyled aria-live="polite" id="chips-container">
        {chips.map((chip) => (
            <ChipStyled key={chip} data-testid={ChipsTestIds.chipItem}>
                <ChipLabelStyled>{chip}</ChipLabelStyled>
                <ChipDeleteButtonStyled
                    icon={IconClose}
                    variant={Button.Variant.FLAT}
                    size={Button.Size.SMALL}
                    onClick={() => onRemove(chip)}
                    data-testid={ChipsTestIds.removeChipItem}
                />
            </ChipStyled>
        ))}
    </ChipsContainerStyled>
);

const Chips = ({
    setFieldValue,
    value,
    className,
    id,
    name,
    items,
    setItems,
    validate,
    delimiters,
    error,
    touched,
    onChange,
    onBlur,
    setFieldTouched,
    placeholder,
}) => {
    const [chips, setChips] = useState(items || []);
    const [textBoxValue, setTextBoxValue] = useState(value || '');
    const selectedDelimiters = delimiters || /[\s,\t\n]+/gm; // default delimters: space, tab, new line | global multiline

    useEffect(() => {
        setFieldValue('allItems', chips);
    }, [chips, setFieldValue]);

    const handleOnChange = (event) => {
        onChange(event, chips);
    };

    const deleteChip = (chip) => {
        const updatedChips = chips.filter((item) => item !== chip);
        setItems(updatedChips);
        setChips(updatedChips);
    };

    const validateValue = useCallback(
        (inputValue) => {
            if (validate) {
                return validate(inputValue);
            }
            return true;
        },
        [validate]
    );

    const isDelimiter = (inputValue) => {
        let isADelimiter = false;

        if (selectedDelimiters.test(inputValue)) {
            isADelimiter = true;
        }
        return isADelimiter;
    };

    const isDuplicate = (inputValue, string) => string.indexOf(inputValue) > -1;

    const sanitizeValue = (inputValue) => {
        let sanitizedValue = inputValue;

        sanitizedValue = sanitizedValue.replace(selectedDelimiters, '').toLowerCase();

        return sanitizedValue;
    };

    const itemTokenizer = () => {
        const inputValue = textBoxValue ? textBoxValue.split(selectedDelimiters) : [];

        return inputValue;
    };

    const getTextBoxInput = () => {
        const inputValue = itemTokenizer();

        return inputValue;
    };

    const createChips = () => {
        const itemsArray = getTextBoxInput();

        const chipsString = chips.join();
        itemsArray.forEach((inputItem) => {
            if (!validateValue(inputItem)) {
                // don't do anything
                // leave the text box with the invalid value
            } else if (isDuplicate(sanitizeValue(inputItem), chipsString)) {
                setFieldValue(name, '');
            } else if (validateValue(inputItem)) {
                const newChipsArray = chips;
                newChipsArray.push(sanitizeValue(inputItem));
                setChips(newChipsArray);
                setFieldValue(name, '');
            }
        });
    };

    const createChipsManually = () => {
        createChips();
    };

    const createChipsByDelimiter = () => {
        if (isDelimiter(textBoxValue)) {
            // Set touched to true when a delimeter is entered so that the error message will display
            setFieldTouched(name, true);
            createChips();
        }
    };

    useEffect(() => {
        createChipsByDelimiter();

        // Set touched to false after chips have been created,
        // if there is no orphaned text to validate.
        // This avoids showing an error message on typing the next email
        if (!textBoxValue) {
            setFieldTouched(name, false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chips, textBoxValue]);

    useEffect(() => {
        setTextBoxValue(value);
    }, [value]);

    useEffect(() => {
        setChips(items);
    }, [items]);

    const handleOnBlur = (e) => {
        createChipsManually();

        onBlur(e);
    };

    return (
        <ContainerStyled className={className}>
            <TextContainerStyled
                hasErrored={isErrored(error, touched)}
                aria-controls="chips-container"
            >
                {chips && chips.length > 0 && (
                    <ChipsContainer
                        chips={chips}
                        onRemove={deleteChip}
                        data-testid={ChipsTestIds.chipsContainer}
                    />
                )}
                <TextBoxStyled
                    id={id}
                    name={name}
                    onChangeHandler={handleOnChange}
                    onBlurHandler={(e) => handleOnBlur(e)}
                    // value={textBoxValue}
                    value={value}
                    data-testid={ChipsTestIds.textBoxInput}
                    placeholder={placeholder}
                />
            </TextContainerStyled>
            <InfoText isErrored={isErrored(error, touched)} errorText={error} />
            {/*
             * we need to somehow store all the validate emails, so that they can be sent off
             * when user clicks on 'Send Invite'
             */}
            <input
                type="hidden"
                value={items}
                id="allItems"
                name="allItems"
                data-testid="hidden-field"
            />
        </ContainerStyled>
    );
};

export default Chips;
