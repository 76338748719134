import React from 'react';
import ErrorComponent from 'components/Error';

const Error = ({ statusCode = ErrorComponent.statusCodes.ERROR_404, staticContext }) => {
    if (staticContext) {
        staticContext.statusCode = statusCode;
    }

    return <ErrorComponent statusCode={statusCode} />;
};

export default Error;
