import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

import { createdReducer, updatedReducer } from '../common';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadProfile.SUCCESS:
            return {
                ...state,
                data: {
                    ...transform.getProfile(action.data),
                },
            };
        case actions.sitter.createProfile.SUCCESS:
            return createdReducer(state, action);
        case actions.sitter.updateProfile.SUCCESS:
            return updatedReducer(state, action);
        default:
            return state;
    }
};
