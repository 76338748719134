import { LOCATION_CHANGE } from 'connected-react-router';
import {
    load,
    loaded,
    error,
    setNavbarOptions,
    setFooterOptions,
    setZendeskOptions,
} from '../../containers/Page/actions';

const initialState = {
    pagesLoading: 0,
    navbarOptions: {
        hideNavbar: false,
        disableLogoLink: false,
        hideLeftSide: false,
        hideRightSide: false,
    },
    footerOptions: {
        hideFooter: false,
        hideLinks: false,
    },
    zendeskOptions: {
        isHidden: false,
    },
};

/**
 * This IIFE returns true the first time it is called, after that it always return false.
 * It is used in the reducer in the location change to detect the first time it is called and do a different logic.
 */
const isFirstLocationChange = (() => {
    let firstLocationChange = true;
    return () => {
        if (firstLocationChange) {
            firstLocationChange = false;
            return true;
        }
        return firstLocationChange;
    };
})();

export default (state = initialState, action) => {
    switch (action.type) {
        case load.ACTION:
            return {
                ...state,
                pagesLoading: state.pagesLoading + 1,
            };
        case loaded.ACTION:
            return {
                ...state,
                pagesLoading: state.pagesLoading > 0 ? state.pagesLoading - 1 : 0,
            };
        case error.ACTION:
        case LOCATION_CHANGE: {
            // Return the current state values for hideNavbar and hideFooter the first time the location change is called
            // or when the new pathname is equal to the old one
            // This is done because the the location change action is called when the user loads the site and because
            // we should't change the navbar state if the location change is for the same pathname.
            if (
                isFirstLocationChange() ||
                (action.payload &&
                    action.payload?.location?.previousLocation &&
                    action.payload?.location?.pathname ===
                        action.payload?.location?.previousLocation?.pathname)
            ) {
                return {
                    pagesLoading: 0,
                    navbarOptions: state.navbarOptions,
                    footerOptions: state.footerOptions,
                    zendeskOptions: state.zendeskOptions,
                };
            }

            // The nexts times the location change action is called is when the user changes the route.
            // We clear the hideNavbar and hideFooter then.
            return {
                pagesLoading: 0,
                navbarOptions: {
                    hideNavbar: false,
                    hideLeftSide: false,
                    disableLogoLink: false,
                    hideRightSide: false,
                },
                footerOptions: {
                    hideFooter: false,
                    hideLinks: false,
                },
                zendeskOptions: {
                    isHidden: false,
                },
            };
        }
        case setNavbarOptions.ACTION:
            return {
                ...state,
                navbarOptions: action.navbarOptions,
            };
        case setFooterOptions.ACTION:
            return {
                ...state,
                footerOptions: action.footerOptions,
            };
        case setZendeskOptions.ACTION:
            return {
                ...state,
                zendeskOptions: action.payload,
            };
        default:
            return state;
    }
};
