import { createAction } from 'utils/actions';

const prefix = 'PLANS';

export const regwallOpen = createAction(prefix, 'REGWALL_OPEN', 'membershipPlan', 'membershipType');
export const regwallClose = createAction(prefix, 'REGWALL_CLOSE');
export const setPlanTypeVisible = createAction(prefix, 'SET_PLAN_TYPE_VISIBLE', 'membershipType');
export const selectPlan = createAction(prefix, 'SELECT_PLAN', 'membershipPlan');
export const changeCurrency = createAction(prefix, 'CHANGE_CURRENCY', 'currencyCode');
export const selectPlanFromUrl = createAction(prefix, 'SELECT_PLAN_FROM_URL', 'membershipPlan');
export const loadPlans = createAction(prefix, 'LOAD_PLANS', 'currencyCode');
export const loadPlansSwitchDetails = createAction(prefix, 'LOAD_PLANS_SWITCH_DETAILS', 'planIds');
export const setMembershipTypeSelected = createAction(
    prefix,
    'SET_MEMBERSHIP_TYPE_SELECTED',
    'membershipType'
);
export const setHideTabs = createAction(prefix, 'SET_HIDE_TABS', 'hideTabs');
export const setHasPendingPlanChange = createAction(
    prefix,
    'SET_HAS_PENDING_PLAN_CHANGE',
    'hasPendingPlanChange'
);
export const setFreeTrial = createAction(prefix, 'SET_FREE_TRIAL', 'freeTrial');
