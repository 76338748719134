import styled, { css, keyframes } from 'styled-components';
import CloudinaryImage from 'components/Image/CloudinaryImage';
import Tooltip from 'components/Tooltip';
import { zIndex } from 'components/ZIndexManager';
import { IconCBC, IconIDCheck1 } from 'components/Icon';
import { Button } from 'components/buttons';
import { Link as RouterLink } from 'react-router-dom';
import { visuallyHide } from '../../utils/utils';
import { Span } from '../Typography';
import Favourite from '../Favourite';

const ReadMoreButton = styled(Button).attrs({
    variant: Button.Variant.LINK,
})`
    display: ${({ expanded }) => (expanded ? 'block' : 'inline')};
    padding: 0;
    margin: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    text-decoration: none;
`;

const ProfileWrapper = styled(RouterLink)`
    width: 100%;
    text-decoration: none;
    color: inherit;
`;

const FavouriteStyled = styled(Favourite)`
    position: absolute;
    z-index: ${zIndex}; // nudge above the link
    top: 10px;
    left: 9px;

    // 375
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumHandset}px) {
        top: 6px;
        left: 5px;
    }

    // 480
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeHandset}px) {
        top: 10px;
        left: 9px;
    }

    // 1024
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        top: 18px;
        left: 17px;
    }

    transition: none;

    :hover {
        transform: scale(1.2);
    }

    // Resizing icons at breakpoints is pretty messy
    // First we increase the size of the box
    & > span {
        @media screen and (min-width: ${({ theme }) => `${theme.screenSize.largeHandset}px`}) {
            width: ${({ theme }) => theme.fontSizes.xlarge};
            height: ${({ theme }) => theme.fontSizes.xlarge};
        }
    }

    // Then increase the font-size to enlarge the icon
    & > span > span {
        @media screen and (min-width: ${({ theme }) => `${theme.screenSize.largeHandset}px`}) {
            font-size: ${({ theme }) => theme.fontSizes.xlarge};
        }
    }
`;

const FavouriteLabelStyled = styled.span`
    ${visuallyHide};
`;

const ProfileCardWrapper = styled.div`
    ${() => `
        display: flex;
        flex-direction: column;
    `}
`;

const ProfileCardWrapperTop = styled.div`
    ${({ theme: { screenSize } }) => `
        display: flex;
        flex-direction: column;

        // 375
        @media screen and (min-width: ${screenSize.mediumHandset}px) {
            flex-direction: row;
        }
    `}
`;

const ProfileCardWrapperBottom = styled.div`
    display: flex;
    flex-direction: row;
`;

const cycleAnimation = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
`;

const ProfileCardSkeletonWrapper = styled.div`
    width: 100%;
`;

const ProfileCardSkeleton = styled.div`
    ${({ aspectRatio, theme }) => css`
        position: relative;
        aspect-ratio: ${aspectRatio?.default || 'auto'};
        background: ${theme.palette.grey[300]};

        &::after {
            content: '';
            background: linear-gradient(
                95deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 20%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 80%,
                rgba(255, 255, 255, 0) 100%
            );
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            animation: ${cycleAnimation} 1.75s infinite;
        }

        @media screen and (min-width: ${theme.screenSize.mediumHandset}px) {
            aspect-ratio: ${aspectRatio?.mediumHandset || 'auto'};
        }

        @media screen and (min-width: ${theme.screenSize.smallTablet}px) {
            aspect-ratio: ${aspectRatio?.smallTablet || 'auto'};
        }

        @media screen and (min-width: ${theme.screenSize.smallDesktop}px) {
            aspect-ratio: ${aspectRatio?.smallDesktop || 'auto'};
        }

        @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
            aspect-ratio: ${aspectRatio?.largeDesktop || 'auto'};
        }
    `}
`;

const imageSize = ({ borderRadius, screenSize, spacing }) => `
    border-radius: ${borderRadius.regular};
    // 375
    @media screen and (min-width: ${screenSize.mediumHandset}px) {
        width: 120px;
        height: 120px;
        margin-right: ${spacing[2]};
    }

    // 480
    @media screen and (min-width: ${screenSize.largeHandset}px) {
        width: 160px;
        height: 160px;
    }

    // 720
    @media screen and (min-width: ${screenSize.mediumTablet}px) {
        width: 272px;
        height: 153px;
        margin-right: ${spacing[3]};
    }

    // 900
    @media screen and (min-width: ${screenSize.mediumDesktop}px) {
        height: 196px;
        width: 348px;
        height: 196px;
    }
`;

const ProfileImageWrapper = styled.div`
    margin-right: ${({ theme }) => theme.spacing[0.5]};
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    position: relative;

    ${({ theme }) => imageSize(theme)}
`;

const ProfileSkeletonImageResizeWrapper = styled.div`
    ${({ theme }) => imageSize(theme)}
`;

const ProfileImage = styled(CloudinaryImage)`
    width: 100%;
    max-height: 193px;
    object-fit: cover;

    ${({ theme }) => imageSize(theme)}
`;

const ProfileInfoWrapper = styled.div`
    ${({ theme: { spacing } }) => `
        margin-top: ${spacing[1.5]};
    `}
`;

const ProfileNamesWrapper = styled.div`
    display: flex;
`;

const ProfileNames = styled.div(
    ({ theme: { screenSize, palette, typography, fontWeights } }) => css`
        ${typography.focalSmall};
        font-weight: ${fontWeights.bold};
        color: ${palette.primaryRegular};
        // 720
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            font-size: ${typography.focalSmall['font-size']};
        }
    `
);

const profileHeadlineStyled = css`
    color: ${({ theme }) => theme.palette.grey[700]};
    margin-top: ${({ theme }) => theme.spacing[2]};
    font-size: ${({ theme }) => theme.typography.p['font-size']};
`;

const ProfileHeadlineDesktopStyled = styled.div`
    ${profileHeadlineStyled};
    display: none;

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: flex;
    }
`;

const ProfileHeadlineTabletStyled = styled.div`
    ${profileHeadlineStyled};
    font-size: ${({ theme }) => theme.typography.smallText['font-size']};
    display: none;

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.largeHandset}px) {
        display: flex;
    }

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: flex;
        font-size: ${({ theme }) => theme.typography.p['font-size']};
    }

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: none;
    }
`;

const ProfileHeadlineMobileStyled = styled.div`
    ${profileHeadlineStyled};
    font-size: ${({ theme }) => theme.typography.smallText['font-size']};

    @media (min-width: ${({ theme }) => theme.screenSize.largeHandset}px) {
        display: none;
    }
`;

const ProfileLocation = styled.div`
    ${({ theme: { palette, typography } }) => `
        font-size: ${typography.smallText['font-size']};
        color: ${palette.textLight};
    `}
`;

const TooltipWrapper = styled.div(
    ({ theme: { spacing } }) => css`
        // Matched to the icon width this aligns the tooltip centrally
        max-width: 28px;
        margin: ${spacing[0.5]} ${spacing[0.5]} ${spacing[0.5]} 0;
    `
);

const TooltipStyled = styled(Tooltip)(
    ({ theme: { borderRadius } }) => css`
        width: unset;
        border-radius: ${borderRadius.regular};
        > p {
            white-space: nowrap;
        }
    `
);

const iconsStyles = `
    width: 28px;
    height: 28px;
`;

const IDBadgeStyled = styled(IconIDCheck1)`
    ${iconsStyles}
    path {
        fill: ${({ theme: { palette } }) => palette.primaryRegular};
    }
`;

const CBCBadgeStyled = styled(IconCBC)`
    ${iconsStyles}
    path {
        fill: ${({ theme: { palette } }) => palette.primaryRegular};
    }
`;

const ReviewStyled = styled.div`
    margin-top: ${({ theme }) => theme.spacing[1]};

    // 960
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        background-color: ${({ theme }) => theme.palette.grey[100]};
        border-radius: ${({ theme: { borderRadius } }) => borderRadius.regular};
        margin-top: ${({ theme }) => theme.spacing[2]};
    }
`;

const ReviewWrapper = styled(RouterLink)`
    display: flex;
    flex: 1;
    flex-direction: column;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.grey[600]};
    // 960
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        padding: ${({ theme: { spacing } }) => spacing[2]};
    }
`;

const ReviewStarsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ReviewCountStyle = styled(Span)`
    color: ${({ theme }) => theme.palette.primaryRegular};
    font-size: ${({ theme }) => theme.typography.smallText['font-size']};
    // 720
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }
`;

const ReviewTextWrapper = styled.div`
    display: none;
    // 900
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        display: inline-block;
        margin-top: ${({ theme }) => theme.spacing[1]};
    }
`;

const ReviewDesktopVisible = styled.div`
    display: none;
    // 960
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        display: flex;
        color: ${({ theme }) => theme.palette.grey[600]};
    }
`;

const ReviewMobileVisible = styled.div`
    display: flex;
    @media (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        display: none;
    }
`;

export {
    FavouriteStyled,
    FavouriteLabelStyled,
    ProfileWrapper,
    ProfileCardWrapper,
    ProfileCardWrapperTop,
    ProfileCardWrapperBottom,
    ProfileCardSkeletonWrapper,
    ProfileCardSkeleton,
    ProfileImageWrapper,
    ProfileSkeletonImageResizeWrapper,
    ProfileImage,
    ProfileInfoWrapper,
    ProfileNamesWrapper,
    ProfileNames,
    ProfileHeadlineDesktopStyled,
    ProfileHeadlineTabletStyled,
    ProfileHeadlineMobileStyled,
    ProfileLocation,
    TooltipWrapper,
    TooltipStyled,
    IDBadgeStyled,
    CBCBadgeStyled,
    ReviewStyled,
    ReviewWrapper,
    ReviewStarsWrapper,
    ReviewTextWrapper,
    ReviewCountStyle,
    ReviewDesktopVisible,
    ReviewMobileVisible,
    ReadMoreButton,
};
