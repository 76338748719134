import paths from './route-paths';
import Hub from './Hub';
import HubRedirect from './HubRedirect';
import Home from './Home';
import Location from './Location';
import Pets from './Pets';
import AboutYou from './AboutYou';
import Responsibilities from './Responsibilities';
import Title from './Title';
import Dates from './Dates';
import ListingPreview from './ListingPreview';
import WhatsNext from './WhatsNext';
import SafetySecurity from './SafetySecurity';

export default [
    {
        path: paths.hub,
        exact: true,
        component: Hub,
    },
    {
        path: paths.hubRedirect,
        exact: true,
        component: HubRedirect,
    },
    {
        path: paths.aboutYou,
        exact: true,
        component: AboutYou,
    },
    {
        path: paths.location,
        exact: true,
        component: Location,
    },
    {
        path: paths.home,
        exact: true,
        component: Home,
    },
    {
        path: paths.pets,
        exact: true,
        component: Pets,
    },
    {
        path: paths.responsibilities,
        exact: true,
        component: Responsibilities,
    },
    {
        path: paths.title,
        exact: true,
        component: Title,
    },
    {
        path: paths.dates,
        exact: true,
        component: Dates,
    },
    {
        path: paths.listingPreview,
        exact: true,
        component: ListingPreview,
    },
    {
        path: paths.whatsNext,
        exact: true,
        component: WhatsNext,
    },
    {
        path: paths.safetySecurity,
        exact: true,
        component: SafetySecurity,
    },
];
