import { settings as actions } from '../../actions';
import {
    baseInitialState,
    loadedDataReducer,
    createdDataReducer,
    updatedDataReducer,
    deletedDataReducer,
} from '../common';

export const initialState = { ...baseInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        // case actions.owner.loadListings.SUCCESS:
        //     return {
        //         ...state,
        //         data: action.data.results.reduce((reminders, listing) => {
        //             listing.reminders.forEach((reminder) => {
        //                 reminders[reminder.id] = reminder;
        //             });
        //             return reminders;
        //         }, {}),
        //     };
        case actions.owner.loadListingReminders.SUCCESS:
            return loadedDataReducer(state, action);
        case actions.owner.createListingReminder.SUCCESS:
            return createdDataReducer(state, action);
        case actions.owner.updateListingReminder.SUCCESS:
            return updatedDataReducer(state, action);
        case actions.owner.removeListingReminder.SUCCESS: {
            return deletedDataReducer(state, action);
        }
        default:
            return state;
    }
};
