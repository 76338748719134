import styled from 'styled-components';

const PageSpinnerContainerStyled = styled.div`
    display: flex;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing[6]};
`;

// eslint-disable-next-line import/prefer-default-export
export { PageSpinnerContainerStyled };
