import React from 'react';
import { NotificationBarStyled } from './CookiePolicy.styles';

const CookiePolicy = ({ onClose, t }) => (
    <NotificationBarStyled
        linkText={t('components_cookiePolicy_body')}
        linkUrl="/pages/cookie-policy/"
        underlinedText={t('components_cookiePolicy_link')}
        target="_blank"
        onClose={onClose}
        id="cookie-policy"
    />
);

export default CookiePolicy;
