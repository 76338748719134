import styled, { css } from 'styled-components';
import { P, H2, H4, H3 } from 'typography';
import { IconEmail, IconExclamationCircle, IconQuestionMark } from 'components/Icon';
import Dialog from 'components/Dialog';
import Link from 'components/Link';
import { Button } from 'components/buttons';
import TrustpilotMini from 'components/Trustpilot/TrustpilotMini';
import { lightHoverReversed, lightActiveReversed } from 'utils/colors';

export const DialogStyled = styled(Dialog)(
    ({ theme: { screenSize, palette, borderRadius } }) => css`
        max-width: unset;
        border-radius: ${borderRadius.small};
        background-color: ${palette.white};
        overflow: scroll;
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            max-width: 1176px;
        }
    `
);

export const ModalContents = styled.div(
    ({ theme: { spacing, palette } }) => css`
        padding: ${spacing[4]} ${spacing[3]};
        background-color: ${palette.white};
        display: flex;
        flex-direction: column;
    `
);

export const TitleWrapper = styled.div(
    ({ theme: { spacing, palette, screenSize } }) => css`
        padding: ${spacing[2]} 0;
        border-bottom: 1px solid ${palette.grey[200]};
        width: 100%;

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            padding: ${spacing[2.5]} 0;
        }
    `
);

export const ModalTitle = styled(P)(
    ({ theme: { palette, fontWeights } }) => css`
        font-weight: ${fontWeights.bold};
        color: ${palette.grey[500]};
        text-align: center;
        margin: 0;
    `
);

export const ModalSubTitle = styled(H2)(
    ({ theme: { fontSizes, screenSize, fontWeights, palette, spacing } }) => css`
        font-weight: ${fontWeights.bold};
        text-align: center;
        font-size: ${fontSizes.medium};
        color: ${palette.grey[700]};
        margin-bottom: ${spacing[4]};
        margin-top: 0;

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            font-size: ${fontSizes.xlarge};
            margin-bottom: ${spacing[8]};
        }
    `
);

export const ModalAuxText = styled(H3)(
    ({ theme: { palette, spacing, screenSize } }) => css`
        text-align: center;
        color: ${palette.grey[600]};
        margin-bottom: ${spacing[4]};
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;

        // 1024px
        @media screen and (min-width: ${screenSize.largeHandset}px) {
            white-space: break-spaces;
        }
    `
);

export const SectionWraper = styled.div(
    ({ theme: { screenSize } }) => css`
        margin: 0 auto;

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            max-width: 600px;
            min-width: 520px;
        }
    `
);

export const BenefitsTitle = styled(H4)(
    ({ theme: { palette, fontWeights, fontSizes, spacing } }) => css`
        font-weight: ${fontWeights.bold};
        color: ${palette.grey[700]};
        font-size: ${fontSizes.medium};
        margin-bottom: ${spacing[4]};
        margin-top: 0;
    `
);

export const BenefitsText = styled.div(
    ({ theme: { palette, fontWeights, fontSizes, spacing } }) => css`
        font-weight: ${fontWeights.bold};
        color: ${palette.accent};
        font-size: ${fontSizes.small};
        margin-bottom: ${spacing[3]};
        margin-top: 0;
        display: flex;
        align-items: flex-start;
    `
);

export const SitterContainer = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        margin-bottom: ${spacing[3]};
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${spacing[2]};

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            gap: ${spacing[4]};
        }
    `
);

export const SitterProfileImg = styled.img(
    ({ theme: { screenSize } }) => css`
        display: inline-block;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        object-fit: cover;

        &:nth-child(4) {
            display: none;
        }

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            width: 120px;
            height: 120px;

            &:nth-child(4) {
                display: inline-block;
            }
        }
    `
);

export const FeatureLinkContainer = styled.ul(
    ({ theme: { spacing, palette } }) => css`
        margin-bottom: ${spacing[4]};
        margin-top: 0;

        && li::marker {
            color: ${palette.primaryRegular};
        }
    `
);

export const FeatureLink = styled(Link)(
    ({ theme: { palette, fontWeights, fontSizes } }) => css`
        color: ${palette.primaryRegular};
        font-weight: ${fontWeights.bold};
        font-size: ${fontSizes.small};
        text-decoration: underline;
    `
);

export const FeatureExternalLink = styled.a(
    ({ theme: { palette, fontWeights, fontSizes } }) => css`
        color: ${palette.primaryRegular};
        font-weight: ${fontWeights.bold};
        font-size: ${fontSizes.small};
        text-decoration: underline;
        cursor: pointer;
    `
);

export const ImageGrid = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        margin-top: 0;
        margin-bottom: 0;
        display: grid;
        overflow: hidden;
        height: 355px;
        width: auto;
        max-width: 475px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: ${spacing[2]} ${spacing[2]};
        grid-template-areas:
            'Image1 Image2'
            'Image4 Image2'
            'Image4 Image5';

        // 600px
        @media screen and (min-width: ${screenSize.smallTablet}px) {
            height: 400px;
            width: 540px;
            max-width: unset;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            gap: ${spacing[2]} ${spacing[2]};
            grid-template-areas:
                'Image1 Image2 Image3'
                'Image4 Image2 Image6'
                'Image4 Image5 Image6';
        }
    `
);

export const ImageGridItem = styled.img(
    ({ theme: { screenSize, borderRadius } }) => css`
        width: 100%;
        height: 100%;
        border-radius: ${borderRadius.regular};
        object-fit: cover;
        overflow: hidden;

        &:nth-child(3) {
            display: none;
        }

        &:nth-child(6) {
            display: none;
        }

        // 600px
        @media screen and (min-width: ${screenSize.smallTablet}px) {
            &:nth-child(3) {
                display: inline;
            }

            &:nth-child(6) {
                display: inline;
            }
        }
    `
);

export const ContactUsContainer = styled.div(
    ({ theme: { spacing, screenSize, palette } }) => css`
        margin-bottom: ${spacing[3.5]};
        margin-top: ${spacing[7]};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top: 1px solid ${palette.grey[100]};

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            margin-top: ${spacing[4]};
            margin-bottom: ${spacing[4]};
            justify-content: flex-start;
            align-items: flex-start;
        }
    `
);

export const ContactUsTitle = styled(H4)(
    ({ theme: { palette, spacing } }) => css`
        color: ${palette.grey[700]};
        margin-bottom: ${spacing[3]};
        margin-top: ${spacing[4]};
    `
);

export const ContactUsText = styled(P)(
    ({ theme: { palette, fontSizes, spacing, screenSize } }) => css`
        color: ${palette.grey[700]};
        font-size: ${fontSizes.small};
        margin-bottom: ${spacing[3]};
        margin-top: 0;

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            margin-bottom: ${spacing[4]};
            width: 520px;
        }
    `
);

export const ContactUsButtonsContainer = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        margin-bottom: ${spacing[3.5]};
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ${spacing[2]};
        width: 100%;

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            margin-bottom: ${spacing[4]};
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: row;
            gap: ${spacing[2]};
        }
    `
);

export const StyledBenefitsIcon = styled(IconExclamationCircle)(
    ({ theme: { palette, spacing } }) => css`
        margin-right: ${spacing[2]};
        margin-top: ${spacing[0.5]};
        && svg {
            fill: ${palette.accent};
            width: 22px;
            height: 22px;
        }
    `
);

export const StyledVisitHelpIcon = styled(IconQuestionMark)(
    ({ theme: { palette, spacing } }) => css`
        margin-right: ${spacing[1]};
        && svg {
            fill: ${palette.primaryRegular};
            width: 22px;
            height: 22px;
        }
    `
);

export const StyledSendUsMessageIcon = styled(IconEmail)(
    ({ theme: { palette, spacing } }) => css`
        margin-right: ${spacing[1]};
        && svg {
            fill: ${palette.primaryRegular};
            width: 22px;
            height: 22px;
        }
    `
);

export const TrustPilotContainer = styled.div(
    ({ theme: { spacing, screenSize, palette } }) => css`
        margin-bottom: ${spacing[1.5]};
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top: 1px solid ${palette.grey[100]};
        border-bottom: 1px solid ${palette.grey[100]};
        padding-top: ${spacing[6]};
        padding-bottom: ${spacing[4.5]};

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            margin-bottom: ${spacing[3]};
        }
    `
);

export const TrustpilotMiniStyled = styled(TrustpilotMini)`
    min-height: 40px;
    width: 100%;
`;

export const ModalFooter = styled.div(
    ({ theme: { spacing, screenSize } }) =>
        css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${spacing[1.5]} ${spacing[3]};
            gap: ${spacing[3]};

            // 1024px
            @media screen and (min-width: ${screenSize.mediumDesktop}px) {
                margin-bottom: ${spacing[12]};
                justify-content: space-around;
                padding: unset;
                gap: unset;
            }
        `
);

export const ButtonStyled = styled(Button)(
    ({ theme: { spacing, fontWeights, fontSizes } }) =>
        css`
            padding-left: ${spacing[4]};
            padding-right: ${spacing[4]};
            font-weight: ${fontWeights.bold};
            font-size: ${fontSizes.small};
        `
);

export const ExternalLinkStyled = styled.a(
    ({ theme: { spacing, fontWeights, fontSizes, screenSize, palette } }) => css`
        transition-property: color, background-color, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
        box-sizing: border-box;
        display: flex;

        color: ${palette.primaryRegular};
        background-color: transparent;
        box-shadow: ${`0 0 0 1px ${palette.primaryRegular}`};
        overflow: hidden;
        padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2]}`};
        padding-left: ${spacing[4]};
        padding-right: ${spacing[4]};
        font-weight: ${fontWeights.bold};
        font-size: ${fontSizes.small};
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        vertical-align: middle;

        text-decoration: none;

        border: none;
        border-radius: ${({ theme }) => theme.borderRadius.button};

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            width: auto;
        }

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${() => lightHoverReversed('rgba(255,255,255,0)')};
            }
        }
        &:active:enabled {
            background-color: ${() => lightActiveReversed('rgba(255,255,255,0)')};
        }
    `
);

export const ClosedImageWrapper = styled.div(
    () => css`
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    `
);

export const ClosedImage = styled.img(
    ({ theme: { screenSize } }) => css`
        display: inline-block;
        width: auto;
        height: auto;
        object-fit: cover;

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            width: auto;
            height: auto;
        }
    `
);

export const ModalFooterClosed = styled.div(
    ({ theme: { spacing, screenSize } }) =>
        css`
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${spacing[1.5]} ${spacing[3]};

            // 1024px
            @media screen and (min-width: ${screenSize.mediumDesktop}px) {
                justify-content: flex-end;
            }
        `
);

export const ButtonCloseStyled = styled(Button)(
    ({ theme: { spacing, fontWeights, fontSizes, screenSize } }) =>
        css`
            padding-left: ${spacing[4]};
            padding-right: ${spacing[4]};
            font-weight: ${fontWeights.bold};
            font-size: ${fontSizes.small};
            width: 100%;
            // 1024px
            @media screen and (min-width: ${screenSize.mediumDesktop}px) {
                width: auto;
            }
        `
);
