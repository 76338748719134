import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { retry } from 'utils/ssr';
import { getMembershipStatus, getMembershipType } from 'utils/account';
import { getIsLoggedIn, getSeoContent } from 'shared/selectors';
import { getAccount } from 'api/selectors/account';
import { getSearchProfile } from 'api/selectors/search';
import asPage from 'containers/Page';
import { VariationTypes } from 'containers/ExperimentalFeature';
import apiActions from 'api/actions';

import { isOwnerSitterProfileFavourite } from 'api/selectors';
import {
    getHasApprovedListing,
    getOwnerListingId,
    getSittersLookingInYourAreaVariant,
} from './selectors';

export const mapStateToProps = (state, props) => ({
    profile: getSearchProfile(state, props.match.params.id),
    membershipStatus: getMembershipStatus(getAccount(state).membershipPlan),
    membershipType: getMembershipType(getAccount(state).membershipPlan),
    isLoggedIn: getIsLoggedIn(state),
    hasApprovedListing: getHasApprovedListing(state),
    ownerListingId: getOwnerListingId(state),
    seoContent: getSeoContent(state),
    router: state.router,
    redirect: '/test',
    profileHasLoaded: state.pages.search.profileDetail.profileLoaded,
    showSittersLookingInYourAreaTestFeatures:
        getSittersLookingInYourAreaVariant(state) === VariationTypes.VARIATION1,
    isFavourited: isOwnerSitterProfileFavourite(state, props.match.params.id),
});

const mapDispatchToProps = (dispatch) => ({
    reloadOwnerFavourites: () => {
        dispatch(apiActions.owner.loadFavourites({ forceReload: true }));
    },
});

const LoadableProfileDetail = loadable(() => retry(() => import('./ProfileDetail')));

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(asPage(withTranslation()(LoadableProfileDetail), 'ProfileDetail', true, true));
