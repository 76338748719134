import { all, call, takeEvery } from 'redux-saga/effects';
import { trackExperimentalFeature } from './helpers';
import { logVariation, LogVariationAction } from './actions';

export function* handleLogVariation(action) {
    const { name, variation, description } = action;
    yield call(trackExperimentalFeature, name, variation, description);
}

export default function* sagas() {
    yield all([takeEvery(logVariation.ACTION, handleLogVariation)]);
}
