import listingEdit from './listing/edit/routes';
import listingWelcomeGuide from './listing/welcome-guide/routes';
import profile from './profile/edit/routes';
import assignments from './assignments/routes';
import saved from './saved/routes';
import membershipSettings from './settings/membership/routes';
import settings from './settings/routes';
import Verifications from './Verifications';
import Favourites from './Favourites';
import Inbox from './Inbox';
import YourDates from './YourDates';
import Applications from './Applications';
import Plans from './Plans';
import paths from './route-paths';
import Upgrade from './Upgrade';

export default [
    {
        path: paths.user.verifications,
        exact: true,
        component: Verifications,
    },
    {
        path: paths.user.favourites,
        exact: true,
        component: Favourites,
    },
    {
        path: paths.user.dates,
        exact: true,
        component: YourDates,
    },
    {
        path: paths.user.inbox,
        exact: true,
        component: Inbox,
    },
    {
        path: paths.user.applications,
        exact: true,
        component: Applications,
    },
    ...listingEdit,
    ...listingWelcomeGuide,
    ...profile,
    ...assignments,
    ...saved,
    ...membershipSettings,
    {
        path: paths.user.plans,
        exact: true,
        component: Plans,
    },
    ...settings,
    {
        path: paths.user.upgrade,
        exact: true,
        component: Upgrade,
    },
];
