import { put, take, select } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { SortDirection } from 'api/helpers/search/constants';
import { NEARBY_PROFILES } from 'config/search';
import { createProfilesSearchQuery } from 'utils/searchProfiles';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
    userTypes,
    VariationTypes,
} from 'containers/ExperimentalFeature';

const getSlugSearch = (geoHierarchy) => ({
    featureCode: ['p'],
    admin1Slug: geoHierarchy.admin1Slug,
    countrySlug: geoHierarchy.countrySlug,
});

const getAdmin2Search = (geoHierarchy) => ({
    featureCode: ['ADM2'],
    admin2Slug: geoHierarchy.admin2Slug,
    countrySlug: geoHierarchy.countrySlug,
});

const getAdmin1Search = (geoHierarchy) => ({
    featureCode: ['ADM1'],
    admin1Slug: geoHierarchy.admin1Slug,
    countrySlug: geoHierarchy.countrySlug,
});

const getCountrySearch = (geoHierarchy) => ({
    'featureCode[]': ['PCLI', 'PCL'],
    countrySlug: geoHierarchy.countrySlug,
});

const getGeoPointSearch = (geoHierarchy) => {
    if (geoHierarchy.slug) {
        return getSlugSearch(geoHierarchy);
    }
    if (geoHierarchy.admin2Slug) {
        return getAdmin2Search(geoHierarchy);
    }
    if (geoHierarchy.admin1Slug) {
        return getAdmin1Search(geoHierarchy);
    }
    if (geoHierarchy.countrySlug) {
        return getCountrySearch(geoHierarchy);
    }

    return null;
};

function* getGeoPointForGeoHierarchy({ geoHierarchy }) {
    const loadPlacesParams = getGeoPointSearch(geoHierarchy);

    yield put(
        apiActions.search.loadPlaces({
            forceReload: true,
            filters: loadPlacesParams,
        })
    );

    const { data } = yield take(settings.search.loadPlaces.DONE);

    if (data && data.length > 0) {
        return {
            ...data[0].location,
        };
    }

    return null;
}

function* doSearchNearbySitters({ filters, place }) {
    const newFilters = {
        ...filters,
        geoData: undefined,
        geoPoint: undefined,
        geoHierarchy: undefined,
        // Always sort by proximity to the search location
        sort: [
            {
                distance: {
                    latitude: place.location.lat,
                    longitude: place.location.lon,
                    order: SortDirection.ASC,
                },
            },
        ],
    };

    // We overfetch by the possible number of duplicates so we can always show the desired amount
    const newQuery = createProfilesSearchQuery(
        1,
        newFilters,
        [],
        NEARBY_PROFILES.resultsToShow + NEARBY_PROFILES.showWhenLessThan
    );

    const searchQueryFilters = newQuery.getRequestData();

    yield put(
        apiActions.search.loadProfiles({
            forceReload: true,
            data: {
                id: NEARBY_PROFILES.key,
                rawFilters: searchQueryFilters,
                searchType: NEARBY_PROFILES.key,
            },
            filters: {
                query: JSON.stringify(searchQueryFilters),
            },
        })
    );

    // Wait for results
    const { data, status, statusCode } = yield take(
        (res) =>
            res.type === settings.search.loadProfiles.DONE &&
            res.requestData.searchType === NEARBY_PROFILES.key
    );

    const { variation } = yield select(getExperimentalFeatureVariationSelector, {
        experiment: experiments.SEARCH_PROFILE_LAYOUT,
        excludeCombo: [userTypes.PaidUser, userTypes.AnonymousUser, userTypes.ExpiredUser],
    });

    const isVariation1OfProfileTest = variation === VariationTypes.VARIATION1;

    if (isVariation1OfProfileTest && data.results && data.results.length > 0) {
        for (let i = 0; i < data.results.length; i++) {
            yield put(
                apiActions.search.loadProfile({
                    data: {
                        id: data.results[i].id,
                    },
                })
            );
        }
    }
}

export {
    doSearchNearbySitters as default,
    getSlugSearch,
    getAdmin1Search,
    getAdmin2Search,
    getCountrySearch,
    getGeoPointSearch,
    getGeoPointForGeoHierarchy,
};
