import styled, { css } from 'styled-components';
import Link from 'components/Link';
import { Panel } from 'components/layouts';
import { ErrorMessage } from 'components/forms';
import { Button } from 'components/buttons';
import { ErrorMessageStyled } from 'components/forms/RegisterForm';
import { P, H1, h1SmallStyles } from 'typography';

export const FormContainerStyled = styled.div(
    ({ theme: { spacing } }) => css`
        margin: ${spacing[2]} 0 0;
    `
);

export const linkStyles = css`
    display: inline;
    text-align: center;
    text-decoration: underline;
    padding: 0;
`;

export const LinkStyled = styled(Link)`
    ${linkStyles};
`;

export const LinkButtonStyled = styled(Button).attrs({
    variant: Button.Variant.LINK,
})`
    ${linkStyles};
`;

export const LoginMessageContainerStyled = styled.span(
    ({ theme: { spacing } }) => css`
        margin: 0 ${spacing[1]};
    `
);

export const LoginMessageStyled = styled.div`
    text-align: center;
`;

export const PanelStyled = styled(Panel)(
    ({ theme: { spacing, screenSize } }) => css`
        padding: ${spacing[4]} ${spacing[3]};

        /* 600px */
        @media screen and (min-width: ${screenSize.smallTablet}px) {
            padding-left: ${spacing[12]};
            padding-right: ${spacing[12]};
        }

        /* 720px */
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            padding: ${spacing[8]} ${spacing[6]};
        }

        /* 1280px */
        @media screen and (min-width: ${screenSize.largeDesktop}px) {
            padding-left: ${spacing[8]};
            padding-right: ${spacing[8]};
        }
    `
);

export const SignUpButtonErrorMessageStyled = styled(ErrorMessageStyled)(
    ({ theme: { spacing } }) => css`
        margin-bottom: ${spacing[2]};
    `
);

export const ThirdPartySignUpButtonError = styled(ErrorMessage)(
    ({ theme: { spacing } }) => css`
        margin-top: 0;
        margin-bottom: ${spacing[2]};
        text-align: center;
        justify-content: center;

        > span {
            flex: 0 auto;
        }

        & svg {
            height: 16px;
            width: 16px;
        }
    `
);

export const IntroParagraphStyled = styled(P)(
    ({ theme: { spacing } }) => css`
        display: block;
        text-align: center;
        margin: 0;
        margin-bottom: ${spacing[3]};
    `
);

export const ButtonContainerStyled = styled.div(
    ({ theme: { spacing } }) => css`
        margin-top: ${spacing[3]};
    `
);

export const ButtonContainerTest = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        margin-top: ${spacing[3]};
        margin-bottom: ${spacing[3]};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            flex-direction: row;
            flex-flow: row wrap;
        }
    `
);

export const GoogleButton = styled.div(
    ({ theme: { spacing } }) => css`
        height: 40px;
        min-height: 40px;
        margin-bottom: ${spacing[2]};
    `
);

export const HeadingStyled = styled(H1)(
    ({ theme: { spacing, screenSize, typography } }) => css`
        ${h1SmallStyles};
        text-align: center;
        display: block;
        margin: 0 0 ${spacing[3]};

        /* 720px */
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            ${typography.h1}
        }
    `
);
