import { call, takeEvery } from 'redux-saga/effects';

import { routes } from 'utils/routes';

import * as actions from './actions';

export function redirectToLogout() {
    window.location = routes.logout();
}

export function* logout() {
    // Do server side redirect to get new JWT, geoIP, etc.
    yield call(redirectToLogout);
}

export default function* logoutSaga() {
    yield takeEvery((action) => action.type === actions.logout.ACTION, logout);
}
