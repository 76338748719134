import { locationName } from 'api/helpers/format/location';

// eslint-disable-next-line import/prefer-default-export
export const SEARCH_FILTERS = {
    create: ({
        category,
        query,
        items,
        christmasSitsChosen = false,
        searchOptions = {},
        searchType,
    }) => {
        const { listId, totalResults, possibleResults, searchMethod, savedSearchName, page } =
            searchOptions;
        const filters = [];
        if (query) {
            // Common
            if (query.geoHierarchy) {
                if (query.place && query.place.location) {
                    filters.push({
                        type: 'geoPointLatitude',
                        value: query.place.location.lat,
                    });
                    filters.push({
                        type: 'geoPointLongitude',
                        value: query.place.location.lon,
                    });
                }

                const prefix = query.seoHierarcy ? 'seoGeo' : 'geo';
                if (query.geoHierarchy.countrySlug) {
                    filters.push({
                        type: `${prefix}HierarchyCountrySlug`,
                        value: query.geoHierarchy.countrySlug,
                    });
                }
                if (query.geoHierarchy.admin1Slug) {
                    filters.push({
                        type: `${prefix}HierarchyAdmin1Slug`,
                        value: query.geoHierarchy.admin1Slug,
                    });
                }
                if (query.geoHierarchy.slug) {
                    filters.push({
                        type: `${prefix}HierarchySlug`,
                        value: query.geoHierarchy.slug,
                    });
                }
            }
            if (query.geoPoint) {
                filters.push({
                    type: 'geoPointLatitude',
                    value: query.geoPoint.latitude,
                });

                filters.push({
                    type: 'geoPointLongitude',
                    value: query.geoPoint.longitude,
                });

                filters.push({
                    type: 'searchRadius',
                    value: query.geoPoint.distance,
                });

                if (query.place) {
                    const { countrySlug, admin1Slug, name } = query.place;

                    if (countrySlug) {
                        filters.push({
                            type: 'geoHierarchyCountrySlug',
                            value: countrySlug,
                        });
                    }

                    if (admin1Slug) {
                        filters.push({
                            type: 'geoHierarchyAdmin1Slug',
                            value: admin1Slug,
                        });
                    }
                    if (name) {
                        filters.push({
                            type: 'geoHierarchySlug',
                            value: name,
                        });
                    }
                }
            }

            if (query.place && query.place.id) {
                filters.push({
                    type: 'placeId',
                    value: query.place.id,
                });

                filters.push({
                    type: 'selected',
                    value: locationName(query.place, true),
                });
            }
            // Listing
            if (query.assignments) {
                const { assignments } = query;
                if (assignments.dateFrom && assignments.dateTo) {
                    filters.push({
                        type: 'assignmentsDateFrom',
                        value: assignments.dateFrom,
                    });
                    filters.push({
                        type: 'assignmentsDateTo',
                        value: assignments.dateTo,
                    });
                }
                if (assignments.durationInDays) {
                    const { durationInDays } = assignments;
                    if (durationInDays.minimum) {
                        filters.push({
                            type: 'durationInDaysMinimum',
                            value: durationInDays.minimum,
                        });
                    }
                    if (durationInDays.maximum) {
                        filters.push({
                            type: 'durationInDaysMaximum',
                            value: durationInDays.maximum,
                        });
                    }
                }
            }

            if (query.pets) {
                const pets = query.pets.filter((pet) => !pet.exclude).map((pet) => pet.type);
                if (pets) {
                    filters.push({
                        type: 'pets',
                        value: pets.join(','),
                    });
                }
            }
            if (query.localAttractions) {
                filters.push({
                    type: 'localAttractions',
                    value: query.localAttractions.join(','),
                });
            }
            if (query.homeType) {
                filters.push({
                    type: 'homeType',
                    value: query.homeType.join(','),
                });
            }
            if (query.sortBy) {
                filters.push({
                    type: 'sortBy',
                    value: query.sortBy.join(','),
                });
            }
            if (query.familyFriendly) {
                filters.push({
                    type: 'familyFriendly',
                    value: true,
                });
            }
            if (query.isAccessibleByPublicTransport) {
                filters.push({
                    type: 'accessiblePublicTransport',
                    value: true,
                });
            }
            if (query.wifiAvailable) {
                filters.push({
                    type: 'wifiAvailable',
                    value: query.wifiAvailable.join(','),
                });
            }
            if (query.disabledAccess) {
                filters.push({
                    type: 'disabledAccess',
                    value: true,
                });
            }
            if (query.carIncluded) {
                filters.push({
                    type: 'carIncluded',
                    value: true,
                });
            }

            // Profile
            if (query.availability) {
                const { availability } = query;
                if (availability.dateFrom) {
                    filters.push({
                        type: 'availabilityDateFrom',
                        value: availability.dateFrom,
                    });
                }
                if (availability.dateTo) {
                    filters.push({
                        type: 'availabilityDateTo',
                        value: availability.dateTo,
                    });
                }
            }
            if (query.animalExperience) {
                if (query.animalExperience) {
                    filters.push({
                        type: 'animalExperience',
                        value: query.animalExperience.join(','),
                    });
                }
            }
            if (query.hasExperience) {
                filters.push({
                    type: 'hasExperience',
                    value: true,
                });
            }
            if (query.reviews) {
                if (query.reviews.quantityMinimum) {
                    filters.push({
                        type: 'reviewsQuantityMinimum',
                        value: query.reviews.quantityMinimum,
                    });
                }
            }
            if (query.verificationLevel) {
                filters.push({
                    type: 'verificationLevel',
                    value: query.verificationLevel.join(','),
                });
            }
        }

        const sorts = (query && query.sort) || [];
        const products = items.map((item, idx) => ({
            product_id: item.id,
            category,
            position: idx,
            assignment_ids: item.assignments
                ? item.assignments.map((assignment) => assignment.id)
                : [],
        }));

        let payload = {
            list_id: listId || 'default',
            totalResults,
            possibleResults,
            category,
            products,
            method: searchMethod,
            name: savedSearchName,
            page,
        };
        if (christmasSitsChosen) {
            payload = {
                ...payload,
                christmasSitsChosen: true,
            };
        }

        for (let i = 0; i < items.length; i += 1) {
            payload[`product_${i}`] = items[i].id;
        }

        if (filters.length > 0 || sorts.length > 0) {
            payload.filters = filters;
            payload.sorts = sorts;
        }

        if (searchType) {
            payload.searchType = searchType;
        }
        return {
            type:
                filters.length > 0 || sorts.length > 0
                    ? 'Product List Filtered'
                    : 'Product List Viewed',
            payload,
        };
    },
};
