import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DurationKeys, DurationInDays, DURATION_KEY_I18N_MAP } from './DurationSelector.constants';
import {
    WrapperStyled,
    GraphStyled,
    RangeSliderStyled,
    TitleWrapperStyled,
    NumberStyled,
    WordStyled,
    DashStyled,
    LastNumberStyled,
    LastWordStyled,
} from './DurationSelector.style';

class DurationSelector extends Component {
    state = {
        ...this.getCurrentState(),
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { minimum, maximum } = this.props.durationInDays;
        const { minimum: minimumNew, maximum: maximumNew } = nextProps.durationInDays;

        const { minimumDuration, maximumDuration } = this.state || {};
        const { minimumDuration: minimumDurationNew, maximumDuration: maximumDurationNew } =
            nextState || {};

        return (
            minimum !== minimumNew ||
            maximum !== maximumNew ||
            minimumDuration !== minimumDurationNew ||
            maximumDuration !== maximumDurationNew
        );
    }

    componentDidUpdate() {
        const { minimum, maximum } = this.state;
        const { minimum: minimumNew, maximum: maximumNew } = this.props.durationInDays;

        const hasChanged = minimum !== minimumNew || maximum !== maximumNew;

        if (hasChanged) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                ...this.getCurrentState(this.props),
            });
        }
    }

    onChange = (data) => {
        this.setState({
            minimumDuration: data[0],
            maximumDuration: data[1],
        });

        if (this.props.onChange) {
            this.props.onChange({
                minimum: DurationInDays[DurationKeys.indexOf(data[0])],
                maximum: DurationInDays[DurationKeys.indexOf(data[1])],
            });
        }
    };

    getCurrentState(props) {
        const { durationInDays } = props || this.props;

        let minimumDuration;
        let maximumDuration;

        // Minimum
        if (typeof durationInDays.minimum !== 'number') {
            minimumDuration = DurationKeys[0];
        } else {
            minimumDuration = DurationKeys[DurationInDays.indexOf(durationInDays.minimum)];
        }

        // Maximum
        if (typeof durationInDays.maximum !== 'number') {
            maximumDuration = DurationKeys[DurationKeys.length - 1];
        } else {
            maximumDuration = DurationKeys[DurationInDays.indexOf(durationInDays.maximum)];
        }

        return {
            minimum: durationInDays.minimum,
            maximum: durationInDays.maximum,
            minimumDuration,
            maximumDuration,
        };
    }

    render() {
        const { t, durationFacets } = this.props;
        const { minimumDuration, maximumDuration } = this.state;

        DurationKeys.map((key) => ({
            slug: key,
            count: 0,
        }));

        const { length } = durationFacets;
        const rawValues = [];
        // Defaults to 0
        length.forEach((duration) => {
            rawValues.push(DurationKeys.indexOf(duration.slug) >= 0 ? duration.count : 0);
        });

        // DURATION_KEY_I18N_MAP
        const minI18Map = DURATION_KEY_I18N_MAP[minimumDuration];
        const maxI18Map = DURATION_KEY_I18N_MAP[maximumDuration];

        const isManySelected = DurationKeys.indexOf(maximumDuration) === DurationKeys.length - 1;

        return (
            <WrapperStyled>
                <TitleWrapperStyled>
                    <NumberStyled>{minI18Map.count}</NumberStyled>
                    <WordStyled>{t(minI18Map.key, { count: minI18Map.count })}</WordStyled>
                    {isManySelected && <LastNumberStyled>+</LastNumberStyled>}
                    {!isManySelected && (
                        <>
                            <DashStyled>-</DashStyled>
                            <NumberStyled>{maxI18Map.count}</NumberStyled>
                            <LastWordStyled>
                                {t(maxI18Map.key, { count: maxI18Map.count })}
                            </LastWordStyled>
                        </>
                    )}
                </TitleWrapperStyled>
                <GraphStyled values={rawValues} />
                <RangeSliderStyled
                    values={DurationKeys}
                    selected={[minimumDuration, maximumDuration]}
                    onChange={(data) => this.onChange(data)}
                />
            </WrapperStyled>
        );
    }
}

DurationSelector.defaultProps = {
    durationFacets: {},
    durationInDays: {},
};

export { DurationSelector as DurationSelectorTestable };
export default withTranslation()(DurationSelector);
