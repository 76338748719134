import { createAction } from 'utils/actions';

const prefix = 'DYNAMIC_FACETS';

export const fetchFacetsRequest = createAction(prefix, 'FETCH_REQUEST', 'payload');

export const fetchFacetsSuccess = createAction(prefix, 'FETCH_SUCCESS', 'payload');

export const fetchListingsFacets = createAction(prefix, 'FETCH_LISTINGS', 'payload');

export const fetchProfilesFacets = createAction(prefix, 'FETCH_PROFILES', 'payload');

export const fetchSEOFacets = createAction(prefix, 'FETCH_SEO', 'payload');
