/* eslint-disable import/prefer-default-export */
import { put, take, race } from 'redux-saga/effects';
import { parse } from 'query-string';
import * as actions from './actions';

export function* preload(location, pageId) {
    const { params, search, pathname } = location;
    const parsedSearch = parse(search, { arrayFormat: 'index' });

    if (parsedSearch.location) {
        try {
            parsedSearch.location = JSON.parse(parsedSearch.location);
        } catch {
            yield put(actions.error.create(pageId, 404));
        }
    }

    // Emit event to do the preload
    yield put(actions.preload.create(pageId, params, parsedSearch, pathname));

    // Block-race until the preload generators are done or a redirect action is dispatched
    yield race({
        preload: take(
            (action) =>
                (action.type === actions.preloaded.ACTION ||
                    action.type === actions.error.ACTION) &&
                action.pageId === pageId
        ),
        redirect: take(actions.redirect.ACTION),
    });
}
