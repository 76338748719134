import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Rating from 'components/next/Rating';
import {
    WrapperStyled,
    RatingItemStyled,
    RatingStarsStyled,
    RatingNameStyled,
} from './ReviewOverviewRating.styles';

const ReviewOverviewRating = ({ organised, reliable, selfSufficient, tidy, petCare }) => {
    const { t } = useTranslation();
    const ratings = [
        {
            id: 0,
            value: organised,
            name: t('containers_leave_review_modal_organised_label'),
        },
        {
            id: 1,
            value: reliable,
            name: t('containers_leave_review_modal_reliable_label'),
        },
        {
            id: 2,
            value: selfSufficient,
            name: t('containers_leave_review_modal_self_sufficient_label'),
        },
        {
            id: 3,
            value: tidy,
            name: t('containers_leave_review_modal_tidy_label'),
        },
        {
            id: 4,
            value: petCare,
            name: t('containers_leave_review_modal_pet_care_label'),
        },
    ].filter((c) => typeof c.value === 'number');

    return (
        <WrapperStyled>
            {ratings.map((rating) => (
                <RatingItemStyled key={rating.id}>
                    <RatingStarsStyled>
                        <Rating max={5} value={rating.value} size="sm" />
                    </RatingStarsStyled>
                    <RatingNameStyled>{rating.name}</RatingNameStyled>
                </RatingItemStyled>
            ))}
        </WrapperStyled>
    );
};

ReviewOverviewRating.propTypes = {
    organised: PropTypes.number,
    reliable: PropTypes.number,
    selfSufficient: PropTypes.number,
    tidy: PropTypes.number,
    petCare: PropTypes.number,
};

ReviewOverviewRating.defaultProps = {
    organised: null,
    reliable: null,
    selfSufficient: null,
    tidy: null,
    petCare: null,
};

export default ReviewOverviewRating;
