import * as actions from './actions';
import * as constants from './constants';

export const initialState = {
    assignment: null,
    current: constants.SCREEN_HOW_WAS_YOUR_SITTER,
    last: constants.SCREEN_HOW_WAS_YOUR_SITTER,
    context: {
        overallScore: 0,
        organised: 0,
        reliable: 0,
        selfSufficient: 0,
        tidy: 0,
        petCare: 0,
        description: '',
    },
    isOpen: false,
    isLoading: false,
};

function mergeContext(current, values) {
    if (!values) {
        return current;
    }

    return {
        ...current,
        ...(values || {}),
    };
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.hydrate.ACTION: {
            const {
                payload: { assignment, current },
            } = action;

            return {
                ...state,
                assignment,
                current,
                last: current,
                isLoading: false,
            };
        }
        case actions.open.ACTION:
            return {
                ...state,
                context: { ...initialState.context },
                isOpen: true,
                isLoading: true,
            };
        case actions.close.ACTION:
            if (state.isSubmitting) {
                return state;
            }

            return {
                ...state,
                isOpen: false,
            };
        case actions.send.ACTION:
            if (state.isSubmitting) {
                return state;
            }

            return {
                ...state,
                current: action.target,
                last: state.current,
            };

        case actions.setContext.ACTION:
            return {
                ...state,
                context: mergeContext(state.context, action.values),
            };
        case actions.submitRequest.ACTION:
            return {
                ...state,
                data: {
                    isLoading: true,
                },
            };
        case actions.submitSuccess.ACTION:
            return {
                ...state,
                data: {
                    isLoading: false,
                },
            };
        case actions.submitFailure.ACTION:
            return {
                ...state,
                data: {
                    isLoading: false,
                },
            };
        default:
            return state;
    }
}
