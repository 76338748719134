import styled from 'styled-components';

const LabelsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: calc(100% - 48px);
`;

export { LabelsWrapper };
