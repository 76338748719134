import styled from 'styled-components';
import Button from '../Button';

export const StyledButton = styled(Button)`
    flex: 0 0 auto;
    justify-content: ${({ iconRight }) => (iconRight ? 'space-between' : 'center')};
`;

export const Text = styled.span`
    padding-right: ${({ iconRight, theme }) => (iconRight ? theme.spacing[2] : '0')};

    padding-left: ${({ iconRight, theme }) => (iconRight ? '0' : theme.spacing[2])};
`;

export const StyledIcon = styled.span`
    flex: none;
`;
