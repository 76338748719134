/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import FocusLock from 'react-focus-lock';
import { DialogVariants } from './Dialog.constants';

// NOTE ON .ths-modal-open:not(&) { overflow: hidden; }
// we've added the rule to prevent scrolling on the document beneath the dialog
// whilst the user scrolls within the dialog.

export const DialogWrapperStyled = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${zIndex};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    .ths-modal-open:not(&) {
        overflow: hidden;
        height: 100%;
    }
`;

const mobileFullscreenDesktopCenteredState = css`
    margin: 0;
    max-width: 920px;
    width: 100%;
    height: 100%;
    box-shadow: none;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        height: auto;
        max-height: 100%;
        width: ${({ theme }) => `calc(100% - ${theme.spacing['6']})`};
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        border-radius: ${({ theme }) => theme.borderRadius.regular};
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
`;

const mobileBottomDesktopCenteredState = css`
    position: absolute;
    bottom: 0;
    margin: 0;
    max-width: 700px;
    width: 100%;
    box-shadow: none;
    border-radius: ${({ theme }) =>
        `${theme.borderRadius.regular} ${theme.borderRadius.regular} 0 0`};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        bottom: auto;
        width: ${({ theme }) => `calc(100% - ${theme.spacing['6']})`};
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        border-radius: ${({ theme }) => theme.borderRadius.regular};
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
`;

const mobileTopDesktopCenteredState = css`
    position: absolute;
    top: 0;
    margin: 0;
    max-width: 920px;
    width: 100%;
    height: 100%;
    box-shadow: none;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: auto;
        max-height: 100%;
        width: ${({ theme }) => `calc(100% - ${theme.spacing['6']})`};
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        border-radius: ${({ theme }) => theme.borderRadius.regular};
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
`;

const searchListingsFirstItemInfoPromptLOWAPPLICANTSABTESTState = css`
    position: absolute;
    bottom: 0;
    margin: 0;
    max-width: 700px;
    width: 100%;
    box-shadow: none;
    border-radius: ${({ theme }) =>
        `${theme.borderRadius.regular} ${theme.borderRadius.regular} 0 0`};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        width: 343px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        max-width: 343px;
        width: ${({ positionLeft }) => `calc(100% - ${positionLeft * 2}px`}); 
        box-shadow: none;
        border-radius: ${({ theme }) => `${theme.borderRadius.regular}`};
        top: ${({ positionTop }) => `${positionTop}px `};
        left: ${({ positionLeft }) => `${positionLeft}px `};
        bottom: auto;
    }
`;

export const DialogStyled = styled.div`
    box-sizing: border-box;
    max-width: ${({ maxWidth, fullscreen }) => {
        if (maxWidth) return maxWidth;
        if (fullscreen) return '100%';

        return undefined;
    }};
    max-height: ${({ maxHeight, fullscreen }) => {
        if (maxHeight) return maxHeight;
        if (fullscreen) return '100%';

        return undefined;
    }};
    width: ${({ fullscreen }) => (fullscreen ? '100%' : undefined)};
    height: ${({ height, fullscreen }) => {
        if (height) return height;
        if (fullscreen) return '100%';

        return undefined;
    }};
    position: relative;

    margin: ${({ fullscreen, theme, positionTop }) =>
        fullscreen || !!positionTop ? '0' : `${theme.spacing['4']}`};
    padding: 0;

    background-color: #fff;
    z-index: ${({ theme }) => zIndex({ theme }) + 1};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: ${({ fullscreen, theme }) => (fullscreen ? 0 : theme.borderRadius.regular)};

    &:focus: {
        outline: none;
    }

    ${({ variant }) =>
        variant === `${DialogVariants.mobileFullscreenDesktopCentered}`
            ? mobileFullscreenDesktopCenteredState
            : ''};
    ${({ variant }) =>
        variant === `${DialogVariants.mobileBottomDesktopCentered}`
            ? mobileBottomDesktopCenteredState
            : ''};
    ${({ variant }) =>
        variant === `${DialogVariants.mobileTopDesktopCentered}`
            ? mobileTopDesktopCenteredState
            : ''};
    ${({ variant }) =>
        variant === `${DialogVariants.searchListingsFirstItemInfoPromptLOWAPPLICANTSABTEST}`
            ? searchListingsFirstItemInfoPromptLOWAPPLICANTSABTESTState
            : ''};
`;

export const ScrollableContainer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: ${({ theme }) => zIndex({ theme }) + 1};

    ${({ variant, theme }) =>
        variant === `${DialogVariants.mobileFullscreenDesktopCentered}` &&
        `
            background: ${theme.palette.white};
            @media screen and (min-width: ${theme.screenSize.mediumTablet}px) {
                background: none;
            }
        `}
`;

export const FocusLockStyled = styled(FocusLock)`
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        height: 100%;
        overflow: auto;
    }
`;
