import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { IconCBC, IconIDCheck1 } from 'components/Icon';
import Tooltip from 'components/Tooltip';
import CloudinaryImage from 'components/Image/CloudinaryImage';
import { visuallyHide } from '../../utils/utils';
import { Span, SmallText, H3 } from '../Typography';
import Favourite from '../Favourite';

/**
 * The various combinations of screen size/profile card variant/column count are difficult to explain
 * so check out a more visual representation:
 * https://docs.google.com/spreadsheets/d/17h2wzkrnkPdS6OmceM1IWgIACFKQ5rGPuo9gMjXN8KY/edit?usp=sharing
 */

const smallTabletToMediumTablet = ({ theme }) => `screen and
    (min-width: ${theme.screenSize.smallTablet}px) and
    (max-width: ${theme.screenSize.mediumTablet - 1}px)`;

const largeTabletToMediumDesktop = ({ theme }) => `screen and
    (min-width: ${theme.screenSize.largeTablet}px) and
    (max-width: ${theme.screenSize.mediumDesktop - 1}px)`;

const largeDesktopPlus = ({ theme }) => `screen and
    (min-width: ${theme.screenSize.largeDesktop}px)`;

const largeProfileCardMedia = ({ theme }) =>
    `${smallTabletToMediumTablet({ theme })},
    ${largeTabletToMediumDesktop({ theme })},
    ${largeDesktopPlus({ theme })}`;

const ProfileContainerStyled = styled.div`
    position: relative;
    padding: 0;

    @media screen and (min-width: ${({ theme }) => `${theme.screenSize.mediumTablet}px`}) {
        padding: ${({ theme }) => theme.spacing[1]};
    }
`;

const FavouriteStyled = styled(Favourite)`
    position: absolute;
    z-index: 10; // nudge above the link
    top: 0;
    right: 0;

    // Needs to be in sync with the ProfileContainerStyled padding
    @media screen and (min-width: ${({ theme }) => `${theme.screenSize.mediumTablet}px`}) {
        top: ${({ theme }) => theme.spacing[1]};
        right: ${({ theme }) => theme.spacing[1]};
    }

    transition: none;

    :hover {
        transform: scale(1.2);
    }

    & > span > span > svg {
        stroke: ${({ theme }) => theme.palette.grey[600]};
        stroke-width: 2px;
    }

    // Resizing icons at breakpoints is pretty messy
    // First we increase the size of the box
    & > span {
        @media screen and (min-width: ${({ theme }) => `${theme.screenSize.largeHandset}px`}) {
            width: ${({ theme }) => theme.fontSizes.xlarge};
            height: ${({ theme }) => theme.fontSizes.xlarge};
        }
    }

    // Then increase the font-size to enlarge the icon
    & > span > span {
        @media screen and (min-width: ${({ theme }) => `${theme.screenSize.largeHandset}px`}) {
            font-size: ${({ theme }) => theme.fontSizes.xlarge};
        }
    }
`;

const FavouriteLabelStyled = styled.span`
    ${visuallyHide};
`;

const LinkStyled = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const ProfileImageOuterWrapperStyled = styled.div`
    position: relative;
    padding: 0 ${({ theme }) => theme.spacing[2]};
    z-index: 1;

    @media screen and (min-width: ${({ theme }) => `${theme.screenSize.largeHandset}px`}) {
        padding: 0 ${({ theme }) => theme.spacing[3]};
    }
`;

const ProfileImageWrapperStyled = styled.div`
    position: relative;
    padding-bottom: 100%;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const ProfileImageContainerStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
`;

const ImageStyled = styled(CloudinaryImage)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const TitleWrapperStyled = styled.div`
    position: relative;
    font-size: ${({ theme }) => theme.fontSizes.medLarge};
    height: 1.2em;
    margin-bottom: ${({ theme }) => theme.spacing['0.5']};

    @media ${largeProfileCardMedia} {
        margin-bottom: ${({ theme }) => theme.spacing['1']};
    }
`;

const TitleStyled = styled(H3)`
    position: absolute;
    width: 100%;
    height: 1.2em;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin: 0;

    @media ${largeProfileCardMedia} {
        font-size: ${({ theme }) => theme.typography.h3['font-size']};
    }
`;

// We use a wrapper rotated 45deg and then rotate the badge back -45deg
// this allows us to maintain a position at the edge of the profile image at all times
const VerificationBadgeOuterWrapperStyled = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

const VerificationBadgeInnerWrapperStyled = styled.span`
    height: 48px;
    width: 48px;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // The translate controls the amount of overlap
    transform: translate(0, 65%) rotate(-45deg);

    @media ${largeProfileCardMedia} {
        height: 64px;
        width: 64px;
    }
`;

const TooltipWrapper = styled.div`
    // Matched to the icon width this aligns the tooltip centrally
    max-width: 28px;

    p {
        white-space: nowrap;
        text-transform: initial;
    }
`;

const TooltipStyled = styled(Tooltip)(
    ({ theme: { borderRadius } }) => css`
        width: unset;
        border-radius: ${borderRadius.regular};
    `
);

const iconsStyles = `
    width: 28px;
    height: 28px;
`;

const IDBadgeStyled = styled(IconIDCheck1)`
    ${iconsStyles}
`;

const CBCBadgeStyled = styled(IconCBC)`
    ${iconsStyles}
`;

const LocationStyled = styled(SmallText).attrs({
    styleOf: 'smallText',
})`
    display: block;
    color: ${({ theme }) => theme.palette.textLight};
    text-align: center;
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing['0.5']};

    @media ${largeProfileCardMedia} {
        font-size: ${({ theme }) => theme.fontSizes.medium};
        margin-bottom: ${({ theme }) => theme.spacing['1']};
    }
`;

const ReviewStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ReviewCountStyle = styled(Span)`
    color: ${({ theme }) => theme.palette.yellow};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-top: ${({ theme }) => theme.spacing[0.5]};
    margin-left: ${({ theme }) => theme.spacing[1]};
`;

const TitleDescriptionStyled = styled(SmallText)`
    color: ${({ theme }) => theme.palette.textLight};
    text-align: center;
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[0.5]};

    @media ${largeProfileCardMedia} {
        margin-bottom: ${({ theme }) => theme.spacing[1]};
    }
`;

export {
    ProfileContainerStyled,
    VerificationBadgeOuterWrapperStyled,
    VerificationBadgeInnerWrapperStyled,
    TooltipWrapper,
    TooltipStyled,
    IDBadgeStyled,
    CBCBadgeStyled,
    FavouriteLabelStyled,
    FavouriteStyled,
    LinkStyled,
    ProfileImageOuterWrapperStyled,
    ProfileImageWrapperStyled,
    ProfileImageContainerStyled,
    LocationStyled,
    TitleWrapperStyled,
    TitleStyled,
    ImageStyled,
    ReviewStyled,
    ReviewCountStyle,
    TitleDescriptionStyled,
};
