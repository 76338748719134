import { fork, call, all } from 'redux-saga/effects';
import apiClientSagas from 'api/sagas';
import containersSaga from 'containers/sagas';
import promptManagerSagas from 'containers/PromptManager/sagas';

import pagesSaga from '../pages/sagas';
import sharedSaga from '../shared/sagas';
import acqCampaignSagas from '../shared/acqCampaign/sagas';
import universalStorageSaga from '../universalStorage/sagas';

export default function* rootSaga() {
    yield all([
        fork(apiClientSagas),
        fork(promptManagerSagas),
        fork(pagesSaga),
        fork(sharedSaga),
        fork(acqCampaignSagas),
        fork(containersSaga),
        fork(universalStorageSaga),
    ]);
}

const waitAll = (sagasWithArgsToWaitFor) =>
    function* waitAllGenerator() {
        for (let i = 0; i < sagasWithArgsToWaitFor.length; i += 1) {
            const { saga, args } = sagasWithArgsToWaitFor[i];
            yield call(saga, args);
        }
    };

export { waitAll };
