export const PAGE_ID = 'ListingWelcomeGuidePrint';

export const EmergencyTypes = {
    hospital: 'hospital',
    pharmacy: 'pharmacy',
    dentist: 'dentist',
    nominated: 'nominated',
};

export const PlacesTypes = {
    shop: 'shop',
    walk: 'walk',
    restaurantOrPub: 'restaurant_or_pub',
    cafe: 'cafe',
    attraction: 'attraction',
};

export const PetTypes = {
    birds: 'birds',
    cats: 'cats',
    dogs: 'dogs',
    farmAnimals: 'farm-animals',
    fish: 'fish',
    horses: 'horses',
    reptiles: 'reptiles',
    poultry: 'poultry',
    smallPets: 'small-pets',
};
