import React from 'react';
import { IconArrowRight, IconArrowLeft } from 'components/Icon';
import helper from '../helper';
import {
    ArrowIconWrapperStyled,
    ArrowIconLinkStyled,
    ArrowIconLinkLabelStyled,
} from '../Pagination.style';
import { PaginationWideStyled } from './RouterPagination.style';

const RouterPaginationWide = ({
    currentPage,
    totalItems,
    itemsPerPage,
    url,
    onItemClick,
    previousPageLabel,
    nextPageLabel,
    ...props
}) => {
    const { showPrevious, showNext } = helper.getPageMetadata(
        currentPage,
        totalItems,
        itemsPerPage,
        0
    );
    const itemClickHandler = (page) => onItemClick && onItemClick(page);

    return (
        <PaginationWideStyled {...props}>
            <ArrowIconWrapperStyled data-testid="RouterPagination_previous">
                {showPrevious && (
                    <ArrowIconLinkStyled
                        to={url(currentPage - 1)}
                        onClick={() => itemClickHandler(currentPage - 1)}
                        aria-label={previousPageLabel}
                    >
                        <IconArrowLeft palette="link" />
                        <ArrowIconLinkLabelStyled>{previousPageLabel}</ArrowIconLinkLabelStyled>
                    </ArrowIconLinkStyled>
                )}
            </ArrowIconWrapperStyled>

            <ArrowIconWrapperStyled data-testid="RouterPagination_next">
                {showNext && (
                    <ArrowIconLinkStyled
                        to={url(currentPage + 1)}
                        onClick={() => itemClickHandler(currentPage + 1)}
                        aria-label={nextPageLabel}
                    >
                        <ArrowIconLinkLabelStyled>{nextPageLabel}</ArrowIconLinkLabelStyled>
                        <IconArrowRight palette="link" />
                    </ArrowIconLinkStyled>
                )}
            </ArrowIconWrapperStyled>
        </PaginationWideStyled>
    );
};

export default RouterPaginationWide;
