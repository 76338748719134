import { connect } from 'react-redux';
import { getAccountCurrentMembershipPlan } from 'api/selectors';
import { isMember } from 'utils/account';
import HandleAddSmartAppBanner from './HandleAddSmartAppBanner';

const mapStateToProps = (state) => ({
    userIsMember: isMember(getAccountCurrentMembershipPlan(state)),
});

export default connect(mapStateToProps)(HandleAddSmartAppBanner);
