/* eslint-disable import/prefer-default-export */

export const ASPECT_RATIO_BY_BREAKPOINT = {
    default: '272 / 422',
    mediumHandset: '327 / 220',
    smallTablet: '552 / 187',
    smallDesktop: '737 / 232',
    largeDesktop: '892 / 232',
};

export const MAX_REVIEW_CHARS = 140;
