import ApiConfig from '../config';
import ApiAction from './common';

const config = {
    load: ['PLANS', 'LOAD', ApiConfig.endpoints.plans._],
    loadSwitchDetails: ['PLANS_SWITCH_DETAILS', 'LOAD', ApiConfig.endpoints.plans.switchDetails],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
