/**
 * Get element Y offset
 * @param element - DOM element
 */
export const getElementDocumentY = (element) => {
    // Fallback to top of the page if no element found
    if (!element) return 0;

    // gets the Y position of the viewport relative to the document
    const viewportY =
        window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;

    // gets the Y position of the element relative to the viewport
    const elementBCR = element.getBoundingClientRect();

    // gets y position of the element relative to the document
    const elementDocumentY = elementBCR.top + viewportY;

    return elementDocumentY;
};

/**
 * Gets the Y position of an element and the offset if one exists.
 * @param element - DOM element
 * @param {number} offset - sets the offset of the returned y position of the element
 */
export const getScrollPosition = (element, offset = 0) => getElementDocumentY(element) + offset;

export const getWindowWidth = () => window?.innerWidth || 0;

const breakpointsForWindowWidth = (windowWidth) => (breakpoint) => breakpoint <= windowWidth;

export const getOffsetValue = (windowWidth, offset) => {
    if (!offset) {
        return 0;
    }

    if (typeof offset === 'number') {
        return offset;
    }

    const validBreakpoints = breakpointsForWindowWidth(windowWidth);
    const offsetBreakpoints = Object.keys(offset);

    const matchingBreakpoints = offsetBreakpoints.filter(validBreakpoints);
    const bestMatchingBreakpoint = matchingBreakpoints.pop();

    return offset[bestMatchingBreakpoint];
};

/**
 * Scroll to the viewport
 * @param element - DOM element
 */
export const scrollViewport = (element, offset, behavior = 'smooth') => {
    const offsetValue = getOffsetValue(getWindowWidth(), offset);

    const scrollPosition = getScrollPosition(element, offsetValue);

    if ('scrollBehavior' in document.documentElement.style) {
        window.scrollTo({
            top: scrollPosition,
            left: 0,
            behavior,
        });
    } else {
        window.scrollTo(0, scrollPosition);
    }
};

/**
 * Scroll an overflown element
 *
 * @param {HTMLElement} element DOM element to scroll
 * @param {number} top pixel location to scroll to vertically, defaults to 0
 * @param {number} left pixel location to scroll to horizontally, defaults to 0
 */
export const scrollElement = (element, top = 0, left = 0) => {
    if ('scrollTo' in element) {
        element.scrollTo({
            top,
            left,
            behavior: 'smooth',
        });
    } else {
        element.scrollTop = top;
        element.scrollLeft = left;
    }
};
