import { settings as actions } from '../../actions';

export default (state = {}, action) => {
    switch (action.type) {
        case actions.verifications.loadStatus.SUCCESS: {
            return {
                ...state,
                ...action.data,
            };
        }
        case actions.verifications.loadReferences.SUCCESS: {
            return {
                ...state,
                references: {
                    ...action.data,
                }
            };
        }
        default:
            return state;
    }
};
