export const PAGE_ID = 'AddonCheckoutPayment';

export const PAGE_SAGA_KEY = 'addonCheckoutPage';

export const PAGE_REDUCER_KEY = 'addonCheckout';

export const ADDONS = {
    SITTER_SIT_CANCELATION: {
        products: [
            {
                name: 'transaction_scaler_modal_addon_sitter_cancelation_cover_title',
                description: 'transaction_scaler_modal_addon_sitter_cancelation_cover_description',
                title: 'transaction_scaler_modal_addon_sitter_cancelation_cover',
                subtitle: 'transaction_scaler_modal_addon_sitter_cancelation_cover_subtitle',
                GBP: 15,
                USD: 18,
            },
        ],
    },
    SITTER_PACKAGE: {
        products: [
            {
                description: 'transaction_scaler_modal_addon_sitter_premium_badge_description',
            },
            {
                description: 'transaction_scaler_modal_addon_sitter_airport_loung_description',
            },
        ],
        basicClassicProduct: {
            description: 'transaction_scaler_modal_addon_sitter_accidentProtection_description',
        },
    },
    OWNER_SIT_CANCELATION: {
        products: [
            {
                name: 'transaction_scaler_modal_addon_sitter_cancelation_cover_title',
                description: 'transaction_scaler_modal_addon_owner_cancelation_cover_description',
                title: 'transaction_scaler_modal_addon_owner_cancelation_cover',
                subtitle: 'transaction_scaler_modal_addon_owner_cancelation_cover_subtitle',
                GBP: 15,
                USD: 18,
            },
        ],
    },
    OWNER_PACKAGE: {
        products: [
            {
                description: 'transaction_scaler_modal_addon_owner_premium_badge_description',
            },
            {
                description: 'transaction_scaler_modal_addon_sitter_airport_loung_description',
            },
        ],
        basicClassicProduct: {
            description: 'transaction_scaler_modal_addon_owner_homeProtection_description',
        },
    },
};

export const elementsOptions = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400;1,500&display=swap',
        },
    ],
};
