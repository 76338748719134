/* eslint-disable-next-line import/prefer-default-export */
export const USER_PROFILE_EDIT_PETS = {
    create(description, pet) {
        const { pet_id: petId } = pet;
        return {
            type: 'Profile Edit Pets',
            payload: {
                description,
                ...(petId ? { petId } : { pet }),
            },
        };
    },
};
