import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getOwnerListingAmenities } from 'api/selectors/owner';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { getAccountCurrentMembershipPlan } from 'api/selectors';
import { isExpired, isOwnerOnly, isPartial } from 'utils/account';
import { getRawListing } from '../selectors';
import { PAGE_ID } from './ListingPreview.constants';

const LoadableListingPreview = loadable(() => retry(() => import('./ListingPreview')));

const mapStateToProps = (
    state,
    {
        match: {
            params: { listingId },
        },
    }
) => {
    const currentMembershipPlan = getAccountCurrentMembershipPlan(state);
    return {
        shouldUseOwnerAndPartialsQuery:
            isOwnerOnly(currentMembershipPlan) ||
            isPartial(currentMembershipPlan) ||
            isExpired(currentMembershipPlan),
        listing: getRawListing(state),
        amenities: getOwnerListingAmenities(state, listingId),
    };
};

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(mapStateToProps)(
    asPage(withTranslation()(LoadableListingPreview), PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
