// eslint-disable-next-line no-unused-vars
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import actions, { settings } from 'api/actions';
import { track, Events } from 'utils/analytics';
import { UPLOADED_PHOTO_TYPES } from 'utils/analytics/events/user/uploadedPhoto';
import { connectListingProfilePhotoRequest } from './actions';

export function* connectListingProfilePhoto(action) {
    yield put(
        actions.account.updateProfilePhoto({
            forceReload: true,
            data: {
                mediaId: action.mediaId,
            },
        })
    );

    yield take(settings.account.updateProfilePhoto.DONE);

    yield call(track, Events.USER_UPLOADED_PHOTO.create(UPLOADED_PHOTO_TYPES.profilePhoto, 1));

    // Reload the account so that the user avatar in the nav updates
    yield put(
        actions.account.load({
            forceReload: true,
        })
    );

    return true;
}

export default function* profilePhotoSaga() {
    yield all([takeEvery(connectListingProfilePhotoRequest.ACTION, connectListingProfilePhoto)]);
}
