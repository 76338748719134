// eslint-disable-next-line import/prefer-default-export
export const USER_PROFILE_EDIT_EXPERIENCE = {
    create(profile) {
        const {
            experience, // boolean
            animalExperience = [],
            relevantExperience = '',
            skills = [],
            yearsOfExperience = 0,
        } = profile || {};

        return {
            type: 'Profile Edit Experience Update',
            payload: {
                experience,
                animalExperience: animalExperience.length > 0,
                relevantExperience: relevantExperience.length > 0,
                skills,
                yearsOfExperience,
            },
        };
    },
};
