import * as actions from './actions';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.load.ACTION:
        case actions.preload.ACTION:
        case actions.lazyload.ACTION:
            return {
                ...state,
                [action.pageId]: {
                    ...state[action.pageId],
                    location: {
                        params: action.params,
                        search: action.search,
                        pathname: action.pathname,
                    },
                },
            };
        case actions.loaded.ACTION: {
            const nextState = {
                ...state,
                [action.pageId]: {
                    ...state[action.pageId],
                    preloaded: false,
                    loaded: true,
                    error: null,
                    redirect: null,
                },
            };

            // Set preloaded to false for all other pages
            Object.keys(nextState).map((key) => {
                if (key !== action.pageId) {
                    nextState[key] = {
                        ...nextState[key],
                        preloaded: false,
                    };
                }
                return key;
            });
            return nextState;
        }
        case actions.preloaded.ACTION:
            return {
                ...state,
                [action.pageId]: {
                    ...state[action.pageId],
                    preloaded: true,
                    loaded: false,
                    error: null,
                    redirect: null,
                },
            };
        case actions.error.ACTION:
            return {
                ...state,
                [action.pageId]: {
                    error: action.statusCode || 500,
                },
            };
        case actions.redirect.ACTION:
            return {
                ...state,
                [action.pageId]: {
                    ...state[action.pageId],
                    redirect: action.to,
                    redirectMessage: action.redirectMessage,
                },
            };
        default:
            return state;
    }
};
