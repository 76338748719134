import * as actions from './actions';

const initialState = {
    profileLoaded: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.profileLoaded.ACTION:
            return {
                ...state,
                profileLoaded: action.profileHasLoaded,
            };
        default:
            return state;
    }
};
