import styled from 'styled-components';
import { Form } from 'formik';
import { PasswordField, ErrorMessage } from 'components/forms';
import { Button } from 'components/buttons';

const ButtonStyled = styled(Button)`
    width: 100%;
`;

const SimplerCreateButtonStyled = styled(Button).attrs({
    variant: Button.Variant.CTA,
})`
    width: 100%;
`;

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const PasswordFieldStyled = styled(PasswordField)`
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const ErrorMessageStyled = styled(ErrorMessage)`
    width: 100%;
`;

const HelperTextStyled = styled.div`
    font-size: ${({ theme }) => theme.typography.smallText['font-size']};
    line-height: ${({ theme }) => theme.typography.smallText['line-height']};
    color: ${({ theme }) => theme.palette.grey[500]};
    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[1]};
    text-align: center;
`;

export {
    ButtonStyled,
    PasswordFieldStyled,
    FormStyled,
    ErrorMessageStyled,
    SimplerCreateButtonStyled,
    HelperTextStyled,
};
