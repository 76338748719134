import React from 'react';
import { useTranslation } from 'react-i18next';
import DynamicFacets, { FACETS_SEARCH_TYPE } from 'pages/search/components/DynamicFacets';
import { routes } from 'utils/routes';
import { FlexboxGrid, Col } from 'components/layouts';

const SeoFacetLinks = ({ pageSlug }) => {
    const { t } = useTranslation();
    // Default route
    let defaultRoute = routes.search.profiles;
    // Text before facets to set "Cat sitters" before the location on facets
    let textBeforeFacets;
    // Check if the page is the cat SEO page
    const isCatSEOPage = pageSlug === 'cat-sitters';
    let facetsHeading = t('components_facets_mainTitle_sitters');
    if (isCatSEOPage) {
        facetsHeading = t('components_content_prismicSlices_SeoFacetLinks_heading_cat_sitters');
        defaultRoute = routes.search.catProfiles;
        textBeforeFacets = t('components_facets_text_before_for_cat_sitters');
    } else if (pageSlug === 'dog-sitters-near-me') {
        facetsHeading = t('components_content_prismicSlices_SeoFacetLinks_heading_dog_sitters');
    }

    return (
        <FlexboxGrid justify={FlexboxGrid.Justify.CENTER}>
            <Col span={12} largeDesktop={8}>
                <DynamicFacets
                    type={FACETS_SEARCH_TYPE.seo}
                    title={facetsHeading}
                    textBeforeFacets={textBeforeFacets}
                    createRouteLink={defaultRoute}
                />
            </Col>
        </FlexboxGrid>
    );
};

export default SeoFacetLinks;
