import * as React from 'react';
import PropTypes from 'prop-types';
import { IconRating } from 'components/Icon';
import { StyledIcon } from './Icon.style';
import IconVariant from './Icon.constants';

function Icon({ isHighlighted, children, variant, ...props }) {
    return (
        <StyledIcon data-highlighted={isHighlighted} variant={variant} {...props}>
            {children || <IconRating />}
        </StyledIcon>
    );
}

Icon.displayName = 'RatingIcon';

Icon.defaultProps = {
    variant: IconVariant.YELLOW,
};

Icon.propTypes = {
    isHighlighted: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    variant: PropTypes.string,
};

export default Icon;
