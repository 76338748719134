import clsx from 'clsx';

const styles = {
    backgroundColor: {
        white: 'white',
        grey_300: 'bg-grey-300',
        primary_300: 'bg-primary-300',
    },
    alignment: {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
    },
};

function usePrismicStyles(props) {
    const classes = clsx(
        typeof props?.backgroundColor === 'string'
            ? styles.backgroundColor[props.backgroundColor]
            : null,
        typeof props?.alignment === 'string' ? styles.alignment[props.alignment] : null
    );

    return classes;
}

export default usePrismicStyles;
