import { ABtest, ABCtest } from './experimentTypes';

export const createExperiment = (type, name) => ({
    name,
    type,
});

/**
 * Create AB test configurations for grouped tests
 * The experiments with name 1,2 and 3 are placed in the respective slots.
 */
export const createGroupedABExperiment = (
    group,
    experimentName1,
    experimentName2,
    experimentName3 = ''
) => {
    const type = ABtest;
    const experiment1 = {
        ...createExperiment(type, experimentName1),
        slot: 1,
        group,
    };
    const experiment2 = {
        ...createExperiment(type, experimentName2),
        slot: 2,
        group,
    };
    const experiment3 = {
        ...createExperiment(type, experimentName3),
        slot: 3,
        group,
    };
    return [experiment1, experiment2, experiment3];
};

/**
 * Create ABC test configurations for grouped tests
 * The experiments with name 1,2 and 3 are placed in the respective slots.
 */
export const createGroupedABCExperiment = (group, experimentName1, experimentName2) => {
    const type = ABCtest;
    const experiment1 = {
        ...createExperiment(type, experimentName1),
        slot: 1,
        group,
    };
    const experiment2 = {
        ...createExperiment(type, experimentName2),
        slot: 2,
        group,
    };
    return [experiment1, experiment2];
};

const [PACKAGING_TEST] = createGroupedABExperiment(
    'price_packaging_group',
    'web_exp_packaging',
    ''
);

const [BROADEN_SIT_SEARCH_RADIUS, QUICK_LOCATION_SEARCH, SEARCH_PROFILE_LAYOUT] =
    createGroupedABExperiment(
        'web_exp_sitter_search_profile_layout',
        'web_exp_broaden_sit_search_radius',
        'web_exp_quick_location_search',
        'web_exp_sitter_search_profile_layout_v2'
    );

const [_PLACEHOLDER_TEST, , PAUSED_SITS_REVIEWING_LABEL] = createGroupedABExperiment(
    'sitter_focused_homepage',
    '',
    '',
    'web_exp_paused_sits_label'
);

const [SITTERS_LOOKING_IN_YOUR_AREA, FREE_TRIAL_OWNER_V2] = createGroupedABExperiment(
    // need to have the group name same as the test name
    // to make sure splits don't change for SITTERS_LOOKING_IN_YOUR_AREA test
    'mx_test_sitter_search_sitters_looking_in_your_area',
    'mx_test_sitter_search_sitters_looking_in_your_area',
    'web_exp_owner_free_trial_v2',
    ''
);

const [COOL_SITS_ON_HOMEPAGE, SIT_DISCOVERY_PAGE, FREE_TRIAL_SITTER_V1] = createGroupedABExperiment(
    'sitter_test_grouping',
    'web_exp_cool_sits_on_homepage',
    'web_exp_sit_discovery_page',
    'web_cm_sitter_free_trial_v1'
);

const [PRINCING_TIERS_ORDER, HOW_IT_WORKS] = createGroupedABExperiment(
    'october_2023_group',
    'web_cm_pricing_tiers_order',
    'web_exp_how_it_works'
);

const [PRICING_FEATURED_BORDER, PRICING_PAGE_CARD_REDESIGN, TWO_STEP_REGWALL] =
    createGroupedABExperiment(
        'april_2024_group',
        'web_cm_pricing_featured_border',
        'web_exp_pricing_page_card_redesign',
        'delex_two_step_regwall'
    );

const [_PLACEHOLDER_TEST_2, PLANS_PAGE_WEEKLY_PRICE] = createGroupedABExperiment(
    'july_2024_group',
    '',
    'web_cm_plans_page_weekly_price'
);

export default {
    // Used for testing console.table in helpers.test.js
    TEST: { name: 'web_test', type: ABtest, useLegacyHash: true },
    PRICE_TEST_AB: createExperiment(ABtest, 'web_price_test_ab'),
    // TODO: update PRICE_TEST_ABC to use the newer helper function
    PRICE_TEST_ABC: { name: 'web_price_test_abc', type: ABCtest, useLegacyHash: true },
    REFER_A_FRIEND_NUDGEWALL_ONE_TOUCH: createExperiment(
        ABtest,
        'web_exp_refer_a_friend_nudgewall_one_touch'
    ),
    PRIORITISE_LOW_APPLICANTS_V2: createExperiment(
        ABtest,
        'web_mx_sit_search_sort_by_applications_v2'
    ),
    SITTERS_LOOKING_IN_YOUR_AREA,
    INC_RADIUS_SORTBY_DISTANCE: createExperiment(
        ABtest,
        'mx_test_search_inc_radius_sort_by_distance_city'
    ),
    SITTER_HOMEPAGE: createExperiment(ABtest, 'web_test_mx_sitter_homepage'),
    APPLY_FOR_LISTING: createExperiment(ABtest, 'web_apply_for_listing_v1'),
    SITTER_SOCIAL_VERIFICATIONS: createExperiment(ABtest, 'web_sitter_social_verifications'),
    RAF_SERVER_SIDE_EMAIL: createExperiment(ABtest, 'web_feature_raf_server_side_email'),
    SAVED_SEARCH: createExperiment(ABtest, 'web_saved_search'),
    PACKAGING_TEST,
    SEARCH_PROFILE_LAYOUT,
    PAUSED_SITS_REVIEWING_LABEL,
    USE_INFINITE_SCROLL_LISTINGS: createExperiment(ABtest, 'mx_test_sit_search_infinite_scroll'),
    RECOMMENDED_CAROUSEL: createExperiment(ABtest, 'web_exp_recommended_carousel'),
    BROADEN_SIT_SEARCH_RADIUS,
    APPLICATION_NUMBER_PARTIALS: createExperiment(ABtest, 'web_exp_application_number_partials'),
    QUICK_LOCATION_SEARCH,
    FREE_TRIAL_OWNER_V2,
    FREE_TRIAL_SITTER_V1,
    SIT_DISCOVERY_PAGE,
    URGENT_SUPPORT_LINE: createExperiment(ABtest, 'web_urgent_support_line'),
    COOL_SITS_ON_HOMEPAGE,
    EXPLORE_SITS_NEARBY: createExperiment(ABtest, 'cc_test_explore_sits_nearby'),
    HOW_IT_WORKS,
    PRINCING_TIERS_ORDER,
    NO_WIZARD_NAV: createExperiment(ABtest, 'web_cm_no_wizard_nav'),
    PRICING_FEATURED_BORDER,
    TWO_STEP_REGWALL,
    PRICING_PAGE_CARD_REDESIGN,
    NO_WIZARD_NAVBAR_V2: createExperiment(ABtest, 'web_delex_no_wizard_nav_v2'),
    ACQ_TIGHTER_FUNNEL: createExperiment(ABtest, 'delex_acq_tighter_funnel'),
    OWNER_FLOW: createExperiment(ABtest, 'delex_owner_flow'),
    HOMEPAGE_CTA: createExperiment(ABtest, 'delex_homepage_cta'),
    PLANS_PAGE_WEEKLY_PRICE,
    FACETS_LISTING_HIGHLIGHTS: createExperiment(
        ABtest,
        'dm_web_plus_app_exp_matching_facets_listing'
    ),
    CHECKOUT_V2_OPTIMISATIONS: createExperiment(ABtest, 'web_cm_checkout_v2_optimisations'),
};
