import { put, take } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { createListingsSearchQuery } from 'utils/searchListings';
import { error as errorAction } from 'containers/Page/actions';
import { PAGE_ID } from '../SearchListings.constants';

function* doSearchDiscoveryCategories(action) {
    const { name, query: discoveryCategoryQuery } = action.category;
    const searchQuery = createListingsSearchQuery({ ...discoveryCategoryQuery });
    const query = searchQuery.getRequestData();

    yield put(
        apiActions.search.loadListings({
            forceReload: true,
            data: {
                id: name,
                rawQuery: query,
                searchType: name,
            },
            filters: {
                query: JSON.stringify(query),
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) =>
            res.type === settings.search.loadListings.DONE && res.requestData.searchType === name
    );

    if (status === settings.search.loadListings.SUCCESS) {
        return true;
    }

    // Error occured
    yield put(errorAction.create(PAGE_ID, statusCode));
    return false;
}

export { doSearchDiscoveryCategories as default };
