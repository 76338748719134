import { settings as actions } from '../../actions';
import { getApplication } from '../../helpers/transform';

import {
    baseInitialState,
} from '../common';

export const initialState = {
    ...baseInitialState,
};

export default (state = initialState, action) => {
    switch (action.type) {
        // case actions.owner.loadListings.SUCCESS:
        //     return {
        //         ...state,
        //         // @TODO - Please revert back to this
        //         // data: action.data.results.reduce((applications, listing) => {
        //         //     listing.openAssignments.forEach((assignment) => {
        //         //         assignment.applications.forEach((application) => {
        //         //             applications[application.id] = application;
        //         //         });
        //         //     });
        //         //
        //         //     return applications;
        //         // }, {}),
        //         data: {
        //             ...state.data,
        //             ...action.data.results.reduce((applications, listing) => {
        //                 listing.assignments.filter(assignment => isOpenAssignment(assignment))
        //                      .forEach((assignment) => {
        //                     assignment.applications.forEach((application) => {
        //                         applications[application.id] = application;
        //                     });
        //                 });
        //
        //                 return applications;
        //             }, {}),
        //         }
        //     };
        case actions.owner.loadOpenAssignmentApplication.SUCCESS:
        case actions.owner.loadOpenAssignmentInvitation.SUCCESS:
        case actions.owner.createOpenAssignmentInvitation.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: {
                        ...state.data[action.data.id],
                        ...getApplication(action.data),
                    },
                },
            };
        case actions.owner.createOpenAssignmentWithInvitationDEPRECATED.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.application.id]: {
                        ...getApplication(action.data.application),
                    },
                },
            };
        case actions.owner.loadOpenAssignmentApplications.SUCCESS:
        case actions.owner.loadOpenAssignmentInvitations.SUCCESS:
        case actions.owner.loadOwnerSitterApplications.SUCCESS:
        case actions.owner.loadOwnerSitterInvitations.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...(action.data.results.reduce((applications, application) => {
                        applications[application.id] = {
                            ...state.data[application.id],
                            ...getApplication(application),
                        };

                        return applications;
                    }, {})),
                },
            };
        case actions.owner.createBatchOpenAssignmentInvitation.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...(action.data.reduce((applications, application) => {
                        applications[application.id] = {
                            ...state.data[application.id],
                            ...getApplication(application),
                        };

                        return applications;
                    }, {})),
                },
            };
        case actions.owner.removeListingOpenAssignment.SUCCESS: {
            const newState = {
                ...state,
            };
            Object.keys(state.data).forEach((application) => {
                if (application.assignmentId === action.data.id) {
                    delete newState.data[application.id];
                }
            });
            return newState;
        }
        case actions.owner.createOpenAssignmentApplication.SUCCESS:
        case actions.owner.updateOpenAssignmentApplication.SUCCESS:
        case actions.owner.confirmOpenAssignmentApplication.SUCCESS:
        case actions.owner.unconfirmOpenAssignmentApplication.SUCCESS:
        case actions.owner.declineOpenAssignmentApplication.SUCCESS:
        case actions.owner.shortlistOpenAssignmentApplication.SUCCESS:
        case actions.owner.unshortlistOpenAssignmentApplication.SUCCESS:
        case actions.owner.cancelOpenAssignmentInvitation.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: {
                        ...getApplication(action.data),
                    },
                },
            };
        case actions.owner.notifyConfirmedAssignmentUnconfirmedApplicants.SUCCESS: {
            const data = {
                ...state.data,
            };
            if (data) {
                Object.keys(data).forEach((id) => {
                    if (data[id].assignmentId === action.requestData.id) {
                        data[id].hasOwnerDeclined = true;
                    }
                });
            }
            return {
                ...state,
                data,
            };
        }
        case actions.owner.archiveConversations.SUCCESS:
        case actions.owner.batchArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const data = {};
            Object.keys(state.data).forEach((applicationId) => {
                const application = state.data[applicationId];
                const { lastMessage, sitterId } = application;
                if (ids.indexOf(sitterId) >= 0 && lastMessage) {
                    data[applicationId] = {
                        ...application,
                        lastMessage: {
                            ...application.lastMessage,
                            isArchived: true,
                        },
                    };
                }
                else {
                    data[applicationId] = application;
                }
            });
            return {
                ...state,
                data,
            };
        }
        case actions.owner.unArchiveConversations.SUCCESS:
        case actions.owner.batchUnArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const data = {};
            Object.keys(state.data).forEach((applicationId) => {
                const application = state.data[applicationId];
                const { lastMessage, sitterId } = application;
                if (ids.indexOf(sitterId) >= 0 && lastMessage) {
                    data[applicationId] = {
                        ...application,
                        lastMessage: {
                            ...application.lastMessage,
                            isArchived: false,
                        },
                    };
                }
                else {
                    data[applicationId] = application;
                }
            });
            return {
                ...state,
                data,
            };
        }
        default:
            return state;
    }
};
