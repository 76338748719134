import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { getAllVerifications, getPhoneVerificationsStatus } from 'api/selectors';
import { getSitterProfile } from 'api/selectors/sitter';
import { getAccountSettings } from 'api/selectors/account';
import { isViewingInAppWebView } from 'shared/selectors';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { PAGE_ID } from './Verifications.constants';

const mapStateToProps = (state) => ({
    verificationStatuses: getAllVerifications(state),
    countryCode: getAccountSettings(state).country,
    profile: getSitterProfile(state),
    isViewingInAppWebView: isViewingInAppWebView(state),
    accountEmail: getAccountSettings(state).email,
    phone: getPhoneVerificationsStatus(state),
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

const Verifications = loadable(() => retry(() => import('./Verifications')));

export default connect(
    mapStateToProps,
    null
)(
    asPage(withTranslation()(Verifications), PAGE_ID, false, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
