export default {
    savedSearchRedirect: '/house-and-pet-sitting-assignments/savedsearch/:savedSearchId/',
    listingsDetail: '/house-and-pet-sitting-assignments/:countrySlug?/:admin1Slug?/:slug?/l/:id/',
    listingApplication: '/house-and-pet-sitting-assignments/l/:listingId/apply/',
    listingsContinent:
        '/house-and-pet-sitting-assignments/:continentSlug(africa|asia|europe|north-america|oceania|south-america|antarctica)/',
    listings: '/house-and-pet-sitting-assignments/:countrySlug?/:admin1Slug?/:slug?/',
    profiles: '/house-and-pet-sitters/:countrySlug?/:admin1Slug?/:slug?/',
    catProfilesSearch: '/cat-sitters/search/',
    catProfiles: '/cat-sitters/:countrySlug?/:admin1Slug?/:slug?/',
    profilesDetail: '/house-and-pet-sitters/:countrySlug?/:admin1Slug?/:slug?/l/:id/',
    profileInvitation: '/house-and-pet-sitters/contact/:contactId/profile/:profileId/invite/',
    findASitterWizard: '/find-a-sitter/',
    findAHouseSitWizard: '/find-a-house-sit/',
    listingSearchDev: '/dev/search-listings/',
};
