/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';

// Modules
import { connect } from 'react-redux';

// Actions
import actions from 'api/actions';

// Helpers
import { MembershipStatus, MembershipType } from 'api/types/membershipTypes';
import { getMembershipType, getMembershipStatus } from 'utils/account';

// Components
import ProfileCard from 'components/ProfileCard';

// Selectors
import { isOwnerSitterProfileFavourite } from 'api/selectors/owner';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';

class ProfileCardView extends PureComponent {
    constructor(props) {
        super(props);

        this.onFavourite = this.onFavourite.bind(this);
    }

    onFavourite() {
        const { id, isFavourite, createFavourite, removeFavourite } = this.props;

        const data = {
            id,
        };

        if (isFavourite) {
            removeFavourite({
                forceReload: true,
                data,
            });
        } else {
            createFavourite({
                forceReload: true,
                data,
            });
        }
    }

    props;

    render() {
        const { membership } = this.props;
        const membershipType = getMembershipType(membership);
        const membershipStatus = getMembershipStatus(membership);

        return (
            <ProfileCard
                {...this.props}
                onFavourite={this.onFavourite}
                showFavourite={
                    (membershipType === MembershipType.OWNER ||
                        membershipType === MembershipType.COMBINED) &&
                    membershipStatus === MembershipStatus.MEMBER
                }
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps;
    return {
        ...ownProps,
        isFavourite: isOwnerSitterProfileFavourite(state, id),
        membership: getAccountCurrentMembershipPlan(state),
    };
};

const mapDispatchToProps = {
    createFavourite: actions.owner.createFavourite,
    removeFavourite: actions.owner.removeFavourite,
};

export { ProfileCardView };
export default connect(mapStateToProps, mapDispatchToProps)(ProfileCardView);
