import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { PAGE_ID } from './AboutYou.constants';
import { getRawProfile } from '../selectors';
import { saveProfile, profilePhotoSave } from './actions';
import { getResponseStatus, getUpdateId } from './selectors';

const LoadableAboutYou = loadable(() => retry(() => import('./AboutYou')));

const mapStateToProps = (state) => ({
    profile: getRawProfile(state),
    responseStatus: getResponseStatus(state),
    updateId: getUpdateId(state),
    isViewingInAppWebView: isViewingInAppWebView(state),
});

const mapDispatchToProps = (dispatch) => ({
    saveProfile: ({ oldProfile, newProfile, hasProfilePhoto, socialVerifications }) => {
        dispatch(saveProfile.create(oldProfile, newProfile, hasProfilePhoto, socialVerifications));
    },
    saveProfilePhoto: (profile) => {
        dispatch(profilePhotoSave.create(profile));
    },
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableAboutYou), PAGE_ID, true, false, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
