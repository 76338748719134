import styled, { css } from 'styled-components';
import { Panel, Col } from 'components/layouts';
import Link from 'components/Link';
import { IconClose } from 'components/Icon';

export const BodyContainer = styled(Panel)(
    ({ theme: { palette, screenSize, spacing } }) => css`
        background-color: ${palette.grey[100]};
        width: 100%;
        padding: 0;
        margin: 0;
        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            padding: 0px ${spacing[1]};
        }
    `
);

export const HeaderWrapper = styled.div(
    () => css`
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    `
);

export const CloseButton = styled(IconClose)(
    ({ theme: { fontSizes, palette } }) => css`
        font-size: ${fontSizes.large};
        cursor: pointer;
        margin-left: auto;
        text-decoration: none;
        & svg {
            fill: ${palette.grey[700]};
        }
    `
);

export const TitleStyled = styled.h1`
    ${({ theme: { fontSizes, screenSize, fonts, fontWeights, palette, spacing } }) => css`
        font-family: ${fonts.header};
        font-size: ${fontSizes.xlarge};
        font-weight: ${fontWeights.semiBold};
        text-align: center;
        margin-top: ${spacing[4]};
        margin-bottom: 0px;
        color: ${palette.primaryDark};
        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            font-size: ${fontSizes.large};
            margin-top: ${spacing[3]};
        }
    `}
`;

export const ConfirmationBodyTextStyled = styled.div`
    ${({ theme: { fontSizes, screenSize, fonts, spacing, fontWeights } }) => css`
        font-family: ${fonts.body};
        font-size: ${fontSizes.medLarge};
        font-weight: ${fontWeights.normal};
        text-align: center;
        margin-top: ${spacing[4]};
        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            font-size: ${fontSizes.medium};
            margin-top: ${spacing[3]};
        }
    `}
`;

export const SemiBoldText = styled.div`
    ${({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        display: inline;
    `}
`;

export const OrderedCol = styled(Col)(
    ({ mobileOrder, theme: { screenSize } }) => css`
        order: ${mobileOrder};

        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            order: 0;
        }
    `
);

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BackToInbox = styled(Link)(
    ({ theme: { fontSizes, palette, fontWeights, spacing, screenSize } }) => css`
        margin-top: ${spacing[4]};
        font-size: ${fontSizes.medium};
        cursor: pointer;
        color: ${palette.primaryRegular};
        font-weight: ${fontWeights.semiBold};
        text-decoration: underline;
        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            margin-top: ${spacing[3]};
        }
    `
);

export const MembershipPlanButton = styled(Link)(
    ({ theme: { fontWeights, screenSize, palette, typography, spacing } }) => css`
        width: 100%;
        color: ${palette.primaryDark};
        font-weight: ${fontWeights.semiBold};
        text-decoration: none;
        margin-top: ${spacing[4]};

        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            margin-top: ${spacing[3]};
        }

        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            ${typography.h3};
            padding-top: ${spacing[2.5]};
            padding-bottom: ${spacing[2.5]};
            color: ${palette.primaryDark};
            font-weight: ${fontWeights.normal};
        }
        font-weight: ${fontWeights.normal};
    `
);
