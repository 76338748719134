import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

import {
    baseInitialResultsListState,
    loadedResultsUniqueListReducer,
    createdResultListReducer,
    deletedResultListReducer,
} from '../common';

import { uniqueArray } from '../../helpers/format/objects';

export const initialState = { ...baseInitialResultsListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadAssignment.SUCCESS: {
            if (transform.isOpenAssignment(action.data)) {
                if (state.results.indexOf(action.data.id) < 0) {
                    return {
                        ...state,
                        results: uniqueArray([
                            ...state.results,
                            action.data.id,
                        ]),
                    };
                }
            }
            else if (state.results.indexOf(action.data.id) >= 0) {
                return {
                    ...state,
                    results: state.results.filter(itemId => itemId !== action.data.id),
                };
            }
            return state;
        }
        case actions.owner.loadOpenAssignments.SUCCESS:
            return loadedResultsUniqueListReducer(state, action);
        case actions.owner.unconfirmConfirmedAssignment.SUCCESS:
        case actions.owner.createListingOpenAssignment.SUCCESS:
            return createdResultListReducer(state, action);
        case actions.owner.removeListingOpenAssignment.SUCCESS:
            return deletedResultListReducer(state, action);
        default:
            return state;
    }
};
