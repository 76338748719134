import { all, call, takeLatest, select, put, take } from 'redux-saga/effects';
import { getLanguage } from 'shared/selectors';
import apiActions, { settings } from 'api/actions';
import * as actions from './actions';

export function* fetchSinglePost({ requestId, lang, area, category, post }) {
    yield put(
        apiActions.content?.loadBlogPost({
            forceReload: true,
            requestId,
            filters: {
                lang,
            },
            data: {
                areaSlug: area,
                categorySlug: category,
                postSlug: post,
            },
        })
    );

    while (true) {
        const { action, type, data } = yield take(settings.content.loadBlogPost.DONE);

        if (action.requestId === requestId) {
            if (type === settings.content.loadBlogPost.FAILURE) {
                return null;
            }

            return data;
        }
    }
}

export function* fetchResources(data) {
    const lang = yield select(getLanguage);

    const posts = (yield all(
        data.payload.map((item, index) => {
            const resource = item.resource.url;

            const url = new URL(resource);
            const [area, category, post] = url.pathname.split('/').filter(String);

            return call(fetchSinglePost, { requestId: index, lang, area, category, post });
        })
    ))
        .filter((p) => p !== null)
        .map((p) => ({
            slug: p.slug,
            shortTitle: p.shortTitle,
            excerpt: p.excerpt,
            coverImage: p.coverImage,
            category: p.category.slug,
            area: 'blog',
        }));

    yield put(actions.fetchPostsSuccess.create(posts));
}

export default function* sagas() {
    yield all([takeLatest(actions.fetchPosts.ACTION, fetchResources)]);
}
