/* eslint-disable import/prefer-default-export */
import { all, takeEvery, put, select, take } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { getLanguage } from 'shared/selectors';
import { fetchBlogPostsRequest, fetchBlogPostsSuccess, fetchBlogPostsFailure } from './actions';
import { BLOG_CAROUSEL_SEARCH_TYPE } from './BlogPostsCarousel.constants';

function* fetchBlogPosts({ payload: { area, categories, subcategories, tags } }) {
    let filters = {
        lang: yield select(getLanguage),
    };
    if (categories) {
        filters = {
            ...filters,
            'category_slugs[]': categories,
        };
    }
    if (subcategories) {
        filters = {
            ...filters,
            'subcategory_slugs[]': subcategories,
        };
    }
    if (tags) {
        filters = {
            ...filters,
            'tag_slugs[]': tags,
        };
    }
    yield put(
        apiActions.content.loadBlogAreaRelatedPosts({
            id: 'related-posts',
            forceReload: true,
            filters,
            data: {
                searchType: BLOG_CAROUSEL_SEARCH_TYPE,
                areaSlug: area,
            },
        })
    );

    const { status } = yield take(settings.content.loadBlogAreaRelatedPosts.DONE);

    if (status !== settings.content.loadBlogAreaRelatedPosts.SUCCESS) {
        yield put(fetchBlogPostsFailure.create());
        return false;
    }

    yield put(fetchBlogPostsSuccess.create());
    return true;
}

export default function* sagas() {
    yield all([takeEvery(fetchBlogPostsRequest.ACTION, fetchBlogPosts)]);
}
