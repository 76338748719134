import { settings as actions } from '../../../actions';

export const initialState = {};

// eslint-disable-next-line consistent-return
function addMessage(messages, message) {
    if (!message) return message;
    if (message.sentBy === 'me') {
        messages[message.recipientId] = message;
    }
    else {
        messages[message.senderId] = message;
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadOpenAssignments.SUCCESS:
        case actions.owner.loadListingOpenAssignments.SUCCESS:
        case actions.owner.loadDeletedAssignments.SUCCESS:
        case actions.owner.loadUnconfirmedPastAssignments.SUCCESS:
        case actions.owner.loadUpcomingAssignments.SUCCESS:
        case actions.owner.loadPastAssignments.SUCCESS: {
            const messages = {};
            action.data.results.forEach((item) => {
                addMessage(messages, item.lastMessage);
            });
            return {
                ...state,
                ...messages,
            };
        }
        case actions.owner.loadOpenAssignmentApplications.SUCCESS:
        case actions.owner.loadOpenAssignmentInvitations.SUCCESS:
        case actions.owner.loadOwnerSitterApplications.SUCCESS:
        case actions.owner.loadOwnerSitterInvitations.SUCCESS: {
            const messages = {};
            action.data.results.forEach((item) => {
                addMessage(messages, item.lastMessage);
            });
            return {
                ...state,
                ...messages,
            };
        }
        case actions.owner.loadConfirmedAssignments.SUCCESS: {
            const messages = {};
            if (action.data.current) {
                addMessage(messages, action.data.current.lastMessage);
            }
            action.data.past.forEach((item) => {
                addMessage(messages, item.lastMessage);
            });
            action.data.upcoming.forEach((item) => {
                addMessage(messages, item.lastMessage);
            });
            return {
                ...state,
                ...messages,
            };
        }
        case actions.owner.createBatchOpenAssignmentInvitation.SUCCESS: {
            return state;
        }
        case actions.owner.loadConversations.SUCCESS: {
            if (action.data.results.length > 0) {
                const messages = {};
                addMessage(messages, action.data.results[0]);
                return {
                    ...state,
                    ...messages,
                };
            }
            return state;
        }
        case actions.owner.createConversation.SUCCESS:
            return {
                ...state,
                [action.data.ownerId]: action.data,
            };
        case actions.owner.notifyConfirmedAssignmentUnconfirmedApplicants.SUCCESS: {
            return state;
        }
        case actions.owner.unconfirmConfirmedAssignment.SUCCESS:
        case actions.owner.loadAssignment.SUCCESS:
        case actions.owner.loadOpenAssignmentApplication.SUCCESS:
        case actions.owner.loadOpenAssignmentInvitation.SUCCESS:
        case actions.owner.createOpenAssignmentInvitation.SUCCESS:
        case actions.owner.createOpenAssignmentApplication.SUCCESS:
        case actions.owner.updateOpenAssignmentApplication.SUCCESS:
        case actions.owner.confirmOpenAssignmentApplication.SUCCESS:
        case actions.owner.unconfirmOpenAssignmentApplication.SUCCESS:
        case actions.owner.declineOpenAssignmentApplication.SUCCESS:
        case actions.owner.shortlistOpenAssignmentApplication.SUCCESS:
        case actions.owner.unshortlistOpenAssignmentApplication.SUCCESS:
        case actions.owner.cancelOpenAssignmentInvitation.SUCCESS: {
            const messages = {};
            addMessage(messages, action.data.lastMessage);
            return {
                ...state,
                ...messages,
            };
        }
        case actions.owner.archiveConversations.SUCCESS:
        case actions.owner.batchArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const messages = {};
            ids.forEach((id) => {
                messages[id] = {
                    ...(state[id] || {}),
                    isArchived: true,
                };
            });
            return {
                ...state,
                ...messages,
            };
        }
        case actions.owner.unArchiveConversations.SUCCESS:
        case actions.owner.batchUnArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const messages = {};
            ids.forEach((id) => {
                messages[id] = {
                    ...(state[id] || {}),
                    isArchived: false,
                };
            });
            return {
                ...state,
                ...messages,
            };
        }
        default:
            return state;
    }
};
