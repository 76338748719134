import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/Breadcrumb';
import { routes } from 'utils/routes';
import HeroWithSearch from './HeroWithSearch';
import { HeroVariants } from './constants';

function renderHero({ variant, ...data }) {
    const { image, actionLabel, actionType, inputLabel, inputPlaceholder, title, subtitle } = data;

    switch (variant) {
        case HeroVariants.HERO_WITH_LOCATION_SEARCH:
            return (
                <HeroWithSearch
                    image={image}
                    actionLabel={actionLabel}
                    actionType={actionType}
                    inputLabel={inputLabel}
                    inputPlaceholder={inputPlaceholder}
                    title={title}
                    subtitle={subtitle}
                />
            );
        default:
            return null;
    }
}

function HeroManager({ data, pageTitle }) {
    return (
        <div className="flex flex-col-reverse gap-8 min-[1280px]:mt-8 min-[1280px]:flex-col">
            <div className="flex justify-center min-[1280px]:mx-12 min-[1280px]:justify-start min-[1454px]:mx-auto min-[1454px]:w-[1360px]">
                <Breadcrumb
                    items={[
                        {
                            id: 'home',
                            text: 'Home',
                            to: routes.home(),
                        },
                        {
                            id: 'current-page',
                            text: pageTitle,
                        },
                    ]}
                />
            </div>

            {renderHero(data)}
        </div>
    );
}

HeroManager.propTypes = {
    data: PropTypes.shape({
        variant: PropTypes.string.isRequired,
        image: PropTypes.shape({
            url: PropTypes.string,
            mobile: PropTypes.shape({
                url: PropTypes.string,
            }),
            alt: PropTypes.string,
        }),
        actionLabel: PropTypes.string,
        actionType: PropTypes.string,
        inputLabel: PropTypes.string,
        inputPlaceholder: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
    }).isRequired,
    pageTitle: PropTypes.string.isRequired,
};

export default HeroManager;
