import * as sliceTypes from 'components/content/prismicSlices/constants';

export const getPagesLoading = (state) => state.pages.app.pagesLoading;

export const getPartnerCode = (state) => state.shared.partnerCode;

export const getNavbarOptions = (state) => state.pages.app.navbarOptions;

export const getFooterOptions = (state) => state.pages.app.footerOptions;

export const getZendeskOptions = (state) => state.pages.app.zendeskOptions;

export const getSeoResourceLinks = (state, pathname, lang) =>
    state.content.seoContent[pathname]?.[lang]?.body?.filter(
        (slice) => slice.sliceType === sliceTypes.RESOURCE_LINKS
    );
