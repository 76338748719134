import styled, { css } from 'styled-components';
import { P, H2, H3, H4 } from 'typography';
import Button from 'components/buttons/Button';

import { IconCancellationInsurance, IconTravel, IconUpgrade } from 'components/Icon';

const ModalSpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ModalTitle = styled(H2)(
    ({ theme: { fontSizes, spacing, palette, screenSize, fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        line-height: 1.5;
        color: ${palette.primaryDark};
        margin: 0 0 ${spacing[3]} 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${fontSizes.large};
        flex-direction: column;

        > span {
            margin-bottom: ${spacing[3]};
        }

        // 720
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            flex-direction: row;
            > span {
                margin-bottom: 0;
                margin-right: ${spacing[2]};
            }
        }

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            font-size: ${fontSizes.xlarge};
        }
    `
);

const ModalSubTitle = styled(H3)(
    ({ theme: { fonts, fontSizes, palette, screenSize } }) => css`
        font-family: ${fonts.default};
        font-weight: normal;
        text-align: center;
        font-size: ${fontSizes.medium};
        color: ${palette.text};

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            font-size: ${fontSizes.medLarge};
        }
    `
);

const ModalSubTitleBold = styled.span`
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
`;

const FeatureCardWrapper = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: ${spacing[4]} auto 0;
        max-width: 500px;

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            max-width: 800px;
        }

        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            max-width: 1000px;
        }
    `
);

const FeatureCard = styled.div(
    ({ theme: { spacing, borderRadius, screenSize, palette } }) => css`
        background-color: ${palette.white};
        width: 272px;
        padding: ${spacing[4]};
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.12);
        border-radius: ${borderRadius.regular};
        margin-bottom: ${spacing[4]};
        height: fit-content;

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            &:nth-child(2) {
                margin-left: ${spacing[4]};
            }
        }

        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            &:nth-child(3) {
                margin-left: ${spacing[4]};
            }
        }
    `
);

const commonIconStyles = `
    width: unset;
    height: unset;
    display: block;

    svg {
        width: 48px;
        height: 48px;
    }
`;

const IconCancellationInsuranceStyled = styled(IconCancellationInsurance)`
    ${commonIconStyles}
`;

const IconTravelStyled = styled(IconTravel)`
    ${commonIconStyles}
`;
const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CardTitle = styled(H4)(
    ({ theme: { typography, fontSizes, palette } }) => css`
        ${typography.h3}
        display: flex;
        align-items: center;
        font-size: ${fontSizes.medium};
        color: ${palette.text};
        min-height: 45px;
        margin-top: 0px;
        margin-bottom: 0px;
    `
);

const UpgradeCard = styled(FeatureCard)`
    border: 4px solid ${({ theme }) => theme.palette.primaryRegular};
    padding: 0px;
`;

const PremiumMembershipBadge = styled.div(
    ({ theme: { borderRadius, fontWeights, palette, typography } }) => css`
        ${typography.smallText};
        font-weight: ${fontWeights.semiBold};
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: ${borderRadius.small};
        background-color: ${palette.primaryDark};
    `
);

const IconPremiumStyled = styled(IconUpgrade)(
    ({ theme: { spacing } }) => css`
        margin-right: ${spacing[0.5]};
        width: 11px;
    `
);

const UpgradeBody = styled.div`
    padding: ${({ theme }) => theme.spacing[4]};
    padding-top: ${({ theme }) => theme.spacing[4]};
    color: ${({ theme }) => theme.palette.white}};
`;

const UpgradeHead = styled.div`
    ${({ theme }) => theme.typography.smallText};
    padding: ${({ theme }) => theme.spacing[1]};
    background-color: ${({ theme }) => theme.palette.primaryRegular}};
    color: ${({ theme }) => theme.palette.white}};
    display: flex;
    justify-content: center;
`;

const CardText = styled(P)(
    ({ theme: { typography, spacing, palette } }) =>
        css`
            ${typography.smallText};
            margin-top: ${spacing[1]};
            color: ${palette.text};
        `
);

const CardButtonStyled = styled(Button)`
    width: 100%;
`;

const ModalFooter = styled.div`
    text-align: center;
`;
const NoExtraCover = styled.div`
    color: ${({ theme }) => theme.palette.primaryRegular};
    text-decoration: underline;
    cursor: pointer;
`;

export {
    NoExtraCover,
    ModalTitle,
    IconCancellationInsuranceStyled,
    IconTravelStyled,
    IconPremiumStyled,
    ModalSpinnerWrapper,
    ModalSubTitle,
    ModalSubTitleBold,
    FeatureCardWrapper,
    FeatureCard,
    CardHeader,
    CardTitle,
    CardText,
    CardButtonStyled,
    UpgradeBody,
    UpgradeCard,
    UpgradeHead,
    PremiumMembershipBadge,
    ModalFooter,
};
