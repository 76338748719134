import styled, { css } from 'styled-components';
import { visuallyHide } from 'utils/utils';

export const Wrapper = styled.div`
    position: relative;
`;

const menuHiddenState = css`
    ${visuallyHide};
`;

export const DropdownMenuStyled = styled.ul`
    position: absolute;
    top: 35px;
    left: 0;
    box-sizing: border-box;
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing[1]};
    cursor: pointer;
    background-color: white;
    list-style: none;
    outline: none;
    border: 1px solid ${({ theme }) => theme.palette.border};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 99;

    ${({ isOpen }) => (!isOpen ? menuHiddenState : '')};
`;

export const MenuItemStyled = styled.li`
    user-select: none;
    white-space: nowrap;
    padding: ${({ theme }) => theme.spacing[1]};
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ highlighted, theme }) =>
        highlighted ? `background-color:  ${theme.palette.grey[100]};` : ''};

    ${({ isSelected, type, theme }) => {
        if (type !== 'simple') return '';

        return isSelected ? `background-color:  ${theme.palette.primaryRegular};` : '';
    }};
`;

export const InputStyled = styled.input``;

export const MenuItemSpanStyled = styled.span``;
