import styled, { css } from 'styled-components';
import { H1, h1Styles } from 'typography';

export const HeadingStyled = styled(H1)(
    ({ theme: { spacing, fontSizes } }) => css`
        ${h1Styles};
        text-align: center;
        margin-bottom: ${spacing[4]};
        margin-top: 0;
        font-size: ${fontSizes.xlarge};
        line-height: ${spacing[6]};
    `
);

export const LoginMessageStyled = styled.div(
    ({ theme: { spacing } }) => css`
        text-align: center;
        margin-top: ${spacing[2]};
    `
);
