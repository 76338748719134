import { put, take, takeLatest } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import * as actions from './actions';

export function* startIdCheck() {
    yield put(apiActions.verifications.createIdVerification());

    const { data } = yield take(
        (res) => res.type === settings.verifications.createIdVerification.DONE
    );

    window.location.href = data.link;
}

export default function* idCheckSaga() {
    yield takeLatest(actions.startIdCheck.ACTION, startIdCheck);
}
