import { call, put, take, select } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { error as errorAction, load as pageActionsLoad } from 'containers/Page/actions';
import { SEARCH_TYPE, NEARBY_PROFILES, PROFILES_RESULTS_PER_PAGE } from 'config/search';
import { track, Events } from 'utils/analytics';
import { base64decode } from 'utils/strings';
import { createProfilesSearchQuery } from 'utils/searchProfiles';
import { getIsMembershipActive } from 'utils/account';
import * as SearchConstants from 'api/helpers/search/constants';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { CountryCodes } from 'utils/constants';
import { getOwnerListings } from 'api/selectors/owner';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
    userTypes,
    VariationTypes,
} from 'containers/ExperimentalFeature';
import { fetchProfilesFacets } from 'pages/search/components/DynamicFacets/actions';
import { SEARCH_TYPE_RESULTS, SEARCH_TYPE_PLACE, PAGE_ID } from '../SearchProfiles.constants';
import { getLocale, getRouterLocation } from '../../../../shared/selectors';
import doSearchNearbySitters from './doSearchNearbySitters';
import {
    getFacetsBelowPlace,
    getGeoHierarchyParamsAndLocationFilters,
    getSearchLocationData,
    CountryCodeToCountrySlugMap,
    getAdmin2Filters,
    isInAPrimaryMarket,
} from '../../helpers';
import * as actions from '../actions';
import { useLocalSits, getSittersLookingInYourAreaVariant } from '../selectors';

function* doSearch({ search, type, params = {} }) {
    // Check the encoded query
    let query;
    const { q } = search;
    if (q) {
        try {
            query = JSON.parse(base64decode(q));
        } catch {
            // If the query is malformed then return a 400 bad request
            yield put(errorAction.create(PAGE_ID, 400));
            return false;
        }
    }

    // eslint-disable-next-line prefer-const
    let { geoHierarchyParams, locationFilters } = getGeoHierarchyParamsAndLocationFilters({
        query,
        params,
    });
    let hasGeoHierarchyParams = Object.keys(geoHierarchyParams).length > 0;
    const membershipPlan = yield select(getAccountCurrentMembershipPlan);
    const { countryISOCode } = yield select(getLocale);
    const isPaidMember = getIsMembershipActive(membershipPlan);

    // For non-members the default search can be based on their locale
    const localSits = yield select(useLocalSits);

    if (localSits) {
        // Update the params and filters to match the locale
        geoHierarchyParams = { countrySlug: CountryCodeToCountrySlugMap[countryISOCode] };
        locationFilters = { geoHierarchy: geoHierarchyParams };
        hasGeoHierarchyParams = true;
    }

    // A location is present, go and get the place
    let place;
    if (Object.keys(locationFilters).length) {
        const response = yield call(getSearchLocationData, SEARCH_TYPE_PLACE, locationFilters);
        if (response.error) {
            yield put(errorAction.create(PAGE_ID, response.error));
            return false;
        }

        place = response.place;
    }

    // Only call placeLoaded when not in "local sits" mode because we don't want
    // it to look like a user-initiated search
    if (place && !localSits) {
        yield put(actions.placeLoaded.create(place));
    } else {
        // No location so clear place
        yield put(actions.placeLoaded.create());
    }

    // If this is a landing page e.g. had geo hierarchy add location data to filters
    let filters = {};
    if (hasGeoHierarchyParams && place) {
        // Set the geo-hierarchy filters from the params
        filters = {
            geoHierarchy: geoHierarchyParams,
            locationData: place,
        };
    }

    // If the user is performing a search on the cat sitters SEO page,
    // we add the animal experience filter with cats by default
    const routeLocation = yield select(getRouterLocation);
    // check if the routeLocation exists
    if (routeLocation) {
        const catSitterLocation = routeLocation.location.pathname;
        const isCatProfileSearch = catSitterLocation.includes('cat-sitters') || false;
        if (isCatProfileSearch) {
            filters = {
                ...filters,
                animalExperience: ['cat'],
            };
        }
    }

    const inAPrimaryMarket = isInAPrimaryMarket(countryISOCode);

    // Only paid members see sitters sorted by `last_modified`, logged out and partials
    // see results ordered by `reviews_count`
    if (!isPaidMember) {
        if (inAPrimaryMarket) {
            if (countryISOCode === CountryCodes.US) {
                // have to make sure that US based users would see background checked members first
                // and so have to add a sort based on if a member has CBC
                // and then sort descendingly by verification_level
                // which means it would have the order of: CBC, ID check, and then the rest of the member base
                filters.sort = [
                    ...(filters.sort || []),
                    ...[
                        {
                            'completed_verifications.has_verified_criminal_background_check':
                                SearchConstants.SortDirection.DESC,
                        },
                    ],
                ];
            }
            // for users in other primary markets would get a descending sort by verification_level
            // i.e. would go in order of: ID check, CBC, rest of the member base
            filters.sort = [
                ...(filters.sort || []),
                ...[{ verification_level: SearchConstants.SortDirection.DESC }],
            ];
        }

        filters.sort = [
            ...(filters.sort || []),
            ...[{ reviews_count: SearchConstants.SortDirection.DESC }],
        ];
    }

    const { variation } = yield select(getExperimentalFeatureVariationSelector, {
        experiment: experiments.SEARCH_PROFILE_LAYOUT,
        excludeCombo: [userTypes.PaidUser, userTypes.AnonymousUser, userTypes.ExpiredUser],
    });
    const isVariation1OfProfileTest = variation === VariationTypes.VARIATION1;

    const resultsPerPage = variation === VariationTypes.VARIATION1 ? 10 : PROFILES_RESULTS_PER_PAGE;
    let searchQuery;

    // AB TEST FOR SITTERS LOOKING IN YOUR AREA
    const sittersLookingInYourAreaVariant = yield select(getSittersLookingInYourAreaVariant);
    if (sittersLookingInYourAreaVariant === VariationTypes.VARIATION1) {
        // SET THE SORT ORDER BY SITTERS LOOKING IN YOUR AREA
        let ownerListings = yield select(getOwnerListings);
        if (!ownerListings || (ownerListings && ownerListings.length < 1)) {
            yield put(apiActions.owner.loadListings({ forceReload: true }));

            const { status } = yield take((res) => res.type === settings.owner.loadListings.DONE);

            if (status === settings.owner.loadListings.SUCCESS) {
                ownerListings = yield select(getOwnerListings);
            }
        }
        const coordinates =
            ownerListings &&
            ownerListings.length > 0 &&
            ownerListings[0].location.coordinates &&
            ownerListings[0].isApproved;
        if (coordinates) {
            filters = {
                sort: [
                    {
                        function: {
                            name: 'RECOMMENDED',
                            order: 'desc',
                        },
                    },
                ],
            };
        }
    }

    // If there is an encoded query use that, if not create a new query
    if (!query) {
        // Create a new search query
        searchQuery = createProfilesSearchQuery(1, filters, [], resultsPerPage);
    } else {
        try {
            // Create a new query from existing filters
            searchQuery = createProfilesSearchQuery(
                query.page,
                {
                    ...query.filters,
                    ...filters,
                },
                [],
                resultsPerPage
            );
        } catch (e) {
            // If the query contains any invalid data then return a 400 bad request
            yield put(errorAction.create(PAGE_ID, 400));
            return false;
        }
    }

    let { searchLevel } = getFacetsBelowPlace(place);

    query = searchQuery.getRequestData();

    yield put(
        apiActions.search.loadProfiles({
            forceReload: true,
            filters: {
                query: JSON.stringify(query),
            },
            data: {
                searchType: SEARCH_TYPE_RESULTS,
                rawQuery: query,
                searchLevel,
            },
        })
    );

    let { data, status, statusCode } = yield take(
        (res) =>
            res.type === settings.search.loadProfiles.DONE &&
            res.requestData.searchType === SEARCH_TYPE_RESULTS
    );

    if (isVariation1OfProfileTest && data.results && data.results.length > 0) {
        for (let i = 0; i < data.results.length; i += 1) {
            yield put(
                apiActions.search.loadProfile({
                    forceReload: true,
                    data: {
                        id: data.results[i].id,
                    },
                })
            );
        }
    }

    // If we have no results search again on the admin2 level
    if (!data || (data && data.total < 1)) {
        const admin2Filter = getAdmin2Filters({ filters });

        if (admin2Filter) {
            const admin2SearchQuery = createProfilesSearchQuery(1, admin2Filter);

            ({ searchLevel } = getFacetsBelowPlace(place));

            const admin2Query = admin2SearchQuery.getRequestData();

            yield put(
                apiActions.search.loadProfiles({
                    forceReload: true,
                    filters: {
                        query: JSON.stringify(admin2Query),
                    },
                    data: {
                        searchType: SEARCH_TYPE_RESULTS,
                        rawQuery: admin2Query,
                        searchLevel,
                    },
                })
            );

            ({ data, status, statusCode } = yield take(
                (res) =>
                    res.type === settings.search.loadProfiles.DONE &&
                    res.requestData.searchType === SEARCH_TYPE_RESULTS
            ));

            if (isVariation1OfProfileTest && data.results && data.results.length > 0) {
                for (let i = 0; i < data.results.length; i += 1) {
                    yield put(
                        apiActions.search.loadProfile({
                            forceReload: true,
                            data: {
                                id: data.results[i].id,
                            },
                        })
                    );
                }
            }
        }
    }

    // Load the facets
    // NOTE: If we're in `localSits` mode, we want to get the worldwide facets
    // which means sending `undefined` in the facets action.
    yield put(
        fetchProfilesFacets.create({
            place,
            filters: {
                ...query.filters,
            },
            searchLevel: localSits ? undefined : searchLevel,
        })
    );

    // Now load map clusters
    if (type === pageActionsLoad.ACTION) {
        // Log analytics on load
        const searchFilters = {
            ...query.filters,
            seoHierarcy: hasGeoHierarchyParams,
            place,
        };

        track(
            Events.SEARCH_FILTERS.create({
                category: SEARCH_TYPE.Profile,
                query: searchFilters,
                items: (data && data.results) || [],
                searchOptions: {
                    totalResults: data.total,
                },
            })
        );
    }

    // Did an error occur?
    if (statusCode === 400) {
        // If the API gives us a 400 then it's likely the geonames data is broken for this location.
        // We decided in gh3345 that the best thing to do is 404
        yield put(errorAction.create(PAGE_ID, 404));
        return false;
    }

    const enoughSitters = data && data.total > NEARBY_PROFILES.showWhenLessThan;

    // If the call was successful but didn't yield enough results in total then fetch nearby sitters
    if (status === settings.search.loadProfiles.SUCCESS && !enoughSitters && place) {
        // Populate nearby sitters
        yield call(doSearchNearbySitters, { filters: query.filters, place });
    } else {
        // Clear nearby sitters
        yield put({
            type: settings.search.loadProfiles.RESET,
            requestData: {
                searchType: NEARBY_PROFILES.key,
            },
        });
    }

    return true;
}

export { doSearch as default };
