import { RECOMMENDED_PROFILES_GROUP } from 'config/search';

export const PAGE_ID = 'SearchProfiles';
export const SEARCH_TYPE_RESULTS = 'search-profiles-results';
export const SEARCH_TYPE_FILTERS = 'search-profiles-filters';
export const SEARCH_TYPE_PLACE = 'search-profiles-place';
export const SEARCH_TYPE_WORLDWIDE_FACETS = 'worldwide-facets';

/**
 * Recommended profiles order
 * @type {string[]}
 */
export const RECOMMENDED_PROFILES_ORDER = [
    RECOMMENDED_PROFILES_GROUP.favouriteYourLocation,
    RECOMMENDED_PROFILES_GROUP.newWithReferences,
    RECOMMENDED_PROFILES_GROUP.experiencedWithAnimals,
    RECOMMENDED_PROFILES_GROUP.yourKindOfSitters,
];

export const RESULT_SCROLL_OFFSET = -120;

export const PAGE_NAME_SEARCH_PROFILES = 'searchProfiles';
export const SEARCH_PAGE_PROFILES = 'profiles';
