import React from 'react';
import { bool, func, string, object } from 'prop-types';
import {
    IconFavourite,
    IconFavouriteOutlineFilled,
    IconFavouriteOutlineFilledCoral,
} from 'components/Icon';
import { LabelStyled, CheckboxInput, IconWrapper, VariationIconWrapper } from './Favourite.style';

const Favourite = ({
    isVariation = false,
    onChange,
    describedBy,
    disabled,
    isChecked,
    id,
    label,
    ...props
}) => (
    <LabelStyled htmlFor={id} {...props}>
        <CheckboxInput
            aria-describedby={describedBy}
            disabled={disabled}
            id={id}
            type="checkbox"
            checked={isChecked}
            onChange={onChange}
        />
        {isVariation ? (
            <VariationIconWrapper disabled={disabled} isChecked={isChecked}>
                {isChecked ? (
                    <IconFavouriteOutlineFilledCoral size="large" />
                ) : (
                    <IconFavouriteOutlineFilled size="large" />
                )}
            </VariationIconWrapper>
        ) : (
            <IconWrapper disabled={disabled} isChecked={isChecked}>
                <IconFavourite isChecked={isChecked} size="large" />
            </IconWrapper>
        )}
        {label}
    </LabelStyled>
);

Favourite.propTypes = {
    /**
     * populates the aria-describedby property of the Favourite input
     */
    describedBy: string,
    /**
     * Indicates whether the Favourite is disabled
     */
    disabled: bool,
    isChecked: bool,
    /**
     * Click handler for when Favourite is toggled
     */
    onChange: func,
    id: string.isRequired,
    label: object.isRequired,
    isVariation: bool,
};

export default Favourite;
