import { push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';

import { createProfilesSearchQuery } from 'utils/searchProfiles';
import { base64encode } from 'utils/strings';

import { getRouterLocation } from 'src/shared/selectors';
import { buildPathName } from '../selectors';

/**
 * Update the url in the browser (this will trigger a load event to actually hit the api)
 *
 * @param params - url params
 * @param filters - search filters
 * @param page - searched page
 * @returns {IterableIterator<*|PutEffect<Action>>}
 */
function* navigateTo(params, filters, page = 1) {
    let pathname;

    function hasLocationInFilters(searchFilters) {
        if (
            searchFilters.geoBounds ||
            searchFilters.geoPoint ||
            searchFilters.worldwide ||
            searchFilters.geoHierarchy
        ) {
            return true;
        }
        return false;
    }

    // If the user is searching for cat sitters, add the animal experience filter
    const routeLocation = yield select(getRouterLocation);
    // check if the routeLocation is a string
    let isCatProfileSearch = false;
    if (routeLocation) {
        const catSitterLocation = routeLocation.location.pathname;
        isCatProfileSearch = catSitterLocation.includes('cat-sitters') || false;
    }

    // If location is in filters we change clean
    // geohierarchy in the url
    if (hasLocationInFilters(filters)) {
        pathname = buildPathName({}, isCatProfileSearch);
    } else {
        pathname = buildPathName(params, isCatProfileSearch);
    }

    const searchQuery = createProfilesSearchQuery(page, filters);

    const location = `?q=${base64encode(JSON.stringify(searchQuery.getRequestData()))}`;

    yield put(
        push({
            pathname,
            search: location,
        })
    );
}

export { navigateTo as default };
