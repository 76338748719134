import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { retry } from 'utils/ssr';
import asPage from 'containers/Page';
import { PAGE_ID } from './constants';
import { getPrivacyPolicyContent } from './selectors';

const LoadablePrivacyPolicy = loadable(() => retry(() => import('./PrivacyPolicy')));

const mapStateToProps = (state) => ({
    content: getPrivacyPolicyContent(state),
});

export default connect(mapStateToProps)(
    asPage(withTranslation()(LoadablePrivacyPolicy), PAGE_ID, true, true)
);
