/* global gsc */
import { MembershipStatus } from 'api/types';
import { MEMBERSHIP_PAID_STATUS } from 'config/constants';
import {
    getMembershipStatus,
    getMembershipType,
    getMembershipTier,
    getCardExpiry,
    getCardExpiryDate,
} from 'utils/account';
import optimizely from 'utils/integrations/optimizely';
import environment from 'environment';

const getIsGetSiteControlEnabled = () => {
    const anonymousUserId = 'NA';
    const featureName = 'web_get_site_control';
    const isEnabled = optimizely.getClient().isFeatureEnabled(featureName, anonymousUserId, {});
    return isEnabled;
};

const initialiseGetSiteControl = () => {
    if (window && !window.gsc) {
        const script = document.createElement('script');
        script.id = 'getsitecontrol';
        script.type = 'text/javascript';
        script.src = 'https://l.getsitecontrol.com/m42xgqwy.js';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        // Makes gsc api available on window
        window.gsc =
            window.gsc ||
            // eslint-disable-next-line func-names
            function (...args) {
                (gsc.q = gsc.q || []).push(args);
            };
    }
};

// Experiments should be a simple key->value map of experiment ids to the variation the user should see, like:
// {
//    webExpOwnerFreeTrial: "variation1",
//    someOtherTest: "control",
// }
// The IDs should be camelCase to work consistently with other values in getsitecontrol
const getSiteControlIdentify = (membership, card, joinDate, experiments = {}) => {
    /**
     * GetSiteControl currently expects the following parameters:
     * userMembershipType: null, housesitter, homeowner, combined
     * userMembershipStatus: null, not paid (partial and expired), paid
     */

    // Returns null if membership is empty
    const membershipStatus = getMembershipStatus(membership);

    let membershipType = null;
    if (membershipStatus) {
        membershipType = getMembershipType(membership);
    }

    // Get the status in the form gsc expects
    let gscMembershipStatus = null;
    if (membershipStatus) {
        if (membershipStatus === MembershipStatus.MEMBER) {
            gscMembershipStatus = MEMBERSHIP_PAID_STATUS.paid;
        } else {
            gscMembershipStatus = MEMBERSHIP_PAID_STATUS.notPaid;
        }
    }

    const membershipTier = getMembershipTier(membership);

    const registeredDate = joinDate || null;
    const planAutorenewing = membership.isAutorenewing || null;
    const membershipStart = membership.startDate || null;
    const membershipEnd = membership.expiryDate || null;
    const cardExpiry = getCardExpiry(card);
    const cardExpiryDate = getCardExpiryDate(card);

    if (window && window.gsc) {
        window.gsc('params', {
            userMembershipStatus: gscMembershipStatus,
            userMembershipType: membershipType,
            membershipTier,
            registeredDate,
            planAutorenewing,
            membershipStart,
            membershipEnd,
            cardExpiry,
            cardExpiryDate,
            environment: environment.vars.mode,
            ...experiments,
        });
    }
};

// eslint-disable-next-line import/prefer-default-export
export { getIsGetSiteControlEnabled, getSiteControlIdentify, initialiseGetSiteControl };
