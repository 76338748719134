// eslint-disable-next-line import/prefer-default-export
export const MEMBER_ADDED_SAFETY_CONTACT = {
    create(payload) {
        return {
            type: 'Member Added Safety Contact',
            payload,
        };
    },
};

export const MEMBER_ADDED_ICE_CONTACT = {
    create(payload) {
        return {
            type: 'Member Added ICE Contact',
            payload,
        };
    },
};
