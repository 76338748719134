import React, { forwardRef } from 'react';
import { WrapperStyled } from './Control.style';

const Control = forwardRef(({ children, ...rest }, ref) => (
    <WrapperStyled ref={ref} {...rest}>
        {children}
    </WrapperStyled>
));

Control.displayName = 'Form.Control';

export default Control;
