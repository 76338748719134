import { IconChevronRight } from 'components/Icon';
import styled from 'styled-components';

export const StyledChevronRightIcon = styled(IconChevronRight)`
    > svg {
        fill: ${({ theme }) => theme.palette.accent};
        height: 18px;
        width: 18px;
    }
`;
