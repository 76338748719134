import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { isViewingInAppWebView } from 'shared/selectors';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { PAGE_ID } from './WhatsNext.constants';

const LoadableWhatsNext = loadable(() => retry(() => import('./WhatsNext')));

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

const mapStateToProps = (state) => ({
    isViewingInAppWebView: isViewingInAppWebView(state),
});

export default connect(mapStateToProps)(
    asPage(withTranslation()(LoadableWhatsNext), PAGE_ID, false, false, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
