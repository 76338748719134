/* eslint-env browser */
import React, { Component } from 'react';
import { clamp } from 'utils/number';
import { addEventListener } from 'utils/funcs';
import { ActionTypes, SliderModes } from './RangeSlider.constants';
import { WrapperStyled, ThumbStyled, TrackWrapperStyled, TrackStyled } from './RangeSlider.style';

class RangeSlider extends Component {
    state = {
        action: ActionTypes.None,
        actionIndex: -1,
        selected: this.getCurrentSelectedIndices(),
        prevSelected: this.getCurrentSelectedIndices(),
        mode: this.getMode(),
    };

    componentDidUpdate() {
        const selected = this.getCurrentSelectedIndices();

        const hasChanged =
            selected[0] !== this.state.prevSelected[0] ||
            selected[1] !== this.state.prevSelected[1];

        if (hasChanged) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                selected,
                prevSelected: selected,
            });
        }
    }

    onKeyDown = (event, index) => {
        const { values } = this.props;

        const { selected, mode } = this.state;

        const minSelected = selected[0];
        const maxSelected = selected[1];

        let newIndex = selected[index];

        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowRight':
                newIndex = this.addToValue(newIndex);

                // Prevent overlaping
                if (index === 0 && newIndex >= maxSelected) {
                    newIndex = this.addToValue(maxSelected, -1);
                }
                break;
            case 'PageUp':
                newIndex = this.addToValue(newIndex, 10);

                // Prevent overlaping
                if (index === 0 && newIndex >= maxSelected) {
                    newIndex = this.addToValue(maxSelected, -1);
                }
                break;
            case 'End':
                if (mode === SliderModes.Index) {
                    newIndex = values.length - 1;
                } else {
                    newIndex = 100;
                }

                // Prevent overlaping
                if (index === 0 && newIndex >= maxSelected) {
                    newIndex = this.addToValue(maxSelected, -1);
                }
                break;
            case 'ArrowDown':
            case 'ArrowLeft':
                newIndex = this.addToValue(newIndex, -1);

                // Prevent overlaping
                if (index === 1 && newIndex <= minSelected) {
                    newIndex = this.addToValue(minSelected, 1);
                }
                break;
            case 'PageDown':
                newIndex = this.addToValue(newIndex, -10);

                // Prevent overlaping
                if (index === 1 && newIndex <= minSelected) {
                    newIndex = this.addToValue(minSelected, 1);
                }
                break;
            case 'Home':
                newIndex = 0;

                // Prevent overlaping
                if (index === 1 && newIndex <= minSelected) {
                    newIndex = this.addToValue(minSelected, 1);
                }
                break;
            default:
                return;
        }

        event.preventDefault();
        this.updateState(event, index, newIndex);
    };

    onFocus = (event, index) => {
        this.setState({
            action: ActionTypes.Focused,
            actionIndex: index,
        });
    };

    onBlur = (event, index) => {
        this.setState({
            action: ActionTypes.None,
            actionIndex: index,
        });
    };

    onClick = (event) => {
        const { values, step } = this.props;
        const { selected, mode } = this.state;

        const percent = this.getOffsetPercentage(this.containerRef, event);
        let newValue = -1;

        if (mode === SliderModes.Index) {
            newValue = this.getPercentToIndex(percent, values.length - 1);
        } else if (mode === SliderModes.Step) {
            newValue = clamp(this.getPercentToStepPercentage(percent, step), 0, 100);
        } else {
            newValue = percent;
        }

        // Find the closest index
        //   if <= first, move first
        //   if >= last, move last
        //   else move closest
        let index = 0;
        if (newValue < selected[0]) index = 0;
        else if (newValue > selected[1]) index = 1;
        else if (Math.abs(newValue - selected[0]) < Math.abs(newValue - selected[1])) index = 0;
        else index = 1;

        if (!(this.state.action === ActionTypes.Click && this.state.actionIndex === index)) {
            this.setState(
                {
                    action: ActionTypes.Click,
                    actionIndex: index,
                },
                () =>
                    setTimeout(() => {
                        this.setState({
                            action: ActionTypes.None,
                            actionIndex: index,
                        });
                    }, 350)
            );
        }

        this.updateState(event, index, newValue);
    };

    onTouchStart = (event, index) => {
        event.preventDefault();

        this.globalMouseUpListener = addEventListener(document, 'touchend', (mouseEvent) =>
            this.onMouseUp(mouseEvent, index)
        );
    };

    onMouseDown = (event, index) => {
        event.preventDefault();

        if (!(this.state.action === ActionTypes.Selected && this.state.actionIndex === index)) {
            this.setState({
                action: ActionTypes.Selected,
                actionIndex: index,
            });
        }

        // Reset
        if (this.globalMouseUpListener) {
            this.globalMouseUpListener.remove();
        }

        if (this.globalMouseMoveListener) {
            this.globalMouseMoveListener.remove();
        }

        this.globalMouseUpListener = addEventListener(document, 'mouseup', (mouseEvent) =>
            this.onMouseUp(mouseEvent, index)
        );
        this.globalMouseMoveListener = addEventListener(document, 'mousemove', (mouseEvent) =>
            this.onMouseMove(mouseEvent, index)
        );
    };

    onMouseUp = (event, index) => {
        event.preventDefault();

        if (!(this.state.action === ActionTypes.None && this.state.actionIndex === index)) {
            this.setState({
                action: ActionTypes.None,
                actionIndex: index,
            });
        }

        // Reset
        if (this.globalMouseUpListener) {
            this.globalMouseUpListener.remove();
        }

        if (this.globalMouseMoveListener) {
            this.globalMouseMoveListener.remove();
        }
    };

    onMouseMove = (event, index) => {
        const { mode, selected } = this.state;
        const { values, step } = this.props;

        if (!(this.state.action === ActionTypes.Moving && this.state.actionIndex === index)) {
            this.setState({
                action: ActionTypes.Moving,
                actionIndex: index,
            });
        }

        const percent = this.getOffsetPercentage(this.containerRef, event);

        let newValue = percent;
        if (mode === SliderModes.Index) {
            newValue = this.getPercentToIndex(percent, values.length - 1);
        } else if (mode === SliderModes.Step) {
            newValue = clamp(this.getPercentToStepPercentage(percent, step), 0, 100);
        }

        const minSelected = selected[0];
        const maxSelected = selected[1];

        // Prevent overlaping
        if (index === 0 && newValue >= maxSelected) {
            newValue = this.addToValue(maxSelected, -1);
        } else if (index === 1 && newValue <= minSelected) {
            newValue = this.addToValue(minSelected);
        }

        this.updateState(event, index, newValue);
    };

    getCurrentSelectedIndices() {
        const { selected, values, min, max } = this.props;

        const mode = this.getMode();

        if (mode === SliderModes.Index) {
            if (!selected) return [0, values.length - 1];
            return [values.indexOf(selected[0]), values.indexOf(selected[1])];
        }

        if (!selected) return [min, max];
        return selected.map((value) => ((value - min) / (max - min)) * 100);
    }

    getMode() {
        const { values, min, max, step } = this.props;
        if (values && values.length > 0) return SliderModes.Index;
        if (typeof min === 'number' && typeof max === 'number' && step) return SliderModes.Step;
        return SliderModes.Fluid;
    }

    getPercentToIndex = (percent, n) => parseInt(Math.round(n * (percent / 100)), 10);

    getPercentToStepPercentage = (percent, step) => parseInt(Math.round(percent / step), 10) * step;

    getOffset = (node) => {
        const { pageYOffset, pageXOffset } = global;
        const { left, top } = node.getBoundingClientRect();

        return {
            top: top + pageYOffset,
            left: left + pageXOffset,
        };
    };

    getMousePosition = (event) => {
        if (event.changedTouches && event.changedTouches[0]) {
            return {
                x: event.changedTouches[0].pageX,
                y: event.changedTouches[0].pageY,
            };
        }

        return {
            x: event.pageX,
            y: event.pageY,
        };
    };

    getOffsetPercentage = (node, event) => {
        const { width } = node.getBoundingClientRect();
        const { left } = this.getOffset(node);
        const { x } = this.getMousePosition(event);

        const xPos = x - 22;

        const value = xPos - left;
        const onePercent = (width - 44) / 100;

        return clamp(value / onePercent, 0, 100);
    };

    getValue = (index) => {
        const { min, max } = this.props;
        const { mode, selected } = this.state;

        const value = selected[index];

        if (mode === SliderModes.Index) {
            return value;
        }
        // prettier-ignore
        return min + ((value / 100) * (max - min));
    };

    getValueText = (index) => {
        const { min, max, values } = this.props;
        const { mode, selected } = this.state;

        const value = selected[index];

        if (mode === SliderModes.Index) {
            return values[index];
        }
        // prettier-ignore
        return min + ((value / 100) * (max - min));
    };

    getThumbLabel = (index) => {
        const { thumbLabels } = this.props;

        return thumbLabels[index];
    };

    addToValue = (value, direction = 1) => {
        const { mode } = this.state;
        const { values, step } = this.props;

        if (mode === SliderModes.Index) {
            return clamp(value + direction, 0, values.length - 1);
        }
        if (mode === SliderModes.Fluid) {
            return clamp(value + direction, 0, 100);
        }
        if (mode === SliderModes.Step) {
            return clamp(value + parseInt(step * direction, 10), 0, 100);
        }

        return value;
    };

    updateState(event, index, newIndex) {
        const { mode } = this.state;
        const { values, min, max } = this.props;

        const newSelected = [...this.state.selected];

        newSelected[index] = newIndex;

        this.setState({
            selected: newSelected,
        });

        if (typeof this.props.onChange === 'function') {
            if (mode === SliderModes.Index) {
                this.props.onChange(newSelected.map((value) => values[value]));
            } else {
                // prettier-ignore
                this.props.onChange(newSelected.map(value => min + ((value / 100) * (max - min))));
            }
        }
    }

    render() {
        const { disabled, tabIndex, values, min, max, className } = this.props;

        const { action, actionIndex, selected, mode } = this.state;

        // Gets the selected values
        const minSelected = selected[0];
        const maxSelected = selected[1];

        const valueMin = mode === SliderModes.Index ? 0 : min;
        const valueMax = mode === SliderModes.Index ? values.length - 1 : max;

        // Calculate inline track and thumb styles
        const inlineTrackStyle = {};
        const inlineThumbStyle = [];
        if (mode === SliderModes.Index) {
            inlineTrackStyle.left = `calc((((100% - 44px) / ${valueMax}) * ${minSelected}) + 33px)`;
            inlineTrackStyle.width = `calc(((((100% - 44px) / ${valueMax}) * ${
                maxSelected - minSelected
            })) - 22px)`;

            selected.forEach((index) =>
                inlineThumbStyle.push({
                    left: `calc(((100% - 44px) / ${valueMax}) * ${index})`,
                })
            );
        } else {
            inlineTrackStyle.left = `calc(((100% - 44px) * ${minSelected / 100}) + 33px)`;
            inlineTrackStyle.width = `calc(((100% - 44px) * ${
                (maxSelected - minSelected) / 100
            }) - 22px)`;

            selected.forEach((index) =>
                inlineThumbStyle.push({
                    left: `calc((100% - 44px) * ${index / 100})`,
                })
            );
        }

        return (
            <WrapperStyled
                className={className}
                ref={(ref) => {
                    this.containerRef = ref;
                }}
            >
                <TrackWrapperStyled onClick={(event) => !disabled && this.onClick(event)}>
                    <TrackStyled style={inlineTrackStyle} />
                </TrackWrapperStyled>

                {selected.map((value, index) => {
                    const key = `thumb-${index}`;
                    // Calculate Aria value, min, max

                    let ariaMin;
                    let ariaMax;
                    if (index === 0) {
                        ariaMin = valueMin;
                        ariaMax = this.getValue(1);
                    } else {
                        ariaMin = this.getValue(0);
                        ariaMax = valueMax;
                    }

                    let ariaValue = value;
                    if (mode !== SliderModes.Index) {
                        // prettier-ignore
                        ariaValue = valueMin + ((minSelected / 100) * (valueMax - valueMin));
                    }

                    return (
                        <ThumbStyled
                            key={key}
                            role="slider"
                            aria-valuenow={ariaValue}
                            aria-valuemin={ariaMin}
                            aria-valuemax={ariaMax}
                            aria-valuetext={this.getValueText(index)}
                            aria-label={this.getThumbLabel(index)}
                            aria-disabled="false"
                            aria-orientation="horizontal"
                            action={actionIndex === index ? action : ActionTypes.None}
                            // prettier-ignore
                            tabIndex={disabled ? -1 : (tabIndex || 0)}
                            percentage={mode !== SliderModes.Index}
                            onBlur={(event) => !disabled && this.onBlur(event, index)}
                            onFocus={(event) => !disabled && this.onFocus(event, index)}
                            onKeyDown={(event) => !disabled && this.onKeyDown(event, index)}
                            onMouseDown={(event) => !disabled && this.onMouseDown(event, index)}
                            onTouchStartCapture={(event) =>
                                !disabled && this.onTouchStart(event, index)
                            }
                            onTouchMove={(event) => !disabled && this.onMouseMove(event, index)}
                            style={inlineThumbStyle[index]}
                        />
                    );
                })}
            </WrapperStyled>
        );
    }
}

RangeSlider.defaultProps = {
    thumbLabels: ['Minimum value', 'Maximum value'],
};

export default RangeSlider;
