/* eslint-disable import/prefer-default-export */
const PAGE_ID = 'ListingDetail';
const SHOW_DOWNLOAD_APP_MODAL_AFTER = 'showDownloadAppModalAfter';
const LAST_SEEN_APP_MODAL_DEFAULT = '2022-10-17T00:00:00.000Z';
const DEFAULT_APP_MODAL_RELIEF_DURATION = 7;
const REDIRECT_MESSAGE_TYPES = { LISTING_ERROR: 'listingError' };

export {
    PAGE_ID,
    SHOW_DOWNLOAD_APP_MODAL_AFTER,
    LAST_SEEN_APP_MODAL_DEFAULT,
    DEFAULT_APP_MODAL_RELIEF_DURATION,
    REDIRECT_MESSAGE_TYPES,
};
