import { takeEvery, all, select } from 'redux-saga/effects';
import { track, Events } from 'utils/analytics';
import * as actions from './actions';
import { QUERY_STRING_KEY } from './constants';
import { getExperimentsFromParams } from './utils';

export function* handleInitialize() {
    const { search } = yield select((state) => state.router.location);
    const params = new URLSearchParams(search);

    if (!params.has(QUERY_STRING_KEY)) {
        return;
    }

    const qs = params.get(QUERY_STRING_KEY);

    const experiments = getExperimentsFromParams(qs);

    if (experiments) {
        Object.entries(experiments).forEach(([key, value]) => {
            track(Events.VIEWED_EXPERIMENTAL_FEATURE.create(key, value));
        });
    }
}

export default function* sagas() {
    yield all([takeEvery(actions.initialize.ACTION, handleInitialize)]);
}
