// eslint-disable-next-line import/prefer-default-export
export const OWNER_PUBLISHED_LISTING = {
    create(listingId) {
        return {
            type: 'Owner Published Listing',
            payload: {
                listingId,
            },
        };
    },
};
