import { v2ApiUrl, v3ApiUrl } from './constants';

export default {
    // GET, POST: Medias
    _: `${v3ApiUrl}medias/`,

    // PUT: Media
    mediaUpdate: `${v2ApiUrl}media/:mediaType:/`,

    // GET, DELETE: Media
    media: `${v2ApiUrl}media/:mediaType:/:mediaId:/`,

    // POST: Upload media chunks
    upload: `${v2ApiUrl}media/upload/:mediaType:/`,

    // GET: Get new upload token
    token: `${v2ApiUrl}media/token/`,
};
