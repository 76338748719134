import { animalMapping } from './common';
import { getSitter } from './profiles';
import { getOwner } from './listings';
import { dateSameOrAfter } from '../format/date';

/**
 * User
 * @param contact
 * @returns {*}
 */
export const getContact = (contact) => {
    if (!contact) return null;

    const {
        id,
        firstName,
        profilePhoto,
        isActive,
        isExpired,
        isRedacted,
        expiryDate,
        membershipType,
    } = contact;

    let contactIsActive = isActive;
    if (isActive === null || isActive === undefined) {
        if (expiryDate) {
            contactIsActive = dateSameOrAfter(expiryDate, new Date().setHours(0, 0, 0, 0));
        } else {
            contactIsActive = true;
        }
    }

    return {
        contactId: id,
        contactName: firstName,
        contactAvatarPhoto: profilePhoto,
        contactIsActive,
        contactIsExpired: isExpired || false,
        contactIsRedacted: isRedacted || false,
        contactMembershipType: membershipType,
    };
};

/**
 * Messages
 * @param message
 * @returns {*}
 */
// eslint-disable-next-line import/prefer-default-export
export const getMessage = (message) => {
    if (!message) return message;

    const { id, profile, listing, lastMessage, newMessagesCount } = message;

    let data = {
        id,
        profile,
        listing,
        lastMessage,
        newMessagesCount,
    };

    if (profile) {
        const sitterData = getSitter(profile.user);
        data = {
            ...data,

            // profile
            profileId: profile.id,
            profileLocation: profile.location,
            profileName: profile.name,
            profileVerificationLevel: profile.verificationLevel,
            ...(sitterData || {}),
        };
    }

    if (listing) {
        const {
            id: listingId,
            user,
            location,
            mainPhoto,
            photos: listingPhotos,
            animals,
        } = listing || {};

        let listingMainPhoto = mainPhoto;
        if (!listingMainPhoto && listingPhotos && listingPhotos.length > 0) {
            listingMainPhoto = listingPhotos[0];
        }

        const ownerData = getOwner(user);
        data = {
            ...data,

            // owner
            ...(ownerData || {}),
            listingId,
            listingLocation: location,
            listingMainPhoto,
            listingAnimals: (animals || []).map((animal) => ({
                ...animal,
                slug: animalMapping(animal.slug),
            })),
        };
    }

    return data;
};

export const getArchiveMessage = (message) => {
    if (!message) return message;

    const {
        membershipType,
        body,
        archived,
        profileId,
        senderId,
        listingId,
        contactId,
        sentBy,
        isRead,
        contact,
        hasOwnerRemoved,
        hasSitterRemoved,
        recipientId,
        hasSitterArchived,
        isArchived,
        sendDate,
        sendDateUtc,
        hasOwnerArchived,
        type,
        id,
        subType,
    } = message;

    let data = {
        membershipType,
        body,
        archived,
        profileId,
        senderId,
        listingId,
        contactId,
        sentBy,
        isRead,
        hasOwnerRemoved,
        hasSitterRemoved,
        recipientId,
        hasSitterArchived: hasSitterArchived === undefined ? isArchived : hasSitterArchived,
        sendDate,
        sendDateUtc,
        hasOwnerArchived: hasOwnerArchived === undefined ? isArchived : hasOwnerArchived,
        type,
        id,
        subType,
    };

    // User data
    const contactData = getContact(contact);
    if (contactData) {
        data = {
            ...data,
            ...contactData,
        };
    }

    return data;
};
