/* eslint-disable import/prefer-default-export */

// Use these to check completion of individual fields
import { AnimalId } from './search/constants';

const IS_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY = 'isAccessibleByPublicTransport';
const IS_NOT_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY = 'isNotAccessibleByPublicTransport';

// About You
const introductionIsEmpty = (listing) => listing.introduction.length === 0;
const profilePhotoIsEmpty = (listing) => {
    const { user = {} } = listing;
    const { profilePhoto = null } = user;
    return !listing.ownerAvatarPhoto && profilePhoto === null;
};

const introductionIsValid = (listing) => listing.introduction.length >= 50;

// Location
const locationIsEmpty = (listing) => listing.location.name === undefined;
const addressIsEmpty = (address) => !address || (address && !address.city);
const localAttractionsIsEmpty = (listing) => listing.localAttractions.length === 0;
const publicTransportHomeFeatureIsEmpty = (homeFeatures) =>
    !homeFeatures ||
    homeFeatures.length === 0 ||
    (!homeFeatures.includes(IS_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY) &&
        !homeFeatures.includes(IS_NOT_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY));

/**
 * The 'hasOwnProperty' check below is required because the web uses these helpers with a raw, untransformed listing,
 * while the app uses the api-clients transformed version. The raw object has an 'isAccessibleByPublicTransport' property
 * on the top level that has three possible values, null, true, and false. The transformed object has a 'homeFeatures'
 * property which is an array of strings that can include a value related to public transport:
 * null  = no string is added to the array
 * true  = 'isAccessibleByPublicTransport' is added to the array
 * false = 'isNotAccessibleByPublicTransport' is added to the array
 */
const publicTransportIsEmpty = (listing) => {
    if (Object.prototype.hasOwnProperty.call(listing, 'isAccessibleByPublicTransport')) {
        return listing.isAccessibleByPublicTransport === null;
    }
    return publicTransportHomeFeatureIsEmpty(listing.homeFeatures);
};

// Home
const photosIsEmpty = (listing) => listing.photos.length === 0;
const photosIsLessThanFive = (listing) => listing.photos.length < 5;
const featuresIsEmpty = (listing) => listing.features.length === 0;
const wifiAvailableIsEmpty = (listing) => listing.wifiAvailable === null;
const homeTypeIsEmpty = (listing) => listing.homeType === null;
const disabledAccessIsEmpty = (listing) => listing.disabledAccess === null;
const petsWelcomeIsEmpty = (listing) => listing.petsWelcome == null;
const suitableForHomeWorkingIsEmpty = (listing) => listing.suitableForHomeWorking == null;

const featuresIsValid = (listing) => listing.features.length >= 50;

// Pets

const petsIsEmpty = (pets) => pets.length === 0;

const hasPetRequiredFields = (animalId) => {
    const id = parseInt(animalId, 10);
    return id !== AnimalId.POULTRY && id !== AnimalId.FISH && id !== AnimalId.FARMANIMAL;
};

const petsIsValid = (pets) => {
    let isValid = true;
    pets.forEach((pet) => {
        if (hasPetRequiredFields(pet.animalId)) {
            if (!pet.name) {
                isValid = false;
            }
            if (!pet.photos || (pet.photos && pet.photos.length < 2)) {
                isValid = false;
            }
        }
    });
    return isValid;
};

const petsIsComplete = (pets) => {
    let isComplete = true;
    pets.forEach((pet) => {
        if (hasPetRequiredFields(pet.animalId)) {
            // required fields
            if (!pet.name) {
                isComplete = false;
            }
            if (!pet.photos || (pet.photos && pet.photos.length < 2)) {
                isComplete = false;
            }
            // recommended fields
            if (pet.requiresRegularMedication === null) {
                isComplete = false;
            }
            // dogs have additional recommended fields
            if (pet.id === AnimalId.DOG) {
                if (pet.size === null) {
                    isComplete = false;
                }
                if (pet.dailyActivityMinutes === null) {
                    isComplete = false;
                }
            }
        }
    });
    return isComplete;
};

// Sitters & responsibilities
const responsibilitiesIsEmpty = (listing) => listing.responsibilities.length === 0;
const wishToVideoCallIsEmpty = (listing) => listing.wishToVideoCall == null;
const wishToMeetInPersonIsEmpty = (listing) => listing.wishToMeetInPerson == null;
const minutesPetCanBeLeftAloneIsEmpty = (listing) => listing.minutesPetCanBeLeftAlone == null;
const childrenPreferencesIsEmpty = (listing) => {
    const isFamilyPreferencesEmptyOrDefault =
        listing.familyPreferences == null || listing.familyPreferences.length === 3;
    const areChildrenPreferencesEmpty =
        listing.childrenPreferences == null || listing.childrenPreferences.length === 0;

    return isFamilyPreferencesEmptyOrDefault && areChildrenPreferencesEmpty;
};

const responsibilitiesIsValid = (listing) => listing.responsibilities.length >= 50;
const childrenPreferencesIsValid = (listing) => {
    const isFamilySelected = (listing.familyPreferences ?? []).includes('family');
    const childrenPreferences = listing.childrenPreferences ?? [];

    // The "childrenPreferences" field is required when "family" is selected in "familyPreferences"
    if (isFamilySelected && childrenPreferences.length === 0) {
        return false;
    }

    return true;
};

// Title
const titleIsEmpty = (listing) => listing.title.length === 0;

// Dates
const datesIsEmpty = (dates) => dates.length === 0;

export const sectionStatus = {
    // 100% completed
    isCompleted: 'isCompleted',
    // All required fields filled
    isIncomplete: 'isIncomplete',
    // Nothing in any field
    isEmpty: 'isEmpty',
    // Some info saved but required fields invalid
    isMissingRequired: 'isMissingRequired',
    // Some custom warning about listing. Must add customWarningText to SectionEditBlock
    isCustomWarning: 'isCustomWarning',
};

export const listingStatus = {
    // Missing required fields before listing can be published
    isMissingRequired: 'isMissingRequired',
    // All required fields completed but missing dates
    isMissingDates: 'isMissingDates',
    // All required fields and dates completed but not sent for review
    isUnpublished: 'isUnpublished',
    // In review with MS and has dates
    isInReviewDates: 'isInReviewWithDates',
    // In review with MS but has no dates
    isInReviewNoDates: 'isInReviewNoDates',
    // Has been approved and has dates, therefore is live
    isReviewedDates: 'isReviewedDates',
    // Has been approved but has no dates
    isReviewedNoDates: 'isReviewedNoDates',
    // Has no data entered yet at all
    isEmpty: 'isEmpty',
};

// About You section

const aboutYouCheck = {
    isEmpty: (listing) => introductionIsEmpty(listing) && profilePhotoIsEmpty(listing),
    isMissingRequired: (listing) =>
        profilePhotoIsEmpty(listing) ||
        (!introductionIsEmpty(listing) && !introductionIsValid(listing)),
    isCompleted: (listing) => introductionIsValid(listing) && !profilePhotoIsEmpty(listing),
};

const getAboutStatus = (listing) => {
    if (aboutYouCheck.isEmpty(listing)) return sectionStatus.isEmpty;
    if (aboutYouCheck.isMissingRequired(listing)) return sectionStatus.isMissingRequired;
    if (aboutYouCheck.isCompleted(listing)) return sectionStatus.isCompleted;
    return sectionStatus.isEmpty;
};

// Location section

const locationCheck = {
    isEmpty: (listing, address) =>
        locationIsEmpty(listing) &&
        addressIsEmpty(address) &&
        localAttractionsIsEmpty(listing) &&
        publicTransportIsEmpty(listing),
    isMissingRequired: (listing, address) => locationIsEmpty(listing) || addressIsEmpty(address),
    isIncomplete: (listing, address) =>
        !locationIsEmpty(listing) &&
        !addressIsEmpty(address) &&
        (localAttractionsIsEmpty(listing) || publicTransportIsEmpty(listing)),
    isCompleted: (listing, address) =>
        !locationIsEmpty(listing) &&
        !addressIsEmpty(address) &&
        !localAttractionsIsEmpty(listing) &&
        !publicTransportIsEmpty(listing),
};

const getLocationStatus = (listing, address) => {
    if (locationCheck.isEmpty(listing, address)) return sectionStatus.isEmpty;
    if (locationCheck.isMissingRequired(listing, address)) return sectionStatus.isMissingRequired;
    if (locationCheck.isIncomplete(listing, address)) return sectionStatus.isIncomplete;
    if (locationCheck.isCompleted(listing, address)) return sectionStatus.isCompleted;
    return sectionStatus.isEmpty;
};

// Home section

export const isPhotoRequired = (listing) =>
    photosIsLessThanFive(listing) && !listing.isApproved && !listing.inReview;
// Listings already live/approved have the listing photo limit of 5 as recommended and not required
export const isPhotoRecommended = (listing) =>
    photosIsLessThanFive(listing) && (listing.isApproved || listing.inReview);

export const isAmenitiesEmpty = (status) => (status ? status === sectionStatus.isEmpty : true);
export const isAmenitiesCompleted = (status) =>
    status ? status === sectionStatus.isCompleted : false;

const homeCheck = (amenitiesStatus) => ({
    isEmpty: (listing) =>
        photosIsEmpty(listing) &&
        featuresIsEmpty(listing) &&
        homeTypeIsEmpty(listing) &&
        disabledAccessIsEmpty(listing) &&
        isAmenitiesEmpty(amenitiesStatus) &&
        petsWelcomeIsEmpty(listing) &&
        suitableForHomeWorkingIsEmpty(listing),
    isMissingRequired: (listing) => isPhotoRequired(listing) || !featuresIsValid(listing),
    isIncomplete: (listing) =>
        featuresIsValid(listing) &&
        (isPhotoRecommended(listing) ||
            homeTypeIsEmpty(listing) ||
            disabledAccessIsEmpty(listing) ||
            isAmenitiesEmpty(amenitiesStatus) ||
            petsWelcomeIsEmpty(listing) ||
            suitableForHomeWorkingIsEmpty(listing)),
    isCompleted: (listing) =>
        featuresIsValid(listing) &&
        !photosIsLessThanFive(listing) &&
        !homeTypeIsEmpty(listing) &&
        !disabledAccessIsEmpty(listing) &&
        isAmenitiesCompleted(amenitiesStatus) &&
        !petsWelcomeIsEmpty(listing) &&
        !suitableForHomeWorkingIsEmpty(listing),
});

const getHomeStatus = (listing, amenitiesStatus) => {
    const check = homeCheck(amenitiesStatus);

    if (check.isEmpty(listing)) return sectionStatus.isEmpty;
    if (check.isMissingRequired(listing)) return sectionStatus.isMissingRequired;
    if (check.isIncomplete(listing)) return sectionStatus.isIncomplete;
    if (check.isCompleted(listing)) return sectionStatus.isCompleted;

    return sectionStatus.isEmpty;
};

// Pets section

const petCheck = {
    isEmpty: (pets) => petsIsEmpty(pets),
    isMissingRequired: (pets) => !petsIsEmpty(pets) && !petsIsValid(pets),
    isIncomplete: (pets) => !petsIsEmpty(pets) && petsIsValid(pets) && !petsIsComplete(pets),
    isCompleted: (pets) => !petsIsEmpty(pets) && petsIsComplete(pets),
};

const getPetsStatus = (pets = []) => {
    if (petCheck.isEmpty(pets)) return sectionStatus.isEmpty;
    if (petCheck.isMissingRequired(pets)) return sectionStatus.isMissingRequired;
    if (petCheck.isIncomplete(pets)) return sectionStatus.isIncomplete;
    if (petCheck.isCompleted(pets)) return sectionStatus.isCompleted;
    return sectionStatus.isEmpty;
};

// Responsibilities section

const responsibilitiesCheck = {
    isEmpty: (listing) =>
        responsibilitiesIsEmpty(listing) &&
        childrenPreferencesIsEmpty(listing) &&
        wishToVideoCallIsEmpty(listing) &&
        wishToMeetInPersonIsEmpty(listing) &&
        minutesPetCanBeLeftAloneIsEmpty(listing),
    isMissingRequired: (listing) =>
        !responsibilitiesIsValid(listing) || !childrenPreferencesIsValid(listing),
    isIncomplete: (listing) =>
        responsibilitiesIsValid(listing) &&
        childrenPreferencesIsValid(listing) &&
        (wishToVideoCallIsEmpty(listing) ||
            wishToMeetInPersonIsEmpty(listing) ||
            minutesPetCanBeLeftAloneIsEmpty(listing)),
    isCompleted: (listing) =>
        responsibilitiesIsValid(listing) && childrenPreferencesIsValid(listing),
};

const getResponsibilitiesStatus = (listing) => {
    if (responsibilitiesCheck.isEmpty(listing)) return sectionStatus.isEmpty;
    if (responsibilitiesCheck.isMissingRequired(listing)) return sectionStatus.isMissingRequired;
    if (responsibilitiesCheck.isIncomplete(listing)) return sectionStatus.isIncomplete;
    if (responsibilitiesCheck.isCompleted(listing)) return sectionStatus.isCompleted;

    return sectionStatus.isEmpty;
};

// Title section

const titleCheck = {
    isEmpty: (listing) => titleIsEmpty(listing),
    isCompleted: (listing) => !titleIsEmpty(listing),
};

const getTitleStatus = (listing) => {
    if (titleCheck.isEmpty(listing)) return sectionStatus.isEmpty;
    if (titleCheck.isCompleted(listing)) return sectionStatus.isCompleted;
    return sectionStatus.isEmpty;
};

// Dates section

const datesCheck = {
    isEmpty: (dates) => datesIsEmpty(dates),
    isCompleted: (dates) => !datesIsEmpty(dates),
};

const getDatesStatus = (dates) => {
    if (datesCheck.isEmpty(dates)) return sectionStatus.isEmpty;
    if (datesCheck.isCompleted(dates)) return sectionStatus.isCompleted;
    return sectionStatus.isEmpty;
};

const getSafetySecurityStatus = (emergencyContact, safetyContact) => {
    if (!emergencyContact && !safetyContact) {
        return sectionStatus.isEmpty;
    }

    if (!emergencyContact || !safetyContact) {
        return sectionStatus.isIncomplete;
    }

    return sectionStatus.isCompleted;
};

const getListingStatus = (listing, dates, amenitiesStatus) => {
    const { isApproved, inReview } = listing;
    const { isCompleted, isIncomplete } = sectionStatus;
    if (isApproved) {
        if (getDatesStatus(dates) === isCompleted) {
            return listingStatus.isReviewedDates;
        }
        return listingStatus.isReviewedNoDates;
    }
    if (inReview) {
        if (getDatesStatus(dates) === isCompleted) {
            return listingStatus.isInReviewDates;
        }
        return listingStatus.isInReviewNoDates;
    }
    if (
        (getAboutStatus(listing) === isIncomplete || getAboutStatus(listing) === isCompleted) &&
        (getHomeStatus(listing, amenitiesStatus) === isIncomplete ||
            getHomeStatus(listing, amenitiesStatus) === isCompleted) &&
        getPetsStatus(listing.pets) === isCompleted &&
        getResponsibilitiesStatus(listing) === isCompleted &&
        getTitleStatus(listing) === isCompleted
    ) {
        if (getDatesStatus(dates) === isCompleted) {
            return listingStatus.isUnpublished;
        }
        return listingStatus.isMissingDates;
    }
    return listingStatus.isMissingRequired;
};

const getListingIsEmpty = (listing, dates, address, amenitiesStatus) => {
    const { isEmpty } = sectionStatus;

    return (
        getAboutStatus(listing) === isEmpty &&
        getHomeStatus(listing, amenitiesStatus) === isEmpty &&
        getPetsStatus(listing.pets) === isEmpty &&
        getResponsibilitiesStatus(listing) === isEmpty &&
        getTitleStatus(listing) === isEmpty &&
        getLocationStatus(listing, address) === isEmpty &&
        getDatesStatus(dates) === isEmpty
    );
};

// Dashboard

/**
 * This function was created to match the logic of the web and the app
 * in order to avoid this error https://trustedhousesitters.atlassian.net/browse/DM-289
 */
const getListingNeedsMoreInfo = (isIncomplete, inReview, isApproved) =>
    isIncomplete && !inReview && !isApproved;

export {
    IS_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY,
    IS_NOT_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY,
    profilePhotoIsEmpty,
    introductionIsValid,
    locationIsEmpty,
    addressIsEmpty,
    localAttractionsIsEmpty,
    wifiAvailableIsEmpty,
    publicTransportIsEmpty,
    featuresIsValid,
    petsIsEmpty,
    hasPetRequiredFields,
    responsibilitiesIsValid,
    childrenPreferencesIsValid,
    titleIsEmpty,
    getAboutStatus,
    getLocationStatus,
    getHomeStatus,
    getResponsibilitiesStatus,
    getTitleStatus,
    getPetsStatus,
    getDatesStatus,
    getListingStatus,
    getListingIsEmpty,
    getSafetySecurityStatus,
    getListingNeedsMoreInfo,
};
