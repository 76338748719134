export const getResponseStatus = (state) =>
    state?.containers?.safetySecurityPage?.response ?? 'SUCCESS';

export const getUpdateId = (state) => state?.containers?.safetySecurityPage?.updateId ?? 0;

export const getEmergencyContact = (state) =>
    state?.account?.emergencyContacts?.emergencyContact?.data ?? null;

export const isFetchingData = (state) =>
    typeof state?.account?.emergencyContacts?.emergencyContact?.responseStatus !== 'number' ||
    typeof state?.account?.emergencyContacts?.safetyContact?.responseStatus !== 'number';

export const getSafetyContact = (state) =>
    state?.account?.emergencyContacts?.safetyContact?.data ?? null;
