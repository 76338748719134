// eslint-disable-next-line import/prefer-default-export
export const USERACQUISITION = {
    create(type, { membershipPlan, email, onTrial, gbpOrderValue }) {
        let orderId = membershipPlan.transactionId;
        if (!orderId) {
            orderId = `${onTrial ? 'trl_' : ''}membershipId_${membershipPlan.id}`;
        }

        const payload = {
            membershipTier: membershipPlan.tier,
            order_id: orderId,
            email,
            revenue: gbpOrderValue,
        };

        if (membershipPlan.promoCode) {
            payload.coupon = membershipPlan.promoCode;
        }

        return {
            type: `${type} Acquisition`,
            payload,
        };
    },
};
