import * as actions from './actions';

const initialState = {
    prompts: [],
    isHydrated: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.collect.ACTION:
            return {
                prompts: [...(action.buffer ?? [])],
                isHydrated: true,
            };
        case actions.remove.ACTION: {
            return {
                ...state,
                prompts: state.prompts.filter((prompt) => prompt.name !== action.registry.name),
            };
        }
        default:
            return state;
    }
};
