import { settings as actions } from '../../../actions';

export const initialState = {};

// eslint-disable-next-line consistent-return
function addMessage(messages, message) {
    if (!message) return message;
    if (message.sentBy === 'me') {
        messages[message.recipientId] = message;
    }
    else {
        messages[message.senderId] = message;
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadApplications.SUCCESS:
        case actions.sitter.loadInvitations.SUCCESS:
        case actions.sitter.loadSitterOwnerApplications.SUCCESS:
        case actions.sitter.loadSitterOwnerInvitations.SUCCESS:
        case actions.sitter.loadCurrentAssignments.SUCCESS:
        case actions.sitter.loadUpcomingAssignments.SUCCESS:
        case actions.sitter.loadPastAssignments.SUCCESS: {
            const messages = {};
            action.data.results.forEach((item) => {
                addMessage(messages, item.lastMessage);
            });
            return {
                ...state,
                ...messages,
            };
        }
        case actions.sitter.loadConfirmedAssignments.SUCCESS: {
            const messages = {};
            if (action.data.current) {
                addMessage(messages, action.data.current.lastMessage);
            }
            action.data.past.forEach((item) => {
                addMessage(messages, item.lastMessage);
            });
            action.data.upcoming.forEach((item) => {
                addMessage(messages, item.lastMessage);
            });
            return {
                ...state,
                ...messages,
            };
        }
        case actions.sitter.loadConversations.SUCCESS: {
            if (action.data.results.length > 0) {
                const messages = {};
                addMessage(messages, action.data.results[0]);
                return {
                    ...state,
                    ...messages,
                };
            }
            return state;
        }
        case actions.sitter.createConversation.SUCCESS:
            return {
                ...state,
                [action.data.ownerId]: action.data,
            };
        case actions.sitter.loadApplication.SUCCESS:
        case actions.sitter.loadInvitation.SUCCESS:
        case actions.sitter.declineInvitation.SUCCESS:
        case actions.sitter.cancelApplication.SUCCESS:
        case actions.sitter.confirmApplication.SUCCESS:
        case actions.sitter.declineSitConfirmation.SUCCESS:
        case actions.sitter.confirmAssignmentDateChange.SUCCESS:
        case actions.sitter.declineAssignmentDateChange.SUCCESS:
        case actions.sitter.confirmInvitation.SUCCESS:
        case actions.sitter.loadAssignment.SUCCESS: {
            const messages = {};
            addMessage(messages, action.data.lastMessage);
            return {
                ...state,
                ...messages,
            };
        }
        case actions.sitter.archiveConversations.SUCCESS:
        case actions.sitter.batchArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const messages = {};
            ids.forEach((id) => {
                messages[id] = {
                    ...(state[id] || {}),
                    isArchived: true,
                };
            });
            return {
                ...state,
                ...messages,
            };
        }
        case actions.sitter.unArchiveConversations.SUCCESS:
        case actions.sitter.batchUnArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const messages = {};
            ids.forEach((id) => {
                messages[id] = {
                    ...(state[id] || {}),
                    isArchived: false,
                };
            });
            return {
                ...state,
                ...messages,
            };
        }
        default:
            return state;
    }
};
