import { getAccount, getAccountCurrentMembershipPlan } from 'api/selectors';
import { getLocale } from 'shared/selectors';
import { features, isFeatureEnabled } from 'components/Feature';
import {
    getMembershipTier,
    getMembershipType,
    isPartial,
    isExpired,
    isAutorenewing,
    getIsMembershipActive,
} from 'utils/account';
import { MembershipType } from 'api/types';

export const getRegwall = (state) => state.containers.plansPage.regwall;

// Get the currency code
export const getCurrencyCode = (state) => {
    // If user has a plan use that currency code
    const account = getAccount(state);

    if (account.incompleteMembershipPlan.currencyCode) {
        return account.incompleteMembershipPlan.currencyCode;
    }

    if (account.membershipPlan.currencyCode) {
        return account.membershipPlan.currencyCode;
    }

    // Has the user changed their currency
    if (state.containers.plansPage.currencyCode) {
        return state.containers.plansPage.currencyCode;
    }

    // Has the user changed their currency from angular?
    const locale = global.localStorage && global.localStorage.getItem('ths-local-currency');
    if (locale) {
        const localeObj = JSON.parse(locale);
        return localeObj.currencyCode;
    }

    // Default use is the user's GeoIP currency
    return getLocale(state).currency.currencyCode;
};

export const getMembershipTypeQueryString = (state) =>
    state.containers.plansPage.membershipTypeQueryString;

export const getHideTabs = (state) => state.containers.plansPage.hideTabs;

export const getHasPendingPlanChange = (state) => state.containers.plansPage.hasPendingPlanChange;

export const getPlanTypeVisible = (state) => state.containers.plansPage.planTypeVisible;

export const getPlanFromUrl = (state) => state.containers.plansPage.planFromUrl;

export const getFreeTrial = (state) => state.containers.plansPage.freeTrial;

export const getFinishedAccountLoadingState = (state) =>
    state.containers.plansPage.finishedLoadingAccountData;

export const getProration = (state) => {
    const currentMembership = getAccountCurrentMembershipPlan(state);

    const currentMembershipTier = getMembershipTier(currentMembership);
    const currentMembershipType = getMembershipType(currentMembership);
    const userIsPartial = isPartial(currentMembership);
    const userIsExpired = isExpired(currentMembership);
    const userIsAutorenwing = isAutorenewing(currentMembership);

    const proration = {
        enabled: !!(currentMembershipType && !userIsPartial && !userIsExpired),
        currentMembershipTier,
        currentMembershipType,
        isAutorenewing: userIsAutorenwing,
    };

    return {
        ...proration,
    };
};

const getTabQueryParam = (state) => {
    const URLParams = new URLSearchParams(state.router.location.search);
    const queryParamTab = URLParams.get('tab');

    return queryParamTab;
};

export const getTabs = (state) => {
    const validTabTypes = [
        {
            type: MembershipType.OWNER,
            label: 'web_membershipTypeDescription_homeowner',
        },
        {
            type: MembershipType.SITTER,
            label: 'web_membershipTypeDescription_housesitter',
        },
        {
            type: MembershipType.COMBINED,
            label: 'web_membershipTypeDescription_combined',
        },
    ];

    const currentMembership = getAccountCurrentMembershipPlan(state);
    const isACombinedMember =
        currentMembership && currentMembership.membershipType === MembershipType.COMBINED;
    const queryParamTab = getTabQueryParam(state);
    const proration = getProration(state);

    // show specific tabs for prorated members
    if (proration.enabled) {
        // return tabs based on the membership type in the users plan and the combined type
        // as long as the user is not a combined member
        if (!queryParamTab && !isACombinedMember) {
            const paidMemberTabs = validTabTypes.filter(
                (tab) =>
                    currentMembership.membershipType === tab.type ||
                    MembershipType.COMBINED === tab.type
            );

            return paidMemberTabs;
        }
        // return tabs based on what was passed in the query param '?tab='
        // also makes sure that the passed param is of a valid type
        if (queryParamTab) {
            const membershipType = getMembershipType(currentMembership);

            let validQueryParamTabs = validTabTypes;

            if (!isACombinedMember) {
                // have a failsafe to always return users plan type and a combined type
                // as long as the user is not combined
                validQueryParamTabs = validTabTypes.filter(
                    (tab) => membershipType === tab.type || MembershipType.COMBINED === tab.type
                );
            }

            // return the one tab if the query param matches the users type
            if (queryParamTab === membershipType) {
                validQueryParamTabs = validTabTypes.filter((tab) => membershipType === tab.type);
            }

            return validQueryParamTabs;
        }
    }

    // otherwise return all three for anon/partial/combined members
    return validTabTypes;
};

// What plan type should be visible on page load? Check current plan and local storage
export const getInitialPlanTypeVisible = (state) => {
    const account = getAccount(state);
    const membershipTypeQueryString = getMembershipTypeQueryString(state);
    const currentMembershipType = getMembershipType(account.membershipPlan);
    const incompleteMembershipPlanType = getMembershipType(account.incompleteMembershipPlan);
    const planTypeVisible = getPlanTypeVisible(state);
    const isActive = getIsMembershipActive(account.membershipPlan);

    // Check in local storage for original membership plan
    const originalMembershipLocalStorageItem =
        global.localStorage && global.localStorage.getItem('ths-original-membership-plan');

    let originalMembershipType;

    if (originalMembershipLocalStorageItem) {
        const originalMembership = JSON.parse(originalMembershipLocalStorageItem);
        originalMembershipType = originalMembership.membershipType;
    }

    // we hide tabs based on things like membership type and proration
    // if the tab type from the query string is hidden we want to skip selecting it
    const tabsVisible = getTabs(state);
    const isMembershipTypeQueryStringVisible = !!tabsVisible.filter(
        (tab) => tab.type === membershipTypeQueryString
    ).length;

    // Plan type in the query string is top trump
    if (membershipTypeQueryString && isMembershipTypeQueryStringVisible) {
        return membershipTypeQueryString;
    }
    // If they are a partial or expired with an incomplete membership, use their incomplete type
    if (incompleteMembershipPlanType && !isActive) {
        return incompleteMembershipPlanType;
    }
    // If they have a current plan then use it
    if (currentMembershipType) {
        return currentMembershipType;
    }
    // if they already have a value set in planTypeVisible (mostly for anon users)
    if (planTypeVisible) {
        return planTypeVisible;
    }
    // If we don't have a membership in props check if we have one
    // in local storage (from angular)
    if (originalMembershipType) {
        return originalMembershipType;
    }
    // Else show owner
    return MembershipType.OWNER;
};
