/**
 * State of the thumb
 *
 *  - None: default state
 *  - Moving: moving thumb using mouse/touch move
 *  - Selected: set when clicked/touched on the thumb
 *  - Click: when clicked on the track
 *  - Focused: when thumb is focused on
 *
 * @type {{None: string, Moving: string, Selected: string, Click: string, Focused: string}}
 */
export const ActionTypes = {
    None: 'none',
    Moving: 'moving',
    Selected: 'selected',
    Click: 'click',
    Focused: 'focused',
};

/**
 * Define different types of the slider
 *
 *  - Fluid: Given min and max are provided, selected values are calculated based of the percentage
 *  - Step: Given min and max and step are provided, selected values are calculated based on the steps made
 *  - Index: Given values arrays is provided, selected value is calculated based of the index of the element
 *
 * @type {{Fluid: string, Step: string, Index: string}}
 */
export const SliderModes = {
    Fluid: 'fluid',
    Step: 'step',
    Index: 'index',
};
