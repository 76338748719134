import loadable from '@loadable/component';
import { connect } from 'react-redux';
import {
    getOwnerListingOpenAssignmentsAscending,
    getOwnerListing,
    getAccount,
} from 'api/selectors';
import { retry } from 'utils/ssr';
import { isPremium, isStandard } from 'utils/account';
import asPage from 'containers/Page';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { openOutsideSubscriptionModal } from 'containers/OutsideSubscriptionModal/actions';
import { getShowOutsideSubscriptionModal } from 'containers/OutsideSubscriptionModal/selectors';
import { requestStatus } from 'api/selectors/request';
import { settings } from 'api/actions';
import { saveDates, deleteDate, boostAssignment } from './actions';
import { PAGE_ID } from './Dates.constants';
import {
    getResponseStatus,
    getUpdateId,
    getBoostedAssignmentResponseStatusCode,
    getBoostedAssignmentResponseTimestamp,
    getLoadOpenAssignmentsUpdateId,
} from './selectors';
import { getListingConfirmedAssignments } from '../selectors';

const LoadableDates = loadable(() => retry(() => import('./Dates')));

const mapStateToProps = (state, props) => {
    const {
        match: {
            params: { listingId },
        },
    } = props;

    const { membershipPlan } = getAccount(state);

    const { status: enableAutorenewalStatus, timestamp: enableAutorenewalTimestamp } =
        requestStatus({
            state,
            actionSettings: settings.account.enableSubscriptionAutorenew,
        }) || {};

    return {
        responseStatus: getResponseStatus(state),
        listing: getOwnerListing(state, listingId),
        updateId: getUpdateId(state),
        boostedAssignmentResponseStatusCode: getBoostedAssignmentResponseStatusCode(state),
        boostedAssignmentResponseTimestamp: getBoostedAssignmentResponseTimestamp(state),
        loadOpenAssignmentsUpdateId: getLoadOpenAssignmentsUpdateId(state),
        openAssignments: getOwnerListingOpenAssignmentsAscending(state, listingId),
        confirmedAssignments: getListingConfirmedAssignments(state, listingId),
        isPremium: isPremium(membershipPlan),
        isStandard: isStandard(membershipPlan),
        isViewingInAppWebView: isViewingInAppWebView(state),
        expiryDate: membershipPlan?.expiryDate,
        isAutorenewing: membershipPlan?.isAutorenewing,
        hasSubscriptionId: membershipPlan?.hasSubscriptionId,
        isOutsideSubscriptionModalOpen: getShowOutsideSubscriptionModal(state),
        enableAutorenewalStatus,
        enableAutorenewalTimestamp,
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveAssignments: (newDates, updatedDates) => {
        dispatch(saveDates.create(newDates, updatedDates));
    },
    deleteAssignment: (assignment) => {
        dispatch(deleteDate.create(assignment));
    },
    boostAssignment: (assignment) => {
        dispatch(boostAssignment.create(assignment));
    },
    openOutsideSubscriptionModal: (modalType) =>
        dispatch(openOutsideSubscriptionModal.create(modalType)),
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(LoadableDates, PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
