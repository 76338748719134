import React from 'react';

import { useRating } from 'components/Rating/context';
import { StyledButton } from './Button.style';

function Button({ rating, shouldAnimate, children, onClick, ...props }) {
    const { updateHoveredRating, updateSelectedRating } = useRating();

    const handleMouseHover = () => {
        updateHoveredRating(rating);
    };

    const handleClick = () => {
        updateSelectedRating(rating);
        onClick(rating);
    };

    return (
        <StyledButton
            type="button"
            data-should-animate={shouldAnimate}
            onClick={handleClick}
            onMouseEnter={handleMouseHover}
            {...props}
        >
            {children}
        </StyledButton>
    );
}

Button.displayName = 'RatingButton';

export default Button;
