const createAction = (prefix, name = prefix, ...argNames) => {
    const type = `${prefix}/${name}`;

    return {
        ACTION: type,
        create: (...args) => {
            const action = { type };
            argNames.forEach((arg, index) => {
                action[argNames[index]] = args[index];
            });

            return action;
        },
    };
};

export { createAction };

export default createAction;
