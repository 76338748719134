import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const CounterDisplayStyled = styled.div`
    display: grid;
    grid-template-columns: 28px 24px 28px;
    grid-template-areas: 'decrement count increment';
    gap: 2px;
    align-items: center;
`;
