import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

// FIXME: This reducer pretends it will have the shape of basePaginatedInitialResultListState
//  but what the api returns looks nothing like that, and the cases that are handled do nothing to preserve the shape.
import {
    basePaginatedInitialResultListState,
    loadedPaginatedResultsUniqueListReducer,
} from '../common';

import { uniqueArray } from '../../helpers/format/objects';

export const initialState = { ...basePaginatedInitialResultListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadConfirmedAssignments.SUCCESS: {
            return {
                ...initialState,
                count: state.count + action.data.past.length,
                total: action.data.past.length,
                results: action.data.past.map((assignment) => assignment.id),
            };
        }
        case actions.owner.loadPastAssignments.SUCCESS: {
            return loadedPaginatedResultsUniqueListReducer(state, action);
        }
        case actions.owner.loadAssignment.SUCCESS: {
            if (transform.isPastAssignment(action.data)) {
                if (state.results.indexOf(action.data.id) < 0) {
                    return {
                        ...state,
                        results: uniqueArray([...state.results, action.data.id]),
                    };
                }
            } else if (state.results.indexOf(action.data.id) >= 0) {
                return {
                    ...state,
                    results: state.results.filter((itemId) => itemId !== action.data.id),
                };
            }
            return state;
        }
        default:
            return state;
    }
};
