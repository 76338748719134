import styled from 'styled-components';
import { CheckboxGroup, PasswordField, ErrorMessage } from 'components/forms';
import CheckBoxButton from 'components/forms/CheckBoxButton/CheckBoxButton';

const ErrorMessageStyled = styled(ErrorMessage)`
    width: 100%;
`;

const CheckboxGroupStyled = styled(CheckboxGroup)`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${({ theme: { screenSize } }) => `
        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            justify-content: space-between;
            flex-direction: row;
        }
    `}
`;

const CheckboxButtonStyled = styled(CheckBoxButton)`
    ${({ theme: { screenSize, spacing } }) => `
        width: 100%;
        margin-top: ${spacing[2]};
        margin-bottom: ${spacing[2]};
        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            width: 164px;
            margin-top: 0px;
            label {
                display: flex;
                justify-content: center;
            }
        }
    `}
`;

export { CheckboxButtonStyled, CheckboxGroupStyled, ErrorMessageStyled };
