/* eslint-disable import/prefer-default-export */

const defaultLocation = {
    id: 'anywhere',
    name: 'Anywhere',
};

const defaultSuggestions = [
    defaultLocation,
    {
        id: '6255146',
        name: 'Africa',
        slug: 'africa',
        asciiName: 'Africa',
        alternateNames:
            'Aafrika,Affrica,Africa,Afrihkka,Afrihkká,Afrika,Afrikka,Afriko,Afrique,Afryka,Afríka,Aphrike,Chau Phi,Châu Phi,afrika,afryqa,afryqya,afurika,an Afraic,apeulika,el Continente Negro,fei zhou,Àfrica,África,Āfrika,Αφρική,Африка,אפריקה,أفريقيا,افریقا,अफ़्रीका,แอฟริกา,アフリカ,非洲,아프리카',
        location: {
            lat: 7.1881,
            lon: 21.09375,
        },
        featureClass: 'L',
        featureCode: 'CONT',
        continentCode: 'AF',
        continentName: 'Africa',
        continentSlug: 'africa',
        countryCode: '',
        countryIso3Code: '',
        countryFipsCode: '',
        countryName: '',
        countrySlug: '',
        cc2: '',
        admin1Code: '',
        admin1Name: '',
        admin1Slug: '',
        admin2Code: '',
        admin2Name: '',
        admin2Slug: '',
        admin3Code: '',
        admin3Name: '',
        admin3Slug: '',
        admin4Code: '',
        admin4Name: '',
        admin4Slug: '',
        population: 1031833000,
        elevation: 0,
        dem: '592',
        timezone: 'Africa/Bangui',
        modificationDate: '2016-06-01',
        lowerCaseName: 'africa',
    },
    {
        id: '6255147',
        name: 'Asia',
        slug: 'asia',
        asciiName: 'Asia',
        alternateNames:
            'Aasia,Asia,Asie,Asien,Asya,Asía,Azia,Azie,Azija,Azio,Azië,Azja,Azsia,Chau A,Châu Á,ajia,an Aise,an Áise,asia,asya,ayshya,esiya mahadvipa,xecheiy,ya zhou,Àsia,Ásia,Ázia,Ázsia,Āzija,Ασία,Азия,Азія,אסיה,آسيا,ایشیا,एशिया महाद्वीप,เอเชีย,アジア,亚洲,아시아',
        location: {
            lat: 29.84064,
            lon: 89.29688,
        },
        featureClass: 'L',
        featureCode: 'CONT',
        continentCode: 'AS',
        continentName: 'Asia',
        continentSlug: 'asia',
        countryCode: '',
        countryIso3Code: '',
        countryFipsCode: '',
        countryName: '',
        countrySlug: '',
        cc2: '',
        admin1Code: '',
        admin1Name: '',
        admin1Slug: '',
        admin2Code: '',
        admin2Name: '',
        admin2Slug: '',
        admin3Code: '',
        admin3Name: '',
        admin3Slug: '',
        admin4Code: '',
        admin4Name: '',
        admin4Slug: '',
        population: 2147483647,
        elevation: 0,
        dem: '5101',
        timezone: 'Australia/Perth',
        modificationDate: '2016-06-01',
        lowerCaseName: 'asia',
    },
    {
        id: '6255148',
        name: 'Europe',
        slug: 'europe',
        asciiName: 'Europe',
        alternateNames:
            'Avrupa,Chau Au,Châu Âu,Eiropa,El viejo continente,Eropa,Eurohpa,Eurohpá,Euroopa,Eurooppa,Europa,Europe,Europo,Európa,Evropa,Evrópa,Ewrop,Eŭropo,an Eoraip,awrwba,ou zhou,yoroppa,yuleob,yurop,yuropa,Ευρώπη,Європа,Европа,Еўропа,אירופה,أوروبا,यूरोप,ยุโรป,ヨーロッパ,欧洲,유럽',
        location: {
            lat: 48.69096,
            lon: 9.14062,
        },
        featureClass: 'L',
        featureCode: 'CONT',
        continentCode: 'EU',
        continentName: 'Europe',
        continentSlug: 'europe',
        countryCode: '',
        countryIso3Code: '',
        countryFipsCode: '',
        countryName: '',
        countrySlug: '',
        cc2: '',
        admin1Code: '',
        admin1Name: '',
        admin1Slug: '',
        admin2Code: '',
        admin2Name: '',
        admin2Slug: '',
        admin3Code: '',
        admin3Name: '',
        admin3Slug: '',
        admin4Code: '',
        admin4Name: '',
        admin4Slug: '',
        population: 0,
        elevation: 0,
        dem: '443',
        timezone: 'Europe/Vaduz',
        modificationDate: '2016-06-01',
        lowerCaseName: 'europe',
    },
    {
        id: '6255149',
        name: 'North America',
        slug: 'north-america',
        asciiName: 'North America',
        alternateNames:
            'Abya Yala,America Septentrional,America del Nord,America del Norte,America do Norte,America settentrionale,Amerika Utara,Amerique du Nord,Ameryka Polnocna,Ameryka Północna,Amèrica del Nord,América Septentrional,América del Norte,América do Norte,Amérique du Nord,Bac My,Boreia Amerike,Bắc Mỹ,Davvi-Amerihkka,Davvi-Amerihkká,Eszak-Amerika,Gogledd America,Kuzey Amerika,Meiricea Thuaidh,Meiriceá Thuaidh,Noord-Amerika,Nord-Amerika,Nordamerika,Nordameriko,Nordur-Amerika,Norteamerica,Norteamérica,North America,Norður-Ameríka,Pivnichna Amerika,Pohja-Ameerika,Pohjois-Amerikka,Põhja-Ameerika,Severna Amerika,Severnaja Amerika,Severni Amerika,Severná Amerika,Severní Amerika,Siaures Amerika,Sjeverna Amerika,Ziemelamerika,Ziemeļamerika,amryka alshmalyt,bei mei zhou,beiamerika,bug-amelika,uttari amarika,xmerikahenux,Észak-Amerika,Šiaurės Amerika,Βόρεια Αμερική,Північна Америка,Северна Америка,Северная Америка,אמריקה הצפונית,أمريكا الشمالية,شمالی امریکہ,उत्तरी अमरीका,อเมริกาเหนือ,北アメリカ,北美洲,북아메리카',
        location: {
            lat: 46.07323,
            lon: -100.54688,
        },
        featureClass: 'L',
        featureCode: 'CONT',
        continentCode: 'NA',
        continentName: 'North America',
        continentSlug: 'north-america',
        countryCode: '',
        countryIso3Code: '',
        countryFipsCode: '',
        countryName: '',
        countrySlug: '',
        cc2: '',
        admin1Code: '',
        admin1Name: '',
        admin1Slug: '',
        admin2Code: '',
        admin2Name: '',
        admin2Slug: '',
        admin3Code: '',
        admin3Name: '',
        admin3Slug: '',
        admin4Code: '',
        admin4Name: '',
        admin4Slug: '',
        population: 0,
        elevation: 0,
        dem: '570',
        timezone: 'America/Cambridge_Bay',
        modificationDate: '2016-06-01',
        lowerCaseName: 'north america',
    },
    {
        id: '6255151',
        name: 'Oceania',
        slug: 'oceania',
        asciiName: 'Oceania',
        alternateNames:
            'Chau GJai Duong,Châu Đại Dương,Eyjaalfa,Eyjaálfa,Oceania,Oceanie,Oceanien,Oceanio,Oceanië,Oceanía,Oceánia,Oceánie,Océanie,Okeaania,Okeania,Okeanija,Okeānija,Okyanusya,Oseania,Ozeanien,an Aigeine,an Aigéine,awqyanwsya,da yang zhou,oseania,osiniya,xo cheiy neiy,Óceánia,Ωκεανία,Океания,Океанія,אוקיאניה,أوقيانوسيا,ओशीनिया,โอเชียเนีย,オセアニア,大洋洲,오세아니아',
        location: {
            lat: -18.31281,
            lon: 138.51562,
        },
        featureClass: 'L',
        featureCode: 'CONT',
        continentCode: 'OC',
        continentName: 'Oceania',
        continentSlug: 'oceania',
        countryCode: '',
        countryIso3Code: '',
        countryFipsCode: '',
        countryName: '',
        countrySlug: '',
        cc2: '',
        admin1Code: '',
        admin1Name: '',
        admin1Slug: '',
        admin2Code: '',
        admin2Name: '',
        admin2Slug: '',
        admin3Code: '',
        admin3Name: '',
        admin3Slug: '',
        admin4Code: '',
        admin4Name: '',
        admin4Slug: '',
        population: 0,
        elevation: 0,
        dem: '72',
        timezone: 'Pacific/Pohnpei',
        modificationDate: '2016-06-01',
        lowerCaseName: 'oceania',
    },
    {
        id: '6255150',
        name: 'South America',
        slug: 'south-america',
        asciiName: 'South America',
        alternateNames:
            'Abya Yala,America Meridional,America del Sud,America del Sur,America do Sul,America meridionale,Amerika Selatan,Amerique du Sud,Ameryka Poludniowa,Ameryka Południowa,Amèrica del Sud,América Meridional,América del Sud,América del Sur,América do Sul,Amérique du Sud,De America,Del-Amerika,Dienvidamerika,Dél-Amerika,Etelae-Amerikka,Etelä-Amerikka,Gueney Amerika,Güney Amerika,Hego-Amerika,Hegoamerika,Jizni Amerika,Jižní Amerika,Juzhna Amerika,Juzhnaja Amerika,Juzna Amerika,Južna Amerika,Južná Amerika,Louna-Ameerika,Lulli-Amerihkka,Lulli-Amerihkká,Lõuna-Ameerika,Meiricea Theas,Meiriceá Theas,Nam My,Nam Mỹ,Notia Amerike,Pietu Amerika,Pietų Amerika,Pivdenna Amerika,Sor-Amerika,South America,Sudamerica,Sudameriko,Sudamérica,Sudur-Amerika,Suedamerika,Suramerica,Suramérica,Suður-Ameríka,Sydamerika,Sør-Amerika,Südamerika,Zuid-Amerika,amryka aljnwbyt,daksini amarika,nam-amelika,nan mei zhou,nanamerika,Νότια Αμερική,Південна Америка,Южна Америка,Южная Америка,אמריקה הדרומית,أمريكا الجنوبية,दक्षिणी अमरीका,อเมริกาใต้,南アメリカ,南美洲,남아메리카',
        location: {
            lat: -14.60485,
            lon: -57.65625,
        },
        featureClass: 'L',
        featureCode: 'CONT',
        continentCode: 'SA',
        continentName: 'South America',
        continentSlug: 'south-america',
        countryCode: '',
        countryIso3Code: '',
        countryFipsCode: '',
        countryName: '',
        countrySlug: '',
        cc2: 'BR,CO,AR,PE,VE,CL,EC,BO,PY,UY,GY,SR,GF,FK',
        admin1Code: '',
        admin1Name: '',
        admin1Slug: '',
        admin2Code: '',
        admin2Name: '',
        admin2Slug: '',
        admin3Code: '',
        admin3Name: '',
        admin3Slug: '',
        admin4Code: '',
        admin4Name: '',
        admin4Slug: '',
        population: 385742554,
        elevation: 0,
        dem: '278',
        timezone: 'America/Recife',
        modificationDate: '2016-06-01',
        lowerCaseName: 'south america',
    },
    {
        id: '6255152',
        name: 'Antarctica',
        slug: 'antarctica',
        asciiName: 'Antarctica',
        alternateNames:
            "'Anetatika,Antarctica,Antarctique,Antarktida,Antarktik,Antarktika,Antarktike,Antarktis,Antarktisz,Antarkto,Antarktyda,Antarktyka,Antarktída,Antarktíka,Antarktīda,Antartica,Antartida,Antartide,Antartika,Antàrtida,Antárktis,Antártica,Antártida,Chau Nam Cuc,Châu Nam Cực,Etelaemanner,Etelämanner,Nam Cuc,Nam Cực,Sudurheimskautslandid,Sudurskautslandid,Suðurheimskautslandið,Suðurskautslandið,alqtb aljnwby,an Antartaice,antarkatika,antarktyka,namgeug,nan ji,nan ji da lu,nan ji zhou,qtb jnwb,ʻAnetātika,Ανταρκτική,Антарктида,Антарктик,Антарктика,Антарктыка,אנטארקטיקה,אנטרקטיקה,أنتاركتيكا,القطب الجنوبي,انتارکتیکا,انٹار ٹکا,قطب جنوب,अंटार्कटिका,আন্টার্কটিকা,അന്‍റാര്‍ട്ടിക്ക,แอนตาร์กติกา,ལྷོ་རྩེའི་མཐའ་གླིང་།,ཨེན་ཊཱག་ཊི་ཀ,ანტარქტიკა,አንታርክቲካ,南极洲,南極,南極大陸,남극",
        location: {
            lat: -78.15856,
            lon: 16.40626,
        },
        featureClass: 'L',
        featureCode: 'CONT',
        continentCode: 'AN',
        continentName: 'Antarctica',
        continentSlug: 'antarctica',
        countryCode: '',
        countryIso3Code: '',
        countryFipsCode: '',
        countryName: '',
        countrySlug: '',
        cc2: '',
        admin1Code: '',
        admin1Name: '',
        admin1Slug: '',
        admin2Code: '',
        admin2Name: '',
        admin2Slug: '',
        admin3Code: '',
        admin3Name: '',
        admin3Slug: '',
        admin4Code: '',
        admin4Name: '',
        admin4Slug: '',
        population: 0,
        elevation: 0,
        dem: '3199',
        timezone: 'Antarctica/Syowa',
        modificationDate: '2015-06-08',
        lowerCaseName: 'antarctica',
    },
];

// A radius from a geoPoint location (longitude and latitude)
// locations within this radius will be included in the results
const defaultGeoPointDistance = '40km';

export { defaultLocation, defaultGeoPointDistance, defaultSuggestions };
