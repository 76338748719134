import { combineReducers } from 'redux';

import applications, { initialState as applicationsInitialState } from './applications';
import invitations, { initialState as invitationsInitialState } from './invitations';
import listings, { initialState as listingsInitialState } from './listings';
import pets, { initialState as petsInitialState } from './pets';
import placesOfInterest, { initialState as placesOfInterestInitialState } from './placesOfInterest';
import messages, { initialState as messageInitialState } from './messages';

export const initialState = {
    applications: applicationsInitialState,
    invitations: invitationsInitialState,
    listings: listingsInitialState,
    pets: petsInitialState,
    placesOfInterest: placesOfInterestInitialState,
    messages: messageInitialState,
};

export default combineReducers({
    applications,
    invitations,
    listings,
    pets,
    placesOfInterest,
    messages,
});
