import React, { Component } from 'react';
import { WorkableWidgetStyled } from './WorkableWidget.style';

class WorkableWidget extends Component {
    elementRef = React.createRef();

    constructor() {
        super();

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        const load = () =>
            window.whr(document).ready(() => {
                window.whr_embed(255648, {
                    detail: 'titles',
                    base: 'jobs',
                    zoom: 'country',
                    grouping: 'departments',
                });
                this.setState({ isLoading: false });
            });

        // Check to see if the script is already loaded
        if (window.whr) {
            load();
        } else {
            const script = document.createElement('script');
            script.id = 'workable';
            script.type = 'text/javascript';
            script.src = 'https://www.workable.com/assets/embed.js';
            script.defer = true;
            document.head.appendChild(script);
            script.onload = load;
        }
    }

    render() {
        const { isLoading } = this.state;
        const { t } = this.props;

        return (
            <>
                {isLoading ? (
                    <span>{t('pages_careers_loading_label')}</span>
                ) : (
                    <WorkableWidgetStyled id="whr_embed_hook" />
                )}
            </>
        );
    }
}

export default WorkableWidget;
