import environment from 'environment';

export const initialiseGTag = () => {
    if (typeof window !== 'undefined' && !window.gtag) {
        const script = document.createElement('script');

        script.id = 'gtag';
        script.type = 'text/javascript';
        script.innerHTML = `window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${environment.vars.googleAnalytics.measurementId}');`;

        document.head.appendChild(script);
    }
};
