import { connect } from 'react-redux';
import AddonOffers from './AddonOffers';
import { loadPremiumPlan, selectUpgrade } from './actions';

const mapDispatchToProps = (dispatch) => ({
    loadPremiumPlan: () => dispatch(loadPremiumPlan.create()),
    selectUpgrade: (upgradeDetails) => {
        dispatch(selectUpgrade.create(upgradeDetails));
    },
});

const mapStateToProps = (state) => ({
    premiumPlan: state?.containers?.addons?.offer?.plan,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddonOffers);
