import { all, fork } from 'redux-saga/effects';
import LogoutSaga from 'containers/Logout/sagas';
import LocationFilter from 'containers/forms/search/FilterLocation/FilterLocation.sagas';
import BreedFilter from 'containers/forms/search/FilterBreed/sagas';
import createAccountSaga from 'containers/forms/Register/sagas';
import uploadRequestWatcherSaga from 'containers/PhotoUploader/sagas';
import sendReferralEmailSaga from 'containers/SendReferralEmail/sagas';
import sendShareListingEmailSaga from 'containers/SendShareListingEmailModal/sagas';
import listingDetailPageDates from 'containers/ListingDetailPage/components/Dates/sagas';
import termsAndConditionsSaga from 'containers/TermsAndConditions/sagas';
import plansPageSagas from 'containers/PlansPage/sagas';
import experimentalFeaturesSagas from 'containers/ExperimentalFeature/sagas';
import geoLocationSagas from 'containers/GeoLocation/sagas';
import blogPostsCarouselSagas from 'containers/BlogPostsCarousel/sagas';
import addonsOffersSagas from 'containers/AddonOffers/sagas';
import addonsCheckoutSagas from 'containers/AddonOffers/components/AddonsCheckout/sagas';
import seoFacetLinksSagasSingleArea from 'containers/SeoFacetLinksSingleArea/sagas';
import RAFNudgewallSagas from 'containers/ReferAFriendNudgewall/sagas';
import FreeTrialEndModal from 'containers/FreeTrialEndModal/sagas';
import outsideSubscriptionModalSagas from 'containers/OutsideSubscriptionModal/sagas';
import leaveReviewAndFeedbackModalSagas from './LeaveReviewAndFeedbackModal/sagas';

export default function* sagas() {
    yield all([
        fork(createAccountSaga),
        fork(LocationFilter),
        fork(BreedFilter),
        fork(LogoutSaga),
        fork(uploadRequestWatcherSaga),
        fork(sendReferralEmailSaga),
        fork(sendShareListingEmailSaga),
        fork(listingDetailPageDates),
        fork(termsAndConditionsSaga),
        fork(plansPageSagas),
        fork(experimentalFeaturesSagas),
        fork(geoLocationSagas),
        fork(blogPostsCarouselSagas),
        fork(addonsOffersSagas),
        fork(addonsCheckoutSagas),
        fork(seoFacetLinksSagasSingleArea),
        fork(RAFNudgewallSagas),
        fork(FreeTrialEndModal),
        fork(leaveReviewAndFeedbackModalSagas),
        fork(outsideSubscriptionModalSagas),
    ]);
}
