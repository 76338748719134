// eslint-disable-next-line import/prefer-default-export
export const SHARED_LISTING = {
    create(method, listingId) {
        return {
            type: 'Shared Listing',
            payload: {
                listing_id: listingId,
                method,
            },
        };
    },
};
