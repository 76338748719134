import { settings as actions } from '../../actions';

import {
    baseInitialResultsState,
    loadedResultsReducer,
    loadedResultsItemReducer,
    updatedDataReducer,
    createdDataReducer,
} from '../common';

export const initialState = { ...baseInitialResultsState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadFeedbacks.SUCCESS:
            return loadedResultsReducer(state, action);
        case actions.owner.loadPastAssignmentFeedback.SUCCESS:
            return loadedResultsItemReducer(state, action);
        case actions.owner.updatePastAssignmentFeedback.SUCCESS:
            return updatedDataReducer(state, action);
        case actions.owner.createPastAssignmentFeedbackRequest.SUCCESS:
            return createdDataReducer(state, action);
        case actions.owner.loadConfirmedAssignments.SUCCESS:
            return {
                ...state,
                ...action.data.past.reduce((feedbacks, assignment) => {
                    if (assignment.review) {
                        feedbacks[assignment.review.id] = assignment.review;
                    }
                    return feedbacks;
                }, {}),
            };
        case actions.owner.loadPastAssignments.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((feedbacks, assignment) => {
                        if (assignment.feedback) {
                            feedbacks[assignment.feedback.id] = assignment.feedback;
                        }
                        return feedbacks;
                    }, {}),
                },
            };
        default:
            return state;
    }
};
