// eslint-disable-next-line import/prefer-default-export
export const VIDEO_STARTED = {
    create({ title }) {
        const payload = {
            title,
        };

        return {
            type: 'Video Started',
            payload,
        };
    },
};

export const VIDEO_ENDED = {
    create({ title }) {
        const payload = {
            title,
        };

        return {
            type: 'Video Ended',
            payload,
        };
    },
};

export const VIDEO_PROGRESS = {
    create({ title, progressPercentage }) {
        const payload = {
            title,
            progressPercentage,
        };

        return {
            type: 'Video Progress',
            payload,
        };
    },
};

export const VIDEO_UNMUTED = {
    create({ title }) {
        const payload = {
            title,
        };

        return {
            type: 'Video Unmuted',
            payload,
        };
    },
};
