// eslint-disable-next-line import/prefer-default-export
export const OWNER_DECLINED_APPLICATION = {
    create(application) {
        const {
            id: applicationId,
            assignmentId,
            assignment,
            listingId,
            profileId,
            isInvited,
            listing,
            profile,
        } = application;
        const { isApproximateDates, startDate, endDate } = assignment;
        const countryName =
            application.listing.location && application.listing.location.countryName;

        const payload = {
            applicationId,
            assignmentId,
            isApproximateDates,
            listingId,
            ownerId: listing.user && listing.user.id,
            profileId,
            sitterId: profile.user && profile.user.id,
            startDate,
            endDate,
            countryName,
            isInvited,
        };

        return {
            type: 'Owner Declined Application',
            payload,
        };
    },
};
