import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
    getSearchProfilesFavouritedYou,
    getSearchProfilesFavouritedYouTotal,
} from 'api/selectors/search';

import { getSearch } from 'containers/Page/selectors';
import asPage from 'containers/Page';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';

import { PAGE_ID } from './SavedYourListing.constants';
import SavedYourListing from './SavedYourListing';

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

const mapStateToProps = (state) => ({
    profiles: getSearchProfilesFavouritedYou(state),
    totalResults: getSearchProfilesFavouritedYouTotal(state),
    pageNumber: parseInt(getSearch(state, PAGE_ID).page, 10) || 1,
});

export default connect(
    mapStateToProps,
    null
)(
    asPage(withTranslation()(SavedYourListing), PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
