import { createGlobalStyle } from 'styled-components';
import sanitizedStyles from './sanitize';
import typography from './typography';
import environment from '../../environment';

// This needs to be a function to delay it until the environment variables are set
const fullStaticPath = () => `${environment.vars.cdnPath}/${environment.vars.staticPath}`;

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${fullStaticPath}/fonts/Poppins-Regular.woff2) format('woff2'),
             url(${fullStaticPath}/fonts/Poppins-Regular.woff) format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(${fullStaticPath}/fonts/Poppins-Medium.woff2) format('woff2'),
             url(${fullStaticPath}/fonts/Poppins-Medium.woff) format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    
    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url(${fullStaticPath}/fonts/Poppins-SemiBold.woff2) format('woff2'),
             url(${fullStaticPath}/fonts/Poppins-SemiBold.woff) format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${fullStaticPath}/fonts/Poppins-Bold.woff2) format('woff2'),
             url(${fullStaticPath}/fonts/Poppins-Bold.woff) format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    ${sanitizedStyles};
    ${typography};

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    fieldset {
        border: 0;
        padding: 0;
    }

    body.no-scroll {
        overflow-y: hidden;
        height: 100vh;
    }

    p {
        white-space: pre-line;
    }
`;

export default GlobalStyle;
