import styled from 'styled-components';
import { MainContainerStyled } from '../Root/Root.style';

// eslint-disable-next-line import/prefer-default-export
export const CounterDisplayCount = styled.span`
    grid-area: count;
    text-align: center;

    ${MainContainerStyled}[data-status='filled'] & {
        font-weight: 600;
    }
`;
