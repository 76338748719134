export const Facet = Object.freeze({
    GLOBAL: 'global',
    COUNTRY: 'country',
    ADMIN1: 'admin1',
    ADMIN2: 'admin2',
    PLACE: 'place',
    ANIMAL: 'animal',
    GEOHASH1: 'geohash1',
    GEOHASH2: 'geohash2',
    GEOHASH3: 'geohash3',
    GEOHASH4: 'geohash4',
    GEOHASH5: 'geohash5',
    GEOHASH6: 'geohash6',
    GEOHASH7: 'geohash7',
    GEOBOUNDS: 'geoBounds',
});

export const Sitemap = Object.freeze({
    COUNTRY: 'countries',
    ADMIN1: 'regions',
    ADMIN2: 'counties',
    PLACE: 'cities',
});

export const SortDirection = Object.freeze({
    ASC: 'asc',
    DESC: 'desc',
});

export const Animal = Object.freeze({
    DOG: 'dog',
    CAT: 'cat',
    REPTILE: 'reptile',
    HORSE: 'horse',
    FISH: 'fish',
    POULTRY: 'poultry',
    FARMANIMAL: 'farm animal',
    BIRD: 'bird',
    SMALLPET: 'small pet',
});
export const AnimalId = Object.freeze({
    DOG: 1,
    CAT: 2,
    REPTILE: 3,
    HORSE: 4,
    FISH: 5,
    POULTRY: 6,
    FARMANIMAL: 7,
    BIRD: 8,
    SMALLPET: 9,
});
export const AnimalIdToAnimal = Object.freeze({
    '1': Animal.DOG,
    '2': Animal.CAT,
    '3': Animal.REPTILE,
    '4': Animal.HORSE,
    '5': Animal.FISH,
    '6': Animal.POULTRY,
    '7': Animal.FARMANIMAL,
    '8': Animal.BIRD,
    '9': Animal.SMALLPET,
});

export const LocalAttraction = Object.freeze({
    COUNTRYSIDE: 'countryside',
    CITY: 'city',
    BEACH: 'beach',
    MOUNTAIN: 'mountain',
});

export const WifiAvailable = Object.freeze({
    NONE: 'none',
    BASIC: 'basic',
    HIGHSPEED: 'high speed',
});

export const Gender = Object.freeze({
    MALE: 'male',
    FEMALE: 'female',
});

export const HomeType = Object.freeze({
    HOUSE: 'house',
    APARTMENT: 'apartment',
});

export const VerificationLevel = Object.freeze({
    BASIC: 'basic',
    STANDARD: 'standard',
    ENHANCED: 'enhanced',
});

/**
 * Available duration in days and correspondent keys
 */
export const DurationInDays = [1, 3, 7, 14, 28, 168, 0];
export const DurationKeys = [
    'no-limit',
    '3-days',
    '4-7-days',
    '1-2-weeks',
    '2-4-weeks',
    '1-6-months',
    '6-months',
];

export const SORT_FUNCTIONS = {
    POPULAR_NEW_SITS: 'POPULAR_NEW_SITS',
    POPULAR_NEW_SITS_WITH_PAUSED: 'POPULAR_NEW_SITS_WITH_PAUSED',
    POPULAR_NEW_SITS_WITH_PAUSED_AND_LOCAL_ATTRACTIONS:
        'POPULAR_NEW_SITS_WITH_PAUSED_AND_LOCAL_ATTRACTIONS',
    LOCAL_SITS: 'LOCAL_SITS',
    LOCAL_SITS_WITH_PAUSED: 'LOCAL_SITS_WITH_PAUSED',
    SITS_WITH_DATES_FIRST: 'SITS_WITH_DATES_FIRST',
    CLOSE_SITS_WITH_DATES_FIRST: 'CLOSE_SITS_WITH_DATES_FIRST',
    PRIORITISE_LOW_APPLICANTS: 'PRIORITISE_LOW_APPLICANTS',
    POPULAR_NEW_SITS_CITY_LEVEL: 'POPULAR_NEW_SITS_CITY_LEVEL',
    POPULAR_NEW_SITS_CITY_LEVEL_WITH_PAUSED: 'POPULAR_NEW_SITS_CITY_LEVEL_WITH_PAUSED',
    RECOMMENDED_USER: 'RECOMMENDED', // get_recommended_id_score_pairs
    RECOMMENDED_SIMILAR: 'SIMILAR', // get_similar_id_score_pairs
    RECOMMENDATIONS: 'RECOMMENDATIONS',
    SIT_START_DATE: 'SIT_START_DATE',
};

export const SORT_FUNCTION_OBJECTS = {
    [SORT_FUNCTIONS.POPULAR_NEW_SITS]: {
        name: SORT_FUNCTIONS.POPULAR_NEW_SITS,
        order: 'asc',
    },
    [SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED]: {
        name: SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED,
        order: 'asc',
    },
    [SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED_AND_LOCAL_ATTRACTIONS]: {
        name: SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED_AND_LOCAL_ATTRACTIONS,
        order: 'asc',
    },
    [SORT_FUNCTIONS.SITS_WITH_DATES_FIRST]: {
        name: SORT_FUNCTIONS.SITS_WITH_DATES_FIRST,
        order: 'asc',
    },
    [SORT_FUNCTIONS.LOCAL_SITS]: {
        name: SORT_FUNCTIONS.LOCAL_SITS,
        order: 'asc',
    },
    [SORT_FUNCTIONS.CLOSE_SITS_WITH_DATES_FIRST]: {
        name: SORT_FUNCTIONS.CLOSE_SITS_WITH_DATES_FIRST,
        order: 'asc',
    },
    [SORT_FUNCTIONS.PRIORITISE_LOW_APPLICANTS]: {
        name: SORT_FUNCTIONS.PRIORITISE_LOW_APPLICANTS,
        order: 'asc',
    },
    [SORT_FUNCTIONS.POPULAR_NEW_SITS_CITY_LEVEL]: {
        name: SORT_FUNCTIONS.POPULAR_NEW_SITS_CITY_LEVEL,
        order: 'asc',
    },
    [SORT_FUNCTIONS.POPULAR_NEW_SITS_CITY_LEVEL_WITH_PAUSED]: {
        name: SORT_FUNCTIONS.POPULAR_NEW_SITS_CITY_LEVEL_WITH_PAUSED,
        order: 'asc',
    },
    [SORT_FUNCTIONS.LOCAL_SITS_WITH_PAUSED]: {
        name: SORT_FUNCTIONS.LOCAL_SITS_WITH_PAUSED,
        order: 'asc',
    },
    [SORT_FUNCTIONS.RECOMMENDED_USER]: {
        name: SORT_FUNCTIONS.RECOMMENDED_USER,
        version: 'v1',
        order: 'asc',
    },
    [SORT_FUNCTIONS.RECOMMENDED_SIMILAR]: {
        name: SORT_FUNCTIONS.RECOMMENDED_SIMILAR,
        version: 'v1',
        order: 'asc',
    },
    [SORT_FUNCTIONS.RECOMMENDATIONS]: {
        name: SORT_FUNCTIONS.RECOMMENDATIONS,
        version: 'v1',
        order: 'desc',
    },
    [SORT_FUNCTIONS.SIT_START_DATE]: {
        name: SORT_FUNCTIONS.SIT_START_DATE,
        order: 'asc',
    },
};

export const getSortFunction = (name, args) => {
    const sortFunctionObject = SORT_FUNCTION_OBJECTS[name];
    return {
        ...sortFunctionObject,
        ...args,
    };
};

export const LISTING_TAGS = {
    DOG: 'dog',
    PUPPIES: 'puppies',
    SENIOR_DOGS: 'seniorDogs',
    CAT: 'cat',
    KITTENS: 'kittens',
    SENIOR_CATS: 'seniorCats',
    REPTILE: 'reptile',
    HORSE: 'horse',
    FISH: 'fish',
    POULTRY: 'poultry',
    LIVESTOCK: 'farm animal',
    BIRD: 'bird',
    SMALL_PET: 'small pet',
    MEDICATION: 'medication',
};

export const LISTING_TAGS_PRIORITY_ORDER = {
    [LISTING_TAGS.DOG]: 1,
    [LISTING_TAGS.PUPPIES]: 2,
    [LISTING_TAGS.SENIOR_DOGS]: 3,
    [LISTING_TAGS.CAT]: 4,
    [LISTING_TAGS.KITTENS]: 5,
    [LISTING_TAGS.SENIOR_CATS]: 6,
    [LISTING_TAGS.REPTILE]: 7,
    [LISTING_TAGS.HORSE]: 8,
    [LISTING_TAGS.FISH]: 9,
    [LISTING_TAGS.POULTRY]: 10,
    [LISTING_TAGS.LIVESTOCK]: 11,
    [LISTING_TAGS.BIRD]: 12,
    [LISTING_TAGS.SMALL_PET]: 13,
    [LISTING_TAGS.MEDICATION]: 14,
};

export const LOW_APPLICANTS_LIMIT = 2;
