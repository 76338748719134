import { settings as actions } from '../../../actions';
import { uniqueArray } from '../../../helpers/format/objects';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadSitterOwnerInvitations.SUCCESS:
        case actions.sitter.loadSitterOwnerApplications.SUCCESS: {
            return {
                ...state,
                [action.requestData.id]: uniqueArray([
                    ...(state[action.requestData.id] || []),
                    ...action.data.results.map(item => item.listingId),
                ]),
            };
        }
        default:
            return state;
    }
};
