import { LOCATION_CHANGE } from 'connected-react-router';
import { parse } from 'query-string';
import { MembershipType } from 'api/types';
import { RegwallMessaging } from 'components/Regwall';
import * as actions from './actions';
import { userRegistered, userLoggedIn } from '../forms/Register/actions';
import { GLOBAL_REGWALL_TYPES } from './constants';

export const getRegwallConfig = (hash) => {
    const parsedHash = parse(hash);

    // Check to see if should trigger the regwall
    if (parsedHash.regwall) {
        return {
            type: parsedHash.regwall,
            next: parsedHash.next,
            openMethod: parsedHash.openMethod ? parsedHash.openMethod : null,
            // the parsed hash of isRegwallCloseable is a string initially
            // and so this would revert back to boolean for easier checking
            isRegwallCloseable: parsedHash.isRegwallCloseable !== 'false',
            regwallCopy: {
                heading: parsedHash.heading ? parsedHash.heading : null,
                subheading: parsedHash.subheading ? parsedHash.subheading : null,
            },
        };
    }
    return {};
};

// This is the type of nested regwall
export const getRegwallType = (type) => {
    if (type === GLOBAL_REGWALL_TYPES.OWNER) return MembershipType.OWNER;
    if (type === GLOBAL_REGWALL_TYPES.SITTER) return MembershipType.SITTER;
    return null;
};

export const initialState = {
    isRegwallVisible: false,
    regwallType: null,
    messaging: RegwallMessaging.JOIN,
    next: null,
    redirect: false,
    openMethod: null,
    isRegwallCloseable: true,
    regwallCopy: {
        heading: null,
        subheading: null,
    },
};

const globalRegwallReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            // On a location change we need to check if the URL has a hash (fragment) in it
            // so that we can decide if the regwall will be shown and what sort of regwall to show
            const { type, next, openMethod, isRegwallCloseable, regwallCopy } = getRegwallConfig(
                action.payload?.location?.hash
            );
            if (!type) {
                // make sure to reset the global regwall to default settings
                // otherwise it would still show the regwall when navigating away
                // e.g. user presses the browser's back button
                return initialState;
            }

            return {
                ...state,
                // open regwall if we have a regwall
                isRegwallVisible: true,
                // Only set the type when owner or sitter
                regwallType: getRegwallType(type),
                messaging: initialState.messaging,
                // Where to redirect once a user registers
                next,
                openMethod,
                isRegwallCloseable,
                regwallCopy,
            };
        }
        case userLoggedIn.ACTION:
        case userRegistered.ACTION: {
            // If a user has just registered we want to close the regwall
            return {
                ...state,
                isRegwallVisible: false,
                redirect: state.next && true,
            };
        }
        case actions.openRegwall.ACTION: {
            return {
                ...state,
                isRegwallVisible: true,
                regwallType: action.payload?.regwallType ?? initialState.regwallType,
                messaging: action.payload?.messaging ?? initialState.messaging,
                next: action.payload?.next ?? initialState.next,
                openMethod: action.payload?.openMethod ?? initialState.openMethod,
                isRegwallCloseable:
                    action.payload?.isRegwallCloseable ?? initialState.isRegwallCloseable,
                regwallCopy: action.payload?.regwallCopy ?? initialState.regwallCopy,
            };
        }
        case actions.closeRegwall.ACTION: {
            return {
                ...state,
                isRegwallVisible: false,
            };
        }
        default:
            return state;
    }
};

export default globalRegwallReducer;
