import { connect } from 'react-redux';
import { getPosts } from './selectors';
import BlogPostsCarousel from './BlogPostsCarousel';
import { categories } from './BlogPostsCarousel.constants';

const mapStateToProps = (state, { area, currentPost }) => {
    const posts = getPosts(state, area);
    // NOTE: If we've the `currentPost`, we filter the current post from the
    // posts array.

    if (currentPost) {
        return {
            posts: posts.filter(
                (post) =>
                    !(
                        post.slug === currentPost.slug &&
                        post.category.slug === currentPost.categorySlug
                    )
            ),
        };
    }

    return {
        posts,
    };
};

export { categories };
export default connect(mapStateToProps)(BlogPostsCarousel);
