import styled, { css } from 'styled-components';
import Anchor from 'components/Anchor';
import { Button } from 'components/buttons';
import { IconClose } from 'components/Icon';

const NotificationBarContainerStyled = styled.div(
    ({ backgroundColour, theme: { palette } }) => css`
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: ${backgroundColour || palette.primaryRegular};
    `
);

const NotificationBarLinkTextStyled = styled(Anchor)(
    ({ textColour, theme: { palette, spacing, screenSize } }) => css`
        color: ${textColour || palette.white};
        padding: ${spacing[2]};
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;

        /* 720 */
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            padding: ${spacing[3]};
        }
    `
);

const NotificationBarTextUnderlinedStyled = styled.span(
    ({ textColour, theme: { palette, spacing } }) => css`
        color: ${textColour || palette.white};
        padding-left: ${spacing[1]};
        text-decoration: underline;
    `
);

const NotificationBarButtonStyled = styled(Button)(
    ({ backgroundColour, textColour, theme: { palette, spacing, screenSize } }) => css`
        padding: ${spacing[2]};
        color: ${textColour || palette.white};
        border-style: none;
        background-color: ${backgroundColour || palette.primaryRegular};

        &:hover:enabled {
            background-color: ${backgroundColour || palette.primaryRegular};
        }

        /* 720 */
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            padding: ${spacing[3]};
        }
    `
);

const NotificationBarIconCloseStyled = styled(IconClose)(
    ({ textColour, theme: { palette } }) => css`
        min-width: 24px;
        min-height: 24px;
        & svg {
            fill: ${textColour || palette.white};
        }
    `
);

export {
    NotificationBarContainerStyled,
    NotificationBarLinkTextStyled,
    NotificationBarTextUnderlinedStyled,
    NotificationBarButtonStyled,
    NotificationBarIconCloseStyled,
};
