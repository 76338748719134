import { put, take } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';

export default function* updateSavedSearch(action) {
    yield put(
        apiActions.search.updateListingsSavedSearch({
            forceReload: true,
            data: action.savedSearch,
        })
    );

    // Wait for results
    const { status } = yield take(settings.search.updateListingsSavedSearch.DONE);

    // Return true/false for success/failure
    return status === settings.search.updateListingsSavedSearch.SUCCESS;
}
