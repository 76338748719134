import styled, { css } from 'styled-components';
import Link from 'components/Link';
import { h3Styles, h2Styles, smallTextStyles } from 'typography';
import { SVGComponent as UnlockSittersIcon } from './assets/unlock-sitters.svg';
import { SVGComponent as UnlockSittingIcon } from './assets/unlock-sitting.svg';

const UpgradeMembershipWrapper = styled.div`
    ${({ theme: { palette, borderRadius, spacing } }) => `
        background-color: ${palette.white};
        padding: ${spacing[1]};
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.12), -4px -4px 20px rgba(255, 255, 255, 0.5);
        border-radius: ${borderRadius.regular};
    `}
`;

const UpgradeMembershipContainer = styled.div`
    ${({ theme: { borderRadius, palette, spacing, screenSize } }) => `
        background-color: ${palette.primarySoftest};
        padding: ${spacing[3]};
        display: flex;
        flex-direction: column;
        border-radius: ${borderRadius.regular};

        // 480
        @media screen and (min-width: ${screenSize.largeHandset}px) {
            flex-direction: row;
        }
    `}
`;

const BodyWrapper = styled.div`
    ${({ theme: { spacing, screenSize } }) => `
        // 720
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            padding-left: ${spacing[3]};
        }
    `}
`;

const TitleStyled = styled.h3`
    ${h3Styles};
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;

    // 1024
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        ${h2Styles};
        order: 1;
        width: 100%;
    }
`;

const BodyStyled = styled.p`
    ${smallTextStyles};
    margin-bottom: 0px;

    ${({ theme }) => `
        color: ${theme.palette.grey[500]};
        margin-top: ${theme.spacing[2]};
        margin-bottom: 0px;
    `};
`;

const CtasWrapperStyled = styled.div`
    ${({ theme: { spacing, screenSize } }) => `
        margin-top: ${spacing[2]};
        width: 100%;

        // 480
        @media screen and (min-width: ${screenSize.largeHandset}px) {
            width: 240px;
            margin-top: 0px;
        }
    `}
`;

const LinkStyled = styled(Link)`
    width: 100%;
    align-self: center;
    margin-bottom: 0px;
`;

const IconStyles = css`
    ${({ theme: { screenSize } }) => `
        display: none;
        // 720
        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            display: block;
        }
    `}
`;

const IconStylesSmall = css`
    ${({ theme: { screenSize } }) => `
        height: 42px;

        // 480
        @media screen and (min-width: ${screenSize.largeHandset}px) {
            display: none;
        }
    `}
`;

const UnlockSittersIconStyled = styled(UnlockSittersIcon)`
    ${IconStyles}
`;

const UnlockSittingIconStyled = styled(UnlockSittingIcon)`
    ${IconStyles}
`;

const UnlockSittersSmallIconStyled = styled(UnlockSittersIcon)`
    ${IconStylesSmall}
`;

const UnlockSittingSmallIconStyled = styled(UnlockSittingIcon)`
    ${IconStylesSmall}
`;

export {
    BodyStyled,
    BodyWrapper,
    CtasWrapperStyled,
    TitleStyled,
    LinkStyled,
    UpgradeMembershipWrapper,
    UpgradeMembershipContainer,
    UnlockSittersIconStyled,
    UnlockSittingIconStyled,
    UnlockSittersSmallIconStyled,
    UnlockSittingSmallIconStyled,
};
