import paths from './route-paths';
import Print from './Print';

export default [
    {
        path: paths.print,
        exact: true,
        component: Print,
    },
];
