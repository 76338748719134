import {
    preload as pageActionsPreload,
    lazyload as pageActionsLazyload,
} from 'containers/Page/actions';
import { all, call, takeEvery } from 'redux-saga/effects';

import { PAGE_ID as PRINT_PAGE_ID } from './Print.constants';
import { loadWelcomeGuide, loadPets, loadPlacesOfInterest, loadWelcomeGuidePhotos } from '../sagas';

export function* load(action) {
    yield all([
        call(loadWelcomeGuide, action.params, action.type, action.pageId),
        call(loadPets, action.params, action.type, action.pageId),
        call(loadPlacesOfInterest, action.params, action.type, action.pageId),
        call(loadWelcomeGuidePhotos, action.params, action.type, action.pageId),
    ]);
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) =>
                action.type === pageActionsPreload.ACTION && action.pageId === PRINT_PAGE_ID,
            load
        ),
        takeEvery(
            (action) =>
                action.type === pageActionsLazyload.ACTION && action.pageId === PRINT_PAGE_ID,
            load
        ),
    ]);
}
