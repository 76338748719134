import styled from 'styled-components';

export const LabelStyled = styled.label`
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    color: ${({ theme }) => theme.palette.grey[700]};

    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.spacing[1]};
    }
`;
