import React, { Component } from 'react';

class TrustpilotLoader extends Component {
    elementRef = React.createRef();

    componentDidMount() {
        const load = () => {
            const { onLoad } = this.props;

            if (typeof onLoad === 'function') {
                onLoad();
            }

            window.Trustpilot.loadFromElement(this.elementRef.current);
        };

        if (window.Trustpilot) {
            load();
        } else {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
            script.onload = load;
        }
    }

    render() {
        const { children } = this.props;

        return children({
            ref: this.elementRef,
        });
    }
}

TrustpilotLoader.defaultProps = {
    onLoad: undefined,
};

export default TrustpilotLoader;
