import { Facet as FacetSearchLevel } from 'api/helpers/search/constants';
import {
    admin1GeoParam,
    admin2GeoParam,
    countryGeoParam,
    getFacetsBelowGeoParam,
    placeGeoParam,
} from 'pages/search/helpers';

export const FACETS_SEARCH_TYPE = {
    listings: 'listings',
    profiles: 'profiles',
    seo: 'seo',
};

export const FACET_LEVEL_NAME = {
    GLOBAL: FacetSearchLevel.GLOBAL,
    COUNTRY: FacetSearchLevel.COUNTRY,
    REGION: FacetSearchLevel.ADMIN1,
    COUNTY: FacetSearchLevel.ADMIN2,
    PLACE: FacetSearchLevel.PLACE,
};

export const GEO_PARAM_MAP = {
    [FACET_LEVEL_NAME.COUNTRY]: countryGeoParam,
    [FACET_LEVEL_NAME.REGION]: admin1GeoParam,
    [FACET_LEVEL_NAME.COUNTY]: admin2GeoParam,
    [FACET_LEVEL_NAME.PLACE]: placeGeoParam,
};

export const GEO_PARAM_NAME = {
    NAME: 'name',
    COUNTRY: 'countryName',
    REGION: 'admin1Name',
};

export const NEEDED_FACETS_LEVELS = {
    [FACET_LEVEL_NAME.GLOBAL]: {
        facets: getFacetsBelowGeoParam(null),
        geoParams: [],
    },
    [FACET_LEVEL_NAME.COUNTRY]: {
        facets: getFacetsBelowGeoParam(countryGeoParam),
        geoParams: [countryGeoParam],
    },
    [FACET_LEVEL_NAME.REGION]: {
        facets: getFacetsBelowGeoParam(admin1GeoParam),
        geoParams: [countryGeoParam, admin1GeoParam],
    },
    [FACET_LEVEL_NAME.COUNTY]: {
        facets: getFacetsBelowGeoParam(admin2GeoParam),
        geoParams: [countryGeoParam, admin1GeoParam, admin2GeoParam],
    },
    [FACET_LEVEL_NAME.PLACE]: {
        facets: getFacetsBelowGeoParam(placeGeoParam),
        geoParams: [countryGeoParam, admin1GeoParam, admin2GeoParam],
    },
};

export const GLOBAL_LEVEL_COLUMNS = [
    {
        i18n: {
            title: 'components_dynamic_facets_column_countries_title',
            expandFewer: 'components_dynamic_facets_column_countries_expand_fewer',
            expandMore: 'components_dynamic_facets_column_countries_expand_more',
        },
        expandable: true,
        width: 2,
        max: 24,
        facets: [FACET_LEVEL_NAME.GLOBAL, FACET_LEVEL_NAME.COUNTRY],
    },
    {
        i18n: {
            title: 'components_dynamic_facets_column_cities_worldwide_title',
            expandFewer: 'components_dynamic_facets_column_cities_expand_fewer',
            expandMore: 'components_dynamic_facets_column_cities_expand_more',
        },
        expandable: true,
        width: 2,
        max: 24,
        facets: [FACET_LEVEL_NAME.GLOBAL, FACET_LEVEL_NAME.PLACE],
        transform: (column) => ({
            ...column,
            facets: column.facets.slice(0, 100),
        }),
    },
];

export const COUNTRY_LEVEL_COLUMNS = [
    {
        i18n: {
            title: 'components_dynamic_facets_column_regions_title',
            expandMore: 'components_dynamic_facets_column_regions_expand_more',
            expandFewer: 'components_dynamic_facets_column_regions_expand_fewer',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.COUNTRY, FACET_LEVEL_NAME.REGION],
    },
    {
        i18n: {
            title: 'components_dynamic_facets_column_counties_title',
            expandFewer: 'components_dynamic_facets_column_counties_expand_fewer',
            expandMore: 'components_dynamic_facets_column_counties_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.COUNTRY, FACET_LEVEL_NAME.COUNTY],
    },
    {
        i18n: {
            title: 'components_dynamic_facets_column_cities_title',
            expandFewer: 'components_dynamic_facets_column_cities_expand_fewer',
            expandMore: 'components_dynamic_facets_column_cities_expand_more',
        },
        expandable: true,
        width: 2,
        max: 24,
        facets: [FACET_LEVEL_NAME.COUNTRY, FACET_LEVEL_NAME.PLACE],
    },
];

export const REGION_LEVEL_COLUMNS = [
    {
        i18n: {
            title: 'components_dynamic_facets_column_counties_title',
            expandFewer: 'components_dynamic_facets_column_counties_expand_fewer',
            expandMore: 'components_dynamic_facets_column_counties_expand_more',
        },
        width: 2,
        max: 24,
        expandable: true,
        facets: [FACET_LEVEL_NAME.REGION, FACET_LEVEL_NAME.COUNTY],
    },
    {
        i18n: {
            title: 'components_dynamic_facets_column_cities_title',
            expandFewer: 'components_dynamic_facets_column_cities_expand_fewer',
            expandMore: 'components_dynamic_facets_column_cities_expand_more',
        },
        expandable: true,
        width: 2,
        max: 24,
        facets: [FACET_LEVEL_NAME.REGION, FACET_LEVEL_NAME.PLACE],
    },
];

export const COUNTY_LEVEL_COLUMNS = [
    {
        i18n: {
            title: 'components_dynamic_facets_column_nearby_locations_title',
            expandFewer: 'components_dynamic_facets_column_cities_expand_fewer',
            expandMore: 'components_dynamic_facets_column_cities_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.COUNTY, FACET_LEVEL_NAME.PLACE],
    },
    {
        i18n: {
            title: ['components_dynamic_facets_column_other_counties_title', GEO_PARAM_NAME.REGION],
            expandFewer: 'components_dynamic_facets_column_counties_expand_fewer',
            expandMore: 'components_dynamic_facets_column_counties_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.COUNTRY, FACET_LEVEL_NAME.COUNTY],
        filter: ({ facets, slug }) => facets.filter((facet) => facet.slug !== slug),
    },
    {
        i18n: {
            title: ['components_dynamic_facets_column_other_cities_title', GEO_PARAM_NAME.COUNTRY],
            expandFewer: 'components_dynamic_facets_column_cities_expand_fewer',
            expandMore: 'components_dynamic_facets_column_cities_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.COUNTRY, FACET_LEVEL_NAME.PLACE],
        filter: ({ facets, listedLocations }) =>
            facets.filter((facet) => !listedLocations.includes(facet.slug)),
    },
    {
        i18n: {
            title: 'components_dynamic_facets_column_cities_worldwide_title',
            expandFewer: 'components_dynamic_facets_column_cities_expand_fewer',
            expandMore: 'components_dynamic_facets_column_cities_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.GLOBAL, FACET_LEVEL_NAME.PLACE],
        filter: ({ facets, listedLocations }) =>
            facets.filter((facet) => !listedLocations.includes(facet.slug)),
    },
];

export const CITY_LEVEL_COLUMNS = [
    {
        i18n: {
            title: 'components_dynamic_facets_column_nearby_locations_title',
            expandFewer: 'components_dynamic_facets_column_locations_expand_fewer',
            expandMore: 'components_dynamic_facets_column_locations_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.COUNTY, FACET_LEVEL_NAME.PLACE],
        filter: ({ facets, slug }) => facets.filter((facet) => facet.slug !== slug),
    },
    {
        i18n: {
            title: [
                'components_dynamic_facets_column_locations_in_place_title',
                GEO_PARAM_NAME.COUNTRY,
            ],
            expandFewer: 'components_dynamic_facets_column_locations_expand_fewer',
            expandMore: 'components_dynamic_facets_column_locations_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.COUNTRY, FACET_LEVEL_NAME.PLACE],
        filter: ({ facets, slug, listedLocations }) =>
            facets
                .filter((facet) => facet.slug !== slug)
                .filter((facet) => !listedLocations.includes(facet.slug)),
    },
    {
        i18n: {
            title: 'components_dynamic_facets_column_cities_worldwide_title',
            expandFewer: 'components_dynamic_facets_column_cities_expand_fewer',
            expandMore: 'components_dynamic_facets_column_cities_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.GLOBAL, FACET_LEVEL_NAME.PLACE],
        filter: ({ facets, slug, listedLocations }) =>
            facets
                .filter((facet) => facet.slug !== slug)
                .filter((facet) => !listedLocations.includes(facet.slug)),
    },
    {
        i18n: {
            title: 'components_dynamic_facets_column_countries_title',
            expandFewer: 'components_dynamic_facets_column_countries_expand_fewer',
            expandMore: 'components_dynamic_facets_column_countries_expand_more',
        },
        expandable: true,
        facets: [FACET_LEVEL_NAME.GLOBAL, FACET_LEVEL_NAME.COUNTRY],
    },
];

export const FACETS_SITEMAP = [
    GEO_PARAM_MAP[FACET_LEVEL_NAME.COUNTRY],
    GEO_PARAM_MAP[FACET_LEVEL_NAME.REGION],
    GEO_PARAM_MAP[FACET_LEVEL_NAME.COUNTY],
];
