import {
    getAccountCurrentMembershipPlan,
    getAccountDetails,
    getAccountIncompleteMembershipPlan,
} from 'api/selectors/account';
import { getSearchListings, getSearchListingsTotal } from 'api/selectors/search';
import { settings } from 'api/actions';
import { hasRequestEnded } from 'api/selectors/request';
import { getSeoContent, getIsLoggedIn } from 'shared/selectors';
import { routes } from 'utils/routes';
import {
    getIsMembershipActive,
    isPartial,
    hasSitter,
    isStandard,
    isPremium,
    isSitterOnly,
    isExpired,
} from 'utils/account';
import { getExperimentalFeatureVariationSelector } from 'containers/ExperimentalFeature/selectors';
import {
    experiments,
    userTypes,
    UTM_SOURCES,
    VariationTypes,
} from 'containers/ExperimentalFeature';
import { setItem } from 'src/universalStorage/actions';
import { getItem } from 'src/universalStorage/selectors';
import { UTM_SOURCE_KEY } from 'src/universalStorage/constants';
import { MembershipType } from 'api/types';
import * as actions from './actions';
import * as selectors from './selectors';
import * as searchSelectors from '../selectors';
import {
    SEARCH_TYPE_RESULTS,
    SEARCH_TYPE_MAP_HIDDEN_CLUSTERS,
    SEARCH_TYPE_TOTAL_AND_GEOFACETS,
    PAGE_NAME_SEARCH_LISTINGS,
    NEW_SITTER_HOMEPAGE_DISMISSED,
    SEARCH_TYPE_EXTENDED_RADIUS_FILTERED_RESULTS,
} from './SearchListings.constants';
import { sitDiscoveryCategory } from './components/SitDiscovery/SitDiscovery.constants';

export const mapStateToProps = (state) => {
    const isLoggedIn = getIsLoggedIn(state);
    const currentMembershipPlan = getAccountCurrentMembershipPlan(state);
    const isChristmasSitsEnabled = selectors.isChristmasSitsEnabled();
    const { variation: sitterHomepageVariation } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.SITTER_HOMEPAGE,
    });

    const isPaidMember = getIsMembershipActive(currentMembershipPlan);
    const isExpiredMember = isExpired(currentMembershipPlan);
    const isPaidOrExpired = isPaidMember || isExpiredMember;

    const { variation: useInfiniteScrollTestVariation, enabled: useInfiniteScrollTestEnabled } =
        getExperimentalFeatureVariationSelector(state, {
            experiment: experiments.USE_INFINITE_SCROLL_LISTINGS,
        });

    const isUseInfiniteScrollVariation =
        useInfiniteScrollTestVariation === VariationTypes.VARIATION1;

    const useInfiniteScroll =
        useInfiniteScrollTestEnabled &&
        isUseInfiniteScrollVariation &&
        isPaidMember &&
        isSitterOnly(currentMembershipPlan);

    const isInfiniteScrollTestEnabled =
        useInfiniteScrollTestEnabled && isPaidMember && isSitterOnly(currentMembershipPlan);

    const isSitterHomepageVariation = sitterHomepageVariation === VariationTypes.VARIATION1;

    const { variation: broadenSitSearchRadiusVariation } = getExperimentalFeatureVariationSelector(
        state,
        {
            experiment: experiments.BROADEN_SIT_SEARCH_RADIUS,
            excludeCombo: [userTypes.PaidUser, userTypes.ExpiredUser],
        }
    );
    const isBroadenSitSearchRadiusVariation =
        broadenSitSearchRadiusVariation === VariationTypes.VARIATION1;

    let discoveryListings = {};

    Object.values(sitDiscoveryCategory).forEach((category) => {
        const discoveryCategoryListings = getSearchListings(state, category);
        discoveryListings = {
            ...discoveryListings,
            [category]: {
                listings: discoveryCategoryListings,
                hasLoaded: hasRequestEnded({
                    state,
                    actionSettings: settings.search.loadListings,
                    objectKey: category,
                }),
            },
        };
    });

    const { variation: sitDiscoveryVariation } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.SIT_DISCOVERY_PAGE,
        excludeCombo: [userTypes.PaidUser, userTypes.ExpiredUser],
    });
    const isSitDiscoveryVariation = sitDiscoveryVariation === VariationTypes.VARIATION1;

    const { variation: sitterFreeTrialVariation } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.FREE_TRIAL_SITTER_V1,
        excludeCombo: [
            userTypes.PaidUser,
            userTypes.ExpiredUser,
            userTypes.PartialOwner,
            userTypes.PartialCombined,
        ],
        excludeUTMSources: [UTM_SOURCES.CJ, UTM_SOURCES.IMPACT],
        utmSource: getItem(state, UTM_SOURCE_KEY)?.name,
    });
    const isSitterFreeTrialVariation = sitterFreeTrialVariation === VariationTypes.VARIATION1;
    const showSitterFreeTrialExperiment =
        isSitterFreeTrialVariation &&
        (!isLoggedIn ||
            (isLoggedIn &&
                getAccountIncompleteMembershipPlan(state).membershipType ===
                    MembershipType.SITTER));

    const {
        enabled: recommendedListingsCarouselEnabled,
        variation: recommendedListingsCarouselVariation,
    } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.RECOMMENDED_CAROUSEL,
        excludeCombo: [userTypes.AnonymousUser, userTypes.PartialUser, userTypes.ExpiredUser],
    });

    return {
        isLoading: selectors.isLoading(state),
        place: selectors.getPlace(state),
        dateRange: selectors.getDateRange(state),
        listings: getSearchListings(state, SEARCH_TYPE_RESULTS),
        listingsTotal: getSearchListingsTotal(state, SEARCH_TYPE_TOTAL_AND_GEOFACETS),
        pageInfo: selectors.getPageInfo(state),
        getPageLink: (page) => selectors.getPageLink(state, page),
        getInfinitePageLink: (page, startingAfter) =>
            selectors.getPageLink(state, page, startingAfter),
        breadcrumbLinks: searchSelectors.getBreadcrumbLinks({
            query: selectors.getFilters(state),
            place: selectors.getPlace(state),
            route: routes.search.listings,
        }),
        alternativeClusterSearchResults: selectors.getSearchListingsQueryFilters(
            state,
            SEARCH_TYPE_MAP_HIDDEN_CLUSTERS
        ),
        listingsGeoBounds: selectors.getListingMapGeoBounds(state),
        mapClusters: selectors.getMapClustersGeoJson(state),
        searchFilters: selectors.getFilters(state),
        seoContent: getSeoContent(state),
        isLoggedIn,
        isPaidMember,
        isPaidOrExpired,
        isPartial: isPartial(currentMembershipPlan),
        isSitterOnly: isSitterOnly(currentMembershipPlan),
        isStandardOrPremium: isStandard(currentMembershipPlan) || isPremium(currentMembershipPlan),
        hasSitterPlan: hasSitter(currentMembershipPlan),
        savedSearches: selectors.getSavedSearches(state),
        showSaveSearchDialog: selectors.getSaveSearchDialog(state),
        isSaveSearchSuccessful: selectors.getIsSaveSearchSuccessful(state),
        savedSearchResponse: selectors.getSavedSearchResponse(state),
        userId: getAccountDetails(state).id,
        searchLevel: searchSelectors.getSearchLevel({ state, page: PAGE_NAME_SEARCH_LISTINGS }),
        isSitterHomepageVariation,
        isNewSitterHomePageModalEnabled: !getItem(state, NEW_SITTER_HOMEPAGE_DISMISSED),
        showLowApplicantsFeatures: isPaidMember,
        showLocalSitsTestFeatures:
            selectors.getLocalSitsABTestVariant(state) === VariationTypes.VARIATION1,
        localSitsABTestVariant: selectors.getLocalSitsABTestVariant(state),
        isGeoPoint: selectors.getIsGeoPoint(state),
        hasSeenLowApplicantsPrompt: !selectors.getShowLowApplicantsPrompt(state),
        router: state.router,
        scrollToResults: selectors.getScrollToResults(state),
        useInfiniteScroll,
        isInfiniteScrollTestEnabled,
        showChristmasSitsFeatures:
            isChristmasSitsEnabled && (isPartial(currentMembershipPlan) || isPaidMember),
        extendedRadiusListings: getSearchListings(
            state,
            SEARCH_TYPE_EXTENDED_RADIUS_FILTERED_RESULTS
        ),
        isBroadenSitSearchRadiusVariation,
        discoveryListings,
        isSitDiscoveryVariation,
        showSitterFreeTrialExperiment,
        recommendedListingsCarouselEnabled,
        recommendedListingsCarouselVariation,
        canonicalPathName: selectors.getCanonicalPathName(state),
    };
};

export const mapDispatchToProps = (dispatch) => ({
    mapZoomedOrPanned: (geoBounds) => {
        dispatch(actions.mapZoomedOrPanned.create(geoBounds));
    },
    hiddenClustersMapSearch: (geoBounds) => {
        dispatch(actions.hiddenClustersMapSearch.create(geoBounds));
    },
    searchFiltersUpdated: (filters, searchMethod) => {
        dispatch(actions.searchFiltersUpdated.create(filters, searchMethod));
    },
    saveSearch: (name) => {
        dispatch(actions.saveSearch.create(name));
    },
    updateSavedSearch: (savedSearch) => {
        dispatch(actions.updateSavedSearch.create(savedSearch));
    },
    deleteSavedSearch: (id, name) => {
        dispatch(actions.deleteSavedSearch.create(id, name));
    },
    openSaveSearchDialog: () => {
        dispatch(actions.openSaveSearchDialog.create());
    },
    resetSaveSearch: () => {
        dispatch(actions.resetSaveSearch.create());
    },
    onCloseNewSitterHomePageModal: () => {
        dispatch(setItem.create(NEW_SITTER_HOMEPAGE_DISMISSED, true));
    },
    onCloseLowApplicantsPrompt: () => {
        dispatch(actions.closeLowApplicantsPrompt.create());
    },
    disableScrolling: () => {
        dispatch(actions.disableScrolling.create());
    },
    changeSuggestedCategory: (suggestedCategory) => {
        dispatch(actions.changeSuggestedCategory.create(suggestedCategory));
    },
    searchDiscoveryCategory: (category) => {
        dispatch(actions.searchDiscoveryCategory.create(category));
    },
    scrollToTop: () => {
        dispatch(actions.scrollToTop.create());
    },
});
