import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import {
    error as errorAction,
    load as pageActionsLoad,
    loaded as pageActionsLoaded,
} from 'containers/Page/actions';
import idCheckSaga from './components/IDCheck/sagas';
import cbcCheckSaga from './components/CriminalBackgroundCheck/sagas';

import { PAGE_ID } from './Verifications.constants';

// This is used to display the country code for the user's verified phone number,
// as well as showing the countries in the dropdown for when the user select's
// a country for their phone number.
export function* loadCountries() {
    yield put(apiActions.places.loadCountries());

    const { status, statusCode } = yield take(
        (res) => res.type === settings.places.loadCountries.DONE
    );

    if (status !== settings.places.loadCountries.SUCCESS) {
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    return true;
}

export function* loadVerificationsStatus() {
    yield put(
        apiActions.verifications.loadStatus({
            forceReload: true,
        })
    );

    const { status, statusCode } = yield take(
        (res) => res.type === settings.verifications.loadStatus.DONE
    );

    if (status !== settings.verifications.loadStatus.SUCCESS) {
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }
    return true;
}

export function* loadProfile() {
    yield put(
        apiActions.sitter.loadProfile({
            forceReload: true,
        })
    );
}

export function* loadVerifications() {
    const verificationsStatusLoaded = yield call(loadVerificationsStatus);

    // We load verificationsStatus first as this is the most critical part of the page.
    // if that fails the page error action is dispatched and we do not load anything else.
    if (verificationsStatusLoaded) {
        yield fork(loadCountries);
        yield fork(loadProfile);
        yield put(pageActionsLoaded.create(PAGE_ID));
        return true;
    }
    return false;
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            loadVerifications
        ),
        fork(idCheckSaga),
        fork(cbcCheckSaga),
    ]);
}
