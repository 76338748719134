import { settings as actions } from '../../actions';

export const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadListingsSavedSearch.SUCCESS: {
            return [
                ...action.data.results,
            ];
        }
        case actions.search.createListingsSavedSearch.SUCCESS: {
            return [
                ...state,
                {
                    ...action.data,
                },
            ];
        }
        case actions.search.updateListingsSavedSearch.SUCCESS: {
            return state.map(item => {
                if (item.id === action.requestData.id) {
                    return {
                        ...item,
                        ...action.requestData
                    };
                }
                return item;
            });
        }
        case actions.search.deleteListingsSavedSearch.SUCCESS: {
            return state.filter(item => item.id !== action.requestData.id);
        }
        default:
            return state;
    }
};
