import { useState, useEffect } from 'react';
import environment from 'environment';
import { parseJwt } from 'utils/strings';
import { isBrowser } from 'utils/funcs';

import useScript from './useScript';

export const isTokenValid = (accessToken) => {
    try {
        parseJwt(accessToken);
        return true;
    } catch {
        return false;
    }
};

export const hasGrantedEmailPermissions = (result) => {
    if (!result.email) {
        // if the user has not share their email then revoke access
        // which would then allow for them to authorize again
        window.google.accounts.id.revoke(result.sub);
        return false;
    }
    return true;
};

const returnError = (error) => ({
    success: false,
    error,
    userData: null,
});

export const getGoogleUserData = (accessToken) => {
    if (!accessToken || !isTokenValid(accessToken)) {
        return returnError('containers_forms_Register_authGoogleInvalidAccessToken');
    }

    const decodeJwtToken = parseJwt(accessToken);

    if (hasGrantedEmailPermissions(decodeJwtToken)) {
        return {
            success: true,
            error: null,
            userData: {
                email: decodeJwtToken.email,
                firstName: decodeJwtToken.given_name,
                lastName: decodeJwtToken.family_name,
                credential: accessToken,
            },
        };
    }
    return returnError('containers_forms_Register_googleEmailPermissionsError');
};

function useGoogle() {
    const [googleLoaded, googleError] = useScript('https://accounts.google.com/gsi/client');
    const [isInitialised, setInitialised] = useState(false);
    const [dataResponse, setDataResponse] = useState({
        success: false,
        error: null,
        userData: null,
    });
    const isWindowAvailable = isBrowser();

    const handleCredentialResponse = (response) => {
        if (response && response.credential && response.credential.length > 0) {
            setDataResponse(getGoogleUserData(response.credential));
        }
    };

    useEffect(() => {
        if (isWindowAvailable) {
            window.googleAsyncInit = () => {
                window?.google?.accounts?.id?.initialize({
                    client_id: environment.vars.google.clientId,
                    callback: handleCredentialResponse,
                });

                setInitialised(true);
                window.THS = window.THS || {};
                window.THS.googleInitialised = true;
            };
        }
    }, [isWindowAvailable]);

    useEffect(() => {
        if (isInitialised) {
            if (document.getElementById('google-sign-up-button')) {
                window.google.accounts.id.renderButton(
                    document.getElementById('google-sign-up-button'),
                    {
                        type: 'standard',
                        theme: 'outline',
                        text: 'signup_with',
                        size: 'large',
                        width: 342,
                        logo_alignment: 'center',
                    }
                );
            }

            if (document.getElementById('google-sign-in-button')) {
                const maxWidth = Math.min(window.screen.width - 48, 384);
                window.google.accounts.id.renderButton(
                    document.getElementById('google-sign-in-button'),
                    {
                        type: 'standard',
                        theme: 'outline',
                        text: 'signin_with',
                        size: 'large',
                        width: maxWidth,
                        logo_alignment: 'center',
                    }
                );
            }
        }
    }, [isInitialised]);

    useEffect(() => {
        if (googleLoaded && !googleError) {
            if (!isInitialised && window.THS && !window.THS.googleInitialised) {
                setInitialised(false);
            }
            // always initialise to make sure the handleCredentialResponse() is set correctly
            window.googleAsyncInit();
        } else if (googleError) {
            setInitialised(false);
        }
        // checks if the script tag already exists in the DOM
        // but was not loaded via the useScript() hook (i.e. was loaded in Angular)
        else if (
            !googleLoaded &&
            !googleError &&
            document.querySelector(`script[src='https://accounts.google.com/gsi/client']`) &&
            window.google
        ) {
            window.googleAsyncInit();
        }
    }, [googleLoaded, googleError, isInitialised]);

    return {
        success: dataResponse.success,
        error: dataResponse.error,
        userData: dataResponse.userData,
        isInitialised,
    };
}

export default useGoogle;
