import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { retry } from 'utils/ssr';
import { getSearchProfiles, getSearchProfilesTotal } from 'api/selectors/search';
import { getOwnerListings } from 'api/selectors/owner';
import asPage from 'containers/Page';

import { NEARBY_PROFILES } from 'config/search';
import { getSeoContent, getIsLoggedIn, getLocale } from 'shared/selectors';
import { getAccountCurrentMembershipPlan } from 'api/selectors';
import { getMembershipStatus } from 'utils/account';
import { routes } from 'utils/routes';
import { MembershipType } from 'api/types';
import { getExperimentalFeatureVariationSelector } from 'containers/ExperimentalFeature/selectors';
import { getAccountIncompleteMembershipPlan } from 'api/selectors/account';
import {
    experiments,
    userTypes,
    VariationTypes,
    UTM_SOURCES,
} from 'containers/ExperimentalFeature';
import { getItem } from 'src/universalStorage/selectors';
import { UTM_SOURCE_KEY } from 'src/universalStorage/constants';
import * as actions from './actions';
import * as selectors from './selectors';
import * as searchSelectors from '../selectors';

import {
    PAGE_ID,
    SEARCH_TYPE_RESULTS,
    PAGE_NAME_SEARCH_PROFILES,
} from './SearchProfiles.constants';

const mapStateToProps = (state) => {
    const { variation } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.FREE_TRIAL_OWNER_V2,
        excludeCombo: [userTypes.PaidUser, userTypes.ExpiredUser],
        excludeUTMSources: [UTM_SOURCES.CJ, UTM_SOURCES.IMPACT],
        utmSource: getItem(state, UTM_SOURCE_KEY)?.name,
    });

    const { enabled: isHowItWorksExpEnabled } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.HOW_IT_WORKS,
        excludeCombo: [userTypes.PaidUser, userTypes.ExpiredUser, userTypes.SitterUser],
    });

    const listings = getOwnerListings(state);
    const ownerListing = listings && listings.length > 0 && listings[0];
    const ownerLocation = ownerListing ? ownerListing.location.name : null;
    const ownerListingApproved = ownerListing ? ownerListing.isApproved : false;
    const isLoggedIn = getIsLoggedIn(state);
    const isFreeTrialVariation = variation === VariationTypes.VARIATION1;
    const showFreeTrialExperiment =
        isFreeTrialVariation &&
        (!isLoggedIn ||
            (isLoggedIn &&
                getAccountIncompleteMembershipPlan(state).membershipType === MembershipType.OWNER));
    const isCatSittersSearch = state.router.location.pathname.includes('cat-sitters');
    let searchFilters = selectors.getFilters(state);
    if (isCatSittersSearch) {
        searchFilters = {
            ...searchFilters,
            filters: {
                ...searchFilters.filters,
                animalExperience: ['cat'],
            },
        };
    }
    return {
        isLoading: selectors.isLoading(state),
        place: selectors.getPlace(state),
        showSittersLookingInYourAreaTestFeatures:
            selectors.getSittersLookingInYourAreaVariant(state) === VariationTypes.VARIATION1,
        sittersLookingInYourAreaTestVariant: selectors.getSittersLookingInYourAreaVariant(state),
        profiles: getSearchProfiles(state, SEARCH_TYPE_RESULTS),
        nearbyProfiles: getSearchProfiles(state, NEARBY_PROFILES.key),
        profilesTotal: getSearchProfilesTotal(state, SEARCH_TYPE_RESULTS),
        pageInfo: selectors.getPageInfo(state),
        getPageLink: (page) => selectors.getPageLink(state, page, isCatSittersSearch),
        breadcrumbLinks: searchSelectors.getBreadcrumbLinks({
            query: selectors.getFilters(state),
            place: selectors.getPlace(state),
            route: !isCatSittersSearch ? routes.search.profiles : routes.search.catProfiles,
        }),
        searchFilters,
        seoContent: getSeoContent(state),
        membershipStatus: getMembershipStatus(getAccountCurrentMembershipPlan(state)),
        isLoggedIn,
        canonicalPathName: selectors.getCanonicalPathName(state, isCatSittersSearch),
        searchLevel: searchSelectors.getSearchLevel({ state, page: PAGE_NAME_SEARCH_PROFILES }),
        locale: getLocale(state),
        router: state.router,
        scrollToResults: selectors.getScrollToResults(state),
        ownerLocation,
        showFreeTrialExperiment,
        hasRegistered: selectors.getHasRegistered(state),
        ownerListingApproved,
        isHowItWorksExpEnabled,
        isCatSittersSearch,
    };
};

const mapDispatchToProps = (dispatch) => ({
    searchFiltersUpdated: (filters) => {
        dispatch(actions.searchFiltersUpdated.create(filters));
    },
    disableScrolling: () => {
        dispatch(actions.disableScrolling.create());
    },
    loadAdditionalProfileData: () => {
        dispatch(actions.loadAdditionalProfileData.create());
    },
});

const LoadableSearchProfiles = loadable(() => retry(() => import('./SearchProfiles')));

const ConnectedSearchProfiles = connect(
    mapStateToProps,
    mapDispatchToProps
)(asPage(withTranslation()(LoadableSearchProfiles), PAGE_ID, true, true));

export default ConnectedSearchProfiles;
