import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

import { baseInitialState } from '../common';

import { uniqueArray } from '../../helpers/format/objects';

export const initialState = {
    ...baseInitialState,
};

export const applicationsByType = {
    confirmed: null,
    pendingApplication: null,
    shortlisted: [],
    invitations: [],
    applications: [],
    declined: [],
    allApplications: [],
};

const nullify = (object, key, check) => {
    if (object === null || object === undefined) return;

    if (object[key] === check) {
        object[key] = null;
    }
};

const removeFromArray = (object, key, value) => {
    if (object === null || object === undefined) return;

    if (object[key] && object[key].indexOf(value) >= 0) {
        const index = object[key].indexOf(value);
        object[key].splice(index, 1);
    }
};

const addToArray = (object, key, value) => {
    if (object === null || object === undefined) return;

    if (object[key] && object[key].indexOf(value) < 0) {
        object[key].push(value);
    }
};

/**
 * Remove a particular application from all of the application sub-groups.
 * @param applications An object holding various sub-groups of applications (invitations, applications, etc)
 * @param application An object representing the application that needs to be removed from the sub-groups
 * @returns {string} prevState A string representing what this application USED to be, based on the sub
 * group it was removed from. The checks are in descending order of precedence and hence we keep the first value
 * that 'prevState' was set to.
 */
function removeApplicationFromAllGroups(applications, application) {
    let prevState;

    // If this application is held in the list of declined applications
    if (applications.declined.indexOf(application.id) >= 0) {
        applications.declined = applications.declined.filter((itemId) => itemId !== application.id);
        prevState = 'declined';
    }

    // If this application is the current confirmed application
    if (applications.confirmed === application.id) {
        applications.confirmed = null;
        prevState = prevState || 'confirmed';
    }

    // If this application is the current pending application
    if (applications.pendingApplication === application.id) {
        applications.pendingApplication = null;
        prevState = prevState || 'pending';
    }

    /**
     * If this application is held in the list of applications
     * 'applications' is different from 'allApplications'
     * 'applications' is a list of all applications made by sitters and still in their initial state (not pending
     * double confirmation, not confirmed, not an invitation, etc)
     * 'allApplications' is a list of all applications in any state (including confirmed, invitations, etc)
     */
    if (applications.applications.indexOf(application.id) >= 0) {
        applications.applications = applications.applications.filter(
            (itemId) => itemId !== application.id
        );
        prevState = prevState || 'application';
    }

    // If this application is held in the list of invitations
    if (applications.invitations.indexOf(application.id) >= 0) {
        applications.invitations = applications.invitations.filter(
            (itemId) => itemId !== application.id
        );
        prevState = prevState || 'invitation';
    }

    return prevState;
}

/**
 * Remove a particular application from all of the application sub-groups and place it in the one correct sub-group.
 * @param applications An object holding various sub-groups of applications (invitations, applications, etc)
 * @param application An object representing the application that needs to be added to the sub-groups
 * @returns {boolean} hasChanged A boolean that represents whether or not the application moved from one sub-group to
 * another
 */
function putApplicationInCorrectGroup(applications, application) {
    if (!application) return;

    let prevState;
    let newState;
    let wasShortListed = false;

    prevState = removeApplicationFromAllGroups(applications, application);

    // If this application is held in the list of shortlisted applications
    if (applications.shortlisted.indexOf(application.id) >= 0) {
        applications.shortlisted = applications.shortlisted.filter(
            (itemId) => itemId !== application.id
        );
        wasShortListed = true;
    }

    // If this application was declined or cancelled
    if (
        application.hasOwnerDeclined ||
        application.hasSitterDeclined ||
        application.hasOwnerCancelled ||
        application.hasSitterCancelled
    ) {
        applications.declined.push(application.id);
        newState = 'declined';
    }
    // If this application has been double confirmed
    else if (
        application.isConfirmed &&
        application.hasOwnerConfirmed &&
        application.hasSitterConfirmed
    ) {
        applications.confirmed = application.id;
        newState = 'confirmed';
    }
    // If this application has been confirmed from owner side, but not double confirmed
    else if (
        !application.isConfirmed &&
        application.hasOwnerConfirmed &&
        !application.hasSitterConfirmed
    ) {
        applications.pendingApplication = application.id;
        newState = 'pending';
    }
    // If this application is an invitation
    else if (application.isInvited && !application.hasSitterConfirmed) {
        applications.invitations.push(application.id);
        newState = 'invitation';
    }
    // If none of the above criteria are met, this is just an application
    else {
        applications.applications.push(application.id);
        newState = 'application';
    }

    if (application.isShortlisted) {
        applications.shortlisted.push(application.id);
    }

    // Add to all applications
    if (applications.allApplications.indexOf(application.id) < 0) {
        applications.allApplications.push(application.id);
    }

    const hasChanged =
        prevState !== newState || Boolean(wasShortListed) !== Boolean(application.isShortlisted);

    return hasChanged;
}

/**
 * Merge together the applications of an assignment returned from an action with the applications of the version of that
 * assignment currently in state.
 * @param newApplications The new applications that were returned from the action
 * @param existingApplications The applications from the version of the assignment currently held in state
 * @returns {Object} allApplications The resultant object with all applications in their correct groups
 */
export function applicationsHelper(newApplications, existingApplications) {
    const allApplications = {
        confirmed: null,
        pendingApplication: null,
        shortlisted: [],
        invitations: [],
        applications: [],
        declined: [],
        allApplications: [],
    };

    if (existingApplications) {
        if (existingApplications.confirmed) {
            allApplications.confirmed = existingApplications.confirmed;
        }
        if (existingApplications.pendingApplication) {
            allApplications.pendingApplication = existingApplications.pendingApplication;
        }
        if (existingApplications.shortlisted) {
            allApplications.shortlisted = [...existingApplications.shortlisted];
        }
        if (existingApplications.invitations) {
            allApplications.invitations = [...existingApplications.invitations];
        }
        if (existingApplications.applications) {
            allApplications.applications = [...existingApplications.applications];
        }
        if (existingApplications.declined) {
            allApplications.declined = [...existingApplications.declined];
        }
        if (existingApplications.allApplications) {
            allApplications.allApplications = [...existingApplications.allApplications];
        }
    }

    if (newApplications) {
        newApplications.forEach((application) =>
            putApplicationInCorrectGroup(allApplications, application)
        );
    }

    return allApplications;
}

export default (state = initialState, action) => {
    switch (action.type) {
        // Add any assignments on a newly loaded listing to the assignments object
        case actions.owner.loadListing.SUCCESS: {
            const listing = action.data;

            if (!listing) return state;
            const { assignments } = listing;

            if (!assignments) return state;

            return {
                ...state,
                data: {
                    ...state.data,
                    ...assignments.reduce((listingAssignments, assignment) => {
                        const oldAssignment = state.data[assignment.id] || {};
                        listingAssignments[assignment.id] = transform.getAssignment({
                            ...assignment,
                            applications: assignment.applications || oldAssignment.applications,
                        });
                        return listingAssignments;
                    }, {}),
                },
            };
        }
        case actions.owner.loadOpenAssignmentApplication.SUCCESS: {
            const assignment = state.data[action.requestData.assignmentId];
            if (!assignment) return state;

            if (!assignment.applications) return state;

            const applications = {
                ...assignment.applications,
            };
            const hasChanged = putApplicationInCorrectGroup(applications, action.data);
            if (hasChanged) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [action.requestData.assignmentId]: {
                            ...state.data[action.requestData.assignmentId],
                            applications,
                        },
                    },
                };
            }

            return state;
        }
        case actions.owner.loadOpenAssignmentApplications.SUCCESS: {
            const currentAssignment = state.data[action.requestData.assignmentId] || {};
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.requestData.assignmentId]: {
                        ...currentAssignment,
                        applications: {
                            ...applicationsHelper(
                                action.data.results,
                                currentAssignment.applications
                            ),
                        },
                    },
                },
            };
        }
        case actions.owner.loadOpenAssignmentInvitations.SUCCESS: {
            const currentAssignment = state.data[action.requestData.assignmentId] || {};
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.requestData.assignmentId]: {
                        ...currentAssignment,
                        applications: {
                            ...applicationsHelper(
                                action.data.results,
                                currentAssignment.applications
                            ),
                        },
                    },
                },
            };
        }
        case actions.owner.notifyConfirmedAssignmentUnconfirmedApplicants.SUCCESS: {
            const assignment = state.data[action.requestData.id];
            if (!assignment) {
                return state;
            }
            const { applications } = assignment;
            if (applications) {
                applications.declined = uniqueArray([
                    ...applications.declined,
                    ...applications.invitations,
                    ...applications.applications,
                    ...applications.shortlisted,
                ]);
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.requestData.id]: {
                        ...assignment,
                        applications,
                    },
                },
            };
        }
        case actions.owner.loadConfirmedAssignments.SUCCESS: {
            const assignments = {};
            action.data.current.forEach((assignment) => {
                const oldAssignment = state.data[assignment.id] || {};
                assignments[assignment.id] = transform.getAssignment({
                    ...assignment,
                    applications: assignment.applications || oldAssignment.applications,
                    isConfirmed: true,
                });
            });
            action.data.upcoming.forEach((assignment) => {
                const oldAssignment = state.data[assignment.id] || {};
                assignments[assignment.id] = transform.getAssignment({
                    ...assignment,
                    applications: assignment.applications || oldAssignment.applications,
                    isConfirmed: true,
                });
            });
            action.data.past.forEach((assignment) => {
                const oldAssignment = state.data[assignment.id] || {};
                assignments[assignment.id] = transform.getAssignment({
                    ...assignment,
                    applications: assignment.applications || oldAssignment.applications,
                    isConfirmed: true,
                });
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    ...assignments,
                },
            };
        }
        case actions.owner.boostOpenAssignment.SUCCESS: {
            const assignment = action.data;

            return {
                ...state,
                data: {
                    ...state.data,
                    [assignment.id]: {
                        ...transform.getAssignment({
                            ...assignment,
                        }),
                    },
                },
            };
        }
        case actions.owner.loadOpenAssignments.SUCCESS:
        case actions.owner.loadListingOpenAssignments.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((assignments, assignment) => {
                        const oldAssignment = state.data[assignment.id] || {};
                        assignments[assignment.id] = {
                            ...transform.getAssignment({
                                ...assignment,
                            }),
                            applications: assignment.applications || oldAssignment.applications,
                        };
                        return assignments;
                    }, {}),
                },
            };
        case actions.owner.loadDeletedAssignments.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((assignments, assignment) => {
                        const oldAssignment = state.data[assignment.id] || {};
                        assignments[assignment.id] = {
                            ...transform.getAssignment(assignment),
                            applications: assignment.applications || oldAssignment.applications,
                            // @TODO - remove when api#3765 done
                            isDeleted: true,
                        };
                        return assignments;
                    }, {}),
                },
            };
        case actions.owner.loadUnconfirmedPastAssignments.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((assignments, assignment) => {
                        const oldAssignment = state.data[assignment.id] || {};
                        assignments[assignment.id] = {
                            ...transform.getAssignment({
                                ...assignment,
                            }),
                            applications: assignment.applications || oldAssignment.applications,
                        };
                        return assignments;
                    }, {}),
                },
            };
        case actions.owner.loadCurrentAssignments.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.reduce((assignments, assignment) => {
                        const oldAssignment = state.data[assignment.id] || {};
                        assignments[assignment.id] = {
                            ...transform.getAssignment({
                                ...assignment,
                                isConfirmed: true,
                            }),
                            applications:
                                assignment.applications || oldAssignment.applications || null,
                        };
                        return assignments;
                    }, {}),
                },
            };
        }
        case actions.owner.loadListingFutureAssignments.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.reduce((assignments, assignment) => {
                        const oldAssignment = state.data[assignment.id] || {};
                        assignments[assignment.id] = {
                            ...transform.getAssignment({
                                ...assignment,
                            }),
                            applications:
                                assignment.applications || oldAssignment.applications || null,
                        };
                        return assignments;
                    }, {}),
                },
            };
        }
        case actions.owner.loadUpcomingAssignments.SUCCESS:
        case actions.owner.loadPastAssignments.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((assignments, assignment) => {
                        const oldAssignment = state.data[assignment.id] || {};
                        assignments[assignment.id] = {
                            ...transform.getAssignment({
                                ...assignment,
                                isConfirmed: true,
                            }),
                            applications:
                                assignment.applications || oldAssignment.applications || null,
                        };
                        return assignments;
                    }, {}),
                },
            };
        }

        case actions.owner.unconfirmConfirmedAssignment.SUCCESS:
        case actions.owner.loadAssignment.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: {
                        ...state.data[action.data.id],
                        ...transform.getAssignment(action.data),
                    },
                },
            };
        case actions.owner.createPastAssignmentReview.SUCCESS: {
            const assignment = state.data[action.requestData.assignmentId];
            return {
                data: {
                    ...state.data,
                    [action.requestData.assignmentId]: {
                        ...assignment,
                        review: {
                            ...action.data,
                        },
                        isReviewProvided: true,
                    },
                },
            };
        }
        case actions.owner.updatePastAssignmentFeedback.SUCCESS: {
            const assignment = state.data[action.requestData.assignmentId];
            return {
                data: {
                    ...state.data,
                    [action.requestData.assignmentId]: {
                        ...assignment,
                        feedback: {
                            ...action.data,
                        },
                    },
                },
            };
        }
        case actions.owner.createPastAssignmentFeedbackRequest.SUCCESS:
            // return setParentPropertyItem(state, 'feedbackRequest', action.data.id, action.data.assignmentId);
            return {
                data: {
                    ...state.data,
                    [action.requestData.assignmentId]: {
                        ...state.data[action.requestData.assignmentId],
                        feedbackRequest: {
                            message: action.data.message,
                            sentAt: action.data.sentAt,
                        },
                    },
                },
            };
        case actions.owner.createListingOpenAssignment.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: {
                        ...transform.getAssignment(action.data),
                        applications: applicationsHelper(),
                    },
                },
            };
        case actions.owner.updateListingOpenAssignment.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: {
                        ...state.data[action.data.id],
                        startDate: action.data.startDate,
                        endDate: action.data.endDate,
                        isApproximateDates: action.data.isApproximateDates,
                    },
                },
            };
        case actions.owner.removeListingOpenAssignment.SUCCESS: {
            const newState = {
                ...state,
            };
            delete newState.data[action.data.id];
            return newState;
        }
        case actions.owner.shareAssignmentWelcomeGuide.SUCCESS:
        case actions.owner.createAssignmentWelcomeGuide.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.requestData.assignmentId]: {
                        ...state.data[action.requestData.assignmentId],
                        welcomeGuideStatus: {
                            ...action.data,
                        },
                    },
                },
            };
        }
        case actions.owner.declineOpenAssignmentApplication.SUCCESS:
        case actions.owner.shortlistOpenAssignmentApplication.SUCCESS:
        case actions.owner.unshortlistOpenAssignmentApplication.SUCCESS:
        case actions.owner.cancelOpenAssignmentInvitation.SUCCESS:
        case actions.owner.confirmOpenAssignmentApplication.SUCCESS:
        case actions.owner.unconfirmOpenAssignmentApplication.SUCCESS: {
            const assignment = {
                ...state.data[action.data.assignmentId],
            };
            if (!assignment) return state;

            const newApplication = { ...action.data };

            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.assignmentId]: {
                        ...state.data[action.data.assignmentId],
                        applications: applicationsHelper([newApplication], assignment.applications),
                    },
                },
            };
        }
        case actions.owner.pauseOpenAssignment.SUCCESS:
        case actions.owner.resumeOpenAssignment.SUCCESS: {
            const assignment = state.data[action.data.id];
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: {
                        ...assignment,
                        isReviewing: action.data.isReviewing,
                    },
                },
            };
        }
        default:
            return state;
    }
};
