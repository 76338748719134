import styled, { css } from 'styled-components';
import Link from 'components/Link';

const BreadcrumbStyled = styled.ol`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
`;

const BreadcrumbItemSeperatorStyled = styled.span`
    margin: ${({ theme }) => `0px ${theme.spacing[1]}`};
`;

const BreadcrumbItemStyled = styled.li`
    display: flex;
    align-items: baseline;

    ${({ isLastItem }) =>
        isLastItem &&
        css`
            color: ${({ theme }) => theme.palette.textLight};
        `};
`;

const BreadcrumbItemTextStyled = styled.span``;

const BreadcrumbItemLinkStyled = styled(Link).attrs({
    variant: Link.Variant.Button.LINK_SUBTLE,
})``;

export {
    BreadcrumbStyled as default,
    BreadcrumbItemSeperatorStyled,
    BreadcrumbItemStyled,
    BreadcrumbItemTextStyled,
    BreadcrumbItemLinkStyled,
};
