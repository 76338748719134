import env from 'environment';

const buildFullPathname = () => `${env.vars.protocol}${env.vars.hostname}`;

// eslint-disable-next-line import/prefer-default-export
export const createBreadcrumbSchemaMarkup = ({ items }) => ({
    '@context': 'http://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => {
        const result = {
            '@type': 'ListItem',
            position: index + 1,
            name: item.text,
        };

        if (typeof item.to === 'string') {
            result.item = `${buildFullPathname()}${item.to}`;
        }

        return result;
    }),
});
