// eslint-disable-next-line import/prefer-default-export
export const USER_PROFILE_EDIT_ABOUT_YOU = {
    create(
        profile = {
            title: '',
            introduction: '',
            placeId: null,
            motivation: '',
            photos: [],
            socialVerifications: {
                airbnb: {
                    id: '',
                    platformIdentifier: '',
                },
                linkedin: {
                    id: '',
                    platformIdentifier: '',
                },
            },
        },
        hasProfilePhoto = false
    ) {
        return {
            type: 'Profile Edit About Update',
            payload: {
                headline: !!profile.title,
                introduction: !!profile.introduction,
                location: !!profile.placeId,
                motivation: !!profile.motivation,
                photosOfYou: profile.photos.length > 0,
                profilePhoto: hasProfilePhoto,
                socialVerifications: {
                    airbnb:
                        !!profile.socialVerifications &&
                        !!profile.socialVerifications.airbnb.platformIdentifier,
                    linkedin:
                        !!profile.socialVerifications &&
                        !!profile.socialVerifications.linkedin.platformIdentifier,
                },
            },
        };
    },
};
