export default {
    hub: '/user/profile/edit/',
    profileDetails: '/user/profile/edit/profile-details',
    aboutYou: '/user/profile/edit/about-you',
    experience: '/user/profile/edit/experience',
    preferences: '/user/profile/edit/preferences',
    profilePreview: '/user/profile/edit/profile-preview',
    references: '/user/profile/edit/references',
    safetySecurity: '/user/profile/edit/safety-security',
};
