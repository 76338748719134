import React, { useState, useEffect } from 'react';
import SearchResultsSection from 'components/SearchProfilesResultsSection';
import Spinner from 'components/Spinner';
import ReferAFriendInline from 'containers/ReferAFriendInline';
import { ContentContainerStyled, HeadingStyled } from 'pages/user/components/common/common.style';
import { track, Events } from 'utils/analytics';
import { scrollViewport } from 'utils/dom';
import { paginationTotalItems, RESULT_SCROLL_OFFSET } from './SavedYourListing.constants';
import {
    SpinnerContainerStyled,
    LabelWrapperStyled,
    LabelTextStyled,
    InfoTipsStyled,
    HeartIconStyled,
    WrapperHeaderStyled,
    InfoBoxStyled,
} from './SavedYourListing.style';

const SavedYourListing = ({ t, profiles, location, pageNumber = 1, totalResults }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, [profiles]);

    const pageInfo = {
        currentPage: pageNumber,
        itemsPerPage: paginationTotalItems,
        totalItems: totalResults,
    };

    const getPageLink = (pageTo) => `${location.pathname}?page=${pageTo}`;

    const searchResultsRef = React.createRef();

    const scrollToSearchResults = () => {
        scrollViewport(searchResultsRef.current, RESULT_SCROLL_OFFSET);
    };

    const onNavigationClick = () => {
        setIsLoading(true);
        scrollToSearchResults();
    };

    const colMediaQueries = {
        span: 6,
        largeTablet: 4,
        wide: 3,
    };

    return (
        <>
            <ContentContainerStyled>
                <WrapperHeaderStyled>
                    <HeartIconStyled size="medium" />
                    <HeadingStyled>{t('pages_user_saved_your_listing')}</HeadingStyled>
                </WrapperHeaderStyled>
                <InfoBoxStyled>
                    <LabelWrapperStyled>
                        <LabelTextStyled data-testid="info-box-title">
                            {!totalResults ? (
                                <span data-testid="info-box-title-no-result">
                                    {t('pages_user_saved_your_listing_info_label_no_result')}
                                </span>
                            ) : (
                                <span data-testid="info-box-title-result">
                                    {t('pages_user_saved_your_listing_info_label', {
                                        count: totalResults,
                                    })}
                                </span>
                            )}
                        </LabelTextStyled>
                    </LabelWrapperStyled>
                    <InfoTipsStyled data-testid="info-box-body">
                        {!totalResults
                            ? t('pages_user_saved_your_listing_info_body_no_result')
                            : t('pages_user_saved_your_listing_info_body')}
                    </InfoTipsStyled>
                </InfoBoxStyled>
                <div ref={searchResultsRef} />
                {isLoading && (
                    <SpinnerContainerStyled>
                        <Spinner />
                    </SpinnerContainerStyled>
                )}
                {!isLoading && !!totalResults && (
                    <SearchResultsSection
                        t={t}
                        profiles={profiles}
                        onNavigationClick={onNavigationClick}
                        pageInfo={pageInfo}
                        getPageLink={getPageLink}
                        colMediaQueries={colMediaQueries}
                    />
                )}
            </ContentContainerStyled>

            {totalResults > 0 ? (
                <ReferAFriendInline
                    review={0}
                    campaign="raf-saved-listings"
                    onShare={(method) => {
                        track(
                            Events.BUTTONCLICK.create({
                                description: 'RAF one-touch box',
                                item: method,
                                location: 'saved-your-listings',
                            })
                        );
                    }}
                />
            ) : null}
        </>
    );
};

export default SavedYourListing;
