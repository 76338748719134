import React from 'react';
import Carousel from 'components/Carousel';
import PostItem from './components/PostItem';
import { HeadingStyled } from './BlogPostCarousel.style';

const BlogPostsCarousel = ({ posts, heading }) => {
    if (!posts || posts.length === 0) {
        return null;
    }

    return (
        <>
            {heading && <HeadingStyled>{heading}</HeadingStyled>}
            <Carousel
                perPage={{
                    mobile: 1.4,
                    largeHandset: 1.8,
                    mediumTablet: 2.6,
                    mediumDesktop: 3,
                    largeDesktop: 4,
                    wide: 4,
                    extraWide: 4,
                }}
                data-testid="blog-posts-carousel-carousel"
            >
                {posts.map(({ category: postCategory, image, excerpt, title, url }) => (
                    <PostItem
                        key={url}
                        category={postCategory}
                        image={image}
                        excerpt={excerpt}
                        title={title}
                        url={url}
                    />
                ))}
            </Carousel>
        </>
    );
};

BlogPostsCarousel.defaultProps = {
    heading: null,
};

export default BlogPostsCarousel;
