import { useContext } from 'react';
import { RegwallDialogContext } from './RegwallDialog.context';

export function useRegwallDialog() {
    const context = useContext(RegwallDialogContext);

    if (typeof context === 'undefined') {
        throw new TypeError(
            `\`useRegwallDialog\` should be used inside \`RegwallDialogContext.Provider\`.`
        );
    }

    return context;
}

export default useRegwallDialog;
