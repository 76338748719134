import { settings as actions } from '../actions';

export const initialState = {
    countries: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.places.loadCountries.SUCCESS:
            return {
                ...state,
                countries: action.data,
            };
        default:
            return state;
    }
};
