import { all, put, takeEvery, take, select } from 'redux-saga/effects';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import actions, { settings } from 'api/actions';
import { SEARCH_TYPE } from 'config/search';
import * as pageActions from 'containers/Page/actions';
import { getMembershipType } from 'utils/account';
import { MembershipType } from 'api/types';
import { getOwnerListings } from 'api/selectors/owner';
import { track, Events } from 'utils/analytics';
import { routes } from 'utils/routes';
import { getSearchProfile } from 'api/selectors';
import { PAGE_ID, REDIRECT_MESSAGE_TYPES } from './ProfileDetail.constants';
import { getProfileDetailParams } from './selectors';
import * as profileDetailActions from './actions';
import configureSittersLookingInYourAreaTestActive from '../SearchProfiles/sagas/configureSittersLookingInYourAreaTestActive';

export function* loadProfile({ type, params }) {
    yield put(profileDetailActions.profileLoaded.create(false));
    yield configureSittersLookingInYourAreaTestActive();

    // Stores the blocking api actions
    const apiActionsToWaitOn = {};

    const membershipPlan = yield select(getAccountCurrentMembershipPlan);
    const membershipType = getMembershipType(membershipPlan);

    if (membershipType === MembershipType.OWNER || membershipType === MembershipType.COMBINED) {
        const ownerListings = yield select(getOwnerListings);

        if (ownerListings.length < 1) {
            // Owner listings not loaded so load
            apiActionsToWaitOn.loadListingsResponse = take([
                settings.owner.loadListings.SUCCESS,
                settings.owner.loadListings.FAILURE,
                settings.owner.loadListings.ERROR,
            ]);

            yield put(actions.owner.loadListings());
        }

        // Owner favourites not loaded so load
        apiActionsToWaitOn.loadFavouritesResponse = take([
            settings.owner.loadFavourites.SUCCESS,
            settings.owner.loadFavourites.FAILURE,
            settings.owner.loadFavourites.ERROR,
        ]);
        yield put(actions.owner.loadFavourites({ forceReload: true }));
    }

    // Now load profile
    apiActionsToWaitOn.loadProfileResponse = take([
        settings.search.loadProfile.SUCCESS,
        settings.search.loadProfile.FAILURE,
        settings.search.loadProfile.ERROR,
    ]);

    yield put(
        actions.search.loadProfile({
            forceReload: true,
            data: {
                id: params.id,
            },
        })
    );

    // Block until api responses are completed
    const { loadProfileResponse } = yield all(apiActionsToWaitOn);

    // If not successful then redirect the user to search profiles page with filters applied
    if (loadProfileResponse.type !== settings.search.loadProfile.SUCCESS) {
        const profileDetailParams = yield select(getProfileDetailParams);

        yield put(
            pageActions.redirect.create(
                PAGE_ID,
                routes.search.profiles(profileDetailParams),
                REDIRECT_MESSAGE_TYPES.PROFILE_ERROR
            )
        );

        return false;
    }

    // Here we're checking if the current pathname on URL is the same as the returned
    // from API; if it's different, redirect to the API response.
    if (loadProfileResponse.type === settings.search.loadProfile.SUCCESS) {
        const buildPathname = routes.search.profilesDetail;
        const currentPathname = buildPathname(yield select(getProfileDetailParams));
        const { data } = loadProfileResponse;
        const responsePathname = buildPathname({
            id: data.id,
            ...data.location,
        });

        if (currentPathname !== responsePathname) {
            yield put(pageActions.redirect.create(PAGE_ID, responsePathname));

            return false;
        }
    }

    yield put(profileDetailActions.profileLoaded.create(true));

    // If successful then show as loaded
    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(PAGE_ID));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(PAGE_ID));
    }

    return true;
}

function* lazyload(action) {
    const { params, search, pathname } = action;
    // Log analytics on preload
    const profileReviews = yield select(
        (state) => getSearchProfile(state, params.id)?.reviewsCount
    );
    const searchParams = new URLSearchParams(search);
    const trackObject = JSON.parse(decodeURIComponent(searchParams.get('t')));
    const productTrigger = trackObject?.productTrigger || null;

    track(
        Events.SEARCH_DETAILS.create({
            category: SEARCH_TYPE.Profile,
            id: params.id,
            suggestedCategory: null,
            totalReviews: profileReviews,
            productTrigger,
        })
    );

    searchParams.delete('t');
    const newSearch = searchParams.toString();
    const newUrl = `${pathname}?${newSearch}`;
    window.history.replaceState({}, '', newUrl);

    yield put(pageActions.lazyloaded.create(PAGE_ID));
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            loadProfile
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            loadProfile
        ),
        takeEvery(
            (action) => action.type === pageActions.lazyload.ACTION && action.pageId === PAGE_ID,
            lazyload
        ),
    ]);
}
