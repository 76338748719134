import { StateContextValues } from './reducer';

export function getFirstName(name) {
    if (typeof name !== 'string') {
        return '';
    }

    const [first] = name.split(' ');

    return first;
}

export function validateSubmitValues(values) {
    if (!values) {
        return false;
    }

    if (values.rating <= 0 || values.rating > 5) {
        return false;
    }

    if (values.description.length === 0) {
        return false;
    }

    return true;
}
