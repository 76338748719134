import { select } from 'redux-saga/effects';
import { getParams, getFilters } from '../selectors';
import navigateTo from './navigateTo';

/**
 * Loads new results based on the map interaction
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export default function* mapZoomedOrPanned(action) {
    const params = yield select(getParams);
    const existingFilters = yield select(getFilters);

    const filters = {
        ...existingFilters.filters,
        sort: existingFilters.sort,
        geoBounds: action.geoBounds,
    };

    // We are supplying geoBounds so get rid of the existing geo filters, if they exist
    if (filters.geoHierarchy) delete filters.geoHierarchy;
    if (filters.geoPoint) delete filters.geoPoint;

    yield navigateTo(params, filters, 1);
}
