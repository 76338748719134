/* eslint-disable import/prefer-default-export */
export const truncateText = (text, maxCharacters = 0, showEllipsis = false) => {
    if (!text || maxCharacters === 0) return text;

    /*
     * Extend maxCharacter by one character
     * This is so that if maxCharacters lands at the end of a word we
     * trim to that word not the one before it
     */
    const newText = text?.substr(0, maxCharacters + 1).replace(/[\s,.;]+\S*$/, '');
    return showEllipsis ? `${newText}…` : newText;
};
