import { put, take, all, call, takeEvery } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';

import {
    load as pageActionsLoad,
    error as errorAction,
    loaded as pageActionsLoaded,
} from 'containers/Page/actions';
import { createListingsSearchQuery } from 'utils/searchListings';
import { base64encode } from 'utils/strings';

import { savedSearchLoadDone as savedSearchLoadDoneAction } from './actions';
import { PAGE_ID } from './SavedSearchRedirect.constants';

export function* loadSavedSearch(params) {
    const { savedSearchId } = params;

    // Load saved search
    yield put(
        apiActions.search.loadListingSavedSearch({
            forceReload: true,
            data: {
                id: savedSearchId,
            },
        })
    );

    // Wait for results
    const { status, statusCode, data } = yield take(
        (res) => res.type === settings.search.loadListingSavedSearch.DONE
    );

    if (status !== settings.search.loadListingSavedSearch.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    const searchQuery = createListingsSearchQuery({
        searchPastAssignments: false,
        filters: {
            ...data.filters,
            sortBy: ['newest'],
        },
        searchPastAndFutureAssignments: false,
    });
    const queryString = `${base64encode(JSON.stringify(searchQuery.getRequestData()))}`;
    const savedSearchName = data.name;

    yield put(savedSearchLoadDoneAction.create(queryString, savedSearchName));

    // Successful page load
    yield put(pageActionsLoaded.create(PAGE_ID));
    return true;
}

export function* load(action) {
    yield call(loadSavedSearch, action.params);
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
    ]);
}
