import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneNumber from 'containers/PhoneNumber';
import { Button } from 'components/buttons';
import { IsoCode } from 'containers/ShowInCountry';
import { Events, track } from 'utils/analytics';

import { isUserInAMajorMarketArea } from '../../helpers';
import MSProfilePicture01 from './assets/ms-member-01.png';
import MSProfilePicture02 from './assets/ms-member-02.png';
import {
    CallButton,
    CallPhoneIcon,
    OpenCallContainer,
    OpenCallAnchor,
    OpenCallIcon,
    ContactProfilePictureWrapper,
    ContactProfilePicture,
    NumberMessageContainer,
    ContactNumber,
    ContactMessage,
    CloseCallButton,
    CloseCallIcon,
} from './Call.style';

export const CallTestIds = {
    openCallContainer: 'Call_openCallContainer',
    closedCallButton: 'Call_closedCallButton',
};

const getProfilePicture = (imageOptions) =>
    imageOptions[Math.floor(Math.random() * imageOptions.length)];

const Call = ({ isCallOpen, setIsCallOpen, disableButton }) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const profilePictures = [MSProfilePicture01, MSProfilePicture02];

    // close the call container when clicking outside the component
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsCallOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [setIsCallOpen]);

    return (
        <>
            {isCallOpen ? (
                <OpenCallContainer ref={ref} data-testid={CallTestIds.openCallContainer}>
                    <PhoneNumber>
                        {({ phoneNumberShort, phoneNumber, freephoneMessage }) => (
                            <>
                                <OpenCallAnchor
                                    href={`tel:${phoneNumberShort}`}
                                    variant={Button.Variant.SECONDARY}
                                    onClick={() => {
                                        track(
                                            Events.BUTTONCLICK.create({
                                                description: 'Contact bubble CTA',
                                                item: 'Call link',
                                            })
                                        );
                                        setIsCallOpen(false);
                                    }}
                                >
                                    <OpenCallIcon />
                                </OpenCallAnchor>

                                <IsoCode>
                                    {(isoCode) => (
                                        // has to be wrapped in a separate div
                                        // to prevent the call container doing a weird jump when being opened
                                        <ContactProfilePictureWrapper
                                            isInAMajorMarket={isUserInAMajorMarketArea(isoCode)}
                                        >
                                            <ContactProfilePicture
                                                src={getProfilePicture(profilePictures)}
                                                alt="Membership Services Member"
                                            />
                                        </ContactProfilePictureWrapper>
                                    )}
                                </IsoCode>

                                <NumberMessageContainer>
                                    <ContactNumber
                                        variant={Button.Variant.LINK}
                                        href={`tel:${phoneNumberShort}`}
                                        onClick={() => {
                                            track(
                                                Events.BUTTONCLICK.create({
                                                    description: 'Contact bubble CTA',
                                                    item: 'Call link',
                                                })
                                            );
                                            setIsCallOpen(false);
                                        }}
                                    >
                                        {phoneNumber}
                                    </ContactNumber>
                                    <ContactMessage>
                                        {freephoneMessage && freephoneMessage.length > 0
                                            ? t('components_supportWidget_24_7_with_message', {
                                                  message: t(freephoneMessage),
                                              })
                                            : t('components_supportWidget_24_7_default')}
                                    </ContactMessage>
                                </NumberMessageContainer>
                            </>
                        )}
                    </PhoneNumber>

                    <CloseCallButton
                        variant={Button.Variant.SECONDARY}
                        onClick={() => setIsCallOpen(false)}
                    >
                        <CloseCallIcon />
                    </CloseCallButton>
                </OpenCallContainer>
            ) : (
                <CallButton
                    variant={Button.Variant.SECONDARY}
                    onClick={() => {
                        track(
                            Events.BUTTONCLICK.create({
                                description: 'Contact bubble CTA',
                                item: 'phone',
                            })
                        );
                        setIsCallOpen(true);
                    }}
                    disabled={disableButton}
                    data-testid={CallTestIds.closedCallButton}
                >
                    <CallPhoneIcon />
                </CallButton>
            )}
        </>
    );
};

export default Call;
