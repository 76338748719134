import styled from 'styled-components';

const SpinnerContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing[8]};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        margin-top: 150px;
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { SpinnerContainerStyled };
