import styled from 'styled-components';
import { IconChevronDown } from 'components/Icon';

// these stlyes have mostly been taken from https://github.com/mdo/wtf-forms/blob/gh-pages/wtf-forms.css
export const SelectContainerStyled = styled.div`
    position: relative;
    display: flex;
    color: currentColor;
    font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const PlaceholderOptionStyled = styled.option`
    color: ${({ theme }) => theme.palette.textLight};
`;

export const IconStyled = styled(IconChevronDown)`
    font-size: inherit;
    height: 100%;
    position: absolute;
    right: 1em;
    pointer-events: none;
`;

export const SelectStyled = styled.select`
    font-size: inherit;
    display: inline-block;
    flex: 1 0 auto;
    margin: 0;
    // 2.5em padding right includes 16px (1em) for the down icon.
    // 16px for the padding betweem the icon and the right border
    // 8px padding between the option text and the down icon
    padding: 0.5em 2.5em 0.5em 1em;
    line-height: 1.5;
    color: ${({ theme }) => theme.palette.text};
    text-align-last: left;

    border: ${({ theme, error }) =>
        error ? `2px solid ${theme.palette.danger}` : `1px solid ${theme.palette.grey[400]}`};
    border-radius: ${({ theme }) => theme.borderRadius.small};

    background-color: white;

    cursor: pointer;
    appearance: none;

    &::-ms-expand {
        display: none;
    }
`;
