// eslint-disable-next-line import/prefer-default-export
export const USER_LISTING_EDIT_OWNER_BOOST = {
    create(assignment) {
        const {
            id: assignmentId,
            listingId,
            applicationsCount = 0,
            boostedCount,
            boostedDatetime,
            endDate,
            startDate,
            invitationsCount = 0,
            isApproximateDates,
            ownerId,
        } = assignment;
        const payload = {
            assignmentId,
            listingId,
            applicationsCount,
            boostedCount,
            boostedDatetime,
            endDate,
            startDate,
            invitationsCount,
            isApproximateDates,
            ownerId,
        };
        return {
            type: 'Owner Boost',
            payload,
        };
    },
};
