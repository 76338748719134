import { IconCheck } from 'components/Icon';
import styled from 'styled-components';
import { ContainerStyled } from '../Root/Root.style';

// eslint-disable-next-line import/prefer-default-export
export const CheckMarkStyled = styled(IconCheck)`
    visibility: hidden;
    font-size: 20px;

    ${ContainerStyled}[data-status='selected'] & {
        visibility: visible;
        align-items: center;
    }
`;
