import React from 'react';
import { FlexboxGrid, Col } from 'components/layouts';
import loadable from '@loadable/component';
import { retry } from 'utils/ssr';
import ProfileCard from 'containers/ProfileCard';
import ProfileSearchCard from 'components/ProfileSearchCard';
import environment from 'src/environment';
import { routes } from 'utils/routes';
import ExperimentalFeature, { experiments, userTypes } from 'containers/ExperimentalFeature';
import {
    FreeTrialWrapperWrapper,
    sitterGridConfig,
    ProfileSearchCardWrapper,
    ProfileCardsCol,
} from './SearchProfilesResults.style';
import CallOut from './components/CallOut';

const FreeTrialBanner = loadable(() => retry(() => import('components/FreeTrialBanner')));

const SearchResults = ({
    profiles = [],
    showTitleDescription = false,
    showProfileVariation = false,

    colMediaQueries = {
        span: 6,
        mediumTablet: 4,
        mediumDesktop: 3,
        largeDesktop: 2,
    },

    showCBC,
    isAnonOrPartial,
    showFreeTrialExperiment,
    isLoggedIn = false,
    showSittersLookingInYourAreaTestFeatures,
    ownerListingApproved,
}) => {
    const getProfileDetailLink = (profile) =>
        routes.search.profilesDetail({ ...profile.location, id: profile.id });
    const ProfilesCircle = () => (
        <FlexboxGrid config={sitterGridConfig} justify={FlexboxGrid.Justify.FLEX_START}>
            {isAnonOrPartial && <CallOut showCBC={showCBC} isVariation={false} />}
            {showFreeTrialExperiment && (
                <FreeTrialWrapperWrapper>
                    <FreeTrialBanner
                        isFullWidth={false}
                        isLoggedIn={isLoggedIn}
                        headerKey="components_free_trial_banner_header_apply"
                    />
                </FreeTrialWrapperWrapper>
            )}
            {profiles.map((profile, i) =>
                profile ? (
                    <Col {...colMediaQueries} key={`profile_${profile.id}`} order={i}>
                        <ProfileCard
                            id={profile.id}
                            key={profile.id}
                            href={getProfileDetailLink(profile)}
                            profile={profile}
                            profilesDefaultImage={environment.vars.profiles.defaultImage}
                            showTitleDescription={showTitleDescription}
                            showSittersLookingInYourAreaTestFeatures={
                                showSittersLookingInYourAreaTestFeatures
                            }
                            ownerListingApproved={ownerListingApproved}
                        />
                    </Col>
                ) : null
            )}
        </FlexboxGrid>
    );
    return (
        <>
            {showProfileVariation ? (
                <ExperimentalFeature
                    experiment={experiments.SEARCH_PROFILE_LAYOUT}
                    excludeCombo={[
                        userTypes.PaidUser,
                        userTypes.AnonymousUser,
                        userTypes.ExpiredUser,
                    ]}
                    analytics={false}
                    control={<ProfilesCircle />}
                    variation1={
                        <FlexboxGrid config={sitterGridConfig} justify={FlexboxGrid.Justify.CENTER}>
                            {isAnonOrPartial && (
                                <ProfileCardsCol
                                    span={12}
                                    smallDesktop={10}
                                    largeDesktop={9}
                                    key="callout"
                                    order={1}
                                >
                                    <CallOut showCBC={showCBC} isVariation />
                                </ProfileCardsCol>
                            )}
                            {profiles.map((profile, i) =>
                                profile ? (
                                    <ProfileCardsCol
                                        span={12}
                                        smallDesktop={10}
                                        largeDesktop={9}
                                        key={`profile_${profile.id}`}
                                        order={i}
                                    >
                                        <ProfileSearchCardWrapper
                                            hasBorder={i > 1 || (i > 0 && !isAnonOrPartial)}
                                        >
                                            <ProfileSearchCard
                                                id={profile.id}
                                                key={profile.id}
                                                href={getProfileDetailLink(profile)}
                                                profile={profile}
                                                profilesDefaultImage={
                                                    environment.vars.profiles.defaultImage
                                                }
                                                showTitleDescription={showTitleDescription}
                                            />
                                        </ProfileSearchCardWrapper>
                                    </ProfileCardsCol>
                                ) : null
                            )}
                        </FlexboxGrid>
                    }
                />
            ) : (
                <ProfilesCircle />
            )}
        </>
    );
};

export default SearchResults;
