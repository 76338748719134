import { Button } from 'components/buttons';

const prismicVariantToButtonVariant = {
    teal: Button.Variant.PRIMARY,
    pink: Button.Variant.CTA,
    outline: Button.Variant.SECONDARY,
};

const alignmentMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

export { prismicVariantToButtonVariant, alignmentMap };
