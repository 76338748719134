import { useMemo } from 'react';
import optimizely from 'utils/integrations/optimizely';
import { ANONYMOUS_USER_ID } from 'containers/ExperimentalFeature';

export const DEFAULT_OPTIONS = {
    userId: ANONYMOUS_USER_ID,
    attributes: {},
};

function useOptimizelyFeature(name, options) {
    const isFeatureEnabled = useMemo(() => {
        const client = optimizely.getClient();
        const { userId, attributes } = {
            ...DEFAULT_OPTIONS,
            ...(options || {}),
        };

        return client.isFeatureEnabled(name, userId, attributes);
    }, [name, options]);

    return isFeatureEnabled;
}

export default useOptimizelyFeature;
