import { settings as actions } from '../../actions';

import {
    baseInitialResultsListState,
    loadedResultsUniqueListReducer,
} from '../common';

export const initialState = { ...baseInitialResultsListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadUnconfirmedPastAssignments.SUCCESS:
            return loadedResultsUniqueListReducer(state, action);
        default:
            return state;
    }
};
