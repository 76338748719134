/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { P, H4 } from '../Typography';

const topTooltipStyles = ({ theme: { spacing, palette } }) => css`
    left: 50%;
    bottom: 100%;
    margin-bottom: ${spacing[2]};
    transform: translateX(-50%);

    ::before,
    ::after {
        border-left: ${spacing[2]} solid transparent;
        border-right: ${spacing[2]} solid transparent;
        top: 100%;
        left: 50%;
        margin-left: -${spacing[2]};
    }

    ::before {
        border-top: ${spacing[1.5]} solid rgba(0, 0, 0, 0.06);
        margin-top: 1px;
    }

    ::after {
        border-top: ${spacing[1.5]} solid ${palette.white};
    }
`;

const bottomTooltipStyles = ({ theme: { spacing, palette } }) => css`
    left: 50%;
    top: 100%;
    margin-top: ${spacing[2]};
    transform: translateX(-50%);

    ::before,
    ::after {
        border-left: ${spacing[2]} solid transparent;
        border-right: ${spacing[2]} solid transparent;
        bottom: 100%;
        left: 50%;
        margin-left: -${spacing[2]};
    }

    ::before {
        border-bottom: ${spacing[1.5]} solid rgba(0, 0, 0, 0.06);
        margin-bottom: 1px;
    }

    ::after {
        border-bottom: ${spacing[1.5]} solid ${palette.white};
    }
`;

const rightTooltipStyles = ({ theme: { spacing, palette } }) => css`
    left: 100%;
    top: 50%;
    margin-left: ${spacing[2]};
    transform: translateY(-50%);

    ::before,
    ::after {
        border-top: ${spacing[2]} solid transparent;
        border-bottom: ${spacing[2]} solid transparent;
        bottom: 50%;
        right: 100%;
        margin-bottom: -${spacing[2]};
    }

    ::before {
        border-right: ${spacing[1.5]} solid rgba(0, 0, 0, 0.06);
        margin-right: 1px;
    }

    ::after {
        border-right: ${spacing[1.5]} solid ${palette.white};
    }
`;

const leftTooltipStyles = ({ theme: { spacing, palette } }) => css`
    right: 100%;
    top: 50%;
    margin-right: ${spacing[2]};
    transform: translateY(-50%);

    ::before,
    ::after {
        border-top: ${spacing[2]} solid transparent;
        border-bottom: ${spacing[2]} solid transparent;
        bottom: 50%;
        left: 100%;
        margin-bottom: -${spacing[2]};
    }

    ::before {
        border-left: ${spacing[1.5]} solid rgba(0, 0, 0, 0.06);
        margin-left: 1px;
    }

    ::after {
        border-left: ${spacing[1.5]} solid ${palette.white};
    }
`;

export const TooltipBox = styled.div(
    ({ direction, theme: { palette } }) => css`
        width: 100%;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.04);
        background-color: ${palette.white};
        z-index: 1;
        visibility: hidden;

        ::before,
        ::after {
            content: '';
            position: absolute;
        }

        ${direction === 'top' && topTooltipStyles};
        ${direction === 'bottom' && bottomTooltipStyles};
        ${direction === 'left' && leftTooltipStyles};
        ${direction === 'right' && rightTooltipStyles};
    `
);

export const TooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;

    :hover ${TooltipBox} {
        visibility: visible;
    }
`;

export const TooltipHeading = styled(H4)(
    ({ theme: { spacing } }) => css`
        padding: ${spacing[2]};
        margin: 0;
    `
);

export const TooltipDivider = styled.hr(
    ({ theme: { palette } }) => css`
        margin: 0;
        color: ${palette.borderLight};
        border-width: 0 0 1px 0;
    `
);

export const TooltipBody = styled(P)(
    ({ theme: { spacing } }) => css`
        margin: 0;
        padding: ${spacing[2]};
    `
);
