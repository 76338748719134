import { END } from 'redux-saga';
import { call, spawn, put, race, take, select, actionChannel, flush } from 'redux-saga/effects';
import { isValid, add, isAfter } from 'date-fns';
import { parseDate } from 'api/helpers/format/date';
import * as pageActions from 'containers/Page/actions';
import { setItem } from 'src/universalStorage/actions';
import { getItem } from 'src/universalStorage/selectors';
import * as constants from './constants';
import * as actions from './actions';
import * as selectors from './selectors';

/**
 * Try to get the values from the storage and filter out all the registries that
 * have already opened before and the `waitFor` is not met yet.
 * If the storage is `undefined`, we just return all the registries.
 */
export function* filterRegistry(registry) {
    const value = yield select((state) => getItem(state, constants.STORAGE_KEY));

    if (!value) {
        return registry;
    }

    return registry.filter(({ name }) => {
        const storageValue = value[name];

        if (typeof storageValue === 'undefined') {
            return true;
        }

        if (typeof storageValue === 'boolean') {
            return false;
        }

        if (typeof storageValue !== 'string') {
            return true;
        }

        const parsedNextDate = parseDate(storageValue);

        if (!isValid(parsedNextDate)) {
            return true;
        }

        if (!isAfter(new Date(), parsedNextDate)) {
            return false;
        }

        return true;
    });
}

/**
 * When a prompt is opened, we persist the day inside the storage calculating
 * the next day we need to check if it'll be possible to open it again.
 */
export function* persistRegistry(registry) {
    const value = yield select((state) => getItem(state, constants.STORAGE_KEY));

    yield put(
        setItem.create(constants.STORAGE_KEY, {
            ...(value || {}),
            [registry.name]:
                typeof registry.waitFor === 'object' ? add(new Date(), registry.waitFor) : true,
        })
    );
}

export function* tryOpenRegistry(registries) {
    // `REGISTRY` is already sorted, we just loop through trying to open some
    // prompt, if possible.
    for (let i = 0; i < registries.length; i += 1) {
        const registry = registries[i];

        yield put({
            type: registry.action,
        });

        const { success } = yield race({
            success: take(actions.success.ACTION),
            failure: take(actions.failure.ACTION),
        });

        // If we successed to open some of the available prompts, we stop trying
        // to open any other prompt, and just return from this function.
        // We stop trying to open other prompt because we never want to show
        // multiple prompts at the same time.
        if (typeof success !== 'undefined') {
            yield put(actions.remove.create(registry));

            return registry;
        }
    }

    return null;
}

export function registryFromBuffer(buffer) {
    const registry = [];

    buffer.forEach(({ config }) => {
        if (typeof config.name !== 'string') {
            throw new TypeError('Field `name` is required');
        }

        if (typeof config.action !== 'string') {
            throw new TypeError('Field `action` is required');
        }

        if (typeof config.priority !== 'number') {
            throw new TypeError('Field `number` is required');
        }

        registry.push(config);
    });

    registry.sort((a, b) => {
        if (a.priority > b.priority) {
            return -1;
        }

        if (b.priority > a.priority) {
            return 1;
        }

        return 0;
    });

    return registry;
}

export function* watchRegister() {
    const channel = yield actionChannel(actions.register.ACTION);

    try {
        while (true) {
            yield take(
                (action) => action.type === pageActions.lazyload.ACTION && action.pageId !== 'App'
            );

            const buffer = yield flush(channel);

            if (buffer !== END) {
                const prompts = yield select(selectors.getPrompts);
                const registry = yield call(registryFromBuffer, [
                    ...buffer,
                    ...prompts.map((p) => ({ config: p })),
                ]);
                const available = yield call(filterRegistry, registry);

                /**
                 * Collect the initial prompts on the first load. These prompts
                 * can be opened on any part of the website, so we need to store
                 * them and try to open if possible later.
                 */
                if (!(yield select(selectors.getIsHydrated))) {
                    yield put(actions.collect.create(available));
                }

                const maybeRegistry = yield call(tryOpenRegistry, available);

                if (maybeRegistry) {
                    yield call(persistRegistry, maybeRegistry);
                }
            }
        }
    } finally {
        channel.close();
    }
}

export default function* sagas() {
    yield spawn(watchRegister);
}
