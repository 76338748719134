import styled, { css } from 'styled-components';
import { visuallyHide } from 'utils/utils';

const hasFocusState = css`
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.primarySoft};
`;

const checkedState = css`
    background-color: ${({ theme }) => theme.palette.primaryRegular};
    border: 2px solid ${({ theme }) => theme.palette.primaryRegular};
`;

const BackgroundStyled = styled.span`
    box-sizing: border-box;
    font-size: ${({ small, large, theme }) => {
        const { fontSizes } = theme;

        if (small) return fontSizes.small;
        if (large) return fontSizes.large;

        return fontSizes.medium;
    }};
    padding: 0;
    width: 3em;
    height: 2em;
    position: relative;
    background-color: white;
    border: 2px solid ${({ theme }) => theme.palette.border};
    border-radius: 2.5em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: background-color 150ms, border 150ms;

    ${({ isChecked }) => (isChecked ? checkedState : undefined)};
    ${({ hasFocus }) => (hasFocus ? hasFocusState : undefined)};
`;

const checkedStateHandle = css`
    border: 1px solid ${({ theme }) => theme.palette.primaryRegular};
    box-shadow: 0 2px 3px 0 hsla(0, 0%, 0%, 0.2);
`;

const ToggleStyled = styled.span`
    box-sizing: border-box;
    font-size: inherit;
    width: calc(2em - 2px); // 2px is the border width
    height: calc(2em - 2px);
    border: 1px solid ${({ theme }) => theme.palette.border};
    border-radius: 50%;
    background-color: white;
    transform: ${({ isChecked }) => (isChecked ? 'initial' : 'translateX(-14px);')};
    transition: 150ms;
    box-shadow: 0 2px 3px 0 ${({ theme }) => theme.palette.border};

    ${({ isChecked }) => (isChecked ? checkedStateHandle : undefined)};
`;

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
`;

const WrapperLabelStyled = styled.label`
    display: flex;
    align-items: ${({ alignStart }) => (alignStart ? 'flex-start' : 'center')};
    justify-content: ${({ fullWidth }) => (fullWidth ? 'space-between' : 'flex-start')};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    ${({ isDisabled }) => (isDisabled ? 'opacity: 0.5;' : undefined)};
`;

const CheckboxStyled = styled.input`
    ${visuallyHide};

    &:focus + label {
        span:last-child {
            box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.focus};
        }
    }
`;

export { BackgroundStyled, ToggleStyled, WrapperStyled, WrapperLabelStyled, CheckboxStyled };
