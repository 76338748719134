import { call, select, put } from 'redux-saga/effects';

import { settings } from 'api/actions';

// from navigateTo
import { createListingsSearchQuery } from 'utils/searchListings';
import { base64encode } from 'utils/strings';

import { SEARCH_TYPE_MAP_HIDDEN_CLUSTERS } from '../SearchListings.constants';

import { getParams, getFilters } from '../selectors';

// from searchListings/sagas/index.js
import doSearch from './doSearch';

/**
 * Loads new results based on the map interaction
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export default function* hiddenClustersMapSearch(action) {
    // We reset the previous results to avoid
    // any comparisons against old data in the map component
    yield put({
        type: settings.search.loadListings.RESET,
        requestData: {
            searchType: SEARCH_TYPE_MAP_HIDDEN_CLUSTERS,
        },
    });

    const params = yield select(getParams);
    const existingFilters = (yield select(getFilters)).filters || {};
    const filters = {
        ...existingFilters,
        geoBounds: action.geoBounds,
    };

    // We are supplying geoBounds so get rid of the existing geo filters, if they exist
    if (filters.geoHierarchy) delete filters.geoHierarchy;
    if (filters.geoPoint) delete filters.geoPoint;

    const searchQuery = createListingsSearchQuery({
        searchPastAssignments: false,
        filters,
        perPage: 0,
    });
    const search = {
        q: base64encode(JSON.stringify(searchQuery.getRequestData())),
    };

    yield call(doSearch, { search, params }, SEARCH_TYPE_MAP_HIDDEN_CLUSTERS);
}
