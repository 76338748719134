import { getParams } from 'containers/Page/selectors';
import {
    getBlogArea,
    getBlogCategorySubcategory,
    getBlogPost,
    getBlogAreaTrendingPosts,
} from 'api/selectors/content';
import { PAGE_ID, SEARCH_TYPE } from './constants';

export const getPost = (state) => {
    const params = getParams(state, PAGE_ID);
    return getBlogPost(state, params.area, params.category, params.slug);
};

export const getSubcategory = (state) => {
    const params = getParams(state, PAGE_ID);
    const post = getPost(state);

    if (!post) {
        return null;
    }

    return getBlogCategorySubcategory(state, params.area, params.category, post.subcategorySlug);
};

export const getPostCategory = (state) => {
    const params = getParams(state, PAGE_ID);
    const area = getBlogArea(state, params.area);
    const post = getPost(state);

    if (!post) {
        return null;
    }

    return area.categories.find((category) => category.slug === post.category.slug) ?? null;
};

export const getTrendingPosts = (state) => {
    const params = getParams(state, PAGE_ID);

    if (!params.area) {
        return null;
    }

    return getBlogAreaTrendingPosts(state, params.area, SEARCH_TYPE);
};
