import { put, takeEvery, take, call } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { track, Events } from 'utils/analytics';
import { showError, showSuccess, setSubmitting } from './actions';

export function* sendShareListingEmail(action) {
    const { listingId, emailAddress } = action.payload;
    const emailAddresses = [emailAddress];

    yield put(setSubmitting.create(true));

    yield put(
        apiActions.search.shareListingEmail({
            data: {
                id: listingId,
                emailAddresses,
            },
            forceReload: true,
        })
    );

    const { status } = yield take(settings.search.shareListingEmail.DONE);

    yield put(setSubmitting.create(false));

    if (status === settings.search.shareListingEmail.SUCCESS) {
        yield put(showSuccess.create());
        yield call(track, Events.USER_SENT_SHARE_LISTING_EMAIL.create(emailAddresses, true));

        return true;
    }

    yield put(showError.create());
    yield call(track, Events.USER_SENT_SHARE_LISTING_EMAIL.create(emailAddresses, false));

    return false;
}

export default function* sagas() {
    yield takeEvery((action) => action.type === 'SEND_SHARE_LISTING_EMAIL', sendShareListingEmail);
}
