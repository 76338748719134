/* global $Values */
import React from 'react';
import ButtonStyled from './Button.style';
import { ButtonSize, ButtonVariant } from './Button.constants';

/**
 * Button component that renders a button element with passed in props
 */
const Button = ({ fullWidth, buttonRef, disabled, loading, type, ...props }) => {
    const isDisabled = disabled || loading;

    return (
        <ButtonStyled
            disabled={isDisabled}
            formNoValidate={type === 'submit'}
            ref={buttonRef}
            type={type}
            fullWidth={fullWidth}
            loading={loading}
            {...props}
        />
    );
};

Button.defaultProps = {
    size: 'medium',
    type: 'button',
    variant: 'primary',
};

Button.Variant = ButtonVariant;
Button.Size = ButtonSize;

export default Button;
