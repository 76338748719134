import * as actions from './actions';

const getEventsState = (event) => ({
    callCount: event ? event.callCount + 1 : 1,
    lastCallDate: new Date().toISOString(),
});

const initialState = {
    events: {},
    promoCode: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.eventTriggered.ACTION:
            return {
                ...state,
                events: {
                    ...state.events,
                    [action.eventType]: getEventsState(state.events[action.eventType]),
                },
            };
        case actions.storePromo.ACTION:
            return {
                ...state,
                promoCode: action.promoCode,
            };
        case actions.storePartner.ACTION:
            return {
                ...state,
                partnerCode: action.partnerCode,
            };
        case actions.searchSuggestionsOnEnter.ACTION:
            return {
                ...state,
                searchSuggestionsOnEnter: action.places,
            };
        case actions.clearSearchSuggestionsOnEnter.ACTION:
            return {
                ...state,
                searchSuggestionsOnEnter: null,
            };
        default:
            return state;
    }
};
