import React from 'react';
import PropTypes from 'prop-types';
import usePrismicStyles from 'hooks/usePrismicStyles';
import {
    Container,
    ContainerTitle,
    StepsGrid,
    StepNumber,
    StepTitle,
    Separator,
    StepDescription,
    Link,
} from './HowItWorks.style';
import { howItWorksUrls, DEFAULT } from './constants';

function HowItWorks({ title, howItWorksLink, steps, backgroundColor }) {
    const howItWorksUrl = howItWorksUrls[howItWorksLink?.url] || DEFAULT;
    const prismicClasses = usePrismicStyles({
        backgroundColor,
    });

    return (
        <div className={prismicClasses}>
            <Container>
                <ContainerTitle>{title}</ContainerTitle>

                <StepsGrid>
                    {steps.map((step) => (
                        <React.Fragment key={step.stepNumber}>
                            <StepNumber>{step.stepNumber}.</StepNumber>
                            <StepTitle>{step.title}</StepTitle>
                            <Separator />
                            <StepDescription>{step.description}</StepDescription>
                        </React.Fragment>
                    ))}
                </StepsGrid>

                {howItWorksLink ? <Link to={howItWorksUrl}>{howItWorksLink.text}</Link> : null}
            </Container>
        </div>
    );
}

HowItWorks.defaultProps = {
    title: null,
    howItWorksLink: null,
    steps: [],
    backgroundColor: 'white',
};

HowItWorks.propTypes = {
    title: PropTypes.string,
    howItWorksLink: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
    }),
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            stepNumber: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })
    ),
    backgroundColor: PropTypes.string,
};

export default HowItWorks;
