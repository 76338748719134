import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'components/Form';
import { MembershipType } from 'api/types';
import PropTypes from 'prop-types';
import {
    CheckboxGroupStyled,
    CheckboxButtonStyled,
    ErrorMessageStyled,
} from './MembershipSelector.style';

const MembershipSelector = ({
    membershipType,
    setMembershipType,
    showMembershipTypeError,
    setShowMembershipTypeError,
}) => {
    const { t } = useTranslation();
    const membershipTypes = [MembershipType.OWNER, MembershipType.SITTER, MembershipType.COMBINED];
    return (
        <>
            <Label>{t('components_registerForm_checkBoxGroup_label')}</Label>
            <CheckboxGroupStyled
                data={membershipTypes}
                renderItem={({ item }) => (
                    <CheckboxButtonStyled
                        key={item}
                        id={item}
                        hasCheckMark={false}
                        isChecked={item === membershipType}
                        onClick={() => {
                            setMembershipType(item);
                            setShowMembershipTypeError(false);
                        }}
                    >
                        {t(`components_registerForm_checkBoxGroup_${item}`)}
                    </CheckboxButtonStyled>
                )}
            />

            {showMembershipTypeError && (
                <ErrorMessageStyled>
                    {t('components_registerForm_checkBoxGroup_error')}
                </ErrorMessageStyled>
            )}
        </>
    );
};

MembershipSelector.propTypes = {
    membershipType: PropTypes.string,
    setMembershipType: PropTypes.func.isRequired,
    showMembershipTypeError: PropTypes.bool.isRequired,
    setShowMembershipTypeError: PropTypes.func.isRequired,
};

MembershipSelector.defaultProps = {
    membershipType: null,
};

export default MembershipSelector;
