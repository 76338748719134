import { settings as actions } from '../../actions';

import {
    baseInitialResultsState,
    loadedResultsReducer,
    loadedResultsItemReducer,
    createdResultReducer,
} from '../common';

export const initialState = { ...baseInitialResultsState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadReviews.SUCCESS:
            return loadedResultsReducer(state, action);
        case actions.owner.loadPastAssignmentReview.SUCCESS:
            return loadedResultsItemReducer(state, action);
        case actions.owner.createPastAssignmentReview.SUCCESS:
            return createdResultReducer(state, action);
        case actions.owner.loadConfirmedAssignments.SUCCESS:
            return {
                ...state,
                ...action.data.past.reduce((reviews, assignment) => {
                    if (assignment.review) {
                        reviews[assignment.review.id] = assignment.review;
                    }
                    return reviews;
                }, {}),
            };
        case actions.owner.loadPastAssignments.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((reviews, assignment) => {
                        if (assignment.review) {
                            reviews[assignment.review.id] = assignment.review;
                        }
                        return reviews;
                    }, {}),
                },
            };
        default:
            return state;
    }
};
