import { combineReducers } from 'redux';
import countries, { initialState as countriesInitialState } from './countries';
import listing, { initialState as listingInitialState } from './listing';
import listings, { initialState as listingsInitialState } from './listings';
import profile, { initialState as profileInitialState } from './profile';
import profiles, { initialState as profilesInitialState } from './profiles';
import facets, { initialState as facetsInitialState } from './facets';
import places, { initialState as placesInitialState } from './places';
import favourites, { initialState as favouritesInitialState } from './favourites';
import favouritedYou, { initialState as favouritedYouInitialState } from './favouritedYou';
import savedSearchFilters, {
    initialState as savedSearchFiltersInitialState,
} from './savedSearchFilters';
import sitemap, { initialState as sitemapInitialState } from './sitemap';

export const initialState = {
    countries: countriesInitialState,
    listing: listingInitialState,
    listings: listingsInitialState,
    profile: profileInitialState,
    profiles: profilesInitialState,
    facets: facetsInitialState,
    places: placesInitialState,
    favourites: favouritesInitialState,
    favouritedYou: favouritedYouInitialState,
    savedSearchFilters: savedSearchFiltersInitialState,
    sitemap: sitemapInitialState,
};

export default combineReducers({
    countries,
    listing,
    listings,
    profile,
    profiles,
    facets,
    places,
    favourites,
    favouritedYou,
    savedSearchFilters,
    sitemap,
});
