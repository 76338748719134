/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import CoverImage from 'components/content/prismicSlices/CoverImage';
import { Panel } from 'components/layouts';

const ContainerStyled = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[8]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[12]};
    }
`;

const CoverImageStyled = styled(CoverImage)`
    margin-bottom: ${({ theme }) => theme.spacing[4]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

const BreadcrumbContainerStyled = styled(Panel)`
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[1]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-top: ${({ theme }) => theme.spacing[4]};
        margin-bottom: ${({ theme }) => theme.spacing[2]};
    }
`;

export { ContainerStyled, CoverImageStyled, BreadcrumbContainerStyled };
