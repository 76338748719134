import { compile, match } from 'path-to-regexp';

import paths from '../route-paths';

// Takes some route paths and compiles them to regex
export const reverse = (routePaths, compiledRoutePaths = {}) => {
    Object.keys(routePaths).forEach((name) => {
        if (typeof routePaths[name] === 'string') {
            compiledRoutePaths[name] = compile(routePaths[name], { encode: encodeURIComponent });
        } else {
            compiledRoutePaths[name] = reverse(routePaths[name]);
        }
    });

    return compiledRoutePaths;
};

// Compile all the paths e.g. can do routes.search.listings({id: '123'})
export const routes = reverse(paths);

/**
 * Create a matcher function that can be used to match other pathnames
 * https://github.com/pillarjs/path-to-regexp#match
 * @param {String} pathname
 * @returns {MatcherFunction}
 */
export const matcher = (pathname) =>
    match(pathname, {
        decode: decodeURIComponent,
    });

/**
 * Loop through all `matchers` trying to match any matcher with provided `pathname`
 * @param {String} pathname
 * @param {MatcherFunction[]} matchers
 * @returns {Boolean}
 */
export const some = (pathname, matchers) => matchers.some((m) => Boolean(m(pathname)));
