import { settings as actions } from '../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadProfilesFacets.SUCCESS:
        case actions.search.loadListingsFacets.SUCCESS: {
            const {
                searchType = 'default',
                searchLevel = 'global',
                location = null,
            } = action.requestData;
            const { facets, total } = action.data;

            return {
                ...state,
                [searchType]: {
                    ...(state?.[searchType] ?? {}),
                    [searchLevel]: {
                        ...(state?.[searchType]?.[searchLevel] ?? {}),
                        ...facets,
                        location,
                        total,
                    },
                },
            };
        }
        default:
            return state;
    }
};
