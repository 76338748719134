import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getSitterReviews } from 'api/selectors/sitter';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { getRawProfile } from '../selectors';
import { PAGE_ID } from './ProfilePreview.constants';

const LoadableProfilePreview = loadable(() => retry(() => import('./ProfilePreview')));

const mapStateToProps = (state) => ({
    profile: getRawProfile(state),
    reviews: getSitterReviews(state),
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(mapStateToProps)(
    asPage(withTranslation()(LoadableProfilePreview), PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
