import { settings } from 'api/actions';

const defaultState = {};

export default (state = defaultState, action) => {
    switch (action.type) {
        case settings.verifications.getProvideReference.SUCCESS: {
            return {
                ...state,
                ...action.data,
            };
        }
        default:
            return state;
    }
};
