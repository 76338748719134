import * as React from 'react';
import * as Rating from '../components';

function StaticRating({ value, max, size, children }) {
    return (
        <Rating.Root initialValue={value} max={max}>
            <Rating.Container size={size}>
                {({ isHighlighted }) => (
                    <Rating.Icon isHighlighted={isHighlighted}>{children}</Rating.Icon>
                )}
            </Rating.Container>
        </Rating.Root>
    );
}

StaticRating.defaultProps = {
    max: 5,
    size: 'lg',
};

export default StaticRating;
