import styled from 'styled-components';
import { IconFavouritePink } from 'components/Icon';
import { smallTextStyles } from 'typography';

const SpinnerContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
`;

const HeartIconStyled = styled(IconFavouritePink)`
    font-size: 2rem;
    margin-right: ${({ theme }) => theme.spacing[2]};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: 3rem;
    }
`;

const WrapperHeaderStyled = styled.div`
    display: flex;
`;

const LabelWrapperStyled = styled.span`
    display: flex;
    flex-wrap: wrap;
`;

const LabelTextStyled = styled.span`
    flex: 1 0 100%;

    ${({ theme }) => `
        font-weight: ${theme.fontWeights.bold};
        margin-right: ${theme.spacing['1.5']};

        /* 720 */
        @media screen and (min-width: ${theme.screenSize.mediumTablet}px) {
            flex: 0 1 auto;
        }
    `};
`;

const InfoStyled = styled.p`
    ${smallTextStyles};
`;

const InfoTipsStyled = styled.p`
    ${smallTextStyles};
    ${({ theme }) => `
        color: ${theme.palette.textLight};
    `};
    margin-bottom: 0;
`;

const InfoBoxStyled = styled.div`
    ${({ theme }) => `
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.palette.white};
        border-radius: ${theme.borderRadius.small};
        border: solid 1px ${theme.palette.borderLight};
        padding: ${theme.spacing[3]};
        margin-bottom: ${theme.spacing[6]};

        /* 800 */
        @media screen and (min-width: ${theme.screenSize.largeTablet}px) {
            margin-bottom: ${theme.spacing[8]};
        }
    `};
`;

export {
    SpinnerContainerStyled,
    HeartIconStyled,
    WrapperHeaderStyled,
    LabelWrapperStyled,
    LabelTextStyled,
    InfoStyled,
    InfoTipsStyled,
    InfoBoxStyled,
};
