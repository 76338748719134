import { getState } from './common';

export const getAllVerifications = state => getState(state).verifications;
export const getEmailVerificationsStatus = state => getAllVerifications(state) && getAllVerifications(state).email;
export const getPhoneVerificationsStatus = state => getAllVerifications(state) && getAllVerifications(state).phone;
export const getReferenceVerificationsStatus = state => getAllVerifications(state) && getAllVerifications(state).reference;
export const getIdentityCheckVerificationsStatus = state => getAllVerifications(state) && getAllVerifications(state).identityCheck;
export const getCriminalBackgroundCheckVerificationsStatus = state => getAllVerifications(state) && getAllVerifications(state).criminalBackgroundCheck;

export const getVerificationReferences = state => getAllVerifications(state) && getAllVerifications(state).references;
