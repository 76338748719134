import { put, take, select, call, takeLatest } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import * as apiSelectors from 'api/selectors';
import { getAccount } from 'api/selectors/account';
import { getLocale, getStoredPromo } from 'shared/selectors';
import * as sharedActions from 'shared/actions';
import { getAcqExperiment } from 'shared/acqCampaign';
import { track, Events } from 'utils/analytics';
import { getPlanPricingExperiment, getPlanVariation } from 'utils/plan';
import { getSplit } from 'containers/ExperimentalFeature/selectors';
import { triggerGetSiteControl, getGSCExperiments } from 'src/shared/sagas';
import {
    isCommissionJunctionEnabled,
    COMMISSION_JUNCTION_TYPES,
} from 'utils/integrations/commissionJunction';
import { isMember } from 'utils/account';
import { isTikTokEnabled, triggerTikTokTracking, TIKTOK_EVENTS } from 'utils/integrations/tiktok';
import * as actions from './actions';
import { registerMethod, errorMessages } from './Register.constants';

const loginActionsAndSettings = {
    [registerMethod.FACEBOOK]: {
        action: apiActions.session.createFacebook,
        settings: settings.session.createFacebook,
    },
    [registerMethod.GOOGLE]: {
        action: apiActions.session.createGoogle,
        settings: settings.session.createGoogle,
    },
};

export function getRegisterEventMethod(method) {
    switch (method) {
        case registerMethod.FACEBOOK:
            return Events.REGISTERED.loginMethod.FACEBOOK;
        case registerMethod.GOOGLE:
            return Events.REGISTERED.loginMethod.GOOGLE;
        default:
            return Events.REGISTERED.loginMethod.EMAIL;
    }
}

export function* validateEmail(email) {
    // Make a request to api to check if email is valid (via NeverBounce)
    yield put(apiActions.account.validateEmail({ data: { email } }));
    const { status, statusCode, data } = yield take(settings.account.validateEmail.DONE);
    if (status !== settings.account.validateEmail.SUCCESS) {
        if (statusCode === 503) {
            // If the status code is 503 then NeverBounce is unavailable
            // so return true anyway to allow members to continue to register
            return true;
        }
        // Otherwise return error message
        return {
            isValidEmail: false,
            error: { email: [errorMessages.INVALID_EMAIL] },
        };
    }
    const { email: isValidEmail } = data;
    if (isValidEmail) {
        return { isValidEmail };
    }
    return { isValidEmail, error: { email: [errorMessages.INVALID_EMAIL] } };
}

export function* loadMembershipPlan({ membershipType, currencyCode }) {
    // Try to select plan
    let membershipPlan = yield select(apiSelectors.getPlan, membershipType);

    // If membership plan doesn't exist load it
    if (!membershipPlan.id) {
        // Find out if this user should be seeing an experimental price variation
        const account = yield select(getAccount);
        const split = yield select(getSplit);
        const pricingExperiment = getPlanPricingExperiment(account, split);
        const planVariation = getPlanVariation(pricingExperiment);

        // Load membership plans
        yield put(
            apiActions.plans.load({
                filters: {
                    currency_code: currencyCode,
                    variation: planVariation,
                },
            })
        );

        // Wait for the plans to load before moving on
        const { status, statusCode, data: error } = yield take(settings.plans.load.DONE);
        if (status === settings.plans.load.SUCCESS) {
            // get membershipPlan out of response
            membershipPlan = yield select(apiSelectors.getPlan, membershipType);
        } else {
            yield put(
                actions.showRegisterErrorMessage.create({
                    error,
                    statusCode,
                })
            );
        }
    }
    return membershipPlan;
}

export function* trackRegisterEvent(method, user) {
    // trigger tracking for TikTok
    if (isTikTokEnabled()) {
        triggerTikTokTracking(TIKTOK_EVENTS.submitForm);
    }

    // Use the register event constants as these are the values expected by analytics
    const registerEventMethod = getRegisterEventMethod(method);
    const acqCampaignOwnerPageUs = yield select(getAcqExperiment('paid_owner_acq_page_us'));

    const registeredEvent = Events.REGISTERED.create(
        user,
        Events.REGISTERED.registerMethod.REGWALL,
        registerEventMethod,
        {
            ...(acqCampaignOwnerPageUs
                ? {
                      paid_owner_test: acqCampaignOwnerPageUs.variation,
                  }
                : {}),
        }
    );

    yield call(track, registeredEvent);
}

export function* logInUser({ loginMethod, loginData, membershipPlan }) {
    const loginType = loginActionsAndSettings[loginMethod];

    yield put(
        loginType.action({
            forceReload: true,
            data: loginData,
            filters: {
                method: loginMethod,
            },
        })
    );

    const { status, statusCode } = yield take(loginType.settings.DONE);

    if (status !== loginType.settings.SUCCESS) {
        // If not successful dispatch action to show error message
        yield put(
            actions.showRegisterErrorMessage.create({
                // will have a generic message for all types of login fails
                // but it needs to be in the same structure as data that is received from the backend
                error: { loginError: [errorMessages.LOGIN_FAILED] },
                statusCode,
            })
        );
    } else {
        // this makes sure to update the store
        yield put(apiActions.account.load({ forceReload: true }));
        // wait until account is finished loading
        yield take(settings.account.load.DONE);

        const account = yield select(getAccount);
        const isAPaidMember = isMember(account.membershipPlan);
        // if it is a partial/expired member then update the incomplete membership plan
        // and would only trigger if a plan was passed down
        // this primarily happens when a user selects a plan on the plans page
        if (!isAPaidMember && membershipPlan) {
            yield put(
                apiActions.account.createIncompleteMembershipPlan({
                    forceReload: true,
                    data: {
                        promoCode: membershipPlan.promoCode && membershipPlan.promoCode.code,
                        membershipPlanId: membershipPlan.id,
                        userId: account.details.id,
                        currencyCode: membershipPlan.currencyCode,
                    },
                })
            );

            // Wait for plan to created
            yield take(settings.account.createIncompleteMembershipPlan.DONE);

            // Reload membership plan
            yield put(
                apiActions.account.loadMembership({
                    forceReload: true,
                })
            );

            yield take(settings.account.loadMembership.DONE);
        }
        // have to dispatch a finished loading state mostly for handling when a plan is selected on the plans page
        yield put(actions.finshedLoadingAccountData.create());
        yield put(actions.userLoggedIn.create());
        yield call(track, Events.USER_LOGGED_IN.create(loginMethod));
    }
}

export function* createAccount(action) {
    // Set the submitting state for the register form
    yield put(actions.setSubmitting.create());

    // Check if the email is valid
    const {
        data: { email },
    } = action;
    const { isValidEmail, error } = yield call(validateEmail, email);
    if (!isValidEmail) {
        yield put(actions.showRegisterErrorMessage.create({ error }));
    }
    if (isValidEmail) {
        const { membershipType, method, signupOrigin } = action;

        let { membershipPlan } = action;
        // check if a plan was passed down
        // it is used when trying to log in via the regwall
        const wasMembershipPlanPassedDown = !!membershipPlan;

        // if we haven't been passed a membershipPlan then load one (and load it as a 12 month plan)
        if (!membershipPlan) {
            const { currency } = yield select(getLocale);

            membershipPlan = yield call(loadMembershipPlan, {
                membershipType,
                currencyCode: currency.currencyCode,
            });
        }

        // If the plans don't load then loadMembershipPlan will show an error so don't continue
        if (membershipPlan.id) {
            // Check if there's a promoCode in the store
            const promoCode = yield select(getStoredPromo);

            // Transform the data into the required structure
            const data = {
                settings: {
                    ...action.data,
                },
                membershipPlan: {
                    membershipPlanId: membershipPlan.id,
                    currencyCode: membershipPlan.currencyCode,
                    promoCode,
                },
            };

            // Send create account request with data and correct parameters
            if (method === registerMethod.FACEBOOK) {
                // The fb signup method requires the data in a bit of an odd structure
                // with the email, fb id and access token both at the top of the data object
                // and within the settings
                yield put(
                    apiActions.account.create({
                        data: {
                            email: action.data.email,
                            userId: action.data.facebookUserId,
                            accessToken: action.data.accessToken,
                            ...data,
                        },
                        filters: {
                            signupOrigin,
                            method: registerMethod.FACEBOOK,
                        },
                    })
                );
            } else if (method === registerMethod.GOOGLE) {
                yield put(
                    apiActions.account.create({
                        data: {
                            credential: action.data.credential,
                            ...data,
                        },
                        filters: {
                            signupOrigin,
                            method: registerMethod.GOOGLE,
                        },
                    })
                );
            } else {
                // Email signUp
                yield put(
                    apiActions.account.create({
                        data,
                        filters: {
                            signupOrigin,
                            method: registerMethod.EMAIL,
                        },
                    })
                );
            }

            const {
                status,
                statusCode,
                data: accountData,
            } = yield take(settings.account.create.DONE);

            if (status !== settings.account.create.SUCCESS) {
                // if a user tries to register with an existing account via Facebook/Google
                // they would instead get logged in
                if (
                    accountData.facebookUserId &&
                    accountData.facebookUserId.includes(
                        errorMessages.ACCOUNT_ALREADY_CREATED_FACEBOOK
                    )
                ) {
                    yield call(logInUser, {
                        loginMethod: registerMethod.FACEBOOK,
                        loginData: {
                            email: action.data.email,
                            userId: action.data.facebookUserId,
                            accessToken: action.data.accessToken,
                        },
                        membershipPlan: wasMembershipPlanPassedDown ? membershipPlan : false,
                    });
                } else if (
                    accountData.googleUserId &&
                    accountData.googleUserId.includes(errorMessages.ACCOUNT_ALREADY_CREATED_GOOGLE)
                ) {
                    yield call(logInUser, {
                        loginMethod: registerMethod.GOOGLE,
                        loginData: {
                            credential: action.data.credential,
                        },
                        membershipPlan: wasMembershipPlanPassedDown ? membershipPlan : false,
                    });
                } else {
                    // If not successful dispatch action to show error message
                    yield put(
                        actions.showRegisterErrorMessage.create({
                            error: accountData,
                            statusCode,
                        })
                    );
                }
            } else {
                // Dispatch that the user has been registered successfully
                yield put(actions.finshedLoadingAccountData.create());
                yield put(actions.userRegistered.create());

                // Identify the user (force run as event limited)
                yield put(sharedActions.identify.create(true));

                // If successful track the register event for analytics
                yield call(trackRegisterEvent, method, {
                    id: accountData.id,
                    settings: { ...action.data },
                    membershipType,
                    membershipPlan,
                });

                const gscExperiments = yield call(getGSCExperiments);

                // call GSC to update user details when successfully registering
                yield call(triggerGetSiteControl, {
                    initialiseGSC: false,
                    experiment: gscExperiments,
                });

                // Trigger comission junction tracking
                if (isCommissionJunctionEnabled()) {
                    yield put(
                        sharedActions.cjTrackingTriggered.create({
                            ...accountData,
                            trackingType: COMMISSION_JUNCTION_TYPES.ACCOUNT_SIGNUP,
                        })
                    );
                }
            }
        }
    }
    // Flip the submittings state back to false
    yield put(actions.setSubmitting.create());
}

export default function* createAccountWatch() {
    yield takeLatest(actions.createAccount.ACTION, createAccount);
}
