// Copied from app/config/currency.js
const countryISOToCurrency = {
    AE: {
        code: 'AED',
        name: 'Dirham',
        sign: 'AED',
    },
    AF: {
        code: 'AFN',
        name: 'Afghani',
        sign: '؋',
    },
    AL: {
        code: 'ALL',
        name: 'Lek',
        sign: 'Lekë',
    },
    AM: {
        code: 'AMD',
        name: 'Dram',
        sign: '֏',
    },
    SX: {
        code: 'ANG',
        name: 'Guilder',
        sign: 'NAf.',
    },
    CW: {
        code: 'ANG',
        name: 'Guilder',
        sign: 'NAf.',
    },
    AO: {
        code: 'AOA',
        name: 'Kwanza',
        sign: 'Kz',
    },
    AR: {
        code: 'ARS',
        name: 'Peso',
        sign: '$',
    },
    HM: {
        code: 'AUD',
        name: 'Dollar',
        sign: '',
    },
    CC: {
        code: 'AUD',
        name: 'Dollar',
        sign: '$',
    },
    AU: {
        code: 'AUD',
        name: 'Dollar',
        sign: '$',
    },
    CX: {
        code: 'AUD',
        name: 'Dollar',
        sign: '$',
    },
    TV: {
        code: 'AUD',
        name: 'Dollar',
        sign: '$',
    },
    NR: {
        code: 'AUD',
        name: 'Dollar',
        sign: '$',
    },
    NF: {
        code: 'AUD',
        name: 'Dollar',
        sign: '$',
    },
    KI: {
        code: 'AUD',
        name: 'Dollar',
        sign: '$',
    },
    AW: {
        code: 'AWG',
        name: 'Guilder',
        sign: 'Afl.',
    },
    AZ: {
        code: 'AZN',
        name: 'Manat',
        sign: '₼',
    },
    BA: {
        code: 'BAM',
        name: 'Marka',
        sign: 'KM',
    },
    BB: {
        code: 'BBD',
        name: 'Dollar',
        sign: '$',
    },
    BD: {
        code: 'BDT',
        name: 'Taka',
        sign: '৳',
    },
    BG: {
        code: 'BGN',
        name: 'Lev',
        sign: 'лв.',
    },
    BH: {
        code: 'BHD',
        name: 'Dinar',
        sign: 'د.ب.‏',
    },
    BI: {
        code: 'BIF',
        name: 'Franc',
        sign: 'FBu',
    },
    BM: {
        code: 'BMD',
        name: 'Dollar',
        sign: '$',
    },
    BN: {
        code: 'BND',
        name: 'Dollar',
        sign: '$',
    },
    BO: {
        code: 'BOB',
        name: 'Boliviano',
        sign: 'Bs',
    },
    BR: {
        code: 'BRL',
        name: 'Real',
        sign: 'R$',
    },
    BS: {
        code: 'BSD',
        name: 'Dollar',
        sign: '$',
    },
    BT: {
        code: 'BTN',
        name: 'Ngultrum',
        sign: 'Nu.',
    },
    BW: {
        code: 'BWP',
        name: 'Pula',
        sign: 'P',
    },
    BY: {
        code: 'BYR',
        name: 'Ruble',
        sign: 'р.',
    },
    BZ: {
        code: 'BZD',
        name: 'Dollar',
        sign: '$',
    },
    CA: {
        code: 'CAD',
        name: 'Dollar',
        sign: '$',
    },
    CD: {
        code: 'CDF',
        name: 'Franc',
        sign: 'FC',
    },
    CH: {
        code: 'CHF',
        name: 'Franc',
        sign: 'CHF',
    },
    LI: {
        code: 'CHF',
        name: 'Franc',
        sign: 'CHF',
    },
    CL: {
        code: 'CLP',
        name: 'Peso',
        sign: '$',
    },
    CN: {
        code: 'CNY',
        name: 'Yuan Renminbi',
        sign: '¥',
    },
    CO: {
        code: 'COP',
        name: 'Peso',
        sign: '$',
    },
    CR: {
        code: 'CRC',
        name: 'Colon',
        sign: '₡',
    },
    CU: {
        code: 'CUP',
        name: 'Peso',
        sign: '$',
    },
    CV: {
        code: 'CVE',
        name: 'Escudo',
        sign: '​',
    },
    CZ: {
        code: 'CZK',
        name: 'Koruna',
        sign: 'Kč',
    },
    DJ: {
        code: 'DJF',
        name: 'Franc',
        sign: 'Fdj',
    },
    FO: {
        code: 'DKK',
        name: 'Krone',
        sign: 'kr',
    },
    DK: {
        code: 'DKK',
        name: 'Krone',
        sign: 'kr.',
    },
    GL: {
        code: 'DKK',
        name: 'Krone',
        sign: 'kr.',
    },
    DO: {
        code: 'DOP',
        name: 'Peso',
        sign: 'RD$',
    },
    DZ: {
        code: 'DZD',
        name: 'Dinar',
        sign: 'د.ج.‏',
    },
    EG: {
        code: 'EGP',
        name: 'Pound',
        sign: '£',
    },
    ER: {
        code: 'ERN',
        name: 'Nakfa',
        sign: 'Nfk',
    },
    ET: {
        code: 'ETB',
        name: 'Birr',
        sign: 'Br',
    },
    CY: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    GR: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    EE: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    LV: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    LT: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    FI: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    AX: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    XK: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    RE: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    YT: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    TF: {
        code: 'EUR',
        name: 'Euro  ',
        sign: '',
    },
    PT: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    ES: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    MT: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    NL: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    AT: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    BE: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    DE: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    LU: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    IE: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    MC: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    FR: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    AD: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    SK: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    VA: {
        code: 'EUR',
        name: 'Euro',
        sign: '',
    },
    SM: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    IT: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    SI: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    ME: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    GF: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    PM: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    MQ: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    MF: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    BL: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    GP: {
        code: 'EUR',
        name: 'Euro',
        sign: '€',
    },
    FJ: {
        code: 'FJD',
        name: 'Dollar',
        sign: '$',
    },
    FK: {
        code: 'FKP',
        name: 'Pound',
        sign: '£',
    },
    GB: {
        code: 'GBP',
        name: 'Pound',
        sign: '£',
    },
    JE: {
        code: 'GBP',
        name: 'Pound',
        sign: '£',
    },
    IM: {
        code: 'GBP',
        name: 'Pound',
        sign: '£',
    },
    GG: {
        code: 'GBP',
        name: 'Pound',
        sign: '£',
    },
    GS: {
        code: 'GBP',
        name: 'Pound',
        sign: '£',
    },
    GE: {
        code: 'GEL',
        name: 'Lari',
        sign: '₾',
    },
    GH: {
        code: 'GHS',
        name: 'Cedi',
        sign: 'GH₵',
    },
    GI: {
        code: 'GIP',
        name: 'Pound',
        sign: '£',
    },
    GM: {
        code: 'GMD',
        name: 'Dalasi',
        sign: 'D',
    },
    GN: {
        code: 'GNF',
        name: 'Franc',
        sign: 'FG',
    },
    GT: {
        code: 'GTQ',
        name: 'Quetzal',
        sign: 'Q',
    },
    GY: {
        code: 'GYD',
        name: 'Dollar',
        sign: '$',
    },
    HK: {
        code: 'HKD',
        name: 'Dollar',
        sign: 'HK$',
    },
    HN: {
        code: 'HNL',
        name: 'Lempira',
        sign: 'L',
    },
    HR: {
        code: 'HRK',
        name: 'Kuna',
        sign: 'HRK',
    },
    HT: {
        code: 'HTG',
        name: 'Gourde',
        sign: 'G',
    },
    HU: {
        code: 'HUF',
        name: 'Forint',
        sign: 'HUF',
    },
    ID: {
        code: 'IDR',
        name: 'Rupiah',
        sign: 'Rp',
    },
    IL: {
        code: 'ILS',
        name: 'Shekel',
        sign: '₪',
    },
    PS: {
        code: 'ILS',
        name: 'Shekel',
        sign: '₪',
    },
    IN: {
        code: 'INR',
        name: 'Rupee',
        sign: '₹',
    },
    IQ: {
        code: 'IQD',
        name: 'Dinar',
        sign: 'IQD',
    },
    IR: {
        code: 'IRR',
        name: 'Rial',
        sign: 'IRR',
    },
    IS: {
        code: 'ISK',
        name: 'Krona',
        sign: 'ISK',
    },
    JM: {
        code: 'JMD',
        name: 'Dollar',
        sign: '$',
    },
    JO: {
        code: 'JOD',
        name: 'Dinar',
        sign: 'د.أ.‏',
    },
    JP: {
        code: 'JPY',
        name: 'Yen',
        sign: '¥',
    },
    KE: {
        code: 'KES',
        name: 'Shilling',
        sign: 'Ksh',
    },
    KG: {
        code: 'KGS',
        name: 'Som',
        sign: 'сом',
    },
    KH: {
        code: 'KHR',
        name: 'Riels',
        sign: '៛',
    },
    KM: {
        code: 'KMF',
        name: 'Franc',
        sign: 'CF',
    },
    KP: {
        code: 'KPW',
        name: 'Won',
        sign: 'KPW',
    },
    KR: {
        code: 'KRW',
        name: 'Won',
        sign: '₩',
    },
    KW: {
        code: 'KWD',
        name: 'Dinar',
        sign: 'د.ك.‏',
    },
    KY: {
        code: 'KYD',
        name: 'Dollar',
        sign: '$',
    },
    KZ: {
        code: 'KZT',
        name: 'Tenge',
        sign: '₸',
    },
    LA: {
        code: 'LAK',
        name: 'Kip',
        sign: '₭',
    },
    LB: {
        code: 'LBP',
        name: 'Pound',
        sign: 'ل.ل.‏',
    },
    LK: {
        code: 'LKR',
        name: 'Rupee',
        sign: 'Rs.',
    },
    LR: {
        code: 'LRD',
        name: 'Dollar',
        sign: '$',
    },
    LS: {
        code: 'LSL',
        name: 'Loti',
        sign: 'R',
    },
    LY: {
        code: 'LYD',
        name: 'Dinar',
        sign: 'د.ل.‏',
    },
    EH: {
        code: 'MAD',
        name: 'Dirham',
        sign: 'د.م.‏',
    },
    MA: {
        code: 'MAD',
        name: 'Dirham',
        sign: 'MAD',
    },
    MD: {
        code: 'MDL',
        name: 'Leu',
        sign: 'L',
    },
    MG: {
        code: 'MGA',
        name: 'Ariary',
        sign: 'Ar',
    },
    MK: {
        code: 'MKD',
        name: 'Denar',
        sign: 'den',
    },
    MM: {
        code: 'MMK',
        name: 'Kyat',
        sign: 'K',
    },
    MN: {
        code: 'MNT',
        name: 'Tugrik',
        sign: '₮',
    },
    MO: {
        code: 'MOP',
        name: 'Pataca',
        sign: 'MOP$',
    },
    MR: {
        code: 'MRO',
        name: 'Ouguiya',
        sign: 'UM',
    },
    MU: {
        code: 'MUR',
        name: 'Rupee',
        sign: 'Rs',
    },
    MV: {
        code: 'MVR',
        name: 'Rufiyaa',
        sign: 'MVR',
    },
    MW: {
        code: 'MWK',
        name: 'Kwacha',
        sign: 'MK',
    },
    MX: {
        code: 'MXN',
        name: 'Peso',
        sign: '$',
    },
    MY: {
        code: 'MYR',
        name: 'Ringgit',
        sign: 'RM',
    },
    MZ: {
        code: 'MZN',
        name: 'Metical',
        sign: 'MTn',
    },
    NA: {
        code: 'NAD',
        name: 'Dollar',
        sign: '$',
    },
    NG: {
        code: 'NGN',
        name: 'Naira',
        sign: '₦',
    },
    NI: {
        code: 'NIO',
        name: 'Cordoba',
        sign: 'C$',
    },
    SJ: {
        code: 'NOK',
        name: 'Krone',
        sign: 'kr',
    },
    NO: {
        code: 'NOK',
        name: 'Krone',
        sign: 'kr',
    },
    BV: {
        code: 'NOK',
        name: 'Krone',
        sign: '',
    },
    NP: {
        code: 'NPR',
        name: 'Rupee',
        sign: 'नेरू',
    },
    CK: {
        code: 'NZD',
        name: 'Dollar',
        sign: '$',
    },
    NZ: {
        code: 'NZD',
        name: 'Dollar',
        sign: '$',
    },
    PN: {
        code: 'NZD',
        name: 'Dollar',
        sign: '$',
    },
    TK: {
        code: 'NZD',
        name: 'Dollar',
        sign: '$',
    },
    NU: {
        code: 'NZD',
        name: 'Dollar',
        sign: '$',
    },
    OM: {
        code: 'OMR',
        name: 'Rial',
        sign: 'ر.ع.‏',
    },
    PA: {
        code: 'PAB',
        name: 'Balboa',
        sign: 'B/.',
    },
    PE: {
        code: 'PEN',
        name: 'Sol',
        sign: 'S/.',
    },
    PG: {
        code: 'PGK',
        name: 'Kina',
        sign: 'K',
    },
    PH: {
        code: 'PHP',
        name: 'Peso',
        sign: '₱',
    },
    PK: {
        code: 'PKR',
        name: 'Rupee',
        sign: 'Rs',
    },
    PL: {
        code: 'PLN',
        name: 'Zloty',
        sign: 'PLN',
    },
    PY: {
        code: 'PYG',
        name: 'Guarani',
        sign: 'Gs.',
    },
    QA: {
        code: 'QAR',
        name: 'Rial',
        sign: 'ر.ق.‏',
    },
    RO: {
        code: 'RON',
        name: 'Leu',
        sign: 'RON',
    },
    RS: {
        code: 'RSD',
        name: 'Dinar',
        sign: 'RSD',
    },
    RU: {
        code: 'RUB',
        name: 'Ruble',
        sign: 'RUB',
    },
    RW: {
        code: 'RWF',
        name: 'Franc',
        sign: 'RF',
    },
    SA: {
        code: 'SAR',
        name: 'Rial',
        sign: 'ر.س.‏',
    },
    SB: {
        code: 'SBD',
        name: 'Dollar',
        sign: '$',
    },
    SC: {
        code: 'SCR',
        name: 'Rupee',
        sign: 'SR',
    },
    SD: {
        code: 'SDG',
        name: 'Pound',
        sign: 'SDG',
    },
    SE: {
        code: 'SEK',
        name: 'Krona',
        sign: 'kr',
    },
    SG: {
        code: 'SGD',
        name: 'Dollar',
        sign: '$',
    },
    SH: {
        code: 'SHP',
        name: 'Pound',
        sign: '£',
    },
    SL: {
        code: 'SLL',
        name: 'Leone',
        sign: 'Le',
    },
    SO: {
        code: 'SOS',
        name: 'Shilling',
        sign: 'S',
    },
    SR: {
        code: 'SRD',
        name: 'Dollar',
        sign: '$',
    },
    SS: {
        code: 'SSP',
        name: 'Pound',
        sign: '£',
    },
    ST: {
        code: 'STD',
        name: 'Dobra',
        sign: 'Db',
    },
    SY: {
        code: 'SYP',
        name: 'Pound',
        sign: 'ل.س.‏',
    },
    SZ: {
        code: 'SZL',
        name: 'Lilangeni',
        sign: 'E',
    },
    TH: {
        code: 'THB',
        name: 'Baht',
        sign: 'THB',
    },
    TJ: {
        code: 'TJS',
        name: 'Somoni',
        sign: 'сом',
    },
    TM: {
        code: 'TMT',
        name: 'Manat',
        sign: 'TMT',
    },
    TN: {
        code: 'TND',
        name: 'Dinar',
        sign: 'د.ت.‏',
    },
    TO: {
        code: 'TOP',
        name: "Pa'anga",
        sign: 'T$',
    },
    TR: {
        code: 'TRY',
        name: 'Lira',
        sign: 'TRY',
    },
    TT: {
        code: 'TTD',
        name: 'Dollar',
        sign: '$',
    },
    TW: {
        code: 'TWD',
        name: 'Dollar',
        sign: 'NT$',
    },
    TZ: {
        code: 'TZS',
        name: 'Shilling',
        sign: 'TSh',
    },
    UA: {
        code: 'UAH',
        name: 'Hryvnia',
        sign: '₴',
    },
    UG: {
        code: 'UGX',
        name: 'Shilling',
        sign: 'USh',
    },
    IO: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    PW: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    TL: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    MH: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    FM: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    TC: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    VG: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    SV: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    EC: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    MP: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    GU: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    PR: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    VI: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    UM: {
        code: 'USD',
        name: 'Dollar ',
        sign: '$',
    },
    AS: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    US: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    BQ: {
        code: 'USD',
        name: 'Dollar',
        sign: '$',
    },
    UY: {
        code: 'UYU',
        name: 'Peso',
        sign: '$',
    },
    UZ: {
        code: 'UZS',
        name: 'Som',
        sign: 'soʻm',
    },
    VE: {
        code: 'VEF',
        name: 'Bolivar',
        sign: 'Bs.',
    },
    VN: {
        code: 'VND',
        name: 'Dong',
        sign: '₫',
    },
    VU: {
        code: 'VUV',
        name: 'Vatu',
        sign: 'VT',
    },
    WS: {
        code: 'WST',
        name: 'Tala',
        sign: 'WS$',
    },
    CF: {
        code: 'XAF',
        name: 'Franc',
        sign: 'FCFA',
    },
    CM: {
        code: 'XAF',
        name: 'Franc',
        sign: 'FCFA',
    },
    CG: {
        code: 'XAF',
        name: 'Franc',
        sign: 'FCFA',
    },
    GQ: {
        code: 'XAF',
        name: 'Franc',
        sign: 'FCFA',
    },
    GA: {
        code: 'XAF',
        name: 'Franc',
        sign: 'FCFA',
    },
    TD: {
        code: 'XAF',
        name: 'Franc',
        sign: 'FCFA',
    },
    AI: {
        code: 'XCD',
        name: 'Dollar',
        sign: '$',
    },
    KN: {
        code: 'XCD',
        name: 'Dollar',
        sign: '$',
    },
    DM: {
        code: 'XCD',
        name: 'Dollar',
        sign: '$',
    },
    AG: {
        code: 'XCD',
        name: 'Dollar',
        sign: '$',
    },
    LC: {
        code: 'XCD',
        name: 'Dollar',
        sign: '$',
    },
    VC: {
        code: 'XCD',
        name: 'Dollar',
        sign: '$',
    },
    MS: {
        code: 'XCD',
        name: 'Dollar',
        sign: '$',
    },
    GD: {
        code: 'XCD',
        name: 'Dollar',
        sign: '$',
    },
    SN: {
        code: 'XOF',
        name: 'Franc',
        sign: 'CFA',
    },
    CI: {
        code: 'XOF',
        name: 'Franc',
        sign: 'CFA',
    },
    BF: {
        code: 'XOF',
        name: 'Franc',
        sign: 'CFA',
    },
    TG: {
        code: 'XOF',
        name: 'Franc',
        sign: 'CFA',
    },
    GW: {
        code: 'XOF',
        name: 'Franc',
        sign: 'CFA',
    },
    BJ: {
        code: 'XOF',
        name: 'Franc',
        sign: 'CFA',
    },
    NE: {
        code: 'XOF',
        name: 'Franc',
        sign: 'CFA',
    },
    ML: {
        code: 'XOF',
        name: 'Franc',
        sign: 'CFA',
    },
    NC: {
        code: 'XPF',
        name: 'Franc',
        sign: 'FCFP',
    },
    PF: {
        code: 'XPF',
        name: 'Franc',
        sign: 'FCFP',
    },
    WF: {
        code: 'XPF',
        name: 'Franc',
        sign: 'FCFP',
    },
    YE: {
        code: 'YER',
        name: 'Rial',
        sign: 'ر.ي.‏',
    },
    ZA: {
        code: 'ZAR',
        name: 'Rand',
        sign: 'R',
    },
    ZM: {
        code: 'ZMW',
        name: 'Kwacha',
        sign: 'K',
    },
    ZW: {
        code: 'ZWL',
        name: 'Dollar',
        sign: '$',
    },
};

const defaultCountryISO = 'US';

const availableCurrencies = [
    {
        currencyCode: 'USD',
        currencyName: 'US Dollars',
        sign: '$',
    },
    {
        currencyCode: 'GBP',
        currencyName: 'British Pound Sterling',
        sign: '£',
    },
    {
        currencyCode: 'EUR',
        currencyName: 'European Union Euro',
        sign: '€',
    },
    {
        currencyCode: 'AUD',
        currencyName: 'Australian Dollars',
        sign: '$',
    },
    {
        currencyCode: 'CAD',
        currencyName: 'Canadian Dollars',
        sign: '$',
    },
    {
        currencyCode: 'NZD',
        currencyName: 'New Zealand Dollars',
        sign: '$',
    },
    {
        currencyCode: 'SGD',
        currencyName: 'Singapore Dollars',
        sign: '$',
    },
    {
        currencyCode: 'ARS',
        currencyName: 'Argentinian Pesos',
        sign: '$',
    },
    {
        currencyCode: 'BRL',
        currencyName: 'Brazilian Real',
        sign: 'R$',
    },
    {
        currencyCode: 'BGN',
        currencyName: 'Bulgarian Lev',
        sign: 'лв.',
    },
    {
        currencyCode: 'CLP',
        currencyName: 'Chilean Peso',
        sign: '$',
    },
    {
        currencyCode: 'CNY',
        currencyName: 'Yuan Renminbi',
        sign: '¥',
    },
    {
        currencyCode: 'DKK',
        currencyName: 'Danish Krone',
        sign: 'kr.',
    },
    {
        currencyCode: 'EGP',
        currencyName: 'Egyptian Pounds',
        sign: '£',
    },
    {
        currencyCode: 'FJD',
        currencyName: 'Fiji Dollars',
        sign: '$',
    },
    {
        currencyCode: 'HKD',
        currencyName: 'Hong Kong Dollars',
        sign: 'HK$',
    },
    {
        currencyCode: 'AED',
        currencyName: 'United Arab Emirates Dirham',
        sign: 'AED',
    },
    {
        currencyCode: 'CHF',
        currencyName: 'Switzerland Franc',
        sign: 'CHF',
    },
    {
        currencyCode: 'CRC',
        currencyName: 'Costa Rican Colon',
        sign: '₡',
    },
    {
        currencyCode: 'INR',
        currencyName: 'Indian Rupee',
        sign: '₹',
    },
    {
        currencyCode: 'JPY',
        currencyName: 'Japanese Yen',
        sign: '¥',
    },
    {
        currencyCode: 'MXN',
        currencyName: 'Mexican Peso',
        sign: '$',
    },
    {
        currencyCode: 'MYR',
        currencyName: 'Malaysian Ringgit',
        sign: 'RM',
    },
    {
        currencyCode: 'THB',
        currencyName: 'Thai Baht',
        sign: 'THB',
    },
    {
        currencyCode: 'XCD',
        currencyName: 'East Caribbean Dollar',
        sign: '$',
    },
    {
        currencyCode: 'ILS',
        currencyName: 'Isreali Shekel',
        sign: '₪',
    },
    {
        currencyCode: 'ZAR',
        currencyName: 'South African Rand',
        sign: 'R',
    },
];

const currencyToSign = {
    USD: '$',
    GBP: '£',
    EUR: '€',
    AUD: '$',
    CAD: '$',
    NZD: '$',
    SGD: '$',
    ARS: '$',
    BRL: 'R$',
    BGN: 'лв.',
    CLP: '$',
    CNY: '¥',
    DKK: 'kr.',
    EGP: '£',
    FJD: '$',
    HKD: 'HK$',
    AED: 'AED',
    CHF: 'CHF',
    CRC: '₡',
    INR: '₹',
    JPY: '¥',
    MXN: '$',
    MYR: 'RM',
    THB: 'THB',
    XCD: '$',
    ILS: '₪',
    ZAR: 'R',
};

const storeCurrencyInLocalStorage = (currencyCode) => {
    const { currencyName, sign } = availableCurrencies.find(
        (item) => item.currencyCode === currencyCode
    );

    if (global.localStorage) {
        global.localStorage.setItem(
            'ths-local-currency',
            JSON.stringify({ name: currencyName, symbol: sign, currencyCode })
        );
    }
};

// This comes from Stripe's list of supported currencies: https://stripe.com/docs/currencies#presentment-currencies
const zeroDecimalCurrencies = [
    'BIF',
    'CLP',
    'DJF',
    'GNF',
    'JPY',
    'KMF',
    'KRW',
    'MGA',
    'PYG',
    'RWF',
    'UGX',
    'VND',
    'VUV',
    'XAF',
    'XOF',
    'XPF',
];

/**
 * A function that returns the decimal representation of an amount
 * if that amount is in a currency that supports decimal representations
 * @param {number} amount - The amount in the smallest denomination of the currency (e.g cents, pennies)
 * @param {string} currency - The currency code of the currency e.g USD, JPY, GBP
 */
function formatCurrency(amount, currency) {
    if (!amount || !currency) return amount;
    if (zeroDecimalCurrencies.includes(currency.toUpperCase())) {
        return amount;
    }
    return amount / 100;
}

export {
    defaultCountryISO,
    countryISOToCurrency,
    availableCurrencies,
    currencyToSign,
    storeCurrencyInLocalStorage,
    formatCurrency,
};
