/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import FocusLock from 'react-focus-lock';
import { lockScrollOnBody } from 'utils/css';
import Blanket from 'components/Blanket';
import ZIndexManager from 'components/ZIndexManager';
import {
    DialogStyled,
    DialogWrapperStyled,
    ScrollableContainer,
    FocusLockStyled,
} from './Dialog.style';
import { DialogVariants } from './Dialog.constants';

/* eslint-disable react/prefer-stateless-function */
class Dialog extends Component {
    open() {
        this.isFirstModalOpen = !global.document.body.classList.contains(this.modalOpenClass);

        if (this.isFirstModalOpen) {
            this.unlockScroll = lockScrollOnBody();
            global.document.body.classList.add(this.modalOpenClass);
        }
    }

    close() {
        if (this.isFirstModalOpen) {
            this.unlockScroll();
            global.document.body.classList.remove(this.modalOpenClass);
        }
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.open();
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.isOpen !== this.props.isOpen) {
            if (nextProps.isOpen) {
                this.open();
            } else {
                this.close();
            }
        }
    }

    componentWillUnmount() {
        this.close();
    }

    onBackgroundClick = () => {
        if (this.props.isModal) return;
        this.props.onCloseHandler();
    };

    // We prevent clicks on the dialog from bubbling up the dom so that we can
    // listen for clicks on the background and close the dialog if it is not a modal.
    onDialogClick = (event) => event.stopPropagation();

    /**
     * @description
     * Simply forwards the event to it's handler
     *
     * @param  {object} event keydown event
     */
    onkeydownHandler = (event) => {
        const keyDownHandler = this.keyDownHandlers[event.key];

        if (keyDownHandler) keyDownHandler.call(this, event);
    };

    keyDownHandlers = {
        Escape(event) {
            if (this.props.isModal) return;

            event.preventDefault();
            this.props.onCloseHandler();
        },
    };

    modalOpenClass = 'ths-modal-open';

    unlockScroll = null;

    render() {
        // Extract `onCloseHandler` so it isn't passed on
        const {
            className,
            blanketColor,
            children,
            isOpen,
            scrollRef,
            dialogRef,
            blanketVariant,
            onCloseHandler,
            ...props
        } = this.props;

        // Warning: super hack ahead
        // The search filters are nested in two levels of ZIndexManager (one for the search bar and one
        // for the filter popup). This means our Dialog can never get a higher z-index than the
        // search filters. As a temporary workaround we can double up our use of ZIndexManager
        // to put us on the same level. A better long-term fix is to refactor the ZIndexManager to make it
        // work for our current use-cases
        return isOpen ? (
            <ZIndexManager layer="modal">
                <ZIndexManager layer="modal">
                    <>
                        <DialogWrapperStyled onKeyDown={this.onkeydownHandler} isOpen={isOpen}>
                            <Blanket
                                isActive={isOpen}
                                backgroundColor={blanketColor}
                                variant={blanketVariant}
                            />

                            {/*
                                When focusLock was nested inside the DialogStyled we saw a scrolling issue.
                                When the content inside the dialog was taller than the dialog it did not automatically scroll
                                despite an overflow auto or scroll.
                                Moving focus lock higher solved this without the need of fixed heights.
                                We've applied this for one variant as it limited testing to known dialogs,
                                otherwise it would be a larger refactor.
                                edit: FocusLock was moved up further to include ScrollableContainer as there was a bug when forms
                                within the dialog were taller than the dialog (clicking on a field made it scroll out of view).
                                Again only applied to one variant.
                             */}
                            {props.variant === DialogVariants.mobileFullscreenDesktopCentered ? (
                                <FocusLock autoFocus={false}>
                                    <ScrollableContainer
                                        variant={props.variant}
                                        ref={scrollRef}
                                        onClick={this.onBackgroundClick}
                                    >
                                        <DialogStyled
                                            onClick={this.onDialogClick}
                                            role="dialog"
                                            aria-modal="true"
                                            aria-label={this.props.ariaLabel}
                                            className={className}
                                            ref={dialogRef}
                                            {...props}
                                        >
                                            {children}
                                        </DialogStyled>
                                    </ScrollableContainer>
                                </FocusLock>
                            ) : (
                                <ScrollableContainer
                                    variant={props.variant}
                                    ref={scrollRef}
                                    onClick={this.onBackgroundClick}
                                >
                                    <DialogStyled
                                        onClick={this.onDialogClick}
                                        role="dialog"
                                        aria-modal="true"
                                        aria-label={this.props.ariaLabel}
                                        className={className}
                                        {...props}
                                        ref={dialogRef}
                                    >
                                        <FocusLockStyled autoFocus={false}>
                                            {children}
                                        </FocusLockStyled>
                                    </DialogStyled>
                                </ScrollableContainer>
                            )}
                        </DialogWrapperStyled>
                    </>
                </ZIndexManager>
            </ZIndexManager>
        ) : null;
    }
}

Dialog.defaultProps = {
    isModal: false,
};

Dialog.Variant = DialogVariants;

export default Dialog;
