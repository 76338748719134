import { connect } from 'react-redux';
import { getAccountCard } from 'api/selectors/account';
import { withRouter } from 'react-router';
import AddonsCheckout from './AddonsCheckout';
import { loadStripe } from './actions';
import { getStripe } from './selectors';

const mapDispatchToProps = (dispatch) => ({
    loadStripe: () => dispatch(loadStripe.create()),
});

const mapStateToProps = (state) => ({
    accountCard: getAccountCard(state),
    stripe: getStripe(),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddonsCheckout));
