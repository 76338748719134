import { v2ApiUrl, v3ApiUrl } from './constants';

export default {
    // GET, DELETE: Account summary
    _: `${v3ApiUrl}account/`,

    // POST: Account deletion request
    delete: `${v3ApiUrl}account/deletion-request/`,

    // GET, PUT: Account settings
    details: `${v3ApiUrl}account/details/`,

    // PUT profile photo
    updateProfilePhoto: `${v2ApiUrl}account/profile/photo/:mediaId:/`,

    // GET, PUT: Account details (First Name, Last Name, Email, Password, Email alerts, Telephone number, ...)
    settings: `${v3ApiUrl}account/settings/`,

    // GET, PUT: Account membership (Renew, Upgrade, Cancel)
    membershipPlan: {
        // GET, DELETE - current plan
        _: `${v3ApiUrl}account/membership-plan/`,

        // POST, PUT, UPDATE
        incomplete: `${v3ApiUrl}account/membership-plan/incomplete/`,

        // POST, PUT, UPDATE
        subscribed: `${v3ApiUrl}account/membership-plan/subscribed/`,
    },

    // GET, POST, DELETE
    card: `${v3ApiUrl}account/card/`,

    // POST, DELETE
    subscription: `${v3ApiUrl}account/subscription/`,
    subscriptionActivate: `${v3ApiUrl}account/subscription/activate/`,

    // GET
    subscriptionRenewalAmount: `${v3ApiUrl}account/subscription/renewal-amount/`,

    // PUT, DELETE
    subscriptionAutorenew: `${v3ApiUrl}account/subscription/autorenew/`,

    // GET, Push notifications settings
    pushNotificationSubscriptions: `${v3ApiUrl}account/notifications/subscriptions/`,

    // GET
    wsToken: `${v3ApiUrl}account/ws-token/`,

    // POST
    forgottenPassword: `${v3ApiUrl}account/settings/reset-password/`,

    // PUT
    changePassword: `${v3ApiUrl}account/settings/change-password/`,

    // POST
    app: `${v3ApiUrl}account/app/`,

    // POST
    validateEmail: `${v3ApiUrl}account/validate-email/`,

    refunds: {
        // GET
        status: `${v3ApiUrl}account/refunds/status/`,

        // POST
        request: `${v3ApiUrl}account/refunds/request/`,
    },
    referAFriend: {
        // POST
        email: `${v3ApiUrl}account/refer-a-friend/email/`,
    },

    emergencyContact: {
        // GET and POST
        _: `${v3ApiUrl}account/emergency-contact-details/representatives/`,

        // PUT
        update: `${v3ApiUrl}account/emergency-contact-details/representatives/:id:/`,
    },

    safetyContact: `${v3ApiUrl}account/emergency-contact-details/user/`,

    email: {
        subscribe: `${v3ApiUrl}account/crm/email-subscribe/`,
        unsubscribe: `${v3ApiUrl}account/crm/email-unsubscribe/`,
    },
};
