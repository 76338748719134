import React from 'react';
import { HelperTextStyled, CheckboxStyled } from './CheckboxField.style';
import ErrorMessage from '../ErrorMessage';

const CheckboxField = ({
    label,
    helperText,
    error,
    touched,
    onChange,
    onBlur,
    checked,
    name,
    ...props
}) => {
    const isErrored = error && touched;

    return (
        <>
            <CheckboxStyled
                id={name}
                name={name}
                label={
                    <div>
                        {label}
                        <HelperTextStyled>{helperText}</HelperTextStyled>
                        {isErrored ? <ErrorMessage>{error}</ErrorMessage> : null}
                    </div>
                }
                onChange={onChange}
                onBlur={onBlur}
                isChecked={checked}
                isErrored={isErrored}
                {...props}
            />
        </>
    );
};

CheckboxField.defaultProps = {};

export default CheckboxField;
