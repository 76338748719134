const DialogVariants = Object.freeze({
    mobileFullscreenDesktopCentered: 'mobileFullscreenDesktopCentered',
    mobileBottomDesktopCentered: 'mobileBottomDesktopCentered',
    mobileTopDesktopCentered: 'mobileTopDesktopCentered',
    searchListingsFirstItemInfoPromptLOWAPPLICANTSABTEST:
        'searchListingsFirstItemInfoPromptLOWAPPLICANTSABTEST',
});

// eslint-disable-next-line import/prefer-default-export
export { DialogVariants };
