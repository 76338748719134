export const UPLOADED_PHOTO_TYPES = {
    home: 'Home',
    profilePhoto: 'Profile',
    petPhoto: 'Pet',
    sitterProfilePhoto: 'Photos of you',
};

// eslint-disable-next-line import/prefer-default-export
export const USER_UPLOADED_PHOTO = {
    create(photoType, photoCount) {
        return {
            type: 'User Uploaded Photo',
            payload: {
                photoType,
                photoCount,
            },
        };
    },
};
