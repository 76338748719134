import { genericType } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const SWIPE = {
    create(options) {
        return {
            type: genericType,
            payload: {
                name: 'swipe',
                ...options,
            },
        };
    },
};
