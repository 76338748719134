import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { ErrorMessage } from 'components/forms';
import { Button } from 'components/buttons';
import {
    CardHeadingAndIconsContainer,
    CardHeading,
    CardIconsContainer,
    CardIcon,
    CardPaymentContainer,
    CardPaymentInputWrapper,
    CardPaymentErrorContainer,
    SavedCardDetailsStyled,
    CardIconWrapper,
    CardInfoWrapper,
    CardLastFour,
    CardExpiry,
    ChangeCardWrapper,
    ChangeCardButton,
} from './FakeCardPayment.style';
import Visa from './assets/visa.svg';
import Mastercard from './assets/mastercard.svg';
import AmericanExpress from './assets/american-express.svg';
import DefaultCard from './assets/default-card.svg';

// this is how the styling is passed down the card input elements
// refer to https://stripe.com/docs/js/appendix/style
const getCardPaymentInputStyles = ({ theme }) => ({
    base: {
        color: `${theme.palette.grey[700]}`,
        fontWeight: `${theme.fontWeights.normal}`,
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        textAlign: 'center',
        '::placeholder': {
            color: `${theme.palette.grey[500]}`,
            fontSize: '16px',
        },
    },
    invalid: {
        iconColor: `${theme.palette.danger}`,
        color: `${theme.palette.grey[700]}`,
    },
});

export const FakeCardPaymentFormTestIds = {
    cardPaymentContainer: 'CardPaymentForm_cardPaymentContainer',
    cardNumberElement: 'CardPaymentForm_cardNumberElement',
    cardExpiryElement: 'CardPaymentForm_cardExpiryElement',
    cardCvcElement: 'CardPaymentForm_cardCvcElement',
    cardErrorContainer: 'CardPaymentForm_cardErrorContainer',
    cardNumberError: 'CardPaymentForm_cardNumberError',
    cardExpiryError: 'CardPaymentForm_cardExpiryError',
    cardCvcError: 'CardPaymentForm_cardCvcError',
    savedCardContainer: 'CardPaymentForm_savedCardContainer',
    savedCardIcon: 'CardPaymentForm_savedCardIcon',
    savedCardTitle: 'CardPaymentForm_savedCardTitle',
    savedCardExpiry: 'CardPaymentForm_savedCardExpiry',
    savedCardChangeButton: 'CardPaymentForm_savedCardChangeButton',
};

const CARD_BRANDS = {
    VISA: 'visa',
    MASTERCARD: 'mastercard',
    AMEX: 'amex',
};

const SavedCardDetails = ({ card, handleChangeCard }) => {
    const { t } = useTranslation();

    const { brand, last4, expiryMonth, expiryYear } = card;

    let cardImg;
    let cardTitle;

    switch (brand) {
        case CARD_BRANDS.VISA:
            cardImg = Visa;
            cardTitle = 'pages_checkout_payment_brand_visa';
            break;
        case CARD_BRANDS.MASTERCARD:
            cardImg = Mastercard;
            cardTitle = 'pages_checkout_payment_brand_mastercard';
            break;
        case CARD_BRANDS.AMEX:
            cardImg = AmericanExpress;
            cardTitle = 'pages_checkout_payment_brand_amex';
            break;
        default:
            cardImg = DefaultCard;
            cardTitle = 'pages_checkout_payment_brand_default';
            break;
    }

    return (
        <SavedCardDetailsStyled>
            {cardImg && (
                <CardIconWrapper>
                    <CardIcon
                        src={cardImg}
                        large
                        data-testid={FakeCardPaymentFormTestIds.savedCardIcon}
                    />
                </CardIconWrapper>
            )}
            <CardInfoWrapper>
                <CardLastFour data-testid={FakeCardPaymentFormTestIds.savedCardTitle}>
                    {brand && t(cardTitle)} {last4}
                </CardLastFour>
                <CardExpiry data-testid={FakeCardPaymentFormTestIds.savedCardExpiry}>
                    {expiryMonth && expiryYear && t(`pages_checkout_payment_expiry`)} {expiryMonth}/
                    {expiryYear.toString().slice(2) || '00'}
                </CardExpiry>
            </CardInfoWrapper>
            <ChangeCardWrapper>
                <ChangeCardButton
                    variant={Button.Variant.LINK}
                    onClick={handleChangeCard}
                    data-testid={FakeCardPaymentFormTestIds.savedCardChangeButton}
                >
                    {t('pages_checkout_payment_change_card')}
                </ChangeCardButton>
            </ChangeCardWrapper>
        </SavedCardDetailsStyled>
    );
};

const FakeCardPaymentForm = ({
    cardPaymentValidation,
    card = {},
    showPaymentMethods,
    handleSetStripeCardElementsReady,
    setShowPaymentMethods,
    useSavedCard,
    setUseSavedCard,
}) => {
    const { t } = useTranslation();
    const theme = useContext(ThemeContext);
    const cardPaymentInputStyles = getCardPaymentInputStyles({ theme });
    const [hasCardPaymentErrored, setHasCardPaymentErrored] = useState(false);
    const [cardNumber, setCardNumber] = useState({
        isErrored: false,
        errorMessage: '',
        empty: true,
        complete: false,
    });
    const [cardExpiry, setCardExpiry] = useState({
        isErrored: false,
        errorMessage: '',
        empty: true,
        complete: false,
    });
    const [cardCvc, setCardCvc] = useState({
        isErrored: false,
        errorMessage: '',
        empty: true,
        complete: false,
    });

    // this sets the error state for all card payment inputs
    // so that it changes the entire border colour when viewing the desktop version of the card payment section
    // and not individual sections
    useEffect(() => {
        if (cardNumber.isErrored || cardExpiry.isErrored || cardCvc.isErrored) {
            setHasCardPaymentErrored(true);
        } else {
            setHasCardPaymentErrored(false);
        }
    }, [cardCvc.isErrored, cardExpiry.isErrored, cardNumber.isErrored]);

    useEffect(() => {
        cardPaymentValidation.setIsCardPaymentComplete(
            cardNumber.complete === true &&
                cardExpiry.complete === true &&
                cardCvc.complete === true
        );
    }, [cardCvc.complete, cardExpiry.complete, cardNumber.complete, cardPaymentValidation]);

    useEffect(
        () => {
            if (cardPaymentValidation.triggerEmptyCardError === true) {
                setCardNumber({
                    ...cardNumber,
                    isErrored: cardNumber.empty === true ? true : cardNumber.isErrored,
                    errorMessage:
                        cardNumber.empty === true
                            ? t('pages_checkout_payment_errorMessages_empty_card_number')
                            : cardNumber.errorMessage,
                });
                setCardExpiry({
                    ...cardExpiry,
                    isErrored: cardExpiry.empty === true ? true : cardExpiry.isErrored,
                    errorMessage:
                        cardExpiry.empty === true
                            ? t('pages_checkout_payment_errorMessages_empty_expiry_date')
                            : cardExpiry.errorMessage,
                });
                setCardCvc({
                    ...cardCvc,
                    isErrored: cardCvc.empty === true ? true : cardCvc.isErrored,
                    errorMessage:
                        cardCvc.empty === true
                            ? t('pages_checkout_payment_errorMessages_empty_cvc')
                            : cardCvc.errorMessage,
                });
            } else {
                cardPaymentValidation.setTriggerEmptyCardError(false);
            }
        },
        // only care about triggerEmptyCardError being changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cardPaymentValidation.triggerEmptyCardError]
    );

    useEffect(() => {
        cardPaymentValidation.setIsCardPaymentFormEmpty(
            cardNumber.empty === true || cardExpiry.empty === true || cardCvc.empty === true
        );
    }, [cardCvc.empty, cardExpiry.empty, cardNumber.empty, cardPaymentValidation]);

    useEffect(() => {
        const hasSavedCard = card && Object.prototype.hasOwnProperty.call(card, 'id');
        setUseSavedCard(hasSavedCard);

        setShowPaymentMethods(!hasSavedCard);
    }, [card, card.id, setUseSavedCard, setShowPaymentMethods]);

    const handleChangeCard = () => {
        setUseSavedCard(false);
        // we set this to false to create a clean slate for the card payment form
        // this allows the loading spinner to show again while the Stripe elements are being loaded
        setShowPaymentMethods(false);
    };
    return (
        <>
            <CardHeadingAndIconsContainer>
                <CardHeading>{t('pages_checkout_payment_details_cardHeading')}</CardHeading>
                <CardIconsContainer>
                    <CardIcon src={Visa} />
                    <CardIcon src={Mastercard} />
                    <CardIcon src={AmericanExpress} />
                </CardIconsContainer>
            </CardHeadingAndIconsContainer>
            {useSavedCard && !showPaymentMethods && (
                <SavedCardDetails
                    card={card}
                    handleChangeCard={handleChangeCard}
                    data-testid={FakeCardPaymentFormTestIds.savedCardContainer}
                />
            )}

            {(!useSavedCard || showPaymentMethods) && (
                <CardPaymentContainer
                    hasCardPaymentErrored={hasCardPaymentErrored}
                    data-testid={FakeCardPaymentFormTestIds.cardPaymentContainer}
                    showPaymentMethods={showPaymentMethods}
                >
                    <CardPaymentInputWrapper
                        cardNumberError={cardNumber.isErrored}
                        hasCardPaymentErrored={hasCardPaymentErrored}
                        mobileFullWidth
                        data-testid={FakeCardPaymentFormTestIds.cardNumberElement}
                        showPaymentMethods={showPaymentMethods}
                    >
                        <CardNumberElement
                            options={{
                                showIcon: true,
                                style: {
                                    ...cardPaymentInputStyles,
                                    base: {
                                        ...cardPaymentInputStyles.base,
                                        textAlign: 'left',
                                    },
                                },
                            }}
                            onChange={(e) => {
                                setCardNumber({
                                    ...cardNumber,
                                    isErrored: e.error,
                                    errorMessage:
                                        e.error &&
                                        t(`pages_checkout_payment_errorMessages_${e.error.code}`),
                                    empty: e.empty,
                                    complete: e.complete,
                                });
                            }}
                            onReady={() => handleSetStripeCardElementsReady({ card: true })}
                        />
                    </CardPaymentInputWrapper>
                    <CardPaymentInputWrapper
                        cardExpiryError={cardExpiry.isErrored}
                        hasCardPaymentErrored={hasCardPaymentErrored}
                        data-testid={FakeCardPaymentFormTestIds.cardExpiryElement}
                        showPaymentMethods={showPaymentMethods}
                    >
                        <CardExpiryElement
                            options={{
                                style: cardPaymentInputStyles,
                            }}
                            onChange={(e) => {
                                setCardExpiry({
                                    ...cardExpiry,
                                    isErrored: e.error,
                                    errorMessage:
                                        e.error &&
                                        t(`pages_checkout_payment_errorMessages_${e.error.code}`),
                                    empty: e.empty,
                                    complete: e.complete,
                                });
                            }}
                            onReady={() => handleSetStripeCardElementsReady({ expiry: true })}
                        />
                    </CardPaymentInputWrapper>
                    <CardPaymentInputWrapper
                        cardCvcError={cardCvc.isErrored}
                        hasCardPaymentErrored={hasCardPaymentErrored}
                        data-testid={FakeCardPaymentFormTestIds.cardCvcElement}
                        showPaymentMethods={showPaymentMethods}
                    >
                        <CardCvcElement
                            options={{
                                style: cardPaymentInputStyles,
                            }}
                            onChange={(e) => {
                                setCardCvc({
                                    ...cardCvc,
                                    isErrored: e.error,
                                    errorMessage:
                                        e.error &&
                                        t(`pages_checkout_payment_errorMessages_${e.error.code}`),
                                    empty: e.empty,
                                    complete: e.complete,
                                });
                            }}
                            onReady={() => handleSetStripeCardElementsReady({ cvc: true })}
                        />
                    </CardPaymentInputWrapper>
                </CardPaymentContainer>
            )}
            {hasCardPaymentErrored && (
                <CardPaymentErrorContainer
                    data-testid={FakeCardPaymentFormTestIds.cardErrorContainer}
                >
                    {cardNumber.isErrored && (
                        <ErrorMessage data-testid={FakeCardPaymentFormTestIds.cardNumberError}>
                            {cardNumber.errorMessage}
                        </ErrorMessage>
                    )}
                    {cardExpiry.isErrored && (
                        <ErrorMessage data-testid={FakeCardPaymentFormTestIds.cardExpiryError}>
                            {cardExpiry.errorMessage}
                        </ErrorMessage>
                    )}
                    {cardCvc.isErrored && (
                        <ErrorMessage data-testid={FakeCardPaymentFormTestIds.cardCvcError}>
                            {cardCvc.errorMessage}
                        </ErrorMessage>
                    )}
                </CardPaymentErrorContainer>
            )}
        </>
    );
};

export default FakeCardPaymentForm;
