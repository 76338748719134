import { Facet as searchFacets } from 'api/helpers/search/constants';
import { base64encode } from 'utils/strings';
import {
    hasCountry,
    hasAdmin1,
    isContinentFeatureCode,
    isCountryFeatureCode,
    isAdmin1FeatureCode,
    isAdmin2FeatureCode,
    isAdmin3FeatureCode,
    isAdmin4FeatureCode,
    isAdminDivisionFeatureCode,
    isPlaceFeatureClass,
    getCountryGeoHierarchy,
    getAdmin1GeoHierarchy,
} from './helpers';
import { SEARCH_TYPE_COUNTRY_CITIES, SEARCH_LEVEL } from './constants';

/**
 * Return a query and search string that represents the current page but:
 * - Without location
 * - On page 1
 *
 * This is useful for SEO links and also for breadcrumbs
 */
export const copySearchForLink = (query) => {
    // Separate existing filters from the rest of the current query
    const { filters: existingFilters = {}, ...existingQuery } = query;

    // Discard any existing location search because we are going to override that with the breadcrumb link
    const { geoBounds, geoHierarchy, geoPoint, ...filters } = existingFilters;

    // The new search is the same as the old except:
    // - No location
    // - Always on the first page
    const newQuery = { ...existingQuery, filters, page: 1 };
    const search = Object.keys(existingQuery).length
        ? `?q=${base64encode(JSON.stringify(newQuery))}`
        : null;

    return { query: newQuery, search };
};

/**
 * Return a query and search string that represents the current page but:
 * - Only location (only geoHierarchy or geoPoint)
 *
 * This is useful for linking between search pages retaining the location they were searching for
 */
export const copyLocationSearchForLink = (query) => {
    // Separate existing filters from the rest of the current query
    const { filters: existingFilters = {} } = query;

    // Discard any existing location search because we are going to override that with the breadcrumb link
    const { geoHierarchy, geoPoint } = existingFilters;

    // The new search is the same as the old except:
    // - Only the location filter is retained (only geoHierarchy or geoPoint)
    const newQuery = { filters: {} };
    if (geoHierarchy) newQuery.filters.geoHierarchy = geoHierarchy;
    else if (geoPoint) newQuery.filters.geoPoint = geoPoint;
    const search = Object.keys(newQuery.filters).length
        ? `?q=${base64encode(JSON.stringify(newQuery))}`
        : null;

    return { query: newQuery, search };
};

/**
 * Given a facet component with some filters applied to it,
 * return the encoded query string with those filters to the facet
 * or return null if there are no filters
 */
export const getDynamicFacetsQueryString = (query) => {
    // The activeMembership is almost always present but it don't chang the result
    // We also don't want to include the geoHierarchy
    const { activeMembership, assignments, geoHierarchy, ...filters } = query;

    // If there are any filters other than the removed ones, build the encoded query string
    const search = Object.keys(filters).length
        ? `?q=${base64encode(
              JSON.stringify({ filters: { ...filters, activeMembership, assignments } })
          )}`
        : null;

    return search;
};

/**
 * Extract location facets from the last search and provide a collection of links
 * to any locations found in the search results.
 *
 * This powers the SEO location lists at the bottom of the search page
 */
export const getFacetLinks = ({ query, facets, route }) => {
    // Extract the facets that were returned as part of the last search
    const facetLinks = {};
    const { search } = copySearchForLink(query);

    // A helper to create the metadata needed to render a facet link
    const format = (item) => {
        const { count, name, ...location } = item;

        return { count, name, location: { pathname: route(location), search } };
    };

    // Only add the facets that were returned from the last search (levels below the current search)
    if (facets[searchFacets.COUNTRY]) {
        facetLinks[searchFacets.COUNTRY] = facets[searchFacets.COUNTRY].map(format);
    }
    if (facets[searchFacets.ADMIN1]) {
        facetLinks[searchFacets.ADMIN1] = facets[searchFacets.ADMIN1].map(format);
    }
    if (facets[searchFacets.ADMIN2]) {
        facetLinks[searchFacets.ADMIN2] = facets[searchFacets.ADMIN2].map(format);
    }
    if (facets[searchFacets.PLACE]) {
        facetLinks[searchFacets.PLACE] = facets[searchFacets.PLACE].map(format);
    }

    return facetLinks;
};

/**
 * Build an array of breadcrumb links/text to be used on the search pages
 *
 * NOTE 1: Our general tactic here is to show breadcrumbs for worldwide > country > admin1 > place
 * We will make all items except the last one links. The last item will be just text.
 * We _could_ make a link but it might not match the page they are on because we use different
 * tactics for user-initiated searches vs landing page searches. QA/UX thought it was better to lose the link
 * than to have it go somewhere slightly different which might be jarring
 *
 * NOTE 2: We cannot make links for other types of searches such as admin2/3/4/division so in this case we
 * will always just show text for that item
 */
export const getBreadcrumbLinks = ({ query, place, route }) => {
    const { search } = copySearchForLink(query);

    // A helper to create the metadata needed to render a facet link
    const getLocation = (slugs) => ({ pathname: route(slugs), search });
    const breadcrumbs = {
        home: { location: { pathname: '/', search } },
        sittingPage: { location: getLocation() },
        hierarchy: [],
    };

    // If we don't have a place yet then return early
    if (!place) return breadcrumbs;

    if (isContinentFeatureCode(place.featureCode)) {
        breadcrumbs.hierarchy.push({ name: place.continentName });
    }
    // If we have a country then add it
    else if (isCountryFeatureCode(place.featureCode)) {
        // They searched for a country directly so just add text (see NOTE 1 above)
        breadcrumbs.hierarchy.push({ name: place.countryName });
    } else if (hasCountry(place)) {
        // They searched for something below country and the results contain country so we can add a link
        breadcrumbs.hierarchy.push({
            name: place.countryName,
            location: getLocation(getCountryGeoHierarchy(place)),
        });

        // If we have an admin 1 then add it
        if (isAdmin1FeatureCode(place.featureCode)) {
            // They searched for an admin1 directly so just add text (see NOTE 1 above)
            breadcrumbs.hierarchy.push({ name: place.admin1Name });
        } else if (hasAdmin1(place)) {
            // They searched for something below admin1 and the results contain admin1 so we can add a link
            breadcrumbs.hierarchy.push({
                name: place.admin1Name,
                location: getLocation(getAdmin1GeoHierarchy(place)),
            });
        }

        // Because of the reasons outlined in NOTE 1 & NOTE 2 (above) we will just show text for the
        // following search types
        if (
            isPlaceFeatureClass(place.featureClass) ||
            isAdmin2FeatureCode(place.featureCode) ||
            isAdmin3FeatureCode(place.featureCode) ||
            isAdmin4FeatureCode(place.featureCode) ||
            isAdminDivisionFeatureCode(place.featureCode)
        ) {
            breadcrumbs.hierarchy.push({ name: place.name });
        }
    }

    return breadcrumbs;
};

export const getSearchLevel = ({ state, page }) => {
    if (!state.pages.search[page]) return '';

    return state.pages.search[page].searchLevel;
};

export const getIsPlaceSearch = ({ state, page }) =>
    getSearchLevel({ state, page }) === SEARCH_LEVEL.PLACE;

export const getPlaceFacets = ({ state, searchType }) => {
    if (!state.search[searchType][SEARCH_TYPE_COUNTRY_CITIES]) return {};

    return state.search[searchType][SEARCH_TYPE_COUNTRY_CITIES].facets;
};
