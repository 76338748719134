import { settings as actions } from '../actions';

import { createdReducer } from './common';

export const initialState = {
    token: '',
    error: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.account.changePassword.SUCCESS:
        case actions.session.create.SUCCESS:
        case actions.session.createApple.SUCCESS:
        case actions.session.createFacebook.SUCCESS:
        case actions.session.createGoogle.SUCCESS:
            return createdReducer(state, action);
        case actions.session.validate.SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        case actions.session.validate.ERROR:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
