import { settings } from 'api/actions';
import * as actions from './actions';

const defaultState = {
    hasMore: false,
    isInitialLoading: true,
    isLoading: true,
    results: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actions.hydrate.ACTION:
            return {
                ...state,
                results: action.payload.results,
                hasMore: action.payload.hasMore,
                isInitialLoading: false,
                isLoading: false,
            };
        case actions.loadMoreRequest.ACTION:
            return {
                ...state,
                isLoading: true,
            };
        case actions.loadMoreSuccess.ACTION:
            return {
                ...state,
                results: [...state.results, ...action.payload.results],
                hasMore: action.payload.hasMore,
                isLoading: false,
            };
        case settings.sitter.createPastAssignmentFeedback.SUCCESS: {
            return {
                ...state,
                results: state.results.map((assignment) => {
                    if (String(assignment.id) === String(action.data.assignmentId)) {
                        return {
                            ...assignment,
                            feedback: action.data,
                            isFeedbackProvided: true,
                        };
                    }

                    return assignment;
                }),
            };
        }
        case settings.sitter.loadAssignment.SUCCESS: {
            return {
                ...state,
                results: state.results.map((value) =>
                    value.id === action.data.id ? action.data : value
                ),
            };
        }
        default:
            return state;
    }
};
