import { locationName, locationType } from 'api/helpers/format/location';

// eslint-disable-next-line import/prefer-default-export
export const SEARCH_LOCATION = {
    create(category, query, place) {
        let location;

        if (place.id === 'anywhere') {
            location = {
                name: place.name,
            };
        } else {
            location = {
                name: locationName(place, true),
                region: place.admin1Name,
                type: place.featureClass ? locationType(place) : undefined,
            };
        }

        let selected = location.name;
        if (location.region) {
            selected = `${selected}, ${location.region}`;
        }
        if (location.type) {
            selected = `${selected}, ${location.type}`;
        }

        const payload = {
            typed: query,
            selected,
            place_id: place.id,
            country: place.countryName,
            admin1: place.admin1Name,
            place: place.name,
            latitude: place.location ? place.location.lat : undefined,
            longitude: place.location ? place.location.lon : undefined,
            category,
        };

        if (place.isSuggestion) {
            payload.searchType = 'Search Bar Dropdown Suggestion';
        }

        return {
            type: 'Products Searched',
            payload,
        };
    },
};
