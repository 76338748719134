import uniq from 'lodash/uniq';

// eslint-disable-next-line import/prefer-default-export
export const USER_SENT_SHARE_LISTING_EMAIL = {
    create(emailAddresses, success) {
        return {
            type: 'Sent Listing Share Emails',
            payload: {
                uniqueEmailCount: uniq(emailAddresses).length,
                success,
            },
        };
    },
};
