import styled from 'styled-components';
import { visuallyHide } from 'utils/utils';

const FieldsetStyled = styled.fieldset`
    border: 0;
    padding: 0;
    margin-left: 0px;
`;

const LegendStyled = styled.legend`
    ${visuallyHide};
`;

export { FieldsetStyled, LegendStyled };
