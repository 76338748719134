/* eslint-disable import/prefer-default-export,quote-props */
/**
 * Capitalize given word
 */
export const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Capitalize all words
 */
export const capitalizeAll = (str = '') => str.split(' ').map(word => capitalize(word)).join(' ');


/**
 * Slugify
 */
export const slugify = str => {
    if (str == null) return '';

    let normalizedString = str.toString();

    if (typeof String.prototype.normalize === 'function') {
        normalizedString = normalizedString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    else {
        const from = 'èéêëēėęÿûüùúūîïíīįìôöòóœøōõàáâäæãåāßśšďḋḑḍḓḏđd̦ɖɗƌłžźżçćčñń';
        const to = 'eeeeeeeyuuuuuiiiiiiooooooooaaaaaaaasssdddddddddddlzzzcccnn';
        const regex = new RegExp(`[${from}]`, 'g');

        // Escape string for regex
        normalizedString = String(normalizedString)
            // eslint-disable-next-line no-useless-escape
            .replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1')
            .replace(regex, (c) => {
                const index = from.indexOf(c);
                return to.charAt(index) || '-';
            })
        ;
    }

    return normalizedString
        .toLowerCase() // Lower case the string
        .replace(/[^\w\s-]/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
        .replace(/[\s_-]+/g, '-');
};
