import styled from 'styled-components';
import { H1, Span, smallTextStyles } from 'typography';

const TitleContainerStyled = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing[1]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[1]};
    }
`;

const TitleStyled = styled(H1)`
    ${({ theme }) => theme.typography.h2};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        ${({ theme }) => theme.typography.h1};
    }
`;

const MobileShareButtonStyled = styled.button`
    all: unset;
    box-sizing: border-box;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        display: none;
    }
`;

const InfoContainerStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        flex-direction: row;
        align-items: center;
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

const AuthorContainerStyled = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[2]};
`;

const AuthorAvatarStyled = styled.img`
    border-radius: 50%;
    height: 48px;
    width: 48px;
    object-fit: cover;
`;

const AuthorDateAndReadingTimeStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[0.5]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        flex-direction: row;
        gap: ${({ theme }) => theme.spacing[1]};
    }
`;

const AuthorName = styled(Span)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    cursor: pointer;
`;

const PublishedContainerStyled = styled.div`
    ${smallTextStyles}
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[0.5]};
    color: ${({ theme }) => theme.palette.textLight};
`;

const ReadingTimeStyled = styled.span`
    &::before {
        content: '•';
        margin-right: ${({ theme }) => theme.spacing[0.5]};
    }
`;

export {
    TitleContainerStyled,
    TitleStyled,
    MobileShareButtonStyled,
    InfoContainerStyled,
    AuthorContainerStyled,
    AuthorAvatarStyled,
    AuthorName,
    AuthorDateAndReadingTimeStyled,
    PublishedContainerStyled,
    ReadingTimeStyled,
};
