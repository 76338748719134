import { v3ApiUrl } from './constants';

export default {
    phone: {
        // POST
        request: `${v3ApiUrl}verifications/phone/request/`,

        // PUT
        verify: `${v3ApiUrl}verifications/phone/:requestId:/`,
    },
    email: {
        // POST Sends a new email for user to verify their email address
        _: `${v3ApiUrl}verifications/email/`,
    },
    id: {
        // POST
        _: `${v3ApiUrl}verifications/id-check/`,
    },
    criminalBackgroundCheck: {
        // POST
        _: `${v3ApiUrl}verifications/criminal-background-check/`,
    },
    social: {
        // POST:
        _: `${v3ApiUrl}verifications/social/:platform:/`,

        // DELETE, PUT
        id: `${v3ApiUrl}verifications/social/:platform:/:id:/`,
    },
    status: {
        // GET
        _: `${v3ApiUrl}verifications/status/`,
    },
    references: {
        // GET
        _: `${v3ApiUrl}verifications/references/`,
        // POST
        request: `${v3ApiUrl}verifications/references/request/`,
        // GET, PUT - provide reference
        provide: `${v3ApiUrl}verifications/references/provide/:referenceRequestToken:/`,
    },
};
