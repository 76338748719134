import styled from 'styled-components';
import { IconArrowLeft, IconArrowRight } from 'components/Icon';
import { H2 } from 'typography';

const TitleStyled = styled(H2)`
    margin-top: 0;
`;

const ListWrapperStyled = styled.div`
    position: relative;
    margin-left: ${({ theme }) => `-${theme.spacing[3]}`};
    margin-right: ${({ theme }) => `-${theme.spacing[3]}`};

    @media (min-width: ${({ theme }) => `${theme.screenSize.mediumTablet}px`}) {
        margin-left: 0;
        margin-right: 0;
    }
`;

const ListContainerStyled = styled.div`
    overflow: hidden;
`;

const ListStyled = styled.ul.attrs(({ position, perPage }) => ({
    style: {
        transform: `translateX(calc(${position} * (-100% / ${perPage})))`,
    },
}))`
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    list-style: none;
    white-space: nowrap;
    padding: 0;

    transition: transform 200ms ease-in-out;

    margin-left: ${({ theme }) => `-${theme.spacing[1]}`};
    margin-right: ${({ theme }) => `-${theme.spacing[1]}`};

    @media (min-width: ${({ theme }) => `${theme.screenSize.mediumTablet}px`}) {
        overflow: visible;
    }
    @media (min-width: ${({ theme }) => `${theme.screenSize.mediumDesktop}px`}) {
        margin-left: ${({ theme, spacingBetweenCards }) =>
            spacingBetweenCards
                ? `-${theme.spacing[spacingBetweenCards]}`
                : `-${theme.spacing[2]}`};
        margin-right: ${({ theme, spacingBetweenCards }) =>
            spacingBetweenCards
                ? `-${theme.spacing[spacingBetweenCards]}`
                : `-${theme.spacing[2]}`};
    }
`;

const ListItemStyled = styled.li`
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    /* theme spacing here needs to be twice the padding value below */
    width: ${({ width, theme }) => `calc(${width} - ${theme.spacing[2]})`};

    padding-left: ${({ theme }) => theme.spacing[1]};
    padding-right: ${({ theme }) => theme.spacing[1]};

    &:first-of-type {
        margin-left: ${({ theme }) => theme.spacing[3]};
    }

    &:last-of-type {
        margin-right: ${({ theme }) => theme.spacing[3]};
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: ${({ width }) => width};
        &:first-of-type,
        &:last-of-type {
            margin: 0;
        }
    }

    @media (min-width: ${({ theme }) => `${theme.screenSize.mediumDesktop}px`}) {
        padding-left: ${({ theme, spacingBetweenCards }) =>
            spacingBetweenCards ? theme.spacing[spacingBetweenCards] : theme.spacing[2]};
        padding-right: ${({ theme, spacingBetweenCards }) =>
            spacingBetweenCards ? theme.spacing[spacingBetweenCards] : theme.spacing[2]};
    }
`;

const ArrowLeftStyled = styled(IconArrowLeft)`
    && {
        width: 100%;
        font-size: 1.4rem; // 21

        & svg {
            fill: ${({ theme }) => theme.palette.primaryRegular};
        }
    }
`;

const ArrowRightStyled = styled(IconArrowRight)`
    && {
        width: 100%;
        font-size: 1.4rem; // 21

        & svg {
            fill: ${({ theme }) => theme.palette.primaryRegular};
        }
    }
`;

const ArrowButtonStyled = styled.button`
    display: none;
    border: none;
    position: absolute;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    top: calc(50% - 1.5em);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 100%;
    transition: background-color 200ms ease-in-out, opacity 350ms ease-in-out;

    &:hover {
        background-color: ${({ theme }) => theme.palette.hoverGrey};
    }

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: block;
    }
`;

const PreviousButtonStyled = styled(ArrowButtonStyled)`
    left: ${({ theme }) => `-${theme.spacing[3]}`};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const NextButtonStyled = styled(ArrowButtonStyled)`
    right: ${({ theme }) => `-${theme.spacing[3]}`};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export {
    TitleStyled,
    ListWrapperStyled,
    ListContainerStyled,
    ListStyled,
    ListItemStyled,
    ArrowLeftStyled,
    ArrowRightStyled,
    PreviousButtonStyled,
    NextButtonStyled,
};
