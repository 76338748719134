import { createAction } from 'utils/actions';

const prefix = 'PHOTO_UPLOAD';

export const uploadRequest = createAction(
    prefix,
    'UPLOAD_REQUEST',
    'photoPreview',
    'fileBuffer',
    'onUploadPhotoError',
    'onUploadPhotoProgress',
    'onUploadPhotoSuccess'
);
export const uploadSuccess = createAction(prefix, 'UPLOAD_SUCCESS', 'photo', 'response');
