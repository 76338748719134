import { all, call, put, take, takeEvery } from 'redux-saga/effects';
import {
    preload as pageActionsPreload,
    preloaded as pageActionsPreloaded,
    load as pageActionsLoad,
    loaded as pageActionsLoaded,
    error as errorAction,
} from 'containers/Page/actions';
import actions, { settings } from 'api/actions';
import { PAGE_ID } from './Location.constants';

export function* loadPageData(params) {
    yield all([
        put(
            actions.owner.loadListingAddress({
                forceReload: true,
                data: {
                    listingId: params.listingId,
                },
            })
        ),

        put(
            actions.owner.loadListing({
                forceReload: true,
                data: {
                    id: params.listingId,
                },
            })
        ),
    ]);

    const [loadAddressResponse, loadListingResponse] = yield all([
        take((res) => res.type === settings.owner.loadListingAddress.DONE),
        take((res) => res.type === settings.owner.loadListing.DONE),
    ]);

    const { statusCode: loadAddressStatusCode } = loadAddressResponse;

    // The address endpoint returns a 404 if the user has not saved an address.
    if (loadAddressStatusCode !== 200 && loadAddressStatusCode !== 404) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, loadAddressStatusCode));
        return false;
    }

    const { status: loadListingStatus, statusCode: loadListingStatusCode } = loadListingResponse;

    if (loadListingStatus !== settings.owner.loadListing.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, loadListingStatusCode));
        return false;
    }

    return true;
}

export function* load(action) {
    const successFullyLoaded = yield call(loadPageData, action.params, PAGE_ID);
    if (successFullyLoaded) {
        yield put(pageActionsLoaded.create(PAGE_ID));
    }
}

export function* preload(action) {
    const successFullyLoaded = yield call(loadPageData, action.params, PAGE_ID);
    if (successFullyLoaded) {
        yield put(pageActionsPreloaded.create(PAGE_ID));
    }
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
    ]);
}
