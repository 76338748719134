import React, { PureComponent } from 'react';
import {
    BackgroundStyled,
    ToggleStyled,
    WrapperStyled,
    WrapperLabelStyled,
    CheckboxStyled,
} from './Switch.style';

class Switch extends PureComponent {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.id = `toggle_${Math.random().toString().replace(/0\./, '')}`;
    }

    onChange() {
        const { isChecked, onChange } = this.props;

        if (typeof onChange === 'function') {
            onChange(!isChecked);
        }
    }

    getCheckboxProps = () => {
        const { name, disabled } = this.props;
        const props = {};

        if (name) {
            props.name = name;
        }

        if (disabled) {
            props.disabled = true;
        } else {
            props.onChange = this.onChange;
        }

        return props;
    };

    render() {
        const { children, rightAligned, alignStart, disabled, isChecked, fullWidth, className } =
            this.props;
        const hasChildren = !!children;

        return (
            <WrapperStyled fullWidth={fullWidth} className={className}>
                <CheckboxStyled
                    id={this.id}
                    type="checkbox"
                    checked={isChecked}
                    {...this.getCheckboxProps()}
                />
                <WrapperLabelStyled
                    fullWidth={fullWidth}
                    isDisabled={disabled}
                    htmlFor={this.id}
                    alignStart={alignStart}
                >
                    {hasChildren && rightAligned && children}
                    <BackgroundStyled {...this.props} isChecked={isChecked}>
                        <ToggleStyled isChecked={isChecked} />
                    </BackgroundStyled>
                    {hasChildren && !rightAligned && children}
                </WrapperLabelStyled>
            </WrapperStyled>
        );
    }
}

Switch.defaultProps = {
    isChecked: false,
};

export default Switch;
