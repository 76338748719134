import React from 'react';
import { Redirect } from 'react-router';

const RedirectComponent = ({ to, statusCode = 301, context }) => {
    if (context) {
        context.statusCode = statusCode;
        context.url = to;
    }
    return <Redirect to={to} />;
};

export default RedirectComponent;
