import styled from 'styled-components';
import Input from '../Input';

export const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.palette.white};
    border: ${({ theme, renderBorder }) =>
        renderBorder ? `1px solid ${theme.palette.border}` : 'none'};
    border-radius: 3px;
`;

export const InputStyled = styled(Input)`
    flex: 1 1 auto;
    border: none;

    ${({ iconLeft }) => (iconLeft ? 'padding-left: 0' : null)};
    ${({ iconRight }) => (iconRight ? 'padding-right: 0' : null)};
`;
