export const REFER_A_FRIEND_NUDGEWALL_LAST_VIEWED_KEY = 'referAFriendNudgewallLastViewedDate';
export const REFER_A_FRIEND_NUDGEWALL_DISPLAYED_KEY = 'isNudgewallDisplayed';
export const REFER_A_FRIEND_NUDGEWALL_DELAY_MS = 10000;
export const REFER_A_FRIEND_NUDGEWALL_DAYS_TO_BE_CANDIDATE = 3;
export const REFER_A_FRIEND_NUDGEWALL_WEEKS_TO_SEE_AGAIN = 2;
export const REFER_A_FRIEND_NUDGEWALL_ONE_TOUCH = 'one-touch';
export const REFER_A_FRIEND_NUDGEWALL_REFER_A_FRIEND = 'refer-a-friend';
export const REFER_A_FRIEND_NUDGEWALL_STATES = {
    CHECKING: 'checking',
    TICKING: 'ticking',
    WAITING_USER: 'waiting-user',
    WAITING_TIME: 'waiting-time',
    BANNER_CONFLICT: 'banner-conflict',
    SOFT_DISMISSED: 'soft-dismissed',
};
