import styled, { css } from 'styled-components';
import { zIndex } from 'components/ZIndexManager';

export const SupportWidgetWrapper = styled.div(
    ({ hideOnThisPage, theme: { screenSize } }) => css`
        display: ${hideOnThisPage ? 'none' : 'block'};

        /* 800 */
        @media screen and (min-width: ${screenSize.largeTablet}px) {
            display: block;
        }
    `
);

export const SupportWidgetButtonContainer = styled.div(
    ({
        addBottomSpacing,
        isCallOpen,
        isZendeskOpen,
        theme: { spacing, screenSize, palette },
    }) => css`
        position: fixed;
        z-index: ${zIndex};
        bottom: ${addBottomSpacing ? '97px' : spacing[4]};
        right: 0;
        margin: 0;
        border-radius: 25px;
        background-color: ${palette.white};
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.12), -4px -4px 20px rgb(255 255 255 / 50%);
        display: ${isZendeskOpen ? 'none' : 'flex'};
        flex-direction: column;

        /* 375 */
        @media screen and (min-width: ${screenSize.mediumHandset}px) {
            margin: 0 ${isCallOpen ? spacing[2] : 0};
        }
        /* 800 */
        @media screen and (min-width: ${screenSize.largeTablet}px) {
            bottom: ${spacing[4]};
        }
        /* 960 */
        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            margin: 0 ${spacing[2]};
            display: flex;
        }
        /* 1280 */
        @media screen and (min-width: ${screenSize.largeDesktop}px) {
            margin: 0 ${spacing[3]};
        }
    `
);
