import styled, { css, keyframes } from 'styled-components';
import { ActionTypes } from './RangeSlider.constants';

const jump = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
`;

const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
`;

const clickedCss = css`
    animation: ${jump} 350ms ease-in-out;
`;

const movingCss = css`
    opacity: ${({ theme }) => theme.activeOpacity};
`;

const ThumbStyled = styled.div`
    position: absolute;
    top: 0;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: transform 100ms ease-in-out, background-color 100ms ease-in-out;

    ${({ action }) => {
        switch (action) {
            case ActionTypes.Moving:
            case ActionTypes.Selected:
                return movingCss;
            default:
                return null;
        }
    }};

    &:after {
        left: 10px;
        top: 10px;
        width: 24px;
        height: 24px;
        background-color: ${({ theme }) => theme.palette.primaryRegular};
        border-radius: 100%;
        content: ' ';

        ${({ action }) => {
            switch (action) {
                case ActionTypes.Click:
                    return clickedCss;
                default:
                    return null;
            }
        }};
    }
`;

const TrackWrapperStyled = styled.div`
    height: 44px;
    width: 100%;

    &:before {
        position: absolute;
        content: ' ';
        width: calc(100% - 44px);
        left: 22px;
        top: 21px;
        height: 2px;
        background-color: ${({ theme }) => theme.palette.border};
    }
`;

const TrackStyled = styled.div`
    position: absolute;
    height: 4px;
    top: ${({ theme }) => theme.spacing[2.5]};
    background-color: ${({ theme }) => theme.palette.primaryRegular};
`;

export { WrapperStyled, ThumbStyled, TrackWrapperStyled, TrackStyled };
