// This is a universal implementation of AsyncLocalStorage. It's a Singleton that can be accessed in a request safe way

let asyncLocalStorage = null;

class BrowserAsyncLocalStorage {
    constructor() {
        this.store = new Map();
    }

    getStore() {
        return this.store;
    }
}

const getAsyncLocalStorage = (AsyncLocalStorageFactory = BrowserAsyncLocalStorage) => {
    if (!asyncLocalStorage) {
        asyncLocalStorage = new AsyncLocalStorageFactory();
    }
    return asyncLocalStorage;
};

export { getAsyncLocalStorage, getAsyncLocalStorage as default };
