import * as actions from './actions';

const initialState = {
    updateId: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.saveSuccess.ACTION:
            return {
                ...state,
                updateId: state.updateId + 1,
                response: 'SUCCESS',
            };
        case actions.saveFailure.ACTION:
            return {
                ...state,
                updateId: state.updateId + 1,
                response: 'FAILURE',
            };
        default:
            return state;
    }
};
