// Figure out what pages to display and whether to show the arrows at each side
const getPageMetadata = (currentPage, totalItems, itemsPerPage, pagesToDisplay) => {
    const totalPages = Math.ceil((totalItems || 1) / itemsPerPage);
    const actualPagesToDisplay = Math.min(pagesToDisplay, totalPages);
    const toTheLeft = (actualPagesToDisplay - 1) / 2;
    const toTheRight = actualPagesToDisplay - 1 - toTheLeft;
    const activeIndex = currentPage - 1;
    const lastIndex = totalPages - 1;
    const potentialStartIndex = activeIndex - toTheLeft;
    const potentialEndIndex = activeIndex + toTheRight;

    const missingFromStart = potentialStartIndex < 0 ? Math.abs(potentialStartIndex) : 0;
    const missingFromEnd = potentialEndIndex > lastIndex ? potentialEndIndex - lastIndex : 0;

    // Assume we can show the whole range as-is (meaning we are not near the start or end of the range)
    // E.g. < 1 2 (3) 4 5 >
    let startIndex = potentialStartIndex;
    let endIndex = potentialEndIndex;

    if (missingFromStart > missingFromEnd) {
        // We don't have enough items before the active one so use what we can and then use as much as possible on
        // the right hand side
        // E.g. < 1 (2) 3 4 5 > or (1) 2 3 4 5 > or < 1 (2)
        startIndex = 0;
        endIndex = lastIndex < actualPagesToDisplay ? lastIndex : actualPagesToDisplay - 1;
    } else if (missingFromStart < missingFromEnd) {
        // We don't have enough items after the active one so use what we can and then use as much as possible on
        // the left hand side
        // E.g. < 1 2 3 (4) 5 > or < 1 2 3 4 (5)
        endIndex = lastIndex;
        startIndex = endIndex - actualPagesToDisplay > 0 ? totalPages - actualPagesToDisplay : 0;
    }

    return {
        displayedPages: Array.from({ length: totalPages }, (_, i) => i + 1).slice(
            startIndex,
            endIndex + 1
        ),
        showPrevious: currentPage !== 1,
        showNext: currentPage !== totalPages,
    };
};

export default {
    getPageMetadata,
};
