import React from 'react';
import PropTypes from 'prop-types';
import { linkResolver } from 'components/content/contentHelpers';
import { ContainerStyled, PanelStyled } from 'components/content/prismicSlices/common.style';
import usePrismicStyles from 'hooks/usePrismicStyles';
import {
    WrapperStyled,
    AnchorStyled,
    ButtonWrapperStyled,
    RichTextStyled,
    TitleStyled,
} from './MultiCallToAction.style';
import { prismicVariantToButtonVariant } from './MultiCallToAction.constants';

const MultiCallAction = ({
    ctaTitle,
    ctaBody,
    ctaItems,
    alignment = 'left',
    richTextProps,
    backgroundColor,
}) => {
    const classes = usePrismicStyles({
        backgroundColor,
    });

    return (
        <PanelStyled className={`${classes} m-0 py-16`}>
            <ContainerStyled mediumTablet={10} mediumDesktop={8}>
                <WrapperStyled alignment={alignment}>
                    {ctaTitle && <TitleStyled>{ctaTitle}</TitleStyled>}
                    {ctaBody && ctaBody.length > 0 && ctaBody[0].text && (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <RichTextStyled
                            richText={ctaBody}
                            linkResolver={linkResolver}
                            {...richTextProps}
                        />
                    )}
                    <ButtonWrapperStyled>
                        {ctaItems.map((item) => (
                            <AnchorStyled
                                target={item.ctaUrl && item.ctaUrl.target}
                                href={item.ctaUrl && item.ctaUrl.url}
                                variant={prismicVariantToButtonVariant[item.ctaStyle]}
                            >
                                {item.ctaText}
                            </AnchorStyled>
                        ))}
                    </ButtonWrapperStyled>
                </WrapperStyled>
            </ContainerStyled>
        </PanelStyled>
    );
};

MultiCallAction.defaultProps = {
    alignment: 'left',
    richTextProps: {},
    backgroundColor: 'white',
};

MultiCallAction.propTypes = {
    ctaTitle: PropTypes.string.isRequired,
    ctaBody: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
        })
    ).isRequired,
    ctaItems: PropTypes.arrayOf(
        PropTypes.shape({
            ctaText: PropTypes.string,
            ctaUrl: PropTypes.shape({
                url: PropTypes.string,
                target: PropTypes.string,
            }),
            ctaStyle: PropTypes.string,
        })
    ).isRequired,
    alignment: PropTypes.string,
    richTextProps: PropTypes.shape({}),
    backgroundColor: PropTypes.string,
};

export default MultiCallAction;
