import { settings as actions } from '../../actions';

export const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadListingAddress.SUCCESS:
        case actions.owner.createListingAddress.SUCCESS:
        case actions.owner.updateListingAddress.SUCCESS: {
            return action.data;
        }
        default:
            return state;
    }
};
