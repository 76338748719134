import styled, { css } from 'styled-components';

const checkedCss = css`
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.primaryRegular};
    background-color: ${({ theme }) => theme.palette.primaryRegular};
    && svg {
        display: inline;
    }

    &[disabled] {
        background-color: ${({ theme }) => theme.palette.grey[400]};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.grey[400]};
        opacity: 0.67;
    }
`;

const uncheckedCss = css`
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.grey[400]};
    background-color: transparent;

    && svg {
        display: none;
    }
`;

const erroredCss = css`
    box-shadow: ${({ theme }) => `0 0 0 2px ${theme.palette.danger}`};
`;

export const IconWrapper = styled.span`
    transition: box-shadow 0.2s ease-out, background-color 0.2s ease-out;
    border-radius: 4px;

    ${({ theme }) => `
        width: ${theme.spacing[3]};
        height: ${theme.spacing[3]};
    `};

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    margin-right: ${({ theme }) => theme.spacing[2]};

    ${({ isChecked }) => (isChecked ? checkedCss : uncheckedCss)};
    ${({ isErrored }) => (isErrored ? erroredCss : null)};

    && svg {
        fill: white;
    }
`;

export const CheckboxInput = styled.input`
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;

    &:focus + span {
        box-shadow: 0 0 0 2px #32b0ca;
    }
`;

export const LabelStyled = styled.label`
    display: flex;
    align-items: center;

    cursor: pointer;
`;
