/* eslint-disable import/prefer-default-export,quote-props */

/**
 * United states name to abbreviation map
 */
const UNITED_STATES = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virgina': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
    'American Samoa': 'AS',
    'District of Columbia': 'DC',
    'Federated States of Micronesia': 'FM',
    Guam: 'GU',
    'Marshall Islands': 'MH',
    'Northern Mariana Islands': 'MP',
    Palau: 'PW',
    'Puerto Rico': 'PR',
    'Virgin Islands': 'VI',
};

const CANADA_STATES = {
    Alberta: 'AB',
    'British Columbia': 'BC',
    Manitoba: 'MB',
    'New Brunswick': 'NB',
    'Newfoundland and Labrador': 'NL',
    'Nova Scotia': 'NS',
    'Northwest Territories': 'NT',
    Nunavut: 'NU',
    Ontario: 'ON',
    'Prince Edward Island': 'PE',
    Quebec: 'QC',
    Saskatchewan: 'SK',
    Yukon: 'YT',
};

const AUSTRALIA_STATES = {
    'New South Wales': 'NSW',
    Queensland: 'QLD',
    'South Australia': 'SA',
    Tasmania: 'TAS',
    Victoria: 'VIC',
    'Western Australia': 'WA',

    'State of New South Wales': 'NSW',
    'State of Queensland': 'QLD',
    'State of South Australia': 'SA',
    'State of Tasmania': 'TAS',
    'State of Victoria': 'VIC',
    'State of Western Australia': 'WA',
};

// Countries that should be prefixed with 'the'
const THE_PREFIX_COUNTRIES = [
    'bahamas',
    'british virgin islands',
    'cayman islands',
    'central african republic',
    'comoros',
    'czech republic',
    'dominican republic',
    'falkland islands',
    'gambia',
    'isle of man',
    'ivory coast',
    'maldives',
    'marshall islands',
    'netherlands',
    'philippines',
    'solomon islands',
    'turks and caicos islands',
    'united arab emirates',
    'united kingdom',
    'united states',
];

export const getHasThePrefix = (locationName) =>
    locationName && THE_PREFIX_COUNTRIES.includes(locationName.toLowerCase());

export const stateAbbr = location => {
    if (!location || Object.keys(location).length === 0) return '';

    // Ignore state abbreviation if the location itself is a state.
    if (location.featureClass === 'A') return '';

    if (location.countryName === 'USA' || location.countryName === 'United States') {
        return UNITED_STATES[location.admin1Name] || location.admin1Name || '';
    }
    if (location.countryName === 'Canada') {
        return CANADA_STATES[location.admin1Name] || location.admin1Name || '';
    }
    if (location.countryName === 'Australia') {
        return AUSTRALIA_STATES[location.admin1Name] || location.admin1Name || '';
    }

    return location.admin1Name || '';
};

export const locationName = (location, withCountry = false, separator = ', ', withStateAbbr = true) => {
    if (!location || Object.keys(location).length === 0) return '';
    let name = location.name || '';
    const locationCountryName = location.countryName ? location.countryName.toLowerCase() : '';
    const countryName =
        locationCountryName === 'usa' || locationCountryName === 'united states'
            ? 'US'
            : location.countryName || '';

    if (
        countryName.toLowerCase() === 'us' ||
        countryName.toLowerCase() === 'usa' ||
        countryName.toLowerCase() === 'canada' ||
        countryName.toLowerCase() === 'australia'
    ) {
        if (withStateAbbr) {
            const state = stateAbbr(location);
            if (state) {
                name = `${name}${separator}${state}`;
            }
        } else if (location.featureClass !== 'A' && location.admin1Name) {
            name = `${name}${separator}${location.admin1Name}`;
        }

        // Location is a county, add State.
        if (withCountry && location.featureCode === 'ADM2') {
            name = `${name}${separator}${location.admin1Name}`;
        }

        // Location is a country, don't duplicate it.
        if (withCountry && location.featureCode !== 'PCLI') {
            name = `${name}, ${countryName}`;
        }
    } else if (withCountry && location.featureCode !== 'PCLI' && location.featureCode !== 'CONT') {
        // Location is a county, add State for Argentina.
        if (countryName.toLowerCase() === 'argentina' && location.featureCode === 'ADM2') {
            name = `${name}${separator}${location.admin1Name}`;
        }

        name = `${name}${separator}${countryName}`;
    }

    return name;
};

export const locationType = (location, detailed = false) => {
    if (!location || Object.keys(location).length === 0) return '';

    const locationCountryName = location.countryName ? location.countryName.toLowerCase() : '';
    const countryName =
        locationCountryName === 'usa' || locationCountryName === 'united states'
            ? 'US'
            : location.countryName || '';

    switch (location.featureClass) {
        case 'L': return 'continent'
        case 'P':
            return 'city';
        case 'A':
            if (
                location.featureCode === 'PCLH' ||
                location.featureCode === 'PCLI' ||
                location.featureCode === 'PCLIX' ||
                location.featureCode === 'PCLS'
            ) {
                return 'country';
            }

            if (countryName === 'US' || countryName === 'Canada' || countryName === 'Australia') {
                switch (location.featureCode) {
                    case 'ADM1':
                    case 'ADM1H':
                        return 'state';
                    case 'ADM2':
                    case 'ADM2H':
                        return detailed ? 'region' : 'region';
                    case 'ADM3':
                    case 'ADM3H':
                        return detailed ? 'county' : 'region';
                    default:
                        return 'region';
                }
            }
            if (detailed) {
                switch (location.featureCode) {
                    case 'ADM1':
                    case 'ADM1H':
                        return 'region';
                    case 'ADM2':
                    case 'ADM2H':
                        return 'county';
                    default:
                        return 'place';
                }
            } else {
                return 'region';
            }
        default:
            return 'place';
    }
};

export const stateName = (location, withCountry = false, separator = ', ') => {
    if (!location || Object.keys(location).length === 0) return '';

    let name = '';
    if (
        location.countryName === 'US' ||
        location.countryName === 'USA' ||
        location.countryName === 'Canada' ||
        location.countryName === 'Australia'
    ) {
        name = `${stateAbbr(location)}`;
        if (withCountry) {
            name = `${name}${separator}${location.countryName || ''}`;
        }
    } else if (withCountry) {
        name = location.countryName || '';
    }
    return name;
};
