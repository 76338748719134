import { routes } from 'utils/routes';

export const supportURL = 'https://support.trustedhousesitters.com';
export const cancelationInsuranceURL =
    'https://support.trustedhousesitters.com/hc/en-gb/articles/360015619898-What-is-Sit-Cancellation-Insurance';
export const contactUsURL =
    'https://support.trustedhousesitters.com/hc/en-gb/articles/360002145618-How-can-I-contact-TrustedHousesitters-for-help-';
export const trustAndSafetyURL =
    'https://support.trustedhousesitters.com/hc/en-gb/categories/360000248597-Trust-and-safety';

// Currently if one of these links objects doesn't have a 'to' set then it needs to have a valid hrefUrl

export const standardOwnerLinks = [
    {
        textSlug: 'cancel_free_trial_modal_feature_home',
        to: routes.page.homeContentsProtection(),
        trackLinkClickSlug: 'Home, Contents & Liability Protection',
    },
    {
        textSlug: 'cancel_free_trial_modal_feature_vet_advice',
        to: routes.blog.post({
            area: 'blog',
            category: 'news',
            slug: 'trusted-tools-24-7-vet-advice-line',
        }),
        trackLinkClickSlug: '24/7 vet advice',
    },
    {
        textSlug: 'cancel_free_trial_modal_feature_member_support',
        to: null,
        trackLinkClickSlug: 'Dedicated member support',
        hrefUrl: contactUsURL,
    },
];

export const premiumOwnerLinks = [
    {
        textSlug: 'cancel_free_trial_modal_feature_insurance',
        to: null,
        trackLinkClickSlug: 'Sit Cancellation Insurance',
        hrefUrl: cancelationInsuranceURL,
    },
    ...standardOwnerLinks,
];

export const sitterLinks = [
    {
        textSlug: 'cancel_free_trial_modal_feature_trust_and_safety',
        to: null,
        trackLinkClickSlug: 'Trust and safety',
        hrefUrl: trustAndSafetyURL,
    },
    {
        textSlug: 'cancel_free_trial_modal_feature_member_support',
        to: null,
        trackLinkClickSlug: 'Dedicated member support',
        hrefUrl: contactUsURL,
    },
];
