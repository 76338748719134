import React from 'react';
import { theme } from 'theme';
import { CoverStyled, ImageStyled } from './CoverImage.style';

const CoverImage = ({ mainImageUrl, mobileImageUrl, alt, className }) => (
    <CoverStyled className={className}>
        <picture>
            <source
                srcSet={mainImageUrl}
                media={`(min-width: ${theme.screenSize.largeHandset}px)`}
            />
            <ImageStyled alt={alt} src={mobileImageUrl || mainImageUrl} />
        </picture>
    </CoverStyled>
);

CoverImage.defaultProps = {
    mobileImageUrl: '',
    alt: '',
};

export default CoverImage;
