import { uniqueArray } from '../helpers/format/objects';

export const basePaginationInitialState = {
    hasMore: null,
    count: 0,
};

// State with only data
export const baseInitialState = {
    data: {},
};

// State with only results (usually list of id's)
export const baseInitialResultsListState = {
    results: [],
};

// State with data and results
export const baseInitialResultsState = {
    ...baseInitialState,
    ...baseInitialResultsListState,
};

/**
 * // Paginated state with data and results
 * @type {{data: {}, hasMore: null, count: number, results: *[]}}
 */
export const basePaginatedInitialState = {
    ...basePaginationInitialState,
    ...baseInitialResultsState,
};

/**
 * // Paginated state with results list
 * @type {{hasMore: null, count: number, results: *[]}}
 */
export const basePaginatedInitialResultListState = {
    ...basePaginationInitialState,
    ...baseInitialResultsListState,
};

export const dataReducerHelper = (state, action, key = 'id') => ({
    ...state.data,
    [action.data[key]]: action.data,
});

export const paginationHelper = (state, action) => ({
    hasMore: action.data.hasMore,
    count: action.data.count,
});

export const loadedPaginatedResultsReducer = (state, action, key = 'id') => {
    // No need to attach previous results
    if (!action.reset) return state;

    return {
        ...state,
        ...paginationHelper(state, action),
        results: [...state.results, ...action.data.results.map((item) => item[key])],
        data: {
            ...state.data,
            ...action.data.results.reduce((items, item) => {
                items[item[key]] = item;
                return items;
            }, {}),
        },
    };
};

export const loadedResultsReducer = (state, action, key = 'id') => ({
    ...state,
    results: [...state.results, ...action.data.results.map((item) => item[key])],
    data: {
        ...state.data,
        ...action.data.results.reduce((items, item) => {
            items[item[key]] = item;
            return items;
        }, {}),
    },
});

export const loadedResultsReducerSimple = (state, action) => ({
    results: [...action.data.results],
});

export const loadedPaginatedResultsItemReducer = (state, action, key = 'id') => ({
    ...state,
    ...paginationHelper(state, action),
    results: [
        ...state.results,
        ...(state.results.indexOf(action.data[key]) < 0 ? [action.data[key]] : []),
    ],
    data: dataReducerHelper(state, action),
});

export const loadedResultsItemReducer = (state, action, key = 'id') => ({
    ...state,
    results: [
        ...state.results,
        ...(state.results.indexOf(action.data[key]) < 0 ? [action.data[key]] : []),
    ],
    data: dataReducerHelper(state, action),
});

/**
 * Merges action results with the currently held state, or replaces what's currently
 * held with the new results.
 * @param state The currently held state
 * @param action The details of the action that has been completed
 * @param key The property of each result item to be used as a key for the array returned
 * @returns {{results: *}} The newly created object representing state
 */
export const loadedResultsUniqueListReducer = (state, action, key = 'id') => {
    const { requestData = {} } = action;
    const { startingAfter } = requestData;

    // If we're paginating (startingAfter), merge the action results with our current state.
    // Otherwise, leave out our current state and just return the action results.
    return {
        results: uniqueArray([
            ...(startingAfter ? state.results : []),
            ...action.data.results.map((item) => item[key]),
        ]),
    };
};

/**
 * Merges action results with the currently held state, or replaces what's currently
 * held with the new results, and includes pagination data in returned object.
 * @param state The currently held state
 * @param action The details of the action that has been completed
 * @param key The property of each result item to be used as a key for the array returned
 */
export const loadedPaginatedResultsUniqueListReducer = (state, action, key = 'id') => ({
    ...loadedResultsUniqueListReducer(state, action, key),
    ...paginationHelper(state, action),
});

export const loadedDataReducer = (state, action, key = 'id') => ({
    ...state,
    data: {
        ...state.data,
        ...action.data.results.reduce((items, item) => {
            items[item[key]] = item;
            return items;
        }, {}),
    },
});

export const loadedReducer = (state, action) => ({
    ...state,
    data: {
        ...action.data,
    },
});

export const loadedDataItemReducer = (state, action, key = 'id') => ({
    ...state,
    data: dataReducerHelper(state, action, key),
});

export const createdResultReducer = (state, action, key = 'id') => ({
    ...state,
    results: [...state.results, action.data[key]],
    data: dataReducerHelper(state, action),
});

export const createdResultListReducer = (state, action, key = 'id') => ({
    ...state,
    results: [...state.results, action.data[key]],
});

export const createdDataReducer = (state, action, key = 'id') => ({
    ...state,
    data: dataReducerHelper(state, action, key),
});

export const createdReducer = (state, action) => ({
    ...state,
    ...action.data,
});

export const updatedResultReducer = (state, action, key = 'id') => ({
    ...state,
    results: state.results,
    data: dataReducerHelper(state, action, key),
});

export const updatedDataReducer = (state, action, key = 'id') => ({
    ...state,
    data: dataReducerHelper(state, action, key),
});

export const updatedReducer = (state, action) => ({
    ...state,
    ...action.data,
});

export const deletedResultReducer = (state, action, key = 'id') => {
    const results = state.results.filter((id) => id !== action[key]);
    const { data } = state;
    delete data[action[key]];

    return {
        ...state,
        results,
        data,
    };
};

export const deletedResultListReducer = (state, action, key = 'id') => ({
    ...state,
    results: state.results.filter((id) => id !== action.requestData[key]),
});

export const deletedDataReducer = (state, action, key = 'id') => {
    const newState = {
        ...state,
        data: {
            ...state.data,
        },
    };

    delete newState.data[action.requestData[key]];

    return newState;
};

/* Some helpers... */
// Loops through object to get given deep key
const getDeepItem = (obj, keys) => {
    let newObj = obj;

    for (let i = 0; i < keys.length; i += 1) {
        newObj = newObj[keys[i]];
    }

    return newObj;
};

const deleteDeepValue = (obj, path, item) => {
    const keys = path.split('.');
    const lastKey = keys[keys.length - 1];

    // Get parent
    let newObj = getDeepItem(obj, keys.splice(0, keys.length - 1));

    if (newObj[lastKey] instanceof Array) {
        newObj = newObj[lastKey];
        const index = newObj.indexOf(item);
        newObj.splice(index, 1);
    } else if (newObj instanceof Object) {
        newObj[lastKey] = null;
    }
};

// @todo: should this be only for lists?
const addToObjectProperty = (obj, path, item) => {
    const keys = path.split('.');
    const lastKey = keys[keys.length - 1];
    // Get parent
    let newObj = getDeepItem(obj, keys.splice(0, keys.length - 1));

    if (newObj[lastKey] instanceof Array) {
        newObj = newObj[lastKey];
        newObj.push(item);
    }
    // @todo: review
    else {
        newObj[lastKey] = item;
    }
};

/**
 * Deletes `item` from `parentKey's` property `key`
 * @param state {Object} current state of application. Must contain a data property
 * @param key {String} property names seperated with . which signifies nested properties
 * e.g. 'apath.thatis.here', or 'aSinglePropertyName'
 * @param item {*}: value that is added
 * @param parentKey {String} the parent property name that is a direct child of state.data
 * e.g. state.data.nameOfParentKey
 * @returns Object: new state
 */
export const deleteParentPropertyItem = (state, key, item, parentKey) => {
    const newState = { ...state };
    deleteDeepValue(newState.data[parentKey], key, item);
    return newState;
};

/**
 * Adds `item` to `parentKey's` property `key`
 * @param state {Object} current state of application. Must contain a data property
 * @param key {String} property names seperated with . which signifies nested properties
 * e.g. 'apath.thatis.here', or 'aSinglePropertyName'
 * @param item {*}: value that is added
 * @param parentKey {String} the parent property name that is a direct child of state.data
 * e.g. state.data.nameOfParentKey
 * @returns Object: new state
 */
export const addParentPropertyItem = (state, key, item, parentKey) => {
    const newState = { ...state };
    addToObjectProperty(newState.data[parentKey], key, item);
    return newState;
};

/**
 * Moves `itemId` from `removeFromParentId`.`removeFromKey` to `moveToParentId`.`moveToKey
 * @param state
 * @param removeFromKey
 * @param moveToKey
 * @param itemId
 * @param removeFromParentKey
 * @param moveToParentKey
 * @returns Object: new state
 */
export const moveParentPropertyListItem = (
    state,
    removeFromKey,
    moveToKey,
    itemId,
    removeFromParentKey,
    moveToParentKey
) => {
    const newState = deleteParentPropertyItem(state, removeFromKey, itemId, removeFromParentKey);
    return addParentPropertyItem(newState, moveToKey, itemId, moveToParentKey);
};

export const setParentPropertyItem = (state, key, itemId, parentKey) => ({
    ...state,
    data: {
        ...state.data,
        [parentKey]: {
            ...state.data[parentKey],
            [key]: itemId,
        },
    },
});

export const moveParentPropertyItem = (
    state,
    removeFromKey,
    moveToKey,
    itemId,
    removeFromParentKey,
    moveToParentKey
) => {
    const newState = deleteParentPropertyItem(state, removeFromKey, itemId, removeFromParentKey);
    return setParentPropertyItem(newState, moveToKey, itemId, moveToParentKey);
};
