import React from 'react';
import { Trans } from 'react-i18next';
import {
    WrapperStyled,
    ContentStyled,
    QuoteStyled,
    FooterStyled,
} from './TrustPilotReviewQuote.style';

const TrustPilotReviewQuote = ({ className, review }) => {
    const name = Object.values(review.person).join(' ');

    return (
        <WrapperStyled className={className}>
            <ContentStyled>
                <QuoteStyled>{review.quote}</QuoteStyled>
                <FooterStyled>
                    <Trans
                        i18nKey="containers_refer_a_friend_inline_trustpilot_quote_footer"
                        values={{
                            name,
                            score: review.score,
                        }}
                        components={{
                            a: <a href={review.url} target="_blank" rel="noreferrer" />, // eslint-disable-line
                        }}
                    />
                </FooterStyled>
            </ContentStyled>
        </WrapperStyled>
    );
};

TrustPilotReviewQuote.defaultProps = {
    className: undefined,
};

export default TrustPilotReviewQuote;
