import { put, takeEvery, all, take, select } from 'redux-saga/effects';
import * as pageActions from 'containers/Page/actions';
import actions, { settings } from 'api/actions';
import { getLanguage } from 'shared/selectors';
import { PAGE_ID, SEARCH_TYPE } from './constants';

function* raiseError(statusCode) {
    yield put(pageActions.error.create(PAGE_ID, statusCode));
}

function putBlogArea({ lang, params }) {
    return put(
        actions.content.loadBlogArea({
            filters: {
                lang,
            },
            data: {
                areaSlug: params.area,
            },
        })
    );
}

function putBlogAreaPosts({ search, lang, params }) {
    return put(
        actions.content.loadBlogAreaPosts({
            id: 'posts',
            forceReload: true,
            filters: {
                lang,
                page: parseInt(search.page, 10) || 1,
                limit: 12,
            },
            data: {
                searchType: SEARCH_TYPE,
                areaSlug: params.area,
            },
        })
    );
}

function loadBlogTrendingPosts({ lang, params }) {
    return put(
        actions.content.loadBlogAreaTrendingPosts({
            id: 'trending-posts',
            forceReload: true,
            filters: {
                lang,
            },
            data: {
                searchType: SEARCH_TYPE,
                areaSlug: params.area,
            },
        })
    );
}

function putBlogAreaFeaturedPosts({ lang, params }) {
    return put(
        actions.content.loadBlogAreaPosts({
            id: 'featured_posts',
            forceReload: true,
            filters: {
                lang,
                page: 1,
                limit: 4,
                page_size: 4,
                featured_on_homepage: 'true',
            },
            data: {
                searchType: 'featured-blog-posts',
                areaSlug: params.area,
            },
        })
    );
}

export function* loadArea({ type, params, search }) {
    const lang = yield select(getLanguage);

    yield all([
        putBlogArea({ lang, params }),
        putBlogAreaPosts({ search, lang, params }),
        loadBlogTrendingPosts({ search, lang, params }),
        putBlogAreaFeaturedPosts({ lang, params }),
    ]);

    const { blogArea, blogAreaPosts, blogAreaFeaturedPosts } = yield all({
        blogArea: take(settings.content.loadBlogArea.DONE),
        // TODO: modify this to only take the specific loadBlogAreaPosts we're waiting on
        // i.e action => action.type === settings.content.loadBlogAreaPosts.DONE && action.id === 'posts'
        blogAreaPosts: take(
            (action) =>
                action.type === settings.content.loadBlogAreaPosts.DONE &&
                action.action.id === 'posts'
        ),
        blogAreaFeaturedPosts: take(
            (action) =>
                action.type === settings.content.loadBlogAreaPosts.DONE &&
                action.action.id === 'featured_posts'
        ),
    });

    if (blogArea.status !== settings.content.loadBlogArea.SUCCESS) {
        yield raiseError(blogArea.statusCode);
        return false;
    }
    if (blogAreaPosts.status !== settings.content.loadBlogAreaPosts.SUCCESS) {
        yield raiseError(blogAreaPosts.statusCode);
        return false;
    }
    if (blogAreaFeaturedPosts.status !== settings.content.loadBlogAreaPosts.SUCCESS) {
        yield raiseError(blogAreaFeaturedPosts.statusCode);
        return false;
    }

    // If successful then show as loaded
    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(PAGE_ID));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(PAGE_ID));
    }

    return true;
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            loadArea
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            loadArea
        ),
    ]);
}
