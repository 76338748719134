import { v2ApiUrl, v3ApiUrl, locisApiUrl } from './constants';

export default {
    places: {
        // GET: Places
        _: `${locisApiUrl}predictions`,

        // GET Countries
        countries: `${v2ApiUrl}places/countries/`,
    },
    // GET: Nearest places
    nearest: `${locisApiUrl}nearest`,

    sitemap: {
        // GET: Sitemap pages
        pages: `${v3ApiUrl}search/:type:/locations/`,

        // GET: Sitemap locations
        locations: `${v3ApiUrl}search/:type:/locations/:level:/`,
    },

    listings: {
        // GET ?page&filters?: Listings
        _: `${v3ApiUrl}search/listings/`,

        // GET: Listing details
        listing: `${v3ApiUrl}search/listings/:id:/`,

        // GET: Recommended listings
        recommended: `${v3ApiUrl}search/listings/recommended/`,

        // GET: Favourites listings
        favourites: `${v3ApiUrl}search/listings/favourites/`,

        saved: {
            // GET: Saved searches
            _: `${v3ApiUrl}search/listings/saved/`,

            // GET Countries
            item: `${v3ApiUrl}search/listings/saved/:id:/`,
        },

        share: {
            // POST
            email: `${v3ApiUrl}search/listings/:id:/share/email/`,
        },
    },

    profiles: {
        // GET ?page&filters: Profiles
        _: `${v3ApiUrl}search/profiles/`,

        // GET: Profile details
        profile: `${v3ApiUrl}search/profiles/:id:/`,

        // GET: Recommended listings
        recommended: `${v3ApiUrl}search/profiles/recommended/`,

        // GET: Favourites listings
        favourites: `${v3ApiUrl}search/profiles/favourites/`,

        // GET: profiles that have favourited the user's listing
        favouritedYou: `${v3ApiUrl}search/profiles/favourited-you/`,

        saved: {
            // GET: Saved searches
            _: `${v3ApiUrl}search/profiles/saved/`,

            // GET Countries
            item: `${v3ApiUrl}search/profiles/saved/:id:/`,
        },
    },
};
