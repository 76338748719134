// eslint-disable-next-line import/prefer-default-export
export const SEARCH_DETAILS = {
    create({ category, id, suggestedCategory, totalReviews = null, productTrigger = null }) {
        let payload = {
            product_id: id,
            category,
            totalReviews,
            productTrigger,
        };
        // part of the SIT_DISCOVERY_PAGE test
        // property to see from which category the listing was clicked on by a user
        // e.g. chose a listing in the 5 star sits carousel
        // it is used for easier tracking since there is no guarantee that a listing will only appear in one category
        if (suggestedCategory) {
            payload = {
                ...payload,
                suggestedCategory,
            };
        }

        return {
            type: 'Product Viewed',
            payload,
        };
    },
};
