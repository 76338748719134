/* eslint-disable camelcase */
/* eslint-disable consistent-return */
// eslint-disable-next-line import/prefer-default-export
export const NEW_USER_ATTRIBUTION = {
    create(expected_membership_type) {
        return {
            type: 'New User Attribution',
            payload: {
                expected_membership_type,
            },
        };
    },
    // If value not in session storage then set it and return true otherwise leave it and return false
    checkTrackingExists: () => {
        const key = 'isNewUser';

        if (!global.sessionStorage.getItem(key)) {
            global.sessionStorage.setItem(key, JSON.stringify(true));
            return true;
        }
        return false;
    },

    MembershipType: {
        OWNER: 'Owner',
        SITTER: 'Sitter',
    },
};
