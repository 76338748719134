import { getParams } from 'containers/Page/selectors';
import { getPage as getContentPage } from 'api/selectors/content';

import { PAGE_ID, SEO_FACET_LINKS } from './constants';

export const getPage = (state) => {
    const params = getParams(state, PAGE_ID);
    return getContentPage(state, params.slug);
};

export const hasSeoFacetLinks = (state) =>
    !!(state.search.profiles[SEO_FACET_LINKS] && state.search.profiles[SEO_FACET_LINKS].facets);

export const hasSeoListingsFacetLinks = (state) =>
    !!(state.search.listings[SEO_FACET_LINKS] && state.search.listings[SEO_FACET_LINKS].facets);
