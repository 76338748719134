import React, { ReactNode, ReactElement } from 'react';
import { ButtonVariant } from '../Button/Button.constants';
import { StyledButton, StyledIcon, Text } from './ButtonIcon.style';

const ButtonIcon = ({ icon, iconProps, children, iconRight, ...props }) => {
    const IconElement = <StyledIcon as={icon} {...iconProps} />;

    return (
        <StyledButton iconRight={iconRight} {...props}>
            {iconRight || IconElement}
            {children && <Text iconRight={iconRight}>{children}</Text>}
            {iconRight && IconElement}
        </StyledButton>
    );
};

ButtonIcon.Variant = ButtonVariant;

export default ButtonIcon;
