import * as React from 'react';
import { LabelTitleStyled } from './Content.style';

function Content({ className, children }) {
    return <LabelTitleStyled className={className}>{children}</LabelTitleStyled>;
}

Content.defaultProps = {
    className: '',
};

export default Content;
