import { channel as sagaChannel } from 'redux-saga';
import { all, takeLatest, put, call, select, fork, take, join } from 'redux-saga/effects';
import { Facet as FacetSearchLevel } from 'api/helpers/search/constants';
import * as actions from './actions';
import * as constants from './constants';
import * as resources from './resources';
import { checkFacetsCache } from './selectors';

/**
 * @typedef FacetsAction
 * @prop {Object | null} place
 * @prop {Object} filters
 * @prop {string} searchLevel
 */

/**
 * @typedef FacetsHandlerPayload
 * @prop {string} searchType
 * @prop {string} searchLevel
 * @prop {Object} filters
 */

/**
 * Handle loading the global level for facets
 * @param {import('redux-saga').Channel} channel
 * @param {import('./resources').DynamicFacetResource} resource
 * @param {FacetsHandlerPayload} payload
 */
export function* handleFetchGlobalFacets(channel, resource) {
    if (!(yield select(checkFacetsCache, resource.type, FacetSearchLevel.GLOBAL))) {
        const requestData = yield call(
            resource.createSearchQuery,
            {},
            constants.NEEDED_FACETS_LEVELS[FacetSearchLevel.GLOBAL]
        );

        yield put(channel, {
            requestData,
            searchLevel: FacetSearchLevel.GLOBAL,
        });
    }
}

/**
 * Handle loading the countries level for facets
 * @param {import('redux-saga').Channel} channel
 * @param {import('./resources').DynamicFacetResource} resource
 * @param {FacetsHandlerPayload} payload
 */
export function* handleFetchCountryFacets(channel, resource, payload) {
    const requestData = yield call(
        resource.createSearchQuery,
        payload.filters,
        constants.NEEDED_FACETS_LEVELS[FacetSearchLevel.COUNTRY]
    );

    yield put(channel, {
        requestData,
        location: payload.location,
        searchLevel: FacetSearchLevel.COUNTRY,
    });
}

/**
 * Handle loading the regions level for facets
 * @param {import('redux-saga').Channel} channel
 * @param {import('./resources').DynamicFacetResource} resource
 * @param {FacetsHandlerPayload} payload
 */
export function* handleFetchRegionFacets(channel, resource, payload) {
    const requestData = yield call(
        resource.createSearchQuery,
        payload.filters,
        constants.NEEDED_FACETS_LEVELS[FacetSearchLevel.ADMIN1]
    );

    yield put(channel, {
        requestData,
        location: payload.location,
        searchLevel: FacetSearchLevel.ADMIN1,
    });
}

/**
 * Handle loading the counties level for facets
 * @param {import('redux-saga').Channel} channel
 * @param {import('./resources').DynamicFacetResource} resource
 * @param {FacetsHandlerPayload} payload
 */
export function* handleFetchCountyFacets(channel, resource, payload) {
    const requestData = yield call(
        resource.createSearchQuery,
        payload.filters,
        constants.NEEDED_FACETS_LEVELS[FacetSearchLevel.ADMIN2]
    );

    yield put(channel, {
        requestData,
        location: payload.location,
        searchLevel: FacetSearchLevel.ADMIN2,
    });

    if (
        !(yield call(resource.checkCache, {
            location: payload.location,
            searchLevel: FacetSearchLevel.COUNTRY,
            compare: [
                constants.FACET_LEVEL_NAME.COUNTRY,
                constants.FACET_LEVEL_NAME.REGION,
                constants.FACET_LEVEL_NAME.COUNTY,
            ],
        }))
    ) {
        yield call(handleFetchCountryFacets, channel, resource, payload);
    }
}

/**
 * Handle loading the cities level for facets
 * @param {import('redux-saga').Channel} channel
 * @param {import('./resources').DynamicFacetResource} resource
 * @param {FacetsHandlerPayload} payload
 */
export function* handleFetchCityFacets(channel, resource, payload) {
    const requestData = yield call(
        resource.createSearchQuery,
        payload.filters,
        constants.NEEDED_FACETS_LEVELS[FacetSearchLevel.PLACE]
    );

    yield put(channel, {
        requestData,
        location: payload.location,
        searchLevel: FacetSearchLevel.PLACE,
    });

    if (
        !(yield call(resource.checkCache, {
            location: payload.location,
            searchLevel: FacetSearchLevel.ADMIN2,
            compare: [
                constants.FACET_LEVEL_NAME.COUNTRY,
                constants.FACET_LEVEL_NAME.COUNTY,
                constants.FACET_LEVEL_NAME.PLACE,
            ],
        }))
    ) {
        yield call(handleFetchCountyFacets, channel, resource, payload);
    }
}

export const FACETS_HANDLERS = {
    [FacetSearchLevel.COUNTRY]: handleFetchCountryFacets,
    [FacetSearchLevel.ADMIN1]: handleFetchRegionFacets,
    [FacetSearchLevel.ADMIN2]: handleFetchCountyFacets,
    [FacetSearchLevel.PLACE]: handleFetchCityFacets,
};

/**
 * @param {FacetsAction} action
 */
const createFacetsPayload = (payload) => {
    const facetsPayload = {
        ...payload,
    };

    if (typeof facetsPayload.searchLevel !== 'string') {
        facetsPayload.searchLevel = undefined;
    }

    // If `geoHierarchy` was not provided from `filters`, we'll going to use
    // the values from `place` object instead if we're not in global search
    if (typeof facetsPayload.searchLevel !== 'undefined') {
        const countryGeoParam = constants.GEO_PARAM_MAP[constants.FACET_LEVEL_NAME.COUNTRY];
        const regionGeoParam = constants.GEO_PARAM_MAP[constants.FACET_LEVEL_NAME.REGION];
        const countyGeoParam = constants.GEO_PARAM_MAP[constants.FACET_LEVEL_NAME.COUNTY];

        facetsPayload.location = {
            [countryGeoParam]: facetsPayload.place[countryGeoParam] ?? undefined,
            [regionGeoParam]: facetsPayload.place[regionGeoParam] ?? undefined,
            [countyGeoParam]: facetsPayload.place[countyGeoParam] ?? undefined,
        };

        facetsPayload.filters.geoHierarchy = facetsPayload.location;
    }

    // We never want to pass `slug` to load facets, as this will only load
    // the facets for a single place which will return just one facet
    if (facetsPayload.location && typeof facetsPayload.location.slug === 'string') {
        const { slug, ...necessaryGeoParams } = facetsPayload.location;
        facetsPayload.location = necessaryGeoParams;
    }

    return facetsPayload;
};

/**
 * redux-saga has a design flaw which doesn't allow us to yield multiple takes
 * after a put are dispatched to the store. We need to schedule all the necessary
 * calls before yield for the result. This is only necessary because we're doing
 * async (select) operations alongside with sync operations (take).
 * As our facets have dependencies between themselves, we need to do this to get
 * the correct facets for a given location. If we don't do this scheduler for
 * the requests, the saga will hold forever because redux-saga doesn't know
 * what to do when we're trying to mix sync with async operations in the same
 * generator (and producing others generators from this operation).
 * @sse https://github.com/redux-saga/redux-saga/issues/1699
 * @param {import('redux-saga').Channel} channel
 * @param {import('./resources').DynamicFacetResource} resource
 */
export function* fetchFacetsScheduler(channel, resource) {
    const requests = [];

    while (true) {
        const action = yield take(channel);

        if (action === 'END') {
            break;
        }

        requests.push(action);
    }

    yield all(
        requests.map(({ requestData, location, searchLevel }) =>
            call(resource.performRequest, requestData, location, searchLevel)
        )
    );

    yield channel.close();
}

/**
 * Factory to create the handler for a given facet search type. Should be used
 * inside the main saga.
 * @param {DynamicFacetResource} resource
 * @returns {Generator}
 */
export const handleFetchFacetsFactory = (resource) =>
    /**
     * @generator
     * @param {FacetsAction} action
     */
    function* handleFetchFacets(action) {
        const channel = yield call(sagaChannel);
        const payload = createFacetsPayload(action.payload);

        yield put(actions.fetchFacetsRequest.create({ searchLevel: payload.searchLevel }));

        // Create our worker that will handle scheduling all necessary requests
        const worker = yield fork(fetchFacetsScheduler, channel, resource);

        // We always want to have the global facets loaded
        yield call(handleFetchGlobalFacets, channel, resource);

        const handler = FACETS_HANDLERS[payload.searchLevel];
        if (handler) {
            yield call(handler, channel, resource, payload);
        }

        // Wait for all the requests to finish
        yield put(channel, 'END');
        yield join(worker);

        yield put(
            actions.fetchFacetsSuccess.create({
                place: payload.place,
                searchLevel: payload.searchLevel,
                location: payload.location,
            })
        );
    };

export default function* sagas() {
    const handleFetchListingsFacets = yield call(handleFetchFacetsFactory, resources.listings);
    const handleFetchProfilesFacets = yield call(handleFetchFacetsFactory, resources.profiles);
    const handleFetchSEOFacets = yield call(handleFetchFacetsFactory, resources.seo);

    yield all([
        takeLatest(actions.fetchListingsFacets.ACTION, handleFetchListingsFacets),
        takeLatest(actions.fetchProfilesFacets.ACTION, handleFetchProfilesFacets),
        takeLatest(actions.fetchSEOFacets.ACTION, handleFetchSEOFacets),
    ]);
}
