import React from 'react';
import { IconCheck } from 'components/Icon';
import { LabelStyled, CheckboxInput, IconWrapper } from './Checkbox.style';

const Checkbox = ({
    onChange,
    onBlur,
    name,
    describedBy,
    disabled,
    isChecked,
    id,
    label,
    className,
    isErrored,
}) => (
    <LabelStyled htmlFor={id} className={className}>
        <CheckboxInput
            aria-describedby={describedBy}
            disabled={disabled}
            id={id}
            type="checkbox"
            checked={isChecked}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
        />
        <IconWrapper disabled={disabled} isChecked={isChecked} isErrored={isErrored}>
            <IconCheck isChecked={isChecked} size="large" />
        </IconWrapper>
        {label}
    </LabelStyled>
);

export default Checkbox;
