import { getState } from './common';
import { transformListingAmenities } from '../helpers/transform/owner/amenities';
import { orderBySentAtMostToLeastRecent } from '../helpers/sitters/profile';

const emptyList = [];

export const getSearchCountries = (state) => getState(state).search.countries;
export const getSearchPlaces = (state, searchType = 'default') => {
    if (!getState(state).search.places[searchType]) return emptyList;
    return getState(state).search.places[searchType];
};
export const getSearchSuggestionsOnEnter = (state) => {
    if (!getState(state).shared.searchSuggestionsOnEnter) return emptyList;
    return getState(state).shared.searchSuggestionsOnEnter;
};
export const getSearchListing = (state, listingId) => {
    if (!getState(state).search.listing[listingId]) return {};
    const listing = getState(state).search.listing[listingId];
    return {
        ...listing,
        amenities: transformListingAmenities(listing.amenities),
    };
};

export const getSearchListings = (state, searchType = 'default') => {
    if (!getState(state).search.listings[searchType]) return emptyList;

    const listingsIdsSet = new Set();

    return getState(state).search.listings[searchType].results.map((listingId) => {
        const listing = getState(state).search.listing[listingId];

        if (!listing) return listing;

        // Ensures that duplicated listings are populated normally,
        // but with a isDuplicated boolean prop
        if (listingsIdsSet.has(listingId)) {
            return {
                ...listing,
                isDuplicated: true,
            };
        }

        listingsIdsSet.add(listingId);

        return listing;
    });
};
export const getSearchListingsTotal = (state, searchType = 'default') => {
    if (!getState(state).search.listings[searchType]) return 0;
    return getState(state).search.listings[searchType].total;
};
export const getSearchListingsFacets = (state, searchType = 'default') => {
    if (!getState(state).search.listings[searchType]) return {};
    return getState(state).search.listings[searchType].facets;
};
export const getSearchListingsFavourites = (state) =>
    getState(state).search.favourites.listings.results.map(
        (listingId) => getState(state).search.listing[listingId]
    );
export const getSearchListingsFilters = (state, searchType = 'default') =>
    getState(state).app.search.listings[searchType] || {};
export const getSearchListingsFavouritesTotal = (state) =>
    getState(state).search.favourites.listings.total || 0;
export const getSearchListingPets = (state, listingId) => {
    const listing = getSearchListing(state, listingId);
    const { pets } = listing;
    return pets || [];
};

export const getSearchProfile = (state, profileId) =>
    getState(state).search.profile[profileId] || {};
export const getSearchProfiles = (state, searchType = 'default') => {
    if (!getState(state).search.profiles[searchType]) return emptyList;
    return getState(state).search.profiles[searchType].results.map(
        (profileId) => getState(state).search.profile[profileId]
    );
};
export const getSearchProfilesTotal = (state, searchType = 'default') => {
    if (!getState(state).search.profiles[searchType]) return 0;
    return getState(state).search.profiles[searchType].total;
};
export const getSearchProfilesFacets = (state, searchType = 'default') => {
    if (!getState(state).search.profiles[searchType]) return {};
    return getState(state).search.profiles[searchType].facets;
};

export const getSearchProfilesFavourites = (state) =>
    getState(state).search.favourites.profiles.results.map(
        (profileId) => getState(state).search.profile[profileId]
    );

export const getSearchProfilesFavouritedYou = (state) =>
    getState(state).search.favouritedYou.results;
export const getSearchProfilesFavouritedYouTotal = (state) =>
    getState(state).search.favouritedYou.total;

export const getSearchProfilesFilters = (state, searchType = 'default') =>
    getState(state).app.search.profiles[searchType] || {};
export const getSearchProfilesFavouritesTotal = (state) =>
    getState(state).search.favourites.profiles.total || 0;

export const getSearchProfileReviewsAndReferences = (state, profileId) => {
    const profile = getSearchProfile(state, profileId);
    if (!profile) return [];

    let sortedCompleteReferences = [];
    if (profile.references) {
        sortedCompleteReferences = profile.references.filter((item) => Boolean(item.reference));
        sortedCompleteReferences.sort(orderBySentAtMostToLeastRecent);
    }

    let sortedAssignmentsWithReviews = [];
    if (profile.pastAssignments) {
        sortedAssignmentsWithReviews = profile.pastAssignments.filter((assignment) =>
            Boolean(assignment.review)
        );
        sortedAssignmentsWithReviews.sort(
            (itemA, itemB) => new Date(itemB.startDate) - new Date(itemA.startDate)
        );
    }

    return [...sortedAssignmentsWithReviews, ...sortedCompleteReferences];
};

export const getSavedSearchFilters = (state) => getState(state).search.savedSearchFilters || [];

export const getSitemapPages = (state, type) => {
    const maybeSitemap = getState(state).search.sitemap;

    if (!maybeSitemap) {
        return false;
    }

    const sitemap = maybeSitemap[type];

    if (!sitemap?.pages) {
        return false;
    }

    return sitemap.pages;
};

export const getSitemapLocations = (state, type, level, page) => {
    const maybeSitemap = getState(state).search.sitemap;

    if (!maybeSitemap) {
        return false;
    }

    const sitemap = maybeSitemap[type];

    if (!sitemap || !sitemap[level] || !sitemap[level][page]) {
        return false;
    }

    return sitemap[level][page];
};
