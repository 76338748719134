import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${({ fallbackMedia }) => fallbackMedia};

    @supports (display: grid) {
        > * {
            width: auto !important;
        }

        /* CSS Grid specific */
        display: grid;
        grid-template-areas: '${({ areas }) => areas || 'none'}';

        ${({ layout }) => layout};
    }
`;

const Grid = ({ settings, className, children }) => {
    // Creates Grid specific media queries "top", "nav"
    const createFallbackMediaQuery = ({ areas, minWidth, columns, rows }) => {
        // Loop over columns and see how many 'auto' width ones there are. Will then need to convert to calc.
        let numberOfAutoColumns = 0;
        const calcExpression = [];
        let autoColumnWidth = '100%';

        if (columns.length > 1) {
            columns.forEach((width) => {
                if (width === 'auto' || width === '1fr') {
                    numberOfAutoColumns += 1;
                } else {
                    calcExpression.push(width);
                }
            });

            if (numberOfAutoColumns > 0) {
                const calcExpressionString = calcExpression.join(' + ');
                if (calcExpressionString) {
                    autoColumnWidth = `((100% - (${calcExpression.join(
                        ' + '
                    )})) / ${numberOfAutoColumns})`;
                } else {
                    autoColumnWidth = `(100% / ${numberOfAutoColumns})`;
                }
            }
        }

        const autoColumnCalc = numberOfAutoColumns ? `calc(${autoColumnWidth})` : autoColumnWidth;

        const itemWidths = {};
        const itemOrder = {};
        let currentItemOrder = 1;
        rows.forEach((rowColumns) => {
            // if number of items in the row matches the number of columns
            if (rowColumns.length === columns.length) {
                rowColumns.forEach((column, index) => {
                    itemWidths[column] =
                        columns[index] === 'auto' || columns[index] === '1fr'
                            ? autoColumnCalc
                            : columns[index];
                    itemOrder[column] = currentItemOrder;
                    currentItemOrder += 1;
                });
            } else if (rowColumns.length < columns.length) {
                // less items than columns
                const unprocessedRowColumns = Array.from(rowColumns);
                let unprocessedColumns = Array.from(columns);

                while (unprocessedRowColumns.length > 1) {
                    const rowColumn = unprocessedRowColumns.shift();
                    const unprocessedColumn = unprocessedColumns.shift();
                    itemWidths[rowColumn] = unprocessedColumn;
                    itemOrder[rowColumn] = currentItemOrder;
                    currentItemOrder += 1;
                }

                // Now need to combine remaining columns. First convert auto to calculation
                unprocessedColumns = unprocessedColumns.map((column) => {
                    if (column === 'auto' || column === '1fr') {
                        return autoColumnWidth;
                    }
                    return column;
                });

                itemWidths[unprocessedRowColumns[0]] = `calc(${unprocessedColumns.join(' + ')})`;
                itemOrder[unprocessedRowColumns[0]] = currentItemOrder;
                currentItemOrder += 1;
            }
        });

        // Now create nth child targeting to apply width and flex-order
        const columnsCSS = [];
        areas.forEach((column, index) => {
            if (column in itemOrder) {
                columnsCSS.push(`
                    > :nth-child(${index + 1}) {
                        order: ${itemOrder[column]};
                        width: ${itemWidths[column]};
                        display: block;
                    }
                `);
            } else {
                columnsCSS.push(`
                    > :nth-child(${index + 1}) {
                        display: none;
                    }
                `);
            }
        });

        const media = `
            @media only screen and (min-width: ${minWidth}) {
                ${columnsCSS.join(' ')};
            }
        `;

        return media;
    };

    // Creates Grid specific media queries
    const createMediaQuery = ({ minWidth, columns, rows, gap, rowGap }) => {
        const numberOfColumns = columns.length;
        const rowCSS = [];
        rows.forEach((row) => {
            const columnCSS = [];
            row.forEach((column) => {
                columnCSS.push(column);
            });

            const definedColumnCount = columnCSS.length;
            if (definedColumnCount < numberOfColumns) {
                for (
                    let columnIndex = 0;
                    columnIndex < numberOfColumns - definedColumnCount;
                    columnIndex += 1
                ) {
                    columnCSS.push(row[row.length - 1]);
                }
            }

            rowCSS.push(columnCSS.join(' '));
        });

        const media = `
            @media only screen and (min-width: ${minWidth}) {
                grid-template-columns: ${columns.join(' ')};
                grid-template-areas: "${rowCSS.join('" "')}";
                grid-gap: ${gap || '0'};
                row-gap: ${rowGap || '0'};
            }
        `;
        return media;
    };

    const areas = [];
    React.Children.forEach(children, (child) => {
        if (child) areas.push(child.props.name);
    });

    const layout = settings.map((breakpointSetting) => createMediaQuery({ ...breakpointSetting }));
    const fallbackMedia = settings.map((breakpointSetting) =>
        createFallbackMediaQuery({ areas, ...breakpointSetting })
    );

    return (
        <Container
            areas={areas.join(' ')}
            layout={layout.join(' ')}
            fallbackMedia={fallbackMedia.join(' ')}
            className={className}
        >
            {children}
        </Container>
    );
};

export default Grid;
