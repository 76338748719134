import loadable from '@loadable/component';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    getOwnerListingPetsDetails,
    getOwnerListingOpenAssignmentsAscending,
    getOwnerAddress,
    getOwnerListingAmenitiesStatus,
} from 'api/selectors/owner';
import { getAccountSettings } from 'api/selectors/account';
import apiActions from 'api/actions';
import { retry } from 'utils/ssr';
import asPage from 'containers/Page';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { getEmergencyContact, getSafetyContact } from 'containers/SafetySecurityPage/selectors';
import { getRawListing, getListingConfirmedAssignments } from '../selectors';
import { getResponseStatus, getUpdateId } from './selectors';
import { PAGE_ID } from './Hub.constants';

const LoadableHubListing = loadable(() => retry(() => import('./Hub')));

const mapStateToProps = (state, props) => {
    const {
        match: {
            params: { listingId },
        },
    } = props;

    return {
        firstName: getAccountSettings(state) ? getAccountSettings(state).firstName : '',
        listing: getRawListing(state),
        amenitiesStatus: getOwnerListingAmenitiesStatus(state, listingId),
        address: getOwnerAddress(state),
        openAssignments: getOwnerListingOpenAssignmentsAscending(state, listingId),
        confirmedAssignments: getListingConfirmedAssignments(state, listingId),
        pets: getOwnerListingPetsDetails(state, listingId),
        emergencyContact: getEmergencyContact(state),
        safetyContact: getSafetyContact(state),
        responseStatus: getResponseStatus(state),
        updateId: getUpdateId(state),
        isViewingInAppWebView: isViewingInAppWebView(state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const {
        match: {
            params: { listingId },
        },
    } = props;

    return {
        sendListingForReview: () => {
            dispatch(
                apiActions.owner.sendListingForReview({
                    forceReload: true,
                    data: {
                        id: listingId,
                        listingId,
                    },
                })
            );
        },
    };
};

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableHubListing), PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
