import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';
import {
    baseInitialState,
    deleteParentPropertyItem,
    addParentPropertyItem,
    setParentPropertyItem,
} from '../common';

export const initialState = { ...baseInitialState };

const getListingPets = (listing) => {
    if (Array.isArray(listing.pets)) return listing.pets;
    try {
        return listing.pets.results;
    } catch (e) {
        return [];
    }
};

const listingHelper = (data) => {
    const listing = data || {};

    return {
        ...listing,
        pets: getListingPets(listing).map((pet) => pet.id),
        photos: (listing.photos || []).map((photo) => photo.id),
        reminders: (listing.reminders || []).map((reminder) => reminder.id),
        guide: (listing.guide && listing.guide.id) || null,
        openAssignments: (listing.openAssignments || []).map((assignment) => assignment.id),
    };
};

const createdUpdatedListingHelper = (state, action) => ({
    ...state,
    data: {
        ...state.data,
        [action.data.id]: {
            ...action.data,
            ...listingHelper(action.data),
        },
    },
});

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadListings.SUCCESS:
            return {
                ...state,
                data: {
                    ...action.data.reduce((listings, listing) => {
                        // listing.openAssignments is an array of ids that link to openAssignments.
                        // It is initially empty.
                        // When we load open assignments we update the listing with the ids.
                        // Hence we want this data to persist when reloading a listing.
                        const openAssignments =
                            !!state.data[listing.id] && state.data[listing.id].openAssignments;

                        listings[listing.id] = {
                            ...transform.getListing(listing),
                            ...(openAssignments ? { openAssignments } : {}),
                        };
                        return listings;
                    }, {}),
                },
            };
        case actions.owner.loadListing.SUCCESS: {
            const listing = action.data;

            // listing.openAssignments is an array of ids that link to openAssignments.
            // It is initially empty.
            // When we load open assignments we update the listing with the ids.
            // Hence we want this data to persist when reloading a listing.
            const openAssignments =
                !!state.data[listing.id] && state.data[listing.id].openAssignments;

            return {
                ...state,
                data: {
                    ...state.data,
                    [listing.id]: {
                        ...transform.getListing(listing),
                        ...(openAssignments ? { openAssignments } : {}),
                    },
                },
            };
        }
        case actions.owner.loadListingOpenAssignments.SUCCESS: {
            const { listingId } = action.requestData;

            return {
                ...state,
                data: {
                    ...state.data,
                    [listingId]: {
                        ...state.data[listingId],
                        openAssignments: action.data.results.map((assignment) => assignment.id),
                    },
                },
            };
        }
        case actions.owner.loadWelcomeGuide.SUCCESS: {
            const { listingId } = action.requestData;
            const listing = state.data[listingId] || {};

            return {
                ...state,
                data: {
                    ...state.data,
                    [listingId]: {
                        ...listing,
                        welcomeGuide: {
                            ...transform.getWelcomeGuide({
                                ...action.data,
                            }),
                        },
                    },
                },
            };
        }
        case actions.owner.loadWelcomeGuidePhotos.SUCCESS: {
            const { listingId } = action.requestData;
            const listing = state.data[listingId] || {};

            return {
                ...state,
                data: {
                    ...state.data,
                    [listingId]: {
                        ...listing,
                        welcomeGuide: {
                            ...listing.welcomeGuide,
                            photos: [...action.data],
                        },
                    },
                },
            };
        }
        case actions.owner.loadListingPlacesOfInterest.SUCCESS: {
            const { listingId } = action.requestData;
            const listing = state.data[listingId] || {};

            return {
                ...state,
                data: {
                    ...state.data,
                    [listingId]: {
                        ...listing,
                        placesOfInterest: {
                            ...listing.placesOfInterest,
                            ...transform.getPlacesOfInterest(action.data),
                        },
                    },
                },
            };
        }
        case actions.owner.loadListingPetPhotos.SUCCESS: {
            const { listingId, id } = action.requestData;
            const listing = state.data[listingId] || {};
            if (!listing) return state;

            const pets = [...(listing.pets || [])];
            pets.forEach((pet) => {
                if (pet.id === id) {
                    pet.photos = [...action.data];
                }
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    [listingId]: {
                        ...listing,
                        pets: [...pets],
                    },
                },
            };
        }
        case actions.owner.createListing.SUCCESS:
            return createdUpdatedListingHelper(state, action);
        case actions.owner.publishListing.SUCCESS: {
            const { id } = action.requestData;
            const listing = state.data[id] || {};
            if (!listing) return state;

            return {
                ...state,
                data: {
                    ...state.data,
                    [id]: {
                        ...listing,
                        publishedDate: action.data.publishedDate,
                    },
                },
            };
        }
        case actions.owner.updateListing.SUCCESS: {
            return createdUpdatedListingHelper(state, action);
        }
        case actions.owner.createListingPhoto.SUCCESS:
            const { listingId } = action.requestData;
            return {
                ...state,
                data: {
                    ...state.data,
                    [listingId]: {
                        ...state.data[listingId],
                        photos: [...state.data[listingId].photos, action.data],
                    },
                },
            };
        case actions.owner.removeListingPhoto.SUCCESS: {
            const { listingId, photoId } = action.requestData;

            // Doing this manually because deleteParentPropertyItem has a couple of issues:
            // - It expects a === object instead of an id
            // - It mutates instead of returning a new array
            return {
                ...state,
                data: {
                    ...state.data,
                    [listingId]: {
                        ...state.data[listingId],
                        photos: state.data[listingId].photos.filter(
                            (photo) => photo.id !== photoId
                        ),
                    },
                },
            };
        }
        case actions.owner.createListingReminder.SUCCESS:
            return addParentPropertyItem(state, 'reminders', action.data.id, action.data.listingId);
        case actions.owner.removeListingReminder.SUCCESS:
            return deleteParentPropertyItem(
                state,
                'reminders',
                action.requestData.id,
                action.requestData.listingId
            );
        case actions.owner.createListingGuide.SUCCESS:
            return setParentPropertyItem(state, 'guide', action.data.id, action.data.listingId);
        case actions.owner.createListingOpenAssignment.SUCCESS:
            return addParentPropertyItem(
                state,
                'openAssignments',
                action.data.id,
                action.requestData.listingId
            );
        case actions.owner.removeListingOpenAssignment.SUCCESS:
            return deleteParentPropertyItem(
                state,
                'openAssignments',
                action.requestData.id,
                action.requestData.listingId
            );
        default:
            return state;
    }
};
