import React, { Component } from 'react';
import {
    SelectContainerStyled,
    SelectStyled,
    IconStyled,
    PlaceholderOptionStyled,
} from './Select.style';

/**
 * @deprecated Use `src/components/Select` instead.
 */
class Select extends Component {
    static defaultProps = {
        onSelection: (item) => {
            //
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.defaultValue || '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { value, selectedIndex } = event.target;

        // we've decreased the selectedItemIndex (this.state.selectedItemIndex - 1)
        // because we have added an extra option for the button text
        // (<option value="">{this.props.buttonText}</option>)
        const selectedItemIndex = this.props.buttonText ? selectedIndex - 1 : selectedIndex;

        // Let the consumer cancel the selection if they want by returning `false`
        const validSelection = this.props.onSelection(this.props.items[selectedItemIndex]);

        if (validSelection !== false) {
            this.setState({ value });
        }

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    render() {
        return (
            <SelectContainerStyled className={this.props.className}>
                <SelectStyled
                    name={this.props.name}
                    aria-label={this.props.ariaLabel}
                    value={this.state.value}
                    onChange={this.handleChange}
                    error={this.props.error}
                >
                    {this.props.buttonText && (
                        <PlaceholderOptionStyled value="">
                            {this.props.buttonText}
                        </PlaceholderOptionStyled>
                    )}
                    {this.props.items.map((option) => {
                        const { key, value } = this.props.itemMapToValueAndKey
                            ? this.props.itemMapToValueAndKey(option)
                            : option;

                        return (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        );
                    })}
                </SelectStyled>
                {this.props.icon ? this.props.icon : <IconStyled />}
            </SelectContainerStyled>
        );
    }
}

export default Select;
