import { parse } from 'query-string';

// eslint-disable-next-line import/prefer-default-export
export const hasFilters = (search) => {
    // Below are the filters we wish to ignore
    // filtersToBeIgnored = {
    //     activeMembership: true,
    //     assignments: {
    //         reviewing: false,
    //         confirmed: false,
    //         durationInDays: { minimum: 1 },
    //     },
    // };
    if (search && search.filters && Object.keys(search.filters).length > 0) {
        const keysWithoutIgnored = Object.keys(search.filters).filter(
            (key) => key !== 'activeMembership' && key !== 'assignments' && key !== 'sortBy'
        );

        if (keysWithoutIgnored.length > 0) {
            return true;
        }
        if (search.filters.assignments) {
            const { assignments } = search.filters;
            const assignmentsWithoutIgnoredProperties = Object.keys(assignments).filter(
                (key) => key !== 'reviewing' && key !== 'confirmed'
            );

            // We only have the ignore filters. No others applied by the user.
            if (assignmentsWithoutIgnoredProperties.length === 0) {
                return false;
            }

            // This covers the case where we only have one filter = durationInDays: { minimum: 1 } (after the
            // duration slider is cleared),
            if (
                assignmentsWithoutIgnoredProperties.length === 1 &&
                assignments.durationInDays &&
                assignments.durationInDays.minimum === 1 &&
                assignments.durationInDays.maximum === undefined
            ) {
                return false;
            }

            return true;
        }
    }

    return false;
};

export const getQueryOptionsAndRelatedURLProperties = () => {
    if (typeof window === 'undefined') return {};
    const { location } = window;
    const { search, pathname } = location;
    const urlParams = new URLSearchParams(search);
    const christmasSits = urlParams.get('christmasSits');
    const filtersQuery = urlParams.get('q');
    return { christmasSits, filtersQuery, pathname };
};
