import { SORT_FUNCTION_OBJECTS, getSortFunction } from "./constants";

import Validator from './validation/validation';
const validate = new Validator({throw: true})
/**
 * Base class for all search filters
 *
 * @export
 * @class Filter
 */
export class Filter {
    /**
     * All filters should add themselves to a filters object (override)
     *
     * @param {object} filters
     * @returns {object}
     * @memberof Filter
     */
    // eslint-disable-next-line class-methods-use-this
    add(filters) {
        validate.test({}, filters);

        return filters;
    }
}

/**
 * Base class for geo filters
 *
 * @export
 * @class GeoFilter
 * @extends {Filter}
 */
export class GeoFilter extends Filter {
    /**
     * Only one GeoFilter is allowed in a query, so remove existing before adding
     *
     * @param {object} filters
     * @returns {object}
     * @memberof GeoFilter
     */
    // eslint-disable-next-line class-methods-use-this
    removeExisting(filters) {
        validate.test({}, filters);

        ['geoPoint', 'geoHierarchy', 'geoBounds'].forEach((field) => {
            delete filters[field];
        });
        return filters;
    }
}

/**
 * A filter based on proximity to a point
 *
 * @export
 * @class GeoPoint
 * @extends {GeoFilter}
 */
export class GeoPoint extends GeoFilter {
    /**
     * Creates an instance of GeoPoint.
     * @param {number} latitude
     * @param {number} longitude
     * @param {string} distance distance with unit e.g. '40km'
     * @memberof GeoPoint
     */
    constructor(latitude, longitude, distance) {
        super();

        validate.number(latitude);
        validate.number(longitude);
        validate.test(['undefined', 'number', 'string'], distance);

        this.args = { latitude, longitude, distance };
    }

    /**
     * Add the filter
     * @param {Object} filters
     * @returns {Object}
     * @memberof GeoPoint
     */
    add(filters) {
        validate.test({}, filters);

        const newFilters = this.removeExisting(filters);
        return { ...newFilters, geoPoint: this.args };
    }
}

/**
 * A bounding box filter
 *
 * @export
 * @class GeoBounds
 * @extends {GeoFilter}
 */
export class GeoBounds extends GeoFilter {
    constructor(north, south, east, west) {
        super();

        validate.number(north);
        validate.number(south);
        validate.number(east);
        validate.number(west);

        this.args = { north, south, east, west };
    }


    /**
     * Add the filter
     * @param {Object} filters
     * @returns {Object}
     * @memberof GeoBounds
     */
    add(filters) {
        validate.test({}, filters);

        const newFilters = this.removeExisting(filters);
        return { ...newFilters, geoBounds: this.args };
    }
}

/**
 * A hierarchy filter
 * @export
 * @class GeoHierarchy
 * @extends {GeoFilter}
 */
export class GeoHierarchy extends GeoFilter {
    /**
     * Creates an instance of GeoHierarchy.
     * @param {Object} args
     * @param {string?} args.continentSlug
     * @param {string?} args.countrySlug
     * @param {string?} args.admin1Slug
     * @param {string?} args.admin2Slug
     * @param {string?} args.admin3Slug
     * @param {string?} args.admin4Slug
     * @param {string?} args.slug
     * @memberof GeoHierarchy
     */
    constructor(args) {
        super();

        validate.test({}, args);

        this.args = args;
    }

    add(filters) {
        validate.test({}, filters);

        const newFilters = this.removeExisting(filters);
        return { ...newFilters, geoHierarchy: this.args };
    }
}


/**
 * A free text filter
 *
 * @export
 * @class FreeText
 * @extends {Filter}
 */
export class FreeText extends Filter {
    /**
     * Creates an instance of FreeText.
     * @param {string} searchString
     * @memberof FreeText
     */
    constructor(searchString) {
        super();
        this.searchString = searchString;
    }

    /**
     * Add the filter
     *
     * @param {object} filters
     * @returns {object}
     * @memberof FreeText
     */
    add(filters) {
        validate.test({}, filters);

        return { ...filters, freeText: this.searchString };
    }
}

export class IdsFilter extends Filter {
    constructor(value) {
        super();

        validate.oneOf(['undefined', []], value);

        this.value = value === undefined ? this.default : value;
    }

    /**
     * Default value of the field
     *
     * @readonly
     * @memberof IdsFilter
     */
    // eslint-disable-next-line class-methods-use-this
    get default() {
        return [];
    }

    /**
     * The name of the field (override)
     *
     * @readonly
     * @memberof IdsFilter
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'ids';
    }

    /**
     * Add the filter
     *
     * @param {object} filters
     * @returns {object}
     * @memberof IdsFilter
     */
    add(filters) {
        validate.test({}, filters);

        filters[this.name] = this.value;
        return filters;
    }
}


export class BooleanFilter extends Filter {
    constructor(value) {
        super();
        validate.oneOf(['undefined', 'boolean'], value);
        this.value = value === undefined ? this.default : value;
    }

    /**
     * Default value of the field
     *
     * @readonly
     * @memberof BooleanFilter
     */
    // eslint-disable-next-line class-methods-use-this
    get default() {
        return true;
    }

    /**
     * The name of the field (override)
     *
     * @readonly
     * @memberof BooleanFilter
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'fieldName';
    }

    /**
     * Add the filter
     *
     * @param {object} filters
     * @returns {object}
     * @memberof BooleanFilter
     */
    add(filters) {
        validate.test({}, filters);

        filters[this.name] = this.value;
        return filters;
    }
}

/**
 * An active membership filter
 *
 * @export
 * @class ActiveMembership
 * @extends {BooleanFilter}
 */
export class ActiveMembership extends BooleanFilter {
    /**
     * The name of the field
     *
     * @readonly
     * @memberof ActiveMembership
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'activeMembership';
    }
}

export class Query {
    /**
     * Creates an instance of Query.
     * @memberof Query
     */
    constructor() {
        this.query = {
            filters: {},
            facets: new Set(),
            sort: [],
            page: 1,
            resultsPerPage: 12,
            debug: false,
        };
    }


    /**
     * Adds one or many filters to the query
     *
     * @returns
     * @memberof Query
     */
    filter(...args) {
        const filters = Array.from(args);

        validate.test([{}], args);

        filters.forEach((filter) => {
            this.query.filters = filter.add(Object.assign({}, this.query.filters));
        });

        return this;
    }


    /**
     * Adds one or many facets to the query
     *
     * @returns
     * @memberof Query
     */
    facet(...args) {
        const facets = Array.from(args);

        facets.forEach((facet) => {
            this.query.facets.add(facet);
        });

        return this;
    }

    /**
     * Set the limit for facet results
     *
     * @returns
     * @memberof Query
     */
    facetResultSize(facetResultSize) {
        this.query.facetResultSize = facetResultSize;

        return this;
    }

    /**
     * Sorts the query
     *
     * @param {string} field
     * @param {string|SortDict} direction
     * @param {string} direction.order
     * @returns
     * @memberof Query
     */
    sort(field, direction) {
        validate.string(field);
        validate.oneOf(['enum:asc,desc', { order: 'enum:asc,desc' }], direction);

        const fieldObject = {};
        fieldObject[field] = direction;
        this.query.sort.push(fieldObject);
        return this;
    }

    sortFunction(name, args) {
        validate.string(name);
        const fieldObject = {
            function: getSortFunction(name, args)
        };
        this.query.sort.push(fieldObject);
    }

    // "function": {
    //     "name": "LOCAL_SITS",
    //     "order": "asc",
    //     "location_bias": {
	// 		"country_code": "UK"
    //     }
    //   }

    /**
     * Sets the page of results to load
     *
     * @param {number} page
     * @memberof Query
     */
    page(page) {
        validate.number(page);

        this.query.page = page;
        return this;
    }

    /**
     * Sets the number of results per page
     *
     * @param {number} resultsPerPage
     * @memberof Query
     */
    resultsPerPage(resultsPerPage) {
        validate.number(resultsPerPage);
        this.query.resultsPerPage = resultsPerPage;
        return this;
    }

    /**
     * Sets debug mode
     *
     * @param {boolean} debug
     * @memberof Query
     */
    debug(debug) {
        validate.boolean(debug);
        this.query.debug = debug;
        return this;
    }

    /**
     * Returns the object representing the query
     *
     * @returns
     * @memberof Query
     */
    getRequestData() {
        return { ...this.query, facets: Array.from(this.query.facets) };
    }
}
