import { css } from 'styled-components';

export default css`
    html {
        font-size: 100%;
    }
    body,
    button,
    input,
    select {
        font-family: ${({ theme }) => theme.fonts.body};
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
        color: ${({ theme }) => theme.palette.text};
    }

    strong {
        font-weight: 600;
    }
`;
