import React, { useContext } from 'react';
import { FlexboxGridContext } from './FlexboxGrid';
import { ColStyled } from './FlexboxGrid.style';

const Col = ({ span, fit, className, children, ...props }) => {
    const { totalCols, config } = useContext(FlexboxGridContext);

    return (
        <ColStyled
            className={className}
            totalCols={totalCols}
            config={config}
            span={span}
            fit={fit}
            {...props}
        >
            {children}
        </ColStyled>
    );
};

Col.defaultProps = {
    span: 12,
    fit: false,
};

export default Col;
