export const SCREEN_HOW_WAS_YOUR_SITTER = 'howWasYourSitter';
export const SCREEN_HOW_ORGANIZED_WAS_YOUR_SITTER = 'howOrganizedWasYourSitter';
export const SCREEN_HOW_RELIABLE_WAS_SITTER = 'howReliableWasYourSitter';
export const SCREEN_HOW_SELF_SUFFICIENT_WAS_SITTER = 'howSelfSufficientWasSitter';
export const SCREEN_HOW_TIDY_WAS_SITTER = 'howSelfTidyWasSitter';
export const SCREEN_HOW_WAS_SITTERS_PET_CARE = 'howWasSittersPetCare';
export const SCREEN_WRITE_REVIEW = 'writeReview';
export const SCREEN_THANKS = 'thanks';
export const SCREEN_EXIT_WARNING = 'exitWarning';
export const SCREEN_REVIEW_WINDOW_CLOSED = 'reviewWindowClosed';

// Rating's labels values
export const RATING_HOW_ORGANIZED_WAS_YOUR_SITTER_LABELS = [
    'containers_leave_review_modal_very_disorganised_label',
    'containers_leave_review_modal_disorganised_label',
    'containers_leave_review_modal_fairly_organised_label',
    'containers_leave_review_modal_organised_label',
    'containers_leave_review_modal_very_organised_label',
];

export const RATING_HOW_RELIABLE_WAS_SITTER_LABELS = [
    'containers_leave_review_modal_very_unreliable_label',
    'containers_leave_review_modal_unreliable_label',
    'containers_leave_review_modal_fairly_reliable_label',
    'containers_leave_review_modal_reliable_label',
    'containers_leave_review_modal_very_reliable_label',
];

export const RATING_HOW_SELF_SUFFICIENT_WAS_SITTER_LABELS = [
    'containers_leave_review_modal_not_at_all_self_sufficient_label',
    'containers_leave_review_modal_not_self_sufficient_label',
    'containers_leave_review_modal_fairly_self_sufficient_label',
    'containers_leave_review_modal_self_sufficient_label',
    'containers_leave_review_modal_very_self_sufficient_label',
];

export const RATING_HOW_TIDY_WAS_SITTER_LABELS = [
    'containers_leave_review_modal_very_untidy_label',
    'containers_leave_review_modal_untidy_label',
    'containers_leave_review_modal_fairly_tidy_label',
    'containers_leave_review_modal_tidy_label',
    'containers_leave_review_modal_very_tidy_label',
];

export const RATING_HOW_WAS_SITTERS_PET_CARE_LABELS = [
    'containers_leave_review_modal_awful_label',
    'containers_leave_review_modal_bad_label',
    'containers_leave_review_modal_okay_label',
    'containers_leave_review_modal_good_label',
    'containers_leave_review_modal_excellent_label',
];
