import { takeEvery, all, select } from 'redux-saga/effects';
import { addDays } from 'date-fns';
import * as actions from './actions';
import { getAllItems } from './selectors';
import { COOKIE_NAME } from './constants';

export function* updateCookie() {
    if (document) {
        // Get all the items to store in a cookie
        const items = yield select(getAllItems);
        // Create a cookie with serialized data
        const expiryDate = addDays(new Date(), 365);
        const cookie = `${COOKIE_NAME}=${JSON.stringify(
            items
        )}; expires=${expiryDate.toUTCString()}; secure; path=/`;

        // Store the cookie
        document.cookie = cookie;
    }
}

export default function* () {
    yield all([
        takeEvery((action) => action.type === actions.setItem.ACTION, updateCookie),
        takeEvery((action) => action.type === actions.removeItem.ACTION, updateCookie),
    ]);
}
