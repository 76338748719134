import { getParams } from 'containers/Page/selectors';
import { getBlogCategory, getBlogCategoryPosts } from 'api/selectors/content';
import { PAGE_ID, SEARCH_TYPE } from './constants';

export const getCategory = (state) => {
    const params = getParams(state, PAGE_ID);
    return getBlogCategory(state, params.area, params.category);
};

export const getPosts = (state) => {
    const params = getParams(state, PAGE_ID);
    return getBlogCategoryPosts(state, params.area, params.category, SEARCH_TYPE);
};
