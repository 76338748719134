/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { IconChevronRight } from 'components/Icon';
import BreadcrumbStyled, {
    BreadcrumbItemSeperatorStyled,
    BreadcrumbItemStyled,
    BreadcrumbItemTextStyled,
    BreadcrumbItemLinkStyled,
} from './Breadcrumb.style';

const Breadcrumb = ({ items, disableLastItem, className, ...rest }) => (
    <BreadcrumbStyled className={className} {...rest}>
        {items.map((item, index) => {
            const isLastItem = index >= items.length - 1;
            const shouldRenderLink =
                typeof item.to === 'string' && (!isLastItem || !disableLastItem);
            const ItemComponent = shouldRenderLink
                ? BreadcrumbItemLinkStyled
                : BreadcrumbItemTextStyled;
            const itemProps = {
                to: shouldRenderLink ? item.to : undefined,
                'data-testid': `item-${item.id}`,
            };

            return (
                <React.Fragment key={item.id}>
                    <BreadcrumbItemStyled
                        isLastItem={isLastItem && disableLastItem}
                        data-testid={item.id}
                    >
                        <ItemComponent {...itemProps}>{item.text}</ItemComponent>
                    </BreadcrumbItemStyled>

                    {!isLastItem && (
                        <BreadcrumbItemSeperatorStyled>
                            <IconChevronRight
                                data-testid="chevron-right"
                                size="small"
                                palette="icon"
                            />
                        </BreadcrumbItemSeperatorStyled>
                    )}
                </React.Fragment>
            );
        })}
    </BreadcrumbStyled>
);

Breadcrumb.propTypes = {
    items: PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        to: PropTypes.string,
    }).isRequired,
    disableLastItem: PropTypes.bool,
    className: PropTypes.string,
};

Breadcrumb.defaultProps = {
    disableLastItem: true,
    className: undefined,
};

export default Breadcrumb;
