import React, { Fragment } from 'react';
import { Redirect } from 'react-router';
import PageSpinner from 'components/Spinner';
import { PageSpinnerContainerStyled } from './SpinnerWithConditionalRedirect.style';

const SpinnerWithConditionalRedirect = ({ shouldRedirect, to }) => (
    <>
        {shouldRedirect ? (
            <Redirect to={to} />
        ) : (
            <PageSpinnerContainerStyled>
                <PageSpinner />
            </PageSpinnerContainerStyled>
        )}
    </>
);

export default SpinnerWithConditionalRedirect;
