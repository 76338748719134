import PropTypes from 'prop-types';

/**
 * Media
 */
export const baseMediaProps = {
    publicId: PropTypes.string,
    description: PropTypes.string,
};

export const baseMediaPhoto = PropTypes.shape(baseMediaProps);
export const baseMediaVideo = PropTypes.shape(baseMediaProps);

/**
 * Location
 */
export const baseLocationProps = {
    continentName: PropTypes.string,
    continentSlug: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    admin1Name: PropTypes.string,
    admin1Slug: PropTypes.string,
    countryName: PropTypes.string,
    countrySlug: PropTypes.string,
    coordinates: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number,
    }),
};
export const baseLocation = PropTypes.shape(baseLocationProps);

/**
 * Notification
 */
export const baseNotificationProps = {
    id: PropTypes.string,
    body: PropTypes.any,
    type: PropTypes.oneOf([
        'message',
        'message-photo',
        'message-video',
        'assignment-application',
        'assignment-datechange',
        'datechange-declined',
        'datechange-accepted',
        'assignment-datedeleted',
        'assignment-invitation',
        'assignment-confirmed',
        'assignment-unconfirmed',
        'assignment-declined',
        'invitation-declined',
        'invitation-cancelled',
        'application-declined',
        'application-confirmed',
        'application-cancelled',
        'welcomeguide-requested',
        'welcomeguide-received',
        'reminders-added',
        'feedback-response',
        'feedback-received',
        'sitconfirmation-confirmed',
        'assignment-finished',
        'sitconfirmation-declined',
        'invitation-acknowledged',
        'feedbackrequest-received',
        'review-received',
        'review-response',
        'reviewrequest-received',
        'favourite-newdates',
        'recommended-sits',
        'recommended-travelinspiration',
        'recommended-petinspiration',
        'system-appupdate',
        'system-tncupdate',
    ]),
    subType: PropTypes.oneOf(['text', 'json', 'url']),
    sendDate: PropTypes.string,
    sentBy: PropTypes.oneOf(['me', 'contact']),
    isRead: PropTypes.bool,
};
export const baseNotification = PropTypes.shape(baseNotificationProps);

/**
 * Assignment
 */
export const baseAssignmentProps = {
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,

    profileId: PropTypes.string,
    listingId: PropTypes.string,
    isReviewing: PropTypes.bool,
    isPrivate: PropTypes.bool,
    isApproximateDates: PropTypes.bool,
};

/**
 * Application types
 */
export const applicationsTypes = {
    confirmed: PropTypes.number,
    shortlisted: PropTypes.arrayOf(PropTypes.number),
    invitations: PropTypes.arrayOf(PropTypes.number),
    applications: PropTypes.arrayOf(PropTypes.number),
    declined: PropTypes.arrayOf(PropTypes.number),
};

/**
 * Welcome guide status
 */
export const baseWelcomeGuideStatusProps = {
    shared: PropTypes.bool,
    requested: PropTypes.bool,
};
export const baseWelcomeGuideStatus = PropTypes.shape(baseWelcomeGuideStatusProps);

/**
 * Feedback
 */
export const baseFeedbackProps = {
    id: PropTypes.string,
    description: PropTypes.string,
    sentAt: PropTypes.string,
    reply: PropTypes.string,
    replySentAt: PropTypes.string,

    recommended: PropTypes.number,

    request: PropTypes.shape({
        id: PropTypes.string,
        message: PropTypes.string,
        sentAt: PropTypes.string,
        completedDate: PropTypes.string,
        rejectedDate: PropTypes.string,
    }),
};
export const baseFeedback = PropTypes.shape(baseFeedbackProps);

/**
 * Review
 */
export const baseReviewProps = {
    id: PropTypes.string,
    description: PropTypes.string,
    sentAt: PropTypes.string,
    reply: PropTypes.string,
    replySentAt: PropTypes.string,

    organised: PropTypes.number,
    reliable: PropTypes.number,
    selfSufficient: PropTypes.number,
    tidy: PropTypes.number,
    petCare: PropTypes.number,

    request: PropTypes.shape({
        id: PropTypes.string,
        message: PropTypes.string,
        sentAt: PropTypes.string,
        completedDate: PropTypes.string,
        rejectedDate: PropTypes.string,
    }),
};
export const baseReview = PropTypes.shape(baseReviewProps);

/**
 * Base Home Features
 */
export const baseHomeFeatures = PropTypes.oneOf([
    'house',
    'apartment',
    'beach',
    'mountain',
    'city',
    'couple',
    'family',

    // based on api-client/src/helpers/transform/listings.js' getHomeFeatures
    'disabledAccess',
    'familyFriendly',
    'carIncluded',
    'isAccessibleByPublicTransport',
    'isNotAccessibleByPublicTransport',

    // based on api-client/src/helpers/transform/common.js' commonKeywordMapping
    'balcony',
    'basic',
    'bathtub',
    'communalGardens',
    'cooker',
    'countrySide',
    'dishwasher',
    'electric',
    'garden',
    'gas',
    'good',
    'highSpeed',
    'hob',
    'iron',
    'jacuzzi',
    'microwave',
    'moderate',
    'no',
    'nonExistent',
    'none',
    'outdoorDryingSpace',
    'other',
    'patio',
    'petFriendly',
    'pool',
    'poorOnSomeNetworks',
    'single',
    'terrace',
    'tumbleDryer',
    'vacuumCleaner',
    'yes',
    'washingMachine',
    'calm',
    'happy',
    'excited',
    'lovable',
    'demanding',
    'independent',
    'aloof',
    'worried',
    'peopleFriendly',
    'friendly',
    'childFriendly',
    'confident',
    'nervous',
    'totallyChilled',
]);

/**
 * Welcome Guide
 */
export const baseContactProps = {
    name: PropTypes.string,
    address: PropTypes.string,
    phoneNumber: PropTypes.string,
    notes: PropTypes.string,
};
export const baseContact = PropTypes.shape(baseContactProps);

export const basePlaceOfInterestProps = {
    id: PropTypes.string,
    category: PropTypes.string,
    name: PropTypes.string,
    whyRecommended: PropTypes.string,
    phoneNumber: PropTypes.string,
    website: PropTypes.string,
    petFriendly: PropTypes.bool,
    dateCreated: PropTypes.string,
    listingId: PropTypes.string,
    media: PropTypes.arrayOf(baseMediaPhoto),
    userId: PropTypes.string,
};
export const basePlaceOfInterest = PropTypes.shape(basePlaceOfInterestProps);

// Animals
export const baseAnimalType = PropTypes.oneOf([
    'dog',
    'cat',
    'bird',
    'fish',
    'lizard',
    'small-pets',
    'sheep',
    'poultry',
    'horse',
]);
export const baseAnimalTypes = PropTypes.arrayOf(baseAnimalType);

// Pet personality - Change to translatable keys when fixed
export const petPersonality = PropTypes.oneOf([
    'Calm',
    'Happy',
    'Excited',
    'Lovable',
    'Demanding',
    'Independent',
    'Aloof',
    'Worried',
    'People Friendly',
    'Pet Friendly',
    'Child Friendly',
    'Confident',
    'Nervous',
    'Totally Chilled',
    'Other',
]);

/**
 * Listing Pet
 */
export const basePet = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    animalSlug: baseAnimalTypes,
    yearOfBirth: PropTypes.number,
    breedName: PropTypes.string,
    generalInformation: PropTypes.string,
    healthInformation: PropTypes.string,
    feedingInstructions: PropTypes.string,
    personality: PropTypes.arrayOf(petPersonality),
    personalityOther: PropTypes.string,
    personalityExtra: PropTypes.string,
    exerciseAndPlay: PropTypes.string,
    houseRules: PropTypes.string,
    toiletAndGrooming: PropTypes.string,
    cleaning: PropTypes.string,
    likesDislikesInformation: PropTypes.string,
    otherInformation: PropTypes.string,
    medias: PropTypes.arrayOf(baseMediaPhoto),
};

/**
 * Local Attractions
 */
export const baseLocalAttraction = PropTypes.oneOf(['beach', 'mountain', 'city', 'countrySide']);

/**
 * Sitter Assignment
 */
export const sitterPastAssignmentProps = {
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    ownerId: PropTypes.string,
    ownerName: PropTypes.string,
    ownerAvatarPhoto: baseMediaPhoto,
    listingLocation: baseLocation,
    review: baseReview,
};
export const sitterPastAssignment = PropTypes.shape(sitterPastAssignmentProps);

/**
 * Reference
 */
export const baseReferenceProps = {
    id: PropTypes.string,
    description: PropTypes.string,
    sentAt: PropTypes.string,

    organised: PropTypes.number,
    reliable: PropTypes.number,
    selfSufficient: PropTypes.number,
    tidy: PropTypes.number,
    petCare: PropTypes.number,
};
export const baseReference = PropTypes.shape(baseReferenceProps);

// OLD
export const pagination = {
    next: PropTypes.string,
    page: PropTypes.number,
    pages: PropTypes.number,
    perPage: PropTypes.number,
    count: PropTypes.number,
};

export const baseProfile = {
    id: PropTypes.number,
    userId: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    avatarPhoto: PropTypes.string,
};

export const baseListing = {
    id: PropTypes.number,
    userId: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    country: PropTypes.string,
    photo: PropTypes.string,
    avatarPhoto: PropTypes.string,
    assignment: PropTypes.shape(baseAssignmentProps),
};

export const baseMessage = {
    id: PropTypes.number,
    sendDate: PropTypes.string,
    type: PropTypes.string,
    subType: PropTypes.string,
    body: PropTypes.string,
    payload: PropTypes.object,
};

export const baseApplication = {
    id: PropTypes.string,
    sitterId: PropTypes.string,
    ownerId: PropTypes.string,
    profileId: PropTypes.string,
    listingId: PropTypes.string,
    assignmentId: PropTypes.string,
    hasOwnerConfirmed: PropTypes.bool,
    hasSitterConfirmed: PropTypes.bool,
    hasAssignmentDatesChanged: PropTypes.bool,
    isInvited: PropTypes.bool,
    isInvitationAcknowledged: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    isShortlisted: PropTypes.bool,
    hasOwnerDeclined: PropTypes.bool,
    hasOwnerCancelled: PropTypes.bool,
    hasSitterDeclined: PropTypes.bool,
    hasSitterCancelled: PropTypes.bool,
};

export const baseOpenAssignmentApplication = {
    id: PropTypes.number,
    date: PropTypes.string,
    invited: PropTypes.bool,
    shortlisted: PropTypes.bool,
    confirmed: PropTypes.bool,
    sitterConfirmed: PropTypes.bool,
    ownerConfirmed: PropTypes.bool,
    assignment: PropTypes.number,
    profile: PropTypes.shape(baseProfile),
    lastMessage: PropTypes.shape(baseMessage),
};

export const baseReminder = {
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
};

export const baseAnimal = {
    id: PropTypes.string,
    number: PropTypes.number,
    order: PropTypes.number,
    name: PropTypes.string,
    descriptionOverride: PropTypes.bool,
    friendlyName: PropTypes.string,
};

export const baseConversationProps = {
    id: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    type: PropTypes.string,
    subType: PropTypes.string,
    sendDate: PropTypes.string,
    // me: senderId === MY USER ID,
    // contact: senderId === CONTACT USER ID
    sentBy: PropTypes.string,
    isRead: PropTypes.bool,
};
export const baseConversation = PropTypes.shape(baseConversationProps);
