import { formatDate } from 'utils/date';
import { formatLocationThePrefix } from 'utils/i18nHelpers/location';
import i18next from 'i18next';

// require(...) is no longer needed with the latest version of i18next
const i18n = i18next;

const isEnLng = (lng) => lng.split('-')[0] === 'en';

const i18nOptions = {
    fallbackLng: 'en-GB',
    supportedLngs: ['en-GB', 'en-US'],
    load: 'currentOnly',

    ns: ['translations', 'design'],
    defaultNS: 'translations',

    debug: false,

    interpolation: {
        escapeValue: false, // not needed for react!!
        format: (value, formatStr, lng) => {
            if (value instanceof Date) return formatDate(value, formatStr, lng);
            if (formatStr === 'en-handle-location-the') {
                return isEnLng(lng) ? formatLocationThePrefix(value, false) : '';
            }
            if (formatStr === 'en-handle-location-the-capitalise') {
                return isEnLng(lng) ? formatLocationThePrefix(value, true) : '';
            }
            if (formatStr === 'possessive') {
                try {
                    if (value.toLowerCase().endsWith('s')) {
                        return `${value}'`;
                    }
                    return `${value}'s`;
                } catch (error) {
                    return value;
                }
            }
            return value;
        },
    },
};

const getI18nIgnoreRoutes = (pathPrefixes) => (req, res, options) => {
    for (let i = 0; i < pathPrefixes.length; i += 1) {
        if (options.getPath(req).startsWith(pathPrefixes[i])) return true;
    }
    return false;
};

export { i18n, i18nOptions, getI18nIgnoreRoutes };
