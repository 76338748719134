import { useEffect } from 'react';
import { track, Events } from 'utils/analytics';
import useFeature from 'hooks/useFeature';
import PropTypes from 'prop-types';

/**
 * The Features component works in unison with Optimizely and allows us to turn off / on features.
 * We can also roll our features to a percentage of users.
 * You can set the percentage of users to roll a feature out to in Optimizely.
 * By default we now send the isStaff attribute so its possible to the select staff member audience in Optimizely
 * By default we now send the beta_group_{{groupName}} attribute so its possible to the select beta group audience in Optimizely
 */
const Feature = ({ name, userId, attributes, log, on, off }) => {
    const { enabled } = useFeature({ name, userId, attributes });

    useEffect(() => {
        if (log && enabled) {
            track(Events.VIEWED_FEATURE.create(name));
        }
    }, [enabled, log, name]);

    return enabled ? on : off;
};

Feature.propTypes = {
    name: PropTypes.string.isRequired,
    userId: PropTypes.string,
    attributes: PropTypes.shape({}),
    log: PropTypes.bool,
    on: PropTypes.element.isRequired,
    off: PropTypes.element.isRequired,
};

// Set default props
Feature.defaultProps = {
    log: false,
    userId: 'n/a',
};

export { Feature as default };
