import React from 'react';

import { useRating } from 'components/Rating/context';
import { LabelsWrapper } from './Labels.style';

function Labels({ children, ...props }) {
    const { ratingSystem } = useRating();

    return (
        <LabelsWrapper {...props}>
            {ratingSystem.map((value, index) => {
                const rating = index + 1;

                return (
                    <React.Fragment key={rating}>
                        {children({
                            rating,
                            label: value,
                        })}
                    </React.Fragment>
                );
            })}
        </LabelsWrapper>
    );
}

Labels.displayName = 'RatingLabels';

export default Labels;
