import styled, { css } from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import ButtonIcon from 'components/buttons/ButtonIcon';
import InputIcon from '../../InputIcon';
import { Variant } from '../Combobox.constants';

const inuputShadow = css`
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

export const InputIconStyled = styled(InputIcon)`
    padding-left: ${({ inputHasFocus, iconLeft, theme }) =>
        inputHasFocus || !iconLeft ? theme.spacing[2] : theme.spacing[6]};
    padding-right: ${({ theme }) => theme.spacing[2]};
    border: 1px solid
        ${({ theme, variant }) =>
            variant === Variant.Default ? theme.palette.borderDark : theme.palette.border};
    ${({ withShadow }) => (withShadow ? inuputShadow : null)};
    border-radius: ${({ theme, variant }) =>
        variant === Variant.LightRounded ? theme.borderRadius.regular : '3px'};
    background-color: ${({ theme }) => theme.palette.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
        // we have added a z-index so that any position fixed save buttons do not
        // overlap the field when they are in focus
        z-index: ${(props) => zIndex(props) + 1};
    }

    // removes the default ie clear icon in favour of our own
    &::-ms-clear {
        width: 0;
        height: 0;
    }

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding-left: ${({ theme, iconLeft }) => (iconLeft ? theme.spacing[6] : theme.spacing[2])};
        margin-left: 0;
        margin-right: 0;
    }
`;

const IconFocusZindex = css`
    z-index: ${(props) => zIndex(props) + 2};
`;

export const SearchIconStyled = styled.span`
    min-width: ${({ theme }) => theme.spacing[6]};
    display: ${({ inputHasFocus }) => inputHasFocus && 'none'};
    user-select: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    ${({ inputHasFocus }) => inputHasFocus && IconFocusZindex};

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: flex;
    }

    && svg {
        fill: ${({ theme }) => theme.palette.grey['600']};
    }
`;

export const ClearButtonIconStyled = styled(ButtonIcon)`
    min-width: ${({ theme }) => theme.spacing[2]};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    padding: ${({ theme }) => theme.spacing[2]};
    margin: 0;
    background: none;
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

    ${({ inputHasFocus }) => inputHasFocus && IconFocusZindex};

    &:hover:enabled,
    &:hover:active {
        background: none;
    }

    &:active:enabled {
        background: none;
    }

    && svg {
        fill: ${({ theme }) => theme.palette.grey['600']};
    }
`;
