import React, { Component } from 'react';
import ZIndexManager from 'components/ZIndexManager';
import { addEventListener } from 'utils/funcs';
import Listbox from './components/Listbox';
import ComboboxInput from './components/ComboboxInput';
import { ComboboxStyled } from './Combobox.style';

class Combobox extends Component {
    static Listbox = Listbox;

    static Input = ComboboxInput;

    static defaultProps = {
        inputValue: '',
        // index of -1 allows us to count up from 0 for first item
        // whereas null would not
        highlightedIndex: -1,
        renderListbox: (listboxProps) => <Listbox {...listboxProps} />,
        renderInput: (focusTextInput, selectHighlighted, clear, setShowSuggestions, inputProps) => (
            <ComboboxInput
                focusTextInput={focusTextInput}
                selectHighlighted={selectHighlighted}
                clear={clear}
                setShowSuggestions={setShowSuggestions}
                {...inputProps}
            />
        ),
    };

    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.inputValue,
            // if the user is scrolling with arrow keys or mouse
            scrollType: null, // oneOf 'keys', 'mouse' or null
            // we save the selectedSuggestion to state so that we can
            // reset it when needed
            selectedSuggestion: null,
            showSuggestions: false,
            highlightedIndex: props.highlightedIndex,
        };
        this.setShowSuggestions = this.setShowSuggestions.bind(this);
        this.selectHighlighted = this.selectHighlighted.bind(this);
        this.onFocusin = this.onFocusin.bind(this);
        this.onFocusout = this.onFocusout.bind(this);

        // This is to show initial search results on focus
        if (props.onInputChange) {
            props.onInputChange(props.inputValue);
        }
    }

    componentDidMount() {
        /**
         * We're setting a global event listener for focusin.
         * When focus is within the combobox we add a focusout event listener and remove
         * the focusin listener.
         * When the focus is outside of the combobox we add a focusin listener and
         * remove the focusout listener
         */
        this.onFocusinListener = addEventListener(window, 'focusin', this.onFocusin);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.scrollType === 'keys') this.scrollHighlightedItemIntoView();

        if (prevProps.inputValue !== this.props.inputValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                inputValue: this.props.inputValue,
            });
        }
    }

    componentWillUnmount() {
        this.cleanup();
    }

    onFocusin = (event) => {
        if (this.rootNode) {
            if (event.target === this.rootNode || this.rootNode.contains(event.target)) {
                this.setShowSuggestions(true);
                this.onFocusoutListener = addEventListener(window, 'focusout', this.onFocusout);
                this.onFocusinListener.remove();
                if (this.props.onFocusHandler) this.props.onFocusHandler();
            }
        }
    };

    onFocusout = (event) => {
        const selectedSuggestionName = this.getSuggestionName(this.state.selectedSuggestion);

        // Check if focus is leaving the combobox
        if (event.relatedTarget === this.rootNode || !this.rootNode.contains(event.relatedTarget)) {
            if (this.state.showSuggestions) this.setShowSuggestions(false);

            // reset input value to previously selected suggestion when the user has changed the input value
            // but not selected another suggestion
            if (this.state.selectedSuggestion && this.state.inputValue !== selectedSuggestionName) {
                this.setInputValue(selectedSuggestionName, () => {
                    if (this.props.onInputChange) this.props.onInputChange(this.state.inputValue);
                });
            }

            this.onFocusoutListener.remove();
            this.onFocusinListener = addEventListener(window, 'focusin', this.onFocusin);
            if (this.props.onFocusoutHandler) this.props.onFocusoutHandler();
        }
    };

    onInputChangeHandler = (event, newValue) => {
        if (this.props.onInputChange) this.props.onInputChange(newValue);

        this.setInputValue(newValue, () => {
            this.setShowSuggestions(true);
        });

        this.setHighlightedIndex(-1);
    };

    /**
     * @description
     * When a selection is made this gets the suggestion
     * and sets it as the state's selected suggestion.
     *
     * @param {object} event DOM event in response to a selection (mouse or key)
     *
     * @returns undefined
     */
    onSelectSuggestionHandler = (event, suggestionIndex) => {
        this.focusTextInput();
        // We return the suggestion object that was initially passed in via prop
        this.selectSuggestion(this.props.suggestions[suggestionIndex]);
        this.setShowSuggestions(false);
    };

    /**
     * @description
     * Simply forwards the event to it's handler
     *
     * @param  {object} event keydown event
     */
    onInputkeydownHandler = (event) => {
        const keyDownHandler = this.inputKeyDownHandlers[event.key];

        if (keyDownHandler) keyDownHandler.call(this, event);
    };

    /**
     * @description
     * Sets the highlighted index to the suggestion that was hovered.
     *
     * @param  {object} event mouseenter DOM event
     */
    onItemMouseEnter = (event, suggestionIndex) => {
        this.setScrollType('mouse');

        this.setHighlightedIndex(parseInt(suggestionIndex, 10));
    };

    setSelectedSuggestion(suggestion) {
        this.setState({
            selectedSuggestion: suggestion,
        });
    }

    setInputValue(inputValue, callback) {
        this.setState(
            {
                inputValue,
            },
            () => {
                if (callback) callback();
            }
        );
    }

    setShowSuggestions(showSuggestions) {
        this.setState({
            showSuggestions,
        });
    }

    getHighlighted() {
        return this.props.suggestions[this.state.highlightedIndex];
    }

    setHighlightedIndex(highlightedIndex) {
        this.setState({
            highlightedIndex,
        });
    }

    setScrollType(scrollType) {
        this.setState({
            scrollType,
        });
    }

    getSuggestionName = (suggestion) => {
        if (suggestion === null) return '';
        return this.props.suggestionMapToNameAndKey(suggestion).name;
    };

    cleanup = () => {
        if (this.onFocusinListener) this.onFocusinListener.remove();
        if (this.onFocusoutListener) this.onFocusoutListener.remove();
    };

    attachRef = (rootNode) => {
        this.rootNode = rootNode;
    };

    resetState() {
        this.setHighlightedIndex(-1);
        this.selectSuggestion(this.state.selectedSuggestion ? this.state.selectedSuggestion : null);

        this.setShowSuggestions(false);
    }

    reset = () => {
        this.focusTextInput();
        this.resetState();
        if (this.props.onReset) this.props.onReset();
    };

    clear = () => {
        this.setHighlightedIndex(-1);
        this.setInputValue('');
        this.setSelectedSuggestion('');

        if (this.props.onClear) this.props.onClear();
        this.setShowSuggestions(true);
        this.focusTextInput();
    };

    focusTextInput = () => {
        this.inputElement.focus();
    };

    inputKeyDownHandlers = {
        ArrowDown(event) {
            event.preventDefault();

            this.setScrollType('keys');

            if (!this.state.showSuggestions) this.setShowSuggestions(true);

            this.moveHighlightedSuggestion('down');
        },

        ArrowUp(event) {
            event.preventDefault();

            this.setScrollType('keys');

            if (!this.state.showSuggestions) this.setShowSuggestions(true);

            this.moveHighlightedSuggestion('up');
        },

        Enter(event) {
            event.preventDefault();
            if (this.props.onEnter) {
                this.props.onEnter(this.state.inputValue);
                this.setShowSuggestions(false);
            } else {
                if (this.state.highlightedIndex === -1) {
                    this.setHighlightedIndex(0);
                    this.selectSuggestion(this.props.suggestions[0]);
                }
                this.selectHighlighted();
            }
        },

        Escape(event) {
            event.preventDefault();
            this.reset();
        },

        Tab() {
            this.setShowSuggestions(false);
        },
    };

    isMenuElementScrollable = () => this.MenuElement.scrollHeight > this.MenuElement.clientHeight;

    moveHighlightedSuggestion(direction) {
        if (!this.props.suggestions.length) return;

        const maxIndex = this.props.suggestions.length - 1;
        // in rare occasions the user can mouse out of the list
        // without a highlightedIndex being set. So we default to 0 to prevent an error
        // being logged
        const { highlightedIndex } = this.state || 0;

        switch (direction) {
            case 'up':
                if (highlightedIndex === -1 || highlightedIndex === 0) {
                    this.setHighlightedIndex(maxIndex);
                    this.setInputValue(this.getSuggestionName(this.props.suggestions[maxIndex]));
                } else {
                    this.setHighlightedIndex(highlightedIndex - 1);
                    this.setInputValue(
                        this.getSuggestionName(this.props.suggestions[highlightedIndex - 1])
                    );
                }
                break;

            case 'down':
                if (highlightedIndex === maxIndex) {
                    this.setHighlightedIndex(0);

                    this.setInputValue(this.getSuggestionName(this.props.suggestions[0]));
                } else {
                    this.setHighlightedIndex(highlightedIndex + 1);
                    this.setInputValue(
                        this.getSuggestionName(this.props.suggestions[highlightedIndex + 1])
                    );
                }
                break;
            default:
                break;
        }
    }

    selectHighlighted() {
        if (this.state.highlightedIndex !== -1) {
            this.selectSuggestion(this.getHighlighted());
        }
    }

    /* eslint-disable consistent-return */
    /**
     * @description
     * Takes a suggestion, sets the input value to the suggestions name,
     * and makes a callback
     *
     * @param  {object} suggestion
     */
    selectSuggestion(suggestion) {
        const callback = this.props.onChangeHandler;

        this.setInputValue(this.getSuggestionName(suggestion));
        this.setShowSuggestions(false);

        if (this.state.selectedSuggestion !== suggestion) {
            this.setSelectedSuggestion(suggestion);

            if (callback) callback(suggestion || null);
        }
    }

    scrollHighlightedItemIntoView() {
        const highlightedSuggestion = this.highlightedRef;
        if (!this.isMenuElementScrollable() || !highlightedSuggestion) return;

        const highlightedSuggestionRect = highlightedSuggestion.getBoundingClientRect();

        this.MenuElement.scrollTop =
            highlightedSuggestionRect.height * highlightedSuggestion.dataset.index;
    }

    render() {
        const {
            id,
            placeholder,
            inputLabel,
            renderItem,
            renderInput,
            renderListbox,
            suggestions,
            suggestionMapToNameAndKey,
            inputProps,
            listboxProps,
            // Avoid passing these handlers down as part of {...props}
            onClear,
            onEnter,
            onInputChange,
            onChangeHandler,
            onFocusHandler,
            onFocusoutHandler,
            ...props
        } = this.props;

        return (
            <ZIndexManager>
                <ComboboxStyled
                    aria-haspopup="listbox"
                    aria-expanded={this.state.showSuggestions}
                    aria-owns={id}
                    ref={this.attachRef}
                    role="combobox"
                    {...props}
                >
                    {renderInput(
                        this.focusTextInput,
                        this.selectHighlighted,
                        this.clear,
                        this.setShowSuggestions,
                        // input props
                        {
                            placeholder,
                            'aria-label': inputLabel,
                            'aria-activedescendant': `${id}-${this.state.highlightedIndex}`,
                            'aria-controls': id,
                            'aria-autocomplete': 'list',
                            inputRef: (el) => {
                                this.inputElement = el;
                            },
                            onChangeHandler: this.onInputChangeHandler,
                            onKeyDown: this.onInputkeydownHandler,
                            role: 'textbox',
                            value: this.state.inputValue,
                            ...inputProps,
                        }
                    )}
                    {renderListbox(
                        // listbox props
                        {
                            id: `${id}`,
                            isVisible: Boolean(suggestions.length) && this.state.showSuggestions,
                            highlightedIndex: this.state.highlightedIndex,
                            highlightedRef: (el) => {
                                this.highlightedRef = el;
                            },
                            menuRef: (el) => {
                                this.MenuElement = el;
                            },
                            onItemMouseEnter: this.onItemMouseEnter,
                            onSelectSuggestionHandler: this.onSelectSuggestionHandler,
                            role: 'listbox',
                            renderItem,
                            suggestions,
                            suggestionMapToNameAndKey,
                            ...listboxProps,
                        }
                    )}
                </ComboboxStyled>
            </ZIndexManager>
        );
    }
}

export default Combobox;
