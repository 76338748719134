import { getState } from './common';

//
// Data selectors
//
export const getBlogArea = (state, slug) => getState(state).content.blog.areas[slug];

export const getBlogTag = (state, areaSlug) => getState(state).content.blog.tags[areaSlug];

export const getBlogAreaPosts = (state, slug, searchType = 'default') =>
    getBlogArea(state, slug) &&
    getBlogArea(state, slug).posts &&
    getBlogArea(state, slug).posts[searchType];

export const getBlogAreaTrendingPosts = (state, slug, searchType = 'default') => {
    const area = getBlogArea(state, slug);

    if (!area || !area.trendingPosts) {
        return null;
    }

    return area.trendingPosts[searchType];
};

export const getBlogAreaRelatedPosts = (state, slug, searchType = 'default') => {
    const area = getBlogArea(state, slug);

    if (!area || !area.relatedPosts) {
        return null;
    }

    return area.relatedPosts[searchType];
};

export const getBlogCategory = (state, areaSlug, slug) => {
    const { categories } = getState(state).content.blog;

    return categories && categories[areaSlug] && categories[areaSlug][slug];
};

export const getBlogCategoryPosts = (state, areaSlug, slug, searchType = 'default') => {
    const category = getBlogCategory(state, areaSlug, slug);

    return category && category.posts[searchType];
};

export const getBlogCategorySubcategory = (state, areaSlug, categorySlug, slug) => {
    const { subcategories } = getState(state).content.blog;

    if (!areaSlug || !categorySlug || !slug) {
        return null;
    }

    return (
        subcategories &&
        subcategories[areaSlug] &&
        subcategories[areaSlug][categorySlug] &&
        subcategories[areaSlug][categorySlug][slug]
    );
};

export const getBlogCategorySubcategoryPosts = (state, areaSlug, categorySlug, slug) => {
    const subcategory = getBlogCategorySubcategory(state, areaSlug, categorySlug, slug);

    return subcategory && subcategory.posts;
};

export const getBlogPost = (state, areaSlug, categorySlug, slug) =>
    getState(state).content.blog.posts[areaSlug] &&
    getState(state).content.blog.posts[areaSlug][categorySlug] &&
    getState(state).content.blog.posts[areaSlug][categorySlug][slug];

/**
 * @param {Object} state
 * @param {string} author Author name
 * @return {Object | undefined}
 */
export const getAuthor = (state, author) => getState(state).content?.blog?.authors?.[author];

export const getSeoContent = (state, path, lang) =>
    getState(state).content.seoContent[path] && getState(state).content.seoContent[path][lang];

export const getPage = (state, slug) => getState(state).content.page[slug];
