import { settings } from 'api/actions';
import * as pageActions from 'containers/Page/actions';
import { PAGE_ID } from './constants';
import * as actions from './actions';

export const initialState = {
    assignment: null,
    isHydrated: false,
    isLoading: false,
    isRequestingFeedback: false,
    isSubmitting: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case pageActions.lazyload.ACTION: {
            if (action.pageId === PAGE_ID) {
                if (state.assignment === null) {
                    return {
                        ...state,
                        isLoading: true,
                    };
                }

                if (state.assignment.id !== action.params.assignmentId) {
                    return {
                        ...state,
                        assignment: null,
                        isLoading: true,
                    };
                }
            }

            return state;
        }
        case actions.hydrate.ACTION:
            return {
                ...state,
                assignment: action.payload,
                isHydrated: true,
                isLoading: false,
            };
        case actions.requestFeedbackRequest.ACTION:
            return {
                ...state,
                isRequestingFeedback: true,
            };
        case actions.requestFeedbackSuccess.ACTION:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    feedbackRequest: action.payload,
                },
                isRequestingFeedback: false,
            };
        case settings.owner.createPastAssignmentReview.SUCCESS:
            return {
                ...state,
                assignment: {
                    ...(state.assignment || {}),
                    review: action.data,
                },
            };
        case settings.owner.updatePastAssignmentFeedback.REQUEST:
            return {
                ...state,
                isSubmitting: true,
            };
        case settings.owner.updatePastAssignmentFeedback.SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                assignment: {
                    ...(state.assignment || {}),
                    feedback: action.data,
                },
            };
        default:
            return state;
    }
}
