// eslint-disable-next-line import/prefer-default-export
export const SHARED_BLOG_POST = {
    create(method, postTitle) {
        return {
            type: 'Shared Post',
            payload: {
                post_title: postTitle,
                method,
            },
        };
    },
};
