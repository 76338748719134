import { put, take, call, select } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import {
    SORT_FUNCTIONS,
    getSortFunction,
    Facet as searchFacets,
} from 'api/helpers/search/constants';
import { getAccountCurrentMembershipPlan } from 'api/selectors';
import { getActiveAndInactiveListings } from 'api/helpers/search/listings';
import { getSearchListings } from 'api/selectors/search';
import { getLocale } from 'shared/selectors';
import { error as errorAction, load as pageActionsLoad } from 'containers/Page/actions';
import { SEARCH_TYPE, SITS_RESULTS_PER_PAGE } from 'config/search';
import { track, Events } from 'utils/analytics';
import { base64decode } from 'utils/strings';
import { isMember, isExpired, isSitterOnly } from 'utils/account';
import { flattenObject } from 'utils/objects';
import { createListingsSearchQuery } from 'utils/searchListings';
import { isFeatureEnabled, features } from 'components/Feature';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
    userTypes,
    VariationTypes,
} from 'containers/ExperimentalFeature';
import { fetchListingsFacets } from 'pages/search/components/DynamicFacets/actions';
import { QUICK_SEARCH_EVENTS } from 'pages/search/FindAHouseSitWizard/components/WizardLocation/components/QuickSearch/QuickSearch.constants';
import { getLocalSitsABTestVariant, getPageInfo } from '../selectors';
import {
    getFacetsBelowPlace,
    getGeoHierarchyParamsAndLocationFilters,
    getSearchLocationData,
    isAdminXFeatureCode,
} from '../../helpers';
import { getSavedSearchName } from '../../SavedSearchRedirect/selectors';
import {
    SEARCH_TYPE_RESULTS,
    SEARCH_TYPE_MAP,
    SEARCH_TYPE_MAP_HIDDEN_CLUSTERS,
    SEARCH_TYPE_PLACE,
    SEARCH_TYPE_TOTAL_AND_GEOFACETS,
    SEARCH_TYPE_EXTENDED_RADIUS_RESULTS,
    PAGE_ID,
    extendedGeoPointDistance,
} from '../SearchListings.constants';
import * as actions from '../actions';
import doSearchMap from './doSearchMap';

// These are the areas (to 2 decimal places) that correspond to the zoom levels
// We used London, and zoomed in, as a starting location.
// With the zoom levels that were actually used to calculate the geohash
// we have rounded down to an approx number
const zoomLevelAreas = {
    maxZoom: 0.15,
    zoom1: 0.61,
    zoom2: 1.5,
    zoom3: 7,
    zoom4: 30,
    zoom5: 157.85,
    zoom6: 500, // whole of uk view
    zoom7: 2500, // europe view
    zoom8: 8000,
    zoom9: 32500.1,
    minZoom: 35721.47,
};

const calculateOptimumGeohashFacet = ({ north, south, east, west }) => {
    const geohashPrecision = {
        GEOHASH2: zoomLevelAreas.zoom8,
        GEOHASH3: zoomLevelAreas.zoom6,
        GEOHASH4: zoomLevelAreas.zoom4,
    };

    // Gets the area from long and lat
    // we do not know if east or west is the larger number as
    // the user can scroll the map continuously in either direction
    let length;
    let height;

    if (east >= west) {
        length = east - west;
    } else {
        length = west - east;
    }

    if (north >= south) {
        height = north - south;
    } else {
        height = south - north;
    }

    const area = length * height;

    if (area > geohashPrecision.GEOHASH2) {
        return searchFacets.GEOHASH2;
    }
    if (area > geohashPrecision.GEOHASH3) {
        return searchFacets.GEOHASH3;
    }
    if (area > geohashPrecision.GEOHASH4) {
        return searchFacets.GEOHASH4;
    }

    return searchFacets.GEOHASH5;
};

const hasUserAppliedFilters = (query) => {
    // if we don't have a query, or the query doesn't have filters
    // then this query cant be filtered
    if (!query || !query.filters) return false;

    const nonUserFilters = new Set([
        'activeMembership',
        'assignments_reviewing',
        'assignments_confirmed',
    ]);
    const flattenedFilters = flattenObject(query.filters);
    const filtersSet = new Set(Object.keys(flattenedFilters));

    const difference = new Set([...filtersSet].filter((x) => !nonUserFilters.has(x)));

    // durationInDays.minimum can equal 1 (which is the same as not filtered)
    // but can still exist in the query
    // So we special case this to check if it's the only applied filter and equals 1 then say there are no filters
    if (
        difference.size === 1 &&
        difference.has('assignments_durationInDays_minimum') &&
        flattenedFilters.assignments_durationInDays_minimum === 1
    ) {
        return false;
    }

    // if the difference between the allowed filters and the actual filters is
    // anything but 0 then we have user defined filters applied
    return difference.size !== 0;
};

// Entry point for load and preload
function* doSearch({ search, type, params }, searchType = SEARCH_TYPE_RESULTS) {
    // Check the encoded query
    let query;
    const { q, searchMethod, searchType: searchTypeFromQuery } = search;
    if (q) {
        try {
            query = JSON.parse(base64decode(q));
        } catch {
            // If the query is malformed then return a 400 bad request
            yield put(errorAction.create(PAGE_ID, 400));
            return false;
        }
    }

    const { startingAfter } = query ?? {};

    const { countryISOCode, latitude, longitude } = yield select(getLocale);
    const membership = yield select(getAccountCurrentMembershipPlan);
    const { geoHierarchyParams, locationFilters } = getGeoHierarchyParamsAndLocationFilters({
        query,
        params,
    });

    // We get a place to populate the location filter and the title for the results and for adding the place
    // to the analytics call down below.
    let place;
    if (Object.keys(locationFilters).length) {
        const response = yield call(getSearchLocationData, SEARCH_TYPE_PLACE, locationFilters);
        if (response.error) {
            yield put(errorAction.create(PAGE_ID, response.error));
            return false;
        }

        place = response.place;
        yield put(actions.placeLoaded.create(place));
    } else if (searchType !== SEARCH_TYPE_MAP_HIDDEN_CLUSTERS) {
        // No location so clear place
        yield put(actions.placeLoaded.create());
    }

    const hasGeoHierarchyParams = Object.keys(geoHierarchyParams).length;

    // If this is a landing page e.g. had geo hierarchy add location data to filters
    let filters = {};
    if (hasGeoHierarchyParams && place) {
        // Set the geohierarchy filters from the params
        filters = {
            geoHierarchy: geoHierarchyParams,
            locationData: place,
        };
    }

    let searchQuery;
    let defaultSort = [
        {
            function: getSortFunction(SORT_FUNCTIONS.SITS_WITH_DATES_FIRST),
        },
    ];

    // check if the popular new sits i.e. pool party flag is enabled
    // this was introduced to see how it would play with pausing assignments when they have more than 5 applicants
    const isPopularNewSitsEnabled = yield call(isFeatureEnabled, {
        name: features.POPULAR_NEW_SITS,
    });

    // only non-members with non-filtered queries
    if (!isMember(membership) && !hasUserAppliedFilters(query)) {
        if (isPopularNewSitsEnabled) {
            defaultSort = [
                {
                    function: getSortFunction(
                        !isExpired(membership)
                            ? SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED
                            : SORT_FUNCTIONS.POPULAR_NEW_SITS,
                        {
                            locationBias: {
                                countryCode: countryISOCode,
                            },
                        }
                    ),
                },
            ];
        } else {
            defaultSort = [
                {
                    function: getSortFunction(
                        !isExpired(membership)
                            ? SORT_FUNCTIONS.LOCAL_SITS_WITH_PAUSED
                            : SORT_FUNCTIONS.LOCAL_SITS,
                        {
                            locationBias: {
                                countryCode: countryISOCode,
                            },
                        }
                    ),
                },
            ];
        }
    }

    const queryFiltersGeoPoint = query ? query.filters?.geoPoint : null;
    const queryFiltersGeoHierarchy = query ? query.filters?.geoHierarchy : null;
    // THIS LOGIC IS TO ORDER SITS BY LOW APPLICANTS BY DEFAULT &
    // TO INCREASE THE RADIUS ON CITY SEARCHES & ALLOW TO ORDER BY DSTANCE
    if (isMember(membership)) {
        const localSitsABTestVariant = yield select(getLocalSitsABTestVariant);

        let latLong = {};
        let sortBy = 'recommended';

        // if there is a sortBy option in the filters we set it here
        if (query && query.filters && query.filters.sortBy) {
            // eslint-disable-next-line prefer-destructuring
            sortBy = query.filters.sortBy[0];
        }
        // queryFiltersGeoPoint indicates that the search is at city level
        if (queryFiltersGeoPoint) {
            yield put(actions.isGeoPoint.create(true));
            const geoPointLat = queryFiltersGeoPoint.latitude;
            const geoPointLng = queryFiltersGeoPoint.longitude;
            latLong = {
                latitude: geoPointLat,
                longitude: geoPointLng,
            };
            filters = {
                ...filters,
                geoPoint: {
                    ...latLong,
                    distance: extendedGeoPointDistance, // this increases the distance to 70km which we only want to do at city level
                },
            };
            if (sortBy === 'distance') {
                defaultSort = [
                    {
                        function: getSortFunction(
                            SORT_FUNCTIONS.CLOSE_SITS_WITH_DATES_FIRST,
                            latLong
                        ),
                    },
                ];
            }
        } else {
            // Only want to reset geoPoint if we're doing main search (not map search)
            // eslint-disable-next-line no-lonely-if
            if (searchType === SEARCH_TYPE_RESULTS) {
                yield put(actions.isGeoPoint.create(false));
            }
        }
        if (sortBy === 'recommended') {
            if (localSitsABTestVariant === VariationTypes.VARIATION1) {
                latLong = {
                    latitude,
                    longitude,
                };
                defaultSort = [
                    {
                        function: getSortFunction(
                            SORT_FUNCTIONS.CLOSE_SITS_WITH_DATES_FIRST,
                            latLong
                        ),
                    },
                ];
            } else {
                defaultSort = [
                    {
                        function: getSortFunction(SORT_FUNCTIONS.PRIORITISE_LOW_APPLICANTS, {
                            maxApplicantsPerAssignment: 2,
                            daysSincePublished: 2,
                            ...latLong,
                        }),
                    },
                ];
            }
        }
        if (sortBy === 'newest') {
            defaultSort = [
                {
                    function: getSortFunction(SORT_FUNCTIONS.SITS_WITH_DATES_FIRST, latLong),
                },
            ];
        }

        if (sortBy === 'start_date') {
            defaultSort = [
                {
                    function: getSortFunction(SORT_FUNCTIONS.SIT_START_DATE),
                },
            ];
        }
    }

    // Use the POPULAR_NEW_SITS sort function for all non-paid user types
    // when a search is performed above the city level
    // Use the new POPULAR_NEW_SITS_CITY_LEVEL for all non-paid user types
    // when a search is performed at or below the city level
    // Override the default 40km radius with a 70km radius for all non-paid user types
    // when a search is performed at or below the city level

    if (!isMember(membership)) {
        if (queryFiltersGeoPoint) {
            const geoPointLat = queryFiltersGeoPoint.latitude;
            const geoPointLng = queryFiltersGeoPoint.longitude;
            defaultSort = [
                {
                    function: getSortFunction(
                        !isExpired(membership)
                            ? SORT_FUNCTIONS.POPULAR_NEW_SITS_CITY_LEVEL_WITH_PAUSED
                            : SORT_FUNCTIONS.POPULAR_NEW_SITS_CITY_LEVEL,
                        {
                            latitude: geoPointLat,
                            longitude: geoPointLng,
                        }
                    ),
                },
            ];
            filters = {
                ...filters,
                geoPoint: {
                    latitude: geoPointLat,
                    longitude: geoPointLng,
                    distance: extendedGeoPointDistance,
                },
            };
        } else if (queryFiltersGeoHierarchy) {
            defaultSort = [
                {
                    function: getSortFunction(
                        !isExpired(membership)
                            ? SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED
                            : SORT_FUNCTIONS.POPULAR_NEW_SITS
                    ),
                },
            ];
        }
    }

    const dateFiltersApplied =
        query?.filters?.assignments?.dateFrom ||
        query?.filters?.assignments?.dateTo ||
        filters?.assignments?.dateFrom ||
        filters?.assignments?.dateTo;

    let addPausedListingsToDisplayedSearchResults = false;
    let addPausedListingsToSearchResultsTotal = false;
    if (!isMember(membership) && !isExpired(membership)) {
        addPausedListingsToDisplayedSearchResults = true;
        addPausedListingsToSearchResultsTotal = true;
    } else if (isMember(membership) && !dateFiltersApplied) {
        addPausedListingsToDisplayedSearchResults = true;
    }

    let addConfirmedListingsToSearch = false;
    if (isMember(membership) && !dateFiltersApplied) {
        addConfirmedListingsToSearch = true;
    }

    // If there is an encoded query use that, if not create a new query
    if (!query) {
        const searchFilters = {
            ...filters,
            sort: defaultSort,
        };

        // Create a new search query
        searchQuery = createListingsSearchQuery({
            searchPastAssignments: false,
            filters: searchFilters,
            searchPastAndFutureAssignments: true,
            addPausedListingsToSearch: addPausedListingsToDisplayedSearchResults,
            addConfirmedListingsToSearch,
        });
    } else {
        let resultsPerPage;

        // If it's a search for results then always show the sits_results_per_page (to match the grid)
        if (searchType === SEARCH_TYPE_RESULTS) {
            resultsPerPage = SITS_RESULTS_PER_PAGE;
        }
        // For other types of search, use what's been passed
        else {
            resultsPerPage = query.resultsPerPage;
        }

        const searchFilters = {
            // We used to use the sort from the url/existing query (`sort: query.sort`) but we are moving to
            // always deciding sort based on context/user status/ab test etc...
            sort: defaultSort,
            ...query.filters,
            ...filters,
        };
        try {
            // Create a new query from existing filters
            searchQuery = createListingsSearchQuery({
                searchPastAssignments: false,
                page: query.page,
                filters: searchFilters,
                perPage: resultsPerPage,
                searchPastAndFutureAssignments: true,
                addPausedListingsToSearch: addPausedListingsToDisplayedSearchResults,
                addConfirmedListingsToSearch,
            });
        } catch {
            // If the query contains any invalid data then return a 400 bad request
            yield put(errorAction.create(PAGE_ID, 400));
            return false;
        }
    }

    // A/B - END
    if (searchType === SEARCH_TYPE_MAP_HIDDEN_CLUSTERS) return true;

    // Always add bounds to query. Also add the facets for lower levels which provide the data for the SEO links
    let { searchLevel } = getFacetsBelowPlace(place);

    // Get plain old js object to stringify
    query = searchQuery.getRequestData();

    const { variation: useInfiniteScrollTestVariation, enabled: useInfiniteScrollTestEnabled } =
        yield select(getExperimentalFeatureVariationSelector, {
            experiment: experiments.USE_INFINITE_SCROLL_LISTINGS,
        });

    const isUseInfiniteScrollVariation =
        useInfiniteScrollTestVariation === VariationTypes.VARIATION1;

    const useInfiniteScroll =
        useInfiniteScrollTestEnabled &&
        isUseInfiniteScrollVariation &&
        isMember(membership) &&
        isSitterOnly(membership);

    // Load the listings and facets!
    yield put(
        apiActions.search.loadListings({
            forceReload: true,
            filters: {
                query: JSON.stringify(query),
            },
            data: {
                searchType,
                rawQuery: query,
                searchLevel,
                ...(startingAfter && useInfiniteScroll ? { startingAfter } : {}),
            },
        })
    );
    yield put(
        fetchListingsFacets.create({
            place,
            filters: {
                ...query.filters,
            },
            searchLevel,
        })
    );

    // Wait until we've got a response
    const { data, requestData, status, statusCode } = yield take(
        (res) =>
            res.type === settings.search.loadListings.DONE &&
            res.requestData.searchType === SEARCH_TYPE_RESULTS
    );

    // Did an error occur?
    if (statusCode === 400) {
        // If the API gives us a 400 then it's likely the geonames data is broken for this location.
        // We decided in gh3345 that the best thing to do is 404
        yield put(errorAction.create(PAGE_ID, 404));
        return false;
    }
    if (status !== settings.search.loadListings.SUCCESS) {
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    // this is a test to add a section between the main results and the without dates results sections
    // it will only appear on the first page and won't add additional map pins
    const { variation: broadenSitSearchVariation } = yield select(
        getExperimentalFeatureVariationSelector,
        {
            experiment: experiments.BROADEN_SIT_SEARCH_RADIUS,
            excludeCombo: [userTypes.PaidUser, userTypes.ExpiredUser],
        }
    );
    const isBroadenSitSearchRadiusVariation =
        broadenSitSearchVariation === VariationTypes.VARIATION1;

    if (isBroadenSitSearchRadiusVariation) {
        const isMemberOrExpired = isMember(membership) || isExpired(membership);
        // didn't want to do another api call to get the active assignments
        // and so used a helper to get them instead
        const searchListingResults = yield select(getSearchListings, SEARCH_TYPE_RESULTS);

        const pageInfo = yield select(getPageInfo);
        // "!isMemberOrExpired" is passed down since we don't show paused listings for paid or expired users
        // and would get the accurate number of listings shown for select user types
        const [activeListings] = getActiveAndInactiveListings(
            searchListingResults,
            !isMemberOrExpired
        );
        const isRegion = queryFiltersGeoHierarchy && isAdminXFeatureCode(place?.featureCode);

        // only want the api call to happen when:
        //  - on the first page of results (don't want to make unecessary calls when paginating)
        //  - the main results section only having less than 12 listings
        //  - and a city/region has been searched for using the search bar
        if (
            pageInfo?.currentPage === 1 &&
            activeListings.length < 12 &&
            (queryFiltersGeoPoint || isRegion)
        ) {
            let extendedRadiusFilters = {
                ...query.filters,
                ...filters,
                sort: defaultSort,
            };

            if (queryFiltersGeoPoint) {
                extendedRadiusFilters = {
                    ...extendedRadiusFilters,
                    geoPoint: {
                        latitude: queryFiltersGeoPoint.latitude,
                        longitude: queryFiltersGeoPoint.longitude,
                        distance: '150km',
                    },
                };
            } else if (isRegion) {
                // when trying to get nearby results around the region
                // it would use the lat and lon which is approximately in the centre of the searched region
                // this will occasionally include listings in another country that is caught by the radius (which is fine)
                extendedRadiusFilters = {
                    ...extendedRadiusFilters,
                    geoPoint: {
                        latitude: place.location.lat,
                        longitude: place.location.lon,
                        distance: '500km',
                    },
                    // need to use the city level sort since we are using geoPoint for region searches
                    // otherwise it won't correctly sort the results by proximity
                    // also don't need to do the isExpired check to either use POPULAR_NEW_SITS_CITY_LEVEL_WITH_PAUSED or POPULAR_NEW_SITS_CITY_LEVEL
                    // since the test variation check already excludes expired users
                    // but something to keep in mind if decommissioned to variation1 once the test is done
                    sort: [
                        {
                            function: getSortFunction(
                                SORT_FUNCTIONS.POPULAR_NEW_SITS_CITY_LEVEL_WITH_PAUSED,
                                {
                                    latitude: place.location.lat,
                                    longitude: place.location.lon,
                                }
                            ),
                        },
                    ],
                };
            }
            // TODO: find a way to modify the query function to limit the amount of results being returned
            // or at least make them appear only on the first page
            const extendedRadius = createListingsSearchQuery({
                searchPastAssignments: false,
                filters: extendedRadiusFilters,
                perPage: 12,
                searchPastAndFutureAssignments: false,
                addPausedListingsToSearch: addPausedListingsToSearchResultsTotal,
                addConfirmedListingsToSearch,
            });
            const extendedRadiusQuery = extendedRadius.getRequestData();
            // do a call to populate 'search-listings-extended-radius-results'
            // in the api, it will in turn create another query object 'search-listings-extended-radius-filtered-results'
            // that contains the results not appearing in the main section but are still in the radius
            yield put(
                apiActions.search.loadListings({
                    forceReload: true,
                    filters: {
                        query: JSON.stringify(extendedRadiusQuery),
                    },
                    data: {
                        searchType: SEARCH_TYPE_EXTENDED_RADIUS_RESULTS,
                        rawQuery: extendedRadiusQuery,
                        searchLevel,
                    },
                })
            );
        }
    }

    let totalResults = null;
    // Now load map clusters
    if (requestData.searchType !== SEARCH_TYPE_MAP_HIDDEN_CLUSTERS) {
        // the search request to get geohash and actual totals.
        const listingsWithActiveDatesSearchQuery = createListingsSearchQuery({
            searchPastAssignments: false,
            filters: query.filters,
            perPage: 0,
            searchPastAndFutureAssignments: false,
            addPausedListingsToSearch: addPausedListingsToSearchResultsTotal,
            addConfirmedListingsToSearch: false, // we always want to exclude confirmed sits from total.
        });
        ({ searchLevel } = getFacetsBelowPlace(place));
        listingsWithActiveDatesSearchQuery.facet(searchFacets.GEOBOUNDS);
        const listingsWithActiveDatesQuery = listingsWithActiveDatesSearchQuery.getRequestData();

        yield put(
            apiActions.search.loadListings({
                forceReload: true,
                filters: {
                    query: JSON.stringify(listingsWithActiveDatesQuery),
                },
                data: {
                    searchType: SEARCH_TYPE_TOTAL_AND_GEOFACETS,
                    rawQuery: listingsWithActiveDatesQuery,
                    searchLevel,
                },
            })
        );

        // Wait until we've got a response
        const { data: mapData } = yield take(
            (res) =>
                res.type === settings.search.loadListings.DONE &&
                res.requestData.searchType === SEARCH_TYPE_TOTAL_AND_GEOFACETS
        );

        if (!mapData || !Object.prototype.hasOwnProperty.call(mapData, 'total')) {
            return false;
        }

        totalResults = mapData.total;

        const geohashFacet = calculateOptimumGeohashFacet(mapData.facets.geoBounds);
        const searchMapStatus = yield call(doSearchMap, {
            filters: query.filters,
            facets: [geohashFacet],
            addPausedListingsToSearch: addPausedListingsToSearchResultsTotal,
        });
        if (!searchMapStatus) {
            return false;
        }
        // Mark the map clusters as loaded
        yield put(actions.mapClustersLoaded.create(SEARCH_TYPE_MAP, geohashFacet));
    }

    if (type === pageActionsLoad.ACTION) {
        // Log analytics on load

        const searchFilters = {
            ...query.filters,
            seoHierarcy: hasGeoHierarchyParams,
            place,
            sort: query,
        };

        // So that analytics for listing filtered is called when sortby is changed.
        const { christmasSits } = search;
        const christmasSitsChosen = !!christmasSits;
        const savedSearchName = yield select(getSavedSearchName);
        let searchTypeTracking;
        if (searchTypeFromQuery && QUICK_SEARCH_EVENTS[searchTypeFromQuery]) {
            searchTypeTracking = QUICK_SEARCH_EVENTS[searchTypeFromQuery];
        }
        track(
            Events.SEARCH_FILTERS.create({
                category: SEARCH_TYPE.Listing,
                query: searchFilters,
                items: (data && data.results) || [],
                christmasSitsChosen,
                searchOptions: {
                    possibleResults: data.total,
                    totalResults,
                    searchMethod,
                    savedSearchName,
                    page: query.page,
                },
                searchType: searchTypeTracking,
            })
        );
    }

    // Clear pins if we have no results.
    if (data.total === 0) {
        yield put(actions.mapClustersLoaded.create(SEARCH_TYPE_RESULTS, null));
    }

    return true;
}

// Search for the given filters.
// fetch clusters for those set of results.
// If you've got less than 9 results.
// Go and fetch completed sits without current dates.
export { doSearch as default, calculateOptimumGeohashFacet };
