import styled from 'styled-components';

import { IconArrowRight } from 'components/Icon';

import { H2, P } from 'typography';
import PageSection from 'components/layouts/PageSection';

const WrapperStyled = styled.div`
    margin: 0 auto;
    max-width: 976px;
    margin-bottom: ${({ theme }) => theme.spacing[6]};

    margin-top: ${({ theme }) => theme.spacing[6]};
    @media screen and (min-width: ${({ theme }) => theme.screenSize.wide}px) {
        margin-top: ${({ theme }) => theme.spacing[12]};
    }
`;

const PageSectionStyled = styled(PageSection)`
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: flex;
    }
`;

const ContainerStyled = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        flex: 0 0 calc(50% - ${({ theme }) => theme.spacing[1.5]});
    }
`;

const TitleStyled = styled(H2)`
    margin-top: 0;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.wide}px) {
        ${({ theme }) => theme.typography.h2Large};
    }
`;

const BodyStyled = styled(P)`
    @media screen and (min-width: ${({ theme }) => theme.screenSize.wide}px) {
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

const UlStyled = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 0 ${({ theme }) => theme.spacing[3]} 0;
`;

const LiStyled = styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.wide}px) {
        margin-bottom: ${({ theme }) => theme.spacing[3]};
    }
`;

const IconArrowRightStyled = styled(IconArrowRight)`
    margin-left: ${({ theme }) => theme.spacing[1.5]};
    font-size: 1rem;

    & > svg {
        fill: ${({ theme }) => theme.palette.primaryRegular};
    }
`;

const ImageWrapperStyled = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        flex: 0 0 calc(50% - ${({ theme }) => theme.spacing[1.5]});
        margin-left: ${({ theme }) => theme.spacing[3]};
    }
`;

const ImageStyled = styled.img`
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.regular};
`;

export {
    WrapperStyled,
    PageSectionStyled,
    ContainerStyled,
    TitleStyled,
    BodyStyled,
    UlStyled,
    LiStyled,
    IconArrowRightStyled,
    ImageWrapperStyled,
    ImageStyled,
};
