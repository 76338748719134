/* eslint-disable import/prefer-default-export */
import { Errors } from 'types/validation';
import { validateEmail, validateName, validatePassword } from 'utils/validation';

export const validateRegister = (values) => {
    const errors = {};
    const { email, password, privacyPolicy, name } = values;

    const emailError = validateEmail(email);
    if (emailError) {
        errors.email = emailError;
    }

    const passwordError = validatePassword(password);
    if (passwordError) {
        errors.password = passwordError;
    }

    const nameError = validateName(name);
    if (nameError) {
        errors.name = nameError;
    }

    if (!privacyPolicy) {
        errors.privacyPolicy = Errors.REQUIRED;
    }

    return errors;
};
