import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ size, theme }) => theme.fontSizes[size]};
    width: 1em;
    height: 1em;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    svg {
        transition-property: fill;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
        fill: ${({ theme, palette }) =>
            theme.palette[palette] ? theme.palette[palette] : theme.palette.icon};
        width: 100%;
        height: 100%;

        ${({ currentColorFill }) =>
            currentColorFill &&
            `
                fill: currentColor;
            `}
    }
`;

export default (Component) =>
    ({ title, currentColorFill = false, ...rest }) =>
        (
            <IconWrapper currentColorFill={currentColorFill} {...rest}>
                <Component title={title} />
            </IconWrapper>
        );
