import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
    preload as pageActionsPreload,
    load as pageActionsLoad,
    preloaded as pageActionsPreloaded,
    loaded as pageActionsLoaded,
} from 'containers/Page/actions';
import { loadEmergencyContact, loadSafetyContact } from 'containers/SafetySecurityPage/sagas';
import { PAGE_ID } from './Hub.constants';

export function* loadHubData() {
    yield all([call(loadEmergencyContact), call(loadSafetyContact)]);
}

export function* preload(action) {
    yield call(loadHubData, action.params);
    yield put(pageActionsPreloaded.create(PAGE_ID));
}

export function* load(action) {
    yield call(loadHubData, action.params);
    yield put(pageActionsLoaded.create(PAGE_ID));
}

export default function* sagas() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
    ]);
}
