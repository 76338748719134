/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const Apple2Icon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 24"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="M19.28 18.424a12.51 12.51 0 0 1-1.236 2.223c-.65.927-1.183 1.569-1.593 1.925-.636.585-1.317.885-2.047.902-.524 0-1.156-.15-1.891-.452-.738-.3-1.416-.45-2.036-.45-.65 0-1.348.15-2.094.45-.747.303-1.348.46-1.809.476-.7.03-1.397-.278-2.093-.926-.444-.387-1-1.052-1.666-1.993-.714-1.005-1.302-2.17-1.762-3.5-.493-1.435-.74-2.825-.74-4.17 0-1.542.333-2.871 1-3.985a5.867 5.867 0 0 1 2.095-2.12 5.635 5.635 0 0 1 2.832-.798c.556 0 1.285.172 2.19.51.904.339 1.484.51 1.738.51.19 0 .835-.2 1.927-.601 1.032-.372 1.904-.526 2.618-.465 1.935.156 3.39.919 4.356 2.293-1.73 1.049-2.587 2.517-2.57 4.401.016 1.468.548 2.69 1.595 3.659.474.45 1.003.798 1.593 1.045-.128.37-.263.725-.406 1.066ZM14.843.96c0 1.15-.42 2.224-1.257 3.218-1.011 1.182-2.234 1.865-3.56 1.757A3.58 3.58 0 0 1 10 5.5c0-1.105.48-2.287 1.334-3.253.426-.489.968-.896 1.626-1.22.655-.32 1.276-.497 1.86-.527.016.154.023.308.023.46Z"
        />
    </svg>
);
const ForwardRef = forwardRef(Apple2Icon);
export default ForwardRef;
