import * as React from 'react';
import { connect } from 'react-redux';
import { MembershipType } from 'api/types';
import { useTranslation } from 'react-i18next';

import * as actions from './actions';
import { getRegisterErrorMessage, getIsSubmitting } from './selectors';

import {
    signupOrigin as signupVariants,
    registerMethod,
    errorMessages,
} from './Register.constants';

const Register = ({
    children,
    initialAccountData = {},
    clearError,
    createAccount,
    membershipType,
    membershipPlan,
    registerErrorMessage,
    method,
    signupOrigin,
    isSubmitting,
    openMethod,
}) => {
    const { t } = useTranslation();
    const handleCreateAccount = (accountDetails) => {
        createAccount(
            {
                ...initialAccountData,
                ...accountDetails,
            },
            membershipType,
            method,
            signupOrigin,
            membershipPlan,
            openMethod
        );
    };

    const handleClearErrorMessage = () => {
        if (registerErrorMessage.error) {
            clearError();
        }
    };

    const getErrorMessage = (error) => {
        const { error: errorDetails } = error;
        let errorMessage = null;
        // If it's a 500 then there's a special 500 error message
        if (errorDetails && errorDetails.statusCode === 500) {
            errorMessage = t('containers_forms_Register_500Error');
        }
        // Otherwise we try and get the error message from the first (hopefully only) key
        else if (errorDetails && Object.keys(errorDetails).length > 0) {
            // Error should be an array, so grab first value
            const errorMessageFromDetails =
                Array.isArray(errorDetails[Object.keys(errorDetails)[0]]) &&
                errorDetails[Object.keys(errorDetails)[0]].length &&
                errorDetails[Object.keys(errorDetails)[0]][0];

            switch (errorMessageFromDetails) {
                // If it's an invalid email (failed email validation in NeverBounce)
                case errorMessages.INVALID_EMAIL:
                    errorMessage = t('containers_forms_Register_invalidEmailError');
                    break;
                case errorMessages.ACCOUNT_ALREADY_CREATED_FACEBOOK:
                    errorMessage = t('containers_forms_Register_alreadyCreatedUsingFacebook');
                    break;
                case errorMessages.ACCOUNT_ALREADY_CREATED_EMAIL:
                    errorMessage = t('containers_forms_Register_alreadyCreatedUsingEmail');
                    break;
                case errorMessages.ACCOUNT_ALREADY_CREATED_GOOGLE:
                    errorMessage = t('containers_forms_Register_alreadyCreatedUsingGoogle');
                    break;
                case errorMessages.AUTH_GOOGLE_INVALID_ACCESS_TOKEN:
                    errorMessage = t('containers_forms_Register_authGoogleInvalidAccessToken');
                    break;
                case errorMessages.AUTH_GOOGLE_EMAIL_MISSING:
                    errorMessage = t('containers_forms_Register_googleEmailPermissionsError');
                    break;
                case errorMessages.LOGIN_FAILED:
                    errorMessage = t('containers_forms_Register_loginFailed');
                    break;
                default:
                    errorMessage = t('containers_forms_Register_defaultEmailError');
            }
        }
        return errorMessage;
    };

    return children({
        clearError: handleClearErrorMessage,
        createAccount: handleCreateAccount,
        errorMessage: getErrorMessage(registerErrorMessage),
        membershipType,
        isSubmitting,
    });
};

Register.SignupOrigin = signupVariants;
Register.Method = registerMethod;

const mapStateToProps = (state) => ({
    registerErrorMessage: getRegisterErrorMessage(state),
    isSubmitting: getIsSubmitting(state),
});

const mapDispatchToProps = (dispatch) => ({
    createAccount: (data, membershipType, method, signupOrigin, membershipPlan, openMethod) => {
        dispatch(
            actions.createAccount.create(
                data,
                membershipType,
                method,
                signupOrigin,
                membershipPlan,
                openMethod
            )
        );
    },
    clearError: () => {
        dispatch(actions.showRegisterErrorMessage.create({}));
    },
});

export { Register };
export default connect(mapStateToProps, mapDispatchToProps)(Register);
