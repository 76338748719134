import styled, { css } from 'styled-components';

const variants = {
    hint: css`
        color: ${({ theme }) => theme.palette.grey[500]};
    `,
    success: css`
        color: ${({ theme }) => theme.palette.primaryRegular};
    `,
    danger: css`
        color: ${({ theme }) => theme.palette.danger};
    `,
};

export const MessageStyled = styled.p`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing[1]};
    ${({ theme }) => theme.typography.smallText};

    ${({ variant }) => typeof variant === 'string' && variants[variant]};
`;

export const MessageIconStyled = styled.span`
    flex-grow: 0;
    margin-right: ${({ theme }) => theme.spacing[1]};

    & svg,
    & path {
        fill: currentColor;
    }
`;
