import React, { Component } from 'react';
import { IconClose, IconSearch } from 'components/Icon';
import { Variant } from '../Combobox.constants';
import { ClearButtonIconStyled, InputIconStyled, SearchIconStyled } from './ComboboxInput.style';

class ComboboxInput extends Component {
    state = {
        hasFocus: false,
    };

    onFocus = () => {
        this.setState(() => ({
            hasFocus: true,
        }));
        if (this.props.onFocusHandler) this.props.onFocusHandler();
    };

    onBlur = () => {
        this.setState(() => ({
            hasFocus: false,
        }));
    };

    renderSearchIcon = (focusTextInput) => {
        const { searchIcon } = this.props;
        return (
            <SearchIconStyled
                as={searchIcon}
                onClick={focusTextInput}
                inputHasFocus={this.state.hasFocus}
            />
        );
    };

    renderClearIcon = (inputValue, clear) => (
        <ClearButtonIconStyled
            onClick={clear}
            icon={IconClose}
            inputHasFocus={this.state.hasFocus}
            isVisible={inputValue !== ''}
        />
    );

    render() {
        const { hasFocus } = this.state;
        const { focusTextInput, searchIcon, clear, ...inputProps } = this.props;
        const inputValue = inputProps.value;
        const hasSearchIcon = !!searchIcon;

        return (
            <InputIconStyled
                {...inputProps}
                onFocusHandler={this.onFocus}
                onBlurHandler={this.onBlur}
                inputHasFocus={hasFocus}
                iconLeft={hasSearchIcon ? this.renderSearchIcon(focusTextInput) : null}
                iconRight={this.renderClearIcon(inputValue, clear)}
                renderBorder={false}
            />
        );
    }
}

ComboboxInput.defaultProps = {
    searchIcon: IconSearch,
    withShadow: true,
    variant: Variant.Default,
};

export default ComboboxInput;
