const features = {
    DASHBOARD_SECTIONS: 'web_dashboard_sections',
    TRUSTPILOT_REVIEWS: 'web_feature_trustpilot_reviews',
    SEARCH_FILTER_FAILED: 'web_feature_search_filter_failed',
    COMMISSION_JUNCTION: 'web_cj',
    EVIDENT_CHECKS: 'web_feature_evident_checks',
    REMOVE_COPY_BASIC_SITTER_COMBINED_PLAN: 'web_basic_sitter_combined_features',
    MANDATORY_VERIFICATION: 'web_app_feature_mx_mandatory_verification',
    SUPPORT_WIDGET: 'web_feature_support_widget',
    INTEGRATION_TIKTOK: 'web_tiktok',
    FACEBOOK_SIGNUP: 'web_feature_facebook_signup',
    FACEBOOK_LOGIN: 'web_feature_facebook_login',
    UK_SITTER_MBG: 'web_feature_uk_sitter_mbg',
    POPULAR_NEW_SITS: 'web_feature_popular_new_sits',
    TRANSATIONAL_SCALER_V3: 'web_feature_transactional_scalers_v3',
    CHRISTMAS_SITS: 'mx_feature_christmas_sits',
    REVIEW_OVERALL_SCORE_RATING: 'delex_review_overall_star_rating',
    PAYMENT_HOLIDAY_FAKEDOOR: 'web_feature_payment_holiday',
    BLIND_REVIEWS: 'blind_reviews',
    NEW_FAVOURITES: 'dm_feature_new_favourites',
    PREVENT_OVERLAPPING_SITS: 'cc_feature_prevent_overlapping_sits',
    AWS_WAF: 'web_feature_aws_waf',
    FEEDBACK_OWNER_CATEGORIES: 'feedback_owner_categories',
    TWO_STEP_REGWALL: 'delex_two_step_regwall',
    HIDE_FAVOURITES: 'dm_web_plus_app_exp_hide_favourites',
    SEND_BILLING_INFORMATION_CHECKOUT: 'cm_feature_billing_info_checkout',
    UPGRADE_LANDING_PAGE: 'web_cm_upgrade_landing_page',
    RAF_WEBVIEW_NAVIGATION: 'cc_feature_RAF_webview_navigation',
    RAF_FREE_MONTHS_ANALYTICS: 'cc_feature_raf_free_months_analytics',
};

export default features;
