import { put, select } from 'redux-saga/effects';

import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { getIsMembershipActive, isOwnerOnly } from 'utils/account';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
} from 'containers/ExperimentalFeature';

import * as profileActions from '../actions';

function* configureSittersLookingInYourAreaTestActive() {
    const membership = yield select(getAccountCurrentMembershipPlan);
    const isPaidMember = getIsMembershipActive(membership);
    const { enabled, variation } = yield select(getExperimentalFeatureVariationSelector, {
        experiment: experiments.SITTERS_LOOKING_IN_YOUR_AREA,
        userId: membership.userId,
    });
    if (enabled && isPaidMember && isOwnerOnly(membership)) {
        yield put(profileActions.sittersLookingInYourAreaVariant.create(variation));
    } else {
        yield put(profileActions.sittersLookingInYourAreaVariant.create(null));
    }
}

export default configureSittersLookingInYourAreaTestActive;
