/* eslint-disable indent */
import { theme } from 'theme';
import { match } from 'path-to-regexp';
import routePaths from 'src/route-paths';
import { APP_WEB_VIEW_TYPES, SplitOverrides } from 'config/constants';

/**
 * Checks if the value passed in has a unit of measurement
 * attached as a suffix
 *
 * @param  {string} val the value to be tested e.g. '15px' or '15'
 *
 * @return {boolean} whether a unit of measurement has been provided
 *
 * @example
 * hasUnitMeasurement('15px'); => true
 * hasUnitMeasurement('15'); => false
 */
export const hasUnitMeasurement = (val) => RegExp(/[a-z]/).test(val);

/**
 * Checks to see if a unit of measurement has been passed in with the value.
 * If so then it returns it, otherwise it returns the number against the predefined
 * spacing scale.
 *
 * @param  {object} spacingScale the theme's spacing values
 * @param  {string} val spacing value e.g. 15px / 10em / 1
 *
 * @return {string} spacing value with measurement unit e.g. 15px / 10em / 2rem
 */
export const getSpacingUnit = (spacingScale, val) => {
    if (hasUnitMeasurement(val)) return val;

    return spacingScale[val];
};

/**
 * @param  {string} paddingInput a space seperated string of spacing values
 * which either relates to the spacing scale (e.g. '2 1 2 0.5') or has absolute values
 * ('15px 10px' / '15px 10px 5px 50px')
 */
export const getSpacingValues = (spacingScale = theme.spacing, paddingInput = '') => {
    if (paddingInput === '') return undefined;
    if (paddingInput === '0') return paddingInput;

    const paddingVals = paddingInput.split(' ');

    return paddingVals.slice(1).reduce((output, val) => {
        const unit = getSpacingUnit(spacingScale, val);

        return `${output} ${unit}`;
    }, getSpacingUnit(spacingScale, paddingVals[0]));
};

/**
 * @description takes a string and returns it as PascalCase
 * e.g. the brown cow returns TheBrownCow
 *
 * @param {string} str a space seperated string of words.
 *
 * @returns {string} a PascalCase string
 */
export const stringToPascalCase = (str) => {
    const upperCaseFirstLetter = (word) => {
        const firstChar = word.charAt(0).toUpperCase();
        return firstChar + word.substring(1).toLowerCase();
    };

    return str.split(' ').map(upperCaseFirstLetter).join('');
};

// hides element but keeps it in the dom for screenreaders
export const visuallyHide = `
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;

export const matchListingDetail = match(routePaths.search.listingsDetail, {
    decode: decodeURIComponent,
});

export const matchProfileDetail = match(routePaths.search.profilesDetail, {
    decode: decodeURIComponent,
});

export const matchEditProfilePreview = match(routePaths.user.profile.edit.profilePreview, {
    decode: decodeURIComponent,
});

// accounts/signup/checkout/billing
export const matchAccountSignupCheckout = match(routePaths.accounts.signUp.checkout, {
    decode: decodeURIComponent,
});

// accounts/signup/checkout/payment
export const matchAccountSignupPayment = match(routePaths.accounts.signUp.payment, {
    decode: decodeURIComponent,
});

// accounts/checkout
export const matchAccountCheckout = match(routePaths.accounts.checkout.payment, {
    decode: decodeURIComponent,
});

// accounts/explore-plans
export const matchAccountExplorePlans = match(routePaths.accounts.explorePlans, {
    decode: decodeURIComponent,
});

// pricing
export const matchPricing = match(routePaths.pricing, {
    decode: decodeURIComponent,
});

// RAF
export const matchReferAFriend = match(routePaths.accounts.referAFriend, {
    decode: decodeURIComponent,
});

// inbox
export const matchInbox = match(routePaths.user.inbox, {
    decode: decodeURIComponent,
});

/**
 * @description takes a string and returns whether it contains either of the app webview user agent strings, and if so,
 * which one. Returns null if it doesn't find either one.
 *
 * @param {string} userAgent.
 *
 * @returns {(string|null)} Returns either a constant value indicating which of the platforms is being used, or null.
 */
export const isAppWebViewUserAgent = (userAgent) => {
    if (userAgent.includes('THSApp/iOS')) return APP_WEB_VIEW_TYPES.iOS;
    if (userAgent.includes('THSApp/Android')) return APP_WEB_VIEW_TYPES.android;
    return null;
};

export const getAppWebViewType = (split) => {
    if (split === SplitOverrides.APP_IOS) return APP_WEB_VIEW_TYPES.iOS;
    if (split === SplitOverrides.APP_ANDROID) return APP_WEB_VIEW_TYPES.android;
    return null;
};

export const isControlSplit = (split) => split && split === SplitOverrides.CONTROL;

export const isBotSplit = (split) => split && split === SplitOverrides.BOT;

/**
 * Formats the sitter name by appending the partner name when available
 *
 * @param {object} profile the profile object that has the firstName and optionally the partner.firstName.
 *
 * @returns {string} the formatted name like "Lucy & John".
 */
export const sitterName = (profile) => {
    let profileName = profile.user.firstName;
    if (profile.partner?.firstName) {
        profileName += ` & ${profile.partner.firstName}`;
    }
    return profileName;
};

/**
 * Truncates the text when it exceeds the maxCharacters
 *
 * @param {string} text the text to truncate.
 * @param {number} maxCharacters the maximum number of characters to show.
 * @param {boolean} showEllipsis whether to show ellipsis at the end of the text.
 *
 * @returns {string} the truncated text when the limit is exceeded or original text.
 */
export const truncateText = (text, maxCharacters = 0, showEllipsis = false) => {
    if (!text || maxCharacters === 0 || text.length <= maxCharacters) return text;

    const newText = text?.substring(0, maxCharacters + 1).replace(/[\s,.;]+\S*$/, '');
    return showEllipsis ? `${newText}…` : newText;
};
