import { combineReducers } from 'redux';

import blog, { initialState as blogInitialState } from './blog';
import seoContent, { initialState as seoContentInitialState } from './seoContent';
import page, { initialState as pageInitialState } from './page';

export const initialState = {
    blog: blogInitialState,
    seoContent: seoContentInitialState,
    page: pageInitialState,
};

export default combineReducers({
    blog,
    seoContent,
    page,
});