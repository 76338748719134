import { combineReducers } from 'redux';

import assignments, { initialState as assignmentsInitialState } from './assignments';
import applications, { initialState as applicationsInitialState } from './applications';
import availabilities, { initialState as availabilitiesInitialState } from './availabilities';
import confirmedAssignments, { initialState as confirmedAssignmentsInitialState } from './confirmedAssignments';
import conversations, { initialState as conversationsInitialState } from './conversations';
import currentAssignments, { initialState as currentAssignmentsInitialState } from './currentAssignments';
import favourites, { initialState as favouritesInitialState } from './favourites';
import feedbacks, { initialState as feedbacksInitialState } from './feedbacks';
import invitations, { initialState as invitationsInitialState } from './invitations';
import photos, { initialState as photosInitialState } from './photos';
import pastAssignments, { initialState as pastAssignmentsInitialState } from './pastAssignments';
import profile, { initialState as profileInitialState } from './profile';
import reviews, { initialState as reviewsInitialState } from './reviews';
import upcomingAssignments, { initialState as upcomingAssignmentsInitialState } from './upcomingAssignments';
import verifications, { initialState as verificationsInitialState } from './verifications';
import owner, { initialState as ownerInitialState } from './owner';
import pets, { initialState as petsInitialState } from './pets';

export const initialState = {
    assignments: assignmentsInitialState,
    applications: applicationsInitialState,
    availabilities: availabilitiesInitialState,
    confirmedAssignments: confirmedAssignmentsInitialState,
    conversations: conversationsInitialState,
    currentAssignments: currentAssignmentsInitialState,
    favourites: favouritesInitialState,
    feedbacks: feedbacksInitialState,
    invitations: invitationsInitialState,
    photos: photosInitialState,
    pastAssignments: pastAssignmentsInitialState,
    profile: profileInitialState,
    reviews: reviewsInitialState,
    upcomingAssignments: upcomingAssignmentsInitialState,
    verifications: verificationsInitialState,
    owner: ownerInitialState,
    pets: petsInitialState,
};

export default combineReducers({
    applications,
    assignments,
    availabilities,
    confirmedAssignments,
    conversations,
    currentAssignments,
    favourites,
    feedbacks,
    invitations,
    photos,
    pastAssignments,
    profile,
    reviews,
    upcomingAssignments,
    verifications,
    owner,
    pets,
});
