import { openUpgradeModal, closeUpgradeModal } from './actions';

export const initialState = {
    showUpgradeModal: false,
    account: {
        membershipPlan: undefined,
    },
};

const upgradeModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case openUpgradeModal.ACTION: {
            return {
                ...state,
                showUpgradeModal: true,
                openUpgradeModalOptions: action.options,
            };
        }
        case closeUpgradeModal.ACTION: {
            return {
                ...state,
                showUpgradeModal: false,
                openUpgradeModalOptions: undefined,
            };
        }
        default:
            return state;
    }
};

export default upgradeModalReducer;
