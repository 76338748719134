// eslint-disable-next-line import/prefer-default-export
export const VIEWED_MBG_MODAL = {
    create({ method }) {
        const payload = {
            method,
        };

        return {
            type: 'Viewed MBG Modal',
            payload,
        };
    },
};
