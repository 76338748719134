/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PrismicRichText, { Elements } from 'prismic-richtext';
import { Link as LinkHelper } from 'prismic-helpers';

import {
    H1 as H1Typography,
    H2 as H2Typography,
    H3 as H3Typography,
    H4 as H4Typography,
    Span as SpanTypography,
} from 'typography';

import {
    BlockquoteStyled,
    AStyled,
    LiStyled,
    UlStyled,
    OlStyled,
    StrongStyled,
    PStyled,
} from './RichText.style';

/**
 * This is a fork from prismic rich text editor, updated with our styled components
 */
const RichText = ({
    richText,
    linkResolver,
    htmlSerializer,
    A,
    Span,
    H1,
    H2,
    H3,
    H4,
    P,
    Blockquote,
    Strong,
    Li,
    Ul,
    Ol,
    className,
}) => {
    const propsWithUniqueKey = (props, key) => Object.assign(props || {}, { key });

    const getStandardTagProps = (element, key) => {
        const props = element.label ? { className: element.label } : {};
        return propsWithUniqueKey(props, key);
    };

    const serializeHyperlink = (element, children, key) => {
        const targetAttr = element.data.target ? { target: element.data.target } : {};
        const relAttr = element.data.target ? { rel: 'noopener' } : {};
        const props = {
            href: LinkHelper.url(element.data, linkResolver),
            ...targetAttr,
            ...relAttr,
        };

        const ATag = A || AStyled;
        return <ATag {...propsWithUniqueKey(props, key)}>{children}</ATag>;
    };

    const serializeLabel = (element, children, key) => {
        const props = element.data ? { className: element.data.label } : {};
        const SpanTag = Span || SpanTypography;
        return <SpanTag {...propsWithUniqueKey(props, key)}>{children}</SpanTag>;
    };

    const serializeSpan = (content) => {
        if (content) {
            return content.split('\n').reduce((acc, p) => {
                if (acc.length === 0) {
                    return [p];
                }
                // @TODO - I am leaving this in cause it's pretty annoying with prettier not complying with eslint rules
                // eslint-disable-next-line no-mixed-operators
                const brIndex = (acc.length + 1) / 2 - 1;
                const br = React.createElement('br', propsWithUniqueKey({}, brIndex));
                return acc.concat([br, p]);
            }, []);
        }
        return null;
    };

    const serializeImage = (element, key) => {
        const linkUrl = element.linkTo ? LinkHelper.url(element.linkTo, linkResolver) : null;
        const linkTarget =
            element.linkTo && element.linkTo.target ? { target: element.linkTo.target } : {};
        const relAttr = linkTarget.target ? { rel: 'noopener' } : {};
        const img = React.createElement('img', {
            src: element.url,
            alt: element.alt || '',
        });

        return React.createElement(
            'p',
            propsWithUniqueKey({ className: [element.label || '', 'block-img'].join(' ') }, key),
            linkUrl
                ? React.createElement('a', { href: linkUrl, ...linkTarget, ...relAttr }, img)
                : img
        );
    };

    const serializeEmbed = (element, key) => {
        const props = {
            'data-oembed': element.oembed.embed_url,
            'data-oembed-type': element.oembed.type,
            'data-oembed-provider': element.oembed.provider_name,
            ...(element.label ? { className: element.label } : {}),
        };

        const embedHtml = React.createElement('div', {
            dangerouslySetInnerHTML: { __html: element.oembed.html },
        });

        return React.createElement('div', propsWithUniqueKey(props, key), embedHtml);
    };

    // @TODO - Add H5 and H6 if needed
    const serialize = (type, element, content, children, index) => {
        switch (type) {
            case Elements.heading1: {
                const H1Tag = H1 || H1Typography;
                return <H1Tag {...getStandardTagProps(element, index)}>{children}</H1Tag>;
            }
            case Elements.heading2: {
                const H2Tag = H2 || H2Typography;
                return (
                    <H2Tag id={element.text} {...getStandardTagProps(element, index)}>
                        {children}
                    </H2Tag>
                );
            }
            case Elements.heading3: {
                const H3Tag = H3 || H3Typography;
                return <H3Tag {...getStandardTagProps(element, index)}>{children}</H3Tag>;
            }
            case Elements.heading4: {
                const H4Tag = H4 || H4Typography;
                return <H4Tag {...getStandardTagProps(element, index)}>{children}</H4Tag>;
            }
            case Elements.heading5: {
                const H4Tag = H4 || H4Typography;
                return <H4Tag {...getStandardTagProps(element, index)}>{children}</H4Tag>;
            }
            case Elements.heading6: {
                const H4Tag = H4 || H4Typography;
                return <H4Tag {...getStandardTagProps(element, index)}>{children}</H4Tag>;
            }
            case Elements.paragraph: {
                const PTag = P || PStyled;
                return <PTag {...getStandardTagProps(element, index)}>{children}</PTag>;
            }
            case Elements.preformatted: {
                const BlockquoteTag = Blockquote || BlockquoteStyled;
                return (
                    <BlockquoteTag {...getStandardTagProps(element, index)}>
                        {children}
                    </BlockquoteTag>
                );
            }
            case Elements.strong: {
                const StrongTag = Strong || StrongStyled;
                return <StrongTag {...getStandardTagProps(element, index)}>{children}</StrongTag>;
            }
            case Elements.em:
                return <em {...getStandardTagProps(element, index)}>{children}</em>;
            case Elements.listItem: {
                const LiTag = Li || LiStyled;
                return <LiTag {...getStandardTagProps(element, index)}>{children}</LiTag>;
            }
            case Elements.oListItem: {
                const LiTag = Li || LiStyled;
                return <LiTag {...getStandardTagProps(element, index)}>{children}</LiTag>;
            }
            case Elements.list: {
                const UlTag = Ul || UlStyled;
                return <UlTag {...getStandardTagProps(element, index)}>{children}</UlTag>;
            }
            case Elements.oList: {
                const OlTag = Ol || OlStyled;
                return <OlTag {...getStandardTagProps(element, index)}>{children}</OlTag>;
            }
            case Elements.image:
                return serializeImage(element, index);
            case Elements.embed:
                return serializeEmbed(element, index);
            case Elements.hyperlink:
                return serializeHyperlink(element, children, index);
            case Elements.label:
                return serializeLabel(element, children, index);
            case Elements.span:
                return serializeSpan(content);
            default:
                return null;
        }
    };

    const serializedChildren = PrismicRichText.serialize(richText, serialize, htmlSerializer);
    return React.createElement('div', propsWithUniqueKey({ className }), serializedChildren);
};

export default RichText;
