import * as actions from './actions';

export const initialState = {
    showOutsideSubscriptionModal: false,
    modalType: null,
    updateAccountStatus: null,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.openOutsideSubscriptionModal.ACTION:
            return {
                ...state,
                showOutsideSubscriptionModal: true,
                modalType: action.modalType,
            };
        case actions.closeOutsideSubscriptionModal.ACTION:
            return {
                ...state,
                showOutsideSubscriptionModal: false,
                modalType: null,
                updateAccountStatus: null,
                error: null,
            };
        case actions.updateAccountStatus.ACTION:
            return {
                ...state,
                updateAccountStatus: action.status,
            };
        case actions.modalError.ACTION:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
