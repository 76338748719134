import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { H2, P, Link } from '@ths/design-system';

import { routes } from 'utils/routes';

import Star from '../assets/verified.svg';

const Success = ({ isLoggedIn }) => {
    const { t } = useTranslation();

    return (
        <>
            <img src={Star} alt="email validation success" />
            <H2 className="mb-0 max-md:text-lg max-md:leading-8">
                {t('page_verify_email_success_title')}
            </H2>
            <P className="max-md:px-3 max-md:text-sm max-md:leading-6">
                {t('page_verify_email_success_text')}
            </P>
            <Link
                isButtonStyle
                className="mt-5"
                variant="accent"
                to={isLoggedIn ? routes.user.verifications() : routes.login()}
            >
                {isLoggedIn
                    ? t('page_verify_email_button_logged_text')
                    : t('page_verify_email_button_text')}
            </Link>
        </>
    );
};

Success.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
};

export default Success;
