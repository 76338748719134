import listingEditRoutes from './listing/edit/route-paths';
import listingWelcomeGuideRoutes from './listing/welcome-guide/route-paths';
import profileEditRoutes from './profile/edit/route-paths';
import savedRoutes from './saved/route-paths';
import assignmentsRoutes from './assignments/route-paths';
import settings from './settings/route-paths';

export default {
    user: {
        verifications: '/user/verifications/',
        listing: {
            edit: listingEditRoutes,
            welcomeGuide: listingWelcomeGuideRoutes,
        },
        profile: {
            edit: profileEditRoutes,
        },
        assignments: assignmentsRoutes,
        saved: savedRoutes,
        ...settings,
        plans: '/user/plans/',
        dates: '/user/:listingId/dates',
        favourites: '/user/favourites/',
        inbox: '/user/inbox/',
        upgrade: '/user/upgrade',
        applications: '/user/listing/:listingId/applications/:assignmentId/',
    },
};
