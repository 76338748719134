import {
    getMembershipStatus,
    isMember,
    isExpired,
    getMembershipType,
    getMembershipTier,
} from 'utils/account';
import {
    getIsLoggedIn,
    getLanguage,
    getStoredPromo,
    isViewingInAppWebView,
    getLocale,
} from 'shared/selectors';
import {
    getAccount,
    getAccountIncompleteMembershipPlan,
    getAccountSubscriptionRenewalAmount,
} from 'api/selectors/account';
import { getPlans } from 'api/selectors/plans';
import * as pageActions from 'containers/Page/actions';
import {
    getRegwall,
    getCurrencyCode,
    getPlanTypeVisible,
    getPlanFromUrl,
    getProration,
    getTabs,
    getHideTabs,
    getHasPendingPlanChange,
    getFreeTrial,
    getFinishedAccountLoadingState,
} from './selectors';
import * as actions from './actions';

const mapStateToProps = (state) => {
    const { membershipPlan } = getAccount(state);

    return {
        isLoggedIn: getIsLoggedIn(state),
        membershipStatus: getMembershipStatus(membershipPlan),
        membershipTier: getMembershipTier(membershipPlan),
        membershipType: getMembershipType(membershipPlan),
        planTypeVisible: getPlanTypeVisible(state),
        plans: getPlans(state),
        currencyCode: getCurrencyCode(state),
        canChangeCurrency: !(isMember(membershipPlan) || isExpired(membershipPlan)),
        language: getLanguage(state),
        promoCode: getStoredPromo(state),
        regwall: getRegwall(state),
        planFromUrl: getPlanFromUrl(state),
        proration: getProration(state),
        validTabs: getTabs(state),
        hasPendingPlanChange: getHasPendingPlanChange(state),
        hideTabs: getHideTabs(state),
        freeTrial: getFreeTrial(state),
        isInAppWebView: isViewingInAppWebView(state),
        finishedLoadingAccountData: getFinishedAccountLoadingState(state),
        currentIncompleteMembershipPlan: getAccountIncompleteMembershipPlan(state),
        renewalAmount: getAccountSubscriptionRenewalAmount(state),
        isoCode: getLocale(state).countryISOCode,
    };
};

const mapDispatchToProps = (dispatch) => ({
    regwallClose: () => {
        dispatch(actions.regwallClose.create());
    },
    setPlanTypeVisible: (membershipType) => {
        dispatch(actions.setPlanTypeVisible.create(membershipType));
    },
    selectPlan: (data) => {
        dispatch(actions.selectPlan.create(data.membershipPlan));
        // clear locally stored paymentIntentSlug if new plan is selected
        if (!data.isSamePlan) {
            dispatch(pageActions.resetPaymentIntentStorage.create());
        }
    },
    changeCurrency: (currencyCode) => {
        dispatch(actions.changeCurrency.create(currencyCode));
    },
    loadPlansSwitchDetails: (planIds) => {
        dispatch(actions.loadPlansSwitchDetails.create(planIds));
    },
    loadPlans: (currencyCode) => {
        dispatch(actions.loadPlans.create(currencyCode));
    },
});

export { mapDispatchToProps, mapStateToProps };
