import { getItem } from 'src/universalStorage/selectors';
import { QUERY_STRING_KEY } from './constants';

const validate = (value) => {
    if (typeof value !== 'object' || Array.isArray(value) || value === null) {
        return false;
    }

    return true;
};

export const getAcqExperiments = (state) => {
    const data = getItem(state, QUERY_STRING_KEY);

    if (!validate(data)) {
        return {};
    }

    return data;
};

export const getAcqExperiment = (experiment) => (state) => {
    const data = getItem(state, QUERY_STRING_KEY);

    if (!validate(data)) {
        return null;
    }

    const value = data[experiment];

    if (!value) {
        return null;
    }

    return {
        name: experiment,
        variation: value,
    };
};
