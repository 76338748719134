import { settings as actions } from '../../actions';

import { uniqueArray } from '../../helpers/format/objects';

export const initialState = { results: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadConfirmedAssignments.SUCCESS:
            return {
                ...state,
                results: uniqueArray([
                    ...action.data.current.map((assignment) => assignment.id),
                    ...action.data.upcoming.map((assignment) => assignment.id),
                ]),
            };
        default:
            return state;
    }
};
