import styled from 'styled-components';
import { IconExclamationCircle } from 'components/Icon';
import { ErrorText } from '../shared.style';

const ErrorTextStyled = styled(ErrorText)`
    display: flex;
    width: 100%;
    align-items: start;
    margin-top: ${({ theme }) => theme.spacing[1]};
`;

const ErrorIcon = styled(IconExclamationCircle)`
    margin-right: ${({ theme }) => theme.spacing[1]};
    margin-top: ${({ theme }) => theme.spacing[0.5]};
    && svg {
        fill: ${({ theme }) => theme.palette.danger};
    }
`;

const ErrorChild = styled.span`
    flex: 1;
`;

export { ErrorTextStyled, ErrorIcon, ErrorChild };
