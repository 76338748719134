import styled from 'styled-components';
import { smallTextStyles } from 'typography';

export const WrapperStyled = styled.div`
    position: relative;
    width: 548px;
    padding: 0 ${({ theme }) => theme.spacing[4]};
    padding-top: ${({ theme }) => theme.spacing[8]};
    padding-bottom: ${({ theme }) => theme.spacing[4]};
    color: ${({ theme }) => theme.palette.primaryRegular};
    background-color: ${({ theme }) => theme.palette.primarySoftest};
    border-radius: ${({ theme }) => theme.borderRadius.regular};

    &::after {
        position: absolute;
        top: ${({ theme }) => theme.spacing[2]};
        left: ${({ theme }) => theme.spacing[4]};
        content: '“';
        padding: 0;
        margin: 0;
        font-family: ${({ theme }) => theme.fonts.variant};
        font-size: 100px;
        line-height: 1;
    }
`;

export const ContentStyled = styled.div``;

export const QuoteStyled = styled.p`
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

export const FooterStyled = styled.small`
    ${smallTextStyles};

    & > a {
        color: ${({ theme }) => theme.palette.primaryRegular};
    }
`;
