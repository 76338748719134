import { animalMapping } from './common';
import { getApplication } from './applications';
import { getOwner } from './listings';
import { getSitter } from './profiles';

/**
 * Assignment
 * @param assignment
 * @param ownerListing
 * @returns {*}
 */
// eslint-disable-next-line import/prefer-default-export
export const getAssignment = (assignment, ownerListing) => {
    if (!assignment) return assignment;

    const {
        id,
        startDate,
        endDate,
        isReviewing,
        isPrivate,
        isConfirmed,
        isDeleted,
        isApproximateDates,
        applicationsCount,
        recentApplications,
        invitationsCount,
        recentInvitations,
        lastMessage,
        newMessagesCount,
        applicantsNewMessagesCount,
        sitterNewMessagesCount,
        profile,
        profileId,
        listing,
        feedback,
        feedbackRequest,
        review,
        reviewRequest,
        welcomeGuideStatus,
        pendingApplicationsCount,
        applicantAvatarPhotoIds,
        // #TODO - Bugfix API#3850
        confirmed,
        reviewing,
        // #Search specific API - does this only includes active applications?
        numberOfApplicants,
        boostedCount,
        boostedDatetime,
        reviewWindowEndDate,
        isReviewProvided,
        isFeedbackProvided,
    } = assignment;

    let data = {
        id,
        startDate,
        endDate,
        isReviewing: isReviewing || reviewing || false,
        isPrivate,
        isConfirmed: isConfirmed || confirmed || !!profileId || false,
        isDeleted,
        isApproximateDates,
        applicationsCount: applicationsCount || numberOfApplicants,
        recentApplications: [],
        invitationsCount,
        recentInvitations: [],
        lastMessage,
        newMessagesCount: newMessagesCount || applicantsNewMessagesCount || 0,
        sitterNewMessagesCount: sitterNewMessagesCount || 0,
        feedback,
        feedbackRequest,
        review,
        reviewRequest,
        welcomeGuideStatus,
        boostedCount,
        boostedDatetime,
        pendingApplicationsCount,
        applicantAvatarPhotoIds,
        reviewWindowEndDate,
        isReviewProvided,
        isFeedbackProvided,
    };

    if (profile && profile.user) {
        let profileName = profile.user.firstName;
        if (profile.partner && profile.partner.firstName) {
            profileName += ` & ${profile.partner.firstName}`;
        }
        const sitterData = getSitter(profile.user);
        data = {
            ...data,

            // profile
            profileId: profile.id,
            profileLocation: profile.location,
            profileName,
            profileVerificationLevel: profile.verificationLevel,
            ...(sitterData || {}),
        };
    }

    // Add listing
    if (ownerListing || listing) {
        const {
            id: listingId,
            user,
            location,
            mainPhoto,
            photos: listingPhotos,
            animals,
            selectedAnimals,
        } = ownerListing || listing;

        let listingMainPhoto = mainPhoto;
        if (!listingMainPhoto && listingPhotos && listingPhotos.length > 0) {
            listingMainPhoto = listingPhotos[0];
        }

        // This needs looking at. Flags like isActive/isExpired/isRedacted are coming back undefined and defaulting to false.
        const ownerData = getOwner(user);
        data = {
            ...data,
            ...(ownerData || {}),
            listingId,
            listingLocation: location,
            listingMainPhoto,
            listingSelectedAnimals: selectedAnimals,
            listingAnimals: (animals || []).map((animal) => ({
                ...animal,
                slug: animalMapping(animal.slug),
            })),
            hasAnimals: selectedAnimals > 0,
        };
    }

    if (recentApplications) {
        data = {
            ...data,
            recentApplications: recentApplications.map((recentApplication) =>
                getApplication(recentApplication)
            ),
        };
    }

    if (recentInvitations) {
        data = {
            ...data,
            recentInvitations: recentInvitations.map((recentInvitation) =>
                getApplication(recentInvitation)
            ),
        };
    }

    return data;
};
