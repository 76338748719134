import { VIEWED_EXPERIMENTAL_FEATURE_MAP } from './constants';

export const isUserInAcqCampaign = (acqExperiments) => Object.entries(acqExperiments).length !== 0;

export const getExperimentsFromParams = (acqCampaignParams) => {
    if (typeof acqCampaignParams !== 'string') {
        return;
    }

    const splits = acqCampaignParams.split(',');

    if (splits.length === 0) {
        return;
    }

    const pattern = /^([a-zA-Z_]+):([0-2])$/;

    const experiments = splits
        .map((s) => pattern.exec(s))
        .filter(Boolean)
        .reduce(
            (acc, cur) => ({
                ...acc,
                [cur[1]]: VIEWED_EXPERIMENTAL_FEATURE_MAP[cur[2]],
            }),
            {}
        );

    return experiments;
};
