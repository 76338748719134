import { settings as actions } from '../actions';
import { generateStatusKey, generateRequestedFieldsSymbol } from '../sagas/helpers';

export const initialState = {};

/**
 * Update resource state
 * @param action
 * @param state
 * @param stateKeys
 * @param actionType
 * @param status
 */
const updateStates = (action, state, stateKeys, actionType, status) => {
    stateKeys.forEach((stateKey) => {
        const stateStatus = state[stateKey] || {};

        state[stateKey] = {
            ...stateStatus,
            [actionType]: {
                status,
                time: new Date().getTime(),
                statusCode: action.statusCode || null,
            },
        };
    });
};

export default (state = initialState, action) => {
    if (!action.actionKey) {
        return state;
    }
    if (!action.type.startsWith('@THS_API')) {
        return state;
    }

    // Extract action state
    const actionStatusRegex = /\$(\w+)\$/;
    /**
     * match = [$REQUESTED$, REQUESTED]
     * match = [SUCCESS, SUCCESS]
     * match = [FAILURE, FAILURE]
     */
    const match = action.type.match(actionStatusRegex);

    // Do not save DONE status
    if (match && match[1] === 'DONE') return state;

    // Skip intermediate updates
    const { shadowUpdate } = action.action;
    const shadowUpdateStatuses = ['REQUEST', 'REQUESTED'];
    // console.log('shadowUpdate', shadowUpdate, match[1]);
    if (shadowUpdate && shadowUpdateStatuses.indexOf(match[1]) >= 0) {
        return state;
    }

    const newState = {
        ...state,
    };

    const actionStatus = state[action.actionKey] || {};

    newState[action.actionKey] = {
        ...actionStatus,
        [action.apiAction.action]: {
            status: match[1],
            timestamp: new Date().getTime(),
            statusCode: action.statusCode || null,
        },
    };

    // loadOwnerSitterPastAssignments
    // loadListingOpenAssignments
    //
    // What fields were requested?
    const requestedFields = generateRequestedFieldsSymbol(action.action);

    // LOAD:{status: "SUCCESS", timestamp: Wed Aug 02 2017 16:14:43 GMT+0100 (BST)}
    switch (action.type) {
        case actions.account.load.SUCCESS: {
            const stateKeys = [
                generateStatusKey('ACCOUNT_DETAILS'),
                generateStatusKey('ACCOUNT_SETTINGS'),
                generateStatusKey('ACCOUNT_MEMBERSHIP'),
                generateStatusKey('ACCOUNT_LISTINGS'),
                generateStatusKey('ACCOUNT_PROFILE'),
                generateStatusKey('ACCOUNT_BILLING_INFORMATION'),
                generateStatusKey('ACCOUNT_MESSAGES'),
            ];
            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }

        case actions.owner.loadListings.SUCCESS: {
            const stateKeys = [];

            action.data.forEach((listing) => {
                // Listings
                stateKeys.push(generateStatusKey('OWNER_LISTING', listing.id, requestedFields));
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.owner.loadOpenAssignmentApplications.SUCCESS: {
            const stateKeys = [];

            action.data.results.forEach((application) => {
                stateKeys.push(
                    generateStatusKey('OWNER_APPLICATION', application.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.owner.loadConfirmedAssignments.SUCCESS: {
            const stateKeys = [];

            if (action.data.current) {
                stateKeys.push(
                    generateStatusKey('OWNER_ASSIGNMENT', action.data.current.id, requestedFields)
                );
            }
            action.data.upcoming.forEach((assignment) => {
                stateKeys.push(
                    generateStatusKey('OWNER_ASSIGNMENT', assignment.id, requestedFields)
                );
            });
            action.data.past.forEach((assignment) => {
                stateKeys.push(
                    generateStatusKey('OWNER_ASSIGNMENT', assignment.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.owner.loadCurrentAssignments.SUCCESS: {
            const stateKeys = [];

            action.data.forEach((assignment) => {
                stateKeys.push(
                    generateStatusKey('OWNER_ASSIGNMENT', assignment.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.owner.loadListingOpenAssignments.SUCCESS:
        case actions.owner.loadOpenAssignments.SUCCESS:
        case actions.owner.loadUpcomingAssignments.SUCCESS:
        case actions.owner.loadPastAssignments.SUCCESS: {
            const stateKeys = [];

            action.data.results.forEach((assignment) => {
                stateKeys.push(
                    generateStatusKey('OWNER_ASSIGNMENT', assignment.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }

        case actions.sitter.loadApplications.SUCCESS:
        case actions.sitter.loadSitterOwnerApplications.SUCCESS: {
            const stateKeys = [];

            action.data.results.forEach((application) => {
                // Application
                stateKeys.push(
                    generateStatusKey('SITTER_APPLICATION', application.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.sitter.loadConfirmedAssignments.SUCCESS: {
            const stateKeys = [];

            action.data.current.forEach((assignment) => {
                stateKeys.push(
                    generateStatusKey('SITTER_ASSIGNMENT', assignment.id, requestedFields)
                );
            });
            action.data.upcoming.forEach((assignment) => {
                stateKeys.push(
                    generateStatusKey('SITTER_ASSIGNMENT', assignment.id, requestedFields)
                );
            });
            action.data.past.forEach((assignment) => {
                stateKeys.push(
                    generateStatusKey('SITTER_ASSIGNMENT', assignment.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.sitter.loadCurrentAssignments.SUCCESS: {
            const stateKeys = [];
            stateKeys.push(generateStatusKey('SITTER_ASSIGNMENT', action.data.id, requestedFields));
            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.sitter.loadUpcomingAssignments.SUCCESS:
        case actions.sitter.loadPastAssignments.SUCCESS: {
            const stateKeys = [];

            action.data.results.forEach((assignment) => {
                stateKeys.push(
                    generateStatusKey('SITTER_ASSIGNMENT', assignment.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.sitter.loadAvailabilities.SUCCESS: {
            const stateKeys = [];

            action.data.forEach((availability) => {
                stateKeys.push(
                    generateStatusKey('SITTER_AVAILABILITY', availability.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.sitter.loadFeedbacks.SUCCESS:
            break;
        case actions.sitter.loadInvitations.SUCCESS: {
            const stateKeys = [];

            action.data.results.forEach((invitation) => {
                // Application
                stateKeys.push(
                    generateStatusKey('SITTER_INVITATION', invitation.id, requestedFields)
                );
            });

            updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            break;
        }
        case actions.sitter.loadProfilePhotos.SUCCESS:
            break;
        case actions.sitter.loadProfile.SUCCESS:
            break;
        case actions.sitter.loadProfileVerifications.SUCCESS:
            break;
        case actions.owner.loadConversations.SUCCESS:
            {
                const stateKeys = [];
                stateKeys.push(
                    generateStatusKey('OWNER_CONVERSATIONS', action.requestData.contactId)
                );
                updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            }
            break;
        case actions.owner.createConversation.SUCCESS:
            {
                const stateKeys = [];
                stateKeys.push(
                    generateStatusKey('OWNER_CONVERSATION', action.requestData.contactId)
                );
                updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            }
            break;
        case actions.sitter.loadConversations.SUCCESS:
            {
                const stateKeys = [];
                stateKeys.push(
                    generateStatusKey('SITTER_CONVERSATIONS', action.requestData.contactId)
                );
                updateStates(action, newState, stateKeys, 'LOAD', 'SUCCESS');
            }
            break;
        case actions.sitter.createConversation.SUCCESS:
            {
                const stateKeys = [];
                stateKeys.push(
                    generateStatusKey('SITTER_CONVERSATION', action.requestData.contactId)
                );
                updateStates(action, newState, stateKeys, 'CREATE', 'SUCCESS');
            }
            break;
        default:
            break;
    }

    return newState;
};
