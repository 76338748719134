import { connect } from 'react-redux';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import actions, { settings } from 'api/actions';
import { isOwnerSitterProfileFavourite } from 'api/selectors/owner';
import { hasRequestEnded } from 'api/selectors/request';
import { MembershipStatus, MembershipType } from 'api/types/membershipTypes';
import { getMembershipType, getMembershipStatus } from 'utils/account';
import ProfileSearchCard from './ProfileSearchCard';

const mapStateToProps = (state, ownProps) => {
    const membership = getAccountCurrentMembershipPlan(state);
    const membershipType = getMembershipType(membership);
    const membershipStatus = getMembershipStatus(membership);
    const showFavourite =
        (membershipType === MembershipType.OWNER || membershipType === MembershipType.COMBINED) &&
        membershipStatus === MembershipStatus.MEMBER;
    const hasProfileDetailLoaded = hasRequestEnded({
        state,
        actionSettings: settings.search.loadProfile,
        objectKey: ownProps.id,
    });

    return {
        ...ownProps,
        membership,
        hasProfileDetailLoaded,
        isFavourite: isOwnerSitterProfileFavourite(state, ownProps.id),
        showFavourite,
    };
};

const mapDispatchToProps = (dispatch) => ({
    createFavourite: (data) => {
        dispatch(actions.owner.createFavourite(data));
    },
    removeFavourite: (data) => {
        dispatch(actions.owner.removeFavourite(data));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSearchCard);
