import { stringify } from 'query-string';

export const defaultLocation = {
    id: 'anywhere',
    name: 'Anywhere',
};

export const utmParams = {
    MEDIUM: 'utm_medium',
};

export const utmMediums = {
    EMAIL: 'email',
};

export const SEARCH_LEVEL = {
    GLOBAL: 'global',
    COUNTRY: 'country',
    REGION: 'region',
    COUNTY: 'county',
    PLACE: 'place',
};

// part of the SIT_DISCOVERY_PAGE test
export const pageViewParamType = {
    DISCOVERY: 'discovery',
};

export const SEARCH_TYPE_COUNTRY_CITIES = 'country-cities';

export const searchPageScrollHash = stringify({ scrollToResults: true });
