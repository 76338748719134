import { settings as actions } from '../../actions';

import {
    baseInitialResultsListState,
    loadedResultsUniqueListReducer,
} from '../common';

export const initialState = { ...baseInitialResultsListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadDeletedAssignments.SUCCESS:
            return loadedResultsUniqueListReducer(state, action);
        case actions.owner.removeListingOpenAssignment.SUCCESS:
            return {
                results: [
                    ...state.results,
                    action.requestData.id,
                ],
            };
        default:
            return state;
    }
};
