import { call, put, select } from 'redux-saga/effects';
import { setItem } from 'src/universalStorage/actions';
import { getItem } from 'src/universalStorage/selectors';
import { STORAGE_KEY } from './constants';

export function validate(value) {
    if (!value) {
        return false;
    }

    if (!Array.isArray(value)) {
        return false;
    }

    return true;
}

export function* getValue() {
    const value = yield select((state) => getItem(state, STORAGE_KEY));

    if (!validate(value)) {
        return null;
    }

    return value;
}

export function* appendAssignment(assignmentId) {
    try {
        const current = yield call(getValue);

        yield put(setItem.create(STORAGE_KEY, [...(current ?? []), String(assignmentId)]));

        return true;
    } catch (e) {
        return false;
    }
}
