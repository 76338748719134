import { createAction } from 'utils/actions';

const prefix = 'USER_PROFILE_EDIT_ABOUT_YOU';

// Expects data in the SaveProfileArgs shape, curse this un-typeable abstraction!
export const saveProfile = createAction(
    prefix,
    'SAVE_PROFILE',
    'oldProfile',
    'newProfile',
    'hasProfilePhoto',
    'socialVerifications'
);
export const saveProfileDone = createAction(prefix, 'SAVE_PROFILE_DONE', 'status');

export const profilePhotoSave = createAction(prefix, 'PROFILE_PHOTO_SAVE', 'profile');
