import styled from 'styled-components';
import { H2 } from 'typography';

const HeadingStyled = styled(H2)`
    ${({ theme }) => theme.typography.h2};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    text-align: center;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        ${({ theme }) => theme.typography.h2Large};
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { HeadingStyled };
