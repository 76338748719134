import Area from './Area';
import Category from './Category';
import Tags from './Tags';
import Post from './Post';
import Subcategory from './Subcategory';
import Author from './Author';
import SearchResults from './SearchResults';
import paths from './route-paths';

export default [
    {
        component: SearchResults,
        path: paths.search,
        exact: false,
    },
    {
        component: Area,
        path: paths.area,
        exact: false,
        routes: [
            {
                component: Author,
                path: paths.author,
                exact: true,
            },
            {
                component: Tags,
                path: paths.tags,
                exact: true,
            },
            {
                component: Subcategory,
                path: paths.subcategory,
                exact: true,
            },
            {
                component: Category,
                path: paths.category,
                exact: true,
            },
            {
                component: Post,
                path: paths.post,
                exact: true,
            },
        ],
    },
];
