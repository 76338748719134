import { combineReducers } from 'redux';

import pastSits from './PastSits/reducer';
import pastSittersReducer from './PastSitters/reducer';
import pastSittersDetailsReducer from './PastSittersDetails/reducer';

export default combineReducers({
    pastSits,
    pastSittersReducer,
    pastSittersDetailsReducer,
});
