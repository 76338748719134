import { select, call } from 'redux-saga/effects';
import axios from 'axios';
import ApiConfig from '../config';
import { getSession } from '../selectors/session';
import { getState } from '../selectors/common';

const TIMEZONE_OFFSET = (new Date()).getTimezoneOffset() / -60;

/**
 * Iterate through object items
 * @returns Array()
 */
export function* iterate(obj) {
    const keys = Object.keys(obj);
    for (let a = 0; a < keys.length; a += 1) {
        const key = keys[a];
        yield [key, obj[key]];
    }
}
export const generateStatusKey = (module, objectKey, requestedFields) => {
    const requestedFieldsEnum = requestedFields || 0;
    const objectKeyValue = objectKey || '';
    return `${module}:${objectKeyValue}:${requestedFieldsEnum}`;
};

export function statusSelector(state, apiAction, statusKey) {
    if (!getState(state).status[statusKey]) return null;
    return getState(state).status[statusKey][apiAction.action];
}

export const generateRequestedFieldsSymbol = (action) => {
    let requestedFields = 0;
    if (action.fields) {
        requestedFields = action.fields.reduce((sum, value) => sum + value, 0);
    }
    return requestedFields;
};


/**
 * Creates http headers object to add to every http call
 *
 * @returns
 */
export function getBaseApiHeaders() {
    const headers = {
        'Timezone-Offset': `${TIMEZONE_OFFSET}`,
        'Api-Key': ApiConfig.apiKey,
        'Api-Version': ApiConfig.apiVersion,
    };

    const { customHeaders } = ApiConfig;

    // Add custom headers
    if (customHeaders) {
        Object.keys(customHeaders).forEach((key) => {
            headers[key] = customHeaders[key];
        });
    }

    return headers;
}

function* getHeaders({ headers: apiHeaders, url }) {
    const headers = getBaseApiHeaders({ headers: apiHeaders, url });

    const session = yield select(getSession);

    const { jwtHeaderName, platformDetailHeaderName } = ApiConfig;
    if (session.token) {
        headers[jwtHeaderName] = `JWT ${session.token}`;
    }

    if (ApiConfig.platformDetail) {
        headers[platformDetailHeaderName] = ApiConfig.platformDetail;
    }

    if (apiHeaders) {
        Object.keys(apiHeaders).forEach((key) => {
            headers[key] = apiHeaders[key];
        });
    }

    // Keep whitelist headers only
    Object.keys(ApiConfig.allowedHeaders).forEach((apiEndpoint) => {
        if (url.includes(apiEndpoint)) {
            Object.keys(headers).forEach((key) => {
                if (ApiConfig.allowedHeaders[apiEndpoint].indexOf(key.toLowerCase()) < 0) {
                    delete headers[key];
                }
            });
        }
    });

    return headers;
}

const responseHandler = fetchPromise => fetchPromise.then(
    response => ({
        response,
    }),
).catch(error => ({
    error,
}));

const buildRequestConfig = (config) => {
    const requestConfig = {
        ...config,
    };

    // Set withCredentials
    Object.keys(ApiConfig.withCredentials).forEach((endpoint) => {
        if (config.url.includes(endpoint)) {
            requestConfig.withCredentials = ApiConfig.withCredentials;
            requestConfig.withCredentials = ApiConfig.withCredentials[endpoint];
        }
    });

    // Use overridden http agents
    Object.keys(ApiConfig.httpAgents).forEach((agent) => {
        if (['httpAgent', 'httpsAgent'].includes(agent)) {
            requestConfig[agent] = ApiConfig.httpAgents[agent];
        }
    });

    return requestConfig;
};

export const Get = (url, headers) => responseHandler(
    axios(buildRequestConfig({
        method: 'get',
        url,
        timeout: ApiConfig.timeout,
        headers,
    })),
);

export const Post = (url, headers, data) => responseHandler(
    axios(buildRequestConfig({
        method: 'post',
        url,
        timeout: ApiConfig.timeout,
        data,
        headers,
    })),
);

export const Put = (url, headers, data) => responseHandler(
    axios(buildRequestConfig({
        method: 'put',
        url,
        timeout: ApiConfig.timeout,
        data,
        headers,
    })),
);
export const Delete = (url, headers) => responseHandler(
    axios(buildRequestConfig({
        method: 'delete',
        url,
        timeout: ApiConfig.timeout,
        headers,
    })),
);

/**
 * Key mapping
 * @param key
 * @returns {*}
 */
const filterKeyMapping = (key) => {
    if (key === 'startingAfter') return 'starting_after';
    return key;
};

/**
 * Constructs url with given endpoint and action
 * @param endpoint
 * @param action
 * @returns String
 */
export function constructUrl({ endpoint, fieldsEnums, ...kwargs }) {
    // Replace endpoint :params: with data
    const re = /(:\w+:)/g;
    const params = endpoint.match(re);
    let url = endpoint;
    if (params) {
        params.forEach((paramType) => {
            const param = paramType.replace(/:/g, '');
            url = url.replace(paramType, kwargs.data[param] || '');
        });
    }

    // Append requested filters to url
    const filterKeys = kwargs.filters ? Object.keys(kwargs.filters) : [];
    const hasFilters = filterKeys.length > 0;

    if (hasFilters) {
        let queryParamsString = '?';
        const queryParams = [];
        for (let a = 0; a < filterKeys.length; a += 1) {
            const key = filterKeys[a];
            const filterKey = filterKeyMapping(key);
            const val = kwargs.filters[key];
            if (filterKey.endsWith('[]')) {
                const filterKeyTmp = filterKey.substring(0, filterKey.length - 2);
                val.forEach(v => queryParams.push(`${filterKeyTmp}=${v}`));
            }
            else {
                queryParams.push(`${filterKey}=${val}`);
            }
        }
        queryParamsString += queryParams.join('&');
        url += queryParamsString;
    }

    // Append requested fields to url
    if (kwargs.fields) {
        let queryParamsString = hasFilters ? '&' : '?';
        const key = 'fields';

        const fields = [];
        kwargs.fields.forEach((field) => {
            if (fieldsEnums[field]) {
                fields.push(fieldsEnums[field]);
            }
        });
        if (fields) {
            const val = fields.join(',');
            queryParamsString += `${key}=${val}`;
            url += queryParamsString;
        }
    }

    if (ApiConfig.siteUrl) {
        return ApiConfig.siteUrl + url;
    }

    return url;
}

/**
 * Load Api Data
 */
export function* reader({ apiAction, actionKey, ...kwargs }) {
    // Create URL
    const url = constructUrl({ endpoint: apiAction.endpoint, fieldsEnums: apiAction.fieldsEnums, ...kwargs });

    // Get headers
    const headers = yield getHeaders({ ...kwargs, url });

    // Fetch data from the server
    if (ApiConfig.apiDebugOutput) {
        // eslint-disable-next-line no-console
        console.log('Get', url, headers, kwargs.data);
    }
    return yield call(Get, url, headers);
}

/**
 * Create Api object
 */
export function* creator({ apiAction, actionKey, ...kwargs }) {
    // Create URL
    const url = constructUrl({ endpoint: apiAction.endpoint, fieldsEnums: apiAction.fieldsEnums, ...kwargs });

    // Get headers
    const headers = yield getHeaders({ ...kwargs, url });

    if (kwargs.rawData) {
        if (ApiConfig.apiDebugOutput) {
            // eslint-disable-next-line no-console
            console.log('Post RAW', url, headers);
        }
        return yield call(Post, url, headers, kwargs.rawData);
    }

    if (ApiConfig.apiDebugOutput) {
        // eslint-disable-next-line no-console
        console.log('Post', url, headers, kwargs.data);
    }
    return yield call(Post, url, headers, kwargs.data);
}

/**
 * Update Api object
 */
export function* updater({ apiAction, actionKey, ...kwargs }) {
    // Create URL
    const url = constructUrl({ endpoint: apiAction.endpoint, fieldsEnums: apiAction.fieldsEnums, ...kwargs });

    // Get headers
    const headers = yield getHeaders({ ...kwargs, url });

    if (ApiConfig.apiDebugOutput) {
        // eslint-disable-next-line no-console
        console.log('Put', url, headers, kwargs.data);
    }
    return yield call(Put, url, headers, kwargs.data);
}

/**
 * Delete Api object
 */
export function* deleter({ apiAction, actionKey, ...kwargs }) {
    // Create URL
    const url = constructUrl({ endpoint: apiAction.endpoint, fieldsEnums: apiAction.fieldsEnums, ...kwargs });

    // Get headers
    const headers = yield getHeaders({ ...kwargs, url });

    if (ApiConfig.apiDebugOutput) {
        // eslint-disable-next-line no-console
        console.log('Delete', url, headers);
    }
    return yield call(Delete, url, headers);
}
