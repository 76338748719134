import React, { useCallback } from 'react';
import environment from 'environment';
import { Events, track } from 'utils/analytics';
import { Button } from 'components/buttons';
import { ZendeskToggleState } from '../../constants';
import { ZendeskButton, ZendeskMessageIcon } from './Zendesk.style';

// Script tag has already been created?
const scriptId = 'ze-snippet';

const zendeskScriptLoaded = () => !!document.getElementById(scriptId);

const injectZendeskScript = (onOpen, onClose) => {
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${environment.vars.zendesk.key}`;
    script.onload = () => {
        const VIRTUAL_AGENT_NAME = 'Bot';
        const HUMAN_DEPARTMENT_NAME = 'Customer Support (ALL)';

        window.zE(() => {
            window.$zopim(() => {
                // Once Zendesk/Zopim has loaded hide button
                window.$zopim.livechat.hideAll();
                // But show chat window
                window.zE.activate();
            });
        });
        // using window.zE('webWidget:on', 'open', onOpen); didn't work on the initial click when first loading in the script
        // so have to rely on the 'userEvent' to set the toggle state of the Zendesk
        window.zE('webWidget:on', 'userEvent', (event) => {
            if (event && event.action === 'Web Widget Opened') {
                onOpen();
            }
        });

        window.zE('webWidget:on', 'chat:departmentStatus', (dept) => {
            if (dept.name === VIRTUAL_AGENT_NAME && dept.status === 'online') {
                window.zE('webWidget', 'updateSettings', {
                    webWidget: {
                        chat: {
                            departments: {
                                enabled: [''],
                                select: VIRTUAL_AGENT_NAME,
                            },
                        },
                    },
                });
            } else if (dept.name === VIRTUAL_AGENT_NAME && dept.status !== 'online') {
                window.zE('webWidget', 'updateSettings', {
                    webWidget: {
                        chat: {
                            departments: {
                                enabled: [''],
                                select: HUMAN_DEPARTMENT_NAME,
                            },
                        },
                    },
                });
            }
        });

        window.zE('webWidget:on', 'close', onClose);
    };
    document.head.appendChild(script);
};

const Zendesk = ({ zendeskToggle, setZendeskToggle }) => {
    const triggerOpen = useCallback(() => {
        if (zendeskToggle !== ZendeskToggleState.CLOSED) return;

        // Load script if not already present in the DOM
        if (!zendeskScriptLoaded()) {
            setZendeskToggle(ZendeskToggleState.OPENING);

            injectZendeskScript(
                () => {
                    setZendeskToggle(ZendeskToggleState.OPEN);
                },
                () => {
                    setZendeskToggle(ZendeskToggleState.CLOSED);
                }
            );
        } else if (window.zE) {
            // Already loaded so just show chat window
            window.zE.activate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zendeskToggle]);

    return (
        <ZendeskButton
            variant={Button.Variant.SECONDARY}
            loading={zendeskToggle !== ZendeskToggleState.CLOSED}
            onClick={() => {
                track(
                    Events.BUTTONCLICK.create({
                        description: 'Contact bubble CTA',
                        item: 'zendesk',
                    })
                );
                triggerOpen();
            }}
        >
            <ZendeskMessageIcon />
        </ZendeskButton>
    );
};

export default Zendesk;
