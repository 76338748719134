import routePaths from './route-paths';
import Sitemap from '.';

export default [
    {
        path: routePaths.sitemap,
        exact: true,
        component: Sitemap,
    },
];
