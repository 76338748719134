import * as React from 'react';

import * as Rating from '../components';

function InteractiveRating({ value, max, onChange }) {
    return (
        <Rating.Root initialValue={value} max={max}>
            <Rating.Container>
                {({ rating, isHighlighted, shouldAnimate }) => (
                    <Rating.Button rating={rating} shouldAnimate={shouldAnimate} onClick={onChange}>
                        <Rating.Icon isHighlighted={isHighlighted} />
                    </Rating.Button>
                )}
            </Rating.Container>
        </Rating.Root>
    );
}

InteractiveRating.defaultProps = {
    value: 0,
    max: 5,
    onChange: () => {},
};

export default InteractiveRating;
