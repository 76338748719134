import { v3ApiUrl, locisApiUrl } from './endpoints/constants';
import endpoints from './endpoints';

// This is used as a singleton for action config, populated on runtime
export const ACTIONS_CONFIG_CACHE = {};

// Whitelisted headers for specific endpoints
const allowedHeaders = {
    [locisApiUrl]: ['origin', 'content-type', 'api-version'],
};

// The XMLHttpRequest.withCredentials property
const withCredentials = {
    [v3ApiUrl]: true,
    [locisApiUrl]: true,
};

// Current supported api Version
const API_VERSION = '2019-10-23';

// Default request timeout
const DEFAULT_REQUEST_TIMEOUT = 15000;

// Actions prefix
const ACTIONS_PREFIX = '@THS_API';

// Redux store prefix
const REDUX_STORE_PREFIX_PATH = [];

// Default API cache
const cache = {};

// Axios httpAgents overrides
const HTTP_AGENTS = {};

// Client overridable settings
export default {
    siteUrl: null,
    endpoints,
    mockProvider: null,
    mockLoadingDelay: false,
    apiKey: null,
    jwtHeaderName: 'Authorization',
    platformDetail: null,
    platformDetailHeaderName: 'THS-Platform-Detail',
    customHeaders: {},
    apiDebugOutput: false,
    withCredentials,
    timeout: DEFAULT_REQUEST_TIMEOUT,
    allowedHeaders,
    cache,
    reduxStorePrefixPath: REDUX_STORE_PREFIX_PATH,
    actionsPrefix: ACTIONS_PREFIX,
    apiVersion: API_VERSION,
    httpAgents: HTTP_AGENTS,
    clientIsApp: false,
};
