import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import * as botd from '@fingerprintjs/botd';
import { identify, trackPage } from 'utils/analytics';
import {
    getAccountCard,
    getAccountCurrentMembershipPlan,
    getAccountDetails,
    getAccountSettings,
} from 'api/selectors';
import { logVariation, LogVariationAction } from 'containers/ExperimentalFeature/actions';
import { getItem } from 'src/universalStorage/selectors';
import { UTM_SOURCE_KEY } from 'src/universalStorage/constants';
import {
    getIsGetSiteControlEnabled,
    getSiteControlIdentify,
    initialiseGetSiteControl,
} from 'utils/integrations/getSiteControl';
import {
    initializeCommissionJunctionTracking,
    isCommissionJunctionEnabled,
    setCjeCookie,
} from 'utils/integrations/commissionJunction';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
    userTypes,
    UTM_SOURCES,
} from 'containers/ExperimentalFeature';
import * as actions from './actions';
import { isEventLimited, isViewingInAppWebView } from './selectors';

// Handles heatmap targeting from experiments features
export function* handleViewedVariationToHotjar({ name, variation }) {
    if (typeof window !== 'undefined') {
        if (Object.prototype.hasOwnProperty.call(window, 'hj')) {
            const eventName = `viewed_test_${name}_${variation}`;
            yield call(window.hj, 'event', eventName);
        }
    }
}

export function* triggerCommissionJunctionTracking() {
    const account = yield select(getAccountDetails);
    const membership = yield select(getAccountCurrentMembershipPlan);
    yield select(setCjeCookie);
    yield put(actions.cjTrackingTriggered.create({ ...account, ...membership }));
}

export function* trackThirdPartyScripts() {
    try {
        if (isCommissionJunctionEnabled()) {
            yield call(triggerCommissionJunctionTracking);
        }
    } catch (e) {
        Sentry.captureException(e);
    }
}

export function* trackPageChange(action) {
    yield all([fork(trackPage, action.event), fork(trackThirdPartyScripts)]);

    yield put(actions.eventTriggered.create(action.type));
}

/**
 * POC: web-BAT-1068
 * Try the initialize botd and detect if the current user is likely
 * to be a bot.
 *
 * @see https://github.com/fingerprintjs/BotD
 */
export function* tryDetectBot() {
    try {
        const response = (yield call(botd.load)).detect();

        return response.bot;
    } catch (_) {
        return undefined;
    }
}

export function* identifyUser(action) {
    const { force } = action;
    const isLimited = yield select(isEventLimited, action.type);

    if (force || !isLimited) {
        const account = yield select(getAccountDetails);
        const membership = yield select(getAccountCurrentMembershipPlan);
        const card = yield select(getAccountCard);
        const settings = yield select(getAccountSettings);
        const isLikelyABot = yield call(tryDetectBot);

        identify({
            account,
            membership,
            card,
            settings,
            isLikelyAHumanFromBotd: typeof isLikelyABot === 'boolean' ? !isLikelyABot : undefined,
        });

        yield put(actions.eventTriggered.create(action.type));
    }
}

export function* getGSCExperiments() {
    let getSiteControlExperiments = {};
    const utmSource = yield select(getItem, UTM_SOURCE_KEY);
    const webExpOwnerFreeTrial = yield select(getExperimentalFeatureVariationSelector, {
        experiment: experiments.FREE_TRIAL_OWNER_V2,
        excludeUTMSources: [UTM_SOURCES.CJ, UTM_SOURCES.IMPACT],
        utmSource: utmSource?.name,
        excludeCombo: [
            userTypes.PaidUser,
            userTypes.ExpiredUser,
            userTypes.SitterUser,
            userTypes.CombinedUser,
        ],
    });

    const webExpSitterFreeTrial = yield select(getExperimentalFeatureVariationSelector, {
        experiment: experiments.FREE_TRIAL_SITTER_V1,
        excludeUTMSources: [UTM_SOURCES.CJ, UTM_SOURCES.IMPACT],
        utmSource: utmSource?.name,
        excludeCombo: [
            userTypes.PaidUser,
            userTypes.ExpiredUser,
            userTypes.PartialOwner,
            userTypes.PartialCombined,
        ],
    });

    getSiteControlExperiments = {
        webExpOwnerFreeTrial: webExpOwnerFreeTrial.variation,
        webExpSitterFreeTrial: webExpSitterFreeTrial.variation,
    };

    return getSiteControlExperiments;
}

export function* triggerGetSiteControl({ initialiseGSC = false, experiment = {} }) {
    const isInAppWebView = yield select(isViewingInAppWebView);
    const isGetSiteControlEnabled = yield select(getIsGetSiteControlEnabled);
    const membership = yield select(getAccountCurrentMembershipPlan);
    const card = yield select(getAccountCard);
    const accountDetails = yield select(getAccountDetails);
    const { joinDate } = accountDetails;
    // Check to see if GetSiteControl is enabled via feature flag
    // Check to see if we're in the app web view
    if (isGetSiteControlEnabled && !isInAppWebView) {
        // Initialise GetSiteControl script
        if (initialiseGSC) {
            yield call(initialiseGetSiteControl);
        }

        // Send membership info to GetSiteControl
        yield call(getSiteControlIdentify, membership, card, joinDate, experiment);
    }
    return true;
}

export default function* pageSaga() {
    yield spawn(initializeCommissionJunctionTracking);
    yield all([
        takeEvery(actions.identify.ACTION, identifyUser),
        takeEvery(actions.trackPage.ACTION, trackPageChange),
        takeEvery(logVariation.ACTION, handleViewedVariationToHotjar),
    ]);
}
