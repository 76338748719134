import { combineReducers } from 'redux';
import areas, { initialState as areasInitialState } from './areas';
import categories, { initialState as categoriesInitialState } from './categories';
import posts, { initialState as postsInitialState } from './posts';
import tags, { initialState as tagsInitialState } from './tags';
import subcategories, { initialState as subcategoriesInitialState } from './subcategories';
import authors, { initialState as authorsInitialState } from './authors';

export const initialState = {
    areas: areasInitialState,
    categories: categoriesInitialState,
    posts: postsInitialState,
    tags: tagsInitialState,
    subcategories: subcategoriesInitialState,
    authors: authorsInitialState,
};

export default combineReducers({
    areas,
    categories,
    posts,
    tags,
    subcategories,
    authors,
});
