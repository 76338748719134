import styled, { css } from 'styled-components';
import { P, H2, H3, H4 } from 'typography';
import { IconCancellationInsurance, IconTravel } from 'components/Icon';
import Dialog from 'components/Dialog';
import Link from 'components/Link';
import { Button } from 'components/buttons';
import { ReactComponent as IconPets1 } from './assets/pets1.svg';
import { ReactComponent as IconPets3 } from './assets/pets3.svg';

export const DialogStyled = styled(Dialog)(
    ({ theme: { screenSize, palette, borderRadius } }) => css`
        max-width: 100%;
        max-width: unset;
        border-radius: ${borderRadius.small};
        background-color: ${palette.grey[100]};

        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            max-width: 1176px;
        }
    `
);

export const ModalContents = styled.div(
    ({ theme: { spacing, screenSize, palette }, isFeatureEnabled }) => css`
        padding: ${spacing[4]} ${spacing[2]};
        margin: ${isFeatureEnabled ? '0 0' : `0 auto`};
        overflow: scroll;
        background-color: ${palette.grey[100]};

        @media screen and (min-width: 672px) {
            padding: ${spacing[6]};
        }
        @media screen and (min-width: ${screenSize.largeDesktop}) {
            padding: ${spacing[6]} 148px;
        }
    `
);

export const ModalTitle = styled(H2)(
    ({ theme: { fontSizes, spacing, palette, screenSize, fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        line-height: 1.5;
        color: ${palette.text};
        margin: 0 0 ${spacing[3]} 0;
        text-align: center;
        font-size: ${fontSizes.large};

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            font-size: ${fontSizes.xlarge};
        }
    `
);

export const ModalSubTitle = styled(H3)(
    ({ theme: { fonts, fontSizes, screenSize } }) => css`
        font-family: ${fonts.default};
        font-weight: normal;
        text-align: center;
        font-size: ${fontSizes.medium};

        // 1024px
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            font-size: ${fontSizes.medLarge};
        }
    `
);

export const FeatureCardWrapper = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        margin: ${spacing[4]} auto 0;
        max-width: 500px;

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            max-width: 800px;
        }

        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            max-width: 1000px;
        }
    `
);

export const FeatureCard = styled.div(
    ({ theme: { spacing, borderRadius, screenSize, palette } }) => css`
        background-color: ${palette.white};
        width: 272px;
        padding: ${spacing[4]};
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.12);
        border-radius: ${borderRadius.regular};
        margin-bottom: ${spacing[4]};

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            &:nth-child(2) {
                margin-left: ${spacing[4]};
            }
        }

        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            &:nth-child(3) {
                margin-left: ${spacing[4]};
            }
        }
    `
);

const commonIconStyles = `
    width: unset;
    height: unset;
    display: block;

    svg {
        width: 48px;
        height: 48px;
    }
`;

export const IconPets1Styled = styled(IconPets1)(
    () => css`
        ${commonIconStyles}
    `
);

export const IconPets3Styled = styled(IconPets3)(
    () => css`
        ${commonIconStyles}
    `
);

export const IconCancellationInsuranceStyled = styled(IconCancellationInsurance)(
    () => css`
        ${commonIconStyles}
    `
);

export const IconTravelStyled = styled(IconTravel)(
    () => css`
        ${commonIconStyles}
    `
);

export const CardTitle = styled(H4)(
    ({ theme: { spacing, typography } }) => css`
        ${typography.h3}
        font-size: 16px;
        min-height: 45px;
        margin-bottom: ${spacing[1]};
    `
);

export const CardText = styled(P)(
    ({ theme: { typography, spacing } }) =>
        css`
            ${typography.smallText};
            margin-top: ${spacing[1]};
        `
);

export const CardLinkStyled = styled(Link)(
    () => css`
        width: 100%;
    `
);

export const ModalFooter = styled.div(
    () =>
        css`
            text-align: center;
        `
);

export const ButtonStyled = styled(Button)(
    ({ theme: { spacing } }) =>
        css`
            padding-left: ${spacing[4]};
            padding-right: ${spacing[4]};
        `
);
