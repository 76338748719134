import apiActions, { settings } from 'api/actions';
import {
    error as errorAction,
    preload as pageActionsPreload,
    lazyload as pageActionsLazyload,
    preloaded as pageActionsPreloaded,
    lazyloaded as pageActionsLazyloaded,
} from 'containers/Page/actions';
import { all, call, put, take, takeEvery } from 'redux-saga/effects';
import { track, Events } from 'utils/analytics';
import { PAGE_ID } from './Refund.constants';

const pageActionsMapped = {
    [pageActionsPreload.ACTION]: pageActionsPreloaded,
    [pageActionsLazyload.ACTION]: pageActionsLazyloaded,
};

export function* loadRefundStatus(pageId) {
    yield put(
        apiActions.account.loadRefundStatus({
            forceReload: true,
        })
    );

    // Wait for results
    const { status, statusCode, data } = yield take(
        (res) => res.type === settings.account.loadRefundStatus.DONE
    );

    if (status !== settings.account.loadRefundStatus.SUCCESS) {
        yield put(errorAction.create(pageId, statusCode));
        return false;
    }

    // analytics to track whether user is eligible for a refund
    track(
        Events.CHECK_REFUND_STATUS.create({
            isEligible: data.isEligible,
            notEligibleReason: data.notEligibleReason,
        })
    );

    return true;
}

export function* load(action) {
    yield call(loadRefundStatus, action.type, action.pageId);
    yield put(pageActionsMapped[action.type].create(action.pageId));
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsLazyload.ACTION && action.pageId === PAGE_ID,
            load
        ),
    ]);
}
