import { v3ApiUrl } from './constants';

export default {
    // Owner summary?
    _: `${v3ApiUrl}owner/`,

    // Favourite profiles
    favourites: {
        // GET, POST (profile_id): Favourite profiles
        _: `${v3ApiUrl}owner/favourites/`,

        // GET, DELETE
        favourite: `${v3ApiUrl}owner/favourites/:id:/`,
    },

    // Sitter profiles
    sitter: {
        profile: {
            // GET (id): Sitter profile details
            _: `${v3ApiUrl}owner/sitter/:id:/profile/`,
        },

        // GET (id): Get all applications related to owner listings assignments, sitter applied to
        applications: `${v3ApiUrl}owner/sitter/:id:/applications/`,

        // GET (id): Get all invitations related to owner listings assignments, sitter was invited for
        invitations: `${v3ApiUrl}owner/sitter/:id:/invitations/`,

        // GET (id): Get all past assignments sitter did for your
        pastAssignments: `${v3ApiUrl}owner/sitter/:id:/assignments/`,
    },

    // @TODO - Is this needed?
    // GET: Owner feedbacks
    feedbacks: `${v3ApiUrl}owner/feedbacks/`,

    // @TODO - Is this needed?
    // GET: Owner reviews
    reviews: `${v3ApiUrl}owner/reviews/`,

    assignments: {
        assignment: {
            // GET assignment status
            _: `${v3ApiUrl}owner/assignments/:id:/`,

            // POST: Request assignment welcome guide
            welcomeGuideDetails: `${v3ApiUrl}owner/assignments/:assignmentId:/welcome-guide-details/`,

            // GET: Assignment events
            reminders: `${v3ApiUrl}owner/assignments/:id:/reminders/`,
        },

        confirmed: {
            // GET: Page 1 the bottom group
            _: `${v3ApiUrl}owner/assignments/confirmed/`,

            // GET ?page: Current assignments
            current: `${v3ApiUrl}owner/assignments/confirmed/current/`,

            // GET ?page: Upcoming assignments
            upcoming: `${v3ApiUrl}owner/assignments/confirmed/upcoming/`,

            // GET ?page: Past assignments
            past: `${v3ApiUrl}owner/assignments/confirmed/past/`,
        },

        // GET ?page
        open: `${v3ApiUrl}owner/assignments/open/`,

        // GET ?page
        deleted: `${v3ApiUrl}owner/assignments/deleted/`,

        // GET ?page
        unconfirmed: {
            past: `${v3ApiUrl}owner/assignments/unconfirmed/past/`,
        },
    },

    listings: {
        // GET, POST: Listings
        _: `${v3ApiUrl}owner/listings/`,

        listing: {
            // GET, PUT: Listing details
            _: `${v3ApiUrl}owner/listings/:id:/`,

            // POST
            publish: `${v3ApiUrl}owner/listings/:id:/publish/`,

            // POST
            review: `${v3ApiUrl}owner/listings/:listingId:/submission/review/`,

            welcomeGuide: {
                // GET, POST, PUT: Listing welcome guide
                _: `${v3ApiUrl}owner/listings/:listingId:/welcome-guide/`,

                photos: {
                    // GET
                    _: `${v3ApiUrl}owner/listings/:listingId:/welcome-guide/photos/`,

                    // POST, DELETE
                    photo: `${v3ApiUrl}owner/listings/:listingId:/welcome-guide/photos/:id:`,
                },
            },

            confirmedAssignments: {
                // GET: Summary of everything below
                _: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/`,

                current: {
                    // GET ?page: Current assignment
                    _: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/current/`,

                    // GET
                    assignment: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/current/:id:/`,
                },
                upcoming: {
                    // GET ?page: Upcoming assignments
                    _: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/upcoming/`,

                    // GET
                    assignment: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/upcoming/:id:/`,

                    // POST
                    notifyUnconfirmed: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/upcoming/:id:/batch-messages/`,
                },
                past: {
                    // GET ?page: Past assignments
                    _: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/past/`,

                    assignment: {
                        // GET
                        _: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/past/:id:/`,

                        // GET, POST: Assignment feedback
                        feedback: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/past/:assignmentId:/feedback/`,

                        // GET, POST: Request review
                        feedbackRequest: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/past/:assignmentId:/feedback-request/`,

                        // GET, PUT: Assignment review
                        review: `${v3ApiUrl}owner/listings/:listingId:/assignments/confirmed/past/:assignmentId:/review/`,
                    },
                },
            },

            openAssignments: {
                // GET ?page, POST: All public/private mixed assignments ordered by start date, but not confirmed
                _: `${v3ApiUrl}owner/listings/:listingId:/assignments/open/`,
                assignment: {
                    // GET
                    _: `${v3ApiUrl}owner/listings/:listingId:/assignments/open/:id:/`,

                    // GET, POST (invite)
                    applications: {
                        _: `${v3ApiUrl}owner/listings/:listingId:/assignments/:assignmentId:/applications/`,
                        // PUT
                        application: `${v3ApiUrl}owner/listings/:listingId:/assignments/open/:assignmentId:/applications/:id:/`,
                    },

                    // POST Sitter profile invitation
                    invitation: `${v3ApiUrl}owner/listings/:listingId:/assignments/open/:assignmentId:/invitation/`,

                    // POST to boost assignment
                    boost: {
                        _: `${v3ApiUrl}owner/listings/:listingId:/assignments/open/:assignmentId:/boost/`,
                    },
                },
            },

            futureAssignments: {
                // GET: All public/private mixed assignments ordered by start date, open & confirmed
                _: `${v3ApiUrl}owner/listings/:listingId:/assignments/future/`,
            },

            assignments: {
                assignment: {
                    // GET
                    _: `${v3ApiUrl}owner/listings/:listingId:/assignments/:id:/`,

                    applications: {
                        // GET assignment applications
                        _: `${v3ApiUrl}owner/listings/:listingId:/assignments/:assignmentId:/applications/`,

                        // PUT assignment application
                        application: `${v3ApiUrl}owner/listings/:listingId:/assignments/:assignmentId:/applications/:id:/`,
                    },

                    invitations: {
                        // POST, GET assignment invitations
                        _: `${v3ApiUrl}owner/listings/:listingId:/assignments/:assignmentId:/invitations/`,

                        // POST: assignment invitations
                        // eslint-disable-next-line max-len
                        // batch: `${v3ApiUrl}owner/listings/:listingId:/assignments/:assignmentId:/invitations/batch/`,

                        // PUT Sitter profile invitation
                        invitation: `${v3ApiUrl}owner/listings/:listingId:/assignments/:assignmentId:/invitations/:id:/`,
                    },
                },
            },

            invitations: {
                // POST: assignment invitations
                batch: `${v3ApiUrl}owner/listings/:listingId:/invitations/batch/`,
            },

            photos: {
                // GET, POST: Listing medias
                _: `${v3ApiUrl}owner/listings/:listingId:/photos/`,

                // GET, PUT, DELETE: Listing medias
                photo: `${v3ApiUrl}owner/listings/:listingId:/photos/:photoId:/`,
            },

            reminders: {
                // GET, POST: Listing reminders
                _: `${v3ApiUrl}owner/listings/:listingId:/reminders/`,

                // GET, PUT, DELETE:
                reminder: `${v3ApiUrl}owner/listings/:listingId:/reminders/:reminderId:/`,
            },

            pets: {
                // GET, POST: Listing pets
                _: `${v3ApiUrl}owner/listings/:listingId:/pets/`,

                // GET, PUT, DELETE: Listing pet
                pet: `${v3ApiUrl}owner/listings/:listingId:/pets/:petId:/`,

                // GET: Listing pet photos
                photos: `${v3ApiUrl}owner/listings/:listingId:/pets/:id:/photos/`,

                // GET, PUT, DELETE: Listing pet photos
                photo: `${v3ApiUrl}owner/listings/:listingId:/pets/:petId:/photos/:photoId:/`,
            },

            placesOfInterest: {
                // GET, POST: Listing places of interest
                _: `${v3ApiUrl}owner/listings/:listingId:/places-of-interest/`,
            },

            address: {
                // GET, POST, PUT: home address
                _: `${v3ApiUrl}owner/listings/:listingId:/address/`,
            },

            guide: {
                // GET, PUT, POST: Listing guide,guide,extra information
                _: `${v3ApiUrl}owner/listings/:listingId:/guide/`,

                medias: {
                    // GET, POST: Guide media
                    _: `${v3ApiUrl}owner/listings/:listingId:/guide/medias/`,

                    // PUT, DELETE
                    media: `${v3ApiUrl}owner/listings/:listingId:/guide/medias/:id:/`,
                },
            },

            amenities: {
                // GET, POST, PUT: Lisiting amenities
                _: `${v3ApiUrl}owner/listings/:listingId:/amenities/`,
            },
        },
    },

    conversations: {
        // GET, POST: Conversations ?page
        _: `${v3ApiUrl}owner/conversations/:contactId:/`,

        // POST: batch applications
        batch: `${v3ApiUrl}owner/conversations/batch/`,
    },
};
