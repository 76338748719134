import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useExperimentalFeature } from 'hooks/useExperimentalFeature';
import {
    Button,
    ErrorMessage,
    FacebookLoginButton,
    ModalContent,
    ModalHeader,
    ModalHeaderCloseButton,
    useTrail,
} from '@ths/design-system';
import { MembershipType } from 'api/types';
import RegwallMessaging from 'components/Regwall/Regwall.constants';
import EmailForm from 'components/Regwall/components/EmailForm';
import { EmailIcon } from '@ths/design-system/icons';
import IFrameEvent from 'components/Regwall/components/IFrameEvent';
import WordDivider from 'components/Regwall/components/WordDivider';
import Feature, { features } from 'components/Feature';
import ExperimentalFeature, { experiments, VariationTypes } from 'containers/ExperimentalFeature';
import Register from 'containers/forms/Register';
import useFacebook from 'hooks/useFacebook';
import useGoogle from 'hooks/useGoogle';
import { Events, track } from 'utils/analytics';
import { STEPS, RegwallTestIds } from '../../RegwallDialog.constants';
import { useRegwallDialog } from '../../useRegwallDialog';
import PrivacyPolicy from '../PrivacyPolicy';
import AlreadyHaveAccount from '../AlreadyHaveAccount';

const useRegwallCopy = ({ messaging, membershipType, regwallCopy }) =>
    useMemo(() => {
        const membershipTypeHeading = membershipType || MembershipType.COMBINED;
        let heading =
            messaging === RegwallMessaging.REGISTER
                ? `components_regwall_heading_${membershipTypeHeading}_register`
                : `components_regwall_heading_${membershipTypeHeading}_join`;
        let subheading =
            messaging === RegwallMessaging.REGISTER
                ? `components_regwall_simpler_body_${membershipType}_register`
                : `components_regwall_simpler_body_${membershipType}_join`;

        if (regwallCopy && Object.keys(regwallCopy).length) {
            if (regwallCopy.heading && regwallCopy.heading.length > 0) {
                heading = regwallCopy.heading;
            }
            if (regwallCopy.subheading && regwallCopy.subheading.length > 0) {
                subheading = regwallCopy.subheading;
            }
        }

        return {
            heading,
            subheading,
        };
    }, [messaging, membershipType, regwallCopy]);

const InitialFormStep = () => {
    const { t } = useTranslation();
    const [showFacebookEmailError, setShowFacebookEmailError] = useState(null);
    const { push } = useTrail();
    const {
        selectedMembershipType,
        shouldSetMembershipType,
        membershipPlan,
        messaging,
        regwallCopy,
        openMethod,
        isOpen,
        setThirdPartyState,
        isCloseable,
        membershipType,
    } = useRegwallDialog();
    const googleData = useGoogle();
    const [getAccessToken, getUserData, isFBInitialised, hasGrantedEmailPermissions] =
        useFacebook();

    const { heading, subheading } = useRegwallCopy({
        messaging,
        membershipType: selectedMembershipType,
        regwallCopy,
    });

    const [twoStepRegwallVariation] = useExperimentalFeature(experiments.TWO_STEP_REGWALL);
    const isControl = twoStepRegwallVariation === VariationTypes.CONTROL;

    useEffect(() => {
        if (isOpen) {
            if (isControl) {
                track(
                    Events.VIEWED_REGWALL.create({
                        membershipType,
                        method: openMethod,
                    })
                );
            } else {
                track(
                    Events.VIEWED_REGWALL.create({
                        membershipType,
                        method: openMethod,
                        regwallStep: 'Step1',
                    })
                );
            }
        }
    }, [isControl, isOpen, membershipType, openMethod]);

    const handleEmailSignupClick = () => {
        track(
            Events.BUTTONCLICK.create({
                description: 'Sign up with email',
            })
        );
        push(STEPS.EMAIL_FORM);
    };

    const handleFacebookSignupClick = () => {
        // If the user has logged in but denied us email permissions
        //  we need to reauth the login process
        let reauth = false;

        if (showFacebookEmailError) reauth = true;

        // You might not see this event in the segment extension. But you can see it in the network tab
        track(
            Events.BUTTONCLICK.create({
                description: 'Sign up with Facebook',
            })
        );

        getAccessToken(reauth).then((accessToken) => {
            getUserData(accessToken).then((result) => {
                if (hasGrantedEmailPermissions(result)) {
                    setThirdPartyState((prevState) => ({
                        ...prevState,
                        method: Register.Method.FACEBOOK,
                        data: result,
                    }));

                    push(STEPS.THIRD_PARTY_FORM);
                } else {
                    setShowFacebookEmailError(true);
                }
            });
        });
    };

    useEffect(() => {
        if (googleData?.success) {
            setThirdPartyState((prevState) => ({
                ...prevState,
                method: Register.Method.GOOGLE,
                data: googleData.userData,
            }));
            push(STEPS.THIRD_PARTY_FORM);
        }
    }, [push, setThirdPartyState, googleData]);

    return (
        <>
            <ModalHeader>{isCloseable && <ModalHeaderCloseButton />}</ModalHeader>

            <ModalContent>
                <div className="mb-8">
                    <h1 className="m-0 mb-2 p-0 text-center text-2xl font-bold text-primary-700 lg:mb-6 lg:text-[2.5rem]">
                        {t(heading)}
                    </h1>
                    {!shouldSetMembershipType && (
                        <p className="m-0 p-0 text-center text-grey-500">{t(subheading)}</p>
                    )}
                </div>

                <div
                    className={clsx(
                        'mx-auto mb-8 flex min-h-[168px] flex-col items-center justify-center gap-4'
                    )}
                >
                    <IFrameEvent eventName="Sign up with Google" sendOnce={false}>
                        <div
                            id="google-sign-up-button"
                            className="max-h-[40px]"
                            data-testid={RegwallTestIds.googleButton}
                        />
                    </IFrameEvent>

                    {googleData?.error?.length > 0 && (
                        <ErrorMessage>{t(googleData.error)}</ErrorMessage>
                    )}

                    <Feature
                        name={features.FACEBOOK_SIGNUP}
                        on={
                            <div className="w-[342px]">
                                <FacebookLoginButton
                                    onClick={handleFacebookSignupClick}
                                    disabled={!isFBInitialised}
                                >
                                    {t('components_regwall_signUpWithFacebook')}
                                </FacebookLoginButton>
                            </div>
                        }
                        off={null}
                    />

                    {showFacebookEmailError && (
                        <ErrorMessage>
                            {t('components_regwall_facebookEmailPermissionError')}
                        </ErrorMessage>
                    )}

                    <ExperimentalFeature
                        analytics={false}
                        experiment={experiments.TWO_STEP_REGWALL}
                        control={null}
                        variation1={
                            <div className="w-[342px]">
                                <Button
                                    variant="accent"
                                    isFullwidth
                                    onClick={handleEmailSignupClick}
                                >
                                    <EmailIcon width={20} height={20} />
                                    {t('components_regwall_signUpWithEmail')}
                                </Button>
                            </div>
                        }
                    />
                </div>

                <ExperimentalFeature
                    analytics={false}
                    experiment={experiments.TWO_STEP_REGWALL}
                    control={
                        <Register
                            membershipType={selectedMembershipType}
                            membershipPlan={membershipPlan}
                            method={Register.Method.EMAIL}
                            signupOrigin={Register.SignupOrigin.REGWALL}
                            openMethod={openMethod}
                            data-testid={RegwallTestIds.register}
                        >
                            {({
                                createAccount,
                                errorMessage,
                                clearError,
                                validateEmail,
                                isSubmitting,
                            }) => (
                                    <>
                                        <WordDivider data-testid={RegwallTestIds.divider}>
                                            {t('components_regwall_or')}
                                        </WordDivider>

                                        <EmailForm
                                            membershipType={selectedMembershipType}
                                            setMembershipType={shouldSetMembershipType}
                                            clearError={clearError}
                                            onSubmit={(submittedDetails) => {
                                                createAccount(submittedDetails);
                                            }}
                                            errorMessage={errorMessage}
                                            validateEmail={validateEmail}
                                            isSubmitting={isSubmitting}
                                        />
                                    </>
                                )}
                        </Register>
                    }
                    variation1={null}
                />

                <ExperimentalFeature
                    analytics={false}
                    experiment={experiments.TWO_STEP_REGWALL}
                    control={
                        <PrivacyPolicy
                            dataTestId="privacy-policy-marketing-opt-in-copy"
                            className="mb-8"
                        />
                    }
                    variation1={null}
                />

                <AlreadyHaveAccount />
            </ModalContent>
        </>
    );
};

export default InitialFormStep;
