import { settings } from 'api/actions';
import * as popupModalActions from './components/PopupModal/actions';
import { responseStatuses } from './Hub.constants';

const defaultState = {
    responseStatus: 200,
    updateId: 0,
    popupModal: {
        shouldOpen: false,
        addedDates: [],
    },
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case settings.owner.sendListingForReview.FAILURE: {
            return {
                ...state,
                responseStatus: responseStatuses.FAILURE,
                updateId: state.updateId + 1,
            };
        }
        case settings.owner.sendListingForReview.SUCCESS: {
            return {
                ...state,
                responseStatus: responseStatuses.SUCCESS,
                updateId: state.updateId + 1,
            };
        }
        case popupModalActions.updateDates.ACTION: {
            if (action.payload.length === 0) {
                return state;
            }

            return {
                ...state,
                popupModal: {
                    ...state.popupModal,
                    shouldOpen: true,
                    addedDates: [...state.popupModal.addedDates, ...action.payload],
                },
            };
        }
        case popupModalActions.close.ACTION:
            return {
                ...state,
                popupModal: {
                    ...state.popupModal,
                    shouldOpen: false,
                    addedDates: [],
                },
            };
        default:
            return state;
    }
};
