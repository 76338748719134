import { MembershipType } from 'api/types/membershipTypes';
import { MembershipPaidStatus } from 'api/types';
import { getIsUpgradeOrDowngrade, UpgradeDowngradeStatus } from 'api/helpers/plans';

// eslint-disable-next-line import/prefer-default-export
export const COMPLETEDORDER = {
    create(
        membership,
        productCategory,
        user,
        customerType,
        onTrial,
        paymentMethod,
        gbpOrderValue,
        email
    ) {
        const {
            currencyCode,
            membershipType,
            months,
            transactionId,
            paymentType,
            price,
            promoCode,
            tier,
        } = membership;

        const userId = user.id;
        const prevMembershipType = user.membershipType;
        const prevMembershipTier = user.tier;
        const isPaidMember = user.membershipStatus === MembershipPaidStatus.PAID;

        const prorationUpgradeDowngrade = getIsUpgradeOrDowngrade({
            fromMembershipType: prevMembershipType,
            fromMembershipTier: prevMembershipTier,
            toMembershipType: membershipType,
            toMembershipTier: tier,
        });

        const upgradingDowngradingStatus = {
            [UpgradeDowngradeStatus.UPGRADE]: 'upgrading',
            [UpgradeDowngradeStatus.DOWNGRADE]: 'downgrading',
        };

        let label;
        if (
            (prorationUpgradeDowngrade === UpgradeDowngradeStatus.UPGRADE ||
                prorationUpgradeDowngrade === UpgradeDowngradeStatus.DOWNGRADE) &&
            isPaidMember
        ) {
            label = upgradingDowngradingStatus[prorationUpgradeDowngrade];
        } else if (customerType === 'New' && membershipType === MembershipType.OWNER) {
            label = 'new_owner';
        } else if (customerType === 'New' && membershipType === MembershipType.SITTER) {
            label = 'new_sitter';
        } else if (customerType === 'New' && membershipType === MembershipType.COMBINED) {
            label = 'new_combined';
        } else if (customerType === 'Renewing' && membershipType === MembershipType.OWNER) {
            label = 'renewing_owner';
        } else if (customerType === 'Renewing' && membershipType === MembershipType.SITTER) {
            label = 'renewing_sitter';
        } else if (customerType === 'Renewing' && membershipType === MembershipType.COMBINED) {
            label = 'renewing_combined';
        }

        let orderId = transactionId;
        if (!transactionId) {
            orderId = `${onTrial ? 'trl_' : ''}membershipId_${membership.id}`;
        }

        const total = !onTrial ? price : 0;

        const payload = {
            label,
            affiliation: 'Trustedhousesitters.com',
            customerType,
            membershipTier: tier,
            membershipType,
            prevMembershipType,
            prevMembershipTier,
            currency: currencyCode,
            order_id: orderId,
            payment_type: paymentType,
            plan_length: months,
            free_trial: !!onTrial,
            products: [
                {
                    name: `${membershipType} membership`,
                    sku: tier,
                    category: productCategory,
                    price: !onTrial ? price : 0,
                    quantity: 1,
                },
            ],
            total,
            revenue: total,
            userId,
            userMembershipStatus: 'paid',
            paymentMethod,
            gbpOrderValue,
            email,
        };

        if (promoCode) {
            payload.coupon = promoCode;
        }

        return {
            type: 'Order Completed',
            payload,
        };
    },
};
