// eslint-disable-next-line import/prefer-default-export
export const VIEWED_FEATURE = {
    create(name) {
        const payload = {
            name,
        };

        return {
            type: 'Viewed Feature',
            payload,
        };
    },
};
