import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CallOutWrapper,
    CallOutContent,
    IconCBCStyled,
    IconIDCheck1Styled,
    MobileTextStyled,
    DesktopTextStyled,
} from './CallOut.style';

const MobileDesktopText = ({ mobileText, desktopText }) => (
    <>
        <MobileTextStyled>{mobileText}</MobileTextStyled>
        <DesktopTextStyled>{desktopText}</DesktopTextStyled>
    </>
);

const CallOut = ({ showCBC, isVariation = false }) => {
    const { t } = useTranslation();

    return (
        <CallOutWrapper fixedOrder={isVariation}>
            <CallOutContent>
                {showCBC ? (
                    <>
                        <MobileDesktopText
                            mobileText={t('pages_searchProfiles_callout_us_mobile')}
                            desktopText={t('pages_searchProfiles_callout_us_desktop')}
                        />
                        <IconCBCStyled />
                    </>
                ) : (
                    <>
                        <MobileDesktopText
                            mobileText={t('pages_searchProfiles_callout_row_mobile')}
                            desktopText={t('pages_searchProfiles_callout_row_desktop')}
                        />
                        <IconIDCheck1Styled />
                    </>
                )}
            </CallOutContent>
        </CallOutWrapper>
    );
};

export default CallOut;
