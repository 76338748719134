import styled, { css } from 'styled-components';
import { Button } from 'components/buttons';
import { IconMessageDots } from 'components/Icon';

export const ZendeskButton = styled(Button)(
    ({ theme: { spacing, palette, screenSize } }) => css`
        border-radius: 0 0 25px 25px;
        border-width: 0;
        box-shadow: 0 0 0 0;
        padding: ${spacing[1.5]};
        background-color: ${palette.white};

        /* 960 */
        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            padding: ${spacing[2]};
        }
    `
);

export const ZendeskMessageIcon = styled(IconMessageDots)(
    ({ theme: { palette, screenSize } }) => css`
        width: 24px;
        height: 24px;

        path {
            fill: ${palette.primaryRegular};
        }

        /* 960 */
        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            width: 28px;
            height: 28px;
        }
    `
);
