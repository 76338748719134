import React, { useState } from 'react';
import TrustpilotMiniStyled from './TrustpilotMini.style';
import TrustpilotLoader from '../TrustpilotLoader';

const TrustpilotMini = (props) => {
    const [hasBeenLoaded, setHasBeenLoaded] = useState(false);

    return (
        <TrustpilotLoader onLoad={() => setHasBeenLoaded(true)}>
            {({ ref }) => (
                <TrustpilotMiniStyled
                    ref={ref}
                    isLoading={!hasBeenLoaded}
                    data-locale="en-GB"
                    data-template-id="5419b6ffb0d04a076446a9af"
                    data-businessunit-id="516218d10000640005259226"
                    data-style-height="40px"
                    data-style-width="100%"
                    data-theme="light"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                />
            )}
        </TrustpilotLoader>
    );
};

export default TrustpilotMini;
