export const CALL_TO_ACTION = 'call_to_action';
export const COMPONENT = 'component';
export const DIVIDER = 'divider';
export const FAQS = 'faqs';
export const FLEXIBLECOLUMNS = 'flexiblecolumns';
export const HERO = 'hero';
export const HTML = 'html';
export const IMAGE_WITH_CAPTION = 'image_with_caption';
export const IMAGE_WITH_TEXT = 'image_with_text';
export const PRICING = 'pricing';
export const QUOTE = 'quote';
export const REGWALL = 'regwall';
export const TEXT = 'text';
export const VIDEO = 'video';
export const RESOURCE_LINKS = 'resource_links';
