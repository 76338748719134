import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import actions from 'api/actions';
import { getOwnerListingPetsDetails } from 'api/selectors/owner';
import { getAnimals, getAnimalBreeds } from 'api/selectors/animals';
import { Animal } from 'api/helpers/search/constants';
import asPage from 'containers/Page';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { retry } from 'utils/ssr';
import { PAGE_ID } from './Pets.constants';
import { savePets } from './actions';
import { getResponseStatus, getUpdateId, getPetSaveStatus } from './selectors';

const LoadablePets = loadable(() => retry(() => import('./Pets')));

const mapStateToProps = (state, props) => {
    const {
        match: {
            params: { listingId },
        },
    } = props;

    const animals = getAnimals(state);
    const animalsBreedsOfType = {
        [Animal.FISH]: [],
        [Animal.POULTRY]: [],
        [Animal.FARMANIMAL]: [],
    };
    animals.forEach((animal) => {
        if (animal.slug === 'fish') {
            animalsBreedsOfType[Animal.FISH] = getAnimalBreeds(state, animal.id) || [];
        } else if (animal.slug === 'poultry') {
            animalsBreedsOfType[Animal.POULTRY] = getAnimalBreeds(state, animal.id) || [];
        } else if (animal.slug === 'farm-animals') {
            animalsBreedsOfType[Animal.FARMANIMAL] = getAnimalBreeds(state, animal.id) || [];
        }
    });

    return {
        animals,
        pets: getOwnerListingPetsDetails(state, listingId).map((pet) => ({
            ...pet,
            tmpId: pet.tmpId || pet.id,
        })),
        responseStatus: getResponseStatus(state),
        updateId: getUpdateId(state),
        petSaveStatus: getPetSaveStatus(state),
        isViewingInAppWebView: isViewingInAppWebView(state),
        ...animalsBreedsOfType,
    };
};

const mapDispatchToProps = (dispatch) => ({
    deletePet: (data) => {
        dispatch(actions.owner.removeListingPet(data));
    },
    savePets: (listingId, pets, saveAll) => {
        dispatch(savePets.create(listingId, pets, saveAll));
    },
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadablePets), PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
