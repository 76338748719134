import styled from 'styled-components';
import { IconCheck } from 'components/Icon';
import { InfoText } from '../../../shared.style';
import ErrorMessage from '../../../ErrorMessage';

const InfoTextStyled = styled(InfoText)`
    margin-top: ${({ theme }) => theme.spacing[0.5]};
`;

const ErrorMessageStyled = styled(ErrorMessage)`
    margin-top: ${({ theme }) => theme.spacing[0.5]};
`;

const HelperTextStyled = styled(InfoTextStyled)`
    display: flex;
    align-items: center;
    color: ${({ theme, isValid }) => (isValid ? theme.palette.text : theme.palette.textLight)};
`;

const SuccessIconStyled = styled(IconCheck)`
    margin-right: ${({ theme }) => theme.spacing[1]};
    && svg {
        fill: ${({ theme }) => theme.palette.primaryRegular};
    }
`;

export { ErrorMessageStyled, InfoTextStyled, HelperTextStyled, SuccessIconStyled };
