import { settings as actions } from '../../actions/index';

export const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadCountries.SUCCESS:
            return [
                ...action.data,
            ];
        default:
            return state;
    }
};
