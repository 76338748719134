// eslint-disable-next-line import/prefer-default-export
export const getRatingAverage = (item) => {
    const { organised, reliable, selfSufficient, tidy, petCare } = item;

    const scoresArray = [organised, reliable, selfSufficient, tidy, petCare];

    const total = scoresArray.reduce((totalSum, score) => totalSum + (score || 0), 0);
    const validScoresCount = scoresArray.filter((value) => !!value).length;

    return total / validScoresCount;
};
