import { connect } from 'react-redux';
import { getAccountDetails } from 'api/selectors/account';
import ReferAFriendInline from './ReferAFriendInline';

const mapStateToProps = (state) => ({
    invite: {
        code: getAccountDetails(state).referAFriendCode,
        url: getAccountDetails(state).referAFriendUrl,
    },
});

export default connect(mapStateToProps)(ReferAFriendInline);
