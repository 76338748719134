import { settings as actions } from '../../../actions';

import * as transform from '../../../helpers/transform';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadSitterOwnerListingPlacesOfInterest.SUCCESS:
            return {
                ...state,
                [action.requestData.id]: {
                    ...state[action.requestData.id],
                    ...transform.getPlacesOfInterest(action.data),
                },
            };
        default:
            return state;
    }
};
