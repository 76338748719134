/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const FieldWrapper = styled.div`
    width: 100%;
    /* set a min-height so that the error messages don't change the height of the form */
    min-height: 112px;
`;

const FieldLabelWrapperStyled = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

export { FieldWrapper, FieldLabelWrapperStyled };
