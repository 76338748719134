import { createAction } from 'utils/actions';

const PREFIX = 'USER_LISTING_EDIT_HOME';

// Expects data in the SaveListingArgs shape, curse this un-typeable abstraction!
export const saveListing = createAction(PREFIX, 'SAVE_LISTING', 'oldListing', 'newListing');
export const saveListingAnalytics = createAction(
    PREFIX,
    'SAVE_LISTING_ANALYTICS',
    'newListing',
    'amenities'
);
export const saveListingDone = createAction(PREFIX, 'SAVE_LISTING_DONE', 'status');
export const saveListingAmenities = createAction(
    PREFIX,
    'SAVE_LISTING_AMENITIES',
    'listingId',
    'payload'
);
