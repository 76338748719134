/* global $Values */
import React from 'react';
import { ButtonVariant } from 'components/buttons/Button/Button.constants';
import LinkStyled from './Link.style';
import LinkVariant from './Link.constants';

const Link = ({
    to,
    variant,
    children,
    className,
    onClick,
    target,
    isUnderlined,
    ...otherProps
}) => {
    // We would maybe normally just spread `otherProps` on to <LinkStyled /> but we end up with too much junk
    // So I will make a special cases for a common props
    const propsToPassDown = {};
    if (otherProps['data-testid']) propsToPassDown['data-testid'] = otherProps['data-testid'];
    if (otherProps['aria-label']) propsToPassDown['aria-label'] = otherProps['aria-label'];

    return (
        <LinkStyled
            to={to}
            variant={variant}
            className={className}
            onClick={onClick}
            target={target}
            isUnderlined={isUnderlined}
            {...propsToPassDown}
        >
            {children}
        </LinkStyled>
    );
};

Link.Variant = {
    ...LinkVariant,
    Button: {
        ...ButtonVariant,
    },
};

Link.defaultProps = {
    variant: Link.Variant.DEFAULT,
};

export default Link;
