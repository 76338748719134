import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccountCurrentMembershipPlan, getAccountSettings } from 'api/selectors';
import {
    ANONYMOUS_USER_ID,
    getExperimentalFeatureVariation,
} from 'containers/ExperimentalFeature/helpers';
import { getSplit } from 'containers/ExperimentalFeature/selectors';
import { getItem } from 'src/universalStorage/selectors';
import { UTM_SOURCE_KEY } from 'src/universalStorage/constants';

/**
 * @param {string} experiment
 * @param {object?} options
 * @returns {[string, object]}
 */
export function useExperimentalFeature(
    experiment,
    { userId, attributes, excludeCombo, showVariationToUsers, hasPaidTestConflict } = {}
) {
    const split = useSelector((state) => getSplit(state));
    const membershipPlan = useSelector((state) => getAccountCurrentMembershipPlan(state));
    const utmSource = useSelector((state) => getItem(state, UTM_SOURCE_KEY)?.name);
    const isStaff = useSelector((state) => getAccountSettings(state)?.isStaff ?? false);
    const { variation, enabled, fullRollout } = useMemo(
        () =>
            getExperimentalFeatureVariation({
                experiment,
                userId: userId || ANONYMOUS_USER_ID,
                attributes: {
                    ...(attributes || {}),
                    isStaff,
                },
                split,
                excludeCombo,
                membershipPlan,
                showVariationToUsers,
                utmSource,
                hasPaidTestConflict,
            }),
        [
            experiment,
            userId,
            attributes,
            isStaff,
            split,
            excludeCombo,
            membershipPlan,
            showVariationToUsers,
            utmSource,
            hasPaidTestConflict,
        ]
    );
    const opts = useMemo(
        () => ({
            enabled,
            fullRollout,
        }),
        [enabled, fullRollout]
    );

    return [variation, opts];
}

export default useExperimentalFeature;
