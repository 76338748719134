export default {
    page: '/pages/:slug/',
    pageOnRoot:
        '/:slug(dog-sitting|dog-sitters-near-me|cat-sitting|cat-sitters|reptile-sitters-near-me|rabbit-sitters-near-me|fish-sitters-near-me|bird-sitters-near-me|horse-sitters-near-me|small-animal-sitters-near-me|chicken-sitters-near-me|farm-sitters-near-me|careers)/',
    insuranceBackedGuarentee: '/pages/insurance-backed-guarantee/',
    homeContentsProtection: '/pages/home-and-contents-protection/',
    pageOnHouseAssignments: '/:section(house-and-pet-sitting-assignments)/:slug(sitter-resources)/',
    pageOnSitters: '/:section(house-and-pet-sitters)/:slug(pet-parent-resources)/',
    happiestPetsCompetition2024: '/happiest-pets-competition-2024/',
};
