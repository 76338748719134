import { getState } from './common';

//
// Data selectors
//
export const getAccount = (state) => getState(state).account;
export const getAccountDetails = (state) => getState(state).account?.details;
export const getAccountSettings = (state) => getState(state).account?.settings;
export const getAccountCurrentMembershipPlan = (state) => getState(state).account.membershipPlan;
export const getAccountIncompleteMembershipPlan = (state) =>
    getState(state).account.incompleteMembershipPlan;
export const getAccountSubscribedMembershipPlan = (state) =>
    getState(state).account.subscribedMembershipPlan;
export const getAccountSubscriptionRenewalAmount = (state) =>
    getState(state).account.subscriptionRenewalAmount;
export const getAccountPushNotificationSubscriptions = (state) =>
    getState(state).account.pushNotificationSubscriptions;
export const getAccountWSToken = (state) => getState(state).account.wsToken;
export const getAccountCard = (state) => getState(state).account.card;
export const getAccountReferFreeMonths = (state) =>
    getState(state).account?.details?.referAFriendReferrerFreeMonths;
export const getUserGroupsFormattedForOptimizely = (state) => {
    const { userGroups = [] } = getAccount(state) || {};
    const formattedUserGroups = userGroups.reduce(
        (acc, curr) => ({ ...acc, [`beta_group_${curr.groupName}`]: true }),
        {}
    );
    return formattedUserGroups;
};
