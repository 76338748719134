import styled from 'styled-components';

const getSpacing = (value, defaultValue, theme) => {
    if (value) {
        if (value.endsWith('px')) {
            return value;
        }
        return theme.spacing[value];
    }
    return defaultValue;
};

const InputStyled = styled.input`
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.palette.text};
    background: ${({ theme }) => theme.palette.white};
    font-size: ${({ theme, ...props }) => {
        const size = props.size || 'medium';
        return theme.fontSizes[size] || '1rem';
    }};
    /* height set in rems to that the element scales with the users font-size */
    height: 3rem;

    margin: 0;

    margin-bottom: ${({ marginBottom, theme }) => getSpacing(marginBottom, 0, theme)};

    padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2]}`};

    border: 1px solid ${({ theme }) => theme.palette.border};
    border-radius: ${({ borderRadius }) => borderRadius || '2px'};
    width: 100%;

    line-height: 1.5;

    &:focus {
        ::placeholder {
            visibility: hidden;
        }
    }

    &:disabled {
        cursor: not-allowed;
        border: 1px solid ${({ theme }) => theme.palette.borderDark};
        opacity: ${({ theme }) => theme.disabledOpacity};
    }

    /* hack to remove webkits autofill styling
        * ref: https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete/32505530#32505530
    */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }
`;

export default InputStyled;
