/* eslint-disable no-underscore-dangle */
import config from './config';

// Singleton that is exported
const environment = {
    vars: {},
};

// Supported api version
const apiVersion = '2021-10-26';

const getListingsDefaults = (staticPath) => ({
    defaultImage: `${staticPath}/images/listings/listing-placeholder-mobile.svg`,
    petPlaceholders: {
        cat: `${staticPath}/images/PetsPlaceholder/Cat.svg`,
        dog: `${staticPath}/images/PetsPlaceholder/Dog.svg`,
        'farm animal': `${staticPath}/images/PetsPlaceholder/Farmanimal.svg`,
        fish: `${staticPath}/images/PetsPlaceholder/Fish.svg`,
        horse: `${staticPath}/images/PetsPlaceholder/Horse.svg`,
        reptile: `${staticPath}/images/PetsPlaceholder/Reptile.svg`,
        bird: `${staticPath}/images/PetsPlaceholder/Bird.svg`,
        poultry: `${staticPath}/images/PetsPlaceholder/Poultry.svg`,
        'small pet': `${staticPath}/images/PetsPlaceholder/Smallpet.svg`,
    },
});

const getProfileDefaults = (staticPath) => ({
    defaultImage: `${staticPath}/images/profiles/avatar-placeholder.svg`,
});

/** WARNING!!!! Anything placed in server environment should be safe to be in the public domain
 * (as it's serialized and embedded in the page)
 */
export const getServerEnvironment = (secrets) => {
    const environments = {
        common: {
            releaseName: config.releaseName,
            env: config.env,
            mode: config.mode,
            basename: config.basename,
            staticPath: config.staticPath,
            protocol: 'https://',
            hostname: 'local.trustedhousesitters.com',
            cdnPath: 'https://local-cdn.trustedhousesitters.com',
            apiClientConfig: {
                apiVersion,
                // Default is 'web', but this may be modified in server.jsx
                platformDetail: 'web',
            },
            cloudinary: {
                hostName: 'https://res.cloudinary.com',
                cloudName: 'trustedhousesitters-development',
                autoUploadDirectory: 'remote_media',
            },
            listings: getListingsDefaults(`${config.basename}/${config.staticPath}`),
            profiles: getProfileDefaults(`${config.basename}/${config.staticPath}`),
            mapbox: {
                accessToken: null,
                styleUrl: `https://api.maptiler.com/maps/streets/style.json?key=${secrets.MAPTILER_API_KEY}`,
            },
            prismic: {
                accessToken: secrets.PRISMIC_ACCESS_TOKEN,
            },
            sentry: {
                dsn: secrets.SENTRY_DSN,
            },
            stripe: {
                publicKey: secrets.STRIPE_PUBLIC_KEY,
            },
            facebook: {
                clientId: secrets.FACEBOOK_CLIENT_ID,
            },
            google: {
                clientId: secrets.GOOGLE_CLIENT_ID,
            },
            googleAnalytics: {
                measurementId: secrets.GOOGLE_ANALYTICS_MEASUREMENT_ID || 'G-MP57F26VZZ',
            },
            segment: {
                writeKey: secrets.SEGMENT_WRITE_KEY,
            },
            user: {
                prefix: 'L',
            },
            optimizely: {
                sdkKey: secrets.OPTIMIZELY_SDK_KEY,
            },
            zendesk: {
                key: secrets.ZENDESK_KEY,
            },
            commissionJunction: {
                enterpriseId: secrets.CJ_ENTERPRISE_ID,
                actionTrackerId: secrets.CJ_ACTION_TRACKER_ID,
            },
            tiktok: {
                key: secrets.TIKTOK_ID,
            },
        },
        test: {},
        development: {
            awsWafUrl:
                'https://3e6707307d9a.eu-west-1.sdk.awswaf.com/3e6707307d9a/39285373eab4/challenge.js',
        },
        developmentStaging: {
            cdnPath: config.cdnPath,
            awsWafUrl:
                'https://3e6707307d9a.eu-west-1.sdk.awswaf.com/3e6707307d9a/39285373eab4/challenge.js',
            listings: getListingsDefaults(`${config.cdnPath}/${config.staticPath}`),
            profiles: getProfileDefaults(`${config.cdnPath}/${config.staticPath}`),
            apiClientConfig: {
                apiVersion,
                jwtHeaderName: 'Authorization-X',
                customHeaders: {
                    Authorization: secrets.CORE_API_BASIC_AUTH,
                },
            },
        },
        staging: {
            hostname: 'staging.trustedhousesitters.com',
            cdnPath: config.cdnPath,
            awsWafUrl:
                'https://3e6707307d9a.eu-west-1.sdk.awswaf.com/3e6707307d9a/39285373eab4/challenge.js',
            listings: getListingsDefaults(`${config.cdnPath}/${config.staticPath}`),
            profiles: getProfileDefaults(`${config.cdnPath}/${config.staticPath}`),
            apiClientConfig: {
                apiVersion,
                jwtHeaderName: 'Authorization-X',
                customHeaders: {
                    Authorization: secrets.CORE_API_BASIC_AUTH,
                },
            },
            user: {
                prefix: 'S',
            },
        },
        hotfix: {
            hostname: 'hotfix.trustedhousesitters.com',
            cdnPath: config.cdnPath,
            awsWafUrl:
                'https://3e6707307d9a.eu-west-1.sdk.awswaf.com/3e6707307d9a/39285373eab4/challenge.js',
            listings: getListingsDefaults(`${config.cdnPath}/${config.staticPath}`),
            profiles: getProfileDefaults(`${config.cdnPath}/${config.staticPath}`),
            apiClientConfig: {
                apiVersion,
                jwtHeaderName: 'Authorization-X',
                customHeaders: {
                    Authorization: secrets.CORE_API_BASIC_AUTH,
                },
            },
            user: {
                // Keeping it the same as staging sinc they both share same db
                prefix: 'S',
            },
        },
        production: {
            hostname: 'www.trustedhousesitters.com',
            cdnPath: config.cdnPath,
            awsWafUrl:
                'https://3e6707307d9a.eu-west-1.sdk.awswaf.com/3e6707307d9a/ac4f8b2ef5b4/challenge.js',
            listings: getListingsDefaults(`${config.cdnPath}/${config.staticPath}`),
            profiles: getProfileDefaults(`${config.cdnPath}/${config.staticPath}`),
            cloudinary: {
                hostName: 'https://res.cloudinary.com',
                cloudName: 'trustedhousesitters',
                autoUploadDirectory: 'remote_media',
            },
            user: {
                prefix: 'P',
            },
        },
    };

    return {
        vars: {
            ...environments.common,
            ...environments[environments.common.mode],
            apiClientConfig: {
                ...environments.common.apiClientConfig,
                ...environments[environments.common.mode].apiClientConfig,
            },
        },
    };
};

export const getBrowserEnvironment = () => window.__ENVIRONMENT__;

// Sets the singleton
export const configureEnvironment = (newEnvironment) => {
    environment.vars = newEnvironment.vars;
};

export default environment;
