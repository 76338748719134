// eslint-disable-next-line import/prefer-default-export
export const CARD_UPDATED = {
    create(userId, expiryDate) {
        return {
            type: 'Card Updated',
            payload: {
                userId,
                expiryDate,
            },
        };
    },
};
