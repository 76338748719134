import React from 'react';
import { SpinnerStyled } from './Spinner.style';

const Spinner = () => (
    <SpinnerStyled role="alert" aria-live="polite" aria-busy="true">
        <div className="sk-chase">
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
        </div>
    </SpinnerStyled>
);

export default Spinner;
