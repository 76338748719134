import loadable from '@loadable/component';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getAccountCurrentMembershipPlan, getAccountSettings } from 'api/selectors/account';
import { isViewingInAppWebView } from 'shared/selectors';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isCombined } from 'utils/account';
import { getSafetyContact, getEmergencyContact } from 'containers/SafetySecurityPage/selectors';
import * as actions from './actions';
import { getRawProfile, getShowIsLiveDialog } from '../selectors';
import { PAGE_ID } from './Hub.constants';

const LoadableHubProfile = loadable(() => retry(() => import('./Hub')));

// eslint-disable-next-line arrow-body-style, no-unused-vars
const mapStateToProps = (state) => {
    const membershipPlan = getAccountCurrentMembershipPlan(state);

    return {
        firstName: getAccountSettings(state) ? getAccountSettings(state).firstName : '',
        profile: getRawProfile(state),
        showIsLiveDialog: getShowIsLiveDialog(state),
        memberBillingCountryCode: getAccountSettings(state).country,
        isViewingInAppWebView: isViewingInAppWebView(state),
        isCombined: isCombined(membershipPlan),
        emergencyContact: getEmergencyContact(state),
        safetyContact: getSafetyContact(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    resetShowIsLiveDialog: () => {
        dispatch(actions.resetShowIsLiveDialog.create(false));
    },
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableHubProfile), PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
