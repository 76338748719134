import React from 'react';
import { BackgroundContainerStyled, LayoutContainerStyled } from './Panel.style';

const FullWidthPanel = ({
    children,
    background,
    className,
    fullWidthUntil,
    hasMaxWidth = true,
    id,
}) => (
    <BackgroundContainerStyled id={id} background={background} className={className}>
        <LayoutContainerStyled hasMaxWidth={hasMaxWidth} fullWidthUntil={fullWidthUntil}>
            {children}
        </LayoutContainerStyled>
    </BackgroundContainerStyled>
);

const Panel = ({ children, background, className, fullWidthUntil, hasMaxWidth = true, id }) =>
    background ? (
        <FullWidthPanel
            id={id}
            background={background}
            className={className}
            fullWidthUntil={fullWidthUntil}
            hasMaxWidth={hasMaxWidth}
        >
            {children}
        </FullWidthPanel>
    ) : (
        <LayoutContainerStyled
            className={className}
            hasMaxWidth={hasMaxWidth}
            fullWidthUntil={fullWidthUntil}
            id={id}
        >
            {children}
        </LayoutContainerStyled>
    );

Panel.defaultProps = {
    fullWidthUntil: 0,
};

export { Panel as default, FullWidthPanel };
