import { routes } from 'utils/routes';
import { getHasMembership, getIsLoggedIn } from 'shared/selectors';

import asPage from './Page';

/* eslint-disable consistent-return */
const redirectIfNotMember = function redirectIfNotMember(isMember) {
    if (isMember) return;
    return routes.login();
};
/* eslint-enable consistent-return */

const asMemberPage = (
    WrappedComponent,
    pageId,
    usePreload = false,
    useLoad = false,
    preserveNextOnRedirect = false
) =>
    asPage(WrappedComponent, pageId, usePreload, useLoad, {
        isProtectedRoute: true,
        checkAuthorised: [
            {
                selector: getHasMembership,
                redirect: redirectIfNotMember,
                preserveNextOnRedirect,
            },
        ],
    });

/**
 * Use this when you're creating a new page and you don't have any sagas that you need
 * to run.
 */
const asPageNoSagas = (WrappedComponent, pageId, options) =>
    asPage(WrappedComponent, pageId, false, false, options);

/**
 * Use this when you're creating a new page and have sagas that you want to run.
 * It will dispatch a preload and a load actions, so your saga should takeEvery for both
 * these actions. In order to get the page to render, you should dispatch preloaded
 * and loaded actions once you've finished your data fetching.
 */
const asPageWithSagas = (WrappedComponent, pageId, pageSaga, pageReducer, options) =>
    asPage(WrappedComponent, pageId, true, true, {
        ...options,
        pageSaga,
        pageReducer,
    });

export const checkNotLoggedIn = {
    selector: getIsLoggedIn,
    redirect: (isLoggedIn) => (!isLoggedIn ? routes.login() : null),
    preserveNextOnRedirect: true,
};

/**
 * Use this when you're creating a new protected page and have sagas that you want to run.
 * It will dispatch a preload and a load actions, so your saga should takeEvery for both
 * these actions. In order to get the page to render, you should dispatch preloaded
 * and loaded actions once you've finished your data fetching.
 */
const asProtectedPageWithSagas = (WrappedComponent, pageId, pageSaga, pageReducer, options) =>
    asPage(WrappedComponent, pageId, true, true, {
        ...options,
        pageSaga,
        pageReducer,
        isProtectedRoute: true,
        checkAuthorised: [checkNotLoggedIn, ...(options?.checkAuthorised ?? [])],
    });

export { asMemberPage, asPageNoSagas, asPageWithSagas, asProtectedPageWithSagas };
