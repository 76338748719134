import React from 'react';
import { useTranslation } from 'react-i18next';
import { H2 } from '@ths/design-system';

import Spinner from 'components/Spinner';

const Loading = () => {
    const { t } = useTranslation();

    return (
        <>
            <H2 className="pb-8 max-md:px-3 max-md:text-lg max-md:leading-8">
                {t('page_verify_email_loading_title')}
            </H2>

            <Spinner />
        </>
    );
};

export default Loading;
