import { connect } from 'react-redux';
import { getLocale } from '../../shared/selectors';

const ShowInCountry = ({ children, ISOCode, userISOCode }) => {
    if (userISOCode === ISOCode) {
        return children;
    }
    return null;
};

const IsoCode = ({ children, userISOCode }) => children(userISOCode);

const mapStateToProps = (state) => {
    const { countryISOCode } = getLocale(state);

    return {
        userISOCode: countryISOCode,
    };
};

const connectedIsoCode = connect(mapStateToProps)(IsoCode);
export { ShowInCountry, connectedIsoCode as IsoCode };
export default connect(mapStateToProps)(ShowInCountry);
