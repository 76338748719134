import { getBlogArea, getBlogAreaRelatedPosts } from 'api/selectors/content';
import { BLOG_CAROUSEL_SEARCH_TYPE } from './BlogPostsCarousel.constants';

// Try to get the full category name, if not possible, just return the current category
// provided.
const tryGetCategoryDescriptionName = (state, areaSlug, category) => {
    try {
        const area = getBlogArea(state, areaSlug);
        const areaCategory = area && area.categories.find((c) => c.slug === category.slug);

        return areaCategory ?? category;
    } catch (e) {
        return category;
    }
};

// eslint-disable-next-line import/prefer-default-export
export const getPosts = (state, area) => {
    const posts = getBlogAreaRelatedPosts(state, area, BLOG_CAROUSEL_SEARCH_TYPE);

    if (posts && Object.prototype.hasOwnProperty.call(posts, 'results')) {
        return posts.results.map(
            ({ category: postCategory, categorySlug, coverImage, excerpt, shortTitle, slug }) => {
                const areaCategory = tryGetCategoryDescriptionName(state, area, postCategory);

                return {
                    category: {
                        url: `/${area}/${postCategory.slug}/`,
                        description: areaCategory.description ?? areaCategory.slug,
                        slug: categorySlug,
                    },
                    slug,
                    image: coverImage,
                    excerpt,
                    title: shortTitle,
                    url: `/${area}/${postCategory.slug}/${slug}/`,
                };
            }
        );
    }

    return [];
};
