// eslint-disable-next-line import/prefer-default-export
export const LISTING_MATCHING_FACETS = {
    create({ highlights, listing }) {
        return {
            type: 'Interaction',
            payload: {
                description: 'Sitter Viewed Matching Facets',
                variation: 'variation1',
                item: highlights,
                listing_id: listing,
            },
        };
    },
};
