// eslint-disable-next-line import/prefer-default-export
export const VIEWED_DOWNLOAD_APP_PROMPT = {
    create({ source }) {
        const payload = {
            source,
        };
        return {
            type: 'Viewed Download App Prompt',
            payload,
        };
    },
};
