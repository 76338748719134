import { createAction } from 'utils/actions';

const prefix = 'EXPERIMENTAL_FEATURE';

// eslint-disable-next-line import/prefer-default-export
export const logVariation = createAction(
    prefix,
    'LOG_VARIATION',
    'name',
    'variation',
    'description'
);
