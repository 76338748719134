import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MembershipType } from 'api/types';
import {
    Wrapper,
    OwnerPanelStyled,
    SitterPanelStyled,
    CombinedPanelStyled,
    PanelInnerStyled,
    MembershipButtonStyled,
    MembershipButtonCombinedStyled,
    TitleStyled,
    TitleMembership,
    TitleCombinedStyled,
    FeaturesSummaryStyled,
    FeaturesListStyled,
    FeaturesListItemStyled,
    CheckIconStyled,
} from './SelectMembershipType.style';

const SelectMembershipType = ({ onSelectMembershipType }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <OwnerPanelStyled>
                <PanelInnerStyled>
                    <TitleStyled membershipType={MembershipType.OWNER}>
                        <Trans
                            i18nKey={`components_regwallDialogTest_membership_title_${MembershipType.OWNER}`}
                        >
                            Owner <TitleMembership>membership</TitleMembership>
                        </Trans>
                    </TitleStyled>

                    <FeaturesSummaryStyled membershipType={MembershipType.OWNER}>
                        {t(`components_regwallDialogTest_featuresSummary_${MembershipType.OWNER}`)}
                    </FeaturesSummaryStyled>
                    <FeaturesListStyled membershipType={MembershipType.OWNER}>
                        <FeaturesListItemStyled>
                            <CheckIconStyled membershipType={MembershipType.OWNER} />
                            {t(
                                `components_regwallDialogTest_membership_item_1_${MembershipType.OWNER}`
                            )}
                        </FeaturesListItemStyled>
                        <FeaturesListItemStyled>
                            <CheckIconStyled membershipType={MembershipType.OWNER} />
                            {t(
                                `components_regwallDialogTest_membership_item_2_${MembershipType.OWNER}`
                            )}
                        </FeaturesListItemStyled>
                        <FeaturesListItemStyled>
                            <CheckIconStyled membershipType={MembershipType.OWNER} />
                            {t(
                                `components_regwallDialogTest_membership_item_3_${MembershipType.OWNER}`
                            )}
                        </FeaturesListItemStyled>
                    </FeaturesListStyled>
                    <MembershipButtonStyled
                        data-testid="choose-membership-owner"
                        membershipType={MembershipType.OWNER}
                        onClick={() => onSelectMembershipType(MembershipType.OWNER)}
                    >
                        {t(
                            `components_regwallDialogTest_membership_button_${MembershipType.OWNER}`
                        )}
                    </MembershipButtonStyled>
                </PanelInnerStyled>
            </OwnerPanelStyled>

            <SitterPanelStyled>
                <PanelInnerStyled>
                    <TitleStyled membershipType={MembershipType.SITTER}>
                        <Trans
                            i18nKey={`components_regwallDialogTest_membership_title_${MembershipType.SITTER}`}
                        >
                            Sitter <TitleMembership>membership</TitleMembership>
                        </Trans>
                    </TitleStyled>
                    <FeaturesSummaryStyled membershipType={MembershipType.SITTER}>
                        {t(`components_regwallDialogTest_featuresSummary_${MembershipType.SITTER}`)}
                    </FeaturesSummaryStyled>
                    <FeaturesListStyled membershipType={MembershipType.SITTER}>
                        <FeaturesListItemStyled>
                            <CheckIconStyled membershipType={MembershipType.SITTER} />
                            {t(
                                `components_regwallDialogTest_membership_item_1_${MembershipType.SITTER}`
                            )}
                        </FeaturesListItemStyled>
                        <FeaturesListItemStyled>
                            <CheckIconStyled membershipType={MembershipType.SITTER} />
                            {t(
                                `components_regwallDialogTest_membership_item_2_${MembershipType.SITTER}`
                            )}
                        </FeaturesListItemStyled>
                        <FeaturesListItemStyled>
                            <CheckIconStyled membershipType={MembershipType.SITTER} />
                            {t(
                                `components_regwallDialogTest_membership_item_3_${MembershipType.SITTER}`
                            )}
                        </FeaturesListItemStyled>
                    </FeaturesListStyled>
                    <MembershipButtonStyled
                        data-testid="choose-membership-sitter"
                        membershipType={MembershipType.SITTER}
                        onClick={() => onSelectMembershipType(MembershipType.SITTER)}
                    >
                        {t(
                            `components_regwallDialogTest_membership_button_${MembershipType.SITTER}`
                        )}
                    </MembershipButtonStyled>
                </PanelInnerStyled>
            </SitterPanelStyled>

            <CombinedPanelStyled>
                <PanelInnerStyled membershipType={MembershipType.COMBINED}>
                    <TitleCombinedStyled membershipType={MembershipType.COMBINED}>
                        {t(
                            `components_regwallDialogTest_membership_title_${MembershipType.COMBINED}`
                        )}
                    </TitleCombinedStyled>
                    <MembershipButtonCombinedStyled
                        data-testid="choose-membership-combined"
                        membershipType={MembershipType.COMBINED}
                        onClick={() => onSelectMembershipType(MembershipType.COMBINED)}
                    >
                        {t(
                            `components_regwallDialogTest_membership_button_${MembershipType.COMBINED}`
                        )}
                    </MembershipButtonCombinedStyled>
                </PanelInnerStyled>
            </CombinedPanelStyled>
        </Wrapper>
    );
};

export default SelectMembershipType;
