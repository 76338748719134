import { settings as actions } from '../../actions';

export const initialState = {
    animals: [],
    breeds: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.animals.load.SUCCESS: {
            return {
                ...state,
                animals: [...action.data],
            };
        }
        case actions.animals.loadBreeds.SUCCESS: {
            return {
                ...state,
                breeds: {
                    ...state.breeds,
                    [action.requestData.id]: [...action.data],
                },
            };
        }
        default:
            return state;
    }
};
