import React, { Component } from 'react';
import { any, bool, func, oneOf, string, object } from 'prop-types';
import StyleInput from './Input.style';

/**
 * @deprecated Use `src/components/Input` instead.
 */
class Input extends Component {
    static propTypes = {
        /**
         * If defined it sets the button's border radius
         * e.g. borderRadius="25px"
         */
        borderRadius: string,
        /**
         * Applies a border style
         * e.g. border="none" OR border="1px solid green"
         */
        border: string,
        /**
         * Type of the input field
         */
        type: string.isRequired,
        /**
         * Disables interactions and applies styling
         */
        disabled: bool,
        /**
         * Applies styling to indicate the users input was invalid
         */
        invalid: bool,
        /**
         * a function passed down reference the dom input element
         */
        inputRef: func,
        /**
         * Descriptive placeholder text that is displayed in the input field when there is no value
         */
        placeholder: string,
        /**
         * Defines the size of the input field
         */
        size: oneOf(['small', 'medium', 'large', 'xlarge']),
        /**
         * Applies styling to indicate the users input was valid
         */
        valid: bool,
        /**
         * Value of the input field
         */
        value: any,
        /**
         * margin is a space seperated string.
         * It can be a string of absolute values which include measurement units
         * e.g. '15px 10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '0.5 1 2'
         * Or a combination of both
         * e.g. '1 10px'
         */
        margin: string,
        /**
         * defines the top margin
         * It can be provided as a string with absolute values which include measurement units
         * e.g. '10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '1'
         */
        marginTop: string,
        /**
         * defines the right margin
         * It can be provided as a string with absolute values which include measurement units
         * e.g. '10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '1'
         */
        marginRight: string,
        /**
         * defines the bottom margin
         * It can be provided as a string with absolute values which include measurement units
         * e.g. '10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '1'
         */
        marginBottom: string,
        /**
         * defines the left margin
         * It can be provided as a string with absolute values which include measurement units
         * e.g. '10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '1'
         */
        marginLeft: string,
        /**
         * Handler for when the input field is blurred
         */
        onChangeHandler: func,
        /**
         * Handler for when the input field is blurred
         */
        onBlurHandler: func,
        /**
         * Handler for when the input field is focused
         */
        onFocusHandler: func,
        /**
         * Signifies whether the input should have rounded sides
         */
        /**
         * padding is a space seperated string.
         * It can be a string of absolute values which include measurement units
         * e.g. '15px 10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '0.5 1 2'
         * Or a combination of both
         * e.g. '1 10px'
         */
        padding: string,
        /**
         * defines the top padding
         * It can be provided as a string with absolute values which include measurement units
         * e.g. '10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '1'
         */
        paddingTop: string,
        /**
         * defines the right padding
         * It can be provided as a string with absolute values which include measurement units
         * e.g. '10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '1'
         */
        paddingRight: string,
        /**
         * defines the bottom padding
         * It can be provided as a string with absolute values which include measurement units
         * e.g. '10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '1'
         */
        paddingBottom: string,
        /**
         * defines the left padding
         * It can be provided as a string with absolute values which include measurement units
         * e.g. '10px'
         * Or a string which contains numbers related to the themes spacing scale
         * e.g. '1'
         */
        paddingLeft: string,
        rounded: bool,
        /**
         * Theme to change the font and style of input
         */
        theme: object,
    };

    static defaultProps = {
        type: 'text',
        size: 'medium',
        value: '',
    };

    constructor(props) {
        super(props);
        this.state = { hasFocus: false };
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
    }

    handleOnChange(event) {
        const { onChangeHandler = () => {} } = this.props;
        onChangeHandler(event, event.target.value);
    }

    handleOnBlur(event) {
        const { onBlurHandler = () => {} } = this.props;
        this.setState({ hasFocus: false });
        onBlurHandler(event);
    }

    handleOnFocus(event) {
        const { onFocusHandler = () => {} } = this.props;
        this.setState({ hasFocus: true });
        onFocusHandler(event);
    }

    render() {
        const {
            type,
            disabled,
            value,
            valid,
            invalid,
            placeholder,
            theme,
            size,
            border,
            borderRadius,
            rounded,
            inputRef,
            name,
            inputMode,
            // We don't want to pass these callbacks down in the spread
            onChangeHandler,
            onBlurHandler,
            onFocusHandler,
            ...otherProps
        } = this.props;
        const { hasFocus } = this.state;

        return (
            <StyleInput
                border={border}
                borderRadius={borderRadius}
                type={type}
                disabled={disabled}
                hasFocus={hasFocus}
                ref={inputRef}
                invalid={invalid}
                onBlur={this.handleOnBlur}
                onFocus={this.handleOnFocus}
                onChange={this.handleOnChange}
                placeholder={placeholder}
                rounded={rounded}
                size={size}
                theme={theme}
                valid={valid}
                value={value}
                name={name}
                inputMode={inputMode}
                {...otherProps}
            />
        );
    }
}

export default Input;
