import styled from 'styled-components';
import { pStyles } from 'typography';
import { MainContainerStyled } from '../Root/Root.style';

// eslint-disable-next-line import/prefer-default-export
export const TitleStyled = styled.span`
    ${pStyles}

    ${MainContainerStyled}[data-status='filled'] & {
        font-weight: 600;
    }
`;
