/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const EmailIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.508 6H4.873l4.573 3.354a1 1 0 0 0 1.158.017L15.508 6ZM3 7.107V13a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7.401l-5.263 3.618a3 3 0 0 1-3.474-.053L3 7.107ZM1 7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V7Z"
            clipRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(EmailIcon);
export default ForwardRef;
