import { all, put, takeLatest, select, call, race, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
import { isValid, differenceInDays } from 'date-fns';
import { getAccountCurrentMembershipPlan } from 'api/selectors';
import { parseDate } from 'api/helpers/format/date';
import { UPGRADE_MEMBERSHIP_DISMISSED_KEY } from 'containers/UpgradeMembership';
import { experiments, VariationTypes } from 'containers/ExperimentalFeature';
import { getExperimentalFeatureVariationSelector } from 'containers/ExperimentalFeature/selectors';
import { getRouterLocation } from 'shared/selectors';
import { track, Events } from 'utils/analytics';
import { MembershipType } from 'api/types';
import {
    HAS_SEEN_LOW_APPLICANTS_PROMPT,
    searchMethods,
} from 'pages/search/SearchListings/SearchListings.constants';
import * as searchListingsSelectors from 'pages/search/SearchListings/selectors';
import { getIsMembershipActive, isSitterOnly } from 'utils/account';
import * as PromptManager from 'containers/PromptManager';
import { getItem } from '../../universalStorage/selectors';
import {
    REFER_A_FRIEND_NUDGEWALL_DELAY_MS,
    REFER_A_FRIEND_NUDGEWALL_DAYS_TO_BE_CANDIDATE,
    REFER_A_FRIEND_NUDGEWALL_WEEKS_TO_SEE_AGAIN,
} from './ReferAFriendNudgewall.constants';
import * as actions from './actions';
import {
    showReferAFriendNudgewallOnThisPage,
    getCurrentPathnameIsDashboard,
    getCurrentPathnameBlocked,
} from './helpers';
import { getIsVisible, getReferalAFriendOneTouchVariation } from './selectors';

export function* dispatchTrackEvent(event) {
    yield call(track, event);
}

export function* getIsUpgradeBannerDismissed() {
    return Boolean(yield getItem(yield select(), UPGRADE_MEMBERSHIP_DISMISSED_KEY));
}

export function* checkIsCombined() {
    const userMembership = yield select(getAccountCurrentMembershipPlan);

    return userMembership.membershipType === MembershipType.COMBINED;
}

export function* checkForUserMembership() {
    const userMembership = yield select(getAccountCurrentMembershipPlan);
    const dateParsed = parseDate(userMembership.startDate);

    return (
        isValid(dateParsed) &&
        differenceInDays(new Date(), dateParsed) >= REFER_A_FRIEND_NUDGEWALL_DAYS_TO_BE_CANDIDATE
    );
}

export function* getVariationOneTouch() {
    const { variation } = yield select(getReferalAFriendOneTouchVariation);
    return variation;
}

/**
 * This generator check if we've conflict with the current AB test running by
 * the MX team called `PRIORITISE_LOW_APPLICANTS_V2`.
 * Returns `true` if is there any conflicts with the current test or `false`
 * if we can show the nudgewall.
 *
 * @param {string} pathname
 * @returns {boolean} hasConflict
 */
export function* checkConflictBetweenABTests(pathname) {
    const isOnPathnameBlocked = !!(yield call(getCurrentPathnameBlocked, pathname));

    if (!isOnPathnameBlocked) {
        return false;
    }

    const { variation, enabled } = yield select((state) =>
        getExperimentalFeatureVariationSelector(state, {
            experiment: experiments.PRIORITISE_LOW_APPLICANTS_V2,
        })
    );
    const isTestEnabled = enabled && variation !== VariationTypes.CONTROL;

    if (isTestEnabled) {
        const state = yield select();
        const hasSeenLowApplicantsPrompt = Boolean(
            yield getItem(state, HAS_SEEN_LOW_APPLICANTS_PROMPT)
        );

        if (hasSeenLowApplicantsPrompt) {
            return false;
        }

        const currentMembershipPlan = getAccountCurrentMembershipPlan(state);
        const isPaidMember = getIsMembershipActive(currentMembershipPlan);
        const searchMethod = searchListingsSelectors.getSearchMethod(state);

        if (
            isPaidMember &&
            searchMethod !== searchMethods.SAVE_SEARCH &&
            isSitterOnly(currentMembershipPlan)
        ) {
            return true;
        }
    }

    return false;
}

export function* canShowNudgewall() {
    const {
        location: { pathname },
    } = yield select(getRouterLocation);

    if (yield checkConflictBetweenABTests(pathname)) {
        return false;
    }

    if (!(yield call(showReferAFriendNudgewallOnThisPage, pathname))) {
        return false;
    }

    const { isCombined, isUpgradeBannerDismissed, isInDashboard } = yield all({
        isCombined: call(checkIsCombined),
        isUpgradeBannerDismissed: call(getIsUpgradeBannerDismissed),
        isInDashboard: call(getCurrentPathnameIsDashboard, pathname),
    });

    if (!isCombined && !isUpgradeBannerDismissed && isInDashboard) {
        return false;
    }

    if (!(yield call(checkForUserMembership))) {
        return false;
    }

    return true;
}

export function* handleShowNudgewall() {
    const oneTouchVariation = yield call(getVariationOneTouch);

    if (oneTouchVariation === VariationTypes.VARIATION1) {
        yield call(
            dispatchTrackEvent,
            Events.REFER_A_FRIEND_NUDGEWALL_TRIGGERED.create({ oneTouch: true })
        );
    } else {
        yield call(dispatchTrackEvent, Events.REFER_A_FRIEND_NUDGEWALL_TRIGGERED.create());
    }

    const { delayed } = yield race({
        locationChange: take(LOCATION_CHANGE),
        delayed: delay(REFER_A_FRIEND_NUDGEWALL_DELAY_MS),
    });

    if (delayed) {
        yield put(actions.show.create());
    }
}

export function* handleSoftDismissal() {
    if (yield select(getIsVisible)) {
        yield put(actions.softDismiss.create());
    }
}

export function* handleTryOpen() {
    if (!(yield call(canShowNudgewall))) {
        yield put(PromptManager.failure.create());
        return;
    }

    yield put(PromptManager.success.create());
    yield call(handleShowNudgewall);
}

export default function* sagas() {
    yield put(
        PromptManager.register.create({
            name: 'raf-nudgewall',
            priority: PromptManager.PRIORITY.LOW,
            action: actions.tryOpen.ACTION,
            waitFor: {
                weeks: REFER_A_FRIEND_NUDGEWALL_WEEKS_TO_SEE_AGAIN,
            },
        })
    );

    yield all([
        yield takeLatest(LOCATION_CHANGE, handleSoftDismissal),
        yield takeLatest(actions.tryOpen.ACTION, handleTryOpen),
    ]);
}
