import {
    format,
    formatDistance as _distanceInWords,
    formatDistanceToNow as _distanceInWordsToNow,
    formatDistanceStrict as _distanceInWordsStrict,
    isValid,
} from 'date-fns';
import en from 'date-fns/locale/en-GB';
import { parseDate } from 'api/helpers/format/date';

const config = {
    locales: {
        en,
        'en-GB': en,
    },
};

/**
 * Return the formatted date string in the given format.
 * Wraps the date-fns method with i18n stuff: https://date-fns.org/v1.29.0/docs/format
 *
 * @param {Date | String | Number} date - Date object
 * @param {String} formatStr - string representation of the required date format
 * @returns {string}
 */
const formatDate = (date, formatStr, lng) => {
    const { locales } = config;
    const dateParsed = parseDate(date);

    if (isValid(dateParsed)) {
        return format(dateParsed, formatStr, {
            locale: locales[lng],
        });
    }

    return 'Invalid Date';
};

/**
 * Return the distance between the given dates in words.
 * Wraps the date-fns method with i18n stuff: https://date-fns.org/v1.29.0/docs/formatDistance
 *
 * @param {string | number | Date} dateToCompare Date
 * @param {string | number | Date} date Date
 * @param {{includeSeconds?: boolean, addSuffix?: boolean}} options options object
 * @returns {string} the distance between the given dates in words.
 */
const formatDistance = (dateToCompare, date, lng, options = {}) => {
    const { locales } = config;
    return _distanceInWords(dateToCompare, date, {
        ...options,
        locale: locales[lng],
    });
};

/**
 * Return the distance between the given date and now in words.
 * Wraps the date-fns method with i18n stuff: https://date-fns.org/v1.29.0/docs/formatDistanceToNow
 *
 * @param {string | number | Date} date
 * @param {{includeSeconds?: boolean, addSuffix?: boolean}} options
 * @returns {string} The distance between the given date and now in words.
 */
const formatDistanceToNow = (date, lng, options) => {
    const { locales } = config;
    return _distanceInWordsToNow(date, {
        ...options,
        locale: locales[lng],
    });
};

/**
 * Return the distance between the given dates in words, using strict units.
 * This is like formatDistance, but does not use helpers like 'almost', 'over', 'less than' and the like.
 * Wraps the date-fns method with i18n stuff: https://date-fns.org/v1.29.0/docs/formatDistanceStrict
 *
 * @param {string | number | Date} dateToCompare Date
 * @param {string | number | Date} date Date
 * @param {{
 *  includeSeconds?: boolean,
 *  addSuffix?: boolean,
 *  partialMethod?: "floor" | "ceil" | "round",
 *  unit?: 's' | 'm' | 'h' | 'd' | 'M' | 'Y'
 * }} options
 * @returns {string} The distance between the given date and now in words.
 */
const formatDistanceStrict = (dateToCompare, date, lng, options) => {
    const { locales } = config;
    return _distanceInWordsStrict(dateToCompare, date, {
        ...options,
        locale: locales[lng],
    });
};

/**
 * Returns a UTC date, ignoring timezones
 * @param {string | number | Date} date Date
 * @returns {string} The distance between the given date and now in words.
 */
const getUTCDate = (dateString = Date.now()) => {
    const date = new Date(dateString);

    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
};

export { formatDate, formatDistance, formatDistanceToNow, formatDistanceStrict, getUTCDate };
