import * as pageActions from 'containers/Page/actions';
import { settings } from 'api/actions';
import { PAGE_ID } from './constants';
import * as actions from './actions';

export const initialState = {
    results: [],
    hasMore: false,
    isLoading: false,
    isLoadingMore: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case pageActions.lazyload.ACTION: {
            if (action.pageId === PAGE_ID) {
                return {
                    ...state,
                    isLoading: true,
                };
            }

            return state;
        }
        case actions.hydrate.ACTION:
            return {
                ...state,
                results: action.payload.results,
                hasMore: action.payload.hasMore,
                isLoading: false,
                isLoadingMore: false,
            };
        case actions.loadMoreRequest.ACTION:
            return {
                ...state,
                isLoadingMore: true,
            };
        case actions.loadMoreSuccess.ACTION:
            return {
                ...state,
                results: [...state.results, ...action.payload.results],
                hasMore: action.payload.hasMore,
                isLoadingMore: false,
            };
        case settings.owner.createPastAssignmentReview.SUCCESS: {
            return {
                ...state,
                results: state.results.map((assignment) => {
                    if (String(assignment.id) === String(action.data.assignmentId)) {
                        return {
                            ...assignment,
                            review: action.data,
                        };
                    }

                    return assignment;
                }),
            };
        }
        case settings.owner.loadAssignment.SUCCESS: {
            return {
                ...state,
                results: state.results.map((value) =>
                    value.id === action.data.id ? action.data : value
                ),
            };
        }
        default:
            return state;
    }
}
