import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { storePromo } from 'shared/actions';
import { retry } from 'utils/ssr';
import asPage from 'containers/Page';

const LoadableRAFLanding = loadable(() => retry(() => import('./RAFLanding')));

const mapDispatchToProps = (dispatch) => ({
    storePromo: (promoCode) => {
        dispatch(storePromo.create(promoCode));
    },
});

export default connect(
    null,
    mapDispatchToProps
)(asPage(withTranslation()(LoadableRAFLanding), 'RAFLanding', false, false));
