import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.4);
    }
    40% {
        transform: scale(0.8);
    }
    60%{
        transform: scale(1.2);            
    }
    80% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
`;

const StyledButton = styled.button`
    all: unset;

    background-color: transparent;
    width: 36px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform-origin: 50% 50%;
    -webkit-tap-highlight-color: transparent;

    &[data-should-animate='true'] {
        animation: ${bounce} 350ms ease-in-out 0ms 1 normal forwards;
    }
`;

export { StyledButton };
