/* eslint-disable max-classes-per-file */
import { format } from 'date-fns';

import { dateSameOrAfter } from 'api/helpers/format/date';
import {
    Query,
    Filter,
    BooleanFilter,
    IdsFilter,
    GeoFilter,
    GeoPoint,
    GeoBounds,
    GeoHierarchy,
    FreeText,
    ActiveMembership,
} from './query';

// Export the base classes
export {
    Query,
    IdsFilter,
    GeoFilter,
    GeoPoint,
    GeoBounds,
    GeoHierarchy,
    FreeText,
    ActiveMembership,
};

/**
 * An assignments filter
 *
 * @export
 * @class Assignments
 * @extends {Filter}
 */
export class Assignments extends Filter {
    /**
     * Creates an instance of Assignments.
     * @param {Object} args
     * @param {date} args.dateFrom
     * @param {date} args.dateTo
     * @memberof Reviews
     */
    constructor({
        dateFrom,
        dateTo,
        approximateDates,
        reviewing,
        confirmed,
        minimumDurationInDays,
        maximumDurationInDays,
        minimumNumberOfApplicants,
        maximumNumberOfApplicants,
    } = {}) {
        super();
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.approximateDates = approximateDates;
        this.reviewing = reviewing;
        this.confirmed = confirmed;
        this.minimumDurationInDays = minimumDurationInDays;
        this.maximumDurationInDays = maximumDurationInDays;
        this.minimumNumberOfApplicants = minimumNumberOfApplicants;
        this.maximumNumberOfApplicants = maximumNumberOfApplicants;
    }

    /**
     * Add the filter
     *
     * @param {object} filters
     * @returns {object}
     * @memberof Assignments
     */
    add(filters) {
        // args all optional
        filters.assignments = {};

        if (this.dateFrom !== undefined) {
            filters.assignments.dateFrom = this.dateFrom;
        }

        if (this.dateTo !== undefined) {
            filters.assignments.dateTo = this.dateTo;
        }

        if (this.approximateDates !== undefined) {
            filters.assignments.approximateDates = this.approximateDates;
        }

        if (this.reviewing !== undefined) {
            filters.assignments.reviewing = this.reviewing;
        }

        if (this.confirmed !== undefined) {
            filters.assignments.confirmed = this.confirmed;
        }

        if (this.minimumDurationInDays !== undefined) {
            filters.assignments.durationInDays = {
                ...filters.assignments.durationInDays,
                minimum: this.minimumDurationInDays,
            };
        }

        if (this.maximumDurationInDays !== undefined) {
            filters.assignments.durationInDays = {
                ...filters.assignments.durationInDays,
                maximum: this.maximumDurationInDays,
            };
        }

        if (this.minimumNumberOfApplicants !== undefined) {
            filters.assignments.numberOfApplicants = {
                ...filters.assignments.numberOfApplicants,
                minimum: this.minimumNumberOfApplicants,
            };
        }

        if (this.maximumNumberOfApplicants !== undefined) {
            filters.assignments.numberOfApplicants = {
                ...filters.assignments.numberOfApplicants,
                maximum: this.maximumNumberOfApplicants,
            };
        }

        return filters;
    }
}

/**
 * A pets filter
 *
 * @export
 * @class Pets
 * @extends {Filter}
 */
export class Pet extends Filter {
    /**
     * Creates an instance of Pet.
     * @param {string} type - type of animal.
     * @param {Object} pet - the pet on the sit.
     * @param {string} pet.breed - breed of animal.
     * @param {boolean} pet.exclude - whether to exclude.
     */
    constructor(type, { breed, exclude } = {}) {
        super();
        this.type = type;
        this.breed = breed;
        this.exclude = exclude;
    }

    /**
     * Add the filter
     *
     * @param {object} filters
     * @returns {object}
     * @memberof Pets
     */
    add(filters) {
        // create pets if not present
        if (!('pets' in filters)) {
            filters.pets = [];
        }

        const pet = { type: this.type };

        if (this.breed !== undefined) {
            pet.breed = this.breed;
        }

        if (this.exclude !== undefined) {
            pet.exclude = this.exclude;
        }

        filters.pets.push(pet);

        return filters;
    }
}

/**
 * A is family friendly filter
 *
 * @export
 * @class FamilyFriendly
 * @extends {BooleanFilter}
 */
export class FamilyFriendly extends BooleanFilter {
    /**
     * The name of the field
     *
     * @readonly
     * @memberof FamilyFriendly
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'familyFriendly';
    }
}

/**
 * A car included filter
 *
 * @export
 * @class CarIncluded
 * @extends {BooleanFilter}
 */
export class CarIncluded extends BooleanFilter {
    /**
     * The name of the field
     *
     * @readonly
     * @memberof CarIncluded
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'carIncluded';
    }
}

/**
 * A disabled access friendly filter
 *
 * @export
 * @class DisabledAccess
 * @extends {BooleanFilter}
 */
export class DisabledAccess extends BooleanFilter {
    /**
     * The name of the field
     *
     * @readonly
     * @memberof DisabledAccess
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'disabledAccess';
    }
}

export class HomeType extends Filter {
    /**
     * Creates an instance of HomeType.
     * @param {string[]} homeType - type of home
     * @memberof HomeType
     */
    constructor(homeType) {
        super();
        this.homeType = homeType;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof HomeType
     */
    add(filters) {
        filters.homeType = this.homeType;

        return filters;
    }
}
export class SortBy extends Filter {
    /**
     * Creates an instance of SortBy.
     * @param {string[]} sortBy - sort option
     * @memberof SortBy
     */
    constructor(sortBy) {
        super();
        this.sortBy = sortBy;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof SortBy
     */
    add(filters) {
        filters.sortBy = this.sortBy;

        return filters;
    }
}

export class WifiAvailable extends Filter {
    /**
     * Creates an instance of WifiAvailable.
     * @param {string[]} wifiAvailable - type of wifi
     * @memberof WifiAvailable
     */
    constructor(wifiAvailable) {
        super();
        this.wifiAvailable = wifiAvailable;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof WifiAvailable
     */
    add(filters) {
        filters.wifiAvailable = this.wifiAvailable;

        return filters;
    }
}

export class AccessibleByPublicTransport extends BooleanFilter {
    get name() {
        return 'isAccessibleByPublicTransport';
    }
}

export class LocalAttractions extends Filter {
    /**
     * Creates an instance of LocalAttractions.
     * @param {string[]} localAttractions - local attractions
     * @memberof LocalAttractions
     */
    constructor(localAttractions) {
        super();
        this.localAttractions = localAttractions;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof LocalAttractions
     */
    add(filters) {
        filters.localAttractions = this.localAttractions;

        return filters;
    }
}

export class Published extends Filter {
    /**
     * Creates an instance of Published.
     * @param {string[]} published - published
     * @memberof Published
     */
    constructor({ after, before }) {
        super();
        this.after = after;
        this.before = before;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof Published
     */
    add(filters) {
        filters.published = {};

        if (this.after !== undefined) {
            filters.published.after = this.after;
        }
        if (this.before !== undefined) {
            filters.published.before = this.before;
        }

        return filters;
    }
}

const isValidAssignment = (assignment, includePausedSits) =>
    (includePausedSits || !assignment.isReviewing) &&
    dateSameOrAfter(assignment.startDate, format(new Date(), 'yyyy-MM-dd'));

export const getActiveAndInactiveListings = (listings, includePausedSits) => {
    // To start with assume we want the whole listings array
    let activeListings = [];
    let inactiveListings = [];
    if (listings.length > 0) {
        activeListings = listings.filter((listing) => {
            const validAssignments = listing.openAssignments.filter((assignment) =>
                isValidAssignment(assignment, includePausedSits)
            );
            // A list is considered "active" if it has any valid assignments
            // to apply for.
            return validAssignments.length > 0;
        });
        // confirmed assignments will be added to the inactiveListings array as once the assignment
        // is confirmed it is removed from the openAssignments array.
        inactiveListings = listings.filter((listing) => {
            const validAssignments = listing.openAssignments.filter((assignment) =>
                isValidAssignment(assignment, includePausedSits)
            );
            // A list is considered "inactive" if it has no valid assignments
            // to apply for.
            return validAssignments.length === 0;
        });
    }

    return [activeListings, inactiveListings];
};

// removes any duplicate listing ids appearing in "listingsToRemove"
// and then returns the remaining array of unique ids
export const filterOutDuplicateListings = ({
    unfilteredListings,
    listingsToRemove
}) => {
    const filteredListingIds = unfilteredListings.filter(
        (listing) => !listingsToRemove.includes(listing)
    );

    return filteredListingIds;
};
