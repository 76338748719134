import { all, put, call, takeLatest, select, takeEvery, take, fork } from 'redux-saga/effects';
import {
    loaded as pageActionsLoaded,
    preloaded as pageActionsPreloaded,
    load as pageActionsLoad,
    preload as pageActionsPreload,
    lazyload as pageActionsLazyload,
    lazyloaded as pageActionsLazyloaded,
    error as errorAction,
} from 'containers/Page/actions';
import apiActions, { settings } from 'api/actions';
import { getSearchListings, getSearchListingsTotal } from 'api/selectors/search';
import * as PromptManager from 'containers/PromptManager';
import { track, Events } from 'utils/analytics';
import { SEARCH_TYPE } from 'config/search';
import { getItem } from 'src/universalStorage/selectors';
import { base64decode, base64encode } from 'utils/strings';
import { getRouterLocation } from 'src/shared/selectors';
import { categories } from 'containers/BlogPostsCarousel';
import { fetchBlogPostsRequest } from 'containers/BlogPostsCarousel/actions';
import { CONTENT_TYPES } from 'config/content';
import * as actions from '../actions';
import doSearch from './doSearch';
import navigateTo from './navigateTo';
import loadFavourites from '../../sagas/loadingFavourites';
import mapZoomedOrPanned from './mapZoomedOrPanned';
import {
    SEARCH_TYPE_RESULTS,
    SEARCH_TYPE_TOTAL_AND_GEOFACETS,
    HAS_SEEN_LOW_APPLICANTS_PROMPT,
    PAGE_ID,
} from '../SearchListings.constants';
import { getParams, getFilters, getPlace } from '../selectors';
import hiddenClustersMapSearch from './hiddenClustersMapSearch';
import saveSearch, { shouldFetchSavedSearches } from './saveSearch';
import deleteSavedSearch from './deleteSavedSearch';
import updateSavedSearch from './updateSavedSearch';
import doSearchDiscoveryCategories from './doSearchDiscoveryCategories';
import configureLocalSitsTestActive from './configureLocalSitsTestActive';

export function* loadBlogPostsCarousel() {
    yield put(
        fetchBlogPostsRequest.create({
            area: CONTENT_TYPES.blog,
            categories: [categories.SITTERS, categories.PETS, categories.TRAVEL],
        })
    );
}

export function* loadSavedSearch() {
    yield put(
        apiActions.search.loadListingsSavedSearch({
            filters: {
                limit: 50,
            },
        })
    );
    yield take(settings.search.loadListingsSavedSearch.DONE);
    yield put(actions.hasSavedSearchResponse.create());
}

function* preload(action) {
    const preloadAction = action;
    if (preloadAction.search && preloadAction.search.q) {
        /*
            Nasty hack, but if we're loading this page for the first time (or refreshing)
            we need to delete startingAfter and if startingAfter we need to reset the page to page 1
            (This doesn't affect normal refreshing since startingAfter is only used in infinite scroll)
        */
        let queryParam;
        try {
            queryParam = JSON.parse(base64decode(preloadAction.search.q));
        } catch (e) {
            yield put(errorAction.create(PAGE_ID, 400));
            return;
        }

        if (queryParam.startingAfter) {
            delete queryParam.startingAfter;
            delete queryParam.page;
        }
        preloadAction.search.q = base64encode(JSON.stringify(queryParam));
    }
    yield loadFavourites(true);
    yield configureLocalSitsTestActive();
    const searchSuccessful = yield doSearch({
        ...preloadAction,
    });

    yield call(shouldFetchSavedSearches, loadSavedSearch);

    if (searchSuccessful) {
        yield put(pageActionsPreloaded.create(PAGE_ID));
    }

    yield fork(loadBlogPostsCarousel, action);
}

export function* lazyload(action) {
    yield call(shouldFetchSavedSearches, loadSavedSearch);

    const { preloaded } = action;

    if (preloaded) {
        const listings = yield select(getSearchListings, SEARCH_TYPE_RESULTS);
        const total = yield select(getSearchListingsTotal, SEARCH_TYPE_TOTAL_AND_GEOFACETS);
        const possibleResults = yield select(getSearchListingsTotal, SEARCH_TYPE_RESULTS);
        const filters = yield select(getFilters);
        const params = yield select(getParams);
        const place = yield select(getPlace);

        const hasGeoHierarchyParams = Object.keys(params).length;

        const searchFilters = {
            ...filters.filters,
            seoHierarcy: hasGeoHierarchyParams,
        };

        if (hasGeoHierarchyParams) {
            searchFilters.geoHierarchy = params;
        }

        if (place) {
            searchFilters.place = place;
        }

        const { searchMethod } = action.search;
        // Log analytics on preload
        if (preloaded) {
            track(
                Events.SEARCH_FILTERS.create({
                    category: SEARCH_TYPE.Listing,
                    query: searchFilters,
                    items: listings || [],
                    searchOptions: {
                        totalResults: total,
                        possibleResults,
                        searchMethod,
                    },
                })
            );
        }
    }

    yield put(pageActionsLazyloaded.create(PAGE_ID));
}

function* load(action) {
    yield put(
        PromptManager.register.create({
            name: 'low-applicants-prompt',
            priority: PromptManager.PRIORITY.MEDIUM,
            action: actions.tryOpenLowApplicantsPrompt.ACTION,
        })
    );

    let loadListings = true;

    if (action.search.q) {
        // Decode query param
        let queryParam;
        try {
            queryParam = JSON.parse(base64decode(action.search.q));
        } catch (e) {
            yield put(errorAction.create(PAGE_ID, 400));
            return;
        }

        // Get router for previous location
        const router = yield select(getRouterLocation);

        /*
        If we're in the infinite scroll test, then we only allow more results from the same page, don't load more results
        if navigation to this page happens any other way - otherwise we can end up with duplicates
        */
        if (
            queryParam.startingAfter &&
            router?.location?.previousLocation?.pathname !== '/house-and-pet-sitting-assignments/'
        ) {
            loadListings = false;
        }
    }

    yield loadFavourites();
    yield configureLocalSitsTestActive();
    const searchSuccessful = loadListings
        ? yield doSearch({
              ...action,
          })
        : true;

    yield call(shouldFetchSavedSearches, loadSavedSearch);

    if (searchSuccessful) {
        yield put(pageActionsLoaded.create(PAGE_ID));
    }

    yield fork(loadBlogPostsCarousel, action);
}

/**
 * Loads new results based on the filters selected
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* searchFiltersUpdated(action) {
    const params = yield select(getParams);
    yield navigateTo(params, action.filters, 1, action.searchMethod);
}

export function* handleTryOpenLowApplicantsPrompt() {
    const hasSeenPrompt = yield select((state) => getItem(state, HAS_SEEN_LOW_APPLICANTS_PROMPT));

    if (hasSeenPrompt) {
        yield put(PromptManager.failure.create());
        return;
    }

    yield put(PromptManager.success.create());
    yield put(actions.openLowApplicantsPrompt.create());
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeEvery(
            (action) => action.type === pageActionsLazyload.ACTION && action.pageId === PAGE_ID,
            lazyload
        ),
        takeLatest(actions.searchFiltersUpdated.ACTION, searchFiltersUpdated),
        takeLatest(actions.mapZoomedOrPanned.ACTION, mapZoomedOrPanned),
        takeLatest(actions.hiddenClustersMapSearch.ACTION, hiddenClustersMapSearch),
        takeLatest(actions.saveSearch.ACTION, saveSearch),
        takeLatest(actions.deleteSavedSearch.ACTION, deleteSavedSearch),
        takeLatest(actions.updateSavedSearch.ACTION, updateSavedSearch),
        takeLatest(actions.tryOpenLowApplicantsPrompt.ACTION, handleTryOpenLowApplicantsPrompt),
        takeLatest(actions.searchDiscoveryCategory.ACTION, doSearchDiscoveryCategories),
    ]);
}
