import styled from 'styled-components';
import { h3Styles, h2LargeStyles, P } from 'typography';

export const WrapperStyled = styled.div``;

export const HeaderStyled = styled.h2`
    ${h3Styles};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.palette.white} !important;
    line-height: 1;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        ${h2LargeStyles};
    }
`;

export const ContentStyled = styled(P)`
    margin-top: ${({ theme }) => theme.spacing[1]};
`;
