import React from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { MembershipType } from 'api/types';
import Anchor from 'components/Anchor';
import { routes } from 'utils/routes';
import { useExperimentalFeature } from 'hooks/useExperimentalFeature';
import { getFullHostname } from 'utils/environment';
import { experiments, VariationTypes } from 'containers/ExperimentalFeature';
import { useRegwallDialog } from '../../useRegwallDialog';

const PrivacyPolicy = ({ className, dataTestId }) => {
    const { shouldSetMembershipType, selectedMembershipType } = useRegwallDialog();
    const [twoStepRegwallVariation] = useExperimentalFeature(experiments.TWO_STEP_REGWALL);
    const isControl = twoStepRegwallVariation === VariationTypes.CONTROL;
    const textSize = isControl ? 'text-sm' : 'text-2xs';

    return (
        <p
            className={clsx('m-0 p-0 text-center text-grey-500', className, textSize)}
            data-testid={dataTestId}
        >
            <Trans
                i18nKey={`components_registerForm_pp_and_mc_auto_opt_in_${
                    shouldSetMembershipType ? MembershipType.COMBINED : selectedMembershipType
                }`}
                components={{
                    ppLink: (
                        <Anchor
                            href={`${getFullHostname()}${routes.privacyPolicy()}`}
                            target="_blank"
                        />
                    ),
                }}
            />
        </p>
    );
};

export default PrivacyPolicy;
