import { BACK_BUTTON_CLICKED } from './backButtonClicked';
import { BUTTONCLICK } from './generic/buttonclick';
import { MEDIA_UPLOAD } from './generic/mediaUpload';
import { KEYBOARDPRESS } from './generic/keyboardPress';
import { LINKCLICK } from './generic/linkclick';
import { OPEN_ACCORDION } from './generic/openAccordion';
import { CARD_UPDATED } from './cardUpdated';
import { REGISTERED } from './registered';
import { COMPLETEDORDER } from './completedOrder';
import { HOMEPAGE_HERO_HEADING } from './homepageHeroHeading';
import { NEW_USER_ATTRIBUTION } from './newUserAttribution';
import { SITTERCOMPLETEDAPPLICATION } from './sitterCreatedApplication';
import { SITTER_STARTED_APPLICATION } from './sitterStartedApplication';
import { SITTER_SUBMIT_APPLICATION } from './sitterSubmitApplication';
import { SAVED_SEARCH } from './search/savedSearch';
import { SAVED_SEARCH_LIMIT } from './search/savedSearchLimit';
import { SAVED_SEARCH_DELETED } from './search/savedSearchDeleted';
import { USER_SENT_SHARE_LISTING_EMAIL } from './search/userSentShareListingEmail';
import { FILTER_LOCATION_FAILED } from './search/filterLocationFailed';
import { RECOMMENDED_CAROUSEL_DETAILS } from './search/recommendedCarouselDetails';
import { SEARCH_LOCATION } from './search/location';
import { SEARCH_FILTERS } from './search/filters';
import { SEARCH_DETAILS } from './search/details';
import { VIEWED_SITS_NEARBY_SECTION } from './search/viewedSitsNearbySection';
import { VIEWED_FEATURE } from './viewedFeature';
import { VIEWED_EXPERIMENTAL_FEATURE } from './viewedExperimentalFeature';
import { VIEWED_EXPERIMENTAL_LANDING_PAGE } from './viewedExperimentalLandingPage';
import { VIEWED_REGWALL } from './viewedRegwall';
import { VIEWED_MBG_MODAL } from './viewedMBGModal';
import { TRACK_RAF_BANNER_VIEW } from './trackRAFBannerView';
import { TRACK_RAF_PAGE_VIEW } from './trackRAFPageView';
import { OWNER_CREATED_ASSIGNMENT } from './ownerCreatedAssignment';
import { OWNER_CREATED_INVITATION } from './ownerCreatedInvitation';
import { OWNER_DELETED_ASSIGNMENT } from './ownerDeletedAssignment';
import { OWNER_UPDATED_ASSIGNMENT } from './ownerUpdatedAssignment';
import { OWNER_CONFIRMED_APPLICATION } from './ownerConfirmedApplication';
import { OWNER_UNCONFIRMED_APPLICATION } from './ownerUnconfirmedApplication';
import { OWNER_DECLINED_APPLICATION } from './ownerDeclinedApplication';
import { OWNER_UNPAUSED_ASSIGNMENT } from './ownerUnpausedAssignment';
import { OWNER_PAUSED_ASSIGNMENT } from './ownerPausedAssignment';
import { FIND_MORE_SITS } from './findMoreSitsCTA';

import { OWNER_PUBLISHED_LISTING } from './ownerPublishedListing';
import { ACCEPTED_CODE_OF_CONDUCT } from './acceptedCodeOfConduct';
import { SITTER_CONFIRMED_APPLICATION } from './sitterConfirmedApplication';
import { MEMBER_ADDED_SAFETY_CONTACT, MEMBER_ADDED_ICE_CONTACT } from './saveSafetyContacts';
import { CURRENCY_CHANGED } from './currencyChanged';
import { REFER_A_FRIEND_NUDGEWALL_TRIGGERED } from './referAFriendNudgewallTriggered';
import { USERACQUISITION } from './userAcquisition';
import { USER_LOGGED_IN } from './user/loggedIn';
import { USER_SENT_RAF_EMAIL } from './user/raf';
import { USER_VISITED_CHECKOUT } from './visitedCheckout';
import { USER_LISTING_EDIT_ABOUT_YOU } from './user/listing/edit/aboutYou';
import { USER_UPLOADED_PHOTO } from './user/uploadedPhoto';
import {
    USER_LISTING_EDIT_HOME,
    USER_LISTING_OPENED_AMENITIES_ACCORDION,
    USER_LISTING_AMENITIES_POPUP_OPENED,
    USER_LISTING_AMENITIES_POPUP_CLOSED,
    USER_LISTING_AMENITIES_POPUP_CTA_CLICK,
    USER_LISTING_PUBLIC_TRANSPORT_MODAL_CLICK,
    USER_LISTING_PUBLIC_TRANSPORT_MODAL_CLOSED,
    USER_LISTING_PUBLIC_TRANSPORT_MODAL_OPENED,
} from './user/listing/edit/home';
import { USER_LISTING_EDIT_LOCATION } from './user/listing/edit/location';
import { USER_LISTING_EDIT_ADDRESS } from './user/listing/edit/address';
import { USER_LISTING_EDIT_PETS } from './user/listing/edit/pets';
import { USER_WELCOME_GUIDE_SHARED } from './user/listing/welcomeGuide/shared';
import { USER_LISTING_EDIT_SITTER_RESPONSIBILITIES } from './user/listing/edit/responsibilities';
import { USER_LISTING_EDIT_TITLE } from './user/listing/edit/title';
import { USER_LISTING_EDIT_OWNER_BOOST } from './user/listing/edit/ownerBoost';
import { USER_PROFILE_EDIT_ABOUT_YOU } from './user/profile/edit/AboutYou';
import { USER_PROFILE_EDIT_EXPERIENCE } from './user/profile/edit/Experience';
import { USER_PROFILE_EDIT_PETS } from './user/profile/edit/Pets';
import { USER_PROFILE_EDIT_PREFERENCES } from './user/profile/edit/Preferences';
import { USER_PROFILE_EDIT_PROFILE_DETAILS } from './user/profile/edit/ProfileDetails';
import { USER_PROFILE_EDIT_SAVE } from './user/profile/edit/ProfileSave';
import { USER_PROFILE_VERIFICATIONS_MANAGE_REFERENCE } from './user/profile/verifications/ManageReference';
import { USER_PROFILE_VERIFICATIONS_VIEWED_PAGE } from './user/profile/verifications/ViewedVerificationsPage';
import { USER_PROFILE_VERIFICATIONS_CREATE_REFERENCE_REQUEST } from './user/profile/verifications/CreateReferenceRequest';
import { USER_PROFILE_VERIFICATIONS_SHARE_REFERENCE_REQUEST } from './user/profile/verifications/ShareReferenceRequest';
import { USER_PROFILE_VERIFICATIONS_RESEND_REFERENCE_CLICK } from './user/profile/verifications/ResendReferenceClick';
import { USER_PROFILE_REFERENCES_VIEWED_PAGE } from './user/profile/verifications/ViewedReferencesPage';
import { USER_PROFILE_HUB_VIEWED_PAGE } from './user/profile/edit/ViewedProfileHubPage';
import { VIEWED_PROFILE_LIVE_DIALOG } from './user/profile/edit/ViewedProfileLiveDialog';
import { CHECK_REFUND_STATUS } from './user/checkRefundStatus';
import { VIDEO_STARTED, VIDEO_ENDED, VIDEO_PROGRESS, VIDEO_UNMUTED } from './video';
import { SHARED_BLOG_POST } from './blog/sharePost';
import {
    BLOG_INTERACTION_SEARCH_BAR,
    BLOG_INTERACTION_SEARCH_BUTTONS_CATEGORY,
    BLOG_INTERACTION_SEARCH_BUTTONS_SUBCATEGORY,
    BLOG_INTERACTION_SEARCH_BUTTONS_TAG,
} from './blog/interaction';
import { SHARED_LISTING } from './sharedListing';
import { VIEWED_CARD_EXPIRY_PROMPT } from './viewedCardExpiryPrompt';
import { CANCEL_DISCOUNT_WARNING_POPUP } from './cancelDiscountWarningPopup';
import { IMPORTED_CONTACTS } from './importedContacts';
import { VIEWED_COMBINED_INTERSTITIAL } from './viewedCombinedInterstitial';
import { VIEWED_FAKE_DOOR_SIT_CONFIRMATION } from './viewedFakeDoorSitConfirmation';
import { VIEWED_UPGRADE_BANNER } from './viewedUpgradeBanner';
import { FREE_TRIAL_STARTED } from './freeTrialStarted';
import { VIEWED_DOWNLOAD_APP_MODAL } from './viewedDownloadAppModal';
import { VIEWED_DOWNLOAD_APP_PROMPT } from './viewedDownloadAppPrompt';
import { VIEWED_LOW_APPLICANTS_EDUCATIONAL_PROMPT } from './viewedLowApplicantsEducationalPrompt';
import { FAKE_DOOR_ACQUISITION } from './fakeDoorAcquisition';
import { VIEWED_FAKE_DOOR_CHECKOUT_MODAL } from './viewedFakeDoorCheckoutModal';
import { VIEWED_FAKE_DOOR_CONFIRMATION_MODAL } from './viewedFakeDoorConfirmationModal';
import { CAROUSEL } from './generic/carousel';
import { VIEWED_LEAVE_A_REFERENCE_THANK_YOU } from './viewedLeaveReferenceThankYou';
import { VIEWED_MODAL } from './viewedModal';
import { VIEWED_END_FREE_TRIAL_MODAL } from './viewedEndFreeTrialModal';
import { LEAVE_FEEDBACK_MODAL } from './leaveFeedbackModal';
import { LEAVE_REVIEW_MODAL } from './leaveReviewModal';
import { ANSWERED_SURVEY } from './survey';
import { AUTO_RENEWAL_TURNED_OFF } from './autorenewal';
import { FAVOURITES } from './favourites';
import { OWNER_REMOVED_FAVOURITE } from './ownerRemovedFavourite';
import { OWNER_ADDED_FAVOURITE } from './ownerAddedFavourite';
import { AUTO_RENEW_FAILURE } from './autoRenewFailure';
import { SWIPE } from './generic/swipe';
import { LISTING_MATCHING_FACETS } from './listingMatchingFacets';

const Events = Object.freeze({
    ACCEPTED_CODE_OF_CONDUCT,
    ANSWERED_SURVEY,
    AUTO_RENEWAL_TURNED_OFF,
    BACK_BUTTON_CLICKED,
    BUTTONCLICK,
    MEDIA_UPLOAD,
    KEYBOARDPRESS,
    LINKCLICK,
    CANCEL_DISCOUNT_WARNING_POPUP,
    CARD_UPDATED,
    CAROUSEL,
    COMPLETEDORDER,
    CURRENCY_CHANGED,
    FILTER_LOCATION_FAILED,
    HOMEPAGE_HERO_HEADING,
    LEAVE_FEEDBACK_MODAL,
    LEAVE_REVIEW_MODAL,
    MEMBER_ADDED_ICE_CONTACT,
    MEMBER_ADDED_SAFETY_CONTACT,
    NEW_USER_ATTRIBUTION,
    REFER_A_FRIEND_NUDGEWALL_TRIGGERED,
    OPEN_ACCORDION,
    OWNER_CONFIRMED_APPLICATION,
    OWNER_UNCONFIRMED_APPLICATION,
    OWNER_CREATED_ASSIGNMENT,
    OWNER_CREATED_INVITATION,
    OWNER_DELETED_ASSIGNMENT,
    OWNER_PUBLISHED_LISTING,
    OWNER_UPDATED_ASSIGNMENT,
    OWNER_DECLINED_APPLICATION,
    OWNER_UNPAUSED_ASSIGNMENT,
    OWNER_PAUSED_ASSIGNMENT,
    REGISTERED,
    SAVED_SEARCH,
    SAVED_SEARCH_LIMIT,
    SAVED_SEARCH_DELETED,
    USER_SENT_SHARE_LISTING_EMAIL,
    RECOMMENDED_CAROUSEL_DETAILS,
    SEARCH_DETAILS,
    SEARCH_FILTERS,
    SEARCH_LOCATION,
    SHARED_BLOG_POST,
    BLOG_INTERACTION_SEARCH_BAR,
    BLOG_INTERACTION_SEARCH_BUTTONS_CATEGORY,
    BLOG_INTERACTION_SEARCH_BUTTONS_SUBCATEGORY,
    BLOG_INTERACTION_SEARCH_BUTTONS_TAG,
    SHARED_LISTING,
    SITTER_CONFIRMED_APPLICATION,
    SITTERCOMPLETEDAPPLICATION,
    SITTER_STARTED_APPLICATION,
    SITTER_SUBMIT_APPLICATION,
    USERACQUISITION,
    USER_LISTING_EDIT_ABOUT_YOU,
    USER_LISTING_EDIT_HOME,
    USER_LISTING_OPENED_AMENITIES_ACCORDION,
    USER_LISTING_AMENITIES_POPUP_OPENED,
    USER_LISTING_AMENITIES_POPUP_CLOSED,
    USER_LISTING_AMENITIES_POPUP_CTA_CLICK,
    USER_LISTING_EDIT_LOCATION,
    USER_LISTING_EDIT_ADDRESS,
    USER_LISTING_EDIT_PETS,
    USER_WELCOME_GUIDE_SHARED,
    USER_LISTING_EDIT_SITTER_RESPONSIBILITIES,
    USER_LISTING_EDIT_TITLE,
    USER_LISTING_EDIT_OWNER_BOOST,
    USER_LOGGED_IN,
    USER_SENT_RAF_EMAIL,
    USER_PROFILE_EDIT_ABOUT_YOU,
    USER_PROFILE_EDIT_EXPERIENCE,
    USER_PROFILE_EDIT_PETS,
    USER_PROFILE_EDIT_PREFERENCES,
    USER_PROFILE_EDIT_PROFILE_DETAILS,
    USER_PROFILE_EDIT_SAVE,
    USER_PROFILE_VERIFICATIONS_VIEWED_PAGE,
    USER_PROFILE_VERIFICATIONS_MANAGE_REFERENCE,
    USER_PROFILE_VERIFICATIONS_CREATE_REFERENCE_REQUEST,
    USER_PROFILE_VERIFICATIONS_SHARE_REFERENCE_REQUEST,
    USER_PROFILE_VERIFICATIONS_RESEND_REFERENCE_CLICK,
    USER_PROFILE_REFERENCES_VIEWED_PAGE,
    USER_PROFILE_HUB_VIEWED_PAGE,
    VIEWED_PROFILE_LIVE_DIALOG,
    CHECK_REFUND_STATUS,
    USER_UPLOADED_PHOTO,
    USER_VISITED_CHECKOUT,
    VIDEO_ENDED,
    VIDEO_PROGRESS,
    VIDEO_STARTED,
    VIDEO_UNMUTED,
    VIEWED_CARD_EXPIRY_PROMPT,
    VIEWED_COMBINED_INTERSTITIAL,
    VIEWED_EXPERIMENTAL_FEATURE,
    VIEWED_EXPERIMENTAL_LANDING_PAGE,
    VIEWED_FAKE_DOOR_SIT_CONFIRMATION,
    VIEWED_FEATURE,
    VIEWED_MBG_MODAL,
    TRACK_RAF_BANNER_VIEW,
    TRACK_RAF_PAGE_VIEW,
    VIEWED_DOWNLOAD_APP_MODAL,
    VIEWED_DOWNLOAD_APP_PROMPT,
    VIEWED_LOW_APPLICANTS_EDUCATIONAL_PROMPT,
    VIEWED_REGWALL,
    VIEWED_UPGRADE_BANNER,
    IMPORTED_CONTACTS,
    FREE_TRIAL_STARTED,
    FAKE_DOOR_ACQUISITION,
    VIEWED_FAKE_DOOR_CHECKOUT_MODAL,
    VIEWED_FAKE_DOOR_CONFIRMATION_MODAL,
    VIEWED_SITS_NEARBY_SECTION,
    USER_LISTING_PUBLIC_TRANSPORT_MODAL_CLICK,
    USER_LISTING_PUBLIC_TRANSPORT_MODAL_CLOSED,
    USER_LISTING_PUBLIC_TRANSPORT_MODAL_OPENED,
    VIEWED_LEAVE_A_REFERENCE_THANK_YOU,
    VIEWED_MODAL,
    VIEWED_END_FREE_TRIAL_MODAL,
    FAVOURITES,
    OWNER_REMOVED_FAVOURITE,
    OWNER_ADDED_FAVOURITE,
    FIND_MORE_SITS,
    AUTO_RENEW_FAILURE,
    SWIPE,
    LISTING_MATCHING_FACETS,
});

// eslint-disable-next-line import/prefer-default-export
export { Events };
