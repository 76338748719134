import { settings } from 'api/actions';

const initialState = {
    assignment: null,
    welcomeGuide: null,
    pets: [],
    placesOfInterest: [],
    welcomeGuidePhotos: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case settings.owner.loadAssignment.SUCCESS:
            return {
                ...state,
                assignment: {
                    ...action.data,
                },
            };
        case settings.owner.loadWelcomeGuide.SUCCESS:
            return {
                ...state,
                welcomeGuide: {
                    ...action.data,
                },
            };
        case settings.owner.loadListingPets.SUCCESS:
            return {
                ...state,
                pets: [...action.data],
            };
        case settings.owner.loadListingPlacesOfInterest.SUCCESS:
            return {
                ...state,
                placesOfInterest: [...action.data],
            };
        case settings.owner.loadWelcomeGuidePhotos.SUCCESS:
            return {
                ...state,
                welcomeGuidePhotos: [...action.data],
            };
        default:
            return state;
    }
};
