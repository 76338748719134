export default {
    profile: {
        wildcard: '/accounts/profile/:splat*/',
        dashboard: '/accounts/profile/dashboard/',
        settings: '/accounts/profile/settings/details/',
        membership: '/accounts/profile/settings/membership/',
        contactSettings: '/accounts/profile/settings/contact-settings/',
        inbox: '/accounts/profile/messages/inbox/',
        favorites: '/accounts/profile/messages/address-book/',
        verifications: '/accounts/profile/verifications/',
        logout: '/accounts/logout/',
        contacts: {
            messages: '/accounts/profile/contacts/:contactId/listings/:listingId/messages/',
        },
        renew: '/accounts/profile/renew/',
        welcomeGuide: '/accounts/profile/listing/:listingId/manual//homeowner/welcome/',
        welcomeGuideWithAssignment:
            '/accounts/profile/listing/:listingId/manual/:assignmentId/homeowner/welcome/',
        welcomeGuideSitter:
            '/accounts/profile/listing/:listingId/manual/:assignmentId/housesitter/welcome/',
    },

    signUp: {
        wildcard: '/accounts/signup/:splat*/',
        pricing: '/accounts/signup/pricing/',
        choosePlan: '/accounts/signup/choose-plan/',
        joinNow: '/accounts/signup/join-now/',
        upgrade: '/accounts/explore-plans/',
        createAccount: '/accounts/signup/join-now/create-account/',
        checkout: '/accounts/signup/checkout/billing/',
        payment: '/accounts/signup/checkout/payment/',
    },

    referAFriend: '/accounts/refer-a-friend/',
    explorePlans: '/accounts/explore-plans/',
    explorePlansClassic: '/accounts/explore-plans/classic/',

    checkout: {
        payment: '/accounts/checkout/',
        activation: '/accounts/checkout/activation/',
        confirmation: '/accounts/checkout/confirmation/',
    },
};
