import React from 'react';
import { ListboxStyled, SuggestionStyled } from './Listbox.style';

const Listbox = ({
    highlightedIndex,
    highlightedRef,
    isVisible,
    id,
    maxHeight,
    menuRef,
    onItemMouseEnter,
    onSelectSuggestionHandler,
    renderItem,
    role,
    suggestions,
    suggestionMapToNameAndKey,
    width,
    ...props
}) => (
    <ListboxStyled
        id={id}
        ref={menuRef}
        isVisible={isVisible}
        maxHeight={maxHeight}
        role={role}
        width={width}
        {...props}
    >
        {suggestions.map((suggestion, index) => {
            const { key } = suggestionMapToNameAndKey(suggestion);

            return (
                <SuggestionStyled
                    id={`${id}-${index}`}
                    data-index={index}
                    highLighted={highlightedIndex === index}
                    ref={highlightedIndex === index ? highlightedRef : undefined}
                    key={key}
                    onClick={(event) => onSelectSuggestionHandler(event, index)}
                    onMouseEnter={(event) => onItemMouseEnter(event, index)}
                    role="option"
                    // tabIndex is added so that the suggestion can receive focus
                    // see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
                    // without it the focusout event will be triggered without a relatedTarget
                    // which will cause the combobox to lose focus according to the onFocusout method of combobox.
                    tabIndex="-1"
                >
                    {renderItem(suggestion)}
                </SuggestionStyled>
            );
        })}
    </ListboxStyled>
);

export default Listbox;
