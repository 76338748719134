import { v2ApiUrl, v3ApiUrl } from './constants';

export default {
    // Sitter summary?
    _: `${v3ApiUrl}sitter/`,

    // Favourite listings
    favourites: {
        // GET, POST (listing_id): Favourite listings
        _: `${v3ApiUrl}sitter/favourites/`,

        // DELETE
        favourite: `${v3ApiUrl}sitter/favourites/:id:/`,
    },

    // Owner Listings
    owner: {
        listing: {
            // GET (ownerId, id): Owner listing details
            _: `${v3ApiUrl}sitter/owner/listings/:id:/`,

            // GET (ownerId, id): Owner Listing pets
            pets: `${v3ApiUrl}sitter/owner/listings/:id:/pets/`,

            // GET (ownerId, id): Owner Listing Places of Interest
            placesOfInterest: `${v3ApiUrl}sitter/owner/listings/:id:/places-of-interest/`,
        },

        // GET (id): Get all applications related to owner listings assignments, sitter applied to
        applications: `${v3ApiUrl}sitter/owner/:id:/applications/`,

        // GET (id): Get all invitations related to owner listings assignments, sitter was invited to
        invitations: `${v3ApiUrl}sitter/owner/:id:/invitations/`,
    },

    assignments: {
        assignment: {
            // GET assignment
            _: `${v3ApiUrl}sitter/assignments/:id:/`,

            // GET: Assignment welcome guide
            welcomeGuide: `${v3ApiUrl}sitter/assignments/:id:/welcome-guide/`,

            // POST: Request assignment welcome guide
            welcomeGuideRequest: `${v3ApiUrl}sitter/assignments/:assignmentId:/welcome-guide-request/`,

            // GET: Assignment events
            reminders: `${v3ApiUrl}sitter/assignments/:id:/reminders/`,
        },

        confirmed: {
            // GET: Page 1 the bottom group
            _: `${v3ApiUrl}sitter/assignments/confirmed/`,

            // GET ?page: Current assignments
            current: {
                _: `${v3ApiUrl}sitter/assignments/confirmed/current/`,
                assignment: `${v3ApiUrl}sitter/assignments/confirmed/current/:id:/`,
            },
            // GET ?page: Upcoming assignments
            upcoming: {
                _: `${v3ApiUrl}sitter/assignments/confirmed/upcoming/`,
                assignment: `${v3ApiUrl}sitter/assignments/confirmed/upcoming/:id:/`,
            },
            // GET ?page: Past assignments
            past: {
                _: `${v3ApiUrl}sitter/assignments/confirmed/past/`,
                // GET
                assignment: `${v3ApiUrl}sitter/assignments/confirmed/past/:id:/`,

                // GET, POST: Assignment feedback
                feedback: `${v3ApiUrl}sitter/assignments/confirmed/past/:assignmentId:/feedback/`,

                // GET, PUT: Assignment review
                review: `${v3ApiUrl}sitter/assignments/confirmed/past/:assignmentId:/review/`,

                // GET, POST: Request review
                reviewRequest: `${v3ApiUrl}sitter/assignments/confirmed/past/:assignmentId:/review-request/`,
            },
        },
    },

    // Sit Invitations
    invitations: {
        // GET: invitations
        _: `${v3ApiUrl}sitter/invitations/`,

        // GET, PUT: invitation details
        invitation: `${v3ApiUrl}sitter/invitations/:id:/`,
    },
    // Sit Applications
    applications: {
        // GET applications, POST: application
        _: `${v3ApiUrl}sitter/applications/`,

        // POST: batch applications
        batch: `${v3ApiUrl}sitter/applications/batch/`,

        // GET, PUT: application details (confirm, unconfirm, cancel)
        application: `${v3ApiUrl}sitter/applications/:id:/`,

        // Only available from v2021-10-26
        messages: `${v3ApiUrl}sitter/applications/messages/`,
    },

    // Profile
    profile: {
        // GET, POST, PUT: Profile details

        _: `${v3ApiUrl}sitter/profile/`,
        // Profile photos
        photos: {
            // GET: Profile photos
            _: `${v3ApiUrl}sitter/profile/photos/`,

            // DELETE, PUT: Profile photo
            // Use this with PUT instead of POST to create a profile photo
            photo: `${v2ApiUrl}listings/housesitter/media/photo/:mediaId:/`,
        },
        // Profile videos
        videos: {
            // GET, POST: Profile videos
            _: `${v3ApiUrl}sitter/profile/videos/`,

            // DELETE, PUT: Profile video
            video: `${v3ApiUrl}sitter/profile/videos/:id:/`,
        },

        availabilities: {
            // GET, POST ?fromDate&toDate: Availabilities
            _: `${v3ApiUrl}sitter/profile/availabilities/`,

            // GET, PUT: Availability
            availability: `${v3ApiUrl}sitter/profile/availabilities/:availabilityId:/`,
        },

        // GET: Verifications
        verifications: `${v3ApiUrl}sitter/profile/verifications/`,
    },

    // GET, POST: Conversations ?page
    conversations: {
        _: `${v3ApiUrl}sitter/conversations/:contactId:/`,

        // POST: batch applications
        batch: `${v3ApiUrl}sitter/conversations/batch/`,
    },

    // GET: Reviews
    reviews: {
        _: `${v3ApiUrl}sitter/reviews/`,
    },

    // GET, POST: Pets
    pets: {
        _: `${v3ApiUrl}sitter/pets/`,

        // PUT, DELETE:
        pet: `${v3ApiUrl}sitter/pets/:pet_id:/`
    },
};
