const ButtonSize = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    XLARGE: 'xlarge',
});

const ButtonVariant = Object.freeze({
    CTA: 'cta',
    ALTERNATE_CTA: 'alternateCta',
    CTA_REVERSED: 'ctaReversed',
    DESTRUCTIVE: 'destructive',
    DROPDOWN: 'dropdown',
    INFO_CLEAR: 'info_clear',
    INFO: 'info',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SECONDARY_PROCEED: 'secondaryProceed',
    SECONDARY_REVERSED: 'secondaryReversed',
    STICKY: 'sticky',
    LINK: 'link',
    LINK_SUBTLE: 'linkSubtle',
    FLAT: 'flat',
    ALTERNATE_PRIMARY: 'alternatePrimary',
    ALTERNATE_SECONDARY: 'alternateSecondary',
    TAB: 'tab',
    SUBTLE: 'subtle',
});

export { ButtonVariant, ButtonSize };
