/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { Panel } from 'components/layouts';

const PanelStyled = styled(Panel)`
    margin-top: ${({ theme }) => theme.spacing[6]};
    margin-bottom: ${({ theme }) => theme.spacing[6]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        margin-top: ${({ theme }) => theme.spacing[8]};
        margin-bottom: ${({ theme }) => theme.spacing[8]};
    }
`;

const VariantPanel = styled(Panel)`
    ${({ theme, variant }) =>
        variant &&
        css`
            padding: ${theme.spacing[0]};
        `}
`;

const FullWidthPanelStyled = styled(PanelStyled)`
    padding: 0;
`;

const ContainerStyled = styled.div`
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: ${({ mediumTablet = 12 }) => `calc(${(100 / 12) * mediumTablet}% - 32px);`};
    }
    /* This is a bit "magic" but it basically gives us "mediumTablet" columns out of 12 (defaulting to 10), minus the margins. */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        width: ${({ mediumDesktop = 10 }) => `calc(${(100 / 12) * mediumDesktop}% - 32px);`};
    }
    /* Same as above but with 8 columns */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        width: ${({ largeDesktop = 8 }) => `calc(${(100 / 12) * largeDesktop}% - 32px);`};
    }
`;

const VideoPanelStyled = styled(PanelStyled)`
    margin-bottom: 0px;
    ${({ backgroundColor }) =>
        backgroundColor &&
        `
        background-color: ${backgroundColor};
    `}
`;

const PStyled = styled.p`
    font-size: ${({ theme }) => theme.fontSizes.medium};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.medLarge};
    }
`;

const PVideoStyled = styled.p`
    ${({ theme: { typography } }) => typography.p};
    margin-top: ${({ theme: { spacing } }) => spacing[6]};
    margin-bottom: 0px;
`;

export {
    ContainerStyled,
    FullWidthPanelStyled,
    PanelStyled,
    PStyled,
    PVideoStyled,
    VideoPanelStyled,
    VariantPanel,
};
