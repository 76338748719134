// eslint-disable-next-line import/prefer-default-export
export const USER_LISTING_EDIT_TITLE = {
    create(listing) {
        const { title = '' } = listing || {};

        return {
            type: 'Listing Edit Title Update',
            payload: {
                title: title.length > 0,
            },
        };
    },
};
