import loadable from '@loadable/component';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getSeoContent } from 'shared/selectors';
import { retry } from 'utils/ssr';
import asPage from 'containers/Page';

const LoadableTrustAndSafety = loadable(() => retry(() => import('./TrustAndSafety')));

const mapStateToProps = (state) => ({
    seoContent: getSeoContent(state),
});

export default connect(
    mapStateToProps,
    null
)(asPage(withTranslation()(LoadableTrustAndSafety), 'TrustAndSafety'));
