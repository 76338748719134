import { createAction } from 'utils/actions';

const PREFIX = 'RAF_NUDGEWALL';

/**
 * @typedef {"checking" | "ticking" | "waiting-user" | "waiting-time" | "banner-conflict"} StateNudgewall
 * @param {StateNudgewall} state
 */
export const updateState = createAction(PREFIX, 'UPDATE', 'state');
export const show = createAction(PREFIX, 'SHOW');
export const hide = createAction(PREFIX, 'HIDE');
export const softDismiss = createAction(PREFIX, 'SOFT_DISMISS');
export const tryOpen = createAction(PREFIX, 'TRY_OPEN');
