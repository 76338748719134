// eslint-disable-next-line import/prefer-default-export
export const USER_PROFILE_REFERENCES_VIEWED_PAGE = {
    create({ completedReferenceCount }) {
        return {
            type: 'Viewed References Page',
            payload: {
                completedReferenceCount,
            },
        };
    },
};
