import { animalMapping } from './common';
import { getAssignment } from './assignments';
import { dateSameOrAfter } from '../format/date';
import { capitalizeAll } from '../format/text';
import { prioritisedPetOrder } from '../format/animals';
/**
 * User
 * @param user
 * @returns {*}
 */
export const getSitter = (user) => {
    if (!user) return null;

    const {
        id,
        firstName,
        profilePhoto,
        isActive,
        isExpired,
        isRedacted,
        expiryDate,
        membershipType,
        membershipTier,
    } = user;

    let sitterIsActive = isActive;
    if (isActive === null || isActive === undefined) {
        if (expiryDate) {
            sitterIsActive = dateSameOrAfter(expiryDate, new Date().setHours(0, 0, 0, 0));
        } else {
            sitterIsActive = true;
        }
    }

    return {
        sitterId: id,
        sitterName: firstName,
        sitterAvatarPhoto: profilePhoto,
        sitterIsActive,
        sitterIsExpired: isExpired || false,
        sitterIsRedacted: isRedacted || false,
        sitterMembershipType: membershipType,
        sitterMembershipTier: membershipTier,
    };
};

/**
 * Profile
 * @param profile
 */
export const getProfile = (profile) => {
    if (!profile) return profile;
    const {
        // Common data (search | edit)
        id,
        user,
        location,
        title,
        introduction,
        hasExperience,
        motivation,
        relevantExperience,
        sex,
        birthDate,
        occupation,
        occupationType,
        partner,
        travellingWithChildren,
        verificationLevel,
        socialVerifications,
        completedVerifications,
        policeCheck,
        preferences,
        photos,
        videos,
        reviewsAverageScore,
        reviewsCount,
        assignments,
        referencesCount,
        references,
        animalExperience,
        availableDates,
        unavailableDates,
        // videoUrl,

        // Edit data
        partnerFirstName,
        partnerSex,
        partnerBirthDate,
        partnerOccupation,
        partnerOccupationType,
        animals,
        favouriteCountries, // /api/v3/sitter/profile/ doesn't return this
        countries, // It returns this, we handle the incompatibility below
        localAttractions,
        childrenAgeGroupsList,
        referencesAverageScore,
        withChildren,
        experience,
        policy,
        policeCheckDate,
        // otherAnimals,

        // Sitter only data
        isIncomplete,
        isPartiallyFilled,
        completedSteps,
        yearsOfExperience,
        skills,
        sitterPets,
    } = profile;

    let data = {
        id,
        title,
        location,
        sex,
        birthDate,
        occupation,
        occupationType,
        referencesAverageScore,
        relevantExperience,
        experience,
        introduction,
        motivation,
        hasExperience,

        verificationLevel,
        socialVerifications,
        completedVerifications: {},
        reviewsAverageScore,
        reviewsCount,
        referencesCount,
        references,

        completedSteps,
        isIncomplete,
        isPartiallyFilled,
        yearsOfExperience,
        skills,

        withChildren: false,
        favouriteCountries: [],
        localAttractions: [],
        animals: [],
        partner: {},
        children: [],
        pastAssignments: [],
        photos: [],
        videos: [],
        mainPhoto: null,
    };

    // Partner
    if (partner) {
        data = {
            ...data,
            partner: {
                ...partner,
                // @TODO workaround for bug https://github.com/trustedhousesitters/trustedhousesitters/issues/4291
                firstName: capitalizeAll(partner.firstName),
            },
        };
    } else {
        data = {
            ...data,
            partner: {
                // @TODO workaround for bug https://github.com/trustedhousesitters/trustedhousesitters/issues/4291
                firstName: capitalizeAll(partnerFirstName),
                sex: partnerSex,
                birthDate: partnerBirthDate,
                occupation: partnerOccupation,
                occupationType: partnerOccupationType,
            },
        };
    }

    // Children
    if (travellingWithChildren) {
        data = {
            ...data,
            children: travellingWithChildren,
            withChildren: true,
        };
    } else if (childrenAgeGroupsList) {
        data = { ...data, children: childrenAgeGroupsList };
    }

    if (withChildren) {
        data = { ...data, withChildren: true };
    }

    // Police check
    if (policeCheck) {
        data = { ...data, policeCheck };
    } else {
        data = {
            ...data,
            policeCheck: {
                type: policy,
                issueDate: policeCheckDate,
            },
        };
    }

    // Preferences
    if (preferences) {
        data = {
            ...data,
            localAttractions: preferences.localAttractions || [],
            favouriteCountries: preferences.countries || [],
        };
    } else {
        let transformedCountries = favouriteCountries || [];

        // /api/v3/sitter/profile/ doesn't return `transformedCountries` it returns `countries`
        // in a different shape
        if (countries) {
            transformedCountries = countries.map((country) => ({
                countryName: country.name,
                countrySlug: country.slug,
            }));
        }

        data = {
            ...data,
            localAttractions: localAttractions || [],
            favouriteCountries: transformedCountries,
        };
    }

    // Assignments
    if (assignments) {
        data = {
            ...data,
            pastAssignments: assignments.map((assignment) => getAssignment(assignment)),
        };
    }

    // Animals
    if (yearsOfExperience) {
        data = { ...data, yearsOfExperience };
    }

    if (skills || animalExperience) {
        let array = skills || [];
        if (animalExperience) {
            array = [...array, ...animalExperience.map((animal) => animalMapping(animal.slug))];
        }
        const prioritizedArray = prioritisedPetOrder(array);
        data = {
            ...data,
            skills: prioritizedArray,
        };
    }

    if (animalExperience) {
        data = {
            ...data,
            animals: animalExperience.map((animal) => ({
                ...animal,
                slug: animalMapping(animal.slug),
            })),
        };
    } else if (animals) {
        data = {
            ...data,
            animals: animals.map((animal) => ({
                ...animal,
                slug: animalMapping(animal.slug),
            })),
        };
    }

    if (sitterPets) {
        const photosAsArray = (input) => {
            if (!input) return [];
            return [
                {
                    id: input.id,
                    publicId: input.publicId,
                    description: input.description,
                },
            ];
        };
        data = {
            ...data,
            sitterPets: sitterPets.map((pet) => ({
                breed: pet.breed,
                relationship: pet.relationship,
                name: pet.name,
                animal: pet.animal,
                photos: photosAsArray(pet.photo),
            })),
        };
    }

    // Available dates
    if (availableDates) {
        data = { ...data, availableDates };
    }

    // Unavailable dates
    if (unavailableDates) {
        data = { ...data, unavailableDates };
    }

    // User data
    const sitterData = getSitter(user);
    if (sitterData) {
        data = { ...data, ...sitterData };
    }

    if (photos && photos.length > 0) {
        data = {
            ...data,
            photos,
            mainPhoto: photos[0],
        };
    }

    if (videos && videos.length > 0) {
        data = { ...data, videos };
    }

    if (completedVerifications) {
        data = { ...data, completedVerifications };
    }

    return data;
};

export const updateProfileFromPartialResponse = (profile, partial) => ({
    ...profile,
    location: { ...profile.location, ...(partial.location || {}) },
    photos: partial.photos,
    referencesAverageScore: partial.referencesAverageScore,
    referencesCount: partial.referencesCount,
    reviewsAverageScore: partial.reviewsAverageScore,
    reviewsCount: partial.reviewsCount,
    ...getSitter(partial.user),
});
