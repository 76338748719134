import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Span } from 'typography';
import { routes } from 'utils/routes';
import { IconShare } from 'components/Icon';
import {
    TitleContainerStyled,
    TitleStyled,
    MobileShareButtonStyled,
    InfoContainerStyled,
    AuthorContainerStyled,
    AuthorAvatarStyled,
    AuthorName,
    AuthorDateAndReadingTimeStyled,
    PublishedContainerStyled,
    ReadingTimeStyled,
} from './Header.style';

const PostHeader = ({ history, canonicalUrl, title, author, published, readingTime }) => {
    const { t } = useTranslation();

    const avatar = author?.avatar;
    const authorName = author?.name;

    const hasAuthorOrPublished = author || published;

    const avatarImageUrl = avatar?.mobile?.url ?? avatar?.url;

    const openNativeShare = async () => {
        try {
            await navigator.share({
                title: title[0].text,
                url: canonicalUrl,
            });
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <TitleContainerStyled>
                <TitleStyled>{title[0].text}</TitleStyled>
                <MobileShareButtonStyled onClick={openNativeShare}>
                    <IconShare palette="primaryRegular" size="large" />
                </MobileShareButtonStyled>
            </TitleContainerStyled>

            {hasAuthorOrPublished && (
                <InfoContainerStyled>
                    <AuthorContainerStyled>
                        {avatarImageUrl && (
                            <AuthorAvatarStyled src={avatarImageUrl} alt={author.avatar.alt} />
                        )}
                        <AuthorDateAndReadingTimeStyled>
                            <AuthorName
                                onClick={() => {
                                    history.push(
                                        routes.blog.author({
                                            area: 'blog',
                                            author: author.shortName,
                                        })
                                    );
                                }}
                            >
                                {authorName}
                            </AuthorName>
                            <PublishedContainerStyled>
                                <Span>
                                    {t('components_content_post_lastPublishedDate', {
                                        date: published,
                                    })}
                                </Span>
                                {readingTime && (
                                    <ReadingTimeStyled>
                                        {t('components_content_post_reading_time', {
                                            readingTime,
                                        })}
                                    </ReadingTimeStyled>
                                )}
                            </PublishedContainerStyled>
                        </AuthorDateAndReadingTimeStyled>
                    </AuthorContainerStyled>
                </InfoContainerStyled>
            )}
        </>
    );
};

export default withRouter(PostHeader);
