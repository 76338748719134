import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import asPage from 'containers/Page';

import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';

import { getSearchQuery, getSearchQueryParams } from './selectors';
import { PAGE_ID } from './SavedSearchRedirect.constants';
import SavedSearchRedirect from './SavedSearchRedirect';

const mapStateToProps = (state) => ({
    searchQuery: getSearchQuery(state),
    queryParamString: getSearchQueryParams(state),
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(mapStateToProps)(
    asPage(withTranslation()(SavedSearchRedirect), PAGE_ID, false, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
