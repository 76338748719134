/* eslint-disable import/prefer-default-export */
import environment from 'environment';

/**
 * This gets the canonical url for a given page
 */
const getCanonical = (pathname) =>
    `${environment.vars.protocol}${environment.vars.hostname}${pathname}`;

const getLocationFromPathname = (pathname) => {
    const pathnameValues = pathname?.split('/')?.filter(String) || [];
    const revertedValues = pathnameValues.slice().reverse();

    if (revertedValues.length > 0) return revertedValues[0];

    return '';
};

const isLocationInDuplicateList = (location, regionsCitiesList) =>
    regionsCitiesList.includes(location);

const isPathnameARegionLocation = (location, pathname) => {
    const pathnameValues = pathname.split('/').filter(String);
    const locationCount = pathnameValues.filter((value) => value === location).length;

    return locationCount === 1;
};

const shouldGenerateACityCanonical = (location, pathname, regionsCitiesList) =>
    isLocationInDuplicateList(location, regionsCitiesList) &&
    isPathnameARegionLocation(location, pathname);

/**
 * This maps canonical regions that have a city of same name
 * to their respective city pages
 */
const getSearchPageCanonical = (pathname = '', regionsCitiesList) => {
    const canonical = getCanonical(pathname);
    const location = getLocationFromPathname(pathname);

    if (shouldGenerateACityCanonical(location, pathname, regionsCitiesList)) {
        return `${canonical}${location}/`;
    }

    return canonical;
};

/**
 * This creates the schema markup for the FAQ page
 *
 * @param {Array} items - the FAQ items
 * @returns {Object} - the schema markup
 */
const createFAQSchemaMarkup = (items) => ({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map((item) => ({
        '@type': 'Question',
        name: item.markup.question,
        acceptedAnswer: {
            '@type': 'Answer',
            text: item.markup.answer,
        },
    })),
});

export { getCanonical, getSearchPageCanonical, getLocationFromPathname, createFAQSchemaMarkup };
