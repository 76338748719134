import { all, put, take, takeEvery, select } from 'redux-saga/effects';
import actions, { settings } from 'api/actions';
import * as pageActions from 'containers/Page/actions';
import { PAGE_ID } from './constants';
import { getPrivacyPolicyContent } from './selectors';

export function* loadPrivacyPolicy({ type }) {
    let privacyPolicyContent = yield select(getPrivacyPolicyContent);

    if (Object.keys(privacyPolicyContent).length === 0) {
        yield put(actions.policies.loadPrivacyPolicy());
        const { status } = yield take(settings.policies.loadPrivacyPolicy.DONE);
        if (status === settings.policies.loadPrivacyPolicy.SUCCESS) {
            privacyPolicyContent = yield select(getPrivacyPolicyContent);
        }
    }

    if (privacyPolicyContent) {
        // If successful then show as loaded
        if (type === pageActions.preload.ACTION) {
            yield put(pageActions.preloaded.create(PAGE_ID));
        } else if (type === pageActions.load.ACTION) {
            yield put(pageActions.loaded.create(PAGE_ID));
        }
    }

    return true;
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            loadPrivacyPolicy
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            loadPrivacyPolicy
        ),
    ]);
}
