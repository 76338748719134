// eslint-disable-next-line import/prefer-default-export
export const USER_LISTING_EDIT_ABOUT_YOU = {
    create(listing = {}) {
        return {
            type: 'Listing Edit About You Update',
            payload: {
                introduction: listing.introduction ? listing.introduction.length >= 50 : false,
                profilePhoto: listing.user ? !!listing.user.profilePhoto : false,
            },
        };
    },
};
