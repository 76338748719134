/* eslint-disable import/prefer-default-export */
import { format, endOfToday, endOfDay, lastDayOfMonth, isValid } from 'date-fns';

// Helpers
import { dateSameOrAfter, dateAfter, parseDate } from 'api/helpers/format/date';
import { MembershipType, MembershipStatus } from 'api/types/membershipTypes';
/* eslint-disable import/no-unresolved, import/extensions */
import { PlanTiers } from 'api/types/plans';
import { getUTCDate } from 'utils/date';

export const getMembershipType = (membership = {}) => membership.membershipType;

/**
 * Returns a flag if membership is active or not
 * @param membership
 * @returns boolean
 */
export const getIsMembershipActive = (membership = {}) => {
    if (membership.expiryDate) {
        const expiryDate = parseDate(membership.expiryDate);

        return dateSameOrAfter(expiryDate, format(getUTCDate(), 'yyyy-MM-dd'));
    }

    return false;
};

/**
 * Returns a flag if there is a pending plan change (e.g. a downgrade)
 * @param membership
 * @param subscribed
 * @returns boolean
 */
export const getHasPendingPlanChange = (membership = {}, subscribed = {}) =>
    Boolean(membership.id && subscribed.id && membership.id !== subscribed.id);

/**
 * Returns a flag if member is active or not
 * @param membership
 * @returns {{isActive: boolean}}
 */
export const getMembershipPartialState = (membership = {}) => !membership.expiryDate;

/**
 * Returns a string representation of the current membershipStatus
 * @param {*} membership
 * @returns partial | member | expired
 */
export function getMembershipStatus(membership) {
    if (!membership || (typeof membership === 'object' && Object.keys(membership).length === 0)) {
        // PLEASE NOTE you should not use getMembershipStatus unless you have first checked
        // if the user has logged in (getIsLoggedIn selector).
        return null;
    }

    const { expiryDate } = membership;
    const expiryDateParsed = parseDate(expiryDate);

    if (expiryDate === null) {
        return MembershipStatus.PARTIAL;
    }

    // checks if today is after expiry date
    if (dateAfter(endOfToday(), endOfDay(expiryDateParsed))) {
        return MembershipStatus.EXPIRED;
    }

    // checks if expiry date is today or after
    if (dateSameOrAfter(endOfDay(expiryDateParsed), endOfToday())) {
        return MembershipStatus.MEMBER;
    }

    return null;
}

export function getMembershipTier(membership) {
    if (membership && membership.tier) {
        return membership.tier;
    }

    return null;
}

// Some helpers for deciding how to treat the user based on their membership
export const isSitterOnly = (membershipPlan) =>
    getMembershipType(membershipPlan) === MembershipType.SITTER;
export const isOwnerOnly = (membershipPlan) =>
    getMembershipType(membershipPlan) === MembershipType.OWNER;
export const isCombined = (membershipPlan) =>
    getMembershipType(membershipPlan) === MembershipType.COMBINED;

export const hasSitter = (membershipPlan) =>
    isSitterOnly(membershipPlan) || isCombined(membershipPlan);

export const hasOwner = (membershipPlan) =>
    isOwnerOnly(membershipPlan) || isCombined(membershipPlan);

export const isPartial = (membershipPlan) =>
    getMembershipStatus(membershipPlan) === MembershipStatus.PARTIAL;

export const isMember = (membershipPlan) =>
    getMembershipStatus(membershipPlan) === MembershipStatus.MEMBER;

export const isExpired = (membershipPlan) =>
    getMembershipStatus(membershipPlan) === MembershipStatus.EXPIRED;

export const isPremium = (membershipPlan = {}) => membershipPlan.tier === PlanTiers.PREMIUM;
export const isStandard = (membershipPlan = {}) => membershipPlan.tier === PlanTiers.STANDARD;
export const isBasic = (membershipPlan = {}) => membershipPlan.tier === PlanTiers.BASIC;
export const isClassic = (membershipPlan = {}) => membershipPlan.tier === PlanTiers.CLASSIC;

export const isCardValidObject = (card) => {
    if (!card || typeof card !== 'object') {
        return false;
    }

    if (
        !Object.prototype.hasOwnProperty.call(card, 'expiryMonth') ||
        !Object.prototype.hasOwnProperty.call(card, 'expiryYear')
    ) {
        return false;
    }

    return true;
};

// Takes card object from getHasMembership
export const getCardExpiry = (card) => {
    if (!isCardValidObject(card)) {
        return null;
    }

    // Creating a Date object ensures single digit months are prefixed with 0 eg 06
    const cardExpiryDateObject = new Date(card.expiryYear, card.expiryMonth - 1);

    if (!isValid(cardExpiryDateObject)) {
        return null;
    }

    return format(cardExpiryDateObject, 'MM/yy');
};

export const getCardExpiryDate = (card) => {
    if (!isCardValidObject(card)) {
        return null;
    }

    const cardExpiryDate = new Date(`${card.expiryYear}-${card.expiryMonth}`);

    if (!isValid(cardExpiryDate)) {
        return null;
    }

    // yyyy-MM-DDTHH:mm:ss.SSSZ
    return endOfDay(lastDayOfMonth(cardExpiryDate));
};

export const isAutorenewing = (membershipPlan) => membershipPlan && membershipPlan.isAutorenewing;

export const getHasSubscriptionId = (membershipPlan) =>
    membershipPlan && membershipPlan.hasSubscriptionId;
