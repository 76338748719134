import React, { useEffect } from 'react';
import { useModal, useTrail } from '@ths/design-system';
import { CloseIcon } from '@ths/design-system/icons';
import { track, Events } from 'utils/analytics';
import SelectMembershipType from '../SelectMembershipType';
import { STEPS } from '../../RegwallDialog.constants';
import { useRegwallDialog } from '../../useRegwallDialog';

const SelectMembershipStep = () => {
    const { requestClose } = useModal();
    const { push } = useTrail();
    const { isCloseable, selectMembershipType, membershipType, openMethod } = useRegwallDialog();

    const handleSelectMembershipType = (type) => {
        selectMembershipType(type);
        track(Events.BUTTONCLICK.create({ description: `reg wall select ${type}` }));
        push(STEPS.INITIAL_FORM);
    };

    useEffect(() => {
        track(
            Events.VIEWED_REGWALL.create({
                membershipType,
                method: openMethod,
            })
        );
    }, [membershipType, openMethod]);

    return (
        <>
            {isCloseable ? (
                <button
                    type="button"
                    className="absolute right-3 top-3 h-12 w-12 border-0 p-0 text-utility-white md:left-3 md:right-0"
                    style={{
                        // FIXME: For some reason, the Panel that the Regwall use
                        // define z-index, we need to override it
                        zIndex: 9999,
                    }}
                    onClick={requestClose}
                >
                    <CloseIcon className="h-5 w-5" />
                </button>
            ) : null}

            <SelectMembershipType onSelectMembershipType={handleSelectMembershipType} />
        </>
    );
};

export default SelectMembershipStep;
