import { settings as actions } from '../../../actions';

import { uniqueArray } from '../../../helpers/format/objects';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadSitterOwnerInvitations.SUCCESS: {
            const { startingAfter } = action.requestData;
            // Append search results
            if (startingAfter) {
                return {
                    ...state,
                    [action.requestData.id]: uniqueArray([
                        ...state[action.requestData.id],
                        ...action.data.results.map(item => item.id),
                    ]),
                };
            }
            return {
                ...state,
                [action.requestData.id]: action.data.results.map(item => item.id),
            };
        }
        case actions.sitter.loadInvitation.SUCCESS: {
            const userId = action.data.listing.user.id;
            const applications = [
                ...(state[userId] || []),
            ];
            if (applications.indexOf(action.data.id) < 0) {
                applications.push(action.data.id);
            }
            return {
                ...state,
                [userId]: applications,
            };
        }
        default:
            return state;
    }
};
