import { connect } from 'react-redux';
import { getLocale } from '../../shared/selectors';

const REGIONAL_SETTINGS = {
    GB: {
        phone: '08081 785384',
        phoneShort: '08081785384',
        phoneVetIsLocal: true,
        country: 'UK',
        countryContext: 'UK',
        currencyCode: 'GBP',
        freephoneMessage: 'components_phoneNumber_freephone_message_freephone',
    },
    US: {
        phone: '844 293 6126',
        phoneShort: '8442936126',
        phoneVet: '+1 602-786-8353',
        phoneVetShort: '+16027868353',
        phoneVetStandardPremium: '+1 845-444-1219',
        phoneVetStandardPremiumShort: '+18454441219',
        phoneVetIsLocal: true,
        country: 'USA',
        countryContext: 'USA',
        currencyCode: 'USD',
        freephoneMessage: 'components_phoneNumber_freephone_message_tollfree',
    },
    CA: {
        phone: '844 294 8535',
        phoneShort: '8442948535',
        phoneVet: '+1 866-880-4829',
        phoneVetShort: '+18668804829',
        phoneVetStandardPremium: '+1 866-880-4829',
        phoneVetStandardPremiumShort: '+18668804829',
        phoneVetIsLocal: true,
        country: 'Canada',
        countryContext: 'Canadian',
        currencyCode: 'CAD',
    },
    AU: {
        phone: '0 1300 297728',
        phoneShort: '01300297728',
        phoneVetIsLocal: false,
        phoneVetOrigin: 'UK',
        country: 'Australia',
        countryContext: 'Australian',
        currencyCode: 'AUD',
        freephoneMessage: 'components_phoneNumber_freephone_message_tollfree',
    },
    NZ: {
        phone: '09 801 6051',
        phoneShort: '098016051',
        phoneVet: '+64 9-887 9467',
        phoneVetShort: '+6498879467',
        phoneVetStandardPremium: '+64 9-887 9467',
        phoneVetStandardPremiumShort: '+6498879467',
        phoneVetIsLocal: true,
        country: 'New Zealand',
        countryContext: 'New Zealand',
        currencyCode: 'NZD',
    },
    IE: {
        phone: '076 680 5343',
        phoneShort: '0766805343',
        phoneVetIsLocal: false,
        phoneVetOrigin: 'UK',
        country: 'Ireland',
        countryContext: 'Irish',
        currencyCode: 'EUR',
    },
    FR: {
        // +44 808 178 5384
        phone: '+44 8081 785 384',
        phoneShort: '+448081785384',
        phoneVetIsLocal: false,
        phoneVetOrigin: 'UK',
        country: 'France',
        countryContext: 'French',
        currencyCode: 'EUR',
    },
    NL: {
        // 0808 178 5384
        phone: '+44 8081 785 384',
        phoneShort: '+448081785384',
        phoneVetIsLocal: false,
        phoneVetOrigin: 'UK',
        country: 'Netherlands',
        countryContext: 'Dutch',
        currencyCode: 'EUR',
    },
    MX: {
        // +1 (844) 293-6126
        phone: '+1 (844) 293 6126',
        phoneShort: '+18442936126',
        phoneVetIsLocal: false,
        phoneVetOrigin: 'UK',
        country: 'Mexico',
        countryContext: 'Mexican',
        currencyCode: 'MXN',
    },
    ZA: {
        phone: '0875504781',
        phoneShort: '0875504781',
        phoneVetIsLocal: false,
        phoneVetOrigin: 'UK',
        country: 'South Africa',
        countryContext: 'South Africa',
        currencyCode: 'ZAR',
    },
    SE: {
        phone: '010 1388551',
        phoneShort: '0101388551',
        phoneVetIsLocal: false,
        phoneVetOrigin: 'UK',
        country: 'Sweden',
        countryContext: 'Swedish',
        currencyCode: 'SEK',
    },
    default: {
        phone: '+44 (0)1273 358769',
        phoneShort: '+441273358769',
        phoneVet: '+44 (0) 203 4740753',
        phoneVetShort: '+442034740753',
        phoneVetStandardPremium: '+44 (0) 333 332 4335',
        phoneVetStandardPremiumShort: '+443333324335',
        phoneVetIsLocal: false,
        phoneVetOrigin: 'UK',
        country: 'UK',
        countryContext: 'UK',
        currencyCode: 'GBP',
    },
};

export const getPhoneNumberForLocale = (countryISOCode) => {
    if (countryISOCode && REGIONAL_SETTINGS[countryISOCode]) {
        return { ...REGIONAL_SETTINGS.default, ...REGIONAL_SETTINGS[countryISOCode] };
    }
    return REGIONAL_SETTINGS.default;
};

const PhoneNumber = ({ children, phoneDetails }) =>
    children({
        phoneNumber: phoneDetails.phone,
        phoneNumberShort: phoneDetails.phoneShort,
        phoneVet: phoneDetails.phoneVet,
        phoneVetShort: phoneDetails.phoneVetShort,
        phoneVetStandardPremium: phoneDetails.phoneVetStandardPremium,
        phoneVetStandardPremiumShort: phoneDetails.phoneVetStandardPremiumShort,
        freephoneMessage: phoneDetails.freephoneMessage,
    });

const mapStateToProps = (state) => {
    const { countryISOCode } = getLocale(state);
    const phoneDetails = getPhoneNumberForLocale(countryISOCode);
    return {
        phoneDetails,
    };
};

export default connect(mapStateToProps)(PhoneNumber);
