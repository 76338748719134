export const STORAGE_KEY = 'prompt-manager';

export const PRIORITY = {
    HIGH: 5,
    MEDIUM: 3,
    LOW: 1,
};

export const TRIGGER = {
    ON_LOAD: 1,
    ON_LOCATION_CHANGE: 2,
};
