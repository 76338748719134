/* eslint-disable react-hooks/rules-of-hooks */
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { locationName as formatLocationName } from 'api/helpers/format/location';
import * as SearchConstants from 'api/helpers/search/constants';
import { SQUARE_BIGGER } from 'config/images';
import { StaticRating } from 'components/Rating';
import { IconReference } from 'components/Icon';
import { useMediaQuery } from 'utils/hooks';
import useQuery from 'hooks/useQuery';
import { getRouteParamsSearchSitters, hasRouteParams } from 'utils/urls';
import environment from '../../environment';

import {
    FavouriteLabelStyled,
    FavouriteStyled,
    LinkStyled,
    ProfileContainerStyled,
    ProfileImageWrapperStyled,
    ProfileImageContainerStyled,
    ProfileImageOuterWrapperStyled,
    LocationStyled,
    TitleWrapperStyled,
    TitleStyled,
    VerificationBadgeOuterWrapperStyled,
    VerificationBadgeInnerWrapperStyled,
    TooltipWrapper,
    TooltipStyled,
    IDBadgeStyled,
    CBCBadgeStyled,
    ImageStyled,
    ReviewStyled,
    ReviewCountStyle,
    TitleDescriptionStyled,
} from './ProfileCard.style';

export const productTriggerParams = new URLSearchParams(
    `?t=${JSON.stringify({ productTrigger: 'Find a pet sitter' })}`
);

// since ProfileCard is a class component it means a hook wrapper needs to be created
// otherwise hooks won't work
export const useWithHooksHOC = (Component) => (props) => {
    // only open link in a new tab if user is browsing from a desktop / laptop
    const mediaQuery = useMediaQuery('(min-width: 600px) and (pointer: fine)');
    const search = useQuery();
    const { pathname } = useLocation();
    return <Component {...props} openInNewTab={mediaQuery} search={search} pathname={pathname} />;
};

class ProfileCard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { linkTarget: '_self' };
    }

    componentDidMount() {
        const { openInNewTab } = this.props;
        this.setState({ linkTarget: openInNewTab ? '_blank' : '_self' });
    }

    renderReview() {
        const { profile } = this.props;
        const {
            referencesCount = 0,
            referencesAverageScore = 0,
            reviewsCount = 0,
            reviewsAverageScore = 0,
        } = profile;

        const hasReviews = reviewsCount > 0 && reviewsAverageScore > 0;
        const hasReferences = referencesCount > 0 && referencesAverageScore > 0;

        if (!hasReviews && !hasReferences) return null;

        return (
            <ReviewStyled>
                {hasReviews && (
                    <>
                        <StaticRating value={reviewsAverageScore} size="sm" />
                        <ReviewCountStyle>{reviewsCount}</ReviewCountStyle>
                    </>
                )}

                {!hasReviews && hasReferences && (
                    <>
                        <StaticRating value={referencesAverageScore} size="sm">
                            <IconReference />
                        </StaticRating>

                        <ReviewCountStyle>{referencesCount}</ReviewCountStyle>
                    </>
                )}
            </ReviewStyled>
        );
    }

    render() {
        const {
            t,
            href,
            id,
            isFavourite,
            profile,
            onFavourite,
            profilesDefaultImage,
            showFavourite,
            showTitleDescription,
            showSittersLookingInYourAreaTestFeatures,
            ownerListingApproved,
            search,
            pathname,
        } = this.props;
        const { linkTarget } = this.state;
        const { sitterAvatarPhoto, sitterName, location, verificationLevel, partner, title } =
            profile;

        const cardImg = {
            default: profilesDefaultImage,
            publicId: sitterAvatarPhoto ? sitterAvatarPhoto.publicId : null,
            imageTransformation: 'square',
        };

        const hasIDCheck = verificationLevel === SearchConstants.VerificationLevel.STANDARD;
        const hasCBC = verificationLevel === SearchConstants.VerificationLevel.ENHANCED;
        const showVerificationBadge = hasIDCheck || hasCBC;

        const hasPartner = !!(partner && partner.firstName);
        const showLookingInYourArea =
            showSittersLookingInYourAreaTestFeatures && ownerListingApproved;
        let sitterTitle = sitterName;
        if (hasPartner) {
            sitterTitle = `${sitterName} & ${partner.firstName}`;
        }

        const searchParams = new URLSearchParams(search.toString());
        if (showLookingInYourArea) {
            searchParams.append('lookingInYourArea', 'true');
        }
        if (hasRouteParams('house-and-pet-sitters', pathname)) {
            searchParams.append(
                'routeParams',
                JSON.stringify(getRouteParamsSearchSitters(pathname))
            );
        }

        const profileLink = `${href}?${productTriggerParams.toString()}${
            searchParams.toString() && `&${searchParams.toString()}`
        }`;

        return (
            <ProfileContainerStyled
                aria-label={t('components_profileCard_ariaLabel', {
                    sitterTitle,
                })}
                data-testid="ProfileCard__container"
            >
                {showFavourite && (
                    <FavouriteStyled
                        label={
                            <FavouriteLabelStyled>
                                {t('components_profileCard_favourite')}
                            </FavouriteLabelStyled>
                        }
                        id={id}
                        isChecked={isFavourite}
                        onChange={() => onFavourite(profile)}
                    />
                )}
                <LinkStyled to={profileLink} target={linkTarget}>
                    <ProfileImageOuterWrapperStyled>
                        <ProfileImageWrapperStyled data-testid="ProfileCard__image">
                            <ProfileImageContainerStyled>
                                <ImageStyled
                                    alt={
                                        !hasPartner
                                            ? t('components_profileCard_imageAlt_single', {
                                                  sitterTitle,
                                              })
                                            : t('components_profileCard_imageAlt_other', {
                                                  sitterTitle,
                                              })
                                    }
                                    imageId={cardImg && cardImg.publicId}
                                    transformation={SQUARE_BIGGER}
                                    placeholderUrl={environment.vars.profiles.defaultImage}
                                    placeholderAlt={t('components_profileCard_placeholder_image')}
                                />
                            </ProfileImageContainerStyled>

                            {showVerificationBadge && (
                                <VerificationBadgeOuterWrapperStyled>
                                    <VerificationBadgeInnerWrapperStyled>
                                        <TooltipWrapper>
                                            <TooltipStyled
                                                body={
                                                    hasIDCheck
                                                        ? t(
                                                              'components_profileCard_verified_tooltip_id'
                                                          )
                                                        : t(
                                                              'components_profileCard_verified_tooltip_cbc'
                                                          )
                                                }
                                            >
                                                {hasIDCheck && <IDBadgeStyled icon="id-check1" />}
                                                {hasCBC && <CBCBadgeStyled icon="cbc" />}
                                            </TooltipStyled>
                                        </TooltipWrapper>
                                    </VerificationBadgeInnerWrapperStyled>
                                </VerificationBadgeOuterWrapperStyled>
                            )}
                        </ProfileImageWrapperStyled>
                    </ProfileImageOuterWrapperStyled>

                    <TitleWrapperStyled>
                        <TitleStyled data-testid="ProfileCard__title">{sitterTitle}</TitleStyled>
                    </TitleWrapperStyled>

                    <LocationStyled data-testid="ProfileCard__location">
                        {showLookingInYourArea
                            ? t('components_profileCard_looking_in_your_area')
                            : formatLocationName(location, true)}
                    </LocationStyled>

                    {showTitleDescription && title ? (
                        <TitleDescriptionStyled>{title}</TitleDescriptionStyled>
                    ) : null}

                    {this.renderReview()}
                </LinkStyled>
            </ProfileContainerStyled>
        );
    }
}

export { ProfileCard };

export default useWithHooksHOC(withTranslation()(ProfileCard));
