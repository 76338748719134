import { put, takeEvery, all, take, select } from 'redux-saga/effects';
import * as pageActions from 'containers/Page/actions';

import actions, { settings } from 'api/actions';
import { getBlogCategory } from 'api/selectors/content';
import { getLanguage } from '../../../shared/selectors';
import { PAGE_ID, SEARCH_TYPE } from './constants';

export function* raiseError(statusCode) {
    yield put(pageActions.error.create(PAGE_ID, statusCode));
}

export function* loadCategory({ type, params, search }) {
    const lang = yield select(getLanguage);

    // See if already loaded
    const category = yield select(getBlogCategory, params.area, params.category);

    // Category information is loaded if it contains the slug
    if (!category || !category.slug) {
        yield put(
            actions.content.loadBlogCategory({
                filters: {
                    lang,
                },
                data: {
                    searchType: SEARCH_TYPE,
                    areaSlug: params.area,
                    categorySlug: params.category,
                },
            })
        );

        const { status: loadBlogCategoryStatus, statusCode: loadBlogCategoryStatusCode } =
            yield take(settings.content.loadBlogCategory.DONE);

        if (loadBlogCategoryStatus !== settings.content.loadBlogCategory.SUCCESS) {
            // Dispatch error
            yield raiseError(loadBlogCategoryStatusCode);
            return false;
        }
    }

    yield put(
        actions.content.loadBlogCategoryAllSubcategories({
            forceReload: true,
            filters: {
                lang,
            },
            data: {
                searchType: SEARCH_TYPE,
                areaSlug: params.area,
                categorySlug: params.category,
            },
        })
    );

    yield put(
        actions.content.loadBlogCategoryPosts({
            forceReload: true,
            filters: {
                lang,
                page: parseInt(search.page, 10) || 1,
                limit: 12,
            },
            data: {
                searchType: SEARCH_TYPE,
                areaSlug: params.area,
                categorySlug: params.category,
            },
        })
    );

    const { status: loadBlogCategoryPostsStatus, statusCode: loadBlogCategoryPostsStatusCode } =
        yield take(settings.content.loadBlogCategoryPosts.DONE);

    if (loadBlogCategoryPostsStatus !== settings.content.loadBlogCategoryPosts.SUCCESS) {
        // Dispatch error
        yield raiseError(loadBlogCategoryPostsStatusCode);
        return false;
    }

    // If successful then show as loaded
    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(PAGE_ID));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(PAGE_ID));
    }

    return true;
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            loadCategory
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            loadCategory
        ),
    ]);
}
