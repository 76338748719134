import React from 'react';
import { Redirect } from 'react-router';
import RegwallDialog from 'components/RegwallDialog';
import { RegwallMessaging } from 'components/Regwall';

const GlobalRegwall = ({
    isRegwallVisible,
    regwallType,
    redirectUrl,
    messaging,
    onRegwallClose,
    openMethod,
    isRegwallCloseable,
    regwallCopy,
}) => {
    if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
    }

    if (!isRegwallVisible) {
        return null;
    }

    return (
        <RegwallDialog
            onCloseHandler={isRegwallCloseable ? () => onRegwallClose() : null}
            isOpen={isRegwallVisible}
            membershipType={regwallType}
            messaging={messaging ?? RegwallMessaging.JOIN}
            openMethod={openMethod}
            regwallCopy={regwallCopy}
        />
    );
};

export default GlobalRegwall;
