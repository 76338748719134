import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { spanStyles, h2Styles, h3Styles, pStyles } from 'typography';
import ImageLazyLoad from 'components/ImageLazyLoad';

const Wrapper = styled.article`
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        &:first-child {
            padding-left: ${({ theme }) => theme.spacing[3]};
        }

        &:last-child {
            padding-right: ${({ theme }) => theme.spacing[3]};
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        &:first-child {
            padding-left: ${({ theme }) => theme.spacing[4]};
        }

        &:last-child {
            padding-right: ${({ theme }) => theme.spacing[4]};
        }
    }
`;

const ImageStyled = styled(ImageLazyLoad)`
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -9999px;
    right: -9999px;
    bottom: -9999px;
    left: -9999px;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.regular};
`;

const LinkStyled = styled(Link)`
    display: block;
    text-decoration: none;
    color: inherit;
`;

const ImageLinkStyled = styled(LinkStyled)`
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;
`;

const CategoryStyled = styled.div`
    ${spanStyles};
    padding: ${({ theme }) => theme.spacing[3]} 0 ${({ theme }) => theme.spacing[1]} 0;
    color: ${({ theme }) => theme.palette.link};
    text-transform: capitalize;
`;

const TitleStyled = styled.h2`
    ${h3Styles};
    margin-top: 0px;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        ${h2Styles};
        margin-bottom: ${({ theme }) => theme.spacing[3]};
    }
`;

const ExcerptStyled = styled.p`
    ${pStyles};
    margin: ${({ theme }) => theme.spacing[2]} 0;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        line-height: 1.6;
        font-size: ${({ theme }) => theme.fontSizes.medLarge};
    }
`;

export {
    Wrapper,
    ImageStyled,
    ImageLinkStyled,
    LinkStyled,
    CategoryStyled,
    TitleStyled,
    ExcerptStyled,
};
