import styled from 'styled-components';
import { smallTextStyles } from 'typography';

export const WrapperStyled = styled.div``;

export const RatingItemStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.spacing[2]};
    }
`;

export const RatingStarsStyled = styled.span`
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: ${({ theme }) => theme.spacing[2]};
`;

export const RatingNameStyled = styled.span`
    ${smallTextStyles};
    flex-grow: 1;
`;
