import { rgba, mix } from 'polished';

const primary = {
    900: '#006263',
    700: '#006263',
    500: '#00A69B',
    300: '#E8FAE9',
    100: '#E8FAE9',
};
// Deprecated colour
const secondary = {
    500: '#74438F',
    300: '#DCC8FF',
};
// Deprecated colour
const tertiary = {
    500: '#25549C',
    300: '#78E7FF',
};
// Deprecated colour
const quaternary = {
    500: '#FFC000',
    300: '#FFF8E2',
};
const accent = {
    500: '#5A35D6',
    300: '#E3D3FF',
};
const greys = {
    900: '#001f1f',
    700: '#003939',
    500: '#657373',
    300: '#E5EBEB',
    200: '#E5EBEB',
    100: '#F2F5F5',
};

const error = '#B00020';
const black = '#000000';
const white = '#FFFFFF';
const focus = '#2CB0DC';
const highlight = {
    500: '#C9E94F',
};

// Add white to generate hover/press/disabled modifiers
// Used to modify darker colours (> 300)
const lightModifiers = (color) => ({
    hover: { value: mix(0.12, white, color) },
    press: { value: mix(0.24, white, color) },
    disabled: { value: rgba(color, 0.54) },
});

// Add black to generate hover/press/disabled modifiers
// Used to modify lighter colours (<= 300)
const darkModifiers = (color) => ({
    hover: { value: mix(0.06, black, color) },
    press: { value: mix(0.12, black, color) },
    disabled: { value: rgba(color, 0.54) },
});

// Represent the Figma view of the world:
// https://www.figma.com/file/gftck8QAXcJCPcpPWan7zU/Colours
// Should always be up to date with Figma. Mapping to named colours is done on the theme level
export default {
    primary: {
        900: {
            value: primary[900],
            modifiers: lightModifiers(primary[900]),
        },
        700: {
            value: primary[700],
            modifiers: lightModifiers(primary[700]),
        },
        500: {
            value: primary[500],
            modifiers: lightModifiers(primary[500]),
        },
        300: {
            value: primary[300],
            modifiers: darkModifiers(primary[300]),
        },
        100: {
            value: primary[100],
            modifiers: darkModifiers(primary[100]),
        },
    },
    secondary: {
        500: {
            value: secondary[500],
            modifiers: lightModifiers(secondary[500]),
        },
        300: {
            value: secondary[300],
            modifiers: darkModifiers(secondary[300]),
        },
    },
    tertiary: {
        500: {
            value: tertiary[500],
            modifiers: lightModifiers(tertiary[500]),
        },
        300: {
            value: tertiary[300],
            modifiers: darkModifiers(tertiary[300]),
        },
    },
    quaternary: {
        500: {
            value: quaternary[500],
            modifiers: lightModifiers(quaternary[500]),
        },
        300: {
            value: quaternary[300],
            modifiers: darkModifiers(quaternary[300]),
        },
    },
    accent: {
        500: {
            value: accent[500],
            modifiers: lightModifiers(accent[500]),
        },
        300: {
            value: accent[300],
            modifiers: darkModifiers(accent[300]),
        },
    },
    greys: {
        900: {
            value: greys[900],
            modifiers: lightModifiers(greys[900]),
        },
        700: {
            value: greys[700],
            modifiers: lightModifiers(greys[700]),
        },
        500: {
            value: greys[500],
            modifiers: lightModifiers(greys[500]),
        },
        300: {
            value: greys[300],
            modifiers: darkModifiers(greys[300]),
        },
        200: {
            value: greys[200],
            modifiers: darkModifiers(greys[200]),
        },
        100: {
            value: greys[100],
            modifiers: darkModifiers(greys[100]),
        },
    },
    error: {
        value: error,
        modifiers: lightModifiers(error),
    },
    black: {
        value: black,
        modifiers: lightModifiers(black),
    },
    white: {
        value: white,
        modifiers: darkModifiers(white),
    },
    focus: {
        value: focus,
        modifiers: lightModifiers(focus),
    },
    highlight: {
        500: { value: highlight[500], modifiers: darkModifiers(highlight[500]) },
    },
};

export const makeTransparent = (color, alpha) => rgba(color, alpha);
