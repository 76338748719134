import { call, put, take, fork } from 'redux-saga/effects';
import { eventChannel, END, channel as sagaChannel } from 'redux-saga';
import throttle from 'lodash/throttle';
import actions, { settings } from 'api/actions';
import endpoints from 'api/config/endpoints';
import { constructUrl } from 'api/sagas/helpers';
import { uploadRequest } from './actions';

// Event channel to connect to the media endpoint, upload a file and emit events for success, progress and failure
export const createUploadFileChannel = (endpoint, photoPreview, fileBuffer, token) => (emitter) => {
    // eslint-disable-next-line no-undef
    const xhr = new XMLHttpRequest();

    // Throttle the progress event because it fires too much
    // and needs to chill out a bit
    const onProgress = throttle((event) => {
        if (event.lengthComputable) {
            const progress = event.loaded / event.total;
            emitter({ progress });
        }
    }, 500);

    const onFailure = () => {
        emitter({ error: { error: new Error('Upload failed'), fileId: photoPreview.tmpId } });
        emitter(END);
    };

    // Define xhr and add event listeners
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);

    xhr.onreadystatechange = () => {
        const { readyState, status, responseText } = xhr;
        if (readyState === 4) {
            if (status === 200) {
                try {
                    const response = JSON.parse(responseText);
                    // The API gives us a number but it should be a string
                    emitter({ success: true, newId: `${response.file.id}` });
                    emitter(END);
                } catch (e) {
                    onFailure();
                }
            } else {
                onFailure();
            }
        }
    };

    xhr.open('POST', endpoint, true);

    // Define headers for the request
    // Endpoint expects chunks so we send the photo in 1 chunk
    const headers = {
        'Content-Type': 'application/octet-stream',
        'Cache-Control': 'no-cache',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Media-Upload-Token': token,
        'X-File-Id': photoPreview.tmpId,
        'X-File-Size': photoPreview.size,
        'X-File-Name': encodeURI(photoPreview.name),
        'X-File-Type': photoPreview.type,
        // Chunks are 0-indexed so even if we have 1 our first one is 0
        'X-File-Number-Of-Chunks': 1,
        'X-File-Current-Chunk': 0,
    };

    Object.keys(headers).forEach((header) => {
        xhr.setRequestHeader(header, headers[header]);
    });

    // Convert our ArrayBuffer before sending
    const uInt8chunk = new Uint8Array(fileBuffer);
    xhr.send(uInt8chunk);

    // Cleanup event listeners
    return () => {
        xhr.upload.removeEventListener('progress', onProgress);
        xhr.upload.removeEventListener('error', onFailure);
        xhr.upload.removeEventListener('abort', onFailure);
        xhr.onreadystatechange = null;
        xhr.abort();
    };
};

// Upload the file through the channel and handle the events emitted
export function* uploadFileSaga(
    photoPreview,
    fileBuffer,
    onUploadPhotoError,
    onUploadPhotoProgress,
    onUploadPhotoSuccess,
    token
) {
    const apiUrl = constructUrl({
        endpoint: endpoints.medias.upload,
        data: {
            mediaType: 'photo',
        },
    });

    const emitterFn = yield call(createUploadFileChannel, apiUrl, photoPreview, fileBuffer, token);
    const channel = eventChannel(emitterFn);

    while (true) {
        const { progress = 0, error, success, newId } = yield take(channel);

        if (error) {
            onUploadPhotoError();
            return;
        }
        if (success) {
            onUploadPhotoSuccess(newId);
            return;
        }
        onUploadPhotoProgress(progress);
    }
}

export function* handleUploadRequest(channel) {
    while (true) {
        const {
            photoPreview,
            fileBuffer,
            onUploadPhotoError,
            onUploadPhotoProgress,
            onUploadPhotoSuccess,
        } = yield take(channel);

        // TODO: With a small API change we could make it so a single token could be used for all photo
        // uploads. The API keeps the token alive for 8 minutes but this limit could be extended (or removed!)
        yield put(actions.medias.uploadToken({ forceReload: true }));

        // TODO: this waits for _any_ token (which works fine because they are reusable in 8 minutes)
        // but it might be better to wait for the response to the exact `put` we issued above
        const { status, data } = yield take(settings.medias.uploadToken.DONE);

        if (status !== settings.medias.uploadToken.SUCCESS) {
            onUploadPhotoError();
        } else {
            yield call(
                uploadFileSaga,
                photoPreview,
                fileBuffer,
                onUploadPhotoError,
                onUploadPhotoProgress,
                onUploadPhotoSuccess,
                data.token
            );
        }
    }
}

/**
 * Wait for uploadRequest actions to be fired and make sure a maximum of 2 are handled in parallel
 */
export default function* uploadRequestWatcherSaga() {
    // Create a channel to queue incoming actions
    const queue = yield call(sagaChannel);

    // Create x worker 'threads' so we can process x actions simultaneously
    for (let i = 0; i < 2; i += 1) {
        yield fork(handleUploadRequest, queue);
    }

    while (true) {
        // take.maybe allows us to either:
        // - get the uploadRequest action or:
        // - get the END action which redux-saga uses to indicate we should stop (used during SSR)
        const action = yield take.maybe(uploadRequest.ACTION);

        // If we received END then drop out of the loop and cleanup
        if (action === END) break;

        // If we got the uploadRequest action add it to the queue
        yield put(queue, action);
    }

    // If we broke out of the loop, perhaps because of END, then we need to clean up
    // Closing the channel allows this saga to exit
    queue.close();
}
