import { all, put, take, call, select, takeEvery } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { getOwnerListingAmenitiesRaw } from 'api/selectors/owner';
import * as pageActions from 'containers/Page/actions';
import { PAGE_ID } from './ListingPreview.constants';
import { assignmentsLoaded } from '../actions';

export function* handleLoadAmenities({ listingId }) {
    const amenities = yield select((state) => getOwnerListingAmenitiesRaw(state, listingId));

    if (!amenities) {
        yield put(
            apiActions.owner.loadListingAmenities({
                forceReload: true,
                data: {
                    listingId,
                },
            })
        );

        yield take((res) => res.type === settings.owner.loadListingAmenities.DONE);
    }
}

export function* loadListingForPreview(listingId) {
    yield put(
        apiActions.owner.loadListing({
            forceReload: true,
            data: {
                id: listingId,
            },
        })
    );

    const loadListingResponse = yield take(settings.owner.loadListing.DONE);
    if (loadListingResponse.status !== settings.owner.loadListing.SUCCESS) {
        // Error occurred
        yield put(pageActions.error.create(PAGE_ID, loadListingResponse.statusCode));
        return false;
    }

    yield put(
        apiActions.owner.loadListingPets({
            forceReload: true,
            data: {
                id: listingId,
                listingId,
            },
        })
    );

    // Wait for results
    const loadListingPetsResponse = yield take(
        (res) => res.type === settings.owner.loadListingPets.DONE
    );
    if (loadListingPetsResponse.status !== settings.owner.loadListingPets.SUCCESS) {
        // Error occurred
        yield put(pageActions.error.create(PAGE_ID, loadListingPetsResponse.statusCode));
        return false;
    }

    yield put(
        apiActions.owner.loadOpenAssignments({
            forceReload: true,
            data: {
                id: listingId,
            },
        })
    );

    // Wait for results
    const loadOpenAssignmentsResponse = yield take(settings.owner.loadOpenAssignments.DONE);
    if (loadOpenAssignmentsResponse.status !== settings.owner.loadOpenAssignments.SUCCESS) {
        // Error occurred
        yield put(pageActions.error.create(PAGE_ID, loadOpenAssignmentsResponse.statusCode));
        return false;
    }

    yield put(
        apiActions.owner.loadPastAssignments({
            forceReload: true,
            filters: {
                limit: 999,
            },
            data: {
                id: listingId,
            },
        })
    );

    // Wait for results
    const loadPastAssignmentsResponse = yield take(
        (res) => res.type === settings.owner.loadPastAssignments.DONE
    );
    if (loadPastAssignmentsResponse.status !== settings.owner.loadPastAssignments.SUCCESS) {
        // Error occurred
        yield put(pageActions.error.create(PAGE_ID, loadPastAssignmentsResponse.statusCode));
        return false;
    }

    // dispatch action with open & past assignments
    yield put(
        assignmentsLoaded.create(
            loadPastAssignmentsResponse.data.results,
            loadOpenAssignmentsResponse.data.results
        )
    );

    return true;
}

function* listingPreviewPreload({ params }) {
    const { listingId } = params;

    yield call(loadListingForPreview, listingId);
    yield call(handleLoadAmenities, {
        listingId,
    });

    yield put(pageActions.preloaded.create(PAGE_ID));
}

function* listingPreviewLoad({ params }) {
    const { listingId } = params;

    yield call(loadListingForPreview, listingId);
    yield call(handleLoadAmenities, {
        listingId,
    });

    yield put(pageActions.loaded.create(PAGE_ID));
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            listingPreviewPreload
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            listingPreviewLoad
        ),
    ]);
}
