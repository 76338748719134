import paths from './route-paths';
import Hub from './Hub';
import AboutYou from './AboutYou';
import ProfileDetails from './ProfileDetails';
import Experience from './Experience';
import Preferences from './Preferences';
import ProfilePreview from './ProfilePreview';
import References from './References';
import SafetySecurity from './SafetySecurity';

export default [
    {
        path: paths.hub,
        exact: true,
        component: Hub,
    },
    {
        path: paths.profileDetails,
        exact: true,
        component: ProfileDetails,
    },
    {
        path: paths.aboutYou,
        exact: true,
        component: AboutYou,
    },
    {
        path: paths.experience,
        exact: true,
        component: Experience,
    },
    {
        path: paths.preferences,
        exact: true,
        component: Preferences,
    },
    {
        path: paths.profilePreview,
        exact: true,
        component: ProfilePreview,
    },
    {
        path: paths.references,
        exact: true,
        component: References,
    },
    {
        path: paths.safetySecurity,
        exact: true,
        component: SafetySecurity,
    },
];
