import styled from 'styled-components';
import { spanStyles } from 'typography';

export const ContainerStyled = styled.div`
    border: 1px solid transparent;
    border-radius: 10px;

    &[data-status='selected'] {
        border-width: 0;
    }
`;

export const LabelStyled = styled.label`
    ${spanStyles}
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing[2.5]};

    border: 1px solid ${({ theme }) => theme.palette.border};
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: inherit;

    padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2.5]}`};
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
`;

export const CheckInputStyled = styled.input`
    display: none;

    &:checked + ${LabelStyled} {
        background-color: ${({ theme }) => theme.palette.primarySoft};
        border: 2px solid ${({ theme }) => theme.palette.primaryDark};
        transition: background-color 250ms ease-in-out;

        svg {
            fill: ${({ theme }) => theme.palette.primaryDark};
        }
    }

    &:checked + ${LabelStyled} * {
        font-weight: 600;
        color: ${({ theme }) => theme.palette.primaryDark};
    }

    &:disabled + ${LabelStyled} {
        cursor: default;

        * {
            color: ${({ theme }) => theme.palette.icon};
            fill: ${({ theme }) => theme.palette.icon};
        }
    }
`;
