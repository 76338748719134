// eslint-disable-next-line import/prefer-default-export
export const OWNER_PAUSED_ASSIGNMENT = {
    create(assignment) {
        const { assignmentId, listingId, ownerId, isApproximateDates, startDate, endDate } =
            assignment;
        const payload = {
            assignmentId,
            isApproximateDates,
            listingId,
            ownerId,
            startDate,
            endDate,
        };

        return {
            type: 'Owner Paused Assignment',
            payload,
        };
    },
};
