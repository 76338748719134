// eslint-disable-next-line import/prefer-default-export
export const SAVED_SEARCH_DELETED = {
    create(id, name) {
        const payload = {
            savedSearchID: id,
            savedSearchName: name,
        };

        return {
            type: 'Saved Search Deleted',
            payload,
        };
    },
};
