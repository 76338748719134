import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Formik } from 'formik';
import Anchor from 'components/Anchor';
import { Field } from 'components/forms';
import { Events, track } from 'utils/analytics';
import { routes } from 'utils/routes';
import { getFullHostname } from 'utils/environment';
import { MembershipType } from 'api/types';
import MembershipSelector from 'components/Regwall/components/MembershipSelector';
import { validateRegister } from './validation';
import {
    FormStyled,
    SimplerCreateButtonStyled,
    PasswordFieldStyled,
    ErrorMessageStyled,
    HelperTextStyled,
} from './RegisterForm.style';

const RegisterForm = ({
    clearError,
    onSubmit,
    membershipType,
    errorMessage,
    className,
    isSubmitting,
    initialData,
    setMembershipType,
    showPrivacyPolicy = true,
}) => {
    const { t } = useTranslation();
    const [showMembershipTypeError, setShowMembershipTypeError] = useState(false);
    const handleFormikSubmit = (
        { name, email, password, privacyPolicy, marketingComms },
        { setSubmitting }
    ) => {
        if (membershipType) {
            track(
                Events.BUTTONCLICK.create({
                    description: 'Sign up with email',
                })
            );

            onSubmit({
                first_name: name,
                email,
                password,
                hasAcceptedPrivacyPolicy: privacyPolicy,
                hasAcceptedMarketingComms: marketingComms,
            });
        } else {
            setShowMembershipTypeError(true);
        }
        // form submission status is handled outside of this component by
        // redux/sagas so we bypass formik's internal submission status
        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={{
                email: (initialData && initialData.email) || '',
                password: (initialData && initialData.password) || '',
                name: (initialData && initialData.first_name) || '',
                privacyPolicy: true,
                marketingComms: true,
            }}
            validate={validateRegister}
            onSubmit={handleFormikSubmit}
        >
            {({ handleBlur, handleChange, handleSubmit, values, touched, errors }) => (
                <FormStyled className={className} onSubmit={handleSubmit}>
                    <Field
                        label={t('components_registerForm_field_firstName')}
                        id="name"
                        placeholder={t('components_registerForm_field_firstName')}
                        name="name"
                        type="text"
                        onChange={(event) => {
                            handleChange(event);
                            clearError();
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                        error={errors.name && t(`web_validation_firstName_${errors.name}`)}
                        touched={touched.name}
                    />
                    <Field
                        label={t('components_registerForm_field_email')}
                        placeholder="Email address"
                        name="email"
                        id="email"
                        type="email"
                        onChange={(event) => {
                            handleChange(event);
                            clearError();
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email && t(`web_validation_email_${errors.email}`)}
                        touched={touched.email}
                    />
                    <PasswordFieldStyled
                        label={t('components_registerForm_field_password')}
                        placeholder="Choose password"
                        name="password"
                        id="password"
                        onChange={(event) => {
                            handleChange(event);
                            clearError();
                        }}
                        onBlur={handleBlur}
                        value={values.password}
                        error={errors.password && t(`web_validation_password_${errors.password}`)}
                        touched={touched.password}
                        canToggle
                        helperText={t('web_validation_password_helperText')}
                        showText={t('components_passwordField_showText')}
                        hideText={t('components_passwordField_hideText')}
                    />

                    {setMembershipType && (
                        <MembershipSelector
                            membershipType={membershipType}
                            setMembershipType={setMembershipType}
                            showMembershipTypeError={showMembershipTypeError}
                            setShowMembershipTypeError={setShowMembershipTypeError}
                        />
                    )}

                    <SimplerCreateButtonStyled type="submit" disabled={isSubmitting}>
                        {t('components_registerForm_button_create_free')}
                    </SimplerCreateButtonStyled>

                    {showPrivacyPolicy ? (
                        <HelperTextStyled data-testid="privacy-policy-marketing-opt-in-copy">
                            <Trans
                                i18nKey={`components_registerForm_pp_and_mc_auto_opt_in_${
                                    setMembershipType ? MembershipType.COMBINED : membershipType
                                }`}
                                components={{
                                    ppLink: (
                                        <Anchor
                                            href={`${getFullHostname()}${routes.privacyPolicy()}`}
                                            target="_blank"
                                        />
                                    ),
                                }}
                            />
                        </HelperTextStyled>
                    ) : null}

                    {errorMessage ? <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled> : null}
                </FormStyled>
            )}
        </Formik>
    );
};

export default RegisterForm;
