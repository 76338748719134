import { put, takeEvery, take } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';

export function* expireUserAccount() {
    yield put(
        apiActions.account.deleteMembershipPlan({
            forceReload: true,
        })
    );

    const { status } = yield take(settings.account.deleteMembershipPlan.DONE);

    if (status === settings.account.deleteMembershipPlan.SUCCESS) {
        yield put(
            apiActions.account.load({
                forceReload: true,
            })
        );
        return true;
    }

    return false;
}

export default function* sagas() {
    yield takeEvery((action) => action.type === 'FREE_TRIAL_END_MODAL', expireUserAccount);
}
