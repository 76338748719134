import React from 'react';
import { routes } from 'utils/routes';
import SpinnerWithConditionalRedirect from 'components/SpinnerWithConditionalRedirect';

const SavedSearchRedirect = ({ searchQuery, queryParamString }) => (
    <SpinnerWithConditionalRedirect
        shouldRedirect={Boolean(searchQuery)}
        to={
            searchQuery
                ? `${routes.search.listings()}?q=${searchQuery}&searchMethod=saveSearch${queryParamString}`
                : null
        }
    />
);

export default SavedSearchRedirect;
