/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ChevronLeftIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M17.488 3.509c.003.394-.144.789-.442 1.091l-7.059 7.162a.384.384 0 0 0 .005.543l7.1 7c.547.538.686 1.412.242 2.037a1.54 1.54 0 0 1-2.344.215l-8.53-8.41a1.54 1.54 0 0 1-.017-2.178l8.34-8.46c.576-.586 1.529-.698 2.156-.169.363.306.546.737.55 1.169Z"
            clipRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(ChevronLeftIcon);
export default ForwardRef;
