import styled from 'styled-components';
import RichText from 'components/content/prismicSlices/RichText';
import Anchor from 'components/Anchor';
import { Heading2 } from 'typography';

import { alignmentMap } from './MultiCallToAction.constants';

const TitleStyled = styled(Heading2)`
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ alignment = 'left' }) => alignmentMap[alignment]};
    text-align: ${({ alignment }) => alignment};
`;

const AnchorStyled = styled(Anchor)`
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    &:last-of-type {
        margin-bottom: 0;
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        margin-bottom: 0;
        margin-right: ${({ theme }) => theme.spacing[4]};
        &:last-of-type {
            margin-right: 0;
        }
    }
`;

const ButtonWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing[3]};
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: ${({ theme }) => theme.spacing[4]};
    }
`;

const RichTextStyled = styled(RichText)`
    width: 100%;
    *:first-child {
        margin-top: 0;
    }
    *:last-child {
        margin-bottom: 0;
    }
`;

export { TitleStyled, WrapperStyled, AnchorStyled, ButtonWrapperStyled, RichTextStyled };
