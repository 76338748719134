import { settings as actions } from '../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadListingPets.SUCCESS:
            return {
                ...state,
                [action.requestData.listingId]: [...action.data],
            };
        case actions.owner.createListingPet.SUCCESS: {
            const { listingId, tmpId } = action.requestData;
            return {
                ...state,
                [listingId]: [
                    ...(state[listingId] || []),
                    {
                        ...action.data,
                        tmpId,
                    },
                ],
            };
        }
        case actions.owner.updateListingPet.SUCCESS: {
            const { listingId, petId } = action.requestData;
            const pets = [...(state[listingId] || [])];
            const index = pets.findIndex(pet => pet.id === petId);
            if (index >= 0) {
                pets[index] = {
                    ...pets[index],
                    ...action.data,
                };
                return {
                    ...state,
                    [listingId]: [...pets],
                };
            }
            return state;
        }
        case actions.owner.removeListingPet.SUCCESS: {
            const { listingId, petId } = action.requestData;
            const pets = [...(state[listingId] || [])];
            const index = pets.findIndex(pet => pet.id === petId);
            if (index >= 0) {
                pets.splice(index, 1);

                return {
                    ...state,
                    [listingId]: [...pets],
                };
            }
            return state;
        }
        case actions.owner.loadListingPetPhotos.SUCCESS: {
            const { listingId, id } = action.requestData;
            const pets = [...(state[listingId] || [])];
            pets.forEach((pet) => {
                if (pet.id === id) {
                    pet.photos = [...action.data];
                }
            });

            return {
                ...state,
                [listingId]: [...pets],
            };
        }
        case actions.owner.createListingPetPhoto.SUCCESS: {
            const { listingId, petId } = action.requestData;
            const pets = [...(state[listingId] || [])];
            const updatedPets = pets.map((pet) => {
                if (pet.id !== petId) return pet;

                return {
                    ...pet,
                    photos: [...pet.photos, action.data],
                };
            });

            return {
                ...state,
                [listingId]: updatedPets,
            };
        }
        case actions.owner.removeListingPetPhoto.SUCCESS: {
            const { listingId, photoId } = action.requestData;
            const pets = [...(state[listingId] || [])];
            const updatedPets = pets.map(pet => ({
                ...pet,
                photos: pet.photos.filter(photo => photo.id !== photoId),
            }));

            return {
                ...state,
                [listingId]: updatedPets,
            };
        }
        case actions.medias.update.SUCCESS: {
            const { media: updatedMedias } = action.requestData;
            const findUpdatedMedia = photoId => updatedMedias.find(updatedMedia => updatedMedia.id === photoId);
            const listingIds = Object.keys(state);
            const newState = {};

            listingIds.forEach((listingId) => {
                const pets = [...(state[listingId] || [])];

                const updatedPets = pets.map(pet => ({
                    ...pet,
                    photos: pet.photos.map((photo) => {
                        const updated = findUpdatedMedia(photo.id);

                        if (!updated) return photo;

                        return {
                            ...photo,
                            description: updated.name,
                        };
                    }),
                }));

                newState[listingId] = updatedPets;
            });

            return newState;
        }
        default:
            return state;
    }
};
