import { MembershipType, PlanTiers } from '../types';

export const UpgradeDowngradeStatus = {
    DOWNGRADE: 'downgrade',
    NO_CHANGE: 'noChange',
    UPGRADE: 'upgrade',
};

export const getIsUpgradeOrDowngrade = ({
    fromMembershipType,
    fromMembershipTier,
    toMembershipType,
    toMembershipTier
}) => {
    if (!fromMembershipTier || !fromMembershipType) return undefined;

    let status;

    function changingTierRules() {
        switch (fromMembershipTier) {
            case PlanTiers.CLASSIC:
                if (toMembershipTier === PlanTiers.CLASSIC) {
                    status = UpgradeDowngradeStatus.NO_CHANGE;
                } else if (toMembershipTier === PlanTiers.BASIC) {
                    status = UpgradeDowngradeStatus.UPGRADE;
                } else if (toMembershipTier === PlanTiers.STANDARD) {
                    status = UpgradeDowngradeStatus.UPGRADE;
                } else if (toMembershipTier === PlanTiers.PREMIUM) {
                    status = UpgradeDowngradeStatus.UPGRADE;
                }
                break;
            case PlanTiers.BASIC:
                if (toMembershipTier === PlanTiers.BASIC) {
                    status = UpgradeDowngradeStatus.NO_CHANGE;
                } else if (toMembershipTier === PlanTiers.STANDARD) {
                    status = UpgradeDowngradeStatus.UPGRADE;
                } else if (toMembershipTier === PlanTiers.PREMIUM) {
                    status = UpgradeDowngradeStatus.UPGRADE;
                }
                break;
            case PlanTiers.STANDARD:
                if (toMembershipTier === PlanTiers.BASIC) {
                    status = UpgradeDowngradeStatus.DOWNGRADE;
                } else if (toMembershipTier === PlanTiers.STANDARD) {
                    status = UpgradeDowngradeStatus.NO_CHANGE;
                } else if (toMembershipTier === PlanTiers.PREMIUM) {
                    status = UpgradeDowngradeStatus.UPGRADE;
                }
                break;
            case PlanTiers.PREMIUM:
                if (toMembershipTier === PlanTiers.BASIC) {
                    status = UpgradeDowngradeStatus.DOWNGRADE;
                } else if (toMembershipTier === PlanTiers.STANDARD) {
                    status = UpgradeDowngradeStatus.DOWNGRADE;
                } else if (toMembershipTier === PlanTiers.PREMIUM) {
                    status = UpgradeDowngradeStatus.NO_CHANGE;
                }
                break;
            default:
                status = undefined;
        }

        return status;
    }


    if (fromMembershipType === MembershipType.COMBINED && toMembershipType !== MembershipType.COMBINED) {
        // If changing Type from Combined to Owner/Sitter (any Tier) is always Downgrade
        status = UpgradeDowngradeStatus.DOWNGRADE;
    } else if (fromMembershipType !== MembershipType.COMBINED && toMembershipType === MembershipType.COMBINED) {
        // From Owner/Sitter to Combined - apply Changing Tier rules
        // But same Tier is Upgrade
        status = changingTierRules();
        if (fromMembershipTier === toMembershipTier) {
            status = UpgradeDowngradeStatus.UPGRADE;
        }
    } else if (fromMembershipType !== MembershipType.COMBINED && toMembershipType !== MembershipType.COMBINED) {
        // From Owner to Sitter (or reverse) - apply Changing Tier rules - but same Tier different Type is Downgrade
        // this isn't currently allowed, but is added here for completness
        status = changingTierRules();
        if (fromMembershipType !== toMembershipType) {
            status = UpgradeDowngradeStatus.DOWNGRADE;
        }
    } else {
        // If same Type then apply Changing Tier rules
        status = changingTierRules();
    }

    return status;
};
