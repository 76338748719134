import styled from 'styled-components';
import FilterLocation from 'containers/forms/search/FilterLocation';
import { theme } from 'theme';
import { H1, SmallText } from 'typography';

export const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        border-radius: ${theme.borderRadius.regular};
        margin: 0 ${theme.spacing[6]};
    }

    /* 1360 + 48 * 2 = max image size + min margins on x axis * left and right side margins */
    @media screen and (min-width: ${1360 + 48 * 2}px) {
        max-width: 1360px;
        margin: 0 auto;
    }
`;

export const HeroImageContainer = styled.picture`
    aspect-ratio: 320 / 156;
    overflow: hidden;

    @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
        aspect-ratio: unset;
        grid-area: 1 / 1 / 3 / 3;
    }
`;

export const HeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const HeroContent = styled.div`
    margin: -${theme.spacing[3]} ${theme.spacing[2]} 0;
    padding: ${theme.spacing[3]} ${theme.spacing[2]};
    border-radius: ${theme.borderRadius.regular};
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[2]};

    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.12);
    background: ${theme.palette.white};

    @media screen and (min-width: ${theme.screenSize.smallTablet}px) {
        margin: -${theme.spacing[3]} ${theme.spacing[4]} 0;
        padding: ${theme.spacing[3]};
    }

    @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        margin: ${theme.spacing[8]};
        margin-right: 0;
        padding: ${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[7]};
    }
`;

export const HeroTitle = styled(H1)`
    margin: 0;
    color: ${theme.palette.primaryDark};
    text-align: center;
    text-transform: none;

    @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
        text-align: left;
    }
`;

export const HeroSubtitle = styled(SmallText)`
    margin: 0;
    color: ${theme.palette.grey[600]};
    text-align: center;

    @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
        text-align: left;
    }
`;

export const HeroSearch = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[1]};
`;

export const HeroSearchLabel = styled(SmallText)`
    color: ${theme.palette.text};
    font-weight: ${theme.fontWeights.bold};
`;

export const HeroSearchInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[1.5]};

    @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
        flex-direction: row;
        gap: 0;
    }
`;

export const HeroSearchInput = styled(FilterLocation)`
    input[type='text'] {
        border-radius: ${theme.borderRadius.regular};
        box-shadow: none;
        border-color: ${theme.palette.grey[400]};
        height: 60px;

        @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
            border-radius: ${theme.borderRadius.regular} 0 0 ${theme.borderRadius.regular};
        }
    }
`;

export const HeroSearchButton = styled.button`
    all: unset;
    cursor: pointer;
    background: ${theme.palette.linkDark};
    border-radius: ${theme.borderRadius.regular};
    padding: ${theme.spacing[1.5]} ${theme.spacing[2]};

    /* FIXME: the line below should use the color #006263 once the redesign is PR is merged */
    color: ${theme.palette.white};
    text-align: center;
    font-weight: ${theme.fontWeights.bold};
    ${theme.typography.smallText}

    @media screen and (min-width: ${theme.screenSize.largeDesktop}px) {
        ${theme.typography.span}
        font-weight: ${theme.fontWeights.bold};
        border-radius: 0 ${theme.borderRadius.regular} ${theme.borderRadius.regular} 0;
        padding: 0 ${theme.spacing[2]};
    }
`;
