import { getState } from './common';

//
// Data selectors
//
export const getAnimals = (state) => getState(state).animals.animals;
export const getAnimalBreeds = (state, animalId) => getState(state).animals?.breeds[animalId];

export const getAnimalsWithBreeds = (state) =>
    getAnimals(state).map((animal) => ({ ...animal, breeds: getAnimalBreeds(state, animal.id) }));
