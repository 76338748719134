import styled from 'styled-components';

const CoverStyled = styled.div`
    min-height: 150px;
    margin-bottom: ${({ theme }) => theme.spacing[0.5]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        min-height: 290px;
        margin: 0 ${({ theme }) => `${theme.spacing[3]}`};
    }
`;

const ImageStyled = styled.img`
    width: 100%;
`;

export { CoverStyled, ImageStyled };
