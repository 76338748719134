import * as React from 'react';
import { CounterContainer, MainContainerStyled } from './Root.style';

/**
 * @description This component is meant to be used alongside the other components in this folder.
 * It uses the same logic applied to the CheckBoxButton component.
 * @example
 * import * as Counter from 'components/forms/CheckCounter';
 *
 * <Counter.Root isActive={true}>
 *   <Counter.Content>
 *    <Counter.Icon>
 *     <IconBell />
 *   </Counter.Icon>
 *   <Counter.Title>Test</Counter.Title>
 *  </Counter.Content>
 *
 *  <Counter.Handler>
 *   <Counter.Button area="decrement" disabled={isDisabled} onClick={handleClick}>
 *    -
 *   </Counter.Button>
 *
 *   <Counter.Display>1</Counter.Display>
 *
 *   <Counter.Button area="increment" disabled={isDisabled} onClick={handleClick}>
 *    +
 *   </Counter.Button>
 *  </Counter.Handler>
 * </Counter.Root>
 */
function Root({ isActive, children }) {
    return (
        <MainContainerStyled data-status={isActive ? 'filled' : 'empty'}>
            <CounterContainer>{children}</CounterContainer>
        </MainContainerStyled>
    );
}

export default Root;
