import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// until we upgrade to react-router-dom v6 we dont have access to useQuery hook
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export default useQuery;
