import { createAction } from 'utils/actions';

const prefix = 'SEARCH_PROFILES';

export const searchFiltersUpdated = createAction(prefix, 'FILTERS_UPDATED', 'filters');
export const placeLoaded = createAction(prefix, 'PLACE_LOADED', 'place');
export const loadAdditionalProfileData = createAction(prefix, 'LOAD_ADDITIONAL_PROFILE_DATA');
export const disableScrolling = createAction(prefix, 'DISABLE_SCROLLING');
export const sittersLookingInYourAreaVariant = createAction(
    prefix,
    'SITTERS_LOOKING_IN_YOUR_AREA_VARIANT',
    'variant'
);
