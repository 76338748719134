import { all, call, takeEvery } from 'redux-saga/effects';
import { loadStripe } from '@stripe/stripe-js';
import environment from 'environment';
import * as actions from './actions';

// The checkout flow
export function* loadStripeCheckout() {
    // Load stripe
    const stripe = yield call(loadStripe, environment.vars.stripe.publicKey);
    window.stripe = stripe;
}

export default function* saga() {
    yield all([takeEvery(actions.loadStripe.ACTION, loadStripeCheckout)]);
}
