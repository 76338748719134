/* global $Values */
import React, { createContext, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { FlexboxGridJustify } from './FlexboxGrid.constants';
import {
    FlexboxGridStyled,
    FlexboxGridInnerStyled,
    DebugFlexboxGridInnerStyled,
    DebugCol,
} from './FlexboxGrid.style';

const FlexboxGridContext = createContext({ totalCols: 12 });

const FlexboxGrid = ({ justify, config, debug, className, children, totalCols }) => {
    const { config: inheritedConfig } = useContext(FlexboxGridContext);
    const theme = useContext(ThemeContext);

    const gridConfig = config || inheritedConfig || theme.grid;

    return (
        <FlexboxGridStyled debug={debug} className={className}>
            <FlexboxGridContext.Provider value={{ totalCols, config: gridConfig }}>
                {debug && (
                    <DebugFlexboxGridInnerStyled config={gridConfig}>
                        {Array(totalCols)
                            .fill()
                            .map((_, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <DebugCol key={i} totalCols={totalCols} config={gridConfig} />
                            ))}
                    </DebugFlexboxGridInnerStyled>
                )}

                <FlexboxGridInnerStyled config={gridConfig} justify={justify}>
                    {children}
                </FlexboxGridInnerStyled>
            </FlexboxGridContext.Provider>
        </FlexboxGridStyled>
    );
};

FlexboxGrid.defaultProps = {
    justify: FlexboxGridJustify.FLEX_START,
    totalCols: 12,
    debug: false,
};

FlexboxGrid.Justify = FlexboxGridJustify;

export { FlexboxGrid as default, FlexboxGridContext };
