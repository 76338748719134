// eslint-disable-next-line import/prefer-default-export
export const USER_LOGGED_IN = {
    create(loginMethod) {
        return {
            type: 'User Logged In',
            payload: {
                loginMethod,
            },
        };
    },
};
