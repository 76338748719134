import { MembershipType } from 'api/types/membershipTypes';
import * as actions from './actions';
import { userRegistered, userLoggedIn, finshedLoadingAccountData } from '../forms/Register/actions';

const initialState = {
    regwall: {
        isOpen: false,
    },
    planTypeVisible: MembershipType.OWNER,
    currencyCode: null,
    planFromUrl: null,
    hideTabs: false,
    hasPendingPlanChange: false,
    freeTrial: null,
    finishedLoadingAccountData: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.regwallOpen.ACTION:
            return {
                ...state,
                regwall: {
                    isOpen: true,
                    membershipPlan: action.membershipPlan,
                    membershipType: action.membershipType,
                },
            };
        // this is the bit that gets makes sure that there is no flash of content on the checkout
        // TODO: find a better/cleaner way of doing redirects on the plans page
        case finshedLoadingAccountData.ACTION:
            return {
                ...state,
                finishedLoadingAccountData: true,
            };
        case userLoggedIn.ACTION:
        case userRegistered.ACTION: // Close the regwall once a user registers
        case actions.regwallClose.ACTION:
            return {
                ...state,
                regwall: {
                    ...state.regwall,
                    isOpen: false,
                },
            };
        case actions.setPlanTypeVisible.ACTION:
            return {
                ...state,
                planTypeVisible: action.membershipType,
            };
        case actions.changeCurrency.ACTION:
            return {
                ...state,
                currencyCode: action.currencyCode.key,
            };
        case actions.selectPlanFromUrl.ACTION:
            return {
                ...state,
                planFromUrl: action.membershipPlan,
            };
        case actions.setMembershipTypeSelected.ACTION:
            return {
                ...state,
                membershipTypeQueryString: action.membershipType,
            };
        case actions.setHideTabs.ACTION:
            return {
                ...state,
                hideTabs: action.hideTabs,
            };
        case actions.setHasPendingPlanChange.ACTION:
            return {
                ...state,
                hasPendingPlanChange: action.hasPendingPlanChange,
            };
        case actions.setFreeTrial.ACTION:
            return {
                ...state,
                freeTrial: action.freeTrial,
            };
        default:
            return state;
    }
};
