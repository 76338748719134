import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { locationName as formatLocationName } from 'api/helpers/format/location';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import {
    getListingId,
    getLocation,
    getWelcomeGuide,
    getPets,
    getPlacesOfInterest,
    getWelcomeGuidePhotos,
} from '../selectors';
import { PAGE_ID } from './Print.constants';

const LoadableWelcomeGuidePrint = loadable(() => retry(() => import('./Print')));

const mapStateToProps = (state) => ({
    listingId: getListingId(state),
    locationName: formatLocationName(getLocation(state)),
    pets: getPets(state),
    placesOfInterest: getPlacesOfInterest(state),
    welcomeGuide: getWelcomeGuide(state) || {},
    photos: getWelcomeGuidePhotos(state),
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(mapStateToProps)(
    asPage(withTranslation()(LoadableWelcomeGuidePrint), PAGE_ID, true, false, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
