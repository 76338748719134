import * as actions from './actions';

const initialState = {
    isReady: false,
    isLoading: false,
    currentPlace: null,
    currentSearchLevel: null,
    currentFilters: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.fetchFacetsRequest.ACTION:
            return {
                ...state,
                isReady: false,
                isLoading: true,
            };
        case actions.fetchFacetsSuccess.ACTION:
            return {
                ...state,
                isReady: true,
                isLoading: false,
                currentPlace: action.payload.place,
                currentSearchLevel: action.payload.searchLevel,
                currentFilters: action.payload.filters,
            };
        default:
            return state;
    }
};
