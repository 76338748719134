import asPage from 'containers/Page';
import { redirectIfLoggedOut } from 'pages/user/helpers';
import loadable from '@loadable/component';
import { retry } from 'utils/ssr';

const LoadableHub = loadable(() => retry(() => import('./Hub')));

export const PAGE_ID = 'SettingsHub';

const authorisationRedirects = [redirectIfLoggedOut];

export default asPage(LoadableHub, PAGE_ID, false, false, {
    isProtectedRoute: true,
    checkAuthorised: authorisationRedirects,
});
