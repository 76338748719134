const PAGE_ID = 'FindAHouseSitWizard';

// the duration values are measured in months
export const dateRanges = [
    {
        type: 'anytime',
        duration: {
            from: null,
            to: null,
        },
    },
    {
        type: 'nextMonth',
        duration: {
            from: 0,
            to: 1,
        },
    },
    {
        type: 'next2Months',
        duration: {
            from: 0,
            to: 2,
        },
    },
    {
        type: 'inTheFuture',
        duration: {
            from: 2,
            to: 12,
        },
    },
];

export default PAGE_ID;
