import loadable from '@loadable/component';
import { connect } from 'react-redux';
import asPage from 'containers/Page';
import { getIsLoggedIn, getSeoContent } from 'src/shared/selectors';
import { retry } from 'utils/ssr';
import { getStepNumber } from 'containers/Wizard/selectors';
import PAGE_ID from './FindASitterWizard.constants';

const LoadableFindASitterWizard = loadable(() => retry(() => import('./FindASitterWizard')));

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
    seoContent: getSeoContent(state),
    stepNumber: getStepNumber(state),
});

export default connect(
    mapStateToProps,
    null
)(asPage(LoadableFindASitterWizard, PAGE_ID, true, true));
