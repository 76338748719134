import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { parse } from 'query-string';
import Error from 'components/Error';
import { injectSaga, injectReducer } from 'utils/asyncSagasAndReducers';
import { preload as preloadSaga } from './sagas';
import * as actions from './actions';
import * as selectors from './selectors';

const defaultOptions = {
    isProtectedRoute: false,

    // Some pages might want to re-run their `load` each time in order to not show stale/incorrect content
    // (blog posts for example).
    // In this case the blog post `load` saga will check the store before making a request which avoids
    // double-load issues
    forceLoad: false,
};

/**
 * Decode nested location data
 * @param search
 */
const parseSearch = (search) => {
    const parsedSearch = parse(search, { arrayFormat: 'index' });

    if (parsedSearch.location) {
        parsedSearch.location = JSON.parse(parsedSearch.location);
    }

    return parsedSearch;
};

const asPage = (
    WrappedComponent,
    pageId,
    usePreload = false,
    useLoad = false,
    options = defaultOptions
) => {
    const tryInjectSagaAndReducer = () => {
        // Try and inject the page specific saga
        if (options.pageReducer) {
            injectReducer(options.pageReducer.key, options.pageReducer.reducer);
        }

        // Try and inject the page specific saga
        if (options.pageSaga) {
            injectSaga(options.pageSaga.key, options.pageSaga.saga);
        }
    };
    class Page extends React.PureComponent {
        /**
         * On a server-side render we call this for all the Pages on the route
         * and wait for them all to dispatch a preloaded action before rendering
         */
        static getPreloadSaga = () => {
            // if provided any page saga and reducers then try to inject
            tryInjectSagaAndReducer();

            return function* preload(location) {
                if (usePreload) {
                    yield preloadSaga(location, pageId);
                }
            };
        };

        static getServerSideQuery = () => {
            if (options.serverSideQuery) {
                return options.serverSideQuery;
            }
            return undefined;
        };

        componentDidMount() {
            const { preloaded, lazyload, match, location } = this.props;

            // if provided any page saga and reducers then try to inject
            tryInjectSagaAndReducer();

            if (options.forceLoad || (useLoad && !preloaded)) {
                const { load } = this.props;

                load(match.params, location.search, location.pathname);
            }

            // Call on client side after component was mounted
            // Good to load content that shouldn't load for SEO/search engine indexing/...
            lazyload(match.params, location.search, location.pathname, preloaded);
        }

        // eslint-disable-next-line camelcase
        UNSAFE_componentWillReceiveProps(nextProps) {
            const { load, lazyload, match, location } = this.props;

            if (
                useLoad &&
                (JSON.stringify(nextProps.match.params) !== JSON.stringify(match.params) ||
                    nextProps.location.search !== location.search)
            ) {
                load(
                    nextProps.match.params,
                    nextProps.location.search,
                    nextProps.location.pathname
                );

                // Call on client side after component was mounted
                // Good to load content that shouldn't load for SEO/search engine indexing/...
                lazyload(
                    nextProps.match.params,
                    nextProps.location.search,
                    nextProps.location.pathname
                );
            }
        }

        render() {
            const { redirect, preserveNextOnRedirect, redirectMessage, ...props } = this.props;
            const { pathname, search, hash } = props.location;

            if (redirect && redirect !== pathname) {
                const redirectSearchParams = new URLSearchParams();

                if (preserveNextOnRedirect) {
                    // Add the 'next' query param to allow redirecting to the requested url after authenticating
                    redirectSearchParams.append('next', `${pathname}${search}${hash}`);
                }
                if (redirectMessage) {
                    redirectSearchParams.append('redirectMessage', redirectMessage);
                }

                const searchParamsStr = redirectSearchParams.toString();
                const redirectTo = `${redirect}${searchParamsStr ? `?${searchParamsStr}` : ''}`;

                return <Redirect to={redirectTo} />;
            }

            const { error } = this.props;

            if (error) {
                const { staticContext } = this.props;

                if (staticContext) {
                    staticContext.statusCode = error;
                }

                return <Error statusCode={error} />;
            }

            // eslint-disable-next-line react/jsx-props-no-spreading
            return <WrappedComponent {...props} />;
        }
    }

    const mapStateToProps = (state, ownProps) => {
        let redirectPath;
        let shouldPreserveNextOnRedirect;

        // We set the redirectPath after evaluating the selectors
        // The first selector that evaluates to a path is used
        if (Array.isArray(options.checkAuthorised)) {
            options.checkAuthorised.some(({ selector, redirect, preserveNextOnRedirect }) => {
                redirectPath = redirect(selector(state), ownProps);
                shouldPreserveNextOnRedirect = !!preserveNextOnRedirect;

                // Exit early if we found a redirect
                if (redirectPath) {
                    return true;
                }

                return false;
            });
        }

        return {
            preloaded: selectors.getPreloaded(state, pageId),
            error: selectors.getError(state, pageId),
            redirect: redirectPath || selectors.getRedirect(state, pageId),
            preserveNextOnRedirect: shouldPreserveNextOnRedirect,
            redirectMessage: selectors.getRedirectMessage(state, pageId),
        };
    };

    const mapDispatchToProps = (dispatch) => ({
        load: (params, search, pathname) => {
            try {
                const parsedSearch = parseSearch(search);
                dispatch(actions.load.create(pageId, params, parsedSearch, pathname));
            } catch {
                dispatch(actions.error.create(pageId, 404));
            }
        },
        lazyload: (params, search, pathname, preloaded) => {
            try {
                const parsedSearch = parseSearch(search);
                dispatch(
                    actions.lazyload.create(pageId, params, parsedSearch, pathname, preloaded)
                );
            } catch {
                dispatch(actions.lazyload.create(pageId, params, {}, pathname, preloaded));
            }
        },
    });

    return connect(mapStateToProps, mapDispatchToProps)(withRouter(Page));
};

export default asPage;
