const CTA_TYPE = {
    SITTER_REGWALL: 'Sitter Regwall',
    OWNER_REGWALL: 'Owner Regwall',
    LINK: 'Link',
};

const HERO_LAYOUT = {
    FULL_WIDTH_IMAGE: 'full width',
    LEFT_SIDE_IMAGE: 'left image',
    RIGHT_SIDE_IMAGE: 'right image',
};

export { CTA_TYPE, HERO_LAYOUT };
