import { useEffect, useRef } from 'react';

// Return whatever the previous value was,
// will return undefined first time around
const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export default usePrevious;
