import { settings as actions } from '../../actions';

import {
    baseInitialResultsListState,
    createdResultListReducer, deletedResultListReducer,
} from '../common';

export const initialState = { ...baseInitialResultsListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadFavourites.SUCCESS:
            return {
                ...state,
                results: [
                    ...action.data,
                ],
            };
        case actions.owner.createFavourite.SUCCESS:
            return createdResultListReducer(state, { data: action.requestData });
        case actions.owner.removeFavourite.SUCCESS:
            return deletedResultListReducer(state, action);
        default:
            return state;
    }
};
