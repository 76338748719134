import optimizely from 'utils/integrations/optimizely';
import { useSelector } from 'react-redux';
import { getAccountSettings, getUserGroupsFormattedForOptimizely } from 'api/selectors';
import { ANONYMOUS_USER_ID } from 'containers/ExperimentalFeature';

// (optimizely bug that if the attribute is undefined it considers it true so we need to set to false by default)
const defaultBetaGroups = {
    beta_group_inbox: false,
};
function useFeature({ name, userId = ANONYMOUS_USER_ID, attributes }) {
    const formattedUserGroups = useSelector(getUserGroupsFormattedForOptimizely);
    const { isStaff = false } = useSelector(getAccountSettings) || {};
    const allAttributes = {
        ...attributes,
        isStaff,
        ...defaultBetaGroups,
        ...formattedUserGroups,
    };
    const enabled = optimizely.getClient().isFeatureEnabled(name, userId, allAttributes);
    return {
        enabled,
    };
}

export default useFeature;
