import { settings } from 'api/actions';
import { notEligibleReasons } from './Refund.constants';

const defaultState = {
    isRefunded: null,
    isEligible: null,
    notEligibleReason: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case settings.account.loadRefundStatus.DONE: {
            const badRequest = action.statusCode !== 200;
            const { isEligible, notEligibleReason } = action.data;

            return {
                ...state,
                isEligible: badRequest ? false : isEligible,
                notEligibleReason: badRequest ? true : notEligibleReason || null,
            };
        }
        case settings.account.createRefundRequest.DONE: {
            const isSuccess = action.statusCode === 201;
            const is404 = action.statusCode === 404;
            let notEligibleReason = null;

            if (is404) {
                notEligibleReason = notEligibleReasons.apiErrorTransactionNotFound;
            } else if (
                action.data &&
                action.data.nonFieldErrors &&
                action.data.nonFieldErrors.length > 0
            ) {
                // We have a specific error
                notEligibleReason = action.data.nonFieldErrors[0];
            } else if (!isSuccess) {
                // Some other kind of error
                notEligibleReason = true;
            }

            return {
                ...state,
                isEligible: !isSuccess ? false : state.isEligible,
                isRefunded: isSuccess,
                notEligibleReason,
            };
        }
        default:
            return state;
    }
};
