import styled from 'styled-components';
import Input from '../../../Input';

const InputStyled = styled(Input)`
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: ${({ theme, border }) => border || `1px solid ${theme.palette.borderDark}`};
    ${({ theme, isErrored }) => {
        if (isErrored) {
            return `border: 2px solid ${theme.palette.danger};`;
        }
        return null;
    }};

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.focus};
    }
`;

const ContainerStyled = styled.div`
    position: relative;
    width: 100%;
`;

export { InputStyled, ContainerStyled };
