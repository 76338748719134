import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContent, ModalHeader, ModalHeaderBackButton, useTrail } from '@ths/design-system';
import Register from 'containers/forms/Register';
import EmailForm from 'components/Regwall/components/EmailForm';
import { track, Events } from 'utils/analytics';
import { useRegwallDialog } from '../../useRegwallDialog';
import PrivacyPolicy from '../PrivacyPolicy';
import AlreadyHaveAccount from '../AlreadyHaveAccount';

const EmailFormStep = () => {
    const { t } = useTranslation();
    const { back } = useTrail();
    const {
        selectedMembershipType,
        membershipPlan,
        shouldSetMembershipType,
        openMethod,
        membershipType,
    } = useRegwallDialog();

    useEffect(() => {
        track(
            Events.VIEWED_REGWALL.create({
                membershipType,
                method: openMethod,
                regwallStep: 'Step2',
            })
        );
    }, [membershipType, openMethod]);

    const handleBack = () => {
        track(
            Events.BUTTONCLICK.create({
                description: 'Regwall back button',
            })
        );
        back();
    };

    return (
        <>
            <ModalHeader title={t('components_regwall_signUpWithEmail')}>
                <ModalHeaderBackButton onClick={() => handleBack()} />
            </ModalHeader>

            <ModalContent>
                <Register
                    membershipType={selectedMembershipType}
                    membershipPlan={membershipPlan}
                    method={Register.Method.EMAIL}
                    signupOrigin={Register.SignupOrigin.REGWALL}
                    openMethod={openMethod}
                >
                    {({ createAccount, errorMessage, clearError, validateEmail, isSubmitting }) => (
                        <EmailForm
                            membershipType={selectedMembershipType}
                            setMembershipType={shouldSetMembershipType}
                            clearError={clearError}
                            onSubmit={(submittedDetails) => {
                                createAccount(submittedDetails);
                            }}
                            errorMessage={errorMessage}
                            validateEmail={validateEmail}
                            isSubmitting={isSubmitting}
                        />
                    )}
                </Register>

                <PrivacyPolicy className="mb-4" />

                <AlreadyHaveAccount />
            </ModalContent>
        </>
    );
};

export default EmailFormStep;
