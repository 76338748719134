// eslint-disable-next-line import/prefer-default-export
export const IMPORTED_CONTACTS = {
    create(provider, count) {
        return {
            type: 'RAF Emails Imported',
            payload: {
                count,
                provider,
            },
        };
    },
};
