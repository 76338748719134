const cachedTestSplitVariation = {};

export const addToCache = (key, value) => {
    cachedTestSplitVariation[key] = value;
};

export const getFromCache = (key) => cachedTestSplitVariation[key];

export const clearCache = () => {
    Object.keys(cachedTestSplitVariation).forEach((key) => delete cachedTestSplitVariation[key]);
};
