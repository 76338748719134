import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAccountCurrentMembershipPlan, getAccountSettings } from 'api/selectors';
import { getItem } from 'src/universalStorage/selectors';
import { UTM_SOURCE_KEY } from 'src/universalStorage/constants';
import { getExperimentalFeatureVariation, ANONYMOUS_USER_ID } from './helpers';
import { logVariation } from './actions';
import { getSplit } from './selectors';

/**
 * The Experimental Feature allows us to perform A/B tests or A/B/C tests
 */
export const ExperimentalFeature = ({
    experiment,
    userId,
    attributes,
    membershipPlan,
    split,
    control,
    variation1,
    variation2,
    excludeCombo,
    showVariationToUsers,
    analytics = true,
    debug = false,
    logVariation: handleLogVariation,
    isStaff,
    utmSource,
    excludeUTMSources,
    hasPaidTestConflict,
    ...props
}) => {
    const variations = { control, variation1, variation2 };
    const { variation, enabled, fullRollout } = getExperimentalFeatureVariation({
        experiment,
        userId,
        attributes: {
            ...attributes,
            isStaff,
        },
        split,
        excludeCombo,
        membershipPlan,
        showVariationToUsers,
        debug,
        utmSource,
        excludeUTMSources,
        hasPaidTestConflict,
    });
    const description = props[`${variation}Description`];

    // Log the view
    useEffect(() => {
        if (analytics && enabled && !fullRollout) {
            handleLogVariation(experiment.name, variation, description);
        }
    }, [
        enabled,
        experiment.name,
        description,
        variation,
        fullRollout,
        analytics,
        handleLogVariation,
    ]);

    return variations[variation];
};

ExperimentalFeature.defaultProps = {
    userId: ANONYMOUS_USER_ID,
};

const mapStateToProps = (state) => {
    const { isStaff = false } = getAccountSettings(state) || {};
    return {
        split: getSplit(state),
        membershipPlan: getAccountCurrentMembershipPlan(state),
        isStaff,
        utmSource: getItem(state, UTM_SOURCE_KEY)?.name,
    };
};

const mapDispatchToProps = {
    logVariation: (name, variation, description) =>
        logVariation.create(name, variation, description),
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperimentalFeature);
