import React from 'react';
import TextNode from 'components/TextNode';
import { HelperTextStyled, SuccessIconStyled, ErrorMessageStyled } from './InfoText.style';

const InfoText = ({ isValid, isErrored, helperText, successText, errorText }) => (
    <>
        {!isErrored && helperText ? (
            <HelperTextStyled data-testid="helper-text" isValid={isValid}>
                {isValid ? <SuccessIconStyled data-testid="success-icon" /> : null}
                <TextNode>{helperText}</TextNode>
            </HelperTextStyled>
        ) : null}
        {isValid && successText ? (
            <HelperTextStyled data-testid="success-text" isValid>
                <SuccessIconStyled data-testid="success-icon" />
                <TextNode>{successText}</TextNode>
            </HelperTextStyled>
        ) : null}
        {isErrored ? (
            <ErrorMessageStyled data-testid="error-text">{errorText}</ErrorMessageStyled>
        ) : null}
    </>
);

export default InfoText;
