import atob from 'atob';
import btoa from 'btoa';

const toTitleCase = (phrase) => {
    if (typeof phrase !== 'string') {
        return '';
    }

    return phrase
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const slugToTitleCase = (text) => {
    if (typeof text !== 'string') {
        return '';
    }

    return text
        .replace(/-/g, ' ')
        .replace(/\s+and\s+/g, ' & ')
        .replace(/\b[a-z]/g, (...args) => args[0].toUpperCase());
};

/**
 * Base64 Encode
 * First we use encodeURIComponent to get percent-encoded UTF-8,
 * then we convert the percent encodings into raw bytes which
 * can be fed into btoa.
 *
 * @param str
 * @returns {string}
 */
const base64encode = (str) =>
    btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
            String.fromCharCode(`0x${p1}`)
        )
    );

/**
 * Base64 Decode
 * Going backwards: from bytestream, to percent-encoding, to original string.
 *
 * @param str
 * @returns {string}
 */
const base64decode = (str) =>
    decodeURIComponent(
        atob(str)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );

/**
 * Take a number of bytes and return a nice string version
 */
const formatBytes = (b) => {
    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = b;

    while (n >= 1024) {
        n /= 1024;
        l += 1;
    }

    return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)} ${units[l]}`;
};

/*
 * Decodes a base64 encoded JWT token
 * used primarily for decoding the token for Google Sign In
 */
const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = base64decode(base64);

    return JSON.parse(jsonPayload);
};

export { toTitleCase, slugToTitleCase, base64encode, base64decode, formatBytes, parseJwt };
