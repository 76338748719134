import { settings as actions } from '../../actions';
import {
    SEARCH_TYPE_EXTENDED_RADIUS_RESULTS,
    SEARCH_TYPE_RESULTS,
    SEARCH_TYPE_EXTENDED_RADIUS_FILTERED_RESULTS,
} from '../../constants/searchTypes';
import { filterOutDuplicateListings } from '../../helpers/search/listings';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadListings.SUCCESS: {
            const {
                searchType = 'default',
                startingAfter,
                facetsOnly = false,
            } = action.requestData;

            // this is part of the BROADEN_SIT_SEARCH_RADIUS test
            // it stores listings appearing in a broader radius when searching for a region/city
            // it would filter out results that appear in the main section i.e. search-listings-results
            // and put the remaining ones inside another query search-listings-extended-radius-filtered-results
            // the filtered query would then be used to show sits in the broaden radius sits section
            if (searchType === SEARCH_TYPE_EXTENDED_RADIUS_RESULTS) {
                const mainSearchListings = state[SEARCH_TYPE_RESULTS].results;
                const extendedRadiusListings = facetsOnly
                    ? []
                    : [...action.data.results.map((listing) => listing.id)];
                // get the listing ids that don't appear in search-listings-results
                // but are present in the extended radius
                const filteredExtendedRadiusListings = filterOutDuplicateListings({
                    unfilteredListings: extendedRadiusListings,
                    listingsToRemove: mainSearchListings,
                });

                return {
                    ...state,
                    [searchType]: {
                        results: extendedRadiusListings,
                        facets: { ...action.data.facets },
                        total: action.data.total,
                    },
                    [SEARCH_TYPE_EXTENDED_RADIUS_FILTERED_RESULTS]: {
                        results: filteredExtendedRadiusListings,
                        facets: null,
                        total: filteredExtendedRadiusListings.length,
                    },
                };
            }
            // Append search results
            if (startingAfter) {
                return {
                    ...state,
                    [searchType]: {
                        results: facetsOnly
                            ? []
                            : [
                                  ...state[searchType].results,
                                  ...action.data.results.map((listing) => listing.id),
                              ],
                        facets: state[searchType].facets,
                        total: action.data.total,
                    },
                };
            }
            return {
                ...state,
                [searchType]: {
                    results: facetsOnly
                        ? []
                        : [...action.data.results.map((listing) => listing.id)],
                    facets: { ...action.data.facets },
                    total: action.data.total,
                },
            };
        }
        case actions.search.loadListings.RESET: {
            const { searchType = 'default' } = action.requestData;

            const { [searchType]: omittedKey, ...restOfState } = state;

            return restOfState;
        }
        default:
            return state;
    }
};
