/* eslint-disable import/prefer-default-export */
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { getSearchLocationData } from 'pages/search/helpers';
import * as actions from './actions';

export function* setGeolocationPlace(place) {
    if (!place) return false;

    yield put(actions.setGeoLocationPlace.create(place));

    return true;
}

export function* clearGeolocationPlace() {
    yield put(actions.clearGeoLocationPlace.create());

    return true;
}

export function* doGeoLocationSearch(location) {
    const { latLong } = location;

    if (!latLong.latitude || !latLong.longitude) return false;

    const place = yield call(getSearchLocationData, 'geoLocation', {
        geoPoint: { ...latLong },
        saveResults: false,
    });

    if (place) {
        yield call(setGeolocationPlace, place);
    }

    return true;
}

export default function* geoLocationSagas() {
    yield all([takeEvery(actions.doGeoLocationSearch.ACTION, doGeoLocationSearch)]);
}
