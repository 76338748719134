import { eventChannel } from 'redux-saga';
import { all, put, take, call, select, fork, takeLatest } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { getSitterAssignment, getOwnerAssignment } from 'api/selectors';
import * as reviewActions from './LeaveReviewModal/actions';
import * as reviewSelectors from './LeaveReviewModal/selectors';
import * as feedbackActions from './LeaveFeedbackModal/actions';
import * as feedbackSelectors from './LeaveFeedbackModal/selectors';

export const ANGULAR_CHANNEL = 'angular-event-channel';
export const TYPE_REVIEW = 'review';
export const TYPE_FEEDBACK = 'feedback';
export const EVENT_OPEN_LEAVE_REVIEW_MODAL = 'open-leave-review-modal';
export const EVENT_OPEN_LEAVE_FEEDBACK_MODAL = 'open-leave-feedback-modal';
export const EVENT_LEAVE_REVIEW_SUCCESS = 'review-created';
export const EVENT_LEAVE_FEEDBACK_SUCCESS = 'feedback-created';

/**
 * Watch the custom event triggered from Angular. This is necessary to trigger
 * the modal from Angular inside React and fetch the assignment.
 */
export function createAngularBridgeChannel() {
    return eventChannel((emit) => {
        const handleEvent = ({ detail: { type, payload } }) => {
            switch (type) {
                case EVENT_OPEN_LEAVE_REVIEW_MODAL:
                    emit({
                        type: TYPE_REVIEW,
                        assignment: payload,
                    });
                    break;
                case EVENT_OPEN_LEAVE_FEEDBACK_MODAL:
                    emit({
                        type: TYPE_FEEDBACK,
                        assignment: payload,
                    });
                    break;
                default:
                    break;
            }
        };

        document.addEventListener(ANGULAR_CHANNEL, handleEvent);

        return () => {
            document.removeEventListener(ANGULAR_CHANNEL, handleEvent);
        };
    });
}

export function* handleOpenReviewModal(id) {
    yield put(
        apiActions.owner.loadAssignment({
            forceReload: true,
            data: {
                id,
            },
        })
    );

    const { status } = yield take(settings.owner.loadAssignment.DONE);

    if (status === settings.owner.loadAssignment.SUCCESS) {
        const assignment = yield select((state) => getOwnerAssignment(state, id));

        yield put(reviewActions.open.create(assignment));
    }
}

export function* handleOpenFeedbackModal(id) {
    yield put(
        apiActions.sitter.loadAssignment({
            forceReload: true,
            data: {
                id,
            },
        })
    );

    const { status } = yield take(settings.sitter.loadAssignment.DONE);

    if (status === settings.sitter.loadAssignment.SUCCESS) {
        const assignment = yield select((state) => getSitterAssignment(state, id));

        yield put(feedbackActions.open.create(assignment));
    }
}

export function* watchAngularEvent() {
    if (typeof document !== 'undefined') {
        const channel = createAngularBridgeChannel();

        while (true) {
            const { type, assignment } = yield take(channel);

            switch (type) {
                case TYPE_REVIEW:
                    yield call(handleOpenReviewModal, assignment.id);
                    break;
                case TYPE_FEEDBACK:
                    yield call(handleOpenFeedbackModal, assignment.id);
                    break;
                default:
                    break;
            }
        }
    }
}

function* dispatchReviewCreation() {
    const assignment = yield select(reviewSelectors.getAssignment);

    document.dispatchEvent(
        new CustomEvent(ANGULAR_CHANNEL, {
            detail: {
                type: EVENT_LEAVE_REVIEW_SUCCESS,
                payload: assignment,
            },
        })
    );
}

export function* dispatchFeedbackCreation() {
    const assignment = yield select(feedbackSelectors.getAssignment);

    document.dispatchEvent(
        new CustomEvent(ANGULAR_CHANNEL, {
            detail: {
                type: EVENT_LEAVE_FEEDBACK_SUCCESS,
                payload: assignment,
            },
        })
    );
}

export default function* sagas() {
    yield all([
        takeLatest(reviewActions.submitRequest.ACTION, dispatchReviewCreation),
        takeLatest(feedbackActions.submitSuccess.ACTION, dispatchFeedbackCreation),
        fork(watchAngularEvent),
    ]);
}
