import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { WrapperStyled, StarStyled, StarOutlineStyled } from './Rating.style';

const Rating = forwardRef(({ max, value, size, ...rest }, ref) => (
    <WrapperStyled ref={ref} {...rest}>
        {[...Array(max)].map((_, index) => {
            const Component = index >= value ? StarOutlineStyled : StarStyled;

            // Disabling eslint for next line because using index as key is not
            // good, but in this case we have a static list, which is fine.
            // eslint-disable-next-line
            return <Component key={`rating-star-${index}`} size={size} />;
        })}
    </WrapperStyled>
));

Rating.propTypes = {
    max: PropTypes.number,
    value: PropTypes.number,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Rating.defaultProps = {
    max: 5,
    value: 0,
    size: 'md',
};

export default Rating;
