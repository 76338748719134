function LoginLinkWrapper(props) {
    return props.children(() => {
        // the reset password page should not redirect back to itself
        if (window.location.pathname !== '/reset-password/') {
            // make sure to not include the hash fragment if it is a regwall
            // this mainly covers the use case when clicking on a login link on the global regwall
            const isHashARegwall = window.location.hash.includes('regwall');
            const locationHash = isHashARegwall ? '' : window.location.hash;

            window.sessionStorage.setItem(
                'ths-redirect-url',
                JSON.stringify(
                    `${window.location.pathname}${window.location.search}${locationHash}`
                )
            );
            window.sessionStorage.setItem(
                'ths-redirect-status',
                JSON.stringify(['not paid', 'expired'])
            );
        }
    });
}

export default LoginLinkWrapper;
