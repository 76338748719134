import { getAsyncLocalStorage } from '../universal-async-local-storage';

export const injectSaga = (key, saga) =>
    getAsyncLocalStorage().getStore().get('injectSaga')(key, saga);

export const setInjectSaga = (injectSagaFn) =>
    getAsyncLocalStorage().getStore().set('injectSaga', injectSagaFn);

export const injectReducer = (key, reducer) =>
    getAsyncLocalStorage().getStore().get('injectReducer')(key, reducer);

export const setInjectReducer = (injectReducerFn) =>
    getAsyncLocalStorage().getStore().set('injectReducer', injectReducerFn);
