import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAccountReferFreeMonths } from 'api/selectors/account';
import { WrapperStyled, HeaderStyled, ContentStyled } from './FreeMonthsText.style';

const FreeMonthsText = () => {
    const { t } = useTranslation();
    const freeMonths = useSelector(getAccountReferFreeMonths);

    return (
        <WrapperStyled>
            <HeaderStyled>
                {t('containers_refer_a_friend_inline_free_months_header', {
                    months: freeMonths,
                })}
            </HeaderStyled>
            <ContentStyled>
                {t('containers_refer_a_friend_inline_free_months_content', {
                    months: freeMonths,
                })}
            </ContentStyled>
        </WrapperStyled>
    );
};

export default FreeMonthsText;
