import {
    isWithinInterval,
    format,
    isBefore,
    isAfter,
    isEqual,
    areIntervalsOverlapping,
    isSameYear,
    parseISO,
    toDate,
} from 'date-fns';

/**
 * This function parse a parameter `DateType` to an instance of native Date object
 * @param {Date|string|number} date
 * @returns {Date}
 */
export const parseDate = date => {
    if (typeof date === 'string') {
        return parseISO(date);
    }

    if (typeof date === 'number') {
        return toDate(date);
    }

    return date;
};

/**
 * Convert date from yyyy-mm-dd (served by DB) to to mm/dd/yyyy format
 */
export const getLocalDate = date => {
    try {
        let newDate;
        let parts;
        if (typeof date === 'string') {
            parts = date.split('-');
            newDate = new Date(+parts[0], parts[1] - 1, +parts[2]);
        } else if (typeof date === 'number') {
            newDate = new Date(date);
        } else {
            newDate = date;
        }
        return newDate;
    } catch (e) {
        return date;
    }
};

/**
 * Convert date to ISO date format
 */
export const toISODate = date => {
    let newDate;
    if (typeof date === 'string') {
        const parts = date.split('-');
        newDate = new Date(+parts[0], parts[1] - 1, +parts[2]);
    } else if (typeof date === 'number') {
        newDate = new Date(date);
    } else {
        newDate = date;
    }
    return `${newDate.getFullYear()}-${`0${newDate.getMonth() + 1}`.slice(
        -2
    )}-${`0${newDate.getDate()}`.slice(-2)}`;
};

/**
 * Convert timestamp to date
 */
export const timestampToDate = timestamp => {
    if (timestamp && timestamp instanceof Date) {
        return timestamp;
    }
    return getLocalDate(timestamp);
};

/**
 * Calculate number of seconds between two dates
 */
export const secondsBetween = (startDate, endDate) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);

    return Math.round((newEndDate - newStartDate) / 1000);
};

/**
 * Calculate number of days between two dates
 */
export const daysBetween = (startDate, endDate) => {
    if (!startDate || !endDate) return null;

    let newStartDate = timestampToDate(startDate);
    let newEndDate = timestampToDate(endDate);

    if (!newStartDate || !newEndDate) return null;

    newStartDate = new Date(newStartDate.getTime());
    newEndDate = new Date(newEndDate.getTime());

    newStartDate.setHours(newEndDate.getHours());
    newStartDate.setMinutes(newEndDate.getMinutes());
    newStartDate.setSeconds(newEndDate.getSeconds());
    newStartDate.setMilliseconds(newEndDate.getMilliseconds());

    return Math.round((newEndDate - newStartDate) / (1000 * 60 * 60 * 24));
};

/**
 * Calculate number of years between two dates
 */
export const yearsBetween = (startDate, endDate) => {
    let newStartDate = timestampToDate(startDate);
    let newEndDate = timestampToDate(endDate);

    if (!newStartDate || !newEndDate) return null;

    newStartDate = new Date(newStartDate.getTime());
    newEndDate = new Date(newEndDate.getTime());

    newStartDate.setHours(newEndDate.getHours());
    newStartDate.setMinutes(newEndDate.getMinutes());
    newStartDate.setSeconds(newEndDate.getSeconds());
    newStartDate.setMilliseconds(newEndDate.getMilliseconds());

    return Math.floor((newEndDate - newStartDate) / (1000 * 60 * 60 * 24 * 365));
};

/**
 * Calculate number of days until today
 */
export const daysUntil = date => daysBetween(date, new Date().setHours(0, 0, 0, 0));

/**
 * Calculate number of days from today
 */
export const daysFrom = date => daysBetween(new Date().setHours(0, 0, 0, 0), date);

/**
 * Check if date is between two dates
 */
export const dateBetween = (date, startDate, endDate) => {
    const dateParsed = parseDate(date);
    const startDateParsed = parseDate(startDate);
    const endDateParsed = parseDate(endDate);

    return isWithinInterval(dateParsed, { start: startDateParsed, end: endDateParsed });
};

/**
 * Helper proxy for isBefore check
 */
export const dateBefore = (dateA, dateB) => {
    const dateAParsed = parseDate(dateA);
    const dateBParsed = parseDate(dateB);

    return isBefore(dateAParsed, dateBParsed);
};

/**
 * Helper proxy for isAfter check
 */
export const dateAfter = (dateA, dateB) => {
    const dateAParsed = parseDate(dateA);
    const dateBParsed = parseDate(dateB);

    return isAfter(dateAParsed, dateBParsed);
};

/**
 * Helper proxy for isSameOrBefore check
 */
export const dateSameOrBefore = (dateA, dateB) => {
    const dateAParsed = parseDate(dateA);
    const dateBParsed = parseDate(dateB);

    return isEqual(dateAParsed, dateBParsed) || isBefore(dateAParsed, dateBParsed);
};

/**
 * Helper proxy for isSameOrAfter check
 */
export const dateSameOrAfter = (dateA, dateB) => {
    const dateAParsed = parseDate(dateA);
    const dateBParsed = parseDate(dateB);

    return isEqual(dateAParsed, dateBParsed) || isAfter(dateAParsed, dateBParsed);
};

/**
 * Helper proxy for isSame check
 */
export const dateEqual = (dateA, dateB) => {
    const dateAParsed = parseDate(dateA);
    const dateBParsed = parseDate(dateB);

    return isEqual(dateAParsed, dateBParsed);
};

/**
 * Check if more than the provided number of days, hours, minutes and seconds
 * has passed between the date provided and now.
 */
export const isOlderThan = (
    {
        date,
        days = 0,
        hours = 0,
        minutes = 0,
        seconds = 0
    }
) => {
    const d = new Date(date);
    const checkDate = new Date();
    const timeOffset =
        days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
    checkDate.setTime(checkDate.getTime() - timeOffset);
    return d < checkDate;
};

/**
 * Check if date is more than days ago
 */
export const isNullOrMoreThanDaysAgo = (date, days) => {
    if (date === null || date === undefined) return true;
    const daysUntilDate = daysUntil(date);

    if (daysUntilDate == null) return true;

    return daysUntilDate > days;
};

/**
 * Check if date is more than hours ago
 */
export const isNullOrOlderThan = (
    {
        date,
        days = 0,
        hours = 0,
        minutes = 0
    }
) => {
    if (date === null || date === undefined) return true;
    return isOlderThan({ date, days, hours, minutes });
};

/**
 * Format date range
 */
export const getFormattedDateRange = (from, to, { alwaysShowYear = false } = {}) => {
    if (!from && !to) return null;

    const fromParsed = parseDate(from);
    const toParsed = parseDate(to);

    const showYear = alwaysShowYear || !isSameYear(fromParsed, toParsed);
    const formattedFrom = format(fromParsed, showYear ? 'dd MMM yyyy' : 'dd MMM');
    const formattedTo = format(toParsed, 'dd MMM yyyy');

    return `${formattedFrom} - ${formattedTo}`;
};

/**
 * Check overlapping dates
 */
export const checkIfDatesOverlapping = (
    startDateA,
    endDateA,
    startDateB,
    endDateB
) => {
    const startDateAParsed = parseDate(startDateA);
    const endDateAParsed = parseDate(endDateA);
    const startDateBParsed = parseDate(startDateB);
    const endDateBParsed = parseDate(endDateB);

    return areIntervalsOverlapping(
        { start: startDateAParsed, end: endDateAParsed },
        { start: startDateBParsed, end: endDateBParsed }
    );
};

/**
 * Check overlapping dates
 */
export const datesOverlapping = (startDate, endDate, dates) => {
    const startDateParsed = parseDate(startDate);
    const endDateParsed = parseDate(endDate);

    for (let a = 0; a < dates.length; a += 1) {
        const currentStartDate = parseDate(dates[a].startDate);
        const currentEndDate = parseDate(dates[a].endDate);

        if (
            checkIfDatesOverlapping(
                startDateParsed,
                endDateParsed,
                currentStartDate,
                currentEndDate
            )
        ) {
            return true;
        }
    }

    return false;
};

/**
 * Returns true if intervalsLeft array has any overlapping date with the intervalsRight array
 * @param {Array<{ startDate: DateType, endDate: DateType }>} intervalsLeft - array of intervals
 * @param {Array<{ startDate: DateType, endDate: DateType }>} intervalsRight - array of intervals
 * @returns {boolean}
 */
export const hasOverlappingIntervals = (intervalsLeft = [], intervalsRight = []) => {
    for (let i = 0; i < intervalsLeft.length; i += 1) {
        const intervalLeft = {
            start: parseDate(intervalsLeft[i].startDate),
            end: parseDate(intervalsLeft[i].endDate),
        };

        for (let j = 0; j < intervalsRight.length; j += 1) {
            const intervalRight = {
                start: parseDate(intervalsRight[j].startDate),
                end: parseDate(intervalsRight[j].endDate),
            };

            if (areIntervalsOverlapping(intervalLeft, intervalRight)) {
                return true;
            }
        }
    }

    return false;
};
