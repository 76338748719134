import * as React from 'react';
import { DecrementButton, IncrementButton } from './Button.style';

const ButtonTypes = {
    increment: IncrementButton,
    decrement: DecrementButton,
};

function Button({ area, disabled, onClick, children, ...props }) {
    if (disabled) {
        return null;
    }

    const Component = ButtonTypes[area];

    return (
        <Component type="button" {...props} onClick={onClick}>
            {children}
        </Component>
    );
}

export default Button;
