import styled, { css } from 'styled-components';
import { mix } from 'polished';
import OurLink from 'components/Link';

const itemSizeSmallScreen = (theme) => `calc(${theme.sizing.touch} - ${theme.spacing[1]})`;
const itemSizeLargeScreen = (theme) => theme.spacing[4];

const PaginationStyled = styled.ol`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PaginationItemStyled = styled.li`
    width: ${({ theme }) => itemSizeSmallScreen(theme)};
    height: ${({ theme }) => itemSizeSmallScreen(theme)};
    margin: 0 ${({ theme }) => theme.spacing[1]} 0 0;
    padding: 0;

    :last-child {
        margin-right: 0;
    }

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: ${({ theme }) => itemSizeLargeScreen(theme)};
        height: ${({ theme }) => itemSizeLargeScreen(theme)};
    }
`;

const InactivePaginationItemStyled = styled(PaginationItemStyled)`
    display: none;

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: block;
    }
`;

const ActivePaginationItemStyled = styled(PaginationItemStyled)`
    display: block;
`;

const inactivePaginationItemStyles = css`
    display: block;
    width: ${({ theme }) => itemSizeSmallScreen(theme)};
    height: ${({ theme }) => itemSizeSmallScreen(theme)};
    line-height: ${({ theme }) => itemSizeSmallScreen(theme)};
    font-size: ${({ theme }) => theme.typography.smallText['font-size']};
    text-align: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.text};

    &:hover {
        text-decoration: underline;
    }

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: ${({ theme }) => itemSizeLargeScreen(theme)};
        height: ${({ theme }) => itemSizeLargeScreen(theme)};
        line-height: ${({ theme }) => itemSizeLargeScreen(theme)};
    }
`;

const InactivePaginationItemButtonStyled = styled.button`
    ${inactivePaginationItemStyles};
    border: 0;
    cursor: pointer;
    background: none;
`;

const ActivePaginationItemButtonStyled = styled(InactivePaginationItemButtonStyled)`
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.primaryRegular};
    color: ${({ theme }) => theme.palette.textWhite};
`;

const iconHoverColor = (theme) => {
    const { strength, color } = theme.opacityOverlay.dark.hover;

    return mix(strength, color, theme.palette.text);
};

const PaginationChevronButtonStyled = styled(InactivePaginationItemButtonStyled)`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;

    /* @TODO: better way to change the colour of the icon on hover */
    &:hover {
        && svg {
            fill: ${({ theme }) => iconHoverColor(theme)};
        }
    }
`;

const ArrowIconWrapperStyled = styled.li`
    height: ${({ theme }) => theme.sizing.touch};
`;

const ArrowIconLinkStyled = styled(OurLink)`
    display: flex;
    align-items: center;
    margin: ${({ theme }) => theme.spacing[1.5]} 0;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin: ${({ theme }) => theme.spacing[1]} 0;
    }
`;

const ArrowIconLinkLabelStyled = styled.div`
    margin: 0 ${({ theme }) => theme.spacing[1]};
`;

export {
    PaginationStyled,
    PaginationItemStyled,
    InactivePaginationItemStyled,
    InactivePaginationItemButtonStyled,
    ActivePaginationItemStyled,
    ActivePaginationItemButtonStyled,
    inactivePaginationItemStyles,
    PaginationChevronButtonStyled,
    ArrowIconWrapperStyled,
    ArrowIconLinkStyled,
    ArrowIconLinkLabelStyled,
};
