export const DurationInDays = [1, 3, 7, 14, 28, 168, 0];

export const DurationKeys = [
    'no-limit',
    '3-days',
    '4-7-days',
    '1-2-weeks',
    '2-4-weeks',
    '1-6-months',
    '6-months',
];

export const DURATION_KEY_I18N_MAP = {
    'no-limit': {
        key: 'web_time_day',
        count: 1,
    },
    '3-days': {
        key: 'web_time_day',
        count: 3,
    },
    '4-7-days': {
        key: 'web_time_week',
        count: 1,
    },
    '1-2-weeks': {
        key: 'web_time_week',
        count: 2,
    },
    '2-4-weeks': {
        key: 'web_time_month',
        count: 1,
    },
    '1-6-months': {
        key: 'web_time_month',
        count: 6,
    },
};
