// eslint-disable-next-line import/prefer-default-export
export const VIEWED_REGWALL = {
    create({ membershipType, method, regwallStep }) {
        const payload = {
            membershipType,
            method,
            regwallStep,
        };

        return {
            type: 'Viewed Regwall',
            payload,
        };
    },
};
