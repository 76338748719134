import loadable from '@loadable/component';
import { connect } from 'react-redux';
import asPage from 'containers/Page';
import { getIsLoggedIn, getSeoContent } from 'src/shared/selectors';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
    userTypes,
    VariationTypes,
} from 'containers/ExperimentalFeature';
import { getStepNumber } from 'containers/Wizard/selectors';
import PAGE_ID from './FindAHouseSitWizard.constants';

const LoadableFindAHouseSitWizard = loadable(() => import('./FindAHouseSitWizard'));

const mapStateToProps = (state) => {
    const { variation } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.QUICK_LOCATION_SEARCH,
        excludeCombo: [userTypes.ExpiredUser],
    });

    const { variation: sitDiscoveryPageVariation } = getExperimentalFeatureVariationSelector(
        state,
        {
            experiment: experiments.SIT_DISCOVERY_PAGE,
            excludeCombo: [userTypes.PaidUser, userTypes.ExpiredUser],
        }
    );

    return {
        isLoggedIn: getIsLoggedIn(state),
        seoContent: getSeoContent(state),
        isQuickLocationVariation: VariationTypes.VARIATION1 === variation,
        isSitDiscoveryPageVariation: VariationTypes.VARIATION1 === sitDiscoveryPageVariation,
        stepNumber: getStepNumber(state),
    };
};

export default connect(
    mapStateToProps,
    null
)(asPage(LoadableFindAHouseSitWizard, PAGE_ID, true, true));
