// eslint-disable-next-line import/prefer-default-export
export const OWNER_UNPAUSED_ASSIGNMENT = {
    create(assignment) {
        const { assignmentId, listingId, isApproximateDates, startDate, endDate, ownerId } =
            assignment;
        const payload = {
            assignmentId,
            isApproximateDates,
            listingId,
            startDate,
            endDate,
            ownerId,
        };

        return {
            type: 'Owner Unpaused Assignment',
            payload,
        };
    },
};
