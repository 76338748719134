// eslint-disable-next-line import/prefer-default-export
export const USER_LISTING_EDIT_SITTER_RESPONSIBILITIES = {
    create(listing) {
        const { responsibilities = '' } = listing || {};

        return {
            type: 'Listing Edit Sitter Responsibilities Update',
            payload: {
                responsibilities: responsibilities.length >= 50,
            },
        };
    },
};
