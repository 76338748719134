import React from 'react';
import { SpinnerContainerStyled } from './PageSpinner.style';
import Spinner from '../Spinner';

const PageSpinner = () => (
    <SpinnerContainerStyled>
        <Spinner />
    </SpinnerContainerStyled>
);

export default PageSpinner;
