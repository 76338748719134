import { all, put, select, takeLatest, call, take } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { getOwnerAssignment } from 'api/selectors';
import { isFeatureEnabled, features } from 'components/Feature';
import { isReviewWindowClosed } from 'pages/user/assignments/shared/utils';
import { track, Events } from 'utils/analytics';
import { validateSubmitValues } from '../LeaveFeedbackModal/utils';
import { assignmentIdFromAction, hasPets } from '../shared/utils';
import * as rootActions from '../actions';
import * as actions from './actions';
import * as selectors from './selectors';
import * as constants from './constants';

export function* fetchPastAssignment(id) {
    const assignment = getOwnerAssignment(yield select(), id);

    if (assignment !== null) {
        return assignment;
    }

    yield put(
        apiActions.owner.loadAssignment({
            forceReload: true,
            data: {
                id,
            },
        })
    );

    const { status } = yield take(settings.owner.loadAssignment.DONE);

    if (status === settings.owner.loadAssignment.FAILURE) {
        return null;
    }

    return getOwnerAssignment(yield select(), id);
}

export function* triggerSendAnalytics() {
    const current = yield select(selectors.getCurrent);

    yield call(track, Events.LEAVE_REVIEW_MODAL.create(current));
}

export function* updateStorage() {
    const assignment = yield select(selectors.getAssignment);

    yield put(rootActions.viewedAssignment.create(assignment));
}

export function* handleSubmit() {
    const assignment = yield select(selectors.getAssignment);
    const values = yield select(selectors.getContext);

    if (!validateSubmitValues(values)) {
        yield put(actions.submitFailure.create());
        return;
    }

    yield put(
        apiActions.owner.createPastAssignmentReview({
            forceReload: true,
            data: {
                assignmentId: assignment.id,
                listingId: assignment.listingId,
                description: values.description,
                overallScore: values.overallScore,
                organised: values.organised,
                ...(hasPets(assignment) && { petCare: values.petCare }),
                reliable: values.reliable,
                selfSufficient: values.selfSufficient,
                tidy: values.tidy,
            },
        })
    );

    const { status } = yield take(settings.owner.createPastAssignmentReview.DONE);

    if (status === settings.owner.createPastAssignmentReview.FAILURE) {
        yield put(actions.submitFailure.create());
        return;
    }

    yield put(
        apiActions.owner.loadAssignment({
            forceReload: true,
            data: {
                id: assignment.id,
            },
        })
    );

    yield take(settings.owner.loadAssignment.DONE);
    yield put(actions.submitSuccess.create());
    yield put(actions.send.create(constants.SCREEN_THANKS));
}

export function* handleOpen(action) {
    const assignment = yield call(fetchPastAssignment, assignmentIdFromAction(action));

    if (!assignment) {
        return;
    }

    if (isReviewWindowClosed(assignment.reviewWindowEndDate) || assignment.isReviewProvided) {
        yield put(
            actions.hydrate.create({
                assignment,
                current: constants.SCREEN_REVIEW_WINDOW_CLOSED,
            })
        );
    } else {
        yield put(
            actions.hydrate.create({
                assignment,
                current: constants.SCREEN_HOW_WAS_YOUR_SITTER,
            })
        );
    }
}

export default function* sagas() {
    yield all([
        takeLatest(actions.submitRequest.ACTION, handleSubmit),
        takeLatest(actions.open.ACTION, handleOpen),
        takeLatest(actions.open.ACTION, triggerSendAnalytics),
        takeLatest(actions.close.ACTION, updateStorage),
        takeLatest(actions.send.ACTION, triggerSendAnalytics),
    ]);
}
