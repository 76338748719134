import { settings as actions } from '../../actions';
import {
    baseInitialState,
    loadedDataItemReducer,
} from '../common';

import { uniqueArray } from '../../helpers/format/objects';

export const initialState = { ...baseInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        // case actions.owner.loadFavourites.SUCCESS:
        //     // Add load more
        //     return {
        //         ...state,
        //         ...action.data.results.reduce((profiles, profile) => {
        //             profiles[profile.id] = transform.getProfile(profile);
        //             return profiles;
        //         }, {}),
        //     };
        case actions.owner.loadSitterProfile.SUCCESS:
            return loadedDataItemReducer(state, action);
        case actions.owner.loadOwnerSitterPastAssignments.SUCCESS:
            return {
                ...state,
                [action.requestData.id]: {
                    ...state[action.requestData.id],
                    pastAssignments: action.data.results,
                },
            };
        case actions.owner.loadOwnerSitterInvitations.SUCCESS:
            return {
                ...state,
                [action.requestData.id]: {
                    ...state[action.requestData.id],
                    invitations: action.data.results.map(invitation => invitation.id),
                },
            };
        case actions.owner.loadOwnerSitterApplications.SUCCESS:
            return {
                ...state,
                [action.requestData.id]: {
                    ...state[action.requestData.id],
                    applications: action.data.results.map(application => application.id),
                },
            };
            // return {
            //     ...state,
            //     [action.data.sitterId]: {
            //         ...state[action.data.sitterId],
            //         applications: action.data.results,
            //     }
            // };
        case actions.owner.loadOpenAssignmentApplication.SUCCESS: {
            const profileId = action.data.profileId;
            const profile = state[profileId] || {};
            const applications = [
                ...(profile.applications || []),
            ];

            if (applications.indexOf(action.data.id) < 0) {
                applications.push(action.data.id);
            }
            return {
                ...state,
                [profileId]: {
                    ...state[profileId],
                    applications,
                },
            };
        }
        case actions.owner.loadOpenAssignmentInvitation.SUCCESS: {
            const profileId = action.data.profileId;
            const profile = state[profileId] || {};
            const invitations = [
                ...(profile.invitations || []),
            ];

            if (invitations.indexOf(action.data.id) < 0) {
                invitations.push(action.data.id);
            }
            return {
                ...state,
                [profileId]: {
                    ...state[profileId],
                    invitations,
                },
            };
        }
        case actions.owner.createOpenAssignmentInvitation.SUCCESS: {
            const profile = state[action.data.profileId] || {};
            const invitations = [
                ...(profile.invitations || []),
            ];
            if (invitations.indexOf(action.data.id) < 0) {
                invitations.push(action.data.id);
            }
            return {
                ...state,
                [action.data.profileId]: {
                    ...state[action.data.profileId],
                    invitations,
                },
            };
        }
        case actions.owner.createBatchOpenAssignmentInvitation.SUCCESS: {
            const profile = state[action.requestData.profileId] || {};
            const invitations = [
                ...(profile.invitations || []),
            ];
            return {
                ...state,
                [action.requestData.profileId]: {
                    ...state[action.requestData.profileId],
                    invitations: uniqueArray([
                        ...invitations,
                        ...[action.data.map(invitation => invitation.id)],
                    ]),
                },
            };
        }
        default:
            return state;
    }
};
