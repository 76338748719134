import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware, { END } from 'redux-saga';
import { routerMiddleware as createRouterMiddleware, connectRouter } from 'connected-react-router';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { coreApi } from 'api/query/core/coreApi';
import apiClientReducers from 'api/reducers';
import page from 'containers/Page/reducers';
import register from 'containers/forms/Register/reducers';
import globalRegwallReducer from 'containers/GlobalRegwall/reducers';
import sendReferralEmail from 'containers/SendReferralEmail/reducers';
import sendShareListingEmail from 'containers/SendShareListingEmailModal/reducers';
import upgradeModalReducer from 'containers/UpgradeModal/reducers';
import containersReducer from 'containers/reducers';
import sagas, { waitAll } from './sagas';
import historyMiddleware from './middleware/history';

import pages from '../pages/reducers';
import shared from '../shared/reducers';
import universalStorage from '../universalStorage/reducers';

// geoip, language and split state is never changed as always bootstrapped from server
const geoip = (state = {}) => state;
const language = (state = {}) => state;
const split = (state = {}) => state;
const asyncDefaultReducer = {};

export const staticReducers = {
    ...apiClientReducers,
    page,
    geoip,
    language,
    split,
    pages,
    shared,
    universalStorage,
    register,
    globalRegwall: globalRegwallReducer,
    sendReferralEmail,
    sendShareListingEmail,
    upgradeModal: upgradeModalReducer,

    // This is a new pattern to try to clean up the root of the store
    containers: containersReducer,
    [coreApi.reducerPath]: coreApi.reducer,
};

// Create a function that allows sagas to be injected and run dynamically
const createSagaInjector = (runSaga) => {
    // Create a dictionary to keep track of injected sagas
    const injectedSagas = new Map();

    const isInjected = (key) => injectedSagas.has(key);

    const injectSaga = (key, saga) => {
        // We won't run saga if it is already injected
        if (isInjected(key)) return null;

        // Sagas return task when they executed, which can be used
        // to cancel them
        const task = runSaga(saga);

        // Save the task if we want to cancel it in the future
        injectedSagas.set(key, task);

        // return the task
        return task;
    };

    return injectSaga;
};

const createReducer = (asyncReducers, history) =>
    combineReducers({
        router: connectRouter(history),
        ...staticReducers,
        ...(Object.keys(asyncReducers).length > 0
            ? { async: combineReducers(asyncReducers) }
            : undefined),
    });

const createReducerInjector = (store, history) => {
    // Create a dictionary to keep track of injected reducers
    const injectedReducers = {};

    // Add injectReducer function to created store object.
    // It will only actually add the reducer if it does
    // not already exist.
    //
    const injectReducer = (key, asyncReducer) => {
        if (!injectedReducers[key]) {
            injectedReducers[key] = asyncReducer;
        }
        store.replaceReducer(createReducer(injectedReducers, history));
    };

    return injectReducer;
};

export const createNewStore = (history, asyncReducers, middleware, initialState) =>
    configureStore({
        reducer: createReducer(asyncReducers, history),
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            }).concat(middleware),
    });

const configure = (initialState, history) => {
    const sagaMiddleware = createSagaMiddleware();
    const routerMiddleware = createRouterMiddleware(history);

    const middleware = [routerMiddleware, sagaMiddleware, historyMiddleware, coreApi.middleware];
    const store = createNewStore(history, asyncDefaultReducer, middleware, initialState);

    const injectReducer = createReducerInjector(store, history);
    const injectSaga = createSagaInjector(sagaMiddleware.run);

    const rootSaga = sagaMiddleware.run(sagas);

    const runPreloadSagas = (preloadSagas = []) => sagaMiddleware.run(waitAll(preloadSagas));
    const stopSagas = () => store.dispatch(END);

    setupListeners(store.dispatch);

    return { store, rootSaga, stopSagas, injectSaga, injectReducer, runPreloadSagas };
};

export default configure;
