import { createAction } from 'utils/actions';

const PREFIX = 'LEAVE_REVIEW_MODAL';

export const tryOpen = createAction(PREFIX, 'TRY_OPEN');
export const hydrate = createAction(PREFIX, 'HYDRATE', 'payload');
export const open = createAction(PREFIX, 'OPEN', 'assignment');
export const close = createAction(PREFIX, 'CLOSE');
export const send = createAction(PREFIX, 'SEND', 'target');
export const setContext = createAction(PREFIX, 'SET_CONTEXT', 'values');
export const submitRequest = createAction(PREFIX, 'SUBMIT_REQUEST');
export const submitSuccess = createAction(PREFIX, 'SUBMIT_SUCCESS');
export const submitFailure = createAction(PREFIX, 'SUBMIT_FAILURE');
