/* eslint-disable import/prefer-default-export */
import actions, { settings } from 'api/actions';
import { Facet as searchFacets } from 'api/helpers/search/constants';
import * as pageActions from 'containers/Page/actions';
import { all, call, put, select, take } from 'redux-saga/effects';
import { createListingsSearchQuery } from 'utils/searchListings';
import { createProfilesSearchQuery } from 'utils/searchProfiles';
import { PAGE_ID, SEO_FACET_LINKS } from './constants';
import { hasSeoFacetLinks, hasSeoListingsFacetLinks } from './selectors';

export function* raiseError(statusCode) {
    yield put(pageActions.error.create(PAGE_ID, statusCode));
}

export function* loadProfilesFacets() {
    const seoFacetLinks = yield select(hasSeoFacetLinks);

    if (seoFacetLinks) {
        return true;
    }

    const searchQuery = createProfilesSearchQuery(
        1,
        {},
        [searchFacets.COUNTRY, searchFacets.ADMIN1, searchFacets.ADMIN2, searchFacets.PLACE],
        0
    );
    const query = searchQuery.getRequestData();

    yield put(
        actions.search.loadProfiles({
            forceReload: true,
            filters: {
                query: JSON.stringify(query),
            },
            data: {
                searchType: SEO_FACET_LINKS,
                rawQuery: query,
            },
        })
    );

    const { action, status } = yield take(
        (res) =>
            res.type === settings.search.loadProfiles.DONE &&
            res.requestData.searchType === SEO_FACET_LINKS
    );

    if (status === settings.search.loadProfiles.SUCCESS) {
        return true;
    }
    yield call(raiseError, action.statusCode);
    return true;
}

export function* loadListingsFacets() {
    const seoFacetLinks = yield select(hasSeoListingsFacetLinks);

    if (seoFacetLinks) {
        return true;
    }

    const searchQuery = createListingsSearchQuery({
        filetrs: {},
        facets: [searchFacets.COUNTRY, searchFacets.PLACE],
        facetResultSize: {
            [searchFacets.COUNTRY]: 20,
            [searchFacets.PLACE]: 20,
        },
    });
    const query = searchQuery.getRequestData();

    yield put(
        actions.search.loadListings({
            forceReload: true,
            filters: {
                query: JSON.stringify(query),
            },
            data: {
                searchType: SEO_FACET_LINKS,
                rawQuery: query,
            },
        })
    );

    const { action, status } = yield take(
        (res) =>
            res.type === settings.search.loadListings.DONE &&
            res.requestData.searchType === SEO_FACET_LINKS
    );

    if (status === settings.search.loadListings.SUCCESS) {
        return true;
    }

    yield call(raiseError, action.statusCode);
    return true;
}

export default function* loadFacets() {
    yield all([call(loadProfilesFacets), call(loadListingsFacets)]);
}
