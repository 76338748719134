import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ItunesAppID, GooglePlayAppID } from 'utils/app/constants';

const HandleAddSmartAppBanner = ({ userIsMember }) => {
    const [installPromptEvent, setInstallPromptEvent] = useState(null);

    useEffect(() => {
        const handleBeforeinstallprompt = (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setInstallPromptEvent(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeinstallprompt);
        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeinstallprompt);
        };
    }, []);

    useEffect(() => {
        if (userIsMember && installPromptEvent) {
            installPromptEvent.prompt();
        }
    }, [userIsMember, installPromptEvent]);

    return (
        userIsMember && (
            <Helmet>
                <meta name="apple-itunes-app" content={`app-id=${ItunesAppID}`} />
                <meta name="google-play-app" content={`app-id=${GooglePlayAppID}`} />
                <link rel="apple-touch-icon" href="/static/favicon/android-chrome-512x512.png" />
                <link rel="android-touch-icon" href="/static/favicon/android-chrome-512x512.png" />
            </Helmet>
        )
    );
};

export default HandleAddSmartAppBanner;
