import { delay as delayP } from 'redux-saga';
import { take, call, fork, race } from 'redux-saga/effects';

// Our ancient version of redux-saga doesn't have the delay effect but we can implement it easily
export const delay = call.bind(null, delayP);

// Like `debounce` from newer versions of redux-saga but it collects the
// batched actions to pass to the given saga. This allows the given saga to see all the action data
export const batch = (ms, pattern, task, ...args) =>
    fork(function* forked() {
        while (true) {
            const actions = [yield take(pattern)];

            while (true) {
                const { debounced, latestAction } = yield race({
                    debounced: delay(ms),
                    latestAction: take(pattern),
                });

                if (debounced) {
                    yield fork(task, ...args, [...actions]);
                    actions.length = 0;
                    break;
                }

                actions.push(latestAction);
            }
        }
    });
