import React from 'react';
import ShareableLinkBlock from 'components/ReferAFriend/components/ShareableLinkBlock';
import FreeMonthsText from 'components/ReferAFriend/components/FreeMonthsText';
import {
    WrapperStyled,
    ContainerStyled,
    ContentLeftStyled,
    ContentRightStyled,
    TrustPilotReviewQuoteStyled,
} from './ReferAFriendInline.style';
import { REVIEWS } from './constants';

const ReferAFriendInline = ({ invite, review, campaign, onShare }) => (
    <WrapperStyled>
        <ContainerStyled>
            <ContentLeftStyled>
                <FreeMonthsText />
            </ContentLeftStyled>

            <ContentRightStyled>
                <ShareableLinkBlock
                    inviteCode={invite.code}
                    inviteURL={invite.url}
                    utm={{ campaign }}
                    onShare={onShare}
                />
            </ContentRightStyled>

            <TrustPilotReviewQuoteStyled review={REVIEWS[review]} />
        </ContainerStyled>
    </WrapperStyled>
);

export default ReferAFriendInline;
