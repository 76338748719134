import ApiConfig from '../config';
import ApiAction from './common';

const { account } = ApiConfig.endpoints;

const config = {
    load: ['ACCOUNT', 'LOAD', account._],
    create: ['ACCOUNT', 'CREATE', account._],
    delete: ['ACCOUNT_DELETION_REQUEST', 'CREATE', account.delete],
    loadDetails: ['ACCOUNT_DETAILS', 'LOAD', account.details],
    updateDetails: [
        'ACCOUNT_DETAILS',
        'UPDATE',
        account.details,
        [],
        'PUT',
        null,
        null,
        { seconds: 2 },
    ],
    updateProfilePhoto: [
        'ACCOUNT_PROFILE_PHOTO',
        'UPDATE',
        account.updateProfilePhoto,
        [],
        'PUT',
        null,
        null,
        { seconds: 2 },
    ],
    loadSettings: ['ACCOUNT_SETTINGS', 'LOAD', account.settings],
    updateSettings: [
        'ACCOUNT_SETTINGS',
        'UPDATE',
        account.settings,
        [],
        'PUT',
        null,
        null,
        { seconds: 1 },
    ],
    loadMembershipPlans: ['ACCOUNT_MEMBERSHIP_PLAN', 'LOAD', account.membershipPlan._],
    loadMembership: ['ACCOUNT_MEMBERSHIP_PLAN', 'LOAD', account.membershipPlan._],
    deleteMembershipPlan: [
        'DELETE_MEMBERSHIP_PLAN',
        'REMOVE',
        account.membershipPlan._,
        [],
        'DELETE',
        null,
        null,
        { seconds: 1 },
    ],
    loadIncompleteMembershipPlan: [
        'ACCOUNT_MEMBERSHIP_PLAN_INCOMPLETE',
        'LOAD',
        account.membershipPlan.incomplete,
    ],
    createIncompleteMembershipPlan: [
        'ACCOUNT_MEMBERSHIP_PLAN_INCOMPLETE',
        'CREATE',
        account.membershipPlan.incomplete,
    ],
    updateIncompleteMembershipPlan: [
        'ACCOUNT_MEMBERSHIP_PLAN_INCOMPLETE',
        'UPDATE',
        account.membershipPlan.incomplete,
    ],
    loadSubscribedMembershipPlan: [
        'ACCOUNT_MEMBERSHIP_PLAN_SUBSCRIBED',
        'LOAD',
        account.membershipPlan.subscribed,
    ],
    createSubscription: ['ACCOUNT_SUBSCRIPTION', 'CREATE', account.subscription],
    deleteSubscription: ['ACCOUNT_SUBSCRIPTION', 'REMOVE', account.subscription],
    loadSubscriptionRenewalAmount: [
        'ACCOUNT_SUBSCRIPTION_RENEWAL_AMOUNT',
        'LOAD',
        account.subscriptionRenewalAmount,
    ],
    enableSubscriptionAutorenew: [
        'ACCOUNT_SUBSCRIPTION_AUTORENEW',
        'UPDATE',
        account.subscriptionAutorenew,
    ],
    disableSubscriptionAutorenew: [
        'ACCOUNT_SUBSCRIPTION_AUTORENEW',
        'REMOVE',
        account.subscriptionAutorenew,
    ],
    loadWSToken: ['ACCOUNT_WS_TOKEN', 'LOAD', account.wsToken],
    loadPushNotificationSubscriptions: [
        'ACCOUNT_PUSH_NOTIFICATION_SUBSCRIPTIONS',
        'LOAD',
        account.pushNotificationSubscriptions,
    ],
    updatePushNotificationSubscriptions: [
        'ACCOUNT_PUSH_NOTIFICATION_SUBSCRIPTIONS',
        'UPDATE',
        account.pushNotificationSubscriptions,
    ],
    requestPasswordReset: [
        'ACCOUNT_PASSWORD_REQUEST',
        'CREATE',
        account.forgottenPassword,
        [],
        'POST',
        null,
        null,
        { seconds: 2 },
    ],
    changePassword: [
        'ACCOUNT_CHANGE_PASSWORD',
        'UPDATE',
        account.changePassword,
        [],
        'PUT',
        null,
        null,
        { seconds: 2 },
    ],
    updateApp: ['ACCOUNT_APP', 'UPDATE', account.app, [], 'PUT', null, null, { seconds: 1 }],
    validateEmail: [
        'ACCOUNT_VALIDATE_EMAIL',
        'CREATE',
        account.validateEmail,
        [],
        'POST',
        null,
        null,
        { seconds: 1 },
    ],
    loadCard: ['ACCOUNT_CARD', 'LOAD', account.card],
    createCard: ['ACCOUNT_CARD', 'CREATE', account.card],
    activateSubscription: ['ACCOUNT_SUBSCRIPTION_ACTIVATE', 'CREATE', account.subscriptionActivate],
    loadRefundStatus: ['ACCOUNT_REFUND_STATUS', 'LOAD', account.refunds.status],
    createRefundRequest: ['ACCOUNT_REFUND_REQUEST', 'CREATE', account.refunds.request],
    sendReferralEmail: ['ACCOUNT_RAF_SEND_EMAIL', 'CREATE', account.referAFriend.email],
    // the emergency contact is meant to be the own user cell phone number
    loadEmergencyContact: ['ACCOUNT_EMERGENCY_CONTACT', 'LOAD', account.emergencyContact._],
    createEmergencyContact: ['ACCOUNT_EMERGENCY_CONTACT', 'CREATE', account.emergencyContact._],
    updateEmergencyContact: [
        'ACCOUNT_EMERGENCY_CONTACT',
        'UPDATE',
        account.emergencyContact.update,
    ],
    // the safety contact is meant to be the cell phone number of someone selected and trusted by the user
    loadSafetyContact: ['ACCOUNT_SAFETY_CONTACT', 'LOAD', account.safetyContact],
    createSafetyContact: ['ACCOUNT_SAFETY_CONTACT', 'CREATE', account.safetyContact],
    updateSafetyContact: ['ACCOUNT_SAFETY_CONTACT', 'UPDATE', account.safetyContact],

    emailSubscribe: ['ACCOUNT_EMAIL_SUBSCRIBE', 'CREATE', account.email.subscribe],
    emailUnsubscribe: ['ACCOUNT_EMAIL_UNSUBSCRIBE', 'CREATE', account.email.unsubscribe],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
