// eslint-disable-next-line import/prefer-default-export
export const FlexboxGridJustify = {
    CENTER: 'center',
    START: 'start',
    END: 'end',
    FLEX_START: 'flex-start',
    FLEX_END: 'flex-end',
    LEFT: 'left',
    RIGHT: 'right',
    NORMAL: 'normal',
    SPACE_BETWEEN: 'space-between',
    SPACE_AROUND: 'space-around',
    SPACE_EVENLY: 'space-evenly',
    STRETCH: 'stretch',
};
