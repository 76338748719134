import { CATEGORIES } from 'api/constants/listing/amenities';
import {
    toArray,
    removeEmptyValues,
    populateCategories,
} from 'api/helpers/transform/owner/amenities';

const checkSelectedAmenities = (category) => (amenities) => amenities.includes(category);

const hasFavouritesAmenities = checkSelectedAmenities(CATEGORIES.SITTER_FAVOURITES);
const hasKitchenAmenities = checkSelectedAmenities(CATEGORIES.KITCHEN);
const hasBathroomAmenities = checkSelectedAmenities(CATEGORIES.BATHROOM);
const hasOutdoorAmenities = checkSelectedAmenities(CATEGORIES.OUTDOOR);
const hasSafetyAmenities = checkSelectedAmenities(CATEGORIES.SECURITY);
const hasParkingAmenities = checkSelectedAmenities(CATEGORIES.PARKING_TRANSPORT);
const hasCleaningAmenities = checkSelectedAmenities(CATEGORIES.CLEANING);

// eslint-disable-next-line import/prefer-default-export
export const USER_LISTING_EDIT_HOME = {
    create(listing, amenities = null) {
        const {
            photos = [],
            wifiAvailable = null,
            carIncluded = null,
            homeType = null,
            disabledAccess = null,
            familyFriendly = null,
            mainPhoto = null,
            features = null,
        } = listing || {};

        const amenitiesEventData =
            amenities === null
                ? {}
                : (() => {
                      const array = toArray(removeEmptyValues(amenities));
                      const addedAmenities = populateCategories(array, 'listing-edit').map(
                          (category) => category.property
                      );

                      return {
                          addedAmenitiesFavourites: hasFavouritesAmenities(addedAmenities),
                          addedAmenitiesKitchen: hasKitchenAmenities(addedAmenities),
                          addedAmenitiesBathroom: hasBathroomAmenities(addedAmenities),
                          addedAmenitiesOutdoor: hasOutdoorAmenities(addedAmenities),
                          addedAmenitiesSafety: hasSafetyAmenities(addedAmenities),
                          addedAmenitiesParking: hasParkingAmenities(addedAmenities),
                          addedAmenitiesCleaning: hasCleaningAmenities(addedAmenities),
                      };
                  })();

        return {
            type: 'Listing Edit Home Update',
            payload: {
                photos: photos.length > 0,
                photoCount: photos.length,
                wifiAvailable: wifiAvailable !== null,
                carIncluded: carIncluded !== null,
                homeType: homeType !== null,
                disabledAccess: disabledAccess !== null,
                familyFriendly: familyFriendly !== null,
                mainPhoto: mainPhoto !== null,
                features: features !== null && features.length >= 50,

                ...amenitiesEventData,
            },
        };
    },
};

export const USER_LISTING_OPENED_AMENITIES_ACCORDION = {
    create(item) {
        return {
            type: 'Interaction',
            payload: {
                description: 'listingAmenities',
                name: 'open accordion',
                item,
                location: 'Listing Edit Home Update',
            },
        };
    },
};

export const USER_LISTING_AMENITIES_POPUP_OPENED = {
    create() {
        return {
            type: 'Amenities pop-up triggered',
            payload: {},
        };
    },
};

export const USER_LISTING_AMENITIES_POPUP_CLOSED = {
    create() {
        return {
            type: 'Interaction',
            payload: {
                name: 'button click',
                description: 'Add amenities Closed',
            },
        };
    },
};

export const USER_LISTING_AMENITIES_POPUP_CTA_CLICK = {
    create() {
        return {
            type: 'Interaction',
            payload: {
                name: 'button click',
                description: 'Add amenities CTA',
            },
        };
    },
};

export const USER_LISTING_PUBLIC_TRANSPORT_MODAL_CLOSED = {
    create() {
        return {
            type: 'Interaction',
            payload: {
                name: 'button click',
                description: 'Check Later Public Transport Change',
            },
        };
    },
};

export const USER_LISTING_PUBLIC_TRANSPORT_MODAL_CLICK = {
    create() {
        return {
            type: 'Interaction',
            payload: {
                name: 'button click',
                description: 'Check Now Public Transport Change',
            },
        };
    },
};

export const USER_LISTING_PUBLIC_TRANSPORT_MODAL_OPENED = {
    create() {
        return {
            type: 'Viewed modal public transport change',
            payload: {},
        };
    },
};
