import React from 'react';
import { TooltipDirection } from './Tooltip.constants';
import {
    TooltipWrapper,
    TooltipBox,
    TooltipHeading,
    TooltipDivider,
    TooltipBody,
} from './Tooltip.style';

export const TooltipTestIds = {
    wrapper: 'Tooltip_wrapper',
    box: 'Tooltip_box',
    heading: 'Tooltip_heading',
    divider: 'Tooltip_divider',
    body: 'Tooltip_body',
};

const Tooltip = ({ className, children, heading, body, direction = TooltipDirection.BOTTOM }) => (
    <TooltipWrapper data-testid={TooltipTestIds.wrapper}>
        {children}
        <TooltipBox className={className} direction={direction} data-testid={TooltipTestIds.box}>
            {heading && (
                <>
                    <TooltipHeading data-testid={TooltipTestIds.heading}>{heading}</TooltipHeading>
                    <TooltipDivider data-testid={TooltipTestIds.divider} />
                </>
            )}
            <TooltipBody data-testid={TooltipTestIds.body}>{body}</TooltipBody>
        </TooltipBox>
    </TooltipWrapper>
);

export default Tooltip;
