import optimizely from 'utils/integrations/optimizely';
import { Events, track } from 'utils/analytics';
import { getAppWebViewType, isControlSplit, isBotSplit } from 'utils/utils';
import { getMembershipStatus, getMembershipTier, getMembershipType } from 'utils/account';
import { MembershipStatus } from 'api/types';
import experiments from './experiments';
import { getTestSplitVariation } from './experimentLib';

// The user id for anonymous users
export const ANONYMOUS_USER_ID = 'NA';

export const VariationTypes = {
    CONTROL: 'control',
    VARIATION1: 'variation1',
    VARIATION2: 'variation2',
};

// this returns either true or false if the user has one of the parameters in the filterCombo
// e.g. filterCombo = [['homeowner', 'member', 'standard'], 'premium']
// (this means that it should return true only for standard paid owners and premium users)
// then the user states are passed down
// e.g. membershipType = 'combined', membershipStatus = 'member', membershipTier = 'standard'
// because the user doesn't have a matching filter it would return false
// unless one of the states were different such as membershipTier = 'premium'
// it would then return true
export const userMatchesFilter = (filterCombo, membershipPlan) => {
    const membershipStatus = getMembershipStatus(membershipPlan);
    const membershipType = getMembershipType(membershipPlan);
    const membershipTier = getMembershipTier(membershipPlan);

    if (!filterCombo || filterCombo.length === 0) {
        return false;
    }

    const appliedFilters = filterCombo.filter((filterUser) => {
        const isAnonymous = filterUser === MembershipStatus.ANONYMOUS && !membershipType;
        const hasStatus = filterUser === membershipStatus;
        const hasType = filterUser === membershipType;
        const hasTier = filterUser === membershipTier;
        const hasComboOfFilters = Array.isArray(filterUser);
        const hasStatusAndType =
            hasComboOfFilters &&
            filterUser.includes(membershipStatus) &&
            filterUser.includes(membershipType);
        const hasStatusAndTier =
            hasComboOfFilters &&
            filterUser.includes(membershipStatus) &&
            filterUser.includes(membershipTier);
        const hasTypeAndTier =
            hasComboOfFilters &&
            filterUser.includes(membershipType) &&
            filterUser.includes(membershipTier);
        const hasStatusTypeAndTier =
            hasComboOfFilters &&
            filterUser.includes(membershipStatus) &&
            filterUser.includes(membershipType) &&
            filterUser.includes(membershipTier);

        if (isAnonymous) return true;
        if (hasStatus) return true;
        if (hasType) return true;
        if (hasTier) return true;

        if (hasStatusAndType) return true;
        if (hasStatusAndTier) return true;
        if (hasTypeAndTier) return true;
        if (hasStatusTypeAndTier) return true;

        return false;
    });
    return appliedFilters.length > 0;
};

// Is the given test enabled for the given user + attributes combo?
const experimentIsEnabled = (name, userId = ANONYMOUS_USER_ID, attributes = {}) =>
    optimizely.getClient().isFeatureEnabled(name, userId, attributes);

// If this experiment is enabled for the given user + attributes combo has it been rolled out to 100% variation1?
const experimentIsRolledOutTo100PercentVariation1 = (
    name,
    userId = ANONYMOUS_USER_ID,
    attributes = {}
) => {
    const enabled = experimentIsEnabled(name, userId, attributes);

    // Certain "audiences" can be applied in Optimizely to force a variation for all users.
    // The only way we can tell if an "audience" is applied is to see if it disables the test
    return (
        enabled &&
        !experimentIsEnabled(name, userId, {
            ...attributes,
            variation: 'variation1',
        })
    );
};

// If this experiment is enabled for the given user + attributes combo has it been rolled out to 100% variation2?
const experimentIsRolledOutTo100PercentVariation2 = (
    name,
    userId = ANONYMOUS_USER_ID,
    attributes = {}
) => {
    const enabled = experimentIsEnabled(name, userId, attributes);

    // Certain "audiences" can be applied in Optimizely to force a variation for all users.
    // The only way we can tell if an "audience" is applied is to see if it disables the test
    return (
        enabled &&
        !experimentIsEnabled(name, userId, {
            ...attributes,
            variation: 'variation2',
        })
    );
};

// Go through the rules listed here https://docs.google.com/spreadsheets/d/11qz6AddUN0vUoSwtUg2uPeftvBGpI409DC--ljvEUpI/edit?usp=sharing
// and return the correct experiment state (enabled, variation and full rollout)
export const getExperimentalFeatureVariation = ({
    experiment,
    userId = ANONYMOUS_USER_ID,
    attributes,
    split,
    membershipPlan,
    excludeCombo = null,
    showVariationToUsers = null,
    debug = false,
    utmSource = '',
    excludeUTMSources = [],
    hasPaidTestConflict = false,
}) => {
    // Rules are tested in order - first match wins and decides what is shown
    let result = null;
    let rule = null;

    // Rule 0: UTMSource is in excludeUTMSources array
    if (utmSource && excludeUTMSources.includes(utmSource)) {
        result = { enabled: false, variation: VariationTypes.CONTROL, fullRollout: false };
        rule = 0;
    }
    // Rule 1: Test is off in Optimizely
    // Optimizely feature rollout slider set to 0%
    else if (!experimentIsEnabled(experiment.name, userId, attributes)) {
        result = { enabled: false, variation: VariationTypes.CONTROL, fullRollout: false };
        rule = 1;
    }

    // Rule 2: "split_v2" cookie set to "control"
    // This is what we do in Mabl
    else if (isControlSplit(split)) {
        result = { enabled: false, variation: VariationTypes.CONTROL, fullRollout: false };
        rule = 2;
    }

    // Rule 3: User matches "excludeCombo" or has a paid test conflict
    // We use this when a certain user type needs to see control instead of taking part in the test
    // TODO: This functionality could be folded in to `showVariationToUsers`
    else if (
        (excludeCombo && userMatchesFilter(excludeCombo, membershipPlan)) ||
        hasPaidTestConflict
    ) {
        result = { enabled: false, variation: VariationTypes.CONTROL, fullRollout: false };
        rule = 3;
    }

    // Rule 4: User matches "showVariationToUsers.variation1"
    // We use this when a certain user type needs to see variation1 instead of taking part in the test
    else if (
        showVariationToUsers &&
        showVariationToUsers.variation1 &&
        userMatchesFilter(showVariationToUsers.variation1, membershipPlan)
    ) {
        result = { enabled: false, variation: VariationTypes.VARIATION1, fullRollout: false };
        rule = 4;
    }

    // Rule 5: User matches "showVariationToUsers.variation2"
    // We use this when a certain user type needs to see variation2 instead of taking part in the test
    else if (
        showVariationToUsers &&
        showVariationToUsers.variation2 &&
        userMatchesFilter(showVariationToUsers.variation2, membershipPlan)
    ) {
        result = { enabled: false, variation: VariationTypes.VARIATION2, fullRollout: false };
        rule = 5;
    }

    // Rule 6: Roll out variation 1 to 100%
    // Optimizely feature "100% Variation 1" audience applied 100% on the slider
    else if (experimentIsRolledOutTo100PercentVariation1(experiment.name, userId, attributes)) {
        result = { enabled: true, variation: VariationTypes.VARIATION1, fullRollout: true };
        rule = 6;
    }

    // Rule 7: Roll out variation 2 to 100%
    // Optimizely feature "100% Variation 2" audience applied 100% on the slider
    else if (experimentIsRolledOutTo100PercentVariation2(experiment.name, userId, attributes)) {
        result = { enabled: true, variation: VariationTypes.VARIATION2, fullRollout: true };
        rule = 7;
    }

    // Rule 8: User is in the app web view or a bot has been detected
    // This is the last rule before we let the "split_v2" cookie decide because all the other
    // rules should apply to the app web view
    else if (getAppWebViewType(split) || isBotSplit(split)) {
        result = { enabled: false, variation: VariationTypes.CONTROL, fullRollout: false };
        rule = 8;
    }

    // Rule 9: Determine the variation based on the "split_v2" cookie
    // Finally if the user did not match any of the above rules we determine the experiment
    // state based on the split value
    else if (split) {
        const testSplitVariations = getTestSplitVariation(experiment);
        const variation = testSplitVariations[split];

        result = { enabled: true, variation, fullRollout: false };
        rule = 9;
    }

    // Rule 10: No cookie fallback
    // Just in case
    else {
        result = { enabled: false, variation: VariationTypes.CONTROL, fullRollout: false };
        rule = 10;
    }

    if (debug) {
        const debugMessage = `Experiment ${
            experiment.name
        } using rule ${rule} is returning ${JSON.stringify(result)} for split ${split}`;

        if (typeof debug === 'function') {
            debug(debugMessage);
        } else {
            // eslint-disable-next-line no-console
            console.log(debugMessage);
        }
    }

    return result;
};

export const trackExperimentalFeature = (name, variation, description) => {
    track(Events.VIEWED_EXPERIMENTAL_FEATURE.create(name, variation, description));
};

export const addDebugTools = () => {
    global.THS = global.THS || {};
    global.THS.experiments = {
        getTestSplitVariation: (experimentName) => {
            const selectedExperiment = Object.values(experiments).find(
                (experiment) => experiment.name === experimentName
            );
            // eslint-disable-next-line no-console
            console.table(getTestSplitVariation(selectedExperiment));
        },
    };
};

export const hasPaidTestConflict = () => {};
