import styled from 'styled-components';
import { h3Styles, h4Styles, pStyles } from 'typography';

const WorkableWidgetStyled = styled.div`
    /* Customisable options for Workable widget as described: https://help.workable.com/hc/en-us/articles/115012801727-How-to-embed-jobs-on-your-website */

    /* Remove created date */
    .whr-date {
        display: none;
    }

    /* Remove job code */
    .whr-code {
        display: none;
    }

    /* Styles for department sections */
    .whr-group {
        ${h4Styles};
        margin-top: ${({ theme }) => theme.spacing[4]};

        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            ${h3Styles};
            margin-top: ${({ theme }) => theme.spacing[6]};
        }
    }

    /* Style for roles in department */
    .whr-items {
        padding: 0;
        list-style-type: none;
    }

    /* Styles for individual role */
    .whr-item {
        padding-bottom: ${({ theme }) => theme.spacing[1]};
        border-bottom: 1px solid ${({ theme }) => theme.palette.borderLight};

        &:not(:first-of-type) {
            margin-top: ${({ theme }) => theme.spacing[1]};
        }

        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            display: flex;
            justify-content: space-between;
        }
    }

    /* Styles for role title */
    .whr-title {
        ${pStyles};
        margin: 0;

        a {
            color: ${({ theme }) => theme.palette.link};
            text-decoration: underline;
            cursor: pointer;
            font-weight: bold;

            &:hover:enabled {
                text-decoration: underline;
            }
        }
    }

    /* Styles for role info */
    .whr-info {
        padding: 0;
        color: ${({ theme }) => theme.palette.textLight};
        list-style-type: none;

        .whr-location {
            span {
                display: none;
            }
        }
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { WorkableWidgetStyled };
