import { settings as actions } from '../../actions';
export const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadPets.SUCCESS:
            return action.data;
        case actions.sitter.createPet.SUCCESS:
            return [...state, action.data];
        case actions.sitter.updatePet.SUCCESS:
            return state.map((pet) => pet.id === action.data.id ? action.data : pet)
        case actions.sitter.removePet.SUCCESS:
            return state.filter((pet) => pet.id !== action.requestData.pet_id);
        default:
            return state;
    }
};
