import styled, { css } from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import { IconInfo } from 'components/Icon';
import { Button, ButtonIcon } from 'components/buttons';

export const ZendeskButtonWrapperStyled = styled.div(
    ({
        hideMobilePage,
        hideBelowMediumDesktop,
        hideAllScreensPage,
        theme: {
            screenSize: { largeTablet, mediumDesktop },
        },
    }) => css`
        display: ${hideMobilePage || hideBelowMediumDesktop || hideAllScreensPage
            ? 'none'
            : 'block'};

        // Show it for 1024px on inbox / RAF, and 800px for pages with hideMobilePage. Pages with
        // hideAllScreensPage will keep it hidden on all screen sizes.
        @media screen and (min-width: ${hideBelowMediumDesktop ? mediumDesktop : largeTablet}px) {
            display: ${hideAllScreensPage ? 'none' : 'block'};
        }
    `
);

export const ZendeskButtonStyled = styled(ButtonIcon).attrs({
    variant: Button.Variant.PRIMARY,
    icon: IconInfo,
    iconProps: { palette: 'highlight', size: 'medLarge' },
})(
    ({ theme: { spacing, fontWeights, screenSize }, isHidden }) => css`
        display: ${isHidden ? 'none' : 'flex'};
        position: fixed;
        z-index: ${zIndex};
        bottom: ${spacing[2]};
        right: ${spacing[3]};
        padding: ${spacing[2]};
        border-style: none;
        font-weight: ${fontWeights.bold};

        &:disabled {
            opacity: 1;
        }

        span:nth-child(2) {
            padding-left: ${spacing[1]};
        }

        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            bottom: ${spacing[2.5]};
            right: ${spacing[5]};
        }
    `
);
