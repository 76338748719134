import { mix } from 'polished';
import { theme } from 'theme';

// Hover states
const hoverLightStrength = theme.opacityOverlay.light.hover.strength;
const hoverLightColor = theme.opacityOverlay.light.hover.color;
const hoverDarkStrength = theme.opacityOverlay.dark.hover.strength;
const hoverDarkColor = theme.opacityOverlay.dark.hover.color;

// Active states
const activeLightStrength = theme.opacityOverlay.light.active.strength;
const activeLightColor = theme.opacityOverlay.light.active.color;
const activeDarkStrength = theme.opacityOverlay.dark.active.strength;
const activeDarkColor = theme.opacityOverlay.dark.active.color;

/**
 * Hover mixin for light color
 * @param baseColor
 * @returns {*}
 */
export const lightHover = (baseColor) => mix(hoverLightStrength, hoverLightColor, baseColor);

/**
 * Hover mixin for light color
 * @param baseColor
 * @returns {*}
 */
export const lightActive = (baseColor) => mix(activeLightStrength, activeLightColor, baseColor);

/**
 * Hover mixin for light color
 * @param baseColor
 * @returns {*}
 */
export const lightHoverReversed = (baseColor) =>
    mix(hoverLightStrength, hoverLightColor, baseColor);

/**
 * Hover mixin for light color
 * @param baseColor
 * @returns {*}
 */
export const lightActiveReversed = (baseColor) =>
    mix(activeLightStrength, activeLightColor, baseColor);

/**
 * Hover mixin for light color
 * @param baseColor
 * @returns {*}
 */
export const darkHover = (baseColor) => mix(hoverDarkStrength, hoverDarkColor, baseColor);

/**
 * Hover mixin for light color
 * @param baseColor
 * @returns {*}
 */
export const darkActive = (baseColor) => mix(activeDarkStrength, activeDarkColor, baseColor);

/**
 * Hover mixin for light color
 * @param baseColor
 * @returns {*}
 */
export const darkHoverReversed = (baseColor) => mix(hoverDarkStrength, hoverDarkColor, baseColor);

/**
 * Hover mixin for light color
 * @param baseColor
 * @returns {*}
 */
export const darkActiveReversed = (baseColor) =>
    mix(activeDarkStrength, activeDarkColor, baseColor);
