import { getHasThePrefix } from 'api/helpers/format/location';

const formatLocationThePrefix = (value, capitalise) => {
    let formattedValue = '';
    const hasThePrefix = getHasThePrefix(value);
    if (hasThePrefix && capitalise) {
        formattedValue = 'The ';
    } else if (hasThePrefix && !capitalise) {
        formattedValue = 'the ';
    }
    return formattedValue;
};

/**
 * Returns the possessive form of a name
 * @param {string} name
 * @returns {string} the person's name
 */

// eslint-disable-next-line import/prefer-default-export
export { formatLocationThePrefix };
