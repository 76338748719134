import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@ths/design-system';
import LoginLinkWrapper from 'components/LoginLinkWrapper';
import Link from 'components/Link';
import { routes } from 'utils/routes';

const AlreadyHaveAccount = () => {
    const { t } = useTranslation();
    const { requestClose } = useModal();

    return (
        <p className="m-0 flex justify-center gap-2 p-0">
            <span>{t('components_regwall_simpler_login_message')}</span>

            <LoginLinkWrapper>
                {(onClick) => (
                    <Link
                        to={routes.login()}
                        onClick={() => {
                            onClick();
                            requestClose();
                        }}
                    >
                        {t('components_regwall_simpler_login')}
                    </Link>
                )}
            </LoginLinkWrapper>
        </p>
    );
};

export default AlreadyHaveAccount;
