import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { getCanonical } from 'utils/content';
import { Helmet } from 'react-helmet';
import CookiePolicy from 'containers/CookiePolicy';
import { CardExpiryNotification } from 'components/notifications';
import ZIndexManager from 'components/ZIndexManager';
import Feature, { features } from 'components/Feature';
import asPage from 'containers/Page';
import { getMembershipStatus, isPartial, getIsMembershipActive, isSitterOnly } from 'utils/account';
import { MembershipStatus } from 'api/types';
import { getAccountCurrentMembershipPlan, getAccountSettings } from 'api/selectors/account';
import {
    getIsLoggedIn,
    getSeoContent,
    isViewingInAppWebView,
    shouldHideNavbarOnPage,
} from 'shared/selectors';
import GlobalRegwall from 'containers/GlobalRegwall';
import SupportWidget from 'components/SupportWidget';
import ZendeskWidget from 'components/ZendeskWidget';
import UpgradeModal from 'containers/UpgradeModal';
import FreeTrialEndModal from 'containers/FreeTrialEndModal';
import { getExperimentalFeatureVariationSelector } from 'containers/ExperimentalFeature/selectors';
import { experiments, VariationTypes } from 'containers/ExperimentalFeature';
import { retry } from 'utils/ssr';
import HandleAddSmartAppBanner from 'components/HandleAddSmartAppBanner';
import { getShowOutsideSubscriptionModal } from 'containers/OutsideSubscriptionModal/selectors';
import { Main, StickyWrapper } from './App.style';
import { getPagesLoading, getNavbarOptions, getFooterOptions } from './selectors';

const LoadableLinksCarousel = loadable(() =>
    retry(() => import('components/content/LinksCarousel'))
);
const LoadableSeoContent = loadable(() => retry(() => import('./SeoContent')));
const LoadableNav = loadable(() => retry(() => import('containers/Nav')));
const LoadableFooter = loadable(() => retry(() => import('components/Footer')));
const LoadableReferAFriendNudgewall = loadable(() =>
    retry(() => import('containers/ReferAFriendNudgewall'))
);
const LoadableCodeOfConduct = loadable(() => retry(() => import('containers/CodeOfConduct')));
const LoadableLeaveReviewModal = loadable(() =>
    retry(() => import('containers/LeaveReviewAndFeedbackModal/LeaveReviewModal'))
);
const LoadableLeaveFeedbackModal = loadable(() =>
    retry(() => import('containers/LeaveReviewAndFeedbackModal/LeaveFeedbackModal'))
);
const LoadableOutsideSubscriptionModal = loadable(() =>
    retry(() => import('containers/OutsideSubscriptionModal'))
);

class App extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { showFooter: true };

        const { history } = this.props;

        this.historyUnlisten = history.listen(() => {
            this.setState({ showFooter: false });
        });
    }

    componentDidUpdate(prevProps) {
        const { pagesLoading, location } = this.props;
        const { showFooter } = this.state;
        // Show the footer if no async operations in progress and currently hidden
        if (pagesLoading === 0 && !showFooter) {
            setTimeout(() => this.setState({ showFooter: true }), 2000);
        }

        // scroll to the top on each page change
        if (location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        // Stop listening on history
        this.historyUnlisten();
    }

    showCodeOfConduct() {
        const pathsToShowOn = ['^/user/', '^/accounts/profile/'];
        const pathsToExcludeFrom = [
            '^/login/',
            '^/reset-password/',
            '^/verify-email/',
            '^/provide-reference/',
        ];
        const regexPathsToShowOn = new RegExp(`${pathsToShowOn.join('|')}`);
        const regexPathsToExcludeFrom = new RegExp(`${pathsToExcludeFrom.join('|')}`);

        // We show the code of conduct for all paid, logged in members
        const {
            isLoggedIn,
            isPartial: isPartialProp,
            location,
            hasAcceptedCodeOfConduct,
        } = this.props;
        if (!isLoggedIn || isPartialProp || regexPathsToExcludeFrom.test(location.pathname)) {
            return false;
        }

        const correctPath = regexPathsToShowOn.test(location.pathname);

        return correctPath && !hasAcceptedCodeOfConduct;
    }

    render() {
        const {
            route,
            location,
            seoContent,
            staticContext = {},
            membershipStatus,
            isInAppWebView,
            shouldHideNavOnPage,
            navbarOptions,
            footerOptions,
            isOutsideSubscriptionModalOpen,
        } = this.props;
        const { showFooter } = this.state;
        const { forceHideFooter } = this.props;
        const canonicalUrl = seoContent.hasCanonicalUrl
            ? seoContent.canonicalUrl
            : getCanonical(location.pathname);

        return (
            <>
                <Helmet>
                    {seoContent.hasTitle ? <title>{seoContent.metaTitle}</title> : null}
                    {seoContent.hasDescription ? (
                        <meta name="description" content={seoContent.metaDescription} />
                    ) : null}
                    {seoContent.hasRobots && <meta name="robots" content={seoContent.metaRobots} />}
                    {seoContent.hasKeywords && (
                        <meta name="keywords" content={seoContent.metaKeywords} />
                    )}
                    <link rel="canonical" href={canonicalUrl} />
                </Helmet>
                {!isInAppWebView && !shouldHideNavOnPage && (
                    <>
                        <ZIndexManager layer="lightbox">
                            <StickyWrapper>
                                <CardExpiryNotification />
                                <CookiePolicy />
                                {!navbarOptions.hideNavbar && (
                                    <LoadableNav options={navbarOptions} />
                                )}
                            </StickyWrapper>
                        </ZIndexManager>
                        {this.showCodeOfConduct() && <LoadableCodeOfConduct isOpen />}
                    </>
                )}
                <GlobalRegwall />
                <UpgradeModal />
                <FreeTrialEndModal />
                {isOutsideSubscriptionModalOpen && <LoadableOutsideSubscriptionModal />}
                <Main>
                    {/* child routes won't render without this */}
                    {renderRoutes(route.routes)}
                </Main>
                {showFooter && !forceHideFooter && (
                    <>
                        {seoContent.hasBody ? (
                            <LoadableSeoContent slices={seoContent.body} />
                        ) : null}
                        {seoContent.hasLinks ? (
                            <LoadableLinksCarousel links={seoContent.links} />
                        ) : null}
                        {!isInAppWebView && !footerOptions.hideFooter && (
                            <LoadableFooter options={footerOptions} />
                        )}
                    </>
                )}
                {membershipStatus === MembershipStatus.MEMBER && <LoadableReferAFriendNudgewall />}
                {membershipStatus === MembershipStatus.MEMBER && <LoadableLeaveFeedbackModal />}
                {membershipStatus === MembershipStatus.MEMBER && <LoadableLeaveReviewModal />}

                {!isInAppWebView && (
                    <Feature
                        name={features.SUPPORT_WIDGET}
                        on={<SupportWidget pathname={location.pathname} />}
                        off={<ZendeskWidget pathname={location.pathname} />}
                    />
                )}

                <HandleAddSmartAppBanner />
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const membershipPlan = getAccountCurrentMembershipPlan(state);

    const { variation: useInfiniteScrollTestVariation, enabled: useInfiniteScrollTestEnabled } =
        getExperimentalFeatureVariationSelector(state, {
            experiment: experiments.USE_INFINITE_SCROLL_LISTINGS,
        });

    const isUseInfiniteScrollVariation =
        useInfiniteScrollTestVariation === VariationTypes.VARIATION1;

    const currentMembershipPlan = getAccountCurrentMembershipPlan(state);
    const isPaidMember = getIsMembershipActive(currentMembershipPlan);

    const isOutsideSubscriptionModalOpen = getShowOutsideSubscriptionModal(state);

    const useInfiniteScroll =
        useInfiniteScrollTestEnabled &&
        isUseInfiniteScrollVariation &&
        isPaidMember &&
        isSitterOnly(currentMembershipPlan);

    const isListingsSearchPage = props.location?.pathname?.startsWith(
        '/house-and-pet-sitting-assignments'
    );

    return {
        seoContent: getSeoContent(state),
        pagesLoading: getPagesLoading(state),
        isLoggedIn: getIsLoggedIn(state),
        membershipStatus: getMembershipStatus(membershipPlan),
        isPartial: isPartial(membershipPlan),
        hasAcceptedCodeOfConduct:
            getAccountSettings(state) && getAccountSettings(state).hasAcceptedCodeOfConduct,
        isInAppWebView: isViewingInAppWebView(state),
        shouldHideNavOnPage: shouldHideNavbarOnPage(props.location?.pathname),
        navbarOptions: getNavbarOptions(state),
        footerOptions: getFooterOptions(state),
        forceHideFooter: isListingsSearchPage && useInfiniteScroll,
        isOutsideSubscriptionModalOpen,
    };
};

export default withRouter(connect(mapStateToProps)(asPage(App, 'App', true, false)));
