/* eslint-disable import/prefer-default-export */

import { put, take, all, call, takeEvery } from 'redux-saga/effects';

import apiActions, { settings } from 'api/actions';

import {
    preload as pageActionsPreload,
    load as pageActionsLoad,
    error as errorAction,
    preloaded as pageActionsPreloaded,
    loaded as pageActionsLoaded,
} from 'containers/Page/actions';
import { loadEmergencyContact, loadSafetyContact } from 'containers/SafetySecurityPage/sagas';

import { PAGE_ID } from './Hub.constants';

export function* loadHubData(params) {
    const { listingId } = params;

    // Load Listing
    yield put(
        apiActions.owner.loadListing({
            forceReload: true,
            data: {
                id: listingId,
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadListing.DONE
    );

    if (status !== settings.owner.loadListing.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    // Load Address, Pets & Open Assignment data
    yield all([
        put(
            apiActions.owner.loadListingAmenities({
                forceReload: true,
                data: {
                    listingId,
                },
            })
        ),
        put(
            apiActions.owner.loadListingAddress({
                forceReload: true,
                data: {
                    listingId: params.listingId,
                },
            })
        ),
        put(
            apiActions.owner.loadListingPets({
                forceReload: true,
                data: {
                    id: listingId,
                    listingId,
                },
            })
        ),
        put(
            apiActions.owner.loadListingOpenAssignments({
                forceReload: true,
                data: {
                    id: listingId,
                    listingId,
                },
            })
        ),
        put(
            apiActions.owner.loadConfirmedAssignments({
                forceReload: true,
                data: {
                    id: listingId,
                    listingId,
                },
            })
        ),
    ]);

    const [
        amenitiesResponse,
        addressResponse,
        petsResponse,
        openAssignmentsResponse,
        confirmedAssignmentsResponse,
    ] = yield all([
        take((res) => res.type === settings.owner.loadListingAmenities.DONE),
        take((res) => res.type === settings.owner.loadListingAddress.DONE),
        take((res) => res.type === settings.owner.loadListingPets.DONE),
        take((res) => res.type === settings.owner.loadListingOpenAssignments.DONE),
        take((res) => res.type === settings.owner.loadConfirmedAssignments.DONE),
    ]);

    if (amenitiesResponse.statusCode !== 200 && amenitiesResponse.statusCode !== 404) {
        yield put(errorAction.create(PAGE_ID, amenitiesResponse.statusCode));
        return false;
    }

    // Wait for address response
    const { statusCode: loadAddressStatusCode } = addressResponse;

    // The address endpoint returns a 404 if the user has not saved an address.
    if (loadAddressStatusCode !== 200 && loadAddressStatusCode !== 404) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, loadAddressStatusCode));
        return false;
    }

    // Wait for pets results
    const { status: petsStatus, statusCode: petsStatusCode } = petsResponse;

    if (petsStatus !== settings.owner.loadListingPets.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, petsStatusCode));
        return false;
    }

    // Wait for open assignments results
    const { status: assignmentStatus, statusCode: assignmentStatusCode } = openAssignmentsResponse;

    if (assignmentStatus !== settings.owner.loadListingOpenAssignments.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, assignmentStatusCode));
        return false;
    }

    // Wait for confirmed assignments results
    const { status: confirmedAssignmentStatus, statusCode: confirmedAssignmentStatusCode } =
        confirmedAssignmentsResponse;

    if (confirmedAssignmentStatus !== settings.owner.loadConfirmedAssignments.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, confirmedAssignmentStatusCode));
        return false;
    }

    // Load Safety & Security data
    yield all([call(loadEmergencyContact), call(loadSafetyContact)]);
    // we don't want to break the page if the safety and security data fails to load
    // so we're just finishing the saga

    return true;
}

export function* preload(action) {
    yield call(loadHubData, action.params);
    yield put(pageActionsPreloaded.create(PAGE_ID));
}

export function* load(action) {
    yield call(loadHubData, action.params);
    yield put(pageActionsLoaded.create(PAGE_ID));
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
    ]);
}
