import { pathnameToPagenameMap, pathnameRegex } from './pageNameData';

const getPagename = ({ pathname, search }) => {
    // If we have a direct string match on the pathname just return that
    let pagename = pathnameToPagenameMap[pathname];
    if (pagename) return pagename;

    const pathWithParams = `${pathname}${search}`;
    // else work through each of the regex we have and check for a match on the path + search params
    pathnameRegex.some(([regex, name]) => {
        const matches = pathWithParams.match(regex);
        if (matches) {
            pagename = name;
            return true;
        }
        return false;
    });

    return pagename;
};

export default getPagename;
