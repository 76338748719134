export const MembershipStatus = {
    ANONYMOUS: 'anonymous',
    PARTIAL: 'partial',
    MEMBER: 'member',
    EXPIRED: 'expired',
};

export const MembershipPaidStatus = {
    PAID: 'paid',
    NOT_PAID: 'not paid',
    EXPIRED: 'expired',
}

export const MembershipType = {
    OWNER: 'homeowner',
    SITTER: 'housesitter',
    COMBINED: 'combined',
};

// Copied from angular/src/app/accounts/components/constants.js
// with additions for tiers
export const MembershipState = {
    NEW_HOMEOWNER: 'new_homeowner',
    NEW_HOUSESITTER: 'new_housesitter',
    NEW_COMBINED: 'new_combined',

    EXPIRED_HOMEOWNER: 'expired_homeowner',
    EXPIRED_HOUSESITTER: 'expired_housesitter',
    EXPIRED_COMBINED: 'expired_combined',

    RENEW_HOMEOWNER: 'renew_homeowner',
    RENEW_HOUSESITTER: 'renew_housesitter',
    RENEW_COMBINED: 'renew_combined',

    UPGRADE_FROM_HOMEOWNER: 'upgrade_from_homeowner',
    UPGRADE_FROM_HOUSESITTER: 'upgrade_from_housesitter',

    DOWNGRADE_TO_HOMEOWNER: 'downgrade_to_homeowner',
    DOWNGRADE_TO_HOUSESITTER: 'downgrade_to_housesitter',

    UPGRADE_FROM_TRANSACTIONAL_HOMEOWNER: 'upgrade_from_transactional_homeowner',

    TRIAL_HOMEOWNER: 'trial_homeowner',
    TRIAL_HOUSESITTER: 'trial_housesitter',
    TRIAL_COMBINED: 'trial_combined',

    // New states to support tiers
    UPGRADE_FROM_CLASSIC: 'upgrade_from_classic',
    UPGRADE_FROM_TIER: 'upgrade_from_tier',
    DOWNGRADE_CATCH_ALL: 'downgrade_catch_all',
};

// Copied from angular/src/app/accounts/components/constants.js
export const MembershipStateClass = {
    NEW: 'new',
    EXPIRED: 'expired',
    RENEW: 'renew',
    UPGRADE: 'upgrade',
    DOWNGRADE: 'downgrade',
    UPGRADE_TRANSACTIONAL: 'upgrade_transactional',
    TRIAL: 'trial',
};
