import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { getVerificationReferences } from 'api/selectors';
import { getSitterProfile } from 'api/selectors/sitter';
import { getAccountSettings } from 'api/selectors/account';
import { isViewingInAppWebView } from 'shared/selectors';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { verificationsApi } from 'api/query/core/verifications';
import { PAGE_ID } from './References.constants';

const mapStateToProps = (state) => ({
    referenceStatuses: getVerificationReferences(state),
    countryCode: getAccountSettings(state).country,
    profile: getSitterProfile(state),
    isViewingInAppWebView: isViewingInAppWebView(state),
    accountEmail: getAccountSettings(state).email,
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

const References = loadable(() => retry(() => import('./References')));

export default connect(
    mapStateToProps,
    null
)(
    asPage(withTranslation()(References), PAGE_ID, false, false, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
        serverSideQuery: ({ store }) => {
            store.dispatch(verificationsApi.endpoints.getVerificationsReferences.initiate({}));
        },
    })
);
