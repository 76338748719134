const constants = {
    simpleTypesIterable: [
        'object',
        'boolean',
        'function',
        'number',
        'string',
        'undefined',
    ],
    simpleTypes: {
        object: 'object',
        boolean: 'boolean',
        function: 'function',
        number: 'number',
        string: 'string',
        undefined: 'undefined',
    },
};

export default constants;
