import { settings as actions } from '../../actions';

export const initialState = { results: [] };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadListingFutureAssignments.SUCCESS: {
          return {
              ...state,
              results: action.data.map((assignment) => assignment.id),
          };
        }
        default:
            return state;
    }
};
