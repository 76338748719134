import * as common from './common';
import * as account from './account';
import * as medias from './medias';
import * as messages from './messages';
import * as owner from './owner';
import * as search from './search';
import * as session from './session';
import * as sitter from './sitter';
import * as notifications from './notifications';

export default {
    common: {
        pagination: common.pagination,
        baseMediaProps: common.baseMediaProps,
        baseMediaPhoto: common.baseMediaPhoto,
        baseMediaVideo: common.baseMediaVideo,
        baseProfile: common.baseProfile,
        baseListing: common.baseListing,
        baseMessage: common.baseMessage,
        baseAssignment: common.baseAssignmentProps,
        baseApplication: common.baseApplication,
        baseReminder: common.baseReminder,
        baseLocation: common.baseLocation,
        baseConversationProps: common.baseConversationProps,
        baseConversation: common.baseConversation,
        baseNotification: common.baseNotification,
        sitterPastAssignmentProps: common.sitterPastAssignmentProps,
        sitterPastAssignment: common.sitterPastAssignment,
        baseReferenceProps: common.baseReferenceProps,
        baseReference: common.baseReference,
    },
    account: {
        settingsProps: account.settingsProps,
        settings: account.settings,
        membershipProps: account.membershipProps,
        membership: account.membership,
        accountProps: account.accountProps,
        account: account.account,
    },
    medias: {
        media: medias.media,
        medias: medias.medias,
    },
    messages: {
        archived: messages.archived,
        messageGenericProps: messages.messageGenericProps,
        messageGeneric: messages.messageGeneric,
        messageProps: messages.messageProps,
        message: messages.message,
        messagePhotoProps: messages.messagePhotoProps,
        messagePhoto: messages.messagePhoto,
        messageVideoProps: messages.messageVideoProps,
        messageVideo: messages.messageVideo,
        messageAssignmentApplicationProps: messages.messageAssignmentApplicationProps,
        messageAssignmentApplication: messages.messageAssignmentApplication,
        messageAssignmentDateChangeProps: messages.messageAssignmentDateChangeProps,
        messageAssignmentDateChange: messages.messageAssignmentDateChange,
        messageAssignmentInvitationProps: messages.messageAssignmentInvitationProps,
        messageAssignmentInvitation: messages.messageAssignmentInvitation,
        messageAssignmentDateChangeDeclinedProps: messages.messageAssignmentDateChangeDeclinedProps,
        messageAssignmentDateDeclinedChange: messages.messageAssignmentDateDeclinedChange,
        messageAssignmentDateChangeAcceptedProps: messages.messageAssignmentDateChangeAcceptedProps,
        messageAssignmentDateChangeAccepted: messages.messageAssignmentDateChangeAccepted,
        messageAssignmentDeletedProps: messages.messageAssignmentDeletedProps,
        messageAssignmentDeleted: messages.messageAssignmentDeleted,
        messageAssignmentConfirmedProps: messages.messageAssignmentConfirmedProps,
        messageAssignmentConfirmed: messages.messageAssignmentConfirmed,
        messageInvitationConfirmedProps: messages.messageInvitationConfirmedProps,
        messageInvitationConfirmed: messages.messageInvitationConfirmed,
        messageInvitationDeclinedProps: messages.messageInvitationDeclinedProps,
        messageInvitationDeclined: messages.messageInvitationDeclined,
        messageInvitationCanceledProps: messages.messageInvitationCanceledProps,
        messageInvitationCanceled: messages.messageInvitationCanceled,
        messageApplicationConfirmedProps: messages.messageApplicationConfirmedProps,
        messageApplicationConfirmed: messages.messageApplicationConfirmed,
        messageApplicationDeclinedProps: messages.messageApplicationDeclinedProps,
        messageApplicationDeclined: messages.messageApplicationDeclined,
    },
    owner: {
        openAssignmentProps: owner.openAssignmentProps,
        openAssignment: owner.openAssignment,
        openAssignments: owner.openAssignments,

        openAssignmentApplicationProps: owner.openAssignmentApplicationProps,
        openAssignmentApplication: owner.openAssignmentApplication,
        openAssignmentApplications: owner.openAssignmentApplications,
        openAssignmentOpenApplications: owner.openAssignmentOpenApplications,
        openAssignmentOpenApplicationsList: owner.openAssignmentOpenApplicationsList,

        confirmedAssignmentProps: owner.confirmedAssignmentProps,
        confirmedAssignment: owner.confirmedAssignment,
        currentAssignmentProps: owner.currentAssignmentProps,
        currentAssignments: owner.currentAssignments,
        upcomingAssignmentProps: owner.upcomingAssignmentProps,
        upcomingAssignment: owner.upcomingAssignment,
        pastAssignmentProps: owner.pastAssignmentProps,
        pastAssignment: owner.pastAssignment,
        pastAssignments: owner.pastAssignments,


        favourite: owner.favourite,
        favourites: owner.favourites,
        media: owner.media,
        medias: owner.medias,
        assignmentProps: owner.assignmentProps,
        assignment: owner.assignment,
        upcomingAssignments: owner.upcoming,
        listingOpenAssignments: owner.listingOpenAssignments,
        listingCurrentAssignment: owner.listingCurrentAssignment,
        listingUpcomingAssignments: owner.listingUpcomingAssignments,
        listingPastAssignments: owner.listingPastAssignments,
        listing: owner.listing,
        listings: owner.listings,
        feedback: owner.review,
        feedbacks: owner.feedbacks,
        review: owner.review,
        reviews: owner.reviews,
        reminders: owner.reminders,
        petProps: owner.petProps,
        pet: owner.pet,
        pets: owner.pets,
        conversations: owner.conversations,
        guideMedias: owner.guideMedias,
        owner: owner.owner,
        listingPastAssignmentFeedback: owner.listingPastAssignmentFeedback,
        listingPastAssignmentFeedbackRequest: owner.listingPastAssignmentFeedbackRequest,
        listingPastAssignmentReview: owner.listingPastAssignmentReview,

        welcomeGuideProps: owner.welcomeGuideProps,
        welcomeGuide: owner.welcomeGuide,
        listingPetProps: owner.listingPetProps,
        listingPet: owner.listingPet,
        listingPets: owner.listingPets,
    },
    search: {
        listing: search.listing,
        listingOpenAssignmentProps: search.openAssignmentProps,
        listingOpenAssignment: search.openAssignment,
        listingOpenAssignments: search.openAssignments,
        listingPastAssignmentProps: search.listingPastAssignmentProps,
        listingPastAssignment: search.listingPastAssignment,
        listingPastAssignments: search.listingPastAssignments,
        listings: search.listings,
        listingsRecommendations: search.listingsRecommendations,
        profile: search.profile,
        profiles: search.profiles,
        profilesRecommendations: search.profilesRecommendations,
    },
    session: {
        authenticate: session.authenticate,
        validate: session.validate,
    },
    sitter: {
        applicationProps: sitter.applicationProps,
        application: sitter.application,
        applications: sitter.applications,
        applicationsList: sitter.applicationsList,

        invitationProps: sitter.invitationProps,
        invitation: sitter.invitation,
        invitations: sitter.invitations,

        confirmedAssignmentProps: sitter.confirmedAssignmentProps,
        confirmedAssignment: sitter.confirmedAssignment,
        currentAssignmentProps: sitter.currentAssignmentProps,
        currentAssignments: sitter.currentAssignments,
        upcomingAssignmentProps: sitter.upcomingAssignmentProps,
        upcomingAssignment: sitter.upcomingAssignment,
        pastAssignmentProps: sitter.pastAssignmentProps,
        pastAssignment: sitter.pastAssignment,
        pastAssignments: sitter.pastAssignments,

        favourite: sitter.favourite,
        favourites: sitter.favourites,
        media: sitter.media,
        medias: sitter.medias,
        listing: common.baseListing,
        assignment: sitter.assignment,
        upcomingAssignments: sitter.upcoming,
        availability: sitter.availability,
        availabilities: sitter.availabilities,
        verifications: sitter.verifications,
        profileProps: sitter.profileProps,
        profile: sitter.profile,
        feedback: sitter.review,
        feedbacks: sitter.feedbacks,
        review: sitter.review,
        reviews: sitter.reviews,
        conversations: sitter.conversations,
        sitter: sitter.sitter,

        welcomeGuideProps: sitter.welcomeGuideProps,
        welcomeGuide: sitter.welcomeGuide,
        listingPetProps: sitter.listingPetProps,
        listingPet: sitter.listingPet,
        listingPets: sitter.listingPets,
    },
    notifications: {
        notificationProps: notifications.notificationProps,
        notification: notifications.notification,
        notifications: notifications.notifications,
    },
};
