import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import actions from 'api/actions';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { getRawListing, getResponseStatus, getUpdateId } from '../selectors';
import { PAGE_ID } from './Responsibilities.constants';

const LoadableResponsibilitiesListing = loadable(() => retry(() => import('./Responsibilities')));

const mapStateToProps = (state) => ({
    listing: getRawListing(state),
    responseStatus: getResponseStatus(state),
    updateId: getUpdateId(state),
    isViewingInAppWebView: isViewingInAppWebView(state),
});

const mapDispatchToProps = {
    saveListing: actions.owner.updateListing,
};

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableResponsibilitiesListing), PAGE_ID, true, false, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
