import { geoNamesCountryCodes } from '../../constants/geoNames';
import { AnimalId } from '../search/constants';

// =======================
// Single field validation
// =======================

// About You
// We've added both a check for the user and the sitterAvatarPhoto. The sitterAvatarPhoto property is
// added by a transform for search profiles, whereas the raw profile response, which is used
// in the profile editing pages has a user property.
const isValidAccountProfilePhoto = (profile) =>
    (!!profile.user && !!profile.user.profilePhoto) ||
    (!!profile.sitterAvatarPhoto && !!profile.sitterAvatarPhoto.publicId);
const isValidProfilePhotos = (profile) => profile.photos.length !== 0;
const isValidLocation = (profile) => profile.location && Object.keys(profile.location).length !== 0;
const isEmptyIntro = (profile) => profile.introduction.length === 0;
const isValidIntro = (profile) => !!profile.introduction && profile.introduction.length >= 50;
const isEmptyMotivation = (profile) => profile.motivation.length === 0;
const isValidMotivation = (profile) => !!profile.motivation && profile.motivation.length >= 50;
const isEmptyTitle = (profile) => profile.title.length === 0;
const isValidTitle = (profile) => !!profile.title && profile.title.length <= 80;
const isEmptyAirbnb = (profile) => !profile.socialVerifications.airbnb.platformIdentifier;
const isValidAirbnb = (profile) => !!profile.socialVerifications.airbnb.platformIdentifier;
const isEmptyLinkedIn = (profile) => !profile.socialVerifications.linkedin.platformIdentifier;
const isValidLinkedIn = (profile) => !!profile.socialVerifications.linkedin.platformIdentifier;

// Experience
const isValidExperienceBool = (profile) => profile.experience;
const isEmptyExperienceArray = (profile) => profile.animalExperience.length === 0;
const isEmptyExperienceText = (profile) => profile.relevantExperience.length === 0;
const isValidExperienceText = (profile) => profile.relevantExperience.length >= 50;
const isEmptyPastPetsArray = (profile) => profile.sitterPets.length === 0;

// Preferences
const isEmptyLocalAttractions = (profile) => profile.localAttractions.length === 0;
const isEmptyLocationArray = (profile) => profile.countries.length === 0;
const isEmptyInterestedAnimals = (profile) => profile.interestedAnimalIds.length === 0;
const happyToVideoCallIsEmpty = (profile) => profile.happyToVideoCall == null;
const happyToMeetInPersonIsEmpty = (profile) => profile.happyToMeetInPerson == null;
const dailyMinutesWillingToWalkDogsIsValid = (profile) => {
    const isDogSelected = (profile.interestedAnimalIds ?? []).includes(AnimalId.DOG);
    const dailyMinutesWillingToWalkDogs = profile.dailyMinutesWillingToWalkDogs ?? null;

    // The "dailyMinutesWillingToWalkDogs" field is required when "DOG" is selected in "interestedAnimalIds"
    if (isDogSelected && !dailyMinutesWillingToWalkDogs) {
        return false;
    }

    return true;
};
const dogSizePreferencesIsValid = (profile) => {
    const isDogSelected = (profile.interestedAnimalIds ?? []).includes(AnimalId.DOG);
    const dogSizePreferences = profile.dogSizePreferences ?? [];

    // The "dailyMinutesWillingToWalkDogs" field is required when "DOG" is selected in "interestedAnimalIds"
    if (isDogSelected && dogSizePreferences.length === 0) {
        return false;
    }

    return true;
};
// Profile Details
const isValidBirthDate = (profile) => !!profile.birthDate;
const isValidOccupationType = (profile) => !!profile.occupationType;
const isEmptyJobTitle = (profile) => profile.occupation.length === 0;
const isValidWithChildren = (profile) => profile.withChildren !== null;
// no value is stored for the solo/partner selector field, so we must check if any partner values are filled in
const hasPartner = (profile) =>
    profile.partner && Object.values(profile.partner).some((val) => val !== null && val !== '');
const isValidPartnerFirstName = (profile) => !!profile.partner.firstName;
const isValidPartnerLastName = (profile) => !!profile.partner.lastName;
const isValidPartnerBirthDate = (profile) => !!profile.partner.birthDate;
const isValidPartnerGender = (profile) => !!profile.partner.sex;
const isValidPartnerOccupationType = (profile) => !!profile.partner.occupationType;
const isEmptyRemoteWorking = (profile) => profile.interestedInRemoteWorking === null;

const getIsUSMember = (memberBillingCountryCode) =>
    memberBillingCountryCode === geoNamesCountryCodes.US;

// NOTE there is a replica of this function in the angular code base here
// angular/src/app/accounts/profile/helpers.js
// Please make sure to update both places if you make changes here.
const hasRequiredVerifications = ({
    profile,
    memberBillingCountryCode,
    /**
     * isEvidentChecksEnabled is a feature flag that is used to determine whether Evident is
     * stable and ready to be used.
     * If Evident went down we can switch off the feature in Optimizely so that the users are able to complete
     * their profile without ID / CBC checks.
     */
    isEvidentChecksEnabled = true,
}) => {
    const isUSMember = getIsUSMember(memberBillingCountryCode);

    if (
        isEvidentChecksEnabled &&
        isUSMember &&
        !profile.completedVerifications.hasVerifiedCriminalBackgroundCheck
    ) {
        return false;
    }

    if (isEvidentChecksEnabled && !profile.completedVerifications?.hasVerifiedIdCheck) {
        return false;
    }

    return (
        profile.completedVerifications.hasVerifiedEmail &&
        profile.completedVerifications.hasVerifiedPhone
    );
};

const orderBySentAtMostToLeastRecent = (itemA, itemB) => {
    if (!itemA.sentAt && !itemB.sentAt) return 0;
    if (!itemA.sentAt) return 1;
    if (!itemB.sentAt) return -1;
    return new Date(itemB.sentAt) - new Date(itemA.sentAt);
};

export {
    isValidAccountProfilePhoto,
    isValidProfilePhotos,
    isValidLocation,
    isEmptyIntro,
    isValidIntro,
    isEmptyMotivation,
    isValidMotivation,
    isEmptyTitle,
    isValidTitle,
    isEmptyAirbnb,
    isValidAirbnb,
    isEmptyLinkedIn,
    isValidLinkedIn,
    isValidExperienceBool,
    isEmptyExperienceArray,
    isEmptyExperienceText,
    isValidExperienceText,
    isEmptyPastPetsArray,
    isEmptyLocalAttractions,
    isEmptyLocationArray,
    isEmptyInterestedAnimals,
    happyToVideoCallIsEmpty,
    happyToMeetInPersonIsEmpty,
    dogSizePreferencesIsValid,
    dailyMinutesWillingToWalkDogsIsValid,
    isValidBirthDate,
    isValidOccupationType,
    isEmptyJobTitle,
    isValidWithChildren,
    hasPartner,
    isValidPartnerFirstName,
    isValidPartnerLastName,
    isValidPartnerBirthDate,
    isValidPartnerGender,
    isValidPartnerOccupationType,
    isEmptyRemoteWorking,
    hasRequiredVerifications,
    orderBySentAtMostToLeastRecent,
};
