import settingsMembershipRoutes from './membership/route-paths';

export default {
    settings: {
        hub: '/user/settings/',
        details: '/user/settings/details/',
        contactPreferences: '/user/settings/contact-preferences/',
        membership: settingsMembershipRoutes,
    },
};
