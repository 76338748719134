/* eslint-disable import/prefer-default-export */
import { createAction } from 'utils/actions';

const prefix = 'SEARCH_PLACES';

export const request = createAction(prefix, 'REQUEST', 'searchString', 'featureCodes');

export const requestOnEnter = createAction(
    'SEARCH_PLACES_ON_ENTER',
    'REQUEST',
    'searchString',
    'featureCodes'
);
