// Auth & Account
import session from './session';
// Account
import account from './account';
// Animals
import animals from './animals';
// Media
import medias from './medias';
// Notifications
import notifications from './notifications';
// Search
import search from './search';
// Owner
import owner from './owner';
// Sitter
import sitter from './sitter';
// Status
import status from './status';
// Messages
import messages from './messages';
// Plans
import plans from './plans';
// Places
import places from './places';

import policies from './policies';
// Content
import content from './content';

import verifications from './verifications';

export default {
    session,
    account,
    animals,
    medias,
    notifications,
    search,
    owner,
    sitter,
    status,
    messages,
    plans,
    places,
    policies,
    content,
    verifications,
};
