import experimentSlots from './experimentSlots';
import { getFromCache, addToCache } from './experimentCache';
import { ABtest, ABCtest } from './experimentTypes';

// Returns an integer hash of a string
export const djbHashers = {
    old: (str) => {
        const len = str.length;
        let hash = 5381;
        for (let idx = 0; idx < len; idx += 1) {
            // prettier-ignore
            hash = (33 * hash) + str.charCodeAt(idx);
        }
        return hash;
    },
    new: (str) => {
        /* eslint-disable no-bitwise */
        // Normally bitwise isn't what we want to do, however, this function is specifically working in binary, so we
        // enable bitwise operators for the whole function
        const len = str.length;
        let hash = 5381;
        for (let idx = 0; idx < len; idx += 1) {
            // prettier-ignore
            hash = ((hash << 5) + hash) + str.charCodeAt(idx);
            hash &= hash;
        }
        return hash & 0x7fffffff; // we want an unsigned int. Unfortunately javascript is too smart for it's own good, so
        // we have to get an unsigned 31bit integer (horrible, i know)
        /* eslint-enable no-bitwise */
    },
};

export const shuffledVariations = ({ type, slot = 1, splitVariate, group, useLegacyHash }) => {
    // Get the unsorted slot variations
    const experimentType = type === ABtest ? 'ab' : 'abc';
    const sortedSplitVariations = [...experimentSlots[experimentType][slot - 1]];

    // Now we need to shuffle them into a psuedo random (but deterministic) sort order
    // Using good ol` fisher-yates algorithm
    const splitVariateString = splitVariate ? `_${splitVariate}` : '';
    const hashString = `${group}${splitVariateString}`;
    for (let s = sortedSplitVariations.length - 1; s > 0; s -= 1) {
        // Get index to swap to
        let hash;
        if (useLegacyHash) {
            hash = djbHashers.old(`${s}_${hashString}`);
        } else {
            hash = djbHashers.new(`${s}_${hashString}`);
        }
        const swapToIndex = hash % (s + 1);
        // Now swap value
        const variation = sortedSplitVariations[s];
        sortedSplitVariations[s] = sortedSplitVariations[swapToIndex];
        sortedSplitVariations[swapToIndex] = variation;
    }

    return sortedSplitVariations;
};

export const getTestSplitVariation = (experiment, numberOfVariationsProp) => {
    /* Set Defaults */
    if (experiment.type === ABCtest && !experiment.slot && !experiment.group) {
        experiment.slot = 3; // experiment slot 3 for ABC tests is used for legacy tests.
        // We assume legacy ABC tests when neither slot nor group has been filled in.
    }
    if (!experiment.slot) {
        experiment.slot = 1;
    }
    if (!experiment.group) {
        experiment.group = experiment.name;
    }

    /* get number of variations */
    const numberOfVariations = numberOfVariationsProp || Object.keys(experiment.type).length;

    /* cached splits escape */
    const cacheKey = `${experiment.group}_${experiment.slot}_${experiment.splitVariate}_${numberOfVariations}`;
    const cached = getFromCache(cacheKey);

    if (cached) {
        return cached;
    }

    /* Shuffle variations */
    const sortedSplitVariations = shuffledVariations(experiment);

    /* Map to splits */
    const testSplitVariation = {};
    for (let s = 0; s < 72; s += 1) {
        testSplitVariation[`split${s + 1}`] = sortedSplitVariations[s];
    }

    /* save to the cache */
    addToCache(cacheKey, testSplitVariation);

    /* return outcome */
    return testSplitVariation;
};
