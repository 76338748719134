import PropTypes from 'prop-types';

import {
    baseConversation,
    baseMediaPhoto,
} from './common';

// eslint-disable-next-line import/prefer-default-export
export const archived = PropTypes.arrayOf(PropTypes.shape(baseConversation));

/**
 * Generic
 * @type {{id, body, type, subType, sendDate, sentBy, isRead}}
 */
export const messageGenericProps = {
    id: PropTypes.string,
    body: PropTypes.any,
    type: PropTypes.string,
    subType: PropTypes.string,
    sendDate: PropTypes.string,
    sentBy: PropTypes.string,
    isRead: PropTypes.bool,
};
export const messageGeneric = PropTypes.shape(messageGenericProps);

/**
 * Message
 * @type {{id, body, type, subType, sendDate, sentBy, isRead}}
 */
export const messageProps = {
    id: PropTypes.string,
    body: PropTypes.string,
    type: PropTypes.string,
    subType: PropTypes.string,
    sendDate: PropTypes.string,
    sentBy: PropTypes.string,
    isRead: PropTypes.bool,
};
export const message = PropTypes.shape(messageProps);

/**
 * Photo Message
 * @type {{photo}}
 */
export const messagePhotoProps = {
    ...messageProps,
    photo: baseMediaPhoto,
};
export const messagePhoto = PropTypes.shape(messagePhotoProps);

/**
 * Video Message
 * @type {{video}}
 */
export const messageVideoProps = {
    ...messageProps,
    video: baseMediaPhoto,
};
export const messageVideo = PropTypes.shape(messageVideoProps);

/**
 * Assignment Application
 * @type {{body}}
 */
export const messageAssignmentApplicationProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        applications: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                assignmentId: PropTypes.string,
                listingId: PropTypes.string,
                startDate: PropTypes.string,
                endDate: PropTypes.string,
            }),
        ),
    }),
};
export const messageAssignmentApplication = PropTypes.shape(messageAssignmentApplicationProps);

/**
 * Assignment Date Change
 * @type {{body}}
 */
export const messageAssignmentDateChangeProps = {
    ...messageProps,
    body: PropTypes.shape({
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            oldDate: PropTypes.shape({
                startDate: PropTypes.string,
                endDate: PropTypes.string,
            }),
        }),
    }),
};
export const messageAssignmentDateChange = PropTypes.shape(messageAssignmentDateChangeProps);

/**
 * Assignment Invitation
 * @type {{body}}
 */
export const messageAssignmentInvitationProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        applications: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                assignmentId: PropTypes.string,
                listingId: PropTypes.string,
                startDate: PropTypes.string,
                endDate: PropTypes.string,
            }),
        ),
    }),
};
export const messageAssignmentInvitation = PropTypes.shape(messageAssignmentInvitationProps);

/**
 * Assignment Date Change Declined
 * @type {{body}}
 */
export const messageAssignmentDateChangeDeclinedProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageAssignmentDateDeclinedChange = PropTypes.shape(messageAssignmentDateChangeDeclinedProps);

/**
 * Assignment Date Change Accepted
 * @type {{body}}
 */
export const messageAssignmentDateChangeAcceptedProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageAssignmentDateChangeAccepted = PropTypes.shape(messageAssignmentDateChangeAcceptedProps);

/**
 * Assignment Deleted
 * @type {{body}}
 */
export const messageAssignmentDeletedProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageAssignmentDeleted = PropTypes.shape(messageAssignmentDeletedProps);

/**
 * Assignment Confirmed
 * @type {{body}}
 */
export const messageAssignmentConfirmedProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageAssignmentConfirmed = PropTypes.shape(messageAssignmentConfirmedProps);

/**
 * Invitation Confirmed
 * @type {{body}}
 */
export const messageInvitationConfirmedProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageInvitationConfirmed = PropTypes.shape(messageInvitationConfirmedProps);

/**
 * Invitation Declined
 * @type {{body}}
 */
export const messageInvitationDeclinedProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageInvitationDeclined = PropTypes.shape(messageInvitationDeclinedProps);

/**
 * Invitation Canceled
 * @type {{body}}
 */
export const messageInvitationCanceledProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageInvitationCanceled = PropTypes.shape(messageInvitationCanceledProps);

/**
 * Application Confirmed
 * @type {{body}}
 */
export const messageApplicationConfirmedProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageApplicationConfirmed = PropTypes.shape(messageApplicationConfirmedProps);

/**
 * Application Declined
 * @type {{body}}
 */
export const messageApplicationDeclinedProps = {
    ...messageProps,
    body: PropTypes.shape({
        message: PropTypes.string,
        application: PropTypes.shape({
            id: PropTypes.string,
            assignmentId: PropTypes.string,
            listingId: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    }),
};
export const messageApplicationDeclined = PropTypes.shape(messageApplicationDeclinedProps);
