// eslint-disable-next-line import/prefer-default-export
export const USER_PROFILE_EDIT_PREFERENCES = {
    create(
        profile = {
            localAttractions: [],
            countries: [],
        }
    ) {
        return {
            type: 'Profile Edit Preferences Update',
            payload: {
                locationCharacteristics: profile.localAttractions.length > 0,
                countries: profile.countries.length > 0,
            },
        };
    },
};
