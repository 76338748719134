import { createAction } from 'utils/actions';

const prefix = 'REGISTER';

export const createAccount = createAction(
    prefix,
    'CREATE_ACCOUNT',
    'data',
    'membershipType',
    'method',
    'signupOrigin',
    'membershipPlan',
    'openMethod'
);
export const showRegisterErrorMessage = createAction(
    prefix,
    'SHOW_REGISTER_ERROR_MESSAGE',
    'error'
);
export const setSubmitting = createAction(prefix, 'SET_SUBMITTING');
export const userRegistered = createAction(prefix, 'USER_REGISTERED');
export const userLoggedIn = createAction(prefix, 'USER_LOGGED_IN');
export const finshedLoadingAccountData = createAction(prefix, 'FINISHED_LOADING_ACCOUNT_DATA');
