import styled, { css } from 'styled-components';

const ContainerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    ${({ size }) => {
        if (size === 'sm') {
            return css`
                gap: ${({ theme }) => theme.spacing[0.5]};

                span {
                    width: auto;
                }

                svg {
                    width: 15px;
                    height: 18px;
                }
            `;
        }

        if (size === 'md') {
            return css`
                gap: ${({ theme }) => theme.spacing[2]};

                svg {
                    width: 18px;
                    height: 17px;
                }
            `;
        }

        return css`
            gap: ${({ theme }) => theme.spacing[2]};

            svg {
                width: inherit;
                height: inherit;
            }
        `;
    }}
`;

export { ContainerWrapper };
