// eslint-disable-next-line import/prefer-default-export
export const USER_LISTING_EDIT_LOCATION = {
    create(listing) {
        const {
            placeId = null,
            location = {},
            localAttractions = [],
            isAccessibleByPublicTransport = null,
        } = listing || {};
        return {
            type: 'Listing Edit Location Update',
            payload: {
                location: location.name !== undefined || placeId !== null,
                localAttractions: localAttractions.length > 0,
                isAccessibleByPublicTransport: isAccessibleByPublicTransport !== null,
            },
        };
    },
};
