/* eslint-disable import/prefer-default-export */

const getSharedDataArray = (assignments) =>
    assignments.map((pastAssignment) => {
        const {
            id,
            startDate,
            endDate,
            ownerAvatarPhoto,
            ownerIsRedacted,
            ownerName,
            listingAnimals,
            listingId,
            listingLocation,
            ownerIsActive,
            sitterIsActive,
        } = pastAssignment;

        return {
            id,
            startDate,
            endDate,
            ownerAvatarPhoto,
            ownerIsRedacted,
            ownerName,
            listingAnimals,
            listingId,
            listingLocation,
            ownerIsActive,
            sitterIsActive,
        };
    });

const getFeedbackArray = (pastAssignments) => {
    // 1. filter initially in here (only has feedback) (feedbackArray)
    const assignmentsWithFeedback = pastAssignments.filter(
        (pastAssignment) => pastAssignment.feedback
    );
    // 2. pass filtered array off to helper.
    const sharedDataArray = getSharedDataArray(assignmentsWithFeedback);
    const feedbackArray = assignmentsWithFeedback
        .map((pastAssignment, index) => {
            const { feedback, profileName, sitterAvatarPhoto, profileLocation, profileId } =
                pastAssignment;

            const {
                version,
                recommended,
                communication,
                hospitality,
                cleanliness,
                petBehaviour,
                accuracyOfListing,
                // shared with review ->
                sentAt,
                description,
                reply,
                replySentAt,
            } = feedback;

            return {
                description,
                sentAt,
                reply,
                replySentAt,
                profileName,
                profileId,
                profileLocation,
                version,
                recommended,
                communication,
                hospitality,
                cleanliness,
                petBehaviour,
                accuracyOfListing,
                sitterAvatarPhoto,
                ...sharedDataArray[index],
            };
        })
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    return feedbackArray;
};

const getReviewArray = (pastAssignments) => {
    const assignmentsWithReview = pastAssignments.filter((pastAssignment) => pastAssignment.review);

    const sharedDataArray = getSharedDataArray(assignmentsWithReview);

    const reviewArray = assignmentsWithReview
        .map((pastAssignment, index) => {
            const { review } = pastAssignment;

            const {
                organised,
                reliable,
                selfSufficient,
                tidy,
                petCare,
                overallScore,
                // shared with feedback ->
                sentAt,
                description,
                reply,
                replySentAt,
            } = review;

            return {
                organised,
                reliable,
                selfSufficient,
                tidy,
                petCare,
                overallScore,
                sentAt,
                description,
                reply,
                replySentAt,
                ...sharedDataArray[index],
            };
        })
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    return reviewArray;
};

export { getFeedbackArray, getReviewArray };
