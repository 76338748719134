import React from 'react';
import { useTranslation } from 'react-i18next';
import * as SearchConstants from 'api/helpers/search/constants';
import { locationName as formatLocationName } from 'api/helpers/format/location';
import { FILM_RATIO_ALT } from 'config/images';
import ZIndexManager from 'components/ZIndexManager';
import Rating from 'components/Rating';
import { getReviewArray } from 'utils/feedbackReview';
import { head, orderBy } from 'lodash/fp';
import { truncateText } from 'components/CollapsableText/components/CollapsableTextByMaxCharacters/helpers';
import { useMediaQuery } from 'utils/hooks';
import useQuery from 'hooks/useQuery';
import { MAX_REVIEW_CHARS, ASPECT_RATIO_BY_BREAKPOINT } from './ProfileSearchCard.constants';
import environment from '../../environment';
import {
    FavouriteStyled,
    FavouriteLabelStyled,
    ProfileWrapper,
    ProfileCardWrapper,
    ProfileCardWrapperTop,
    ProfileCardWrapperBottom,
    ProfileCardSkeletonWrapper,
    ProfileCardSkeleton,
    ProfileImageWrapper,
    ProfileSkeletonImageResizeWrapper,
    ProfileImage,
    ProfileInfoWrapper,
    ProfileNamesWrapper,
    ProfileNames,
    ProfileHeadlineDesktopStyled,
    ProfileHeadlineTabletStyled,
    ProfileHeadlineMobileStyled,
    ProfileLocation,
    TooltipWrapper,
    TooltipStyled,
    IDBadgeStyled,
    CBCBadgeStyled,
    ReviewStyled,
    ReviewWrapper,
    ReviewStarsWrapper,
    ReviewTextWrapper,
    ReviewCountStyle,
    ReviewDesktopVisible,
    ReviewMobileVisible,
    ReadMoreButton,
} from './ProfileSearchCard.style';

const ProfileSearchCard = ({
    href,
    id,
    isFavourite,
    showFavourite,
    createFavourite,
    removeFavourite,
    hasProfileDetailLoaded,
    profile,
}) => {
    const { t } = useTranslation();

    // only open link in a new tab if user is browsing from a desktop / laptop
    const mediaQuery = useMediaQuery('(min-width: 600px) and (pointer: fine)');
    const search = useQuery();
    const linkTarget = mediaQuery ? '_blank' : '_self';

    const {
        sitterAvatarPhoto,
        sitterName,
        location,
        verificationLevel,
        partner,
        title,
        referencesCount = 0,
        referencesAverageScore = 0,
        reviewsCount = 0,
        reviewsAverageScore = 0,
        references,
        pastAssignments,
    } = profile;
    const hasIDCheck = verificationLevel === SearchConstants.VerificationLevel.STANDARD;
    const hasCBC = verificationLevel === SearchConstants.VerificationLevel.ENHANCED;

    const getRating = (item) => {
        const { organised, reliable, selfSufficient, tidy, petCare } = item;
        const scoresArray = [
            organised || 0,
            reliable || 0,
            selfSufficient || 0,
            tidy || 0,
            petCare || 0,
        ];
        return (
            scoresArray.reduce((sum, value) => sum + value, 0) /
            scoresArray.filter((value) => value !== 0).length
        );
    };

    let sitterTitle = sitterName;
    if (partner && partner.firstName) {
        sitterTitle = `${sitterName} & ${partner.firstName}`;
    }

    const onFavourite = () => {
        const data = {
            id,
        };

        if (isFavourite) {
            removeFavourite({
                forceReload: true,
                data,
            });
        } else {
            createFavourite({
                forceReload: true,
                data,
            });
        }
    };

    const Review = ({ shouldRenderReviewsText = false }) => {
        const hasReviews = reviewsCount > 0 && reviewsAverageScore > 0;
        const hasReferences = referencesCount > 0 && referencesAverageScore > 0;
        if (!hasReviews && !hasReferences) return null;

        const shouldTruncateText = (text) => {
            if (MAX_REVIEW_CHARS < text.length) {
                return `"${truncateText(text, MAX_REVIEW_CHARS, true)}"`;
            }
            return `"${text}"`;
        };

        const ReviewText = () => {
            // get the reviews from the pastAssignments object
            const reviews = getReviewArray(pastAssignments).map((review) => ({
                ...review,
                average: getRating(review),
            }));
            // get the high latest review from the reviews array
            const orderedAndSorted = orderBy(['average', 'endDate'], ['desc', 'desc'])(reviews);
            const betterReview = orderedAndSorted ? head(orderedAndSorted) : undefined;
            if (!betterReview) return null;
            const text = betterReview.description || '';
            if (betterReview.average < 4.5 || !text.length) return null;
            return (
                <ReviewTextWrapper>
                    {shouldTruncateText(text)}
                    {MAX_REVIEW_CHARS < text.length && (
                        <ReadMoreButton>{t('components_CommentCard_readMore')}</ReadMoreButton>
                    )}
                </ReviewTextWrapper>
            );
        };

        const ReferenceText = () => {
            // filters refereces, only returns references with a sendAt value
            const filteredReferences = references
                .filter(({ sentAt }) => !!sentAt)
                .map((reference) => ({ ...reference, average: getRating(reference) }));
            // get the high latest reference from the references array
            const orderedAndSorted = orderBy(
                ['average', 'sentAt'],
                ['desc', 'desc']
            )(filteredReferences);
            const betterReference = orderedAndSorted ? head(orderedAndSorted) : undefined;
            if (!betterReference) return null;
            const text = betterReference.reference || '';
            if (betterReference.average < 4.5 || !text.length) return null;
            return (
                <ReviewTextWrapper>
                    {shouldTruncateText(text)}
                    {MAX_REVIEW_CHARS < text.length && (
                        <ReadMoreButton>{t('components_CommentCard_readMore')}</ReadMoreButton>
                    )}
                </ReviewTextWrapper>
            );
        };

        return (
            <ReviewStyled>
                {hasReviews && (
                    <>
                        <ReviewWrapper to={`${href}?r=reviews`}>
                            <ReviewStarsWrapper>
                                <Rating rating={reviewsAverageScore} />
                                <ReviewCountStyle>
                                    {t('components_profileCard_reviews', {
                                        count: reviewsCount,
                                        reviewsCount,
                                    })}
                                </ReviewCountStyle>
                            </ReviewStarsWrapper>
                            {pastAssignments && shouldRenderReviewsText && <ReviewText />}
                        </ReviewWrapper>
                    </>
                )}
                {!hasReviews && hasReferences && (
                    <>
                        <ReviewWrapper to={`${href}?r=references`}>
                            <ReviewStarsWrapper>
                                <Rating
                                    rating={referencesAverageScore}
                                    variant={Rating.Variant.REFERENCE}
                                />
                                <ReviewCountStyle>
                                    {t('components_profileCard_references', {
                                        count: referencesCount,
                                        referencesCount,
                                    })}
                                </ReviewCountStyle>
                            </ReviewStarsWrapper>
                            {references && shouldRenderReviewsText && <ReferenceText />}
                        </ReviewWrapper>
                    </>
                )}
            </ReviewStyled>
        );
    };

    if (!hasProfileDetailLoaded) {
        return (
            <ProfileCardSkeletonWrapper>
                <ProfileCardSkeleton aspectRatio={ASPECT_RATIO_BY_BREAKPOINT} />
            </ProfileCardSkeletonWrapper>
        );
    }

    return (
        <ProfileWrapper
            to={`${href}${search.toString() && `?${search.toString()}`}`}
            target={linkTarget}
        >
            <ProfileCardWrapper>
                <ProfileCardWrapperTop>
                    <ProfileImageWrapper>
                        <ProfileSkeletonImageResizeWrapper>
                            <ProfileImage
                                alt={
                                    !(partner && partner.firstName)
                                        ? t('components_profileCard_imageAlt_single', {
                                              sitterTitle,
                                          })
                                        : t('components_profileCard_imageAlt_other', {
                                              sitterTitle,
                                          })
                                }
                                imageId={sitterAvatarPhoto ? sitterAvatarPhoto.publicId : null}
                                transformation={FILM_RATIO_ALT}
                                options={{ format: null }}
                                placeholderUrl={environment.vars.profiles.defaultImage}
                                placeholderAlt={t('components_profileCard_placeholder_image')}
                            />
                            {showFavourite && (
                                <ZIndexManager layer="navigationButtons">
                                    <FavouriteStyled
                                        label={
                                            <FavouriteLabelStyled>
                                                {t('components_profileCard_favourite')}
                                            </FavouriteLabelStyled>
                                        }
                                        isVariation
                                        id={id}
                                        isChecked={isFavourite}
                                        onChange={() => onFavourite()}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                    />
                                </ZIndexManager>
                            )}
                        </ProfileSkeletonImageResizeWrapper>
                    </ProfileImageWrapper>

                    <ProfileInfoWrapper>
                        <ProfileNamesWrapper>
                            {(hasIDCheck || hasCBC) && (
                                <TooltipWrapper>
                                    <TooltipStyled
                                        body={
                                            hasIDCheck
                                                ? t('components_profileCard_verified_tooltip_id')
                                                : t('components_profileCard_verified_tooltip_cbc')
                                        }
                                    >
                                        {hasIDCheck && <IDBadgeStyled icon="id-check1" />}
                                        {hasCBC && <CBCBadgeStyled icon="cbc" />}
                                    </TooltipStyled>
                                </TooltipWrapper>
                            )}
                            <ProfileNames>{sitterTitle}</ProfileNames>
                        </ProfileNamesWrapper>
                        <ProfileLocation>{formatLocationName(location, true)}</ProfileLocation>
                        <ReviewMobileVisible>
                            <Review shouldRenderReviewsText={false} />
                        </ReviewMobileVisible>
                        <ProfileHeadlineDesktopStyled>{title}</ProfileHeadlineDesktopStyled>
                        <ProfileHeadlineTabletStyled>{title}</ProfileHeadlineTabletStyled>
                        <ReviewDesktopVisible>
                            <Review shouldRenderReviewsText />
                        </ReviewDesktopVisible>
                    </ProfileInfoWrapper>
                </ProfileCardWrapperTop>
                <ProfileCardWrapperBottom>
                    <ProfileHeadlineMobileStyled>{title}</ProfileHeadlineMobileStyled>
                </ProfileCardWrapperBottom>
            </ProfileCardWrapper>
        </ProfileWrapper>
    );
};

export default ProfileSearchCard;
