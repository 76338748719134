import { settings as actions } from '../../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        // Load blog category
        case actions.content.loadBlogCategory.SUCCESS: {
            const { areaSlug, categorySlug } = action.requestData;
            return {
                ...state,
                [areaSlug]: {
                    ...state[areaSlug],
                    [categorySlug]: {
                        ...action.data,
                        posts: {
                            ...(state[areaSlug] && state[areaSlug][categorySlug]
                                ? state[areaSlug][categorySlug].posts
                                : {}),
                        },
                    },
                },
            };
        }
        case actions.content.loadBlogCategoryAllSubcategories.SUCCESS: {
            const { areaSlug, categorySlug } = action.requestData;
            const { results } = action.data;

            return {
                ...state,
                [areaSlug]: {
                    ...state[areaSlug],
                    [categorySlug]: {
                        ...(state[areaSlug] && state[areaSlug][categorySlug]
                            ? state[areaSlug][categorySlug]
                            : {}),
                        subcategories: results,
                    },
                },
            };
        }
        // Load blog category posts
        case actions.content.loadBlogCategoryPosts.SUCCESS: {
            const { searchType = 'default', areaSlug, categorySlug } = action.requestData;
            return {
                ...state,
                [areaSlug]: {
                    ...state[areaSlug],
                    [categorySlug]: {
                        ...(state[areaSlug] && state[areaSlug][categorySlug]
                            ? state[areaSlug][categorySlug]
                            : {}),
                        posts: {
                            ...(state[areaSlug] && state[areaSlug][categorySlug]
                                ? state[areaSlug][categorySlug].posts
                                : {}),
                            [searchType]: {
                                ...action.data,
                                page: action.action.filters.page,
                                limit: action.action.filters.limit,
                            },
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};
