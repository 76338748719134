import { all, put, take, call, takeEvery, select, spawn } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { Events, track } from 'utils/analytics';
import {
    load as pageActionsLoad,
    preload as pageActionsPreload,
    error as errorAction,
    preloaded as pageActionsPreloaded,
    loaded as pageActionsLoaded,
} from 'containers/Page/actions';
import apiActions, { settings } from 'api/actions';
import {
    getOwnerListing,
    getOwnerAssignment,
    getOwnerListingAmenitiesRaw,
} from 'api/selectors/owner';
import * as amenityTransform from 'api/helpers/transform/owner/amenities';
import * as popupModalActions from '../Hub/components/PopupModal/actions';
import {
    saveDates,
    saveDatesDONE,
    deleteDate,
    deleteDateDONE,
    loadOpenAssignmentsSUCCESS,
    boostAssignment,
} from './actions';
import { PAGE_ID } from './Dates.constants';

export function* dispatchOpenAmenityPopup({ dates }) {
    const listingId = dates.find(({ date }) => date.listingId)?.date?.listingId;

    if (!listingId) {
        return;
    }

    const listing = yield select(getOwnerListing, listingId);

    if (!listing.isApproved) {
        return;
    }

    const amenities = yield select(getOwnerListingAmenitiesRaw, listingId);

    if (!amenities) {
        return;
    }

    const { wifiType, hasCarAccess, ...amenitiesWithoutWifiAndCar } = amenities;

    if (Object.keys(amenityTransform.removeEmptyValues(amenitiesWithoutWifiAndCar)).length > 0) {
        return;
    }

    yield delay(1600);
    yield call(track, Events.USER_LISTING_AMENITIES_POPUP_OPENED.create());
    yield put(
        popupModalActions.updateDates.create(
            dates.map(({ date }) => [date.startDate, date.endDate])
        )
    );
}

export function* preloadDatesData(params) {
    const { listingId } = params;

    // In the redux store the owner's listing has an openAssignments property which is,
    // an array of ids that link to openAssignments. This is initially empty,
    // when we load the openAssignments, each assignment id is added to the array.
    // Hence we need to load the listing first then openAssignments.

    // It's not important to us if the listing is up to date, so we check if one already
    // exists and, if not, we load a new one.
    const listingInStore = yield select(getOwnerListing, listingId);

    if (typeof listingInStore === 'undefined') {
        // Load Listing
        yield put(
            apiActions.owner.loadListing({
                forceReload: true,
                data: {
                    id: listingId,
                },
            })
        );

        // Wait for results
        const { status, statusCode } = yield take(
            (res) => res.type === settings.owner.loadListing.DONE
        );

        if (status !== settings.owner.loadListing.SUCCESS) {
            // Error occurred
            yield put(errorAction.create(PAGE_ID, statusCode));
            return false;
        }
    }

    yield all([
        put(
            apiActions.owner.loadListingOpenAssignments({
                forceReload: true,
                data: {
                    id: listingId,
                    listingId,
                },
            })
        ),
        put(
            apiActions.owner.loadConfirmedAssignments({
                forceReload: true,
                data: {
                    id: listingId,
                    listingId,
                },
            })
        ),
    ]);

    const [openAssignmentsResponse, confirmedAssignmentsResponse] = yield all([
        take((res) => res.type === settings.owner.loadListingOpenAssignments.DONE),
        take((res) => res.type === settings.owner.loadConfirmedAssignments.DONE),
    ]);

    // Wait for open assignment results
    const { status: openAssignmentsStatus, statusCode: openAssignmentsStatusCode } =
        openAssignmentsResponse;

    if (openAssignmentsStatus !== settings.owner.loadListingOpenAssignments.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, openAssignmentsStatusCode));
        return false;
    }

    // We use this to update loadOpenAssignmentsUpdateId, which counts
    // successful loading of openAssignments
    yield put(loadOpenAssignmentsSUCCESS.create());

    // Wait for confirmed assignment results
    const { status: confirmedAssignmentsStatus, statusCode: confirmedAssignmentsStatusCode } =
        confirmedAssignmentsResponse;

    if (confirmedAssignmentsStatus !== settings.owner.loadConfirmedAssignments.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, confirmedAssignmentsStatusCode));
        return false;
    }

    return true;
}

export function* load(action) {
    const successFullyLoaded = yield call(preloadDatesData, action.params, PAGE_ID);
    if (successFullyLoaded) {
        yield put(pageActionsLoaded.create(PAGE_ID));
    }
}

export function* preload(action) {
    const successFullyLoaded = yield call(preloadDatesData, action.params, PAGE_ID);
    if (successFullyLoaded) {
        yield put(pageActionsPreloaded.create(PAGE_ID));
    }
}

/**
 * @description creates a new listing and returns the api status
 * @param {object} assignment which has the shape
 * {
 *      listingId: string,
 *      startDate: string,
 *      endDate: string,
 * }
 */

// function to return assignment values used for analytics
// this is inline with what we return for the delete event
const formatAssignmentAnalytics = (assignment) => {
    const {
        endDate,
        startDate,
        listingId,
        id,
        boostedCount,
        boostedDatetime,
        isUpdated,
        isPrivate,
        isReviewing,
        invitationsCount,
    } = assignment;

    return {
        assignmentId: id,
        endDate,
        startDate,
        listingId,
        boostedCount,
        boostedDatetime,
        isPrivate,
        isReviewing,
        isUpdated,
        invitationsCount,
    };
};

export function* createListingOpenAssignment(assignment) {
    yield put(apiActions.owner.createListingOpenAssignment({ data: assignment }));

    // Wait for results
    const response = yield take(
        (res) =>
            res.type === settings.owner.createListingOpenAssignment.DONE &&
            res.requestData.startDate === assignment.startDate
    );

    return {
        status: response.status,
        date: response.data,
    };
}

export function* updateListingOpenAssignment(assignment) {
    yield put(
        apiActions.owner.updateListingOpenAssignment({
            data: {
                ...assignment,
                id: assignment.id,
            },
        })
    );

    // Wait for results
    const response = yield take(
        (res) =>
            res.type === settings.owner.updateListingOpenAssignment.DONE &&
            res.requestData.startDate === assignment.startDate
    );

    return {
        status: response.status,
        date: response.data,
    };
}

export function* deleteListingOpenAssignment(action) {
    yield put(
        apiActions.owner.removeListingOpenAssignment({
            data: {
                listingId: action.assignment.listingId,
                id: action.assignment.id,
            },
        })
    );

    // Wait for results
    const response = yield take(
        (res) =>
            res.type === settings.owner.removeListingOpenAssignment.DONE &&
            res.requestData.id === action.assignment.id
    );

    return response.status;
}

export function* saveListingOpenAssignments(action) {
    const { newDates, updatedDates } = action;

    // =======================
    // NEW CREATED ASSIGNMENTS
    // =======================

    const newListingOpenAssignments = yield all(
        newDates.map((assignment) => call(createListingOpenAssignment, assignment))
    );

    // check for a failure or error in creating any of the new assignments
    const newOpenAssignmentsHasFailure = newListingOpenAssignments.some(
        (res) =>
            res.status === settings.owner.createListingOpenAssignment.FAILURE ||
            res.status === settings.owner.createListingOpenAssignment.ERROR
    );

    // Successful new open assignments created
    const successfulNewOpenAssignments = newListingOpenAssignments.filter(
        (res) => res.status === settings.owner.createListingOpenAssignment.SUCCESS
    );

    // Track analytics on new successful assignments
    successfulNewOpenAssignments.map((res) =>
        track(Events.OWNER_CREATED_ASSIGNMENT.create(formatAssignmentAnalytics(res.date)))
    );

    // If we had new dates and saved them successfully then publish
    // There is a check for whether the listing is live on the api side
    if (successfulNewOpenAssignments.length > 0) {
        // Publish listing
        yield put(
            apiActions.owner.publishListing({
                forceReload: true,
                data: {
                    id: newDates[0].listingId,
                },
            })
        );
        yield take(settings.owner.publishListing.DONE);
    }

    // ===================
    // UPDATED ASSIGNMENTS
    // ===================

    const updateListingOpenAssignments = yield all(
        updatedDates.map((assignment) => call(updateListingOpenAssignment, assignment))
    );

    // check for a failure in updating any of the assignments
    const updateOpenAssignmentsHasFailure = updateListingOpenAssignments.some(
        (res) =>
            res.status === settings.owner.updateListingOpenAssignment.FAILURE ||
            res.status === settings.owner.updateListingOpenAssignment.ERROR
    );

    // Track analytics on updated successful assignments
    updateListingOpenAssignments
        .filter((res) => res.status === settings.owner.updateListingOpenAssignment.SUCCESS)
        .map((res) =>
            track(Events.OWNER_UPDATED_ASSIGNMENT.create(formatAssignmentAnalytics(res.date)))
        );

    yield spawn(dispatchOpenAmenityPopup, {
        dates: [...successfulNewOpenAssignments, ...updateListingOpenAssignments],
    });

    const response = {
        FAILURE: 'FAILURE',
        SUCCESS: 'SUCCESS',
    };

    const getResponse = () => {
        if (newOpenAssignmentsHasFailure || updateOpenAssignmentsHasFailure) {
            return response.FAILURE;
        }
        return response.SUCCESS;
    };

    yield put(saveDatesDONE.create(getResponse()));
}

export function* removeListingOpenAssignments(action) {
    const deleteOpenAssignment = yield call(deleteListingOpenAssignment, action);

    const response = {
        FAILURE: 'FAILURE',
        SUCCESS: 'SUCCESS',
    };

    const getResponse = () => {
        if (deleteOpenAssignment === settings.owner.removeListingOpenAssignment.SUCCESS) {
            track(
                Events.OWNER_DELETED_ASSIGNMENT.create({
                    ...action.assignment,
                    assignmentId: action.assignment.id,
                })
            );
            return response.SUCCESS;
        }
        return response.FAILURE;
    };

    yield put(deleteDateDONE.create(getResponse()));
}

export function* createBoostAssignment({ assignment }) {
    yield put(
        apiActions.owner.boostOpenAssignment({
            data: {
                assignmentId: assignment.id,
                listingId: assignment.listingId,
            },
        })
    );

    // Wait for results
    const { status } = yield take(settings.owner.boostOpenAssignment.DONE);

    if (status === settings.owner.boostOpenAssignment.SUCCESS) {
        const assignmentInStore = yield select(getOwnerAssignment, assignment.id);
        const boostEvent = Events.USER_LISTING_EDIT_OWNER_BOOST.create(assignmentInStore);
        yield call(track, boostEvent);
    }
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeEvery((action) => action.type === saveDates.ACTION, saveListingOpenAssignments),
        takeEvery((action) => action.type === deleteDate.ACTION, removeListingOpenAssignments),
        takeEvery((action) => action.type === boostAssignment.ACTION, createBoostAssignment),
    ]);
}
