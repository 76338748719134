// eslint-disable-next-line import/prefer-default-export
export const RECOMMENDED_CAROUSEL_DETAILS = {
    create({ numberOfResults }) {
        return {
            type: 'Recommended carousel results detail',
            payload: {
                'Number of results': numberOfResults,
            },
        };
    },
};
