import { put, take, select } from 'redux-saga/effects';

import apiActions, { settings } from 'api/actions';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { MembershipType } from 'api/types/membershipTypes';

import { getMembershipType } from 'utils/account';

/**
 * Loads all sitter & owner favourite listings
 * @param wait - Allow SSR to wait for the results
 */
function* loadFavourites(wait) {
    const membership = yield select(getAccountCurrentMembershipPlan);
    const membershipType = getMembershipType(membership);

    if (membershipType === MembershipType.SITTER || membershipType === MembershipType.COMBINED) {
        yield put(apiActions.sitter.loadFavourites());
        if (wait) {
            yield take(settings.sitter.loadFavourites.DONE);
        }
    }
    if (membershipType === MembershipType.OWNER || membershipType === MembershipType.COMBINED) {
        yield put(apiActions.owner.loadFavourites());
        if (wait) {
            yield take(settings.owner.loadFavourites.DONE);
        }
    }
}

export default loadFavourites;
