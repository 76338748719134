import { settings as actions } from '../../actions';

import * as transform from '../../helpers/transform';

import { uniqueArray } from '../../helpers/format/objects';

export const initialState = {
    results: {
        current: [],
        upcoming: [],
        past: [],
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadConfirmedAssignments.SUCCESS:
            // Review structure
            return {
                ...initialState,
                results: {
                    current: action.data.current.map(assignment => assignment.id),
                    upcoming: action.data.upcoming.map(assignment => assignment.id),
                    past: action.data.past.map(assignment => assignment.id),
                },
            };
        case actions.owner.loadAssignment.SUCCESS: {
            const { id } = action.data;

            let current = state.results.current;
            let upcoming = state.results.upcoming;
            let past = state.results.past;

            let hasChanged = false;
            if (transform.isCurrentAssignment(action.data)) {
                if (current.indexOf(id) < 0) {
                    hasChanged = true;
                    current = uniqueArray([
                        ...current,
                        id,
                    ]);
                }
                if (upcoming.indexOf(id) < 0) {
                    hasChanged = true;
                    upcoming = upcoming.filter(itemId => itemId !== id);
                }
                if (past.indexOf(id) < 0) {
                    hasChanged = true;
                    past = past.filter(itemId => itemId !== id);
                }
            }
            else if (transform.isUpcomingAssignment(action.data)) {
                if (upcoming.indexOf(id) < 0) {
                    hasChanged = true;
                    upcoming = uniqueArray([
                        ...upcoming,
                        id,
                    ]);
                }
                if (current.indexOf(id) < 0) {
                    hasChanged = true;
                    current = current.filter(itemId => itemId !== id);
                }
                if (past.indexOf(id) < 0) {
                    hasChanged = true;
                    past = past.filter(itemId => itemId !== id);
                }
            }
            else if (transform.isPastAssignment(action.data)) {
                if (past.indexOf(id) < 0) {
                    hasChanged = true;
                    past = uniqueArray([
                        ...past,
                        id,
                    ]);
                }
                if (upcoming.indexOf(id) < 0) {
                    hasChanged = true;
                    upcoming = upcoming.filter(itemId => itemId !== id);
                }
                if (current.indexOf(id) < 0) {
                    hasChanged = true;
                    current = current.filter(itemId => itemId !== id);
                }
            }

            if (hasChanged) {
                return {
                    results: {
                        past,
                        upcoming,
                        current,
                    },
                };
            }

            return state;
        }
        case actions.owner.unconfirmConfirmedAssignment.SUCCESS:
            return {
                ...state,
                results: {
                    current: state.results.current.filter(assignmentId => assignmentId !== action.data.id),
                    upcoming: state.results.upcoming.filter(assignmentId => assignmentId !== action.data.id),
                    past: state.results.past.filter(assignmentId => assignmentId !== action.data.id),
                },
            };
        default:
            return state;
    }
};
