import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { withTranslation } from 'react-i18next';
import { retry } from 'utils/ssr';
import { asPageNoSagas } from 'containers/Page';
import { getAccount } from 'api/selectors/account';
import { getSplit } from 'containers/ExperimentalFeature/selectors';
import { getIsLoggedIn } from '../../../shared/selectors';

const LoadableProfile = loadable(() => retry(() => import('./Profile')));

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
    account: getAccount(state),
    split: getSplit(state),
});

export default connect(mapStateToProps)(
    asPageNoSagas(withTranslation()(LoadableProfile), 'Profile')
);
