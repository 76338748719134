import { initialState } from './reducer';

const getState = (state) => state?.containers?.leaveReviewAndFeedbackModal?.feedback;

export const getIsOpen = (state) => getState(state)?.isOpen ?? initialState.isOpen;

export const getCurrent = (state) => getState(state)?.current ?? initialState.current;

export const getLast = (state) => getState(state)?.last ?? initialState.last;

export const getAssignment = (state) => getState(state)?.assignment ?? initialState.assignment;

export const getContext = (state) => getState(state)?.context ?? initialState.context;

export const getIsSubmitting = (state) =>
    getState(state)?.isSubmitting ?? initialState.isSubmitting;
