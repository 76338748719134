// Components
import { theme } from 'theme';
import { RECOMMENDED_SITS_GROUP } from 'config/search';

export const PAGE_ID = 'SearchListings';
export const BASE_PATHNAME = '/house-and-pet-sitting-assignments/';
export const SEARCH_TYPE_MAP = 'search-listings-map';
export const SEARCH_TYPE_MAP_HIDDEN_CLUSTERS = 'hiddenClustersMapSearch';
export const SEARCH_TYPE_RESULTS = 'search-listings-results';
export const SEARCH_TYPE_FILTERS = 'search-listings-filters';
export const SEARCH_TYPE_PLACE = 'search-listings-place';
export const SEARCH_TYPE_TOTAL_AND_GEOFACETS = 'total-and-geofacets-results';
export const SEARCH_TYPE_EXTENDED_RADIUS_RESULTS = 'search-listings-extended-radius-results';
export const SEARCH_TYPE_EXTENDED_RADIUS_FILTERED_RESULTS =
    'search-listings-extended-radius-filtered-results';

export const SEARCH_PAGE_LISTINGS = 'listings';
export const PAGE_NAME_SEARCH_LISTINGS = 'searchListings';

export const NEW_SITTER_HOMEPAGE_DISMISSED = 'NEW_SITTER_HOMEPAGE_DISMISSED';

export const HAS_SEEN_LOW_APPLICANTS_PROMPT = 'hasSeenLowApplicantsPrompt';

export const SearchSettings = [
    {
        gap: '0px',
        minWidth: '0px',
        columns: ['100%'],
        rows: [['inventory']],
    },
    {
        gap: '0px',
        minWidth: `${theme.screenSize.largeDesktop}px`,
        columns: ['66%', 'auto'],
        rows: [['inventory', 'map']],
    },
];

export const RemovedMapSearchSettings = [
    {
        gap: '0px',
        minWidth: '0px',
        columns: ['100%'],
        rows: [['inventory']],
    },
];

export const InventorySettings = {
    base: {
        verticalSpacing: 24,
        horizontalSpacing: 24,
    },
    mediumDesktop: {
        verticalSpacing: 32,
        horizontalSpacing: 24,
    },
};

/**
 * Recommended sits order
 * @type {string[]}
 */
export const RECOMMENDED_SITS_ORDER = [
    RECOMMENDED_SITS_GROUP.beach,
    RECOMMENDED_SITS_GROUP.city,
    RECOMMENDED_SITS_GROUP.countryside,
    RECOMMENDED_SITS_GROUP.mountains,
];

/**
 * A search method parameter is passed in to the searchlistings url
 * This indicates whether a user is viewing a saved search or not.
 */
export const searchMethods = {
    SAVE_SEARCH: 'saveSearch',
};

export const RESULT_SCROLL_OFFSET = -120;
export const SKELETON_PLACEHOLDER_ITEMS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const VALUE_PROP_RESULT_SCROLL_OFFSET = {
    0: -166,
    [theme.screenSize.smallTablet]: -182,
    [theme.screenSize.largeDesktop]: -137,
};
export const ASPECT_RATIO_BY_BREAKPOINT = {
    default: '327 / 359',
    smallTablet: '264 / 317',
    smallDesktop: '277 / 325',
    largeDesktop: '282 / 329',
};
export const extendedGeoPointDistance = '70km';

export const DEFAULT_NEXT_PAGE = 2;

export const SEARCH_CAROUSEL_VARIANTS = {
    DISCOVERY: 'discovery',
    RECOMMENDED: 'recommended',
};
