import { useState, useEffect } from 'react';
import environment from 'environment';

import useScript from './useScript';

/**
    A hook that loads the facebook sdk and creates a callback to initialise which facebook will call once it's loaded
    and returns the following:
    - getAccessToken: returns a promise and if successful an accessToken otherwise does nothing
    - getUserData: takes the accessToken and returns a promise and if successful then the user's data from
        facebook otherwise an error
    - isInitialised: returns true or false to indicate whether facebook has been initialised yet or not

    If the Facebook script is already loaded and initialised from the Angular app or a previous use of this hook then
    there will be a fbInitialised property on window.THS to indicate this.
 */
function useFacebook() {
    const [fbLoaded, fbError] = useScript('https://connect.facebook.net/en_US/sdk.js');
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isInitialised, setInitialised] = useState(false);
    const [fbAccessToken, setAccessToken] = useState('');

    const getUserData = (accessToken) =>
        new Promise((resolve, reject) => {
            window.FB.api('/me', { fields: ['email', 'first_name'] }, (response) => {
                const { error } = response;
                if (!error) {
                    // eslint-disable-next-line camelcase
                    const { id, email, first_name } = response;
                    resolve({
                        email,
                        firstName: first_name,
                        facebookUserId: id,
                        accessToken,
                    });
                } else {
                    setAuthenticated(false);
                    reject(error);
                }
            });
        });

    const login = (reauth) => {
        const params = reauth ? { auth_type: 'rerequest' } : null;

        return new Promise((resolve) => {
            window.FB.login(
                (response) => {
                    const { status } = response;
                    if (status === 'connected') {
                        setAuthenticated(true);
                        // Resolve but we don't reject here as we don't need to trigger an error if a user doesn't
                        // login e.g. if they close the fb window. They are free to try again.
                        resolve(response.authResponse.accessToken);
                    }
                },
                {
                    scope: 'email',
                    return_scopes: true,
                    ...params,
                }
            );
        });
    };

    const getAccessToken = (reauth) =>
        new Promise((resolve) => {
            if (isAuthenticated && !reauth) {
                if (fbAccessToken) {
                    resolve(fbAccessToken);
                } else {
                    window.FB.getLoginStatus((response) => {
                        const { status } = response;
                        if (status === 'connected') {
                            setAuthenticated(true);
                            // Resolve with access token so can be passed to next function call e.g. getUserData
                            resolve(response.authResponse.accessToken);
                        } else {
                            // If not connected go straight to authenticated flow
                            resolve(login(reauth));
                        }
                    });
                }
            } else {
                // If not authenticated go straight to authenticated flow
                resolve(login(reauth));
            }
        });

    const hasGrantedEmailPermissions = (result) => {
        if (result.email) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        // Create the async callback
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: environment.vars.facebook.clientId,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v2.12', // keep this version aligned with the angular version
            });
            setInitialised(true);

            // Store that fb is initialised on the window so when navigating back & forward
            // or to another facebook integration then we can set the initialised state correctly
            // If the window.THS object does not exist then create it first
            window.THS = window.THS || {};
            window.THS.fbInitialised = true;
        };
    });

    useEffect(() => {
        if (fbLoaded && !fbError) {
            // Check the window to see if we have already initialised e.g. in angular
            if (!isInitialised && window.THS && window.THS.fbInitialised) {
                setInitialised(true);
            }

            if (!isAuthenticated) {
                window.FB.getLoginStatus((response) => {
                    const { status } = response;
                    if (status === 'connected') {
                        setAuthenticated(true);
                        setAccessToken(response.authResponse.accessToken);
                    } else {
                        setAuthenticated(false);
                    }
                });
            }
        }
    }, [fbLoaded, fbError, isInitialised, isAuthenticated]);

    return [getAccessToken, getUserData, isInitialised, hasGrantedEmailPermissions];
}

export default useFacebook;
