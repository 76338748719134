import { createAction } from 'utils/actions';

const prefix = 'USER_SAVED_SEARCH_REDIRECT';

// eslint-disable-next-line import/prefer-default-export
export const savedSearchLoadDone = createAction(
    prefix,
    'SAVED_SEARCH_LOAD_DONE',
    'searchQuery',
    'savedSearchName'
);
