import React, { useMemo } from 'react';
import { renderRoutes } from 'react-router-config';
import ZendeskWidget from 'components/ZendeskWidget';
import OwnerFlowContextProvider from './OwnerFlowContext';

const Root = ({ route, location }) => {
    const element = useMemo(
        () => renderRoutes(route.routes, {}, { location }),
        [route.routes, location]
    );

    return (
        <OwnerFlowContextProvider>
            {element}
            <ZendeskWidget optionsProps={{ isHidden: true }} />
        </OwnerFlowContextProvider>
    );
};

export default Root;
