import styled, { css } from 'styled-components';

const pageSectionStyles = css`
    padding-top: ${({ theme, top }) => (top ? theme.spacing[3] : null)};
    padding-bottom: ${({ theme, bottom }) => (bottom ? theme.spacing[3] : null)};

    padding-left: ${({ theme, fullWidth }) => (!fullWidth ? theme.spacing[3] : null)};
    padding-right: ${({ theme, fullWidth }) => (!fullWidth ? theme.spacing[3] : null)};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[6] : null)};
        padding-bottom: ${({ theme, bottom }) => (bottom ? theme.spacing[6] : null)};

        padding-left: ${({ theme }) => theme.spacing[3]};
        padding-right: ${({ theme }) => theme.spacing[3]};
    }

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[6] : null)};
        padding-bottom: ${({ theme, bottom }) => (bottom ? theme.spacing[6] : null)};

        padding-left: ${({ theme }) => theme.spacing[4]};
        padding-right: ${({ theme }) => theme.spacing[4]};
    }

    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        padding-top: ${({ theme, top }) => (top ? theme.spacing[6] : null)};
        padding-bottom: ${({ theme, bottom }) => (bottom ? theme.spacing[6] : null)};

        padding-left: ${({ theme }) => theme.spacing[5]};
        padding-right: ${({ theme }) => theme.spacing[5]};
    }
`;

const PageSectionStyled = styled.section`
    ${pageSectionStyles};
`;

export { PageSectionStyled, pageSectionStyles };
