import { settings as actions } from '../../actions';

import { basePaginatedInitialResultListState, paginationHelper } from '../common';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadConversations.SUCCESS: {
            let objectState;
            if (state[action.requestData.contactId]) {
                objectState = state[action.requestData.contactId];
            } else {
                objectState = { ...basePaginatedInitialResultListState };
            }

            const { shadowUpdate, filters } = action.action;
            const { order_by: orderBy } = filters || {};
            const { startingAfter } = action.requestData;
            // Append search results
            if (startingAfter) {
                const oldMessages = [...state[action.requestData.contactId].results];
                const newMessages = [...action.data.results];
                // Load newer
                if (orderBy && orderBy === 'send_date') {
                    newMessages.reverse();
                    return {
                        ...state,
                        [action.requestData.contactId]: {
                            ...paginationHelper(objectState, action),
                            results: [...newMessages, ...oldMessages],
                        },
                    };
                }
                // Load more
                return {
                    ...state,
                    [action.requestData.contactId]: {
                        ...paginationHelper(objectState, action),
                        results: [...oldMessages, ...newMessages],
                    },
                };
            }

            if (shadowUpdate) {
                // Only new messages to results set, if there's fixed amount of new messages
                const messages = state[action.requestData.contactId];
                if (messages && messages.length > 0 && action.data.results.length > 0) {
                    const currentLast = messages[0];

                    const exists =
                        action.data.results.filter((message) => message.id === currentLast.id)
                            .length > 0;

                    if (exists) {
                        const newMessages = [];
                        for (let a = 0; a < action.data.results.length; a += 1) {
                            const message = action.data.results[a];
                            if (message.id === currentLast.id) break;

                            newMessages.push({
                                ...message,
                            });
                        }

                        return {
                            ...state,
                            [action.requestData.contactId]: {
                                ...paginationHelper(objectState, action),
                                results: [...newMessages, ...action.data.results],
                            },
                        };
                    }
                }
            }

            return {
                ...state,
                [action.requestData.contactId]: {
                    ...paginationHelper(objectState, action),
                    results: [...action.data.results],
                },
            };
        }
        case actions.owner.createConversation.SUCCESS: {
            let objectState;
            if (state[action.requestData.contactId]) {
                objectState = state[action.requestData.contactId];
            } else {
                objectState = { ...basePaginatedInitialResultListState };
            }

            const newMessage = {
                ...action.data,
            };

            const { tmpId } = action.requestData;

            // Remove tmpId
            if (tmpId) {
                return {
                    ...state,
                    [action.requestData.contactId]: {
                        ...objectState,
                        count: objectState.count + 1,
                        results: [
                            newMessage,
                            ...objectState.results.filter((item) => item.id !== tmpId),
                        ],
                    },
                };
            }

            return {
                ...state,
                [action.requestData.contactId]: {
                    ...objectState,
                    count: objectState.count + 1,
                    results: [newMessage, ...objectState.results],
                },
            };
        }
        default:
            return state;
    }
};
