import styled from 'styled-components';
import { h2LargeStyles, h3Styles, pStyles } from 'typography';
import { IconPencilDrawing, IconExclamationCircle, IconCheck, IconInfo } from 'components/Icon';
import { Select } from 'components/forms';
import { visuallyHide } from 'utils/utils';

const LabelStyled = styled.label`
    ${visuallyHide};
`;

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing[8]};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        margin-top: 150px;
    }
`;

const ContentContainerStyled = styled.div`
    max-width: 920px;
    padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[3]} ${theme.spacing[12]}`};

    /* 800 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        padding: ${({ theme, hasBreadcrumbs }) => {
            if (hasBreadcrumbs)
                return `${theme.spacing[6]} ${theme.spacing[3]} ${theme.spacing[16]}`;

            return `${theme.spacing[12]} ${theme.spacing[3]} ${theme.spacing[16]}`;
        }};

        width: calc(10 / 12 * 100%);
        margin: 0 auto;
    }
`;

const HeadingStyled = styled.h1`
    ${h3Styles};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        ${h2LargeStyles};
        max-width: 520px;
    }

    /* 800 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[3]};
    }
`;

const PencilIconStyled = styled(IconPencilDrawing)`
    width: 3rem;
    height: 3rem;
    margin-bottom: ${({ theme }) => theme.spacing['2']};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: 4rem;
        height: 4rem;
    }
`;

const HubHeaderWrapperStyled = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: flex;
        justify-content: space-between;
    }

    /* 800 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

const SelectStyled = styled(Select)`
    min-width: 100%;

    & > select {
        text-align-last: left;
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        min-width: calc(50% - ${({ theme }) => theme.spacing[1]});
    }
`;

const PinkTextStyled = styled.div`
    ${pStyles};
    display: block;
    color: ${({ theme }) => theme.palette.accent};
`;

const DangerTextStyled = styled.div`
    ${pStyles};
    display: block;
    color: ${({ theme }) => theme.palette.danger};
`;

const GreenTextStyled = styled.div`
    ${pStyles};
    display: block;
    color: ${({ theme }) => theme.palette.primaryRegular};
`;

const IconExclamationCircleStyled = styled(IconExclamationCircle)`
    display: inline-flex;
    margin-right: ${({ theme }) => theme.spacing[0.5]};
    vertical-align: middle;
    margin-bottom: 3px;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-right: ${({ theme }) => theme.spacing[1]};
    }
`;

const IconCheckStyled = styled(IconCheck)`
    display: inline-flex;
    margin-right: ${({ theme }) => theme.spacing[0.5]};
    vertical-align: middle;
    margin-bottom: 3px;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-right: ${({ theme }) => theme.spacing[1]};
    }
`;

const IconInfoStyled = styled(IconInfo)`
    display: inline-flex;
    margin-right: ${({ theme }) => theme.spacing[0.5]};
    vertical-align: middle;
    margin-bottom: 3px;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-right: ${({ theme }) => theme.spacing[1]};
    }
`;

export {
    LabelStyled,
    SpinnerContainer,
    ContentContainerStyled,
    HeadingStyled,
    PencilIconStyled,
    HubHeaderWrapperStyled,
    SelectStyled,
    PinkTextStyled,
    DangerTextStyled,
    GreenTextStyled,
    IconExclamationCircleStyled,
    IconCheckStyled,
    IconInfoStyled,
};
