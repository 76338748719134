import { put, take, takeLatest } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import * as actions from './actions';

export function* startCBCCheck() {
    yield put(apiActions.verifications.createCBCVerification());

    const { status, data } = yield take(
        (res) => res.type === settings.verifications.createCBCVerification.DONE
    );

    if (status === settings.verifications.createCBCVerification.SUCCESS) {
        window.location.href = data.link;
    }
}

export default function* cbcCheckSaga() {
    yield takeLatest(actions.startCBCCheck.ACTION, startCBCCheck);
}
