import * as actions from './actions';

const initialState = {
    error: {},
    isSubmitting: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.showRegisterErrorMessage.ACTION:
            return {
                ...state,
                error: action.error,
            };
        case actions.setSubmitting.ACTION:
            return {
                ...state,
                isSubmitting: !state.isSubmitting,
            };
        default:
            return state;
    }
};
