import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getCountries } from 'api/selectors';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { getRawProfile, getResponseStatus, getUpdateId } from '../selectors';
import { saveProfile } from './actions';
import { PAGE_ID } from './Preferences.constants';

const LoadablePreferences = loadable(() => retry(() => import('./Preferences')));

const mapStateToProps = (state) => ({
    allCountries: getCountries(state),
    profile: getRawProfile(state),
    responseStatus: getResponseStatus(state),
    updateId: getUpdateId(state),
    isViewingInAppWebView: isViewingInAppWebView(state),
});

const mapDispatchToProps = (dispatch) => ({
    saveProfile: (profile) => {
        dispatch(saveProfile.create(profile));
    },
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadablePreferences), PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
