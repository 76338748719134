import React from 'react';
import { GRAPH_HEIGHT, GRAPH_WIDTH } from './Graph.constants';
import { WrapperStyled, PathStyled } from './Graph.style';

const Graph = ({
    width,
    height,
    values,
    preserveAspectRatio,

    /**
     * classnames passed down by styled components
     */
    className,

    ...props
}) => {
    const getPath = () => {
        // Normalise values
        const maxValue = Math.max(...values);
        const normalisedValues = values.map((value) => {
            let newValue = value;
            if (maxValue > 0) {
                newValue = parseInt((value / maxValue) * GRAPH_HEIGHT, 10);
            }
            return newValue;
        });

        const steps = normalisedValues.length - 1;
        const stepSize = GRAPH_WIDTH / steps;

        const from = 0;
        const to = GRAPH_WIDTH;

        let currentStep = Math.floor(from / stepSize) * stepSize;
        const pathPoints = [
            {
                type: 'M',
                point: {
                    x: 0,
                    y: GRAPH_HEIGHT,
                },
            },
        ];

        while (currentStep < to) {
            currentStep += stepSize;
            currentStep = Math.min(currentStep, to);
            const stepIndex = Math.ceil(currentStep / stepSize);
            const step = stepIndex * stepSize;
            // prettier-ignore
            const midStep = step - (stepSize / 2);
            const value = GRAPH_HEIGHT - normalisedValues[stepIndex];
            const prevValue = stepIndex === 1 ? 0 : GRAPH_HEIGHT - normalisedValues[stepIndex - 1];
            pathPoints.push({
                type: 'C',
                point: {
                    x: currentStep,
                    y: value,
                },
                cp1: {
                    x: midStep,
                    y: prevValue,
                },
                cp2: {
                    x: midStep,
                    y: value,
                },
            });

            if (currentStep === to) {
                // down
                pathPoints.push({
                    type: 'L',
                    point: {
                        x: currentStep,
                        y: GRAPH_HEIGHT,
                    },
                });
                // back to the start
                pathPoints.push({
                    ...pathPoints[0],
                });
                pathPoints[pathPoints.length - 1].type = 'L';
            }
        }

        const pathPointsString = pathPoints.map((bp) => {
            if (bp.type === 'M') {
                return `M${bp.point.x} ${bp.point.y}`;
            }
            if (bp.type === 'C') {
                return `C${bp.cp1.x},${bp.cp1.y} ${bp.cp2.x},${bp.cp2.y} ${bp.point.x},${bp.point.y}`;
            }
            if (bp.type === 'L') {
                return `L${bp.point.x} ${bp.point.y}`;
            }
            return '';
        });

        return pathPointsString.join(' ');
    };

    // Do not render if there's nothing to render
    if (!values || (values && !values.length)) return null;

    return (
        <WrapperStyled className={className || ''}>
            <svg
                height={height}
                width={width}
                viewBox={`0 0 ${GRAPH_WIDTH} ${GRAPH_HEIGHT}`}
                preserveAspectRatio={preserveAspectRatio}
                {...props}
            >
                <PathStyled d={getPath()} stroke={null} />
            </svg>
        </WrapperStyled>
    );
};

Graph.defaultProps = {
    width: '100%',
    height: GRAPH_HEIGHT,
    preserveAspectRatio: 'none',
};

export default Graph;
