import PropTypes from 'prop-types';

import {
    baseMediaPhoto,
    baseMediaVideo,
    baseLocation,
    baseNotification,
    baseAssignmentProps,
    baseWelcomeGuideStatus,
    baseReview,
    baseFeedback,

    baseContactProps,
    basePlaceOfInterest,
    baseContact,

    baseProfile,
    baseListing,
    baseReminder,
    baseConversation, baseReference,
} from './common';


/**
 * Assignment
 */
export const assignmentProps = {
    ...baseAssignmentProps,

    listingLocation: baseLocation,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    profileLocation: baseLocation,
    ownerName: PropTypes.string,
    listingMainPhoto: baseMediaPhoto,
    ownerAvatarPhoto: baseMediaPhoto,
    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,

    welcomeGuideStatus: baseWelcomeGuideStatus,

    review: baseReview,
    feedback: baseFeedback,

    selectedAnimals: PropTypes.number,
};


/**
 * Application
 */
export const applicationProps = {
    id: PropTypes.string,
    assignmentId: PropTypes.string,
    ownerId: PropTypes.string,
    sitterId: PropTypes.string,
    listingId: PropTypes.string,
    profileId: PropTypes.string,
    assignment: PropTypes.shape({
        id: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        listingId: PropTypes.string,
        ownerId: PropTypes.string,
        isApproximateDates: PropTypes.bool,
        isReviewing: PropTypes.bool,
    }),
    hasOwnerConfirmed: PropTypes.bool,
    hasSitterConfirmed: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    hasAssignmentDatesChanged: PropTypes.bool,
    isInvited: PropTypes.bool,
    isShortlisted: PropTypes.bool,
    hasOwnerDeclined: PropTypes.bool,
    hasOwnerCancelled: PropTypes.bool,
    hasSitterDeclined: PropTypes.bool,
    hasSitterCancelled: PropTypes.bool,
    ownerName: PropTypes.string,
    listingMainPhoto: baseMediaPhoto,
    listingLocation: baseLocation,
    ownerAvatarPhoto: baseMediaPhoto,
    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,
};
export const application = PropTypes.shape(applicationProps);

export const applications = PropTypes.shape({
    confirmed: application,
    invitations: PropTypes.arrayOf(application),
    applications: PropTypes.arrayOf(application),
    declined: PropTypes.arrayOf(application),
});
export const applicationsList = PropTypes.arrayOf(application);


/**
 * Application
 */
export const invitationProps = {
    ...applicationProps,
    isInvited: true,
};
export const invitation = PropTypes.shape(invitationProps);
export const invitations = PropTypes.arrayOf(invitation);


/**
 * Confirmed Assignments
 */
export const confirmedAssignmentProps = {
    ...baseAssignmentProps,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    profileLocation: baseLocation,
    listingLocation: baseLocation,
    ownerName: PropTypes.string,
    listingMainPhoto: baseMediaPhoto,
    ownerAvatarPhoto: baseMediaPhoto,
    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,

    welcomeGuideStatus: baseWelcomeGuideStatus,
};
export const confirmedAssignment = PropTypes.shape(confirmedAssignmentProps);

export const currentAssignmentProps = {
    ...baseAssignmentProps,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    listingLocation: baseLocation,
    profileLocation: baseLocation,
    ownerName: PropTypes.string,
    listingMainPhoto: baseMediaPhoto,
    ownerAvatarPhoto: baseMediaPhoto,
    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,

    welcomeGuideStatus: baseWelcomeGuideStatus,
};
export const currentAssignment = PropTypes.shape(currentAssignmentProps);
export const currentAssignments = PropTypes.arrayOf(currentAssignment);

export const upcomingAssignmentProps = {
    ...currentAssignmentProps,
};
export const upcomingAssignment = PropTypes.shape(upcomingAssignmentProps);

export const pastAssignmentProps = {
    ...baseAssignmentProps,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    profileLocation: baseLocation,
    ownerName: PropTypes.string,
    listingMainPhoto: baseMediaPhoto,
    ownerAvatarPhoto: baseMediaPhoto,

    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,

    review: baseReview,
    feedback: baseFeedback,
    reviewRequest: PropTypes.shape({
        sentAt: PropTypes.string,
        message: PropTypes.string,
    }),
    feedbackRequest: PropTypes.shape({
        sentAt: PropTypes.string,
        message: PropTypes.string,
    }),

    selectedAnimals: PropTypes.number,
};
export const pastAssignment = PropTypes.shape(pastAssignmentProps);
export const pastAssignments = PropTypes.arrayOf(pastAssignment);


export const welcomeGuideProps = {
    id: PropTypes.string,
    listingId: PropTypes.string,
    dateModified: PropTypes.string,
    address: PropTypes.string,
    contacts: PropTypes.arrayOf(baseContact),
    howToGetThere: PropTypes.string,
    kitchenAppliances: PropTypes.arrayOf(PropTypes.oneOf([
        'gas', 'electric', 'cooker', 'hob', 'microwave', 'dishwasher', 'washingMachine', 'tumbleDryer',
        'iron', 'outdoorDryingSpace', 'vacuumCleaner', 'hover',
    ])),
    kitchenAppliancesOther: PropTypes.string,
    kitchenSpecialInstructions: PropTypes.string,
    heatingInstructions: PropTypes.string,
    waterInstructions: PropTypes.string,
    electricityInstructions: PropTypes.string,
    garbageAndRecyclingInstructions: PropTypes.string,
    cleaningInstructions: PropTypes.string,
    hasCleaner: PropTypes.bool,
    cleanerDetails: PropTypes.string,
    roomsInstructions: PropTypes.string,
    roomsExtraFeatures: PropTypes.arrayOf(PropTypes.oneOf([
        'pool', 'jacuzzi', 'bathtub',
    ])),
    roomsSpecialInstructions: PropTypes.string,
    hasFirstAidKit: PropTypes.bool,
    firstAidKitInstructions: PropTypes.string,
    houseRules: PropTypes.string,
    toysAndActivities: PropTypes.string,
    homeExtraInformation: PropTypes.string,
    doorsAndWindowsInstructions: PropTypes.string,
    spareKeysContacts: baseContact,
    securityAlarmInstructions: PropTypes.string,
    fireAlarmInstructions: PropTypes.string,
    securityExtraInformation: PropTypes.string,
    wifiSpeed: PropTypes.oneOf(['highSpeed', 'basic', 'none']),
    wifiDetails: PropTypes.string,
    phoneDetails: PropTypes.string,
    phoneSignal: PropTypes.oneOf(['good', 'moderate', 'poor', 'nonExisting']),
    audioDetails: PropTypes.string,
    tvDetails: PropTypes.string,
    hasHomeOffice: PropTypes.bool,
    desktopAvailable: PropTypes.string,
    desktopInstructions: PropTypes.string,
    hifiExtraInformation: PropTypes.string,
    indoorPlantsInstructions: PropTypes.string,
    outdoorSpaceDetails: PropTypes.arrayOf(PropTypes.oneOf([
        'garden', 'patio', 'terrace', 'balcony', 'communalGardens',
    ])),
    gardeningInstructions: PropTypes.string,
    hasGardener: PropTypes.bool,
    gardenerDetails: PropTypes.string,
    gardenExtraInformation: PropTypes.string,
    postDetails: PropTypes.string,
    photos: PropTypes.arrayOf(baseMediaPhoto),
    videos: PropTypes.arrayOf(baseMediaVideo),
    veterinaryInformation: PropTypes.shape({
        ...baseContactProps,
        website: PropTypes.string,
    }),
    carNeeded: PropTypes.bool,
    useOfCar: PropTypes.bool,
    carDetails: PropTypes.string,
    carInsuranceDetails: PropTypes.string,
    carParking: PropTypes.string,
    busDetails: PropTypes.string,
    trainDetails: PropTypes.string,
    taxiDetails: PropTypes.string,
    carHireDetails: PropTypes.string,
    shoppingForFood: PropTypes.arrayOf(basePlaceOfInterest),
    restaurantsAndPubs: PropTypes.arrayOf(basePlaceOfInterest),
    favouriteCafes: PropTypes.arrayOf(basePlaceOfInterest),
    niceWalks: PropTypes.arrayOf(basePlaceOfInterest),
    localAttractions: PropTypes.arrayOf(basePlaceOfInterest),
    neighbourhoodExtraInformation: PropTypes.string,
    nominatedPeople: PropTypes.arrayOf(baseContact),
    hospitalDetails: PropTypes.shape({
        ...baseContactProps,
        website: PropTypes.string,
    }),
    pharmacyDetails: PropTypes.shape({
        ...baseContactProps,
        website: PropTypes.string,
    }),
    dentistDetails: PropTypes.shape({
        ...baseContactProps,
        website: PropTypes.string,
    }),
    repairsContactDetails: PropTypes.string,
    policeContactDetails: PropTypes.string,
};
export const welcomeGuide = PropTypes.shape(welcomeGuideProps);

export const listingPetProps = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    animalSlug: PropTypes.string,
    yearOfBirth: PropTypes.number,
    breedName: PropTypes.string,
    photos: PropTypes.array,
    videos: PropTypes.array,
    generalInformation: PropTypes.string,
    healthInformation: PropTypes.string,
    feedingInstructions: PropTypes.string,
    personality: PropTypes.array,
    personalityOther: PropTypes.string,
    personalityExtra: PropTypes.string,
    exerciseAndPlay: PropTypes.string,
    houseRules: PropTypes.string,
    toiletAndGrooming: PropTypes.string,
    cleaning: PropTypes.string,
    likesDislikesInformation: PropTypes.string,
    otherInformation: PropTypes.string,
};

export const listingPet = PropTypes.shape(listingPetProps);
export const listingPets = PropTypes.arrayOf(listingPet);

// OLD
/**
 * Sitter
 * @TODO
 */
export const sitter = {
};

/**
 * Sitter Favourites
 */
export const favourite = PropTypes.shape(baseListing);
export const favourites = PropTypes.arrayOf(favourite);

/**
 * Sitter Feedbacks
 */
export const feedback = PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    reply: PropTypes.string,
    provided: PropTypes.string,
    requested: PropTypes.bool,
    request: PropTypes.shape({
        message: PropTypes.string,
        completedDate: PropTypes.string,
    }),
    recommended: PropTypes.number,
    sitter: PropTypes.shape(baseProfile),
});
export const feedbacks = PropTypes.arrayOf(feedback);

/**
 * Sitter Reviews
 */
export const review = PropTypes.shape({
    id: PropTypes.number,
    reply: PropTypes.string,
    description: PropTypes.string,
    provided: PropTypes.string,
    requested: PropTypes.bool,
    request: PropTypes.shape({
        message: PropTypes.string,
        completedDate: PropTypes.string,
    }),
    review: PropTypes.shape({
        organised: PropTypes.number,
        reliable: PropTypes.number,
        selfSufficient: PropTypes.number,
        tidy: PropTypes.number,
        petCare: PropTypes.number,
    }),
    listing: PropTypes.shape(baseListing),
});
export const reviews = PropTypes.arrayOf(review);


/**
 * Sitter Assignments
 */
export const assignment = PropTypes.shape({
    ...baseAssignmentProps,
    welcomeGuide: PropTypes.shape({
        requested: PropTypes.string,
        shared: PropTypes.string,
    }),
    reminders: PropTypes.arrayOf(PropTypes.shape(baseReminder)),
});

export const current = PropTypes.shape(assignment);
export const upcoming = PropTypes.arrayOf(PropTypes.shape(assignment));
export const past = PropTypes.arrayOf(pastAssignment);

export const confirmedAssignments = {
    current,
    upcoming,
    past,
};

/**
 * Sitter Availability
 */
export const availability = PropTypes.shape({
    id: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    approximateDates: PropTypes.bool,
    dateCreated: PropTypes.string,
    lastModified: PropTypes.string,
    available: PropTypes.bool,
    date: PropTypes.string,
    listing: PropTypes.shape(baseListing),
});
export const availabilities = PropTypes.arrayOf(availability);

/**
 * Sitter Conversations
 */
export const conversations = PropTypes.arrayOf(PropTypes.shape(baseConversation));

/**
 * Sitter Profile Verifications
 */
export const verifications = {
    basic: PropTypes.shape({
        email: PropTypes.shape({
            status: PropTypes.oneOf(['not_requested', 'requested', 'pending', 'verified']),
        }),
        phone: PropTypes.shape({
            status: PropTypes.oneOf(['not_requested', 'requested', 'pending', 'verified']),
            number: PropTypes.string,
        }),
        external: PropTypes.shape({
            status: PropTypes.oneOf(['not_requested', 'requested', 'pending', 'verified']),
            count: PropTypes.number,
            pending: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string,
                email: PropTypes.string,
                requestMessage: PropTypes.string,
                referenceType: PropTypes.string,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                phone: PropTypes.string,
            })),
        }),
    }),
    standard: PropTypes.shape({
        identityCheck: PropTypes.shape({
            checkId: PropTypes.string,
            status: PropTypes.oneOf(['not_requested', 'requested', 'pending', 'verified']),
            identity: PropTypes.shape({
                status: PropTypes.oneOf(['not_requested', 'requested', 'pending', 'verified']),
                slvCreatedAt: PropTypes.string,
            }),
            document: PropTypes.shape({
                status: PropTypes.oneOf(['not_requested', 'requested', 'pending', 'verified']),
            }),
        }),
    }),
    enhanced: PropTypes.shape({
        criminalCheck: PropTypes.shape({
            checkId: PropTypes.string,
            status: PropTypes.oneOf(['not_requested', 'requested', 'pending', 'verified']),
            price: PropTypes.number,
        }),
    }),
};

/**
 * Sitter Profile
 */
export const profileProps = {
    id: PropTypes.string,
    animals: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
    })),
    birthDate: PropTypes.string,
    children: PropTypes.array,
    completedSteps: PropTypes.any,
    experience: PropTypes.bool,
    favouriteCountries: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
    })),
    hasExperience: PropTypes.bool,
    introduction: PropTypes.string,
    isIncomplete: PropTypes.bool,
    isPartiallyFilled: PropTypes.bool,
    localAttractions: PropTypes.arrayOf(PropTypes.string),
    location: baseLocation,
    mainPhoto: baseMediaPhoto,
    motivation: PropTypes.string,
    occupation: PropTypes.string,
    occupationType: PropTypes.string,
    partner: PropTypes.shape({
        firstName: PropTypes.string,
        sex: PropTypes.string,
        birthDate: PropTypes.string,
        occupation: PropTypes.string,
        occupationType: PropTypes.string,
    }),
    pastAssignments,
    photos: PropTypes.arrayOf(baseMediaPhoto),
    policeCheck: PropTypes.any,
    referencesAverageScore: PropTypes.number,
    references: PropTypes.arrayOf(baseReference),
    referencesCount: PropTypes.number,
    relevantExperience: PropTypes.string,
    reviewsAverageScore: PropTypes.number,
    reviewsCount: PropTypes.number,
    sex: PropTypes.string,
    sitterAvatarPhoto: baseMediaPhoto,
    sitterId: PropTypes.string,
    sitterName: PropTypes.string,
    title: PropTypes.string,
    videos: PropTypes.array,
    withChildren: PropTypes.bool,
};
export const profile = PropTypes.shape(profileProps);

/**
 * Sitter Profile Medias
 // @TODO
 */
export const media = PropTypes.shape({
    id: PropTypes.number,
});
export const medias = PropTypes.arrayOf(media);


/**
 * Sitter - Owner listing
 */
export const sitterOwnerListing = PropTypes.shape({
    ...baseListing,
});
