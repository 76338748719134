import SettingsHub from './hub';
import AccountDetails from './account';
import ContactPreferences from './contactPreferences';
import paths from './route-paths';

export default [
    {
        path: paths.settings.hub,
        exact: true,
        component: SettingsHub,
    },
    {
        path: paths.settings.details,
        exact: true,
        component: AccountDetails,
    },
    {
        path: paths.settings.contactPreferences,
        exact: true,
        component: ContactPreferences,
    },
];
