import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import { createListingsSearchQuery } from 'utils/searchListings';
import { base64encode } from 'utils/strings';

import { buildPathName } from '../selectors';
import { getQueryOptionsAndRelatedURLProperties } from '../helpers';
/**
 * Update the url in the browser (this will trigger a load event to actually hit the api)
 *
 * @param params object - url params
 * @param filters object - search filters
 * @param page - searched page
 * @returns {IterableIterator<*|PutEffect<Action>>}
 */
function* navigateTo(params, filters, page = 1, searchMethod) {
    let pathname;
    function hasLocationInFilters(searchFilters) {
        if (
            searchFilters.geoBounds ||
            searchFilters.geoPoint ||
            searchFilters.worldwide ||
            searchFilters.geoHierarchy
        ) {
            return true;
        }
        return false;
    }

    // If location is in filters we clean
    // geohierarchy in the url
    if (hasLocationInFilters(filters)) {
        pathname = buildPathName();
    } else {
        pathname = buildPathName(params);
    }

    const searchQuery = createListingsSearchQuery({
        searchPastAssignments: false,
        page,
        filters,
        searchPastAndFutureAssignments: true,
    });

    let queryString = `?q=${base64encode(JSON.stringify(searchQuery.getRequestData()))}`;

    if (searchMethod) {
        queryString = `${queryString}&searchMethod=${searchMethod}`;
    }

    // THIS IS FOR THE CHRISTMAS SITS FEATURE
    const { christmasSits } = getQueryOptionsAndRelatedURLProperties();
    if (christmasSits || filters.isChristmasSits) {
        if (filters.isChristmasSits !== false) {
            queryString = `${queryString}&christmasSits=true`;
        }
    }

    // THIS IS FOR THE QUICK SEARCH AB TEST web_exp_quick_location_search QUICK_LOCATION_SEARCH
    if (filters.searchType) {
        queryString = `${queryString}&searchType=${filters.searchType}`;
    }
    yield put(
        push({
            pathname,
            search: queryString,
        })
    );
}

export { navigateTo as default };
