import * as React from 'react';
import { IconWrapper } from './Icon.style';

function Icon({ className, children }) {
    return <IconWrapper className={className}>{children}</IconWrapper>;
}

Icon.defaultProps = {
    className: '',
};

export default Icon;
