import React from 'react';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import asPage from 'containers/Page';
import ExperimentalFeature, { experiments, userTypes } from 'containers/ExperimentalFeature';
import { Redirect } from 'react-router';
import { routes } from 'utils/routes';
import { retry } from 'utils/ssr';
import { PAGE_ID } from './SearchListings.constants';
import { mapStateToProps, mapDispatchToProps } from './mapStateAndDispatch';
import { pageViewParamType } from '../constants';

const LoadableSearchListings = loadable(() => retry(() => import('./SearchListings')));

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
const ExperimentalComponent = (props) => {
    const listingsBaseURL = routes.search.listings();
    const {
        location: { pathname, search, hash },
        isSitDiscoveryVariation,
    } = props;

    const pageViewParamRegex = /(\?|&)pageView=discovery/i;
    const hasPageViewParam = pageViewParamRegex.test(search);

    // make sure to only trigger the experimental feature if a user:
    //   - is on the base url
    //   - there isn't a anything in search e.g. a query param
    // for some reason when making a check for either a ?q or ?pageView param it causes the page to not work
    // so have to rely on the search property being empty
    // and do a separate empty ExperimentalFeature in SearchListings to enable test tracking
    // so that it catches wizard journey as well
    return pathname === listingsBaseURL && search.length < 1 ? (
        <ExperimentalFeature
            analytics={false}
            experiment={experiments.SIT_DISCOVERY_PAGE}
            control={<LoadableSearchListings {...props} />}
            variation1={
                <Redirect
                    to={{
                        pathname: listingsBaseURL,
                        search: `?pageView=${pageViewParamType.DISCOVERY}`,
                        hash,
                    }}
                />
            }
            excludeCombo={[userTypes.PaidUser, userTypes.ExpiredUser]}
        />
    ) : (
        <>
            {/* remove ?pageView if in the control variation of the SIT_DISCOVERY_PAGE test  */}
            {hasPageViewParam && !isSitDiscoveryVariation ? (
                <Redirect
                    to={{
                        pathname: listingsBaseURL,
                        search: search.replace(pageViewParamRegex, ''),
                        hash,
                    }}
                />
            ) : (
                <LoadableSearchListings {...props} />
            )}
        </>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(asPage(ExperimentalComponent, PAGE_ID, true, true));
