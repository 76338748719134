import { settings as actions } from '../../actions';

// FIXME: This reducer pretends it will have the shape of basePaginatedInitialResultListState
//  but what the api returns looks nothing like that, and the cases that are handled do nothing to preserve the shape.
import {
    basePaginatedInitialResultListState,
    loadedPaginatedResultsUniqueListReducer,
} from '../common';

export const initialState = { ...basePaginatedInitialResultListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadConfirmedAssignments.SUCCESS:
            if (action.data.current) {
                return {
                    ...state,
                    results: action.data.current.map((assignment) => assignment.id),
                };
            }
            return state;
        case actions.sitter.loadCurrentAssignments.SUCCESS:
            return loadedPaginatedResultsUniqueListReducer(state, action);
        default:
            return state;
    }
};
