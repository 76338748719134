import React from 'react';
import { IconChevronRight, IconChevronLeft } from 'components/Icon';
import helper from '../helper';
import {
    PaginationStyled,
    InactivePaginationItemStyled,
    ActivePaginationItemStyled,
    PaginationItemStyled,
} from '../Pagination.style';
import {
    InactiveRouterPaginationItemLinkStyled,
    ActiveRouterPaginationItemLinkStyled,
    RouterPaginationChevronLinkStyled,
} from './RouterPagination.style';

const RouterPagination = ({
    currentPage,
    totalItems,
    itemsPerPage,
    maxPagesToDisplay,
    url,
    onItemClick,
    ariaPageLabel,
    ariaCurrentPageLabel,
    ariaPreviousPageLabel,
    ariaNextPageLabel,
    ...props
}) => {
    const { displayedPages, showPrevious, showNext } = helper.getPageMetadata(
        currentPage,
        totalItems,
        itemsPerPage,
        maxPagesToDisplay
    );
    const itemClickHandler = (page) => onItemClick && onItemClick(page);

    const pageLinks = displayedPages.map((page) => {
        const current = page === currentPage;
        const Item = current ? ActivePaginationItemStyled : InactivePaginationItemStyled;
        const ItemLink = current
            ? ActiveRouterPaginationItemLinkStyled
            : InactiveRouterPaginationItemLinkStyled;
        const testid = current ? 'RouterPagination_item_selected' : 'RouterPagination_item';
        const ariaProps = current
            ? {
                  'aria-label': ariaCurrentPageLabel(page), // `Current Page, Page ${page}`
                  'aria-current': true,
              }
            : {
                  'aria-label': ariaPageLabel(page), // `Goto Page ${page}`,
              };

        return (
            <Item key={page} data-testid={testid}>
                <ItemLink to={url(page)} onClick={() => itemClickHandler(page)} {...ariaProps}>
                    {page}
                </ItemLink>
            </Item>
        );
    });

    return (
        <PaginationStyled {...props}>
            <PaginationItemStyled data-testid="RouterPagination_previous">
                {showPrevious && (
                    <RouterPaginationChevronLinkStyled
                        to={url(currentPage - 1)}
                        onClick={() => itemClickHandler(currentPage - 1)}
                        aria-label={ariaPreviousPageLabel}
                        rel="prev"
                    >
                        <IconChevronLeft size="medium" palette="text" />
                    </RouterPaginationChevronLinkStyled>
                )}
            </PaginationItemStyled>
            {pageLinks}
            <PaginationItemStyled data-testid="RouterPagination_next">
                {showNext && (
                    <RouterPaginationChevronLinkStyled
                        to={url(currentPage + 1)}
                        onClick={() => itemClickHandler(currentPage + 1)}
                        aria-label={ariaNextPageLabel}
                        rel="next"
                    >
                        <IconChevronRight size="medium" palette="text" />
                    </RouterPaginationChevronLinkStyled>
                )}
            </PaginationItemStyled>
        </PaginationStyled>
    );
};

export default RouterPagination;
