/* constants */
const ctrl = 'control';
const var1 = 'variation1';
const var2 = 'variation2';

const variations = [ctrl, var1, var2];

const SplitCount = 72;

/* output */
const ABTest1 = [];
const ABTest2 = [];
const ABTest3 = [];
const ABCTest1 = [];
const ABCTest2 = [];
const ABCTest3 = [];

const result = {
    ab: [ABTest1, ABTest2, ABTest3],
    abc: [ABCTest1, ABCTest2, ABCTest3],
};
/* eslint-disable no-plusplus */

/* ABtests */
variations.slice(0, 2).forEach((variation) => {
    for (let j = SplitCount / 2; j--; ) {
        ABTest1.push(variation);
    }
});

for (let i = 2; i--; ) {
    variations.slice(0, 2).forEach((variation) => {
        for (let j = SplitCount / 4; j--; ) {
            ABTest2.push(variation);
        }
    });
}

for (let i = 4; i--; ) {
    variations.slice(0, 2).forEach((variation) => {
        for (let j = SplitCount / 8; j--; ) {
            ABTest3.push(variation);
        }
    });
}

/* ABtests */
for (let i = 8; i--; ) {
    variations.forEach((variation) => {
        for (let j = SplitCount / 24; j--; ) {
            ABCTest1.push(variation);
        }
    });
}

for (let i = 24; i--; ) {
    variations.forEach((variation) => {
        for (let j = SplitCount / 72; j--; ) {
            ABCTest2.push(variation);
        }
    });
}

variations.forEach((variation) => {
    for (let j = SplitCount / 3; j--; ) {
        ABCTest3.push(variation);
    }
}); // ABCTest3 does not match the other patterns due to being the fallback for legacy ABC tests
/* eslint-enable no-plusplus */

export default result;
