import { settings as actions } from '../../actions';

// FIXME: This reducer pretends it will have the shape of basePaginatedInitialResultListState
//  but what the api returns looks nothing like that, and the cases that are handled do nothing to preserve the shape.
import {
    basePaginatedInitialResultListState,
    loadedPaginatedResultsUniqueListReducer,
} from '../common';

export const initialState = { ...basePaginatedInitialResultListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadConfirmedAssignments.SUCCESS:
            return {
                ...state,
                results: action.data.past.map((assignment) => assignment.id),
            };
        case actions.sitter.loadPastAssignments.SUCCESS:
            return loadedPaginatedResultsUniqueListReducer(state, action);
        default:
            return state;
    }
};
