import React, { useState, useEffect, useRef } from 'react';

const HappiestPetsCompetition2024 = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (typeof document === 'undefined') {
            return undefined;
        }

        if (!containerRef.current) {
            return undefined;
        }

        // https://help.woobox.com/articles/embedding-campaigns-on-your-website-as-js/
        const element = document.createElement('script');
        element.id = 'woobox-sdk';
        element.src = '//woobox.com/js/plugins/woo.js';

        containerRef.current.parentNode.appendChild(element);

        return () => {
            if (typeof window !== 'undefined') {
                // Need to set Woo to undefined to fix page navigation when
                // destroying the root element.
                window.Woo = undefined;
            }

            if (typeof document !== 'undefined') {
                // Remove all woobox sdk elements
                document.querySelectorAll('[id^="woobox-sdk"]').forEach((el) => {
                    el.remove();
                });
            }
        };
    }, []);

    return (
        <div>
            <div ref={containerRef} id="woobox-root" />
            <div className="woobox-offer" data-offer="q34cdr" />
        </div>
    );
};

export default HappiestPetsCompetition2024;
