import styled from 'styled-components';
import { MainContainerStyled } from '../Root/Root.style';

export const CounterDisplayButton = styled.button`
    all: unset;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 28px;
    width: 28px;
    border-radius: 50%;

    background-color: ${({ theme }) => theme.palette.grey[100]};

    -webkit-tap-highlight-color: transparent;

    ${MainContainerStyled}[data-status='filled'] & {
        background-color: ${({ theme }) => theme.palette.primaryRegular}1a;
        color: ${({ theme }) => theme.palette.primaryDark};
    }
`;

export const DecrementButton = styled(CounterDisplayButton)`
    grid-area: decrement;
`;

export const IncrementButton = styled(CounterDisplayButton)`
    grid-area: increment;
`;
