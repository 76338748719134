// eslint-disable-next-line import/prefer-default-export
export const USER_WELCOME_GUIDE_SHARED = {
    create(listingId, assignmentId) {
        return {
            type: 'Welcome Guide Shared',
            payload: {
                listingId,
                assignmentId,
            },
        };
    },
};
