import Register from 'containers/forms/Register';

export const STEPS = {
    SELECT_MEMBERSHIP: 'select-membership',
    INITIAL_FORM: 'initial-form',
    EMAIL_FORM: 'email-form',
    THIRD_PARTY_FORM: 'third-party-form',
};

export const OpenMethod = {
    TIME_DELAY: 'Time Delay',
    HERO_JOIN_NOW: 'Hero Join Now',
    READY_TO_GET_STARTED: 'Ready To Get Started',
    OWNER_MONEY_BACK_GUARANTEE: 'Find A Sitter Money Back Guarantee Join Now',
    SIT_HERO_JOIN_NOW: 'Find A Sit Hero Join Now',
    NAV_BAR: 'Nav Bar',
    NAV_BAR_MENU: 'Nav Bar Menu',
    PRISMIC_LANDING_PAGE: 'Prismic Landing Page',
    PRICING_PAGE: 'Pricing page',
    FIND_A_SITTER_WIZARD: 'Find a Sitter Wizard',
    FIND_A_SIT_WIZARD: 'Find a Sit Wizard',
    FIND_A_SITTER_NAVBAR_LINK: 'Find a Sitter Nav Bar Link',
    FIND_A_SIT_NAVBAR_LINK: 'Find a Sit Nav Bar Link',
    FREE_TRIAL_BANNER: 'Free Trial Banner',
};

export const RegwallTestIds = {
    chooseMethod: 'Regwall_chooseMethod',
    register: 'Regwall_register',
    registerFacebook: 'Regwall_registerFacebook',
    facebookForm: 'Regwall_facebookForm',
    registerGoogle: 'Regwall_registerGoogle',
    googleForm: 'Regwall_googleForm',
    emailForm: 'Regwall_emailForm',
    heading: 'Regwall_heading',
    introParagraph: 'Regwall_introParagraph',
    buttonContainer: 'Regwall_buttonContainer',
    googleButton: 'Regwall_googleButton',
    googleButtonIcon: 'Regwall_googleButtonIcon',
    googleButtonError: 'Regwall_googleButtonError',
    facebookButton: 'Regwall_facebookButton',
    facebookButtonError: 'Regwall_facebookButtonError',
    divider: 'Regwall_divider',
};

export const RegwallStates = {
    EMAIL: Register.Method.EMAIL,
    FACEBOOK: Register.Method.FACEBOOK,
    GOOGLE: Register.Method.GOOGLE,
};
