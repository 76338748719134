import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getOwnerListingAmenitiesRaw, getOwnerListingAmenitiesStatus } from 'api/selectors/owner';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import * as appMessaging from 'utils/app/messaging';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { getRawListing } from '../selectors';
import { PAGE_ID } from './Home.constants';
import { saveListing, saveListingAmenities, saveListingAnalytics } from './actions';
import { getResponseStatus, getUpdateId } from './selectors';

const LoadableHomeListing = loadable(() => retry(() => import('./Home')));

const mapStateToProps = (
    state,
    {
        match: {
            params: { listingId },
        },
    }
) => ({
    listing: getRawListing(state),
    amenities: {
        data: getOwnerListingAmenitiesRaw(state, listingId),
        status: getOwnerListingAmenitiesStatus(state, listingId),
    },
    responseStatus: getResponseStatus(state),
    updateId: getUpdateId(state),
    isViewingInAppWebView: isViewingInAppWebView(state),
});

const mapDispatchToProps = (dispatch) => ({
    saveListing: ({ oldListing, newListing, amenities, isEditingAmenitiesOnApp = false }) => {
        dispatch(saveListing.create(oldListing, newListing));
        dispatch(
            saveListingAmenities.create(oldListing.id, {
                ...amenities,
            })
        );
        dispatch(saveListingAnalytics.create(newListing, amenities));

        if (isEditingAmenitiesOnApp && appMessaging.isEnabled()) {
            appMessaging.postMessage(appMessaging.Messages.CLOSE_WEBVIEW.create());
        }
    },
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableHomeListing), PAGE_ID, true, false, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
