export const TRANSLATION_KEY = 'design';

export interface CountryStates {
    [key: string]: string;
}

export const UNITED_STATES: CountryStates = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virgina': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
    'American Samoa': 'AS',
    'District of Columbia': 'DC',
    'Federated States of Micronesia': 'FM',
    Guam: 'GU',
    'Marshall Islands': 'MH',
    'Northern Mariana Islands': 'MP',
    Palau: 'PW',
    'Puerto Rico': 'PR',
    'Virgin Islands': 'VI',
};

export const CANADA_STATES: CountryStates = {
    Alberta: 'AB',
    'British Columbia': 'BC',
    Manitoba: 'MB',
    'New Brunswick': 'NB',
    'Newfoundland and Labrador': 'NL',
    'Nova Scotia': 'NS',
    'Northwest Territories': 'NT',
    Nunavut: 'NU',
    Ontario: 'ON',
    'Prince Edward Island': 'PE',
    Quebec: 'QC',
    Saskatchewan: 'SK',
    Yukon: 'YT',
};

export const AUSTRALIA_STATES: CountryStates = {
    'New South Wales': 'NSW',
    Queensland: 'QLD',
    'South Australia': 'SA',
    Tasmania: 'TAS',
    Victoria: 'VIC',
    'Western Australia': 'WA',

    'State of New South Wales': 'NSW',
    'State of Queensland': 'QLD',
    'State of South Australia': 'SA',
    'State of Tasmania': 'TAS',
    'State of Victoria': 'VIC',
    'State of Western Australia': 'WA',
};

export const LOW_APPLICANTS_LIMIT = 2;
