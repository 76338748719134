import { select, put, call, take } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { createListingsSearchQuery } from 'utils/searchListings';
import { createProfilesSearchQuery } from 'utils/searchProfiles';
import { checkFacetsCache, getFacetsLevel } from './selectors';
import { GEO_PARAM_MAP, FACETS_SEARCH_TYPE } from './constants';

/**
 * @typedef DynamicFacetResource
 * @prop {Generator} createSearchQuery
 * @prop {Generator} performRequest
 * @prop {Generator} checkCache
 */

/**
 * @typedef DynamicFacetResourceFactoryConfig
 * @prop {string} searchType
 * @prop {function(filters, facets): any[]} config.searchQueryFunction The create search query function with the paramters that will be used to create the search query
 * @prop {function(): Action} loadActionCreator
 * @prop {Object} loadActionSettings
 */

/**
 * Create a new object that can be used by the sagas to load the resource
 * @param {string} searchType
 * @param {DynamicFacetResourceFactoryConfig} config
 * @return {DynamicFacetResource}
 */
export const resourceFactory = (searchType, config) => ({
    type: searchType,
    createSearchQuery: function* createSearchQuery(filters, { facets, geoParams }) {
        const newFilters = { ...filters };

        if (newFilters.geoHierarchy) {
            newFilters.geoHierarchy = geoParams.reduce((acc, cur) => {
                const paramSlug = newFilters.geoHierarchy[cur];

                if (paramSlug) {
                    return {
                        ...acc,
                        [cur]: paramSlug,
                    };
                }

                return acc;
            }, {});
        }

        const searchQuery = yield call(...config.searchQueryFunction(newFilters, facets));

        return searchQuery.getRequestData();
    },
    performRequest: function* performRequest(query, location, searchLevel) {
        yield put(
            config.loadAction({
                forceReload: true,
                filters: {
                    query: JSON.stringify(query),
                },
                data: {
                    location,
                    searchType,
                    searchLevel,
                },
            })
        );

        return yield take(
            (action) =>
                action.type === config.loadActionSettings.DONE &&
                action.requestData.searchType === searchType &&
                action.requestData.searchLevel === searchLevel
        );
    },
    /**
     * Check if we already have the facets loaded in the cache
     *
     * @generator
     * @param {Object} options
     * @param {string} options.searchLevel Search level you want to load
     * @param {Object} options.location The facet location object
     * @param {string[]} options.compare Compare array that can be used to tell the function what geoParam needs to be compare with the current location loaded in the facet
     * @returns {boolean}
     */
    checkCache: function* checkCache({ location, searchLevel, compare }) {
        const isSearchLevelHydrated = yield select(checkFacetsCache, searchType, searchLevel);

        if (!isSearchLevelHydrated) {
            return false;
        }

        const { location: currentLocation } = yield select(getFacetsLevel, searchType, searchLevel);

        if (!currentLocation) {
            return false;
        }

        return !compare.some((key) => {
            const geoParam = GEO_PARAM_MAP[key];
            return currentLocation[geoParam] !== location[geoParam];
        });
    },
});

export const listings = resourceFactory(FACETS_SEARCH_TYPE.listings, {
    searchQueryFunction: (filters, facets) => [
        createListingsSearchQuery,
        {
            searchPastAssignments: false,
            filters,
            facets,
            facetResultSize: {
                country: 200,
                place: 200,
                admin1: 200,
                admin2: 200,
            },
            perPage: 0,
            searchPastAndFutureAssignments: true,
            preferGeoHierarchy: true,
        },
    ],
    loadAction: apiActions.search.loadListingsFacets,
    loadActionSettings: settings.search.loadListingsFacets,
});

export const profiles = resourceFactory(FACETS_SEARCH_TYPE.profiles, {
    searchQueryFunction: (filters, facets) => [
        createProfilesSearchQuery,
        1,
        filters,
        facets,
        undefined,
        {
            country: 200,
            place: 200,
            admin1: 200,
            admin2: 200,
        },
        true,
    ],
    loadAction: apiActions.search.loadProfilesFacets,
    loadActionSettings: settings.search.loadProfilesFacets,
});

// This is almost the same as the `profiles` because, right now,
// for SEO content we always load the profiles facets
export const seo = resourceFactory(FACETS_SEARCH_TYPE.seo, {
    searchQueryFunction: (filters, facets) => [
        createProfilesSearchQuery,
        1,
        filters,
        facets,
        undefined,
        {
            country: 200,
            place: 200,
            admin1: 200,
            admin2: 200,
        },
        true,
    ],
    loadAction: apiActions.search.loadProfilesFacets,
    loadActionSettings: settings.search.loadProfilesFacets,
});
