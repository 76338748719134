import { all, select, put, take, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    load as pageActionsLoad,
    preload as pageActionsPreload,
    error as errorAction,
    preloaded as pageActionsPreloaded,
    loaded as pageActionsLoaded,
} from 'containers/Page/actions';
import { photoDiff } from 'containers/PhotoUploader/helpers';
import apiActions, { settings } from 'api/actions';
import { getOwnerListingPetsDetails } from 'api/selectors/owner';
import { track, Events } from 'utils/analytics';
import { UPLOADED_PHOTO_TYPES } from 'utils/analytics/events/user/uploadedPhoto';
import { PAGE_ID } from './Pets.constants';
import * as actions from './actions';

function* preloadPetsData(params) {
    const { listingId } = params;

    // Preload animal types and breeds for Fish, Poultry and Livestock
    yield put(apiActions.animals.load());
    const animals = yield take((res) => res.type === settings.animals.load.DONE);
    // need to check that the result is successful otherwise the data is the error message object which you cant filter
    if (animals.status === settings.animals.load.SUCCESS) {
        const animalSlugs = ['fish', 'poultry', 'farm-animals'];
        const { data: animalsData = [] } = animals || {};
        const animalsFiltered = animalsData.filter(
            (animal) => animalSlugs.indexOf(animal.slug) >= 0
        );
        for (let i = 0; i < animalsFiltered.length; i += 1) {
            yield put(
                apiActions.animals.loadBreeds({
                    data: {
                        id: animalsFiltered[i].id,
                    },
                })
            );
            yield take((res) => res.type === settings.animals.loadBreeds.DONE);
        }
    } else {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, animals.statusCode));
        // return false so the rest of the saga doesnt run unnecessarily if there is an error getting the animals
        return false;
    }

    yield put(
        apiActions.owner.loadListingPets({
            forceReload: true,
            data: {
                id: listingId,
                listingId,
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadListingPets.DONE
    );

    if (status === settings.owner.loadListingPets.SUCCESS) {
        yield put(pageActionsPreloaded.create(PAGE_ID));
        return true;
    }

    // Error occurred
    yield put(errorAction.create(PAGE_ID, statusCode));

    return false;
}

/**
 * Create or Save updated pet
 */
function* savePets(action) {
    const { listingId, pets, saveAll } = action;

    const oldPets = yield select(getOwnerListingPetsDetails, listingId);
    let totalPhotoCount = oldPets.reduce(
        (total, pet) => (pet.photos ? total + pet.photos.length : total),
        0
    );

    for (let i = 0; i < pets.length; i += 1) {
        const pet = pets[i];
        let petId = pet.id;
        const oldVersionOfPet = oldPets.find((oldPet) => oldPet.id === petId);
        const { addedPhotos, deletedPhotos, updatedMedias } = photoDiff(
            oldVersionOfPet ? oldVersionOfPet.photos : [],
            pet.photos
        );

        // Create
        if (!petId) {
            yield put(
                apiActions.owner.createListingPet({
                    forceReload: true,
                    data: pet,
                })
            );
            const { status, data } = yield take(
                (res) => res.type === settings.owner.createListingPet.DONE
            );

            if (status !== settings.owner.createListingPet.SUCCESS) {
                // We can only link photos if the pet creation was successful so exit early if not
                // eslint-disable-next-line no-continue
                continue;
            }

            petId = data.id;
        }
        // Save if there is an update
        else {
            yield put(
                apiActions.owner.updateListingPet({
                    forceReload: true,
                    data: pet,
                })
            );
            yield take((res) => res.type === settings.owner.updateListingPet.DONE);

            // delete removed photos
            for (let a = 0; a < deletedPhotos.length; a += 1) {
                const deletedPhoto = deletedPhotos[a];

                yield put(
                    apiActions.owner.removeListingPetPhoto({
                        forceReload: true,
                        data: { listingId, petId, photoId: deletedPhoto.id },
                    })
                );

                yield take((res) => res.type === settings.owner.removeListingPetPhoto.DONE);
            }

            // update captions/order
            if (updatedMedias.length) {
                yield put(
                    apiActions.medias.update({
                        forceReload: true,
                        data: {
                            media: updatedMedias,
                            mediaType: 'photo',
                        },
                    })
                );

                yield take((res) => res.type === settings.medias.update.DONE);
            }
        }

        // link all the new photos
        for (let a = 0; a < addedPhotos.length; a += 1) {
            const addedPhoto = addedPhotos[a];

            yield put(
                apiActions.owner.createListingPetPhoto({
                    forceReload: true,
                    data: { listingId, petId, photoId: addedPhoto.id },
                })
            );

            yield take((res) => res.type === settings.owner.createListingPetPhoto.DONE);

            // eslint-disable-next-line no-plusplus
            yield call(
                track,
                Events.USER_UPLOADED_PHOTO.create(UPLOADED_PHOTO_TYPES.petPhoto, ++totalPhotoCount)
            );
        }

        // Done saving this pet
        yield put(actions.savePetDone.create(200, petId));
    }

    // Done saving
    yield put(actions.savePetsDone.create(200, saveAll));
}

export function* load(action) {
    yield call(preloadPetsData, action.params, PAGE_ID);
    yield put(pageActionsLoaded.create(PAGE_ID));
}

export function* preload(action) {
    yield call(preloadPetsData, action.params, PAGE_ID);
    yield put(pageActionsPreloaded.create(PAGE_ID));
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeLatest(actions.savePets.ACTION, savePets),
    ]);
}
