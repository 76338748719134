import loadable from '@loadable/component';
import { getAccountSettings } from 'api/selectors';
import { asPageNoSagas } from 'containers/Page';
import { redirectIfLoggedOut } from 'pages/user/helpers';
import { routes } from 'utils/routes';
import { retry } from 'utils/ssr';

// Load your component and include retry which tries to handle chunk failures
const LoadableSearchListingsDev = loadable(() => retry(() => import('./SearchListingsDev')));

export const PAGE_ID = 'SearchListingsDev';

const redirectIfNotStaff = {
    selector: getAccountSettings,
    redirect: (accountSettings) => {
        const { isStaff = false } = accountSettings || {};
        if (isStaff) return null;
        return routes.accounts.profile.dashboard();
    },
};

const authorisationRedirects = [redirectIfLoggedOut, redirectIfNotStaff];

export default asPageNoSagas(LoadableSearchListingsDev, PAGE_ID, {
    isProtectedRoute: true,
    checkAuthorised: authorisationRedirects,
});
