import { getState } from './common';
import { isReviewWindowOpen } from '../helpers/assignments';

export const getSitterOwnerMessage = (state, ownerId) =>
    getState(state).sitter.owner.messages[ownerId] || null;

export const getSitterAssignment = (state, id) => {
    const assignment = getState(state).sitter.assignments[id];
    if (!assignment) return null;
    if (!assignment.ownerId) return assignment;

    const lastMessage = getSitterOwnerMessage(state, assignment.ownerId);

    return {
        ...assignment,
        lastMessage,
    };
};

export const getSitterApplication = (state, id) => {
    const application = getState(state).sitter.applications.data[id];
    if (!application) return {};
    if (!application.ownerId) return application;

    const lastMessage = getSitterOwnerMessage(state, application.ownerId);

    return {
        ...application,
        lastMessage,
    };
};

//
// Helpers
//
function sitterAssignmentsSelectorHelper(
    state,
    assignmentsKey,
    sortKey = 'startDate',
    sortDirection = 'asc'
) {
    const assignments = [];
    getState(state).sitter[assignmentsKey].results.forEach((assignmentId) => {
        assignments.push(getSitterAssignment(state, assignmentId));
    });

    return assignments.sort((assignment1, assignment2) => {
        const startDate1 = new Date(assignment1[sortKey]);
        const startDate2 = new Date(assignment2[sortKey]);
        if (sortDirection === 'asc') {
            return startDate1 - startDate2;
        }
        return startDate2 - startDate1;
    });
}

function sitterAssignmentsSelectorHelperUnsorted(state, assignmentsKey) {
    return getState(state).sitter[assignmentsKey].results || [];
}

//
// Data selectors
//
export const getSitter = (state) => getState(state).sitter.data;
export const getSitterFavourites = (state) => {
    const emptyList = [];

    if (!getState(state).sitter.favourites.results) return emptyList;

    return getState(state).sitter.favourites.results.map((id) => ({
        id,
        ...getState(state).search.listing[id],
    }));
};
export const getSitterProfile = (state) => getState(state).sitter.profile.data;
export const getSitterProfileMedias = (state) => getState(state).sitter.medias.data;

export const getSitterProfileApplications = (state) =>
    getState(state).sitter.applications.results.map((application) =>
        getSitterApplication(state, application)
    );

export const getHasMoreSitterProfileApplications = (state) =>
    getState(state).sitter.applications.hasMore;

export const getSitterProfileApplicationsCount = (state) =>
    getState(state).sitter.applications.count;

export const getSitterProfileInvitations = (state) => {
    const emptyList = [];

    if (!getState(state).sitter.invitations.results) return emptyList;

    return getState(state).sitter.invitations.results.map((invitationId) =>
        getSitterApplication(state, invitationId)
    );
};
export const getSitterProfileInvitation = (state, id) => getSitterApplication(state, id);

export const getSitterOwnerApplications = (state, id) => {
    const applications = getState(state).sitter.owner.applications[id] || [];
    return applications.map((applicationId) => getSitterApplication(state, applicationId)) || [];
};

export const getSitterOwnerInvitations = (state, id) => {
    const invitations = getState(state).sitter.owner.invitations[id] || [];
    return invitations.map((applicationId) => getSitterApplication(state, applicationId)) || [];
};

export const getSitterOwnerApplicationsInvitations = (state, id) => {
    const applications = getSitterOwnerApplications(state, id);
    const invitations = getSitterOwnerInvitations(state, id);
    return [...applications, ...invitations];
};

export const getSitterProfileCurrentAssignments = (state) =>
    sitterAssignmentsSelectorHelper(state, 'currentAssignments');

export const getSitterProfileUpcomingAssignments = (state) =>
    sitterAssignmentsSelectorHelper(state, 'upcomingAssignments');

export const getSitterProfileUpcomingAssignmentsUnsorted = (state) =>
    sitterAssignmentsSelectorHelperUnsorted(state, 'upcomingAssignments');

export const getSitterProfilePastAssignments = (state) =>
    sitterAssignmentsSelectorHelper(state, 'pastAssignments', 'endDate').reverse();

export const getSitterProfilePastAssignmentsUnsorted = (state) =>
    sitterAssignmentsSelectorHelperUnsorted(state, 'pastAssignments');

export const getSitterProfileReviews = (state) => {
    const pastAssignments = getSitterProfilePastAssignments(state);
    return pastAssignments.filter(
        (assignment) => assignment.review || assignment.reviewRequestedDate
    );
};

export const getSitterPastAssignmentsForFeedbackWithReviewWindowOpen = (state) => {
    const pastAssignments = getSitterProfilePastAssignments(state);

    return pastAssignments.filter(
        (assignment) =>
            isReviewWindowOpen(assignment.reviewWindowEndDate) && !assignment.isFeedbackProvided
    );
};

export const getSitterProfileConfirmedAssignments = (state) =>
    getState(state).sitter.confirmedAssignments;
export const getSitterProfilePastAssignmentFeedback = (state, id) =>
    getState(state).sitter.feedbacks.data[id];
export const getSitterProfilePastAssignmentReview = (state, id) =>
    getState(state).sitter.reviews.data[id];
export const getSitterProfileAvailabilities = (state) => getState(state).sitter.availabilities;
export const getSitterProfileVerifications = (state) => getState(state).sitter.verifications;
export const getSitterConversations = (state) => getState(state).sitter.conversations;
export const getSitterConversation = (state, ownerId) => {
    const conversation = getState(state).sitter.conversations[ownerId];
    if (conversation) {
        return conversation.results || [];
    }
    return [];
};
export const getSitterConversationTotal = (state, sitterId) => {
    const conversation = getState(state).sitter.conversations[sitterId];
    if (conversation) {
        return conversation.count;
    }
    return 0;
};

export const getSitterFeedbacks = (state) => {
    const emptyList = [];

    if (!getState(state).sitter.feedbacks.results) return emptyList;

    return getState(state).sitter.feedbacks.results.map(
        (feedback) => getState(state).sitter.feedbacks.data[feedback]
    );
};
export const getSitterReviews = (state) => state?.sitter?.reviews?.reviews;
export const getSitterReview = (state, id) => getState(state).sitter.reviews.data[id];

export const getSitterOwnerWelcomeGuide = (state, assignmentId) => {
    const assignment = getSitterAssignment(state, assignmentId);
    if (assignment) {
        return assignment.welcomeGuide;
    }
    return null;
};

// export const getSitterOwnerListing = (state, id) => getState(state).sitter.listings[id];
export const getSitterOwnerListing = (state, id) => getState(state).search.listing[id];
export const getSitterOwnerListingPlacesOfInterest = (state, id) =>
    getState(state).sitter.owner.placesOfInterest[id];
export const getSitterOwnerListingPets = (state, id) => getState(state).sitter.owner.pets[id];

// Logical operations
export const isSitterOwnerListingFavourite = (state, id) =>
    getState(state).sitter.favourites.results.indexOf(id) >= 0;

export const getSitterApplicationsCount = (state) => getState(state).sitter.applications.count;
export const getSitterInvitationsCount = (state) => getState(state).sitter.invitations.count;
export const getSitterPastAssignmentsCount = (state) =>
    getState(state).sitter.pastAssignments.count;
export const getSitterPets = (state) => getState(state).sitter.pets;
