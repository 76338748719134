import React from 'react';
import Redirect from 'components/Redirect';

import HowItWorksOwner from './HowItWorksOwner';
import HowItWorksSitter from './HowItWorksSitter';

import paths from './route-paths';

export default [
    {
        path: paths.howItWorks,
        exact: true,
        component: ({ staticContext }) => (
            <Redirect to={paths.findASitter} context={staticContext} />
        ),
    },
    {
        path: paths.findASitter,
        exact: true,
        component: HowItWorksOwner,
    },
    {
        path: paths.findAHouseSit,
        exact: true,
        component: HowItWorksSitter,
    },
];
