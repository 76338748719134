import account from './account';
import content from './content';
import animals from './animals';
import medias from './medias';
import messages from './messages';
import notifications from './notifications';
import owner from './owner';
import places from './places';
import plans from './plans';
import privacyPolicy from './privacyPolicy';
import search from './search';
import session from './session';
import sitter from './sitter';
import terms from './terms';
import shortUrls from './shortUrls';
import verifications from './verifications';
import version from './version';

export default {
    account,
    content,
    animals,
    medias,
    messages,
    notifications,
    owner,
    places,
    plans,
    privacyPolicy,
    search,
    session,
    sitter,
    terms,
    shortUrls,
    verifications,
    version,
};
