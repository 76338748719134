import { saveProfileDone } from './actions';

const defaultState = {
    responseStatus: 200,
    updateId: 0,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case saveProfileDone.ACTION: {
            return {
                responseStatus: action.status,
                updateId: state.updateId + 1,
            };
        }
        default:
            return state;
    }
};
