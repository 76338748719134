import { connect } from 'react-redux';
import loadable from '@loadable/component';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { getSeoContent } from 'src/shared/selectors';
import { getArea } from 'pages/blog/selectors';
import { PAGE_ID } from './constants';
import { getCategoryNames, getPosts, getFeaturedPosts, getTrendingPosts } from './selectors';

const Area = loadable(() => retry(() => import('./Area')));

export const mapStateToProps = (state) => ({
    area: getArea(state, PAGE_ID),
    categoryNames: getCategoryNames(state),
    posts: getPosts(state),
    featuredPosts: getFeaturedPosts(state),
    trendingPosts: getTrendingPosts(state),
    seoContent: getSeoContent(state),
});

export default connect(mapStateToProps)(asPage(Area, PAGE_ID, true, true));
