import PastSits from './PastSits';
import PastSitsDetails from './PastSitsDetails';
import PastSitters from './PastSitters';
import PastSittersDetails from './PastSittersDetails';
import paths from './route-paths';

export default [
    {
        path: paths.pastSits,
        exact: true,
        component: PastSits,
    },
    {
        path: paths.pastSitsDetails,
        exact: true,
        component: PastSitsDetails,
    },
    {
        path: paths.pastSitters,
        exact: true,
        component: PastSitters,
    },
    {
        path: paths.pastSittersDetails,
        exact: true,
        component: PastSittersDetails,
    },
];
