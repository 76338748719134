import uniq from 'lodash/uniq';

// Take the old and new social verifications objects and figure out what changed
// eslint-disable-next-line import/prefer-default-export
export const socialVerificationsDiff = (oldSocialVerifications, newSocialVerifications) => {
    const addedSocialVerifications = {};
    const deletedSocialVerifications = {};
    const updatedSocialVerifications = {};

    // Get a mix of all the keys so we can be sure we catch added, edited and removed
    const allKeys = uniq([
        ...Object.keys(oldSocialVerifications),
        ...Object.keys(newSocialVerifications),
    ]);

    allKeys.forEach((socialVerificationKey) => {
        const existingSocialVerification = oldSocialVerifications[socialVerificationKey];
        const hasExisting = !!(
            existingSocialVerification && existingSocialVerification.platformIdentifier
        );
        const newSocialVerification = newSocialVerifications[socialVerificationKey];
        const hasNew = !!(newSocialVerification && newSocialVerification.platformIdentifier);

        if (hasExisting && !hasNew) {
            // It was there and now it's gone so add it to the removed list
            deletedSocialVerifications[socialVerificationKey] = {
                id: existingSocialVerification.id,
            };
        } else if (hasExisting && hasNew) {
            if (
                existingSocialVerification.platformIdentifier !==
                newSocialVerification.platformIdentifier
            ) {
                // The identifier has changed so add it to the updated list
                updatedSocialVerifications[socialVerificationKey] = {
                    id: existingSocialVerification.id,
                    platformIdentifier: newSocialVerification.platformIdentifier,
                };
            }
        } else if (!hasExisting && hasNew) {
            // It wasn't present and now it is so add it to the to-be-created list
            addedSocialVerifications[socialVerificationKey] = {
                ...newSocialVerification,
            };
        }
    });

    return {
        addedSocialVerifications,
        deletedSocialVerifications,
        updatedSocialVerifications,
    };
};
