/* eslint-disable import/prefer-default-export */
export const MEMBERSHIP_PAID_STATUS = {
    paid: 'paid',
    notPaid: 'not paid',
    onTrial: 'on trial',
};

export const APP_WEB_VIEW_TYPES = {
    iOS: 'ios',
    android: 'android',
};

export const SplitOverrides = {
    CONTROL: 'control',
    APP_IOS: 'app_ios',
    APP_ANDROID: 'app_android',
    BOT: 'bot',
};

export const ASSIGNMENT_STATUS = {
    BUSY: 'busy',
    REVIEWING: 'reviewing',
    ALREADY_APPLIED: 'already_applied',
    ALREADY_INVITED: 'already_invited',
    CAN_APPLY: 'can_apply',
    CONFIRMED_ASSIGNMENT: 'confirmed_assignment',
};

export const PAUSE_APPLICATIONS_LIMIT = 5;

export const ACCEPTED_FILE_EXTS = ['.jpg', '.jpeg', '.jpe', '.png', '.webp', '.gif', '.heic'];
