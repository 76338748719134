// eslint-disable-next-line import/prefer-default-export
export const SessionStorage = {
    setItem: (key, val) => {
        if (global && global.sessionStorage) {
            global.sessionStorage.setItem(key, JSON.stringify(val));
        }
    },
    getItem: (key) => {
        let val;
        if (global && global.sessionStorage) {
            const storageVal = global.sessionStorage.getItem(key);
            if (storageVal) val = JSON.parse(storageVal);
        }
        return val;
    },
};
