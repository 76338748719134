export const BED_TYPES = 'bedTypes';
export const BED_TYPES_KING = 'king';
export const BED_TYPES_QUEEN = 'queen';
export const BED_TYPES_DOUBLE = 'double';
export const BED_TYPES_SINGLE = 'single';
export const BED_TYPES_SOFA_BED = 'sofaBed';
export const BED_TYPES_CHILD_BED = 'childBed';
export const BED_TYPES_CRIB = 'crib';

export const STORAGE_TYPES = 'storageTypes';
export const STORAGE_TYPES_WARDROBE = 'wardrobe';
export const STORAGE_TYPES_DRAWERS = 'drawers';

export const HEATING_TYPES = 'heatingTypes';
export const HEATING_TYPES_CENTRAL = 'central';
export const HEATING_TYPES_LOG_BURNER = 'logBurner';
export const HEATING_TYPES_FIRE_PLACE = 'fireplace';

export const WIFI_TYPE = 'wifiType';
export const WIFI_TYPE_BASIC = 'basic';
export const WIFI_TYPE_HIGH_SPEED = 'highSpeed';

export const WORKSPACE_TYPES = 'workspaceTypes';
export const WORKSPACE_TYPES_OFFICE = 'office';
export const WORKSPACE_TYPES_DESK = 'desk';
export const WORKSPACE_TYPES_WORKTOP = 'worktop';

export const TV_TYPES = 'tvTypes';
export const TV_TYPES_STREAMING_SERVICES = 'streamingServices';
export const TV_TYPES_CABLE = 'cable';
export const TV_TYPES_DVD = 'dvd';
export const TV_TYPES_STANDARD = 'standardTv';

export const STOVE_TYPE = 'stoveType';
export const STOVE_TYPE_GAS = 'gas';
export const STOVE_TYPE_ELECTRIC = 'electric';
export const STOVE_TYPE_INDUCTION = 'induction';
export const STOVE_TYPE_OTHER = 'other';

export const PARKING_TYPE = 'parkingType';
export const PARKING_TYPE_FREE_OFF_STREET = 'freeOffStreet';
export const PARKING_TYPE_FREE_ON_STREET = 'freeOnStreet';
export const PARKING_TYPE_PAID_OFF_STREET = 'paidOffStreet';
export const PARKING_TYPE_PAID_ON_STREET = 'paidOnStreet';

export const HAS_BEDDING = 'hasBedding';
export const HAS_AIR_CONDITIONING = 'hasAirConditioning';
export const HAS_BALCONY = 'hasBalcony';
export const HAS_BARBECUE = 'hasBarbecue';
export const HAS_BATH = 'hasBath';
export const HAS_BATHROOM_ESSENTIALS = 'hasBathroomEssentials';
export const HAS_BIKE_ACCESS = 'hasBikeAccess';
export const HAS_BLENDER = 'hasBlender';
export const HAS_BURGLAR_ALARM = 'hasBurglarAlarm';
export const HAS_CAR_ACCESS = 'hasCarAccess';
export const HAS_CARBON_MONOXIDE_ALARM = 'hasCarbonMonoxideAlarm';
export const HAS_COFFEE_MAKER = 'hasCoffeeMaker';
export const HAS_COMMUNAL_GARDENS = 'hasCommunalGardens';
export const HAS_COOKING_BASICS = 'hasCookingBasics';
export const HAS_DINING_AREA = 'hasDiningArea';
export const HAS_DISHWASHER = 'hasDishwasher';
export const HAS_DRYER = 'hasDryer';
export const HAS_FIRE_ALARM = 'hasFireAlarm';
export const HAS_FIRE_EXTINGUISHER = 'hasFireExtinguisher';
export const HAS_FIRST_AID_KIT = 'hasFirstAidKit';
export const HAS_FREEZER = 'hasFreezer';
export const HAS_FRIDGE = 'hasFridge';
export const HAS_GARDEN = 'hasGarden';
export const HAS_HAIRDRYER = 'hasHairdryer';
export const HAS_HOT_TUB = 'hasHotTub';
export const HAS_IRON = 'hasIron';
export const HAS_KETTLE = 'hasKettle';
export const HAS_MICROWAVE = 'hasMicrowave';
export const HAS_OUTDOOR_DINING_AREA = 'hasOutdoorDiningArea';
export const HAS_OVEN = 'hasOven';
export const HAS_PATIO = 'hasPatio';
export const HAS_POOL = 'hasPool';
export const HAS_SCENIC_VIEWS = 'hasScenicViews';
export const HAS_SECURITY_CAMERAS = 'hasSecurityCameras';
export const HAS_SHAMPOO = 'hasShampoo';
export const HAS_SHOWER = 'hasShower';
export const HAS_SOFA = 'hasSofa';
export const HAS_STEREO = 'hasStereo';
export const HAS_TERRACE = 'hasTerrace';
export const HAS_TOASTER = 'hasToaster';
export const HAS_VACUUM = 'hasVacuum';
export const HAS_WASHER = 'hasWasher';

export const TYPES = {
    CHECK: 'boolean',
    SELECT: 'select',
    MULTISELECT: 'multiselect',
    AGREE: 'agree',
};

export const CATEGORIES = {
    SITTER_FAVOURITES: 'sitterFavourites',
    KITCHEN: 'kitchen',
    BATHROOM: 'bathroom',
    OUTDOOR: 'outdoor',
    SECURITY: 'security',
    PARKING_TRANSPORT: 'parkingTransport',
    CLEANING: 'cleaning',
    BEDROOM: 'bedroom',
    HEATING_COOLING: 'heatingCooling',
    INTERNET_OFFICE: 'internetOffice',
    SEATING_AREAS: 'seatingAreas',
    ENTERTAINMENT: 'entertainment',
};

export const LIST = [
    BED_TYPES,
    STORAGE_TYPES,
    HEATING_TYPES,
    WIFI_TYPE,
    WORKSPACE_TYPES,
    TV_TYPES,
    STOVE_TYPE,
    PARKING_TYPE,
    HAS_BEDDING,
    HAS_AIR_CONDITIONING,
    HAS_BALCONY,
    HAS_BARBECUE,
    HAS_BATH,
    HAS_BATHROOM_ESSENTIALS,
    HAS_BIKE_ACCESS,
    HAS_BLENDER,
    HAS_BURGLAR_ALARM,
    HAS_CAR_ACCESS,
    HAS_CARBON_MONOXIDE_ALARM,
    HAS_COFFEE_MAKER,
    HAS_COMMUNAL_GARDENS,
    HAS_COOKING_BASICS,
    HAS_DINING_AREA,
    HAS_DISHWASHER,
    HAS_DRYER,
    HAS_FIRE_ALARM,
    HAS_FIRE_EXTINGUISHER,
    HAS_FIRST_AID_KIT,
    HAS_FREEZER,
    HAS_FRIDGE,
    HAS_GARDEN,
    HAS_HAIRDRYER,
    HAS_HOT_TUB,
    HAS_IRON,
    HAS_KETTLE,
    HAS_MICROWAVE,
    HAS_OUTDOOR_DINING_AREA,
    HAS_OVEN,
    HAS_PATIO,
    HAS_POOL,
    HAS_SCENIC_VIEWS,
    HAS_SECURITY_CAMERAS,
    HAS_SHAMPOO,
    HAS_SHOWER,
    HAS_SOFA,
    HAS_STEREO,
    HAS_TERRACE,
    HAS_TOASTER,
    HAS_VACUUM,
    HAS_WASHER,
];

export const AMENITIES_CATEGORIES = {
    [CATEGORIES.SITTER_FAVOURITES]: {
        property: CATEGORIES.SITTER_FAVOURITES,
        analyticsId: 'amenitiesFavourites',
    },
    [CATEGORIES.KITCHEN]: {
        property: CATEGORIES.KITCHEN,
        analyticsId: 'amenitiesKitchen',
    },
    [CATEGORIES.BATHROOM]: {
        property: CATEGORIES.BATHROOM,
        analyticsId: 'amenitiesBathroom',
    },
    [CATEGORIES.OUTDOOR]: {
        property: CATEGORIES.OUTDOOR,
        analyticsId: 'amenitiesOutdoor',
    },
    [CATEGORIES.SECURITY]: {
        property: CATEGORIES.SECURITY,
        analyticsId: 'amenitiesSecurity',
    },
    [CATEGORIES.PARKING_TRANSPORT]: {
        property: CATEGORIES.PARKING_TRANSPORT,
        analyticsId: 'amenitiesParking',
    },
    [CATEGORIES.CLEANING]: {
        property: CATEGORIES.CLEANING,
        analyticsId: 'amenitiesCleaning',
    },
    [CATEGORIES.BEDROOM]: {
        property: CATEGORIES.BEDROOM,
    },
    [CATEGORIES.HEATING_COOLING]: {
        property: CATEGORIES.HEATING_COOLING,
    },
    [CATEGORIES.INTERNET_OFFICE]: {
        property: CATEGORIES.INTERNET_OFFICE,
    },
    [CATEGORIES.SEATING_AREAS]: {
        property: CATEGORIES.SEATING_AREAS,
    },
    [CATEGORIES.ENTERTAINMENT]: {
        property: CATEGORIES.ENTERTAINMENT,
    },
};

export const AMENITIES_LISTING_CATEGORIES = [
    AMENITIES_CATEGORIES[CATEGORIES.BEDROOM],
    AMENITIES_CATEGORIES[CATEGORIES.INTERNET_OFFICE],
    AMENITIES_CATEGORIES[CATEGORIES.KITCHEN],
    AMENITIES_CATEGORIES[CATEGORIES.HEATING_COOLING],
    AMENITIES_CATEGORIES[CATEGORIES.BATHROOM],
    AMENITIES_CATEGORIES[CATEGORIES.OUTDOOR],
    AMENITIES_CATEGORIES[CATEGORIES.PARKING_TRANSPORT],
    AMENITIES_CATEGORIES[CATEGORIES.SEATING_AREAS],
    AMENITIES_CATEGORIES[CATEGORIES.ENTERTAINMENT],
    AMENITIES_CATEGORIES[CATEGORIES.SECURITY],
    AMENITIES_CATEGORIES[CATEGORIES.CLEANING],
];

export const AMENITIES_EDIT_LISTING_CATEGORIES = [
    AMENITIES_CATEGORIES[CATEGORIES.SITTER_FAVOURITES],
    AMENITIES_CATEGORIES[CATEGORIES.KITCHEN],
    AMENITIES_CATEGORIES[CATEGORIES.BATHROOM],
    AMENITIES_CATEGORIES[CATEGORIES.OUTDOOR],
    AMENITIES_CATEGORIES[CATEGORIES.SECURITY],
    AMENITIES_CATEGORIES[CATEGORIES.PARKING_TRANSPORT],
    AMENITIES_CATEGORIES[CATEGORIES.CLEANING],
];

export const AMENITIES_LIST = [
    {
        property: BED_TYPES,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.BEDROOM],
        type: TYPES.MULTISELECT,
        options: [
            {
                property: BED_TYPES_KING,
            },
            {
                property: BED_TYPES_QUEEN,
            },
            {
                property: BED_TYPES_DOUBLE,
            },
            {
                property: BED_TYPES_SINGLE,
            },
            {
                property: BED_TYPES_SOFA_BED,
            },
            {
                property: BED_TYPES_CHILD_BED,
            },
            {
                property: BED_TYPES_CRIB,
            },
        ],
    },
    {
        property: HAS_BEDDING,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.BEDROOM],
        type: TYPES.CHECK,
    },
    {
        property: STORAGE_TYPES,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.BEDROOM],
        type: TYPES.MULTISELECT,
        options: [
            {
                property: STORAGE_TYPES_WARDROBE,
            },
            {
                property: STORAGE_TYPES_DRAWERS,
            },
        ],
    },
    {
        property: HAS_AIR_CONDITIONING,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.HEATING_COOLING],
        type: TYPES.CHECK,
    },
    {
        property: HEATING_TYPES,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.HEATING_COOLING],
        type: TYPES.MULTISELECT,
        options: [
            {
                property: HEATING_TYPES_CENTRAL,
            },
            {
                property: HEATING_TYPES_LOG_BURNER,
            },
            {
                property: HEATING_TYPES_FIRE_PLACE,
            },
        ],
    },
    {
        property: WIFI_TYPE,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.INTERNET_OFFICE],
        type: TYPES.SELECT,
        options: [
            {
                property: WIFI_TYPE_BASIC,
            },
            {
                property: WIFI_TYPE_HIGH_SPEED,
            },
        ],
    },
    {
        property: WORKSPACE_TYPES,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.INTERNET_OFFICE],
        type: TYPES.MULTISELECT,
        options: [
            {
                property: WORKSPACE_TYPES_OFFICE,
            },
            {
                property: WORKSPACE_TYPES_DESK,
            },
            {
                property: WORKSPACE_TYPES_WORKTOP,
            },
        ],
    },
    {
        property: HAS_SOFA,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.SEATING_AREAS],
        type: TYPES.CHECK,
    },
    {
        property: HAS_DINING_AREA,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.SEATING_AREAS],
        type: TYPES.CHECK,
    },
    {
        property: TV_TYPES,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.ENTERTAINMENT],
        type: TYPES.MULTISELECT,
        options: [
            {
                property: TV_TYPES_STREAMING_SERVICES,
            },
            {
                property: TV_TYPES_CABLE,
            },
            {
                property: TV_TYPES_DVD,
            },
            {
                property: TV_TYPES_STANDARD,
            },
        ],
    },
    {
        property: HAS_STEREO,
        categories: [CATEGORIES.SITTER_FAVOURITES, CATEGORIES.ENTERTAINMENT],
        type: TYPES.CHECK,
    },
    {
        property: STOVE_TYPE,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.SELECT,
        options: [
            {
                property: STOVE_TYPE_GAS,
            },
            {
                property: STOVE_TYPE_ELECTRIC,
            },
            {
                property: STOVE_TYPE_INDUCTION,
            },
            {
                property: STOVE_TYPE_OTHER,
            },
        ],
    },
    {
        property: HAS_OVEN,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_MICROWAVE,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_COFFEE_MAKER,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_TOASTER,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_KETTLE,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_FRIDGE,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_FREEZER,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_COOKING_BASICS,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_DISHWASHER,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_BLENDER,
        categories: [CATEGORIES.KITCHEN],
        type: TYPES.CHECK,
    },
    {
        property: HAS_BATH,
        categories: [CATEGORIES.BATHROOM],
        type: TYPES.CHECK,
    },
    {
        property: HAS_SHOWER,
        categories: [CATEGORIES.BATHROOM],
        type: TYPES.CHECK,
    },
    {
        property: HAS_SHAMPOO,
        categories: [CATEGORIES.BATHROOM],
        type: TYPES.CHECK,
    },
    {
        property: HAS_HAIRDRYER,
        categories: [CATEGORIES.BATHROOM],
        type: TYPES.CHECK,
    },
    {
        property: HAS_BATHROOM_ESSENTIALS,
        categories: [CATEGORIES.BATHROOM],
        type: TYPES.CHECK,
    },
    {
        property: HAS_GARDEN,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_PATIO,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_BALCONY,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_TERRACE,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_COMMUNAL_GARDENS,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_SCENIC_VIEWS,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_HOT_TUB,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_POOL,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_BARBECUE,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_OUTDOOR_DINING_AREA,
        categories: [CATEGORIES.OUTDOOR],
        type: TYPES.CHECK,
    },
    {
        property: HAS_SECURITY_CAMERAS,
        categories: [CATEGORIES.SECURITY],
        type: TYPES.AGREE,
    },
    {
        property: HAS_FIRST_AID_KIT,
        categories: [CATEGORIES.SECURITY],
        type: TYPES.CHECK,
    },
    {
        property: HAS_FIRE_ALARM,
        categories: [CATEGORIES.SECURITY],
        type: TYPES.CHECK,
    },
    {
        property: HAS_FIRE_EXTINGUISHER,
        categories: [CATEGORIES.SECURITY],
        type: TYPES.CHECK,
    },
    {
        property: HAS_CARBON_MONOXIDE_ALARM,
        categories: [CATEGORIES.SECURITY],
        type: TYPES.CHECK,
    },
    {
        property: HAS_BURGLAR_ALARM,
        categories: [CATEGORIES.SECURITY],
        type: TYPES.CHECK,
    },
    {
        property: PARKING_TYPE,
        categories: [CATEGORIES.PARKING_TRANSPORT],
        type: TYPES.SELECT,
        options: [
            {
                property: PARKING_TYPE_FREE_OFF_STREET,
            },
            {
                property: PARKING_TYPE_FREE_ON_STREET,
            },
            {
                property: PARKING_TYPE_PAID_OFF_STREET,
            },
            {
                property: PARKING_TYPE_PAID_ON_STREET,
            },
        ],
    },
    {
        property: HAS_CAR_ACCESS,
        categories: [CATEGORIES.PARKING_TRANSPORT],
        type: TYPES.CHECK,
    },
    {
        property: HAS_BIKE_ACCESS,
        categories: [CATEGORIES.PARKING_TRANSPORT],
        type: TYPES.CHECK,
    },
    {
        property: HAS_WASHER,
        categories: [CATEGORIES.CLEANING],
        type: TYPES.CHECK,
    },
    {
        property: HAS_DRYER,
        categories: [CATEGORIES.CLEANING],
        type: TYPES.CHECK,
    },
    {
        property: HAS_VACUUM,
        categories: [CATEGORIES.CLEANING],
        type: TYPES.CHECK,
    },
    {
        property: HAS_IRON,
        categories: [CATEGORIES.CLEANING],
        type: TYPES.CHECK,
    },
];

// Weights of each amenity to sorting them when
// display in listing page.
// Every other amenity that isn't in this list
// should have the same weight.
// NOTE: Order is important here!
export const WEIGHTS = [
    WIFI_TYPE,
    PARKING_TYPE,
    HAS_POOL,
    HAS_HOT_TUB,
    HAS_SCENIC_VIEWS,
    HAS_GARDEN,
    HAS_PATIO,
    HAS_BALCONY,
    HAS_TERRACE,
    HAS_CAR_ACCESS,
    WORKSPACE_TYPES,
    HAS_COMMUNAL_GARDENS,
    HAS_AIR_CONDITIONING,
    HAS_BIKE_ACCESS,
    TV_TYPES,
    HAS_WASHER,
    HAS_DRYER,
    HAS_BATH,
    BED_TYPES,
    STORAGE_TYPES,
    HAS_SHOWER,
    HEATING_TYPES,
    STOVE_TYPE,
    HAS_OVEN,
    HAS_MICROWAVE,
    HAS_COFFEE_MAKER,
    HAS_TOASTER,
    HAS_OUTDOOR_DINING_AREA,
    HAS_KETTLE,
    HAS_FRIDGE,
    HAS_FREEZER,
    HAS_DISHWASHER,
    HAS_SECURITY_CAMERAS,
    HAS_BLENDER,
    HAS_SOFA,
    HAS_BARBECUE,
    HAS_DINING_AREA,
    HAS_STEREO,
    HAS_FIRE_ALARM,
    HAS_FIRE_EXTINGUISHER,
    HAS_CARBON_MONOXIDE_ALARM,
    HAS_BURGLAR_ALARM,
    HAS_VACUUM,
    HAS_IRON,
    HAS_FIRST_AID_KIT,
    HAS_BEDDING,
    HAS_COOKING_BASICS,
    HAS_SHAMPOO,
    HAS_HAIRDRYER,
    HAS_BATHROOM_ESSENTIALS,
].reduce((acc, cur, index) => ({ ...acc, [cur]: index }), {});

export const TRANSFERRED_AMENITIES_TO_IGNORE = [WIFI_TYPE, HAS_CAR_ACCESS];
