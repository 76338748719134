import styled, { css } from 'styled-components';
import { Col } from 'components/layouts';

const sitterGridConfig = {
    base: {
        verticalSpacing: 32,
        horizontalSpacing: 16,
    },
    mediumDesktop: {
        verticalSpacing: 32,
        horizontalSpacing: 32,
    },
};

const ProfileSearchCardWrapper = styled.div`
    ${({ hasBorder, theme: { spacing, palette } }) =>
        hasBorder &&
        `
        border-top: 1px solid ${palette.grey[100]};
        padding-top: ${spacing[4]};
    `}
`;

const ProfileCardsCol = styled(Col)`
    /* screen size 1280px */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        max-width: 1280px;
    }
`;

const FreeTrialWrapperWrapper = styled.div(
    ({ theme: { screenSize, spacing }, fixedOrder }) => css`
        flex: 0 0 100%;
        padding-left: ${spacing[2]};
        order: 2;
        margin-top: ${spacing[3.5]};

        ${!fixedOrder
            ? `
            order: 4;
            // 720
            @media (min-width: ${screenSize.mediumTablet}px) {
                order: 6;
            }

            // 1024
            @media (min-width: ${screenSize.mediumDesktop}px) {
                order: 8;
            }

            // 1280
            @media (min-width: ${screenSize.largeDesktop}px) {
                order: 12;
            }
        
        `
            : `
            margin: 0px;
            padding-left: 0px;
        `}
    `
);

// eslint-disable-next-line import/prefer-default-export
export { FreeTrialWrapperWrapper, sitterGridConfig, ProfileSearchCardWrapper, ProfileCardsCol };
