import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@ths/design-system';
import {
    FacetsGridContainer,
    FacetsList,
    FacetsListActionContainer,
    FacetsListAction,
} from './DynamicFacets.style';

const DynamicFacets = ({ title, columns, sitemap, textBeforeFacets }) => {
    const [expandableColumns, setExpandableColumns] = useState({});
    const { t } = useTranslation();
    const hasAnyFacets = columns.some((column) => column.facets.length > 0);

    useEffect(() => {
        setExpandableColumns({});
    }, [columns]);

    const handleExpandColumn = (id) => {
        setExpandableColumns((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    if (!hasAnyFacets) {
        return null;
    }

    const cols = columns.map((col) => col.width);

    return (
        <div className="flex flex-col gap-6">
            <h2 className="m-0 mb-6 p-0 text-center font-semibold text-primary-700">{title}</h2>

            <FacetsGridContainer columns={cols}>
                {columns.map((column) => {
                    if (column.facets.length === 0) {
                        return null;
                    }

                    const { title: i18nTitle, expandFewer, expandMore } = column.i18n;

                    const { facets } =
                        typeof column.transform === 'function' ? column.transform(column) : column;

                    const visibleFacets = expandableColumns[column.id] ? facets.length : column.max;

                    return (
                        <div key={column.id} className="flex flex-col gap-4">
                            <h3 className="m-0 p-0 font-semibold text-primary-700">
                                {t(i18nTitle[0], { place: i18nTitle[1] })}
                            </h3>

                            <FacetsList
                                columns={column.width}
                                rows={Math.ceil(visibleFacets / column.width)}
                            >
                                {facets.map((facet, index) => (
                                    <li
                                        key={facet.id}
                                        data-visible={
                                            !!expandableColumns[column.id] || index < column.max
                                        }
                                        className='data-[visible="false"]:hidden'
                                    >
                                        <Link
                                            to={facet.route}
                                            className="font-semibold text-primary-900"
                                        >
                                            {textBeforeFacets || null}
                                            {facet.name}
                                        </Link>
                                    </li>
                                ))}
                            </FacetsList>

                            <FacetsListActionContainer columns={column.width}>
                                {column.expandable && column.facets.length > column.max ? (
                                    <FacetsListAction
                                        startAt={column.width}
                                        type="button"
                                        onClick={() => handleExpandColumn(column.id)}
                                    >
                                        {t(
                                            expandableColumns[column.id]
                                                ? expandFewer[0]
                                                : expandMore[0]
                                        )}
                                    </FacetsListAction>
                                ) : null}
                            </FacetsListActionContainer>
                        </div>
                    );
                })}
            </FacetsGridContainer>

            {sitemap ? (
                <div className="mt-8 flex justify-center">
                    <Link to={sitemap.route}>
                        <Button size="lg">
                            {t(sitemap.i18n.link[0], {
                                level: sitemap.i18n.link[1],
                            })}
                        </Button>
                    </Link>
                </div>
            ) : null}
        </div>
    );
};

DynamicFacets.defaultProps = {
    sitemap: null,
};

DynamicFacets.propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            i18n: PropTypes.shape({
                title: PropTypes.arrayOf(PropTypes.string).isRequired,
                expandFewer: PropTypes.arrayOf(PropTypes.string),
                expandMore: PropTypes.arrayOf(PropTypes.string),
            }).isRequired,
            width: PropTypes.number,
            max: PropTypes.number,
            expandable: PropTypes.bool,
            facets: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    route: PropTypes.string.isRequired,
                })
            ).isRequired,
            transform: PropTypes.func,
        })
    ).isRequired,
    sitemap: PropTypes.shape({
        i18n: PropTypes.shape({
            link: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
        route: PropTypes.string.isRequired,
    }),
};

export default DynamicFacets;
