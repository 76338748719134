import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconLink, IconCopy, IconShare, IconShareIOS } from 'components/Icon';
import { Button } from '@ths/design-system';
import { useUserAgent, OS } from 'utils/ssr/userAgent';
import { createReferAFriendShareableURL } from 'utils/share';
import {
    WrapperStyled,
    TitleDescriptionStyled,
    IconContainerStyled,
    LinkURLStyled,
    CopyButtonIconContainerStyled,
} from './ShareableLinkBlock.style';
import { SHARE_METHOD, TIMEOUT_TWO_SECONDS } from './constants';

const createShareObject = (url) => ({
    title: 'TrustedHousesitters',
    text: '',
    url,
});

const createShareURL = (url, method, utm) => {
    const mergedUTM = {
        ...{
            source: method,
            medium: 'refer-a-friend',
        },
        ...utm,
    };

    return createReferAFriendShareableURL(url, mergedUTM);
};

const ShareableInviteCodeBanner = ({ inviteCode, inviteURL, utm, onShare }) => {
    const [hasBeenShared, setHasBeenShared] = useState(false);
    const timeoutRef = useRef();
    const userAgent = useUserAgent();
    const { t } = useTranslation();

    const handleOnShareCallback = (method) => {
        switch (method) {
            case SHARE_METHOD.share:
                onShare(`mobileshare-${userAgent.os.name}`);
                break;
            case SHARE_METHOD.copy:
                onShare('copy-link');
                break;
            default:
                onShare('other');
                break;
        }
    };

    const shareUsingCopyLink = async () => {
        const url = createShareURL(inviteURL, 'copy-link', utm);

        try {
            // Try to get the permissions to write to the user's clipboard
            const permission = await window.navigator.permissions.query({
                name: 'clipboard-write',
            });

            if (permission.state === 'granted' || permission.state === 'prompt') {
                await window.navigator.clipboard.writeText(url);
                return [true, SHARE_METHOD.copy];
            }

            return [false];
        } catch (_) {
            // Browser doesn't support Permission API, so we can just copy directly
            try {
                await window.navigator.clipboard.writeText(url);
                return [true, SHARE_METHOD.copy];
            } catch (__) {
                return [false];
            }
        }
    };

    const shareUsingShareMenu = async () => {
        try {
            // Try to use the builtin share function, if we throw here
            // we'll use the standard copy function to share the link
            const url = createShareURL(inviteURL, 'share-menu', utm);
            await window.navigator.share(createShareObject(url));

            return [true, SHARE_METHOD.share];
        } catch (_) {
            // Browser doesn't support the share function, so we'll going
            // to try to use the standard copy to clipboard function
            return shareUsingCopyLink();
        }
    };

    const handleOnShare = async () => {
        // As writing to the clipboard is a transient event, it NEEDS (REALLY!)
        // to be called after a action triggered by the user
        if (!hasBeenShared) {
            const [success, method] = userAgent.isMobile()
                ? await shareUsingShareMenu()
                : await shareUsingCopyLink();

            if (success) {
                handleOnShareCallback(method);
                setHasBeenShared(true);
            }
        }
    };

    useEffect(() => {
        if (hasBeenShared) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                setHasBeenShared(false);
            }, TIMEOUT_TWO_SECONDS);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [hasBeenShared]);

    if (userAgent.isMobile()) {
        return (
            <Button
                variant="inverted"
                isFullwidth
                onClick={handleOnShare}
                data-testid="shareable-link-copy-button"
            >
                <CopyButtonIconContainerStyled>
                    {userAgent.isOS(OS.iOS) ? (
                        <IconShareIOS size="medLarge" />
                    ) : (
                        <IconShare size="medLarge" />
                    )}
                </CopyButtonIconContainerStyled>
                <span>{t('containers_refer_a_friend_inline_copy_button_mobile')}</span>
            </Button>
        );
    }

    return (
        <>
            <TitleDescriptionStyled>
                {t('containers_refer_a_friend_inline_share_description')}
            </TitleDescriptionStyled>
            <WrapperStyled role="button" onClick={handleOnShare}>
                <IconContainerStyled>
                    <IconLink />
                </IconContainerStyled>
                <LinkURLStyled>
                    <span>
                        <Trans
                            i18nKey="containers_refer_a_friend_inline_share_url"
                            values={{ code: inviteCode }}
                            components={{ bold: <strong /> }}
                        />
                    </span>
                </LinkURLStyled>
                <Button variant="inverted" data-testid="shareable-link-copy-button">
                    {!hasBeenShared ? (
                        <CopyButtonIconContainerStyled>
                            <IconCopy size="medLarge" />
                        </CopyButtonIconContainerStyled>
                    ) : null}
                    <span>
                        {t(
                            !hasBeenShared
                                ? 'containers_refer_a_friend_inline_copy_button'
                                : 'containers_refer_a_friend_inline_copy_button_copied'
                        )}
                    </span>
                </Button>
            </WrapperStyled>
        </>
    );
};

ShareableInviteCodeBanner.defaultProps = {
    utm: {},
    onShare: () => {},
};

export default ShareableInviteCodeBanner;
