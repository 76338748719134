import React, { useState, useEffect } from 'react';
import { track, Events } from 'utils/analytics';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { FlexboxGrid, Col } from 'components/layouts';
import PageSpinner from 'components/PageSpinner';
import Spinner from 'components/Spinner';
import { availableCurrencies } from 'config/currency';
import {
    BodyContainer,
    TitleStyled,
    SpinnerWrapper,
    HeaderWrapper,
    BodyWrapper,
    BodyRow,
    OrderedCol,
    RegularPayButton,
    BackButton,
    CloseButton,
    IconStyled,
    TitleWrapper,
    SpinnerContainer,
} from './AddonsCheckout.style';
import FakeCardPayment from '../FakeCardPayment';
import { elementsOptions } from './AddonsCheckout.constants';
import FakeDoorErrorContent from '../FakeDoorErrorContent';

const AddonsCheckout = ({
    accountCard,
    addons,
    loadStripe,
    currency,
    stripe,
    membership,
    onStepBackClicked,
    closeModal,
    openedFromDashboard,
}) => {
    const { t } = useTranslation();
    const [isCardPaymentFormEmpty, setIsCardPaymentFormEmpty] = useState(false);
    const [isCardPaymentComplete, setIsCardPaymentComplete] = useState(false);
    const [triggerEmptyCardError, setTriggerEmptyCardError] = useState(false);
    const [useSavedCard, setUseSavedCard] = useState(false);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [stripeCardElementsReady, setStripeCardElementsReady] = useState({});
    const [isCardFullyLoaded, setIsCardFullyLoaded] = useState(false);
    const [redirectToConfirmation, setRedirectToConfirmation] = useState(false);
    const [date, setDate] = useState(null);

    useEffect(() => {
        track(
            Events.VIEWED_FAKE_DOOR_CHECKOUT_MODAL.create(
                openedFromDashboard ? 'Dashboard' : 'Sit confirmation'
            )
        );
    }, [openedFromDashboard]);

    useEffect(() => {
        loadStripe();
    }, [loadStripe]);

    useEffect(() => {
        if (stripe) {
            setDate(Date.now());
        }
    }, [stripe]);

    useEffect(() => {
        const cardIsFullyLoaded =
            stripeCardElementsReady.card &&
            stripeCardElementsReady.expiry &&
            stripeCardElementsReady.cvc;

        if (cardIsFullyLoaded) {
            setIsCardFullyLoaded(true);
            setShowPaymentMethods(true);
        }
    }, [stripeCardElementsReady]);

    const handleSetStripeCardElementsReady = (cardElement) => {
        setStripeCardElementsReady((before) => ({
            ...before,
            ...cardElement,
        }));
    };

    const orderValue = (currentCurrency) =>
        addons.products.reduce((total, addon) => total + addon[currentCurrency], 0) -
        ((addons?.discount && addons?.discount[currentCurrency]) || 0);

    const handleSubmit = () => {
        track(
            Events.FAKE_DOOR_ACQUISITION.create(
                membership,
                orderValue('GBP'),
                orderValue(currency),
                currency,
                addons?.products.map((o) => t(o.name)).join(' + ')
            )
        );
        if (isCardPaymentFormEmpty && !useSavedCard) {
            setTriggerEmptyCardError(true);

            return;
        }
        if (!isCardPaymentComplete && !useSavedCard) {
            return;
        }
        setRedirectToConfirmation(true);
    };

    if (!stripe) {
        return (
            <SpinnerContainer>
                <Spinner />
            </SpinnerContainer>
        );
    }

    return !redirectToConfirmation ? (
        <BodyContainer data-testid="addons-checkout-wrapper-id">
            <FlexboxGrid
                justify={FlexboxGrid.Justify.CENTER}
                config={{
                    base: {
                        verticalSpacing: 0,
                        horizontalSpacing: 0,
                    },
                    largeDesktop: {
                        verticalSpacing: 0,
                        horizontalSpacing: 0,
                    },
                }}
            >
                <Col span={12}>
                    <HeaderWrapper>
                        {!openedFromDashboard && (
                            <BackButton onClick={() => onStepBackClicked(null)}>
                                <IconStyled />
                                {t('transaction_scaler_modal_back_button')}
                            </BackButton>
                        )}

                        <CloseButton onClick={() => closeModal()} />
                    </HeaderWrapper>
                    <TitleWrapper>
                        <TitleStyled>{t('transaction_scaler_modal_title')}</TitleStyled>
                    </TitleWrapper>
                </Col>
                <OrderedCol mobileOrder={1} span={12} largeTablet={10} mediumDesktop={6}>
                    {addons && (
                        <BodyWrapper>
                            {addons.products.map((addon) => (
                                <BodyRow key={`addon_${addon.name}`}>
                                    <span>{t(addon.name)}</span>
                                    <span>{`${availableCurrencies[currency].sign}${addon[currency]}`}</span>
                                </BodyRow>
                            ))}
                            {addons.discount && (
                                <BodyRow>
                                    <span>Discount</span>
                                    <span>
                                        -
                                        {`${availableCurrencies[currency].sign}${addons.discount[currency]}`}
                                    </span>
                                </BodyRow>
                            )}
                            <BodyRow isTotal>
                                <span>Total</span>
                                <span>
                                    {`${availableCurrencies[currency].sign} ${orderValue(
                                        currency
                                    )}`}
                                </span>
                            </BodyRow>
                        </BodyWrapper>
                    )}
                    <Elements key={date} stripe={stripe} options={elementsOptions}>
                        {!showPaymentMethods && !useSavedCard && !isCardFullyLoaded && (
                            <SpinnerWrapper>
                                <PageSpinner />
                            </SpinnerWrapper>
                        )}
                        <FakeCardPayment
                            cardPaymentValidation={{
                                triggerEmptyCardError,
                                setTriggerEmptyCardError,
                                setIsCardPaymentFormEmpty,
                                setIsCardPaymentComplete,
                            }}
                            showPaymentMethods={showPaymentMethods}
                            setShowPaymentMethods={setShowPaymentMethods}
                            useSavedCard={useSavedCard}
                            setUseSavedCard={setUseSavedCard}
                            card={accountCard}
                            handleSetStripeCardElementsReady={handleSetStripeCardElementsReady}
                        />
                    </Elements>
                    <RegularPayButton
                        type="submit"
                        disabled={(!stripe || !showPaymentMethods) && !useSavedCard}
                        onClick={handleSubmit}
                    >
                        {t('transaction_scaler_modal_checkout_button')}
                    </RegularPayButton>
                </OrderedCol>
            </FlexboxGrid>
        </BodyContainer>
    ) : (
        <FakeDoorErrorContent closeModal={closeModal} />
    );
};

export default AddonsCheckout;
