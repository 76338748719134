import { settings as actions } from '../../actions';
import { sectionStatus } from '../../helpers/listing-edit';
import * as AMENITIES from '../../constants/listing/amenities';

export const initialState = {};

const fields = {
    array:
        (options = {}) =>
        (value) => {
            if (!Array.isArray(value)) {
                return false;
            }

            if (options.minLength) {
                return value.length >= options.minLength;
            }

            return true;
        },
    string:
        (options = {}) =>
        (value) => {
            if (typeof value !== 'string') {
                return false;
            }

            if (options.minLength) {
                return value.length >= options.minLength;
            }

            return true;
        },
    boolean: () => (value) => typeof value === 'boolean',
};

const amenitiesTypes = {
    [AMENITIES.BED_TYPES]: fields.array({
        minLength: 1,
    }),
    [AMENITIES.STORAGE_TYPES]: fields.array({
        minLength: 1,
    }),
    [AMENITIES.HEATING_TYPES]: fields.array({
        minLength: 1,
    }),
    [AMENITIES.WIFI_TYPE]: fields.string({
        minLength: 1,
    }),
    [AMENITIES.WORKSPACE_TYPES]: fields.array({
        minLength: 1,
    }),
    [AMENITIES.TV_TYPES]: fields.array({
        minLength: 1,
    }),
    [AMENITIES.STOVE_TYPE]: fields.string({
        minLength: 1,
    }),
    [AMENITIES.PARKING_TYPE]: fields.string({
        minLength: 1,
    }),
    [AMENITIES.HAS_BEDDING]: fields.boolean(),
    [AMENITIES.HAS_AIR_CONDITIONING]: fields.boolean(),
    [AMENITIES.HAS_BALCONY]: fields.boolean(),
    [AMENITIES.HAS_BARBECUE]: fields.boolean(),
    [AMENITIES.HAS_BATH]: fields.boolean(),
    [AMENITIES.HAS_BATHROOM_ESSENTIALS]: fields.boolean(),
    [AMENITIES.HAS_BIKE_ACCESS]: fields.boolean(),
    [AMENITIES.HAS_BLENDER]: fields.boolean(),
    [AMENITIES.HAS_BURGLAR_ALARM]: fields.boolean(),
    [AMENITIES.HAS_CAR_ACCESS]: fields.boolean(),
    [AMENITIES.HAS_CARBON_MONOXIDE_ALARM]: fields.boolean(),
    [AMENITIES.HAS_COFFEE_MAKER]: fields.boolean(),
    [AMENITIES.HAS_COMMUNAL_GARDENS]: fields.boolean(),
    [AMENITIES.HAS_COOKING_BASICS]: fields.boolean(),
    [AMENITIES.HAS_DINING_AREA]: fields.boolean(),
    [AMENITIES.HAS_DISHWASHER]: fields.boolean(),
    [AMENITIES.HAS_DRYER]: fields.boolean(),
    [AMENITIES.HAS_FIRE_ALARM]: fields.boolean(),
    [AMENITIES.HAS_FIRE_EXTINGUISHER]: fields.boolean(),
    [AMENITIES.HAS_FIRST_AID_KIT]: fields.boolean(),
    [AMENITIES.HAS_FREEZER]: fields.boolean(),
    [AMENITIES.HAS_FRIDGE]: fields.boolean(),
    [AMENITIES.HAS_GARDEN]: fields.boolean(),
    [AMENITIES.HAS_HAIRDRYER]: fields.boolean(),
    [AMENITIES.HAS_HOT_TUB]: fields.boolean(),
    [AMENITIES.HAS_IRON]: fields.boolean(),
    [AMENITIES.HAS_KETTLE]: fields.boolean(),
    [AMENITIES.HAS_MICROWAVE]: fields.boolean(),
    [AMENITIES.HAS_OUTDOOR_DINING_AREA]: fields.boolean(),
    [AMENITIES.HAS_OVEN]: fields.boolean(),
    [AMENITIES.HAS_PATIO]: fields.boolean(),
    [AMENITIES.HAS_POOL]: fields.boolean(),
    [AMENITIES.HAS_SCENIC_VIEWS]: fields.boolean(),
    [AMENITIES.HAS_SECURITY_CAMERAS]: fields.boolean(),
    [AMENITIES.HAS_SHAMPOO]: fields.boolean(),
    [AMENITIES.HAS_SHOWER]: fields.boolean(),
    [AMENITIES.HAS_SOFA]: fields.boolean(),
    [AMENITIES.HAS_STEREO]: fields.boolean(),
    [AMENITIES.HAS_TERRACE]: fields.boolean(),
    [AMENITIES.HAS_TOASTER]: fields.boolean(),
    [AMENITIES.HAS_VACUUM]: fields.boolean(),
    [AMENITIES.HAS_WASHER]: fields.boolean(),
};

const getAmenitiesStatus = (amenities) => {
    const validations = Object.entries(amenitiesTypes).reduce(
        (acc, [key, value]) => {
            const amenity = amenities[key];

            if (typeof amenity === 'undefined') {
                return acc;
            }

            if (!value(amenity)) {
                return {
                    ...acc,
                    [sectionStatus.isEmpty]: acc[sectionStatus.isEmpty] + 1,
                };
            }

            return {
                ...acc,
                [sectionStatus.isCompleted]: acc[sectionStatus.isCompleted] + 1,
            };
        },
        {
            [sectionStatus.isEmpty]: 0,
            [sectionStatus.isCompleted]: 0,
        }
    );

    return validations.isCompleted > 0 ? sectionStatus.isCompleted : sectionStatus.isEmpty;
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadListingAmenities.SUCCESS: {
            const { listingId } = action.action.data;

            return {
                ...state,
                [listingId]: {
                    data: action.data,
                    status: getAmenitiesStatus(action.data),
                },
            };
        }
        case actions.owner.loadListingAmenities.FAILURE: {
            const { listingId } = action.action.data;

            return {
                ...state,
                [listingId]: {
                    data: null,
                    status: sectionStatus.isEmpty,
                },
            };
        }
        case actions.owner.createListingAmenities.SUCCESS:
        case actions.owner.updateListingAmenities.SUCCESS: {
            const { listingId, ...rest } = action.action.data;

            return {
                ...state,
                [listingId]: {
                    data: {
                        listingId,
                        ...rest,
                    },
                    status: getAmenitiesStatus({
                        ...rest,
                    }),
                },
            };
        }
        default:
            return state;
    }
};
