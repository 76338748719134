import { settings as actions } from '../actions';

import {
    basePaginatedInitialState, loadedResultsReducer, createdResultReducer, updatedResultReducer, deletedResultReducer,
} from './common';

export const initialState = { ...basePaginatedInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.medias.load.SUCCESS:
            return loadedResultsReducer(state, action);
        case actions.medias.create.SUCCESS:
            return createdResultReducer(state, action);
        case actions.medias.update.SUCCESS:
            return updatedResultReducer(state, action);
        case actions.medias.remove.SUCCESS:
            return deletedResultReducer(state, action, 'mediaId');
        default:
            return state;
    }
};
