import { settings as actions } from '../../actions';
import { selectedAnimalsToList } from '../../helpers/format/animals';
import { animalMapping } from '../../helpers/transform/common';
import { baseInitialResultsState, createdDataReducer } from '../common';

export const initialState = {
    ...baseInitialResultsState,
    reviews: {
        count: 0,
        results: [],
        hasMore: false,
    },
};

const getReviews = (payload) => {
    if (!payload) {
        return initialState.reviews;
    }

    return {
        count: payload?.count ?? 0,
        hasMore: payload?.hasMore ?? false,
        results:
            payload?.results?.map(
                ({
                    assignment,
                    id,
                    organised,
                    reliable,
                    selfSufficient,
                    tidy,
                    petCare,
                    overallScore,
                    sentAt,
                    description,
                    reply,
                    replySentAt,
                }) => ({
                    assignment,
                    id,
                    organised,
                    reliable,
                    selfSufficient,
                    tidy,
                    petCare,
                    overallScore,
                    sentAt,
                    description,
                    reply,
                    replySentAt,
                    startDate: assignment.startDate,
                    endDate: assignment.endDate,
                    ownerAvatarPhoto: assignment.listing.user.profilePhoto,
                    ownerIsRedacted: assignment.listing.user.isRedacted,
                    ownerName: assignment.listing.user.firstName,
                    listingAnimals: selectedAnimalsToList(assignment.listing.selectedAnimals).map(
                        (animal) => ({
                            ...animal,
                            slug: animalMapping(animal),
                        })
                    ),
                    listingId: assignment.listing.id,
                    listingLocation: assignment.listing.location,
                    ownerIsActive: assignment.listing.user.isActive,
                    sitterIsActive: assignment.profile.user.isActive,
                })
            ) ?? [],
    };
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadReviews.SUCCESS:
            return {
                ...state,
                reviews: {
                    ...getReviews(action.data),
                },
            };
        case actions.sitter.createPastAssignmentReviewRequest.SUCCESS:
            return {
                ...state,
                ...createdDataReducer(state, action),
            };
        case actions.sitter.loadConfirmedAssignments.SUCCESS:
            return {
                ...state,
                ...action.data.past.reduce((reviews, assignment) => {
                    if (assignment.review) {
                        reviews[assignment.review.id] = assignment.review;
                    }
                    return reviews;
                }, {}),
            };
        case actions.sitter.loadPastAssignments.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((reviews, assignment) => {
                        if (assignment.review) {
                            reviews[assignment.review.id] = assignment.review;
                        }
                        return reviews;
                    }, {}),
                },
            };
        default:
            return state;
    }
};
