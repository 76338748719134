import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        /**
         * API call returns all current and upcoming sits, as well as the first page of past sits.
         */
        case actions.sitter.loadConfirmedAssignments.SUCCESS: {
            const assignments = {};
            action.data.current.forEach((assignment) => {
                assignments[assignment.id] = transform.getAssignment({
                    ...assignment,
                    isConfirmed: true,
                });
            });
            action.data.upcoming.forEach((assignment) => {
                assignments[assignment.id] = transform.getAssignment({
                    ...assignment,
                    isConfirmed: true,
                });
            });
            action.data.past.forEach((assignment) => {
                assignments[assignment.id] = transform.getAssignment({
                    ...assignment,
                    isConfirmed: true,
                });
            });

            Object.keys(state).forEach((id) => {
                if (state[id].welcomeGuide && !assignments[id].welcomeGuide) {
                    assignments[id].welcomeGuide = state[id].welcomeGuide;
                }
            });

            /**
             * In this reducer's case, we always replace all of our current state. This makes sure that when
             * a sitter has a sit unconfirmed, the state is updated correctly the next time load is called, even if
             * the unconfirmed assignment has been placed in this list from offline state.
             */
            return {
                ...assignments,
            };
        }
        case actions.sitter.loadCurrentAssignments.SUCCESS:
        case actions.sitter.loadUpcomingAssignments.SUCCESS:
        case actions.sitter.loadPastAssignments.SUCCESS:
            return {
                ...state,
                ...action.data.results.reduce((assignments, assignment) => {
                    assignments[assignment.id] = {
                        ...transform.getAssignment({
                            ...assignment,
                            isConfirmed: true,
                        }),
                        // review: assignment.review ? assignment.review.id : assignment.reviewId,
                        // feedback: assignment.feedback ? assignment.feedback.id : assignment.feedbackId,
                    };
                    return assignments;
                }, {}),
            };
        case actions.sitter.loadAssignment.SUCCESS:
            return {
                ...state,
                [action.data.id]: {
                    ...transform.getAssignment(action.data),
                },
            };
        case actions.sitter.requestAssignmentWelcomeGuide.SUCCESS:
            return {
                ...state,
                [action.requestData.assignmentId]: {
                    ...state[action.requestData.assignmentId],
                    welcomeGuideStatus: {
                        ...state[action.requestData.assignmentId].welcomeGuideStatus,
                        requested: true,
                    },
                },
            };
        case actions.sitter.loadAssignmentWelcomeGuide.SUCCESS:
            return {
                ...state,
                [action.requestData.id]: {
                    ...state[action.requestData.id],
                    welcomeGuide: {
                        ...state[action.requestData.id].welcomeGuide,
                        ...transform.getWelcomeGuide(action.data),
                    },
                },
            };
        case actions.sitter.createPastAssignmentFeedback.SUCCESS:
            return {
                ...state,
                [action.requestData.assignmentId]: {
                    ...state[action.requestData.assignmentId],
                    feedback: {
                        ...action.data,
                    },
                    isFeedbackProvided: true,
                },
            };
        case actions.sitter.updatePastAssignmentReview.SUCCESS: {
            const assignment = state[action.requestData.assignmentId];
            return {
                ...state,
                [action.requestData.assignmentId]: {
                    ...assignment,
                    review: {
                        ...action.data,
                    },
                },
            };
        }
        case actions.sitter.createPastAssignmentReviewRequest.SUCCESS:
            return {
                ...state,
                [action.requestData.assignmentId]: {
                    ...state[action.requestData.assignmentId],
                    reviewRequest: {
                        message: action.data.message,
                        sentAt: action.data.sentAt,
                    },
                },
            };
        case actions.sitter.archiveConversations.SUCCESS:
        case actions.sitter.batchArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const data = {};
            Object.keys(state).forEach((assignmentId) => {
                const assignment = state[assignmentId];
                const { lastMessage, ownerId } = assignment;
                if (ids.indexOf(ownerId) >= 0 && lastMessage) {
                    data[assignmentId] = {
                        ...assignment,
                        lastMessage: {
                            ...assignment.lastMessage,
                            isArchived: true,
                        },
                    };
                } else {
                    data[assignmentId] = assignment;
                }
            });
            return data;
        }
        case actions.sitter.unArchiveConversations.SUCCESS:
        case actions.sitter.batchUnArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const data = {};
            Object.keys(state).forEach((assignmentId) => {
                const assignment = state[assignmentId];
                const { lastMessage, ownerId } = assignment;
                if (ids.indexOf(ownerId) >= 0 && lastMessage) {
                    data[assignmentId] = {
                        ...assignment,
                        lastMessage: {
                            ...assignment.lastMessage,
                            isArchived: false,
                        },
                    };
                } else {
                    data[assignmentId] = assignment;
                }
            });
            return data;
        }
        default:
            return state;
    }
};
