import { settings as actions } from '../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.account.loadRefundStatus.SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};
