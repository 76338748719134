/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { BlanketVariant } from './Blanket.constants';

const variants = {
    [BlanketVariant.DARK]: css`
        background-color: rgba(51, 51, 51, 0.74);
    `,
    // This seems like the most used value throughout the codebase
    [BlanketVariant.LIGHT]: css`
        background-color: rgba(0, 0, 0, 0.64);
        opacity: 0.5;
    `,
};

export const BlanketStyled = styled.div`
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 500;
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
    transition: ${({ isActive }) =>
        isActive
            ? 'visibility 0s linear 0s, opacity 300ms;'
            : 'visibility 0s linear 300sm, opacity 300ms;'};

    ${({ variant }) => {
        // If there's a variant we prefer to use that
        if (variants[variant]) return variants[variant];

        // Otherwise default back to the old method of passing a background-color
        return css`
            background-color: ${({ backgroundColor }) => `${backgroundColor}`};
            opacity: 0.5;
        `;
    }};
`;
