/* eslint-disable import/prefer-default-export */
import { put, take } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import {
    error as errorAction,
    preload as pageActionsPreload,
    lazyload as pageActionsLazyload,
    preloaded as pageActionsPreloaded,
    lazyloaded as pageActionsLazyloaded,
} from 'containers/Page/actions';

export function* loadListing(params, actionType, PAGE_ID) {
    yield put(
        apiActions.owner.loadListing({
            forceReload: true,
            data: {
                id: params.listingId,
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadListing.DONE
    );

    yield put(
        apiActions.owner.loadListingAmenities({
            forceReload: true,
            data: {
                listingId: params.listingId,
            },
        })
    );

    const pageActionsMapped = {
        [pageActionsPreload.ACTION]: pageActionsPreloaded,
        [pageActionsLazyload.ACTION]: pageActionsLazyloaded,
    };

    if (status === settings.owner.loadListing.SUCCESS) {
        yield put(pageActionsMapped[actionType].create(PAGE_ID));
        return true;
    }

    // Error occurred
    yield put(errorAction.create(PAGE_ID, statusCode));

    return false;
}
