import { settings as actions } from '../../actions';

export const initialState = {
    results: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadInvitations.SUCCESS:
            return {
                ...state,
                results: [...state.results, ...action.data.results.map((item) => item.id)],
            };
        case actions.sitter.loadInvitation.SUCCESS: {
            const invitations = [...state.results];
            if (invitations.indexOf(action.data.id) < 0) {
                invitations.push(action.data.id);
                return {
                    ...state,
                    results: invitations,
                };
            }

            return state;
        }
        case actions.sitter.confirmInvitation.SUCCESS:
            return {
                ...state,
                results: state.results.filter((itemId) => itemId !== action.data.id),
            };
        case actions.sitter.loadApplications.SUCCESS: {
            const applicationIDs = action.data.results.map((item) => item.id);
            return {
                ...state,
                results: state.results.filter((result) => applicationIDs.indexOf(result) < 0),
            };
        }
        default:
            return state;
    }
};
