import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { getPlans } from 'api/selectors/plans';
import { getAccount } from 'api/selectors';
import apiActions, { settings } from 'api/actions';
import { PlanTiers } from 'api/types';
import { routes } from 'utils/routes';
import * as actions from './actions';

function* loadSwitchPrice(planIdsToFetch) {
    yield put(
        apiActions.plans.loadSwitchDetails({
            forceReload: true,
            filters: {
                'plan_ids[]': planIdsToFetch,
            },
        })
    );
    yield take(settings.plans.loadSwitchDetails.DONE);
}

function* loadAllPremiumPlans(currencyCode) {
    yield put(
        apiActions.plans.load({
            forceReload: true,
            filters: {
                currency_code: currencyCode,
                'tiers[]': [PlanTiers.PREMIUM],
            },
        })
    );

    yield take(settings.plans.load.DONE);

    const plansIds = (yield select(getPlans)).map(({ id }) => id);
    yield loadSwitchPrice(plansIds);

    return yield select(getPlans);
}

export function* loadPremiumPlanToUpgrade() {
    const plan = yield select(getAccountCurrentMembershipPlan);

    if (plan) {
        const { membershipType: currentPlanMembershipType, currencyCode } = plan;

        const premiumPlanToUpgrade = (yield loadAllPremiumPlans(currencyCode)).filter(
            ({ membershipType }) => membershipType === currentPlanMembershipType
        );

        if (premiumPlanToUpgrade.length > 0) {
            yield put(actions.premiumPlanLoaded.create(premiumPlanToUpgrade[0]));
        }
    }
}

export function* upgradeSelected({ upgradeDetails }) {
    const { membershipPlan, currencyCode, membershipType } = upgradeDetails;
    const account = yield select(getAccount);
    if (global.localStorage) {
        global.localStorage.setItem(
            'ths-original-membership-plan',
            JSON.stringify({
                id: membershipPlan.id,
                membershipType,
                originalPrice: membershipPlan.originalPrice,
            })
        );
    }

    yield put(
        apiActions.account.createIncompleteMembershipPlan({
            forceReload: true,
            data: {
                membershipPlanId: membershipPlan.id,
                userId: account.details.id,
                currencyCode,
            },
        })
    );

    // Reload membership plan
    yield put(
        apiActions.account.loadMembership({
            forceReload: true,
        })
    );
    const { status: loadMembershipStatus } = yield take(settings.account.loadMembership.DONE);
    if (loadMembershipStatus === settings.account.loadMembership.SUCCESS) {
        // Redirect to checkout
        yield put(
            push({
                pathname: routes.accounts.checkout.payment(),
            })
        );
    }
}

export default function* saga() {
    yield all([
        takeEvery(actions.loadPremiumPlan.ACTION, loadPremiumPlanToUpgrade),
        takeEvery(actions.selectUpgrade.ACTION, upgradeSelected),
    ]);
}
