import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Trail, TrailContext, TrailRoutes, TrailRoute } from '@ths/design-system';
import { track, Events } from 'utils/analytics';
import * as registerActions from 'containers/forms/Register/actions';
import ExperimentalFeature, { experiments } from 'containers/ExperimentalFeature';
import { RegwallMessaging } from '../Regwall';
import { STEPS } from './RegwallDialog.constants';
import { RegwallDialogContext } from './RegwallDialog.context';
import SelectMembershipStep from './components/SelectMembershipStep';
import InitialFormStep from './components/InitialFormStep';
import EmailFormStep from './components/EmailFormStep';
import ThirdPartyFormStep from './components/ThirdPartyFormStep';

const RegwallDialog = ({
    isOpen,
    openMethod,
    onCloseHandler,
    membershipType = null,
    shouldSetMembershipType = false,
    membershipPlan,
    messaging = RegwallMessaging.REGISTER,
    regwallCopy,
}) => {
    const [selectedMembershipType, setSelectedMembershipType] = useState(membershipType);
    const [thirdPartyState, setThirdPartyState] = useState({
        method: null,
        data: null,
    });
    const dispatch = useDispatch();

    const handleSelectMembershipType = useCallback((type) => {
        setSelectedMembershipType(type);
    }, []);

    const handleRequestClose = useCallback(() => {
        dispatch(registerActions.showRegisterErrorMessage.create({}));

        if (typeof onCloseHandler === 'function') {
            track(Events.BUTTONCLICK.create({ description: 'reg wall select close' }));

            onCloseHandler();
        }
    }, [dispatch, onCloseHandler]);

    const ModalWrapper = (isMobileFullscreen, current) => (
        <Modal
            isOpen={isOpen}
            isMobileFullscreen={isMobileFullscreen}
            variant={current !== STEPS.INITIAL_FORM ? 'default' : 'compact'}
            size={current === STEPS.SELECT_MEMBERSHIP ? 'flex' : 'md'}
            onRequestClose={handleRequestClose}
        >
            <RegwallDialogContext.Provider
                value={{
                    selectedMembershipType,
                    membershipPlan,
                    shouldSetMembershipType,
                    messaging,
                    regwallCopy,
                    openMethod,
                    isOpen,
                    thirdPartyState,
                    membershipType,
                    setThirdPartyState,
                    isCloseable: typeof onCloseHandler === 'function',
                    selectMembershipType: handleSelectMembershipType,
                }}
            >
                <TrailRoutes>
                    <TrailRoute path={STEPS.SELECT_MEMBERSHIP} Component={SelectMembershipStep} />
                    <TrailRoute path={STEPS.INITIAL_FORM} Component={InitialFormStep} />
                    <TrailRoute path={STEPS.EMAIL_FORM} Component={EmailFormStep} />
                    <TrailRoute path={STEPS.THIRD_PARTY_FORM} Component={ThirdPartyFormStep} />
                </TrailRoutes>
            </RegwallDialogContext.Provider>
        </Modal>
    );

    return (
        <Trail
            initialPath={
                selectedMembershipType === null && !shouldSetMembershipType
                    ? STEPS.SELECT_MEMBERSHIP
                    : STEPS.INITIAL_FORM
            }
        >
            <TrailContext.Consumer>
                {({ current }) => (
                    <ExperimentalFeature
                        analytics={false}
                        experiment={experiments.TWO_STEP_REGWALL}
                        control={ModalWrapper(
                            current === STEPS.SELECT_MEMBERSHIP ||
                                current === STEPS.EMAIL_FORM ||
                                current === STEPS.INITIAL_FORM ||
                                current === STEPS.THIRD_PARTY_FORM,
                            current
                        )}
                        variation1={ModalWrapper(
                            current === STEPS.SELECT_MEMBERSHIP || current === STEPS.EMAIL_FORM,
                            current,
                            true
                        )}
                    />
                )}
            </TrailContext.Consumer>
        </Trail>
    );
};

export { RegwallDialog };

export default RegwallDialog;
