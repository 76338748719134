import { combineReducers } from 'redux';

import inbox, { initialState as inboxInitialState } from './inbox';
import archive, { initialState as archiveInitialState } from './archive';

export const initialState = {
    inbox: inboxInitialState,
    archive: archiveInitialState,
};

export default combineReducers({
    inbox,
    archive,
});
