import { getAccount } from 'api/selectors/account';
import * as contentSelectors from 'api/selectors/content';
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from 'config/language';
import { getAppWebViewType, isBotSplit, isControlSplit, matchInbox } from 'utils/utils';
import { getSplit } from 'containers/ExperimentalFeature/selectors';

const getIsLoggedIn = (state) => {
    const account = getAccount(state);
    const isLoggedIn = account && account.details ? Object.keys(account.details).length > 0 : false;

    return isLoggedIn;
};

const getLocale = (state) => state.geoip;

const getLanguage = (state) =>
    SUPPORTED_LANGUAGES.includes(state.language) ? state.language : DEFAULT_LANGUAGE;

const getHasMembership = (state) => {
    const account = getAccount(state);
    const isLoggedIn = getIsLoggedIn(state);
    return isLoggedIn && account.membershipPlan.expiryDate;
};

/**
 *
 * @param state
 * @returns {string|null} Returns the platform the user is viewing on (src/config/constants.js or null if the user is
 * not in a webview.
 */
const isViewingInAppWebView = (state) => getAppWebViewType(state.split);

const shouldHideNavbarOnPage = (pathname) => {
    const hideList = [matchInbox(pathname)];
    return hideList.some((match) => !!match);
};
/**
 * Checks whether given event is limited and returns a boolean denoting the state
 */
const isEventLimited = (state, eventType, nTimes) => {
    const eventCallExists = eventType in state.shared.events;
    let isLimited = true;

    // Default, check if trigger does not exists
    if (nTimes === undefined) {
        isLimited = eventCallExists;
    }
    // Check if called less than nTimes
    else {
        isLimited = nTimes
            ? eventCallExists && state.shared.events[eventType].callCount >= nTimes
            : eventCallExists;
    }
    return isLimited;
};

const getStoredPromo = (state) => state.shared.promoCode;

const getRouterLocation = (state) => state.router;

const NOT_CHECKED_FOR_CONTENT = { notCheckedForContent: true };

const getSeoContent = (state, pathname) => {
    let currentRoutePathname = pathname;
    if (!currentRoutePathname) {
        const location = getRouterLocation(state);
        currentRoutePathname = location.location.pathname;
    }

    const language = getLanguage(state);
    const seoContent = contentSelectors.getSeoContent(state, currentRoutePathname, language);
    if (!seoContent) {
        return NOT_CHECKED_FOR_CONTENT;
    }

    return seoContent;
};

const getTermsContent = (state) => state.policies.terms;

const getCurrentFreeTrialStatus = (membership) => membership?.isOnFreeTrial;

export default getIsLoggedIn;

export {
    getIsLoggedIn,
    getLocale,
    getLanguage,
    getHasMembership,
    isEventLimited,
    getSeoContent,
    getRouterLocation,
    getStoredPromo,
    isViewingInAppWebView,
    shouldHideNavbarOnPage,
    getTermsContent,
    getCurrentFreeTrialStatus,
};
