import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

import { basePaginatedInitialState } from '../common';

import { uniqueArray } from '../../helpers/format/objects';

export const initialState = { ...basePaginatedInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadApplications.SUCCESS: {
            const { startingAfter } = action.requestData;
            // Append search results
            if (startingAfter) {
                return {
                    ...state,
                    hasMore: action.data.hasMore,
                    count: action.data.count,
                    results: uniqueArray([
                        ...state.results,
                        ...action.data.results.map((item) => item.id),
                    ]),
                    data: {
                        ...state.data,
                        ...action.data.results.reduce((items, item) => {
                            items[item.id] = transform.getApplication(item);
                            return items;
                        }, {}),
                    },
                };
            }
            return {
                ...state,
                hasMore: action.data.hasMore,
                count: action.data.count,
                results: uniqueArray([...action.data.results.map((item) => item.id)]),
                data: {
                    ...state.data,
                    ...action.data.results.reduce((items, item) => {
                        items[item.id] = transform.getApplication(item);
                        return items;
                    }, {}),
                },
            };
        }
        case actions.sitter.loadInvitations.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((items, item) => {
                        items[item.id] = transform.getApplication(item);
                        return items;
                    }, {}),
                },
            };
        case actions.sitter.loadInvitation.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: transform.getApplication(action.data),
                },
            };
        }
        case actions.sitter.loadApplication.SUCCESS: {
            const applications = [...state.results];
            if (applications.indexOf(action.data.id) < 0) {
                applications.push(action.data.id);
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [action.data.id]: transform.getApplication(action.data),
                    },
                    results: applications,
                };
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: transform.getApplication(action.data),
                },
            };
        }
        case actions.sitter.loadSitterOwnerApplications.SUCCESS:
        case actions.sitter.loadSitterOwnerInvitations.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data.results.reduce((items, item) => {
                        items[item.id] = transform.getApplication(item);
                        return items;
                    }, {}),
                },
            };
        case actions.sitter.createBatchApplications.SUCCESS:
            return {
                ...state,
                results: [...state.results, ...action.data.map((item) => item.id)],
                data: {
                    ...state.data,
                    ...action.data.reduce((items, item) => {
                        items[item.id] = transform.getApplication(item);
                        return items;
                    }, {}),
                },
            };
        case actions.sitter.createApplication.SUCCESS:
            return {
                ...state,
                results: uniqueArray([...state.results, action.data.id]),
                data: {
                    ...state.data,
                    [action.data.id]: transform.getApplication(action.data),
                },
            };
        case actions.sitter.confirmInvitation.SUCCESS:
            return {
                ...state,
                results: uniqueArray([...state.results, action.data.id]),
                data: {
                    ...state.data,
                    [action.data.id]: transform.getApplication(action.data),
                },
            };
        case actions.sitter.declineInvitation.SUCCESS:
        case actions.sitter.cancelApplication.SUCCESS:
        case actions.sitter.confirmApplication.SUCCESS:
        case actions.sitter.declineSitConfirmation.SUCCESS:
        case actions.sitter.confirmAssignmentDateChange.SUCCESS:
        case actions.sitter.declineAssignmentDateChange.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.id]: transform.getApplication(action.data),
                },
            };
        case actions.sitter.archiveConversations.SUCCESS:
        case actions.sitter.batchArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const data = {};
            Object.keys(state.data).forEach((applicationId) => {
                const application = state.data[applicationId];
                const { lastMessage, ownerId } = application;
                if (ids.indexOf(ownerId) >= 0 && lastMessage) {
                    data[applicationId] = {
                        ...application,
                        lastMessage: {
                            ...application.lastMessage,
                            isArchived: true,
                        },
                    };
                } else {
                    data[applicationId] = application;
                }
            });
            return {
                ...state,
                data,
            };
        }
        case actions.sitter.unArchiveConversations.SUCCESS:
        case actions.sitter.batchUnArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const data = {};
            Object.keys(state.data).forEach((applicationId) => {
                const application = state.data[applicationId];
                const { lastMessage, ownerId } = application;
                if (ids.indexOf(ownerId) >= 0 && lastMessage) {
                    data[applicationId] = {
                        ...application,
                        lastMessage: {
                            ...application.lastMessage,
                            isArchived: false,
                        },
                    };
                } else {
                    data[applicationId] = application;
                }
            });
            return {
                ...state,
                data,
            };
        }
        default:
            return state;
    }
};
