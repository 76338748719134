import { settings as actions } from '../../actions';

import {
    deletedResultListReducer,
} from '../common';


export const initialState = {
    listings: {
        results: [],
    },
    profiles: {
        results: [],
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadListingsFavourites.SUCCESS: {
            const { startingAfter } = action.requestData;
            // Append search results
            if (startingAfter) {
                return {
                    ...state,
                    listings: {
                        ...state.listings,
                        results: [
                            ...state.listings.results,
                            ...action.data.results.map(listing => listing.id),
                        ],
                        facets: { ...action.data.facets },
                        total: action.data.total,
                    },
                };
            }
            return {
                ...state,
                listings: {
                    ...state.listings,
                    results: action.data.results.map(listing => listing.id),
                    facets: action.data.facets,
                    total: action.data.total,
                },
            };
        }
        case actions.search.loadProfilesFavourites.SUCCESS: {
            const { startingAfter } = action.requestData;
            // Append search results
            if (startingAfter) {
                return {
                    ...state,
                    profiles: {
                        ...state.profiles,
                        results: [
                            ...state.profiles.results,
                            ...action.data.results.map(listing => listing.id),
                        ],
                        facets: { ...action.data.facets },
                        total: action.data.total,
                    },
                };
            }
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    results: action.data.results.map(listing => listing.id),
                    facets: action.data.facets,
                    total: action.data.total,
                },
            };
        }
        case actions.owner.removeFavourite.SUCCESS:
            return {
                ...state,
                profiles: deletedResultListReducer(state.profiles, action),
            };
        case actions.sitter.removeFavourite.SUCCESS:
            return {
                ...state,
                listings: deletedResultListReducer(state.listings, action),
            };
        default:
            return state;
    }
};
