import Register from 'containers/forms/Register';

const RegwallMessaging = {
    REGISTER: 'REGISTER', // Aimed at creating a free account
    JOIN: 'JOIN', // Aimed at registering and going through to checkout
};

export const RegwallStates = {
    EMAIL: Register.Method.EMAIL,
    FACEBOOK: Register.Method.FACEBOOK,
    GOOGLE: Register.Method.GOOGLE,
};

export default RegwallMessaging;
