import environment from 'environment';

function getImagePublicId(photoPublicId, cloudinaryConfig) {
    const { autoUploadDirectory } = cloudinaryConfig;

    return RegExp(`${autoUploadDirectory}`).test(`${photoPublicId}`)
        ? `${photoPublicId}`
        : `${autoUploadDirectory}/${photoPublicId}`;
}

const getCloudinaryImageUrl = (
    photoPublicId,
    transformation,
    customTransform = '',
    defaultImage = undefined
) => {
    if (!photoPublicId && defaultImage) {
        return defaultImage;
    }

    const { hostName, cloudName } = environment.vars.cloudinary;
    let imageTransformation;

    if (customTransform) {
        imageTransformation = `${customTransform}/`;
    } else {
        imageTransformation = transformation ? `t_${transformation},f_auto/` : '';
    }

    const photoId = getImagePublicId(photoPublicId, environment.vars.cloudinary);

    return `${hostName}/${cloudName}/image/upload/${imageTransformation}v1/${photoId}`;
};

// Reference:
// - https://cloudinary.com/documentation/transformation_reference
// - https://cloudinary.com/documentation/face_detection_based_transformations
const cloudinaryTransformations = {
    crop: 'c',
    width: 'w',
    height: 'h',
    format: 'f',
    quality: 'q',
    gravity: 'g',
    named: 't',
};

const cloudinaryParseOption = (option, value) => {
    const transformation = cloudinaryTransformations[option];

    if (!transformation) {
        throw new TypeError(`Invalid cloudinary transformation: "${option}".`);
    }

    if (Array.isArray(value)) {
        return `${transformation}_${value[0]}:${value[1]}`;
    }

    return `${transformation}_${value}`;
};

const cloudinaryImageLoader = ({ publicId, options = {} }) => {
    const { hostName, cloudName } = environment.vars.cloudinary;
    const transformations = Object.entries(options)
        .filter(([, value]) => !!value)
        .reduce((acc, [key, value]) => [...acc, cloudinaryParseOption(key, value)], [])
        .join(',');

    const baseUrl = `${hostName}/${cloudName}/image/upload`;
    const imagePublicId = getImagePublicId(publicId, environment.vars.cloudinary);

    if (!transformations) {
        return `${baseUrl}/v1/${imagePublicId}`;
    }

    return `${baseUrl}/${transformations}/v1/${imagePublicId}`;
};

export { getImagePublicId, getCloudinaryImageUrl, cloudinaryImageLoader };
