import { connect } from 'react-redux';
import loadable from '@loadable/component';
import asPage from 'containers/Page';
import { getArea } from 'pages/blog/selectors';
import { getSeoContent } from 'shared/selectors';
import { retry } from 'utils/ssr';
import { PAGE_ID } from './constants';
import { getPost, getPostCategory, getTrendingPosts } from './selectors';

const Post = loadable(() => retry(() => import('./Post')));

const mapStateToProps = (state) => ({
    area: getArea(state, PAGE_ID),
    post: getPost(state),
    category: getPostCategory(state),
    trendingPosts: getTrendingPosts(state),
    seoContent: getSeoContent(state),
});

export default connect(mapStateToProps)(asPage(Post, PAGE_ID, true, true, { forceLoad: true }));
