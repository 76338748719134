import styled, { css } from 'styled-components';
import { mix } from 'polished';
import { IconCheck } from 'components/Icon';
import { theme as defaultTheme } from 'theme';
import { visuallyHide } from 'utils/utils';
import { Variant } from './CheckButton.constants';

// Hover states
const hoverLightStrength = defaultTheme.opacityOverlay.light.hover.strength;
const hoverLightColor = defaultTheme.opacityOverlay.light.hover.color;

// Active states
const activeLightStrength = defaultTheme.opacityOverlay.light.active.strength;
const activeLightColor = defaultTheme.opacityOverlay.light.active.color;

// Local style
const checkedCss = css`
    border: solid 2px ${({ theme }) => theme.palette.primaryRegular};
    padding: ${({ theme, variant }) =>
        `${theme.spacing[1]} ${
            variant === Variant.Secondary ? theme.spacing['1.5'] : theme.spacing[2]
        }`};
    background-color: ${({ theme, variant }) =>
        variant === Variant.Secondary ? theme.palette.primaryRegular : 'inherit'};

    && svg {
        fill: ${({ theme, variant }) =>
            variant === Variant.Primary ? theme.palette.icon : theme.palette.white};
    }
`;

const uncheckedCss = css`
    border: solid 1px
        ${({ theme, variant }) =>
            variant === Variant.Primary ? theme.palette.border : theme.palette.borderDark};
    // Added 1px to the padding, to get rid of the annoying jumping of the content when border width increases to 2px
    padding: ${({ theme, variant }) =>
        `${parseInt(theme.spacing[1], 10) + 1}px ${
            variant === Variant.Secondary
                ? theme.spacing['1.5']
                : `${parseInt(theme.spacing[2], 10) + 1}px`
        }`};

    ${({ theme }) => `
        @media only screen and (min-width: ${theme.screenSize.mediumTablet}px) {
            justify-content: flex-start;
        }
    `};
`;

export const WrapperStyled = styled.div`
    display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'inline-flex')};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    &:hover {
        background-color: ${({ theme }) =>
            mix(hoverLightStrength, hoverLightColor, theme.palette.white)};
    }
    &:active {
        background-color: ${({ theme }) =>
            mix(activeLightStrength, activeLightColor, theme.palette.white)};
    }
`;

export const WrapperLabelStyled = styled.label`
    transition-property: color, background-color, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'inline-flex')};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    min-height: 3em;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
    opacity: ${({ theme, isDisabled }) => (isDisabled ? theme.disabledOpacity : 1)};
    vertical-align: middle;

    text-decoration: none;
    box-shadow: none;

    border-radius: ${({ theme }) => theme.borderRadius.button};
    margin: 0;

    text-align: center;

    && svg {
        fill: ${({ theme, variant }) =>
            variant === Variant.Primary ? theme.palette.icon : theme.palette.iconDark};
    }

    ${({ isChecked }) => (isChecked ? checkedCss : uncheckedCss)};
`;

export const IconWrapperStyled = styled.span`
    ${({ theme }) => `
        @media only screen and (min-width: ${theme.screenSize.mediumTablet}px) {
            font-size: 1rem;
            margin-right: ${theme.spacing[1]};
        }
    `};
`;

export const IconStyled = styled.span`
    font-size: 1.5rem;
    margin-right: ${({ theme }) => theme.spacing[1]};
    display: ${({ alwaysShowIcons, variant }) =>
        variant === Variant.Secondary || alwaysShowIcons ? 'flex' : 'none'};

    ${({ theme }) => `
        @media only screen and (min-width: ${theme.screenSize.mediumTablet}px) {
            display: flex;
        }
    `};
`;

const checkedLabelCss = css`
    color: ${({ theme, variant }) =>
        variant === Variant.Primary ? theme.palette.text : theme.palette.white};
`;

const labelSecondaryVariant = css`
    margin-right: auto;
    flex: 1;
    text-align: left;
`;

export const LabelStyled = styled.span`
    ${({ theme }) => theme.typography.smallText};
    ${({ isChecked }) => (isChecked ? checkedLabelCss : null)};
    ${({ variant }) => (variant === Variant.Secondary ? labelSecondaryVariant : null)};

    ${({ theme, alwaysShowIcons }) => `
        @media only screen and (min-width: ${theme.screenSize.mediumTablet}px) {
            margin-right: ${alwaysShowIcons ? '0' : 'auto'};
            flex: 1;
            text-align: left;
        }
    `};
`;

export const CheckIconStyled = styled(IconCheck)`
    font-size: 1.23em;
    margin-left: ${({ theme }) => theme.spacing[1]};
    display: ${({ alwaysShowIcons, variant }) =>
        alwaysShowIcons || variant === Variant.Secondary ? 'flex' : 'none'};

    && svg {
        fill: ${({ theme, variant }) =>
            variant === Variant.Primary ? theme.palette.primaryRegular : theme.palette.white};
    }

    ${({ theme, fullWidth }) => `
        @media only screen and (min-width: ${theme.screenSize.mediumTablet}px) {
            display: flex;
            margin-left: ${fullWidth ? 'auto' : theme.spacing[1]};
        }
    `};
`;

export const CheckboxStyled = styled.input`
    ${visuallyHide};
`;
