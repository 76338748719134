import { getParams } from 'containers/Page/selectors';
import { getArea } from 'pages/blog/selectors';
import { getBlogAreaPosts, getBlogAreaTrendingPosts } from 'api/selectors/content';
import { PAGE_ID, SEARCH_TYPE } from './constants';

export const getPosts = (state) => {
    const params = getParams(state, PAGE_ID);
    return getBlogAreaPosts(state, params.area, SEARCH_TYPE);
};

export const getFeaturedPosts = (state) => {
    const params = getParams(state, PAGE_ID);
    return getBlogAreaPosts(state, params.area, 'featured-blog-posts');
};

export const getTrendingPosts = (state) => {
    const params = getParams(state, PAGE_ID);
    return getBlogAreaTrendingPosts(state, params.area, SEARCH_TYPE);
};

export const getCategoryNames = (state) => {
    const area = getArea(state, PAGE_ID);
    const names = {};
    if (area && area.categories) {
        area.categories.forEach((category) => {
            names[category.slug] = category.description;
        });
    }
    return names;
};
