import { settings } from 'api/actions';
import * as actions from './Hub/actions';

const defaultState = {
    updateId: 0,
    showIsLiveDialog: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actions.resetShowIsLiveDialog.ACTION:
            return {
                ...state,
                showIsLiveDialog: false,
            };
        case settings.sitter.loadProfile.SUCCESS: {
            return {
                ...state,
                profile: {
                    ...action.data,
                },
            };
        }
        case settings.sitter.loadAvailabilities.SUCCESS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                },
            };
        case settings.sitter.updateProfile.DONE: {
            const isSuccess = action.statusCode === 200;
            const profile = isSuccess
                ? {
                      ...action.data,
                  }
                : state.profile;

            let showIsLiveDialog = false;

            if (isSuccess && action.data.isIncomplete !== state.profile.isIncomplete) {
                showIsLiveDialog = true;
            }

            return {
                ...state,
                profile,
                responseStatus: action.statusCode,
                updateId: state.updateId + 1,
                showIsLiveDialog,
            };
        }
        default:
            return state;
    }
};
