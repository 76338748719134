import search from './pages/search/route-paths';
import blog from './pages/blog/route-paths';
import page from './pages/Page/route-paths';
import accounts from './pages/accounts/route-paths';
import howItWorks from './pages/howItWorks/route-paths';
import sitemap from './pages/Sitemap/route-paths';
import user from './pages/user/route-paths';
import email from './pages/email/route-paths';
import join from './pages/join/route-paths';
import ownerFlow from './pages/OwnerFlow/route-paths';

// Add all app route paths in here
export default {
    home: '/',
    login: '/login/',
    resetPassword: {
        requestNewPassword: '/reset-password/',
        setNewPassword: `/reset-password/:uidb64-:token/`,
    },
    search,
    blog,
    page,
    join,
    email,
    accounts,
    howItWorks,
    sitemap,
    trustAndSafety: '/trust-and-safety/',
    pricing: '/pricing/',
    help: '/help/',
    careers: '/careers/',
    privacyPolicy: '/privacy-policy/',
    terms: '/terms-and-conditions/',
    refer: {
        landing: '/refer/:referCode/',
    },
    ...user,
    appDownload: '/app-download/',
    provideReference: '/provide-reference/:referenceRequestToken/',
    logout: '/api/accounts/logout/',
    verifyEmail: '/verify-email/',
    ownerFlow,
};
