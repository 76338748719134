/* eslint-disable import/prefer-default-export */
import sbjs from 'sourcebuster';
import environment from 'environment';
import { getIsMembershipActive } from 'utils/account';
import { endOfDay, format, formatISO, lastDayOfMonth } from 'date-fns';
import { MEMBERSHIP_PAID_STATUS } from 'config/constants';
import { isAppWebViewUserAgent, isBotSplit } from 'utils/utils';
import * as appMessaging from 'utils/app/messaging';
import getPagename from './getPageName';

const getSplitFromInitialState = () => {
    // This is pretty nasty. Due to track not using Redux, it's a massive job to access "split" from the store.
    // This approach is safe as the "split" is only ever set in the SSR...but still feels bad
    // eslint-disable-next-line no-underscore-dangle
    const { split } = window.__INITIAL_STATE__ || {};

    return split;
};

const trackingEnabled = () => {
    const split = getSplitFromInitialState();

    if (isBotSplit(split)) return false;

    return window && window.analytics;
};

const isAppWebView = () => {
    const browserUserAgent = window.navigator.userAgent;
    return isAppWebViewUserAgent(browserUserAgent);
};

const getAnalytics = () => window.analytics;
const getSourcebusterProperties = () => (sbjs.get && sbjs.get.current ? sbjs.get.current : {});

// Gets first letter of enviroment P' for production ‘S’ for staging, 'D’ for local
const getEnvironmentFirstLetter = () => environment.vars.mode.substr(0, 1).toUpperCase();

// subscriberId is used by Salesforce to identify the user
// It is the user's id prefixed with the environment first letter
const getSubscriberId = () => {
    let subscriberId;

    const userId = getAnalytics().user && getAnalytics().user().id();

    if (userId) {
        subscriberId = `${getEnvironmentFirstLetter()}${userId}`;
    }
    return subscriberId;
};

let prevPath = '';
const trackPage = (
    { customProps, triggerManually } = {
        customProps: {},
        triggerManually: false,
    }
) => {
    if (isAppWebView()) {
        if (appMessaging.isEnabled()) {
            const { pathname } = window.location;

            if (pathname !== prevPath || triggerManually) {
                prevPath = pathname;
                const name = getPagename(window.location);
                appMessaging.postMessage(
                    appMessaging.Messages.TRACK_ANALYTICS_EVENT.create({
                        analyticsEventType: 'webview page viewed',
                        properties: {
                            name,
                            path: pathname,
                            url: window.location.href,
                        },
                    })
                );
            }
        }
        return false;
    }

    if (!trackingEnabled()) return false;

    const { pathname } = window.location;

    const split = getSplitFromInitialState();

    /*
     * We can run into a situation when navigating between React and Angular pages
     * where tracking is triggered twice, which we want to avoid if possible.
     */
    if (pathname !== prevPath || triggerManually) {
        prevPath = pathname;
        /**
         * Send page track event to segment on route change.
         * sbjs.get.current gives information about the source of the user e.g.
         * did they come from email / search etc
         */
        const name = getPagename(window.location);

        const subscriberId = getSubscriberId();

        getAnalytics().page(name, {
            ...getSourcebusterProperties(),
            ...(subscriberId && { subscriberId }),
            // FIXME: Removing the title as we're always getting the wrong title.
            // What is happening is that when the title is updated async, the analytics
            // is getting the "old" page title instead of waiting for the new title
            // to be set and then send the "new" title to the analytics.
            title: undefined,
            path: pathname,
            url: window.location.href,
            referrer: document.referrer,
            userAgent: window.navigator.userAgent,
            split,
            ...customProps,
        });

        return true;
    }

    return false;
};

export const getGoogleAnalyticsSession = async () => {
    const timeout = (ms) => new Promise((_, reject) => setTimeout(() => reject(), ms));

    try {
        const { vars } = environment;
        const getSessionId = () =>
            new Promise((resolve) =>
                window.gtag('get', vars.googleAnalytics.measurementId, 'session_id', resolve)
            );
        const getSessionNumber = () =>
            new Promise((resolve) =>
                window.gtag('get', vars.googleAnalytics.measurementId, 'session_number', resolve)
            );

        // NOTE: here we race the both promises because google analytics doesnt
        // resolve the promise if we have the google analytics disabled on our
        // website.
        return {
            id: await Promise.race([getSessionId(), timeout(1000)]),
            number: await Promise.race([getSessionNumber(), timeout(1000)]),
        };
    } catch (e) {
        return null;
    }
};

const createPlanId = (membershipType, membershipPlanId, currencyCode) => {
    const memberTypeCode = {
        homeowner: 'ho',
        housesitter: 'hs',
        combined: 'co',
    };
    const planId = `${
        memberTypeCode[membershipType]
    }-${membershipPlanId}-${currencyCode.toLowerCase()}`;

    return planId;
};

const identify = ({ account, membership, card, settings, isLikelyAHumanFromBotd }) => {
    if (!trackingEnabled() || isAppWebView()) return false;

    // Identify the user - only once after initial SSR. Login/Logout do a full page reload.
    const { id, joinDate } = account || {};
    const {
        membershipType,
        currencyCode,
        membershipPlanId,
        months,
        isAutorenewing,
        startDate,
        expiryDate,
        tier,
    } = membership || {};
    const { email } = settings || {};
    const membershipTier = tier || null;

    // default to anonymous user
    let userMembershipStatus = null;

    // default to an empty id, months, and isAutorenewing
    let planId = null;
    let membershipMonths = null;
    let planAutorenewing = null;
    let membershipStart = null;
    let membershipEnd = null;
    let cardExpiry = null;
    let cardExpiryDate = null;

    // All logged in users will have an id.
    if (id) {
        const registeredDate = joinDate;

        if (getIsMembershipActive(membership)) {
            // Only get card info if the user is a paid member
            // (only paid members have cards associated with their accounts)
            if (card && card.expiryMonth && card.expiryYear) {
                const cardExpiryDateObject = new Date(card.expiryYear, card.expiryMonth - 1);
                cardExpiry = format(cardExpiryDateObject, 'MM/yy');
                // returns yyyy-MM-DDTHH:mm:ss.SSSZ
                cardExpiryDate = formatISO(endOfDay(lastDayOfMonth(cardExpiryDateObject)));
            }
            if (membership.isOnFreeTrial) {
                userMembershipStatus = MEMBERSHIP_PAID_STATUS.onTrial;
            } else {
                userMembershipStatus = MEMBERSHIP_PAID_STATUS.paid;
            }
            // populate planId, months, and isAutorenewing only when the user has paid
            // and planId is in the format of: 'memberTypeCode-membershipPlanId-currency' (e.g. 'hs-4-usd')
            planId = createPlanId(membershipType, membershipPlanId, currencyCode);
            membershipMonths = months;
            planAutorenewing = isAutorenewing;
            membershipStart = startDate;
            membershipEnd = expiryDate;
        } else {
            userMembershipStatus = MEMBERSHIP_PAID_STATUS.notPaid;
        }

        getAnalytics().identify(id, {
            subscriberId: `${getEnvironmentFirstLetter()}${id}`,
            userMembershipStatus,
            registeredDate,
            planId,
            planAutorenewing,
            membershipType,
            membershipMonths,
            membershipStart,
            membershipEnd,
            membershipTier,
            cardExpiry,
            cardExpiryDate,
            email,
            userAgent: window.navigator.userAgent,
        });
    } else {
        // according to the docs we do not send a user id if they are anonymous
        // https://segment.com/docs/sources/website/analytics.js/
        getAnalytics().identify({
            userMembershipStatus,
            userAgent: window.navigator.userAgent,
            isLikelyAHumanFromBotd,
        });
    }

    return true;
};

const track = async (action) => {
    if (isAppWebView()) {
        if (appMessaging.isEnabled()) {
            appMessaging.postMessage(
                appMessaging.Messages.TRACK_ANALYTICS_EVENT.create({
                    analyticsEventType: action.type,
                    properties: { ...action.payload },
                })
            );
        }
        return false;
    }

    if (!trackingEnabled()) return false;

    const context = action.payload?.context || null;

    let payload;
    if (context !== null) {
        payload = action.payload;
        delete payload.context;
    } else {
        payload = action.payload;
    }
    const { pathname, href } = window.location;
    const subscriberId = getSubscriberId();
    const split = getSplitFromInitialState();
    const googleAnalyticsSession = await getGoogleAnalyticsSession();
    const common = {
        ...(subscriberId && { subscriberId }),
        ...payload,
        ...getSourcebusterProperties(),
        ...(googleAnalyticsSession && {
            session_id: googleAnalyticsSession.id,
            session_number: googleAnalyticsSession.number,
        }),
        userAgent: window.navigator.userAgent,
        split,
        path: pathname,
        url: href,
    };

    if (context !== null) {
        // returns an object containing all user traits
        const userTraits = getAnalytics().user().traits();
        getAnalytics().track(action.type, common, {
            context: { ...userTraits, ...context },
        });
    } else {
        getAnalytics().track(action.type, common);
    }

    return true;
};

export { identify, track, trackPage };
