import { call, put, takeEvery, all, take, select } from 'redux-saga/effects';
import * as pageActions from 'containers/Page/actions';
import actions, { settings } from 'api/actions';
import { getBlogCategory, getBlogCategorySubcategory } from 'api/selectors/content';
import { getLanguage } from '../../../shared/selectors';
import { PAGE_ID } from './constants';

export function* raiseError(statusCode) {
    yield put(pageActions.error.create(PAGE_ID, statusCode));
}

export function* loadCategory({ params }) {
    const lang = yield select(getLanguage);

    const category = yield select(getBlogCategory, params.area, params.category);

    if (!category || !category.slug) {
        yield put(
            actions.content.loadBlogCategory({
                filters: {
                    lang,
                },
                data: {
                    areaSlug: params.area,
                    categorySlug: params.category,
                },
            })
        );

        const { status: loadBlogCategoryStatus, statusCode: loadBlogCategoryStatusCode } =
            yield take(settings.content.loadBlogCategory.DONE);

        if (loadBlogCategoryStatus !== settings.content.loadBlogCategory.SUCCESS) {
            yield call(raiseError, loadBlogCategoryStatusCode);
            return false;
        }
    }

    return true;
}

export function* loadSubcategory({ params }) {
    const lang = yield select(getLanguage);

    const subcategory = yield select(
        getBlogCategorySubcategory,
        params.area,
        params.category,
        params.subcategory
    );

    if (!subcategory || !subcategory.slug) {
        yield put(
            actions.content.loadBlogCategorySubcategory({
                forceReload: true,
                filters: {
                    lang,
                },
                data: {
                    areaSlug: params.area,
                    categorySlug: params.category,
                    subcategorySlug: params.subcategory,
                },
            })
        );

        const {
            status: loadBlogCategorySubcategoryStatus,
            statusCode: loadBlogCategorySubcategoryStatusCode,
        } = yield take(settings.content.loadBlogCategorySubcategory.DONE);

        if (
            loadBlogCategorySubcategoryStatus !==
            settings.content.loadBlogCategorySubcategory.SUCCESS
        ) {
            yield call(raiseError, loadBlogCategorySubcategoryStatusCode);
            return false;
        }
    }

    return true;
}

export function* loadSubcategoryPosts({ type, params, search }) {
    yield all([call(loadCategory, { params }), call(loadSubcategory, { params })]);

    const lang = yield select(getLanguage);

    yield put(
        actions.content.loadBlogCategorySubcategoryPosts({
            forceReload: true,
            filters: {
                lang,
                page: parseInt(search.page, 10) || 1,
                limit: 12,
            },
            data: {
                areaSlug: params.area,
                categorySlug: params.category,
                subcategorySlug: params.subcategory,
            },
        })
    );

    const {
        status: loadBlogCategorySubcategoryPostsStatus,
        statusCode: loadBlogCategorySubcategoryPostsStatusCode,
    } = yield take(settings.content.loadBlogCategorySubcategoryPosts.DONE);

    if (
        loadBlogCategorySubcategoryPostsStatus !==
        settings.content.loadBlogCategorySubcategoryPosts.SUCCESS
    ) {
        yield call(raiseError, loadBlogCategorySubcategoryPostsStatusCode);
        return false;
    }

    // If successful then show as loaded
    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(PAGE_ID));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(PAGE_ID));
    }

    return true;
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            loadSubcategoryPosts
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            loadSubcategoryPosts
        ),
    ]);
}
