import { useMemo } from 'react';
import * as namedTransforms from 'config/images';
import { cloudinaryImageLoader } from 'utils/integrations/cloudinary';

function useCloudinaryImage(publicId, transformation, options = {}) {
    const src = useMemo(() => {
        if (!publicId) {
            return options.placeholder;
        }
        const { placeholder, ...cloudinaryOptions } = options;

        // NOTE: For now we only support named transforms.
        return cloudinaryImageLoader({
            publicId,
            options: {
                named: transformation.name,
                format: 'auto',
                ...cloudinaryOptions,
            },
        });
    }, [publicId, transformation, options]);

    return src;
}

export default useCloudinaryImage;
