import { baseAssignmentFields, baseApplicationFields } from './common';

/**
 * Assignment fields
 */
export const assignmentFields = [
    ...baseAssignmentFields,
    'ownerId', // Assignment listing owner id
    'sitterId', // Assignment sitter id
    'listingLocation', // Listing location
    'recentApplications', // Recent 10 Applications
    'applicationsCount', // Number of all applications
    'welcomeGuideStatus', // Welcome guide requested/shared/last-modified/last-opened
    'lastMessage', // Last notification sent
    'newMessagesCount', // Number of unread notifications (@Additional resource?)
    'review', // Assignment Review
    'feedback', // Assignment Feedback
    'reviewRequest', // Assignment Review Requested Date
    'feedbackRequest', // Assignment Feedback Requested Date
    'selectedAnimals', // Listing selected animals
];

/**
 * Open assignment fields
 */
export const openAssignmentFields = [
    ...baseAssignmentFields,
    'listingLocation', // Listing location
    'recentApplications', // Recent 10 Applications
    'applicationsCount', // Number of all applications
];

/**
 * Open assignment applications/invitations fields
 */
export const openAssignmentApplicationFields = [
    ...baseApplicationFields,
    'profileName', // Full applicant profile name
    'sitterAvatarPhoto', // Sitter avatar photo
    'lastMessage', // Last notification sent
    'newMessagesCount', // Number of unread notifications (@Additional resource?)
];

/**
 * Confirmed assignment fields
 */
export const confirmedAssignmentFields = [
    ...baseAssignmentFields,
    'ownerId', // Assignment listing owner id
    'sitterId', // Assignment sitter id
    'listingLocation', // Listing location
    'profileLocation', // Profile location
    'welcomeGuideStatus', // Welcome guide requested/shared/last-modified/last-opened
    'lastMessage', // Last notification sent
    'newMessagesCount', // Number of unread notifications (@Additional resource?)
];

/**
 * Current assignment fields
 */
export const currentAssignmentFields = [
    ...confirmedAssignmentFields,
];

/**
 * Upcoming assignment fields
 */
export const upcomingAssignmentFields = [
    ...confirmedAssignmentFields,
];

/**
 * Past assignment fields
 */
export const pastAssignmentFields = [
    ...baseAssignmentFields,
    'ownerId', // Assignment listing owner id
    'sitterId', // Assignment sitter id
    'listingLocation', // Listing location
    'profileLocation', // Profile location
    'lastMessage', // Last notification sent
    'newMessagesCount', // Number of unread notifications (@Additional resource?)
    'review', // Assignment Review
    'feedback', // Assignment Feedback
    'reviewRequest', // Assignment Review Requested Date
    'feedbackRequest', // Assignment Feedback Requested Date
    'selectedAnimals', // Listing selected animals
];
