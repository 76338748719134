import * as React from 'react';
import { CheckInputStyled, ContainerStyled, LabelStyled } from './Root.style';

/**
 * @description This component is meant to be used alongside the other components in this folder.
 * It uses the same principles as the components in the RadixUI library.
 * Every component is opiniated on its styling and behaviour, but they can be extended and re-styled individually.
 *
 * @example
 * import * as CheckBoxButton from 'components/forms/CheckBoxButton';
 *
 * <CheckBoxButton.Root
 *    isChecked={isChecked}
 *    onClick={handleClick}
 * >
 *   <CheckBoxButton.Content>
 *    <CheckBoxButton.Icon>
 *     <DogIcon />
 *    </CheckBoxButton.Icon>
 *   <CheckBoxButton.Title>Dog</CheckBoxButton.Title>
 *  </CheckBoxButton.Content>
 *
 *  <CheckBoxButton.CheckMark />
 * </CheckBoxButton.Root>
 * */
function Root({ className, id, isChecked, disabled, onClick, children }) {
    const buttonId = `checkButton_${id || Math.random().toString().replace(/0\./, '')}`;

    const handleChange = () => {
        onClick(!isChecked);
    };

    return (
        <ContainerStyled className={className} data-status={isChecked && 'selected'}>
            <CheckInputStyled
                id={buttonId}
                type="checkbox"
                disabled={disabled}
                checked={isChecked}
                onChange={handleChange}
            />

            <LabelStyled htmlFor={buttonId}>{children}</LabelStyled>
        </ContainerStyled>
    );
}

Root.defaultProps = {
    className: '',
    id: undefined,
    disabled: false,
};

export default Root;
