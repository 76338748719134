import { all, put, takeEvery, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import actions, { settings } from 'api/actions';
import { getAccount } from 'api/selectors';
import { routes } from 'utils/routes';
import { PlanTiers } from 'api/types';
import { getStoredPromo } from 'shared/selectors';
import { storePromo as storePromoAction } from 'shared/actions';
import {
    selectUpgradePlan as selectUpgradePlanAction,
    loadPlans as loadPlansAction,
} from './actions';

export function* loadPlans() {
    const account = yield select(getAccount);
    const existingMembershipPlan = account.membershipPlan;
    const tiersToLoad = [PlanTiers.CLASSIC, PlanTiers.BASIC, PlanTiers.STANDARD, PlanTiers.PREMIUM];

    // A promo code could be in the store already - if so use it
    // If not look in session storage (as used by angular) - if it's there then use it but
    // also copy it back in to the store
    let promoCode = yield select(getStoredPromo);

    if (!promoCode && global.sessionStorage) {
        promoCode = JSON.parse(global.sessionStorage.getItem('promo'));
        if (promoCode) {
            yield put(storePromoAction.create(promoCode));
        }
    }

    yield put(
        actions.plans.load({
            forceReload: true,
            filters: {
                currency_code: existingMembershipPlan.currencyCode,
                promo_code: promoCode,
                variation: existingMembershipPlan.variation,
                'tiers[]': tiersToLoad,
            },
        })
    );

    yield take(settings.plans.load.DONE);
}

export function* selectUpgradePlan({ membershipPlan }) {
    const account = yield select(getAccount);

    yield put(
        actions.account.createIncompleteMembershipPlan({
            forceReload: true,
            data: {
                promoCode: membershipPlan.promoCode && membershipPlan.promoCode.code,
                membershipPlanId: membershipPlan.id,
                userId: account.details.id,
                currencyCode: membershipPlan.currencyCode,
            },
        })
    );

    // Wait for plan to created
    yield take(settings.account.createIncompleteMembershipPlan.DONE);

    // Redirect to checkout
    yield put(
        push({
            pathname: routes.accounts.signUp.upgrade(),
            search: '?membershipType=combined',
        })
    );
}

export default function* saga() {
    yield all([
        takeEvery(selectUpgradePlanAction.ACTION, selectUpgradePlan),
        takeEvery(loadPlansAction.ACTION, loadPlans),
    ]);
}
