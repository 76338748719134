import React, { forwardRef } from 'react';
import { WrapperStyled } from './Field.style';

const Field = forwardRef(({ children, ...rest }, ref) => (
    <WrapperStyled ref={ref} {...rest}>
        {children}
    </WrapperStyled>
));

Field.displayName = 'Form.Field';

export default Field;
