const blogRoutePrefix = 'area(blog|engineering|content)';

export default {
    area: `/:${blogRoutePrefix}/`,
    search: `/:${blogRoutePrefix}/search/`,
    author: `/:${blogRoutePrefix}/authors/:author/`,
    category: `/:${blogRoutePrefix}/:category/`,
    post: `/:${blogRoutePrefix}/:category/:slug/`,
    tags: `/:${blogRoutePrefix}/tags/:tag/`,
    subcategory: `/:${blogRoutePrefix}/:category/subcategories/:subcategory/`,
    codeOfConduct: '/blog/news/code-of-conduct/',
};
