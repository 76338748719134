export const getShareUrl = ({
    mainUrl: receivedMainUrl,
    utmSource: receivedUtmSource,
    utmMedium: receivedUtmMedium,
    utmCampaign: receivedUtmCampaign,
    raf,
    referrerListingId,
    referrerProfileId,
}) => {
    const mainUrl = receivedMainUrl.slice(-1) === '/' ? receivedMainUrl : `${receivedMainUrl}/`;
    const utmSource = `utm_source=${receivedUtmSource}`;
    const utmMedium = receivedUtmMedium ? `utm_medium=${receivedUtmMedium}` : '';
    const utmCampaign = receivedUtmCampaign ? `utm_campaign=${receivedUtmCampaign}` : '';
    let rafAndListingProfile = '';
    if (raf && (referrerListingId || referrerProfileId)) {
        rafAndListingProfile = `&promo=${raf}&`;
        if (referrerListingId) rafAndListingProfile += `referrerListingId=${referrerListingId}`;
        else rafAndListingProfile += `referrerProfileId=${referrerProfileId}`;
    }

    return `${mainUrl}?${utmSource}&${utmMedium}&${utmCampaign}${rafAndListingProfile}`;
};

/**
 * @typedef ReferAFriendShareableURLUTM
 * @prop source?: string
 * @prop medium?: string
 * @prop campaign?: string
 */

/**
 * @param {string} url
 * @param {ReferAFriendShareableURLUTM} utm
 */
export const createReferAFriendShareableURL = (url, utm = {}) => {
    if (!utm || Object.keys(utm).length === 0) {
        return url;
    }

    const utmQueryString = Object.entries(utm)
        .reduce((cur, [key, value]) => {
            if (typeof value === 'string') {
                return [...cur, `utm_${key}=${encodeURI(value)}`];
            }

            return cur;
        }, [])
        .join('&');

    return `${url}?${utmQueryString}`;
};
