import { v3ApiUrl } from './constants';

export default {
    // Blog
    blog: {
        areas: {
            // GET: All areas
            _: `${v3ApiUrl}content/blog/areas/`,
        },
        area: {
            // GET: Area
            _: `${v3ApiUrl}content/blog/areas/:areaSlug:/`,

            // GET: Area posts
            posts: `${v3ApiUrl}content/blog/areas/:areaSlug:/posts/`,

            // GET: Area categories
            categories: `${v3ApiUrl}content/blog/areas/:areaSlug:/categories/`,

            // GET: Trending posts for area
            trendingPosts: `${v3ApiUrl}content/blog/areas/:areaSlug:/trending/posts/`,

            // GET: Related posts for area
            relatedPosts: `${v3ApiUrl}content/blog/areas/:areaSlug:/related/posts/`,
        },
        category: {
            // GET: Category
            _: `${v3ApiUrl}content/blog/areas/:areaSlug:/categories/:categorySlug:/`,

            // GET: Category posts
            posts: `${v3ApiUrl}content/blog/areas/:areaSlug:/categories/:categorySlug:/posts/`,

            subcategory: {
                // GET: Category subcategory
                _: `${v3ApiUrl}content/blog/areas/:areaSlug:/categories/:categorySlug:/subcategories/:subcategorySlug:/`,

                // GET: Category subcategories
                all: `${v3ApiUrl}content/blog/areas/:areaSlug:/categories/:categorySlug:/subcategories/`,

                // GET: Category subcategory posts
                posts: `${v3ApiUrl}content/blog/areas/:areaSlug:/categories/:categorySlug:/subcategories/:subcategorySlug:/posts/`,
            },
        },
        tags: {
            // GET: Tag
            _: `${v3ApiUrl}content/blog/areas/:areaSlug:/tags/:tagSlug:/`,

            // GET: All posts with tag associated with
            posts: `${v3ApiUrl}content/blog/areas/:areaSlug:/tags/:tagSlug:/posts/`,
        },
        post: {
            // GET: Post
            _: `${v3ApiUrl}content/blog/areas/:areaSlug:/categories/:categorySlug:/posts/:postSlug:/`,
        },
        author: {
            // GET: Author information
            _: `${v3ApiUrl}content/blog/author/:authorName:/`,

            // GET: All posts from given author
            posts: `${v3ApiUrl}content/blog/author/:authorName:/posts/`,
        },
    },
    // GET: Page Seo Content
    seoContent: `${v3ApiUrl}content/seo-content/`,
    page: {
        // GET: Post
        _: `${v3ApiUrl}content/pages/:pageSlug:/`,
    },
};
