import { coreApi as api } from './coreApi';
export const addTagTypes = ['verifications'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            createVerificationsCriminalBackgroundCheck: build.mutation<
                CreateVerificationsCriminalBackgroundCheckApiResponse,
                CreateVerificationsCriminalBackgroundCheckApiArg
            >({
                query: () => ({ url: `/verifications/criminal-background-check/`, method: 'POST' }),
                invalidatesTags: ['verifications'],
            }),
            createVerificationsEmail: build.mutation<
                CreateVerificationsEmailApiResponse,
                CreateVerificationsEmailApiArg
            >({
                query: () => ({ url: `/verifications/email/`, method: 'POST' }),
                invalidatesTags: ['verifications'],
            }),
            createVerificationsEmailVerify: build.mutation<
                CreateVerificationsEmailVerifyApiResponse,
                CreateVerificationsEmailVerifyApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/email-verify/`,
                    method: 'POST',
                    body: queryArg.emailVerify,
                }),
                invalidatesTags: ['verifications'],
            }),
            getVerificationsIdCheck: build.query<
                GetVerificationsIdCheckApiResponse,
                GetVerificationsIdCheckApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/id-check/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['verifications'],
            }),
            createVerificationsIdCheck: build.mutation<
                CreateVerificationsIdCheckApiResponse,
                CreateVerificationsIdCheckApiArg
            >({
                query: () => ({ url: `/verifications/id-check/`, method: 'POST' }),
                invalidatesTags: ['verifications'],
            }),
            updateVerificationsPhone: build.mutation<
                UpdateVerificationsPhoneApiResponse,
                UpdateVerificationsPhoneApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/phone/${queryArg.requestId}/`,
                    method: 'PUT',
                    body: queryArg.phoneVerify,
                }),
                invalidatesTags: ['verifications'],
            }),
            createVerificationsPhoneRequest: build.mutation<
                CreateVerificationsPhoneRequestApiResponse,
                CreateVerificationsPhoneRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/phone/request/`,
                    method: 'POST',
                    body: queryArg.phoneRequest,
                }),
                invalidatesTags: ['verifications'],
            }),
            getVerificationsReferences: build.query<
                GetVerificationsReferencesApiResponse,
                GetVerificationsReferencesApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/references/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                        verified: queryArg.verified,
                    },
                }),
                providesTags: ['verifications'],
            }),
            getVerificationsReference: build.query<
                GetVerificationsReferenceApiResponse,
                GetVerificationsReferenceApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/references/${queryArg.referenceId}/`,
                }),
                providesTags: ['verifications'],
            }),
            deleteVerificationsReference: build.mutation<
                DeleteVerificationsReferenceApiResponse,
                DeleteVerificationsReferenceApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/references/${queryArg.referenceId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['verifications'],
            }),
            getVerificationsReferencesProvide: build.query<
                GetVerificationsReferencesProvideApiResponse,
                GetVerificationsReferencesProvideApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/references/provide/${queryArg.token}/`,
                }),
                providesTags: ['verifications'],
            }),
            updateVerificationsReferencesProvide: build.mutation<
                UpdateVerificationsReferencesProvideApiResponse,
                UpdateVerificationsReferencesProvideApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/references/provide/${queryArg.token}/`,
                    method: 'PUT',
                    body: queryArg.referenceProvide,
                }),
                invalidatesTags: ['verifications'],
            }),
            createVerificationsReferencesRequest: build.mutation<
                CreateVerificationsReferencesRequestApiResponse,
                CreateVerificationsReferencesRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/references/request/`,
                    method: 'POST',
                    body: queryArg.referenceRequest,
                }),
                invalidatesTags: ['verifications'],
            }),
            createVerificationsSocialAirbnb: build.mutation<
                CreateVerificationsSocialAirbnbApiResponse,
                CreateVerificationsSocialAirbnbApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/social/airbnb/`,
                    method: 'POST',
                    body: queryArg.social,
                }),
                invalidatesTags: ['verifications'],
            }),
            updateVerificationsSocialAirbnb: build.mutation<
                UpdateVerificationsSocialAirbnbApiResponse,
                UpdateVerificationsSocialAirbnbApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/social/airbnb/${queryArg.socialId}/`,
                    method: 'PUT',
                    body: queryArg.social,
                }),
                invalidatesTags: ['verifications'],
            }),
            deleteVerificationsSocialAirbnb: build.mutation<
                DeleteVerificationsSocialAirbnbApiResponse,
                DeleteVerificationsSocialAirbnbApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/social/airbnb/${queryArg.socialId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['verifications'],
            }),
            createVerificationsSocialLinkedin: build.mutation<
                CreateVerificationsSocialLinkedinApiResponse,
                CreateVerificationsSocialLinkedinApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/social/linkedin/`,
                    method: 'POST',
                    body: queryArg.social,
                }),
                invalidatesTags: ['verifications'],
            }),
            updateVerificationsSocialLinkedin: build.mutation<
                UpdateVerificationsSocialLinkedinApiResponse,
                UpdateVerificationsSocialLinkedinApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/social/linkedin/${queryArg.socialId}/`,
                    method: 'PUT',
                    body: queryArg.social,
                }),
                invalidatesTags: ['verifications'],
            }),
            deleteVerificationsSocialLinkedin: build.mutation<
                DeleteVerificationsSocialLinkedinApiResponse,
                DeleteVerificationsSocialLinkedinApiArg
            >({
                query: (queryArg) => ({
                    url: `/verifications/social/linkedin/${queryArg.socialId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['verifications'],
            }),
            getVerificationsStatus: build.query<
                GetVerificationsStatusApiResponse,
                GetVerificationsStatusApiArg
            >({
                query: () => ({ url: `/verifications/status/` }),
                providesTags: ['verifications'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as verificationsApi };
export type CreateVerificationsCriminalBackgroundCheckApiResponse =
    /** status 200  */ CriminalBackgroundCheck;
export type CreateVerificationsCriminalBackgroundCheckApiArg = void;
export type CreateVerificationsEmailApiResponse = /** status 201 No response body */
    | undefined
    | /** status 204  */ {
          [key: string]: any;
      };
export type CreateVerificationsEmailApiArg = void;
export type CreateVerificationsEmailVerifyApiResponse = unknown;
export type CreateVerificationsEmailVerifyApiArg = {
    emailVerify: EmailVerify;
};
export type GetVerificationsIdCheckApiResponse = /** status 200  */ IdCheck;
export type GetVerificationsIdCheckApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
};
export type CreateVerificationsIdCheckApiResponse = /** status 200  */ IdCheck;
export type CreateVerificationsIdCheckApiArg = void;
export type UpdateVerificationsPhoneApiResponse = /** status 200  */ PhoneVerify;
export type UpdateVerificationsPhoneApiArg = {
    requestId: string;
    phoneVerify: PhoneVerify;
};
export type CreateVerificationsPhoneRequestApiResponse = /** status 201  */
    | PhoneRequest
    | /** status 204  */ {
          [key: string]: any;
      };
export type CreateVerificationsPhoneRequestApiArg = {
    phoneRequest: PhoneRequest;
};
export type GetVerificationsReferencesApiResponse = /** status 200  */ PaginatedResponse27;
export type GetVerificationsReferencesApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
    /** Filter the references and only retrieve the verified ones. */
    verified?: boolean;
};
export type GetVerificationsReferenceApiResponse = /** status 200  */ Reference;
export type GetVerificationsReferenceApiArg = {
    referenceId: string;
};
export type DeleteVerificationsReferenceApiResponse = unknown;
export type DeleteVerificationsReferenceApiArg = {
    referenceId: string;
};
export type GetVerificationsReferencesProvideApiResponse = /** status 200  */ ReferenceProvide;
export type GetVerificationsReferencesProvideApiArg = {
    token: string;
};
export type UpdateVerificationsReferencesProvideApiResponse = /** status 200  */ ReferenceProvide;
export type UpdateVerificationsReferencesProvideApiArg = {
    token: string;
    referenceProvide: ReferenceProvide;
};
export type CreateVerificationsReferencesRequestApiResponse = /** status 201  */ ReferenceRequest;
export type CreateVerificationsReferencesRequestApiArg = {
    referenceRequest: ReferenceRequest;
};
export type CreateVerificationsSocialAirbnbApiResponse = /** status 200  */ Social;
export type CreateVerificationsSocialAirbnbApiArg = {
    social: Social;
};
export type UpdateVerificationsSocialAirbnbApiResponse = /** status 200  */ Social;
export type UpdateVerificationsSocialAirbnbApiArg = {
    socialId: string;
    social: Social;
};
export type DeleteVerificationsSocialAirbnbApiResponse = unknown;
export type DeleteVerificationsSocialAirbnbApiArg = {
    socialId: string;
};
export type CreateVerificationsSocialLinkedinApiResponse = /** status 200  */ Social;
export type CreateVerificationsSocialLinkedinApiArg = {
    social: Social;
};
export type UpdateVerificationsSocialLinkedinApiResponse = /** status 200  */ Social;
export type UpdateVerificationsSocialLinkedinApiArg = {
    socialId: string;
    social: Social;
};
export type DeleteVerificationsSocialLinkedinApiResponse = unknown;
export type DeleteVerificationsSocialLinkedinApiArg = {
    socialId: string;
};
export type GetVerificationsStatusApiResponse = /** status 200  */ VerificationStatus;
export type GetVerificationsStatusApiArg = void;
export type StatusB93Enum = 'requested' | 'pending' | 'verified' | 'failed';
export type CriminalBackgroundCheck = {
    completedDate?: string | null;
    id: string;
    link: string;
    requestedDate: string;
    status: StatusB93Enum;
    userId: number;
};
export type EmailVerify = {
    token: string;
};
export type IdCheck = {
    completedDate?: string | null;
    id: string;
    link: string;
    requestedDate: string;
    status: StatusB93Enum;
    userId: number;
};
export type PhoneVerify = {
    code: string;
    id: string;
    number: string;
    requestId: string;
    requestedDatetime: string;
    verifiedDatetime: string | null;
};
export type PhoneRequest = {
    country: string;
    countryId: number;
    id: string;
    number: string;
    requestId: string;
    requestedDatetime: string;
};
export type CharacteristicsEnum =
    | 'caring'
    | 'tidy'
    | 'reliable'
    | 'responsible'
    | 'trustworthy'
    | 'animal_lover'
    | 'friendly'
    | 'professional'
    | 'organised';
export type RefereeConnectionEnum =
    | 'friend'
    | 'family'
    | 'colleague'
    | 'house_or_pet_sitter'
    | 'neighbour'
    | 'other';
export type Reference = {
    averageScore: number;
    characteristics: CharacteristicsEnum[];
    firstName: string;
    happyToBeContacted: boolean | null;
    hasLookedAfterHouse: boolean | null;
    hasLookedAfterPets: boolean | null;
    id: string;
    organised: number | null;
    petCare: number | null;
    refereeConnection: RefereeConnectionEnum;
    reference: string;
    referenceVersion: number;
    reliable: number | null;
    requestedDatetime: string;
    selfSufficient: number | null;
    tidy: number | null;
    token: string;
    verifiedDatetime: string | null;
};
export type PaginatedResponse27 = {
    count: number;
    hasMore: boolean;
    results: Reference[];
};
export type Type5BcEnum = 'employment' | 'character' | 'house' | 'landlord';
export type ReferenceProvide = {
    characteristics: CharacteristicsEnum[];
    firstName: string;
    happyToBeContacted: boolean | null;
    hasLookedAfterHouse: boolean | null;
    hasLookedAfterPets: boolean | null;
    id: string;
    organised: number;
    petCare?: number | null;
    refereeConnection: RefereeConnectionEnum;
    refereeUser: string;
    reference: string;
    referenceVersion?: number;
    reliable: number;
    requestMessage: string;
    selfSufficient: number;
    tidy: number;
    type: Type5BcEnum;
    verifiedDatetime: string | null;
};
export type ReferenceRequest = {
    firstName: string;
    id: string;
    token: string;
};
export type PlatformEnum = 'airbnb' | 'facebook' | 'instagram' | 'linkedin';
export type Social = {
    id: string;
    platform: PlatformEnum;
    platformIdentifier: string;
    user: number;
};
export type VerificationStatus = {
    criminalBackgroundCheck: string;
    email: string;
    identityCheck: string;
    phone: string;
    reference: string;
};
export const {
    useCreateVerificationsCriminalBackgroundCheckMutation,
    useCreateVerificationsEmailMutation,
    useCreateVerificationsEmailVerifyMutation,
    useGetVerificationsIdCheckQuery,
    useCreateVerificationsIdCheckMutation,
    useUpdateVerificationsPhoneMutation,
    useCreateVerificationsPhoneRequestMutation,
    useGetVerificationsReferencesQuery,
    useGetVerificationsReferenceQuery,
    useDeleteVerificationsReferenceMutation,
    useGetVerificationsReferencesProvideQuery,
    useUpdateVerificationsReferencesProvideMutation,
    useCreateVerificationsReferencesRequestMutation,
    useCreateVerificationsSocialAirbnbMutation,
    useUpdateVerificationsSocialAirbnbMutation,
    useDeleteVerificationsSocialAirbnbMutation,
    useCreateVerificationsSocialLinkedinMutation,
    useUpdateVerificationsSocialLinkedinMutation,
    useDeleteVerificationsSocialLinkedinMutation,
    useGetVerificationsStatusQuery,
} = injectedRtkApi;
