// eslint-disable-next-line import/prefer-default-export
export const ANSWERED_SURVEY = {
    create({ description, answer, reason }) {
        return {
            type: 'Answered Survey',
            payload: {
                description,
                answer,
                reason,
            },
        };
    },
};
