import styled from 'styled-components';

export const FacetsGridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;

    @media screen and (min-width: 768px) {
        ${({ columns }) => `
            grid-template-columns: ${columns.reduce((acc, width) => `${acc} ${width}fr`, '')};
            column-gap: 16px;
        `}
    }
`;

export const FacetsList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    row-gap: 8px;

    @media screen and (min-width: 768px) {
        ${({ columns, rows }) => `
            grid-auto-flow: column;
            grid-template-columns: repeat(${columns}, 1fr);
            grid-template-rows: repeat(${rows}, auto);
            column-gap: 16px;
        `}
    }
`;

export const FacetsListActionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
        ${({ columns }) => `
            grid-template-columns: repeat(${columns}, 1fr);
            column-gap: 16px;
        `}
    }
`;

export const FacetsListAction = styled.button`
    all: unset;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.link};
    font-weight: 600;
    text-decoration: underline;

    @media screen and (min-width: 768px) {
        ${({ startAt }) => `
            grid-column-start: ${startAt};  
        `}
    }
`;
