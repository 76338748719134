// A List of blacklisted errors
export const BLACKLISTED_ERROR_TYPES = [
    // MapBox GL - Maps
    'WebGL',
    // MapBox GL - Maps
    'WebGLRenderingContext',
    // Do not throw errors in console when we exceeded quota
    'QuotaExceededError',
    // Honey - browser extension
    'honeypay',
    // GetSiteControl popups
    'getsitecontrol',
    // ProfitWell
    'profitwell',
];

// eslint-disable-next-line import/prefer-default-export
export const filterInboundSentryLogs = (event) => {
    let isBlackListed = false;

    try {
        for (let i = 0; i < event.exception.values.length; i += 1) {
            const exception = event.exception.values[i];
            const { type = '', value = '', stacktrace = [] } = exception;

            // Check type
            if (type !== null && BLACKLISTED_ERROR_TYPES.some((val) => type.includes(val))) {
                isBlackListed = true;
                break;
            }

            // Check keyword in value
            if (value !== null && BLACKLISTED_ERROR_TYPES.some((val) => value.includes(val))) {
                isBlackListed = true;
                break;
            }

            // Check keyword in stacktrace
            if (stacktrace !== null) {
                const stacktraceString = JSON.stringify(stacktrace);
                if (BLACKLISTED_ERROR_TYPES.some((val) => stacktraceString.includes(val))) {
                    isBlackListed = true;
                    break;
                }
            }
        }
    } catch (e) {
        // eslint-disable-next-line no-empty
    }

    if (isBlackListed) return null;
    return event;
};
