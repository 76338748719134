// eslint-disable-next-line import/prefer-default-export
export const OWNER_CREATED_ASSIGNMENT = {
    create(assignment) {
        const payload = {
            ...assignment,
        };

        return {
            type: 'Owner Created Assignment',
            payload,
        };
    },
};
