import PropTypes from 'prop-types';

export const notificationProps = {
    id: PropTypes.string,
    body: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
    }),
    type: PropTypes.oneOf([
        'message', 'message-photo', 'message-video', 'assignment-application', 'assignment-datechange', 'datechange-declined',
        'datechange-accepted', 'assignment-datedeleted', 'assignment-invitation', 'assignment-confirmed', 'assignment-unconfirmed',
        'assignment-declined', 'invitation-declined', 'application-declined', 'application-confirmed', 'application-cancelled',
        'sitconfirmation-declined', 'sitconfirmation-confirmed', 'invitation-cancelled', 'invitation-acknowledged',
        'assignment-finished',
        'welcomeguide-requested', 'welcomeguide-received', 'reminders-added', 'feedback-received', 'feedback-response',
        'feedbackrequest-received', 'review-received', 'review-response', 'reviewrequest-received', 'favourite-newdates',
        'recommended-sits', 'recommended-travelinspiration', 'recommended-petinspiration',
        'system-appupdate', 'system-tncupdate',
        'going-away-anytime-soon', 'want-to-reach-out',
    ]),
    sendDate: PropTypes.string,
};

export const notification = PropTypes.shape(notificationProps);

export const notifications = PropTypes.arrayOf(notification);
