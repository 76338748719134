import { routes } from 'utils/routes';
import howItWorksRoutes from 'pages/howItWorks/route-paths';

const pathnameToPagenameMap = {
    [routes.home()]: 'Home',
    [howItWorksRoutes.howItWorks]: 'How It Works',
    [howItWorksRoutes.findASitter]: 'How It Works - Find a sitter',
    [howItWorksRoutes.findAHouseSit]: 'How It Works - Find a house sit',
    [routes.trustAndSafety()]: 'Trust and Safety',
    [routes.page.page({ slug: 'insurance-backed-guarantee' })]: 'IBG',
    [routes.page.page({ slug: 'home-and-contents-protection' })]: 'HCP',
    [routes.page.page({ slug: 'app' })]: 'App',
    [routes.page.page({ slug: 'about' })]: 'About Us',
    [routes.page.pageOnRoot({ slug: 'dog-sitting' })]: 'Landing',
    [routes.page.pageOnRoot({ slug: 'dog-sitters-near-me' })]: 'Landing',
    [routes.page.pageOnRoot({ slug: 'cat-sitting' })]: 'Landing',
    [routes.page.pageOnRoot({ slug: 'cat-sitters' })]: 'Landing',
    [routes.page.happiestPetsCompetition2024()]: 'Happiest Pets Competition 2024',
    [routes.privacyPolicy()]: 'Privacy Policy',
    [routes.terms()]: 'Terms of Service',
    [routes.pricing()]: 'Pricing',
    [routes.accounts.checkout.payment()]: 'Digital Wallet Checkout',
    [routes.accounts.explorePlans()]: 'Explore Plans',
    [routes.accounts.referAFriend()]: 'Refer-A-Friend',
    [routes.accounts.profile.dashboard()]: 'Dashboard',
    [routes.accounts.profile.inbox()]: 'Inbox',
    [routes.user.assignments.pastSits()]: 'Past Sits',
    [routes.user.assignments.pastSitters()]: 'Past Sitters',
    [routes.accounts.profile.settings()]: 'Settings Page',
    [routes.accounts.signUp.joinNow()]: 'Join Now',
    [routes.accounts.signUp.createAccount()]: 'Create Account',
    [routes.accounts.signUp.checkout()]: 'Billing',
    [routes.accounts.signUp.payment()]: 'Payment',
    [routes.accounts.signUp.pricing()]: 'Pricing',
    [routes.accounts.signUp.choosePlan()]: 'Choose Plan',
    [routes.user.saved.savedYourListing()]: 'Saved Your Listing',
    [routes.user.settings.membership.refund()]: 'Refund',
    [routes.search.findASitterWizard()]: 'Find a Sitter Wizard',
    [routes.search.findAHouseSitWizard()]: 'Find a Sit Wizard',
    [routes.login()]: 'Login',
    [routes.user.favourites()]: 'Favourites',
    [routes.user.inbox()]: 'Inbox',
    [routes.user.upgrade()]: 'Upgrade Landing',
    [routes.user.settings.hub()]: 'Settings hub',
    [routes.user.settings.details()]: 'Account Details',
    [routes.user.settings.membership.manage()]: 'Membership Settings',
    [routes.user.settings.contactPreferences()]: 'Contact Preferences',
    [routes.join.petParents()]: 'Owner Acquisition',
};

// pathnameToPagenameMap[homeRoute] = 'Home';

const pathnameRegex = [
    [/\/pages\/.*/i, 'Landing'],
    [/\/dog-sitters\/.*/i, 'Pet Landing Page'],
    [/\/blog\/.*?\/.*?\//i, 'Blog'],
    [/\/refer\/raf.*\//i, 'Referral Landing'],
    [/\/engineering\/.*/i, 'Engineering'],
    [/\/provide-reference\/.*/i, 'Provide Reference'],
    [/^\/house-and-pet-sitting-assignments\/((?!\/l\/).)*$/i, 'Find a Sit Home'],
    [/^\/house-and-pet-sitters\/((?!\/l\/).)*$/i, 'Find a Sitter Home'],
    [/^\/cat-sitters\/((?!\/l\/).)*$/i, 'Find a Cat Sitter Home'],
    [/^\/user\/listing\/(\d*)\/applications\/(\d*)/, 'Assignment applications'],
];

export { pathnameToPagenameMap, pathnameRegex };
