import { settings as actions } from '../../actions';

import {
    baseInitialState, loadedDataReducer, createdDataReducer, updatedDataReducer, deletedDataReducer,
} from '../common';

export const initialState = { ...baseInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        // case actions.owner.loadListings.SUCCESS:
        //     return {
        //         ...state,
        //         data: {
        //             ...state.data,
        //             ...action.data.results.reduce((medias, listing) => {
        //                 listing.medias.forEach((media) => {
        //                     medias[media.id] = media;
        //                 });
        //                 return medias;
        //             }, {}),
        //         },
        //     };
        case actions.owner.loadListingPhotos.SUCCESS:
            return loadedDataReducer(state, action);
        case actions.owner.createListingPhoto.SUCCESS:
            return createdDataReducer(state, action);
        case actions.owner.updateListingPhoto.SUCCESS:
            return updatedDataReducer(state, action);
        case actions.owner.removeListingPhoto.SUCCESS:
            return deletedDataReducer(state, action);
        default:
            return state;
    }
};
