import { all, fork } from 'redux-saga/effects';
import searchListings from './SearchListings/sagas';
import listingDetail from './ListingDetail/sagas';
import searchProfiles from './SearchProfiles/sagas';
import profileDetail from './ProfileDetail/sagas';
import savedSearchRedirect from './SavedSearchRedirect/sagas';
import findASitterWizard from './FindASitterWizard/sagas';
import findAHouseSitWizard from './FindAHouseSitWizard/sagas';
import dynamicFacetsSagas from './components/DynamicFacets/sagas';

export default function* sagas() {
    yield all([
        fork(searchListings),
        fork(listingDetail),
        fork(searchProfiles),
        fork(profileDetail),
        fork(savedSearchRedirect),
        fork(findASitterWizard),
        fork(findAHouseSitWizard),
        fork(dynamicFacetsSagas),
    ]);
}
