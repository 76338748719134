import PropTypes from 'prop-types';
import styled from 'styled-components';

const Area = styled.div`
    grid-area: ${(props) => props.name};

    > * {
        width: 100%;
    }

    @supports (display: grid) {
        > * {
            width: auto;
        }
    }
`;

Area.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Area;
