import React, { forwardRef } from 'react';
import { IconCheckCircle, IconExclamationCircle } from 'components/Icon';
import { MessageStyled, MessageIconStyled } from './Message.style';

const VARIANT_ICONS = {
    success: IconCheckCircle,
    danger: IconExclamationCircle,
};

const Message = forwardRef(({ variant, children, ...rest }, ref) => {
    const MessageIcon = VARIANT_ICONS[variant];

    return (
        <MessageStyled ref={ref} variant={variant} {...rest}>
            {MessageIcon ? (
                <MessageIconStyled>
                    <MessageIcon />
                </MessageIconStyled>
            ) : null}

            {children}
        </MessageStyled>
    );
});

Message.displayName = 'Form.Message';

Message.defaultProps = {
    variant: 'hint',
};

export default Message;
