import ApiConfig from '../config';
import ApiAction from './common';

const config = {
    load: ['MEDIAS', 'LOAD', ApiConfig.endpoints.medias._],
    create: ['MEDIAS', 'CREATE', ApiConfig.endpoints.medias._],
    update: ['MEDIAS', 'UPDATE', ApiConfig.endpoints.medias.mediaUpdate],
    remove: ['MEDIAS', 'REMOVE', ApiConfig.endpoints.medias.media],
    uploadChunk: ['MEDIA_UPLOAD', 'CREATE', ApiConfig.endpoints.medias.upload],
    uploadToken: ['MEDIA_UPLOAD_TOKEN', 'LOAD', ApiConfig.endpoints.medias.token],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
