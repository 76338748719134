import loadable from '@loadable/component';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { retry } from 'utils/ssr';
import asPage from 'containers/Page';
import { getSearchListing, getSearchProfile } from 'api/selectors/search';
import { getSitterProfile, isSitterOwnerListingFavourite } from 'api/selectors/sitter';
import { MembershipType } from 'api/types/membershipTypes';
import { getIsLoggedIn, getSeoContent } from 'shared/selectors';
import { utmMediums } from 'utils/integrations/sbjs';
import { getAccount, getAccountCurrentMembershipPlan } from 'api/selectors/account';
import {
    getMembershipStatus,
    getMembershipType,
    getIsMembershipActive,
    isOwnerOnly,
    isPartial,
    isExpired,
} from 'utils/account';
import { setItem } from 'src/universalStorage/actions';
import { getItem } from 'src/universalStorage/selectors';
import { addDays, isAfter } from 'date-fns';
import {
    DEFAULT_APP_MODAL_RELIEF_DURATION,
    LAST_SEEN_APP_MODAL_DEFAULT,
    SHOW_DOWNLOAD_APP_MODAL_AFTER,
} from './ListingDetail.constants';

const getCameFromEmail = (state) => {
    let cameFromEmail = false;
    if (state.router && state.router.location && state.router.location.search) {
        const searchObj = parse(state.router.location.search);
        if (
            searchObj.utm_source === utmMediums.EMAIL ||
            searchObj.utm_medium === utmMediums.EMAIL
        ) {
            cameFromEmail = true;
        }
    }

    return cameFromEmail;
};

const getRouter = (state) => {
    if (state.router) return state.router;
};

export const mapStateToProps = (state, props) => {
    const currentMembershipPlan = getAccountCurrentMembershipPlan(state);
    const isPaidMember = getIsMembershipActive(currentMembershipPlan);
    const showLowApplicantsFeatures = isPaidMember;

    const listing = getSearchListing(state, props.match.params.id);

    const membershipType = getMembershipType(getAccount(state).membershipPlan);

    let isFavourited = false;

    if (membershipType === MembershipType.SITTER || membershipType === MembershipType.COMBINED) {
        isFavourited = isSitterOwnerListingFavourite(state, listing.id);
    }

    const today = new Date();
    const lastSeenAppModal = new Date(
        getItem(state, SHOW_DOWNLOAD_APP_MODAL_AFTER) || LAST_SEEN_APP_MODAL_DEFAULT
    );

    const sitterProfile = getSitterProfile(state);

    const isAnon = !getIsLoggedIn(state);
    return {
        listing,
        membershipStatus: getMembershipStatus(getAccount(state).membershipPlan),
        membershipType,
        sitterProfile,
        isLoggedIn: getIsLoggedIn(state),
        seoContent: getSeoContent(state),
        cameFromEmail: getCameFromEmail(state),
        router: getRouter(state),
        showLowApplicantsFeatures,
        isFavourited,
        shouldUseOwnerAndPartialsQuery:
            isOwnerOnly(currentMembershipPlan) ||
            isPartial(currentMembershipPlan) ||
            isExpired(currentMembershipPlan) ||
            isAnon,
        showDownloadAppModal: isAfter(today, lastSeenAppModal),
    };
};

const mapDispatchToProps = (dispatch) => ({
    markDownloadAppModalAsSeen: () => {
        dispatch(
            setItem.create(
                SHOW_DOWNLOAD_APP_MODAL_AFTER,
                addDays(new Date(), DEFAULT_APP_MODAL_RELIEF_DURATION).toISOString()
            )
        );
    },
});

const LoadableListingDetail = loadable(() => retry(() => import('./ListingDetail')));

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableListingDetail), 'ListingDetail', true, true, {
        forceLoad: true,
    })
);
