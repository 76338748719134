import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, oneOf, string } from 'prop-types';
import ButtonIcon from 'components/buttons/ButtonIcon';
import { IconChevronDown, IconChevronUp } from 'components/Icon';
import Menu from './DropdownMenu';
import { Wrapper } from './Dropdown.style';

class Dropdown extends Component {
    static propTypes = {
        /**
         * text to be used in the button that instigates the dropdown menu
         */
        buttonText: string.isRequired,
        /**
         * an id which for the dropdown control which is passed to the dropdown menu's aria labelledby
         * for screen readers
         */
        idForAriaLabelledBy: string.isRequired,
        /**
         * whether the list accepts a multiple selections
         */
        isMultiSelect: bool,
        /**
         * the array of items to display in the dropdown menu
         */
        items: array.isRequired,
        /**
         * A function which takes each option and returns the option value (string to display),
         * and key.
         * e.g.
         * const option = {title: Dogs, id: 10}
         * const itemMapToValueAndKey = (option) => ({value: option.title, key: option.id})
         */
        itemMapToValueAndKey: func.isRequired,
        /**
         * a callback that is called when a selecion is made and the dropdown has closed
         */
        onSelection: func,
        /**
         * array of pre selected item indexes that refer to their index within props.items
         */
        preSelectedIndexes: arrayOf(number),
        /**
         * the type of control presented to the user
         */
        type: oneOf(['checkbox', 'simple', 'switch', 'radio']).isRequired,
        /**
         * whether the dropdown should show selections in the button text
         */
        showSelectionInButtonText: bool,
    };

    static defaultProps = {
        isMultiSelect: false,
        items: [],
        onSelection: (items) => {
            //
        },
        preSelectedIndexes: [],
        type: 'simple',
        showSelectionInButtonText: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            // if they user is scrolling with arrow keys or mouse
            scrollType: null, // oneOf 'keys', 'mouse' or null
            selectedItemsIndexes: [...this.props.preSelectedIndexes],
            highlightedIndex: -1,
            selectedItemsValues: this.getSelectedItemsValues([...this.props.preSelectedIndexes]),
        };
    }

    componentDidMount() {
        // listen for clicks on the parent element or any outside of the component to exit dropdown
        const onMouseUp = (event) => {
            if (
                (event.target === this.rootNode || !this.rootNode.contains(event.target)) &&
                this.state.isOpen
            ) {
                this.toggleMenu();
            }
        };

        this.cleanup = () => {
            window.removeEventListener('mouseup', onMouseUp);
        };

        window.addEventListener('mouseup', onMouseUp);
    }

    componentWillUnmount() {
        this.cleanup();
    }

    onSelectHandler = (event) => {
        const selectedItemIndex = parseInt(this.getLiNode(event.target).dataset.index, 10);

        this.selectItem(selectedItemIndex);
    };

    onItemsMouseOverHandler = (event) => {
        if (event.target.nodeName.toLowerCase() === 'ul') return;

        const itemIndex = this.getLiNode(event.target).dataset.index;

        this.setState({
            scrollType: 'mouse',
            highlightedIndex: parseInt(itemIndex, 10),
        });
    };

    getSelectedItems = () =>
        this.state.selectedItemsIndexes.map((itemIndex) => this.props.items[itemIndex]) || [];

    getSelectedItemsValues = (selectedItemsIndexes) =>
        selectedItemsIndexes.map((itemIndex) => {
            const { value } = this.props.itemMapToValueAndKey(this.props.items[itemIndex]);
            return value;
        });

    get highlightedItemsId() {
        return `${this.props.idForAriaLabelledBy}-${this.state.highlightedIndex}`;
    }

    // menu id is used for aria-control to signify which menu is controlled by the menu button.
    get menuId() {
        return `${this.props.idForAriaLabelledBy}-menu`;
    }

    // gets index of item in state's selectedItemsIndexes
    getIndexOfSelectedItem(itemIndex) {
        return this.state.selectedItemsIndexes.indexOf(itemIndex);
    }

    // get the list item node
    getLiNode = (node) => {
        let li = node;

        if (!this.isNodeLi(node)) {
            li = this.getLiNode(node.parentElement);
        }

        return li;
    };

    // check if node is a list item
    isNodeLi = (node) => node.nodeName.toLowerCase() === 'li';

    selectItem(itemIndex) {
        const selectedItemIndex = this.getIndexOfSelectedItem(itemIndex);
        // check if deselect
        if (selectedItemIndex > -1) {
            this.setState(({ selectedItemsIndexes }) => {
                selectedItemsIndexes.splice(selectedItemIndex, 1);

                return {
                    selectedItemsIndexes,
                };
            });
        } else if (this.props.isMultiSelect) {
            this.setState(({ selectedItemsIndexes }) => {
                selectedItemsIndexes.push(itemIndex);

                return {
                    selectedItemsIndexes,
                };
            });
        } else {
            this.setState(({ selectedItemsIndexes }) => {
                selectedItemsIndexes[0] = itemIndex;

                return {
                    selectedItemsIndexes,
                };
            });

            this.toggleMenu();
        }

        this.setState(() => ({
            selectedItemsValues: this.getSelectedItemsValues(this.state.selectedItemsIndexes),
        }));
    }

    // Down Arrow, Space, Enter
    // Opens menu and moves focus to first menuitem
    // Up Arrow opens menu and moves focus to last menuitem
    buttonOnkeydownHandler = (event) => {
        // event.preventDefault();

        switch (event.key) {
            case 'ArrowDown':
            case 'Enter':
            case 'Space':
                this.setState({ highlightedIndex: 0 });
                this.setState({ isOpen: true });
                this.MenuElement.focus();
                break;

            case 'ArrowUp':
                this.setState({ highlightedIndex: this.props.items.length - 1 });
                this.setState({ isOpen: true });
                this.MenuElement.focus();
                break;

            default:
                break;
        }
    };

    toggleMenu = () =>
        this.setState(
            ({ isOpen }) => ({ isOpen: !isOpen }),
            () => {
                if (!this.state.isOpen) {
                    this.ButtonElement.focus();
                    this.props.onSelection(this.getSelectedItems());
                } else {
                    this.MenuElement.focus();
                }
            }
        );

    moveHighlightedSuggestion(direction) {
        const maxIndex = this.props.items.length - 1;
        const { highlightedIndex } = this.state;

        switch (direction) {
            case 'up':
                if (highlightedIndex === -1 || highlightedIndex === 0) {
                    this.setState({ highlightedIndex: maxIndex });
                    // this.setInputValue(this.state.suggestions[maxIndex].name);
                } else {
                    this.setState({ highlightedIndex: highlightedIndex - 1 });
                    // this.setInputValue(this.state.suggestions[highlightedIndex - 1].name);
                }
                break;

            case 'down':
                if (highlightedIndex === maxIndex) {
                    this.setState({ highlightedIndex: 0 });
                    // this.setInputValue(this.state.suggestions[0].name);
                } else {
                    this.setState({ highlightedIndex: highlightedIndex + 1 });
                    // this.setInputValue(this.state.suggestions[highlightedIndex + 1].name);
                }
                break;
            default:
                break;
        }
    }

    /**
     * @description
     * Simply forwards the event to it's handler
     *
     * @param  {object} event keydown event
     */
    menuOnkeydownHandler = (event) => {
        const keyDownHandler = this.menukeyDownHandlers[event.key];

        if (keyDownHandler) {
            keyDownHandler.call(this, event);
        }

        // We can only test for regex /w characters. Which means characters outside of the ASCII character set
        // are not supported until unicode regex patterns become available. Which is in a proposal, see
        // https://github.com/tc39/proposal-regexp-unicode-property-escapes
        else if (/\w/.test(event.key)) {
            this.characterKeyPressHandler(event.key);
        }
    };

    // start from the previous position if the letter is the same.
    /**
     * @description
     * Moves highlighted index to the next menu item with a value that starts with the
     * typed character if such an menu item exists. Otherwise, focus does not move.
     *
     * @param {string} key the keyboard event key
     */
    characterKeyPressHandler(key) {
        // an array of prop items first character so that we can perform indexOf
        // which is useful because we can specify a start position (getStartingIndex)
        // e.g. itemsMapped = ['a', 'd', 'f', 'a']
        const itemsMapped = this.props.items
            .map(this.props.itemMapToValueAndKey)
            .map((item) => item.value.charAt(0).toLowerCase());

        const previousIndex = this.state.highlightedIndex;

        // the previous highlighted items first character
        const previousKey = (itemsMapped[previousIndex] || '').charAt(0).toLowerCase();

        function getStartingIndex() {
            if (previousKey === key) return previousIndex + 1;
            return 0;
        }

        const indexOfItem = itemsMapped.indexOf(key.toLowerCase(), getStartingIndex());

        if (indexOfItem < 0) return;

        this.setState({
            scrollType: 'keys',
            isOpen: true,
            highlightedIndex: indexOfItem,
        });
    }

    menukeyDownHandlers = {
        ArrowDown(event) {
            event.preventDefault();

            this.setState({
                scrollType: 'keys',
                isOpen: true,
            });

            this.moveHighlightedSuggestion('down');
        },

        ArrowUp(event) {
            event.preventDefault();

            this.setState({
                scrollType: 'keys',
                isOpen: true,
            });

            this.moveHighlightedSuggestion('up');
        },

        Enter(event) {
            event.preventDefault();
            const { highlightedIndex } = this.state;

            if (highlightedIndex > -1) {
                this.selectItem(highlightedIndex);
            }
        },

        Escape(event) {
            event.preventDefault();
            this.toggleMenu();
        },

        End(event) {
            event.preventDefault();
            this.setState({ highlightedIndex: this.props.items.length - 1 });
        },

        Home(event) {
            event.preventDefault();
            this.setState({ highlightedIndex: 0 });
        },

        Tab() {
            this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
            this.props.onSelection(this.getSelectedItems());
        },

        // space character
        ' ': (event) => {
            event.preventDefault();
            const { highlightedIndex } = this.state;

            if (highlightedIndex > -1) {
                this.selectItem(highlightedIndex);
            }
        },
    };

    render() {
        return (
            <Wrapper
                ref={(rootNode) => {
                    this.rootNode = rootNode;
                }}
            >
                <ButtonIcon
                    tabIndex="0"
                    aria-controls={this.menuId}
                    aria-expanded={this.state.isOpen}
                    buttonRef={(el) => {
                        this.ButtonElement = el;
                    }}
                    dropdown
                    icon={this.state.isOpen ? IconChevronUp : IconChevronDown}
                    id={this.props.idForAriaLabelledBy}
                    onClick={() => {
                        this.toggleMenu();
                    }}
                    onKeyDown={this.buttonOnkeydownHandler}
                    right
                >
                    {this.props.showSelectionInButtonText && this.state.selectedItemsIndexes.length
                        ? this.state.selectedItemsValues.join(', ')
                        : this.props.buttonText}
                </ButtonIcon>

                <Menu
                    ariaActivedescendant={this.highlightedItemsId}
                    ariaLabelledBy={this.props.idForAriaLabelledBy}
                    highlightedIndex={this.state.highlightedIndex}
                    id={this.menuId}
                    isMultiSelect={this.props.isMultiSelect}
                    isOpen={this.state.isOpen}
                    items={this.props.items}
                    itemMapToValueAndKey={this.props.itemMapToValueAndKey}
                    menuRef={(el) => {
                        this.MenuElement = el;
                    }}
                    onItemsMouseOverHandler={this.onItemsMouseOverHandler}
                    onKeyDown={this.menuOnkeydownHandler}
                    onSelectHandler={this.onSelectHandler}
                    selectedItemsIndexes={this.state.selectedItemsIndexes}
                    type={this.props.type}
                />
            </Wrapper>
        );
    }
}

export default Dropdown;
