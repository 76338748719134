import React from 'react';
import PropTypes from 'prop-types';
import LinkVariant from 'components/Link/Link.constants';
import { ButtonVariant } from 'components/buttons/Button/Button.constants';
import { AnchorStyled } from './Anchor.style';

const Anchor = ({ className, href, variant, children, target, rel, ...props }) => {
    // If target='_blank' we want to add the rel attribute with 'noopener noreferrer'.
    // This is good for security and SEO
    let safeRel = rel;
    if (target === '_blank') {
        safeRel = (rel && `${rel} noopener noreferrer`) || 'noopener noreferrer';
    }

    return (
        <AnchorStyled
            href={href}
            variant={variant}
            className={className}
            target={target}
            rel={safeRel}
            {...props}
        >
            {children}
        </AnchorStyled>
    );
};

Anchor.propTypes = {
    /**
     * classnames passed down by styled components
     */
    className: PropTypes.string,

    href: PropTypes.string.isRequired,

    /**
     * All variants that are accepted by Button
     */
    variant: PropTypes.string.isRequired,

    rel: PropTypes.string,
    target: PropTypes.string,
    children: PropTypes.node,
};

Anchor.Variant = {
    ...LinkVariant,
    Button: {
        ...ButtonVariant,
    },
};

Anchor.defaultProps = {
    variant: LinkVariant.DEFAULT,
};

export default Anchor;
