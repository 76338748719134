import styled from 'styled-components';
import Graph from 'components/Graph';
import RangeSlider from 'components/RangeSlider';

const WrapperStyled = styled.div`
    position: relative;
    padding: 0 2px ${({ theme }) => theme.spacing[3]} 0;
`;

const GraphStyled = styled(Graph)`
    padding: 0 ${({ theme }) => theme.spacing[3]};
`;

const RangeSliderStyled = styled(RangeSlider)`
    position: absolute;
    bottom: 0;
    left: 0;
`;

const TitleWrapperStyled = styled.div`
    padding: 0 0 ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[3]};
`;

const NumberStyled = styled.span`
    padding-right: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.palette.grey[600]};
`;

const WordStyled = styled.span`
    padding-right: ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.palette.grey[500]};
`;

const LastNumberStyled = styled(WordStyled)`
    padding-right: 0;
    color: ${({ theme }) => theme.palette.grey[600]};
`;

const LastWordStyled = styled(WordStyled)`
    padding-right: 0;
`;

const DashStyled = styled.span`
    padding-right: ${({ theme }) => theme.spacing[2.5]};
    color: ${({ theme }) => theme.palette.grey[500]};
`;

export {
    WrapperStyled,
    GraphStyled,
    RangeSliderStyled,
    TitleWrapperStyled,
    NumberStyled,
    WordStyled,
    DashStyled,
    LastNumberStyled,
    LastWordStyled,
};
