import * as constants from 'containers/LeaveReviewAndFeedbackModal/LeaveReviewModal/constants';

// eslint-disable-next-line import/prefer-default-export
export const LEAVE_REVIEW_MODAL = {
    create(screen) {
        switch (screen) {
            case constants.SCREEN_HOW_WAS_YOUR_SITTER:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal rate Sitter',
                    },
                };
            case constants.SCREEN_HOW_ORGANIZED_WAS_YOUR_SITTER:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal rate Organisation skills',
                    },
                };
            case constants.SCREEN_HOW_RELIABLE_WAS_SITTER:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal rate Reliability',
                    },
                };
            case constants.SCREEN_HOW_SELF_SUFFICIENT_WAS_SITTER:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal rate Self-Sufficiency',
                    },
                };
            case constants.SCREEN_HOW_TIDY_WAS_SITTER:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal rate Tidiness',
                    },
                };
            case constants.SCREEN_HOW_WAS_SITTERS_PET_CARE:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal rate Pet Care',
                    },
                };
            case constants.SCREEN_WRITE_REVIEW:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal write review',
                    },
                };
            case constants.SCREEN_THANKS:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal Thanks',
                    },
                };
            case constants.SCREEN_EXIT_WARNING:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Review Modal Exiting flow',
                    },
                };
            default:
                return {};
        }
    },
};
