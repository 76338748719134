import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getAccount } from 'api/selectors/account';
import { getMembershipType } from 'utils/account';
import UpgradeMembership from './UpgradeMembership';
import { setItem } from '../../universalStorage/actions';
import { UNLOCK_COPY, UPGRADE_MEMBERSHIP_DISMISSED_KEY } from './UpgradeMembership.constants';

const mapStateToProps = (state) => {
    const { membershipPlan } = getAccount(state);
    return {
        membershipType: getMembershipType(membershipPlan),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onUpgradeBannerDismissed: () => {
        dispatch(setItem.create(UPGRADE_MEMBERSHIP_DISMISSED_KEY, true));
    },
});

export { UNLOCK_COPY, UPGRADE_MEMBERSHIP_DISMISSED_KEY };
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpgradeMembership));
