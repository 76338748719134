import { getState } from './common';
import props from '../props';
import { transformListingAmenities } from '../helpers/transform/owner/amenities';
import { applicationsByType } from '../reducers/owner/assignments';
import {
    TRANSFERRED_AMENITIES_TO_IGNORE,
    LIST as RAW_AMENITIES_LIST,
} from '../constants/listing/amenities';
import { isReviewWindowOpen } from '../helpers/assignments';

export const getOwnerSitterMessage = (state, sitterId) =>
    getState(state).owner.sitter.messages[sitterId] || null;

export const getOwnerAssignment = (state, id) => {
    const assignment = getState(state).owner.assignments.data[id];
    if (!assignment) return {};
    if (!assignment.sitterId) return assignment;

    const lastMessage = getOwnerSitterMessage(state, assignment.sitterId);

    return {
        ...assignment,
        lastMessage,
    };
};

export const getOwnerApplication = (state, id) => {
    const application = getState(state).owner.applications.data[id];
    if (!application) return {};

    const lastMessage = getOwnerSitterMessage(state, application.sitterId);

    return {
        ...application,
        lastMessage,
    };
};

//
// Helpers
//
export function assignmentsDateSortHelper(assignments, sortKey = 'startDate') {
    const sortKeys = sortKey.split('.');
    return assignments.sort((assignmentA, assignmentB) => {
        let dateA = assignmentA;
        for (let a = 0; a < sortKeys.length; a += 1) {
            if (!dateA[sortKeys[a]]) return -1;
            dateA = dateA[sortKeys[a]];
        }
        let dateB = assignmentB;
        for (let b = 0; b < sortKeys.length; b += 1) {
            if (!dateB[sortKeys[b]]) return -1;
            dateB = dateB[sortKeys[b]];
        }
        return new Date(dateB) - new Date(dateA);
    });
}

function assignmentsSelectorHelper(state, assignmentsKey, sortDates = true) {
    const assignments = [];
    getState(state).owner[assignmentsKey].results.forEach((assignmentId) => {
        const assignment = getOwnerAssignment(state, assignmentId);
        if (assignment) {
            assignments.push(getOwnerAssignment(state, assignmentId));
        }
    });

    if (sortDates) return assignmentsDateSortHelper(assignments);

    return assignments;
}

//
// Data selectors
//
export const getOwner = (state) => getState(state).owner;
export const getOwnerFavourites = (state) => {
    const emptyList = [];

    if (!getState(state).owner.favourites.results) return emptyList;

    return getState(state).owner.favourites.results.map((id) => ({
        id,
        ...getState(state).search.profile[id],
    }));
};
export const getOwnerConfirmedAssignments = (state) => getState(state).owner.confirmedAssignments;
export const getOwnerOpenAssignments = (state) =>
    assignmentsSelectorHelper(state, 'openAssignments').reverse();
export const getOwnerOpenAssignmentsByIds = (state, ids) => {
    if (!ids) return [];

    const assignments = assignmentsSelectorHelper(state, 'openAssignments');
    return assignments.filter((assignment) => ids.indexOf(assignment.id) >= 0);
};
export const getOwnerDeletedAssignments = (state) =>
    assignmentsSelectorHelper(state, 'deletedAssignments');
export const getOwnerDeletedAssignmentsByIds = (state, ids) => {
    if (!ids) return [];

    const assignments = assignmentsSelectorHelper(state, 'deletedAssignments');
    return assignments.filter((assignment) => ids.indexOf(assignment.id) >= 0);
};

export const getOwnerUnconfirmedPastAssignments = (state) =>
    assignmentsSelectorHelper(state, 'unconfirmedPastAssignments');
export const getOwnerUnconfirmedPastAssignmentsByIds = (state, ids) => {
    if (!ids) return [];

    const assignments = assignmentsSelectorHelper(state, 'unconfirmedPastAssignments');
    return assignments.filter((assignment) => ids.indexOf(assignment.id) >= 0);
};

export const getOwnerOpenAssignmentGroupApplications = (state, assignmentId) => {
    const assignment = getOwnerAssignment(state, assignmentId);
    if (!assignment) {
        return {
            ...applicationsByType,
        };
    }
    return assignment.applications;
};

export const getOwnerOpenAssignmentSortedApplications = (
    state,
    assignmentId,
    includes = ['confirmed', 'shortlisted', 'invitations', 'applications', 'declined']
) => {
    const applications = [];
    const assignment = getOwnerAssignment(state, assignmentId);
    if (!assignment) return applications;
    if (!assignment.applications) return applications;

    if (includes.indexOf('confirmed') >= 0 && assignment.applications.confirmed) {
        applications.push(getOwnerApplication(state, assignment.applications.confirmed));
    }
    if (includes.indexOf('shortlisted') >= 0 && assignment.applications.shortlisted) {
        assignment.applications.shortlisted.forEach((applicationId) => {
            applications.push(getOwnerApplication(state, applicationId));
        });
    }
    if (includes.indexOf('invitations') >= 0 && assignment.applications.invitations) {
        assignment.applications.invitations.forEach((applicationId) => {
            applications.push(getOwnerApplication(state, applicationId));
        });
    }
    if (includes.indexOf('applications') >= 0 && assignment.applications.applications) {
        assignment.applications.applications.forEach((applicationId) => {
            applications.push(getOwnerApplication(state, applicationId));
        });
    }
    if (includes.indexOf('declined') >= 0 && assignment.applications.declined) {
        assignment.applications.declined.forEach((applicationId) => {
            applications.push(getOwnerApplication(state, applicationId));
        });
    }

    return applications;
};

export const getOwnerAssignmentApplications = (state, assignmentId) => {
    const assignment = getOwnerAssignment(state, assignmentId);
    if (!assignment) return [];
    const { applications } = assignment;
    if (!applications) return [];
    const { allApplications } = applications;
    if (!allApplications) return [];
    return allApplications.map((id) => getOwnerApplication(state, id));
};

/**
 * Return assignment applications sorted by last message sent date
 * while confirmed assignments stays pinned to the top.
 * @param state
 * @param assignmentId
 * @returns {*}
 */
export const getOwnerOpenAssignmentApplicationsConfirmedAndSortedByLastMessage = (
    state,
    assignmentId
) => {
    const assignment = getOwnerAssignment(state, assignmentId);
    if (!assignment) return [];
    if (!assignment.applications) return [];

    const unsortedApplications = assignment.applications.allApplications
        .filter((applicationId) => applicationId !== assignment.applications.confirmed)
        .map((applicationId) => getOwnerApplication(state, applicationId));
    // const unsortedApplications = getOwnerOpenAssignmentSortedApplications(
    //     state, assignmentId,
    //     ['shortlisted', 'invitations', 'applications', 'declined'],
    // );
    const applications = assignmentsDateSortHelper(unsortedApplications, 'lastMessage.sendDate');
    if (assignment.applications.confirmed) {
        return [getOwnerApplication(state, assignment.applications.confirmed), ...applications];
    }
    return applications;
};

export const getOwnerOpenAssignmentApplicationsConfirmedActive = (state, assignmentId) => {
    const assignment = getOwnerAssignment(state, assignmentId);

    if (!assignment) return [];
    if (!assignment.applications) return [];

    const unsortedApplications = assignment.applications.allApplications
        .filter((applicationId) => applicationId !== assignment.applications.confirmed)
        .map((applicationId) => getOwnerApplication(state, applicationId));

    const unsortedFilteredApplications = unsortedApplications.filter((application) => {
        const { lastMessage } = application;
        if (!lastMessage) return true;
        return !lastMessage.isArchived;
    });

    if (assignment.applications.confirmed) {
        return [
            getOwnerApplication(state, assignment.applications.confirmed),
            ...unsortedFilteredApplications,
        ];
    }
    return unsortedFilteredApplications;
};

export const getOwnerOpenAssignmentApplicationsConfirmedActiveAndSortedByLastMessage = (
    state,
    assignmentId
) => {
    const assignment = getOwnerAssignment(state, assignmentId);
    if (!assignment) return [];
    if (!assignment.applications) return [];

    const unsortedApplications = assignment.applications.allApplications
        .filter((applicationId) => applicationId !== assignment.applications.confirmed)
        .map((applicationId) => getOwnerApplication(state, applicationId));
    // const unsortedApplications = getOwnerOpenAssignmentSortedApplications(
    //     state, assignmentId,
    //     ['shortlisted', 'invitations', 'applications', 'declined'],
    // );
    const unsortedFilteredApplications = unsortedApplications.filter((application) => {
        const { lastMessage } = application;
        if (!lastMessage) return true;
        return !lastMessage.isArchived;
    });

    const applications = assignmentsDateSortHelper(
        unsortedFilteredApplications,
        'lastMessage.sendDate'
    );
    if (assignment.applications.confirmed) {
        return [getOwnerApplication(state, assignment.applications.confirmed), ...applications];
    }
    return applications;
};

export const getOwnerOpenAssignmentSortedActiveApplications = (state, assignmentId) =>
    getOwnerOpenAssignmentSortedApplications(state, assignmentId, [
        'confirmed',
        'shortlisted',
        'invitations',
        'applications',
    ]);

export const getOwnerOpenAssignmentApplications = (state, assignmentId) => {
    const assignment = getOwnerAssignment(state, assignmentId);
    if (!assignment) return [];
    if (!assignment.applications) return [];

    return getOwnerOpenAssignmentSortedApplications(state, assignmentId, [
        'shortlisted',
        'invitations',
        'applications',
        'declined',
    ]);
};

export const getOwnerOpenAssignmentUnsortedApplications = (state, assignmentId) => {
    const assignment = getOwnerAssignment(state, assignmentId);
    if (!assignment) return [];
    if (!assignment.applications) return [];

    return assignment.applications;
};

export const getOwnerOpenAssignmentUnsortedInvitations = (state, assignmentId) => {
    const assignment = getOwnerAssignment(state, assignmentId);
    if (!assignment) return [];
    if (!assignment.invitations) return [];

    return assignment.invitations;
};

export const getOwnerCurrentAssignments = (state) =>
    assignmentsSelectorHelper(state, 'currentAssignments');
export const getOwnerUpcomingAssignments = (state) =>
    assignmentsSelectorHelper(state, 'upcomingAssignments').reverse();
export const getOwnerPastAssignments = (state) =>
    assignmentsSelectorHelper(state, 'pastAssignments');
export const getOwnerPastAssignmentFeedback = (state, id) =>
    getState(state).owner.feedbacks.data[id];
export const getOwnerPastAssignmentReview = (state, id) => getState(state).owner.reviews.data[id];

export const getOwnerPastAssignmentsForReviewWithReviewWindowOpen = (state) => {
    const pastAssignments = getOwnerPastAssignments(state);
    return pastAssignments.filter(
        (assignment) =>
            isReviewWindowOpen(assignment.reviewWindowEndDate) && !assignment.isReviewProvided
    );
};

export const getOwnerProfileReviews = (state) => {
    const pastAssignments = getOwnerPastAssignments(state);
    return pastAssignments.filter(
        (assignment) => assignment.review || assignment.reviewRequestedDate
    );
};

export const getOwnerListing = (state, listingId) => getState(state).owner.listings.data[listingId];
export const getOwnerListings = (state) => {
    const listings = [];
    Object.keys(getState(state).owner.listings.data).forEach((listingId) => {
        listings.push(getState(state).owner.listings.data[listingId]);
    });
    return listings;
};
export const getOwnerLastUpdatedListings = (state) => {
    const listings = getOwnerListings(state);
    let lastUpdatedListing;
    let lastUpdate;
    listings.forEach((listing) => {
        if (!lastUpdate) {
            lastUpdatedListing = listing;
            lastUpdate = listing.publishedDate;
        } else if (lastUpdate < listing.publishedDate) {
            lastUpdatedListing = listing;
            lastUpdate = listing.publishedDate;
        }
    });
    return lastUpdatedListing;
};
export const getOwnerListingMedias = (state) => getState(state).owner.medias;
export const getOwnerListingOpenAssignments = (state, listingId) => {
    const listing = getOwnerListing(state, listingId);
    if (!listing) return [];
    if (!listing.openAssignments) return [];

    return listing.openAssignments
        .filter((assignmentId) => assignmentId in getState(state).owner.assignments.data)
        .map((assignmentId) => getState(state).owner.assignments.data[assignmentId])
        .sort(
            (assignment1, assignment2) =>
                new Date(assignment2.startDate) - new Date(assignment1.startDate)
        );
};
export const getOwnerListingOpenAssignmentsAscending = (state, listingId) =>
    getOwnerListingOpenAssignments(state, listingId).reverse();
export const getOwnerListingGuide = (state, listingId) =>
    getState(state).owner.guides[getState(state).owner.listings[listingId].guide];
export const getOwnerListingGuideMedias = (state) => getState(state).owner.guideMedias;
export const getOwnerPastAssignmentFeedbackRequest = (state) =>
    getState(state).owner.listingPastAssignmentFeedbackRequest;
export const getOwnerListingReminders = (state, listingId) =>
    getState(state).owner.listings[listingId].reminders.map(
        (reminderId) => getState(state).owner.reminders[reminderId]
    );

// sending param 'false' so assignmentsSelectorHelper doesnt reorder the dates array
export const getOwnerListingFutureAssignments = (state) =>
    assignmentsSelectorHelper(state, 'futureAssignments', false);

export const getOwnerFeedbacks = (state) => getState(state).owner.feedbacks;
export const getOwnerReviews = (state) => getState(state).owner.reviews;
export const getOwnerConversations = (state) => getState(state).owner.conversations;
export const getOwnerConversation = (state, sitterId) => {
    const conversation = getState(state).owner.conversations[sitterId];
    if (conversation) {
        return conversation.results || [];
    }
    return [];
};
export const getOwnerConversationTotal = (state, sitterId) => {
    const conversation = getState(state).owner.conversations[sitterId];
    if (conversation) {
        return conversation.count;
    }
    return 0;
};

// Historically an owner / combined could have multiple listings, we did some work to
// phase that out as part of https://trustedhousesitters.atlassian.net/browse/CONNECT-1049
// However there are still some that remain. Membership services are contacting those to archive
// their listings so that they keep only one.
// So this selector can be used to return a specific listing or the first that is in the array.
export const getListing = (state, id) => {
    const listings = getState(state).owner.listings.data;
    if (id) {
        return listings[id];
    }
    return listings[Object.keys(listings)[0]];
};

export const getOwnerListingPlacesOfInterest = (state, listingId) => {
    const listing = getListing(state, listingId);
    if (!listing) return [];

    return listing.placesOfInterest;
};

export const getOwnerAddress = (state) => {
    const address = getState(state).owner && getState(state).owner.address;
    if (!address) return null;

    return address;
};

// export const getListingPetsByType = (state, id) => {
//     const petsByType = {};
//
//     const listing = getListing(state, id);
//     if (!listing) return petsByType;
//
//     for (let a = 0; a < listing.pets.length; a += 1) {
//         const pet = getState(state).owner.pets.data[listing.pets[a]];
//         const animalType = pet.animal.type;
//         if (!petsByType[animalType]) {
//             petsByType[animalType] = {
//                 animal: pet.animal,
//                 pets: [],
//             };
//         }
//         petsByType[animalType].pets.push(pet);
//     }
//
//     return petsByType;
// };
export const getOwnerListingPets = (state, listingId) => {
    const listing = getListing(state, listingId);
    if (!listing) return [];

    return listing.pets || [];
};
export const getOwnerListingPetsDetails = (state, listingId) => {
    const pets = getState(state).owner.pets[listingId];
    if (!pets) return [];

    return pets || [];
};

export const getOwnerWelcomeGuide = (state, listingId) => {
    const listing = getOwnerListing(state, listingId);
    if (!listing) {
        return null;
    }

    return listing.welcomeGuide;
};

export const getOwnerSitterProfile = (state, id) => getState(state).owner.profiles[id] || {};

export const getOwnerPastAssignmentsForSitterProfile = (state, id) => {
    const profile = getOwnerSitterProfile(state, id);
    if (!profile) return [];

    return profile.pastAssignments || [];
};

export const getOwnerSitterApplications = (state, id) => {
    if (getState(state).owner.profiles[id]) {
        return (getState(state).owner.profiles[id].applications || [])
            .map((applicationId) => getOwnerApplication(state, applicationId) || null)
            .filter((application) => application !== null);
    }
    return [];
};

export const getOwnerSitterInvitations = (state, id) => {
    if (getState(state).owner.profiles[id]) {
        return (getState(state).owner.profiles[id].invitations || [])
            .map((invitationId) => getOwnerApplication(state, invitationId) || null)
            .filter((invitation) => invitation !== null);
    }
    return [];
};

export const getOwnerSitterApplicationsInvitations = (state, id) => {
    const applications = getOwnerSitterApplications(state, id);
    const invitations = getOwnerSitterInvitations(state, id);
    return [...applications, ...invitations];
};

export const getOwnerSitterApplicationsIds = (state, id) => {
    if (getState(state).owner.profiles[id]) {
        return getState(state).owner.profiles[id].applications || [];
    }
    return [];
};

export const getOwnerSitterApplication = (state, profileId, id) => {
    const applications = getOwnerSitterApplications(state, profileId);
    for (let a = 0; a < applications.length; a += 1) {
        if (applications[a].id === id) {
            return applications[a];
        }
    }
    return null;
};

// Logical operations
export const isOwnerSitterProfileFavourite = (state, id) =>
    getState(state).owner.favourites.results.indexOf(id) >= 0;
// const profile = getState(state).owner.profiles.data[id];
// if (!profile) return null;
// return !!(profile && profile.isFavourite);

export const getOwnerOpenAssignment = (state, id) => {
    const assignment = getState(state).owner.assignments.data[id];

    if (!assignment) return null;

    const openAssignment = {};
    Object.keys(props.owner.openAssignmentProps).forEach((propName) => {
        openAssignment[propName] = assignment[propName];
    });

    return openAssignment;
};

export const getOwnerPastAssignmentsCount = (state) =>
    getState(state).owner.pastAssignments.results.length;

export const getOwnerListingHasAmenities = (state, id) =>
    !!getState(state)?.owner?.amenities?.[id]?.data ?? false;

export const getOwnerListingAmenitiesStatus = (state, id) =>
    getState(state)?.owner?.amenities?.[id]?.status ?? null;

export const isOwnerListingAmenitiesStatusCompleted = (state, id) =>
    getOwnerListingAmenitiesStatus(state, id) === 'isCompleted';

export const doesOwnerListingHaveAnyNonTransferredCompletedAmenities = (state, id) => {
    const amenityData = getState(state)?.owner?.amenities?.[id]?.data;

    if (!amenityData) {
        return false;
    }

    const shouldShowAmenitiesPrompt = RAW_AMENITIES_LIST.some((amenityKey) => {
        const amenity = amenityData[amenityKey];

        // Ignore null values and transferred values marked to be ignored
        if (amenity === null || TRANSFERRED_AMENITIES_TO_IGNORE.includes(amenityKey)) {
            return false;
        }

        // Ignore empty arrays and strings
        if ((Array.isArray(amenity) || typeof amenity === 'string') && amenity.length === 0) {
            return false;
        }

        return true;
    });

    return shouldShowAmenitiesPrompt;
};

export const getOwnerListingAmenitiesRaw = (state, id) => {
    const amenities = getState(state)?.owner?.amenities?.[id]?.data;

    if (!amenities) {
        return null;
    }

    return amenities;
};

export const getOwnerListingAmenities = (state, id) => {
    const amenities = getState(state)?.owner?.amenities?.[id]?.data;

    if (!amenities) {
        return null;
    }

    return transformListingAmenities(amenities);
};
