import { getParams } from 'containers/Page/selectors';
import {
    getBlogCategory,
    getBlogCategorySubcategory,
    getBlogCategorySubcategoryPosts,
} from 'api/selectors/content';
import { PAGE_ID } from './constants';

export const getCategory = (state) => {
    const params = getParams(state, PAGE_ID);
    return getBlogCategory(state, params.area, params.category);
};

export const getSubcategory = (state) => {
    const { area, category, subcategory } = getParams(state, PAGE_ID);

    return getBlogCategorySubcategory(state, area, category, subcategory);
};

export const getSubcategoryPosts = (state) => {
    const { area, category, subcategory } = getParams(state, PAGE_ID);

    return getBlogCategorySubcategoryPosts(state, area, category, subcategory);
};
