function getLabelVisibility(rating, hoveredRating, selectedRating) {
    const hasNoHoveredStar = hoveredRating === 0;
    const isHovered = hoveredRating === rating;
    const isSelected = selectedRating === rating;

    if (isHovered || (isSelected && hasNoHoveredStar)) {
        return 'visible';
    }

    return 'hidden';
}

export { getLabelVisibility };
