import { stringify } from 'query-string';

import * as pageSelectors from 'containers/Page/selectors';
import { createProfilesSearchQuery } from 'utils/searchProfiles';
import { base64decode, base64encode } from 'utils/strings';

import { getIsMembershipActive } from 'utils/account';
import { routes } from 'utils/routes';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { CountryCodeToCountrySlugMap, getGeoHierarchyParamsAndLocationFilters } from '../helpers';
import { PAGE_ID, SEARCH_TYPE_RESULTS } from './SearchProfiles.constants';
import { getLocale } from '../../../shared/selectors';

export const buildPathName = (params = {}, isCatSearch = false, isCatGeoLocationSearch = false) => {
    // If the user is searching for cat sitters,
    // we need to change the url to cat-sitters instead of house-and-pet-sitters
    if (isCatSearch) {
        // If the user is not using the manual search page
        // i.e. they clicked on a location link
        // we set the canonical url to use /cat-sitters/{location}
        // otherwise it will be /cat-sitters/search/
        if (isCatGeoLocationSearch) {
            return routes.search.catProfiles({ ...params });
        }
        return routes.search.catProfilesSearch({ ...params });
    }
    return routes.search.profiles({ ...params });
};

export const buildSearch = (search) => {
    if (search.location) {
        const { name, location, countryName, admin1Name, admin2Name, admin3Name, admin4Name } =
            search.location;
        const locationData = {
            name,
            location,
            countryName,
            admin1Name,
            admin2Name,
            admin3Name,
            admin4Name,
        };
        // FIXME: This is not ideal, we shouldn't reassign parameters variables
        // eslint-disable-next-line no-param-reassign
        search.location = JSON.stringify(locationData);
    }

    return `?${stringify(search, { arrayFormat: 'index' })}`;
};

export const getSearch = (state) => pageSelectors.getSearch(state, PAGE_ID);
export const getParams = (state) => pageSelectors.getParams(state, PAGE_ID);
export const getPlace = (state) => state.pages.search.searchProfiles.place;
export const getScrollToResults = (state) => state.pages.search.searchProfiles.scrollToResults;
export const getHasRegistered = (state) => state.pages.search.searchProfiles.hasRegistered;
export const isLoading = (state) =>
    state.pages.search.searchProfiles.query['search-profiles-results'] &&
    state.pages.search.searchProfiles.query['search-profiles-results'].isLoading;

/*
 * Excludes map bounds data
 */
export const getFilters = (state) => {
    const { q } = getSearch(state);

    try {
        return JSON.parse(base64decode(q));
    } catch (e) {
        return {};
    }
};

/**
 * Generate a link to the given page. Returns a url object to pass to <link />
 */
export const getPageLink = (state, page, isCatSearch = false) => {
    const params = getParams(state);
    const filters = getFilters(state);

    let query;
    if (!Object.keys(filters).length) {
        const searchQuery = createProfilesSearchQuery(page);
        query = searchQuery.getRequestData();
    } else {
        query = { ...filters, page };
    }

    return {
        pathname: buildPathName(params, isCatSearch),
        search: `?q=${base64encode(JSON.stringify(query))}`,
    };
};

export const getPathName = (state) => buildPathName(getParams(state));

// Where duplicated region and place slugs occur, remove the place slug
export const getCanonicalPathName = (state, isCatSittersSearch = false) => {
    const params = getParams(state);

    if (params.admin1Slug === params.slug) {
        const { slug, ...rest } = params;
        return buildPathName(rest, isCatSittersSearch, false);
    }
    return buildPathName(params, isCatSittersSearch, true);
};

export const getSearchProfilesQuery = (state, searchType = 'default') => ({
    ...state.pages.search.searchProfiles.query[searchType],
});
/*
 * Gets sitter looking in your area variant
 */
export const getSittersLookingInYourAreaVariant = (state) =>
    state.pages.search.searchProfiles.sittersLookingInYourAreaVariant;

export const getSearchProfilesFiltersLink = (state, searchType = 'default') => {
    const pathname = buildPathName(getParams(state));
    const query = getSearchProfilesQuery(state, searchType);
    const page = 1;

    const searchQuery = createProfilesSearchQuery(page, query);
    return `${pathname}?q=${base64encode(JSON.stringify(searchQuery))}`;
};

/**
 * Extract the page information (current page, total etc...) for the current search
 */
export const getPageInfo = (state) => {
    const { pageInfo } = getSearchProfilesQuery(state, SEARCH_TYPE_RESULTS);

    return pageInfo;
};

export const useLocalSits = (state) => {
    const query = getFilters(state);
    const params = getParams(state);

    const membershipPlan = getAccountCurrentMembershipPlan(state);
    const isPaidMember = getIsMembershipActive(membershipPlan);
    const locale = getLocale(state);
    const { locationFilters } = getGeoHierarchyParamsAndLocationFilters({ query, params });
    const hasLocationFilters = Object.keys(locationFilters).length > 0;
    const explicitWorldwideSearch = !!(query && query.filters && query.filters.worldwide);

    const localSits = !!(
        !isPaidMember &&
        !hasLocationFilters &&
        !explicitWorldwideSearch &&
        CountryCodeToCountrySlugMap[locale.countryISOCode]
    );

    return localSits;
};
