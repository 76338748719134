import React from 'react';
import BaseInput from './components/BaseInput';
import InfoText from './components/InfoText';
import { isErrored } from './helper';
import InputLabel from '../InputLabel/InputLabel';
import { FieldWrapper, FieldLabelWrapperStyled } from './Field.style';

/**
 * @deprecated Use `src/components/Form/Field` instead.
 */
const Field = ({
    label,
    type,
    name,
    inputMode,
    value,
    error,
    touched,
    isValid,
    onChange,
    onBlur,
    onFocus,
    helperText,
    successText,
    placeholder,
    disabled,
    inputRef,
    className,
    ...props
}) => {
    const input = (
        <BaseInput
            type={type}
            name={name}
            inputMode={inputMode}
            value={value}
            touched={touched}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            helperText={helperText}
            placeholder={placeholder}
            disabled={disabled}
            inputRef={inputRef}
            isErrored={isErrored(error, touched)}
            {...props}
        />
    );

    return (
        <FieldWrapper className={className}>
            {label ? (
                <InputLabel inputId={name}>
                    <FieldLabelWrapperStyled>{label}</FieldLabelWrapperStyled>
                    {input}
                </InputLabel>
            ) : (
                input
            )}
            <InfoText
                isValid={isValid}
                isErrored={isErrored(error, touched)}
                helperText={helperText}
                errorText={error}
                successText={successText}
            />
        </FieldWrapper>
    );
};

export default Field;
