import { createAction } from '../../utils/actions';

const prefix = 'PAGE';

export const preload = createAction(prefix, 'PRELOAD', 'pageId', 'params', 'search', 'pathname');
export const preloaded = createAction(prefix, 'PRELOADED', 'pageId');
export const load = createAction(prefix, 'LOAD', 'pageId', 'params', 'search', 'pathname');
export const loaded = createAction(prefix, 'LOADED', 'pageId');
export const lazyload = createAction(
    prefix,
    'LAZYLOAD',
    'pageId',
    'params',
    'search',
    'pathname',
    'preloaded'
);
export const lazyloaded = createAction(prefix, 'LAZYLOADED', 'pageId');
export const error = createAction(prefix, 'ERROR', 'pageId', 'statusCode');
export const redirect = createAction(prefix, 'REDIRECT', 'pageId', 'to', 'redirectMessage');
export const setNavbarOptions = createAction(prefix, 'SET_NAVBAR_OPTIONS', 'navbarOptions');
export const setFooterOptions = createAction(prefix, 'SET_FOOTER_OPTIONS', 'footerOptions');
export const setZendeskOptions = createAction(prefix, 'SET_ZENDESK_OPTIONS', 'payload');
export const resetPaymentIntentStorage = createAction(prefix, 'RESET_PAYMENT_INTENT_STORAGE');
export const getLatestMembershipPlan = createAction(prefix, 'GET_LATEST_MEMBERSHIP_PLAN');
