import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadProfiles.SUCCESS: {
            const { facetsOnly = false } = action.requestData;

            return facetsOnly
                ? state
                : {
                      ...state,
                      ...action.data.results.reduce(
                          (profiles, profile) => ({
                              [profile.id]: state[profile.id]
                                  ? transform.updateProfileFromPartialResponse(
                                        state[profile.id],
                                        profile
                                    )
                                  : transform.getProfile(profile),
                              ...profiles,
                          }),
                          {}
                      ),
                  };
        }
        case actions.search.loadProfile.SUCCESS:
            return {
                ...state,
                [action.data.id]: transform.getProfile({
                    ...(state[action.data.id] || {}),
                    ...action.data,
                }),
            };
        case actions.search.loadProfilesFavourites.SUCCESS:
            return {
                ...state,
                ...action.data.results.reduce((profiles, profile) => {
                    profiles[profile.id] = transform.getProfile({
                        ...(state[profile.id] || {}),
                        ...profile,
                    });
                    return profiles;
                }, {}),
            };
        default:
            return state;
    }
};
