import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { getIsMembershipActive } from 'utils/account';
import { routes } from 'utils/routes';
import { getIsLoggedIn } from 'src/shared/selectors';
import optimizely from 'utils/integrations/optimizely';
import { ANONYMOUS_USER_ID } from 'containers/ExperimentalFeature';
import { getAccountSettings } from 'api/selectors';
import { features, isFeatureEnabled } from 'components/Feature';

/* eslint-disable consistent-return */
const redirectPathIfLoggedOut = (isLoggedIn) => {
    if (isLoggedIn) return;
    return routes.login();
};

const redirectPathIfExpired = (membershipPlan) => {
    if (getIsMembershipActive(membershipPlan)) return;
    return routes.accounts.profile.renew();
};

/* eslint-enable consistent-return */

const redirectIfLoggedOut = {
    selector: getIsLoggedIn,
    redirect: redirectPathIfLoggedOut,
    preserveNextOnRedirect: true,
};

// TODO: this user journey is probably not quite right.
// Maybe switch to [redirectIfLoggedOut, onlyPaidMembers] for a better UX
const redirectIfExpired = {
    selector: getAccountCurrentMembershipPlan,
    redirect: redirectPathIfExpired,
    preserveNextOnRedirect: true,
};

// Similar to the above but we just want paid members and all others are
// redirected to explore-plans to get them to pay
const onlyPaidMembers = {
    selector: getAccountCurrentMembershipPlan,
    redirect: (membershipPlan) => {
        if (getIsMembershipActive(membershipPlan)) return null;
        return routes.accounts.explorePlans();
    },
    // These users will be going to explore-plans which doesn't use `next`
    preserveNextOnRedirect: false,
};

// Used to redirect users to the plans page if they are not logged in
const redirectToPlansIfLoggedOut = {
    selector: getIsLoggedIn,
    redirect: (isLoggedIn) => {
        if (isLoggedIn) return null;
        return routes.accounts.explorePlans();
    },
    // These users will be going to explore-plans which doesn't use `next`
    preserveNextOnRedirect: false,
};

/**
 * Redirects a user based on a feature flag
 *
 * @param {string} featureName The feature flag name
 * @param {string|function} redirectPath The path to redirect to or a function that returns a path
 * @param {boolean} expectedValue The expected value of the feature flag
 */
const redirectBasedOnFeatureFlag = (featureName, redirectPath, expectedValue = true) => ({
    selector: getAccountSettings,
    redirect: (accountSettings, props) => {
        const { isStaff = false } = accountSettings || {};

        const enabled = optimizely
            .getClient()
            .isFeatureEnabled(featureName, ANONYMOUS_USER_ID, { isStaff });

        if (enabled === expectedValue) return null;

        if (typeof redirectPath === 'function') {
            return redirectPath(props);
        }

        return redirectPath;
    },
    // These users will be going to explore-plans which doesn't use `next`
    preserveNextOnRedirect: false,
});

export {
    redirectIfExpired,
    redirectIfLoggedOut,
    onlyPaidMembers,
    redirectToPlansIfLoggedOut,
    redirectBasedOnFeatureFlag,
};
