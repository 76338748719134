export const OS = {
    iOS: 'iOS',
    android: 'Android',
    chromeOS: 'ChromiumOS',
    windows: 'Windows',
    macOS: 'MacOS',
    unix: 'unix-gnu-like',
    unknown: 'unknown',
};

export const Device = {
    desktop: 'desktop',
    mobile: 'mobile',
    other: 'other',
    tablet: 'tablet',
};

export const Browser = {
    chrome: 'chrome',
    firefox: 'firefox',
    safari: 'safari',
    edge: 'edge',
    opera: 'opera',
    other: 'other',
};

/**
 * Map the values returned by the `ua-parser-js` typescript package
 * @see https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/ua-parser-js/index.d.ts
 */
export const OSTypes = {
    [OS.iOS]: ['iOS', 'iPadOS'],
    [OS.android]: ['Android', 'Tizen', 'Sailfish'],
    [OS.chromeOS]: ['Chromium OS'],
    [OS.windows]: ['Windows', 'Windows [Phone/Mobile]'],
    [OS.macOS]: ['Mac OS'],
    [OS.unix]: [
        'Arch',
        'CentOS',
        'Fedora',
        'FreeBSD',
        'Debian',
        'DragonFly',
        'Linux',
        'Mandriva',
        'Gentoo',
        'GNU',
        'Mint',
        'NetBSD',
        'OpenBSD',
        'PCLinuxOS',
        'RedHat',
        'Slackware',
        'Solaris',
        'SUSE',
        'Ubuntu',
        'UNIX',
        'VectorLinux',
        'WebOS',
    ],
};

export const DeviceTypes = {
    [Device.mobile]: ['mobile'],
    [Device.tablet]: ['tablet'],
    [Device.other]: ['console', 'smarttv', 'wearable', 'embedded'],
};

export const BrowserTypes = {
    [Browser.chrome]: ['Chrome Headless', 'Chrome WebView', 'Chrome', 'Chromium'],
    [Browser.firefox]: ['Firefox', 'Mozilla'],
    [Browser.safari]: ['Safari', 'Mobile Safari'],
    [Browser.edge]: ['Edge'],
    [Browser.opera]: ['Opera'],
};
