// eslint-disable-next-line import/prefer-default-export
export const USER_VISITED_CHECKOUT = {
    create(userId) {
        return {
            type: 'User Visited Checkout',
            payload: {
                userId,
            },
        };
    },
};
