/* eslint-disable no-underscore-dangle */
/* eslint-env browser */
import 'intersection-observer';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { useSSR, initReactI18next } from 'react-i18next';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import createHistory from 'history/createBrowserHistory';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/react';
import { ToastProvider } from '@ths/design-system';
import apiClientConfig from 'api/config';
import { sbjsInit } from 'utils/analytics/init';
import isDevelopmentMode from 'utils/environment';
import optimizely from 'utils/integrations/optimizely';
import { filterInboundSentryLogs } from 'utils/integrations/sentry';
import { addDebugTools } from 'containers/ExperimentalFeature/helpers';
import ThemeProvider from 'components/ThemeProvider';
import { setInjectSaga, setInjectReducer } from 'utils/asyncSagasAndReducers';
import UserAgentContextProvider from 'utils/ssr/userAgent/UserAgentContext';
import './client-public-path';
import { i18n as i18nBase, i18nOptions } from './i18n';
import environment, { configureEnvironment, getBrowserEnvironment } from './environment';
import configureStore from './store/configure';
import routes, { ContextProvider } from './routes';

configureEnvironment(getBrowserEnvironment());

// Configure api client
Object.keys(environment.vars.apiClientConfig).forEach((key) => {
    apiClientConfig[key] = environment.vars.apiClientConfig[key];
});

// Instantiate optimizely client
optimizely.createBrowserInstance(JSON.parse(window.optimizelyDatafile));

const { basename } = environment.vars;

const history = createHistory({ basename });
sbjsInit(); // Initial page view
history.listen(sbjsInit); // And on each route change

// Redux bootstrap
const initialState = window.__INITIAL_STATE__;
const { store, injectSaga, injectReducer } = configureStore(initialState, history);
// Store inject saga to allow lazy loading of page sagas
setInjectSaga(injectSaga);
setInjectReducer(injectReducer);

// i18n bootstrap
// need to initialize to work on the client side
i18nBase.use(initReactI18next).init(i18nOptions);
const initialI18nStore = window.__INITIAL_I18N_STORE__;

// Mode and env are backwards, mode is the environment we're running in (local, staging, hotfix, production) and
// env is which mode (development or production) we are running in
// Sentry is enabled for any environment that is running in production mode
const sentryDSN = environment.vars.env === 'production' ? environment.vars.sentry.dsn : null;

// Only send 10% of traces in prod to try to avoid filling up our quota
const sentryTracesSampleRate = environment.vars.mode === 'production' ? 0.1 : 1;

Sentry.init({
    dsn: sentryDSN,
    environment: environment.vars.mode,
    release: environment.vars.releaseName,
    beforeSend: filterInboundSentryLogs,
    // Move to no new naming: https://docs.sentry.io/platforms/javascript/guides/react/migration/v7-to-v8/v7-deprecations/
    integrations: [
        Sentry.browserTracingIntegration({ enableInp: true }),
        Sentry.browserProfilingIntegration(),
        Sentry.dedupeIntegration(),
    ],
    tracesSampleRate: sentryTracesSampleRate,
    ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'TypeError: Failed to fetch',
        'ChunkLoadError',
        '@webkit-masked-url',
        'chrome-extension',
        'Failed to load https://js.appboycdn.com/web-sdk',
        'Failed to load https://www.googletagmanager.com',
    ],
    profilesSampleRate: 1.0,
});

async function loadDevModules() {
    const whyDidYouRender = await import('@welldone-software/why-did-you-render');
    whyDidYouRender.default(React);
}

// using useSSR hook as per the docs instruction https://react.i18next.com/latest/ssr
const App = ({ context }) => {
    useSSR(initialI18nStore, initialState.language);

    return (
        <ThemeProvider>
            <UserAgentContextProvider header={window.navigator.userAgent}>
                <ToastProvider>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <ContextProvider context={context}>
                                {renderRoutes(routes)}
                            </ContextProvider>
                        </ConnectedRouter>
                    </Provider>
                </ToastProvider>
            </UserAgentContextProvider>
        </ThemeProvider>
    );
};

const context = {};
const root = document.getElementById('app');
loadableReady(async () => {
    if (isDevelopmentMode()) {
        await loadDevModules();
        addDebugTools();
    }
    if (initialState) {
        hydrate(<App context={context} />, root);
    }
});
