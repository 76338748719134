import { put, takeEvery, take, call } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { track, Events } from 'utils/analytics';
import { showError, showSuccess, setSubmitting } from './actions';

export function* sendReferralEmail(action) {
    const { emailAddress } = action.payload;
    const emailAddresses = [...emailAddress];

    yield put(setSubmitting.create(true));

    yield put(
        apiActions.account.sendReferralEmail({
            data: {
                emailAddresses,
            },
            forceReload: true,
        })
    );

    const { status } = yield take(settings.account.sendReferralEmail.DONE);

    yield put(setSubmitting.create(false));

    if (status === settings.account.sendReferralEmail.SUCCESS) {
        yield put(showSuccess.create());
        yield call(track, Events.USER_SENT_RAF_EMAIL.create(emailAddresses, true));

        return true;
    }

    yield put(showError.create());
    yield call(track, Events.USER_SENT_RAF_EMAIL.create(emailAddresses, false));

    return false;
}

export default function* sagas() {
    yield takeEvery((action) => action.type === 'SEND_REFERRAL_EMAIL', sendReferralEmail);
}
