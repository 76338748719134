import { all, put, takeLatest, select, takeEvery, fork } from 'redux-saga/effects';

import {
    loaded as pageActionsLoaded,
    preloaded as pageActionsPreloaded,
    load as pageActionsLoad,
    preload as pageActionsPreload,
    lazyload as pageActionsLazyload,
    lazyloaded as pageActionsLazyloaded,
} from 'containers/Page/actions';
import apiActions from 'api/actions';
import { getSearchProfiles, getSearchProfilesTotal } from 'api/selectors/search';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
    userTypes,
    VariationTypes,
} from 'containers/ExperimentalFeature';
import { track, Events } from 'utils/analytics';
import { SEARCH_TYPE } from 'config/search';

import { categories } from 'containers/BlogPostsCarousel';
import { fetchBlogPostsRequest } from 'containers/BlogPostsCarousel/actions';
import { CONTENT_TYPES } from 'config/content';
import * as actions from '../actions';
import doSearch from './doSearch';
import navigateTo from './navigateTo';
import loadFavourites from './loadingFavourites';
import configureSittersLookingInYourAreaTestActive from './configureSittersLookingInYourAreaTestActive';

import { SEARCH_TYPE_RESULTS } from '../SearchProfiles.constants';
import { getParams, getFilters, getPlace, getSearchProfilesQuery } from '../selectors';

export function* loadBlogPostsCarousel() {
    yield put(
        fetchBlogPostsRequest.create({
            area: CONTENT_TYPES.blog,
            categories: [categories.OWNERS, categories.PETS, categories.TRAVEL],
        })
    );
}

function* preload(action) {
    yield loadFavourites(true);
    yield configureSittersLookingInYourAreaTestActive();
    const searchSuccessful = yield doSearch({
        ...action,
    });

    if (searchSuccessful) {
        yield put(pageActionsPreloaded.create('SearchProfiles'));
    }

    yield fork(loadBlogPostsCarousel, action);
}

function* lazyload(action) {
    const { preloaded } = action;

    if (preloaded) {
        const profiles = yield select(getSearchProfiles, SEARCH_TYPE_RESULTS);
        const total = yield select(getSearchProfilesTotal, SEARCH_TYPE_RESULTS);
        const filters = yield select(getFilters);
        const params = yield select(getParams);
        const place = yield select(getPlace);
        const searchQuery = yield select(getSearchProfilesQuery, SEARCH_TYPE_RESULTS);
        const hasGeoHierarchyParams = Object.keys(params).length;

        const searchFilters = {
            ...filters.filters,
            seoHierarcy: hasGeoHierarchyParams,
        };

        if (hasGeoHierarchyParams > 0) {
            searchFilters.geoHierarchy = params;
        } else if (
            searchQuery &&
            searchQuery.geoHierarchy &&
            Object.keys(searchQuery.geoHierarchy).length > 0
        ) {
            searchFilters.geoHierarchy = searchQuery.geoHierarchy;
        }

        if (place && Object.keys(place).length > 0) {
            searchFilters.place = place;
        }
        // Log analytics on preload
        if (preloaded) {
            track(
                Events.SEARCH_FILTERS.create({
                    category: SEARCH_TYPE.Profile,
                    query: searchFilters,
                    items: profiles || [],
                    searchOptions: {
                        totalResults: total,
                    },
                })
            );
        }
    }

    yield put(pageActionsLazyloaded.create('SearchProfiles'));
}

function* load(action) {
    yield loadFavourites();
    yield configureSittersLookingInYourAreaTestActive();
    const searchSuccessful = yield doSearch({
        ...action,
    });

    if (searchSuccessful) {
        yield put(pageActionsLoaded.create('SearchProfiles'));
    }

    yield fork(loadBlogPostsCarousel, action);
}

/**
 * Loads new results based on the filters selected
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* searchFiltersUpdated(action) {
    const params = yield select(getParams);
    yield navigateTo(params, action.filters, 1, action.searchMethod);
}

function* loadAdditionalProfileData() {
    const { variation } = yield select(getExperimentalFeatureVariationSelector, {
        experiment: experiments.SEARCH_PROFILE_LAYOUT,
        excludeCombo: [userTypes.PaidUser, userTypes.AnonymousUser, userTypes.ExpiredUser],
    });
    const isVariation1OfProfileTest = variation === VariationTypes.VARIATION1;

    const profiles = yield select(getSearchProfiles, SEARCH_TYPE_RESULTS);

    if (isVariation1OfProfileTest && profiles && profiles.length > 0) {
        for (let i = 0; i < profiles.length; i += 1) {
            yield put(
                apiActions.search.loadProfile({
                    forceReload: true,
                    data: {
                        id: profiles[i].id,
                    },
                })
            );
        }
    }
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) =>
                action.type === pageActionsLoad.ACTION && action.pageId === 'SearchProfiles',
            load
        ),
        takeEvery(
            (action) =>
                action.type === pageActionsPreload.ACTION && action.pageId === 'SearchProfiles',
            preload
        ),
        takeEvery(
            (action) =>
                action.type === pageActionsLazyload.ACTION && action.pageId === 'SearchProfiles',
            lazyload
        ),
        takeLatest(actions.searchFiltersUpdated.ACTION, searchFiltersUpdated),
        takeLatest(actions.loadAdditionalProfileData.ACTION, loadAdditionalProfileData),
    ]);
}
