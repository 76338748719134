import { settings } from 'api/actions';
import { saveDatesDONE, deleteDateDONE, loadOpenAssignmentsSUCCESS } from './actions';

const defaultState = {
    responseStatus: 200,
    updateId: 0,
    boostedAssignmentResponseStatusCode: null,
    boostedAssignmentResponseTimestamp: null,
    loadOpenAssignmentsUpdateId: 0,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case loadOpenAssignmentsSUCCESS.ACTION: {
            return {
                ...state,
                loadOpenAssignmentsUpdateId: state.loadOpenAssignmentsUpdateId + 1,
            };
        }
        case saveDatesDONE.ACTION: {
            return {
                ...state,
                responseStatus: action.status,
                updateId: state.updateId + 1,
            };
        }
        case deleteDateDONE.ACTION: {
            return {
                ...state,
                responseStatus: action.status,
                updateId: state.updateId,
            };
        }
        case settings.owner.boostOpenAssignment.DONE: {
            return {
                ...state,
                boostedAssignmentResponseStatusCode: action.statusCode,
                boostedAssignmentResponseTimestamp: new Date().getTime(),
            };
        }
        default:
            return state;
    }
};
