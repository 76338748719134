import React from 'react';

import { useRating } from 'components/Rating/context';
import { RatingLabel } from './Label.style';
import { getLabelVisibility } from './utils';

function Label({ rating, children, ...props }) {
    const { hoveredRating, selectedRating } = useRating();

    return (
        <RatingLabel
            {...props}
            data-visible={getLabelVisibility(rating, hoveredRating, selectedRating)}
        >
            {children}
        </RatingLabel>
    );
}

Label.displayName = 'RatingLabel';

export default Label;
