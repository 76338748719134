import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import GlobalRegwall from './GlobalRegwall';
import { closeRegwall } from './actions';
import {
    getIsRegwallVisible,
    getRegwallType,
    getRegwallMessaging,
    getRedirectUrl,
    getRegwallOpenMethod,
    getIsRegwallCloseable,
    getRegwallCopy,
} from './selectors';

const mapStateToProps = (state) => ({
    isRegwallVisible: getIsRegwallVisible(state),
    regwallType: getRegwallType(state),
    messaging: getRegwallMessaging(state),
    redirectUrl: getRedirectUrl(state),
    openMethod: getRegwallOpenMethod(state),
    isRegwallCloseable: getIsRegwallCloseable(state),
    regwallCopy: getRegwallCopy(state),
});

const mapDispatchToProps = (dispatch) => ({
    onRegwallClose: () => {
        // replace current location with same location but remove the hash
        // This fixes an inability to re-open the same regwall in firefox
        const location = JSON.parse(JSON.stringify(window.location));
        location.hash = '';

        dispatch(replace(location));
        dispatch(closeRegwall.create());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRegwall);
