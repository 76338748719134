import { Animal, LocalAttraction, HomeType, AnimalId } from 'api/helpers/search/constants';
import {
    IconApartment,
    IconBeach,
    IconBird,
    IconCat,
    IconCity,
    IconCountryside,
    IconDisabled,
    IconDog,
    IconFamily,
    IconFarm,
    IconFish,
    IconHorse,
    IconHouse,
    IconMountain,
    IconPoultry,
    IconReptile,
    IconSmallPet,
    IconWifi,
    IconWifi2,
    IconCar,
    IconPublicTransport,
    IconNoPublicTransport,
} from 'components/Icon';

//
// Common
//
const AnimalToIconMap = Object.freeze({
    [Animal.DOG]: IconDog,
    [Animal.CAT]: IconCat,
    [Animal.BIRD]: IconBird,
    [Animal.FISH]: IconFish,
    [Animal.REPTILE]: IconReptile,
    [Animal.SMALLPET]: IconSmallPet,
    [Animal.FARMANIMAL]: IconFarm,
    [Animal.POULTRY]: IconPoultry,
    [Animal.HORSE]: IconHorse,
});

const Pets = [
    { type: Animal.DOG, icon: AnimalToIconMap[Animal.DOG] },
    { type: Animal.CAT, icon: AnimalToIconMap[Animal.CAT] },
    { type: Animal.REPTILE, icon: AnimalToIconMap[Animal.REPTILE] },
    { type: Animal.HORSE, icon: AnimalToIconMap[Animal.HORSE] },
    { type: Animal.FISH, icon: AnimalToIconMap[Animal.FISH] },
    { type: Animal.BIRD, icon: AnimalToIconMap[Animal.BIRD] },
    { type: Animal.POULTRY, icon: AnimalToIconMap[Animal.POULTRY] },
    { type: Animal.FARMANIMAL, icon: AnimalToIconMap[Animal.FARMANIMAL] },
    { type: Animal.SMALLPET, icon: AnimalToIconMap[Animal.SMALLPET] },
];

const Animals = [...Pets];

const LocalAttractions = [
    { type: LocalAttraction.BEACH, icon: IconBeach },
    { type: LocalAttraction.CITY, icon: IconCity },
    { type: LocalAttraction.COUNTRYSIDE, icon: IconCountryside },
    { type: LocalAttraction.MOUNTAIN, icon: IconMountain },
];

const InterestedAnimalIds = [
    { id: AnimalId.DOG, slug: Animal.DOG },
    { id: AnimalId.SMALLPET, slug: Animal.SMALLPET },
    { id: AnimalId.CAT, slug: Animal.CAT },
    { id: AnimalId.FISH, slug: Animal.FISH },
    { id: AnimalId.REPTILE, slug: Animal.REPTILE },
    { id: AnimalId.POULTRY, slug: Animal.POULTRY },
    { id: AnimalId.HORSE, slug: Animal.HORSE },
    { id: AnimalId.FARMANIMAL, slug: Animal.FARMANIMAL },
    { id: AnimalId.BIRD, slug: Animal.BIRD },
];

const DailyMinutesWillingToWalkDogs = [30, 60, 120, 180];
const DogSizePreferences = ['XS', 'S', 'M', 'L', 'XL'];
const MinutesPetsLeftAlone = [0, 120, 240, 360, 480];
const FamilyPreferences = ['single', 'couple', 'family'];

const HomeTypes = [
    { type: HomeType.HOUSE, icon: IconHouse },
    { type: HomeType.APARTMENT, icon: IconApartment },
];

const HomeFeatures = [
    { type: 'familyFriendly', icon: IconFamily },
    { type: 'wifiAvailable', icon: IconWifi },
    { type: 'disabledAccess', icon: IconDisabled },
    { type: 'isAccessibleByPublicTransport', icon: IconDisabled },
    { type: 'carIncluded', icon: 'car-included' },
];

const HomeListingIcons = {
    carIncluded: IconCar,
    disabledAccess: IconDisabled,
    familyFriendly: IconFamily,
    apartment: IconApartment,
    house: IconHouse,
    beach: IconBeach,
    city: IconCity,
    countrySide: IconCountryside,
    mountain: IconMountain,
    basic: IconWifi2,
    highSpeed: IconWifi2,
    isAccessibleByPublicTransport: IconPublicTransport,
    isNotAccessibleByPublicTransport: IconNoPublicTransport,
};

const TrustAndSafeties = [{ type: 'reviews' }, { type: 'hasExperience' }];

const FiltersSelectionType = Object.freeze({
    Value: 'value',
    Selected: 'selected',
    Count: 'count',
});

const SEARCH_TYPE = {
    Listing: 'listing',
    Profile: 'profile',
    Wizard: 'wizard',
};

//
// Sits
//
const SitsAvailableFilters = Object.freeze({
    Sortby: 'sortBy',
    Location: 'location',
    Dates: 'dates',
    Duration: 'duration',
    Pets: 'pets',
    LocalAttractions: 'localAttractions',
    HomeTypes: 'homeTypes',
    HomeFeatures: 'homeFeatures',
    Other: 'other',
});

const SitsDefaultFilters = Object.freeze({
    [SitsAvailableFilters.Location]: '',
    [SitsAvailableFilters.Dates]: {
        dateFrom: null,
        dateTo: null,
    },
    [SitsAvailableFilters.Duration]: {
        durationFacets: [],
        durationInDays: {
            minimum: 1,
            maximum: 0,
        },
    },
    [SitsAvailableFilters.Pets]: [],
    [SitsAvailableFilters.LocalAttractions]: [],
    [SitsAvailableFilters.HomeTypes]: [],
    [SitsAvailableFilters.HomeFeatures]: {
        familyFriendly: false,
        wifiAvailable: false,
        disabledAccess: false,
        isAccessibleByPublicTransport: false,
        carIncluded: false,
    },
    [SitsAvailableFilters.Sortby]: ['recommended'],
});

const SitsDefaultSearchQueryFilters = Object.freeze({
    assignments: {
        dateFrom: null,
        dateTo: null,
        durationInDays: {
            minimum: 1,
            maximum: 0,
        },
    },
    homeType: [],
    localAttractions: [],
    pets: [],
    familyFriendly: null,
    wifiAvailable: null,
    disabledAccess: null,
    isAccessibleByPublicTransport: null,
    carIncluded: null,
});

export const SITS_RESULTS_PER_PAGE = 12;

//
// Sitters
//
const ProfilesAvailableFilters = Object.freeze({
    Location: 'profileLocation',
    Dates: 'availability',
    Animals: 'animalExperience',
    TrustAndSafeties: 'trustAndSafeties',
    // Age: 'SittersAge',
    // ProfileFilters: 'SittersProfileFilters',
    // Other: 'sittersOther',
});

const ProfilesDefaultFilters = Object.freeze({
    [ProfilesAvailableFilters.Animals]: [],
    [ProfilesAvailableFilters.TrustAndSafeties]: {
        reviews: null,
        verificationLevel: null,
        hasExperience: false,
    },
});

export const PROFILES_RESULTS_PER_PAGE = 24;

// Sitter Recommendation Groups
const RECOMMENDED_SITS_GROUP = {
    beach: 'beach', // beach local attraction assignments
    city: 'city', // city local attraction assignments
    countryside: 'countryside', // countryside local attraction assignments
    mountains: 'mountain', // mountains local attraction assignments
    theseMightBeBack: 'theseMightBeBack', // search results of completed assignment in the past
};

// Owner Recommendation Groups
const RECOMMENDED_PROFILES_GROUP = {
    favouriteYourLocation: 'favouriteYourLocation',
    newWithReferences: 'newWithReferences',
    experiencedWithAnimals: 'experiencedWithAnimals',
    yourKindOfSitters: 'yourKindOfSitters',
};

const NEARBY_PROFILES = {
    key: 'nearbySitters',
    resultsToShow: 24,
    showWhenLessThan: 10,
};

const ProfilesDefaultSearchQueryFilters = Object.freeze({
    availability: {
        dateFrom: null,
        dateTo: null,
    },
    animalExperience: [],
    memberReviews: null,
    verifications: null,
    houseSittingExperience: null,
});

export {
    AnimalToIconMap,
    Pets,
    Animals,
    LocalAttractions,
    InterestedAnimalIds,
    DailyMinutesWillingToWalkDogs,
    DogSizePreferences,
    MinutesPetsLeftAlone,
    FamilyPreferences,
    HomeTypes,
    HomeFeatures,
    HomeListingIcons,
    TrustAndSafeties,
    FiltersSelectionType,
    SitsAvailableFilters,
    SitsDefaultFilters,
    SitsDefaultSearchQueryFilters,
    ProfilesAvailableFilters,
    ProfilesDefaultFilters,
    ProfilesDefaultSearchQueryFilters,
    RECOMMENDED_SITS_GROUP,
    RECOMMENDED_PROFILES_GROUP,
    NEARBY_PROFILES,
    SEARCH_TYPE,
};
