import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import actions from 'api/actions';
import { getOwnerAddress } from 'api/selectors';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import { isViewingInAppWebView } from 'shared/selectors';
import { getRawListing, getResponseStatus, getUpdateId } from '../selectors';
import { getSaveAddressResponseStatus, getSaveAddressUpdateId } from './selectors';
import { PAGE_ID } from './Location.constants';

const LoadableLocationListing = loadable(() => retry(() => import('./Location')));

const mapStateToProps = (state) => ({
    listing: getRawListing(state),
    address: getOwnerAddress(state),
    responseStatus: getResponseStatus(state),
    updateId: getUpdateId(state),
    saveaddressResponseStatus: getSaveAddressResponseStatus(state),
    saveaddressUpdateId: getSaveAddressUpdateId(state),
    isViewingInAppWebView: isViewingInAppWebView(state),
});

const mapDispatchToProps = (dispatch) => ({
    saveListing: (action) => dispatch(actions.owner.updateListing(action)),
});

const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableLocationListing), PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
