import { put, select, take } from 'redux-saga/effects';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { getIsMembershipActive, isSitterOnly } from 'utils/account';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
} from 'containers/ExperimentalFeature';
import apiActions, { settings } from 'api/actions';
import * as actions from '../actions';
import * as selectors from '../selectors';

export default function* configureLocalSitsTestActive() {
    const membership = yield select(getAccountCurrentMembershipPlan);
    const isPaidMember = getIsMembershipActive(membership);
    const { enabled: isLocalSitsTestActive, variation: localSitsTestVariation } = yield select(
        getExperimentalFeatureVariationSelector,
        {
            experiment: experiments.EXPLORE_SITS_NEARBY,
            userId: membership.userId,
        }
    );
    if (isLocalSitsTestActive && isPaidMember && isSitterOnly(membership)) {
        // check to see if already in the test so dont call loadProfile again unneccessarily
        const hasLocalSitsABTestVariant = yield select(selectors.getLocalSitsABTestVariant);
        if (!hasLocalSitsABTestVariant) {
            yield put(
                apiActions.search.loadProfile({
                    forceReload: true,
                    data: {
                        id: 'me',
                    },
                })
            );
            const { data, status } = yield take(
                (res) => res.type === settings.search.loadProfile.DONE
            );

            if (status === settings.search.loadProfile.SUCCESS) {
                if (!data.assignments || (data.assignments && data.assignments.length === 0)) {
                    yield put(actions.localSitsABTestVariant.create(localSitsTestVariation));
                } else {
                    yield put(actions.localSitsABTestVariant.create(null));
                }
            } else {
                yield put(actions.localSitsABTestVariant.create(null));
            }
        }
    } else {
        yield put(actions.localSitsABTestVariant.create(null));
    }
}
