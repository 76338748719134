import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'components/Link';
import {
    WrapperStyled,
    PageSectionStyled,
    ContainerStyled,
    TitleStyled,
    BodyStyled,
    UlStyled,
    LiStyled,
    IconArrowRightStyled,
    ImageWrapperStyled,
    ImageStyled,
} from './Error.style';
import { ERROR_CODES, MESSAGES, AVAILABLE_LINKS } from './Error.constants';
import error404Image from './assets/image-error-404.jpg';
import error500Image from './assets/image-error-500.jpg';

const Error = ({ statusCode = ERROR_CODES.ERROR_500, children }) => {
    const { t } = useTranslation();

    let supportedStatusCode = statusCode;
    // Is this error code supported
    if (!Object.values(ERROR_CODES).includes(statusCode)) {
        supportedStatusCode = ERROR_CODES.ERROR_500;
    }

    const { titleKey, bodyKey } = MESSAGES[supportedStatusCode];

    const image =
        {
            [ERROR_CODES.ERROR_404]: error404Image,
            [ERROR_CODES.ERROR_500]: error500Image,
        }[supportedStatusCode] || error500Image;

    const links = AVAILABLE_LINKS[supportedStatusCode];

    return (
        <WrapperStyled>
            <PageSectionStyled>
                <ContainerStyled>
                    <TitleStyled>{t(titleKey)}</TitleStyled>
                    <BodyStyled>{t(bodyKey)}</BodyStyled>
                    <UlStyled>
                        {links &&
                            links.map((link) => (
                                <LiStyled key={link.url}>
                                    <Link to={link.url}>{t(link.i18nKey)}</Link>
                                    <IconArrowRightStyled size="small" />
                                </LiStyled>
                            ))}
                    </UlStyled>
                </ContainerStyled>
                {image && (
                    <ImageWrapperStyled>
                        <ImageStyled src={image} />
                    </ImageWrapperStyled>
                )}
            </PageSectionStyled>
            {children}
        </WrapperStyled>
    );
};

Error.statusCodes = ERROR_CODES;

export default Error;
