import { useEffect, useMemo, useState } from 'react';

/**
 * @description takes a string and returns a boolean value
 *
 * @param {string} qs string value of a media query for width e.g. '(min-width: 600px)'
 *
 * @returns {boolean} a boolean if the supplied media query applies to the current width of a screen
 */
// eslint-disable-next-line import/prefer-default-export
export const useMediaQuery = (qs) => {
    const mq = useMemo(() => {
        if (typeof window === 'undefined') {
            return () => {};
        }
        return window.matchMedia(qs);
    }, [qs]);
    const [matches, setMatches] = useState(mq?.matches);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return () => {};
        }

        function handler(e) {
            setMatches(e.matches);
        }

        mq.addListener(handler);

        return () => mq.removeListener(handler);
    }, [mq]);

    return matches;
};
