import { commonKeywordMapping } from './common';

/**
 * Welcome Guide
 * @param welcomeGuide
 */
// eslint-disable-next-line import/prefer-default-export
export const getWelcomeGuide = (welcomeGuide) => {
    if (!welcomeGuide) return welcomeGuide;

    const {
        homeDetails,
        petsDetails,
        transportDetails,
        neighbourhoodDetails,
        emergencyDetails,
    } = welcomeGuide;

    const { personality = [] } = petsDetails || {};
    return {
        homeDetails: {
            ...homeDetails,
            kitchenAppliances: homeDetails.kitchenAppliances.map(item => commonKeywordMapping(item)),
            roomsExtraFeatures: homeDetails.roomsExtraFeatures.map(item => commonKeywordMapping(item)),
            wifiSpeed: commonKeywordMapping(homeDetails.wifiSpeed),
            phoneSignal: commonKeywordMapping(homeDetails.phoneSignal),
            outdoorSpaceDetails: homeDetails.outdoorSpaceDetails.map(item => commonKeywordMapping(item)),
        },
        petsDetails: {
            ...petsDetails,
            personality: personality.map(item => commonKeywordMapping(item)),
        },
        transportDetails,
        neighbourhoodDetails,
        emergencyDetails,
    };
};

export const getPlacesOfInterest = (placesOfInterest) => {
    if (!placesOfInterest) return placesOfInterest;

    const newData = {
        shoppingForFood: [],
        restaurantsAndPubs: [],
        favouriteCafes: [],
        niceWalks: [],
        localAttractions: [],
    };

    placesOfInterest.forEach((place) => {
        switch (place.category) {
            case ('shop'):
                newData.shoppingForFood.push({ ...place });
                break;
            case ('walk'):
                newData.niceWalks.push({ ...place });
                break;
            case ('restaurant_or_pub'):
                newData.restaurantsAndPubs.push({ ...place });
                break;
            case ('attraction'):
                newData.localAttractions.push({ ...place });
                break;
            case ('cafe'):
                newData.favouriteCafes.push({ ...place });
                break;
            default:
                break;
        }
    });

    return newData;
};
