import * as actions from './actions';

export const initialState = {
    plan: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.premiumPlanLoaded.ACTION:
            return {
                ...state,
                plan: action.plan,
            };
        default:
            return state;
    }
};
