import * as React from 'react';
import { CounterDisplayCount } from './Display.style';

function Display({ children, shouldSkipParsing = false }) {
    const counter = shouldSkipParsing ? children : parseInt(children, 10);

    return <CounterDisplayCount>{counter}</CounterDisplayCount>;
}

export default Display;
