import { LOCATION_CHANGE } from 'connected-react-router';
import * as actions from './actions';

const initialState = {
    stepNumber: 1,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.updateStepNumber.ACTION:
            return {
                ...state,
                stepNumber:
                    Number.isNaN(action.stepNumber) && action.stepNumber < 1
                        ? state.stepNumber
                        : action.stepNumber,
            };
        // reset step number when navigating away from the page
        case LOCATION_CHANGE:
            return initialState;
        default:
            return state;
    }
};
