/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const WarningIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="M10 12a1 1 0 0 1-1-1V6a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1ZM10 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        />
        <path
            fill="currentColor"
            d="M10 1a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm0 2a7 7 0 1 1 0 14 7 7 0 0 1 0-14Z"
        />
    </svg>
);
const ForwardRef = forwardRef(WarningIcon);
export default ForwardRef;
