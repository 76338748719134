import asPage from 'containers/Page';
import { redirectIfLoggedOut } from 'pages/user/helpers';
import loadable from '@loadable/component';
import * as pageActions from 'containers/Page/actions';
import { retry } from 'utils/ssr';

const LoadableUpgrade = loadable(() => retry(() => import('./Upgrade')));

export const PAGE_ID = 'UpgradeLanding';

const authorisationRedirects = [redirectIfLoggedOut];

export default asPage(LoadableUpgrade, PAGE_ID, false, false, {
    isProtectedRoute: true,
    checkAuthorised: authorisationRedirects,
    serverSideQuery: ({ store }) => {
        store.dispatch(pageActions.setFooterOptions.create({ hideLinks: true }));
        store.dispatch(pageActions.setZendeskOptions.create({ isHidden: true }));
    },
});
