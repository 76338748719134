import React from 'react';
import PropTypes from 'prop-types';
import { linkResolver } from 'components/content/contentHelpers';
import { P } from '@ths/design-system';
import RichText from '../../../RichText';

const Subheading = ({ subheading }) => {
    if (!subheading) {
        return null;
    }

    if (typeof subheading === 'string') {
        return (
            <div className="flex justify-center">
                <P className="my-[-16px] max-w-[554px] text-center text-grey-700">{subheading}</P>
            </div>
        );
    }

    return (
        <div className="flex justify-center">
            <RichText
                richText={subheading}
                linkResolver={linkResolver}
                className="my-[-16px] max-w-[554px] text-center text-grey-700"
            />
        </div>
    );
};

Subheading.defaultProps = {
    subheading: null,
};

Subheading.propTypes = {
    subheading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                spans: PropTypes.arrayOf(
                    PropTypes.shape({
                        start: PropTypes.number.isRequired,
                        end: PropTypes.number.isRequired,
                        type: PropTypes.string.isRequired,
                        data: PropTypes.shape({
                            id: PropTypes.string.isRequired,
                            slug: PropTypes.string.isRequired,
                            tags: PropTypes.arrayOf(PropTypes.string).isRequired,
                            lang: PropTypes.string.isRequired,
                        }).isRequired,
                    })
                ),
                text: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            })
        ),
    ]),
};

export default Subheading;
