import { match } from 'path-to-regexp';
import { routes } from 'utils/routes';
import routePaths from 'src/route-paths';

const matchPathname = (pathname) =>
    match(pathname, {
        decode: decodeURIComponent,
    });

const allowList = [
    routePaths.accounts.profile.wildcard,
    routePaths.search.listings,
    routePaths.search.profiles,
    routePaths.search.listingsDetail,
    routePaths.search.profilesDetail,
].map(matchPathname);

const blockListForABTest = [routePaths.search.listings, routePaths.search.profiles].map(
    matchPathname
);

export const showReferAFriendNudgewallOnThisPage = (pathname) =>
    allowList.some((fn) => fn(pathname));

export const getCurrentPathnameIsDashboard = (pathname) =>
    pathname === routes.accounts.profile.dashboard();

// !! NOTE: Delete this after the AB test is over
export const getCurrentPathnameBlocked = (pathname) =>
    blockListForABTest.some((fn) => fn(pathname));
