import { settings } from 'api/actions';
import closeListingInReviewPrompt from './actions';

const defaultState = {
    // We show a spinner for the first time the page is loaded, when we
    // have not received dates yet from the api.
    hasDatesLoadedInitially: false,
    showListingInReviewPrompt: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case settings.owner.loadListingFutureAssignments.DONE: {
            return {
                ...state,
                hasDatesLoadedInitially: true,
            };
        }
        case settings.owner.sendListingForReview.SUCCESS: {
            return {
                ...state,
                showListingInReviewPrompt: true,
            };
        }
        case closeListingInReviewPrompt.ACTION: {
            return {
                ...state,
                showListingInReviewPrompt: false,
            };
        }
        default:
            return state;
    }
};
