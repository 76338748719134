import React, { PureComponent } from 'react';
import { Variant } from './CheckButton.constants';
import {
    CheckboxStyled,
    CheckIconStyled,
    IconStyled,
    LabelStyled,
    WrapperLabelStyled,
    WrapperStyled,
} from './CheckButton.style';

class CheckButton extends PureComponent {
    constructor(props) {
        super(props);

        this.buttonId = props.id || Math.random().toString().replace(/0\./, '');
        this.id = `checkButton_${this.buttonId}`;
        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        const { isChecked } = this.props;
        this.props.onChange(!isChecked);
    }

    render() {
        const {
            label,
            icon,
            variant,
            iconProps,
            alwaysShowIcons,
            isChecked,
            hasCheckIcon,
            disabled,
            name,
            fullWidth,
            className,
        } = this.props;

        // Set checkbox props
        const checkboxProps = {};
        if (name) {
            checkboxProps.name = name;
        }
        if (disabled) {
            checkboxProps.disabled = true;
        } else {
            checkboxProps.onChange = this.onChange;
        }

        return (
            <WrapperStyled fullWidth={fullWidth} className={className} variant={variant}>
                <CheckboxStyled
                    id={this.id}
                    type="checkbox"
                    checked={isChecked}
                    {...checkboxProps}
                    variant={variant}
                />
                <WrapperLabelStyled
                    htmlFor={this.id}
                    isChecked={isChecked}
                    isDisabled={disabled}
                    fullWidth={fullWidth}
                    variant={variant}
                >
                    {!!icon && (
                        <IconStyled
                            as={icon}
                            {...iconProps}
                            alwaysShowIcons={alwaysShowIcons}
                            variant={variant}
                        />
                    )}
                    <LabelStyled isChecked={isChecked} variant={variant}>
                        {label}
                    </LabelStyled>
                    {isChecked && hasCheckIcon && (
                        <CheckIconStyled alwaysShowIcons={alwaysShowIcons} variant={variant} />
                    )}
                </WrapperLabelStyled>
            </WrapperStyled>
        );
    }
}

CheckButton.defaultProps = {
    isChecked: false,
    hasCheckIcon: true,
    variant: Variant.Primary,
};

CheckButton.Variant = {
    ...Variant,
};

export default CheckButton;
