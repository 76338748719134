import React from 'react';
import PropTypes from 'prop-types';
import { matchRoutes } from 'react-router-config';
import config from './config';

// pages
import Root from './pages/Root';
import App from './pages/App';
import Home from './pages/Home';
import TrustAndSafety from './pages/TrustAndSafety';
import RAFLanding from './pages/RAFLanding';
import ExplorePlans from './pages/plans/ExplorePlans';
import Pricing from './pages/plans/Pricing';
import PrivacyPolicy from './pages/policies/PrivacyPolicy';
import Terms from './pages/policies/Terms';
import AppDownload from './pages/AppDownload';
import ProvideReference from './pages/ProvideReference';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import SetNewPassword from './pages/ResetPassword/SetNewPassword';
import VerifyEmail from './pages/VerifyEmail';
import ErrorPage from './pages/Error';

// routes
import ownerFlow from './pages/OwnerFlow/routes';
import blog from './pages/blog/routes';
import page from './pages/Page/routes';
import accounts from './pages/accounts/routes';
import search from './pages/search/routes';
import howItWorks from './pages/howItWorks/routes';
import sitemap from './pages/Sitemap/routes';
import user from './pages/user/routes';
import email from './pages/email/routes';
import join from './pages/join/routes';

import paths from './route-paths';

const routes = [
    {
        component: Root,
        routes: [
            ownerFlow,
            {
                component: App,
                routes: [
                    {
                        path: paths.home,
                        exact: true,
                        component: Home,
                    },
                    {
                        path: paths.trustAndSafety,
                        exact: true,
                        component: TrustAndSafety,
                    },
                    {
                        path: paths.privacyPolicy,
                        exact: true,
                        component: PrivacyPolicy,
                    },
                    {
                        path: paths.terms,
                        exact: true,
                        component: Terms,
                    },
                    {
                        path: paths.refer.landing,
                        exact: true,
                        component: RAFLanding,
                    },
                    {
                        path: paths.accounts.explorePlans,
                        exact: true,
                        component: ExplorePlans,
                    },
                    {
                        path: paths.pricing,
                        exact: true,
                        component: Pricing,
                    },
                    {
                        path: paths.appDownload,
                        exact: true,
                        component: AppDownload,
                    },
                    {
                        path: paths.provideReference,
                        exact: true,
                        component: ProvideReference,
                    },
                    {
                        path: paths.login,
                        exact: true,
                        component: Login,
                    },
                    {
                        path: paths.resetPassword.requestNewPassword,
                        exact: true,
                        component: ResetPassword,
                    },
                    {
                        path: paths.resetPassword.setNewPassword,
                        exact: true,
                        component: SetNewPassword,
                    },
                    {
                        path: paths.verifyEmail,
                        exact: true,
                        component: VerifyEmail,
                    },
                    ...blog,
                    ...page,
                    ...accounts,
                    ...search,
                    ...howItWorks,
                    ...sitemap,
                    ...user,
                    ...email,
                    ...join,
                    {
                        path: '*',
                        component: ErrorPage,
                    },
                ],
            },
        ],
    },
];

export const getServerSideQueries = (location) => {
    const { basename } = config;
    const pathname = location.pathname.slice(basename.length);
    const branch = matchRoutes(routes, pathname);
    const preloadSagasWithArgs = [];
    const serverSideQueries = [];

    const addPreloadSagaWithArgs = (saga, match) => {
        preloadSagasWithArgs.push({
            saga,
            args: {
                params: match.params,
                pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    };

    const promises = [];

    branch.forEach(({ route, match }) => {
        // Is this a lazy loaded component, if so load it (to get access to the static preload saga)
        if (route.component?.load) {
            promises.push(
                new Promise((resolve) => {
                    route.component.load().then((component) => {
                        if (component.default && component.default.getPreloadSaga?.()) {
                            addPreloadSagaWithArgs(component.default.getPreloadSaga(), match);
                        }

                        if (component?.default?.getServerSideQuery) {
                            const query = component.default.getServerSideQuery(match);
                            if (query) {
                                serverSideQueries.push({ query, match });
                            }
                        }

                        resolve();
                    });
                })
            );
            route.component.load().then((component) => {
                if (component.default && component.default.getPreloadSaga?.()) {
                    addPreloadSagaWithArgs(component.default.getPreloadSaga(), match);
                }
                if (component?.default?.getServerSideQuery) {
                    const query = component.default.getServerSideQuery(match);
                    if (query) {
                        serverSideQueries.push({ query, match });
                    }
                }
            });
        } else {
            if (route.component?.getPreloadSaga) {
                addPreloadSagaWithArgs(route.component.getPreloadSaga(), match);
            }
            if (route?.component?.getServerSideQuery) {
                const query = route.component.getServerSideQuery(match);
                if (query) {
                    serverSideQueries.push({ query, match });
                }
            }
        }
    });

    return Promise.allSettled(promises).then(() => [preloadSagasWithArgs, serverSideQueries]);
};

export class ContextProvider extends React.Component {
    getChildContext() {
        const { context } = this.props;

        return {
            ...context,
        };
    }

    render() {
        const { children } = this.props;

        return React.Children.only(children);
    }
}

ContextProvider.childContextTypes = {
    staticContext: PropTypes.object,
};

ContextProvider.propTypes = {
    context: PropTypes.object.isRequired,
    children: PropTypes.node,
};

ContextProvider.defaultProps = {
    children: null,
};

export default routes;
