import { all, put, select, take, call, takeLatest, fork } from 'redux-saga/effects';
import { sub, isFuture, isToday } from 'date-fns';
import apiActions, { settings } from 'api/actions';
import {
    getAccountCurrentMembershipPlan,
    getSitterProfilePastAssignments,
    getOwnerPastAssignments,
} from 'api/selectors';
import { dateBefore, parseDate } from 'api/helpers/format/date';
import routePaths from 'src/route-paths';
import { getHasMembership, getRouterLocation } from 'shared/selectors';
import { matcher as matcherRoute, some as someRoute } from 'utils/routes';
import { hasSitter, hasOwner } from 'utils/account';
import * as PromptManager from 'containers/PromptManager';
import { isFeatureEnabled, features } from 'components/Feature';
import reviewSagas from './LeaveReviewModal/sagas';
import * as reviewActions from './LeaveReviewModal/actions';
import feedbackSagas from './LeaveFeedbackModal/sagas';
import * as feedbackActions from './LeaveFeedbackModal/actions';
import * as actions from './actions';
import * as storage from './storage';
import watchAngularSaga from './watchAngular';

const matchers = [
    matcherRoute(routePaths.accounts.explorePlans),
    matcherRoute(routePaths.accounts.profile.inbox),
    matcherRoute(routePaths.accounts.profile.settings),
    matcherRoute(routePaths.accounts.referAFriend),
    matcherRoute(routePaths.pricing),
];

export function* checkCurrentPathname() {
    const {
        location: { pathname },
    } = yield select(getRouterLocation);

    return someRoute(pathname, matchers);
}

export function* fetchSitterPastAssignments() {
    const assignments = yield select(getSitterProfilePastAssignments);

    if (assignments.length !== 0) {
        return assignments;
    }

    yield put(
        apiActions.sitter.loadPastAssignments({
            forceReload: true,
            filters: {
                order_by: '-start_date',
            },
        })
    );

    const { status } = yield take(settings.sitter.loadPastAssignments.DONE);

    if (status === settings.sitter.loadPastAssignments.SUCCESS) {
        // We need to select here again because `api-client` is doing some
        // things to transform the api response. This is not just a selector,
        // is doing a lot of things so we can't just return the data returned
        // by the `take` above.
        return yield select(getSitterProfilePastAssignments);
    }

    return [];
}

export function* fetchOwnerPastAssignments() {
    const assignments = yield select(getOwnerPastAssignments);

    if (assignments.length !== 0) {
        return assignments;
    }

    yield put(
        apiActions.owner.loadPastAssignments({
            forceReload: true,
            filters: {
                order_by: 'start_date',
            },
        })
    );

    const { status } = yield take(settings.owner.loadPastAssignments.DONE);

    if (status === settings.owner.loadPastAssignments.SUCCESS) {
        // We need to select here again because `api-client` is doing some
        // things to transform the api response. This is not just a selector,
        // is doing a lot of things so we can't just return the data returned
        // by the `take` above.
        return yield select(getOwnerPastAssignments);
    }

    return [];
}

export function getAvailableAssignments(assignments) {
    // TODO: Placeholder function, should be replaced after we merge api-client changes
    function isReviewWindowClosed(date) {
        if (!date) {
            return true;
        }

        const parsedDate = typeof date === 'string' ? parseDate(date) : date;

        return !isToday(parsedDate) && !isFuture(parsedDate);
    }

    if (isFeatureEnabled({ name: features.BLIND_REVIEWS })) {
        return assignments.filter(
            (assignment) => !isReviewWindowClosed(assignment.reviewWindowEndDate)
        );
    }

    return assignments.filter((assignment) =>
        dateBefore(sub(Date.now(), { months: 3 }), assignment.endDate)
    );
}

export function* filterAssignments(assignments) {
    const availableAssignments = getAvailableAssignments(assignments);
    const value = yield call(storage.getValue);

    if (!value) {
        return availableAssignments;
    }

    return availableAssignments.filter((assignment) => !value.includes(String(assignment.id)));
}

export function* hydratePastAssignments() {
    const membershipPlan = yield select(getAccountCurrentMembershipPlan);

    if (hasOwner(membershipPlan)) {
        // First we check if we have any pending reviews, if yes we just return from
        // this function because we don't want to show review and feedback at the
        // same time. Pending review takes priority over feedback.
        const assignments = yield call(filterAssignments, yield call(fetchOwnerPastAssignments));
        const pendingReviews = assignments.filter((assignment) => !assignment.isReviewProvided);

        if (pendingReviews.length > 0) {
            yield put(reviewActions.open.create(pendingReviews[0]));

            return true;
        }
    }

    if (hasSitter(membershipPlan)) {
        const assignments = yield call(filterAssignments, yield call(fetchSitterPastAssignments));
        const pendingFeedbacks = assignments.filter((assignment) => !assignment.isFeedbackProvided);

        if (pendingFeedbacks.length > 0) {
            yield put(feedbackActions.open.create(pendingFeedbacks[0]));

            return true;
        }
    }

    return false;
}

export function* handleViewedAssignment({ assignment }) {
    if (assignment) {
        yield call(storage.appendAssignment, assignment.id);
    }
}

export function* canOpenModal() {
    if (!(yield select(getHasMembership))) {
        return false;
    }

    if (yield call(checkCurrentPathname)) {
        return false;
    }

    return yield call(hydratePastAssignments);
}

export function* handleTryOpen() {
    if (!(yield call(canOpenModal))) {
        yield put(PromptManager.failure.create());
        return;
    }

    yield put(PromptManager.success.create());
}

export default function* sagas() {
    yield put(
        PromptManager.register.create({
            name: 'leave-review-feedback-modal',
            priority: PromptManager.PRIORITY.HIGH,
            action: actions.tryOpen.ACTION,
            waitFor: {
                days: 3,
            },
        })
    );

    yield all([
        fork(reviewSagas),
        fork(feedbackSagas),
        fork(watchAngularSaga),
        takeLatest(actions.tryOpen.ACTION, handleTryOpen),
        takeLatest(actions.viewedAssignment.ACTION, handleViewedAssignment),
    ]);
}
