import { settings as actions } from '../../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        // Load blog category subcategory info
        case actions.content.loadBlogCategorySubcategory.SUCCESS: {
            const { areaSlug, categorySlug, subcategorySlug } = action.requestData;

            const areaData = state[areaSlug] || {};
            const categoryData = areaData[categorySlug] || {};
            const subcategoryData = categoryData[subcategorySlug] || {};

            return {
                ...state,
                [areaSlug]: {
                    ...areaData,
                    [categorySlug]: {
                        ...categoryData,
                        [subcategorySlug]: {
                            ...subcategoryData,
                            ...action.data,
                        },
                    },
                },
            };
        }
        // Load blog category subcategory posts
        case actions.content.loadBlogCategorySubcategoryPosts.SUCCESS: {
            const {
                areaSlug,
                categorySlug,
                subcategorySlug,
            } = action.requestData;

            const areaData = state[areaSlug] || {};
            const categoryData = areaData[categorySlug] || {};
            const subcategoryData = categoryData[subcategorySlug] || {};
            const subcategoryPosts = subcategoryData.posts || {};

            return {
                ...state,
                [areaSlug]: {
                    ...areaData,
                    [categorySlug]: {
                        ...categoryData,
                        [subcategorySlug]: {
                            ...subcategoryData,
                            posts: {
                                ...subcategoryPosts,
                                ...action.data,
                                page: action.action.filters.page,
                                limit: action.action.filters.limit,
                            },
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};
