export const registerMethod = {
    FACEBOOK: 'facebook',
    GOOGLE: 'google',
    EMAIL: 'email',
};

export const signupOrigin = {
    // Needs to stay as firewall for consistency
    REGWALL: 'firewall',
    JOIN: 'join',
};

export const errorMessages = {
    INVALID_EMAIL: 'INVALID_EMAIL',
    ACCOUNT_ALREADY_CREATED_FACEBOOK: 'apiErrorAuthAccountAlreadyCreatedFacebook',
    ACCOUNT_ALREADY_CREATED_EMAIL: 'apiErrorAuthAccountAlreadyCreatedEmail',
    ACCOUNT_ALREADY_CREATED_GOOGLE: 'apiErrorAuthAccountAlreadyCreatedGoogle',
    AUTH_GOOGLE_INVALID_ACCESS_TOKEN: 'apiErrorAuthGoogleInvalidAccessToken',
    AUTH_GOOGLE_EMAIL_MISSING: 'apiErrorAuthGoogleEmailMissing',
    LOGIN_FAILED: 'loginFailed',
};
