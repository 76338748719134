import sbjs from 'sourcebuster';

// NOTE: this stuff only works on the client, use during SSR will result in weirdness

// Translate the cryptic keys that sbjs uses in to more readable forms
export const sbjsKeyMap = {
    CURRENT_PAGE: 'cpg',
    SESSION_PAGE_COUNT: 'pgs',
    TRAFFIC_TYPE: 'typ',
    UTM_SOURCE: 'src',
    UTM_MEDIUM: 'mdm',
    UTM_CAMPAIGN: 'cmp',
    UTM_CONTENT: 'cnt',
    UTM_TERM: 'trm',
    FULL_DATE: 'fd',
    ENTRY_POINT: 'ep',
    REFERER_URL: 'rf',
};

export const utmMediums = {
    EMAIL: 'email',
    ORGANIC: 'organic',
    FACEBOOKDOTCOM: 'facebook.com',
    FACEBOOK: 'facebook',
};

// sbjs has the concept of "current" and "first". Both have a basic set of
// properties and an additional set which we will just merge and treat as one.
export const getCurrent = () => ({
    ...(sbjs.get && sbjs.get.current ? sbjs.get.current : {}),
    ...(sbjs.get && sbjs.get.current_add ? sbjs.get.current_add : {}),
});

export const getSession = () => (sbjs.get && sbjs.get.session ? sbjs.get.session : {});

// Some helpers for accessing specific properties
export const getEntryPoint = () => getCurrent()[sbjsKeyMap.ENTRY_POINT];
export const getCurrentPage = () => getSession()[sbjsKeyMap.CURRENT_PAGE];
export const getUtmMedium = () => getCurrent()[sbjsKeyMap.UTM_MEDIUM];
export const getTrafficType = () => getCurrent()[sbjsKeyMap.TRAFFIC_TYPE];
export const getSessionPageCount = () => parseInt(getSession()[sbjsKeyMap.SESSION_PAGE_COUNT], 10);
export const getUtmSource = () => getCurrent()[sbjsKeyMap.UTM_SOURCE];

// Some helpers for asking questions about the data
export const userCameFromEmail = () => getUtmMedium() === utmMediums.EMAIL;
export const userCameFromSearch = () => getTrafficType() === utmMediums.ORGANIC;
export const userCameFromFacebook = () =>
    getUtmSource() === utmMediums.FACEBOOK || getUtmSource() === utmMediums.FACEBOOKDOTCOM;

export const userOnEntryPage = () => getSessionPageCount() === 1;
