import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setItem } from '../../universalStorage/actions';
import { getItem } from '../../universalStorage/selectors';
import CookiePolicy from './CookiePolicy';

const mapStateToProps = (state) => {
    const cookiePolicyAccepted = getItem(state, 'cookiePolicyAccepted');

    return {
        cookiePolicyAccepted,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onCookiePolicyAccept: () => {
        dispatch(setItem.create('cookiePolicyAccepted', true));
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CookiePolicy));
