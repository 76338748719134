import { settings } from 'api/actions';
import { userRegistered } from 'containers/forms/Register/actions';
import * as actions from './actions';

const initialState = {
    query: {},
    isLoading: false,
    scrollToResults: false,
    hasRegistered: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.placeLoaded.ACTION:
            return {
                ...state,
                place: {
                    ...action.place,
                },
            };
        case settings.search.loadProfiles.REQUEST: {
            if (action.data.searchType === 'search-profiles-results') {
                const newState = {
                    ...state,
                    hasRegistered: false,
                    query: {
                        ...state.query,
                        'search-profiles-results': {
                            ...state.query['search-profiles-results'],
                            isLoading: true,
                        },
                    },
                };
                return newState;
            }
            return { ...state, hasRegistered: false };
        }
        case settings.search.loadProfiles.SUCCESS: {
            const { searchType, rawQuery, searchLevel } = action.requestData;

            if (rawQuery) {
                const query = {
                    availability: {},
                    ...rawQuery.filters,
                    pageInfo: {
                        currentPage: rawQuery.page,
                        itemsPerPage: rawQuery.resultsPerPage,
                        totalItems: action.data.total,
                    },
                };
                // Sort
                if (rawQuery.sort) {
                    query.sort = rawQuery.sort;
                }
                // Dates
                if (query.availability) {
                    // Default search - both or none of the dates must be set
                    if (
                        searchType === 'default' &&
                        !(query.availability.dateFrom && query.availability.dateTo)
                    ) {
                        if (query.availability.dateFrom) {
                            delete query.availability.dateFrom;
                        }
                        if (query.availability.dateTo) {
                            delete query.availability.dateTo;
                        }
                    }
                }

                // Trust and safety
                const trustAndSafeties = {};
                let hasTrustAndSafeties = false;
                if (query.reviews) {
                    trustAndSafeties.reviews = query.reviews;
                    hasTrustAndSafeties = true;
                    delete query.reviews;
                }
                if (query.verificationLevel) {
                    trustAndSafeties.verificationLevel =
                        query.verificationLevel.length > 0 ? query.verificationLevel : null;
                    hasTrustAndSafeties = true;
                    delete query.verificationLevel;
                }
                if (query.hasExperience) {
                    trustAndSafeties.hasExperience = query.hasExperience;
                    hasTrustAndSafeties = true;
                    delete query.hasExperience;
                }
                if (hasTrustAndSafeties) {
                    query.trustAndSafeties = trustAndSafeties;
                }

                // Family Friendly
                const profileFilters = [];
                if (query.travellingWithChildren) {
                    profileFilters.push('familyFriendly');
                    delete query.travellingWithChildren;
                }
                if (profileFilters.length > 0) {
                    query.profileFilters = profileFilters;
                }

                return {
                    ...state,
                    query: {
                        ...state.query,
                        [searchType]: {
                            ...query,
                            isLoading: false,
                        },
                    },
                    searchLevel,
                };
            }
            return state;
        }
        case userRegistered.ACTION: {
            // only enable scrolling after registering to prevent any side effects
            return {
                ...state,
                scrollToResults: true,
                hasRegistered: true,
            };
        }
        case actions.loadAdditionalProfileData.ACTION: {
            return {
                ...state,
                hasRegistered: false,
            };
        }
        case actions.disableScrolling.ACTION: {
            return {
                ...state,
                scrollToResults: false,
            };
        }
        case actions.sittersLookingInYourAreaVariant.ACTION: {
            return {
                ...state,
                sittersLookingInYourAreaVariant: action.variant,
            };
        }
        default:
            return state;
    }
};
