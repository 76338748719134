import { settings as actions } from '../../actions';

import { baseInitialResultsListState, loadedResultsUniqueListReducer } from '../common';

import { uniqueArray } from '../../helpers/format/objects';

import * as transform from '../../helpers/transform';

export const initialState = { ...baseInitialResultsListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadConfirmedAssignments.SUCCESS:
            return {
                ...initialState,
                results: action.data.current.map((assignment) => assignment.id),
            };
        case actions.owner.loadCurrentAssignments.SUCCESS: {
            return {
                results: action.data.map((assignment) => assignment.id),
            };
        }
        case actions.owner.loadAssignment.SUCCESS: {
            if (transform.isCurrentAssignment(action.data)) {
                if (state.results.indexOf(action.data.id) < 0) {
                    return {
                        ...state,
                        results: uniqueArray([...state.results, action.data.id]),
                    };
                }
            } else if (state.results.indexOf(action.data.id) >= 0) {
                return {
                    ...state,
                    results: state.results.filter((itemId) => itemId !== action.data.id),
                };
            }
            return state;
        }
        default:
            return state;
    }
};
