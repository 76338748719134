import React from 'react';
import { useInView } from 'react-intersection-observer';
import { DefaultImageStyled, ImageStyled, PlaceholderStyled } from './ImageLazyLoad.style';

const ImageLazyLoad = ({ alt, className, src, width, height }) => {
    const [ref, isInView] = useInView({
        triggerOnce: true,
        rootMargin: '15% 0px',
    });

    return (
        <>
            <noscript>
                <DefaultImageStyled
                    src={src}
                    className={className}
                    alt={alt}
                    width={width}
                    height={height}
                />
            </noscript>

            {!isInView && <PlaceholderStyled ref={ref} width={width} height={height} />}

            {isInView && (
                <ImageStyled
                    ref={ref}
                    src={src}
                    inView={isInView}
                    className={className}
                    alt={alt}
                    width={width}
                    height={height}
                />
            )}
        </>
    );
};

export default ImageLazyLoad;
