import {
    Animal,
    LocalAttraction,
    WifiAvailable,
    getSortFunction,
    SORT_FUNCTIONS,
} from 'api/helpers/search/constants';
import { SITS_RESULTS_PER_PAGE } from 'config/search';

export const sitDiscoveryCategory = {
    WIZARD_RESULTS: 'wizardResults',
    CITY_BREAK: 'cityBreak',
    EXOTIC_LOCATIONS: 'exoticLocations',
    SITS_WITH_DOGS: 'sitsWithDogs',
    WORK_FROM_ANYWHERE: 'workFromAnywhere',
    RURAL_RETREAT: 'ruralRetreat',
    LONG_STAYS: 'longStays',
};

const discoverySort = [
    {
        function: getSortFunction(SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED),
    },
];

export const sitDiscoveryCategoryFilters = {
    [sitDiscoveryCategory.CITY_BREAK]: {
        name: sitDiscoveryCategory.CITY_BREAK,
        query: {
            perPage: SITS_RESULTS_PER_PAGE,
            filters: {
                localAttractions: [LocalAttraction.CITY],
                sort: [
                    {
                        function: getSortFunction(
                            SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED_AND_LOCAL_ATTRACTIONS,
                            {
                                localAttractions: [LocalAttraction.CITY],
                            }
                        ),
                    },
                ],
            },
        },
    },
    [sitDiscoveryCategory.EXOTIC_LOCATIONS]: {
        name: sitDiscoveryCategory.EXOTIC_LOCATIONS,
        query: {
            perPage: SITS_RESULTS_PER_PAGE,
            filters: {
                localAttractions: [LocalAttraction.BEACH],
                sort: [
                    {
                        function: getSortFunction(
                            SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED_AND_LOCAL_ATTRACTIONS,
                            {
                                localAttractions: [LocalAttraction.BEACH],
                            }
                        ),
                    },
                ],
            },
        },
    },
    [sitDiscoveryCategory.SITS_WITH_DOGS]: {
        name: sitDiscoveryCategory.SITS_WITH_DOGS,
        query: {
            perPage: SITS_RESULTS_PER_PAGE,
            filters: {
                pets: [
                    {
                        type: Animal.DOG,
                        exclude: false,
                    },
                    {
                        type: Animal.CAT,
                        exclude: true,
                    },
                    {
                        type: Animal.REPTILE,
                        exclude: true,
                    },
                    {
                        type: Animal.HORSE,
                        exclude: true,
                    },
                    {
                        type: Animal.FISH,
                        exclude: true,
                    },
                    {
                        type: Animal.BIRD,
                        exclude: true,
                    },
                    {
                        type: Animal.POULTRY,
                        exclude: true,
                    },
                    {
                        type: Animal.FARMANIMAL,
                        exclude: true,
                    },
                    {
                        type: Animal.SMALLPET,
                        exclude: true,
                    },
                ],
                sort: discoverySort,
            },
        },
    },
    [sitDiscoveryCategory.WORK_FROM_ANYWHERE]: {
        name: sitDiscoveryCategory.WORK_FROM_ANYWHERE,
        query: {
            perPage: SITS_RESULTS_PER_PAGE,
            filters: {
                wifiAvailable: [WifiAvailable.HIGHSPEED],
                sort: discoverySort,
            },
        },
    },
    [sitDiscoveryCategory.RURAL_RETREAT]: {
        name: sitDiscoveryCategory.RURAL_RETREAT,
        query: {
            perPage: SITS_RESULTS_PER_PAGE,
            filters: {
                localAttractions: [LocalAttraction.COUNTRYSIDE, LocalAttraction.MOUNTAIN],
                sort: [
                    {
                        function: getSortFunction(
                            SORT_FUNCTIONS.POPULAR_NEW_SITS_WITH_PAUSED_AND_LOCAL_ATTRACTIONS,
                            {
                                localAttractions: [
                                    LocalAttraction.COUNTRYSIDE,
                                    LocalAttraction.MOUNTAIN,
                                ],
                            }
                        ),
                    },
                ],
            },
        },
    },
    [sitDiscoveryCategory.LONG_STAYS]: {
        name: sitDiscoveryCategory.LONG_STAYS,
        query: {
            perPage: SITS_RESULTS_PER_PAGE,
            filters: {
                assignments: {
                    durationInDays: {
                        minimum: 14,
                    },
                },
                sort: discoverySort,
            },
        },
    },
};
