import { combineReducers } from 'redux';

import messages, { initialState as messageInitialState } from './messages';

export const initialState = {
    messages: messageInitialState,
};

export default combineReducers({
    messages,
});
