// eslint-disable-next-line import/prefer-default-export
export const VIEWED_EXPERIMENTAL_LANDING_PAGE = {
    create(payload) {
        return {
            type: 'Viewed Experimental Landing Page',
            payload: {
                path: payload.path,
                url: payload.url,
                expName: payload.experiment.name,
                expVariant: payload.experiment.variant,
            },
        };
    },
};
