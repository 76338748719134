const shareOptions = Object.freeze({
    EMAIL: 'email',
    WHATSAPP: 'whatsapp',
    COPYLINK: 'copy-link',
    MESSENGER: 'messenger',
});

// eslint-disable-next-line import/prefer-default-export
export const USER_PROFILE_VERIFICATIONS_SHARE_REFERENCE_REQUEST = {
    create({ shareOption }) {
        return {
            type: 'Share Reference Request',
            payload: {
                shareOption,
            },
        };
    },
    shareOptions,
};
