const SQUARE_SMALL = {
    name: 'square_small',
    width: 48 * 2, // 96
    height: 48 * 2, // 96
};

const SQUARE = {
    name: 'square',
    width: 96 * 2, // 128
    height: 96 * 2, // 128
};

const SQUARE_BIG = {
    name: 'square_big',
    width: 128 * 2, // 256
    height: 128 * 2, // 256
};

const SQUARE_BIGGER = {
    name: 'square_bigger',
    width: 256 * 2, // 512
    height: 256 * 2, // 512
};

const FILM_RATIO_SMALL = {
    name: 'film_ratio_small',
    width: 120 * 2, // 240
    height: 80 * 2, // 160
};

const FILM_RATIO = {
    name: 'film_ratio',
    width: 180 * 2, // 360
    height: 120 * 2, // 240
};

const FILM_RATIO_BIG = {
    name: 'film_ratio_big',
    width: 300 * 2, // 600
    height: 200 * 2, // 400
};

const FILM_RATIO_ALT = {
    name: 'film_ratio_alt',
    width: 300 * 2, // 600
    height: 200 * 2, // 400
};

const ORIGINAL_RATIO_SMALL = {
    name: 'original_ratio_small',
    width: 120 * 2, // 240
    height: 80 * 2, // 160
};

const ORIGINAL_RATIO = {
    name: 'original_ratio',
    width: 180 * 2, // 360
    height: 120 * 2, // 240
};

const ORIGINAL_RATIO_BIG = {
    name: 'original_ratio_big',
    width: 300 * 2, // 600
    height: 200 * 2, // 400
};

const GALLERY = {
    name: 'gallery',
};

// This focusses on the middle of the image so no black borders
const SQUARE_520 = {
    name: 'square_520',
};

export {
    SQUARE,
    SQUARE_SMALL,
    SQUARE_BIG,
    SQUARE_BIGGER,
    FILM_RATIO,
    FILM_RATIO_SMALL,
    FILM_RATIO_BIG,
    FILM_RATIO_ALT,
    GALLERY,
    ORIGINAL_RATIO,
    ORIGINAL_RATIO_SMALL,
    ORIGINAL_RATIO_BIG,
    SQUARE_520,
};
