import { settings } from 'api/actions';

export const initialState = {
    createResponseError: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case settings.verifications.createCBCVerification.DONE: {
            return {
                ...state,
                createResponseError: action.statusCode !== 201 && action.data,
            };
        }
        default:
            return state;
    }
};
