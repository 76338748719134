import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import environment from 'environment';
import ZIndexManager from 'components/ZIndexManager';
import { getZendeskOptions } from 'pages/App/selectors';
import {
    matchProfileDetail,
    matchAccountCheckout,
    matchReferAFriend,
    matchInbox,
    matchListingDetail,
} from 'utils/utils';
import { Events, track } from 'utils/analytics';
import { ZendeskButtonWrapperStyled, ZendeskButtonStyled } from './ZendeskWidget.style';

function isOnMobileHideList(pathname) {
    const hideList = [matchProfileDetail(pathname), matchAccountCheckout(pathname)];
    return hideList.some((match) => !!match);
}

function isOnAllSizesHideList(pathname) {
    const hideList = [matchListingDetail(pathname)];
    return hideList.some((match) => !!match);
}

// Script tag has already been created?
const scriptId = 'ze-snippet';

const zendeskScriptLoaded = () => !!document.getElementById(scriptId);

const injectZendeskScript = (zendeskKey, onOpen, onClose) => {
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.onload = () => {
        const VIRTUAL_AGENT_NAME = 'Bot';
        const HUMAN_DEPARTMENT_NAME = 'Customer Support (ALL)';

        window.zE(() => {
            window.$zopim(() => {
                // Once Zendesk/Zopim has loaded hide button
                window.$zopim.livechat.hideAll();
                // But show chat window
                window.zE.activate();
                onOpen();
            });
        });

        window.zE('webWidget:on', 'chat:departmentStatus', (dept) => {
            if (dept.name === VIRTUAL_AGENT_NAME && dept.status === 'online') {
                window.zE('webWidget', 'updateSettings', {
                    webWidget: {
                        chat: {
                            departments: {
                                enabled: [''],
                                select: VIRTUAL_AGENT_NAME,
                            },
                        },
                    },
                });
            } else if (dept.name === VIRTUAL_AGENT_NAME && dept.status !== 'online') {
                window.zE('webWidget', 'updateSettings', {
                    webWidget: {
                        chat: {
                            departments: {
                                enabled: [''],
                                select: HUMAN_DEPARTMENT_NAME,
                            },
                        },
                    },
                });
            }
        });

        window.zE('webWidget:on', 'close', onClose);
    };
    document.head.appendChild(script);
};

const ZendeskWidgetState = {
    OPENING: 'OPENING',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
};

const ZendeskWidget = ({ pathname, optionsProps }) => {
    const { t } = useTranslation();
    const zendeskOptions = useSelector(getZendeskOptions);
    const options = optionsProps || zendeskOptions;
    const zendeskKey = environment.vars.zendesk.key;
    const [state, setState] = useState(ZendeskWidgetState.CLOSED);

    const [isOnMobileHidePage, setIsOnMobileHidePage] = useState(true);
    const [isOnAllScreensHidePage, setIsOnAllScreensHidePage] = useState(true);

    // Pages that hide the "Help" button below medium desktop (1024px)
    const hideBelowMediumDesktop = useMemo(
        () => !!matchInbox(pathname) || !!matchReferAFriend(pathname),
        [pathname]
    );

    const triggerOpen = useCallback(() => {
        if (state !== ZendeskWidgetState.CLOSED) return;

        // Load script if not already present in the DOM
        if (!zendeskScriptLoaded()) {
            setState(ZendeskWidgetState.OPENING);

            track(
                Events.BUTTONCLICK.create({
                    description: 'Help chatbot button clicked',
                })
            );

            injectZendeskScript(
                zendeskKey,
                () => {
                    setState(ZendeskWidgetState.OPEN);
                },
                () => {
                    setState(ZendeskWidgetState.CLOSED);
                }
            );
        } else if (window.zE) {
            track(
                Events.BUTTONCLICK.create({
                    description: 'Help chatbot button clicked',
                })
            );

            // Already loaded so just show chat window
            window.zE.activate();
            setState(ZendeskWidgetState.OPEN);
        }
    }, [state, zendeskKey]);

    useEffect(() => {
        if (pathname) {
            setIsOnMobileHidePage(isOnMobileHideList(pathname));
            setIsOnAllScreensHidePage(isOnAllSizesHideList(pathname));
        }
    }, [pathname]);
    return (
        <ZendeskButtonWrapperStyled
            hideMobilePage={isOnMobileHidePage}
            hideBelowMediumDesktop={hideBelowMediumDesktop}
            hideAllScreensPage={isOnAllScreensHidePage}
        >
            <ZIndexManager layer="tooltip">
                <ZendeskButtonStyled
                    id="ths-zendesk-help-button"
                    isHidden={options?.isHidden ?? false}
                    loading={state !== ZendeskWidgetState.CLOSED}
                    onClick={triggerOpen}
                >
                    {t('components_zendeskWidget_button')}
                </ZendeskButtonStyled>
            </ZIndexManager>
        </ZendeskButtonWrapperStyled>
    );
};

export default ZendeskWidget;
