const BLOG_CAROUSEL_SEARCH_TYPE = 'carousel-blog-posts';

const categories = {
    SITTERS: 'sitters',
    OWNERS: 'owners',
    TRAVEL: 'travel',
    PETS: 'pets',
};

export { BLOG_CAROUSEL_SEARCH_TYPE, categories };
