import { all, put, take, call, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import apiActions, { settings } from 'api/actions';
import * as pageActions from 'containers/Page/actions';
import * as sharedActions from 'shared/actions';
import {
    getAccount,
    getAccountCurrentMembershipPlan,
    getAccountSubscribedMembershipPlan,
} from 'api/selectors';
import { getPlans } from 'api/selectors/plans';
import { PlanTiers } from 'api/types';
import { getPlanVariation, getPlanPricingExperiment } from 'utils/plan';
import { isPartial, isMember, getHasPendingPlanChange } from 'utils/account';
import { getSplit } from 'containers/ExperimentalFeature/selectors';
import { getIsLoggedIn, getStoredPromo } from 'src/shared/selectors';
import { availableCurrencies } from 'utils/currency';
import { batch } from 'utils/sagas';
import { routes } from 'utils/routes';
import { track, Events } from 'utils/analytics';
import { finshedLoadingAccountData } from 'containers/forms/Register/actions';
import * as actions from './actions';
import { getInitialPlanTypeVisible, getPlanTypeVisible, getCurrencyCode } from './selectors';
import { PAGE_IDS } from './constants';

const ANIMALFRIENDS = 'animalfriends';

const isAbleToUsePartnerCode = ({ partnerCode, membershipPlan, isLoggedIn }) => {
    if (partnerCode === ANIMALFRIENDS) {
        // £59 is only visible to anon or partial users
        if (!isLoggedIn) return true;
        if (isPartial(membershipPlan)) return true;
    }
    // Assume that the user should *not* be able to access the partner price variation
    return false;
};

export function* getVariation() {
    // check partnerCode exists first
    const partnerCode = yield select((state) => state.shared && state.shared.partnerCode);

    const account = yield select(getAccount);
    const membershipPlan = yield select(getAccountCurrentMembershipPlan);
    const isLoggedIn = yield select(getIsLoggedIn);

    if (
        isAbleToUsePartnerCode({
            partnerCode,
            membershipPlan,
            isLoggedIn,
        })
    ) {
        return partnerCode;
    }
    // only go and get the test variation if we're not using partner variation
    const split = yield select(getSplit);
    const pricingExperiment = getPlanPricingExperiment(account, split);
    return getPlanVariation(pricingExperiment);
}

export function* loadPlansSwitchDetails(action) {
    const account = yield select(getAccount);
    const isAPaidMember = isMember(account.membershipPlan);
    if (!isAPaidMember) return false;

    let promoCode = yield select(getStoredPromo);
    if (!promoCode && global.sessionStorage) {
        promoCode = JSON.parse(global.sessionStorage.getItem('promo'));
        if (promoCode) {
            yield put(sharedActions.storePromo.create(promoCode));
        }
    }

    const allPlans = yield select(getPlans);

    // Only load switch prices for plans that don't have it already
    const planIdsToFetch = allPlans
        .filter((plan) => action.planIds.includes(plan.id))
        .filter((plan) => plan.switchPrice === null)
        .map((plan) => plan.id);

    // Else don't call the API client
    if (planIdsToFetch.length <= 0) return false;

    yield put(
        apiActions.plans.loadSwitchDetails({
            forceReload: true,
            filters: {
                promo_code: promoCode,
                'plan_ids[]': planIdsToFetch,
            },
        })
    );

    yield take(settings.plans.loadSwitchDetails.DONE);

    return true;
}

// Allows calls for `loadPlansSwitchDetails` to be batched and executed using fewer network requests.
// Use with `batch`
export function* batchedLoadPlansSwitchDetails(loadPlansSwitchDetailsActions) {
    const allPlanIds = loadPlansSwitchDetailsActions
        .map((action) => action.planIds)
        .reduce((a, b) => [...a, ...b], []);

    yield call(loadPlansSwitchDetails, { planIds: allPlanIds });
}

// Load all the plans
export function* loadPlans(maybeCurrencyCode) {
    let currencyCode = maybeCurrencyCode;
    let promoCode = yield select(getStoredPromo);

    if (!promoCode && global.sessionStorage) {
        promoCode = JSON.parse(global.sessionStorage.getItem('promo'));
        if (promoCode) {
            yield put(sharedActions.storePromo.create(promoCode));
        }
    }

    const variation = yield getVariation();

    if (!currencyCode) {
        currencyCode = yield select(getCurrencyCode);
    }

    const tiersToLoad = [PlanTiers.CLASSIC, PlanTiers.BASIC, PlanTiers.STANDARD, PlanTiers.PREMIUM];

    const filters = {
        currency_code: currencyCode,
        promo_code: promoCode,
        variation,
        'tiers[]': tiersToLoad,
    };

    // Sending `promo_code: null` creates a load of redundant API requests
    // https://trustedhousesitters.atlassian.net/browse/GROW-1419
    if (!promoCode) delete filters.promo_code;

    yield put(
        apiActions.plans.load({
            forceReload: true,
            filters: {
                ...filters,
            },
        })
    );

    let loadingPlans = [take(settings.plans.load.DONE)];

    // If it is a paid member, get their renewal amount to fill up the 'YOUR PLAN' card
    const account = yield select(getAccount);
    if (isMember(account.membershipPlan)) {
        yield put(apiActions.account.loadSubscriptionRenewalAmount({ forceReload: true }));
        loadingPlans = [...loadingPlans, take(settings.account.loadSubscriptionRenewalAmount.DONE)];
    }

    // Block until got all the plans (for SSR)
    yield all(loadingPlans);
}

export function* planSelected({ membershipPlan }) {
    const planTypeVisible = yield select(getPlanTypeVisible);
    const isLoggedIn = yield select(getIsLoggedIn);
    const account = yield select(getAccount);
    const currencyCode = yield select(getCurrencyCode);

    if (global.localStorage) {
        global.localStorage.setItem(
            'ths-original-membership-plan',
            JSON.stringify({
                id: membershipPlan.id,
                membershipType: planTypeVisible,
                originalPrice: membershipPlan.originalPrice,
            })
        );
    }

    // If partial then update plan
    if (isLoggedIn) {
        yield put(
            apiActions.account.createIncompleteMembershipPlan({
                forceReload: true,
                data: {
                    promoCode: membershipPlan.promoCode && membershipPlan.promoCode.code,
                    membershipPlanId: membershipPlan.id,
                    userId: account.details.id,
                    currencyCode,
                },
            })
        );

        // Wait for plan to created
        yield take(settings.account.createIncompleteMembershipPlan.DONE);

        // Persist currency to local storage
        const thsLocalCurrency =
            global.localStorage && global.localStorage.getItem('ths-local-currency');
        if (!thsLocalCurrency) {
            // eslint-disable-next-line no-use-before-define
            storeCurrencyInLocalStorage(currencyCode);
        }

        // Reload membership plan
        yield put(
            apiActions.account.loadMembership({
                forceReload: true,
            })
        );

        // TODO: loading state after clicking a "buy plan" button
        const { status: loadMembershipStatus } = yield take(settings.account.loadMembership.DONE);

        if (loadMembershipStatus === settings.account.loadMembership.SUCCESS) {
            yield put(
                push({
                    pathname: routes.accounts.checkout.payment(),
                })
            );
        }
    }
    // If anonymous show the regwall
    else {
        yield put(actions.regwallOpen.create(membershipPlan, membershipPlan.membershipType));
    }
}

// sets the freeTrial in the redux store if there is a free trial promo applied on valid plans
export function* setFreeTrial() {
    const allPlans = yield select(getPlans);
    const account = yield select(getAccount);
    const isAPaidMember = isMember(account.membershipPlan);

    // want to hide visual elements for paid users
    if (!isAPaidMember && allPlans && allPlans.length > 0) {
        // it checks for:
        //   - if there are any values in either freeMonths or freeDays and is not a classic plan
        //   - then returns membership types and tiers from plans that were found with those values
        //   - then filters again to make sure that the values are unique and don't repeat
        const plansWithFreeTrial = allPlans.filter(
            (plan) =>
                ((plan.freeMonths && plan.freeMonths > 0) ||
                    (plan.freeDays && plan.freeDays > 0)) &&
                plan.tier !== PlanTiers.CLASSIC
        );
        const plansWithFreeTrialMembershipType = plansWithFreeTrial
            .map((plan) => plan.membershipType)
            .filter((value, index, self) => self.indexOf(value) === index);
        const plansWithFreeTrialTier = plansWithFreeTrial
            .map((plan) => plan.tier)
            .filter((value, index, self) => self.indexOf(value) === index);

        if (plansWithFreeTrialMembershipType.length > 0 || plansWithFreeTrialTier.length > 0) {
            yield put(
                actions.setFreeTrial.create({
                    membershipTypes: plansWithFreeTrialMembershipType,
                    tiers: plansWithFreeTrialTier,
                })
            );
        }
    }
}

export function* load({ search, type, pageId }) {
    // By the time we get here, we may not have an incomplete membership plan loaded into state - but that doesn't mean
    // the user doesn't have one!
    yield put(apiActions.account.loadIncompleteMembershipPlan({ forceReload: true }));
    // We only wait for done, because the request will fail if we don't have an incomplete plan - but we don't care.
    yield take(settings.account.loadIncompleteMembershipPlan.DONE);

    const isLoggedIn = yield select(getIsLoggedIn);
    if (isLoggedIn) {
        // if the user is already logged in then there is no need to wait for account to finish loading
        // it is only used for when logging via the regwall and then being redirected to the checkout with a selected plan
        // TODO: find a better/cleaner way of doing redirects on the plans page
        yield put(finshedLoadingAccountData.create());

        yield put(
            apiActions.account.loadSubscribedMembershipPlan({
                forceReload: true,
            })
        );
        const { status } = yield take(settings.account.loadSubscribedMembershipPlan.DONE);
        let hasPendingPlanChange = false;
        // Skip checking getHasPendingPlanChange when status is
        // status == settings.account.loadSubscribedMembershipPlan.FAILURE
        // (e.g. when there is no subscribed plan so the API request returned 404)
        if (status === settings.account.loadSubscribedMembershipPlan.SUCCESS) {
            const subscribedMembershipPlan = yield select(getAccountSubscribedMembershipPlan);
            const account = yield select(getAccount);
            hasPendingPlanChange = getHasPendingPlanChange(
                account.membershipPlan,
                subscribedMembershipPlan
            );
        }
        yield put(actions.setHasPendingPlanChange.create(hasPendingPlanChange));
    }

    // get hideTabs from query string
    const { hideTabs } = search;
    // add tabsHidden to the store
    yield put(actions.setHideTabs.create(hideTabs));

    // get membershipType from query string
    const { membershipType } = search;
    // add membershipTypeSelected to the store
    // do this before requesting whichPlanTypeIsVisible
    yield put(actions.setMembershipTypeSelected.create(membershipType));

    // Set this first so it doesn't cause a double load of the switch plans
    const whichPlanTypeIsVisible = yield select(getInitialPlanTypeVisible);
    yield put(actions.setPlanTypeVisible.create(whichPlanTypeIsVisible, false));

    // We opt to always reload plans right now to avoid weird flashes of
    // incorrect content caused by angular pages being able to set currency
    yield call(loadPlans);

    yield call(setFreeTrial);

    // See if plan has been preselected in the url in the "plan" query param
    const planId = search.plan;

    if (planId) {
        const plans = yield select(getPlans);
        const membershipPlan = plans.find((plan) => plan.id === planId) || null;

        // Plan is found so select it
        if (membershipPlan) {
            yield call(planSelected, { membershipPlan });
        }

        // If plan isn't found that's fine we should still create the action with null
        yield put(actions.selectPlanFromUrl.create(membershipPlan));
    } else {
        // Make sure to reset this incase navigating back to this page later
        yield put(actions.selectPlanFromUrl.create(null));
    }

    // If successful then show as loaded
    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(pageId));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(pageId));
    }

    return true;
}

const storeCurrencyInLocalStorage = (currencyCode) => {
    const { currencyName, sign } = availableCurrencies.find(
        (item) => item.currencyCode === currencyCode
    );

    if (global.localStorage) {
        global.localStorage.setItem(
            'ths-local-currency',
            JSON.stringify({ name: currencyName, symbol: sign, currencyCode })
        );
    }
};

export function* currencyChanged({ currencyCode }) {
    // Get the current currency
    const previousCurrencyCode = yield select(getCurrencyCode);
    const isLoggedIn = yield select(getIsLoggedIn);

    // Track change event
    track(Events.CURRENCY_CHANGED.create(currencyCode.key, previousCurrencyCode));

    // for partial users (don't have to worry about paid/expired members since they can't see the currency switcher)
    if (isLoggedIn) {
        yield put(
            apiActions.account.updateIncompleteMembershipPlan({
                forceReload: true,
                data: {
                    currencyCode: currencyCode.key,
                },
            })
        );
    }

    // reload plans
    yield loadPlans(currencyCode.key);

    // Persist in localstorage for Angular
    storeCurrencyInLocalStorage(currencyCode.key);
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) =>
                [
                    pageActions.preload.ACTION,
                    pageActions.load.ACTION, // Always need to handle load actions as this controls visibility of footer
                ].includes(action.type) &&
                [PAGE_IDS.PRICING, PAGE_IDS.EXPLORE, PAGE_IDS.UPGRADE].includes(action.pageId),
            load
        ),
        takeEvery(actions.selectPlan.ACTION, planSelected),
        takeEvery(actions.changeCurrency.ACTION, currencyChanged),
        takeEvery(actions.loadPlans.ACTION, ({ currencyCode }) => loadPlans(currencyCode)),
        // adds a 250ms delay so that on the desktop view (where all of the cards are visible)
        // is then making only one load switch prices request instead of three separate ones
        batch(250, actions.loadPlansSwitchDetails.ACTION, batchedLoadPlansSwitchDetails),
    ]);
}
