import { useContext } from 'react';
import { UserAgentContext } from './UserAgentContext';

const useUserAgent = () => {
    const userAgent = useContext(UserAgentContext);

    if (!userAgent) {
        throw new Error('useUserAgent must be used within UserAgentContextProvider');
    }

    return userAgent.current;
};

export default useUserAgent;
