import ApiConfig from '../config';
import ApiAction from './common';

const config = {
    // Sitter
    load: ['SITTER', 'LOAD', ApiConfig.endpoints.sitter._],

    // Profile
    loadProfile: ['SITTER_PROFILE', 'LOAD', ApiConfig.endpoints.sitter.profile._],
    createProfile: ['SITTER_PROFILE', 'CREATE', ApiConfig.endpoints.sitter.profile._],
    updateProfile: ['SITTER_PROFILE', 'UPDATE', ApiConfig.endpoints.sitter.profile._],

    // Profile media
    loadProfilePhotos: [
        'SITTER_PROFILE_PHOTOS',
        'LOAD',
        ApiConfig.endpoints.sitter.profile.photos._,
    ],
    // Should be CREATE but the v3 api doesn't work so I'm going with v2 which needs UPDATE
    createProfilePhoto: [
        'SITTER_PROFILE_PHOTOS',
        'UPDATE',
        ApiConfig.endpoints.sitter.profile.photos.photo,
    ],
    updateProfilePhoto: [
        'SITTER_PROFILE_PHOTO',
        'UPDATE',
        ApiConfig.endpoints.sitter.profile.photos.photo,
    ],
    removeProfilePhoto: [
        'SITTER_PROFILE_PHOTO',
        'REMOVE',
        ApiConfig.endpoints.sitter.profile.photos.photo,
    ],

    // Applications
    loadApplications: ['SITTER_APPLICATIONS', 'LOAD', ApiConfig.endpoints.sitter.applications._],
    loadApplication: [
        'SITTER_APPLICATION',
        'LOAD',
        ApiConfig.endpoints.sitter.applications.application,
    ],
    createApplication: ['SITTER_APPLICATION', 'CREATE', ApiConfig.endpoints.sitter.applications._],
    createBatchApplications: [
        'SITTER_APPLICATION',
        'CREATE_BATCH',
        ApiConfig.endpoints.sitter.applications.batch,
        [],
        'POST',
    ],
    updateApplication: [
        'SITTER_APPLICATION',
        'UPDATE',
        ApiConfig.endpoints.sitter.applications.application,
    ],
    confirmApplication: [
        'SITTER_APPLICATION',
        'CONFIRM',
        ApiConfig.endpoints.sitter.applications.application,
        [],
        'PUT',
        { hasSitterConfirmed: true },
        null,
    ],
    cancelApplication: [
        'SITTER_APPLICATION',
        'UPDATE',
        ApiConfig.endpoints.sitter.applications.application,
        [],
        'PUT',
        { hasSitterCancelled: true },
        null,
    ],
    declineAssignmentDateChange: [
        'SITTER_ASSIGNMENT_DATE_CHANGE',
        'DECLINE',
        ApiConfig.endpoints.sitter.applications.application,
        [],
        'PUT',
        { hasSitterCancelled: true },
        null,
    ],
    confirmAssignmentDateChange: [
        'SITTER_ASSIGNMENT_DATE_CHANGE',
        'CONFIRM',
        ApiConfig.endpoints.sitter.applications.application,
        [],
        'PUT',
        { hasAssignmentDatesChanged: false },
        null,
    ],
    loadApplicationsWithMessages: [
        'SITTER_APPLICATIONS_MESSAGES',
        'LOAD',
        ApiConfig.endpoints.sitter.applications.messages,
    ],

    declineSitConfirmation: [
        'SITTER_PROFILE_ASSIGNMENT_CONFIRMATION',
        'DECLINE',
        ApiConfig.endpoints.sitter.applications.application,
        [],
        'PUT',
        { hasSitterCancelled: true },
        null,
    ],
    // Listing
    loadSitterOwnerApplications: [
        'SITTER_OWNER_APPLICATIONS',
        'LOAD',
        ApiConfig.endpoints.sitter.owner.applications,
    ],
    loadSitterOwnerInvitations: [
        'SITTER_OWNER_INVITATIONS',
        'LOAD',
        ApiConfig.endpoints.sitter.owner.invitations,
    ],
    loadSitterOwnerListingPets: [
        'SITTER_OWNER_LISTING_PETS',
        'LOAD',
        ApiConfig.endpoints.sitter.owner.listing.pets,
    ],
    loadSitterOwnerListingPlacesOfInterest: [
        'SITTER_OWNER_LISTING_PLACES_OF_INTEREST',
        'LOAD',
        ApiConfig.endpoints.sitter.owner.listing.placesOfInterest,
    ],
    // Invitations
    loadInvitations: [
        'SITTER_INVITATIONS',
        'LOAD',
        ApiConfig.endpoints.sitter.invitations._,
        [],
        'GET',
        { isInvited: true },
        null,
    ],
    loadInvitation: [
        'SITTER_INVITATION',
        'LOAD',
        ApiConfig.endpoints.sitter.invitations.invitation,
    ],
    declineInvitation: [
        'SITTER_INVITATION',
        'DECLINE',
        ApiConfig.endpoints.sitter.invitations.invitation,
        [],
        'PUT',
        { hasSitterDeclined: true },
        null,
    ],
    confirmInvitation: [
        'SITTER_INVITATION',
        'CONFIRM',
        ApiConfig.endpoints.sitter.invitations.invitation,
        [],
        'PUT',
        { isInvitationAcknowledged: true },
        null,
    ],

    // Assignment
    loadAssignment: [
        'SITTER_ASSIGNMENT',
        'LOAD',
        ApiConfig.endpoints.sitter.assignments.assignment._,
    ],
    // Welcome Guide
    loadAssignmentWelcomeGuide: [
        'SITTER_ASSIGNMENT_WELCOME_GUIDE',
        'LOAD',
        ApiConfig.endpoints.sitter.assignments.assignment.welcomeGuide,
    ],
    requestAssignmentWelcomeGuide: [
        'SITTER_ASSIGNMENT_WELCOME_GUIDE',
        'REQUEST',
        ApiConfig.endpoints.sitter.assignments.assignment.welcomeGuideRequest,
        [],
        'POST',
        null,
        null,
        {
            minutes: 1,
        },
    ],

    // Confirmed Assignments
    loadConfirmedAssignments: [
        'SITTER_CONFIRMED_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.sitter.assignments.confirmed._,
    ],

    // Current assignments
    loadCurrentAssignments: [
        'SITTER_CURRENT_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.sitter.assignments.confirmed.current._,
    ],
    // Upcoming assignments
    loadUpcomingAssignments: [
        'SITTER_UPCOMING_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.sitter.assignments.confirmed.upcoming._,
    ],
    // Past Assignments
    loadPastAssignments: [
        'SITTER_PAST_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.sitter.assignments.confirmed.past._,
    ],
    loadPastAssignmentFeedback: [
        'SITTER_PAST_ASSIGNMENT_FEEDBACK',
        'LOAD',
        ApiConfig.endpoints.sitter.assignments.confirmed.past.feedback,
    ],
    createPastAssignmentFeedback: [
        'SITTER_PAST_ASSIGNMENT_FEEDBACK',
        'CREATE',
        ApiConfig.endpoints.sitter.assignments.confirmed.past.feedback,
    ],
    createPastAssignmentReviewRequest: [
        'SITTER_PAST_ASSIGNMENT_REVIEW_REQUEST',
        'CREATE',
        ApiConfig.endpoints.sitter.assignments.confirmed.past.reviewRequest,
    ],
    updatePastAssignmentReview: [
        'SITTER_PAST_ASSIGNMENT_REVIEW',
        'UPDATE',
        ApiConfig.endpoints.sitter.assignments.confirmed.past.review,
    ],

    // Sitter Favourite Profiles
    loadFavourites: ['SITTER_FAVOURITES', 'LOAD', ApiConfig.endpoints.sitter.favourites._],
    createFavourite: [
        'SITTER_FAVOURITES',
        'CREATE',
        ApiConfig.endpoints.sitter.favourites._,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    loadFavourite: ['SITTER_FAVOURITE', 'LOAD', ApiConfig.endpoints.sitter.favourites.favourite],
    removeFavourite: [
        'SITTER_FAVOURITE',
        'REMOVE',
        ApiConfig.endpoints.sitter.favourites.favourite,
        [],
        'DELETE',
        null,
        null,
        {
            seconds: 2,
        },
    ],

    // Availabilities
    loadAvailabilities: [
        'SITTER_AVAILABILITIES',
        'LOAD',
        ApiConfig.endpoints.sitter.profile.availabilities._,
    ],
    createAvailability: [
        'SITTER_AVAILABILITIES',
        'CREATE',
        ApiConfig.endpoints.sitter.profile.availabilities._,
    ],
    updateAvailability: [
        'SITTER_AVAILABILITY',
        'UPDATE',
        ApiConfig.endpoints.sitter.profile.availabilities.availability,
    ],
    removeAvailability: [
        'SITTER_AVAILABILITY',
        'REMOVE',
        ApiConfig.endpoints.sitter.profile.availabilities.availability,
    ],

    // Conversation
    loadConversations: ['SITTER_CONVERSATIONS', 'LOAD', ApiConfig.endpoints.sitter.conversations._],
    createConversation: [
        'SITTER_CONVERSATION',
        'CREATE',
        ApiConfig.endpoints.sitter.conversations._,
        [],
        'POST',
        null,
        null,
        {
            seconds: 1,
        },
    ],
    archiveConversations: [
        'SITTER_CONVERSATION',
        'ARCHIVE',
        ApiConfig.endpoints.sitter.conversations._,
        [],
        'PUT',
        {
            hasHousesitterArchived: true,
        },
        null,
        {
            seconds: 1,
        },
    ],
    batchArchiveConversations: [
        'SITTER_CONVERSATION',
        'BATCH_ARCHIVE',
        ApiConfig.endpoints.sitter.conversations.batch,
        [],
        'PUT',
        {
            hasHousesitterArchived: true,
        },
        null,
        {
            seconds: 1,
        },
    ],
    unArchiveConversations: [
        'SITTER_CONVERSATION',
        'UNARCHIVE',
        ApiConfig.endpoints.sitter.conversations._,
        [],
        'PUT',
        {
            hasHousesitterArchived: false,
        },
        null,
        {
            seconds: 1,
        },
    ],
    batchUnArchiveConversations: [
        'SITTER_CONVERSATION',
        'BATCH_UNARCHIVE',
        ApiConfig.endpoints.sitter.conversations.batch,
        [],
        'PUT',
        {
            hasHousesitterArchived: false,
        },
        null,
        {
            seconds: 1,
        },
    ],
    deleteConversations: [
        'SITTER_CONVERSATIONS',
        'UPDATE',
        ApiConfig.endpoints.sitter.conversations._,
        [],
        'PUT',
        {
            hasHousesitterDeleted: true,
        },
        null,
        {
            seconds: 1,
        },
    ],
    batchDeleteConversations: [
        'SITTER_CONVERSATIONS',
        'UPDATE',
        ApiConfig.endpoints.sitter.conversations.batch,
        [],
        'PUT',
        {
            hasHousesitterDeleted: true,
        },
        null,
        {
            seconds: 1,
        },
    ],

    // Reviews
    loadReviews: ['SITTER_REVIEWS', 'LOAD', ApiConfig.endpoints.sitter.reviews._],
    loadMoreReviews: ['SITTER_MORE_REVIEWS', 'LOAD', ApiConfig.endpoints.sitter.reviews._],

    // ?????
    loadFeedbacks: ['SITTER_FEEDBACKS', 'LOAD', ApiConfig.endpoints.sitter.feedbacks],
    loadProfileVerifications: [
        'SITTER_PROFILE_VERIFICATIONS',
        'LOAD',
        ApiConfig.endpoints.sitter.profile.verifications,
    ],

    // Pets cared for in the past
    loadPets: ['SITTER_PETS', 'LOAD', ApiConfig.endpoints.sitter.pets._],
    createPet: [
        'SITTER_PET',
        'CREATE',
        ApiConfig.endpoints.sitter.pets._,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    updatePet: ['SITTER_PET', 'UPDATE', ApiConfig.endpoints.sitter.pets.pet],
    removePet: [
        'SITTER_PET',
        'REMOVE',
        ApiConfig.endpoints.sitter.pets.pet,
        [],
        'DELETE',
        null,
        null,
        {
            seconds: 2,
        },
    ],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
