import React, { createContext, useRef, ComponentProps } from 'react';
import UserAgent from './UserAgent';

export const UserAgentContext = createContext(undefined);

const UserAgentContextProvider = ({ header, children }) => {
    const instance = useRef(new UserAgent(header));

    return <UserAgentContext.Provider value={instance}>{children}</UserAgentContext.Provider>;
};

export default UserAgentContextProvider;
