import styled, { css } from 'styled-components';
import {
    IconReview,
    IconReviewOutline,
    IconFeedback,
    IconFeedbackOutline,
    IconReference,
    IconReferenceOutline,
} from 'components/Icon';
import { SizeVariant } from './Rating.constants';

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: row;
`;

const IconWrapper = styled.div`
    margin-right: ${({ theme, isLast }) => (isLast ? theme.spacing[1] : null)};
`;

const ratingIconSize = {
    [SizeVariant.SMALL]: css`
        font-size: 0.9375em; // 15
    `,
    [SizeVariant.DEFAULT]: css`
        font-size: 1.125em; // 18
    `,
};

const iconStyles = css`
    /* Override default with styling for the variant */
    ${({ size }) => ratingIconSize[size]};
`;

const IconFeedbackStyled = styled(IconFeedback).attrs({
    palette: 'primaryRegular',
})`
    ${iconStyles};
`;

const IconFeedbackOutlineStyled = styled(IconFeedbackOutline).attrs({
    palette: 'yellow',
})`
    ${iconStyles};
`;

const IconReviewStyled = styled(IconReview).attrs({
    palette: 'yellow',
})`
    ${iconStyles};
`;

const IconReviewOutlineStyled = styled(IconReviewOutline).attrs({
    palette: 'yellow',
})`
    ${iconStyles};
`;

const IconReferenceStyled = styled(IconReference).attrs({
    palette: 'yellow',
})`
    ${iconStyles};
`;

const IconReferenceOutlineStyled = styled(IconReferenceOutline).attrs({
    palette: 'yellow',
})`
    ${iconStyles};
`;

export {
    IconWrapper,
    IconFeedbackStyled,
    IconFeedbackOutlineStyled,
    IconReviewStyled,
    IconReviewOutlineStyled,
    IconReferenceStyled,
    IconReferenceOutlineStyled,
    WrapperStyled,
};
