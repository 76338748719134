import styled, { css } from 'styled-components';
import ButtonIcon from 'components/buttons/ButtonIcon';
import Input from '../Input';

export const ContainerStyled = styled.div(
    () => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        /*
        * this is to deal with when the button overflows outside the form container
        * which happens when a chip inside the input is very long
        */
        min-width: 1%;
    `
);

export const TextContainerStyled = styled.div(
    ({ hasErrored, theme: { fontSizes, borderRadius, spacing, palette } }) => css`
        font-size: ${fontSizes.medium};
        border: ${hasErrored ? `solid 1px ${palette.danger}` : `solid 1px ${palette.borderDark}`};
        border-radius: ${borderRadius.small};
        /* 11px is used to make the text box has height of 48px */
        padding: 11px ${spacing[2]};
        min-height: 48px;
        max-height: 206px;
        overflow-y: auto;
        overflow-x: hidden;
    `
);

export const TextBoxStyled = styled(Input)(
    ({ theme: { spacing, palette } }) => css`
        outline: none;
        padding: 0 ${spacing[1]};
        white-space: nowrap;
        color: ${palette.grey[700]};
        border: none;
        height: auto;
    `
);

export const ChipsContainerStyled = styled.div(
    ({ theme: { spacing } }) => css`
        display: inline-block;
        user-select: none;
        margin: 0 ${spacing[0.5]};
        white-space: break-spaces;
        max-width: 100%;
    `
);

export const ChipStyled = styled.span(
    ({ theme: { borderRadius, palette, spacing } }) => css`
        line-height: 1.5;
        border-radius: ${borderRadius.small};
        background-color: ${palette.grey[200]};
        color: ${palette.grey[700]};
        margin-right: ${spacing[1]};
        margin-bottom: ${spacing[1]};
        display: inline-flex;
        min-width: 0;
        max-width: 100%;
    `
);

export const ChipLabelStyled = styled.span(
    ({ theme: { spacing } }) => css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-left: ${spacing[1]};
    `
);

export const ChipDeleteButtonStyled = styled(ButtonIcon)(
    ({ theme: { spacing, palette } }) => css`
        padding-left: ${spacing[2]};
        padding-right: ${spacing[1]};
        line-height: 1.5;
        flex-shrink: 0;
        outline: none;
        && svg {
            fill: ${palette.grey[600]};
            width: 12px;
            height: 12px;
        }
    `
);
