import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        // Search
        case actions.search.loadListings.SUCCESS: {
            const { facetsOnly = false } = action.requestData;
            if (facetsOnly) {
                return state;
            }

            return {
                ...state,
                ...action.data.results.reduce((listings, listing) => {
                    listings[listing.id] = transform.getListing({
                        ...(state[listing.id] || {}),
                        ...listing,
                    });
                    return listings;
                }, {}),
            };
        }
        case actions.search.loadListing.SUCCESS:
            return {
                ...state,
                [action.data.id]: transform.getListing({
                    ...(state[action.data.id] || {}),
                    ...action.data,
                }),
            };
        case actions.search.loadListingsFavourites.SUCCESS:
            return {
                ...state,
                ...action.data.results.reduce((listings, listing) => {
                    listings[listing.id] = transform.getListing({
                        ...(state[listing.id] || {}),
                        ...listing,
                    });
                    return listings;
                }, {}),
            };
        // Favourites
        // case actions.sitter.loadFavourites.SUCCESS: {
        //     const listings = {};
        //     action.data.results.forEach((listing) => {
        //         listings[listing.id] = transform.getListing({
        //             ...(state[listing.id] || {}),
        //             ...listing,
        //         });
        //     });
        //     return {
        //         ...state,
        //         ...listings,
        //     };
        // }
        // case actions.sitter.loadFavourite.SUCCESS: {
        //     return {
        //         ...state,
        //         [action.data.id]: transform.getListing({
        //             ...(state[action.data.id] || {}),
        //             ...action.data,
        //         }),
        //     };
        // }
        default:
            return state;
    }
};
