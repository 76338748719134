import { all, fork } from 'redux-saga/effects';

import app from './App/sagas';
import blog from './blog/sagas';
import page from './Page/sagas';
import search from './search/sagas';
import sitemap from './Sitemap/sagas';
import privacyPolicy from './policies/PrivacyPolicy/sagas';
import verifications from './user/Verifications/sagas';
import editListing from './user/listing/edit/sagas';
import editListingHub from './user/listing/edit/Hub/sagas';
import editListingPets from './user/listing/edit/Pets/sagas';
import editListingDates from './user/listing/edit/Dates/sagas';
import editListingLocation from './user/listing/edit/Location/sagas';
import editListingListingPreview from './user/listing/edit/ListingPreview/sagas';
import editProfile from './user/profile/edit/sagas';
import editProfileExperience from './user/profile/edit/Experience/sagas';
import editProfileHub from './user/profile/edit/Hub/sagas';
import welcomeGuidePrint from './user/listing/welcome-guide/Print/sagas';
import settingsRefund from './user/settings/membership/Refund/sagas';
import savedYourListing from './user/saved/SavedYourListing/sagas';
import dashboard from './accounts/Dashboard/sagas';

export default function* sagas() {
    yield all([
        fork(app),
        fork(blog),
        fork(dashboard),
        fork(page),
        fork(search),
        fork(sitemap),
        fork(privacyPolicy),
        fork(verifications),
        fork(editListing),
        fork(editListingHub),
        fork(editListingPets),
        fork(editListingDates),
        fork(editListingLocation),
        fork(editListingListingPreview),
        fork(editProfile),
        fork(editProfileExperience),
        fork(editProfileHub),
        fork(welcomeGuidePrint),
        fork(settingsRefund),
        fork(savedYourListing),
    ]);
}
