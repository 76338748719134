import { isToday, isFuture } from 'date-fns';
import { parseDate } from 'api/helpers/format/date';
import { getRatingAverage } from 'api/helpers/transform/ratings';
import environment from 'environment';
import { features, isFeatureEnabled } from 'components/Feature';
import { getCloudinaryImageUrl } from 'utils/integrations/cloudinary';
import { SQUARE_BIGGER } from 'config/images';

const defaultAvatarImagePath = environment?.vars?.profiles?.defaultImage;

/**
 * @param {string | Date | null} date
 * @return {boolean}
 */
export function isReviewWindowClosed(date) {
    if (!isFeatureEnabled({ name: features.BLIND_REVIEWS })) {
        return false;
    }

    if (!date) {
        return false;
    }

    const parsedDate = typeof date === 'string' ? parseDate(date) : date;

    return !isToday(parsedDate) && !isFuture(parsedDate);
}

/**
 * @param {Object | null} review
 * @return {number | null}
 */
export function calculateReviewRating(review) {
    if (!review) {
        return null;
    }

    if (typeof review.overallScore === 'number') {
        return review.overallScore;
    }

    return Math.round(getRatingAverage(review));
}

/**
 * @param {Object | null} feedback
 * @return {number | null}
 */
export function calculateFeedbackRating(feedback) {
    if (!feedback) {
        return null;
    }

    return feedback.recommended;
}

export function hasReview(assignment) {
    return (assignment?.review ?? null) !== null;
}

export function isReviewProvided(assignment) {
    return assignment?.isReviewProvided ?? false;
}

export function isReviewPublished(assignment) {
    return assignment?.review?.isPublished ?? false;
}

export function hasFeedback(assignment) {
    return (assignment?.feedback ?? null) !== null;
}

export function isFeedbackProvided(assignment) {
    return assignment?.isFeedbackProvided ?? false;
}

export function isFeedbackPublished(assignment) {
    return assignment?.feedback?.isPublished ?? false;
}

export function canLeaveReview(assignment) {
    if (!assignment) {
        return false;
    }

    if (assignment.review !== null) {
        return false;
    }

    if (!assignment.profile?.user?.isActive) {
        return false;
    }

    if (isReviewWindowClosed(assignment.reviewWindowEndDate)) {
        return false;
    }

    return true;
}

export function reviewRequested(assignment) {
    return (assignment?.reviewRequest ?? null) !== null;
}

export function feedbackRequested(assignment) {
    return (assignment?.feedbackRequest ?? null) !== null;
}

export function reviewRating(assignment) {
    if (!assignment) {
        return null;
    }

    if (!assignment.review) {
        return null;
    }

    if (typeof assignment.review.overallScore === 'number') {
        return assignment.review.overallScore;
    }

    return Math.round(getRatingAverage(assignment.review));
}

export function feedbackRating(assignment) {
    if (!assignment) {
        return null;
    }

    if (!assignment.feedback) {
        return null;
    }

    return assignment.feedback.recommended;
}

export function sitterProfilePhoto(assignment) {
    if (typeof assignment.profile?.user?.profilePhoto?.publicId === 'string') {
        return getCloudinaryImageUrl(assignment.profile.user.profilePhoto.publicId);
    }

    return environment.vars.profiles.defaultImage;
}

export function sitterPhoto(assignment) {
    if (assignment?.profile.photos?.length > 0) {
        const photo = assignment.profile.photos[0];

        if (typeof photo.publicId === 'string') {
            return getCloudinaryImageUrl(photo.publicId);
        }
    }

    return environment.vars.profiles.defaultImage;
}
export function sitterIsActive(assignment) {
    return Boolean(assignment?.profile?.user?.isActive);
}

export const getAvatarImage = (publicId) =>
    getCloudinaryImageUrl(publicId, SQUARE_BIGGER.name, '', defaultAvatarImagePath);
