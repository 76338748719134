import { dateAfter, dateBefore, dateSameOrAfter, dateSameOrBefore } from '../format/date';
import { Animal } from '../search/constants';

export const isConfirmedAssignment = (assignment) => {
    if (!assignment) return false;
    const { profile, profileId, isConfirmed } = assignment;
    return isConfirmed || profileId || (profile && profile.id);
};

/**
 * Is open assignment
 * @param assignment
 * @returns {boolean}
 */
export const isOpenAssignment = (assignment) => {
    if (!assignment) return false;
    const { startDate } = assignment;
    return !isConfirmedAssignment(assignment) && dateAfter(startDate, new Date().setHours(0, 0, 0, 0));
};

/**
 * Is open assignment or starts 'today'
 * @param assignment
 * @returns {boolean}
 */
export const isOpenOrLastMinuteAssignment = (assignment) => {
    if (!assignment) return false;
    const { startDate } = assignment;
    return !isConfirmedAssignment(assignment) && dateSameOrAfter(startDate, new Date().setHours(0, 0, 0, 0));
};

/**
 * Is open, published assignment
 * @param assignment
 * @returns {boolean}
 */
export const isOpenPublishedAssignment = (assignment) => {
    if (!assignment) return false;
    const { isReviewing, isPrivate } = assignment;
    return isOpenOrLastMinuteAssignment(assignment) && !isReviewing && !isPrivate;
};


/**
 * Is deleted assignment
 * @param assignment
 * @returns {boolean}
 */
export const isDeletedAssignment = (assignment) => {
    if (!assignment) return false;
    const { isDeleted } = assignment;
    return isDeleted;
};

/**
 * Is past assignments
 * @param assignment
 * @returns {boolean}
 */
export const isPastAssignment = (assignment) => {
    if (!assignment) return false;
    const { endDate } = assignment;
    return isConfirmedAssignment(assignment) && dateBefore(endDate, new Date().setHours(0, 0, 0, 0));
};


/**
 * Is current assignments
 * @param assignment
 * @returns {boolean}
 */
export const isCurrentAssignment = (assignment) => {
    if (!assignment) return false;
    const { startDate, endDate } = assignment;
    return isConfirmedAssignment(assignment)
        && dateSameOrAfter(new Date().setHours(0, 0, 0, 0), startDate)
        && dateSameOrBefore(new Date().setHours(0, 0, 0, 0), endDate)
    ;
};


/**
 * Is upcoming assignments
 * @param assignment
 * @returns {boolean}
 */
export const isUpcomingAssignment = (assignment) => {
    if (!assignment) return false;
    const { startDate } = assignment;
    return isConfirmedAssignment(assignment) && dateAfter(startDate, new Date().setHours(0, 0, 0, 0));
};

/**
 * Animal mapping
 * @param slug
 * @returns {*}
 */
export const animalMapping = (slug) => {
    const oldNewMap = {
        dogs: Animal.DOG,
        cats: Animal.CAT,
        birds: Animal.BIRD,
        reptiles: Animal.REPTILE,
        horses: Animal.HORSE,
        poultry: Animal.POULTRY,
        fish: Animal.FISH,
        'small-pets': Animal.SMALLPET,
        'farm-animals': Animal.FARMANIMAL,

        Dog: Animal.DOG,
        Cat: Animal.CAT,
        Bird: Animal.BIRD,
        Reptile: Animal.REPTILE,
        Horse: Animal.HORSE,
        Poultry: Animal.POULTRY,
        Fish: Animal.FISH,
        'Small pet': Animal.SMALLPET,
        'Farm animal': Animal.FARMANIMAL,
    };
    if (slug in oldNewMap) {
        return oldNewMap[slug];
    }
    return slug;
};

/**
 * Old front end driven data transformed to keywords
 * @param keyword
 * @returns {*}
 */
export const commonKeywordMapping = (keyword) => {
    if (!keyword) return keyword;

    const oldNewMap = {
        balcony: 'balcony',
        basic: 'basic',
        bathtub: 'bathtub',
        'communal gardens': 'communalGardens',
        cooker: 'cooker',
        countryside: 'countrySide',
        dishwasher: 'dishwasher',
        electric: 'electric',
        garden: 'garden',
        gas: 'gas',
        good: 'good',
        'high speed': 'highSpeed',
        hob: 'hob',
        iron: 'iron',
        jacuzzi: 'jacuzzi',
        microwave: 'microwave',
        moderate: 'moderate',
        no: 'no',
        'non-existent': 'nonExistent',
        none: 'none',
        'outdoor drying space': 'outdoorDryingSpace',
        other: 'other',
        patio: 'patio',
        'pet friendly': 'petFriendly',
        pool: 'pool',
        'poor on some networks': 'poorOnSomeNetworks',
        singlePerson: 'single',
        terrace: 'terrace',
        'tumble dryer': 'tumbleDryer',
        'vacuum cleaner': 'vacuumCleaner',
        yes: 'yes',
        'washing machine': 'washingMachine',
        // Pet
        calm: 'calm',
        happy: 'happy',
        excited: 'excited',
        lovable: 'lovable',
        demanding: 'demanding',
        independent: 'independent',
        aloof: 'aloof',
        worried: 'worried',
        'people friendly': 'peopleFriendly',
        friendly: 'friendly',
        'child friendly': 'childFriendly',
        confident: 'confident',
        nervous: 'nervous',
        'totally chilled': 'totallyChilled',
    };

    if (keyword.toLowerCase() in oldNewMap) {
        return oldNewMap[keyword.toLowerCase()];
    }
    return keyword;
};

/**
 * Parse message object
 * @param message
 * @returns {*}
 */
export const parseMessage = message => message;
