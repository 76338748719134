import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isBasic as isPlanBasic, isClassic as isPlanClassic } from 'utils/account';
import Link from 'components/Link';
import { track, Events } from 'utils/analytics';
import { availableCurrencies } from 'config/currency';
import Spinner from 'components/Spinner';
import { ADDONS } from './components/AddonsCheckout/AddonsCheckout.constants';
import AddonsCheckout from './components';
import {
    NoExtraCover,
    ModalTitle,
    IconCancellationInsuranceStyled,
    IconPremiumStyled,
    ModalSpinnerWrapper,
    ModalSubTitle,
    ModalSubTitleBold,
    FeatureCardWrapper,
    FeatureCard,
    CardHeader,
    CardTitle,
    CardText,
    CardButtonStyled,
    UpgradeBody,
    UpgradeCard,
    UpgradeHead,
    PremiumMembershipBadge,
    ModalFooter,
} from './AddonOffers.style';

export const ADDON_OFFERS_TESTIDS = {
    addonOffersModal: 'addonOffersModal',
    addonsOffersNotNowCta: 'addonsOffersNotNowCta',
    addonsOffersUpgradeCta: 'addonsOffersUpgradeCta',
};

const trackButtonClick = (description) => {
    track(
        Events.BUTTONCLICK.create({
            description,
        })
    );
};

const ProductCardComponent = ({ product, currencyCode, onProductSelected }) => {
    const { t } = useTranslation();
    return (
        <FeatureCard>
            <CardHeader>
                <CardTitle>{t(ADDONS[product].products[0].title)}</CardTitle>
            </CardHeader>

            <CardText>{t(ADDONS[product].products[0].subtitle)}</CardText>

            <CardButtonStyled
                variant={Link.Variant.Button.SECONDARY_PROCEED}
                onClick={() => {
                    trackButtonClick(
                        `Add Sit Cancellation for ${availableCurrencies[currencyCode].sign}${ADDONS[product].products[0][currencyCode]}`
                    );
                    onProductSelected(ADDONS[product]);
                }}
            >
                {t('fake_door_modal_card_add_for_cta', {
                    price: `${availableCurrencies[currencyCode].sign}${ADDONS[product].products[0][currencyCode]}`,
                })}
            </CardButtonStyled>
        </FeatureCard>
    );
};

const UpgradeCardComponent = ({
    product,
    currencyCode,
    isBasicOrClassic,
    premiumPlan,
    membershipType,
    onUpgradeSelected,
}) => {
    const { t } = useTranslation();

    return (
        <UpgradeCard>
            <UpgradeHead>{t('fake_door_modal_best_value')}</UpgradeHead>

            <UpgradeBody>
                <PremiumMembershipBadge>
                    <IconPremiumStyled palette="yellow" />
                    {t('transaction_scaler_modal_premium_membership_label')}
                </PremiumMembershipBadge>
                <CardHeader>
                    <CardTitle>
                        {t('transaction_scaler_modal_addon_upgrade_cancelation_cover')}
                    </CardTitle>
                </CardHeader>

                {ADDONS[product].products.map((item) => (
                    <CardText key={item.description}>
                        {'+ '}
                        {t(item.description)}
                    </CardText>
                ))}

                {isBasicOrClassic && (
                    <CardText key={ADDONS[product].basicClassicProduct.description}>
                        {'+ '}
                        {t(ADDONS[product].basicClassicProduct.description)}
                    </CardText>
                )}
                <CardButtonStyled
                    data-testid={ADDON_OFFERS_TESTIDS.addonsOffersUpgradeCta}
                    variant={Link.Variant.Button.PRIMARY}
                    onClick={() => {
                        trackButtonClick(
                            `Upgrade for ${availableCurrencies[currencyCode].sign}${premiumPlan.switchPrice}`
                        );
                        onUpgradeSelected({
                            membershipPlan: premiumPlan,
                            currencyCode,
                            membershipType,
                        });
                    }}
                >
                    {t('fake_door_modal_card_upgrade_for_cta', {
                        price: `${availableCurrencies[currencyCode].sign}${premiumPlan.switchPrice}`,
                    })}
                </CardButtonStyled>
            </UpgradeBody>
        </UpgradeCard>
    );
};

const AddonOffers = ({
    membership,
    closeModal,
    isOwnerConfirmation,
    loadPremiumPlan,
    premiumPlan,
    selectUpgrade,
    openCheckout,
}) => {
    const isBasicOrClassic = isPlanBasic(membership) || isPlanClassic(membership);
    const isSitter = !isOwnerConfirmation;
    const [selectedProduct, setSelectedProduct] = useState(
        openCheckout ? ADDONS.OWNER_SIT_CANCELATION : null
    );
    const { currencyCode } = membership;
    const { t } = useTranslation();
    useEffect(() => {
        if (loadPremiumPlan && !openCheckout) {
            track(Events.VIEWED_FAKE_DOOR_SIT_CONFIRMATION.create());
            loadPremiumPlan();
        }
    }, [loadPremiumPlan, openCheckout]);

    return (
        <>
            {!selectedProduct ? (
                <>
                    {!premiumPlan ? (
                        <ModalSpinnerWrapper>
                            <Spinner />
                        </ModalSpinnerWrapper>
                    ) : (
                        <>
                            <ModalTitle data-testid={ADDON_OFFERS_TESTIDS.addonOffersModal}>
                                <IconCancellationInsuranceStyled />{' '}
                                {t('fake_door_modal_add_sit_cancellation')}
                            </ModalTitle>
                            <ModalSubTitle>
                                {isSitter ? (
                                    <Trans
                                        i18nKey="transaction_scaler_modal_addon_sitter_cancelation_cover_description"
                                        components={{
                                            bold: <ModalSubTitleBold />,
                                        }}
                                    />
                                ) : (
                                    <Trans
                                        i18nKey="transaction_scaler_modal_addon_owner_cancelation_cover_description"
                                        components={{
                                            bold: <ModalSubTitleBold />,
                                        }}
                                    />
                                )}
                            </ModalSubTitle>

                            <FeatureCardWrapper>
                                <ProductCardComponent
                                    product={
                                        isSitter
                                            ? 'SITTER_SIT_CANCELATION'
                                            : 'OWNER_SIT_CANCELATION'
                                    }
                                    currencyCode={currencyCode}
                                    onProductSelected={setSelectedProduct}
                                />

                                <UpgradeCardComponent
                                    closeModal={closeModal}
                                    isBasicOrClassic={isBasicOrClassic}
                                    currencyCode={currencyCode}
                                    selectUpgrade={selectUpgrade}
                                    product={isSitter ? 'SITTER_PACKAGE' : 'OWNER_PACKAGE'}
                                    membershipType={membership.membershipType}
                                    premiumPlan={premiumPlan}
                                    onUpgradeSelected={(upgrade) => {
                                        selectUpgrade(upgrade);
                                        closeModal();
                                    }}
                                />
                            </FeatureCardWrapper>

                            <ModalFooter>
                                <NoExtraCover
                                    data-testid={ADDON_OFFERS_TESTIDS.addonsOffersNotNowCta}
                                    onClick={() => {
                                        trackButtonClick("I don't need any extra cover");
                                        closeModal();
                                    }}
                                >
                                    {t('fake_door_not_now_cta')}
                                </NoExtraCover>
                            </ModalFooter>
                        </>
                    )}
                </>
            ) : (
                <AddonsCheckout
                    addons={selectedProduct}
                    currency={currencyCode}
                    onStepBackClicked={setSelectedProduct}
                    closeModal={closeModal}
                    openedFromDashboard={openCheckout}
                    membership={membership}
                />
            )}
        </>
    );
};

export default AddonOffers;
