import { savedSearchLoadDone } from './actions';

const defaultState = {
    searchQuery: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case savedSearchLoadDone.ACTION:
            return {
                ...state,
                searchQuery: action.searchQuery,
                savedSearchName: action.savedSearchName,
            };
        default:
            return state;
    }
};
