import React, { PureComponent } from 'react';
import { bool, func, oneOf } from 'prop-types';
import { IconCheck, IconRadio } from 'components/Icon';
import Switch from '../Switch';
import { MenuItemStyled as SimpleItem } from './Dropdown.style';

const iconTypeMap = {
    checkbox: IconCheck,
    radio: IconRadio,
};

export default class DropdownMenuItem extends PureComponent {
    static propTypes = {
        /**
         * indicates whether the item is highlighted an in receipt of the user's focus
         */
        highlighted: bool,
        /**
         * indicates whether the item is selected in the dropdown's state
         */
        isSelected: bool,
        /**
         * on selection handler
         */
        onSelectHandler: func,
        type: oneOf(['checkbox', 'radio', 'simple', 'switch']).isRequired,
    };

    get type() {
        const menuItemTypes = {
            checkbox: 'menuitemcheckbox',
            radio: 'menuitemradio',
            simple: 'menuitem',
            switch: 'menuitemcheckbox',
        };

        return menuItemTypes[this.props.type];
    }

    renderItem = (item) => {
        const renderTypes = {
            checkbox: this.renderCheckboxItem,
            radio: this.renderRadioItem,
            simple: this.renderSimpleItem,
            switch: this.renderSwitchItem,
        };

        return renderTypes[item]();
    };

    renderRadioItem = () => {
        // we don't want to pass `onSelectHandler` any further
        const { onSelectHandler, ...otherProps } = this.props;
        const Icon = iconTypeMap.radio;

        return (
            <SimpleItem
                {...otherProps}
                aria-checked={this.props.isSelected}
                onClick={this.props.onSelectHandler}
                role={this.type}
            >
                {this.props.children}

                <Icon
                    highlighted={this.props.highlighted}
                    size="large"
                    state={this.props.isSelected ? 'checked' : 'unchecked'}
                    marginLeft="2"
                />
            </SimpleItem>
        );
    };

    renderSwitchItem = () => {
        // we don't want to pass `onSelectHandler` any further
        const { onSelectHandler, ...otherProps } = this.props;

        return (
            <SimpleItem
                {...otherProps}
                aria-checked={this.props.isSelected}
                onClick={this.props.onSelectHandler}
                role={this.type}
            >
                {this.props.children}

                <Switch
                    hasFocus={this.props.highlighted}
                    isChecked={this.props.isSelected}
                    marginLeft="2"
                />
            </SimpleItem>
        );
    };

    renderCheckboxItem = () => {
        // we don't want to pass `onSelectHandler` any further
        const { onSelectHandler, ...otherProps } = this.props;
        const Icon = iconTypeMap.checkbox;

        return (
            <SimpleItem
                {...otherProps}
                aria-checked={this.props.isSelected}
                onClick={this.props.onSelectHandler}
                role={this.type}
            >
                {this.props.children}

                <Icon
                    highlighted={this.props.highlighted}
                    size="large"
                    isChecked={this.props.isSelected}
                    marginLeft="0.5"
                />
            </SimpleItem>
        );
    };

    renderSimpleItem = () => {
        // we don't want to pass `onSelectHandler` any further
        const { onSelectHandler, ...otherProps } = this.props;

        return (
            <SimpleItem
                {...otherProps}
                aria-current={this.props.isSelected}
                onClick={this.props.onSelectHandler}
                role={this.type}
            >
                {this.props.children}
            </SimpleItem>
        );
    };

    render() {
        return this.renderItem(this.props.type);
    }
}
