import ApiConfig from '../config';
import ApiAction from './common';

const extraArgs = ['GET', null, null, null, 'contentKeyGenerator'];

const config = {
    // Blog areas
    loadBlogAreas: ['CONTENT_BLOG_AREAS', 'LOAD', ApiConfig.endpoints.content.blog.areas._],
    // Blog area
    loadBlogArea: [
        'CONTENT_BLOG_AREA',
        'LOAD',
        ApiConfig.endpoints.content.blog.area._,
        [],
        ...extraArgs,
    ],
    // Blog area posts
    loadBlogAreaPosts: [
        'CONTENT_BLOG_AREA_POSTS',
        'LOAD',
        ApiConfig.endpoints.content.blog.area.posts,
        [],
        ...extraArgs,
    ],
    // Blog area categories
    loadBlogAreaCategories: [
        'CONTENT_BLOG_AREA_CATEGORIES',
        'LOAD',
        ApiConfig.endpoints.content.blog.area.categories,
        [],
        ...extraArgs,
    ],
    // Blog area trending posts
    loadBlogAreaTrendingPosts: [
        'CONTENT_BLOG_AREA_TRENDING_POSTS',
        'LOAD',
        ApiConfig.endpoints.content.blog.area.trendingPosts,
        [],
        ...extraArgs,
    ],
    // Blog area related posts
    loadBlogAreaRelatedPosts: [
        'CONTENT_BLOG_AREA_RELATED_POSTS',
        'LOAD',
        ApiConfig.endpoints.content.blog.area.relatedPosts,
        [],
        ...extraArgs,
    ],
    // Blog area category
    loadBlogCategory: [
        'CONTENT_BLOG_CATEGORY',
        'LOAD',
        ApiConfig.endpoints.content.blog.category._,
        [],
        ...extraArgs,
    ],
    // Blog category
    loadBlogCategoryPosts: [
        'CONTENT_BLOG_CATEGORY_POSTS',
        'LOAD',
        ApiConfig.endpoints.content.blog.category.posts,
        [],
        ...extraArgs,
    ],
    // Blog category subcategories
    loadBlogCategorySubcategory: [
        'CONTENT_BLOG_CATEGORY_SUBCATEGORY',
        'LOAD',
        ApiConfig.endpoints.content.blog.category.subcategory._,
        [],
        ...extraArgs,
    ],
    // Blog category all subcategories
    loadBlogCategoryAllSubcategories: [
        'CONTENT_BLOG_CATEGORY_ALL_SUBCATEGORIES',
        'LOAD',
        ApiConfig.endpoints.content.blog.category.subcategory.all,
        [],
        ...extraArgs,
    ],
    // Blog category subcategories posts
    loadBlogCategorySubcategoryPosts: [
        'CONTENT_BLOG_CATEGORY_SUBCATEGORY_POSTS',
        'LOAD',
        ApiConfig.endpoints.content.blog.category.subcategory.posts,
        [],
        ...extraArgs,
    ],
    // Blog post
    loadBlogPost: [
        'CONTENT_BLOG_POST',
        'LOAD',
        ApiConfig.endpoints.content.blog.post._,
        [],
        ...extraArgs,
    ],
    // Blog tag
    loadBlogTag: [
        'CONTENT_BLOG_TAG',
        'LOAD',
        ApiConfig.endpoints.content.blog.tags._,
        [],
        ...extraArgs,
    ],
    // Blog tags posts
    loadBlogTagsPosts: [
        'CONTENT_BLOG_TAGS_POSTS',
        'LOAD',
        ApiConfig.endpoints.content.blog.tags.posts,
        [],
        ...extraArgs,
    ],
    // Blog author details
    loadBlogAuthor: [
        'CONTENT_BLOG_AUTHOR',
        'LOAD',
        ApiConfig.endpoints.content.blog.author._,
        [],
        ...extraArgs,
    ],
    loadBlogAuthorPosts: [
        'CONTENT_BLOG_AUTHOR_POSTS',
        'LOAD',
        ApiConfig.endpoints.content.blog.author.posts,
        [],
        ...extraArgs,
    ],
    // SEO Content
    loadSeoContent: [
        'CONTENT_SEO_CONTENT',
        'LOAD',
        ApiConfig.endpoints.content.seoContent,
        [],
        ...extraArgs,
    ],

    // Blog post
    loadPage: ['CONTENT_PAGE', 'LOAD', ApiConfig.endpoints.content.page._, [], ...extraArgs],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
