import styled from 'styled-components';

const InfoText = styled.div`
    font-size: ${({ theme }) => theme.typography.smallText['font-size']};
    line-height: ${({ theme }) => theme.typography.smallText['line-height']};
`;

const ErrorText = styled(InfoText)`
    color: ${({ theme }) => theme.palette.danger};
`;

export { InfoText, ErrorText };
