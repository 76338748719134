// eslint-disable-next-line import/prefer-default-export
export const HOMEPAGE_HERO_HEADING = {
    create(name, variation, description) {
        const payload = {
            name,
            variation,
            description,
        };

        return {
            type: 'Viewed Homepage Hero Heading',
            payload,
        };
    },
};
