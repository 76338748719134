// eslint-disable-next-line import/prefer-default-export
export const USER_PROFILE_VERIFICATIONS_VIEWED_PAGE = {
    create({ userEmail, userPhone, idChecked, idCbcChecked, completedReferenceCount }) {
        return {
            type: 'Viewed Verifications Page',
            payload: {
                idCheck: idChecked,
                phoneNumber: userPhone,
                emailAddress: userEmail,
                idCbcCheck: idCbcChecked,
                completedReferenceCount,
            },
        };
    },
};
