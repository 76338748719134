import { all, put, takeEvery, take, select } from 'redux-saga/effects';
import actions, { settings } from 'api/actions';
import * as pageActions from 'containers/Page/actions';
import { getMembershipType, getIsMembershipActive } from 'utils/account';
import { getAccountCurrentMembershipPlan, getAccountSettings } from 'api/selectors/account';
import { SEARCH_TYPE } from 'config/search';
import { getSitterProfile } from 'api/selectors/sitter';
import { getSearchProfile } from 'api/selectors/search';
import { MembershipType } from 'api/types';
import { routes } from 'utils/routes';
import { track, Events } from 'utils/analytics';
import { hasRequiredVerifications } from 'api/helpers/sitters/profile';
import { features, isFeatureEnabled } from 'components/Feature';
import { PAGE_ID, REDIRECT_MESSAGE_TYPES } from './ListingDetail.constants';
import { getListingDetailParams } from './selectors';
import loadFavourites from '../sagas/loadingFavourites';
import { getSuggestedCategory } from '../SearchListings/selectors';

export function* loadListing({ type, params }) {
    yield loadFavourites();

    // Stores the blocking api actions
    const apiActionsToWaitOn = {};

    // First see if need to load profile
    const membershipPlan = yield select(getAccountCurrentMembershipPlan);
    const membershipType = getMembershipType(membershipPlan);
    const countryCode = yield select(getAccountSettings)?.country;

    if (membershipType === MembershipType.SITTER || membershipType === MembershipType.COMBINED) {
        const sitterProfile = yield select(getSitterProfile);
        if (!sitterProfile) {
            // The sitter profile is not loaded so load it
            apiActionsToWaitOn.loadProfileResponse = take([
                settings.sitter.loadProfile.SUCCESS,
                settings.sitter.loadProfile.FAILURE,
                settings.sitter.loadProfile.ERROR,
            ]);

            yield put(actions.sitter.loadProfile());
        }

        const isPaidMember = getIsMembershipActive(membershipPlan);
        const isSitterIncomplete =
            sitterProfile?.isIncomplete ||
            (sitterProfile?.completedVerifications
                ? !hasRequiredVerifications({
                      profile: sitterProfile,
                      memberBillingCountryCode: countryCode,
                      isEvidentChecksEnabled: isFeatureEnabled({ name: features.EVIDENT_CHECKS }),
                  })
                : null);

        const searchProfile = sitterProfile?.id
            ? yield select(getSearchProfile, sitterProfile.id)
            : undefined;

        // Load only if not already loaded and for paid members. The assignments from the in the
        // pastAssignments field and it holds the confirmed assignments.
        if (!searchProfile?.pastAssignments && isPaidMember && !isSitterIncomplete) {
            yield put(
                actions.search.loadProfile({
                    forceReload: true,
                    data: {
                        id: 'me',
                    },
                })
            );
        }
    }

    // Now load listing
    apiActionsToWaitOn.loadListingResponse = take([
        settings.search.loadListing.SUCCESS,
        settings.search.loadListing.FAILURE,
        settings.search.loadListing.ERROR,
    ]);

    yield put(
        actions.search.loadListing({
            forceReload: true,
            data: {
                id: params.id,
            },
        })
    );

    // Block until api responses are completed
    const { loadListingResponse } = yield all(apiActionsToWaitOn);

    // If not successful then redirect the user to search listings page with filters applied
    if (loadListingResponse.type !== settings.search.loadListing.SUCCESS) {
        const listingDetailParams = yield select(getListingDetailParams);

        yield put(
            pageActions.redirect.create(
                PAGE_ID,
                routes.search.listings(listingDetailParams),
                REDIRECT_MESSAGE_TYPES.LISTING_ERROR
            )
        );

        return false;
    }

    // Here we're checking if the current pathname on URL is the same as the returned
    // from API; if it's different, redirect to the API response.
    if (loadListingResponse.type === settings.search.loadListing.SUCCESS) {
        const buildPathname = routes.search.listingsDetail;
        const currentPathname = buildPathname(yield select(getListingDetailParams));
        const { data } = loadListingResponse;
        const responsePathname = buildPathname({
            id: data.id,
            ...data.location,
        });

        if (currentPathname !== responsePathname) {
            yield put(pageActions.redirect.create(PAGE_ID, responsePathname));

            return false;
        }
    }

    // If successful then show as loaded
    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(PAGE_ID));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(PAGE_ID));
    }

    return true;
}

function* lazyload(action) {
    // Log analytics on preload
    const { params, search, pathname } = action;

    // part of the SIT_DISCOVERY_PAGE test
    const suggestedCategory = yield select(getSuggestedCategory);

    // Log productTrigger in analytic event
    const searchParams = new URLSearchParams(search);
    const trackObject = JSON.parse(decodeURIComponent(searchParams.get('t')));
    const productTrigger = trackObject?.productTrigger || null;

    track(
        Events.SEARCH_DETAILS.create({
            category: SEARCH_TYPE.Listing,
            id: params.id,
            suggestedCategory,
            productTrigger,
        })
    );

    searchParams.delete('t');
    const newSearch = searchParams.toString();
    const newUrl = `${pathname}?${newSearch}`;
    window.history.replaceState({}, '', newUrl);

    yield put(pageActions.lazyloaded.create(PAGE_ID));
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            loadListing
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            loadListing
        ),
        takeEvery(
            (action) => action.type === pageActions.lazyload.ACTION && action.pageId === PAGE_ID,
            lazyload
        ),
    ]);
}
