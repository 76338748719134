import { all, put, select, take, call, takeLatest } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { getSitterAssignment } from 'api/selectors';
import { isReviewWindowClosed } from 'pages/user/assignments/shared/utils';
import { track, Events } from 'utils/analytics';
import { isFeatureEnabled, features } from 'components/Feature';
import { assignmentIdFromAction } from '../shared/utils';
import * as rootActions from '../actions';
import * as actions from './actions';
import * as selectors from './selectors';
import * as constants from './constants';
import { validateSubmitValues } from './utils';

export function* fetchPastAssignment(id) {
    const assignment = getSitterAssignment(yield select(), id);

    if (assignment !== null) {
        return assignment;
    }

    yield put(
        apiActions.sitter.loadAssignment({
            forceReload: true,
            data: {
                id,
            },
        })
    );

    const { status } = yield take(settings.sitter.loadAssignment.DONE);

    if (status === settings.sitter.loadAssignment.FAILURE) {
        return null;
    }

    return getSitterAssignment(yield select(), id);
}

export function* triggerSendAnalytics() {
    const current = yield select(selectors.getCurrent);

    yield call(track, Events.LEAVE_FEEDBACK_MODAL.create(current));
}

export function* updateStorage() {
    const assignment = yield select(selectors.getAssignment);

    yield put(rootActions.viewedAssignment.create(assignment));
}

export function* handleOpen(action) {
    const assignment = yield call(fetchPastAssignment, assignmentIdFromAction(action));

    if (!assignment) {
        return;
    }

    if (isReviewWindowClosed(assignment.reviewWindowEndDate) || assignment.isFeedbackProvided) {
        yield put(
            actions.hydrate.create({
                assignment,
                current: constants.SCREEN_REVIEW_WINDOW_CLOSED,
            })
        );
    } else {
        yield put(
            actions.hydrate.create({
                assignment,
                current: constants.SCREEN_HOW_WAS_YOUR_SIT,
            })
        );
    }
}

const buildFeedbackPayload = (values, assignment) => {
    const isOwnerCategoriesEnabled = isFeatureEnabled({ name: features.FEEDBACK_OWNER_CATEGORIES });
    if (isOwnerCategoriesEnabled) {
        return {
            description: values.description,
            recommended: values.rating,
            communication: values.communication,
            hospitality: values.hospitality,
            cleanliness: values.cleanliness,
            petBehaviour: values.petBehaviour,
            accuracyOfListing: values.accuracyOfListing,
            assignmentId: assignment.id,
            version: 2,
        };
    }

    return {
        description: values.description,
        recommended: values.rating,
        assignmentId: assignment.id,
        version: 1,
    };
};

export function* handleSubmit() {
    const assignment = yield select(selectors.getAssignment);
    const values = yield select(selectors.getContext);

    if (!validateSubmitValues(values)) {
        yield put(actions.submitFailure.create());
        return;
    }

    yield put(
        apiActions.sitter.createPastAssignmentFeedback({
            forceReload: true,
            data: buildFeedbackPayload(values, assignment),
        })
    );

    const { status } = yield take(settings.sitter.createPastAssignmentFeedback.DONE);

    if (status === settings.sitter.createPastAssignmentFeedback.FAILURE) {
        yield put(actions.submitFailure.create());
        return;
    }

    yield put(
        apiActions.sitter.loadAssignment({
            forceReload: true,
            data: {
                id: assignment.id,
            },
        })
    );

    yield take(settings.sitter.loadAssignment.DONE);
    yield put(actions.submitSuccess.create());
    yield put(actions.send.create(constants.SCREEN_THANKS));
}

export default function* sagas() {
    yield all([
        takeLatest(actions.submitRequest.ACTION, handleSubmit),
        takeLatest(actions.open.ACTION, handleOpen),
        takeLatest(actions.open.ACTION, triggerSendAnalytics),
        takeLatest(actions.send.ACTION, triggerSendAnalytics),
        takeLatest(actions.close.ACTION, updateStorage),
    ]);
}
