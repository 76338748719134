import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const IconWrapper = styled.span`
    font-size: 22px;

    svg {
        fill: ${({ theme }) => theme.palette.text};
    }
`;
