import React from 'react';
import { truncateText } from 'components/CollapsableText/components/CollapsableTextByMaxCharacters/helpers';
import {
    Wrapper,
    ImageStyled,
    LinkStyled,
    ImageLinkStyled,
    CategoryStyled,
    TitleStyled,
    ExcerptStyled,
} from './PostItem.style';

const PostItem = ({ category, image, excerpt, title, url }) => (
    <Wrapper>
        <ImageLinkStyled to={url} data-testid="post-item-image-link">
            {image && <ImageStyled src={image.url} alt={image.alt} />}
        </ImageLinkStyled>

        <CategoryStyled data-testid="post-item-category">
            <LinkStyled to={category.url}>{category.description}</LinkStyled>
        </CategoryStyled>

        <TitleStyled data-testid="post-item-title">
            <LinkStyled to={url}>{title}</LinkStyled>
        </TitleStyled>

        {excerpt ? (
            <ExcerptStyled data-testid="post-item-excerpt">
                <LinkStyled to={url}>{truncateText(excerpt, 80, true)}</LinkStyled>
            </ExcerptStyled>
        ) : null}
    </Wrapper>
);

export default PostItem;
