import { combineReducers } from 'redux';

import edit from './edit/reducers';
import editHub from './edit/Hub/reducers';
import editPets from './edit/Pets/reducers';
import editDates from './edit/Dates/reducers';
import editHome from './edit/Home/reducers';
import editLocation from './edit/Location/reducers';
import welcomeGuide from './welcome-guide/reducers';

export default combineReducers({
    edit,
    editHub,
    editPets,
    editDates,
    editHome,
    editLocation,
    welcomeGuide,
});
