/* eslint-disable import/prefer-default-export */

// react-dropzone doesn't tell us why something was rejected or give us access to the functions it uses
// so simply pasting here for now.
// From: https://github.com/react-dropzone/react-dropzone/blob/master/src/utils/index.js
export function fileMatchSize(file, minSize, maxSize) {
    if (file.size != null) {
        if (minSize != null && maxSize != null) return file.size >= minSize && file.size <= maxSize;
        if (minSize != null) return file.size >= minSize;
        if (maxSize != null) return file.size <= maxSize;
    }
    return true;
}

export function fileMatchExt(file, exts) {
    if (file.name == null) return false;

    return exts.some((ext) => file.name.endsWith(ext));
}
