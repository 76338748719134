import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContent, ModalHeader, ModalHeaderCloseButton } from '@ths/design-system';
import { MembershipType } from 'api/types';
import MembershipSelector from 'components/Regwall/components/MembershipSelector';
import MarketingPolicyForm from 'components/forms/MarketingPolicyForm';
import Register from 'containers/forms/Register';
import { RegwallTestIds } from 'components/RegwallDialog/RegwallDialog.constants';
import { useRegwallDialog } from '../../useRegwallDialog';
import AlreadyHaveAccount from '../AlreadyHaveAccount';

const ThirdPartyStepForm = () => {
    const { t } = useTranslation();
    const [showMembershipTypeError, setShowMembershipTypeError] = useState(false);
    const {
        selectedMembershipType,
        membershipPlan,
        shouldSetMembershipType,
        openMethod,
        thirdPartyState,
        isCloseable,
        selectMembershipType,
    } = useRegwallDialog();

    if (!thirdPartyState.method || !thirdPartyState.data) {
        return null;
    }

    const headerTitle = (() => {
        switch (thirdPartyState.method) {
            case Register.Method.GOOGLE:
                return t('components_regwall_signUpWithGoogle');
            case Register.Method.FACEBOOK:
                return t('components_regwall_signUpWithFacebook');
            default:
                return null;
        }
    })();

    const getDataTestId = () => {
        switch (thirdPartyState.method) {
            case Register.Method.GOOGLE:
                return RegwallTestIds.registerGoogle;
            case Register.Method.FACEBOOK:
                return RegwallTestIds.registerFacebook;
            default:
                return RegwallTestIds.register;
        }
    };

    return (
        <>
            <ModalHeader title={headerTitle}>
                {isCloseable ? <ModalHeaderCloseButton /> : null}
            </ModalHeader>

            <ModalContent>
                <div className="mb-8">
                    <h2 className="m-0 p-0 text-center text-xl font-bold text-primary-700 md:text-[2.5rem]">
                        {t(
                            `components_regwall_facebookHeading_${
                                shouldSetMembershipType
                                    ? MembershipType.SITTER
                                    : selectedMembershipType
                            }`
                        )}
                    </h2>
                </div>

                <Register
                    initialAccountData={thirdPartyState.data}
                    membershipType={selectedMembershipType}
                    membershipPlan={membershipPlan}
                    method={thirdPartyState.method}
                    signupOrigin={Register.SignupOrigin.REGWALL}
                    openMethod={openMethod}
                    data-testid={getDataTestId}
                >
                    {({ createAccount, errorMessage }) => (
                        <>
                            {shouldSetMembershipType ? (
                                <MembershipSelector
                                    membershipType={selectedMembershipType}
                                    setMembershipType={selectMembershipType}
                                    showMembershipTypeError={showMembershipTypeError}
                                    setShowMembershipTypeError={setShowMembershipTypeError}
                                />
                            ) : null}

                            <MarketingPolicyForm
                                membershipType={selectedMembershipType}
                                onSubmit={(e) => {
                                    if (!selectedMembershipType) {
                                        setShowMembershipTypeError(true);
                                        return;
                                    }

                                    createAccount(e);
                                }}
                                errorMessage={errorMessage}
                            />
                        </>
                    )}
                </Register>

                <AlreadyHaveAccount />
            </ModalContent>
        </>
    );
};

export default ThirdPartyStepForm;
