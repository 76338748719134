import { combineReducers } from 'redux';
import promptManagerReducer from 'containers/PromptManager/reducer';
import freeTrialEndModalReducer from 'containers/FreeTrialEndModal/reducers';
import geoLocationReducers from './GeoLocation/reducers';
import plansPageReducers from './PlansPage/reducers';
import wizardReducers from './Wizard/reducers';
import passwordChangeModalReducer from './PasswordChangeModal/reducers';
import addonsOfferReducer from './AddonOffers/reducers';
import RAFNudgewallReducer from './ReferAFriendNudgewall/reducer';
import SafetySecurityPageReducer from './SafetySecurityPage/reducer';
import leaveReviewAndFeedbackModalReducer from './LeaveReviewAndFeedbackModal/reducer';
import outsideSubscriptionModalReducers from './OutsideSubscriptionModal/reducers';

export default combineReducers({
    promptManager: promptManagerReducer,
    geoLocation: geoLocationReducers,
    plansPage: plansPageReducers,
    wizard: wizardReducers,
    passwordChangeModal: passwordChangeModalReducer,
    addons: combineReducers({ offer: addonsOfferReducer }),
    rafNudgewall: RAFNudgewallReducer,
    freeTrialEndModal: freeTrialEndModalReducer,
    safetySecurityPage: SafetySecurityPageReducer,
    leaveReviewAndFeedbackModal: leaveReviewAndFeedbackModalReducer,
    outsideSubscriptionModal: outsideSubscriptionModalReducers,
});
