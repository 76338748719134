/**
 * Base assignment fields
 */
export const baseAssignmentFields = [
    'startDate', // Assignment start date
    'endDate', // Assignment end date
    'profileId', // Assignment profile id
    'listingId', // Listing id
    'isReviewing', // Is owner reviewing applicants
    'isApproximateDates', // Are dates approximate
    'isPrivate', // Is assignment private
];

/**
 * Base assignment application/invitation fields
 */
export const baseApplicationFields = [
    'assignmentId', // Application assignment id
    'ownerId', // Application listing owner id
    'sitterId', // Application sitter id
    'listingId', // Application listing id
    'profileId', // Application profile id
    'isConfirmed', // Assignment confirmed
    'hasOwnerConfirmed', // Application confirmed - owner side
    'hasSitterConfirmed', // Application confirmed - sitter side
    'hasAssignmentDatesChanged', // Assignment dates changed
    'isInvited', // Is invitation
    'isShortlisted', // Is short listed
    'hasOwnerDeclined', // Owner declined (application)
    'hasOwnerCancelled', // Owner canceled (invitation)
    'hasSitterDeclined', // Sitter declined (invitation)
    'hasSitterCancelled', // Sitter canceled (application)
];
