import { connect } from 'react-redux';
import asPage from 'containers/Page';
import { getSeoContent } from 'shared/selectors';
import { PAGE_ID } from './constants';
import { getPage } from './selectors';
import Page from './Page';

const mapStateToProps = (state) => ({
    page: getPage(state),
    seoContent: getSeoContent(state),
});

const ConnectedPage = connect(mapStateToProps)(Page);

export default asPage(ConnectedPage, PAGE_ID, true, true, { forceLoad: true });
