const config = {
    all: {
        env: process.env.NODE_ENV || 'development',
        mode: process.env.MODE || 'development',
        basename: process.env.PUBLIC_PATH || '',
        host: process.env.HOST || 'localhost',
        port: process.env.PORT || 3333,
        staticPath: process.env.STATIC_PATH || 'static',
        cdnPath: process.env.CDN_HOST || '',
        staticSrc: 'dist/public',
        tokenCookie: process.env.TOKEN_COOKIE,
        debugIPAddress: null,
        sitemapHost: process.env.SITEMAP_HOST,
        s3Host: process.env.S3_HOST,
        releaseName: process.env.RELEASE_NAME,
        hostRedirectorIgnoreForHeaders: ['x-set-split-control'], // Ignored as indicates Mabl request
    },
    test: {},
    development: {
        staticSrc: 'public',
        debugIPAddress: '37.205.63.53', // UK IP address
        // debugIPAddress: '185.33.0.132', // Andorra
        // debugIPAddress: '214.2.16.12', // US IP address
        // debugIPAddress: '14.192.160.12', // AU IP address
        // debugIPAddress: '116.89.228.172', // NZ IP address
        // debugIPAddress: '138.197.153.229', // CA IP address
        // debugIPAddress: '14.191.255.255', // Vietnam IP address
        // debugIPAddress: '5.181.169.255', // Russia IP address
        // debugIPAddress: '178.18.25.15', // The Netherlands
        // debugIPAddress: '88.26.205.197' // Spain
        // debugIPAddress: '8.243.208.0', // Mexico
        // debugIPAddress: '109.41.3.149', // Germany
        // debugIPAddress: '2.47.255.255', // Italy
        // debugIPAddress: '1.187.255.255', // India
        // debugIPAddress: '177.47.27.205', // Brazil
        // debugIPAddress: '2.15.255.255', // France
        // debugIPAddress: '3.255.255.255', // Ireland
        // debugIPAddress: '2.57.211.255', // Poland
        // debugIPAddress: '57.7.118.255', // Belgium
        // debugIPAddress: '24.232.255.255', // Argentina
        // debugIPAddress: '2.56.169.255', // Switzerland
        // debugIPAddress: '143.128.145.37', // South Africa
    },
    production: {},
};

export default { ...config.all, ...config[config.all.env] };
