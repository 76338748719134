import styled from 'styled-components';

const RatingLabel = styled.span`
    position: absolute;

    display: block;
    opacity: 0;
    min-height: 16px;
    font-size: 10px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.borderDark};
    transition: opacity 250ms;

    &[data-visible='visible'] {
        opacity: 1;
    }
`;

export { RatingLabel };
