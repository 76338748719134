import { settings as actions } from '../../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.content.loadBlogAuthor.SUCCESS: {
            const { shortName, ...rest } = action.data;

            return {
                ...state,
                [shortName]: {
                    ...(state[shortName] || {}),
                    shortName,
                    ...rest,
                },
            };
        }
        case actions.content.loadBlogAuthorPosts.SUCCESS: {
            const { authorName } = action.requestData;
            const { filters } = action.action;
            const { results, count, hasMore } = action.data;

            return {
                ...state,
                [authorName]: {
                    ...(state[authorName] || {}),
                    posts: {
                        results,
                        count,
                        page: filters.page,
                        limit: filters.limit,
                        hasMore,
                    },
                },
            };
        }
        default:
            return state;
    }
};
