import { getSearchListingsFacets, getSearchListings } from 'api/selectors/search';
import * as ListingFilters from 'api/helpers/search/listings';
import * as pageSelectors from 'containers/Page/selectors';
import { createListingsSearchQuery } from 'utils/searchListings';
import { base64decode, base64encode } from 'utils/strings';
import { getLocale } from 'shared/selectors';
import { SITS_RESULTS_PER_PAGE, RECOMMENDED_SITS_GROUP } from 'config/search';
import { features as optimizelyFeatures, isFeatureEnabled } from 'components/Feature';
import { getQueryOptionsAndRelatedURLProperties } from './helpers';
import { geoParams } from '../helpers';
import { copyLocationSearchForLink } from '../selectors';

import {
    PAGE_ID,
    BASE_PATHNAME,
    SEARCH_TYPE_RESULTS,
    SEARCH_TYPE_TOTAL_AND_GEOFACETS,
} from './SearchListings.constants';

export const buildPathName = (params = {}) => {
    // BASE_PATHNAME includes a trailing forward slash
    let pathname = BASE_PATHNAME;

    geoParams.forEach((param) => {
        if (params[param]) {
            // All of ours paths end with a trailing forward slash
            pathname = `${pathname}${params[param]}/`;
        }
    });

    return pathname;
};
export const isChristmasSitsEnabled = () =>
    isFeatureEnabled({
        name: optimizelyFeatures.CHRISTMAS_SITS,
    });

export const getSearch = (state) => pageSelectors.getSearch(state, PAGE_ID);
export const getParams = (state) => pageSelectors.getParams(state, PAGE_ID);
export const isLoading = (state) =>
    (state.pages.search.searchListings.query[SEARCH_TYPE_RESULTS] &&
        state.pages.search.searchListings.query[SEARCH_TYPE_RESULTS].isLoading) ||
    (state.pages.search.searchListings.query[SEARCH_TYPE_TOTAL_AND_GEOFACETS] &&
        state.pages.search.searchListings.query[SEARCH_TYPE_TOTAL_AND_GEOFACETS].isLoading) ||
    (state.status['SEARCH_PROFILE:me:0'] &&
        state.status['SEARCH_PROFILE:me:0'].LOAD.status === 'REQUESTED');

/*
 * Gets current filters
 */
export const getFilters = (state) => {
    const { q } = getSearch(state);
    try {
        return JSON.parse(base64decode(q));
    } catch (e) {
        return {};
    }
};

export const getCanonicalPathName = (state) => {
    const params = getParams(state);

    if (params.admin1Slug === params.slug) {
        const { slug, ...rest } = params;
        return buildPathName(rest);
    }
    return buildPathName(params);
};

/*
 * Gets search method (eg save search)
 */
export const getSearchMethod = (state) => {
    const { searchMethod } = getSearch(state);
    return searchMethod;
};
export const getLocalSitsABTestVariant = (state) =>
    state.pages.search.searchListings.localSitsABTestVariant;
/**
 * Gets whether the current search is based on a geopoint
 */
export const getIsGeoPoint = (state) => state.pages.search.searchListings.isGeoPoint;
/**

 * Generate a link to the given page. Returns a url object to pass to <link />
 */
export const getPageLink = (state, page, startingAfter) => {
    const params = getParams(state);
    const filters = getFilters(state);
    const searchMethod = getSearchMethod(state);
    let query;
    if (!Object.keys(filters).length) {
        const searchQuery = createListingsSearchQuery({
            searchPastAssignments: false,
            page,
            searchPastAndFutureAssignments: true,
        });
        query = searchQuery.getRequestData();
    } else {
        query = { ...filters, page };
    }

    if (startingAfter) {
        query.startingAfter = startingAfter;
    }

    let queryString = `?q=${base64encode(JSON.stringify(query))}`;
    if (searchMethod) {
        queryString = `${queryString}&searchMethod=${searchMethod}`;
    }

    // THIS IS FOR THE LOW APPLICANTS AB TEST
    const { sortBy, christmasSits } = getQueryOptionsAndRelatedURLProperties();
    if (sortBy) {
        queryString = `${queryString}&sortBy=${sortBy}`;
    }
    if (christmasSits) {
        queryString = `${queryString}&christmasSits=true`;
    }
    return {
        pathname: buildPathName(params),
        search: queryString,
    };
};

export const getPathName = (state) => buildPathName(getParams(state));

export const getPlace = (state) => state.pages.search.searchListings.place;

export const getListingsGeoBounds = (state) => {
    // We get geofacets from SEARCH_TYPE_TOTAL_AND_GEOFACETS as this request
    // filters out those sits without dates in the future (i.e only those with open assigments)
    const facets = getSearchListingsFacets(state, SEARCH_TYPE_TOTAL_AND_GEOFACETS);

    return facets.geoBounds;
};

export const getSearchListingsQueryFilters = (state, searchType = 'default') => ({
    ...state.pages.search.searchListings.query[searchType],
});

export const getMapClustersGeoJson = (state) => {
    const searchType = state.pages.search.searchListings.map.clustersSearchType;
    const geohashFacet = state.pages.search.searchListings.map.clustersGeohashFacet;

    const facets = getSearchListingsFacets(state, searchType);

    const features = [];

    if (facets[geohashFacet]) {
        facets[geohashFacet].forEach((cluster) => {
            for (let a = 0; a < cluster.count; a += 1) {
                features.push({
                    type: 'Feature',
                    properties: {
                        count: '1',
                        geohash: cluster.slug,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [cluster.coordinates.lon, cluster.coordinates.lat],
                    },
                });
            }
        });
    }

    const featureCollection = {
        type: 'FeatureCollection',
        features,
    };

    return featureCollection;
};

/**
 * Get a path with query string base64 encoded from a attraction filter
 */
export const getQueryStringFromAttraction = (state) => (attraction) => {
    const pathname = buildPathName(getParams(state));
    const FIRST_PAGE = 1;

    const searchQuery = createListingsSearchQuery({
        searchPastAssignments: false,
        page: FIRST_PAGE,
    });
    searchQuery.resultsPerPage(SITS_RESULTS_PER_PAGE);
    searchQuery.filter(new ListingFilters.LocalAttractions([attraction]));

    return `${pathname}?q=${base64encode(JSON.stringify(searchQuery.getRequestData()))}`;
};

/**
 * Extract the page information (current page, total etc...) for the current search
 */
export const getPageInfo = (state) => {
    const { pageInfo } = getSearchListingsQueryFilters(state, SEARCH_TYPE_RESULTS);

    return pageInfo;
};

/**
 * Extract the date range from the current search
 */
export const getDateRange = (state) => {
    const filters = getSearchListingsQueryFilters(state, SEARCH_TYPE_RESULTS);

    if (!filters || !filters.assignments) return {};

    const { dateFrom, dateTo } = filters.assignments;

    return { dateFrom, dateTo };
};

/**
 * Make a link for the current location but for sitter search. Useful for switching users from
 * sit search to sitter search but retaining the location.
 *
 * If the user is on a geoHierarchy landing page we will retain this but lose the query string:
 *
 * - /house-and-pet-sitting-assignments/united-kingdom/england/london/
 *   -> /house-and-pet-sitters/united-kingdom/england/london/
 * - /house-and-pet-sitting-assignments/united-kingdom/england/london/?q=blah...
 *   -> /house-and-pet-sitters/united-kingdom/england/london/
 *
 * But if they only have a location in the query string we will keep that in tact (only the location
 * filter will be retained - other filters will be lost):
 *
 * - /house-and-pet-sitting-assignments/?q=blah...
 *   -> /house-and-pet-sitters/?q=blah...
 */
export const getSitterSearchLink = (state, searchType = 'default', route) => {
    const objectHasData = (obj) => Object.keys(obj).some((key) => obj[key] != null);
    const params = getParams(state);
    let newQueryString = null;

    if (!objectHasData(params)) {
        const filters = getSearchListingsQueryFilters(state, searchType);
        const copied = copyLocationSearchForLink({ filters });
        newQueryString = objectHasData(copied.query.filters) ? copied.search : null;
    }

    return { pathname: route(params), search: newQueryString };
};

export const getListingMapGeoBounds = (state) => {
    const place = getPlace(state);
    const geoip = getLocale(state);
    const { longitude } = geoip;
    const searchFilters = getFilters(state);

    const hasPlace = Boolean(place && place.id);
    const hasFilters = Object.keys(searchFilters).length > 0;
    const hasLongitude = !!longitude;
    const useListingsGeoBounds = hasPlace || hasFilters || !hasLongitude;

    // Sensible offset to be applied to the center point.
    // with 180 the west and east sides would be the same
    const offset = 170;

    // Gets geo bounds from either search geobounds or centered around geoip lat/lon
    return useListingsGeoBounds
        ? getListingsGeoBounds(state)
        : {
              west: parseFloat(longitude) - offset,
              south: -90,
              east: parseFloat(longitude) + offset,
              north: 90,
          };
};

export const getIsLandingPage = (state) => {
    const params = getParams(state);
    return params && params.countrySlug;
};

// @TODO - A/B Test - SIT_SEARCH_MOVE_MATCHING_SITS_BLOCK
const getTheseMightBeBackListingsLength = (listings) => {
    if (listings.length === 0) {
        return 12;
    }
    if (listings.length >= 1 && listings.length <= 3) {
        return 9;
    }
    if (listings.length >= 4 && listings.length <= 6) {
        return 6;
    }
    if (listings.length >= 7 && listings.length <= 9) {
        return 3;
    }
    return 0;
};
// @TODO - A/B Test - SIT_SEARCH_MOVE_MATCHING_SITS_BLOCK

export const getListingsWithCompletedSits = (state) => {
    const listings = getSearchListings(state, SEARCH_TYPE_RESULTS);
    const theseMightBeBackListings = getSearchListings(
        state,
        RECOMMENDED_SITS_GROUP.theseMightBeBack
    );

    // get list of ids in the listings
    const availableListingIds = listings.map((listing) => listing.id);
    // filter out completedListings with an id that's also in the available listings

    return theseMightBeBackListings
        .filter(({ id }) => !availableListingIds.includes(id))
        .slice(0, getTheseMightBeBackListingsLength(listings));
};

export const getSavedSearches = (state) => state.search.savedSearchFilters;
export const getIsSaveSearchSuccessful = (state) =>
    state.pages.search.searchListings.isSaveSearchSuccessful;
export const getSaveSearchDialog = (state) =>
    state.pages.search.searchListings.showSaveSearchDialog;
export const getSavedSearchResponse = (state) =>
    state.pages.search.searchListings.savedSearchResponse;
export const getScrollToResults = (state) => state.pages.search.searchListings.scrollToResults;
export const getSuggestedCategory = (state) => state.pages.search.searchListings.suggestedCategory;

export const getShowLowApplicantsPrompt = (state) =>
    state.pages.search.searchListings.showLowApplicantsPrompt;
