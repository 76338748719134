// eslint-disable-next-line import/prefer-default-export
export const REGISTERED = {
    registerMethod: {
        JOIN_NOW: 'Join Now Reg Form',
        REGWALL: 'Firewall sign-up',
    },
    loginMethod: {
        EMAIL: 'email',
        FACEBOOK: 'facebook',
        GOOGLE: 'google',
    },

    // TODO: First arg is a "user-like" object until we can refactor the core code (or get rid of it)
    create(user, registerMethod, loginMethod, options = {}) {
        const { id, membershipType, settings, wizardData } = user;

        return {
            type: 'Registered',
            payload: {
                userId: id || null,
                category: registerMethod,
                label: membershipType,
                loginMethod,
                optedIn: settings.hasAcceptedMarketingComms,
                ...wizardData,
                ...options,
            },
        };
    },
};
