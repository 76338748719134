import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

import {
    basePaginatedInitialState,
} from '../common';
import { uniqueArray } from '../../helpers/format/objects';

export const initialState = { ...basePaginatedInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.messages.loadArchived.SUCCESS: {
            const { startingAfter } = action.requestData;
            // Append search results
            if (startingAfter) {
                return {
                    ...state,
                    count: action.data.count,
                    data: {
                        ...state.data,
                        ...action.data.results.reduce((items, item) => {
                            items[item.id] = transform.getArchiveMessage(item);
                            return items;
                        }, {}),
                    },
                    results: uniqueArray([
                        ...state.results,
                        ...action.data.results.map(item => item.id),
                    ]),
                };
            }
            return {
                ...state,
                count: action.data.count,
                data: {
                    ...action.data.results.reduce((items, item) => {
                        items[item.id] = transform.getArchiveMessage(item);
                        return items;
                    }, {}),
                },
                results: [
                    ...action.data.results.map(item => item.id),
                ],
            };
        }
        case actions.owner.unArchiveConversations.SUCCESS:
        case actions.owner.batchUnArchiveConversations.SUCCESS:
        case actions.sitter.unArchiveConversations.SUCCESS:
        case actions.sitter.batchUnArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const results = [];
            state.results.forEach((messageThreadId) => {
                const messageThread = state.data[messageThreadId];
                const { contactId: messageContactId } = messageThread;

                if (ids.indexOf(messageContactId) < 0) {
                    results.push(messageThreadId);
                }
            });
            return {
                ...state,
                results,
            };
        }
        default:
            return state;
    }
};
