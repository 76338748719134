const SizeVariant = Object.freeze({
    DEFAULT: 'default',
    SMALL: 'small',
});

const RatingVariant = Object.freeze({
    REVIEW: 'review',
    REFERENCE: 'reference',
    FEEDBACK: 'feedback',
});

export { SizeVariant, RatingVariant };
