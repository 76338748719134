import styled from 'styled-components';

import { Blockquote, A, P } from 'typography';

const LiStyled = styled.li`
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const UlStyled = styled.ul`
    padding-left: ${({ theme }) => theme.spacing[2]};
`;

const OlStyled = styled.ol`
    padding-left: ${({ theme }) => theme.spacing[2]};
`;

const StrongStyled = styled.strong`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const BlockquoteStyled = styled(Blockquote)``;

const AStyled = styled(A)`
    color: ${({ theme }) => theme.palette.primaryRegular};
    font-style: normal;
`;

const PStyled = styled(P)`
    .indent-1 {
        display: block;
        padding-left: ${({ theme }) => theme.spacing[2]};
    }
    .indent-2 {
        display: block;
        padding-left: ${({ theme }) => theme.spacing[4]};
    }
`;

export { BlockquoteStyled, AStyled, LiStyled, UlStyled, OlStyled, StrongStyled, PStyled };
