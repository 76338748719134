import { all, put, take, call, takeLatest } from 'redux-saga/effects';
import { photoDiff } from 'containers/PhotoUploader/helpers';
import apiActions, { settings } from 'api/actions';
import { track, Events } from 'utils/analytics';
import { UPLOADED_PHOTO_TYPES } from 'utils/analytics/events/user/uploadedPhoto';
import {
    saveProfile as saveProfileAction,
    saveProfileDone as saveProfileDoneAction,
    profilePhotoSave,
} from './actions';
import { socialVerificationsDiff } from './helpers';

export function* saveSocialVerifications(oldSocialVerifications, newSocialVerifications) {
    const { addedSocialVerifications, deletedSocialVerifications, updatedSocialVerifications } =
        socialVerificationsDiff(oldSocialVerifications, newSocialVerifications);

    // eslint-disable-next-line no-restricted-syntax
    for (const [deletedKey, deletedSocialVerification] of Object.entries(
        deletedSocialVerifications
    )) {
        yield put(
            apiActions.verifications.removeSocialVerification({
                forceReload: true,
                data: {
                    platform: deletedKey,
                    id: deletedSocialVerification.id,
                },
            })
        );
        yield take((res) => res.type === settings.verifications.removeSocialVerification.DONE);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [createdKey, createdSocialVerification] of Object.entries(
        addedSocialVerifications
    )) {
        yield put(
            apiActions.verifications.createSocialVerification({
                forceReload: true,
                data: {
                    platform: createdKey,
                    platformIdentifier: createdSocialVerification.platformIdentifier,
                },
            })
        );
        yield take((res) => res.type === settings.verifications.createSocialVerification.DONE);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [updatedKey, updatedSocialVerification] of Object.entries(
        updatedSocialVerifications
    )) {
        yield put(
            apiActions.verifications.updateSocialVerification({
                forceReload: true,
                data: {
                    platform: updatedKey,
                    id: updatedSocialVerification.id,
                    platformIdentifier: updatedSocialVerification.platformIdentifier,
                },
            })
        );
        yield take((res) => res.type === settings.verifications.updateSocialVerification.DONE);
    }

    return true;
}

export function* saveProfile(action) {
    const { oldProfile, newProfile, hasProfilePhoto, socialVerifications } = action;
    const { addedPhotos, deletedPhotos, updatedMedias } = photoDiff(
        oldProfile.photos,
        newProfile.photos
    );
    let totalPhotoCount = oldProfile.photos ? oldProfile.photos.length - deletedPhotos.length : 0;

    yield put(
        apiActions.sitter.updateProfile({
            forceReload: true,
            data: newProfile,
        })
    );
    yield take((res) => res.type === settings.sitter.updateProfile.DONE);

    // delete removed photos
    for (let a = 0; a < deletedPhotos.length; a += 1) {
        const deletedPhoto = deletedPhotos[a];

        yield put(
            apiActions.sitter.removeProfilePhoto({
                forceReload: true,
                data: { mediaId: deletedPhoto.id },
            })
        );

        yield take((res) => res.type === settings.sitter.removeProfilePhoto.DONE);
    }

    // link all the new photos
    for (let a = 0; a < addedPhotos.length; a += 1) {
        const addedPhoto = addedPhotos[a];

        yield put(
            apiActions.sitter.createProfilePhoto({
                forceReload: true,
                data: { mediaId: addedPhoto.id },
            })
        );

        yield take((res) => res.type === settings.sitter.createProfilePhoto.DONE);

        yield call(
            track,
            // eslint-disable-next-line no-plusplus
            Events.USER_UPLOADED_PHOTO.create(
                UPLOADED_PHOTO_TYPES.sitterProfilePhoto,
                ++totalPhotoCount
            )
        );
    }

    // update captions/order
    if (updatedMedias.length) {
        yield put(
            apiActions.medias.update({
                forceReload: true,
                data: {
                    media: updatedMedias,
                    mediaType: 'photo',
                },
            })
        );

        yield take((res) => res.type === settings.medias.update.DONE);
    }
    yield call(saveSocialVerifications, oldProfile.socialVerifications, socialVerifications);

    yield call(
        track,
        Events.USER_PROFILE_EDIT_ABOUT_YOU.create(newProfile, hasProfilePhoto, socialVerifications)
    );

    // Reload the profile so we don't have stale data on the hub page or
    // if the user tries to come back here quickly
    yield put(
        apiActions.sitter.loadProfile({
            forceReload: true,
        })
    );

    yield take((res) => res.type === settings.sitter.loadProfile.DONE);

    yield put(saveProfileDoneAction.create(200));

    return true;
}

// As the profile photo is a required field and doesn't require the user to click save
// we add this PUT to make sure the sitter goes live if they return to hub page without a save click
export function* saveProfilePhoto(action) {
    const { profile } = action;
    yield put(
        apiActions.sitter.updateProfile({
            forceReload: true,
            data: profile,
        })
    );
    yield take((res) => res.type === settings.sitter.updateProfile.DONE);

    return true;
}

export default function* pageSaga() {
    yield all([takeLatest(saveProfileAction.ACTION, saveProfile)]);
    yield all([takeLatest(profilePhotoSave.ACTION, saveProfilePhoto)]);
}
