/* eslint-disable import/prefer-default-export */
import { put, takeEvery, select, call } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { Events, track } from 'utils/analytics';
import { getOwnerCurrentAssignments, getListing } from 'api/selectors/owner';

export function* reloadAssignments(action) {
    const currentAssignments = yield select(getOwnerCurrentAssignments);
    const isAssignmentInCurrentAssignments = currentAssignments.some(
        (assignmentId) => assignmentId === action.data.assignmentId
    );

    if (isAssignmentInCurrentAssignments) {
        yield put(
            apiActions.owner.loadCurrentAssignments({
                data: {},
            })
        );
        return;
    }

    // if assignment is not in currentAssignments, then it is in futureAssignments
    const listing = yield select(getListing);
    const listingId = listing.id;

    if (listingId) {
        yield put(
            apiActions.owner.loadListingFutureAssignments({
                forceReload: true,
                data: {
                    id: listingId,
                    listingId,
                },
            })
        );
    }
}

export function* onShareWelcomeDone(action) {
    const listing = yield select(getListing);
    const listingId = listing.id;
    const { assignmentId } = action.data;
    yield call(track, Events.USER_WELCOME_GUIDE_SHARED.create(listingId, assignmentId));
    yield call(reloadAssignments, action);
}

export default function* componentSaga() {
    yield takeEvery(
        (action) => action.type === settings.owner.shareAssignmentWelcomeGuide.DONE,
        onShareWelcomeDone
    );
}
