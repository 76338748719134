import { call, put, take } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { track, Events } from 'utils/analytics';

export default function* deleteSavedSearch(action) {
    yield put(
        apiActions.search.deleteListingsSavedSearch({
            forceReload: true,
            data: {
                id: action.id,
            },
        })
    );

    // Wait for results
    const { status, requestData } = yield take(settings.search.deleteListingsSavedSearch.DONE);

    if (status === settings.search.deleteListingsSavedSearch.SUCCESS) {
        yield call(track, Events.SAVED_SEARCH_DELETED.create(requestData.id, action.name));
    }

    // Return true/false for success/failure
    return status === settings.search.deleteListingsSavedSearch.SUCCESS;
}
