import { createAction } from 'utils/actions';

const prefix = 'SHARED';

export const eventTriggered = createAction(prefix, 'EVENT_TRIGGERED', 'eventType');

export const clearStore = createAction(prefix, 'CLEAR_STORE');

export const identify = createAction(prefix, 'IDENTIFY', 'force');
export const trackPage = createAction(prefix, 'TRACK_PAGE', 'event');

export const storePromo = createAction(prefix, 'STORE_PROMO', 'promoCode');

export const storePartner = createAction(prefix, 'STORE_PARTNER', 'partnerCode');

export const searchSuggestionsOnEnter = createAction(
    prefix,
    'SEARCH_SUGGESTIONS_ON_ENTER',
    'places'
);

export const clearSearchSuggestionsOnEnter = createAction(
    prefix,
    'CLEAR_SEARCH_SUGGESTIONS_ON_ENTER'
);

export const cjTrackingTriggered = createAction(prefix, 'CJ_TRACKING_TRIGGERED', 'params');
