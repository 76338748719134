import { all, call, put, takeEvery, fork } from 'redux-saga/effects';
import {
    preload as pageActionsPreload,
    lazyload as pageActionsLazyload,
} from 'containers/Page/actions';
import apiActions, { settings } from 'api/actions';
import { loadProfile } from '../sagas';
import { PAGE_ID as ABOUT_YOU_PAGE_ID } from './AboutYou/AboutYou.constants';
import { PAGE_ID as PROFILE_DETAILS_PAGE_ID } from './ProfileDetails/ProfileDetails.constants';
import { PAGE_ID as EXPERIENCE_PAGE_ID } from './Experience/Experience.constants';
import { PAGE_ID as HUB_PAGE_ID } from './Hub/Hub.constants';
import aboutYouSaga from './AboutYou/sagas';
import preferencesSaga from './Preferences/sagas';
import profilePreviewSaga from './ProfilePreview/sagas';

function* loadPets() {
    yield put(apiActions.sitter.loadPets());
}

export function* load(action) {
    yield call(loadProfile, action.params, action.type, action.pageId);
    yield call(loadPets, action.params, action.type, action.pageId);
}

export function* reloadProfile() {
    yield put(
        apiActions.sitter.loadProfile({
            forceReload: true,
        })
    );
}

export default function* pageSaga() {
    const pageIds = [ABOUT_YOU_PAGE_ID, PROFILE_DETAILS_PAGE_ID, EXPERIENCE_PAGE_ID, HUB_PAGE_ID];

    yield all([
        takeEvery(
            (action) =>
                action.type === pageActionsPreload.ACTION && pageIds.includes(action.pageId),
            load
        ),
        takeEvery(
            (action) =>
                action.type === pageActionsLazyload.ACTION && pageIds.includes(action.pageId),
            load
        ),
        takeEvery(settings.account.updateProfilePhoto.DONE, reloadProfile),
        fork(aboutYouSaga),
        fork(preferencesSaga),
        fork(profilePreviewSaga),
    ]);
}
