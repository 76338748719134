import { LISTING_TAGS_PRIORITY_ORDER } from '../search/constants';

const ANIMAL_TO_NUMBER = [
    {
        number: 1,
        type: 'dog',
    },
    {
        number: 2,
        type: 'cat',
    },
    {
        number: 4,
        type: 'lizard',
    },
    {
        number: 8,
        type: 'horse',
    },
    {
        number: 16,
        type: 'fish',
    },
    {
        number: 32,
        type: 'poultry',
    },
    {
        number: 64,
        type: 'sheep',
    },
    {
        number: 128,
        type: 'bird',
    },
    {
        number: 256,
        type: 'small-pets',
    },
];

// eslint-disable-next-line import/prefer-default-export
export const selectedAnimalsToList = (selectedAnimals) =>
    ANIMAL_TO_NUMBER.reduce((acc, cur) => {
        // eslint-disable-next-line no-bitwise
        if (cur.number & selectedAnimals) {
            return [...acc, cur.type];
        }

        return acc;
    }, []);


export const prioritisedPetOrder = (input) => {
    return input
        // Filter out unknown tags
        .filter((value) => LISTING_TAGS_PRIORITY_ORDER[value] !== undefined)
        // Sort by priority
        .sort((a, b) => LISTING_TAGS_PRIORITY_ORDER[a] - LISTING_TAGS_PRIORITY_ORDER[b]);
};
