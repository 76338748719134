import * as actions from './actions';

const defaultState = {
    responseStatus: 200,
    updateId: 0,
    petSaveStatus: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actions.savePetDone.ACTION: {
            const previousPetSaveStatus = state.petSaveStatus[action.petId] || {
                responseStatus: 200,
                updateId: 0,
            };

            return {
                ...state,
                petSaveStatus: {
                    ...state.petSaveStatus,
                    [action.petId]: {
                        responseStatus: action.statusCode,
                        updateId: previousPetSaveStatus.updateId + 1,
                    },
                },
            };
        }
        case actions.savePetsDone.ACTION: {
            if (action.saveAll) {
                return {
                    ...state,
                    responseStatus: action.statusCode,
                    updateId: state.updateId + 1,
                };
            }

            return state;
        }
        default:
            return state;
    }
};
