import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
    0% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.2)
    }
    100% {
        transform: scale(1)
    }
`;

const pulseAnimationRule = css`
    ${pulse} 0.7s ease-in-out;
`;

const checkedCss = css`
    animation: ${pulseAnimationRule};

    & > span > svg {
        stroke-width: 0px !important;
        fill: ${({ theme }) => theme.palette.accent};
    }
`;

const uncheckedCss = css`
    & > span > svg { {
        stroke: white;
        stroke-width: 2px;
    }
`;

export const IconWrapper = styled.span`
    ${({ theme }) => `
        width: ${theme.spacing[3]};
        height: ${theme.spacing[3]};
    `};

    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;

    & > span > svg {
        fill: transparent;
    }

    ${({ isChecked }) => (isChecked ? checkedCss : uncheckedCss)};
`;

export const VariationIconWrapper = styled.span`
    ${({ theme }) => `
    width: ${theme.spacing[3]};
    height: ${theme.spacing[3]};
    `};

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CheckboxInput = styled.input`
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
`;

export const LabelStyled = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
