import styled, { css } from 'styled-components';
import { Button } from 'components/buttons';
import { Panel, Col } from 'components/layouts';
import { IconArrowLeft, IconClose } from 'components/Icon';

export const BodyContainer = styled(Panel)(
    ({ theme: { palette, screenSize, spacing } }) => css`
        background-color: ${palette.grey[100]};
        width: 100%;
        padding: 0;
        margin: 0;
        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            padding: 0px ${spacing[1]};
        }
    `
);

export const TitleWrapper = styled.div(
    ({ theme: { screenSize, spacing } }) => css`
        width: 100%;
        margin-top: ${spacing[4]};
        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            margin-top: ${spacing[3]};
        }
    `
);

export const TitleStyled = styled.h1`
    ${({ theme: { fontSizes, screenSize, fonts, fontWeights, palette } }) => css`
        font-family: ${fonts.header};
        font-size: ${fontSizes.xlarge};
        font-weight: ${fontWeights.semiBold};
        text-align: center;
        color: ${palette.primaryDark};
        margin: 0;
        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            font-size: ${fontSizes.large};
        }
    `}
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 0;
    position: relative;
    z-index: 1;
`;

export const FormRow = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        padding-top: ${spacing[3]};

        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            padding-top: ${spacing[3.5]};
        }
    `
);

export const HeaderWrapper = styled.div(
    () => css`
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    `
);

export const BodyWrapper = styled.div(
    ({ theme: { palette, spacing, borderRadius, screenSize } }) => css`
        display: flex;
        flex-direction: column;
        padding: ${spacing[2.5]};
        border-radius: ${borderRadius.regular};
        background-color: ${palette.white};
        margin-top: ${spacing[4]};

        // 720
        @media screen and (max-width: ${screenSize.mediumTablet}px) {
            margin-top: ${spacing[3]};
        }
    `
);

export const BodyRow = styled.div(
    ({ isTotal, theme: { fontSizes, palette, fonts, fontWeights, spacing } }) => css`
        display: flex;
        padding: ${spacing[0.5]} ${spacing[3]};
        width: 100%;
        align-items: center;
        justify-content: space-between;
        font-family: ${fonts.body};
        font-size: ${fontSizes.medium};
        font-weight: ${isTotal ? fontWeights.semiBold : fontWeights.normal};
        ${!isTotal &&
        `
            color: ${palette.grey[500]};
        `};
    `
);

export const OrderedCol = styled(Col)(
    ({ mobileOrder, theme: { screenSize } }) => css`
        order: ${mobileOrder};

        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            order: 0;
        }
    `
);

export const BackButton = styled.div(
    ({ theme: { palette, fontWeights, fontSizes } }) => css`
        display: flex;
        align-items: center;
        color: ${palette.primaryRegular};
        font-weight: ${fontWeights.semiBold};
        font-size: ${fontSizes.medium};
        cursor: pointer;
    `
);

export const IconStyled = styled(IconArrowLeft)`
    margin-left: -${({ theme }) => theme.spacing[0.5]};
    margin-right: ${({ theme }) => theme.spacing[1]};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    & svg {
        fill: ${({ theme }) => theme.palette.primaryRegular};
    }
`;

export const CloseButton = styled(IconClose)(
    ({ theme: { fontSizes, palette } }) => css`
        font-size: ${fontSizes.large};
        cursor: pointer;
        margin-left: auto;
        text-decoration: none;
        & svg {
            fill: ${palette.grey[700]};
        }
    `
);

export const RegularPayButton = styled(Button).attrs({
    variant: Button.Variant.PRIMARY,
})(
    ({ theme: { fontWeights, screenSize, typography, spacing } }) => css`
        width: 100%;
        font-weight: ${fontWeights.semiBold};

        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            ${typography.h3};
            padding-top: ${spacing[2.5]};
            padding-bottom: ${spacing[2.5]};
            font-weight: ${fontWeights.normal};
        }
        font-weight: ${fontWeights.normal};
    `
);

export const SpinnerContainer = styled.div(
    ({ theme: { spacing } }) => css`
        display: flex;
        justify-content: center;
        margin-top: ${spacing[8]};
        margin-bottom: ${spacing[8]};
    `
);
