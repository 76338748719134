const paths = {
    index: '/get-started/pet-parents/',
    numberOfPets: '/get-started/pet-parents/number-of-pets/',
    sitterExperience: '/get-started/pet-parents/sitter-experience/',
    petsAreHappierAtHome: '/get-started/pet-parents/pets-are-happier-at-home/',
    travel: {
        index: '/get-started/pet-parents/travel/',
        dates: '/get-started/pet-parents/travel/dates/',
        tripLength: '/get-started/pet-parents/travel/trip-length/',
        petCare: '/get-started/pet-parents/travel/pet-care/',
    },
    location: '/get-started/pet-parents/location/',
    loadingResults: '/get-started/pet-parents/loading-results/',
    register: '/get-started/pet-parents/register/',
};

export default paths;
