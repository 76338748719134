// eslint-disable-next-line import/prefer-default-export
export const SITTERCOMPLETEDAPPLICATION = {
    // application is the application the user made
    // applicationNumber is the nth application the user is for the assignment
    // e.g. 1 = first, 2 = second etc
    create(application, applicationNumber) {
        const { id: applicationId, assignmentId, isInvited, listingId, profileId } = application;
        const { endDate, startDate, isApproximateDates } = application.assignment;
        const ownerId = application.listing.user.id;
        const sitterId = application.profile.user.id;
        const { countryName } = application.listing.location;

        const payload = {
            applicationId,
            assignmentId,
            isInvited,
            listingId,
            profileId,
            ownerId,
            sitterId,
            endDate,
            startDate,
            isApproximateDates,
            countryName,
            applicationNumber,
        };

        return {
            type: 'Sitter Created Application',
            payload,
        };
    },
};
