import React, { useEffect } from 'react';
import { Events, track } from 'utils/analytics';
import { IFrameEventStyled } from './IFrameEvent.style';

// What/Why/How/Limits:
// This component is used to send analytics events when a Third Party Button rendered in an Cross-Origin iframe is clicked eg. Google Sign In.
// We're not able to hook onto Cross-Origin elements in iFrames, or interrogate them so we can't fire the event directly.
// The method we use is to set focus on an element, and when the button in the iframe is clicked the focus will move to the iFrame.
// When the focused element is blurred, we check if the focus is on the iFrame. If it is, we send the event.
// Limits: if the focus is taken away from this component by an interaction with another component, then the event will not fire.
// The event will not fire when using the keyboard to navigate.

//  Use like this:
// <IFrameEvent eventName="Sign up with Google" sendOnce={false}>
//     <GoogleButton id="google-sign-up-button" />
// </IFrameEvent>;

function sendEvent(eventName) {
    track(
        Events.BUTTONCLICK.create({
            description: eventName,
        })
    );
}

function useIFrameEvent({ iframeEventElementId, iframeWrapperElementId, sendOnce, eventName }) {
    useEffect(() => {
        const iFrameEventComponent = document.getElementById(iframeEventElementId);
        const iFrameWrapperComponent = document.getElementById(iframeWrapperElementId);
        let isKeyDownEvent = false;
        let eventHasJustBeenSent = false;

        const handleFocusOut = (event) => {
            setTimeout(() => {
                const focussedOnIFrame =
                    document.activeElement === iFrameWrapperComponent.querySelector('iframe');
                // Google Sign In button creates this div
                // iPhones set the focus to this div, not the IFrame
                const focussedOnGoogleDiv =
                    document.activeElement ===
                    iFrameEventComponent.querySelector(`div[aria-label="Sign in with Google"]`);
                if (
                    (focussedOnIFrame || focussedOnGoogleDiv) &&
                    !isKeyDownEvent &&
                    !eventHasJustBeenSent
                ) {
                    sendEvent(eventName);

                    // iPhones trigger the focusout event twice, so to reduce noise we toggle this prop on
                    // then reset it after x time
                    eventHasJustBeenSent = true;
                    setTimeout(() => {
                        eventHasJustBeenSent = false;
                    }, [100]);
                }

                if (!sendOnce && !isKeyDownEvent && event.relatedTarget === null)
                    iFrameEventComponent.focus();
                isKeyDownEvent = false;
            }, 1);
        };

        if (iFrameEventComponent) {
            iFrameEventComponent.focus();

            // If we use the keyboard to navigate we will trigger the blur event
            // In this case we don't want to trigger the analytics event.
            // Also, if sendOnce is false, focus will be sent back to this element, breaking keyboard navigation.
            // So, we check for the keydown event before the blur event and use that to set the condition.
            iFrameEventComponent.addEventListener('keydown', () => {
                isKeyDownEvent = true;
            });

            document.addEventListener('focusout', handleFocusOut);
        }

        return () => document.removeEventListener('focusout', handleFocusOut);
    }, [iframeEventElementId, iframeWrapperElementId, sendOnce, eventName]);

    return null;
}

const IFrameEvent = ({
    children,

    // If false the focus will be reset after the event is sent.
    // Caution advised as it may cause issues with what the user is trying to do.
    // And will make the events noisy
    sendOnce = true,

    eventName,
}) => {
    useIFrameEvent({
        iframeEventElementId: 'iframeevent',
        iframeWrapperElementId: 'iframewrapper',
        sendOnce,
        eventName,
    });

    return (
        // Adding tab-index makes it focusable.
        // Hide it for screen readers
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <div id="iframewrapper">
            <IFrameEventStyled tabIndex={0} id="iframeevent" aria-hidden="true" />
            {children}
        </div>
    );
};

export default IFrameEvent;
