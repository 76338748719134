export const PAGE_ID = 'SettingsMembershipRefund';

export const notEligibleReasons = {
    apiErrorTransactionNotFound: 'apiErrorTransactionNotFound',
    apiErrorRefundMultipleTransactions: 'apiErrorRefundMultipleTransactions',
    apiErrorRefundAlreadyRefunded: 'apiErrorRefundAlreadyRefunded',
    apiErrorRefundUsedServiceApplication: 'apiErrorRefundUsedServiceApplication',
    apiErrorRefundUsedServiceAssignment: 'apiErrorRefundUsedServiceAssignment',
    apiErrorRefundUsedServiceMessage: 'apiErrorRefundUsedServiceMessage',
};
