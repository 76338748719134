import React, { forwardRef, useState } from 'react';
import env from 'environment';
import useCloudinaryImage from 'hooks/useCloudinaryImage';
import * as namedTransformations from 'config/images';

const CloudinaryImage = forwardRef(
    ({ imageId, transformation, placeholderUrl, alt, style, className, options }, ref) => {
        const [hasError, setHasError] = useState(false);
        const src = useCloudinaryImage(imageId, transformation, {
            placeholder: placeholderUrl || env.vars.listings.defaultImage,
            ...options,
        });

        return (
            <img
                ref={ref}
                className={className}
                src={!hasError ? src : placeholderUrl || env.vars.listings.defaultImage}
                alt={alt}
                style={style}
                loading="lazy"
                onLoadStart={() => {
                    setHasError(false);
                }}
                onErrorCapture={() => {
                    setHasError(true);
                }}
            />
        );
    }
);

CloudinaryImage.defaultProps = {
    transformation: namedTransformations.FILM_RATIO_SMALL,
    alt: undefined,
    placeholderUrl: undefined,
    style: undefined,
    className: undefined,
    options: {},
};

export default CloudinaryImage;
