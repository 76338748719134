import { put, takeEvery, all, take } from 'redux-saga/effects';

import api, { settings } from 'api/actions';

import * as sharedActions from 'shared/actions';

import { request, requestOnEnter } from './FilterLocation.actions';

/**
 * searches for places based on string
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* searchPlaces(options, action) {
    const { searchString, featureCodes } = action;

    const defaultFeatureCodes = [
        // Continents
        'CONT',
        // Political entity
        'PCLI',
        'PCL',
        'PCLS',
        'PCLD',
        'PCLIX',
        // Administrative levels
        'ADM1',
        'ADM2',
        'ADM3',
        'ADM4',
        'ADMD',
        // Capital cities
        'PPLC',
        // Populated Places
        'PPL',
        'PPLA',
        'PPLA2',
        'PPLA3',
        'PPLA4',
        // Freely associated state
        'PCLF',
        // 'PPLX', // Section of populated place, do not search
    ];

    // Adds feature classes so locis can lookup for continents
    // L = continents
    // A = countries, regions
    // P = city, towns
    const defaultFeatureClass = ['L', 'A', 'P'];

    const filterFeatureCodes = featureCodes || defaultFeatureCodes;

    const filters = {
        search: searchString,
    };

    // If empty search string, set feature class to something that doesn't exist
    // so that we get no results back
    if (searchString.length === 0) {
        filters.featureClass = 'NA';
    } else {
        filters['featureCode[]'] = filterFeatureCodes;
        filters['featureClass[]'] = defaultFeatureClass;
    }

    // do a separate search when enter is pressed, otherwise do a regular search
    if (options.onEnter) {
        yield put(
            api.search.loadPlaces({
                forceReload: true,
                filters,
                id: 'searchOnEnter',
            })
        );
        const status = yield take((newAction) => {
            // get the appropriate SUCCESS request that matches the id
            if (
                newAction.type === settings.search.loadPlaces.SUCCESS &&
                newAction.action.id === 'searchOnEnter'
            ) {
                return true;
            }
            return false;
        });
        yield put(sharedActions.searchSuggestionsOnEnter.create(status.data));
    } else {
        yield put(
            api.search.loadPlaces({
                forceReload: true,
                filters,
            })
        );
    }
}

function* listenForSearchPlaces() {
    yield all([
        takeEvery(request.ACTION, searchPlaces, {}),
        takeEvery(requestOnEnter.ACTION, searchPlaces, { onEnter: true }),
    ]);
}

export default listenForSearchPlaces;
