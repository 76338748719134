/* eslint-disable max-classes-per-file */
import { format } from 'date-fns';
import {
    Query,
    Filter,
    BooleanFilter,
    IdsFilter,
    GeoFilter,
    GeoPoint,
    GeoBounds,
    GeoHierarchy,
    FreeText,
    ActiveMembership,
} from './query';
import { parseDate } from '../format/date';

// Export the base classes
export {
    Query,
    IdsFilter,
    GeoFilter,
    GeoPoint,
    GeoBounds,
    GeoHierarchy,
    FreeText,
    ActiveMembership,
};

/**
 * A gender filter
 *
 * @export
 * @class Gender
 * @extends {Filter}
 */
export class Gender extends Filter {
    /**
     * Creates an instance of Gender.
     * @param {string} gender
     * @memberof Gender
     */
    constructor(gender) {
        super();
        this.gender = gender;
    }

    /**
     * Add the filter
     *
     * @param {object} filters
     * @returns {object}
     * @memberof Gender
     */
    add(filters) {
        return { ...filters, gender: this.gender };
    }
}

/**
 * An age filter
 *
 * @export
 * @class Age
 * @extends {Filter}
 */
export class Age extends Filter {
    /**
     * Creates an instance of Age.
     * @param {Object} args
     * @param {number} args.minimum
     * @param {number} args.maximum
     * @memberof Age
     */
    constructor(args) {
        super();
        this.args = args;
    }

    /**
     * Add the filter
     *
     * @param {object} filters
     * @returns {object}
     * @memberof Age
     */
    add(filters) {
        return { ...filters, age: this.args };
    }
}

/**
 * A has sitting experience filter
 *
 * @export
 * @class HasExperience
 * @extends {BooleanFilter}
 */
export class HasExperience extends BooleanFilter {
    /**
     * The name of the field
     *
     * @readonly
     * @memberof ActiveMembership
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'hasExperience';
    }
}

/**
 * A has police check filter
 *
 * @export
 * @class PoliceCheck
 * @extends {BooleanFilter}
 */
export class PoliceCheck extends BooleanFilter {
    /**
     * The name of the field
     *
     * @readonly
     * @memberof PoliceCheck
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'policeCheck';
    }
}

/**
 * An worldwide search filter
 *
 * @export
 * @class Worldwide
 * @extends {BooleanFilter}
 */
export class Worldwide extends BooleanFilter {
    /**
     * The name of the field
     *
     * @readonly
     * @memberof ActiveMembership
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'worldwide';
    }

    // eslint-disable-next-line class-methods-use-this
    get default() {
        return false;
    }
}

/**
 * A travelling with partner e.g. as a couple filter
 *
 * @export
 * @class TravellingWithPartner
 * @extends {Filter}
 */
export class TravellingWithPartner extends Filter {
    /**
     * Creates an instance of TravellingWithPartner.
     * @param {Object} args
     * @param {Age} args.age
     * @param {Gender} args.gender
     * @memberof Age
     */
    constructor({ age, gender } = {}) {
        super();
        this.age = age;
        this.gender = gender;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof TravellingWithPartner
     */
    add(filters) {
        // Age and Gender are optional
        filters.travellingWithPartner = {};

        if (this.age !== undefined) {
            filters.travellingWithPartner = this.age.add(filters.travellingWithPartner);
        }

        if (this.gender !== undefined) {
            filters.travellingWithPartner = this.gender.add(filters.travellingWithPartner);
        }

        return filters;
    }
}

/**
 * A travelling with children filter
 *
 * @export
 * @class TravellingWithChildren
 * @extends {Filter}
 */
export class TravellingWithChildren extends Filter {
    /**
     * Creates an instance of TravellingWithChildren.
     * @param {Object} args
     * @param {boolean} args.exclude
     * @param {number} args.ageMinimum
     * @memberof TravellingWithChildren
     */
    constructor({ exclude, ageMinimum } = {}) {
        super();
        this.exclude = exclude;
        this.ageMinimum = ageMinimum;
    }

    /**
     * Add the filter
     *
     * @param {object} filters
     * @returns {object}
     * @memberof TravellingWithChildren
     */
    add(filters) {
        // exclude and ageMinimum are optional
        filters.travellingWithChildren = {};

        if (this.exclude !== undefined) {
            filters.travellingWithChildren.exclude = this.exclude;
        }

        if (this.ageMinimum !== undefined) {
            filters.travellingWithChildren.ageMinimum = this.ageMinimum;
        }

        return filters;
    }
}
/**
 * A verification level filter
 *
 * @export
 * @class TravellingWithChildren
 * @extends {Filter}
 */
export class VerificationLevel extends Filter {
    /**
     * Creates an instance of VerificationLevel.
     * @param {string[]} levels - types of verification
     * @memberof VerificationLevel
     */
    constructor(levels) {
        super();
        this.levels = levels;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof VerificationLevel
     */
    add(filters) {
        filters.verificationLevel = this.levels;

        return filters;
    }
}

/**
 * A reviews filter
 *
 * @export
 * @class Reviews
 * @extends {Filter}
 */
export class Reviews extends Filter {
    /**
     * Creates an instance of Reviews.
     * @param {Object} args
     * @param {number} args.quantityMinimum
     * @param {number} args.ratingMinimum
     * @memberof Reviews
     */
    constructor({ quantityMinimum, ratingMinimum } = {}) {
        super();
        this.quantityMinimum = quantityMinimum;
        this.ratingMinimum = ratingMinimum;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof Reviews
     */
    add(filters) {
        // quantityMinimum and ratingMinimum are optional
        filters.reviews = {};

        if (this.quantityMinimum !== undefined) {
            filters.reviews.quantityMinimum = this.quantityMinimum;
        }

        if (this.ratingMinimum !== undefined) {
            filters.reviews.ratingMinimum = this.ratingMinimum;
        }

        return filters;
    }
}

/**
 * A references filter
 *
 * @export
 * @class Reviews
 * @extends {Filter}
 */
export class References extends Filter {
    /**
     * Creates an instance of References.
     * @param {Object} args
     * @param {number} args.quantityMinimum
     * @memberof References
     */
    constructor({ quantityMinimum } = {}) {
        super();
        this.quantityMinimum = quantityMinimum;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof References
     */
    add(filters) {
        // quantityMinimum is optional
        filters.references = {};

        if (this.quantityMinimum !== undefined) {
            filters.references.quantityMinimum = this.quantityMinimum;
        }

        return filters;
    }
}

/**
 * A photos filter
 *
 * @export
 * @class Photos
 * @extends {BooleanFilter}
 */
export class Photos extends BooleanFilter {
    /**
     * The name of the field
     *
     * @readonly
     * @memberof Photos
     */
    // eslint-disable-next-line class-methods-use-this
    get name() {
        return 'photos';
    }
}

/**
 * An availability filter
 *
 * @export
 * @class Availability
 * @extends {Filter}
 */
export class Availability extends Filter {
    /**
     * Creates an instance of Availability.
     * @param {date} dateFrom - from date in UTC
     * @param {date} dateTo - to date in UTC
     * @memberof Availability
     */
    constructor(dateFrom, dateTo) {
        super();
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof Availability
     */
    add(filters) {
        const dateFrom = parseDate(this.dateFrom);
        const dateTo = parseDate(this.dateTo);

        return {
            ...filters,
            availability: {
                dateFrom: format(dateFrom, 'yyyy-MM-dd'),
                dateTo: format(dateTo, 'yyyy-MM-dd'),
            },
        };
    }
}

/**
 * A preferences filter
 *
 * @export
 * @class Preferences
 * @extends {Filter}
 */
export class Preferences extends Filter {
    /**
     * Creates an instance of Preferences.
     * @param {Object} args
     * @param {string[]} args.localAttractions
     * @param {string[]} args.countries - array of country slugs
     * @memberof Preferences
     */
    constructor({ localAttractions, countries } = {}) {
        super();
        this.localAttractions = localAttractions;
        this.countries = countries;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof Reviews
     */
    add(filters) {
        // localAttractions and countries are optional
        filters.preferences = {};

        if (this.localAttractions !== undefined) {
            filters.preferences.localAttractions = this.localAttractions;
        }

        if (this.countries !== undefined) {
            filters.preferences.countries = this.countries;
        }

        return filters;
    }
}

export class AnimalExperience extends Filter {
    /**
     * Creates an instance of AnimalExperience.
     * @param {string[]} animals - animal name
     * @memberof AnimalExperience
     */
    constructor(animals) {
        super();
        this.animals = animals;
    }

    /**
     * Add the filter
     *
     * @param {Object} filters
     * @returns {Object}
     * @memberof AnimalExperience
     */
    add(filters) {
        filters.animalExperience = this.animals;

        return filters;
    }
}
