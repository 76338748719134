export const Messages = {
    CLOSE_WEBVIEW: {
        create({ closeWebViewEventType } = {}) {
            return {
                type: 'Close Webview',
                payload: {
                    closeWebViewEventType,
                },
            };
        },
    },
    TRACK_ANALYTICS_EVENT: {
        create({ analyticsEventType, properties }) {
            return {
                type: 'Track Analytics Event',
                payload: {
                    analyticsEventType,
                    properties,
                },
            };
        },
    },
};

export const isEnabled = () =>
    Boolean(window.ReactNativeWebView && window.ReactNativeWebView.postMessage);

export const postMessage = (message) => {
    if (isEnabled()) {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
};
