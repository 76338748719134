import { settings as actions } from '../../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        // Load blog area
        case actions.content.loadBlogArea.SUCCESS: {
            const { areaSlug } = action.requestData;
            return {
                ...state,
                [areaSlug]: {
                    ...action.data,
                    posts: {
                        ...(state[areaSlug] ? state[areaSlug].posts : {}),
                    },
                    trendingPosts: {
                        ...(state[areaSlug] ? state[areaSlug].trendingPosts : {}),
                    },
                    isHydrated: true,
                },
            };
        }
        // Load blog area posts
        case actions.content.loadBlogAreaPosts.SUCCESS: {
            const { searchType = 'default', areaSlug } = action.requestData;
            return {
                ...state,
                [areaSlug]: {
                    ...state[areaSlug],
                    posts: {
                        ...(state[areaSlug] ? state[areaSlug].posts : {}),
                        [searchType]: {
                            ...action.data,
                            page: action.action.filters.page,
                            limit: action.action.filters.limit,
                        },
                    },
                },
            };
        }
        // Load blog area trending posts
        case actions.content.loadBlogAreaTrendingPosts.SUCCESS: {
            const { searchType = 'default', areaSlug } = action.requestData;
            return {
                ...state,
                [areaSlug]: {
                    ...state[areaSlug],
                    trendingPosts: {
                        ...(state[areaSlug] ? state[areaSlug].trendingPosts : {}),
                        [searchType]: {
                            ...action.data,
                            page: action.action.filters.page,
                            limit: action.action.filters.limit,
                        },
                    },
                },
            };
        }
        // Load blog area related posts
        case actions.content.loadBlogAreaRelatedPosts.SUCCESS: {
            const { searchType = 'default', areaSlug } = action.requestData;
            return {
                ...state,
                [areaSlug]: {
                    ...state[areaSlug],
                    relatedPosts: {
                        ...(state[areaSlug] ? state[areaSlug].relatedPosts : {}),
                        [searchType]: {
                            ...action.data,
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};
