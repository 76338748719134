import { put, takeEvery, all, take, select, fork } from 'redux-saga/effects';
import * as pageActions from 'containers/Page/actions';
import actions, { settings } from 'api/actions';
import { getLanguage } from 'shared/selectors';
import { getPage } from 'api/selectors/content';
import { fetchSEOFacets } from 'pages/search/components/DynamicFacets/actions';
import {
    fetchPosts,
    fetchPostsSuccess,
} from 'components/content/prismicSlices/ResourceLinks/actions';
import resourceLinksSagas from 'components/content/prismicSlices/ResourceLinks/sagas';
import { getPage as getPageSelector } from './selectors';
import { PAGE_ID } from './constants';

export function* raiseError(statusCode) {
    yield put(pageActions.error.create(PAGE_ID, statusCode));
}

export function* loadPage({ params }) {
    // Not loaded already
    const lang = yield select(getLanguage);

    // See if already loaded
    const page = yield select(getPage, params.slug);

    if (page) {
        if (page?.showFacetLinks) {
            // Load the worldwide facets
            yield put(
                fetchSEOFacets.create({
                    place: null,
                    filters: {},
                    searchLevel: undefined,
                })
            );
        }

        return true;
    }

    yield put(
        actions.content.loadPage({
            filters: {
                lang,
            },
            data: {
                pageSlug: params.slug,
            },
        })
    );

    const {
        action: loadPageAction,
        status: loadPageStatus,
        data: loadPageData,
        statusCode: loadPageStatusCode,
    } = yield take(settings.content.loadPage.DONE);

    // check to see if went to a non existing page
    if (loadPageStatusCode === 404) {
        yield put(pageActions.error.create(PAGE_ID, 404));
        return false;
    }

    if (loadPageStatus !== settings.content.loadPage.SUCCESS) {
        // Dispatch error
        yield raiseError(loadPageAction.statusCode);
        return false;
    }

    // Check if the data returned from Prismic has `resource_links`.
    // If yes we dispatch an action to load all the related blog posts and
    // populate the store with them.
    const resources = loadPageData?.body?.filter((slice) => slice.sliceType === 'resource_links');
    if (resources?.length > 0) {
        yield put(fetchPosts.create(resources?.at(0).items));
        yield take(fetchPostsSuccess.ACTION);
    }

    if (loadPageData.showFacetLinks) {
        // Load the worldwide facets
        yield put(
            fetchSEOFacets.create({
                place: null,
                filters: {},
                searchLevel: undefined,
            })
        );
    }

    return true;
}

export function* setNavBarAndFooterVisibility() {
    const { hideNavbar, hideFooter } = yield select(getPageSelector);
    yield put(
        pageActions.setNavbarOptions.create({
            hideLeftSide: hideNavbar,
            hideRightSide: hideNavbar,
            disableLogoLink: hideNavbar,
        })
    );
    yield put(pageActions.setFooterOptions.create({ hideLinks: hideFooter }));
}

export function* load(action) {
    const loaded = yield loadPage(action);

    if (loaded) {
        yield setNavBarAndFooterVisibility();
        yield put(pageActions.loaded.create(PAGE_ID));
    }
}

function* preload(action) {
    const loaded = yield loadPage(action);

    if (loaded) {
        yield setNavBarAndFooterVisibility();
        yield put(pageActions.preloaded.create(PAGE_ID));
    }
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            load
        ),
        fork(resourceLinksSagas),
    ]);
}
