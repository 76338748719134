// eslint-disable-next-line import/prefer-default-export
export const CURRENCY_CHANGED = {
    create(currencyCode, previousCurrencyCode, page) {
        return {
            type: 'Currency Changed',
            payload: {
                currencyCode,
                previousCurrencyCode,
                page,
            },
        };
    },
};
