import React from 'react';
import { ContainerStyled, InputStyled } from './BaseInput.style';

const BaseInput = ({
    label,
    type,
    value,
    name,
    inputMode,
    error,
    helperText,
    placeholder,
    onChange,
    onBlur,
    onFocus,
    disabled,
    touched,
    inputRef,
    className,
    isErrored,
    ...props
}) => (
    <ContainerStyled>
        <InputStyled
            type={type}
            name={name}
            inputMode={inputMode}
            value={value}
            onChangeHandler={onChange}
            onBlurHandler={onBlur}
            onFocusHandler={onFocus}
            placeholder={placeholder}
            disabled={disabled}
            className={className}
            ref={inputRef}
            isErrored={isErrored}
            {...props}
        />
    </ContainerStyled>
);

export default BaseInput;
