import styled, { css } from 'styled-components';
import Anchor from 'components/Anchor';
import { Button } from 'components/buttons';
import { IconPhone, IconClose } from 'components/Icon';

export const CallButton = styled(Button)(
    ({ theme: { spacing, palette, screenSize } }) => css`
        border-radius: 25px 25px 0 0;
        border-width: 0;
        box-shadow: 0 0 0 0;
        padding: ${spacing[1.5]};
        background-color: ${palette.primarySoftest};

        /* 960 */
        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            padding: ${spacing[2]};
        }
    `
);

export const CallPhoneIcon = styled(IconPhone)(
    ({ theme: { palette, screenSize } }) => css`
        width: 24px;
        height: 24px;

        path {
            fill: ${palette.primaryRegular};
        }

        /* 960 */
        @media screen and (min-width: ${screenSize.smallDesktop}px) {
            width: 28px;
            height: 28px;
        }
    `
);

export const OpenCallContainer = styled.div(
    ({ theme: { palette } }) => css`
        border-radius: 25px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        background-color: ${palette.white};
    `
);

export const OpenCallAnchor = styled(Anchor)(
    ({ theme: { spacing, palette } }) => css`
        border-radius: 25px 0 0 25px;
        border-width: 0;
        box-shadow: 0 0 0 0;
        padding: 0 ${spacing[2]};
        background-color: ${palette.primarySoftest};
    `
);

export const OpenCallIcon = styled(IconPhone)(
    ({ theme: { palette, screenSize } }) => css`
        width: 24px;
        height: 24px;

        path {
            fill: ${palette.primaryRegular};
        }

        /* 375 */
        @media screen and (min-width: ${screenSize.mediumHandset}px) {
            width: 28px;
            height: 28px;
        }
    `
);

export const ContactProfilePictureWrapper = styled.div(
    ({ isInAMajorMarket, theme: { spacing, palette, screenSize } }) => css`
        display: none;
        width: 48px;
        height: 48px;
        align-self: center;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 2px ${palette.borderLight};
        margin: ${spacing[2]};
        margin-right: 0;
        align-self: center;
        border-radius: 50%;

        /* 375 */
        @media screen and (min-width: ${screenSize.mediumHandset}px) {
            ${isInAMajorMarket ? 'display: block;' : null};
        }

        /* 480 */
        @media screen and (min-width: ${screenSize.largeHandset}px) {
            display: block;
        }
    `
);

export const ContactProfilePicture = styled.img(
    () => css`
        width: 100%;
        height: 100%;
        border-radius: 50%;
    `
);

export const NumberMessageContainer = styled.div(
    ({ theme: { spacing } }) => css`
        display: flex;
        flex-direction: column;
        margin: ${spacing[2]};
        align-self: center;
    `
);

export const ContactNumber = styled(Anchor)(
    ({ theme: { typography, palette, fontWeights, screenSize } }) => css`
        ${typography.span};
        font-weight: ${fontWeights.semiBold};
        color: ${palette.primaryRegular};
        padding: 0;
        white-space: normal;
        text-align: left;

        /* 480 */
        @media screen and (min-width: ${screenSize.largeHandset}px) {
            ${typography.h3};
            color: ${palette.primaryRegular};
        }
    `
);

export const ContactMessage = styled.span(
    ({ theme: { typography, palette } }) => css`
        ${typography.smallText};
        color: ${palette.textLight};
    `
);

export const CloseCallButton = styled(Button)(
    ({ theme: { spacing, palette } }) => css`
        border-radius: 0 25px 25px 0;
        border-width: 0;
        border-left: 1px solid ${palette.borderLight};
        box-shadow: 0 0 0 0;
        padding: 0 ${spacing[2]};
        background-color: ${palette.white};
    `
);

export const CloseCallIcon = styled(IconClose)(
    ({ theme: { screenSize } }) => css`
        width: 20px;
        height: 20px;

        /* 375 */
        @media screen and (min-width: ${screenSize.mediumHandset}px) {
            width: 24px;
            height: 24px;
        }
    `
);
