import React, { Fragment } from 'react';
import { RouterPaginationStyled } from './SearchProfilesResultsSection.style';
import SearchResults from '../SearchProfilesResults';

const SearchResultsSection = ({
    t,
    profiles,
    colMediaQueries,
    pageInfo,
    getPageLink,
    onNavigationClick,
    showCBC,
    isAnonOrPartial,
    showProfileVariation = false,
    showFreeTrialExperiment,
    showSittersLookingInYourAreaTestFeatures,
    ownerListingApproved,
    isLoggedIn = false,
}) => (
    <>
        <SearchResults
            showProfileVariation={showProfileVariation}
            profiles={profiles}
            colMediaQueries={colMediaQueries}
            showCBC={showCBC}
            isAnonOrPartial={isAnonOrPartial}
            showFreeTrialExperiment={showFreeTrialExperiment}
            showSittersLookingInYourAreaTestFeatures={showSittersLookingInYourAreaTestFeatures}
            ownerListingApproved={ownerListingApproved}
            isLoggedIn={isLoggedIn}
        />
        {pageInfo.totalItems > pageInfo.itemsPerPage && (
            <RouterPaginationStyled
                currentPage={pageInfo.currentPage}
                totalItems={pageInfo.totalItems}
                itemsPerPage={pageInfo.itemsPerPage}
                maxPagesToDisplay={3}
                url={getPageLink}
                onItemClick={onNavigationClick}
                ariaPageLabel={(page) => t('pages_searchProfiles_gotoPage', { page })}
                ariaCurrentPageLabel={(page) => t('pages_searchProfiles_currentPage', { page })}
                ariaPreviousPageLabel={t('pages_searchProfiles_previousPage')}
                ariaNextPageLabel={t('pages_searchProfiles_nextPage')}
            />
        )}
    </>
);

export default SearchResultsSection;
