import styled from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import { visuallyHide } from 'utils/utils';

export const ListboxStyled = styled.ul`
    position: absolute;
    cursor: pointer;
    min-width: 160px;
    max-height: 400px;
    z-index: ${(props) => zIndex(props) + 1};
    overflow: auto;
    background-color: white;
    box-sizing: border-box;
    list-style: none;
    margin: 0;

    padding: ${({ theme }) => theme.spacing[2]};

    width: 100%;

    border: 1px solid ${({ theme }) => theme.palette.border};
    border-top: none;

    ${({ isVisible }) => (!isVisible ? visuallyHide : '')};
`;

export const SuggestionStyled = styled.li`
    box-sizing: border-box;
    padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[4]}`};
    ${({ highLighted, theme }) =>
        highLighted ? `background-color: ${theme.palette.grey[100]};` : ''};
`;
