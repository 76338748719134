import { all, call, take, put, select } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { Events, track } from 'utils/analytics';
import * as actions from './actions';

export function* loadCountries() {
    const countries = yield select((state) => state.places.countries);

    if (countries?.length > 0) {
        return true;
    }

    yield put(
        apiActions.places.loadCountries({
            forceReload: true,
        })
    );

    const { status } = yield take((res) => res.type === settings.places.loadCountries.DONE);

    return status === settings.places.loadCountries.SUCCESS;
}

export function* loadEmergencyContact() {
    const emergencyContact = yield select(
        (state) => state.account.emergencyContacts.emergencyContact?.data
    );

    if (emergencyContact) {
        return true;
    }

    yield put(
        apiActions.account.loadEmergencyContact({
            forceReload: true,
        })
    );

    const { status } = yield take((res) => res.type === settings.account.loadEmergencyContact.DONE);

    return status === settings.account.loadEmergencyContact.SUCCESS;
}

export function* loadSafetyContact() {
    const safetyContact = yield select(
        (state) => state.account.emergencyContacts.safetyContact?.data
    );

    if (safetyContact) {
        return true;
    }

    yield put(
        apiActions.account.loadSafetyContact({
            forceReload: true,
        })
    );

    const { status, statusCode } = yield take(
        (res) => res.type === settings.account.loadSafetyContact.DONE
    );

    return status === settings.account.loadSafetyContact.SUCCESS || statusCode === 404;
}

export function* handleLoad() {
    const [loadCountriesSuccess, loadSafetyContactSuccess, loadUserContactSuccess] = yield all([
        call(loadCountries),
        call(loadEmergencyContact),
        call(loadSafetyContact),
    ]);

    return loadCountriesSuccess && loadSafetyContactSuccess && loadUserContactSuccess;
}

export function trackSavedEmergencyContact(operationSucceed, payload) {
    if (operationSucceed) {
        track(Events.MEMBER_ADDED_ICE_CONTACT.create(payload));
    }
}

export function trackSavedSafetyContact(operationSucceed, payload) {
    if (operationSucceed) {
        track(Events.MEMBER_ADDED_SAFETY_CONTACT.create(payload));
    }
}

export function* createEmergencyContact(data) {
    yield put(
        apiActions.account.createEmergencyContact({
            forceReload: true,
            data,
        })
    );

    const { status } = yield take(
        (res) => res.type === settings.account.createEmergencyContact.DONE
    );

    return status === settings.account.createEmergencyContact.SUCCESS;
}

export function* updateEmergencyContact(data) {
    yield put(
        apiActions.account.updateEmergencyContact({
            forceReload: true,
            data,
        })
    );

    const { status } = yield take(
        (res) => res.type === settings.account.updateEmergencyContact.DONE
    );

    return status === settings.account.updateEmergencyContact.SUCCESS;
}

export function* createSafetyContact(data) {
    yield put(
        apiActions.account.createSafetyContact({
            forceReload: true,
            data,
        })
    );

    const { status } = yield take((res) => res.type === settings.account.createSafetyContact.DONE);

    return status === settings.account.createSafetyContact.SUCCESS;
}

export function* updateSafetyContact(data) {
    yield put(
        apiActions.account.updateSafetyContact({
            forceReload: true,
            data,
        })
    );

    const { status } = yield take((res) => res.type === settings.account.updateSafetyContact.DONE);

    return status === settings.account.updateSafetyContact.SUCCESS;
}

export function* saveEmergencyContact({ id, name, relationship, phoneNumber: { number, code } }) {
    const hasValidInfo = name || relationship || number || code;

    const data = {
        id,
        name,
        description: relationship,
        phone: number,
        countryId: code,
    };

    if (id) {
        const updatedSucceed = yield call(updateEmergencyContact, data);
        yield call(trackSavedEmergencyContact, updatedSucceed, data);

        return updatedSucceed;
    }

    if (!hasValidInfo) {
        return true;
    }

    const createSucceed = yield call(createEmergencyContact, data);
    yield call(trackSavedEmergencyContact, createSucceed, data);

    return createSucceed;
}

export function* saveSafetyContact({ phoneNumber: { number, code } }) {
    const hasValidInfo = number || code;

    const previousSafetyContact = yield select(
        (state) => state.account.emergencyContacts.safetyContact.data
    );

    const data = {
        phone: number,
        countryId: code,
    };

    if (previousSafetyContact) {
        const updatedSucceed = yield call(updateSafetyContact, data);
        yield call(trackSavedSafetyContact, updatedSucceed, data);

        return updatedSucceed;
    }

    if (!hasValidInfo) {
        return true;
    }

    const createSucceed = yield call(createSafetyContact, data);
    yield call(trackSavedSafetyContact, createSucceed, data);

    return createSucceed;
}

export function* handleSave(action) {
    const { emergencyContact, safetyContact } = action.payload;

    const [saveEmergencyContactSuccess, saveSafetyContactSuccess] = yield all([
        call(saveEmergencyContact, emergencyContact),
        call(saveSafetyContact, safetyContact),
    ]);

    if (saveEmergencyContactSuccess && saveSafetyContactSuccess) {
        yield put(actions.saveSuccess.create());
        return true;
    }

    yield put(actions.saveFailure.create());
    return false;
}
