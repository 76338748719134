import React from 'react';

import RatingContext from '../../context';
import { createRatingStructure } from './utils';

const ANIMATION_TIMEOUT = 350;

function Root({ initialValue, max, labels, children }) {
    const [hoveredRating, setHoveredRating] = React.useState(0);
    const [selectedRating, setSelectedRating] = React.useState(() => {
        const roundedInitialRating = Math.round(initialValue);

        return Math.min(roundedInitialRating, max);
    });
    const [shouldAnimateIcon, setShouldAnimateIcon] = React.useState(false);

    const resetHoveredRating = () => {
        setHoveredRating(0);
    };

    const updateHoveredRating = (value) => {
        setHoveredRating(value);
    };

    const updateSelectedRating = (value) => {
        setSelectedRating(value);
        setShouldAnimateIcon(true);
    };

    React.useEffect(() => {
        let timeout;

        if (shouldAnimateIcon) {
            timeout = setTimeout(() => {
                setShouldAnimateIcon(false);
            }, ANIMATION_TIMEOUT);
        }

        return () => clearTimeout(timeout);
    }, [shouldAnimateIcon]);

    return (
        <RatingContext.Provider
            value={{
                ratingSystem: createRatingStructure(labels, max),
                hoveredRating,
                selectedRating,
                shouldAnimateIcon,
                resetHoveredRating,
                updateHoveredRating,
                updateSelectedRating,
            }}
        >
            {children}
        </RatingContext.Provider>
    );
}

Root.defaultProps = {
    initialValue: 0,
    max: 5,
    labels: undefined,
};

export default Root;
