/* eslint-disable indent */
import styled from 'styled-components';
import Button from 'components/buttons/Button';
import InputLabel from '../../InputLabel';

const ButtonStyled = styled(Button).attrs({
    variant: 'link',
})`
    margin-left: auto;
    &:hover {
        text-decoration: none;
    }
    font-size: 14px;
    padding: 0;
`;

const LabelStyled = styled(InputLabel)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export { ButtonStyled, LabelStyled };
