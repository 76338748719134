import { useState, useEffect } from 'react';

export const ANGULAR_EVENT_NAME = 'angular-event-channel';

/**
 * @typedef {Object} AngularEvent
 * @prop {string} type
 * @prop {Object<string, any>} payload
 */

/**
 * @typedef {Object} AngularCustomEventObject
 * @prop {"angular-event-channel"} type
 * @prop {AngularEvent} detail
 */

/**
 * @param {string | string[]} type The event type (or types if you pass an array of strings) you're going to listen for
 * @param {Object?} options
 * @param {boolean} options.onlyLatest Only send the latest event that come from the angular app
 * @return {Object<string, any>[]}
 */
const useAngularBridge = (
    type,
    options = {
        onlyLatest: true,
    }
) => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        /**
         * @param {AngularEvent} event
         */
        const eventHandler = ({ type: eventType, detail }) => {
            if (eventType !== ANGULAR_EVENT_NAME) {
                return;
            }

            if (typeof type === 'string') {
                if (type !== detail.type) {
                    return;
                }
            } else if (Array.isArray(type)) {
                if (!type.some((t) => t === detail.type)) {
                    return;
                }
            }

            setEvents((prevState) => [...prevState, detail]);
        };

        document.addEventListener(ANGULAR_EVENT_NAME, eventHandler);

        return () => {
            document.removeEventListener(ANGULAR_EVENT_NAME, eventHandler);
        };
    }, [type]);

    if (options.onlyLatest) {
        const latestEvent = events.length > 0 ? events[events.length - 1] : undefined;

        return [latestEvent?.payload, latestEvent?.type];
    }

    return events;
};

export default useAngularBridge;
