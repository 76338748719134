import styled, { css } from 'styled-components';
import { RadioSize } from './Radio.constants';

const generateMargin = (theme, radioFirst, size) => {
    const margin = radioFirst ? 'margin-right' : 'margin-left';
    const value = size === RadioSize.SMALL ? theme.spacing[1] : theme.spacing[2];

    return css`
        ${margin}: ${value};
    `;
};

export const RadioInputStyled = styled.input.attrs({
    type: 'radio',
})`
    appearance: none;
    cursor: pointer;
    border-radius: 50%;
    width: ${({ theme }) => theme.spacing[3]};
    height: ${({ theme }) => theme.spacing[3]};
    ${({ theme, hasRadioFirst, size }) => generateMargin(theme, hasRadioFirst, size)};
    border: 1px solid ${({ theme }) => theme.palette.border};

    :checked {
        // Make a circle that's 12px wide
        background: repeating-radial-gradient(
            ${({ theme }) => theme.palette.primaryRegular},
            ${({ theme }) => theme.palette.primaryRegular} 6px,
            ${({ theme }) => theme.palette.white} 6px,
            ${({ theme }) => theme.palette.white} 100%
        );
    }

    :checked[disabled] {
        // Make a circle that's 12px wide (disabled version)
        background: repeating-radial-gradient(
            ${({ theme }) => theme.palette.border},
            ${({ theme }) => theme.palette.border} 6px,
            ${({ theme }) => theme.palette.white} 6px,
            ${({ theme }) => theme.palette.white} 100%
        );
    }
`;

export const LabelStyled = styled.label`
    display: flex;
    align-items: center;

    cursor: pointer;

    &[disabled] {
        color: ${({ theme }) => theme.palette.textLight};
    }
`;
