import colors, { makeTransparent } from './colors';

const variantFont =
    'Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol';
const defaultFont =
    "'Poppins', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif";
const palette = {
    // Deprecated colour
    primaryDarkest: colors.primary[900].value,
    primaryDark: colors.primary[700].value,
    primaryRegular: colors.primary[500].value,
    primarySoft: colors.primary[300].value,
    // Deprecated colour
    primarySoftest: colors.primary[100].value,
    // Deprecated colour
    secondaryRegular: colors.secondary[500].value,
    // Deprecated colour
    secondarySoft: colors.secondary[300].value,
    // Deprecated colour
    tertiaryRegular: colors.tertiary[500].value,
    // Deprecated colour
    tertiarySoft: colors.tertiary[300].value,
    accent: colors.accent[500].value,
    accentLight: colors.accent[300].value,
    danger: colors.error.value,
    text: colors.greys[700].value,
    textLight: colors.greys[500].value,
    textWhite: colors.white.value,
    link: colors.accent[500].value,
    linkDark: colors.greys[700].value,
    icon: colors.greys[500].value,
    iconDark: colors.greys[700].value,
    borderLight: colors.greys[200].value,
    border: colors.greys[300].value,
    borderDark: colors.greys[500].value,
    // yellow - Deprecated colour
    yellow: colors.quaternary[500].value,
    // Deprecated colour
    lightYellow: colors.quaternary[300].value,
    // hover background color on white
    hoverGrey: colors.greys[200].value,
    // Focus ring color
    focus: colors.focus.value,
    // Highlight color
    highlight: colors.highlight[500].value,
    // monochrome colors when a named variable doesn't quite fit
    white: colors.white.value,
    black: colors.black.value,
    grey: {
        900: colors.greys[900].value,
        700: colors.greys[700].value,
        // Deprecated colour
        600: colors.greys[700].value,
        500: colors.greys[500].value,
        // Deprecated colour
        400: colors.greys[300].value,
        300: colors.greys[200].value,
        // Deprecated colour
        200: colors.greys[200].value,
        100: colors.greys[100].value,
    },
    facebook: '#3b5998',
    facebookMessenger: '#006aff',
};

const theme = {
    palette,
    spacing: {
        // padding and margins
        0: '0',
        0.5: '4px',
        1: '8px',
        1.5: '12px',
        2: '16px',
        2.5: '20px',
        3: '24px',
        3.5: '28px',
        4: '32px',
        4.5: '36px',
        5: '40px',
        6: '48px',
        7: '56px',
        8: '64px',
        9: '72px',
        10: '80px',
        11: '88px',
        12: '96px',
        13: '104px',
        15.5: '124px',
        16: '128px',
        23: '184px',
        30: '240px',
        38: '304px',
        54: '432px',
        80: '640px',
        87: '696px',
        117: '936px',
    },
    sizing: {
        touch: '48px',
    },
    fonts: {
        header: defaultFont,
        body: defaultFont,
        default: defaultFont,
        variant: variantFont,
    },
    fontFamily: defaultFont,
    typography: {
        h1large: {
            'font-family': defaultFont,
            /* font-size: 72px */
            /* line-height: 80px */
            'font-size': '4.5rem',
            'line-height': '1.111111',
            'font-weight': '700',
            color: `${palette.primaryDark}`,
        },
        h1: {
            'font-family': defaultFont,
            /* font-size: 40px */
            /* line-height: 48px */
            'font-size': '2.5rem',
            'line-height': '1.2',
            'font-weight': '700',
            color: `${palette.primaryDark}`,
        },
        h1Variant: {
            'font-family': defaultFont,
            /* font-size: 40px */
            /* line-height: 48px */
            'font-size': '2.5rem',
            'line-height': '1.2',
            'font-weight': '600',
            color: `${palette.primaryDark}`,
        },
        h1small: {
            'font-family': defaultFont,
            /* font-size: 32px */
            /* line-height: 40px */
            'font-size': '2rem',
            'line-height': '1.25',
            'font-weight': '700',
            color: `${palette.primaryDark}`,
        },
        h2Large: {
            'font-family': defaultFont,
            /* font-size: 32px */
            /* line-height: 40px */
            'font-size': '2rem',
            'line-height': '1.25',
            'font-weight': '600',
            color: `${palette.primaryDark}`,
        },
        h2: {
            'font-family': defaultFont,
            /* font-size: 24px */
            /* line-height: 32px */
            'font-size': '1.5rem',
            'line-height': '1.333333',
            'font-weight': '600',
            color: `${palette.primaryDark}`,
        },
        h3: {
            'font-family': defaultFont,
            /* font-size: 20px */
            /* line-height: 32px */
            'font-size': '1.25rem;',
            'line-height': '1.6',
            'font-weight': '600',
            color: `${palette.primaryDark}`,
        },
        h4: {
            'font-family': defaultFont,
            /* font-size: 20px */
            /* line-height: 32px */
            'font-size': '1.25rem;',
            'line-height': '1.6',
            'font-weight': '600',
            color: `${palette.primaryDark}`,
        },
        p: {
            /* font-size: 16px */
            /* line-height: 28px */
            'font-size': '1rem',
            'line-height': '1.75',
            'font-weight': '400',
        },
        focal: {
            /* font-size: 24px */
            /* line-height: 36px */
            'font-size': '1.5rem',
            'line-height': '1.5',
        },
        focalSmall: {
            /* font-size: 20px */
            /* line-height: 32px */
            'font-size': '1.25rem',
            'line-height': '1.6',
            'font-weight': '400',
        },
        span: {
            /* font-size: 16px */
            /* line-height: 28px */
            'font-size': '1rem',
            'line-height': '1.75',
            'font-weight': '400',
        },
        label: {
            /* font-size: 16px */
            /* line-height: 24px */
            'font-size': '1rem',
            'line-height': '1.5',
            'font-weight': '500',
            color: `${palette.text}`,
        },
        blockquote: {
            /* font-size: 40px */
            /* line-height: 48px */
            'font-size': '2.5rem',
            'line-height': '1.2',
            'font-weight': '400',
        },
        smallText: {
            /* font-size: 14px */
            /* line-height: 24px */
            'font-size': '0.875rem',
            'line-height': '1.71428571429',
        },
        reallySmallText: {
            /* font-size: 12px */
            /* line-height: 20px */
            'font-size': '0.75rem',
            'line-height': '1.4285714286',
        },
        smallestText: {
            /* font-size: 10px */
            /* line-height: 14.2px */
            'font-size': '0.625rem',
            'line-height': '1.42',
        },
        a: {
            color: 'blue',
        },
        hero: {
            'font-family': defaultFont,
            /* font-size: 32px */
            /* line-height: 40px */
            'font-size': '2rem',
            'line-height': '1.25rem',
            'font-weight': '700',
        },
    },
    fontSizes: {
        xsmall: '0.625rem',
        small: '0.875rem',
        medSmall: '0.875rem',
        medium: '1rem',
        // Large on updated branding
        medLarge: '1.25rem',
        // Extra large on updated branding
        large: '1.5rem',
        xlarge: '2rem',
        xxlarge: '3rem',
    },
    fontWeights: {
        normal: 400,
        semiBold: 500,
        bold: 600,
        extraBold: 700,
    },
    // https://material.io/guidelines/layout/responsive-ui.html#responsive-ui-breakpoints
    breakpoints: {
        xsmall: '480',
        small: '600',
        medium: '840',
        large: '960',
        xlarge: '1280',
        xxlarge: '1440',
    },
    zIndexLayers: [
        'imageShadow',
        'navigationButtons',
        'disclaimer',
        'stickyFilterBar',
        'topnav',
        'searchFilters',
        'tooltip',
        'modal',
        'lightbox',
    ],
    screenSize: {
        mediumHandset: 375,
        largeHandset: 480,
        smallTablet: 600,
        mediumTablet: 720,
        largeTablet: 800,
        smallDesktop: 960,
        mediumDesktop: 1024,
        largeDesktop: 1280,
        wide: 1440,
        extraWide: 1920,
    },
    grid: {
        base: {
            verticalSpacing: 32,
            horizontalSpacing: 16,
        },
        mediumDesktop: {
            verticalSpacing: 48,
            horizontalSpacing: 32,
        },
    },
    opacityOverlay: {
        light: {
            hover: {
                strength: 0.06,
                color: palette.black,
            },
            active: {
                strength: 0.12,
                color: palette.black,
            },
        },
        dark: {
            hover: {
                strength: 0.12,
                color: palette.white,
            },
            active: {
                strength: 0.24,
                color: palette.white,
            },
            hoverText: {
                strength: 0.48,
                color: palette.white,
            },
        },
    },
    disabledOpacity: 0.54, // adds an opacity to indicate a component is disabled
    activeOpacity: 0.78, // blend value for button hover and active states
    overlayOpacity: 0.64,
    borderRadius: {
        large: '20px',
        regular: '10px',
        button: '10px',
        small: '5px',
    },
};

export { defaultFont, theme as default };
