import {
    IS_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY,
    IS_NOT_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY,
} from '../listing-edit';
import {
    animalMapping,
    commonKeywordMapping,
    isOpenOrLastMinuteAssignment,
    isOpenPublishedAssignment,
    isPastAssignment,
    isCurrentAssignment,
    isUpcomingAssignment,
} from './common';
import { dateSameOrAfter } from '../format/date';
import { getAssignment } from './assignments';

/**
 * User
 * @param user
 * @returns {*}
 */
export const getOwner = (user) => {
    if (!user) return null;

    const {
        id,
        firstName,
        profilePhoto,
        isActive,
        isExpired,
        isRedacted,
        expiryDate,
        membershipType,
        membershipTier,
    } = user;

    let ownerIsActive = isActive;
    if (isActive === null || isActive === undefined) {
        if (expiryDate) {
            ownerIsActive = dateSameOrAfter(expiryDate, new Date().setHours(0, 0, 0, 0));
        } else {
            ownerIsActive = true;
        }
    }

    return {
        ownerId: id,
        ownerName: firstName,
        ownerTier: membershipTier,
        ownerAvatarPhoto: profilePhoto,
        ownerIsActive,
        ownerIsExpired: isExpired || false,
        ownerIsRedacted: isRedacted || false,
        ownerMembershipType: membershipType,
    };
};

// @TODO - This could be done better in the future
const getLocalAttractions = (localAttractions) =>
    localAttractions && !Number.isInteger(localAttractions) ? localAttractions : [];

export const getHomeFeatures = (listing) => {
    const {
        homeType,
        disabledAccess,
        wifiAvailable,
        familyFriendly,
        carIncluded,
        localAttractions,
        isAccessibleByPublicTransport,
    } = listing;

    const listingLocalAttractions = getLocalAttractions(localAttractions);
    const features = [];

    if (homeType) {
        features.push(commonKeywordMapping(homeType));
    }

    if (disabledAccess) {
        features.push('disabledAccess');
    }

    if (wifiAvailable && wifiAvailable !== 'none') {
        features.push(commonKeywordMapping(wifiAvailable));
    }

    if (familyFriendly) {
        features.push('familyFriendly');
    }

    if (localAttractions) {
        listingLocalAttractions.forEach((localAttraction) => {
            features.push(commonKeywordMapping(localAttraction));
        });
    }

    if (carIncluded) {
        features.push('carIncluded');
    }

    if (isAccessibleByPublicTransport) {
        features.push(IS_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY);
    }

    if (isAccessibleByPublicTransport === false) {
        features.push(IS_NOT_ACCESSIBLE_BY_PUBLIC_TRANSPORT_KEY);
    }

    return features;
};

/**
 * Listing
 * @param listing
 * @returns {{openAssignments, pastAssignments}}
 */
// eslint-disable-next-line import/prefer-default-export
export const getListing = (listing) => {
    if (!listing) return listing;

    const {
        id,
        title,
        introduction,
        home,
        responsibilities,
        features,
        assignments,
        animals,
        pets,
        homeType,
        localAttractions,
        location,
        carIncluded,
        familyFriendly,
        familyPreferences,
        disabledAccess,
        wifiAvailable,
        photos,
        videos,
        user,
        published,
        publishedDate,
        isApproved,
        inReview,
        isIncomplete,
        ownerId,
        amenities,
    } = listing;

    const listingLocalAttractions = getLocalAttractions(localAttractions);
    const homeFeatures = getHomeFeatures(listing);

    let data = {
        id,
        title,
        introduction,
        home,
        responsibilities,
        features,
        animals,
        pets,
        location,
        isApproved,
        inReview,
        isIncomplete,
        ownerId,
        homeType: commonKeywordMapping(homeType),
        disabledAccess,
        wifiAvailable: commonKeywordMapping(wifiAvailable),
        familyFriendly,
        familyPreferences,
        localAttractions: listingLocalAttractions.map((item) => commonKeywordMapping(item)),
        carIncluded,
        homeFeatures,
        openAssignments: [],
        pastAssignments: [],
        photos: [],
        mainPhoto: null,
        videos,

        publishedDate: published || publishedDate,
        amenities,
    };

    let petPhotos = [];

    // Assignments
    if (assignments) {
        const allAssignments = assignments.map((assignment) => getAssignment(assignment, listing));
        data = {
            ...data,
            openAssignments: allAssignments.filter((assignment) =>
                isOpenOrLastMinuteAssignment(assignment)
            ),
            publishedAssignment: allAssignments.filter((assignment) =>
                isOpenPublishedAssignment(assignment)
            ),
            pastAssignments: allAssignments.filter((assignment) => isPastAssignment(assignment)),
            upcomingAssignments: allAssignments.filter(
                (assignment) => isCurrentAssignment(assignment) || isUpcomingAssignment(assignment)
            ),
        };
    }

    // Animals
    if (animals) {
        data = {
            ...data,
            animals: animals.map((animal) => ({
                ...animal,
                slug: animalMapping(animal.slug),
            })),
        };
    }

    // Pets -> Animals
    if (pets) {
        const petAnimals = {};
        const mappedPets = [];
        pets.forEach((pet) => {
            const { name: animalType } = pet.animal || {};
            const petType = pet.type || animalType;
            if (!petAnimals[petType]) {
                petAnimals[petType] = {
                    slug: animalMapping(petType),
                    count: 0,
                };
            }
            if (pet.photos) {
                petPhotos = [...petPhotos, ...pet.photos];
            }
            petAnimals[petType].count += 1;
            mappedPets.push({
                ...pet,
                name: pet.name || petType,
                photos: pet.photos || [],
                type: animalMapping(petType),
            });
        });

        data = {
            ...data,
            animals: Object.keys(petAnimals).map((type) => petAnimals[type]),
            pets: mappedPets,
        };
    }

    // User data
    const ownerData = getOwner(user);
    if (ownerData) {
        data = {
            ...data,
            ...ownerData,
        };
    }

    if (photos && photos.length > 0) {
        data = {
            ...data,
            photos,
            mainPhoto: photos[0],
        };
    }

    if (petPhotos.length > 0) {
        petPhotos.forEach((petPhoto) => {
            const photoAlreadyExists = data.photos.find((photo) => photo.id === petPhoto.id);
            if (!photoAlreadyExists) {
                data.photos.push(petPhoto);
            }
        });
    }
    return data;
};
