/* eslint-disable import/prefer-default-export */
import { getOwnerListings } from 'api/selectors/owner';
import { PAGE_ID } from './ProfileDetail.constants';

export const getHasApprovedListing = (state) => {
    const ownerListing = getOwnerListings(state);
    return ownerListing ? Boolean(ownerListing.find((listing) => listing.isApproved)) : null;
};

export const getOwnerListingId = (state) => {
    const ownerListing = getOwnerListings(state);
    return ownerListing.length > 0 ? ownerListing[0].id : null;
};

export const getProfileDetailParams = (state) => {
    const pageInformation = state.page[PAGE_ID];

    if (typeof pageInformation === 'object') {
        return pageInformation.location.params;
    }

    return {};
};

/*
 * Gets sitter looking in your area variant
 */
export const getSittersLookingInYourAreaVariant = (state) =>
    state.pages.search.searchProfiles.sittersLookingInYourAreaVariant;
