import {
    experiments,
    getExperimentalFeatureVariation,
    ANONYMOUS_USER_ID,
} from 'containers/ExperimentalFeature';
import { getMembershipStatus } from 'utils/account';
import { MembershipStatus } from 'api/types/membershipTypes';

export const getSplitVariationByPlanVariation = (planVariation) => {
    if (!planVariation) return 'control';
    const variationMap = {
        'price-variant-1': 'variation1',
        'price-variant-2': 'variation2',
    };

    return variationMap[planVariation];
};

// Return an ExperimentalFeature object representing the currently-active price test (if any).
// At the moment we can only support A/B or A/B/C tests, the backend doesn't support A/B/C/D.
// We will return:
// - If the A/B ExperimentalFeature is enabled it will be returned otherwise
// - The A/B/C ExperimentalFeature regardless of whether or not it is enabled
// Pass this object to `getPlanVariation` to get the variation string (or null) that is expected by the API
export const getPlanPricingExperiment = (account, split) => {
    const userId = account && account.details.id ? account.details.id : ANONYMOUS_USER_ID;
    let pricingExperimentAB = getExperimentalFeatureVariation({
        experiment: experiments.PRICE_TEST_AB,
        userId,
        split,
    });

    let pricingExperimentABC = getExperimentalFeatureVariation({
        experiment: experiments.PRICE_TEST_ABC,
        userId,
        split,
    });

    // If the user is a paid member, we get their splits variation by their plan.
    if (
        account &&
        account.membershipPlan &&
        getMembershipStatus(account.membershipPlan) === MembershipStatus.MEMBER
    ) {
        pricingExperimentAB = pricingExperimentAB.enabled
            ? {
                  ...pricingExperimentAB,
                  variation: getSplitVariationByPlanVariation(account.membershipPlan.variation),
              }
            : pricingExperimentAB;

        pricingExperimentABC = pricingExperimentABC.enabled
            ? {
                  ...pricingExperimentABC,
                  variation: getSplitVariationByPlanVariation(account.membershipPlan.variation),
              }
            : pricingExperimentABC;
    }

    // If there's an active AB test then return it otherwise return the ABC test (which may also be disabled)
    return pricingExperimentAB.enabled
        ? { key: experiments.PRICE_TEST_AB, ...pricingExperimentAB }
        : { key: experiments.PRICE_TEST_ABC, ...pricingExperimentABC };
};

// Convert the given ExperimentalFeature in to the correct variation string (or null) to be passed to the API
export const getPlanVariation = (pricingExperiment) => {
    if (!pricingExperiment || !pricingExperiment.enabled) return null;

    // Based on the price test variation we will pass different params to the API
    const priceVariantMap = {
        control: null,
        variation1: 'price-variant-1',
        variation2: 'price-variant-2',
    };

    return priceVariantMap[pricingExperiment.variation];
};

export const isBalanceRemainingOnPlan = (incompleteMembershipPlan = {}) => {
    const hasFullDiscount = incompleteMembershipPlan?.discount === 100;
    const hasFullCredit =
        incompleteMembershipPlan?.credit >= incompleteMembershipPlan?.originalPrice;

    return !(hasFullDiscount || hasFullCredit);
};
