import React from 'react';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { retry } from 'utils/ssr';
import asPage from 'containers/Page';
import ExperimentalFeature, { experiments, userTypes } from 'containers/ExperimentalFeature';
import { mapStateToProps, mapDispatchToProps } from 'containers/PlansPage/connect';
import { PAGE_IDS } from 'containers/PlansPage/constants';

const LoadablePlans = loadable(() => retry(() => import('containers/PlansPage')));
const LoadableNewPlans = loadable(() => retry(() => import('../ExplorePlans/NewPlansPage')));

const ConnectedLoadablePlans = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(LoadablePlans));

const ConnectedLoadableNewPlans = connect(null, mapDispatchToProps)(LoadableNewPlans);

export default connect()(
    asPage(
        (props) => (
            <ExperimentalFeature
                experiment={experiments.PRICING_PAGE_CARD_REDESIGN}
                excludeCombo={[userTypes.PaidUser]}
                control={<ConnectedLoadablePlans {...props} variant={PAGE_IDS.PRICING} />}
                variation1={<ConnectedLoadableNewPlans {...props} />}
            />
        ),
        PAGE_IDS.PRICING,
        true,
        true
    )
);
