import React from 'react';
import Redirect from 'components/Redirect';
import ReferAFriend from 'pages/ReferAFriend';
import Dashboard from './Dashboard';
import Profile from './Profile';
import Checkout from './checkout/Checkout';
import Confirmation from './checkout/Confirmation';
import CheckoutActivation from './checkout/CheckoutActivation';
import paths from './route-paths';

export default [
    // The wildcard pattern we were using for the profile route was not matching for the
    // welcome guide when it did not have an assignment, this resulted in a 404 error.
    // Hence we have added a new route specifically to match this.
    {
        path: paths.profile.welcomeGuide,
        component: Profile,
    },
    {
        path: paths.profile.dashboard,
        component: Dashboard,
    },
    {
        path: paths.profile.wildcard,
        component: Profile,
    },
    {
        path: paths.signUp.wildcard,
        component: ({ staticContext }) => (
            <Redirect to={paths.checkout.payment} context={staticContext} />
        ),
    },
    {
        path: paths.referAFriend,
        component: ReferAFriend,
    },
    {
        path: paths.checkout.payment,
        exact: true,
        component: Checkout,
    },
    {
        path: paths.checkout.activation,
        exact: true,
        component: CheckoutActivation,
    },
    {
        path: paths.checkout.confirmation,
        exact: true,
        component: Confirmation,
    },
];
