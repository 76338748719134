import styled from 'styled-components';
import { P } from 'typography';

export const WrapperStyled = styled.div`
    display: flex;
    height: 52px;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.borderRadius.regular};
    cursor: pointer;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        flex-direction: row;
    }
`;

export const TitleDescriptionStyled = styled(P)`
    margin-top: 0;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const IconContainerStyled = styled.div`
    flex-grow: 0;
    padding-left: ${({ theme }) => theme.spacing[2]};
    padding-right: ${({ theme }) => theme.spacing[2]};

    & svg {
        fill: ${({ theme }) => theme.palette.text};
    }
`;

export const LinkURLStyled = styled.div`
    flex-grow: 1;
    margin-right: ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.palette.text};
`;

export const CopyButtonIconContainerStyled = styled.span`
    margin-right: ${({ theme }) => theme.spacing[1]};

    & svg {
        fill: ${({ theme }) => theme.palette.grey[700]};
    }
`;
