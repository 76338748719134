/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const CloseIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="M3.293 15.293a1 1 0 1 0 1.414 1.414L10 11.414l5.293 5.293a1 1 0 1 0 1.414-1.414L11.414 10l5.293-5.293a1 1 0 1 0-1.414-1.414L10 8.586 4.707 3.293a1 1 0 0 0-1.414 1.414L8.586 10l-5.293 5.293Z"
        />
    </svg>
);
const ForwardRef = forwardRef(CloseIcon);
export default ForwardRef;
