// eslint-disable-next-line import/prefer-default-export
export const CHECK_REFUND_STATUS = {
    create({ isEligible, notEligibleReason }) {
        return {
            type: 'Check Refund Status',
            payload: {
                isEligible,
                ...(notEligibleReason && { notEligibleReason }),
            },
        };
    },
};
