import styled from 'styled-components';

const StyledIcon = styled.span`
    &,
    & span {
        width: 100%;
        height: 100%;
    }

    path {
        fill: transparent;
        stroke: ${({ theme }) => theme.palette.border};
        stroke-width: 10%;

        transition: all 250ms;
    }

    &[data-highlighted='true'] path {
        fill: ${({ theme, variant }) => theme.palette[variant]};
        stroke-width: 0;
    }
`;

export { StyledIcon };
