import { settings as actions } from '../actions';

export const initialState = [];

const getSwitchPrice = ({ state, plan}) => {
    // If the switch price has already been loaded we use that value
    // otherwise return null.
    // This fixes a race condition bug where the plans are reloaded after loadSwitchDetails on navigation back
    // which resets switchPrice value. https://trustedhousesitters.atlassian.net/browse/GROW-1529
    const planFromState = state.find(statePlan => {
        return statePlan.id === plan.id
    });
    
    let switchPrice = null;

    if (planFromState && planFromState.switchPrice) {
        switchPrice = planFromState.switchPrice;
    }
    
    return switchPrice;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.plans.load.SUCCESS:
            return [
                ...action.data.map(plan => ({
                    // @TODO - Fixme when we unify the api
                    ...plan,
                    ...plan.promoCode,

                    // To keep this object a consistent shape we will add all the fields we
                    // will use for plan price
                    switchPrice: getSwitchPrice({ state, plan }),
                    membershipStateClass: null,
                }))
            ];
        case actions.plans.loadSwitchDetails.SUCCESS:
            return state.map((existingPlan) => {
                const matchingSwitchPlan = action.data.find(switchPlan => switchPlan.id === existingPlan.id);
                
                return !matchingSwitchPlan
                    ? existingPlan
                    : {
                        ...existingPlan,

                        switchPrice: matchingSwitchPlan.price,
                        membershipStateClass: matchingSwitchPlan.membershipStateClass,
                    };
            });
        default:
            return state;
    }
};
