import loadable from '@loadable/component';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import actions from 'api/actions';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { redirectIfLoggedOut } from 'pages/user/helpers';
import { PAGE_ID } from './Refund.constants';
import { getIsEligible, getIsRefunded, getNotEligibleReason } from './selectors';

const LoadableRefund = loadable(() => retry(() => import('./Refund')));

const mapStateToProps = (state) => ({
    isEligible: getIsEligible(state),
    isRefunded: getIsRefunded(state),
    notEligibleReason: getNotEligibleReason(state),
});

const mapDispatchToProps = {
    requestRefund: actions.account.createRefundRequest,
};

const authorisationRedirects = [redirectIfLoggedOut];

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    asPage(withTranslation()(LoadableRefund), PAGE_ID, false, false, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
