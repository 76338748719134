import { LOCATION_CHANGE } from 'connected-react-router';

let previousPayload = {};

const historyMiddleware = () => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        const newAction = {
            ...action,
            payload: {
                ...action.payload,
                location: {
                    ...action.payload?.location,
                    previousLocation: previousPayload,
                },
            },
        };
        previousPayload = action.payload?.location;
        return next(newAction);
    }
    return next(action);
};

export default historyMiddleware;
