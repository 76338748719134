/* eslint-disable import/prefer-default-export */

import environment from 'environment';

const isDevelopmentMode = () =>
    environment.vars.mode === 'development' || environment.vars.mode === 'developmentStaging';

const isProductionMode = () => environment.vars.mode === 'production';

const getFullHostname = () => `${environment.vars.protocol}${environment.vars.hostname}`;

const isProductionEnvironment = () => environment.vars.env === 'production';

export { isDevelopmentMode as default, getFullHostname, isProductionEnvironment, isProductionMode };
