import { baseUrl, v3ApiUrl } from './constants';

export default {
    // POST (username, password), // session/authenticate/
    authenticate: `${baseUrl}api-token-auth/`,
    authenticateApple: `${v3ApiUrl}auth/apple/`,
    authenticateFacebook: `${v3ApiUrl}auth/facebook/`,
    authenticateGoogle: `${v3ApiUrl}auth/google/`,
    authenticateCustomer: `${v3ApiUrl}auth/customer/`,

    authenticateSso: {
        // POST - username, password, client
        _: `${v3ApiUrl}auth/sso/`,
        // POST - facebook credentials, client
        facebook: `${v3ApiUrl}auth/sso/facebook/`,
        // POST - jwt token, client
        token: `${v3ApiUrl}auth/sso/token/`,
    },

    // DELETE: logout
    logout: `${v3ApiUrl}auth/`,

    // POST (token) // session/validate/
    validate: `${baseUrl}api-token-refresh/`,
};
