import React, { useEffect, useState, Fragment } from 'react';
import { match } from 'path-to-regexp';
import { MembershipStatus } from 'api/types';
import { addWeeks, lastDayOfMonth, endOfDay } from 'date-fns';
import { Events, track } from 'utils/analytics';
import { SessionStorage } from 'utils/sessionStorage';

import NotificationBar from '../NotificationBar';
import {
    IS_CARD_EXPIRY_NOTIFICATION_VIEWED_KEY,
    membershipPageUrl,
} from './CardExpiryNotification.constants';

const matchMembershipPage = match(membershipPageUrl, {
    decode: decodeURIComponent,
});

const isAutorenewingInXWeeks = ({ autorenewDate, amountOfWeeks }) => {
    const currentDate = new Date();
    const dateIn4Weeks = addWeeks(currentDate, amountOfWeeks);

    if (autorenewDate <= dateIn4Weeks) {
        return true;
    }
    return false;
};

const CardExpiryNotification = ({ t, location, membershipPlan, membershipStatus, cardDetails }) => {
    const [visible, setVisible] = useState(false);
    const isUserRenewingIn4Weeks = isAutorenewingInXWeeks({
        autorenewDate: new Date(membershipPlan.expiryDate),
        amountOfWeeks: 4,
    });
    const currentDate = new Date();
    const cardExpiryDate = endOfDay(
        lastDayOfMonth(new Date(`${cardDetails.expiryYear}-${cardDetails.expiryMonth}`))
    );

    global.cardExpiryAngularPathChange = (locationUrl) => {
        if (matchMembershipPage(locationUrl.pathname)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        if (!SessionStorage.getItem(IS_CARD_EXPIRY_NOTIFICATION_VIEWED_KEY)) {
            SessionStorage.setItem(IS_CARD_EXPIRY_NOTIFICATION_VIEWED_KEY, false);
        }
    }, []);

    useEffect(() => {
        const showCardExpiryNotification = () => {
            setVisible(true);
            SessionStorage.setItem(IS_CARD_EXPIRY_NOTIFICATION_VIEWED_KEY, true);

            track(Events.VIEWED_CARD_EXPIRY_PROMPT.create({ description: 'card expiry banner' }));
        };

        if (!matchMembershipPage(location.pathname)) {
            if (
                !SessionStorage.getItem(IS_CARD_EXPIRY_NOTIFICATION_VIEWED_KEY) &&
                membershipStatus === MembershipStatus.MEMBER &&
                membershipPlan.isAutorenewing &&
                isUserRenewingIn4Weeks &&
                cardExpiryDate < currentDate
            ) {
                showCardExpiryNotification();
            }
        }
    }, [
        location.pathname,
        membershipStatus,
        membershipPlan.isAutorenewing,
        isUserRenewingIn4Weeks,
        currentDate,
        cardExpiryDate,
        visible,
    ]);

    if (visible) {
        return (
            <NotificationBar
                linkText={t('components_cardExpiry_body')}
                linkUrl={membershipPageUrl}
                underlinedText={t('components_cardExpiry_underlined')}
                target="_self"
                textColour="#333333"
                backgroundColour="#ffb30f"
                onClick={() => {
                    setVisible(false);
                    track(Events.BUTTONCLICK.create({ description: 'card expiry banner' }));
                }}
                onClose={() => setVisible(false)}
            />
        );
    }
    return <></>;
};

export default CardExpiryNotification;
