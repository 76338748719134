import { settings as actions } from '../../actions';

import {
    baseInitialResultsListState, loadedResultsUniqueListReducer,
} from '../common';

export const initialState = { ...baseInitialResultsListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadConfirmedAssignments.SUCCESS:
            return {
                ...state,
                results: action.data.upcoming.map(assignment => assignment.id),
            };
        case actions.sitter.loadUpcomingAssignments.SUCCESS:
            return loadedResultsUniqueListReducer(state, action);
        default:
            return state;
    }
};
