import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from 'pages/search/SearchProfiles/actions';
import { defaultLocation } from 'pages/search/constants';
import { getGeoHierarchy } from 'api/helpers/search/common';
import { getGeopoint } from 'pages/search/helpers';
import { ActionTypes } from './constants';
import {
    HeroContainer,
    HeroContent,
    HeroImage,
    HeroImageContainer,
    HeroSearch,
    HeroSearchButton,
    HeroSearchInput,
    HeroSearchInputContainer,
    HeroSearchLabel,
    HeroSubtitle,
    HeroTitle,
} from './HeroWithSearch.style';

function HeroWithSearch({
    image,
    actionLabel,
    actionType,
    inputLabel,
    inputPlaceholder,
    title,
    subtitle,
}) {
    const [searchLocation, setSearchLocation] = useState(null);
    const dispatch = useDispatch();

    function handleClick() {
        if (actionType === ActionTypes.PROFILES_SEARCH) {
            const hasLocation = searchLocation && Object.keys(searchLocation).length;
            const filters = {};
            if (hasLocation) {
                // if the search is for 'anywhere' we don't need geopoint or geohierarchy
                if (searchLocation.name === defaultLocation.name) {
                    filters.worldwide = true;
                } else {
                    // this helper function uses the featureCode to determine what level of geoHierarchy it is
                    // and returns the appropriate country/admin slugs as using empty values breaks the search
                    const geoHierarchy = getGeoHierarchy(searchLocation);
                    // this replicates what we do in the search page location search
                    // we use featureClass to determine whether we should use geoPoint (cities)
                    // or geoHierarchy (regions)
                    if (
                        (searchLocation.featureClass === 'A' ||
                            searchLocation.featureClass === 'L') &&
                        geoHierarchy
                    ) {
                        filters.geoHierarchy = geoHierarchy;
                    } else if (searchLocation.location && searchLocation.location.lon) {
                        filters.geoPoint = getGeopoint(searchLocation.location);
                    }
                }
            }
            dispatch(actions.searchFiltersUpdated.create(filters));
        }
    }

    return (
        <HeroContainer>
            <HeroImageContainer>
                <source media="(min-width: 768px)" srcSet={image.url} />
                <HeroImage src={image.mobile?.url} alt={image.alt} />
            </HeroImageContainer>

            <HeroContent>
                <HeroTitle>{title}</HeroTitle>
                <HeroSubtitle>{subtitle}</HeroSubtitle>

                <HeroSearch>
                    <HeroSearchLabel>{inputLabel}</HeroSearchLabel>

                    <HeroSearchInputContainer>
                        <HeroSearchInput
                            inputValue={searchLocation?.name || ''}
                            placeholder={inputPlaceholder}
                            id="hero-search"
                            type="profile"
                            onChange={(locationObj) => setSearchLocation(locationObj)}
                        />
                        <HeroSearchButton type="button" onClick={() => handleClick()}>
                            {actionLabel}
                        </HeroSearchButton>
                    </HeroSearchInputContainer>
                </HeroSearch>
            </HeroContent>
        </HeroContainer>
    );
}

HeroWithSearch.defaultProps = {
    image: {},
    actionLabel: '',
    actionType: '',
    inputLabel: '',
    inputPlaceholder: '',
    title: '',
    subtitle: '',
};

HeroWithSearch.propTypes = {
    image: PropTypes.shape({
        url: PropTypes.string,
        mobile: PropTypes.shape({
            url: PropTypes.string,
        }),
        alt: PropTypes.string,
    }),
    actionLabel: PropTypes.string,
    actionType: PropTypes.string,
    inputLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export default HeroWithSearch;
