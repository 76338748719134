// eslint-disable-next-line import/prefer-default-export
export const OWNER_CREATED_INVITATION = {
    create(invitation) {
        const { assignmentId, assignment, listingId, profile } = invitation;
        const { startDate, endDate, isPrivate } = assignment;
        const payload = {
            assignmentId,
            listingId,
            sitterUserId: profile.user && profile.user.id,
            startDate,
            endDate,
            isPrivate,
        };

        return {
            type: 'Owner Created Invitation',
            payload,
        };
    },
};
