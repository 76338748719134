import styled from 'styled-components';
import Panel from 'components/layouts/Panel';
import TrustPilotReviewQuote from 'components/ReferAFriend/components/TrustPilotReviewQuote';

export const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing[8]};
    padding: ${({ theme }) => theme.spacing[6]} 0;
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) => theme.palette.grey[700]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[30]};
        padding: ${({ theme }) => theme.spacing[12]} 0;
    }
`;

export const ContainerStyled = styled(Panel)`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;

export const ContentLeftStyled = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        max-width: 460px;
        margin-right: ${({ theme }) => theme.spacing[6]};
    }
`;

export const ContentRightStyled = styled.div`
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: auto;
    }
`;

export const TrustPilotReviewQuoteStyled = styled(TrustPilotReviewQuote)`
    display: none;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        position: absolute;
        display: block;
        bottom: -138px;
    }
`;
