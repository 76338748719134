import { combineReducers } from 'redux';

import listing from './listing/reducers';
import profile from './profile/reducers';
import settings from './settings/reducers';
import verifications from './Verifications/reducers';
import assignments from './assignments/reducers';

export default combineReducers({
    assignments,
    listing,
    profile,
    settings,
    verifications,
});
