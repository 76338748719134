import { settings as actions } from '../../actions';

import {
    createdResultReducer, updatedResultReducer, deletedResultReducer,
} from '../common';

export const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadAvailabilities.SUCCESS:
            return [
                ...action.data,
            ];
        case actions.sitter.createAvailability.SUCCESS:
            return createdResultReducer(state, action);
        case actions.sitter.updateAvailability.SUCCESS:
            return updatedResultReducer(state, action);
        case actions.sitter.removeAvailability.SUCCESS:
            return deletedResultReducer(state, action, 'availabilityId');
        default:
            return state;
    }
};
