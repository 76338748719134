import React from 'react';

import { useRating } from 'components/Rating/context';
import { ContainerWrapper } from './Container.style';
import { shouldHighlight } from './utils';

function Container({ children, size, ...props }) {
    const { ratingSystem, hoveredRating, selectedRating, shouldAnimateIcon, resetHoveredRating } =
        useRating();

    return (
        <ContainerWrapper onMouseLeave={resetHoveredRating} size={size} {...props}>
            {ratingSystem.map((_, index) => {
                const rating = index + 1;
                const isHighlighted = shouldHighlight(rating, hoveredRating, selectedRating);
                const shouldAnimate = shouldAnimateIcon && selectedRating === rating;

                return (
                    <React.Fragment key={rating}>
                        {children({
                            rating,
                            isHighlighted,
                            shouldAnimate,
                        })}
                    </React.Fragment>
                );
            })}
        </ContainerWrapper>
    );
}

Container.displayName = 'RatingContainer';

export default Container;
