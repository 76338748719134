/* eslint-disable import/prefer-default-export */

const getResponseStatus = (state) => state.pages.user.listing.editDates.responseStatus;
const getUpdateId = (state) => state.pages.user.listing.editDates.updateId || 0;
const getBoostedAssignmentResponseStatusCode = (state) =>
    state.pages.user.listing.editDates.boostedAssignmentResponseStatusCode;
const getBoostedAssignmentResponseTimestamp = (state) =>
    state.pages.user.listing.editDates.boostedAssignmentResponseTimestamp;
const getLoadOpenAssignmentsUpdateId = (state) =>
    state.pages.user.listing.editDates.loadOpenAssignmentsUpdateId;

export {
    getResponseStatus,
    getUpdateId,
    getBoostedAssignmentResponseStatusCode,
    getBoostedAssignmentResponseTimestamp,
    getLoadOpenAssignmentsUpdateId,
};
