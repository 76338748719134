import { settings as actions } from '../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadProfiles.SUCCESS: {
            const { searchType = 'default', startingAfter, facetsOnly = false } = action.requestData;
            // Append search results
            if (startingAfter) {
                return {
                    ...state,
                    [searchType]: {
                        results: facetsOnly ? [] : [
                            ...state[searchType].results,
                            ...action.data.results.map(profile => profile.id),
                        ],
                        facets: state[searchType].facets,
                        total: action.data.total,
                    },
                };
            }
            return {
                ...state,
                [searchType]: {
                    results: facetsOnly ? [] : [
                        ...action.data.results.map(profile => profile.id),
                    ],
                    facets: { ...action.data.facets },
                    total: action.data.total,
                },
            };
        }
        case actions.search.loadProfiles.RESET: {
            const { searchType = 'default' } = action.requestData;

            const { [searchType]: omittedKey, ...restOfState } = state;

            return restOfState;
        }
        default:
            return state;
    }
};
