import React from 'react';
import { Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import Root from './Root';
import LayoutWithNavbar from './layouts/LayoutWithNavbar';
import WhatPetsDoYouHave from './pages/WhatPetsDoYouHave';
import HowManyPetsDoYouHave from './pages/HowManyPetsDoYouHave';
import DoYouNeedSitterWithSpecificExperience from './pages/DoYouNeedSitterWithSpecificExperience';
import PetsAreHappierAtHome from './pages/PetsAreHappierAtHome';
import Travel from './pages/Travel/pages/Travel';
import TravelDates from './pages/Travel/pages/Dates';
import TravelTripLength from './pages/Travel/pages/TripLength';
import TravelPetCare from './pages/Travel/pages/PetCare';
import Location from './pages/Location';
import LoadingResults from './pages/LoadingResults';
import paths from './route-paths';

const GenericTravelSegment = loadable(() =>
    import('./pages/Travel/pages/components/GenericTravelSegment/GenericTravelSegment')
);

const Placeholder = loadable(() => import('./shared/Placeholder'));

const routes = {
    path: paths.index,
    component: Root,
    routes: [
        {
            path: paths.loadingResults,
            component: LoadingResults,
        },
        {
            path: paths.index,
            component: LayoutWithNavbar,
            routes: [
                {
                    path: paths.index,
                    exact: true,
                    component: WhatPetsDoYouHave,
                    handle: {
                        step: 1,
                        segment: loadable(() => import('./pages/WhatPetsDoYouHave/Segment')),
                    },
                },
                {
                    path: paths.numberOfPets,
                    component: HowManyPetsDoYouHave,
                    handle: {
                        step: 2,
                        segment: loadable(() => import('./pages/HowManyPetsDoYouHave/Segment')),
                    },
                },
                {
                    path: paths.sitterExperience,
                    component: DoYouNeedSitterWithSpecificExperience,
                    handle: {
                        step: 3,
                        segment: loadable(() =>
                            import('./pages/DoYouNeedSitterWithSpecificExperience/Segment')
                        ),
                    },
                },
                {
                    path: paths.petsAreHappierAtHome,
                    component: PetsAreHappierAtHome,
                    handle: {
                        step: 4,
                        segment: loadable(() => import('./pages/PetsAreHappierAtHome/Segment')),
                    },
                },
                {
                    path: paths.travel.index,
                    exact: true,
                    component: Travel,
                    handle: {
                        step: 5,
                        segment: loadable(() => import('./pages/Travel/pages/Travel/Segment')),
                    },
                },
                {
                    path: paths.travel.dates,
                    component: TravelDates,
                    handle: {
                        step: 5.34,
                        segment: GenericTravelSegment,
                    },
                },
                {
                    path: paths.travel.tripLength,
                    component: TravelTripLength,
                    handle: {
                        step: 5.67,
                        segment: GenericTravelSegment,
                    },
                },
                {
                    path: paths.travel.petCare,
                    component: TravelPetCare,
                    handle: {
                        step: 5.5,
                        segment: GenericTravelSegment,
                    },
                },
                {
                    path: paths.location,
                    component: Location,
                    handle: {
                        step: 6,
                        segment: loadable(() => import('./pages/Location/Segment')),
                    },
                },
                {
                    path: paths.register,
                    component: () => <div>register</div>,
                    handle: {
                        segment: Placeholder,
                    },
                },
                {
                    path: '*',
                    render: () => <Redirect to={paths.index} />,
                },
            ],
        },
        {
            path: '*',
            render: () => <Redirect to={paths.index} />,
        },
    ],
};

export default routes;
