import UAParser from 'ua-parser-js';
import * as constants from './constants';

const mapUserAgentToConstants = (mapObject, name, defaultValue) => {
    const constant = Object.entries(mapObject).find(([, value]) => {
        if (value.some((type) => type.includes(name))) {
            return true;
        }

        return false;
    });

    return constant ? constant[0] : defaultValue;
};

export default class UserAgent {
    #ua = null;

    #data = {
        browser: {
            name: constants.Browser.other,
            version: 'unknown',
        },
        device: {
            name: constants.Device.other,
            vendor: 'unknown',
        },
        os: {
            name: constants.OS.unknown,
            version: 'unknown',
        },
    };

    constructor(header) {
        if (typeof header !== 'string') {
            throw new Error('Header needs to be a string');
        }

        this.#parse(header);
    }

    /**
     * Current `user-agent` header string
     * @returns {string | null}
     */
    get header() {
        return this.#ua.getUA();
    }

    /**
     * Get the parsed browser information from `user-agent`
     * @returns {string}
     */
    get browser() {
        return this.#data.browser;
    }

    /**
     * Get the parsed device information from `user-agent`
     * @returns {string}
     */
    get device() {
        return this.#data.device;
    }

    /**
     * Get the parsed OS information from `user-agent`
     * @returns {string}
     */
    get os() {
        return this.#data.os;
    }

    /**
     * Get if the current `user-agent` is valid and can be used
     * @returns {boolean}
     */
    isValid() {
        return !!this.#ua;
    }

    /**
     * Check the current parsed browser information
     * @param {string} browser
     * @returns {boolean}
     */
    isBrowser(browser) {
        return this.#data.browser.name === browser;
    }

    /**
     * Check the current parsed device information
     * @param {string} device
     * @returns {boolean}
     */
    isDevice(device) {
        return this.#data.device.name === device;
    }

    /**
     * Check the current parsed OS information
     * @param {string} os
     * @returns {boolean}
     */
    isOS(os) {
        return this.#data.os.name === os;
    }

    /**
     * Check if the current `user-agent` is a mobile device
     * @returns {boolean}
     */
    isMobile() {
        return (
            this.#data.device.name === constants.Device.mobile ||
            this.#data.device.name === constants.Device.tablet
        );
    }

    /**
     * Check if the current `user-agent` is a desktop (or unknown) device
     * @returns {boolean}
     */
    isDesktop() {
        return (
            this.#data.device.name === constants.Device.desktop ||
            this.#data.device.name === constants.Device.other
        );
    }

    /**
     * Parse the current header information to get the desired information
     * we need from the `user-agent` header
     * @param {string} header The `user-agent` header string
     */
    #parse(header) {
        this.#ua = new UAParser(header);

        const userBrowser = this.#ua.getBrowser();
        const userDevice = this.#ua.getDevice();
        const userOS = this.#ua.getOS();

        this.#data = {
            browser: {
                name: mapUserAgentToConstants(
                    constants.BrowserTypes,
                    userBrowser.name,
                    constants.Browser.other
                ),
                version: userBrowser.version,
            },
            device: {
                name: mapUserAgentToConstants(
                    constants.DeviceTypes,
                    userDevice.type,
                    constants.Device.desktop
                ),
                vendor: userDevice.vendor,
            },
            os: {
                name: mapUserAgentToConstants(constants.OSTypes, userOS.name, constants.OS.unknown),
                version: userOS.version,
            },
        };
    }
}
