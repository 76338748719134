import { put, takeEvery, all, take, call, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import actions, { settings } from 'api/actions';
import config from 'api/config';
import { AVAILABLE_ACTION_STATUSES } from 'api/actions/constants';
import { getSession } from 'api/selectors/session';
import * as pageActions from 'containers/Page/actions';
import * as sharedActions from 'shared/actions';
import { getLanguage, getSeoContent, getHasMembership } from 'shared/selectors';
import { initialiseTikTok, isTikTokEnabled } from 'utils/integrations/tiktok';
import { initialiseGTag } from 'utils/integrations/googleTagManager';
import { LocalStorage } from 'utils/localStorage';
import { triggerGetSiteControl, getGSCExperiments } from 'src/shared/sagas';
import * as acqCampaignActions from 'shared/acqCampaign/actions';
import {
    fetchPosts,
    fetchPostsSuccess,
} from 'components/content/prismicSlices/ResourceLinks/actions';
import { getPartnerCode, getSeoResourceLinks } from './selectors';
import { matcher } from '../../utils/routes';
import searchRoutePaths from '../search/route-paths';

const integrations = [[isTikTokEnabled, initialiseTikTok]];

const disallowedSEOContentMatchers = [
    matcher('/accounts/(.*)'),
    matcher('/user/(.*)'),
    matcher(searchRoutePaths.profilesDetail),
    matcher(searchRoutePaths.listingsDetail),
];

const allowedTrackingKeys = ['wizardTrigger'];

export function* loadSeoContent(pathname) {
    const language = yield select(getLanguage);

    // Check if pathname is not disallowed for loading SEO content
    const allowed = !disallowedSEOContentMatchers.some((pathnameMatcher) =>
        pathnameMatcher(pathname)
    );

    // If allowed
    if (allowed) {
        // Do not re-load if already loaded
        const seoContent = yield select(getSeoContent, pathname);

        if (seoContent.notCheckedForContent) {
            yield put(
                actions.content.loadSeoContent({
                    forceReload: true,
                    filters: {
                        path: pathname,
                        lang: language,
                    },
                })
            );
            const { status } = yield take(settings.content.loadSeoContent.DONE);

            if (status === settings.content.loadSeoContent.SUCCESS) {
                const filteredSlices = yield select(getSeoResourceLinks, pathname, language);

                const resourceLinksSlice = filteredSlices?.at(0);

                if (resourceLinksSlice) {
                    yield put(fetchPosts.create(resourceLinksSlice.items));
                    yield take(fetchPostsSuccess.ACTION);
                }
            }
        }
    }
}

// BE CAREFUL WHAT GOES IN HERE - this is run on every SSR request. Performance matters.
export function* preload(action) {
    // Load Account
    yield put(actions.account.load());
    yield take(settings.account.load.DONE);

    // if there's a promo query parameter then store it
    const { promo } = action.search;
    const { partner } = action.search;

    if (promo) {
        yield put(sharedActions.storePromo.create(promo));
    }
    if (partner) {
        yield put(sharedActions.storePartner.create(partner));
    }

    yield call(loadSeoContent, action.pathname);

    yield put(pageActions.preloaded.create('App'));
}

export function* load() {
    yield put(pageActions.loaded.create('App'));
}

// Needed to debounce
let pageReloading = false;
export const setPageReloading = (status) => {
    pageReloading = status;
};
export const getPageReloading = () => pageReloading;

// If there's been an auth failure, validate the JWT to make sure it's valid
export function* handleAPIAuthFailure() {
    const session = yield select(getSession);

    if (session.token && !getPageReloading()) {
        setPageReloading(true);
        // Check the JWT token is still valid
        yield put(actions.session.validate({ data: { ...session }, forceLoad: true }));
        const { statusCode } = yield take(settings.session.validate.DONE);
        if (statusCode !== 200) {
            // JWT isn't valid so do a hard reload of the page
            window.location.reload();
        } else {
            // JWT is valid so this failure has been caused by something else. Reset the global to catch
            // other session logouts
            setPageReloading(false);
        }
    }
}

export function* lazyload() {
    // Watch for API call failures
    // Regex using interpolation is painful (^@THS_API\/.+\/\$FAILURE\$$)
    const failureAPIStatusActionRegex = new RegExp(
        `^${config.actionsPrefix}\\/.+\\/\\$${AVAILABLE_ACTION_STATUSES.FAILURE}\\$$`
    );
    // Only interested in 401 authorisation failed errors
    yield takeEvery(
        (action) => failureAPIStatusActionRegex.test(action.type) && action.statusCode === 401,
        handleAPIAuthFailure
    );

    const partnerCode = yield select(getPartnerCode);
    if (partnerCode) {
        window.sessionStorage.setItem('partnerCode', partnerCode);
    } else if (window.sessionStorage.getItem('partnerCode')) {
        yield put(sharedActions.storePartner.create(window.sessionStorage.getItem('partnerCode')));
    }

    // For users who have a membership, fetch card details.
    const hasMembership = yield select(getHasMembership);
    if (hasMembership) {
        yield put(actions.account.loadCard());
        yield take(settings.account.loadCard.DONE);
    }

    // Initialise gtag function
    initialiseGTag();

    // initialise integrations when feature is enabled
    integrations.forEach(([isEnabled, initialize]) => {
        if (isEnabled()) {
            initialize();
        }
    });

    const gscExperiments = yield call(getGSCExperiments);

    yield call(triggerGetSiteControl, {
        initialiseGSC: true,
        experiment: gscExperiments,
    });

    yield put(sharedActions.identify.create());

    yield put(acqCampaignActions.initialize.create());

    yield put(pageActions.lazyloaded.create('App'));
}

function removeNoScroll() {
    if (document.body.classList.contains('no-scroll')) {
        document.body.classList.remove('no-scroll');
    }
    return true;
}

function* locationChange(action) {
    // Look for custom track events to inject into page loaded event
    const searchParams = new URLSearchParams(action.payload.location.search);
    const customProps = {};
    if (searchParams.has('t')) {
        try {
            const trackObject = JSON.parse(decodeURIComponent(searchParams.get('t')));

            Object.entries(trackObject).forEach(([key, value]) => {
                if (allowedTrackingKeys.includes(key) && typeof value === 'string') {
                    customProps[`${key}`] = value;
                }
            });
        } catch (e) {
            // If the track event isn't valid JSON do nothing
        }
    }
    yield put(sharedActions.trackPage.create({ customProps }));
    yield call(removeNoScroll);
    yield call(loadSeoContent, action.payload?.location?.pathname);
}

const handleResetPaymentIntentStorage = () => {
    LocalStorage.removeItem('paymentIntentSlug');
};

// it gets triggered when the autorenew is enabled/disabled  on the settings page
// fixes an issue where there is a autorenew discrepancy between Angular and React
// by getting the latest state of the membership plan
function* getLatestMembershipPlan() {
    yield put(
        actions.account.loadMembership({
            forceReload: true,
        })
    );
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === 'App',
            preload
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === 'App',
            load
        ),
        takeEvery(
            (action) => action.type === pageActions.lazyload.ACTION && action.pageId === 'App',
            lazyload
        ),
        takeEvery((action) => action.type === LOCATION_CHANGE, locationChange),
        takeEvery(
            (action) => action.type === pageActions.resetPaymentIntentStorage.ACTION,
            handleResetPaymentIntentStorage
        ),
        takeEvery(
            (action) => action.type === pageActions.getLatestMembershipPlan.ACTION,
            getLatestMembershipPlan
        ),
    ]);
}
