import {
    createInstance,
    enums as OptimizelyEnums,
    logging as OptimizelyLogging,
    setLogLevel,
} from '@optimizely/optimizely-sdk';

class OptimizelyManager {
    createServerInstance(sdkKey, sentry, datafile) {
        const logger = {
            log: (level, message) => {
                if (level === OptimizelyEnums.LOG_LEVEL.ERROR) {
                    sentry.captureException(new Error(message));

                    const defaultLogger = OptimizelyLogging.createLogger();
                    defaultLogger.log(level, message);
                }
            },
        };

        setLogLevel(OptimizelyEnums.LOG_LEVEL.ERROR);

        const optimizelyArgs = {
            datafileOptions: {
                autoUpdate: true,
                updateInterval: 30000,
            },
            logger,
        };

        if (datafile) {
            optimizelyArgs.datafile = datafile;
        } else {
            optimizelyArgs.sdkKey = sdkKey;
        }

        this.client = createInstance(optimizelyArgs);
    }

    createBrowserInstance(datafile) {
        setLogLevel(OptimizelyEnums.LOG_LEVEL.ERROR);

        this.client = createInstance({
            datafile,
        });
    }

    getClient() {
        return this.client;
    }
}

const optimizelyManager = new OptimizelyManager();

export default optimizelyManager;
