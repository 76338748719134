import * as React from 'react';
import * as BaseCheckBoxButton from '.';

function CheckBoxButton({ Icon, hasCheckMark, children, ...rest }) {
    return (
        <BaseCheckBoxButton.Root {...rest}>
            <BaseCheckBoxButton.Content>
                {Icon ? (
                    <BaseCheckBoxButton.Icon>
                        <Icon />
                    </BaseCheckBoxButton.Icon>
                ) : null}
                <BaseCheckBoxButton.Title>{children}</BaseCheckBoxButton.Title>
            </BaseCheckBoxButton.Content>

            {hasCheckMark ? <BaseCheckBoxButton.CheckMark /> : null}
        </BaseCheckBoxButton.Root>
    );
}

CheckBoxButton.defaultProps = {
    Icon: null,
    hasCheckMark: true,
    disabled: false,
};

export default CheckBoxButton;
