import PropTypes from 'prop-types';

import {
    baseMediaPhoto,
    baseMediaVideo,

    baseLocation,
    baseNotification,
    baseAssignmentProps,
    baseWelcomeGuideStatus,
    baseReview,
    baseFeedback,
    baseLocalAttraction,

    baseContactProps,
    basePlaceOfInterest,
    baseContact,

    baseProfile,
    baseListing,
    baseReminder,
    basePet,
    baseConversation,
} from './common';

/**
 * Assignment
 */
export const assignmentProps = {
    ...baseAssignmentProps,

    listingLocation: baseLocation,
    recentApplications: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        assignmentId: PropTypes.string,
        profileName: PropTypes.string,
        sitterAvatarPhoto: baseMediaPhoto,
    })),
    applicationsCount: PropTypes.number,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    profileLocation: baseLocation,
    profileName: PropTypes.string,
    sitterAvatarPhoto: baseMediaPhoto,
    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,

    welcomeGuideStatus: baseWelcomeGuideStatus,

    review: baseReview,
    feedback: baseFeedback,

    selectedAnimals: PropTypes.number,
};

/**
 * Open assignment
 */
export const openAssignmentProps = {
    ...baseAssignmentProps,

    listingLocation: baseLocation,
    recentApplications: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        assignmentId: PropTypes.string,
        profileName: PropTypes.string,
        sitterAvatarPhoto: baseMediaPhoto,
    })),
    applicationsCount: PropTypes.number,
};
export const openAssignment = PropTypes.shape(openAssignmentProps);
export const openAssignments = PropTypes.arrayOf(openAssignment);


/**
 * Open assignment application
 */
export const openAssignmentApplicationProps = {
    id: PropTypes.string,
    assignmentId: PropTypes.string,
    ownerId: PropTypes.string,
    sitterId: PropTypes.string,
    listingId: PropTypes.string,
    profileId: PropTypes.string,
    assignment: PropTypes.shape({
        id: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        listingId: PropTypes.string,
        ownerId: PropTypes.string,
        isApproximateDates: PropTypes.bool,
        isReviewing: PropTypes.bool,
    }),
    hasOwnerConfirmed: PropTypes.bool,
    hasSitterConfirmed: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    hasAssignmentDatesChanged: PropTypes.bool,
    isInvited: PropTypes.bool,
    isShortlisted: PropTypes.bool,
    hasOwnerDeclined: PropTypes.bool,
    hasOwnerCancelled: PropTypes.bool,
    hasSitterDeclined: PropTypes.bool,
    hasSitterCancelled: PropTypes.bool,
    profileName: PropTypes.string,
    sitterAvatarPhoto: baseMediaPhoto,
    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,
};
export const openAssignmentApplication = PropTypes.shape(openAssignmentApplicationProps);

export const openAssignmentApplications = PropTypes.shape({
    confirmed: openAssignmentApplication,
    shortlisted: PropTypes.arrayOf(openAssignmentApplication),
    invitations: PropTypes.arrayOf(openAssignmentApplication),
    applications: PropTypes.arrayOf(openAssignmentApplication),
    declined: PropTypes.arrayOf(openAssignmentApplication),
});
export const openAssignmentOpenApplications = PropTypes.shape({
    confirmed: openAssignmentApplication,
    shortlisted: PropTypes.arrayOf(openAssignmentApplication),
    invitations: PropTypes.arrayOf(openAssignmentApplication),
    applications: PropTypes.arrayOf(openAssignmentApplication),
});
export const openAssignmentOpenApplicationsList = PropTypes.arrayOf(openAssignmentApplication);


/**
 * Confirmed Assignments
 */
export const confirmedAssignmentProps = {
    ...baseAssignmentProps,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    listingLocation: baseLocation,
    profileLocation: baseLocation,
    profileName: PropTypes.string,
    sitterAvatarPhoto: baseMediaPhoto,
    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,

    welcomeGuideStatus: baseWelcomeGuideStatus,
};
export const confirmedAssignment = PropTypes.shape(confirmedAssignmentProps);

export const currentAssignmentProps = {
    ...baseAssignmentProps,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    listingLocation: baseLocation,
    profileLocation: baseLocation,
    profileName: PropTypes.string,
    sitterAvatarPhoto: baseMediaPhoto,
    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,

    welcomeGuideStatus: baseWelcomeGuideStatus,
};
export const currentAssignment = PropTypes.shape(currentAssignmentProps);
export const currentAssignments = PropTypes.arrayOf(currentAssignment);

export const upcomingAssignmentProps = {
    ...currentAssignmentProps,
};
export const upcomingAssignment = PropTypes.shape(upcomingAssignmentProps);

export const pastAssignmentProps = {
    ...baseAssignmentProps,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    profileLocation: baseLocation,
    profileName: PropTypes.string,
    sitterAvatarPhoto: baseMediaPhoto,

    lastMessage: baseNotification,
    newMessagesCount: PropTypes.number,

    review: baseReview,
    feedback: baseFeedback,
    reviewRequest: PropTypes.shape({
        sentAt: PropTypes.string,
        message: PropTypes.string,
    }),
    feedbackRequest: PropTypes.shape({
        sentAt: PropTypes.string,
        message: PropTypes.string,
    }),

    selectedAnimals: PropTypes.number,
};
export const pastAssignment = PropTypes.shape(pastAssignmentProps);
export const pastAssignments = PropTypes.arrayOf(pastAssignment);

// OLD
// @TODO
export const owner = {

};
// @TODO END

export const favourite = baseProfile;
export const favourites = PropTypes.arrayOf(PropTypes.shape(favourite));

// @TODO
export const media = {
    id: PropTypes.number,
};
export const medias = PropTypes.arrayOf(PropTypes.shape(media));
// @TODO END


export const assignment = {
    ...baseAssignmentProps,
    approximateDates: PropTypes.bool,
    reviewing: PropTypes.bool,
    deleted: PropTypes.bool,
    isNew: PropTypes.bool,
    dateCreated: PropTypes.string,
    lastModified: PropTypes.string,
    confirmed: PropTypes.bool,
    sitterConfirmed: PropTypes.bool,
    ownerConfirmed: PropTypes.bool,

    listing: PropTypes.shape(baseListing),
    sitter: PropTypes.shape(baseProfile),
    review: PropTypes.shape({
        provided: PropTypes.string,
        requested: PropTypes.bool,
    }),
    welcomeGuide: PropTypes.shape({
        requested: PropTypes.string,
        shared: PropTypes.string,
    }),
    reminders: PropTypes.arrayOf(PropTypes.shape(baseReminder)),
    details: PropTypes.any,
};
export const current = PropTypes.arrayOf(PropTypes.shape(assignment));
export const upcoming = PropTypes.arrayOf(confirmedAssignment);
export const past = PropTypes.arrayOf(confirmedAssignment);
export const confirmedAssignments = {
    current,
    upcoming,
    past,
};

export const listingOpenAssignments = PropTypes.arrayOf(PropTypes.shape(openAssignment));
export const listingCurrentAssignment = confirmedAssignment;
export const listingUpcomingAssignments = upcoming;
export const listingPastAssignments = past;

export const listing = {
    id: PropTypes.string,

    title: PropTypes.string,
    introduction: PropTypes.string,
    features: PropTypes.string,

    user: PropTypes.shape({
        id: PropTypes.string,
        profilePhoto: baseMediaPhoto,
        firstName: PropTypes.string,
    }),
    ownerFirstName: PropTypes.string,

    pets: PropTypes.arrayOf(PropTypes.shape(basePet)),
    otherAnimals: PropTypes.string,

    homeType: PropTypes.oneOf(['house', 'apartment']),
    disabledAccess: PropTypes.bool,
    wifiAvailable: PropTypes.string,
    carIncluded: PropTypes.bool,
    familyType: PropTypes.oneOf(['single', 'couple', 'family']),
    familyFriendly: PropTypes.bool,
    localAttractions: PropTypes.arrayOf(baseLocalAttraction),

    hasDatesSet: PropTypes.bool,

    approvedDate: PropTypes.string,
    archived: PropTypes.oneOf(['0-active', '1-old-active', '2-archived']),
    isArchived: PropTypes.bool,
    isArchiveReady: PropTypes.bool,
    isPartiallyFilled: PropTypes.bool,

    location: baseLocation,

    isIncomplete: PropTypes.bool,
};
export const listings = PropTypes.arrayOf(PropTypes.shape(listing));

export const feedbackRequest = {
    message: PropTypes.string,
    completedDate: PropTypes.string,
};

export const reviewRequest = {
    message: PropTypes.string,
    completedDate: PropTypes.string,
};

export const feedback = {
    id: PropTypes.number,
    description: PropTypes.string,
    reply: PropTypes.string,
    provided: PropTypes.string,
    requested: PropTypes.bool,
    request: PropTypes.shape(feedbackRequest),
    recommended: PropTypes.number,
    sitter: PropTypes.shape(baseProfile),
};
export const feedbacks = PropTypes.arrayOf(PropTypes.shape(feedback));

export const review = {
    id: PropTypes.number,
    reply: PropTypes.string,
    description: PropTypes.string,
    provided: PropTypes.string,
    requested: PropTypes.bool,
    request: PropTypes.shape(reviewRequest),
    review: PropTypes.shape({
        organised: PropTypes.number,
        reliable: PropTypes.number,
        selfSufficient: PropTypes.number,
        tidy: PropTypes.number,
        petCare: PropTypes.number,
    }),
    listing: PropTypes.shape(baseListing),
};
export const reviews = PropTypes.arrayOf(PropTypes.shape(review));

export const reminders = PropTypes.arrayOf(PropTypes.shape(baseReminder));

export const petProps = basePet;
export const pet = PropTypes.shape(petProps);
export const pets = PropTypes.arrayOf(pet);

export const conversations = PropTypes.arrayOf(PropTypes.shape(baseConversation));


export const welcomeGuideProps = {
    id: PropTypes.string,
    listingId: PropTypes.string,
    dateModified: PropTypes.string,
    address: PropTypes.string,
    contacts: PropTypes.arrayOf(baseContact),
    howToGetThere: PropTypes.string,
    kitchenAppliances: PropTypes.arrayOf(PropTypes.oneOf([
        'gas', 'electric', 'cooker', 'hob', 'microwave', 'dishwasher', 'washingMachine', 'tumbleDryer',
        'iron', 'outdoorDryingSpace', 'vacuumCleaner', 'hover',
    ])),
    kitchenAppliancesOther: PropTypes.string,
    kitchenSpecialInstructions: PropTypes.string,
    heatingInstructions: PropTypes.string,
    waterInstructions: PropTypes.string,
    electricityInstructions: PropTypes.string,
    garbageAndRecyclingInstructions: PropTypes.string,
    cleaningInstructions: PropTypes.string,
    hasCleaner: PropTypes.bool,
    cleanerDetails: PropTypes.string,
    roomsInstructions: PropTypes.string,
    roomsExtraFeatures: PropTypes.arrayOf(PropTypes.oneOf([
        'pool', 'jacuzzi', 'bathtub',
    ])),
    roomsSpecialInstructions: PropTypes.string,
    hasFirstAidKit: PropTypes.bool,
    firstAidKitInstructions: PropTypes.string,
    houseRules: PropTypes.string,
    toysAndActivities: PropTypes.string,
    homeExtraInformation: PropTypes.string,
    doorsAndWindowsInstructions: PropTypes.string,
    spareKeysContacts: baseContact,
    securityAlarmInstructions: PropTypes.string,
    fireAlarmInstructions: PropTypes.string,
    securityExtraInformation: PropTypes.string,
    wifiSpeed: PropTypes.oneOf(['highSpeed', 'basic', 'none']),
    wifiDetails: PropTypes.string,
    phoneDetails: PropTypes.string,
    phoneSignal: PropTypes.oneOf(['good', 'moderate', 'poor', 'nonExisting']),
    audioDetails: PropTypes.string,
    tvDetails: PropTypes.string,
    hasHomeOffice: PropTypes.bool,
    desktopAvailable: PropTypes.string,
    desktopInstructions: PropTypes.string,
    hifiExtraInformation: PropTypes.string,
    indoorPlantsInstructions: PropTypes.string,
    outdoorSpaceDetails: PropTypes.arrayOf(PropTypes.oneOf([
        'garden', 'patio', 'terrace', 'balcony', 'communalGardens',
    ])),
    gardeningInstructions: PropTypes.string,
    hasGardener: PropTypes.bool,
    gardenerDetails: PropTypes.string,
    gardenExtraInformation: PropTypes.string,
    postDetails: PropTypes.string,
    photos: PropTypes.arrayOf(baseMediaPhoto),
    videos: PropTypes.arrayOf(baseMediaVideo),
    veterinaryInformation: PropTypes.shape({
        ...baseContactProps,
        website: PropTypes.string,
    }),
    carNeeded: PropTypes.bool,
    useOfCar: PropTypes.bool,
    carDetails: PropTypes.string,
    carInsuranceDetails: PropTypes.string,
    carParking: PropTypes.string,
    busDetails: PropTypes.string,
    trainDetails: PropTypes.string,
    taxiDetails: PropTypes.string,
    carHireDetails: PropTypes.string,
    shoppingForFood: PropTypes.arrayOf(basePlaceOfInterest),
    restaurantsAndPubs: PropTypes.arrayOf(basePlaceOfInterest),
    favouriteCafes: PropTypes.arrayOf(basePlaceOfInterest),
    niceWalks: PropTypes.arrayOf(basePlaceOfInterest),
    localAttractions: PropTypes.arrayOf(basePlaceOfInterest),
    neighbourhoodExtraInformation: PropTypes.string,
    nominatedPeople: PropTypes.arrayOf(baseContact),
    hospitalDetails: PropTypes.shape({
        ...baseContactProps,
        website: PropTypes.string,
    }),
    pharmacyDetails: PropTypes.shape({
        ...baseContactProps,
        website: PropTypes.string,
    }),
    dentistDetails: PropTypes.shape({
        ...baseContactProps,
        website: PropTypes.string,
    }),
    repairsContactDetails: PropTypes.string,
    policeContactDetails: PropTypes.string,
};
export const welcomeGuide = PropTypes.shape(welcomeGuideProps);

export const listingPetProps = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    animalSlug: PropTypes.string,
    yearOfBirth: PropTypes.number,
    breedName: PropTypes.string,
    photos: PropTypes.array,
    videos: PropTypes.array,
    generalInformation: PropTypes.string,
    healthInformation: PropTypes.string,
    feedingInstructions: PropTypes.string,
    personality: PropTypes.array,
    personalityOther: PropTypes.string,
    personalityExtra: PropTypes.string,
    exerciseAndPlay: PropTypes.string,
    houseRules: PropTypes.string,
    toiletAndGrooming: PropTypes.string,
    cleaning: PropTypes.string,
    likesDislikesInformation: PropTypes.string,
    otherInformation: PropTypes.string,
};

export const listingPet = PropTypes.shape(listingPetProps);
export const listingPets = PropTypes.arrayOf(listingPet);


export const guideMedias = PropTypes.arrayOf(PropTypes.shape(media));

export const listingPastAssignmentFeedback = feedback;
export const listingPastAssignmentFeedbackRequest = feedbackRequest;

export const listingPastAssignmentReview = review;
