import { put, take } from 'redux-saga/effects';

import apiActions, { settings } from 'api/actions';

import { error as errorAction } from 'containers/Page/actions';
import { createListingsSearchQuery } from 'utils/searchListings';

import { SEARCH_TYPE_MAP } from '../SearchListings.constants';

function* doSearchMap({
    filters,
    facets,
    addPausedListingsToSearch,
    addConfirmedListingsToSearch,
}) {
    let searchQuery = createListingsSearchQuery({
        searchPastAssignments: false,
        filters,
        facets,
        perPage: 0,
        searchPastAndFutureAssignments: false,
        addPausedListingsToSearch,
        addConfirmedListingsToSearch,
        facetResultSize: {
            geohash: 300,
        },
    });
    searchQuery = searchQuery.resultsPerPage(0);

    // Dispatch load listings call to get cluster info
    yield put(
        apiActions.search.loadListings({
            forceReload: true,
            filters: {
                query: JSON.stringify(searchQuery.getRequestData()),
            },
            data: {
                searchType: SEARCH_TYPE_MAP,
            },
        })
    );

    const { status, statusCode } = yield take(
        (res) =>
            res.type === settings.search.loadListings.DONE &&
            res.requestData.searchType === SEARCH_TYPE_MAP
    );

    if (status === settings.search.loadListings.SUCCESS) {
        return true;
    }

    // Error occured
    yield put(errorAction.create('SearchListings', statusCode));
    return false;
}

export { doSearchMap as default };
