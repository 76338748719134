import { createAction } from 'utils/actions';

const prefix = 'EDIT_LISTING';

// eslint-disable-next-line import/prefer-default-export
export const assignmentsLoaded = createAction(
    prefix,
    'ASSIGNMENTS_LOADED',
    'pastAssignments',
    'openAssignments'
);
