import { put, takeEvery } from 'redux-saga/effects';

import api from 'api/actions';

import { request } from './actions';

/**
 * searches for places based on string
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* searchBreed(action) {
    const { breed, animalId } = action;

    if (!breed) return;

    yield put(
        api.animals.loadBreeds({
            forceReload: true,
            data: {
                id: animalId,
            },
            filters: {
                q: breed,
            },
        })
    );
}

function* listenForSearchBreed() {
    yield takeEvery(request.ACTION, searchBreed);
}

export default listenForSearchBreed;
