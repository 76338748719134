import * as actions from './actions';

export default (state = {}, action) => {
    switch (action.type) {
        case actions.setGeoLocationPlace.ACTION:
            return {
                ...state,
                place: action.place.place,
            };
        case actions.clearGeoLocationPlace.ACTION:
            return {
                ...state,
                place: undefined,
            };
        default:
            return state;
    }
};
