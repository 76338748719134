import { settings as actions } from '../../actions';

import {
    loadedResultsReducer, createdResultReducer, updatedResultReducer, deletedResultReducer,
} from '../common';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadProfilePhotos.SUCCESS:
            return loadedResultsReducer(state, action);
        case actions.sitter.createProfilePhoto.SUCCESS:
            return createdResultReducer(state, action);
        case actions.sitter.updateProfilePhoto.SUCCESS:
            return updatedResultReducer(state, action);
        case actions.sitter.removeProfilePhoto.SUCCESS:
            return deletedResultReducer(state, action, 'id');
        default:
            return state;
    }
};
