import ApiConfig from '../config';
import ApiAction from './common';

const config = {
    create: ['SESSION', 'CREATE', ApiConfig.endpoints.session.authenticate],
    logout: ['SESSION', 'REMOVE', ApiConfig.endpoints.session.logout],
    createApple: [
        'SESSION',
        'CREATE_APPLE',
        ApiConfig.endpoints.session.authenticateApple,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    createFacebook: [
        'SESSION',
        'CREATE_FACEBOOK',
        ApiConfig.endpoints.session.authenticateFacebook,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    createGoogle: [
        'SESSION',
        'CREATE_GOOGLE',
        ApiConfig.endpoints.session.authenticateGoogle,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    createSso: ['SESSION', 'CREATE_SSO', ApiConfig.endpoints.session.authenticateSso._, [], 'POST'],
    createSsoFacebook: [
        'SESSION',
        'CREATE_SSO_FACEBOOK',
        ApiConfig.endpoints.session.authenticateSso.facebook,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    createSsoToken: [
        'SESSION',
        'CREATE_SSO_TOKEN',
        ApiConfig.endpoints.session.authenticateSso.token,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    validate: [
        'SESSION',
        'VALIDATE',
        ApiConfig.endpoints.session.validate,
        [],
        'POST',
        null,
        null,
        {
            hours: 1,
        },
    ],
    createCustomer: [
        'SESSION',
        'CREATE_CUSTOMER',
        ApiConfig.endpoints.session.authenticateCustomer,
        [],
        'POST',
    ],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
