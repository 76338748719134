import { fileMatchSize, fileMatchExt } from 'utils/files';
import { uploadStatus, uploadError, itemType } from './PhotoUploader.constants';

// Are we in the final state or still processing?
export const isProcessing = (photoPreview) =>
    photoPreview.status === uploadStatus.WAITING || photoPreview.status === uploadStatus.UPLOADING;

// Has this upload been cancelled?
export const isCancelled = (photoPreview) => photoPreview.status === uploadStatus.CANCELLED;

// Can this failed upload be retried?
export const canRetryUpload = (photoPreview) =>
    photoPreview.status === uploadStatus.FAILED && photoPreview.error === uploadError.GENERAL;

// Is there any error on this upload?
export const hasError = (photoPreview) => photoPreview.status === uploadStatus.FAILED;

// Did the user supply a bad image?
export const hasUserError = (photoPreview) =>
    hasError(photoPreview) && photoPreview.error !== uploadError.GENERAL;

// Does this upload count towards the total (failed & cancelled do not count)
export const countsTowardsTotal = (photoPreview) =>
    isProcessing(photoPreview) || photoPreview.status === uploadStatus.SUCCESS;

// Format the dropped file in to a PhotoPreviewType
export const processDroppedFile = (file, index, rest) => ({
    apiId: null,
    tmpId: `${new Date().getTime()}-${index}`,
    name: file.name,
    size: file.size,
    type: file.type,
    status: uploadStatus.WAITING,
    error: null,
    progress: null,
    file,
    ...rest,
});

// What's the most pressing thing wrong with this file?
export const findPrimaryError = (file, acceptedFileExts, minFileSize, maxFileSize) => {
    if (!fileMatchExt(file, acceptedFileExts)) {
        return uploadError.FILE_TYPE;
    }
    if (!fileMatchSize(file, minFileSize, maxFileSize)) {
        return uploadError.TOO_BIG;
    }

    return uploadError.GENERAL;
};

// Format this photo as an "existing" type.
export const createExistingPhoto = (photo) => ({
    type: itemType.EXISTING,
    data: { ...photo },
});

// Format this photo as a "preview" type.
export const createPhotoPreview = (photoPreview) => ({
    type: itemType.PREVIEW,
    data: { ...photoPreview },
});

// Find a photoPreview by it's tmpId or null if not found
export const findPhotoPreviewByTmpId = (items, tmpId) =>
    items.find((item) => {
        if (item.type === itemType.EXISTING) return false;

        return item.data.tmpId === tmpId;
    }) || null;

// Return a new array consisting of the current items with all the unknown items appended at the end
export const addNewItems = (currentItems, newItems) => {
    const knownIds = currentItems.map((currentItem) => {
        if (currentItem.type === itemType.EXISTING) return currentItem.data.id;

        return currentItem.data.apiId;
    });

    const unknownItems = newItems
        .filter((newItem) => !knownIds.includes(newItem.id))
        .map((newItem) => createExistingPhoto(newItem));

    return [...currentItems, ...unknownItems];
};

// Return a new array where items that are no longer needed have been removed
export const removeDeletedItems = (currentItems, newItems) => {
    const newItemIds = newItems.map((newItem) => newItem.id);

    return currentItems.filter((currentItem) => {
        if (currentItem.type === itemType.EXISTING && !newItemIds.includes(currentItem.data.id)) {
            return false;
        }

        return true;
    });
};

// Return a new array with only the items that are existing or uploads that don't have errors
export const itemsWithoutError = (items) =>
    items.filter((item) => item.type === itemType.EXISTING || !hasError(item.data));

// Return a new array with only the items that have errors
export const itemsWithError = (items) =>
    items.filter((item) => item.type === itemType.PREVIEW && hasError(item.data));

// Delete unneeded items, add new ones and update existing ones that may have changed, returns a new array
// where each item is a shallow copy of the previous item (more immutable but perhaps causes excessive re-renders?)
export const updateItems = (currentItems, newItems) => {
    const itemsAfterDelete = removeDeletedItems(currentItems, newItems);
    const itemsAfterAdd = addNewItems(itemsAfterDelete, newItems);
    const findNewItem = (id) => newItems.find((newItem) => newItem.id === id) || null;

    return itemsAfterAdd.map((item) => {
        if (item.type === itemType.EXISTING) {
            // Simply replace existing items with the new version
            // TODO: could cause excessive re-renders of children? If we don't care about
            // `item.description` updating in the store we could just `return item;`
            return createExistingPhoto(findNewItem(item.data.id));
        }

        // if there's no apiId then this item can be ignored because it's not finished yet
        if (!item.data.apiId) return item;

        // In this case a preview may have finished uploading and become a full photo (if not just
        // ignore for now)
        const newItem = findNewItem(item.data.apiId);
        return newItem ? createExistingPhoto(newItem) : item;
    });
};

export const updatePreview = (preview, status, error = null, progress = null) => ({
    ...preview,
    data: {
        ...preview.data,
        status,
        error,
        // Stop progress going backwards when the events end up in the wrong order
        // eslint-disable-next-line no-nested-ternary
        progress: preview.data.progress
            ? progress
                ? Math.max(preview.data.progress, progress)
                : preview.data.progress
            : progress,
    },
});

// Returns a new array where the given photo preview has been updated
export const updatePreviewById = (items, tmpId, status, error, progress = null) =>
    items.map((item) => {
        if (item.type === itemType.EXISTING) return item;
        if (item.data.tmpId === tmpId) {
            return updatePreview(item, status, error, progress);
        }

        return item;
    });

// Returns a new array where every photo preview has been updated
export const updateAllPreviews = (items, status, error, progress = null) =>
    items.map((item) => {
        if (item.type === itemType.EXISTING) return item;

        return updatePreview(item, status, error, progress);
    });

// Count all existing photos and uploads that have not failed or been cancelled towards the total
export const countRemainingItems = (items, maxItems) => {
    const potentiallyValidItems = items.filter((item) => {
        if (item.type === itemType.EXISTING) return true;

        return countsTowardsTotal(item.data);
    });

    return maxItems - potentiallyValidItems.length;
};

// Just the IDs of existing items, in the right order
export const extractOrderedIds = (items) =>
    items
        .map((item) => {
            if (item.type === itemType.EXISTING) return item.data.id;
            if (item.type === itemType.PREVIEW && item.data.apiId) return item.data.apiId;
        })
        .filter((item) => !!item);

// Find photos to add, photos to delete and photos to update
export const photoDiff = (oldPhotos = [], newPhotos = []) => {
    const findById = (photos, id) => photos.find((photo) => photo.id === id);
    const addedPhotos = newPhotos.filter((newPhoto) => !findById(oldPhotos, newPhoto.id));
    const deletedPhotos = oldPhotos.filter((oldPhoto) => !findById(newPhotos, oldPhoto.id));
    const notDeletedPhotos = newPhotos.filter((newPhoto) => !findById(deletedPhotos, newPhoto.id));

    // We care about captions/order from photos that were not deleted
    const updatedMedias = notDeletedPhotos.map((updatedMedia, index) => ({
        id: updatedMedia.id,
        name: updatedMedia.description || null,
        order: index + 1,
    }));

    return {
        addedPhotos,
        deletedPhotos,
        updatedMedias,
    };
};

// The following takes an image and compares it against the maxWidth and maxHeight. If
// it is taller or wider it resizes it by redrawing it to a blob.
// Then it uses a callback with a new image url created from the blob.
export const resizeImage = (image, maxWidth, maxHeight, callback, onError) => {
    const img = new Image();

    img.onload = () => {
        const originalWidth = img.width;
        const originalHeight = img.height;
        let { width, height } = img;

        // Resize the image
        if (originalHeight > maxHeight || originalWidth > maxWidth) {
            let scale = 1;
            if (originalHeight - maxHeight > originalWidth - maxWidth) {
                // scale to max height
                scale = maxHeight / originalHeight;
            } else {
                // scale to max width
                scale = maxWidth / originalWidth;
            }
            width = originalWidth * scale;
            height = originalHeight * scale;
        }

        // Draw the image to a canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        // Create a blob
        canvas.toBlob((blob) => {
            /**
                Clean up url on unmount to avoid memory leaks.
                When you use the createObjectURL method, it tells the system to keep an internal
                reference to your media (which might be a Blob, a File, or a StorageFile).
                The system uses this internal reference to stream the object to the appropriate element.
                But the system doesn't know when the data is needed, so it keeps the internal reference
                until you tell it to release.
                It's easy to accidently retain unnecessary internal references, which can consume
                large amounts of memory.

                ref = https://docs.microsoft.com/en-us/previous-versions/windows/apps/hh781216(v=win.10)?redirectedfrom=MSDN#revoke-all-urls-created-with-urlcreateobjecturl-to-avoid-memory-leaks
            */
            URL.revokeObjectURL(img.src);
            callback(URL.createObjectURL(blob));
        });
    };

    img.onerror = (imgError) => {
        onError?.(imgError);
    };

    img.src = URL.createObjectURL(image);
};
