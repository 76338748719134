// Solution taken from https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
function retry(fn, { retries = 3, interval = 500, exponentialBackoff = true } = {}) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    // Exit the recursion
                    if (retries === 1) {
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, {
                        retries: retries - 1,
                        interval: exponentialBackoff ? interval * 2 : interval,
                    }).then(resolve, reject);
                }, interval);
            });
    });
}

export default retry;
