// eslint-disable-next-line import/prefer-default-export
export const SITTER_SUBMIT_APPLICATION = {
    create({ pasted, applicationLength }) {
        const payload = {
            pasted,
            applicationLength,
        };

        return {
            type: 'Submit Application',
            payload,
        };
    },
};
