import SearchListings from './SearchListings';
import SearchProfiles from './SearchProfiles';
import ListingDetail from './ListingDetail';
import ListingApplication from './ListingApplication';
import ProfileDetail from './ProfileDetail';
import ProfileInvitation from './ProfileInvitation';
import SavedSearchRedirect from './SavedSearchRedirect';
import FindASitterWizard from './FindASitterWizard';
import FindAHouseSitWizard from './FindAHouseSitWizard';
import SearchListingsDev from './SearchListingsDev';
import paths from './route-paths';

export default [
    {
        path: paths.savedSearchRedirect,
        exact: true,
        component: SavedSearchRedirect,
    },
    {
        path: paths.profileInvitation,
        exact: true,
        component: ProfileInvitation,
    },
    {
        path: paths.profilesDetail,
        exact: true,
        component: ProfileDetail,
    },
    {
        path: paths.listingsDetail,
        exact: true,
        component: ListingDetail,
    },
    {
        path: paths.listingApplication,
        exact: true,
        component: ListingApplication,
    },
    {
        path: paths.listingsContinent,
        exact: true,
        component: SearchListings,
    },
    {
        path: paths.listings,
        exact: true,
        component: SearchListings,
    },
    {
        path: paths.profiles,
        exact: true,
        component: SearchProfiles,
    },
    {
        path: paths.catProfilesSearch,
        exact: true,
        component: SearchProfiles,
    },
    {
        path: paths.catProfiles,
        exact: true,
        component: SearchProfiles,
    },
    {
        path: paths.findASitterWizard,
        exact: true,
        component: FindASitterWizard,
    },
    {
        path: paths.findAHouseSitWizard,
        exact: true,
        component: FindAHouseSitWizard,
    },
    {
        path: paths.listingSearchDev,
        exact: true,
        component: SearchListingsDev,
    },
];
