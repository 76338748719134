import { settings as actions } from '../../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        // Load tag
        case actions.content.loadBlogTag.SUCCESS: {
            const { areaSlug } = action.requestData;

            return {
                ...state,
                [areaSlug]: {
                    ...state[areaSlug],
                    ...action.data
                },
            };
        }

        // Load blog tag posts
        case actions.content.loadBlogTagsPosts.SUCCESS: {
            const { areaSlug } = action.requestData;

            return {
                ...state,
                [areaSlug]: {
                    ...state[areaSlug],
                    ...action.data,
                    page: action.action.filters.page,
                    limit: action.action.filters.limit,
                },
            };
        }
        default:
            return state;
    }
};
