export const getSearchQuery = (state) => state.pages.search.savedSearchRedirect.searchQuery;

export const getSavedSearchName = (state) => state.pages.search.savedSearchRedirect.savedSearchName;

// this is used to get the UTMs if we have any
export const getSearchQueryParams = (state) => {
    if (state.router && state.router.location && state.router.location.search) {
        return state.router.location.search;
    }
    return '';
};
