import { settings } from 'api/actions';
import { resetVerifyErrorResponseText } from './actions';

export const initialState = {
    createRequestId: null,
    createUpdateId: 0,
    verifyRequestId: null,
    createErrorResponseText: null,
    createSuccess: null,
    verifyErrorResponseText: null,
    verifySuccess: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case settings.verifications.createPhoneVerification.DONE: {
            const createSuccess = action.statusCode === 201;
            let errorText = null;

            if (!createSuccess) {
                if (action.statusCode === 400) {
                    errorText = action.data;
                } else {
                    errorText = 'genericError';
                }
            }

            return {
                ...state,
                createSuccess,
                createRequestId: action.data.requestId,
                createUpdateId: state.createUpdateId + 1,
                createErrorResponseText: errorText,
            };
        }
        case settings.verifications.verifyPhoneVerification.DONE: {
            const verifySuccess = action.statusCode === 200;
            let errorText = null;

            if (!verifySuccess) {
                if (action.statusCode === 400) {
                    errorText = action.data;
                } else {
                    errorText = 'genericError';
                }
            }

            return {
                ...state,
                verifySuccess,
                verifyRequestId: action.data.requestId,
                verifyErrorResponseText: errorText,
            };
        }
        case resetVerifyErrorResponseText.ACTION:
            return {
                ...state,
                verifyErrorResponseText: null,
            };
        default:
            return state;
    }
};
