import { all, call, put, takeEvery, fork, select } from 'redux-saga/effects';
import {
    preload as pageActionsPreload,
    lazyload as pageActionsLazyload,
} from 'containers/Page/actions';
import actions, { settings } from 'api/actions';
import profilePhotoSaga from 'pages/user/components/AccountProfilePhoto/sagas';
import { loadListing } from '../sagas';
import { PAGE_ID as ABOUTYOU_PAGE_ID } from './AboutYou/AboutYou.constants';
import { PAGE_ID as HOME_PAGE_ID } from './Home/Home.constants';
import { PAGE_ID as RESPONSIBILITIES_PAGE_ID } from './Responsibilities/Responsibilities.constants';
import { PAGE_ID as TITLE_PAGE_ID } from './Title/Title.constants';
import { PAGE_ID as SAFETY_SECURITY_PAGE_ID } from './SafetySecurity/constants';
import homeSaga from './Home/sagas';
import { getRawListing } from './selectors';

export function* load(action) {
    yield call(loadListing, action.params, action.type, action.pageId);
}

export function* reloadListing() {
    const listing = yield select(getRawListing);

    yield put(
        actions.owner.loadListing({
            forceReload: true,
            data: {
                id: listing.id,
            },
        })
    );
}

export default function* pageSaga() {
    const pageIds = [
        ABOUTYOU_PAGE_ID,
        HOME_PAGE_ID,
        RESPONSIBILITIES_PAGE_ID,
        TITLE_PAGE_ID,
        SAFETY_SECURITY_PAGE_ID,
    ];

    yield all([
        takeEvery(
            (action) =>
                action.type === pageActionsPreload.ACTION && pageIds.includes(action.pageId),
            load
        ),
        takeEvery(
            (action) =>
                action.type === pageActionsLazyload.ACTION && pageIds.includes(action.pageId),
            load
        ),
        takeEvery(settings.account.updateProfilePhoto.DONE, reloadListing),
        fork(homeSaga),
        fork(profilePhotoSaga),
    ]);
}
