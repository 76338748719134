import styled, { css } from 'styled-components';
import { Span } from 'typography';
import { Button } from 'components/buttons';

export const CardHeading = styled(Span)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
    `
);

export const CardPaymentContainer = styled.div(
    ({
        hasCardPaymentErrored,
        showPaymentMethods,
        theme: { spacing, screenSize, palette, borderRadius },
    }) => css`
        margin-top: ${spacing[1]};
        margin-bottom: ${hasCardPaymentErrored ? 0 : spacing[3]};
        display: flex;
        flex-flow: row wrap;
        min-height: 48px;
        position: relative;
        overflow: hidden;
        border-radius: ${borderRadius.regular};
        z-index: 0;

        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            margin-bottom: ${hasCardPaymentErrored ? 0 : spacing[3.5]};
            flex-flow: nowrap;
            box-sizing: border-box;
            /* add error border to around the card inputs since it is styled for them to look like they are in one component */
            border: 1px solid ${showPaymentMethods ? palette.grey[400] : palette.grey[100]};
            border-radius: ${borderRadius.regular};
            ${hasCardPaymentErrored &&
            `
                    border: 2px solid ${palette.danger};
            `};
        }
    `
);

const cardInputDesktopStyles = ({ theme: { spacing } }) => css`
    border-radius: 0;
    border-width: 0;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    flex: 2;

    &:first-child {
        flex: 5;
        padding-left: ${spacing[2]};
    }

    &:last-child {
        margin: 0;
        padding-right: ${spacing[2]};
        flex: 1;
    }
`;

export const CardPaymentInputWrapper = styled.div(
    ({
        mobileFullWidth,
        cardNumberError,
        cardExpiryError,
        cardCvcError,
        showPaymentMethods,
        theme: { spacing, borderRadius, palette, screenSize },
    }) => css`
        padding: ${spacing[2]};
        border-radius: ${borderRadius.regular};
        box-sizing: border-box;
        flex: 1 0 0;

        &:last-child {
            margin-left: ${spacing[1]};
        }

        ${showPaymentMethods
            ? `
                background-color: ${palette.white};
                border: 1px solid ${palette.grey[400]};
            `
            : `
                background-color: ${palette.grey[100]};
                border: 1px solid ${palette.grey[100]};
            `};

        ${mobileFullWidth &&
        `
            flex: 1 100%;
            margin-bottom: ${spacing[1]};
        `};
        /* add error border to a specific card input since they are all separated on mobile view */
        ${(cardNumberError || cardExpiryError || cardCvcError) &&
        `
            border: 2px solid ${palette.danger};
        `};

        /* 1024 */
        @media screen and (min-width: ${screenSize.mediumDesktop}px) {
            ${cardInputDesktopStyles};
        }
    `
);

export const CardPaymentErrorContainer = styled.div(
    ({ theme: { spacing } }) => css`
        margin-bottom: ${spacing[3]};
    `
);

export const CardHeadingAndIconsContainer = styled.div(
    ({ theme: { spacing } }) => css`
        margin-top: ${spacing[3]};
        display: flex;
        justify-content: space-between;
    `
);

export const CardIconsContainer = styled.div(
    () => css`
        display: flex;
        flex-direction: row;
        justify-content: center;
    `
);

export const CardIcon = styled.img(
    ({ theme: { spacing }, large }) => css`
        padding-right: ${spacing[1]};
        /* need to amend the size of the cards so that it can actually fit on the smaller screens */
        max-height: 20px;

        ${large
            ? `
            max-height: 32px;
            min-height: 28px;
        `
            : null}

        :last-child {
            padding-right: 0;
        }
    `
);

export const SavedCardDetailsStyled = styled.div(
    ({ theme: { spacing, palette, borderRadius } }) => css`
        background-color: ${palette.white};
        padding: ${spacing[2.5]};
        border-radius: ${borderRadius.regular};
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: ${spacing[2.5]};
        margin-bottom: ${spacing[3.5]};
        align-items: center;
    `
);

export const CardIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const CardInfoWrapper = styled.div(
    ({ theme: { spacing } }) => css`
        padding-left: ${spacing[2]};
        display: flex;
        flex-direction: column;
    `
);

export const CardLastFour = styled.span(
    ({ theme: { palette } }) => css`
        color: ${palette.grey[500]};
    `
);

export const CardExpiry = styled.span(
    ({ theme: { palette } }) => css`
        color: ${palette.grey[500]};
    `
);

export const ChangeCardWrapper = styled.div(
    ({ theme: { spacing, screenSize, typography } }) => css`
        ${typography.smallText};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        /* 480px */
        @media screen and (min-width: ${screenSize.largeHandset}px) {
            padding-left: ${spacing[2]};
            flex-grow: 1;
            justify-content: flex-end;
            margin-left: unset;
        }
    `
);

export const ChangeCardButton = styled(Button)(
    ({ theme: { typography } }) => css`
        ${typography.smallText};
    `
);
