import account, { settings as accountSettings } from './account';
import animals, { settings as animalsSettings } from './animals';
import medias, { settings as mediasSettings } from './medias';
import messages, { settings as messagesSettings } from './messages';
import notifications, { settings as notificationsSettings } from './notifications';
import owner, { settings as ownerSettings } from './owner';
import search, { settings as searchSettings } from './search';
import session, { settings as sessionSettings } from './session';
import sitter, { settings as sitterSettings } from './sitter';
import plans, { settings as plansSettings } from './plans';
import places, { settings as placesSettings } from './places';
import content, { settings as contentSettings } from './content';
import policies, { settings as policiesSettings } from './policies';
import verifications, { settings as verificationsSettings } from './verifications';
import version, { settings as versionSettings } from './version';

export default {
    account,
    animals,
    medias,
    messages,
    notifications,
    owner,
    search,
    session,
    sitter,
    plans,
    places,
    policies,
    content,
    verifications,
    version,
};

const settings = {
    account: accountSettings,
    animals: animalsSettings,
    medias: mediasSettings,
    messages: messagesSettings,
    notifications: notificationsSettings,
    owner: ownerSettings,
    search: searchSettings,
    session: sessionSettings,
    sitter: sitterSettings,
    plans: plansSettings,
    places: placesSettings,
    policies: policiesSettings,
    content: contentSettings,
    verifications: verificationsSettings,
    version: versionSettings,
};

export { settings };
