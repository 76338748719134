// eslint-disable-next-line import/prefer-default-export
export const LocalStorage = {
    setItem: (key, val) => {
        if (global && global.localStorage) {
            global.localStorage.setItem(key, JSON.stringify(val));
        }
    },
    getItem: (key) => {
        let val;
        if (global && global.localStorage) {
            const storageVal = global.localStorage.getItem(key);
            if (storageVal) val = JSON.parse(storageVal);
        }
        return val;
    },
    removeItem: (key) => {
        if (global && global.localStorage) {
            global.localStorage.removeItem(key);
        }
    },
    /**
     * @template {string | object | array} T
     * @param {string} key The key you want to look for in LocalStorage
     * @param {T} value The value you want to set in the LocalStorage
     * @returns {Promise<T>} The returned value from LocalStorage or undefined if none
     * @async
     */
    setItemAsync: (key, value) =>
        new Promise((resolve, reject) => {
            if (typeof window === 'undefined' || !global || !global.localStorage) {
                reject(new ReferenceError('LocalStorage its not defined.'));
            } else if (typeof key !== 'string') {
                reject(new TypeError('LocalStorage only accept string as key.'));
            } else {
                const parsedValue = JSON.stringify(value);
                global.localStorage.setItem(key, parsedValue);

                resolve(parsedValue);
            }
        }),
    /**
     * @template {any | undefined} T
     * @param {string} key The key you want to look for in LocalStorage
     * @returns {Promise<T>} The returned value from LocalStorage or undefined if none
     * @async
     */
    getItemAsync: (key) =>
        new Promise((resolve, reject) => {
            if (typeof window === 'undefined' || !global || !global.localStorage) {
                reject(new ReferenceError('LocalStorage its not defined.'));
            } else if (typeof key !== 'string') {
                reject(new TypeError('LocalStorage only accept string as key.'));
            } else {
                try {
                    const storageValue = global.localStorage.getItem(key);
                    resolve(JSON.parse(storageValue));
                } catch (_) {
                    resolve(undefined);
                }
            }
        }),
};
