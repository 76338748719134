import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCreateVerificationsEmailVerifyMutation } from 'api/query/core/verifications.ts';

import { Loading, Success, Failure } from './components';

const VerifyEmail = () => {
    const { search } = useLocation();
    const isLoggedIn = useSelector((store) => !!store?.session?.token);
    const [emailVerifyRequestMutation, emailVerifyRequestResult] =
        useCreateVerificationsEmailVerifyMutation();

    const token = new URLSearchParams(search).get('token');

    const emailVerifyMutation = (tokenParam) => {
        emailVerifyRequestMutation({
            emailVerify: {
                token: tokenParam,
            },
        });
    };

    const emailVerificationStatus = () => {
        const { isError, isSuccess } = emailVerifyRequestResult;

        if (isSuccess) {
            return 'success';
        }
        if (isError || !token) {
            return 'failure';
        }
        return 'loading';
    };

    const renderContent = () => {
        switch (emailVerificationStatus()) {
            case 'loading':
                return <Loading />;
            case 'success':
                return <Success isLoggedIn={isLoggedIn} />;
            case 'failure':
                return <Failure isLoggedIn={isLoggedIn} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (token) {
            emailVerifyMutation(token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex min-h-[calc(100vh_-_63px)] flex-col items-center py-18 text-center md:py-36">
            {renderContent()}
        </div>
    );
};

export default VerifyEmail;
