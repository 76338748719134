/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { RouterPagination } from 'components/Pagination';

const RouterPaginationStyled = styled(RouterPagination)`
    margin-top: ${({ theme }) => theme.spacing[4]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;

export { RouterPaginationStyled };
