import React from 'react';
import { RegisterFormStyled } from './EmailForm.style';

const EmailForm = ({
    membershipType,
    setMembershipType,
    clearError,
    onSubmit,
    errorMessage,
    isSubmitting,
    initialData,
}) => (
    <RegisterFormStyled
        clearError={clearError}
        errorMessage={errorMessage}
        initialData={initialData}
        membershipType={membershipType}
        setMembershipType={setMembershipType}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        showPrivacyPolicy={false}
    />
);

EmailForm.defaultProps = {};

export default EmailForm;
