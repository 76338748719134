import { settings as actions } from '../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadPlaces.SUCCESS:
        case actions.search.loadNearest.SUCCESS: {
            let searchType = 'default';

            if (action.requestData && action.requestData.searchType) {
                searchType = action.requestData.searchType;
            }

            return {
                ...state,
                [searchType]: [
                    ...action.data,
                ],
            };
        }
        case actions.search.loadPlaces.RESET: {
            let searchType = 'default';

            if (action.requestData && action.requestData.searchType) {
                searchType = action.requestData.searchType;
            }

            return {
                ...state,
                [searchType]: [
                    ...(action.data || []),
                ],
            };
        }
        default:
            return state;
    }
};
