import { Facet } from 'api/helpers/search/constants';
import { settings } from 'api/actions';
import { userRegistered } from 'containers/forms/Register/actions';
import * as actions from './actions';

import {
    SEARCH_TYPE_MAP,
    SEARCH_TYPE_TOTAL_AND_GEOFACETS,
    SEARCH_TYPE_RESULTS,
} from './SearchListings.constants';

const initialState = {
    map: {
        open: false,
        clustersSearchType: SEARCH_TYPE_MAP,
        clustersGeohashFacet: Facet.GEOHASH1,
    },
    query: {},
    isSaveSearchSuccessful: null,
    showSaveSearchDialog: false,
    isLoading: false,
    scrollToResults: false,
    suggestedCategory: null,
    showLowApplicantsPrompt: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'openedMap':
            return {
                ...state,
                map: {
                    ...state.map,
                    open: true,
                },
            };
        case 'closedMap':
            return {
                ...state,
                map: {
                    ...state.map,
                    open: false,
                },
            };
        case actions.placeLoaded.ACTION:
            return {
                ...state,
                place: {
                    ...action.place,
                },
            };
        case actions.mapClustersLoaded.ACTION:
            return {
                ...state,
                map: {
                    ...state.map,
                    clustersSearchType: action.searchType,
                    clustersGeohashFacet: action.geohashFacet,
                },
            };
        case settings.search.createListingsSavedSearch.DONE: {
            const isSuccess = action.statusCode === 201;
            return {
                ...state,
                isSaveSearchSuccessful: isSuccess,
                showSaveSearchDialog: !isSuccess,
            };
        }
        case actions.resetSaveSearch.ACTION:
            return {
                ...state,
                showSaveSearchDialog: false,
                isSaveSearchSuccessful: null,
            };
        case actions.openSaveSearchDialog.ACTION:
            return {
                ...state,
                showSaveSearchDialog: true,
            };
        case actions.hasSavedSearchResponse.ACTION:
            return {
                ...state,
                savedSearchResponse: true,
            };
        case settings.search.loadListings.REQUEST: {
            if (
                action.data.searchType === SEARCH_TYPE_RESULTS ||
                action.data.searchType === SEARCH_TYPE_TOTAL_AND_GEOFACETS
            ) {
                const newState = {
                    ...state,
                    query: {
                        ...state.query,
                        [action.data.searchType]: {
                            ...state.query[action.data.searchType],
                            isLoading: true,
                        },
                    },
                };
                return newState;
            }
            return state;
        }
        case settings.search.loadListings.SUCCESS: {
            const { searchType, rawQuery, searchLevel } = action.requestData;
            let newState = { ...state, suggestedCategory: null };

            if (rawQuery) {
                const query = {
                    assignments: {},
                    ...rawQuery.filters,
                    pageInfo: {
                        currentPage: rawQuery.page,
                        itemsPerPage: rawQuery.resultsPerPage,
                        totalItems: action.data.total,
                    },
                };

                // Sort
                if (rawQuery.sort) {
                    query.sort = rawQuery.sort;
                }

                // Dates
                if (query.assignments) {
                    // Default search - both or none of the dates must be set
                    if (
                        searchType === 'default' &&
                        !(query.assignments.dateFrom && query.assignments.dateTo)
                    ) {
                        if (query.assignments.dateFrom) {
                            delete query.assignments.dateFrom;
                        }
                        if (query.assignments.dateTo) {
                            delete query.assignments.dateTo;
                        }
                    }
                    // Date options
                    const dateOptions = [];
                    if (query.assignments.approximateDates) {
                        dateOptions.push('approximateDates');
                        delete query.assignments.approximateDates;
                    }
                    if (query.assignments.reviewing) {
                        dateOptions.push('reviewing');
                        delete query.assignments.reviewing;
                    }
                    if (query.assignments.confirmed) {
                        dateOptions.push('confirmed');
                        delete query.assignments.confirmed;
                    }
                    if (dateOptions.length > 0) {
                        query.assignments.dateOptions = dateOptions;
                    }
                }

                newState = {
                    ...newState,
                    query: {
                        ...newState.query,
                        [searchType]: {
                            ...query,
                            isLoading: false,
                        },
                    },
                    searchLevel,
                };
            }

            return newState;
        }

        case userRegistered.ACTION:
        case actions.scrollToTop.ACTION: {
            // only enable scrolling after registering to prevent any side effects
            return {
                ...state,
                scrollToResults: true,
            };
        }
        case actions.disableScrolling.ACTION: {
            return {
                ...state,
                scrollToResults: false,
            };
        }
        case actions.isGeoPoint.ACTION: {
            return {
                ...state,
                isGeoPoint: action.isGeoPoint,
            };
        }
        case actions.changeSuggestedCategory.ACTION: {
            return {
                ...state,
                suggestedCategory: action.suggestedCategory,
            };
        }
        case actions.openLowApplicantsPrompt.ACTION: {
            return {
                ...state,
                showLowApplicantsPrompt: true,
            };
        }
        case actions.closeLowApplicantsPrompt.ACTION: {
            return {
                ...state,
                showLowApplicantsPrompt: false,
            };
        }
        case actions.localSitsABTestVariant.ACTION: {
            return {
                ...state,
                localSitsABTestVariant: action.variant,
            };
        }
        default:
            return state;
    }
};
