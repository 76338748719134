import { slugify } from 'api/helpers/format/text';

const debounce = (fn, time) => {
    let timeout;

    return (...args) => {
        const functionCall = () => fn.apply(this, args);

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
    };
};

const isBrowser = () => typeof window !== 'undefined';

/**
 * @description
 * Event listener helper that returns remove func.
 *
 * @param node
 * @param event
 * @param handler
 * @param capture
 *
 * @example
 * addEventListener(document, 'mouseup', this.onMouseUp);
 *
 * @returns {{remove: remove}}
 */
const addEventListener = (node, event, handler, capture) => {
    node.addEventListener(event, handler, capture);
    return {
        remove: function remove() {
            node.removeEventListener(event, handler, capture);
        },
    };
};

export { debounce, slugify, isBrowser, addEventListener };
