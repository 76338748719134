import paths from './route-paths';
import PetParents from './PetParents';

export default [
    {
        path: paths.petParents,
        exact: true,
        component: PetParents,
    },
];
