import styled from 'styled-components';
import { GRAPH_HEIGHT } from './Graph.constants';

const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    height: ${GRAPH_HEIGHT}px;
`;

const PathStyled = styled.path`
    fill: ${({ theme }) => theme.palette.grey[100]};
`;

export { WrapperStyled, PathStyled };
