import styled from 'styled-components';

const BackgroundContainerStyled = styled.div`
    ${({ background }) => background && `background-color: ${background};`};
`;

const LayoutContainerStyled = styled.div`
    max-width: ${({ hasMaxWidth }) => (hasMaxWidth ? '1440px' : 'auto')};
    margin-left: auto;
    margin-right: auto;

    padding-left: ${({ fullWidthUntil, theme }) => (fullWidthUntil < 320 ? theme.spacing[3] : 0)};
    padding-right: ${({ fullWidthUntil, theme }) => (fullWidthUntil < 320 ? theme.spacing[3] : 0)};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding-left: ${({ fullWidthUntil, theme }) =>
            fullWidthUntil <= theme.screenSize.mediumTablet ? theme.spacing[3] : 0};
        padding-right: ${({ fullWidthUntil, theme }) =>
            fullWidthUntil <= theme.screenSize.mediumTablet ? theme.spacing[3] : 0};
    }

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        padding-left: ${({ fullWidthUntil, theme }) =>
            fullWidthUntil <= theme.screenSize.mediumDesktop ? theme.spacing[4] : 0};
        padding-right: ${({ fullWidthUntil, theme }) =>
            fullWidthUntil <= theme.screenSize.mediumDesktop ? theme.spacing[4] : 0};
    }

    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        padding-left: ${({ fullWidthUntil, theme }) =>
            fullWidthUntil <= theme.screenSize.largeDesktop ? theme.spacing[5] : 0};
        padding-right: ${({ fullWidthUntil, theme }) =>
            fullWidthUntil <= theme.screenSize.largeDesktop ? theme.spacing[5] : 0};
    }
`;

export { BackgroundContainerStyled, LayoutContainerStyled };
