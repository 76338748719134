// eslint-disable-next-line import/prefer-default-export
export const FILTER_LOCATION_FAILED = {
    create(action, input, category) {
        const payload = {
            action,
            input,
            category,
        };

        return {
            type: 'Filter locations failed',
            payload,
        };
    },
};
