import * as actions from './actions';

export default function reducer(state = {}, action) {
    switch (action.type) {
        case actions.fetchPostsSuccess.ACTION:
            return {
                ...state,
                posts: action.payload,
            };
        default:
            return state;
    }
}
