import { settings } from 'api/actions';
import { assignmentsLoaded } from './actions';

const defaultState = {
    updateId: 0,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case settings.owner.loadListing.SUCCESS:
            return {
                ...state,
                listing: {
                    ...action.data,
                },
            };
        case settings.owner.updateListing.DONE: {
            const isSuccess = action.statusCode === 200;
            const listing = isSuccess ? { ...action.data } : state.listing;

            return {
                ...state,
                listing,
                responseStatus: action.statusCode,
                updateId: state.updateId + 1,
            };
        }
        case assignmentsLoaded.ACTION: {
            return {
                ...state,
                listing: {
                    ...state.listing,
                    assignments: [...action.pastAssignments, ...action.openAssignments],
                },
            };
        }
        case settings.owner.loadListingPets.SUCCESS: {
            const findPetById = (pets, id) => pets.find((pet) => pet.id === id);
            const listing = state.listing || { pets: [] };
            const existingPets = listing.pets;
            const updatedPets = action.data.filter((incomingPet) =>
                findPetById(existingPets, incomingPet.id)
            );
            const newPets = action.data.filter(
                (incomingPet) => !findPetById(existingPets, incomingPet.id)
            );

            const updatedExistingPets = existingPets.map((existingPet) => {
                const update = findPetById(updatedPets, existingPet.id);

                return update ? { ...existingPet, ...update } : existingPet;
            });

            return {
                ...state,
                listing: {
                    ...state.listing,
                    pets: [...updatedExistingPets, ...newPets],
                },
            };
        }
        default:
            return state;
    }
};
