import { combineReducers } from 'redux';
import applications, { initialState as applicationsInitialState } from './applications';
import assignments, { initialState as assignmentsInitialState } from './assignments';
import currentAssignments, {
    initialState as currentAssignmentsInitialState,
} from './currentAssignments';
import confirmedAssignments, {
    initialState as confirmedAssignmentsInitialState,
} from './confirmedAssignments';
import upcomingAssignments, {
    initialState as upcomingAssignmentsInitialState,
} from './upcomingAssignments';
import pastAssignments, { initialState as pastAssignmentsInitialState } from './pastAssignments';
import futureAssignments, {
    initialState as futureAssignmentsInitialState,
} from './futureAssignments';
import conversations, { initialState as conversationsInitialState } from './conversations';
import favourites, { initialState as favouritesInitialState } from './favourites';
import feedbacks, { initialState as feedbacksInitialState } from './feedbacks';
import guides, { initialState as guidesInitialState } from './guides';
import address, { initialState as addressInitialState } from './address';
import listings, { initialState as listingsInitialState } from './listings';
import medias, { initialState as mediasInitialState } from './medias';
import openAssignments, { initialState as openAssignmentsInitialState } from './openAssignments';
import deletedAssignments, {
    initialState as deletedAssignmentsInitialState,
} from './deletedAssignments';
import unconfirmedPastAssignments, {
    initialState as unconfirmedPastAssignmentsInitialState,
} from './unconfirmedPastAssignments';
import pets, { initialState as petsInitialState } from './pets';
import reminders, { initialState as remindersInitialState } from './reminders';
import reviews, { initialState as reviewsInitialState } from './reviews';
import profiles, { initialState as profilesInitialState } from './profiles';
import sitter, { initialState as sitterInitialState } from './sitter';
import amenities, { initialState as amenitiesInitialState } from './amenities';

export const initialState = {
    applications: applicationsInitialState,
    assignments: assignmentsInitialState,
    currentAssignments: currentAssignmentsInitialState,
    confirmedAssignments: confirmedAssignmentsInitialState,
    upcomingAssignments: upcomingAssignmentsInitialState,
    pastAssignments: pastAssignmentsInitialState,
    futureAssignments: futureAssignmentsInitialState,
    conversations: conversationsInitialState,
    favourites: favouritesInitialState,
    feedbacks: feedbacksInitialState,
    address: addressInitialState,
    guides: guidesInitialState,
    listings: listingsInitialState,
    medias: mediasInitialState,
    openAssignments: openAssignmentsInitialState,
    deletedAssignments: deletedAssignmentsInitialState,
    unconfirmedPastAssignments: unconfirmedPastAssignmentsInitialState,
    pets: petsInitialState,
    reminders: remindersInitialState,
    reviews: reviewsInitialState,
    profiles: profilesInitialState,
    sitter: sitterInitialState,
    amenities: amenitiesInitialState,
};

export default combineReducers({
    applications,
    assignments,
    confirmedAssignments,
    currentAssignments,
    upcomingAssignments,
    pastAssignments,
    futureAssignments,
    conversations,
    favourites,
    feedbacks,
    address,
    guides,
    listings,
    medias,
    openAssignments,
    deletedAssignments,
    unconfirmedPastAssignments,
    pets,
    reminders,
    reviews,
    profiles,
    sitter,
    amenities,
});
