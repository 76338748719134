import { Facet } from './constants';

/**
 * Calculates optimum geohash facet
 */
// eslint-disable-next-line import/prefer-default-export
export const calculateOptimumGeohashFacet = (
    north,
    south,
    east,
    west
) => {
    const area = (east - west) * (north - south);
    if (area > 2025.0) {
        return Facet.GEOHASH1;
    }
    if (area > 63.28125) {
        return Facet.GEOHASH2;
    }
    if (area > 1.9775390625) {
        return Facet.GEOHASH3;
    }

    return Facet.GEOHASH4;
};

export const getGeoHierarchy = (place) => {
    if (place.featureCode === 'CONT') {
        return {
            continentSlug: place.continentSlug,
        };
    }
    if (['PCLH', 'PCLI', 'PCLIX', 'PCLS'].includes(place.featureCode)) {
        return {
            countrySlug: place.countrySlug,
        };
    }
    if (['ADM1', 'ADM1H'].includes(place.featureCode)) {
        return {
            countrySlug: place.countrySlug,
            admin1Slug: place.admin1Slug,
        };
    }
    if (['ADM2', 'ADM2H'].includes(place.featureCode)) {
        return {
            countrySlug: place.countrySlug,
            admin1Slug: place.admin1Slug,
            admin2Slug: place.admin2Slug,
        };
    }
    if (['ADM3', 'ADM3H'].includes(place.featureCode)) {
        return {
            countrySlug: place.countrySlug,
            admin1Slug: place.admin1Slug,
            ...(place.admin2Slug ? { admin2Slug: place.admin2Slug } : {} ),
            admin3Slug: place.admin3Slug,
        };
    }
    if (['ADM4', 'ADM4H'].includes(place.featureCode)) {
        return {
            countrySlug: place.countrySlug,
            admin1Slug: place.admin1Slug,
            ...(place.admin2Slug ? { admin2Slug: place.admin2Slug } : {}),
            ...(place.admin3Slug ? { admin3Slug: place.admin3Slug } : {}),
            admin4Slug: place.admin4Slug,
        };
    }
    return null;
};
