import { generateStatusKey, generateRequestedFieldsSymbol } from '../sagas/helpers';
import { AVAILABLE_ACTION_STATUSES } from '../actions/constants';
import { getState } from './common';

/**
 * Look up the status of a specific request by building a key from the info provided about the request.
 * @param {object} state - Current redux store state.
 * @param {string} module - Config for the action that started the request.
 * @param {string} objectKey - Key that was passed in the request, normally the ID of the thing we want back from the server.
 * @param {array} fields - The fields we requested back from the server.
 */
const getRequestStatus = ({ state, module, objectKey, fields }) => {
    const requestedFields = generateRequestedFieldsSymbol({ fields });
    const actionKey = generateStatusKey(module, objectKey, requestedFields);
    return state.status[actionKey];
};

/**
 * Check if the status of a request matches any of the statuses passed in.
 * @param {object} state - Current redux store state.
 * @param {object} actionSettings - Config for the action that started the request.
 * @param {string} objectKey - Key that was passed in the request, normally the ID of the thing we want back from the server.
 * @param {array} fields - The fields we requested back from the server.
 * @param {string} action - The action of the request. This is generally defined in the actionSettings object. Default is 'LOAD'.
 */
const doesRequestStatusMatch = ({
    state,
    module,
    objectKey,
    fields,
    action = 'LOAD',
    statusesToMatch = [AVAILABLE_ACTION_STATUSES.SUCCESS],
}) => {
    const status = getRequestStatus({ state, module, objectKey, fields });
    if (!status) return false;
    if (!status[action]) return false;
    const currentRequestStatus = status[action].status;
    return statusesToMatch.includes(currentRequestStatus);
};

/**
 * Check if there is a status recorded at all for a specific request
 * @param {object} state - Current redux store state.
 * @param {object} actionSettings - Config for the action that started the request.
 * @param {string} objectKey - Key that was passed in the request, normally the ID of the thing we want back from the server.
 * @param {array} fields - The fields we requested back from the server.
 * @param {string} action - The action of the request. This is generally defined in the actionSettings object. Default is 'LOAD'.
 */
const didRequestStart = ({ state, module, objectKey, fields, action = 'LOAD' }) => {
    const status = getRequestStatus({ state, module, objectKey, fields });
    if (!status) return false;
    return !!status[action];
};

/**
 * Find out if a specific request is currently running
 * @param {object} state - Current redux store state.
 * @param {object} actionSettings - Config for the action that started the request.
 * @param {string} objectKey - Key that was passed in the request, normally the ID of the thing we want back from the server.
 * @param {array} fields - The fields we requested back from the server.
 * @param {array} validEndStatuses - Array of strings representing what this call counts as a request that has 'ended'. Defaults to just ['SUCCESS'].
 */
export const isRequestRunning = ({
    state,
    actionSettings,
    objectKey = null,
    fields = [],
    validEndStatuses,
}) => {
    if (getState(state).status) {
        const retrievedState = getState(state);
        return (
            didRequestStart({
                state: retrievedState,
                module: actionSettings.module,
                objectKey,
                fields,
                action: actionSettings.action,
            }) &&
            !doesRequestStatusMatch({
                state: retrievedState,
                module: actionSettings.module,
                objectKey,
                fields,
                action: actionSettings.action,
                statusesToMatch: validEndStatuses,
            })
        );
    }
    return false;
};

/**
 * Find out if a specific request has ended
 * @param {object} state - Current redux store state.
 * @param {object} actionSettings - Config for the action that started the request.
 * @param {string} objectKey - Key that was passed in the request, normally the ID of the thing we want back from the server.
 * @param {array} fields - The fields we requested back from the server.
 * @param {array} validEndStatuses - Array of strings representing what this call counts as a request that has 'ended'. Defaults to just ['SUCCESS'].
 */
export const hasRequestEnded = ({
    state,
    actionSettings,
    objectKey = null,
    fields = [],
    validEndStatuses,
}) => {
    if (getState(state).status) {
        const retrievedState = getState(state);
        return doesRequestStatusMatch({
            state: retrievedState,
            module: actionSettings.module,
            objectKey,
            fields,
            action: actionSettings.action,
            statusesToMatch: validEndStatuses,
        });
    }
    return false;
};

/**
 * Get information about the status of an ongoing request
 * @param {object} state - Current redux store state.
 * @param {object} actionSettings - Config for the action that started the request.
 * @param {string} objectKey - Key that was passed in the request, normally the ID of the thing we want back from the server.
 * @param {array} fields - The fields we requested back from the server.
 */
export const requestStatus = ({ state = null, actionSettings, objectKey = null, fields = [] }) => {
    if (getState(state).status) {
        const status = getRequestStatus({
            state,
            module: actionSettings.module,
            objectKey,
            fields,
        });
        if (!status) return null;
        if (!status[actionSettings.action]) return null;
        return status[actionSettings.action];
    }
    return null;
};
