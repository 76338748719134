import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { H2, P, Small } from '@ths/design-system';
import { routes } from 'utils/routes';
import usePrismicStyles from 'hooks/usePrismicStyles';
import Link from 'components/Link';
import { StyledChevronRightIcon } from './ResourceLinks.style';
import { DEFAULT_MOBILE_IMAGE_WIDTH } from './constants';
import Subheading from './components/Subheading';

const ResourceLinks = ({ backgroundColor, heading, subheading, staticPosts }) => {
    const postsFromStore = useSelector((store) => store?.pages?.page?.resourceLinks?.posts);
    const prismicClasses = usePrismicStyles({
        backgroundColor,
    });

    const posts = staticPosts || postsFromStore;

    return (
        <div className={prismicClasses}>
            <div className="mx-auto my-0 flex max-w-[965px] flex-col gap-y-[72px] px-6 pb-[92px] pt-[86px] md:px-8 lg:px-0">
                <div className="flex flex-col gap-y-4">
                    <H2 className="m-0 text-center text-primary-700">{heading}</H2>
                    <Subheading subheading={subheading} />
                </div>

                <div className="grid grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-11 md:gap-y-11 lg:grid-cols-3">
                    {posts?.map(
                        ({
                            slug,
                            shortTitle,
                            excerpt,
                            coverImage,
                            area = 'blog',
                            category,
                            categorySlug,
                        }) => (
                            <div
                                key={slug}
                                className="grid grid-flow-row grid-rows-[auto_65px_auto] gap-y-[15px]"
                            >
                                <picture>
                                    <source
                                        media={`(min-width: ${
                                            coverImage.mobile?.dimensions?.width ||
                                            DEFAULT_MOBILE_IMAGE_WIDTH
                                        }px)`}
                                        srcSet={coverImage.url}
                                    />
                                    <img
                                        src={coverImage.mobile?.url || coverImage.url}
                                        alt={coverImage.alt}
                                        className="h-[169px] w-full rounded-[10px] object-cover"
                                    />
                                </picture>
                                <div className="flex items-center justify-between gap-x-4">
                                    <Link
                                        to={routes.blog.post({
                                            area,
                                            category:
                                                typeof category === 'string'
                                                    ? category
                                                    : categorySlug,
                                            slug,
                                        })}
                                    >
                                        <P
                                            color="accent"
                                            className="m-0 line-clamp-2 overflow-hidden font-semibold underline underline-offset-2"
                                        >
                                            {shortTitle}
                                        </P>
                                    </Link>
                                    <StyledChevronRightIcon />
                                </div>
                                <Small className="m-0 line-clamp-3 overflow-hidden">
                                    {excerpt}
                                </Small>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

ResourceLinks.defaultProps = {
    backgroundColor: 'white',
    subheading: null,
    staticPosts: null,
};

ResourceLinks.propTypes = {
    backgroundColor: PropTypes.string,
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.arrayOf(
        PropTypes.shape({
            spans: PropTypes.arrayOf(
                PropTypes.shape({
                    start: PropTypes.number.isRequired,
                    end: PropTypes.number.isRequired,
                    type: PropTypes.string.isRequired,
                    data: PropTypes.shape({
                        id: PropTypes.string.isRequired,
                        slug: PropTypes.string.isRequired,
                        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
                        lang: PropTypes.string.isRequired,
                    }).isRequired,
                })
            ),
            text: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        })
    ),
    staticPosts: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string.isRequired,
            shortTitle: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired,
            coverImage: PropTypes.shape({
                alt: PropTypes.string.isRequired,
                mobile: PropTypes.shape({
                    dimensions: PropTypes.shape({
                        width: PropTypes.number.isRequired,
                    }).isRequired,
                    url: PropTypes.string.isRequired,
                }).isRequired,
                url: PropTypes.string.isRequired,
            }).isRequired,
            area: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
        })
    ),
};

export default ResourceLinks;
