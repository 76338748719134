import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getSearchPlaces, getSearchSuggestionsOnEnter } from 'api/selectors/search';
import { settings as apiActions } from 'api/actions/search';
import { defaultSuggestions } from 'components/forms/FilterLocation/FilterLocation.constants';
import { track, Events } from 'utils/analytics';
import {
    experiments,
    getExperimentalFeatureVariationSelector,
    userTypes,
    VariationTypes,
} from 'containers/ExperimentalFeature';
import FilterLocation from 'components/forms/FilterLocation/FilterLocation';
import * as sharedActions from 'shared/actions';
import { debounce } from 'utils/funcs';
import { features, isFeatureEnabled } from 'components/Feature';

import quickSearchLocations from 'pages/search/FindAHouseSitWizard/components/WizardLocation/components/QuickSearch/QuickSearch.constants';
import * as actions from './FilterLocation.actions';

const trackFilteredLocationFailed = ({ action, input, suggestions, type }) => {
    // When there are no results, the object has one item of Anywhere
    const noFilteredResults = suggestions.length <= 1;

    if (
        action &&
        input &&
        noFilteredResults &&
        isFeatureEnabled({ name: features.SEARCH_FILTER_FAILED })
    ) {
        track(Events.FILTER_LOCATION_FAILED.create(action, input, type));
    }
};

const debouncedTrackFilteredLocationFailed = debounce(
    ({ action, input, suggestions, pathname, type }) =>
        trackFilteredLocationFailed({
            action,
            input,
            suggestions,
            pathname,
            type,
        }),
    1000
);

const FilterLocationSearch = (props) => {
    const [typedLocation, setTypedLocation] = useState('');
    const [inputOnEnter, setInputOnEnter] = useState('');
    const [prevInput, setPrevInput] = useState(undefined);

    const {
        onChange,
        searchSuggestionsOnEnter,
        searchPlaces,
        searchPlacesOnEnter,
        clearSuggestionsOnEnter,
        featureCodes,
        type,
        resetPlaces,
        onClear,
        ...filterLocationProps
    } = props;

    useEffect(
        () => {
            // To reduce noise - check that the event has not just been fired with the same value
            if (prevInput !== typedLocation) {
                debouncedTrackFilteredLocationFailed({
                    action: 'typing timeout',
                    input: typedLocation,
                    suggestions: props.suggestions,
                    type,
                });
            }
            setPrevInput(typedLocation);
        },
        // eslint-disable-next-line
        [props.suggestions],
    );

    const onChangeHandler = (place) => {
        props.onChange(place);
        // Log analytics
        if (typedLocation && place) {
            track(Events.SEARCH_LOCATION.create(type, typedLocation, place));
        }
    };

    const onInputChange = (location) => {
        searchPlaces(location, featureCodes);
        setTypedLocation(location);
    };

    const onEnter = (inputValue) => {
        setInputOnEnter(inputValue);
        searchPlacesOnEnter(inputValue, featureCodes);

        trackFilteredLocationFailed({
            action: 'enter key',
            input: inputValue,
            suggestions: props.suggestions,
            type,
        });
    };

    const onClearHandler = () => {
        if (onClear) onClear();
        resetPlaces();
    };

    useEffect(() => {
        const place = searchSuggestionsOnEnter[0];
        if (place) {
            track(Events.SEARCH_LOCATION.create(type, inputOnEnter, place));
            onChange(place);
            // Now dispatch something that empties this data from shared/searchSuggestionsOnEnter
            clearSuggestionsOnEnter();
        }
    }, [searchSuggestionsOnEnter, onChange, type, inputOnEnter, clearSuggestionsOnEnter]);

    return (
        <FilterLocation
            {...filterLocationProps}
            onClear={onClearHandler}
            onChange={onChangeHandler}
            onInputChange={onInputChange}
            onEnter={onEnter}
        />
    );
};

const mapStateToProps = (state, props) => {
    let suggestions = getSearchPlaces(state);
    const searchSuggestionsOnEnter = getSearchSuggestionsOnEnter(state);
    const { variation } = getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.QUICK_LOCATION_SEARCH,
        excludeCombo: [userTypes.ExpiredUser],
    });
    const isVariationQuickLocationSearch = variation === VariationTypes.VARIATION1;
    if (!suggestions.length && !props.hideDefaultLocation) {
        suggestions = defaultSuggestions;
        if (isVariationQuickLocationSearch) {
            suggestions = [...quickSearchLocations];
        }
    } else {
        suggestions.sort((a, b) => b.population - a.population);
    }
    searchSuggestionsOnEnter.sort((a, b) => b.population - a.population);
    return { suggestions, searchSuggestionsOnEnter };
};

const mapDispatchToProps = (dispatch) => ({
    resetPlaces: () => {
        dispatch({
            type: apiActions.loadPlaces.RESET,
        });
    },
    searchPlaces: (searchString, featureCodes) => {
        dispatch(actions.request.create(searchString, featureCodes));
    },
    searchPlacesOnEnter: (searchString, featureCodes) => {
        dispatch(actions.requestOnEnter.create(searchString, featureCodes));
    },
    clearSuggestionsOnEnter: () => {
        dispatch(sharedActions.clearSearchSuggestionsOnEnter.create());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterLocationSearch);
