/* eslint-disable import/prefer-default-export */
/**
 * @description
 * Clamp given number between min and max values
 *
 * @param num
 * @param min
 * @param max
 *
 * @example
 * clamp(15, 0, 10); -> Will clamp the number to max: 10
 * clamp(-5, 0, 10); -> Will clamp the number to min: 0
 *
 * @returns {number}
 */
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
