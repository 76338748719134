export const REVIEWS = [
    {
        id: '597eb52dfba87f08a82521c4',
        person: {
            firstName: 'Donna',
            lastName: 'R',
        },
        score: 5,
        quote: 'Thank goodness someone referred us to Trusted Housesitters because now we can visit the grand children guilt free.',
        url: 'https://uk.trustpilot.com/reviews/597eb52dfba87f08a82521c4',
    },
    {
        id: '59886ea0fba87f08a82760e6',
        person: {
            firstName: 'Julie',
        },
        score: 5,
        quote: "Friends referred us to the site and we have been so happy we have referred other people to the site. Couldn't be happier.",
        url: 'https://uk.trustpilot.com/reviews/59886ea0fba87f08a82760e6',
    },
    {
        id: '5e0f298a98483693a07ce232',
        person: {
            firstName: 'Lorraine',
            lastName: 'Flynn',
        },
        score: 5,
        quote: 'So happy to be referred to TrustedHousesitters by a friend. So easy to set up and find our sitters who were fantastic.',
        url: 'https://uk.trustpilot.com/users/5e0f298a98483693a07ce232',
    },
    {
        id: '5d897b5f3585c708a4e27810',
        person: {
            firstName: 'Margaret',
            lastName: 'Miller',
        },
        score: 5,
        quote: 'I met a lady on an airplane and was complaining about pet care while traveling. She referred me to Trusted Housesitters and I have never looked back.',
        url: 'https://uk.trustpilot.com/reviews/5d897b5f3585c708a4e27810',
    },
];
