import { MembershipType } from 'api/types/membershipTypes';

// remove when marketing is done with testing free trials
// eslint-disable-next-line import/prefer-default-export
export const FREE_TRIAL_STARTED = {
    create(membership, customerType) {
        const { membershipType } = membership;

        let label;

        if (customerType === 'New' && membershipType === MembershipType.OWNER) {
            label = 'new_owner';
        } else if (customerType === 'New' && membershipType === MembershipType.SITTER) {
            label = 'new_sitter';
        } else if (customerType === 'New' && membershipType === MembershipType.COMBINED) {
            label = 'new_combined';
        } else if (customerType === 'Renewing' && membershipType === MembershipType.OWNER) {
            label = 'renewing_owner';
        } else if (customerType === 'Renewing' && membershipType === MembershipType.SITTER) {
            label = 'renewing_sitter';
        } else if (customerType === 'Renewing' && membershipType === MembershipType.COMBINED) {
            label = 'renewing_combined';
        }

        return {
            type: 'Free Trial Started',
            payload: {
                label,
            },
        };
    },
};
