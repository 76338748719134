import { settings as actions } from '../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        // Load blog post
        case actions.content.loadSeoContent.SUCCESS: {
            const path = action.action.filters.path;
            const lang = action.action.filters.lang;
            const oldState = state[path];
            const oldStateForLang = oldState ? oldState[lang] : {};

            return {
                ...state,
                [path]: {
                    ...oldState,
                    [lang]: {
                        ...oldStateForLang,
                        ...action.data,
                        hasTitle: action.data.metaTitle,
                        hasDescription: action.data.metaDescription,
                        hasRobots: action.data.metaRobots,
                        hasKeywords: action.data.metaKeywords,
                        hasCanonicalUrl: action.data.canonicalUrl,
                        hasLinks: action.data.links && action.data.links.length > 0,
                        hasBody: action.data.body && action.data.body.length > 0,
                    },
                },
            };
        }
        default:
            return state;
    }
};
