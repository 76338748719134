import PropTypes from 'prop-types';

import { baseMediaProps } from './common';

export const settingsProps = {
    alerts: PropTypes.oneOf(['daily', 'real', 'none']),
    canContactPhone: PropTypes.bool,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
};
export const settings = PropTypes.shape(settingsProps);

export const membershipProps = {
    expiryDate: PropTypes.string,
    membershipType: PropTypes.oneOf(['homeowner', 'housesitter', 'combined']),
};
export const membership = PropTypes.shape(membershipProps);

export const wsTokenProps = {
    timestamp: PropTypes.string,
    token: PropTypes.string,
};
export const wsToken = PropTypes.shape(wsTokenProps);
// export const messages = {
//     unreadCount: PropTypes.number,
//     token: PropTypes.shape({
//         timestamp: PropTypes.number,
//         token: PropTypes.string,
//     }),
// };

export const pushNotificationSubscriptionsProps = {
    applicationUpdates: PropTypes.bool,
    dailySits: PropTypes.bool,
    messages: PropTypes.bool,
    recommendations: PropTypes.bool,
};
export const pushNotificationSubscriptions = PropTypes.shape(pushNotificationSubscriptionsProps);

export const detailsProps = {
    facebookUserId: PropTypes.string,
    hasAccessToVetHelp: PropTypes.bool,
    joinDate: PropTypes.string, // "2014-10-10T21:55:04"
    profilePhoto: baseMediaProps,
    referAFriendCode: PropTypes.string,
};
export const details = PropTypes.shape(detailsProps);

export const accountProps = {
    id: PropTypes.string,
    details: PropTypes.shape(details),
    membership: PropTypes.shape(membership),
    messageToken: PropTypes.shape(wsToken),
    pushNotifications: PropTypes.shape(pushNotificationSubscriptions),
    settings: PropTypes.shape(settings),
};
export const account = PropTypes.shape(accountProps);


// @TODO ?
// Sitter information with data count and last modified date
export const sitterProps = {
    applications: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    availabilities: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    conversations: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    favourites: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    feedbacks: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    invitations: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    medias: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    profile: PropTypes.shape({
        lastModified: PropTypes.string,
    }),
    reviews: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    verifications: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
};
export const sitter = PropTypes.shape(sitterProps);

// Owner information with data count and last modified date
export const ownerProps = {
    applications: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    availabilities: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    conversations: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    favourites: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    feedbacks: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    invitations: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    medias: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    profile: PropTypes.shape({
        lastModified: PropTypes.string,
    }),
    reviews: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
    verifications: PropTypes.shape({
        count: PropTypes.number,
        lastModified: PropTypes.string,
    }),
};
export const owner = PropTypes.shape(ownerProps);
