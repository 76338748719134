import apiActions, { settings } from 'api/actions';
import {
    error as errorAction,
    preload as pageActionsPreload,
    lazyload as pageActionsLazyload,
    preloaded as pageActionsPreloaded,
    lazyloaded as pageActionsLazyloaded,
} from 'containers/Page/actions';
import { put, take } from 'redux-saga/effects';

const pageActionsMapped = {
    [pageActionsPreload.ACTION]: pageActionsPreloaded,
    [pageActionsLazyload.ACTION]: pageActionsLazyloaded,
};

export function* loadAssignment(params, actionType, PAGE_ID) {
    yield put(
        apiActions.owner.loadAssignment({
            forceReload: true,
            data: {
                id: params.assignmentId,
                listingId: params.listingId,
                assignmentId: params.assignmentId,
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadAssignment.DONE
    );

    if (status !== settings.owner.loadAssignment.SUCCESS) {
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    yield put(pageActionsMapped[actionType].create(PAGE_ID));
    return true;
}

export function* loadWelcomeGuide(params, actionType, PAGE_ID) {
    yield put(
        apiActions.owner.loadWelcomeGuide({
            forceReload: true,
            data: {
                id: params.listingId,
                listingId: params.listingId,
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadWelcomeGuide.DONE
    );

    if (status !== settings.owner.loadWelcomeGuide.SUCCESS) {
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    yield put(pageActionsMapped[actionType].create(PAGE_ID));
    return true;
}

export function* loadPets(params, actionType, PAGE_ID) {
    yield put(
        apiActions.owner.loadListingPets({
            forceReload: true,
            data: {
                id: params.listingId,
                listingId: params.listingId,
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadListingPets.DONE
    );

    if (status !== settings.owner.loadListingPets.SUCCESS) {
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    yield put(pageActionsMapped[actionType].create(PAGE_ID));
    return true;
}

export function* loadPlacesOfInterest(params, actionType, PAGE_ID) {
    yield put(
        apiActions.owner.loadListingPlacesOfInterest({
            forceReload: true,
            data: {
                id: params.listingId,
                listingId: params.listingId,
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadListingPlacesOfInterest.DONE
    );

    if (status !== settings.owner.loadListingPlacesOfInterest.SUCCESS) {
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    yield put(pageActionsMapped[actionType].create(PAGE_ID));
    return true;
}

export function* loadWelcomeGuidePhotos(params, actionType, PAGE_ID) {
    yield put(
        apiActions.owner.loadWelcomeGuidePhotos({
            forceReload: true,
            data: {
                id: params.listingId,
                listingId: params.listingId,
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadWelcomeGuidePhotos.DONE
    );

    if (status !== settings.owner.loadWelcomeGuidePhotos.SUCCESS) {
        yield put(errorAction.create(PAGE_ID, statusCode));
        return false;
    }

    yield put(pageActionsMapped[actionType].create(PAGE_ID));
    return true;
}
