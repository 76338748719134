import { settings as actions } from '../../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.sitter.loadSitterOwnerListingPets.SUCCESS:
            return {
                ...state,
                [action.requestData.id]: [...action.data],
            };
        default:
            return state;
    }
};
