import React from 'react';
import { Container, InputStyled } from './InputIcon.style';

/**
 * @deprecated Use `src/components/Input` instead.
 */
const InputIcon = ({
    iconLeft,
    iconRight,
    onLeftIconClick,
    onRightIconClick,
    borderRadius,
    renderBorder,
    ...props
}) => {
    if (typeof iconLeft === 'string') {
        console.log(
            new TypeError(
                `\`icon: ${iconLeft}\` prop as string is not supported, use the actual imported icon component.`
            )
        );
    }

    if (typeof iconRight === 'string') {
        console.log(
            new TypeError(
                `\`icon: ${iconRight}\` prop as string is not supported, use the actual imported icon component.`
            )
        );
    }

    // This function is used for backward compatibility because some components
    // pass icons through a plain JSX instead of ReactElement.
    const renderIcon = (Icon) => {
        if (typeof Icon === 'object') {
            return Icon;
        }

        if (typeof Icon === 'function') {
            return <Icon />;
        }

        return null;
    };

    const hasIconLeft = !!iconLeft;
    const hasIconRight = !!iconRight;

    return (
        <Container renderBorder={renderBorder}>
            {hasIconLeft ? renderIcon(iconLeft) : null}

            <InputStyled iconLeft={hasIconLeft} iconRight={hasIconRight} {...props} />

            {hasIconRight ? renderIcon(iconRight) : null}
        </Container>
    );
};

InputIcon.defaultProps = {
    renderBorder: true,
};

export default InputIcon;
