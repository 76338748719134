import { all, put, takeEvery } from 'redux-saga/effects';
import {
    preload as pageActionsPreload,
    preloaded as pageActionsPreloaded,
    load as pageActionsLoad,
    loaded as pageActionsLoaded,
    setNavbarOptions as pageActionsSetNavbarOptions,
    setFooterOptions as pageActionsSetFooterOptions,
} from 'containers/Page/actions';
import PAGE_ID from './FindAHouseSitWizard.constants';

export function* setNavBarAndFooterVisibility() {
    yield put(pageActionsSetNavbarOptions.create({ hideLeftSide: true, hideRightSide: true }));
    yield put(pageActionsSetFooterOptions.create({ hideLinks: true }));
}

export function* load() {
    yield setNavBarAndFooterVisibility();
    yield put(pageActionsLoaded.create(PAGE_ID));
}

export function* preload() {
    yield setNavBarAndFooterVisibility();
    yield put(pageActionsPreloaded.create(PAGE_ID));
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
    ]);
}
