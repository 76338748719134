import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

export const initialState = {
        results: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadProfilesFavouritedYou.SUCCESS: {
            return {
                    results: action.data.results.map(profile => transform.getProfile(profile)),
                    total: action.data.total,
            };
        }
        default:
            return state;
    }
};
