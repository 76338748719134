export function getCookie(name) {
    return (
        decodeURIComponent(
            document.cookie.replace(
                new RegExp(
                    `(?:(?:^|.*;)\\s*${encodeURIComponent(name).replace(
                        /[-.+*]/g,
                        '\\$&'
                    )}\\s*\\=\\s*([^;]*).*$)|^.*$`
                ),
                '$1'
            )
        ) || null
    );
}

export function setCookie(name, value, expires, path, domain, secure) {
    if (!name || /^(?:expires|max-age|path|domain|secure)$/i.test(name)) {
        return false;
    }
    let sExpires = '';
    if (expires) {
        switch (expires.constructor) {
            case Number:
                sExpires =
                    expires === Infinity
                        ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                        : `; max-age=${expires}`;
                break;
            case String:
                sExpires = `; expires=${expires}`;
                break;
            case Date:
                sExpires = `; expires=${expires.toUTCString()}`;
                break;
            default:
                break;
        }
    }
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${sExpires}${
        domain ? `; domain=${domain}` : ''
    }${path ? `; path=${path}` : ''}${secure ? '; secure' : ''}`;
    return true;
}
