// @ flow
import React, { useEffect } from 'react';
import { FlexboxGrid } from 'components/layouts';
import { useTranslation } from 'react-i18next';
import { routes } from 'utils/routes';
import { track, Events } from 'utils/analytics';
import Link from 'components/Link';

import {
    BodyContainer,
    HeaderWrapper,
    CloseButton,
    TitleStyled,
    ConfirmationBodyTextStyled,
    MembershipPlanButton,
    OrderedCol,
    BackToInbox,
    BodyWrapper,
    SemiBoldText,
} from './FakeDoorErrorContent.style';

export default function FakeDoorErrorContent({ closeModal }) {
    const { t } = useTranslation();

    useEffect(() => {
        track(Events.VIEWED_FAKE_DOOR_CONFIRMATION_MODAL.create());
    }, []);

    const trackButtonEvent = (item) => {
        track(
            Events.BUTTONCLICK.create({
                description: 'Sit Upsell Error page CTA',
                item,
            })
        );
    };
    return (
        <BodyContainer data-testid="addons-checkout-wrapper-id">
            <HeaderWrapper>
                <CloseButton onClick={() => closeModal()} />
            </HeaderWrapper>
            <FlexboxGrid
                justify={FlexboxGrid.Justify.CENTER}
                config={{
                    base: {
                        verticalSpacing: 0,
                        horizontalSpacing: 0,
                    },
                    largeDesktop: {
                        verticalSpacing: 0,
                        horizontalSpacing: 0,
                    },
                }}
            >
                <OrderedCol mobileOrder={1} span={12} largeTablet={10} mediumDesktop={7}>
                    <BodyWrapper>
                        <TitleStyled>
                            {t('transaction_scaler_modal_confirmation_title')}
                        </TitleStyled>

                        <ConfirmationBodyTextStyled>
                            {t('transaction_scaler_modal_confirmation_sub_title')}
                        </ConfirmationBodyTextStyled>

                        <ConfirmationBodyTextStyled>
                            <SemiBoldText>
                                {t('transaction_scaler_modal_confirmation_charge_disclaimer')}
                            </SemiBoldText>
                        </ConfirmationBodyTextStyled>

                        <ConfirmationBodyTextStyled>
                            {t('transaction_scaler_modal_confirmation_label')}
                        </ConfirmationBodyTextStyled>
                        <MembershipPlanButton
                            variant={Link.Variant.Button.PRIMARY}
                            onClick={() => {
                                trackButtonEvent('View Membership Plans');
                                closeModal();
                            }}
                            to={`${routes.accounts.explorePlans()}`}
                        >
                            {t('transaction_scaler_modal_confirmation_membership_plan')}
                        </MembershipPlanButton>

                        <BackToInbox
                            onClick={() => {
                                trackButtonEvent('Back to Inbox');
                                closeModal();
                            }}
                            to={routes.user.inbox()}
                        >
                            {t('transaction_scaler_modal_confirmation_back_to_inbox')}
                        </BackToInbox>
                    </BodyWrapper>
                </OrderedCol>
            </FlexboxGrid>
        </BodyContainer>
    );
}
