import {
    experiments,
    getExperimentalFeatureVariationSelector,
} from 'containers/ExperimentalFeature';

export const getCurrentState = (state) => state.containers?.rafNudgewall?.state ?? null;

export const getIsVisible = (state) => state.containers?.rafNudgewall?.shouldShow ?? false;

export const getReferalAFriendOneTouchVariation = (state) =>
    getExperimentalFeatureVariationSelector(state, {
        experiment: experiments.REFER_A_FRIEND_NUDGEWALL_ONE_TOUCH,
    });
