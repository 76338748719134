import {
    Facet as FacetSearchLevel,
    Sitemap as SitemapSearchLevel,
} from 'api/helpers/search/constants';

export const PAGE_ID = 'SitemapPage';
export const PAGE_REDUCER_KEY = 'sitemap';
export const PAGE_SAGA_KEY = 'sitemap';
export const SITEMAP_PATH = 'sitemap';

export const SITEMAP_TYPES = {
    LISTINGS: 'listings',
    PROFILES: 'profiles',
};

export const SITEMAP_LEVELS = {
    COUNTRY: FacetSearchLevel.COUNTRY,
    REGION: FacetSearchLevel.ADMIN1,
    COUNTY: FacetSearchLevel.ADMIN2,
    CITY: FacetSearchLevel.PLACE,
};

export const SITEMAP_LINKS = {
    [SITEMAP_LEVELS.COUNTRY]: SitemapSearchLevel.COUNTRY,
    [SITEMAP_LEVELS.REGION]: SitemapSearchLevel.ADMIN1,
    [SITEMAP_LEVELS.COUNTY]: SitemapSearchLevel.ADMIN2,
    [SITEMAP_LEVELS.CITY]: SitemapSearchLevel.PLACE,
};

export const SITEMAP_PAGES = {
    HOUSE_SITS: 'house-sits',
    PET_SITTERS: 'pet-sitters',
};

export const PAGES_DATA = [
    {
        level: SITEMAP_LEVELS.COUNTRY,
        link: SITEMAP_LINKS[SITEMAP_LEVELS.COUNTRY],
    },
    {
        level: SITEMAP_LEVELS.REGION,
        link: SITEMAP_LINKS[SITEMAP_LEVELS.REGION],
    },
    {
        level: SITEMAP_LEVELS.COUNTY,
        link: SITEMAP_LINKS[SITEMAP_LEVELS.COUNTY],
    },
    {
        level: SITEMAP_LEVELS.CITY,
        link: SITEMAP_LINKS[SITEMAP_LEVELS.CITY],
    },
];

export const TITLE_TYPE = {
    [SITEMAP_TYPES.LISTINGS]: {
        title: 'pages_sitemap_navigation_house_sits',
        description: 'pages_sitemap_location_listings_description',
    },
    [SITEMAP_TYPES.PROFILES]: {
        title: 'pages_sitemap_navigation_pet_sitters',
        description: 'pages_sitemap_location_profiles_description',
    },
};

export const TITLE_LEVEL = {
    [FacetSearchLevel.COUNTRY]: 'pages_sitemap_pagination_countries',
    [FacetSearchLevel.ADMIN1]: 'pages_sitemap_pagination_regions',
    [FacetSearchLevel.ADMIN2]: 'pages_sitemap_pagination_counties',
    [FacetSearchLevel.PLACE]: 'pages_sitemap_pagination_cities',
};
