import { isFeatureEnabled } from 'components/Feature/helpers';
import Features from 'components/Feature/features';
import { removeUndefinedAndNullKeys } from 'utils/objects';
import { actionChannel, take, call } from 'redux-saga/effects';
import * as sharedActions from 'shared/actions';
import { routes } from 'utils/routes';
import { addMonths } from 'date-fns';
import { getCookie, setCookie } from 'utils/cookie';
import environment from 'environment';

const CJ_SCRIPT_ID = 'cjapitag';
const CJ_COOKIE_NAME = 'cje';

const COMMISSION_JUNCTION_TYPES = {
    ACCOUNT_CENTER: 'accountCenter',
    ACCOUNT_SIGNUP: 'accountSignup',
    CART: 'cart',
    CONVERSION_CONFIRMATION: 'conversionConfirmation',
    DEPARTMENT: 'department',
    HOMEPAGE: 'homepage',
    INFORMATION: 'information',
    PRODUCT_DETAIL: 'productDetail',
    SEARCH_RESULTS: 'searchResults',
    SUB_CATEGORY: 'subCategory',
};

const CUSTOMER_STATUS = {
    NEW: 'new',
    RETURN: 'return',
};

const pathnameToPageTypeMap = {
    [routes.home()]: COMMISSION_JUNCTION_TYPES.HOMEPAGE,
    [routes.accounts.checkout.payment()]: COMMISSION_JUNCTION_TYPES.CART,
};

const pathnameTopageTypeRegex = [
    {
        regexPath: /^\/house-and-pet-sitters\/((?!q=|\/).)*$/i,
        pageType: COMMISSION_JUNCTION_TYPES.SEARCH_RESULTS,
    },
    {
        regexPath: /^\/house-and-pet-sitting-assignments\/((?!q=|\/).)*$/i,
        pageType: COMMISSION_JUNCTION_TYPES.SEARCH_RESULTS,
    },
];

const getPageType = (pathname, trackingType) => {
    if (trackingType) return trackingType;

    if (pathnameToPageTypeMap[pathname]) return pathnameToPageTypeMap[pathname];

    const { pageType } = pathnameTopageTypeRegex.find(({ regexPath }) =>
        pathname.match(regexPath)
    ) || { pageType: COMMISSION_JUNCTION_TYPES.INFORMATION };

    return pageType;
};

const placeCommissionJunctionPageTag = (params) => {
    if (window.cj.order) delete window.cj.order;

    window.cj.sitePage = {
        ...params,
    };
};

const placeCommissionJunctionOrderTag = (params) => {
    if (window.cj.sitePage) delete window.cj.sitePage;

    window.cj.order = {
        ...params,
        cjeventOrder: getCookie(CJ_COOKIE_NAME) || getCookie('cjevent_dc'),
        actionTrackerId: environment.vars.commissionJunction.actionTrackerId,
    };
};

const isCommissionJunctionEnabled = () => isFeatureEnabled({ name: Features.COMMISSION_JUNCTION });

const removeCjScript = () => {
    const script = document.getElementById(CJ_SCRIPT_ID);
    if (script) script.remove();
};

const triggerCommissionJunction = () =>
    new Promise((resolve) => {
        removeCjScript();

        const script = document.createElement('script');
        script.id = CJ_SCRIPT_ID;
        script.type = 'text/javascript';
        script.src = 'https://www.mczbf.com/tags/12701/tag.js';
        script.defer = true;
        script.onload = resolve;
        document.head.appendChild(script);
    });

const placeCommissionJunctionTag = ({ id: userId, trackingType, ...userDetails }) => {
    const pageType = getPageType(window.location.pathname, trackingType);
    const params = {
        enterpriseId: environment.vars.commissionJunction.enterpriseId,
        pageType,
        userId,
    };

    if (!window?.cj) window.cj = {};

    if (pageType !== COMMISSION_JUNCTION_TYPES.CONVERSION_CONFIRMATION) {
        placeCommissionJunctionPageTag(removeUndefinedAndNullKeys(params));
    } else {
        const {
            currency,
            promoCode: coupon,
            discount,
            price: amount,
            transactionId: orderId,
            membershipPlanId,
            membershipType: customerType,
            customerStatus,
        } = userDetails;

        placeCommissionJunctionOrderTag(
            removeUndefinedAndNullKeys({
                ...params,
                currency,
                coupon,
                discount,
                amount,
                orderId,
                customerType,
                customerStatus,
                items: [
                    {
                        unitPrice: amount,
                        quantity: 1,
                        itemId: membershipPlanId,
                    },
                ],
            })
        );
    }

    return triggerCommissionJunction();
};

function* initializeCommissionJunctionTracking() {
    if (isCommissionJunctionEnabled()) {
        const commissionJunctionChannel = yield actionChannel(
            sharedActions.cjTrackingTriggered.ACTION
        );
        while (true) {
            const { params } = yield take(commissionJunctionChannel);
            yield call(placeCommissionJunctionTag, params);
        }
    }
}

function setCjeCookie(state) {
    const URLParams = new URLSearchParams(state.router.location.search);
    const queryParamCjevent = URLParams.get('cjevent');
    const cjeCookie = getCookie(CJ_COOKIE_NAME);
    if (queryParamCjevent && cjeCookie !== queryParamCjevent) {
        const expiryDate = addMonths(new Date(), 13);
        setCookie(
            CJ_COOKIE_NAME,
            queryParamCjevent,
            expiryDate,
            '/',
            '.trustedhousesitters.com',
            true
        );
    }
}

export {
    initializeCommissionJunctionTracking,
    isCommissionJunctionEnabled,
    placeCommissionJunctionTag,
    setCjeCookie,
    COMMISSION_JUNCTION_TYPES,
    CUSTOMER_STATUS,
};
