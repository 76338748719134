import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import { track, Events } from 'utils/analytics';
import { SEARCH_CAROUSEL_VARIANTS } from 'pages/search/SearchListings/SearchListings.constants';
import {
    ListItemStyled,
    ListWrapperStyled,
    ListContainerStyled,
    ListStyled,
    ArrowLeftStyled,
    ArrowRightStyled,
    PreviousButtonStyled,
    NextButtonStyled,
} from './Carousel.style';

const ScreenSize = {
    MOBILE: 'mobile',
    MEDIUM_HANDSET: 'mediumHandset',
    LARGE_HANDSET: 'largeHandset',
    SMALL_TABLET: 'smallTablet',
    MEDIUM_TABLET: 'mediumTablet',
    LARGE_TABLET: 'largeTablet',
    SMALL_DESKTOP: 'smallDesktop',
    MEDIUM_DESKTOP: 'mediumDesktop',
    LARGE_DESKTOP: 'largeDesktop',
    WIDE: 'wide',
    EXTRA_WIDE: 'extraWide',
};

class Carousel extends Component {
    static defaultProps = {
        perPage: {
            mobile: 1.5,
            largeHandset: 2.1,
            smallTablet: 3,
            mediumTablet: 3,
            largeTablet: 3,
            mediumDesktop: 4,
            largeDesktop: 4,
            wide: 4.7,
            extraWide: 6,
        },
    };

    constructor(props) {
        super(props);

        this.onUpdateDimensions = this.onUpdateDimensions.bind(this);
    }

    state = {
        position: 0,
        screenSize: ScreenSize.MOBILE,
        touchstartX: 0,
        touchstartY: 0,
        touchendX: 0,
        touchendY: 0,
        hasSwipedLeft: false,
        hasSwipedRight: false,
    };

    componentDidMount() {
        this.onUpdateDimensions();
        window.addEventListener('resize', this.onUpdateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onUpdateDimensions);
        window.removeEventListener('touchstart', this.handleTouchStart);
        window.removeEventListener('touchend', this.handleTouchEnd);
    }

    /**
     * Shift the list to the left by 1 if is not Recommended Carousel
     * If it is Recommended Carousel, shift by perPage number
     */
    onPreviousButtonPress = () => {
        const { analyticsDescription, analyticsItem, variant } = this.props;
        if (analyticsDescription) {
            track(
                Events.CAROUSEL.create({
                    description: `${analyticsDescription} Carousel Previous`,
                    item: analyticsItem || undefined,
                })
            );
        }
        const perPage = this.getPerPageFromScreenSize();
        const subtractedNumber = variant === SEARCH_CAROUSEL_VARIANTS.RECOMMENDED ? perPage : 1;
        this.setState({
            position: Math.max(0, this.state.position - subtractedNumber),
        });
    };

    /**
     * Shift the list to the right by 1 if is not Recommended Carousel
     * If it is Recommended Carousel, shift by perPage number
     */
    onNextButtonPress = () => {
        const { children, analyticsDescription, analyticsItem, variant } = this.props;
        const childrenLength = React.Children.count(children);

        const perPage = this.getPerPageFromScreenSize();

        if (analyticsDescription) {
            track(
                Events.CAROUSEL.create({
                    description: `${analyticsDescription} Carousel Next`,
                    item: analyticsItem || undefined,
                })
            );
        }
        const addedNumber = variant === SEARCH_CAROUSEL_VARIANTS.RECOMMENDED ? perPage : 1;
        this.setState({
            // Move one item to the left, capping at length - perPage to always show at last perPage on the page
            position: Math.min(childrenLength - perPage, this.state.position + addedNumber),
        });
    };

    /**
     * Update available filters for type
     */
    onUpdateDimensions() {
        const { perPage, children } = this.props;

        const childrenLength = React.Children.count(children);
        const width =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        let screenSize = ScreenSize.MOBILE;
        // have to check if the optional props inside perPage were passed in
        // otherwise it breaks other carousels on different pages
        if (perPage.mediumHandset && width >= this.props.theme.screenSize.mediumHandset) {
            screenSize = ScreenSize.MEDIUM_HANDSET;
        }
        if (width >= this.props.theme.screenSize.largeHandset) {
            screenSize = ScreenSize.LARGE_HANDSET;
        }
        if (perPage.smallTablet && width >= this.props.theme.screenSize.smallTablet) {
            screenSize = ScreenSize.SMALL_TABLET;
        }
        if (width >= this.props.theme.screenSize.mediumTablet) {
            screenSize = ScreenSize.MEDIUM_TABLET;
        }
        if (perPage.largeTablet && width >= this.props.theme.screenSize.largeTablet) {
            screenSize = ScreenSize.LARGE_TABLET;
        }
        if (perPage.smallDesktop && width >= this.props.theme.screenSize.smallDesktop) {
            screenSize = ScreenSize.SMALL_DESKTOP;
        }
        if (width >= this.props.theme.screenSize.mediumDesktop) {
            screenSize = ScreenSize.MEDIUM_DESKTOP;
        }
        if (width >= this.props.theme.screenSize.largeDesktop) {
            screenSize = ScreenSize.LARGE_DESKTOP;
        }
        if (perPage.wide && width >= this.props.theme.screenSize.wide) {
            screenSize = ScreenSize.WIDE;
        }
        if (width >= this.props.theme.screenSize.extraWide) {
            screenSize = ScreenSize.EXTRA_WIDE;
        }
        const perPageScreenSize = this.getPerPageFromScreenSize();
        const position = Math.max(
            0,
            Math.min(childrenLength - perPageScreenSize, this.state.position)
        );

        // FIXME(lucas): I think this need to be set dynamically?
        const isScrollable =
            screenSize === ScreenSize.MOBILE || screenSize === ScreenSize.LARGE_HANDSET;

        this.setState({
            position: isScrollable ? 0 : position,
            screenSize,
        });
    }

    /**
     * Callback for on item click
     * @param index - index of the item clicked
     */
    onItemClick = (index) => {
        if (this.props.onItemClick) {
            this.props.onItemClick(index);
        }
    };

    /**
     * Calculate items per page based on the screenSize
     * @param isMediumDesktop - a boolean whetere it's for a medium screen
     */
    getPerPageFromScreenSize() {
        const { screenSize } = this.state;

        // If some sizes are not provided in the perPage component props, it'll be taken from the defaultProps
        const perPage = {
            ...Carousel.defaultProps.perPage,
            ...this.props.perPage,
        };

        return perPage[screenSize];
    }

    /**
     * Returns a total count of loaded items
     */
    getChildrenCount() {
        const { children } = this.props;
        return React.Children.count(children);
    }

    handleGesture = () => {
        const { analyticsDescription, analyticsItem } = this.props;
        if (analyticsDescription) {
            const xDiff = this.state.touchendX - this.state.touchstartX;
            const yDiff = this.state.touchendY - this.state.touchstartY;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0 && !this.state.hasSwipedLeft) {
                    this.setState({
                        hasSwipedLeft: true,
                    });
                    track(
                        Events.CAROUSEL.create({
                            description: `${analyticsDescription} Carousel Swipe Left`,
                            item: analyticsItem || undefined,
                        })
                    );
                } else if (!this.state.hasSwipedRight) {
                    this.setState({
                        hasSwipedRight: true,
                    });
                    track(
                        Events.CAROUSEL.create({
                            description: `${analyticsDescription} Carousel Swipe Right`,
                            item: analyticsItem || undefined,
                        })
                    );
                }
            }
        }
    };

    handleTouchStart = (e) => {
        this.setState({
            touchstartX: e.changedTouches[0].clientX,
            touchstartY: e.changedTouches[0].clientY,
            touchendX: 0,
            touchendY: 0,
        });
    };

    handleTouchEnd = (e) => {
        this.setState(
            {
                touchendX: e.changedTouches[0].clientX,
                touchendY: e.changedTouches[0].clientY,
            },
            this.handleGesture
        );
    };

    render() {
        const { children, className, analyticsDescription, analyticsItem } = this.props;

        // Don't show if there are no search results yet
        if (!this.getChildrenCount()) {
            return null;
        }

        const perPage = this.getPerPageFromScreenSize();
        const firstItem = this.state.position === 0;
        // Only show relevant data
        const totalChildren = this.getChildrenCount();
        const lastItem = totalChildren < perPage || perPage + this.state.position === totalChildren;

        // Provide sensible defaults and an escape hatch if custom styling and/or behaviour is required
        const ListItem = this.props.listItem || ListItemStyled;
        const ListContainer = this.props.listContainer || ListContainerStyled;

        return (
            <ListWrapperStyled className={className}>
                <ListContainer
                    onTouchStart={analyticsDescription && this.handleTouchStart}
                    onTouchEnd={analyticsDescription && this.handleTouchEnd}
                >
                    <ListStyled
                        position={this.state.position}
                        perPage={perPage}
                        spacingBetweenCards={this.props.spacingBetweenCards}
                    >
                        {React.Children.map(children, (child) => (
                            <ListItem
                                width={`${100 / perPage}%`}
                                perPage={perPage}
                                spacingBetweenCards={this.props.spacingBetweenCards}
                            >
                                {child}
                            </ListItem>
                        ))}
                    </ListStyled>
                </ListContainer>
                <PreviousButtonStyled
                    data-testid="carousel-previous-button"
                    onClick={() => this.onPreviousButtonPress()}
                    visible={!firstItem}
                >
                    <ArrowLeftStyled />
                </PreviousButtonStyled>
                <NextButtonStyled
                    data-testid="carousel-next-button"
                    onClick={() => this.onNextButtonPress()}
                    visible={!lastItem}
                >
                    <ArrowRightStyled />
                </NextButtonStyled>
            </ListWrapperStyled>
        );
    }
}
const TranslatedCarousel = withTranslation()(Carousel);
const TranslatedCarouselWithTheme = withTheme(TranslatedCarousel);

export { Carousel as BaseCarousel, TranslatedCarouselWithTheme as default };
