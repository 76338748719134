import * as actions from './actions';
import * as constants from './ReferAFriendNudgewall.constants';

const initialState = {
    state: null,
    shouldShow: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.updateState.ACTION:
            return {
                ...state,
                state: action.state,
                shouldShow:
                    action.state === constants.REFER_A_FRIEND_NUDGEWALL_STATES.BANNER_CONFLICT
                        ? false
                        : state.shouldShow,
            };
        case actions.show.ACTION:
            return {
                ...state,
                shouldShow: true,
            };
        case actions.hide.ACTION:
        case actions.softDismiss.ACTION:
            return {
                ...state,
                shouldShow: false,
            };
        default:
            return state;
    }
};
