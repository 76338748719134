import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { locationName, locationType } from 'api/helpers/format/location';
import { Span } from 'typography';

import { debounce } from 'utils/funcs';
import { Combobox } from 'components/forms';

import { MetaInfoStyled } from './FilterLocation.style';
import { defaultLocation } from './FilterLocation.constants';

const isDefaultLocation = (item) => item.id === defaultLocation.id;

class FilterLocation extends Component {
    static suggestionMapToNameAndKey = (item) => {
        if (isDefaultLocation(item)) {
            return {
                name: item.name,
                key: item.id,
            };
        }

        return {
            name: locationName(item, true, ', ', false),
            key: item.id,
        };
    };

    renderItem = (item) => {
        const isTheDefaultLocation = isDefaultLocation(item);

        const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);

        let location;

        if (isTheDefaultLocation) {
            location = {
                name: item.name,
            };
        } else {
            location = {
                name: locationName(item, true, ', ', false),
                region: item.admin1Name,
                type: item.featureClass ? locationType(item) : undefined,
            };
        }

        return (
            <>
                <span>{location.name}</span>

                {!isTheDefaultLocation && (
                    <MetaInfoStyled>
                        {location.type && (
                            <Span styleOf="smallText">{capitalizeFirstLetter(location.type)}</Span>
                        )}
                    </MetaInfoStyled>
                )}
            </>
        );
    };

    render() {
        const { onInputChange, onChange, t, ...props } = this.props;

        return (
            <Combobox
                onInputChange={debounce(onInputChange, 100)}
                onChangeHandler={onChange}
                renderItem={this.renderItem}
                suggestionMapToNameAndKey={FilterLocation.suggestionMapToNameAndKey}
                {...props}
            />
        );
    }
}

export default withTranslation()(FilterLocation);
