// eslint-disable-next-line import/prefer-default-export
export const FAKE_DOOR_ACQUISITION = {
    create(membership, gbpOrderValue, originalOrderValue, currency, product, variation) {
        const { membershipType } = membership;

        const payload = {
            gbpOrderValue,
            orderValue: originalOrderValue,
            product,
            currency,
            membershipType,
            variation,
        };

        return {
            type: 'Fake door transaction',
            payload,
        };
    },
};
