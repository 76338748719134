const CountryCodes = {
    GB: 'GB',
    US: 'US',
    NL: 'NL',
    AU: 'AU',
    CA: 'CA',
    NZ: 'NZ',
    DE: 'DE',
    ES: 'ES',
    FR: 'FR',
    MX: 'MX',
    AR: 'AR',
    ZA: 'ZA',
    IE: 'IE',
    IT: 'IT',
    BR: 'BR',
    CH: 'CH',
    RU: 'RU',
    BE: 'BE',
    PL: 'PL',
    IN: 'IN',
};

// eslint-disable-next-line import/prefer-default-export
export { CountryCodes };
