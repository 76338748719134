import { settings } from 'api/actions';

const defaultState = {
    updateId: 0,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case settings.verifications.createEmailVerification.DONE: {
            return {
                ...state,
                responseStatus: action.statusCode,
                updateId: state.updateId + 1,
            };
        }
        default:
            return state;
    }
};
