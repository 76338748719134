import Subscribe from './subscribe';
import Unsubscribe from './unsubscribe';
import paths from './route-paths';

export default [
    {
        path: paths.subscribe,
        exact: true,
        component: Subscribe,
    },
    {
        path: paths.unsubscribe,
        exact: true,
        component: Unsubscribe,
    },
];
