import { combineReducers } from 'redux';

import email from './components/Email/reducers';
import phone from './components/Phone/reducers';
import criminalBackgroundCheck from './components/CriminalBackgroundCheck/reducers';

export default combineReducers({
    email,
    phone,
    criminalBackgroundCheck,
});
