import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Formik } from 'formik';
import Anchor from 'components/Anchor';
import { ErrorMessage } from 'components/forms';
import { routes } from 'utils/routes';
import { getFullHostname } from 'utils/environment';
import { MembershipType } from 'api/types';
import { SimplerCreateButtonStyled, HelperTextStyled } from '../RegisterForm/RegisterForm.style';

const MarketingPolicyForm = ({ membershipType, onSubmit, errorMessage }) => {
    const { t } = useTranslation();
    const handleFormikSubmit = ({ privacyPolicy, marketingComms }, { setSubmitting }) => {
        onSubmit({
            hasAcceptedPrivacyPolicy: privacyPolicy,
            hasAcceptedMarketingComms: marketingComms,
        });
        setSubmitting(false);
    };

    const getTranslationSuffix = () => {
        switch (membershipType) {
            case MembershipType.OWNER:
                return MembershipType.OWNER;
            case MembershipType.SITTER:
                return MembershipType.SITTER;
            case MembershipType.COMBINED:
                return MembershipType.COMBINED;
            default:
                return null;
        }
    };

    return (
        <Formik
            initialValues={{
                privacyPolicy: true,
                marketingComms: true,
            }}
            onSubmit={handleFormikSubmit}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <SimplerCreateButtonStyled fullWidth type="submit">
                        {t('components_registerForm_button_create_free')}
                    </SimplerCreateButtonStyled>

                    <HelperTextStyled data-testid="privacy-policy-marketing-opt-in-copy">
                        <Trans
                            i18nKey={`components_registerForm_pp_and_mc_auto_opt_in_${getTranslationSuffix()}`}
                            components={{
                                ppLink: (
                                    <Anchor
                                        href={`${getFullHostname()}${routes.privacyPolicy()}`}
                                        target="_blank"
                                    />
                                ),
                            }}
                        />
                    </HelperTextStyled>

                    {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
                </form>
            )}
        </Formik>
    );
};

export default MarketingPolicyForm;
