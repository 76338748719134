import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { retry } from 'utils/ssr';
import { asMemberPage } from 'containers/Page';
import * as pageActions from 'containers/Page/actions';

import { getReferAFriendUrl, getReferAFriendMonthsEarned } from './selectors';

const LoadableReferAFriend = loadable(() => retry(() => import('./ReferAFriend')));

const mapStateToProps = (state) => ({
    referAFriendUrl: getReferAFriendUrl(state),
    totalMonthsEarned: getReferAFriendMonthsEarned(state),
});

const mapDispatchToProps = (dispatch) => ({
    hideFooter: (shouldHide) => {
        dispatch(
            pageActions.setFooterOptions.create({
                hideFooter: shouldHide,
            })
        );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(asMemberPage(withTranslation()(LoadableReferAFriend), 'ReferAFriend', false, false, true));
