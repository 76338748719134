import { animalMapping } from './common';
import { getOwner } from './listings';
import { getSitter } from './profiles';

/**
 * Application
 * @param application
 * @returns {*}
 */
// eslint-disable-next-line import/prefer-default-export
export const getApplication = (application) => {
    if (!application) return application;

    const {
        id,
        profile,
        listing,
        assignment,
        isConfirmed,
        hasOwnerConfirmed,
        hasSitterConfirmed,
        hasAssignmentDatesChanged,
        isInvited,
        isInvitationAcknowledged,
        isShortlisted,
        hasOwnerDeclined,
        hasOwnerCancelled,
        hasSitterDeclined,
        hasSitterCancelled,
        lastMessage,
        newMessagesCount,
        startDate,
        endDate,
    } = application;

    let data = {
        id,
        profile,
        listing,
        assignment,
        // @TODO - Remove when fixed
        isConfirmed,
        hasOwnerConfirmed,
        hasSitterConfirmed,
        hasAssignmentDatesChanged,
        isInvited,
        isInvitationAcknowledged,
        isShortlisted: !!isShortlisted,
        hasOwnerDeclined,
        hasOwnerCancelled,
        hasSitterDeclined,
        hasSitterCancelled,
        lastMessage,
        newMessagesCount,
        startDate,
        endDate,
    };

    if (profile && profile.user) {
        let profileName = profile.user.firstName;
        if (profile.partner && profile.partner.firstName) {
            profileName += ` & ${profile.partner.firstName}`;
        }
        const sitterData = getSitter(profile.user);
        data = {
            ...data,

            // profile
            profileId: profile.id,
            profileLocation: profile.location,
            profileName,
            profileVerificationLevel: profile.verificationLevel,
            ...(sitterData || {}),
        };
    }

    if (listing) {
        const {
            id: listingId,
            user,
            location,
            mainPhoto,
            photos: listingPhotos,
            animals,
        } = listing || {};

        let listingMainPhoto = mainPhoto;
        if (!listingMainPhoto && listingPhotos && listingPhotos.length > 0) {
            listingMainPhoto = listingPhotos[0];
        }

        const ownerData = getOwner(user);
        data = {
            ...data,

            ...(ownerData || {}),
            listingId,
            listingLocation: location,
            listingMainPhoto,
            listingAnimals: (animals || []).map(animal => ({
                ...animal,
                slug: animalMapping(animal.slug),
            })),
        };
    }

    if (assignment) {
        data = {
            ...data,
            assignmentId: assignment.id,
            isApproximateDates: assignment.isApproximateDates,
            isReviewing: assignment.isReviewing,
            startDate: assignment.startDate,
            endDate: assignment.endDate,
            listingId: assignment.listingId || data.listingId,
            ownerId: assignment.ownerId || data.ownerId,
        };
    }

    return data;
};
