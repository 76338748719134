import * as ProfileFilters from 'api/helpers/search/profiles';
import * as SearchConstants from 'api/helpers/search/constants';

import { PROFILES_RESULTS_PER_PAGE } from 'config/search';

/**
 * Create profiles search query
 * @param page
 * @param filters
 * @param facets
 * @param resultsPerPage
 * @returns {Query}
 */
const createProfilesSearchQuery = (
    page,
    filters = {},
    facets = [],
    resultsPerPage = PROFILES_RESULTS_PER_PAGE,
    facetResultSize,
    preferGeoHierarchy = false
) => {
    const {
        activeMembership,
        availability,
        age,
        animalExperience = [],
        gender,
        profileFilters,
        geoPoint,
        geoHierarchy,
        reviews,
        verificationLevel,
        hasExperience,
        travellingWithPartner,
        preferences,
        worldwide,
        sort,
        nearMyLocation,
    } = filters;
    const { dateFrom, dateTo } = availability || {};

    // Create a new search query
    const searchQuery = new ProfileFilters.Query();
    searchQuery.resultsPerPage(resultsPerPage);

    // Only active members
    if (activeMembership !== undefined) {
        searchQuery.filter(new ProfileFilters.ActiveMembership(activeMembership));
    } else {
        searchQuery.filter(new ProfileFilters.ActiveMembership(true));
    }

    // Add availability
    if (dateFrom && dateTo) {
        searchQuery.filter(new ProfileFilters.Availability(dateFrom, dateTo));
    }

    // Loop over all possible pets and mark non selected as excluded
    if (animalExperience && animalExperience.length > 0) {
        searchQuery.filter(
            new ProfileFilters.AnimalExperience(
                animalExperience.filter((pet) => pet !== null && pet !== false)
            )
        );
    }

    // Age
    if (age && (age.minimum || age.maximum)) {
        const sitterAge = {};
        if (age.minimum) sitterAge.minimum = age.minimum;
        if (age.maximum) sitterAge.maximum = age.maximum;
        searchQuery.filter(new ProfileFilters.Age(sitterAge));
    }

    // Gender
    if (travellingWithPartner !== undefined) {
        searchQuery.filter(new ProfileFilters.TravellingWithPartner(...travellingWithPartner));
    }
    if (gender) {
        if (gender === 'couple') {
            searchQuery.filter(new ProfileFilters.TravellingWithPartner());
        } else {
            searchQuery.filter(new ProfileFilters.Gender(gender));
        }
    }

    // Preferences
    if (preferences) {
        searchQuery.filter(
            new ProfileFilters.Preferences({
                ...preferences,
            })
        );
    }

    // Trust and Safeties
    if (reviews) {
        searchQuery.filter(
            new ProfileFilters.Reviews({
                quantityMinimum: 1,
            })
        );
    }
    if (verificationLevel && verificationLevel.length > 0) {
        searchQuery.filter(
            new ProfileFilters.VerificationLevel([
                SearchConstants.VerificationLevel.BASIC,
                SearchConstants.VerificationLevel.STANDARD,
                SearchConstants.VerificationLevel.ENHANCED,
            ])
        );
    }
    if (hasExperience) {
        searchQuery.filter(new ProfileFilters.HasExperience());
    }

    // Other profile filters
    if (profileFilters) {
        profileFilters.forEach((trustAndSafety) => {
            switch (trustAndSafety) {
                case 'familyFriendly':
                    searchQuery.filter(new ProfileFilters.TravellingWithChildren());
                    break;
                default:
                    break;
            }
        });
    }

    if (geoPoint && !(preferGeoHierarchy && geoHierarchy)) {
        searchQuery.filter(
            new ProfileFilters.GeoPoint(geoPoint.latitude, geoPoint.longitude, geoPoint.distance)
        );
    } else if (geoHierarchy) {
        searchQuery.filter(new ProfileFilters.GeoHierarchy(geoHierarchy));
    }

    if (worldwide) {
        searchQuery.filter(new ProfileFilters.Worldwide(true));
    }

    // Profiles closest to my home
    if (nearMyLocation) {
        searchQuery.filter(
            new ProfileFilters.GeoPoint(
                nearMyLocation.location.coordinates.lat,
                nearMyLocation.location.coordinates.lon,
                '20050km'
            )
        );
    }

    if (sort && sort.length) {
        sort.forEach((sortObject) => {
            Object.keys(sortObject).forEach((key) => searchQuery.sort(key, sortObject[key]));
        });
    } else {
        searchQuery.sort('indexed_date', SearchConstants.SortDirection.DESC);
    }
    if (sort && sort.function) {
        searchQuery.query.sort = sort.function;
    }

    // Set page
    if (page && page > 1) {
        searchQuery.page(page);
    }

    // Add facets
    if (facets && facets.length > 0) {
        searchQuery.facet(...facets);
    }

    // Add facetResultSize
    if (facetResultSize) {
        searchQuery.facetResultSize(facetResultSize);
    }
    return searchQuery;
};

export {
    // eslint-disable-next-line import/prefer-default-export
    createProfilesSearchQuery,
};
