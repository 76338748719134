/* eslint-disable import/prefer-default-export */
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mix } from 'polished';
import { PaginationStyled, inactivePaginationItemStyles } from '../Pagination.style';

const PaginationWideStyled = styled(PaginationStyled)`
    justify-content: space-between;
`;

const InactiveRouterPaginationItemLinkStyled = styled(Link)`
    ${inactivePaginationItemStyles};
`;

const ActiveRouterPaginationItemLinkStyled = styled(InactiveRouterPaginationItemLinkStyled)`
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.primaryRegular};
    color: ${({ theme }) => theme.palette.textWhite};
`;

const iconHoverColor = (theme) => {
    const { strength, color } = theme.opacityOverlay.dark.hover;

    return mix(strength, color, theme.palette.text);
};

const RouterPaginationChevronLinkStyled = styled(InactiveRouterPaginationItemLinkStyled)`
    display: flex;
    align-items: center;
    justify-content: center;

    /* @TODO: better way to change the colour of the icon on hover */
    &:hover {
        && svg {
            fill: ${({ theme }) => iconHoverColor(theme)};
        }
    }
`;

export {
    PaginationWideStyled,
    InactiveRouterPaginationItemLinkStyled,
    ActiveRouterPaginationItemLinkStyled,
    RouterPaginationChevronLinkStyled,
};
