import * as actions from './actions';

export const removeProperty =
    (prop) =>
    ({ [prop]: _, ...rest }) =>
        rest;

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.setItem.ACTION:
            return {
                ...state,
                [action.key]: action.item,
            };
        case actions.removeItem.ACTION: {
            const removeKey = removeProperty(action.key);
            return removeKey(state);
        }
        default:
            return state;
    }
};
