import { settings as actions } from '../../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        // Load blog post
        case actions.content.loadBlogPost.SUCCESS: {
            return {
                ...state,
                [action.requestData.areaSlug]: {
                    ...state[action.requestData.areaSlug],
                    [action.requestData.categorySlug]: {
                        ...((state[action.requestData.areaSlug] &&
                            state[action.requestData.areaSlug][action.requestData.categorySlug]) ||
                            {}),
                        [action.requestData.postSlug]: {
                            ...action.data,
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};
