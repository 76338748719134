import React, { useState, useEffect } from 'react';
import ZIndexManager from 'components/ZIndexManager';
import { matchProfileDetail, matchListingDetail } from 'utils/utils';
import { routes } from 'utils/routes';
import Call from './components/Call';
import Zendesk from './components/Zendesk';
import { ZendeskToggleState } from './constants';
import { SupportWidgetWrapper, SupportWidgetButtonContainer } from './SupportWidget.style';

export const SupportWidgetTestIds = {
    wrapper: 'SupportWidget_wrapper',
    buttonContainer: 'SupportWidget_buttonContainer',
    call: 'SupportWidget_call',
    zendesk: 'SupportWidget_zendesk',
};

const isOnSelectPage = (pathname) => {
    const selectPageList = [matchProfileDetail(pathname), matchListingDetail(pathname)];

    return selectPageList.some((match) => !!match);
};

const SupportWidget = ({ pathname }) => {
    const [addBottomSpacing, setAddBottomSpacing] = useState(false);
    const [isCallOpen, setIsCallOpen] = useState(false);
    const [zendeskToggle, setZendeskToggle] = useState(ZendeskToggleState.CLOSED);

    useEffect(() => {
        if (pathname) {
            setAddBottomSpacing(isOnSelectPage(pathname));
        }
    }, [pathname]);

    return (
        <SupportWidgetWrapper
            hideOnThisPage={pathname === routes.accounts.checkout.payment()}
            data-testid={SupportWidgetTestIds.wrapper}
        >
            <ZIndexManager layer="modal">
                <SupportWidgetButtonContainer
                    addBottomSpacing={addBottomSpacing}
                    isCallOpen={isCallOpen}
                    isZendeskOpen={zendeskToggle === ZendeskToggleState.OPEN}
                    data-testid={SupportWidgetTestIds.buttonContainer}
                >
                    <Call
                        isCallOpen={isCallOpen}
                        setIsCallOpen={setIsCallOpen}
                        disableButton={zendeskToggle !== ZendeskToggleState.CLOSED}
                        data-testid={SupportWidgetTestIds.call}
                    />
                    {!isCallOpen && (
                        <Zendesk
                            zendeskToggle={zendeskToggle}
                            setZendeskToggle={setZendeskToggle}
                            data-testid={SupportWidgetTestIds.zendesk}
                        />
                    )}
                </SupportWidgetButtonContainer>
            </ZIndexManager>
        </SupportWidgetWrapper>
    );
};

export default SupportWidget;
