import styled from 'styled-components';
import { theme } from 'theme';
import LinkComponent from 'components/Link';
import { smallTextStyles, pStyles, h2LargeStyles, h2Styles } from 'typography';

export const Container = styled.section`
    padding: ${theme.spacing[6]} ${theme.spacing[4]};

    @media screen and (min-width: ${theme.screenSize.smallDesktop}px) {
        padding: ${theme.spacing[8]} ${theme.spacing[6]} ${theme.spacing[10]};
        max-width: calc(1120px + ${theme.spacing[6]});
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing[6]};
    }
`;

export const ContainerTitle = styled.h2`
    ${h2Styles}
    text-align: center;
`;

export const StepsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${theme.spacing[1]};
    margin-bottom: ${theme.spacing[6]};

    @media screen and (min-width: ${theme.screenSize.smallDesktop}px) {
        grid-auto-flow: column;
        row-gap: ${theme.spacing[3]};
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, auto);
    }
`;

export const StepNumber = styled.span`
    ${h2LargeStyles}
    color: ${theme.palette.primaryDark};
`;

export const StepTitle = styled.h3`
    ${pStyles}
    color: ${theme.palette.primaryDark};
    font-weight: ${theme.fontWeights.bold};

    @media screen and (min-width: ${theme.screenSize.smallDesktop}px) {
        padding-right: ${theme.spacing[4]};
    }

    margin: 0;
`;

export const Separator = styled.div`
    display: none;

    @media screen and (min-width: ${theme.screenSize.smallDesktop}px) {
        display: block;
        border-top: 2px dashed ${theme.palette.accent};
        position: relative;

        &::before {
            content: '';
            background-color: ${theme.palette.accent};
            display: block;
            position: absolute;
            top: -7px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
        }
    }
`;

export const StepDescription = styled.p`
    ${pStyles}
    margin: 0;
    margin-bottom: ${theme.spacing[3]};

    @media screen and (min-width: ${theme.screenSize.smallDesktop}px) {
        ${smallTextStyles}
        margin-bottom: 0;
        padding-right: ${theme.spacing[4]};
    }
`;

export const Link = styled(LinkComponent)`
    ${pStyles}
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.palette.primaryDark};
    text-decoration: underline;
    display: block;

    @media screen and (min-width: ${theme.screenSize.smallDesktop}px) {
        text-align: center;
    }
`;
