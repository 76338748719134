import { v3ApiUrl } from './constants';

export default {
    // GET: load all animals
    _: `${v3ApiUrl}animals/`,

    breeds: {
        // GET: load all animal breeds
        _: `${v3ApiUrl}animals/:id:/breeds/`,
    },
};
