// eslint-disable-next-line import/prefer-default-export
export const uploadStatus = {
    // User just selected this photo for upload but upload has not started yet
    WAITING: 'waiting',

    // Photo is uploading (progress can be reported)
    UPLOADING: 'uploading',

    // Photo has uploaded. This photo will transition to EXISTING after a short delay
    SUCCESS: 'success',

    // Photo upload has failed. There should be a linked `uploadError`
    FAILED: 'failed',

    // Photo upload has been cancelled, either by new upload(s) replacing it or by the user
    CANCELLED: 'cancelled',
};

export const uploadError = {
    TOO_BIG: 'too_big',
    FILE_TYPE: 'file_type',
    GENERAL: 'general',
};

export const itemType = {
    EXISTING: 'existing',
    PREVIEW: 'preview',
};
