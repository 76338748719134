// eslint-disable-next-line import/prefer-default-export
export const VIEWED_END_FREE_TRIAL_MODAL = {
    create() {
        return {
            type: 'Viewed Modal',
            payload: {
                description: 'Cancel Free Trial Benefits Modal',
            },
        };
    },
};
