export default {
    hub: '/user/listing/:listingId?/edit/',
    hubRedirect: '/user/listing/:listingId?/hub-redirect/',
    aboutYou: '/user/listing/:listingId?/edit/about-you/',
    home: '/user/listing/:listingId?/edit/home/',
    location: '/user/listing/:listingId?/edit/location/',
    pets: '/user/listing/:listingId?/edit/pets/',
    responsibilities: '/user/listing/:listingId?/edit/responsibilities/',
    title: '/user/listing/:listingId?/edit/title/',
    dates: '/user/listing/:listingId?/edit/dates/',
    listingPreview: '/user/listing/:listingId?/edit/listing-preview/',
    whatsNext: '/user/listing/:listingId/edit/whats-next/',
    safetySecurity: '/user/listing/:listingId?/edit/safety-security/',
};
