import { combineReducers } from 'redux';

import { settings as actions } from '../../actions';

import settings from './settings';
import details from './details';
import membershipPlan from './membershipPlan';
import incompleteMembershipPlan from './incompleteMembershipPlan';
import subscribedMembershipPlan from './subscribedMembershipPlan';
import wsToken from './wsToken';
import pushNotificationSubscriptions from './pushNotificationSubscriptions';
import subscriptionRenewalAmount from './subscriptionRenewalAmount';
import validateEmail from './validateEmail';
import refunds from './refunds';
import emergencyContacts from './emergencyContacts';
import userGroups from './userGroups';

export const initialState = {
    settings: {},
    details: {},
    membershipPlan: {},
    incompleteMembershipPlan: {},
    subscribedMembershipPlan: {},
    wsToken: {},
    pushNotificationSubscriptions: {},
    validateEmail: {},
    emergencyContacts: {},
    userGroups: [],
};

const accountReducer =
    (reducer) =>
    (state = initialState, action) => {
        switch (action.type) {
            case actions.account.create.SUCCESS:
            case actions.account.load.SUCCESS: {
                let incompleteMembershipPlanData = {};
                const { expiryDate } = action.data.membershipPlan;
                if (!expiryDate) {
                    incompleteMembershipPlanData = action.data.membershipPlan;
                }
                return {
                    ...state,
                    settings: action.data.settings,
                    details: {
                        id: action.data.id,
                        ...action.data.details,
                    },
                    membershipPlan: action.data.membershipPlan,
                    incompleteMembershipPlan: incompleteMembershipPlanData,
                    wsToken: action.data.wsToken,
                    pushNotificationSubscriptions: action.data.pushNotificationSubscriptions,
                    validateEmail: action.data.validateEmail,
                    userGroups: action.data.userGroups,
                };
            }
            default:
                if (!('validateEmail' in state)) {
                    return {
                        ...state,
                        validateEmail: {},
                    };
                }
                return reducer(state, action);
        }
    };

const card = (state = {}, action) => {
    switch (action.type) {
        case actions.account.loadCard.SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};

export default accountReducer(
    combineReducers({
        settings,
        details,
        membershipPlan,
        incompleteMembershipPlan,
        subscribedMembershipPlan,
        wsToken,
        pushNotificationSubscriptions,
        validateEmail,
        card,
        refunds,
        subscriptionRenewalAmount,
        emergencyContacts,
        userGroups,
    })
);
