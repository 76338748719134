import { PAGE_ID } from './ListingDetail.constants';

// eslint-disable-next-line import/prefer-default-export
export const getListingDetailParams = ({ page }) => {
    const pageInformation = page[PAGE_ID];

    if (typeof pageInformation === 'object') {
        return pageInformation.location.params;
    }

    return '';
};
