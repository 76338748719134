import React, { useState } from 'react';
import TextNode from 'components/TextNode';
import BaseInput from '../components/BaseInput';
import { ButtonStyled } from './PasswordField.style';
import { FieldWrapper, FieldLabelWrapperStyled } from '../Field.style';
import InfoText from '../components/InfoText';
import { isErrored } from '../helper';
import InputLabel from '../../InputLabel';

const PasswordField = ({
    label,
    value,
    name,
    error,
    helperText,
    placeholder,
    onChange,
    onBlur,
    onFocus,
    disabled,
    touched,
    inputRef,
    className,
    canToggle = true,
    showText = 'Show',
    hideText = 'Hide',
    ...props
}) => {
    const [type, setType] = useState('password');

    const onClick = () => {
        if (type === 'password') {
            setType('text');
        } else setType('password');
    };

    const isValid = () => {
        if (touched && !error) {
            return true;
        }
        return false;
    };

    return (
        <FieldWrapper className={className}>
            <InputLabel inputId={name}>
                <FieldLabelWrapperStyled>
                    <TextNode>{label}</TextNode>
                    {canToggle ? (
                        <ButtonStyled data-testid="show-button" onClick={onClick}>
                            {type === 'password' ? showText : hideText}
                        </ButtonStyled>
                    ) : null}
                </FieldLabelWrapperStyled>

                <BaseInput
                    id={name}
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    disabled={disabled}
                    inputRef={inputRef}
                    isErrored={isErrored(error, touched)}
                    data-testid="input"
                    {...props}
                />
            </InputLabel>
            <InfoText
                helperText={helperText}
                errorText={error}
                isErrored={isErrored(error, touched)}
                isValid={isValid()}
            />
        </FieldWrapper>
    );
};

export default PasswordField;
