import { getOwnerCurrentAssignments, getOwnerUpcomingAssignments } from 'api/selectors';

/* eslint-disable import/prefer-default-export */

const getRawListing = (state) => state.pages.user.listing.edit.listing || {};
const getResponseStatus = (state) => state.pages.user.listing.edit.responseStatus;
const getUpdateId = (state) => state.pages.user.listing.edit.updateId || 0;

const getListingConfirmedAssignments = (state, listingId) =>
    [...getOwnerCurrentAssignments(state), ...getOwnerUpcomingAssignments(state)].filter(
        (assignment) => assignment.listingId === listingId
    );

export { getRawListing, getResponseStatus, getUpdateId, getListingConfirmedAssignments };
