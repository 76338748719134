import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

import {
    baseInitialResultsListState,
    loadedResultsUniqueListReducer,
} from '../common';

import { uniqueArray } from '../../helpers/format/objects';

export const initialState = { ...baseInitialResultsListState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadConfirmedAssignments.SUCCESS:
            return {
                ...initialState,
                results: action.data.upcoming.map(assignment => assignment.id),
            };
        case actions.owner.unconfirmConfirmedAssignment.SUCCESS:
            return {
                ...state,
                results: state.results.filter(assignmentId => assignmentId !== action.data.id),
            };
        case actions.owner.loadUpcomingAssignments.SUCCESS:
            return loadedResultsUniqueListReducer(state, action);
        case actions.owner.loadAssignment.SUCCESS: {
            if (transform.isUpcomingAssignment(action.data)) {
                if (state.results.indexOf(action.data.id) < 0) {
                    return {
                        ...state,
                        results: uniqueArray([
                            ...state.results,
                            action.data.id,
                        ]),
                    };
                }
            }
            else if (state.results.indexOf(action.data.id) >= 0) {
                return {
                    ...state,
                    results: state.results.filter(itemId => itemId !== action.data.id),
                };
            }
            return state;
        }
        default:
            return state;
    }
};
