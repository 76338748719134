import { settings as actions } from '../../actions';

import {
    baseInitialState, loadedDataReducer, createdDataReducer, updatedDataReducer,
} from '../common';

export const initialState = { ...baseInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.owner.loadListingGuide.SUCCESS:
            return loadedDataReducer(state, action, 'listingId');
        case actions.owner.createListingGuide.SUCCESS:
            return createdDataReducer(state, action, 'listingId');
        case actions.owner.updateListingGuide.SUCCESS:
            return updatedDataReducer(state, action, 'listingId');
        default:
            return state;
    }
};
