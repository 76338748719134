/* eslint-disable import/prefer-default-export */
import sbjs from 'sourcebuster';

export const sbjsInit = () => {
    // sourcebuster creates a cookie containing some information about where the user came from.
    // We will use this information client-side and server-side to give data to our analytics events.
    // This initialisation config came straight from the existing site's Google Tag Manager config
    sbjs.init({
        domain: 'trustedhousesitters.com',

        referrals: [
            {
                host: 'facebook.com',
                medium: 'social',
            },
            {
                host: 'instagram.com',
                medium: 'social',
            },
        ],

        organics: [
            {
                host: 'duckduckgo.com',
                param: 'q',
                display: 'duckduckgo',
            },
        ],
    });
};
