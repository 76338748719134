import React from 'react';
import { bool, string, node } from 'prop-types';
import styled from 'styled-components';
import { visuallyHide } from 'utils/utils';

const StyledLabel = styled.label`
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    color: ${({ theme }) => theme.palette.grey[700]};
    ${({ hide }) => (hide ? visuallyHide : '')};
`;

/**
 * Descriptive label that is used with the input
 * @deprecated Use `src/components/Form/Label` instead.
 */
const InputLabel = ({ inputId, hide, children, className }) => (
    <StyledLabel hide={hide} htmlFor={inputId} className={className}>
        {children}
    </StyledLabel>
);

InputLabel.propTypes = {
    /**
     * input id that links label to the form input
     */
    inputId: string.isRequired,
    /**
     * indicates whether the label should be hidden from the interface
     */
    hide: bool,
    /**
     * content inserted into the label
     */
    children: node.isRequired,
    className: string,
};

export default InputLabel;
