import styled, { css } from 'styled-components';

const DefaultImageStyled = styled.img`
    max-width: 100%;
`;

const hiddenStyle = css`
    display: none;
`;

const visibleStyle = css`
    display: initial;
`;

const PlaceholderStyled = styled.div`
    ${({ width, height }) => css`
        aspect-ratio: ${width} / ${height};
    `}
`;

const ImageStyled = styled(DefaultImageStyled)`
    ${hiddenStyle};
    html.no-js & {
        ${visibleStyle};
    }
    ${({ inView }) => inView && visibleStyle};
`;

export { DefaultImageStyled, PlaceholderStyled, ImageStyled };
