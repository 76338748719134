import PropTypes from 'prop-types';

import {
    baseAssignmentProps,
    basePet,
    baseLocation,
    baseMediaPhoto,
    baseMediaVideo,
    baseReview,
    baseFeedback,
    baseHomeFeatures,
    baseLocalAttraction,
    sitterPastAssignment,
    baseReference,
} from './common';

export const openAssignmentProps = {
    ...baseAssignmentProps,
    approximateDates: PropTypes.bool,
    reviewing: PropTypes.bool,
    dateCreated: PropTypes.string,
    lastModified: PropTypes.string,
    numberOfApplications: PropTypes.number,
};
export const openAssignment = PropTypes.shape(openAssignmentProps);
export const openAssignments = PropTypes.arrayOf(openAssignment);

export const listingPastAssignmentProps = {
    ...baseAssignmentProps,

    ownerId: PropTypes.string,
    sitterId: PropTypes.string,

    profileLocation: baseLocation,
    ownerName: PropTypes.string,
    listingMainPhoto: baseMediaPhoto,
    ownerAvatarPhoto: baseMediaPhoto,

    review: baseReview,
    feedback: baseFeedback,

    selectedAnimals: PropTypes.number,
};
export const listingPastAssignment = PropTypes.shape(listingPastAssignmentProps);
export const listingPastAssignments = PropTypes.arrayOf(listingPastAssignment);

export const listingProps = {
    id: PropTypes.string,
    userId: PropTypes.string,
    firstName: PropTypes.string,
    title: PropTypes.string,
    location: baseLocation,

    photo: baseMediaPhoto,
    avatarPhoto: baseMediaPhoto,

    introduction: PropTypes.string,
    home: PropTypes.string,
    responsibilities: PropTypes.string,
    features: PropTypes.string,

    assignments: PropTypes.arrayOf(PropTypes.shape(openAssignment)),
    openAssignments,

    photos: PropTypes.arrayOf(baseMediaPhoto),
    videos: PropTypes.arrayOf(baseMediaVideo),

    homeFeatures: PropTypes.arrayOf(baseHomeFeatures),

    selectedAnimals: PropTypes.number,
    otherAnimals: PropTypes.string,
    pets: PropTypes.arrayOf(PropTypes.shape(basePet)),

    homeType: PropTypes.oneOf(['house', 'apartment']),
    disabledAccess: PropTypes.bool,
    wifiAvailable: PropTypes.string,
    carIncluded: PropTypes.bool,
    familyFriendly: PropTypes.bool,
    localAttractions: PropTypes.arrayOf(baseLocalAttraction),
};
export const listing = PropTypes.shape(listingProps);
export const listings = PropTypes.arrayOf(listing);
export const listingsRecommendations = listings;

const animal = {
    id: PropTypes.number,
    name: PropTypes.string,
};

const country = {
    id: PropTypes.number,
    name: PropTypes.string,
    // continentName: PropTypes.string,
    // continentSlug: PropTypes.string,
    // countryName: PropTypes.string,
    // countrySlug: PropTypes.string,
};

export const profileProps = {
    id: PropTypes.string,
    user: PropTypes.shape({
        id: PropTypes.string,
        profilePhoto: baseMediaPhoto,
        firstName: PropTypes.string,
    }),
    location: baseLocation,
    title: PropTypes.string,
    introduction: PropTypes.string,
    hasExperience: PropTypes.bool,
    motivation: PropTypes.string,
    relevantExperience: PropTypes.string,
    sex: PropTypes.string,
    birthDate: PropTypes.string,
    occupation: PropTypes.string,
    occupationType: PropTypes.string,
    travellingWithPartner: PropTypes.shape({
        firstName: PropTypes.string,
        sex: PropTypes.string,
        birthDate: PropTypes.string,
        occupation: PropTypes.string,
        occupationType: PropTypes.string,
    }),
    travellingWithChildren: PropTypes.arrayOf(
        PropTypes.oneOf(['1-3 years', '4-7 years', '8-12 years', '13-17 years', '18+ years'])
    ),
    verificationLevel: PropTypes.oneOf(['basic', 'standard', 'enhanced']),
    policeCheck: PropTypes.shape({
        type: PropTypes.oneOf(['certified', 'self certified']),
        issueDate: PropTypes.string,
    }),
    preferences: PropTypes.shape({
        localAttractions: PropTypes.arrayOf(PropTypes.oneOf(['city', 'mountain', 'countrySide'])),
        countries: PropTypes.arrayOf(country),
    }),
    photos: PropTypes.arrayOf(baseMediaPhoto),
    reviewsAverageScore: PropTypes.number,
    reviewsCount: PropTypes.number,
    assignments: PropTypes.arrayOf(sitterPastAssignment),
    referencesCount: PropTypes.number,
    references: PropTypes.arrayOf(baseReference),
    animalExperience: PropTypes.arrayOf(PropTypes.shape(animal)),
    videoUrl: PropTypes.string,
    availableDates: PropTypes.arrayOf(
        PropTypes.shape({
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            approximateDates: PropTypes.bool,
        })
    ),
    unavailableDates: PropTypes.arrayOf(
        PropTypes.shape({
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            approximateDates: PropTypes.bool,
        })
    ),
};
export const profile = PropTypes.shape(profileProps);
export const profiles = PropTypes.arrayOf(profile);
export const profilesRecommendations = PropTypes.arrayOf(profile);
