import {
    SCREEN_EXIT_WARNING,
    SCREEN_HOW_WAS_YOUR_SIT,
    SCREEN_THANKS,
    SCREEN_WRITE_FEEDBACK,
    SCREEN_CLEANLINESS,
    SCREEN_COMMUNICATION,
    SCREEN_HOSPITALITY,
    SCREEN_PET_BEHAVIOUR,
    SCREEN_LISTING_ACCURACY,
} from 'containers/LeaveReviewAndFeedbackModal/LeaveFeedbackModal/constants';

// eslint-disable-next-line import/prefer-default-export
export const LEAVE_FEEDBACK_MODAL = {
    create(screen) {
        switch (screen) {
            case SCREEN_EXIT_WARNING:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal Exiting flow',
                    },
                };
            case SCREEN_HOW_WAS_YOUR_SIT:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal How was your sit',
                    },
                };
            case SCREEN_THANKS:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal Thanks',
                    },
                };
            case SCREEN_WRITE_FEEDBACK:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal Write a review',
                    },
                };
            case SCREEN_CLEANLINESS:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal rate Cleanliness',
                    },
                };
            case SCREEN_COMMUNICATION:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal rate Communication',
                    },
                };
            case SCREEN_HOSPITALITY:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal rate Hospitality',
                    },
                };
            case SCREEN_PET_BEHAVIOUR:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal rate Pet Behavior',
                    },
                };
            case SCREEN_LISTING_ACCURACY:
                return {
                    type: `Viewed Modal`,
                    payload: {
                        description: 'Feedback Modal rate Accuracy of Listing',
                    },
                };
            default:
                return {};
        }
    },
};
