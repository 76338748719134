import { all, call, put, takeEvery } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { take } from 'lodash';

import {
    preload as pageActionsPreload,
    load as pageActionsLoad,
    error as errorAction,
    preloaded as pageActionsPreloaded,
    loaded as pageActionsLoaded,
    lazyload as pageActionsLazyload,
} from 'containers/Page/actions';
import { PAGE_ID } from './Experience.constants';

const ANIMAL_SLUGS_WITH_ALWAYS_LOADED_BREEDS = ['fish', 'poultry', 'farm-animals'];

function* preloadAnimalsData() {
    // Preload animal types and breeds for Fish, Poultry and Livestock
    yield put(apiActions.animals.load());
    const animals = yield take((res) => res.type === settings.animals.load.DONE);

    if (animals.status !== settings.animals.load.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, animals.statusCode));
        // return false so the rest of the saga doesnt run unnecessarily if there is an error getting the animals
        return false;
    }

    const { data: animalsData = [] } = animals || {};

    for (let i = 0; i < animalsData.length; i += 1) {
        const animal = animalsData[i];

        if (ANIMAL_SLUGS_WITH_ALWAYS_LOADED_BREEDS.includes(animal.slug)) {
            yield put(apiActions.animals.loadBreeds({ data: { id: animal.id } }));
            yield take((res) => res.type === settings.animals.loadBreeds.DONE);
        }
    }

    // Wait for results
    const { status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadListingPets.DONE
    );

    if (status === settings.owner.loadListingPets.SUCCESS) {
        yield put(pageActionsPreloaded.create(PAGE_ID));
        return true;
    }

    // Error occurred
    yield put(errorAction.create(PAGE_ID, statusCode));

    return false;
}

export function* load(action) {
    yield call(preloadAnimalsData, action.params, PAGE_ID);
    yield put(pageActionsLoaded.create(PAGE_ID));
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLazyload.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
    ]);
}
