import { createAction } from 'utils/actions';

const PREFIX = 'PROMPT_MANAGER';

export const register = createAction(PREFIX, 'REGISTER', 'config');
export const success = createAction(PREFIX, 'SUCCESS');
export const failure = createAction(PREFIX, 'FAILURE');

/// @private
export const collect = createAction(PREFIX, 'COLLECT', 'buffer');
export const remove = createAction(PREFIX, 'REMOVE', 'registry');
