import { openFreeTrialEndModal, closeFreeTrialEndModal } from './actions';

export const initialState = {
    showFreeTrialModal: false,
    account: {
        membershipPlan: undefined,
    },
};

const freeTrialEndModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case openFreeTrialEndModal.ACTION: {
            return {
                ...state,
                showFreeTrialModal: true,
            };
        }
        case closeFreeTrialEndModal.ACTION: {
            return {
                ...state,
                showFreeTrialModal: false,
            };
        }
        default:
            return state;
    }
};

export default freeTrialEndModalReducer;
