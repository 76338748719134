import { settings as actions } from '../../actions';
import * as transform from '../../helpers/transform';

import {
    basePaginatedInitialState,
} from '../common';
import { uniqueArray } from '../../helpers/format/objects';

export const initialState = { ...basePaginatedInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.messages.load.SUCCESS: {
            const { requestData = {}, data = {} } = action;
            const { startingAfter } = requestData;
            const { results = {} } = data;

            const inboxDataIDs = results.map(item => item.id);
            const archiveFormattedInboxData = results.reduce((items, item) => {
                items[item.id] = transform.getArchiveMessage(item);
                return items;
            }, {});

            // TODO - Not currently implemented, this API endpoint does not accept 'startingAfter' param
            // Append search results to current data
            if (startingAfter) {
                return {
                    ...state,
                    count: action.data.count,
                    data: {
                        ...state.data,
                        ...archiveFormattedInboxData,
                    },
                    results: uniqueArray([
                        ...state.results,
                        ...inboxDataIDs,
                    ]),
                };
            }
            // Replace current state with newly loaded data
            return {
                ...state,
                count: action.data.count,
                data: {
                    ...archiveFormattedInboxData,
                },
                results: [
                    ...inboxDataIDs,
                ],
            };
        }
        case actions.owner.archiveConversations.SUCCESS:
        case actions.owner.batchArchiveConversations.SUCCESS:
        case actions.sitter.archiveConversations.SUCCESS:
        case actions.sitter.batchArchiveConversations.SUCCESS: {
            const { contactId, contactIds } = action.requestData;
            const hasContactId = !!contactId;
            const ids = hasContactId ? [contactId] : contactIds;

            const results = [];
            state.results.forEach((messageThreadId) => {
                const messageThread = state.data[messageThreadId];
                const { contactId: messageContactId } = messageThread;

                if (ids.indexOf(messageContactId) < 0) {
                    results.push(messageThreadId);
                }
            });
            return {
                ...state,
                results,
            };
        }
        default:
            return state;
    }
};
