import styled from 'styled-components';
import Checkbox from '../Checkbox';
import { InfoText } from '../shared.style';

const HelperTextStyled = styled(InfoText)`
    color: ${({ theme }) => theme.palette.grey[500]};
    margin-top: ${({ theme }) => theme.spacing[1]};
`;

const CheckboxStyled = styled(Checkbox)`
    align-items: flex-start;
    min-height: 110px;
    width: 100%;
`;

export { HelperTextStyled, CheckboxStyled };
