export const hasFacetsLoaded = (searchType) => (state) =>
    typeof state?.search?.facets?.[searchType] !== 'undefined' ?? false;

export const isHydrated = (searchType) => (searchLevel, state) =>
    typeof state?.search?.facets?.[searchType]?.[searchLevel] !== 'undefined' ?? false;

export const getLevel =
    (searchType) =>
    (searchLevel, { search }) =>
        search?.facets?.[searchType]?.[searchLevel] ?? null;

export const getIsReady = (state) => state?.pages?.search?.dynamicFacets?.isReady ?? false;

export const getCurrentPlace = (state) => state?.pages?.search?.dynamicFacets?.currentPlace ?? null;

export const getCurrentSearchLevel = (state) =>
    state?.pages?.search?.dynamicFacets?.currentSearchLevel ?? null;

export const getCurrentFilters = (state) =>
    state?.pages?.search?.dynamicFacets?.currentFilters ?? {};

export const checkFacetsCache = (state, searchType, searchLevel) =>
    isHydrated(searchType)(searchLevel, state);

export const getFacetsLevel = (state, searchType, searchLevel) =>
    getLevel(searchType)(searchLevel, state);
