import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { redirectIfExpired, redirectIfLoggedOut } from 'pages/user/helpers';
import asPage from 'containers/Page';
import {
    getAccount,
    getAccountDetails,
    getAccountCurrentMembershipPlan,
} from 'api/selectors/account';
import { getOwnerListings } from 'api/selectors/owner';
import { getSplit } from 'containers/ExperimentalFeature/selectors';
import { isClassic, isCombined, isSitterOnly } from 'utils/account';
import { retry } from 'utils/ssr';
import { UPGRADE_MEMBERSHIP_DISMISSED_KEY } from 'containers/UpgradeMembership';
import { getItem } from 'src/universalStorage/selectors';
import { getIsLoggedIn, getCurrentFreeTrialStatus } from 'shared/selectors';
import { PAGE_ID } from './Dashboard.constants';

const LoadableDashboard = loadable(() => retry(() => import('./Dashboard')));
const authorisationRedirects = [redirectIfLoggedOut, redirectIfExpired];

const mapStateToProps = (state) => {
    const membershipPlan = getAccountCurrentMembershipPlan(state);
    const listings = getOwnerListings(state);
    return {
        isLoggedIn: getIsLoggedIn(state),
        account: getAccount(state),
        listings,
        split: getSplit(state),
        isSitterOnly: isSitterOnly(membershipPlan),
        isCombined: isCombined(membershipPlan),
        isClassic: isClassic(membershipPlan),
        referAFriendMonthsEarned: getAccountDetails(state).referAFriendMonthsEarned,
        membershipExpiryDate: membershipPlan.expiryDate,
        isUpgradeBannerEnabled: !getItem(state, UPGRADE_MEMBERSHIP_DISMISSED_KEY),
        isCurrentlyOnFreeTrial: getCurrentFreeTrialStatus(membershipPlan),
    };
};

export default connect(mapStateToProps)(
    asPage(LoadableDashboard, PAGE_ID, true, true, {
        isProtectedRoute: true,
        checkAuthorised: authorisationRedirects,
    })
);
