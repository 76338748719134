import { getDynamicFacetsQueryString } from 'pages/search/selectors';
import { SITEMAP_LINKS, SITEMAP_PAGES, SITEMAP_TYPES } from 'pages/Sitemap/Sitemap.constants';
import {
    hasFacetsLoaded,
    getLevel,
    getIsReady,
    getCurrentPlace,
    getCurrentSearchLevel,
    getCurrentFilters,
} from './selectors';
import {
    FACET_LEVEL_NAME,
    GLOBAL_LEVEL_COLUMNS,
    COUNTRY_LEVEL_COLUMNS,
    REGION_LEVEL_COLUMNS,
    COUNTY_LEVEL_COLUMNS,
    CITY_LEVEL_COLUMNS,
} from './constants';

export const formatColumnArray = (columnIdentifier, facets, createRoute) =>
    facets.map(({ name, count, ...rest }, index) => ({
        id: `${columnIdentifier}.facet.${index}`,
        name,
        count,
        route: createRoute(rest),
    }));

export const parseI18n = (i18n, currentPlace) =>
    Object.entries(i18n).reduce((acc, [key, value]) => {
        if (typeof value === 'string') {
            return {
                ...acc,
                [key]: [value],
            };
        }

        if (Array.isArray(value)) {
            const [i18nKey, ...params] = value;

            return {
                ...acc,
                [key]: [i18nKey, params.map((param) => currentPlace[param])],
            };
        }

        return acc;
    }, {});

const facetsWithTheSameRoute = (currentFacet) => (facet) =>
    facet.route.pathname === currentFacet.route.pathname;

const outRepeatedFacets = (facets) => (currentFacet) =>
    !facets.find(facetsWithTheSameRoute(currentFacet));

const buildUniqueFacetsList = (facets, currentColumn) =>
    facets.concat(currentColumn.facets.filter(outRepeatedFacets(facets)));

const facetsWithTheSameId = (currentFacet) => (facet) => facet.id === currentFacet.id;

const facetsWithinTheList = (filteredFacets) => (currentFacet) =>
    filteredFacets.find(facetsWithTheSameId(currentFacet));

const buildColumnsWithoutRepeatedLocations = (filteredFacets) => (columns, currentColumn) =>
    [
        ...columns,
        {
            ...currentColumn,
            facets: currentColumn.facets.filter(facetsWithinTheList(filteredFacets)),
        },
    ];

export const parseLevelColumns = (state, searchType, createRoute) => (columns) => {
    const place = getCurrentPlace(state);
    const listedLocations = [];

    const facetsColumns = columns.map(
        (
            {
                i18n,
                width = 1,
                max = 12,
                expandable = false,
                facets: [searchLevel, param],
                filter,
                transform,
            },
            index
        ) => {
            const id = `column.${index}`;
            const searchLevelFacets = getLevel(searchType)(searchLevel, state);
            const placeSlug = place?.slug;

            let facets = [];

            if (searchLevelFacets) {
                facets = filter
                    ? filter({
                          facets: searchLevelFacets[param],
                          slug: placeSlug,
                          listedLocations,
                      })
                    : searchLevelFacets[param];
            }

            listedLocations.push(...facets.slice(0, max).map(({ slug }) => slug));

            return {
                id,
                i18n: parseI18n(i18n, place),
                width,
                max,
                expandable,
                facets: formatColumnArray(
                    id,
                    !expandable ? facets.slice(0, max) : facets,
                    createRoute
                ),
                transform,
            };
        }
    );

    const filteredFacets = [...facetsColumns].reverse().reduce(buildUniqueFacetsList, []);

    return facetsColumns.reduce(buildColumnsWithoutRepeatedLocations(filteredFacets), []);
};

export const createColumns = (state, { type, createRouteLink }) => {
    if (typeof createRouteLink !== 'function') {
        throw new TypeError('You need to provide a function to resolve the route link.');
    }

    if (!hasFacetsLoaded(type)(state) || !getIsReady(state)) {
        return [];
    }

    const searchLevel = getCurrentSearchLevel(state);
    const search = getDynamicFacetsQueryString(getCurrentFilters(state));
    const createRoute = (location) => ({
        pathname: createRouteLink(location),
        search,
    });
    const parseColumns = parseLevelColumns(state, type, createRoute);

    switch (searchLevel) {
        case FACET_LEVEL_NAME.COUNTRY:
            return parseColumns(COUNTRY_LEVEL_COLUMNS);
        case FACET_LEVEL_NAME.REGION:
            return parseColumns(REGION_LEVEL_COLUMNS);
        case FACET_LEVEL_NAME.COUNTY:
            return parseColumns(COUNTY_LEVEL_COLUMNS);
        case FACET_LEVEL_NAME.PLACE:
            return parseColumns(CITY_LEVEL_COLUMNS);
        default: {
            return parseColumns(GLOBAL_LEVEL_COLUMNS);
        }
    }
};

export const createSitemap = (state, { createSitemapLink, type }) => {
    if (typeof createSitemapLink !== 'function') {
        return null;
    }

    const searchType =
        type === SITEMAP_TYPES.LISTINGS ? SITEMAP_PAGES.HOUSE_SITS : SITEMAP_PAGES.PET_SITTERS;
    const searchLevel = getCurrentSearchLevel(state);

    if (!searchLevel) {
        return {
            i18n: {
                link: ['components_dynamic_facets_explore_all_locations_global'],
            },
            route: {
                pathname: createSitemapLink({ type: searchType }),
            },
        };
    }

    const sitemapObjectMap = {
        type: searchType,
        level: SITEMAP_LINKS[searchLevel],
    };
    const sitemapPathname = createSitemapLink(sitemapObjectMap);

    return {
        i18n: {
            link: [
                'components_dynamic_facets_explore_all_locations_by_level',
                SITEMAP_LINKS[searchLevel],
            ],
        },
        route: {
            pathname: sitemapPathname,
        },
    };
};
