import * as React from 'react';
import { TitleStyled } from './Title.style';

function Title({ className, children }) {
    return <TitleStyled className={className}>{children}</TitleStyled>;
}

Title.defaultProps = {
    className: '',
};

export default Title;
