import ApiConfig from '../config';
import ApiAction from './common';

const config = {
    loadStatus: ['VERIFICATIONS_STATUS', 'LOAD', ApiConfig.endpoints.verifications.status._],
    // Sends a new email for user to verify their email address
    createEmailVerification: [
        'EMAIL_VERIFICATION',
        'CREATE',
        ApiConfig.endpoints.verifications.email._,
    ],

    createIdVerification: ['ID_VERIFICATION', 'CREATE', ApiConfig.endpoints.verifications.id._],
    // Create new request to start Criminal Background Check
    createCBCVerification: [
        'CBC_VERIFICATION',
        'CREATE',
        ApiConfig.endpoints.verifications.criminalBackgroundCheck._,
    ],

    createPhoneVerification: [
        'PHONE_VERIFICATION_REQUEST',
        'CREATE',
        ApiConfig.endpoints.verifications.phone.request,
    ],
    // Verifies a 4 digit code provided by the user
    verifyPhoneVerification: [
        'PHONE_VERIFICATION_VERIFY',
        'UPDATE',
        ApiConfig.endpoints.verifications.phone.verify,
    ],

    loadReferences: [
        'REFERENCES_VERIFICATION',
        'LOAD',
        ApiConfig.endpoints.verifications.references._,
    ],
    // Creates a reference request and returns a link for the member to provide to their referee
    createReferenceRequest: [
        'REFERENCE_VERIFICATION_REQUEST',
        'CREATE',
        ApiConfig.endpoints.verifications.references.request,
    ],
    getProvideReference: [
        'REFERENCE_VERIFICATION_PROVIDE',
        'LOAD',
        ApiConfig.endpoints.verifications.references.provide,
    ],
    updateProvideReference: [
        'REFERENCE_VERIFICATION_PROVIDE',
        'UPDATE',
        ApiConfig.endpoints.verifications.references.provide,
    ],

    createSocialVerification: [
        'SOCIAL_VERIFICATION',
        'CREATE',
        ApiConfig.endpoints.verifications.social._,
    ],
    updateSocialVerification: [
        'SOCIAL_VERIFICATION',
        'UPDATE',
        ApiConfig.endpoints.verifications.social.id,
    ],
    removeSocialVerification: [
        'SOCIAL_VERIFICATION',
        'REMOVE',
        ApiConfig.endpoints.verifications.social.id,
    ],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
