// eslint-disable-next-line import/prefer-default-export
export const VIEWED_FAKE_DOOR_CHECKOUT_MODAL = {
    create(category) {
        return {
            type: 'Viewed Fake Door Checkout Modal',
            payload: {
                category,
            },
        };
    },
};
