import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
    const nodeRef = useRef();
    const [isPortalElementCreated, setIsPortalElementCreated] = useState(false);

    useEffect(() => {
        nodeRef.current = document.createElement('div');
        document.body.appendChild(nodeRef.current);
        setIsPortalElementCreated(true);

        return () => {
            document.body.removeChild(nodeRef.current);
        };
    }, []);

    return isPortalElementCreated ? createPortal(children, nodeRef.current) : null;
};

export default Portal;
