import { formatDate } from 'utils/date';

const getAssignmentId = (state) =>
    state.pages.user.listing.welcomeGuide.assignment &&
    state.pages.user.listing.welcomeGuide.assignment.id;

const getListingId = (state) =>
    state.pages.user.listing.welcomeGuide.welcomeGuide &&
    state.pages.user.listing.welcomeGuide.welcomeGuide.listingId;

const getAssignmentDates = (state) => {
    if (state.pages.user.listing.welcomeGuide.assignment) {
        const { startDate, endDate } = state.pages.user.listing.welcomeGuide.assignment;
        return {
            startDate: formatDate(startDate, 'DDDo MMM yyyy'),
            endDate: formatDate(endDate, 'DDDo MMM yyyy'),
        };
    }
    return null;
};

const getLocation = (state) =>
    state.pages.user.listing.welcomeGuide.assignment &&
    state.pages.user.listing.welcomeGuide.assignment.listing.location;

const getWelcomeGuide = (state) => state.pages.user.listing.welcomeGuide.welcomeGuide;

const getPets = (state) => state.pages.user.listing.welcomeGuide.pets;

const getPlacesOfInterest = (state) => state.pages.user.listing.welcomeGuide.placesOfInterest;

const getWelcomeGuidePhotos = (state) => state.pages.user.listing.welcomeGuide.welcomeGuidePhotos;

export {
    getAssignmentId,
    getListingId,
    getAssignmentDates,
    getLocation,
    getWelcomeGuide,
    getPets,
    getPlacesOfInterest,
    getWelcomeGuidePhotos,
};
