import { all, put, take, select, call, race, takeLatest, takeEvery } from 'redux-saga/effects';
import { photoDiff } from 'containers/PhotoUploader/helpers';
import apiActions, { settings } from 'api/actions';
import { getOwnerListingAmenitiesRaw } from 'api/selectors/owner';
import { sanitize, removeEmptyValues } from 'api/helpers/transform/owner/amenities';
import * as pageActions from 'containers/Page/actions';
import { track, Events } from 'utils/analytics';
import { UPLOADED_PHOTO_TYPES } from 'utils/analytics/events/user/uploadedPhoto';
import { PAGE_ID } from './Home.constants';
import {
    saveListing as saveListingAction,
    saveListingDone as saveListingDoneAction,
    saveListingAmenities,
    saveListingAnalytics,
} from './actions';

export function* handleLoadAmenities({ listingId }) {
    const amenities = yield select((state) => getOwnerListingAmenitiesRaw(state, listingId));

    if (!amenities) {
        yield put(
            apiActions.owner.loadListingAmenities({
                forceReload: true,
                data: {
                    listingId,
                },
            })
        );

        yield take((res) => res.type === settings.owner.loadListingAmenities.DONE);
    }
}

export function* handleSaveAmenities({ listingId, payload }) {
    const amenities = yield select((state) => getOwnerListingAmenitiesRaw(state, listingId));

    if (!amenities) {
        if (Object.keys(removeEmptyValues(payload)).length === 0) {
            return;
        }

        yield put(
            apiActions.owner.createListingAmenities({
                forceReload: true,
                data: sanitize({
                    ...payload,
                    listingId,
                }),
            })
        );

        return;
    }

    yield put(
        apiActions.owner.updateListingAmenities({
            forceReload: true,
            data: sanitize({
                ...amenities,
                ...payload,
            }),
        })
    );
}

export function* handleSaveListingAnalytics(action) {
    const { newListing, amenities } = action;

    const { cancel } = yield race({
        sucess: all([
            take(saveListingDoneAction.ACTION),
            take([
                settings.owner.createListingAmenities.SUCCESS,
                settings.owner.updateListingAmenities.SUCCESS,
            ]),
        ]),
        cancel: take([
            settings.owner.updateListing.FAILURE,
            settings.owner.createListingAmenities.FAILURE,
            settings.owner.updateListingAmenities.FAILURE,
        ]),
    });

    if (cancel) {
        return;
    }

    yield call(track, Events.USER_LISTING_EDIT_HOME.create(newListing, amenities));
}

export function* saveListing(action) {
    const { oldListing, newListing } = action;
    const { addedPhotos, deletedPhotos, updatedMedias } = photoDiff(
        oldListing.photos,
        newListing.photos
    );
    let totalPhotoCount = oldListing.photos ? oldListing.photos.length - deletedPhotos.length : 0;

    yield put(
        apiActions.owner.updateListing({
            forceReload: true,
            data: newListing,
        })
    );
    yield take((res) => res.type === settings.owner.updateListing.DONE);

    // delete removed photos
    for (let a = 0; a < deletedPhotos.length; a += 1) {
        const deletedPhoto = deletedPhotos[a];

        yield put(
            apiActions.owner.removeListingPhoto({
                forceReload: true,
                data: { listingId: oldListing.id, photoId: deletedPhoto.id },
            })
        );

        yield take((res) => res.type === settings.owner.removeListingPhoto.DONE);
    }

    // link all the new photos
    for (let a = 0; a < addedPhotos.length; a += 1) {
        const addedPhoto = addedPhotos[a];

        yield put(
            apiActions.owner.createListingPhoto({
                forceReload: true,
                data: { listingId: oldListing.id, photoId: addedPhoto.id },
            })
        );

        yield take((res) => res.type === settings.owner.createListingPhoto.DONE);

        // eslint-disable-next-line no-plusplus
        yield call(
            track,
            Events.USER_UPLOADED_PHOTO.create(UPLOADED_PHOTO_TYPES.home, ++totalPhotoCount)
        );
    }

    // update captions/order
    if (updatedMedias.length) {
        yield put(
            apiActions.medias.update({
                forceReload: true,
                data: {
                    media: updatedMedias,
                    mediaType: 'photo',
                },
            })
        );

        yield take((res) => res.type === settings.medias.update.DONE);
    }

    // Reload the listing so we don't have stale data on the hub page or
    // if the user tries to come back here quickly
    yield put(
        apiActions.owner.loadListing({
            forceReload: true,
            data: {
                id: oldListing.id,
            },
        })
    );

    yield take((res) => res.type === settings.owner.loadListing.DONE);

    yield put(saveListingDoneAction.create(200));

    return true;
}

export function* handleLoadPage({ type, params }) {
    yield call(handleLoadAmenities, {
        listingId: params.listingId,
    });

    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(PAGE_ID));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(PAGE_ID));
    }
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            handleLoadPage
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            handleLoadPage
        ),
        takeLatest(saveListingAction.ACTION, saveListing),
        takeLatest(saveListingAmenities.ACTION, handleSaveAmenities),
        takeLatest(saveListingAnalytics.ACTION, handleSaveListingAnalytics),
    ]);
}
