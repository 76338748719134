// eslint-disable-next-line import/prefer-default-export
export const BLOG_INTERACTION_SEARCH_BAR = {
    create({ searchTerm }) {
        return {
            type: 'Blog Search Interaction',
            payload: {
                description: 'Search Bar',
                searchTerm,
            },
        };
    },
};

export const BLOG_INTERACTION_SEARCH_BUTTONS_CATEGORY = {
    create({ item }) {
        return {
            type: 'Blog Search Interaction',
            payload: {
                description: `Search Buttons - Category`,
                item,
            },
        };
    },
};

export const BLOG_INTERACTION_SEARCH_BUTTONS_SUBCATEGORY = {
    create({ item }) {
        return {
            type: 'Blog Search Interaction',
            payload: {
                description: `Search Buttons - Subcategory`,
                item,
            },
        };
    },
};

export const BLOG_INTERACTION_SEARCH_BUTTONS_TAG = {
    create({ item }) {
        return {
            type: 'Blog Search Interaction',
            payload: {
                description: `Search Buttons - Tags`,
                item,
            },
        };
    },
};
