const equal = (a, b) => {
    if (a === b) return true;

    const arrA = Array.isArray(a);
    const arrB = Array.isArray(b);
    let itemIndex;

    if (arrA && arrB) {
        if (a.length !== b.length) return false;
        for (itemIndex = 0; itemIndex < a.length; itemIndex += 1) {
            if (!equal(a[itemIndex], b[itemIndex])) return false;
        }
        return true;
    }

    if (arrA !== arrB) return false;

    if (a && b && typeof a === 'object' && typeof b === 'object') {
        const keys = Object.keys(a);
        if (keys.length !== Object.keys(b).length) return false;

        const dateA = a instanceof Date;
        const dateB = b instanceof Date;
        if (dateA && dateB) return a.getTime() === b.getTime();
        if (dateA !== dateB) return false;

        const regexpA = a instanceof RegExp;
        const regexpB = b instanceof RegExp;
        if (regexpA && regexpB) return a.toString() === b.toString();
        if (regexpA !== regexpB) return false;

        for (itemIndex = 0; itemIndex < keys.length; itemIndex += 1) {
            if (!Object.prototype.hasOwnProperty.call(b, keys[itemIndex])) {
                return false;
            }
        }

        for (itemIndex = 0; itemIndex < keys.length; itemIndex += 1) {
            if (!equal(a[keys[itemIndex]], b[keys[itemIndex]])) return false;
        }
        return true;
    }

    return false;
};

const flattenObject = (obj, prefix = '') =>
    Object.keys(obj).reduce((acc, k) => {
        const pre = prefix.length ? `${prefix}_` : '';

        if (Array.isArray(obj[k])) acc[pre + k] = obj[k];
        else if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
        else acc[pre + k] = obj[k];

        return acc;
    }, {});

const removeUndefinedAndNullKeys = (obj) =>
    Object.keys(obj).reduce(
        (acc, key) =>
            obj[key] !== undefined && obj[key] !== null ? { ...acc, [key]: obj[key] } : acc,
        {}
    );

export { equal, flattenObject, removeUndefinedAndNullKeys };

export default equal;
