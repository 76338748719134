import React from 'react';
import { FieldsetStyled, LegendStyled } from './CheckboxGroup.style';

const CheckboxGroup = ({ legendText, data, renderItem, onChange, ...props }) => {
    /**
     *
     * Determines if an item should be checked / selected.
     *
     * @param {object} item an item within the data that is passed to
     * the main component
     * @param {integer} index the index of the item within the data
     * @param {integer} selectedItemIndex the index of the selected item
     *
     * @returns boolean whether the item should be checked or not
     */
    const getIsChecked = (item, index, selectedItemIndex) => {
        if (index === selectedItemIndex) return !item.isChecked;
        return item.isChecked;
    };

    const onToggle = (event, selectedItemIndex) => {
        const itemsWithIsChecked = data.map((item, index) => ({
            ...item,
            isChecked: getIsChecked(item, index, selectedItemIndex),
        }));

        if (onChange) {
            onChange(itemsWithIsChecked);
        }
    };

    return (
        <FieldsetStyled {...props}>
            {legendText ? <LegendStyled>{legendText}</LegendStyled> : null}
            {data.map((item, index) => {
                const onCheckboxChange = (event) => onToggle(event, index);
                return renderItem({ item, index, onChange: onCheckboxChange });
            })}
        </FieldsetStyled>
    );
};

export { CheckboxGroup as default };
