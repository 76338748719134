// eslint-disable-next-line import/prefer-default-export
export const USER_PROFILE_EDIT_PROFILE_DETAILS = {
    create(
        profile = {
            birthDate: null,
            sex: '',
            occupation: '',
            occupationType: null,
            withChildren: null,
            partner: {
                firstName: '',
                lastName: '',
                birthDate: null,
                sex: '',
                occupation: '',
                occupationType: null,
            },
        }
    ) {
        return {
            type: 'Profile Edit Details Update',
            payload: {
                dateOfBirth: !!profile.birthDate,
                sex: !!profile.sex,
                occupation: profile.occupation.length > 0,
                occupationType: !!profile.occupationType,
                withChildren: profile.withChildren !== null,
                partnerFirstName: profile.partner.firstName.length > 0,
                partnerLastName: profile.partner.lastName.length > 0,
                partnerDateOfBirth: !!profile.partner.birthDate,
                partnerSex: !!profile.partner.sex,
                partnerOccupation: !!profile.partner.occupation,
                partnerOccupationType: !!profile.partner.occupationType,
            },
        };
    },
};
