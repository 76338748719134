import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { usePrevious } from 'hooks';
import { retry } from 'utils/ssr';

const LoadableCookiePolicy = loadable(() =>
    retry(() => import('components/notifications/CookiePolicy'))
);

const CookiePolicy = ({ cookiePolicyAccepted, onCookiePolicyAccept, location: { pathname } }) => {
    const prevPathname = usePrevious(pathname);

    // TODO: remove this when AngularApp no longer needed
    global.agreeToCookiePolicy = () => {
        onCookiePolicyAccept();
    };

    useEffect(() => {
        if (!cookiePolicyAccepted && prevPathname && prevPathname !== pathname) {
            onCookiePolicyAccept();
        }
    }, [prevPathname, pathname, cookiePolicyAccepted, onCookiePolicyAccept]);

    return cookiePolicyAccepted ? null : <LoadableCookiePolicy onClose={onCookiePolicyAccept} />;
};

export default CookiePolicy;
