import { getAccountCurrentMembershipPlan, getAccountSettings } from 'api/selectors';
import { getExperimentalFeatureVariation } from './helpers';

export const getSplit = (state) => state.split;

export const getExperimentalFeatureVariationSelector = (state, args) => {
    const split = getSplit(state);
    const membershipPlan = getAccountCurrentMembershipPlan(state);
    const { isStaff = false } = getAccountSettings(state) || {};

    return getExperimentalFeatureVariation({
        split,
        membershipPlan,
        attributes: {
            ...args.attributes,
            isStaff,
        },
        ...args,
    });
};
