/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { baseLinkStyle } from 'components/Link/Link.style';
import { baseButtonStyle } from 'components/buttons/Button/Button.style';
import { ButtonVariant } from 'components/buttons/Button/Button.constants';

const AnchorStyled = styled.a`
    ${({ variant }) => {
        if (Object.values(ButtonVariant).includes(variant)) {
            return baseButtonStyle;
        }
        return baseLinkStyle;
    }};
`;

export { AnchorStyled };
