import { getState } from './common';

//
// Data selectors
//
export const getSession = state => getState(state).session;

//
// Status selectors
//
export const getSessionStatus = state => getState(state).session.status;
