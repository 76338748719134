import { settings as actions } from '../../actions';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.account.loadMembership.SUCCESS: {
            const { expiryDate } = action.data;
            if (!expiryDate) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state;
        }
        case actions.account.loadIncompleteMembershipPlan.SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        case actions.account.createIncompleteMembershipPlan.SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        case actions.account.updateIncompleteMembershipPlan.SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};
