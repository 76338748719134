/* eslint-disable no-underscore-dangle */
import constants from './constants';

/*
 * Usage:
 *
 * import and initialise  the validator using the following code
import Validator from './validation/validation';
const validate = new Validator({throw: true});
 * The constructor accepts two options:
 * throw: when set to false return a boolean rather than throwing an error. defaults to true
 * silent: then not in throwing mode, silent will prevent anything being sent to the console. Useful for testing
 * datatypes without an incorrect value being an error. defaults to false
 *
 * the validator then offers a number of helpful functions for runtime validation:
 * validator.test(): takes two paramaters and returns a pass/fail boolean. In throwing mode throws an error instead of
 * returning false
 * The first paramater is the test, the second the value to test against.
 * possible first paramaters:
 * 'any': will accept any value.
 * one of: 'string', 'number', 'undefined', 'function', 'object' or 'boolean': will accept values of the given type, as
 * per typeof.
 * {}: will accept any object, similar to 'object' although 'object' is faster.
 * {key: 'string}: key value pairs will require the given object to have that key, and recursively test the value
 * (accepting all possible arguments for test)
 * an empty array will accpet an array with any contents
 * an array with a single value will accept an array of that type, calling test on each value of the array
 * an array with multiple values will operate as an OR on the values in the array. So ['string', 'number'] would accept
 * either a string and a number
 *
 * enums can be used using the syntax "enum:possible,values" where all possible values should be comma separated
 * *without spaces*
 *
 * providing a class will test if the given value is an instance of said class
 *
 * 'null' will test for null
 *
 * The validator also offers a handful of shorthand functions for basic datatypes:
 * 'string', 'number', 'undefined', 'function', 'object' or 'boolean' can all be used in the following form:
validator.string(value);
 *
 * a simpler enumeration methid is offered that is provided with an array of string values to test against:
validator.enumeration(['accepted', 'strings'], value);
 *
 * a standalone oneOf method is provided to reduce confusion vs testing for an array, as it also accepts a single value
 * array, i.e. ['string'] to only accept a string.
 */

class Validator {
    constructor(prams) {
        if (typeof prams === 'object' && typeof prams.throw === 'undefined') {
            this._throw = true;
        }
        else if (typeof prams === 'object') {
            this._throw = prams.throw === true; // we want this value to always be a boolean even if we get guff
            this._silent = prams.silent;
        }
        else {
            this.throw = true;
        }


        // shorthand helper functions for each type
        constants.simpleTypesIterable.forEach((type) => {
            this[type] = value => this._typeValidate(type, value);
        });
    }

    oneOf(types, value) {
        // type could be any of the list
        const result = types.filter(type => (new Validator({ throw: false, silent: true }))._typeValidate(type, value));
        if (result.length < 1) {
            return this._throwError(`expected one of: ${types.join('; ')} but got ${typeof value}`);
        }
        return true;
    }

    test(type, value) {
        if (type === 'any') return true;
        // any value is acceptable for an any, so always pass.

        if (typeof type === 'string' && type.match(/^enum:/)) {
            return this._enumParse(type, value);
        }
        if (type === 'null') {
            if (value !== null) {
                return this._throwError(`expected null but got ${typeof value}`);
            }
            return true;
        }

        if (constants.simpleTypesIterable.includes(type)) {
            // if we can check with just a simple typeof, we do so
            if (!this._simpleTypeCheck(type, value)) {
                return this._throwError(`expected ${type} but got ${typeof value}`);
            }
            return true;
        }
        else if (Array.isArray(type)) {
            // Arrays could either be because they accept multiple values (more than one value in the array) or because
            // we're looking specifically for an array of values
            if (type.length > 1) {
                // a long array is an OR value
                return this.oneOf(type, value);
            } if (type.length === 0) {
                // an empty array is equivalent to ["any"] (but faster to run, and therefore preferred)
                if (Array.isArray(value)) {
                    return true;
                }
                return this._throwError(`expected array but got ${typeof value}`);
            }
            else if (!Array.isArray(value) || value.filter(val => !((new Validator({ throw: false, silent: true })).test(type[0], val))).length > 0) {
                // a single item array means we should test each item of the value array against the type array's first
                // item
                return this._throwError(`expected array of ${type[0]} but got ${typeof value}`);
            }
        }
        else if (typeof type === 'function') {
            // if we're given a function we assume it's a constructor
            return this._classValidate(type, value);
        }
        else if (typeof type === 'object') {
            // if we're given an object we want to make sure all the properties are available on the supplied object for
            // it to be valid
            return this._objectValidate(type, value);
        }
        return true;
    }

    enumeration(enumeration, value) {
        if (typeof value === 'string' && enumeration.includes(value)) {
            return true;
        }
        return this._throwError(`expected one of: "${enumeration.join('; ')}" but got ${JSON.stringify(value)}`);
    }

    _enumParse(enumeration, value) {
        let possibleValues = enumeration.match(/enum:(.*)/);
        possibleValues = possibleValues[1].split(',');
        return this.enumeration(possibleValues, value);
    }

    _objectValidate(obj, value) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (Object.prototype.hasOwnProperty.call(value, key)) {
                    if (!this._typeValidate(obj[key], value[key])) {
                        return false;
                    }
                }
                else if (!this._throwError(`expected object structure "${JSON.stringify(obj)}" but got ${JSON.stringify(value)}`)) {
                    return false;
                }
            }
        }
        return this.test('object', value);
    }

    _typeValidate(type, value, errorString) {
        const typeValid = (new Validator({ throw: false, silent: true })).test(type, value);
        if (!typeValid) {
            return this._throwError(errorString || `expected ${type} but got ${typeof value}`);
        }
        return true;
    }

    _classValidate(type, value) {
        if (!(value instanceof type)) {
            return this._throwError(`expected instance of class: ${type.constructor.name} but got ${typeof type}`);
        }
        return true;
    }
    _simpleTypeCheck(type, value) {
        // We suppress valid-typeof here as any other way of doing this massively complicates the logic
        // eslint-disable-next-line valid-typeof
        return typeof value === type;
    }

    _throwError(errorText) {
        const error = new TypeError(errorText);

        if (error.stack) { // stack trace on errors is non standard, so we only refactor it if we've got it
            // we don't care about the validator in these errors, we want to see the function that triggered the
            // validator, so we filter out those lines.
            let trace = error.stack.split('\n');
            trace = trace.filter(line => !line.match(/validation\.js/));
            error.stack = trace.join('\n');
        }

        // if we're enabled to throw errors (default) we throw, otherwise we just log the error and return false
        if (this._throw) {
            throw error;
        }
        else if (this._silent) {
            return false;
        }
        else {
            console.error(error);
            return false;
        }
    }
}

export default Validator;
