import { Errors } from 'types/validation';

// We don't allow some keyboard symbols as part of a first_name due to security concerns
// The first_name field is used in email campaigns and is an easy target for injecting malicious content
// This regex matches against common keyboard symbols used in links, scripts, emails, or code blocks
const restrictedSymbolsRegex = /[.:;/\\@()<>[\]{}]/i;

export function validateName(name) {
    let error;
    if (!name) {
        error = Errors.REQUIRED;
    } else if (restrictedSymbolsRegex.test(name)) {
        error = Errors.INVALID;
    }
    return error;
}

export function validateEmail(email) {
    let error;
    if (!email) {
        error = Errors.REQUIRED;
    } else if (email.split('@').length !== 2) {
        error = Errors.INVALID;
    } else if (!/.+@.+\..+$/.test(email)) {
        error = Errors.INVALID;
    }

    return error;
}

export function validatePassword(password) {
    let error;

    if (!password) {
        error = Errors.REQUIRED;
    } else if (password.length < 10) {
        error = Errors.TOO_SHORT;
    }

    return error;
}
