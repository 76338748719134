import { Sitemap as SitemapSearchLevel } from '../../helpers/search/constants';
import { settings as actions } from '../../actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.search.loadSitemapPages.SUCCESS: {
            const type = action.action.data?.type || 'listings';

            return {
                ...state,
                [type]: {
                    ...state[type],
                    pages: action.data,
                },
            };
        }
        case actions.search.loadSitemapLocations.SUCCESS: {
            const page = action.action.filters?.page || 1;
            const level = action.action.data?.level || SitemapSearchLevel.COUNTRY;
            const type = action.action.data?.type || 'listings';

            return {
                ...state,
                [type]: {
                    ...state[type],
                    [level]: {
                        [page]: action.data,
                    },
                },
            };
        }
        default:
            return state;
    }
};
