import {
    LIST,
    WEIGHTS,
    AMENITIES_LISTING_CATEGORIES,
    AMENITIES_EDIT_LISTING_CATEGORIES,
    AMENITIES_LIST,
    TYPES,
    PARKING_TYPE,
} from '../../../constants/listing/amenities';

const defaultOptions = {
    maxNoOfTags: 5,
    category: 'listing',
};

/**
 * Sanitize the amenities fields to be consistent with what the
 * API expects to receive: all boolean `false` values should be null.
 */
export const sanitize = amenities => Object.entries(amenities).reduce((acc, [key, value]) => {
    if (typeof value === 'boolean' && !value) {
        return {
            ...acc,
            [key]: null,
        };
    }

    return {
        ...acc,
        [key]: value,
    };
}, {});

/**
 * Remove all empty values from the amenities object. Useful when using
 * with the whole amenities object and we don't need to use the values that
 * the user doesn't provided.
 */
export const removeEmptyValues = (amenities) =>
    Object.entries(amenities)
        .filter(([key]) => LIST.includes(key))
        .reduce((acc, [key, value]) => {
            if (Array.isArray(value) && value.length === 0) {
                return acc;
            }

            if (value === null || value === '') {
                return acc;
            }

            return {
                ...acc,
                [key]: value,
            };
        }, {});

/**
 * Tranforms the amenities object into a new array of [type, value].
 * @throws
 */
export const toArray = obj => {
    if (typeof obj !== 'object') {
        throw new TypeError(
            `\`amenities\` is not an object. Expected: \`object\`, Found: \`${typeof obj}\`.`
        );
    }

    return Object.entries(obj).reduce((acc, [key, value]) => [...acc, [key, value]], []);
};

/**
 * Tranforms the amenities array of tuples back into object.
 * @throws
 */
export const toObject = arr => {
    if (!Array.isArray(arr)) {
        throw new TypeError(
            `\`amenities\` is not an array. Expected: \`array\`, Found: \`${typeof obj}\`.`
        );
    }

    return arr.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

/**
 * Sort the amenities array based on weights for each amenity.
 * Note: doesn't mutate the original array.
 * @throws
 */
export const sort = arr => {
    if (!Array.isArray(arr)) {
        throw new TypeError(
            `\`amenities\` is not an array. Expected: \`array\`, Found: \`${typeof obj}\`.`
        );
    }

    const sortedArray = [...arr];

    sortedArray.sort(([first], [second]) => {
        const firstWeight = WEIGHTS[first];
        const secondWeight = WEIGHTS[second];

        if (firstWeight > secondWeight) {
            return 1;
        }

        return firstWeight < secondWeight ? -1 : 0;
    });

    return sortedArray;
};

export function populateTags(amenities) {
    return amenities.reduce((acc, [key, values = null]) => {
        const amenity = AMENITIES_LIST.find((item) => item.property === key);

        if (!amenity) {
            return acc;
        }

        const { property, type } = amenity;
        if (type === TYPES.MULTISELECT) {
            return [
                ...acc,
                ...values.map((value) => ({
                    property,
                    value,
                })),
            ];
        }
        if (property === PARKING_TYPE) {
            return [
                ...acc,
                {
                    hidePropertyTitle: true,
                    property,
                    value: values,
                },
            ];
        }
        return [
            ...acc,
            {
                property,
                value: values,
            },
        ];
    }, []);
}

export function getCategories(name) {
    switch (name) {
        case 'listing':
            return AMENITIES_LISTING_CATEGORIES;
        case 'listing-edit':
            return AMENITIES_EDIT_LISTING_CATEGORIES;
        default:
            throw new TypeError(`Category provided not found: ${name}`);
    }
}

export function populateCategories(amenities, categoryName) {
    const categories = getCategories(categoryName).map((category) => ({
        ...category,
        amenities: [],
    }));

    amenities.forEach(([key, value]) => {
        const amenity = AMENITIES_LIST.find((element) => element.property === key);

        if (!amenity) {
            return;
        }

        const category = categories.find((c) => amenity.categories.includes(c.property));

        if (!category) {
            return;
        }

        if (Array.isArray(amenity.options)) {
            category.amenities.push({
                ...amenity,
                options: amenity.options.filter((option) => value.includes(option.property)),
            });
        } else {
            category.amenities.push(amenity);
        }
    });

    return categories.filter((category) => category.amenities.length !== 0);
}

export function transformListingAmenities(amenities, options = defaultOptions) {
    if (!amenities || typeof amenities !== 'object') {
        return {
            tags: [],
            amenities: [],
            total: 0,
        };
    }

    const array = sort(toArray(removeEmptyValues(amenities)));
    const transformedAmenities = {
        tags: populateTags(array).slice(0, options.maxNoOfTags),
        categories: populateCategories(array, options.category),
        total: array.length,
    };
    return transformedAmenities;
}
