import React from 'react';
import { ErrorTextStyled, ErrorIcon, ErrorChild } from './ErrorMessage.style';

/**
 * @deprecated Use `src/components/Form/Message` instead.
 */
const ErrorMessage = ({ className, children, hasIcon = true }) => (
    <ErrorTextStyled className={className}>
        {hasIcon ? <ErrorIcon /> : null}
        <ErrorChild>{children}</ErrorChild>
    </ErrorTextStyled>
);

export default ErrorMessage;
