import { showError, showSuccess, setSubmitting } from './actions';

export const initialState = {
    networkError: false,
    isSuccessful: false,
    isSubmitting: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case showError.ACTION:
            return {
                ...state,
                networkError: true,
                isSuccessful: false,
            };
        case showSuccess.ACTION:
            return {
                ...state,
                networkError: false,
                isSuccessful: true,
            };
        case setSubmitting.ACTION:
            return {
                ...state,
                // Clear these bits of state when a new submission starts
                networkError: action.isSubmitting ? false : state.networkError,
                isSuccessful: action.isSubmitting ? false : state.isSuccessful,
                isSubmitting: action.isSubmitting,
            };
        default:
            return state;
    }
};
