import * as React from 'react';

import * as Rating from '../components';
import { Wrapper } from './InteractiveRatingWithLabel.style';

function InteractiveRatingWithLabel({ value, labels, onChange, variant }) {
    return (
        <Rating.Root initialValue={value} labels={labels}>
            <Wrapper>
                <Rating.Container>
                    {({ rating, isHighlighted, shouldAnimate }) => (
                        <Rating.Button
                            rating={rating}
                            shouldAnimate={shouldAnimate}
                            onClick={onChange}
                        >
                            <Rating.Icon isHighlighted={isHighlighted} variant={variant} />
                        </Rating.Button>
                    )}
                </Rating.Container>

                <Rating.Labels>
                    {({ rating, label }) => <Rating.Label rating={rating}>{label}</Rating.Label>}
                </Rating.Labels>
            </Wrapper>
        </Rating.Root>
    );
}

InteractiveRatingWithLabel.defaultProps = {
    value: 0,
    labels: ['Awful', 'Bad', 'Okay', 'Good', 'Excellent'],
    onChange: () => {},
};

export default InteractiveRatingWithLabel;
