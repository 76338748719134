// eslint-disable-next-line import/prefer-default-export
export const VIEWED_EXPERIMENTAL_FEATURE = {
    create(name, variation, description) {
        const payload = {
            name,
            variation,
            description,
        };

        return {
            type: 'Viewed Experimental Feature',
            payload,
        };
    },
};
