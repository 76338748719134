import { settings as actions } from '../../actions';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.account.loadEmergencyContact.SUCCESS: {
            const {
                statusCode,
                data: [emergencyContact],
            } = action;

            if (!emergencyContact) {
                return {
                    ...state,
                    emergencyContact: {
                        responseStatus: statusCode,
                        data: null,
                    },
                };
            }

            const { id, phone, name, countryId, description } = emergencyContact;

            return {
                ...state,
                emergencyContact: {
                    responseStatus: statusCode,
                    data: {
                        name,
                        relationship: description,
                        phoneNumber: {
                            code: countryId,
                            number: phone,
                        },
                        id: Number(id),
                    },
                },
            };
        }

        case actions.account.createEmergencyContact.SUCCESS:
        case actions.account.updateEmergencyContact.SUCCESS: {
            const {
                data: { phone, id, countryId, name, description },
                statusCode,
            } = action;

            return {
                ...state,
                emergencyContact: {
                    responseStatus: statusCode,
                    data: {
                        name,
                        relationship: description,
                        phoneNumber: {
                            code: countryId,
                            number: phone,
                        },
                        id: Number(id),
                    },
                },
            };
        }

        case actions.account.loadEmergencyContact.FAILURE:
            return {
                ...state,
                emergencyContact: {
                    responseStatus: action.statusCode,
                    data: null,
                },
            };

        case actions.account.createEmergencyContact.FAILURE:
        case actions.account.updateEmergencyContact.FAILURE: {
            const { statusCode } = action;

            return {
                ...state,
                emergencyContact: {
                    responseStatus: statusCode,
                    data: state.emergencyContact.data,
                },
            };
        }

        // user emergency contact
        case actions.account.createSafetyContact.SUCCESS:
        case actions.account.updateSafetyContact.SUCCESS:
        case actions.account.loadSafetyContact.SUCCESS: {
            const {
                statusCode,
                data: { phone, countryId },
            } = action;

            return {
                ...state,
                safetyContact: {
                    responseStatus: statusCode,
                    data: {
                        phoneNumber: {
                            code: countryId,
                            number: phone,
                        },
                    },
                },
            };
        }

        case actions.account.loadSafetyContact.FAILURE: {
            const { statusCode } = action;

            return {
                ...state,
                safetyContact: {
                    ...state.safetyContact,
                    responseStatus: statusCode,
                    data: null,
                },
            };
        }

        case actions.account.createSafetyContact.FAILURE:
        case actions.account.updateSafetyContact.FAILURE: {
            const { statusCode } = action;

            return {
                ...state,
                safetyContact: {
                    ...state.safetyContact,
                    responseStatus: statusCode,
                    data: state.safetyContact.data,
                },
            };
        }

        default:
            return state;
    }
};
