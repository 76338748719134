import ApiConfig from '../config';
import ApiAction from './common';

const config = {
    load: ['NOTIFICATIONS', 'LOAD', ApiConfig.endpoints.notifications._],
    acknowledgeNotification: ['NOTIFICATIONS', 'ACKNOWLEDGE', ApiConfig.endpoints.notifications.notification, [], 'PUT',
        { isRead: true }, null,
    ],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
