/* eslint-disable import/prefer-default-export */

import { genericType } from './constants';

export const KEYBOARDPRESS = {
    create(options) {
        return {
            type: genericType,
            payload: {
                name: 'keyboard press',
                ...options,
            },
        };
    },
};
