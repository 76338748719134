import ApiConfig from '../config';
import ApiAction from './common';

import {
    openAssignmentFields,
    openAssignmentApplicationFields,
    confirmedAssignmentFields,
    currentAssignmentFields,
    upcomingAssignmentFields,
    pastAssignmentFields,
} from '../fields/owner';

const config = {
    // Owner
    load: ['OWNER', 'LOAD', ApiConfig.endpoints.owner._],

    // Listing
    loadListings: [
        'OWNER_LISTINGS',
        'LOAD',
        ApiConfig.endpoints.owner.listings._,
        [
            'pets', // Listing pets
        ],
    ],
    loadListing: [
        'OWNER_LISTING',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing._,
        [
            'pets', // Listing pets
        ],
    ],
    createListing: ['OWNER_LISTINGS', 'CREATE', ApiConfig.endpoints.owner.listings._],
    updateListing: ['OWNER_LISTING', 'UPDATE', ApiConfig.endpoints.owner.listings.listing._],

    // Publish listing
    publishListing: [
        'OWNER_LISTING_PUBLISH',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.publish,
    ],

    // Send listing for membership services for review
    sendListingForReview: [
        'OWNER_LISTING_SEND_FOR_REVIEW',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.review,
    ],

    // Listing media
    loadListingPhotos: [
        'OWNER_LISTING_PHOTOS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.photos._,
    ],
    createListingPhoto: [
        'OWNER_LISTING_PHOTO',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.photos.photo,
    ],
    updateListingPhoto: [
        'OWNER_LISTING_PHOTO',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.photos.photo,
    ],
    removeListingPhoto: [
        'OWNER_LISTING_PHOTO',
        'REMOVE',
        ApiConfig.endpoints.owner.listings.listing.photos.photo,
    ],

    // Listing pets
    loadListingPets: [
        'OWNER_LISTING_PETS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.pets._,
    ],
    createListingPet: [
        'OWNER_LISTING_PETS',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.pets._,
    ],
    updateListingPet: [
        'OWNER_LISTING_PET',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.pets.pet,
    ],
    removeListingPet: [
        'OWNER_LISTING_PET',
        'REMOVE',
        ApiConfig.endpoints.owner.listings.listing.pets.pet,
    ],
    loadListingPetPhotos: [
        'OWNER_LISTING_PET_PHOTOS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.pets.photos,
    ],
    createListingPetPhoto: [
        'OWNER_LISTING_PET_PHOTO',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.pets.photo,
    ],
    removeListingPetPhoto: [
        'OWNER_LISTING_PET_PHOTO',
        'REMOVE',
        ApiConfig.endpoints.owner.listings.listing.pets.photo,
    ],

    // Assignment
    loadAssignment: [
        'OWNER_ASSIGNMENT',
        'LOAD',
        ApiConfig.endpoints.owner.assignments.assignment._,
    ],
    // Welcome Guide
    createWelcomeGuide: [
        'OWNER_WELCOME_GUIDE',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.welcomeGuide._,
    ],
    loadWelcomeGuide: [
        'OWNER_ASSIGNMENT_WELCOME_GUIDE',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.welcomeGuide._,
    ],
    createAssignmentWelcomeGuide: [
        'OWNER_ASSIGNMENT_WELCOME_GUIDE',
        'CREATE',
        ApiConfig.endpoints.owner.assignments.assignment.welcomeGuideDetails,
        [],
        'POST',
    ],
    shareAssignmentWelcomeGuide: [
        'OWNER_ASSIGNMENT_WELCOME_GUIDE',
        'SHARE',
        ApiConfig.endpoints.owner.assignments.assignment.welcomeGuideDetails,
        [],
        'PUT',
        null,
        {
            share: true,
        },
        {
            minutes: 1,
        },
    ],
    loadWelcomeGuidePhotos: [
        'OWNER_ASSIGNMENT_WELCOME_GUIDE_PHOTOS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.welcomeGuide.photos._,
    ],

    // Places of interest
    loadListingPlacesOfInterest: [
        'OWNER_LISTING_PLACES_OF_INTEREST',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.placesOfInterest._,
    ],

    loadListingAddress: [
        'OWNER_LISTING_ADDRESS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.address._,
    ],
    createListingAddress: [
        'OWNER_LISTING_ADDRESS',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.address._,
    ],
    updateListingAddress: [
        'OWNER_LISTING_ADDRESS',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.address._,
    ],

    // Open Assignments
    loadOpenAssignments: [
        'OWNER_OPEN_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.assignments.open,
        [...openAssignmentFields],
    ],
    pauseOpenAssignment: [
        'OWNER_OPEN_ASSIGNMENT',
        'PAUSE',
        ApiConfig.endpoints.owner.listings.listing.openAssignments.assignment._,
        [],
        'PUT',
        { isReviewing: true },
        null,
        {
            seconds: 2,
        },
    ],
    resumeOpenAssignment: [
        'OWNER_OPEN_ASSIGNMENT',
        'RESUME',
        ApiConfig.endpoints.owner.listings.listing.openAssignments.assignment._,
        [],
        'PUT',
        { isReviewing: false },
        null,
        {
            seconds: 2,
        },
    ],
    createOpenAssignmentWithInvitationDEPRECATED: [
        'OWNER_OPEN_ASSIGNMENT_WITH_INVITATION__DEPRECATED',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.openAssignments.assignment._,
        [],
        'POST',
        { invited: true },
        null,
    ],

    // Open Assignments Applications / Invitations
    loadOpenAssignmentApplications: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATIONS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications._,
        [...openAssignmentApplicationFields],
    ],
    loadOpenAssignmentApplication: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATION',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications.application,
        [...openAssignmentApplicationFields],
    ],
    createOpenAssignmentApplication: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATION',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications._,
    ],
    updateOpenAssignmentApplication: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATION',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications.application,
    ],
    shortlistOpenAssignmentApplication: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATION',
        'SHORTLIST',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications.application,
        [],
        'PUT',
        { isShortlisted: true },
        null,
        {
            seconds: 1,
        },
    ],
    unshortlistOpenAssignmentApplication: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATION',
        'UNSHORTLIST',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications.application,
        [],
        'PUT',
        { isShortlisted: false },
        null,
        {
            seconds: 1,
        },
    ],
    confirmOpenAssignmentApplication: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATION',
        'CONFIRM',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications.application,
        [],
        'PUT',
        { hasOwnerConfirmed: true },
        null,
        {
            seconds: 2,
        },
    ],
    unconfirmOpenAssignmentApplication: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATION',
        'UNCONFIRM',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications.application,
        [],
        'PUT',
        { hasOwnerConfirmed: false },
        null,
        {
            seconds: 2,
        },
    ],
    declineOpenAssignmentApplications: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATIONS',
        'DECLINE',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment._,
        [],
        'PUT',
        { hasOwnerDeclined: true },
        null,
        {
            seconds: 2,
        },
    ],
    declineOpenAssignmentApplication: [
        'OWNER_OPEN_ASSIGNMENT_APPLICATION',
        'DECLINE',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.applications.application,
        [],
        'PUT',
        { hasOwnerDeclined: true },
        null,
        {
            seconds: 2,
        },
    ],
    loadOpenAssignmentInvitations: [
        'OWNER_OPEN_ASSIGNMENT_INVITATIONS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.invitations._,
        [...openAssignmentApplicationFields],
    ],
    loadOpenAssignmentInvitation: [
        'OWNER_OPEN_ASSIGNMENT_INVITATION',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.invitations.invitation,
        [...openAssignmentApplicationFields],
    ],
    createOpenAssignmentInvitation: [
        'OWNER_OPEN_ASSIGNMENT_INVITATION',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.invitations._,
    ],
    createBatchOpenAssignmentInvitation: [
        'OWNER_OPEN_ASSIGNMENT_INVITATION',
        'CREATE_BATCH',
        ApiConfig.endpoints.owner.listings.listing.invitations.batch,
        [],
        'POST',
    ],
    cancelOpenAssignmentInvitation: [
        'OWNER_OPEN_ASSIGNMENT_INVITATION',
        'CANCEL',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.invitations.invitation,
        [],
        'PUT',
        { hasOwnerCancelled: true },
        null,
    ],
    deleteOpenAssignmentInvitation: [
        'OWNER_OPEN_ASSIGNMENT_INVITATION',
        'REMOVE',
        ApiConfig.endpoints.owner.listings.listing.assignments.assignment.invitations.invitation,
        [],
        'DELETE',
    ],
    // Listing
    loadListingOpenAssignments: [
        'OWNER_LISTING_OPEN_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.openAssignments._,
    ],
    createListingOpenAssignment: [
        'OWNER_LISTING_OPEN_ASSIGNMENTS',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.openAssignments._,
        [],
        'POST',
        null,
        null,
        {
            seconds: 5,
        },
    ],
    updateListingOpenAssignment: [
        'OWNER_LISTING_OPEN_ASSIGNMENT',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.openAssignments.assignment._,
        [],
        'PUT',
        null,
        null,
        {
            seconds: 3,
        },
    ],
    removeListingOpenAssignment: [
        'OWNER_LISTING_OPEN_ASSIGNMENT',
        'REMOVE',
        ApiConfig.endpoints.owner.listings.listing.openAssignments.assignment._,
    ],

    // Listing future assignments
    loadListingFutureAssignments: [
        'OWNER_LISTING_FUTURE_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.futureAssignments._,
    ],

    notifyConfirmedAssignmentUnconfirmedApplicants: [
        'OWNER_LISTING_CONFIRMED_ASSIGNMENT',
        'BATCH_NOTIFY',
        ApiConfig.endpoints.owner.listings.listing.confirmedAssignments.upcoming.notifyUnconfirmed,
        [],
        'POST',
    ],

    // Open Assignments
    loadDeletedAssignments: [
        'OWNER_DELETED_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.assignments.deleted,
        [...openAssignmentFields],
    ],

    loadUnconfirmedPastAssignments: [
        'OWNER_UNCONFIRMED_PAST_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.assignments.unconfirmed.past,
        [...openAssignmentFields],
    ],

    boostOpenAssignment: [
        'OWNER_OPEN_ASSIGNMENT_BOOST',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.openAssignments.assignment.boost._,
        [],
        'POST',
        null,
        null,
        {
            seconds: 1,
        },
    ],

    // Confirmed Assignments
    loadConfirmedAssignments: [
        'OWNER_CONFIRMED_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.assignments.confirmed._,
        [...confirmedAssignmentFields],
    ],

    // Current assignments
    loadCurrentAssignments: [
        'OWNER_CURRENT_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.assignments.confirmed.current,
        [...currentAssignmentFields],
    ],

    // Upcoming assignments
    loadUpcomingAssignments: [
        'OWNER_UPCOMING_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.assignments.confirmed.upcoming._,
        [...upcomingAssignmentFields],
    ],
    unconfirmConfirmedAssignment: [
        'OWNER_CONFIRMED_ASSIGNMENTS',
        'UNCONFIRM',
        ApiConfig.endpoints.owner.listings.listing.confirmedAssignments.upcoming.assignment,
        [],
        'PUT',
        { profileId: null },
        null,
    ],

    // Past Assignments
    loadPastAssignments: [
        'OWNER_PAST_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.assignments.confirmed.past,
        [...pastAssignmentFields],
    ],
    loadPastAssignmentFeedback: [
        'OWNER_PAST_ASSIGNMENT_FEEDBACK',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.confirmedAssignments.past.assignment.feedback,
    ],
    updatePastAssignmentFeedback: [
        'OWNER_PAST_ASSIGNMENT_FEEDBACK',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.confirmedAssignments.past.assignment.feedback,
    ],
    loadPastAssignmentFeedbackRequest: [
        'OWNER_PAST_ASSIGNMENT_FEEDBACK_REQUEST',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.confirmedAssignments.past.assignment
            .feedbackRequest,
    ],
    createPastAssignmentFeedbackRequest: [
        'OWNER_PAST_ASSIGNMENT_FEEDBACK_REQUEST',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.confirmedAssignments.past.assignment
            .feedbackRequest,
    ],
    loadPastAssignmentReview: [
        'OWNER_PAST_ASSIGNMENT_REVIEW',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.confirmedAssignments.past.assignment.review,
    ],
    createPastAssignmentReview: [
        'OWNER_PAST_ASSIGNMENT_REVIEW',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.confirmedAssignments.past.assignment.review,
    ],

    // Welcome Guide
    loadListingGuide: [
        'OWNER_LISTING_GUIDE',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.guide,
    ],
    createListingGuide: [
        'OWNER_LISTING_GUIDE',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.guide,
    ],
    updateListingGuide: [
        'OWNER_LISTING_GUIDE',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.guide,
    ],
    loadListingGuideMedias: [
        'OWNER_LISTING_GUIDE_MEDIAS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.guide.medias._,
    ],
    createListingGuideMedia: [
        'OWNER_LISTING_GUIDE_MEDIAS',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.guide.medias._,
    ],
    updateListingGuideMedia: [
        'OWNER_LISTING_GUIDE_MEDIA',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.guide.medias.media,
    ],
    removeListingGuideMedia: [
        'OWNER_LISTING_GUIDE_MEDIA',
        'REMOVE',
        ApiConfig.endpoints.owner.listings.listing.guide.medias.media,
    ],

    // Amenities
    loadListingAmenities: [
        'OWNER_LISTING_AMENITIES',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.amenities._,
    ],
    createListingAmenities: [
        'OWNER_LISTING_AMENITIES',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.amenities._,
    ],
    updateListingAmenities: [
        'OWNER_LISTING_AMENITIES',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.amenities._,
    ],

    // Owner Favourite Profiles
    loadFavourites: ['OWNER_FAVOURITES', 'LOAD', ApiConfig.endpoints.owner.favourites._],
    createFavourite: [
        'OWNER_FAVOURITES',
        'CREATE',
        ApiConfig.endpoints.owner.favourites._,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    loadFavourite: ['OWNER_FAVOURITE', 'LOAD', ApiConfig.endpoints.owner.favourites.favourite],
    removeFavourite: [
        'OWNER_FAVOURITE',
        'REMOVE',
        ApiConfig.endpoints.owner.favourites.favourite,
        [],
        'DELETE',
        null,
        null,
        {
            seconds: 2,
        },
    ],

    // Conversation
    loadConversations: ['OWNER_CONVERSATIONS', 'LOAD', ApiConfig.endpoints.owner.conversations._],
    createConversation: [
        'OWNER_CONVERSATION',
        'CREATE',
        ApiConfig.endpoints.owner.conversations._,
        [],
        'POST',
        null,
        null,
        {
            seconds: 1,
        },
    ],
    archiveConversations: [
        'OWNER_CONVERSATION',
        'ARCHIVE',
        ApiConfig.endpoints.owner.conversations._,
        [],
        'PUT',
        {
            hasHomeownerArchived: true,
        },
        null,
        {
            seconds: 1,
        },
    ],
    batchArchiveConversations: [
        'OWNER_CONVERSATION',
        'BATCH_ARCHIVE',
        ApiConfig.endpoints.owner.conversations.batch,
        [],
        'PUT',
        {
            hasHomeownerArchived: true,
        },
        null,
        {
            seconds: 1,
        },
    ],
    unArchiveConversations: [
        'OWNER_CONVERSATION',
        'UNARCHIVE',
        ApiConfig.endpoints.owner.conversations._,
        [],
        'PUT',
        {
            hasHomeownerArchived: false,
        },
        null,
        {
            seconds: 1,
        },
    ],
    batchUnArchiveConversations: [
        'OWNER_CONVERSATION',
        'BATCH_UNARCHIVE',
        ApiConfig.endpoints.owner.conversations.batch,
        [],
        'PUT',
        {
            hasHomeownerArchived: false,
        },
        null,
        {
            seconds: 1,
        },
    ],

    // Sitter Profile (Part of the Search!!)
    loadSitterProfile: ['OWNER_SITTER_PROFILE', 'LOAD', ApiConfig.endpoints.owner.sitter.profile._],

    loadOwnerSitterApplications: [
        'OWNER_SITTER_APPLICATIONS',
        'LOAD',
        ApiConfig.endpoints.owner.sitter.applications,
    ],
    loadOwnerSitterInvitations: [
        'OWNER_SITTER_INVITATIONS',
        'LOAD',
        ApiConfig.endpoints.owner.sitter.invitations,
    ],
    loadOwnerSitterPastAssignments: [
        'OWNER_SITTER_PAST_ASSIGNMENTS',
        'LOAD',
        ApiConfig.endpoints.owner.sitter.pastAssignments,
    ],

    // ?????
    loadListingReminders: [
        'OWNER_LISTING_REMINDERS',
        'LOAD',
        ApiConfig.endpoints.owner.listings.listing.reminders._,
    ],
    createListingReminder: [
        'OWNER_LISTING_REMINDERS',
        'CREATE',
        ApiConfig.endpoints.owner.listings.listing.reminders._,
    ],
    updateListingReminder: [
        'OWNER_LISTING_REMINDER',
        'UPDATE',
        ApiConfig.endpoints.owner.listings.listing.reminders.reminder,
    ],
    removeListingReminder: [
        'OWNER_LISTING_REMINDER',
        'REMOVE',
        ApiConfig.endpoints.owner.listings.listing.reminders.reminder,
    ],

    loadFeedbacks: ['OWNER_FEEDBACKS', 'LOAD', ApiConfig.endpoints.owner.feedbacks],
    loadReviews: ['OWNER_REVIEWS', 'LOAD', ApiConfig.endpoints.owner.reviews],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
