import React, { createContext, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as PropTypes from 'prop-types';

export const OwnerFlowContext = createContext(undefined);

export function createInitialState({ location }) {
    return {
        data: {
            ...(location.state ?? {}),
        },
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case 'SAVE':
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        case 'RESET':
            return {
                ...state,
                data: {},
            };
        default:
            return state;
    }
}

const OwnerFlowContextProvider = ({ children }) => {
    const location = useLocation();
    const [state, dispatch] = useReducer(reducer, { location }, createInitialState);
    const [showDebug, setShowDebug] = useState(true);

    return (
        <>
            <OwnerFlowContext.Provider value={[state, dispatch]}>
                {children}
            </OwnerFlowContext.Provider>

            {/* FIXME: DEBUG STUFF */}
            {showDebug ? (
                <div className="fixed bottom-2 left-2 z-20 max-h-44 max-w-56 overflow-y-auto rounded-lg border-solid border-grey-500 bg-utility-white">
                    <div className="flex w-full items-center justify-between gap-x-2 bg-grey-300 px-2">
                        <span className="text-2xs font-bold">DEBUG WINDOW</span>
                        <button
                            type="button"
                            className="border-0 p-0 text-2xs font-bold"
                            onClick={() => setShowDebug(false)}
                        >
                            X
                        </button>
                    </div>
                    <pre className="m-0 px-2 text-2xs">{JSON.stringify(state, null, 1)}</pre>
                </div>
            ) : null}
        </>
    );
};

OwnerFlowContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default OwnerFlowContextProvider;
