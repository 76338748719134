import { createAction } from 'utils/actions';

const prefix = 'SEARCH_LISTINGS';

export const searchFiltersUpdated = createAction(
    prefix,
    'FILTERS_UPDATED',
    'filters',
    'searchMethod'
);
export const mapZoomedOrPanned = createAction(prefix, 'MAP_ZOOMED_OR_PANNED', 'geoBounds');
export const hiddenClustersMapSearch = createAction(
    prefix,
    'HIDDEN_CLUSTERS_MAP_SEARCH',
    'geoBounds'
);
export const mapClustersLoaded = createAction(
    prefix,
    'MAP_CLUSTERS_LOADED',
    'searchType',
    'geohashFacet'
);
export const placeLoaded = createAction(prefix, 'PLACE_LOADED', 'place');
export const saveSearch = createAction(prefix, 'SAVE_SEARCH', 'name');
export const updateSavedSearch = createAction(prefix, 'UPDATE_SAVED_SEARCH', 'savedSearch');
export const deleteSavedSearch = createAction(prefix, 'DELETE_SAVED_SEARCH', 'id', 'name');
export const openSaveSearchDialog = createAction(prefix, 'OPEN_DIALOGSHOW');
export const resetSaveSearch = createAction(prefix, 'RESET_SAVED_SEARCH');
export const hasSavedSearchResponse = createAction(prefix, 'SAVED_SEARCH_RESPONSE');
export const disableScrolling = createAction(prefix, 'DISABLE_SCROLLING');
export const isGeoPoint = createAction(prefix, 'IS_GEO_POINT', 'isGeoPoint');
export const changeSuggestedCategory = createAction(
    prefix,
    'CHANGE_SUGGESTED_CATEGORY',
    'suggestedCategory'
);
export const tryOpenLowApplicantsPrompt = createAction(prefix, 'TRY_OPEN_LOW_APPLICANTS_PROMPT');
export const openLowApplicantsPrompt = createAction(prefix, 'OPEN_LOW_APPLICANTS_PROMPT');
export const closeLowApplicantsPrompt = createAction(prefix, 'CLOSE_LOW_APPLICANTS_PROMPT');
export const scrollToTop = createAction(prefix, 'SCROLL_TO_TOP');
export const searchDiscoveryCategory = createAction(
    prefix,
    'SEARCH_DISCOVERY_CATEGORY',
    'category'
);

export const localSitsABTestVariant = createAction(prefix, 'LOCAL_SITS_AB_TEST_VARIANT', 'variant');
