import paths from './route-paths';
import Refund from './Refund';
import Membership from '.';

export default [
    {
        path: paths.manage,
        exact: true,
        component: Membership,
    },
    {
        path: paths.refund,
        exact: true,
        component: Refund,
    },
];
