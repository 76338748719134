import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dialog from 'components/Dialog';
import Link from 'components/Link';
import Portal from 'components/Portal';
import { routes } from 'utils/routes';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { isPremium, isBasic, isSitterOnly } from 'utils/account';
import { track, Events } from 'utils/analytics';
import TextNode from 'components/TextNode';
import {
    DialogStyled,
    ModalContents,
    ModalTitle,
    ModalSubTitle,
    ModalFooter,
    ButtonStyled,
    BenefitsTitle,
    TitleWrapper,
    BenefitsText,
    StyledBenefitsIcon,
    SitterContainer,
    SitterProfileImg,
    FeatureLinkContainer,
    FeatureLink,
    FeatureExternalLink,
    ImageGrid,
    ImageGridItem,
    ExternalLinkStyled,
    ContactUsContainer,
    ContactUsTitle,
    ContactUsText,
    ContactUsButtonsContainer,
    StyledVisitHelpIcon,
    StyledSendUsMessageIcon,
    TrustPilotContainer,
    TrustpilotMiniStyled,
    ModalAuxText,
    ClosedImageWrapper,
    ClosedImage,
    ModalFooterClosed,
    ButtonCloseStyled,
    SectionWraper,
} from './FreeTrialEndModal.style';
import { closeFreeTrialEndModal } from './actions';
import {
    supportURL,
    contactUsURL,
    standardOwnerLinks,
    premiumOwnerLinks,
    sitterLinks,
} from './constants';
import Sitter1 from './assets/sitter-1.jpg';
import Sitter2 from './assets/sitter-2.jpg';
import Sitter3 from './assets/sitter-3.jpg';
import Sitter4 from './assets/sitter-4.jpg';

import grid1 from './assets/grid-1.jpg';
import grid2 from './assets/grid-2.jpg';
import grid3 from './assets/grid-3.jpg';
import grid4 from './assets/grid-4.jpg';
import grid5 from './assets/grid-5.jpg';
import grid6 from './assets/grid-6.jpg';

import closedImage from './assets/closed-account.jpg';

const trackButtonClick = (description, item) => {
    track(
        Events.BUTTONCLICK.create({
            description,
            item,
        })
    );
};

const trackLinkClick = (description, item) => {
    track(
        Events.LINKCLICK.create({
            description,
            item,
        })
    );
};

const FreeTrialEndModal = ({ membership, isOpen, closeModal, expireUserAccount }) => {
    const { t } = useTranslation();
    const [closedState, setClosedState] = useState(false);
    const [redirectState, setRedirectState] = useState(false);

    const isSitter = isSitterOnly(membership);

    let benefit1Copy = 'cancel_free_trial_modal_benefits_account';
    let benefit2Copy = 'cancel_free_trial_modal_benefits_applications';
    let benefit3Copy = isBasic(membership)
        ? 'cancel_free_trial_modal_benefits_features_basic'
        : 'cancel_free_trial_modal_benefits_features';

    // Basic owners see alternative benefit3Copy and no links
    let linksArray =
        !isBasic(membership) && (isPremium(membership) ? premiumOwnerLinks : standardOwnerLinks);

    if (isSitter) {
        benefit1Copy = 'cancel_free_trial_modal_benefits_sitter_trial_end';
        benefit2Copy = 'cancel_free_trial_modal_benefits_sitter_access';
        benefit3Copy = 'cancel_free_trial_modal_benefits_sitter_protection';
        linksArray = sitterLinks;
    }

    useEffect(() => {
        if (isOpen) track(Events.VIEWED_END_FREE_TRIAL_MODAL.create());
    }, [isOpen]);

    if (redirectState) return <Redirect to={routes.home()} />;

    return (
        <Portal>
            <DialogStyled
                onCloseHandler={() => closeModal()}
                isOpen={isOpen}
                variant={Dialog.Variant.mobileFullscreenDesktopCentered}
                blanketColor="rgba(0,0,0,0.64)"
            >
                {!closedState ? (
                    <>
                        <TitleWrapper>
                            <ModalTitle>{t('cancel_free_trial_modal_top_text')}</ModalTitle>
                        </TitleWrapper>
                        <ModalContents>
                            <ModalSubTitle>
                                {t('cancel_free_trial_modal_are_you_sure')}
                            </ModalSubTitle>

                            {/* Benefits section */}
                            <SectionWraper>
                                <BenefitsTitle>
                                    {t('cancel_free_trial_modal_benefits_title')}
                                </BenefitsTitle>

                                {/* Lost benefits list */}
                                <BenefitsText>
                                    <StyledBenefitsIcon />
                                    <TextNode> {t(benefit1Copy)} </TextNode>
                                </BenefitsText>
                                <BenefitsText>
                                    <StyledBenefitsIcon />
                                    <TextNode> {t(benefit2Copy)} </TextNode>
                                </BenefitsText>
                                {/* Don't show the sitter images for a sitter cancelling trial */}
                                {!isSitter && (
                                    <SitterContainer>
                                        <SitterProfileImg
                                            src={Sitter1}
                                            alt="House Sitter with a dog licking his face"
                                        />
                                        <SitterProfileImg
                                            src={Sitter2}
                                            alt="Sitter with a dog on her arms"
                                        />
                                        <SitterProfileImg
                                            src={Sitter3}
                                            alt="Sitter hugging two cats"
                                        />
                                        <SitterProfileImg
                                            src={Sitter4}
                                            alt="Sitters with a dog in front of a camera"
                                        />
                                    </SitterContainer>
                                )}
                                <BenefitsText>
                                    <StyledBenefitsIcon />
                                    <TextNode> {t(benefit3Copy)} </TextNode>
                                </BenefitsText>

                                {/* Links to benefits */}
                                {linksArray ? (
                                    <FeatureLinkContainer>
                                        {linksArray.map((link) => {
                                            const { textSlug, to, trackLinkClickSlug } = link;
                                            return (
                                                <li key={trackLinkClickSlug}>
                                                    {to ? (
                                                        <FeatureLink
                                                            to={to}
                                                            onClick={() => {
                                                                trackLinkClick(
                                                                    'End Trial Modal CTA',
                                                                    trackLinkClickSlug
                                                                );
                                                                closeModal();
                                                            }}
                                                        >
                                                            {t(textSlug)}
                                                        </FeatureLink>
                                                    ) : (
                                                        <FeatureExternalLink
                                                            onClick={() => {
                                                                trackLinkClick(
                                                                    'End Trial Modal CTA',
                                                                    trackLinkClickSlug
                                                                );
                                                                closeModal();
                                                                window.location.href = link.hrefUrl;
                                                            }}
                                                        >
                                                            {t(textSlug)}
                                                        </FeatureExternalLink>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </FeatureLinkContainer>
                                ) : null}
                            </SectionWraper>

                            {/* Image collage */}
                            <SectionWraper>
                                <ImageGrid>
                                    <ImageGridItem
                                        src={grid1}
                                        style={{ gridArea: 'Image1' }}
                                        alt="Sitter with a dog"
                                    />
                                    <ImageGridItem
                                        src={grid2}
                                        style={{ gridArea: 'Image2' }}
                                        alt="Sitter on a hill with three dogs"
                                    />
                                    <ImageGridItem
                                        src={grid3}
                                        style={{ gridArea: 'Image3' }}
                                        alt="Sitter and dog smiling"
                                    />
                                    <ImageGridItem
                                        src={grid4}
                                        style={{ gridArea: 'Image4' }}
                                        alt="Sitter with a cat"
                                    />
                                    <ImageGridItem
                                        src={grid5}
                                        style={{ gridArea: 'Image5' }}
                                        alt="Sitter with a dog on a garden"
                                    />
                                    <ImageGridItem
                                        src={grid6}
                                        style={{ gridArea: 'Image6' }}
                                        alt="Sitter hug a Donkey while he eats"
                                    />
                                </ImageGrid>
                            </SectionWraper>

                            {/* Help with trial issues */}
                            <SectionWraper>
                                <ContactUsContainer>
                                    <ContactUsTitle>
                                        {t('cancel_free_trial_modal_contact_us_title')}
                                    </ContactUsTitle>
                                    <ContactUsText>
                                        {t('cancel_free_trial_modal_contact_us_text')}
                                    </ContactUsText>
                                    <ContactUsButtonsContainer>
                                        <ExternalLinkStyled
                                            onClick={() => {
                                                trackButtonClick(
                                                    'End Trial Modal CTA',
                                                    'Visit the help centre'
                                                );
                                                closeModal();
                                                window.location.href = supportURL;
                                            }}
                                        >
                                            <StyledVisitHelpIcon />
                                            <TextNode>
                                                {t('cancel_free_trial_modal_help_center_cta')}
                                            </TextNode>
                                        </ExternalLinkStyled>
                                        <ExternalLinkStyled
                                            onClick={() => {
                                                trackButtonClick(
                                                    'End Trial Modal CTA',
                                                    'Send us a message'
                                                );
                                                closeModal();
                                                window.location.href = contactUsURL;
                                            }}
                                        >
                                            <StyledSendUsMessageIcon />
                                            <TextNode>
                                                {t('cancel_free_trial_modal_send_us_message_cta')}
                                            </TextNode>
                                        </ExternalLinkStyled>
                                    </ContactUsButtonsContainer>
                                </ContactUsContainer>
                            </SectionWraper>

                            {/* Trustpilot */}
                            <SectionWraper>
                                <TrustPilotContainer>
                                    <TrustpilotMiniStyled />
                                </TrustPilotContainer>
                            </SectionWraper>

                            {/* Close account / continue trial */}
                            <ModalFooter>
                                <ButtonStyled
                                    variant={Link.Variant.Button.SUBTLE}
                                    onClick={() => {
                                        trackButtonClick('Free Trial Close Account CTA');
                                        expireUserAccount();
                                        setClosedState(true);
                                    }}
                                >
                                    {t('cancel_free_trial_modal_close_account_cta')}
                                </ButtonStyled>
                                <ButtonStyled
                                    variant={Link.Variant.Button.PRIMARY}
                                    onClick={() => {
                                        trackButtonClick('Free Trial Modal Continue Trial CTA');
                                        closeModal();
                                    }}
                                >
                                    {t('cancel_free_trial_modal_continue_trial_cta')}
                                </ButtonStyled>
                            </ModalFooter>
                        </ModalContents>
                    </>
                ) : (
                    <>
                        {/* Closed account confirmation */}
                        <TitleWrapper />
                        <ModalContents>
                            <ModalSubTitle>
                                {t('cancel_free_trial_modal_account_closed')}
                            </ModalSubTitle>
                            <ModalAuxText>
                                {t('cancel_free_trial_modal_account_closed_subtext')}
                            </ModalAuxText>
                            <ClosedImageWrapper>
                                <ClosedImage
                                    src={closedImage}
                                    alt="Drawing of a dog, cat and a bird"
                                />
                            </ClosedImageWrapper>
                        </ModalContents>
                        <ModalFooterClosed>
                            <ButtonCloseStyled
                                variant={Link.Variant.Button.PRIMARY}
                                onClick={() => {
                                    trackButtonClick(
                                        'Free Trial Modal Close Modal After Closed Account'
                                    );
                                    closeModal();
                                    setRedirectState(true);
                                }}
                            >
                                {t('cancel_free_trial_modal_close_button')}
                            </ButtonCloseStyled>
                        </ModalFooterClosed>
                    </>
                )}
            </DialogStyled>
        </Portal>
    );
};

const mapStateToProps = (state) => {
    const { showFreeTrialModal } = state.containers.freeTrialEndModal;

    return {
        membership: getAccountCurrentMembershipPlan(state),
        isOpen: showFreeTrialModal,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    closeModal: () => {
        dispatch(closeFreeTrialEndModal.create());
    },
    expireUserAccount: () => {
        dispatch({
            type: 'FREE_TRIAL_END_MODAL',
        });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeTrialEndModal);
