const countryISOToCurrency = {
    AE: 'AED',
    AF: 'AFN',
    AL: 'ALL',
    AM: 'AMD',
    SX: 'ANG',
    CW: 'ANG',
    AO: 'AOA',
    AR: 'ARS',
    HM: 'AUD',
    CC: 'AUD',
    AU: 'AUD',
    CX: 'AUD',
    TV: 'AUD',
    NR: 'AUD',
    NF: 'AUD',
    KI: 'AUD',
    AW: 'AWG',
    AZ: 'AZN',
    BA: 'BAM',
    BB: 'BBD',
    BD: 'BDT',
    BG: 'BGN',
    BH: 'BHD',
    BI: 'BIF',
    BM: 'BMD',
    BN: 'BND',
    BO: 'BOB',
    BR: 'BRL',
    BS: 'BSD',
    BT: 'BTN',
    BW: 'BWP',
    BY: 'BYR',
    BZ: 'BZD',
    CA: 'CAD',
    CD: 'CDF',
    CH: 'CHF',
    LI: 'CHF',
    CL: 'CLP',
    CN: 'CNY',
    CO: 'COP',
    CR: 'CRC',
    CU: 'CUP',
    CV: 'CVE',
    CZ: 'CZK',
    DJ: 'DJF',
    FO: 'DKK',
    DK: 'DKK',
    GL: 'DKK',
    DO: 'DOP',
    DZ: 'DZD',
    EG: 'EGP',
    ER: 'ERN',
    ET: 'ETB',
    CY: 'EUR',
    GR: 'EUR',
    EE: 'EUR',
    LV: 'EUR',
    LT: 'EUR',
    FI: 'EUR',
    AX: 'EUR',
    XK: 'EUR',
    RE: 'EUR',
    YT: 'EUR',
    TF: 'EUR',
    PT: 'EUR',
    ES: 'EUR',
    MT: 'EUR',
    NL: 'EUR',
    AT: 'EUR',
    BE: 'EUR',
    DE: 'EUR',
    LU: 'EUR',
    IE: 'EUR',
    MC: 'EUR',
    FR: 'EUR',
    AD: 'EUR',
    SK: 'EUR',
    VA: 'EUR',
    SM: 'EUR',
    IT: 'EUR',
    SI: 'EUR',
    ME: 'EUR',
    GF: 'EUR',
    PM: 'EUR',
    MQ: 'EUR',
    MF: 'EUR',
    BL: 'EUR',
    GP: 'EUR',
    FJ: 'FJD',
    FK: 'FKP',
    GB: 'GBP',
    JE: 'GBP',
    IM: 'GBP',
    GG: 'GBP',
    GS: 'GBP',
    GE: 'GEL',
    GH: 'GHS',
    GI: 'GIP',
    GM: 'GMD',
    GN: 'GNF',
    GT: 'GTQ',
    GY: 'GYD',
    HK: 'HKD',
    HN: 'HNL',
    HR: 'HRK',
    HT: 'HTG',
    HU: 'HUF',
    ID: 'IDR',
    IL: 'ILS',
    PS: 'ILS',
    IN: 'INR',
    IQ: 'IQD',
    IR: 'IRR',
    IS: 'ISK',
    JM: 'JMD',
    JO: 'JOD',
    JP: 'JPY',
    KE: 'KES',
    KG: 'KGS',
    KH: 'KHR',
    KM: 'KMF',
    KP: 'KPW',
    KR: 'KRW',
    KW: 'KWD',
    KY: 'KYD',
    KZ: 'KZT',
    LA: 'LAK',
    LB: 'LBP',
    LK: 'LKR',
    LR: 'LRD',
    LS: 'LSL',
    LY: 'LYD',
    EH: 'MAD',
    MA: 'MAD',
    MD: 'MDL',
    MG: 'MGA',
    MK: 'MKD',
    MM: 'MMK',
    MN: 'MNT',
    MO: 'MOP',
    MR: 'MRO',
    MU: 'MUR',
    MV: 'MVR',
    MW: 'MWK',
    MX: 'MXN',
    MY: 'MYR',
    MZ: 'MZN',
    NA: 'NAD',
    NG: 'NGN',
    NI: 'NIO',
    SJ: 'NOK',
    NO: 'NOK',
    BV: 'NOK',
    NP: 'NPR',
    CK: 'NZD',
    NZ: 'NZD',
    PN: 'NZD',
    TK: 'NZD',
    NU: 'NZD',
    OM: 'OMR',
    PA: 'PAB',
    PE: 'PEN',
    PG: 'PGK',
    PH: 'PHP',
    PK: 'PKR',
    PL: 'PLN',
    PY: 'PYG',
    QA: 'QAR',
    RO: 'RON',
    RS: 'RSD',
    RU: 'RUB',
    RW: 'RWF',
    SA: 'SAR',
    SB: 'SBD',
    SC: 'SCR',
    SD: 'SDG',
    SE: 'SEK',
    SG: 'SGD',
    SH: 'SHP',
    SL: 'SLL',
    SO: 'SOS',
    SR: 'SRD',
    SS: 'SSP',
    ST: 'STD',
    SY: 'SYP',
    SZ: 'SZL',
    TH: 'THB',
    TJ: 'TJS',
    TM: 'TMT',
    TN: 'TND',
    TO: 'TOP',
    TR: 'TRY',
    TT: 'TTD',
    TW: 'TWD',
    TZ: 'TZS',
    UA: 'UAH',
    UG: 'UGX',
    IO: 'USD',
    PW: 'USD',
    TL: 'USD',
    MH: 'USD',
    FM: 'USD',
    TC: 'USD',
    VG: 'USD',
    SV: 'USD',
    EC: 'USD',
    MP: 'USD',
    GU: 'USD',
    PR: 'USD',
    VI: 'USD',
    UM: 'USD',
    AS: 'USD',
    US: 'USD',
    BQ: 'USD',
    UY: 'UYU',
    UZ: 'UZS',
    VE: 'VEF',
    VN: 'VND',
    VU: 'VUV',
    WS: 'WST',
    CF: 'XAF',
    CM: 'XAF',
    CG: 'XAF',
    GQ: 'XAF',
    GA: 'XAF',
    TD: 'XAF',
    AI: 'XCD',
    KN: 'XCD',
    DM: 'XCD',
    AG: 'XCD',
    LC: 'XCD',
    VC: 'XCD',
    MS: 'XCD',
    GD: 'XCD',
    SN: 'XOF',
    CI: 'XOF',
    BF: 'XOF',
    TG: 'XOF',
    GW: 'XOF',
    BJ: 'XOF',
    NE: 'XOF',
    ML: 'XOF',
    NC: 'XPF',
    PF: 'XPF',
    WF: 'XPF',
    YE: 'YER',
    ZA: 'ZAR',
    ZM: 'ZMW',
    ZW: 'ZWL',
};

const defaultCountryISO = 'US';

const availableCurrencies = {
    USD: {
        currencyCode: 'USD',
        currencyName: 'US Dollars',
        sign: '$',
    },
    GBP: {
        currencyCode: 'GBP',
        currencyName: 'British Pound Sterling',
        sign: '£',
    },
    EUR: {
        currencyCode: 'EUR',
        currencyName: 'European Union Euro',
        sign: '€',
    },
    AUD: {
        currencyCode: 'AUD',
        currencyName: 'Australian Dollars',
        sign: '$',
    },
    CAD: {
        currencyCode: 'CAD',
        currencyName: 'Canadian Dollars',
        sign: '$',
    },
    NZD: {
        currencyCode: 'NZD',
        currencyName: 'New Zealand Dollars',
        sign: '$',
    },
    SGD: {
        currencyCode: 'SGD',
        currencyName: 'Singapore Dollars',
        sign: '$',
    },
    ARS: {
        currencyCode: 'ARS',
        currencyName: 'Argentinian Pesos',
        sign: '$',
    },
    BRL: {
        currencyCode: 'BRL',
        currencyName: 'Brazilian Real',
        sign: 'R$',
    },
    BGN: {
        currencyCode: 'BGN',
        currencyName: 'Bulgarian Lev',
        sign: 'лв.',
    },
    CLP: {
        currencyCode: 'CLP',
        currencyName: 'Chilean Peso',
        sign: '$',
    },
    CNY: {
        currencyCode: 'CNY',
        currencyName: 'Yuan Renminbi',
        sign: '¥',
    },
    DKK: {
        currencyCode: 'DKK',
        currencyName: 'Danish Krone',
        sign: 'kr.',
    },
    EGP: {
        currencyCode: 'EGP',
        currencyName: 'Egyptian Pounds',
        sign: '£',
    },
    FJD: {
        currencyCode: 'FJD',
        currencyName: 'Fiji Dollars',
        sign: '$',
    },
    HKD: {
        currencyCode: 'HKD',
        currencyName: 'Hong Kong Dollars',
        sign: 'HK$',
    },
    AED: {
        currencyCode: 'AED',
        currencyName: 'UAE Dirham',
        sign: 'AED',
    },
    CHF: {
        currencyCode: 'CHF',
        currencyName: 'Switzerland Franc',
        sign: 'CHF',
    },
    CRC: {
        currencyCode: 'CRC',
        currencyName: 'Costa Rican Colon',
        sign: '₡',
    },
    INR: {
        currencyCode: 'INR',
        currencyName: 'Indian Rupee',
        sign: '₹',
    },
    JPY: {
        currencyCode: 'JPY',
        currencyName: 'Japanese Yen',
        sign: '¥',
    },
    MXN: {
        currencyCode: 'MXN',
        currencyName: 'Mexican Peso',
        sign: '$',
    },
    MYR: {
        currencyCode: 'MYR',
        currencyName: 'Malaysian Ringgit',
        sign: 'RM',
    },
    THB: {
        currencyCode: 'THB',
        currencyName: 'Thai Baht',
        sign: 'THB',
    },
    XCD: {
        currencyCode: 'XCD',
        currencyName: 'East Caribbean Dollar',
        sign: '$',
    },
    ILS: {
        currencyCode: 'ILS',
        currencyName: 'Isreali Shekel',
        sign: '₪',
    },
    ZAR: {
        currencyCode: 'ZAR',
        currencyName: 'South African Rand',
        sign: 'R',
    },
};

export { defaultCountryISO, countryISOToCurrency, availableCurrencies };
