/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { IconCBC, IconIDCheck1 } from 'components/Icon';

export const CallOutWrapper = styled.div(
    ({ theme: { screenSize, spacing }, fixedOrder }) => css`
        flex: 0 0 100%;
        padding-left: ${spacing[2]};
        order: 1;
        margin-top: ${spacing[3.5]};

        ${!fixedOrder
            ? `
            order: 2;
            // 720
            @media (min-width: ${screenSize.mediumTablet}px) {
                order: 3;
            }

            // 1024
            @media (min-width: ${screenSize.mediumDesktop}px) {
                order: 4;
            }

            // 1280
            @media (min-width: ${screenSize.largeDesktop}px) {
                order: 6;
            }
        
        `
            : `
            margin: 0px;
            padding-left: 0px;
        `}
    `
);

export const CallOutContent = styled.div(
    ({ theme: { screenSize, spacing, borderRadius, typography, palette } }) => css`
        width: 100%;
        border-radius: ${borderRadius.regular};
        background-color: ${palette.primarySoftest};
        padding: ${spacing[4]};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: ${palette.textLight};

        // 960
        @media (min-width: ${screenSize.smallDesktop}px) {
            flex-direction: row-reverse;
            justify-content: center;
            ${typography.focalSmall};
            color: ${palette.textLight};
        }
    `
);

const IconStyles = ({ theme: { spacing, screenSize } }) => css`
    flex-shrink: 0;
    margin-left: ${spacing[2]};

    // 960
    @media (min-width: ${screenSize.smallDesktop}px) {
        margin-left: 0;
        margin-right: ${spacing[2]};
    }
`;

export const IconIDCheck1Styled = styled(IconIDCheck1)(
    () => css`
        width: 43px;
        height: 33px;
        ${IconStyles}

        svg {
            height: 32px;
        }
    `
);

export const IconCBCStyled = styled(IconCBC)(
    () => css`
        width: 38px;
        height: 43px;
        ${IconStyles}

        svg {
            height: 48px;
        }
    `
);

export const MobileTextStyled = styled.span(
    ({ theme: { screenSize } }) => css`
        display: inline;

        // 960
        @media (min-width: ${screenSize.smallDesktop}px) {
            display: none;
        }
    `
);

export const DesktopTextStyled = styled.span(
    ({ theme: { screenSize } }) => css`
        display: none;

        // 960
        @media (min-width: ${screenSize.smallDesktop}px) {
            display: inline;
        }
    `
);
