// import { locationName } from 'api/helpers/format/location';

// eslint-disable-next-line import/prefer-default-export
export const SAVED_SEARCH = {
    create(filters, locationSlugs, id) {
        const {
            assignments,
            pets,
            localAttractions,
            homeType,
            familyFriendly,
            wifiAvailable,
            disabledAccess,
            carIncluded,
        } = filters;

        const hasBeach = localAttractions && localAttractions.includes('beach');
        const hasCity = localAttractions && localAttractions.includes('city');
        const hasCountryside = localAttractions && localAttractions.includes('countryside');
        const hasMountain = localAttractions && localAttractions.includes('mountain');

        const hasApartment = homeType && homeType.includes('apartment');
        const hasHouse = homeType && homeType.includes('house');

        const hasHighSpeedWifi = wifiAvailable && wifiAvailable.includes('high speed');

        const isPetIncluded = (pet) => {
            if (pets) {
                const petObj = pets.find((x) => x.type === pet);
                return !petObj.exclude;
            }
            return false;
        };

        const hasBird = isPetIncluded('bird');
        const hasCat = isPetIncluded('cat');
        const hasDog = isPetIncluded('dog');
        const hasFarmAnimal = isPetIncluded('farm animal');
        const hasFish = isPetIncluded('fish');
        const hasHorse = isPetIncluded('horse');
        const hasPoultry = isPetIncluded('poultry');
        const hasReptile = isPetIncluded('reptile');
        const hasSmallPet = isPetIncluded('small pet');

        const payload = {
            admin1Slug: locationSlugs.admin1Slug,
            apartment: hasApartment || false,
            beach: hasBeach || false,
            bird: hasBird || false,
            carIncluded: carIncluded || false,
            cat: hasCat || false,
            city: hasCity || false,
            countrySlug: locationSlugs.countrySlug,
            countryside: hasCountryside || false,
            disabledAccess: disabledAccess || false,
            dog: hasDog || false,
            familyFriendly: familyFriendly || false,
            farmAnimal: hasFarmAnimal || false,
            fish: hasFish || false,
            highSpeedWifi: hasHighSpeedWifi || false,
            horse: hasHorse || false,
            house: hasHouse || false,
            mountain: hasMountain || false,
            poultry: hasPoultry || false,
            reptile: hasReptile || false,
            slug: locationSlugs.slug,
            smallPet: hasSmallPet || false,
            startDate: (assignments && assignments.dateFrom) || null,
            endDate: (assignments && assignments.dateTo) || null,
            durationMin:
                assignments && assignments.durationInDays
                    ? assignments.durationInDays.minimum
                    : null,
            durationMax:
                assignments && assignments.durationInDays
                    ? assignments.durationInDays.maximum
                    : null,
            id,
        };

        return {
            type: 'Saved search',
            payload,
        };
    },
};
