import React from 'react';
import { string } from 'prop-types';
import { ThemeProvider } from 'styled-components';

const baseValue = 1000;

/**
 * Creates a key: value pair object from an array of 2 item arrays.
 * e.g
 * fromPairs([ [1, 2], [2, 3], [3,4] ])
 * -> {
 *  1: 2,
 *  2: 3,
 *  3: 4
 * }
 * @param {array} pairs an array of 2 item arrays.
 */
const fromPairs = (pairs) => {
    const result = pairs.reduce(
        (accum, currentVal) => ({
            ...accum,
            [currentVal[0]]: currentVal[1],
        }),
        {}
    );
    return result;
};

class ZIndexManager extends React.Component {
    getZIndexMap = (layers) => fromPairs(layers.map((label, index) => [label, index]));

    // prettier-ignore
    getLevelIndex = (level, value = 0) => (level * baseValue) + value;

    getNextLevel = (zIndexMap, level, layer) => {
        const obj = {
            zIndexManager: {
                level,
                value: this.getLevelIndex(level, zIndexMap[layer]),
            },
        };
        return obj;
    };

    getTheme =
        (layer) =>
        ({ zIndexManager, zIndexLayers, ...prevTheme }) => {
            const zIndexMap = this.getZIndexMap(zIndexLayers);

            const theme = {
                ...prevTheme,
                zIndexLayers,
                ...this.getNextLevel(
                    zIndexMap,
                    (zIndexManager ? zIndexManager.level : 0) + 1,
                    layer
                ),
            };
            return theme;
        };

    render() {
        const { children, layer } = this.props;
        const theme = this.getTheme(layer);
        return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
    }
}

ZIndexManager.propTypes = {
    layer: string,
};

ZIndexManager.defaultProps = {
    layer: '',
};

export { ZIndexManager as default };
