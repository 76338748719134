import { routes } from 'utils/routes';

const buildOppositeSearchUrl = (root, routeBuildingFn) => (pathname, search) => {
    const pathnameArray = pathname.split('/');
    const [country, region, city] = pathnameArray.filter((item) => item && item !== root);
    const oppositeSearchUrl = routeBuildingFn({
        countrySlug: country,
        admin1Slug: region,
        slug: city,
    });

    return `${oppositeSearchUrl}${search}`;
};

const getSitsPageUrl = (pathname, search) => {
    const buildSitsUrl = buildOppositeSearchUrl('house-and-pet-sitters', routes.search.listings);
    return buildSitsUrl(pathname, search);
};

const getSittersPageUrl = (pathname, search) => {
    const buildSittersUrl = buildOppositeSearchUrl(
        'house-and-pet-sitting-assignments',
        routes.search.profiles
    );
    return buildSittersUrl(pathname, search);
};

const getSearchParamValue = (search, searchParamName) => {
    const URLParams = new URLSearchParams(search);
    return URLParams.get(searchParamName);
};

const getRouteParams = (root, pathname) => {
    const pathnameArray = pathname.split('/');
    const [country, region, city] = pathnameArray.filter((item) => item && item !== root);
    return {
        countrySlug: country,
        admin1Slug: region,
        slug: city,
    };
};

const hasRouteParams = (root, pathname) => {
    const pathnameArray = pathname.split('/');
    const [param] = pathnameArray.filter((item) => item && item !== root);
    return !!param;
};

const getRouteParamsSearchSitters = (pathname) => getRouteParams('house-and-pet-sitters', pathname);

const getRouteParamsSearchSits = (pathname) =>
    getRouteParams('house-and-pet-sitting-assignments', pathname);

export {
    getSearchParamValue,
    getSitsPageUrl,
    getSittersPageUrl,
    hasRouteParams,
    getRouteParamsSearchSitters,
    getRouteParamsSearchSits,
};
