import ApiConfig, { ACTIONS_CONFIG_CACHE } from '../config';
import { AVAILABLE_ACTION_STATUSES, HTTP_VERB_MAPPING } from './constants';

const generateOptionalFieldsEnum = (optionalFields) => {
    const fields = {};
    const enums = {};

    let index = 1;
    if (optionalFields && optionalFields.length > 0) {
        optionalFields.forEach((field) => {
            fields[field] = index;
            enums[index] = field;
            index *= 2;
        });
    }
    return { fields, enums };
};

/**
 * Action creator
 */
export default (
    actions,
    settings,
    name,
    module,
    action,
    endpoint,
    optionalFields,
    verb,
    data,
    defaultData,
    dataValidTime,
    keyGenerator
) => {
    const actionStatuses = {};
    Object.keys(AVAILABLE_ACTION_STATUSES).reduce((statuses, status) => {
        statuses[status] = `${ApiConfig.actionsPrefix}/${module}/${action}/$${status}$`;
        return statuses;
    }, actionStatuses);

    // Generate optional fields enum
    const { fields, enums } = generateOptionalFieldsEnum(optionalFields);

    /**
     * Action
     * @param kwargs {{forceReload}, {data}}
     */
    actions[name] = ({ ...kwargs }) => ({
        ...kwargs,
        type: actionStatuses.REQUEST,
        data: {
            ...defaultData,
            ...kwargs.data,
            ...data,
        },
    });

    // Create action settings
    const actionSetting = {
        ...actionStatuses,
        module,
        action,
        endpoint,
        dataValidTime,
        fields,
        keyGenerator,
        fieldsEnums: enums,
        verb: verb || HTTP_VERB_MAPPING[action],
    };
    settings[name] = actionSetting;
    ACTIONS_CONFIG_CACHE[actionStatuses[AVAILABLE_ACTION_STATUSES.REQUEST]] = actionSetting;
};
