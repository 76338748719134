import React from 'react';
import { MembershipType } from 'api/types';

import {
    UnlockSittersIconStyled,
    UnlockSittingIconStyled,
    UnlockSittersSmallIconStyled,
    UnlockSittingSmallIconStyled,
} from './UpgradeMembership.style';

const UNLOCK_COPY = {
    [MembershipType.SITTER]: {
        header: 'containers_upgradeMembership_unlock_sitters_header',
        body: 'containers_upgradeMembership_unlock_sitters_body',
        icon: () => <UnlockSittersIconStyled />,
        iconSmall: () => <UnlockSittersSmallIconStyled />,
    },
    [MembershipType.OWNER]: {
        header: 'containers_upgradeMembership_unlock_sits_header',
        body: 'containers_upgradeMembership_unlock_sits_body',
        icon: () => <UnlockSittingIconStyled />,
        iconSmall: () => <UnlockSittingSmallIconStyled />,
    },
};

const UPGRADE_MEMBERSHIP_DISMISSED_KEY = 'UPGRADE_BANNER_DISMISSED';

const UpgradeMembershipTestIds = {
    header: 'UpgradeMembership_header',
    body: 'UpgradeMembership_body',
    iWantCTA: 'UpgradeMembership_i_want_cta',
    dontWantButton: 'UpgradeMembership_dont_want_button',
};

export { UNLOCK_COPY, UPGRADE_MEMBERSHIP_DISMISSED_KEY, UpgradeMembershipTestIds };
