import { settings } from 'api/actions';

const defaultState = {
    saveAddressUpdateId: 0,
    saveAddressResponseStatus: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case settings.owner.createListingAddress.DONE:
        case settings.owner.updateListingAddress.DONE: {
            return {
                saveAddressUpdateId: state.saveAddressUpdateId + 1,
                saveAddressResponseStatus: action.statusCode,
            };
        }

        default:
            return state;
    }
};
