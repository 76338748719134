import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { retry } from 'utils/ssr';
import asPage from 'containers/Page';
import { getSeoContent } from 'src/shared/selectors';
import { PAGE_ID } from './constants';
import { getCategory, getSubcategory, getSubcategoryPosts } from './selectors';

const Subcategory = loadable(() => retry(() => import('./Subcategory')));

export const mapStateToProps = (state) => ({
    category: getCategory(state),
    subcategory: getSubcategory(state),
    posts: getSubcategoryPosts(state),
    seoContent: getSeoContent(state),
});

export default connect(mapStateToProps)(asPage(Subcategory, PAGE_ID, true, true));
