import { useState, useEffect } from 'react';

const defaultOptions = {
    async: false,
    defer: true,
};

// Store loadedable scripts cached info
const cacheLoaded = {};
const cacheError = {};

function useScript(src, options = defaultOptions) {
    const [state, setState] = useState({ loaded: false, error: false });

    // React Hook useEffect has a complex expression in the dependency array, hence a variable
    // that can be statically checked is needed
    const cachedSourceCheck = cacheLoaded[src];

    useEffect(() => {
        // If script already exists then don't load again.
        const cachedSource = cacheLoaded[src];
        // script is loaded but it could be errant
        if (cachedSource) {
            setState({
                loaded: cachedSource,
                error: cacheError[src],
            });
        }
        // check if the script is in DOM
        else if (!document.querySelector(`script[src='${src}']`)) {
            cacheLoaded[src] = false;
            cacheError[src] = false;

            // Create script
            const script = document.createElement('script');

            // Set options
            Object.entries({ ...defaultOptions, ...options }).forEach(([key, value]) => {
                script[key] = value;
            });

            // Set `src` after setting options to override in case of passing it down
            script.src = src;

            // on load
            script.onload = () => {
                cacheLoaded[src] = true;
                cacheError[src] = false;

                setState({
                    loaded: cacheLoaded[src],
                    error: cacheError[src],
                });
            };

            // on error
            script.onerror = () => {
                // Remove from cache for re-try
                delete cacheLoaded[src];
                delete cacheError[src];

                setState({
                    loaded: true,
                    error: true,
                });
            };

            // Add script to document body
            document.body.appendChild(script);
        }
        // Re-run if the source or cache changes
    }, [src, cachedSourceCheck, options]);

    return [state.loaded, state.error];
}

export default useScript;
