import { createAction } from 'utils/actions';

const prefix = 'EDIT_LISTING_DATES';

export const saveDates = createAction(prefix, 'DATES_SAVED', 'newDates', 'updatedDates');
export const saveDatesDONE = createAction(prefix, 'DATES_SAVED_DONE', 'status');

export const deleteDate = createAction(prefix, 'DATE_DELETED', 'assignment');
export const deleteDateDONE = createAction(prefix, 'DATE_DELETED_DONE', 'status');

export const loadOpenAssignmentsSUCCESS = createAction(prefix, 'LOAD_OPEN_ASSIGNMENTS_SUCCESS');

export const boostAssignment = createAction(prefix, 'BOOST_ASSIGNMENT', 'assignment');
