import styled from 'styled-components';
import { spanStyles } from 'typography';

export const MainContainerStyled = styled.div`
    border: 1px solid transparent;

    &[data-status='filled'] {
        border-width: 0;
    }
`;

export const CounterContainer = styled.div`
    ${spanStyles}
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid ${({ theme }) => theme.palette.border};
    border-radius: 10px;
    background-color: ${({ theme }) => theme.palette.white};

    padding: ${({ theme }) =>
        `${theme.spacing[1.5]} ${theme.spacing[1.5]} ${theme.spacing[1.5]} ${theme.spacing[2.5]}`};

    ${MainContainerStyled}[data-status='filled'] & {
        background-color: ${({ theme }) => theme.palette.primarySoftest};
        border: 2px solid ${({ theme }) => theme.palette.primaryDark};
        transition: background-color 250ms ease-in-out;

        * {
            color: ${({ theme }) => theme.palette.primaryDark};
            fill: ${({ theme }) => theme.palette.primaryDark};
        }
    }
`;
