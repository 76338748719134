import React, { useMemo, useRef } from 'react';
import { renderRoutes } from 'react-router-config';
import { Button, Link } from '@ths/design-system';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { SVGComponent as BrandRefreshLogoIcon } from 'components/Logo/brandRefreshLogoIcon.svg';
import { SVGComponent as BrandRefreshLogoFull } from 'components/Logo/brandRefreshLogoFull.svg';
import { track, Events } from 'utils/analytics';

const handleQuestionClick = () => {
    if (typeof document !== 'undefined') {
        const button = document.querySelector('#ths-zendesk-help-button');
        button?.click();
    }
};

const LayoutWithNavbar = ({ route, location }) => {
    const element = useMemo(
        () => renderRoutes(route.routes, {}, { location }),
        [route.routes, location]
    );
    const currentRoute = useMemo(
        () => route.routes.find((r) => r.path === location.pathname),
        [route.routes, location.pathname]
    );
    const elementRef = useRef(null);
    const segmentRef = useRef(null);

    const handleLogoClick = () => {
        track(
            Events.BUTTONCLICK.create({
                description: 'THS Logo nav bar',
                name: 'link click',
            })
        );
    };

    const handleLoginClick = () => {
        track(
            Events.BUTTONCLICK.create({
                description: 'Owner Acquisition Flow',
                item: 'Log in',
            })
        );
    };

    return (
        <div className="w-screen md:h-screen">
            <div className="h-full w-full pb-24 pt-18 md:grid md:grid-cols-2 md:pb-0 md:pt-0">
                <div className="fixed left-0 top-0 z-10 flex w-full justify-between bg-utility-white p-6 md:hidden">
                    <Link to="/" onClick={handleLogoClick}>
                        <BrandRefreshLogoIcon />
                    </Link>
                    <div>
                        <Button
                            variant="grey"
                            size="sm"
                            className="p-4 text-grey-500"
                            onClick={handleQuestionClick}
                        >
                            Questions?
                        </Button>
                        <Link
                            isButtonStyle
                            variant="grey"
                            size="sm"
                            className="text-grey-500"
                            to="/login"
                            onClick={handleLoginClick}
                        >
                            Login
                        </Link>
                    </div>
                </div>
                <div className="relative flex h-full w-full flex-col pb-18 md:overflow-y-auto md:pb-12">
                    <Link
                        className="mb-8 hidden shrink-0 flex-grow-0 p-9 md:block"
                        to="/"
                        onClick={handleLogoClick}
                    >
                        <BrandRefreshLogoFull />
                    </Link>
                    <SwitchTransition>
                        <CSSTransition
                            key={location.pathname}
                            nodeRef={elementRef}
                            timeout={250}
                            unmountOnExit
                            classNames={{
                                enter: 'opacity-0',
                                enterActive: 'opacity-100 transition-opacity',
                                exit: 'opacity-100',
                                exitActive: 'opacity-0 transition-opacity',
                            }}
                        >
                            <div
                                ref={elementRef}
                                className="w-full px-6 md:my-auto md:px-9 lg:px-24"
                            >
                                {element}
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
                <div className="relative h-full w-full">
                    <div className="absolute right-6 top-14 hidden gap-x-4 md:flex">
                        <Button variant="subtle" onClick={handleQuestionClick}>
                            Questions?
                        </Button>
                        <Link isButtonStyle variant="subtle" to="/login" onClick={handleLoginClick}>
                            Log in
                        </Link>
                    </div>
                    <SwitchTransition>
                        <CSSTransition
                            key={location.pathname}
                            nodeRef={segmentRef}
                            timeout={250}
                            unmountOnExit
                            classNames={{
                                enter: 'opacity-0',
                                enterActive: 'opacity-100 transition-opacity',
                                exit: 'opacity-100',
                                exitActive: 'opacity-0 transition-opacity',
                            }}
                        >
                            {typeof currentRoute?.handle?.segment !== 'undefined' ? (
                                <div ref={segmentRef} className="h-full w-full">
                                    <currentRoute.handle.segment />
                                </div>
                            ) : null}
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </div>
        </div>
    );
};

export default LayoutWithNavbar;
