import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import actions, { settings } from 'api/actions';
import { getSitemapPages, getSitemapLocations } from 'api/selectors/search';
import * as pageActions from 'containers/Page/actions';
import { PAGE_ID } from './Sitemap.constants';
import { getSitemapSearchType, getSitemapSearchLevel, getPageNumber } from './utils';

export function* handleSitemapPagesFetch(type) {
    const pages = yield select(getSitemapPages, type);

    if (!pages) {
        yield put(
            actions.search.loadSitemapPages({
                forceReload: true,
                data: {
                    type,
                },
            })
        );

        const { status, statusCode } = yield take(settings.search.loadSitemapPages.DONE);

        if (status !== settings.search.loadSitemapPages.SUCCESS && statusCode !== 404) {
            const err = new Error(`Failed to load sitemap pages. Status code: ${statusCode}`);
            Sentry.captureException(err);
        }
    }
}

export function* handleSitemapLocationsFetch(type, level, page) {
    const pageLocations = yield select(getSitemapLocations, type, level, page);

    if (!pageLocations) {
        yield put(
            actions.search.loadSitemapLocations({
                forceReload: true,
                filters: {
                    page,
                },
                data: {
                    type,
                    level,
                },
            })
        );

        const { status, statusCode } = yield take(settings.search.loadSitemapLocations.DONE);

        if (status !== settings.search.loadSitemapLocations.SUCCESS && statusCode !== 404) {
            const err = new Error(`Failed to load sitemap locations. Status code: ${statusCode}`);
            Sentry.captureException(err);
        }
    }
}

export function* load({ type, params }) {
    const sitemapSearchType = getSitemapSearchType(params?.type);
    const level = getSitemapSearchLevel(params?.level);
    const pageNumber = getPageNumber(params?.page);

    yield all([
        call(handleSitemapPagesFetch, sitemapSearchType),
        call(handleSitemapLocationsFetch, sitemapSearchType, level, pageNumber),
    ]);

    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(PAGE_ID));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(PAGE_ID));
    }
}

export default function* saga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            load
        ),
    ]);
}
