import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from '@ths/design-system';
import WorkableWidget from './WorkableWidget';

function Workable({ heading }) {
    return (
        <div className="mx-auto max-w-[960px] px-6">
            {heading ? <H2 color="primary">{heading}</H2> : null}
            <WorkableWidget />
        </div>
    );
}

Workable.defaultProps = {
    heading: null,
};

Workable.propTypes = {
    heading: PropTypes.string,
};

export default Workable;
