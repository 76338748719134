import { all, put, take, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    load as pageActionsLoad,
    preload as pageActionsPreload,
    error as errorAction,
    preloaded as pageActionsPreloaded,
    loaded as pageActionsLoaded,
} from 'containers/Page/actions';
import apiActions, { settings } from 'api/actions';
import { track, Events } from 'utils/analytics';
import { PAGE_ID } from './Preferences.constants';
import { saveProfile as saveProfileAction } from './actions';

function* loadData() {
    yield put(apiActions.places.loadCountries());
    yield put(
        apiActions.sitter.loadProfile({
            forceReload: true,
        })
    );

    // Wait for results
    const [loadCountriesResponse, loadProfileResponse] = yield all([
        take((res) => res.type === settings.places.loadCountries.DONE),
        take((res) => res.type === settings.sitter.loadProfile.DONE),
    ]);

    if (loadCountriesResponse.status !== settings.places.loadCountries.SUCCESS) {
        // An error occurred loading countries
        yield put(errorAction.create(PAGE_ID, loadCountriesResponse.statusCode));
        return false;
    }

    if (loadProfileResponse.status !== settings.sitter.loadProfile.SUCCESS) {
        // An error occurred loading the profile
        yield put(errorAction.create(PAGE_ID, loadProfileResponse.statusCode));
        return false;
    }

    yield put(pageActionsPreloaded.create(PAGE_ID));
    return true;
}

export function* load(action) {
    yield call(loadData, action.params, PAGE_ID);
    yield put(pageActionsLoaded.create(PAGE_ID));
}

export function* preload(action) {
    yield call(loadData, action.params, PAGE_ID);
    yield put(pageActionsPreloaded.create(PAGE_ID));
}

export function* saveProfile(action) {
    const { profile } = action;

    yield put(
        apiActions.sitter.updateProfile({
            forceReload: true,
            data: profile,
        })
    );
    const updateProfileResponse = yield take(
        (res) => res.type === settings.sitter.updateProfile.DONE
    );

    if (updateProfileResponse.status === settings.sitter.updateProfile.SUCCESS) {
        yield call(track, Events.USER_PROFILE_EDIT_PREFERENCES.create(profile));

        // Reload the profile so we don't have stale data on the hub page or
        // if the user tries to come back here quickly
        yield put(
            apiActions.sitter.loadProfile({
                forceReload: true,
            })
        );

        yield take((res) => res.type === settings.sitter.loadProfile.DONE);

        return true;
    }

    return false;
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeLatest(saveProfileAction.ACTION, saveProfile),
    ]);
}
