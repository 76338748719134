import styled, { css } from 'styled-components';
import { IconReview, IconReviewOutline } from 'components/Icon';

const sizes = {
    sm: css`
        font-size: 1rem;

        &:not(:last-child) {
            margin-right: ${({ theme }) => theme.spacing[0.5]};
        }
    `,
    md: css`
        font-size: 1.25rem;

        &:not(:last-child) {
            margin-right: ${({ theme }) => theme.spacing[1]};
        }
    `,
    lg: css`
        font-size: 2.5rem;

        &:not(:last-child) {
            margin-right: ${({ theme }) => theme.spacing[2]};
        }
    `,
};

export const WrapperStyled = styled.div`
    display: flex;
    align-items: center;
`;

export const StarStyled = styled(IconReview)`
    & svg {
        fill: ${({ theme }) => theme.palette.grey[700]};
    }

    ${({ size }) => (typeof size === 'string' ? sizes[size] : null)};
`;

export const StarOutlineStyled = styled(IconReviewOutline)`
    & svg {
        fill: ${({ theme }) => theme.palette.grey[500]};
    }

    ${({ size }) => (typeof size === 'string' ? sizes[size] : null)};
`;
