/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const ComboboxStyled = styled.div`
    position: relative;

    width: 100%;
`;

export { ComboboxStyled };
