import { combineReducers } from 'redux';
import searchListings from './SearchListings/reducers';
import listingDetail from './ListingDetail/reducers';
import searchProfiles from './SearchProfiles/reducers';
import profileDetail from './ProfileDetail/reducers';
import savedSearchRedirect from './SavedSearchRedirect/reducers';
import dynamicFacets from './components/DynamicFacets/reducer';

export default combineReducers({
    searchListings,
    listingDetail,
    profileDetail,
    searchProfiles,
    savedSearchRedirect,
    dynamicFacets,
});
