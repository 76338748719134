import { all, call, put, take, takeEvery, select } from 'redux-saga/effects';

import apiActions, { settings } from 'api/actions';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';

import { hasOwner } from 'utils/account';

import {
    preload as pageActionsPreload,
    preloaded as pageActionsPreloaded,
    load as pageActionsLoad,
    loaded as pageActionsLoaded,
    error as errorAction,
} from 'containers/Page/actions';
import { PAGE_ID, paginationTotalItems } from './SavedYourListing.constants';

/**
 * Loads all owner favourites
 */
export function* loadOwnerFavourites() {
    const membership = yield select(getAccountCurrentMembershipPlan);

    if (hasOwner(membership)) {
        yield put(apiActions.owner.loadFavourites());
    }
}

export function* loadSavedYourListing(action, type) {
    const page = (action.search.page && parseInt(action.search.page, 10)) || 1;

    yield put(
        apiActions.search.loadProfilesFavouritedYou({
            ...(type === pageActionsLoad.ACTION && { forceReload: true }),
            filters: {
                query: JSON.stringify({
                    facets: {},
                    sort: [{ function: { name: 'ORDEREDINDEX', order: 'desc' } }],
                    filters: {},
                    resultsPerPage: paginationTotalItems,
                    page,
                }),
            },
        })
    );

    // Wait for results
    const { status, statusCode } = yield take(settings.search.loadProfilesFavouritedYou.DONE);

    if (status !== settings.search.loadProfilesFavouritedYou.SUCCESS) {
        // Error occurred
        yield put(errorAction.create(PAGE_ID, statusCode));
        return;
    }

    yield call(loadOwnerFavourites);

    // Pre/loaded page action when successful
    if (type === pageActionsPreload.ACTION) {
        yield put(pageActionsPreloaded.create(PAGE_ID));
    } else if (type === pageActionsLoad.ACTION) {
        yield put(pageActionsLoaded.create(PAGE_ID));
    }
}

export function* preload(action) {
    yield call(loadSavedYourListing, action, pageActionsPreload.ACTION);
}

export function* load(action) {
    yield call(loadSavedYourListing, action, pageActionsLoad.ACTION);
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            load
        ),
    ]);
}
