import React from 'react';

import Redirect from 'components/Redirect';
import Page from '.';
import HappiestPetsCompetition2024 from './pages/HappiestPetsCompetition2024';
import paths from './route-paths';

export default [
    {
        path: paths.insuranceBackedGuarentee,
        exact: false,
        component: ({ staticContext }) => (
            <Redirect to={paths.homeContentsProtection} context={staticContext} />
        ),
    },
    {
        component: HappiestPetsCompetition2024,
        path: paths.happiestPetsCompetition2024,
        exact: true,
    },
    {
        component: Page,
        path: paths.pageOnSitters,
        exact: true,
    },
    {
        component: Page,
        path: paths.pageOnHouseAssignments,
        exact: true,
    },
    {
        component: Page,
        path: paths.page,
        exact: true,
    },
    {
        component: Page,
        path: paths.pageOnRoot,
        exact: true,
    },
];
