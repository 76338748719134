import styled, { css, keyframes } from 'styled-components';

const cycleAnimation = keyframes`
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
`;

const TrustpilotMiniStyled = styled.div`
    max-width: 420px;
    height: 40px;

    ${({ isLoading }) =>
        isLoading &&
        css`
            position: relative;
            background-color: ${({ theme }) => theme.palette.grey[300]};
            overflow: hidden;

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 0) 0,
                    rgba(255, 255, 255, 0.2) 20%,
                    rgba(255, 255, 255, 0.5) 60%,
                    rgba(255, 255, 255, 0)
                );
                content: '';
                animation: ${cycleAnimation} 1.75s infinite;
            }
        `}

    // Hardcoding the same breakpoint Trustpilot uses
    // Forgive the !important - the Trustpilot widget uses inline styles
    & > iframe {
        height: 40px !important;
    }

    @media screen and (min-width: 480px) {
        height: 20px;

        & > iframe {
            // Hardcoding the same breakpoint Trustpilot uses
            // Forgive the !important - the Trustpilot widget uses inline styles
            height: 20px !important;
        }
    }
`;

export default TrustpilotMiniStyled;
