import React from 'react';
import { Heading2, Heading3 } from 'typography';
import loadable from '@loadable/component';
import { MembershipType } from 'api/types/membershipTypes';
import { retry } from 'utils/ssr';
import MultiCallToAction from 'components/content/prismicSlices/MultiCallToAction';
import {
    ContainerStyled,
    FullWidthPanelStyled,
    PanelStyled,
    PStyled,
    PVideoStyled,
    VideoPanelStyled,
} from 'components/content/prismicSlices/common.style';
import { linkResolver } from 'components/content/contentHelpers';
import { HERO_LAYOUT } from 'components/content/prismicSlices/Hero/Hero.constants';
import HeroManager from 'components/content/prismicSlices/HeroManager';
import HowItWorks from 'components/content/prismicSlices/HowItWorks';
import ResourceLinks from 'components/content/prismicSlices/ResourceLinks';
import Workable from 'components/content/prismicSlices/Workable';

const LoadableHero = loadable(() => retry(() => import('components/content/prismicSlices/Hero')));
const LoadableRichText = loadable(() =>
    retry(() => import('components/content/prismicSlices/RichText'))
);
const LoadableDivider = loadable(() =>
    retry(() => import('components/content/prismicSlices/Divider'))
);
const LoadableBlockquote = loadable(() =>
    retry(() => import('components/content/prismicSlices/Blockquote'))
);
const LoadableTextImage = loadable(() =>
    retry(() => import('components/content/prismicSlices/TextImage'))
);
const LoadablePostCallToAction = loadable(() =>
    retry(() => import('components/content/prismicSlices/PostCallToAction'))
);
const LoadableImageWithCaption = loadable(() =>
    retry(() => import('components/content/prismicSlices/ImageWithCaption'))
);
const LoadablePricing = loadable(() =>
    retry(() => import('components/content/prismicSlices/Pricing'))
);
const LoadableInlineRegwall = loadable(() =>
    retry(() => import('components/content/prismicSlices/InlineRegwall'))
);
const LoadableFlexibleColumns = loadable(() =>
    retry(() => import('components/content/prismicSlices/FlexibleColumns'))
);
const LoadableSingleColumnVideo = loadable(() =>
    retry(() => import('components/content/prismicSlices/SingleColumnVideo'))
);
const LoadableFAQ = loadable(() => import('components/content/prismicSlices/FAQ'));

const richTextProps = {
    P: PStyled,
    H2: Heading2,
    H3: Heading3,
};

const Page = ({ data }) => {
    const body = data.body.map((slice, index) => {
        const key = `${slice.sliceType}.${index}`;

        switch (slice.sliceType) {
            case 'flexiblecolumns': {
                return (
                    <FullWidthPanelStyled key={key} hasMaxWidth={false}>
                        <LoadableFlexibleColumns slice={slice} />
                    </FullWidthPanelStyled>
                );
            }
            case 'faqs':
                return <LoadableFAQ key={key} slice={slice} />;
            case 'hero': {
                const { heading, subheading, ctaType, ctaText, ctaUrl, image, variant } =
                    slice.primary;
                return (
                    <PanelStyled
                        hasMaxWidth={false}
                        fullWidthUntil={variant === HERO_LAYOUT.FULL_WIDTH_IMAGE ? '722' : ''}
                        key={key}
                    >
                        <LoadableHero
                            key={key}
                            heading={heading}
                            variant={variant}
                            subheading={subheading}
                            mobileImageSrc={image.url}
                            desktopImageSrc={image.url}
                            ctaType={ctaType}
                            ctaText={ctaText}
                            ctaUrl={ctaUrl}
                        />
                    </PanelStyled>
                );
            }
            case 'hero_with_variants': {
                return <HeroManager data={slice.primary} pageTitle={data.shortTitle} />;
            }
            case 'how_it_works': {
                return (
                    <HowItWorks
                        title={slice.primary.title}
                        howItWorksLink={{
                            text: slice.primary.howItWorksLinkText,
                            url: slice.primary.howItWorksLink,
                        }}
                        steps={slice.items}
                        backgroundColor={slice.primary.bgColor}
                    />
                );
            }
            case 'resource_links': {
                return (
                    <ResourceLinks
                        backgroundColor={slice.primary.backgroundColor}
                        heading={slice.primary.heading}
                        subheading={slice.primary.subheading}
                    />
                );
            }
            case 'text':
                return (
                    <PanelStyled key={key}>
                        <ContainerStyled>
                            <LoadableRichText
                                richText={slice.primary.text}
                                linkResolver={linkResolver}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...richTextProps}
                            />
                        </ContainerStyled>
                    </PanelStyled>
                );
            case 'divider':
                return (
                    <ContainerStyled key={key}>
                        <LoadableDivider key={key} />
                    </ContainerStyled>
                );
            case 'quote':
                if (slice.primary.quote.length === 0) return null;
                return (
                    <PanelStyled key={key}>
                        <ContainerStyled>
                            <LoadableBlockquote>{slice.primary.quote[0].text}</LoadableBlockquote>
                        </ContainerStyled>
                    </PanelStyled>
                );
            case 'image_with_caption':
                return (
                    <LoadableImageWithCaption
                        key={key}
                        alignment={slice.primary.alignment}
                        image={slice.primary.image}
                        caption={slice.primary.caption}
                        link={slice.primary.link?.url}
                    />
                );
            case 'video': {
                const { title, embeddedMedia, ctaText, ctaUrl, ctaColor, backgroundColor, text } =
                    slice.primary;
                return (
                    <VideoPanelStyled
                        key={key}
                        backgroundColor={backgroundColor}
                        hasMaxWidth={false}
                    >
                        <ContainerStyled>
                            <LoadableSingleColumnVideo
                                title={title}
                                url={embeddedMedia}
                                ctaText={ctaText}
                                ctaUrl={ctaUrl}
                                ctaColor={ctaColor}
                                backgroundColor={backgroundColor}
                                text={text}
                                linkResolver={linkResolver}
                                richTextProps={{ ...richTextProps, P: PVideoStyled }}
                            />
                        </ContainerStyled>
                    </VideoPanelStyled>
                );
            }
            case 'call_to_action': {
                const { ctaTitle, ctaBody, alignment, ctaText, ctaStyle, ctaUrl, backgroundColor } =
                    slice.primary;

                // Default to using the "old" style with non-repeatable CTAs
                // if we have text for that cta
                const hasPrimaryCTA = slice.primary && slice.primary.ctaText;

                if (hasPrimaryCTA) {
                    return (
                        <LoadablePostCallToAction
                            key={key}
                            ctaTitle={ctaTitle}
                            ctaText={ctaText}
                            ctaStyle={ctaStyle}
                            ctaUrl={ctaUrl}
                            alignment={alignment}
                            backgroundColor={backgroundColor}
                        />
                    );
                }

                return (
                    <MultiCallToAction
                        key={key}
                        richTextProps={richTextProps}
                        alignment={alignment}
                        ctaTitle={ctaTitle}
                        ctaBody={ctaBody}
                        ctaItems={slice.items}
                        backgroundColor={backgroundColor}
                    />
                );
            }
            case 'image_with_text':
                return (
                    <PanelStyled key={key}>
                        <ContainerStyled>
                            <LoadableTextImage
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...slice.primary}
                                linkResolve={linkResolver}
                                richTextProps={richTextProps}
                            />
                        </ContainerStyled>
                    </PanelStyled>
                );
            case 'pricing':
                return (
                    <FullWidthPanelStyled key={key} hasMaxWidth={false}>
                        <span id="price">
                            <LoadablePricing
                                cards={slice.items}
                                sectionTitle={slice.primary.sectionTitle}
                                mainCtaText={slice.primary.mainCtaText}
                                mainCtaLink={slice.primary.mainCtaLink}
                            />
                        </span>
                    </FullWidthPanelStyled>
                );
            case 'regwall':
                return (
                    <span id="signup" key={key}>
                        <LoadableInlineRegwall
                            title={slice.primary.title}
                            selectedMembershipTypes={{
                                [MembershipType.OWNER]: slice.primary.showOwnerMembershipType,
                                [MembershipType.SITTER]: slice.primary.showSitterMembershipType,
                                [MembershipType.COMBINED]: slice.primary.showCombinedMembershipType,
                            }}
                            ctaText={slice.primary.ctaText}
                            ownerUrl={slice.primary.redirectOwnerMembershipType}
                            sitterUrl={slice.primary.redirectSitterMembershipType}
                            combinedUrl={slice.primary.redirectCombinedMembershipType}
                            redirectCtaText={slice.primary.redirectCtaText}
                            redirectCtaHeader={slice.primary.redirectCtaHeader}
                        />
                    </span>
                );
            case 'workable_widget':
                return <Workable heading={slice.primary.heading} />;
            default:
                return null;
        }
    });

    return <article>{body}</article>;
};

export default Page;
