import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getMembershipStatus } from 'utils/account';
import { getAccount, getAccountCurrentMembershipPlan } from 'api/selectors';

import CardExpiryNotification from './CardExpiryNotification';

const mapStateToProps = (state) => ({
    membershipPlan: getAccountCurrentMembershipPlan(state),
    membershipStatus: getMembershipStatus(getAccountCurrentMembershipPlan(state)),
    cardDetails: getAccount(state).card,
});

const ConnectedCardExpiryNotification = withRouter(
    connect(mapStateToProps)(withTranslation()(CardExpiryNotification))
);

export default ConnectedCardExpiryNotification;
