/* eslint-disable import/prefer-default-export */
import { all, call, put, take, takeEvery, select, fork } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import {
    error as errorAction,
    preload as pageActionsPreload,
    load as pageActionsLoad,
    preloaded as pageActionsPreloaded,
    loaded as pageActionsLoaded,
} from 'containers/Page/actions';
import { getMembershipType } from 'utils/account';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { MembershipType } from 'api/types';

import dateListItemSagas from './components/ListingSection/components/DateList/components/DateListItem/sagas';
import { PAGE_ID } from './Dashboard.constants';

export function* loadListings() {
    yield put(apiActions.owner.loadListings({ forceReload: true }));

    // Wait for results
    const { data, status, statusCode } = yield take(
        (res) => res.type === settings.owner.loadListings.DONE
    );

    if (status === settings.owner.loadListings.SUCCESS) {
        const listingId = data && data.length ? data[0].id : null;

        if (listingId) {
            yield all([
                put(
                    apiActions.owner.loadListingFutureAssignments({
                        forceReload: true,
                        data: {
                            id: listingId,
                            listingId,
                        },
                    })
                ),
                put(
                    apiActions.owner.loadCurrentAssignments({
                        data: {},
                    })
                ),
            ]);
        }

        return true;
    }
    // Error occurred
    yield put(errorAction.create(PAGE_ID, statusCode));
    return false;
}

export function* loadDashboardData(action) {
    const membershipPlan = yield select(getAccountCurrentMembershipPlan);
    const membershipType = getMembershipType(membershipPlan);
    // The successfulAction will only be dispatched if we successfully load data.
    // If an error is received whilst loading data then an errorAction is
    // dispatched instead (the errorAction is dispatched where the error occurred).
    let dispatchSuccessfulAction = true;

    if (membershipType !== MembershipType.SITTER) {
        // If the loadlistings is successful it will return true, Otherwise
        // within loadListings an errorAction will be dispatched and it will return false.
        dispatchSuccessfulAction = yield call(loadListings);

        yield put(apiActions.account.loadDetails({ forceReload: true }));
    }

    const successfulAction = {
        [pageActionsPreload.ACTION]: pageActionsPreloaded,
        [pageActionsLoad.ACTION]: pageActionsLoaded,
    };

    if (dispatchSuccessfulAction) {
        yield put(successfulAction[action.type].create(PAGE_ID));
    }
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActionsPreload.ACTION && action.pageId === PAGE_ID,
            loadDashboardData
        ),
        takeEvery(
            (action) => action.type === pageActionsLoad.ACTION && action.pageId === PAGE_ID,
            loadDashboardData
        ),
        fork(dateListItemSagas),
    ]);
}
