import React, { useEffect } from 'react';
import { MembershipType } from 'api/types';
import { Button } from '@ths/design-system';
import Link from 'components/Link';
import { routes } from 'utils/routes';
import { track, Events } from 'utils/analytics';

import { UNLOCK_COPY, UpgradeMembershipTestIds } from './UpgradeMembership.constants';
import {
    UpgradeMembershipContainer,
    BodyWrapper,
    CtasWrapperStyled,
    UpgradeMembershipWrapper,
    BodyStyled,
    TitleStyled,
    LinkStyled,
} from './UpgradeMembership.style';

const UpgradeMembership = ({ membershipType, onUpgradeBannerDismissed, t }) => {
    const explorePlansCombinedRoute = `${routes.accounts.explorePlans()}?membershipType=${
        MembershipType.COMBINED
    }`;

    const dismissUpgradeBanner = () => {
        onUpgradeBannerDismissed();
        track(Events.BUTTONCLICK.create({ description: 'Combined upgrade banner - close' }));
    };

    useEffect(() => {
        track(Events.VIEWED_UPGRADE_BANNER.create('dashboard'));
    }, []);

    return (
        <UpgradeMembershipWrapper>
            <UpgradeMembershipContainer>
                {UNLOCK_COPY[membershipType].icon()}
                <BodyWrapper>
                    <TitleStyled data-testid={UpgradeMembershipTestIds.header}>
                        {t(UNLOCK_COPY[membershipType].header)}
                        {UNLOCK_COPY[membershipType].iconSmall()}
                    </TitleStyled>
                    <BodyStyled data-testid={UpgradeMembershipTestIds.body}>
                        {t(UNLOCK_COPY[membershipType].body)}
                    </BodyStyled>
                </BodyWrapper>
                <CtasWrapperStyled>
                    <LinkStyled
                        variant={Link.Variant.Button.PRIMARY}
                        to={explorePlansCombinedRoute}
                        data-testid={UpgradeMembershipTestIds.iWantCTA}
                        onClick={() => {
                            track(
                                Events.BUTTONCLICK.create({
                                    description: 'Combined upgrade banner - Find out more',
                                })
                            );
                        }}
                    >
                        {t('containers_upgradeMembership_find_out_cta')}
                    </LinkStyled>
                    <Button
                        data-testid={UpgradeMembershipTestIds.dontWantButton}
                        className="mt-2"
                        variant="secondary"
                        isFullwidth
                        onClick={dismissUpgradeBanner}
                    >
                        {t('containers_upgradeMembership_no_need_cta')}
                    </Button>
                </CtasWrapperStyled>
            </UpgradeMembershipContainer>
        </UpgradeMembershipWrapper>
    );
};

export default UpgradeMembership;
