import { connect } from 'react-redux';
import { createColumns, createSitemap } from './helpers';
import DynamicFacets from './DynamicFacets';

export { FACETS_SEARCH_TYPE } from './constants';

const mapStateToProps = (state, ownProps) => {
    if (typeof ownProps.type !== 'string') {
        throw new TypeError('You need to provide a search type for this component.');
    }

    return {
        columns: createColumns(state, ownProps),
        sitemap: createSitemap(state, ownProps),
    };
};

export default connect(mapStateToProps)(DynamicFacets);
