import { differenceInCalendarDays } from 'date-fns';
import { features, isFeatureEnabled } from 'components/Feature';
import { parseDate } from 'api/helpers/format/date';

export function assignmentIdFromAction(action) {
    // For compatibility reasons, we accept both ways

    if (Object.prototype.hasOwnProperty.call(action, 'assignment')) {
        return action.assignment.id;
    }

    if (Object.prototype.hasOwnProperty.call(action, 'id')) {
        return action.id;
    }

    throw new Error('Unable to get assignment id from open action.');
}

const getDaysLeft = (reviewWindowEndDate) => {
    try {
        const reviewEndDate = parseDate(reviewWindowEndDate);
        const today = new Date();
        const diffInDays = differenceInCalendarDays(reviewEndDate, today);

        return diffInDays;
    } catch (error) {
        return 'invalid_date_format';
    }
};

export default getDaysLeft;

export function hasPets(assignment) {
    return assignment?.hasAnimals;
}

export const isOwnerCategoriesEnabled = () =>
    isFeatureEnabled({ name: features.FEEDBACK_OWNER_CATEGORIES });
