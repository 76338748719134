import React from 'react';
import {
    NotificationBarContainerStyled,
    NotificationBarLinkTextStyled,
    NotificationBarTextUnderlinedStyled,
    NotificationBarButtonStyled,
    NotificationBarIconCloseStyled,
} from './NotificationBar.style';

const NotificationBar = ({
    linkText,
    linkUrl,
    underlinedText,
    target,
    textColour,
    backgroundColour,
    onClose,
    ...props
}) => (
    <NotificationBarContainerStyled backgroundColour={backgroundColour} {...props}>
        <NotificationBarLinkTextStyled textColour={textColour} href={linkUrl} target={target}>
            {linkText}
            <NotificationBarTextUnderlinedStyled textColour={textColour}>
                {underlinedText}
            </NotificationBarTextUnderlinedStyled>
        </NotificationBarLinkTextStyled>
        <NotificationBarButtonStyled
            textColour={textColour}
            backgroundColour={backgroundColour}
            onClick={onClose}
            data-testid="close-button"
        >
            <NotificationBarIconCloseStyled textColour={textColour} size="large" />
        </NotificationBarButtonStyled>
    </NotificationBarContainerStyled>
);

export default NotificationBar;
