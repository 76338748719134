/**
 * Adds a class to the element
 * @param query
 * @param className
 */
export const addClass = (query, className) => {
    document.querySelector(query).classList.add(className);
};

/**
 * Removes given class from the element
 * @param query
 * @param cls
 */
export const removeClass = (query, className) => {
    document.querySelector(query).classList.remove(className);
};

/**
 * Prevent body from scrolling and returns a helper function to release the lock
 * @returns {Function}
 */
export const lockScrollOnBody = () => {
    addClass('body', 'no-scroll');
    return () => {
        removeClass('body', 'no-scroll');
    };
};
