import { select, put, take, fork, call } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { MembershipType } from 'api/types';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { track, Events } from 'utils/analytics';
import { createListingsSearchQuery } from 'utils/searchListings';
import { getParams, getFilters, getPlace } from '../selectors';
import { getGeoHierarchyParamsAndLocationFilters } from '../../helpers';

/*
 * Call the callback if we need to fetch the saved search for initial
 * loading phase of the search page. This is necessary because only
 * paid plans has saved search available and we don't want to fetch something
 * user will not use.
 *
 * @param {function(...args):void} callback
 * @returns {void}
 */
export function* shouldFetchSavedSearches(callback, ...args) {
    const { membershipType } = yield select(getAccountCurrentMembershipPlan);
    if (membershipType === MembershipType.SITTER || membershipType === MembershipType.COMBINED) {
        yield fork(callback, ...args);
    }
}

export default function* saveSearch(action) {
    // We will need the URL params in case this is a geoHierarchy search
    const params = yield select(getParams);

    // This gets all the other filters including the other location ones (geoPoint and geoBounds)
    const existingQuery = (yield select(getFilters)) || {};

    // This helper will help us establish if we need geoHierarchy for this search
    const { locationFilters } = getGeoHierarchyParamsAndLocationFilters({
        query: existingQuery,
        params,
    });

    // Merge the two kinds of filters (not entirely sure why we treat them so differently?)
    const existingFiltersWithLocation = {
        ...(existingQuery.filters || {}),
        ...locationFilters,
    };

    // Use the query builder to correctly format everything
    const searchQueryBuilder = createListingsSearchQuery({
        searchPastAssignments: false,
        filters: existingFiltersWithLocation,
        perPage: 0,
    });

    // And extract the data we actually need
    const query = searchQueryBuilder.getRequestData();

    const locationObject = yield select(getPlace);

    let locationSlugs;

    locationSlugs = {
        slug: (locationObject && locationObject.slug) || '',
        countrySlug: (locationObject && locationObject.countrySlug) || '',
        admin1Slug: (locationObject && locationObject.admin1Slug) || '',
        admin2Slug: (locationObject && locationObject.admin2Slug) || '',
        admin3Slug: (locationObject && locationObject.admin3Slug) || '',
    };

    // GeoBounds cannot be converted into a single placename
    // but we want to show analytics that the user did save a location filter
    if (query.filters && query.filters.geoBounds) {
        locationSlugs = {
            slug: 'geoBounds',
            countrySlug: 'geoBounds',
            admin1Slug: 'geoBounds',
        };
    }

    yield put(
        apiActions.search.createListingsSavedSearch({
            forceReload: true,
            data: {
                name: action.name,
                filters: query.filters,
                notifyByPush: false,
                notifyByEmail: true,
            },
        })
    );

    // Wait for results
    const { status, data } = yield take(settings.search.createListingsSavedSearch.DONE);

    if (status === settings.search.createListingsSavedSearch.SUCCESS) {
        yield call(track, Events.SAVED_SEARCH.create(query.filters, locationSlugs, data.id));
    }

    // Return true/false for success/failure
    return status === settings.search.createListingsSavedSearch.SUCCESS;
}
