import { locationName, locationType } from 'api/helpers/format/location';
import { put, take, call } from 'redux-saga/effects';
import { track, Events } from 'utils/analytics';
import apiActions, { settings } from 'api/actions';
import { Facet } from 'api/helpers/search/constants';
import { error as errorAction } from 'containers/Page/actions';
import { CountryCodes } from 'utils/constants';
import { defaultLocation } from './constants';

export const placeGeoParam = 'slug';
export const admin1GeoParam = 'admin1Slug';
export const admin2GeoParam = 'admin2Slug';
export const admin3GeoParam = 'admin3Slug';
export const admin4GeoParam = 'admin4Slug';
export const countryGeoParam = 'countrySlug';
export const geoParams = [countryGeoParam, admin1GeoParam, admin2GeoParam, placeGeoParam];

// Must render correct geo hierarchy facet based on current params
export const geoParamFacetMap = [
    [placeGeoParam, Facet.PLACE],
    [admin2GeoParam, Facet.PLACE],
    [admin1GeoParam, Facet.ADMIN2],
    [countryGeoParam, Facet.ADMIN1],
    [null, Facet.COUNTRY],
];

// Note: Some countries like Honk Kong, Macao, ... are semi-independent political entity ( PCLS )
// Note #2: Some countries like Puerto Rico, Guadeloupe, Anguilla, ... are dependent political entity ( PCLD )
// Note #3: Some countries like Aruba, ... are a section of independent political entity ( PCLIX )
// Note #3: Some countries like Marshall Islands ... are a freely associated state
export const continentFeatureCodes = ['CONT'];
export const continentFeatureClass = 'L';
export const countryFeatureCodes = ['PCLI', 'PCL', 'PCLS', 'PCLD', 'PCLIX', 'PCLF'];
export const admin1FeatureCodes = ['ADM1', 'ADM1H'];
export const admin2FeatureCodes = ['ADM2', 'ADM2H'];
export const admin3FeatureCodes = ['ADM3', 'ADM3H'];
export const admin4FeatureCodes = ['ADM4', 'ADM4H'];
export const adminDivisionFeatureCodes = ['ADMD'];
// TODO: It's not entirely clear to me what the entire list of "place" feature codes is, add later!
// Testing by feature class works for now
export const placeFeatureClass = 'P';

// Some helpers that help figure out which level the user searched for
export const isContinentFeatureCode = (featureCode) => continentFeatureCodes.includes(featureCode);
export const isCountryFeatureCode = (featureCode) => countryFeatureCodes.includes(featureCode);
export const isAdmin1FeatureCode = (featureCode) => admin1FeatureCodes.includes(featureCode);
export const isAdmin2FeatureCode = (featureCode) => admin2FeatureCodes.includes(featureCode);
export const isAdmin3FeatureCode = (featureCode) => admin3FeatureCodes.includes(featureCode);
export const isAdmin4FeatureCode = (featureCode) => admin4FeatureCodes.includes(featureCode);
export const isAdminDivisionFeatureCode = (featureCode) =>
    adminDivisionFeatureCodes.includes(featureCode);
export const isAdminXFeatureCode = (featureCode) =>
    isAdmin1FeatureCode(featureCode) ||
    isAdmin2FeatureCode(featureCode) ||
    isAdmin3FeatureCode(featureCode) ||
    isAdmin4FeatureCode(featureCode) ||
    isAdminDivisionFeatureCode(featureCode);
export const isPlaceFeatureClass = (featureClass) => featureClass === placeFeatureClass;

// Some helpers that tell you which levels are contained in the search regardless of what the actual search was for
export const hasCountry = (place) => !!place.countrySlug;
export const hasAdmin1 = (place) => !!place.admin1Slug;
export const hasAdmin2 = (place) => !!place.admin2Slug;
export const hasAdmin3 = (place) => !!place.admin3Slug;
export const hasAdmin4 = (place) => !!place.admin4Slug;
export const hasAdminX = (place) =>
    hasAdmin1(place) || hasAdmin2(place) || hasAdmin3(place) || hasAdmin4(place);
export const hasPlace = (place) => isPlaceFeatureClass(place.featureClass);

// Some helpers that can convert place objects in to GeoHierarchys
export const getCountryGeoHierarchy = (place) => ({
    countrySlug: place.countrySlug,
});
export const getAdmin1GeoHierarchy = (place) => ({
    countrySlug: place.countrySlug,
    admin1Slug: place.admin1Slug,
});
export const getAdmin2GeoHierarchy = (place) => ({
    countrySlug: place.countrySlug,
    admin2Slug: place.admin2Slug,
});
export const getAdmin3GeoHierarchy = (place) => ({
    countrySlug: place.countrySlug,
    admin3Slug: place.admin3Slug,
});
export const getAdmin4GeoHierarchy = (place) => ({
    countrySlug: place.countrySlug,
    admin4Slug: place.admin4Slug,
});
export const getPlaceGeoHierarchy = (place) => ({
    countrySlug: place.countrySlug,
    admin1Slug: place.admin1Slug,
    slug: place.slug,
});

/**
 * Returns a string representing the lowest geoParam used for the given search or null if none was found.
 *
 * for the fallback We return admin1 for all of the adminx because the   other adminx's are not in the `geoParams` list
 */
export const getLowestGeoParamOfSearch = (place = {}) => {
    if (isCountryFeatureCode(place.featureCode)) {
        return { lowest: countryGeoParam, searchLevel: Facet.COUNTRY };
    }
    if (isAdmin2FeatureCode(place.featureCode)) {
        return { lowest: admin2GeoParam, searchLevel: Facet.ADMIN2 };
    }
    if (isAdmin1FeatureCode(place.featureCode)) {
        return { lowest: admin1GeoParam, searchLevel: Facet.ADMIN1 };
    }
    // Fallback
    if (isAdminXFeatureCode(place.featureCode)) {
        return { lowest: admin1GeoParam, searchLevel: Facet.ADMIN1 };
    }
    if (isPlaceFeatureClass(place.featureClass)) {
        return { lowest: placeGeoParam, searchLevel: Facet.PLACE };
    }
    return { lowest: null, searchLevel: null };
};

// If event not being tracked before then start tracking and fire event once
export const newUserAttributionEventOwner = () => {
    if (Events.NEW_USER_ATTRIBUTION.checkTrackingExists()) {
        track(Events.NEW_USER_ATTRIBUTION.create(Events.NEW_USER_ATTRIBUTION.MembershipType.OWNER));
    }
};

export const newUserAttributionEventSitter = () => {
    if (Events.NEW_USER_ATTRIBUTION.checkTrackingExists()) {
        track(
            Events.NEW_USER_ATTRIBUTION.create(Events.NEW_USER_ATTRIBUTION.MembershipType.SITTER)
        );
    }
};

/**
 * Returns an array of facets representing the levels below a given geoParam string. Examples:
 *
 * null => [COUNTRY, ADMIN1, ADMIN2, PLACE]
 * "countrySlug" => [ADMIN1, ADMIN2, PLACE]
 * "admin2slug" => [ADMIN2, PLACE]
 * "admin1slug" => [PLACE]
 * "slug" => [PLACE]
 */
export const getFacetsBelowGeoParam = (targetGeoParam = null) => {
    const facetMap = [...geoParamFacetMap].reverse();
    const worldwideSearch = targetGeoParam === null;

    // Produce an array containing the facets below the current level as defined by geoParamFacetMap
    return facetMap.reduce((facets, [geoParam, facet]) => {
        const hasLowerLevel = !!facet;
        const levelAboveMatched = facets.length > 0;
        const match = targetGeoParam === geoParam;

        // Add this facet if:
        // - We have a lower level (for example `slug` has no lower level but the others do) AND
        // - It's a worldwide search (so we add them all) OR
        // - This `geoParam` matches the users search OR
        // - We already found a match so all lower levels get added
        if (hasLowerLevel && (worldwideSearch || match || levelAboveMatched)) {
            return [...facets, facet];
        }

        return facets;
    }, []);
};

/**
 * Returns an array of facets representing the levels below the lowest point in a locis search place. Examples:
 *
 * worldwide search => [COUNTRY, ADMIN2, ADMIN1, PLACE]
 * country search => [ADMIN2, ADMIN1, PLACE]
 * admin2 search => [ADMIN1, PLACE]
 * admin1 search => [PLACE]
 * place search => [PLACE]
 */
export const getFacetsBelowPlace = (place = {}) => {
    const { lowest, searchLevel } = getLowestGeoParamOfSearch(place);
    const facets = getFacetsBelowGeoParam(lowest);
    return { facets, searchLevel };
};

export const getGeoHierarchyParamsAndLocationFilters = ({
    query,
    params,
    updatedGeoParams = geoParams,
}) => {
    // Looks for geohierarchy params
    const geoHierarchyParams = { ...params };

    Object.keys(params).forEach(
        (key) =>
            updatedGeoParams.includes(key) &&
            params[key] === undefined &&
            delete geoHierarchyParams[key]
    );

    const hasGeoHierarchyParams = Object.keys(geoHierarchyParams).length;

    // See if there is a location selected e.g. as parameters or in the qeury
    const locationFilters = {};
    if (hasGeoHierarchyParams) {
        locationFilters.geoHierarchy = geoHierarchyParams;
    } else if (query && query.filters && query.filters.geoHierarchy) {
        locationFilters.geoHierarchy = query.filters.geoHierarchy;
    } else if (query && query.filters && query.filters.geoPoint) {
        locationFilters.geoPoint = query.filters.geoPoint;
    }

    return { geoHierarchyParams, locationFilters };
};

/**
 * Transforms path geohierarchy into location search filters
 * @param pathGeohierarchy
 * @returns {null}
 */
export const getLocationFiltersFromGeohierarchy = (geoHierarchy) => {
    const filters = {
        ...geoHierarchy,
    };

    if (filters.slug) {
        filters.featureClass = ['P'];
    } else if (filters.admin4Slug) {
        filters.featureCode = ['ADM4'];
    } else if (filters.admin3Slug) {
        filters.featureCode = ['ADM3'];
    } else if (filters.admin2Slug) {
        filters.featureCode = ['ADM2'];
    } else if (filters.admin1Slug) {
        filters.featureCode = ['ADM1'];
    } else if (filters.countrySlug) {
        // PPLC - Hong Kong and similar countries that are also capitals
        filters['featureCode[]'] = ['PCLI', 'PCL', 'PCLS', 'PCLD', 'PCLIX'];
    } else {
        return null;
    }

    return filters;
};

/**
 * Transforms path geohierarchy into location search filters
 *
 * We allow mixed mapping on the old website.
 * This introduces an issue that users are able to set their location to admin2, admin3 or admin4 levels.
 *
 * As a result, when those url’s are hit (/country/admin1/place|admin2-4)
 * and we need to iterate through all the admin1 levels.
 * @TODO - Remove this search when location data of listings/profiles is updated
 *
 * Some countries are either dependent, semi-dependent political entities or a section of independent political entity,
 * and we need to search by all possible entities.
 */
export function* doGeohierarchyLocationData(searchType, geoHierarchy) {
    // Define search stack transformation
    const searchStack = [];

    // Based off the search criteria (slug, admin4, admin3, admin2, admin1 or country) define possible combinations
    // Add transforms to the stack, where the top priority search is added to the top of the stack
    if (geoHierarchy.slug) {
        searchStack.push({
            transformFrom: 'slug',
            transformTo: 'admin4Slug',
            filter: { featureCode: ['ADM4'] },
        });
        searchStack.push({
            transformFrom: 'slug',
            transformTo: 'admin3Slug',
            filter: { featureCode: ['ADM3'] },
        });
        searchStack.push({
            transformFrom: 'slug',
            transformTo: 'admin2Slug',
            filter: { featureCode: ['ADM2'] },
        });
        searchStack.push({
            transformFrom: 'slug',
            transformTo: 'slug',
            filter: { featureClass: ['P'] },
        });
    } else if (geoHierarchy.admin4Slug) {
        searchStack.push({
            transformFrom: 'admin4Slug',
            transformTo: 'slug',
            filter: { featureClass: ['P'] },
        });
        searchStack.push({
            transformFrom: 'admin4Slug',
            transformTo: 'admin4Slug',
            filter: { featureCode: ['ADM4'] },
        });
    } else if (geoHierarchy.admin3Slug) {
        searchStack.push({
            transformFrom: 'admin3Slug',
            transformTo: 'slug',
            filter: { featureClass: ['P'] },
        });
        searchStack.push({
            transformFrom: 'admin3Slug',
            transformTo: 'admin4Slug',
            filter: { featureCode: ['ADM4'] },
        });
        searchStack.push({
            transformFrom: 'admin3Slug',
            transformTo: 'admin3Slug',
            filter: { featureCode: ['ADM3'] },
        });
    } else if (geoHierarchy.admin2Slug) {
        searchStack.push({
            transformFrom: 'admin2Slug',
            transformTo: 'slug',
            filter: { featureClass: ['P'] },
        });
        searchStack.push({
            transformFrom: 'admin2Slug',
            transformTo: 'admin4Slug',
            filter: { featureCode: ['ADM4'] },
        });
        searchStack.push({
            transformFrom: 'admin2Slug',
            transformTo: 'admin3Slug',
            filter: { featureCode: ['ADM3'] },
        });
        searchStack.push({
            transformFrom: 'admin2Slug',
            transformTo: 'admin2Slug',
            filter: { featureCode: ['ADM2'] },
        });
    } else if (geoHierarchy.admin1Slug) {
        searchStack.push({
            transformFrom: 'admin1Slug',
            transformTo: 'slug',
            filter: { featureClass: ['P'] },
        });
        searchStack.push({
            transformFrom: 'admin1Slug',
            transformTo: 'admin4Slug',
            filter: { featureCode: ['ADM4'] },
        });
        searchStack.push({
            transformFrom: 'admin1Slug',
            transformTo: 'admin3Slug',
            filter: { featureCode: ['ADM3'] },
        });
        searchStack.push({
            transformFrom: 'admin1Slug',
            transformTo: 'admin2Slug',
            filter: { featureCode: ['ADM2'] },
        });
        searchStack.push({
            transformFrom: 'admin1Slug',
            transformTo: 'admin1Slug',
            filter: { featureCode: ['ADM1'] },
        });
    } else if (geoHierarchy.countrySlug) {
        // Note: Some countries like Honk Kong, Macao, ... are semi-independent political entity ( PCLS )
        // Note #2: Some countries like Puerto Rico, Guadeloupe, Anguilla, ... are dependent political entity ( PCLD )
        // Note #3: Some countries like Aruba, ... are a section of independent political entity ( PCLIX )
        // Nore #3: Some countries like Marshall Islands ... are a freely associated state
        searchStack.push({
            transformFrom: 'countrySlug',
            transformTo: 'countrySlug',
            filter: {
                'featureCode[]': countryFeatureCodes,
            },
        });
    } else if (geoHierarchy.continentSlug) {
        searchStack.push({
            transformFrom: 'continentSlug',
            transformTo: 'continentSlug',
            filter: {
                'featureCode[]': continentFeatureCodes,
                featureClass: continentFeatureClass,
            },
        });
    }

    // Search foreach combination given the criteria
    while (searchStack.length > 0) {
        const searchOption = searchStack.pop();

        const filters = {
            ...geoHierarchy,
            ...searchOption.filter,
        };

        delete filters[searchOption.transformFrom];
        filters[searchOption.transformTo] = geoHierarchy[searchOption.transformFrom];

        yield put(
            apiActions.search.loadPlaces({
                forceReload: true,
                data: {
                    searchType,
                },
                filters,
            })
        );

        const { data, status, statusCode } = yield take(
            (res) =>
                res.type === settings.search.loadPlaces.DONE &&
                res.requestData.searchType === searchType
        );

        if (status !== settings.search.loadPlaces.SUCCESS) {
            // Error occurred
            return { error: statusCode };
        }

        if (data && data.length > 0) {
            return { place: data[0] };
        }
    }

    return { error: 404 };
}

export function* getSearchLocationData(searchType, { geoHierarchy, geoPoint }) {
    // Depending on passed in filters, do different place search
    if (geoHierarchy) {
        const { place, error } = yield call(doGeohierarchyLocationData, searchType, geoHierarchy);

        if (error) {
            // Error occurred
            return { error };
        }

        return { place };
    }
    if (geoPoint) {
        yield put(
            apiActions.search.loadNearest({
                forceReload: true,
                data: {
                    searchType,
                },
                filters: {
                    lat: geoPoint.latitude,
                    lon: geoPoint.longitude,
                },
            })
        );

        const { data, status, statusCode } = yield take(
            (res) =>
                res.type === settings.search.loadNearest.DONE &&
                res.requestData.searchType === searchType
        );

        if (status !== settings.search.loadNearest.SUCCESS) {
            // Error occurred
            return { error: statusCode };
        }

        if (data && data.length > 0) {
            return { place: data[0] };
        }
    }

    // Place not found
    return { error: 404 };
}

const isDefaultLocation = ({ item }) => item.id === defaultLocation.id;

export const searchedLocationIsACountry = ({ location }) =>
    locationType(location) === Facet.COUNTRY;

export const locationIsUKorUS = ({ location, checkIsCountry = true }) => {
    if (checkIsCountry) {
        return (
            searchedLocationIsACountry({ location }) &&
            (location.countryCode === CountryCodes.US || location.countryCode === CountryCodes.GB)
        );
    }
    return location.countryCode === CountryCodes.US || location.countryCode === CountryCodes.GB;
};

export const locationIsUS = ({ location }) => location.countryCode === CountryCodes.US;

const suggestionMapToNameAndKey = (item) => {
    if (isDefaultLocation({ item })) {
        return {
            name: item.name,
            key: item.id,
        };
    }

    return {
        name: locationName(item, true, ', ', false),
        key: item.id,
    };
};

export const getHasPlace = (place) => Boolean(Object.keys({ ...place }).length);

export const getFilterLocationInputValue = ({ place }) => {
    if (!getHasPlace(place)) return '';

    if (place.name) {
        if (place.name === '') return '';
        return suggestionMapToNameAndKey(place).name;
    }

    return '';
};

export const getPlaceName = (place) => {
    if (place && typeof place === 'string') {
        const [city] = place.split(',');
        return city;
    }

    return '';
};

export const getPageTitle = ({ place, key, keyWithPlace, t }) => {
    if (getHasPlace(place)) {
        return t(keyWithPlace, {
            placeName: getFilterLocationInputValue({ place }),
        });
    }

    return t(key);
};

function* getPlaceFromParams(geoHierarchyParams, placeSearchType) {
    let place;

    if (geoHierarchyParams) {
        const response = yield call(getSearchLocationData, placeSearchType, {
            geoHierarchy: geoHierarchyParams,
        });
        if (response.error) {
            yield put(errorAction.create('SearchListings', response.error));
        }

        place = response.place;
    }

    return place;
}

export function* getFacetsAndFiltersFromParams({
    params,
    placeLoadedAction,
    placeSearchType,
    updatedGeoParams = geoParams,
}) {
    const facets = [];
    const filters = {};

    const { geoHierarchyParams } = getGeoHierarchyParamsAndLocationFilters({
        params,
        updatedGeoParams,
    });

    const hasGeoHierarchyParams = Object.keys(geoHierarchyParams).length;

    let place;

    if (hasGeoHierarchyParams) {
        place = yield getPlaceFromParams(geoHierarchyParams, placeSearchType);

        if (place && placeLoadedAction) {
            yield put(placeLoadedAction.create(place));
        }

        filters.geoHierarchy = geoHierarchyParams;
    } else if (placeLoadedAction) {
        // No location so clear place
        yield put(placeLoadedAction.create());
    }

    const { facets: lowestFacets } = getFacetsBelowPlace(place);

    facets.push(...lowestFacets);

    return {
        facets,
        filters,
    };
}

export const CountryCodeToCountrySlugMap = {
    GB: 'united-kingdom',
    US: 'united-states',
    AU: 'australia',
    CA: 'canada',
    NZ: 'new-zealand',
    ES: 'spain',
    FR: 'france',
    DE: 'germany',
    IT: 'italy',
};

export const getAdmin2Filters = ({ filters }) => {
    if (!filters || !filters.geoHierarchy || !filters.geoHierarchy.slug) {
        return false;
    }

    const { slug: admin2Slug, ...geoHierarchy } = filters.geoHierarchy;
    const updatedFilters = { ...filters, geoHierarchy: { ...geoHierarchy, admin2Slug } };

    return updatedFilters;
};

export const defaultGeoPointDistance = '40km';

export const getGeopoint = (location) => ({
    longitude: location.lon,
    latitude: location.lat,
    distance: defaultGeoPointDistance,
});

export const isInAPrimaryMarket = (isoCode) =>
    isoCode === CountryCodes.GB ||
    isoCode === CountryCodes.US ||
    isoCode === CountryCodes.CA ||
    isoCode === CountryCodes.AU ||
    isoCode === CountryCodes.NZ;
