import { format, isBefore, isValid } from 'date-fns';
import * as ListingFilters from 'api/helpers/search/listings';
import * as SearchConstants from 'api/helpers/search/constants';
import { parseDate } from 'api/helpers/format/date';
import { SITS_RESULTS_PER_PAGE } from 'config/search';

/**
 * Create listings search query
 * @param {Object} config
 * @param {bool} config.searchPastAssignments
 * @param {number} config.page
 * @param {Object} config.filters
 * @param {Object[]} config.facets
 * @param {Object[]} config.ignoredFilters
 * @param {number} config.perPage
 * @param {boolean} config.searchPastAndFutureAssignments
 * @param {number} config.facetResultSize
 * @param {boolean} config.addPausedListingsToSearch
 * @param {boolean} config.addConfirmedListingsToSearch
 * @param {boolean} config.preferGeoHierarchy
 * @returns {Query}
 */
const createListingsSearchQuery = ({
    searchPastAssignments,
    page = 1,
    filters = {},
    facets = [],
    ignoredFilters = [],
    perPage = SITS_RESULTS_PER_PAGE,
    searchPastAndFutureAssignments,
    facetResultSize,
    addPausedListingsToSearch = false,
    addConfirmedListingsToSearch = false,
    preferGeoHierarchy = false,
}) => {
    const {
        activeMembership,
        assignments,
        familyFriendly,
        wifiAvailable,
        isAccessibleByPublicTransport,
        disabledAccess,
        carIncluded,
        homeType = [],
        localAttractions = [],
        pets = [],
        geoPoint,
        geoHierarchy,
        geoBounds,
        // Location data obtained from url (pathGeohierarchy) and then parsed from data returned by Locis
        locationData,
        sort: sortFromFilters,
        published,
        sortBy = [],
    } = filters;

    const {
        dateFrom,
        dateTo,
        durationInDays,
        numberOfApplicants,
        reviewing,
        approximateDates,
        confirmed,
    } = assignments || {};
    const today = new Date();
    let sort = sortFromFilters ? [...sortFromFilters] : null;

    // Create a new search query
    const searchQuery = new ListingFilters.Query();
    searchQuery.resultsPerPage(perPage);

    // Only active members
    if (activeMembership !== undefined) {
        searchQuery.filter(new ListingFilters.ActiveMembership(activeMembership));
    } else {
        searchQuery.filter(new ListingFilters.ActiveMembership(true));
    }

    const dateTodayISO = format(today, 'yyyy-MM-dd');
    const dateSettings = {};
    if (searchPastAssignments) {
        dateSettings.dateTo = dateTodayISO;
    } else {
        const dateFromParsed = parseDate(dateFrom);
        const dateToParsed = parseDate(dateTo);

        if (!addConfirmedListingsToSearch) {
            dateSettings.confirmed = false;
        }
        if (!addPausedListingsToSearch) {
            dateSettings.reviewing = false;
        }

        if (dateFromParsed || dateToParsed) {
            /**
             * Not searching in the past
             * ensure dateFrom is set to today or future
             */
            if (dateFrom) {
                if (isBefore(dateFromParsed, today)) {
                    dateSettings.dateFrom = dateTodayISO;
                } else if (isValid(dateFromParsed)) {
                    dateSettings.dateFrom = dateFrom;
                }
            }

            /**
             * Not searching in the past
             * ensure dateTo is set to today or future
             */
            if (dateTo) {
                if (isBefore(dateToParsed, today)) {
                    dateSettings.dateTo = dateTodayISO;
                } else if (isValid(dateToParsed)) {
                    dateSettings.dateTo = dateTo;
                }
            }
        } else if (!searchPastAndFutureAssignments) {
            // Only show listings with active assignments in the future
            dateSettings.confirmed = false;
            dateSettings.dateFrom = dateTodayISO;
        }

        if (durationInDays && ignoredFilters.indexOf('length') < 0) {
            dateSettings.confirmed = false;
            dateSettings.reviewing = false;
            if (!dateSettings.dateFrom) {
                dateSettings.dateFrom = dateTodayISO;
            }

            if (durationInDays.minimum) {
                dateSettings.minimumDurationInDays = durationInDays.minimum;
            }
            if (durationInDays.maximum) {
                dateSettings.maximumDurationInDays = durationInDays.maximum;
            }
        }
        if (numberOfApplicants) {
            if (numberOfApplicants.minimum) {
                dateSettings.minimumNumberOfApplicants = numberOfApplicants.minimum;
            }
            if (numberOfApplicants.maximum) {
                dateSettings.maximumNumberOfApplicants = numberOfApplicants.maximum;
            }
        }
    }
    const filterDateOptions = {};

    if (reviewing) {
        filterDateOptions.reviewing = true;
    }
    if (approximateDates) {
        filterDateOptions.approximateDates = true;
    }
    if (confirmed) {
        filterDateOptions.confirmed = true;
    }

    searchQuery.filter(
        new ListingFilters.Assignments({
            ...dateSettings,
            ...filterDateOptions,
        })
    );

    if (published) {
        searchQuery.filter(
            new ListingFilters.Published({
                after: published.after || undefined,
                before: published.before || undefined,
            })
        );
    }

    // Loop over all possible pets and mark non selected as excluded
    pets.forEach((pet) => {
        searchQuery.filter(new ListingFilters.Pet(pet.type, { exclude: pet.exclude }));
    });

    // Loop over all possible local attractions and mark non selected as excluded
    if (localAttractions && localAttractions.length > 0) {
        searchQuery.filter(new ListingFilters.LocalAttractions(localAttractions));
    }

    // Loop over all possible home types and mark non selected as excluded
    if (homeType && homeType.length > 0) {
        searchQuery.filter(new ListingFilters.HomeType(homeType));
    }

    // If sortby filter present, checks to see if location is a city if distance is selected
    // and sets as recommended if it is, otherwise uses the selected sortby option
    if (sortBy && sortBy.length > 0) {
        if (sortBy[0] === 'distance' && !geoPoint) {
            searchQuery.filter(new ListingFilters.SortBy(['recommended']));
        } else {
            searchQuery.filter(new ListingFilters.SortBy(sortBy));
        }
    }

    // Loop over all possible home filters
    if (familyFriendly) searchQuery.filter(new ListingFilters.FamilyFriendly());
    if ((wifiAvailable instanceof Array && wifiAvailable.length > 0) || wifiAvailable) {
        searchQuery.filter(
            new ListingFilters.WifiAvailable([SearchConstants.WifiAvailable.HIGHSPEED])
        );
    }
    if (disabledAccess) searchQuery.filter(new ListingFilters.DisabledAccess());
    if (isAccessibleByPublicTransport)
        searchQuery.filter(new ListingFilters.AccessibleByPublicTransport());
    if (carIncluded) searchQuery.filter(new ListingFilters.CarIncluded());

    // A user does interact with the map (dragging, zooming)
    if (geoBounds) {
        searchQuery.filter(
            new ListingFilters.GeoBounds(
                geoBounds.north,
                geoBounds.south,
                geoBounds.east,
                geoBounds.west
            )
        );
    }
    // getPoint is set when a user searches for a place
    else if (geoPoint && !(preferGeoHierarchy && geoHierarchy)) {
        searchQuery.filter(
            new ListingFilters.GeoPoint(geoPoint.latitude, geoPoint.longitude, geoPoint.distance)
        );
    }
    // geoHierarchy is set when a user searches for anything above a place
    else if (geoHierarchy) {
        const geoHierarchyData = {
            ...geoHierarchy,
        };

        // When searching through search landing pages, we do a place search around admin1 area and order the results
        // by distance from the geoPoint. By removing Slug from we ensure to get all relevant search results.
        if (geoHierarchyData.slug && locationData) {
            // Delete slug from geo hierarchy in order to satisfy melbourne effect
            // Limit the search inside the region but order by distance from geoPoint
            delete geoHierarchyData.slug;

            sort = [
                {
                    function: SearchConstants.getSortFunction(
                        SearchConstants.SORT_FUNCTIONS.CLOSE_SITS_WITH_DATES_FIRST,
                        {
                            latitude: locationData.location.lat,
                            longitude: locationData.location.lon,
                        }
                    ),
                },
            ];
        }

        searchQuery.filter(new ListingFilters.GeoHierarchy(geoHierarchyData));
    }

    // Set sort
    if (sort) {
        sort.forEach((sortObject) => {
            Object.keys(sortObject).forEach((key) => {
                searchQuery.sort(key, sortObject[key]);
            });
        });
    } else {
        searchQuery.sort('published', SearchConstants.SortDirection.DESC);
    }

    // Set page
    if (page && page > 1) {
        searchQuery.page(page);
    }

    // Add facets
    if (facets && facets.length > 0) {
        searchQuery.facet(...facets);
    }

    if (facetResultSize) {
        searchQuery.facetResultSize(facetResultSize);
    }
    return searchQuery;
};

export {
    // eslint-disable-next-line import/prefer-default-export
    createListingsSearchQuery,
};
