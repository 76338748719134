import ApiConfig from '../config';
import ApiAction from './common';

const config = {
    loadPlaces: ['SEARCH_PLACES', 'LOAD', ApiConfig.endpoints.search.places._],
    loadNearest: ['SEARCH_NEAREST', 'LOAD', ApiConfig.endpoints.search.nearest],
    loadCountries: ['SEARCH_COUNTRIES', 'LOAD', ApiConfig.endpoints.search.places.countries],

    // Listings
    loadListings: [
        'SEARCH_LISTINGS',
        'LOAD',
        ApiConfig.endpoints.search.listings._,
        [],
        'GET',
        null,
        null,
        null,
        'searchKeyGenerator',
    ],
    loadListing: [
        'SEARCH_LISTING',
        'LOAD',
        ApiConfig.endpoints.search.listings.listing,
        [],
        'GET',
        null,
        null,
        {
            minutes: 1,
        },
    ],
    loadListingsFavourites: [
        'SEARCH_LISTINGS_FAVOURITES',
        'LOAD',
        ApiConfig.endpoints.search.listings.favourites,
        [],
        'GET',
        null,
        null,
        {
            minutes: 1,
        },
    ],
    // Saved search
    loadListingsSavedSearch: [
        'SEARCH_LISTINGS_SAVED',
        'LOAD',
        ApiConfig.endpoints.search.listings.saved._,
        [],
        'GET',
        null,
        null,
        {
            minutes: 1,
        },
    ],
    createListingsSavedSearch: [
        'SEARCH_LISTINGS_SAVED',
        'CREATE',
        ApiConfig.endpoints.search.listings.saved._,
        [],
        'POST',
        null,
        null,
        {
            seconds: 2,
        },
    ],
    loadListingSavedSearch: [
        'SEARCH_LISTINGS_SAVED_ITEM',
        'LOAD',
        ApiConfig.endpoints.search.listings.saved.item,
        [],
        'GET',
        null,
        null,
        {
            minutes: 1,
        },
    ],
    deleteListingsSavedSearch: [
        'SEARCH_LISTINGS_SAVED_ITEM',
        'REMOVE',
        ApiConfig.endpoints.search.listings.saved.item,
        [],
        'DELETE',
        null,
        null,
        {
            seconds: 1,
        },
    ],
    updateListingsSavedSearch: [
        'SEARCH_LISTINGS_SAVED_ITEM',
        'UPDATE',
        ApiConfig.endpoints.search.listings.saved.item,
        [],
        'PUT',
        null,
        null,
        {
            seconds: 1,
        },
    ],
    shareListingEmail: [
        'SEARCH_LISTINGS_SHARE',
        'CREATE',
        ApiConfig.endpoints.search.listings.share.email,
    ],

    // Profiles
    loadProfiles: ['SEARCH_PROFILES', 'LOAD', ApiConfig.endpoints.search.profiles._],
    loadProfile: [
        'SEARCH_PROFILE',
        'LOAD',
        ApiConfig.endpoints.search.profiles.profile,
        [],
        'GET',
        null,
        null,
        {
            minutes: 1,
        },
    ],
    loadProfilesFavourites: [
        'SEARCH_PROFILES_FAVOURITES',
        'LOAD',
        ApiConfig.endpoints.search.profiles.favourites,
        [],
        'GET',
        null,
        null,
        {
            minutes: 1,
        },
    ],
    loadProfilesFavouritedYou: [
        'SEARCH_PROFILES_FAVOURITED_YOU',
        'LOAD',
        ApiConfig.endpoints.search.profiles.favouritedYou,
        [],
        'GET',
        null,
        null,
        {
            minutes: 1,
        },
    ],
    // Saved search
    loadProfilesSavedSearch: [
        'SEARCH_PROFILES_SAVED',
        'LOAD',
        ApiConfig.endpoints.search.profiles.saved._,
        [],
        'GET',
        null,
        null,
        {
            minutes: 1,
        },
    ],
    createProfilesSavedSearch: [
        'SEARCH_PROFILES_SAVED',
        'CREATE',
        ApiConfig.endpoints.search.profiles.saved._,
    ],
    deleteProfilesSavedSearch: [
        'SEARCH_PROFILES_SAVED_ITEM',
        'REMOVE',
        ApiConfig.endpoints.search.profiles.saved.item,
    ],

    // Sitemap
    loadSitemapPages: [
        'SEARCH_SITEMAP_PAGES',
        'LOAD',
        ApiConfig.endpoints.search.sitemap.pages,
        [],
        'GET',
        null,
        null,
    ],
    loadSitemapLocations: [
        'SEARCH_SITEMAP_LOCATIONS',
        'LOAD',
        ApiConfig.endpoints.search.sitemap.locations,
        [],
        'GET',
        null,
        null,
    ],

    // Facets
    loadListingsFacets: ['SEARCH_LISTINGS_FACETS', 'LOAD', ApiConfig.endpoints.search.listings._],
    loadProfilesFacets: ['SEARCH_PROFILES_FACETS', 'LOAD', ApiConfig.endpoints.search.profiles._],
};

const actions = {};
const settings = {};

const actionTypes = Object.keys(config);
for (let a = 0; a < actionTypes.length; a += 1) {
    ApiAction(actions, settings, actionTypes[a], ...config[actionTypes[a]]);
}

export default actions;
export { settings };
