import {
    SITEMAP_PAGES,
    SITEMAP_LEVELS,
    SITEMAP_TYPES,
    TITLE_TYPE,
    TITLE_LEVEL,
} from './Sitemap.constants';

export function getSitemapSearchType(type) {
    return type === SITEMAP_PAGES.HOUSE_SITS ? SITEMAP_TYPES.LISTINGS : SITEMAP_TYPES.PROFILES;
}

export function getSitemapSearchLevel(level) {
    const levels = {
        countries: SITEMAP_LEVELS.COUNTRY,
        regions: SITEMAP_LEVELS.REGION,
        counties: SITEMAP_LEVELS.COUNTY,
        cities: SITEMAP_LEVELS.CITY,
    };

    return levels[level] || 'country';
}

export function getPageNumber(page) {
    try {
        return parseInt(page.replace(/\D/g, ''), 10);
    } catch {
        return 1;
    }
}

export const generateTitle = (t, type, level, currentPage) => {
    const typeTitle = t(TITLE_TYPE[type].title);
    const siteName = t('pages_sitemap_title');
    const levelName = t(TITLE_LEVEL[level]);

    return `${typeTitle} ${siteName} - ${levelName} ${currentPage ? `Page ${currentPage}` : ''}`;
};

export const generateMetaDescription = (t, type, level, currentPage) => {
    const typeDescription = t(TITLE_TYPE[type].description);
    const levelName = t(TITLE_LEVEL[level]);

    return `${typeDescription} ${levelName} - ${currentPage ? `Page ${currentPage}` : ''}`;
};
