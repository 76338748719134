import React from 'react';
import { LabelStyled, RadioInputStyled } from './Radio.style';
import { RadioSize } from './Radio.constants';

const Radio = ({
    onChange,
    onBlur,
    name,
    describedBy,
    disabled,
    isChecked,
    hasRadioFirst,
    id,
    label,
    className,
    size,
}) => (
    <LabelStyled disabled={disabled} htmlFor={id} className={className}>
        {!hasRadioFirst && label}

        <RadioInputStyled
            aria-describedby={describedBy}
            disabled={disabled}
            id={id}
            checked={isChecked}
            hasRadioFirst={hasRadioFirst}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            size={size}
        />

        {hasRadioFirst && label}
    </LabelStyled>
);

Radio.defaultProps = {
    hasRadioFirst: false,
    size: RadioSize.MEDIUM,
};

Radio.Size = RadioSize;

export default Radio;
