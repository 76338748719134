import ApiConfig from '../config';

// eslint-disable-next-line import/prefer-default-export
export const getState = (state) => {
    let stateRoot = state;

    if (ApiConfig.reduxStorePrefixPath.length === 1) {
        return stateRoot[ApiConfig.reduxStorePrefixPath[0]];
    }
    else if (ApiConfig.reduxStorePrefixPath.length > 1) {
        for (let pathIndex = 0; pathIndex < ApiConfig.reduxStorePrefixPath.length; pathIndex += 1) {
            stateRoot = stateRoot[ApiConfig.reduxStorePrefixPath[pathIndex]];
        }
        return stateRoot;
    }

    return stateRoot;
};

