import React from 'react';
import { IconChevronRight, IconChevronLeft } from 'components/Icon';
import helper from './helper';
import {
    PaginationStyled,
    PaginationItemStyled,
    InactivePaginationItemStyled,
    InactivePaginationItemButtonStyled,
    ActivePaginationItemStyled,
    ActivePaginationItemButtonStyled,
    PaginationChevronButtonStyled,
} from './Pagination.style';

const Pagination = ({
    currentPage,
    totalItems,
    itemsPerPage,
    maxPagesToDisplay,
    onItemClick,
    ariaPageLabel,
    ariaCurrentPageLabel,
    ariaPreviousPageLabel,
    ariaNextPageLabel,
    ...props
}) => {
    const { displayedPages, showPrevious, showNext } = helper.getPageMetadata(
        currentPage,
        totalItems,
        itemsPerPage,
        maxPagesToDisplay
    );
    const itemClickHandler = (page) => onItemClick && onItemClick(page);

    const pageLinks = displayedPages.map((page) => {
        const current = page === currentPage;
        const Item = current ? ActivePaginationItemStyled : InactivePaginationItemStyled;
        const ItemButton = current
            ? ActivePaginationItemButtonStyled
            : InactivePaginationItemButtonStyled;
        const testid = current ? 'Pagination_item_selected' : 'Pagination_item';
        const ariaProps = current
            ? {
                  'aria-label': ariaCurrentPageLabel(page), // `Current Page, Page ${page}`
                  'aria-current': true,
              }
            : {
                  'aria-label': ariaPageLabel(page), // `Goto Page ${page}`,
              };

        return (
            <Item key={page} data-testid={testid}>
                <ItemButton onClick={() => itemClickHandler(page)} {...ariaProps}>
                    {page}
                </ItemButton>
            </Item>
        );
    });

    return (
        <PaginationStyled {...props}>
            <PaginationItemStyled>
                {showPrevious && (
                    <PaginationChevronButtonStyled
                        aria-label={ariaPreviousPageLabel}
                        data-testid="Pagination_previous"
                        rel="prev"
                        onClick={() => itemClickHandler(currentPage - 1)}
                    >
                        <IconChevronLeft size="medium" palette="text" />
                    </PaginationChevronButtonStyled>
                )}
            </PaginationItemStyled>
            {pageLinks}
            <PaginationItemStyled>
                {showNext && (
                    <PaginationChevronButtonStyled
                        aria-label={ariaNextPageLabel}
                        data-testid="Pagination_next"
                        rel="next"
                        onClick={() => itemClickHandler(currentPage + 1)}
                    >
                        <IconChevronRight size="medium" palette="text" />
                    </PaginationChevronButtonStyled>
                )}
            </PaginationItemStyled>
        </PaginationStyled>
    );
};

export default Pagination;
