/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { mix } from 'polished';
import {
    lightHover,
    lightActive,
    darkHover,
    darkActive,
    darkHoverReversed,
    darkActiveReversed,
    lightHoverReversed,
    lightActiveReversed,
} from 'utils/colors';
import { theme as defaultTheme } from 'theme';

const hoverDarkStrength = defaultTheme.opacityOverlay.dark.hover.strength;
const activeDarkStrength = defaultTheme.opacityOverlay.dark.active.strength;

/**
 * Provides a function to create a styled button component
 *
 * @param {boolean | string} fullWidth whether the button should be full width
 * if true button will display full width for all breakpoints
 * if breakpoint is passed in button is displayed full width up to breakpoint
 *
 * @return {function} a styled button component
 */
const fullWidthState = css`
    @media (max-width: ${({ fullWidth }) => `${fullWidth}px`}) {
        display: flex;
        width: 100%;
    }
`;

const animateLoading = css`
    @keyframes animateLoading {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(100%);
        }
    }
`;

const loadingState = ({ loadingColor, opacity }) =>
    css`
        ${({ loading }) =>
            loading &&
            `
                ${animateLoading};

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: ${loadingColor};
                    opacity: ${opacity || '0.5'};
                    width: 100%;
                    height: 4px;
                    animation: animateLoading 2.5s infinite;
                }
            `};
    `;

const variants = {
    // light
    primary: css`
        color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.grey[700]};
        border: none;
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${({ theme }) => theme.palette.grey[900]};
            }
        }
        &:focus {
            background-color: ${({ theme }) => theme.palette.grey[900]};
        }
        &:active:enabled {
            background-color: ${({ theme }) => lightActive(theme.palette.grey[700])};
        }

        ${({ theme }) => loadingState({ loadingColor: theme.palette.black })};
    `,
    // light
    secondary: css`
        color: ${({ theme }) => theme.palette.grey[700]};
        background-color: ${({ theme }) => theme.palette.white};
        box-shadow: ${({ theme }) => `0 0 0 2px ${theme.palette.grey[700]}`};
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${({ theme }) => lightHover(theme.palette.white)};
            }
        }
        &:active:enabled {
            background-color: ${({ theme }) => lightActive(theme.palette.white)};
        }

        ${({ theme }) => loadingState({ loadingColor: theme.palette.black })};
    `,
    // light
    secondaryProceed: css`
        color: ${({ theme }) => theme.palette.grey[700]};
        background-color: transparent;
        box-shadow: ${({ theme }) => `0 0 0 2px ${theme.palette.grey[700]}`};
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${() => lightHoverReversed('rgba(255,255,255,0)')};
            }
        }
        &:active:enabled {
            background-color: ${() => lightActiveReversed('rgba(255,255,255,0)')};
        }

        ${({ theme }) => loadingState({ loadingColor: theme.palette.grey[700] })};
    `,
    // light
    secondaryReversed: css`
        color: ${({ theme }) => theme.palette.white};
        background-color: transparent;
        box-shadow: ${({ theme }) => `0 0 0 1px ${theme.palette.white}`};
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${() => darkHoverReversed('rgba(255,255,255,0)')};
            }
        }
        &:active:enabled {
            background-color: ${() => darkActiveReversed('rgba(255,255,255,0)')};
        }

        ${({ theme }) => loadingState({ loadingColor: theme.palette.black })};
    `,
    // accent cta
    cta: css`
        color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.accent};
        border: none;
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${({ theme }) => darkHover(theme.palette.accent)};
            }
        }
        &:active:enabled {
            background-color: ${({ theme }) => darkActive(theme.palette.accent)};
        }
    `,
    // Secondary cta
    alternateCta: css`
        color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.tertiaryRegular};
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${({ theme }) => darkHover(theme.palette.tertiaryRegular)};
            }
        }
        &:active:enabled {
            background-color: ${({ theme }) => darkActive(theme.palette.tertiaryRegular)};
        }
    `,
    ctaReversed: css`
        color: ${({ theme }) => theme.palette.accent};
        background-color: transparent;
        box-shadow: ${({ theme }) => `0 0 0 1px ${theme.palette.accent}`};
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                color: ${({ theme }) => theme.palette.white};
                background-color: ${({ theme }) => theme.palette.accent};
            }
        }
        &:active:enabled {
            color: ${({ theme }) => theme.palette.white};
            background-color: ${({ theme }) => theme.palette.accent};
        }

        ${({ theme }) => loadingState({ loadingColor: theme.palette.accent, opacity: '1' })};
    `,
    // dark
    destructive: css`
        color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.danger};
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${({ theme }) => darkHover(theme.palette.danger)};
            }
        }
        &:active:enabled {
            background-color: ${({ theme }) => darkActive(theme.palette.danger)};
        }

        ${({ theme }) => loadingState({ loadingColor: theme.palette.black })};
    `,
    // light
    sticky: css`
        color: ${({ theme }) => theme.palette.text};
        background-color: ${({ theme }) => theme.palette.white};
        /* First shadow is actually a faux border so that the border does not affect the width of the button */
        box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.border},
            0 2px 4px 0 rgba(0, 0, 0, ${hoverDarkStrength});

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${({ theme }) => lightHover(theme.palette.white)};
                box-shadow: 0 0 0 1px
                        ${({ theme }) =>
                            `${mix(
                                theme.activeOpacity,
                                theme.palette.border,
                                theme.palette.white
                            )}`},
                    0 2px 4px 0 rgba(0, 0, 0, ${hoverDarkStrength});
            }
        }
        &:active:enabled {
            background-color: ${({ theme }) => lightActive(theme.palette.white)};
            box-shadow: 0 0 0 1px
                    ${({ theme }) =>
                        `${mix(theme.activeOpacity, theme.palette.border, theme.palette.white)}`},
                0 2px 4px 0 rgba(0, 0, 0, ${activeDarkStrength});
        }
    `,
    dropdown: css`
        border: ${({ theme }) => `1px solid ${theme.palette.border}`};
    `,
    // light
    link: css`
        background-color: initial;
        border: none;
        text-decoration: none;
        color: ${({ theme }) => theme.palette.link};
        font-weight: ${({ theme }) => theme.fontWeights.bold};

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
                text-decoration-skip: ink;
                color: ${({ theme }) => lightHover(theme.palette.link)};
            }
        }
        &:active {
            text-decoration: underline;
            text-decoration-skip: ink;
            color: ${({ theme }) => lightActive(theme.palette.link)};
        }
    `,
    linkSubtle: css`
        padding: 0;
        background-color: initial;
        border: none;
        text-decoration: none;
        color: ${({ theme }) => theme.palette.link};
        font-weight: ${({ theme }) => theme.fontWeights.normal};

        @media (hover: hover) {
            &:hover {
                text-decoration-skip: ink;
                color: ${({ theme }) => lightHover(theme.palette.link)};
            }
        }
        &:active {
            text-decoration-skip: ink;
            color: ${({ theme }) => lightActive(theme.palette.link)};
        }
    `,
    flat: css`
        padding: 0;
        background-color: transparent;
        border: none;
        text-decoration: none;

        @media (hover: hover) {
            &:hover {
                background-color: transparent;
                border: none;
            }
        }
    `,
    // accent
    accent: css`
        color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.accent};
        border: none;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${({ theme }) => lightHover(theme.palette.accent)};
            }
        }
        &:active:enabled {
            background-color: ${({ theme }) => lightActive(theme.palette.accent)};
        }
    `,
    // secondary color
    alternatePrimary: css`
        color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.secondaryRegular};
        border: none;
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${({ theme }) => lightHover(theme.palette.secondaryRegular)};
            }
        }
        &:active:enabled {
            background-color: ${({ theme }) => lightActive(theme.palette.secondaryRegular)};
        }

        ${({ theme }) => loadingState({ loadingColor: theme.palette.black })};
    `,
    alternateSecondary: css`
        color: ${({ theme }) => theme.palette.secondaryRegular};
        background-color: transparent;
        box-shadow: ${({ theme }) => `0 0 0 1px ${theme.palette.secondaryRegular}`};
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${() => lightHoverReversed('rgba(255,255,255,0)')};
            }
        }
        &:active:enabled {
            background-color: ${() => lightActiveReversed('rgba(255,255,255,0)')};
        }

        ${({ theme }) =>
            loadingState({ loadingColor: theme.palette.secondaryRegular, opacity: '1' })};
    `,
    tab: css`
        padding: 0;
        background-color: transparent;
        border: none;
        text-decoration: none;
        box-sizing: border-box;
        border-radius: 0;
        font-weight: ${({ isSelected, theme }) =>
            isSelected ? theme.fontWeights.semiBold : theme.fontWeights.normal};
        color: ${({ theme }) => theme.palette.grey[700]};
        box-shadow: inset 0 -4px ${({ isSelected, theme }) => (isSelected ? theme.palette.accent : 'transparent')};
        padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[3.5]}`};

        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
            font-size: ${({ theme }) => theme.fontSizes.medLarge};
        }
    `,
    subtle: css`
        color: ${({ theme }) => theme.palette.grey[500]};
        border: ${({ theme }) => `1px solid ${theme.palette.grey[300]}`};
        overflow: hidden;

        @media (hover: hover) {
            &:hover:not([disabled]) {
                background-color: ${() => lightHoverReversed('rgba(255,255,255,0)')};
            }
        }
        &:active:enabled {
            background-color: ${() => lightActiveReversed('rgba(255,255,255,0)')};
        }
    `,
};

const baseButtonStyle = css`
    transition-property: color, background-color, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    box-sizing: border-box;
    display: ${({ fullWidth }) => (fullWidth === true ? 'flex' : 'inline-flex')};
    width: ${({ fullWidth }) => (fullWidth === true ? '100%' : 'auto')};

    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    text-decoration: none;

    border: none;
    border-radius: ${({ theme }) => theme.borderRadius.button};
    margin: 0;
    padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2]}`};

    font-size: 1rem;
    /* To adjust the size of the button we affect the font size and other sizes are updated accordingly */
    font-size: ${({ theme, size }) => theme.fontSizes[size]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: 1.5;

    text-align: center;
    white-space: nowrap;

    /* Select the styling for the specific type of button */
    ${({ variant }) => variants[variant]};
    ${({ border }) => {
        if (border) return `border: ${border}`;
        return null;
    }};

    ${({ rounded }) => rounded && 'border-radius: 1.5em'};
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};

    &:disabled {
        cursor: not-allowed;
        opacity: ${({ theme }) => theme.disabledOpacity};
    }

    ${({ fullWidth }) => (fullWidth ? fullWidthState : '')};
`;

const nonDomProps = ['loading'];
const ButtonStyled = styled.button.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        !nonDomProps.includes(prop) && defaultValidatorFn(prop),
})`
    ${baseButtonStyle};
`;

export { ButtonStyled as default, variants, baseButtonStyle };
