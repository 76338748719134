// eslint-disable-next-line import/prefer-default-export
export const USER_LISTING_EDIT_PETS = {
    create(pets = []) {
        let petIntroduction = false;
        pets.forEach((pet) => {
            if (!petIntroduction) {
                const { name, yearOfBirth, breedId, breeds = [] } = pet;
                petIntroduction =
                    Boolean(name) || Boolean(yearOfBirth) || Boolean(breedId) || breeds.length > 0;
            }
        });
        return {
            type: 'Listing Edit Pets Update',
            payload: {
                pets: pets.length > 0,
                petIntroduction,
            },
        };
    },
};
