import { put, takeEvery, all, take, select, call, fork } from 'redux-saga/effects';
import * as pageActions from 'containers/Page/actions';
import actions, { settings } from 'api/actions';
import {
    getBlogPost,
    getBlogCategorySubcategory,
    getBlogAreaTrendingPosts,
} from 'api/selectors/content';
import { getLanguage } from 'shared/selectors';
import { fetchBlogPostsRequest } from 'containers/BlogPostsCarousel/actions';
import { PAGE_ID, SEARCH_TYPE } from './constants';
import { getPost } from './selectors';

export function* raiseError(statusCode) {
    yield put(pageActions.error.create(PAGE_ID, statusCode));
}

export function* loadBlogPostsCarousel({ area }) {
    const post = yield select(getPost);

    if (post) {
        yield put(
            fetchBlogPostsRequest.create({
                area,
                categories: post?.categorySlug ? [post.categorySlug] : [],
                subcategories: post?.subcategorySlug ? [post.subcategorySlug] : [],
                tags: post?.tags || [],
            })
        );

        return true;
    }

    return false;
}

export function* loadTrendingPosts({ area }) {
    const trendingPosts = yield select(getBlogAreaTrendingPosts, area, SEARCH_TYPE);

    if (!trendingPosts) {
        yield put(
            actions.content.loadBlogAreaTrendingPosts({
                forceReload: true,
                filters: {
                    lang: yield select(getLanguage),
                },
                data: {
                    areaSlug: area,
                    searchType: SEARCH_TYPE,
                },
            })
        );
    }

    return true;
}

export function* loadSubcategory({ area, category, subcategory }) {
    if (!(yield select(getBlogCategorySubcategory, area, category))) {
        yield put(
            actions.content.loadBlogCategorySubcategory({
                forceReload: true,
                filters: {
                    lang: yield select(getLanguage),
                },
                data: {
                    areaSlug: area,
                    categorySlug: category,
                    subcategorySlug: subcategory,
                },
            })
        );

        yield take(settings.content.loadBlogCategorySubcategory.DONE);
    }

    return true;
}

export function* fetchPost({ params, lang }) {
    // See if already loaded
    const postInStore = yield select(getBlogPost, params.area, params.category, params.slug);

    if (!postInStore) {
        yield put(
            actions.content.loadBlogPost({
                filters: {
                    lang,
                },
                data: {
                    areaSlug: params.area,
                    categorySlug: params.category,
                    postSlug: params.slug,
                },
            })
        );

        const { status: loadBlogPostStatus, statusCode } = yield take(
            settings.content.loadBlogPost.DONE
        );

        if (loadBlogPostStatus !== settings.content.loadBlogPost.SUCCESS) {
            return { found: false, status: statusCode };
        }
    }

    return { found: true };
}

export function* loadPost({ params }) {
    const lang = yield select(getLanguage);

    const { found, status } = yield call(fetchPost, { params, lang });

    if (!found) {
        yield call(raiseError, status);
        return false;
    }

    const post = yield select(getBlogPost, params.area, params.category, params.slug);

    if (post && typeof post.subcategorySlug === 'string') {
        yield call(loadSubcategory, {
            area: params.area,
            category: post.categorySlug,
            subcategory: post.subcategorySlug,
        });
    }

    yield fork(loadTrendingPosts, {
        area: params.area,
    });
    yield fork(loadBlogPostsCarousel, { area: params.area });

    return true;
}

export function* setNavBarAndFooterVisibility() {
    const post = yield select(getPost);

    if (post) {
        const { hideNavbar, hideFooter } = post;

        yield put(
            pageActions.setNavbarOptions.create({
                hideLeftSide: hideNavbar ?? false,
                hideRightSide: hideNavbar ?? false,
                disableLogoLink: hideNavbar ?? false,
            })
        );

        yield put(pageActions.setFooterOptions.create({ hideLinks: hideFooter ?? false }));
    }
}

function* load(action) {
    const loaded = yield loadPost(action);
    if (loaded) {
        yield setNavBarAndFooterVisibility();
        yield put(pageActions.loaded.create(PAGE_ID));
    }
}

function* preload(action) {
    const loaded = yield loadPost(action);
    if (loaded) {
        yield setNavBarAndFooterVisibility();
        yield put(pageActions.preloaded.create(PAGE_ID));
    }
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) => action.type === pageActions.preload.ACTION && action.pageId === PAGE_ID,
            preload
        ),
        takeEvery(
            (action) => action.type === pageActions.load.ACTION && action.pageId === PAGE_ID,
            load
        ),
    ]);
}
