import { settings as actions } from '../../actions';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.account.loadSettings.SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        case actions.account.updateSettings.SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};
