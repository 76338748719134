import React from 'react';
import * as PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { renderRoutes } from 'react-router-config';
import GlobalStyle from 'components/themes/global';
import './Root.css';

const Root = ({ route }) => (
    <>
        <Helmet titleTemplate="%s | TrustedHousesitters.com">
            <title>Find the Perfect Pet Sitter! Home & Pet Sitting Made Easy</title>
            <meta
                name="description"
                content="Find the perfect home & pet sitter. View references, video profiles and relevant house & pet sitting experience."
            />
            <meta name="apple-mobile-web-app-title" content="TrustedHousesitters" />
            <meta name="application-name" content="TrustedHousesitters" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-config" content="none" />
        </Helmet>
        <GlobalStyle />
        {renderRoutes(route.routes)}
    </>
);

Root.propTypes = {
    route: PropTypes.object.isRequired,
};

export default Root;
