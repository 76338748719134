import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { createBreadcrumbSchemaMarkup } from 'utils/analytics/schemas';
import PostHeader from 'components/content/prismicSlices/Header';
import SeoFacetLinks from 'components/content/prismicSlices/SeoFacetLinks';
import { PanelStyled } from 'components/content/prismicSlices/common.style';
import { FlexboxGrid, Col, Panel } from 'components/layouts';
import Breadcrumb from 'components/Breadcrumb';
import { getParams } from 'containers/Page/selectors';
import PageContent from './components/LandingPageTemplate';
import { CoverImageStyled, BreadcrumbContainerStyled } from './Page.style';
import { PAGE_ID } from './constants';

const getPageTitleFromSlice = (title) => {
    if (!title || title.length === 0) {
        return null;
    }

    if (title[0].text === '') {
        return null;
    }

    return title;
};

const Page = ({ page, seoContent }) => {
    const { t } = useTranslation();
    const { section } = useSelector((state) => getParams(state, PAGE_ID));
    const theme = useContext(ThemeContext);
    const pageTitle = getPageTitleFromSlice(page?.pageTitle);

    const homeBreadcrumb = {
        id: 'home',
        text: t('navigation_home'),
        to: '/',
    };

    const sectionBreadcrumb = {
        id: section,
        text: t(`navigation_${section}`),
        to: `/${section}`,
    };

    const pageBreadcrumb = {
        id: 'current-page',
        text: pageTitle ? pageTitle[0].text : null,
    };

    const breadcrumbItems = [homeBreadcrumb, sectionBreadcrumb, pageBreadcrumb].filter(
        (item) => item.id
    );

    if (!page) {
        return null;
    }

    return (
        <>
            <Helmet>
                {!seoContent.hasTitle && page.metaTitle && <title>{page.metaTitle}</title>}
                {!seoContent.hasDescription && page.metaDescription && (
                    <meta name="description" content={page.metaDescription} />
                )}
                {!seoContent.hasRobots && page.metaRobots && (
                    <meta name="robots" content={page.metaRobots} />
                )}
                {!seoContent.hasCanonicalUrl && page.canonicalUrl && (
                    <link rel="canonical" href={page.canonicalUrl} />
                )}
                <script type="application/ld+json">
                    {JSON.stringify(
                        createBreadcrumbSchemaMarkup({
                            items: breadcrumbItems,
                        })
                    )}
                </script>
            </Helmet>

            {/* FIXME: All pages should have a pageTitle */}
            {pageTitle && (
                <>
                    <BreadcrumbContainerStyled>
                        <FlexboxGrid justify={FlexboxGrid.Justify.CENTER}>
                            <Col mediumDesktop={10} largeDesktop={8}>
                                <Breadcrumb items={breadcrumbItems} data-testid="page-breadcrumb" />
                            </Col>
                        </FlexboxGrid>
                    </BreadcrumbContainerStyled>

                    <Panel>
                        <FlexboxGrid justify={FlexboxGrid.Justify.CENTER}>
                            <Col mediumDesktop={10} largeDesktop={8}>
                                <PostHeader title={pageTitle} data-testid="postHeader" />
                            </Col>
                        </FlexboxGrid>
                    </Panel>
                </>
            )}

            {page.coverImage && page.coverImage.url && (
                <Panel fullWidthUntil={theme.screenSize.mediumTablet}>
                    <FlexboxGrid justify={FlexboxGrid.Justify.CENTER}>
                        <Col>
                            <CoverImageStyled
                                mainImageUrl={page.coverImage.url}
                                mobileImageUrl={
                                    page.coverImage.mobile && page.coverImage.mobile.url
                                }
                                alt={page.coverImage.alt}
                                data-testid="coverImage"
                            />
                        </Col>
                    </FlexboxGrid>
                </Panel>
            )}

            <PageContent data={page} />

            {page.showFacetLinks && (
                <PanelStyled hasMaxWidth>
                    <SeoFacetLinks pageSlug={page.slug} data-testid="seoFacetLinks" />
                </PanelStyled>
            )}
        </>
    );
};

export default Page;
