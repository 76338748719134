// These are the geodata country codes that we have in our database
// They are from geonames.org, for example US = 6252001
// https://www.geonames.org/6252001/united-states.html
// We only need US for now but this can be added to when needed.
const geoNamesCountryCodes = {
    US: '6252001',
};

// eslint-disable-next-line import/prefer-default-export
export { geoNamesCountryCodes };
