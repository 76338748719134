import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from 'components/Dialog';
import Link from 'components/Link';
import Portal from 'components/Portal';
import AddonOffers from 'containers/AddonOffers';
import { routes } from 'utils/routes';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { isFeatureEnabled, features } from 'components/Feature';
import {
    isBasic as isPlanBasic,
    isClassic as isPlanClassic,
    isSitterOnly as isPlanSitter,
} from 'utils/account';
import { track, Events } from 'utils/analytics';
import {
    DialogStyled,
    ModalContents,
    ModalTitle,
    ModalSubTitle,
    FeatureCardWrapper,
    FeatureCard,
    IconPets1Styled,
    IconPets3Styled,
    IconCancellationInsuranceStyled,
    IconTravelStyled,
    CardTitle,
    CardText,
    CardLinkStyled,
    ButtonStyled,
    ModalFooter,
} from './UpgradeModal.style';
import { closeUpgradeModal } from './actions';

const trackButtonClick = ({ description, item }) => {
    track(
        Events.BUTTONCLICK.create({
            description,
            item,
        })
    );
};

const FeatureCardComponent = ({ icon, title, text, closeModal, taller }) => {
    const { t } = useTranslation();

    return (
        <FeatureCard>
            {icon}
            <CardTitle>{title}</CardTitle>
            <CardText taller={taller}>{text}</CardText>
            <CardLinkStyled
                variant={Link.Variant.Button.PRIMARY}
                to={routes.pricing()}
                onClick={() => {
                    trackButtonClick({
                        description: `Sit confirmation Upgrade CTA`,
                        item: `${title}`,
                    });
                    closeModal();
                }}
            >
                {t('upgrade_modal_feature_cta')}
            </CardLinkStyled>
        </FeatureCard>
    );
};

const UpgradeModal = ({ membership, isOpen, closeModal, isOwnerConfirmation, openCheckout }) => {
    const { t } = useTranslation();

    const isBasic = isPlanBasic(membership);
    const isClassic = isPlanClassic(membership);
    const isSitter = isPlanSitter(membership);

    const isClassicOrBasic = isClassic || isBasic;

    const showThirdParty = isClassicOrBasic && isSitter;
    const showContentsProtection = isBasic && !isSitter;

    useEffect(() => {
        if (isOpen) {
            track(
                Events.VIEWED_MODAL.create({
                    description: 'Sit Confirmation Upgrade Modal',
                })
            );
        }
    }, [isOpen]);

    return (
        <Portal>
            <DialogStyled
                onCloseHandler={() => closeModal()}
                isOpen={isOpen}
                variant={Dialog.Variant.mobileFullscreenDesktopCentered}
                blanketColor="rgba(0,0,0,0.64)"
            >
                <ModalContents
                    isFeatureEnabled={
                        isFeatureEnabled({ name: features.TRANSATIONAL_SCALER_V3 }) &&
                        (membership.currencyCode === 'GBP' || membership.currencyCode === 'USD')
                    }
                >
                    {isFeatureEnabled({ name: features.TRANSATIONAL_SCALER_V3 }) &&
                    (membership.currencyCode === 'GBP' || membership.currencyCode === 'USD') ? (
                        <AddonOffers
                            membership={membership}
                            closeModal={closeModal}
                            isOwnerConfirmation={isOwnerConfirmation}
                            openCheckout={openCheckout}
                        />
                    ) : (
                        <>
                            <ModalTitle>
                                {isSitter
                                    ? t('upgrade_modal_sitter_title')
                                    : t('upgrade_modal_title')}
                            </ModalTitle>
                            <ModalSubTitle>
                                {isSitter
                                    ? t('upgrade_modal_sitter_sub_title')
                                    : t('upgrade_modal_sub_title')}
                            </ModalSubTitle>

                            <FeatureCardWrapper>
                                {showThirdParty && (
                                    <FeatureCardComponent
                                        icon={<IconPets3Styled />}
                                        title={t('upgrade_modal_feature_third_party_title')}
                                        text={t('upgrade_modal_feature_third_party_text')}
                                        setIsOpen={closeModal}
                                        closeModal={closeModal}
                                    />
                                )}
                                {showContentsProtection && (
                                    <FeatureCardComponent
                                        icon={<IconPets1Styled />}
                                        title={t('upgrade_modal_feature_content_protection_title')}
                                        text={t('upgrade_modal_feature_content_protection_text')}
                                        setIsOpen={closeModal}
                                        closeModal={closeModal}
                                    />
                                )}
                                <FeatureCardComponent
                                    icon={<IconCancellationInsuranceStyled />}
                                    title={t('upgrade_modal_feature_cancellation_protection_title')}
                                    text={
                                        isSitter
                                            ? t(
                                                  'upgrade_modal_sitter_feature_cancellation_protection_text'
                                              )
                                            : t(
                                                  'upgrade_modal_feature_cancellation_protection_text'
                                              )
                                    }
                                    setIsOpen={closeModal}
                                    closeModal={closeModal}
                                />
                                <FeatureCardComponent
                                    icon={<IconTravelStyled />}
                                    title={t('upgrade_modal_feature_lounge_passes_title')}
                                    text={t('upgrade_modal_feature_lounge_passes_text')}
                                    setIsOpen={closeModal}
                                    closeModal={closeModal}
                                />
                            </FeatureCardWrapper>

                            <ModalFooter>
                                <ButtonStyled
                                    variant={Link.Variant.Button.SECONDARY_PROCEED}
                                    onClick={() => {
                                        trackButtonClick("Sit confirmation I'm ok for now CTA");
                                        closeModal();
                                    }}
                                >
                                    {t('upgrade_modal_cta')}
                                </ButtonStyled>
                            </ModalFooter>
                        </>
                    )}
                </ModalContents>
            </DialogStyled>
        </Portal>
    );
};

const mapStateToProps = (state) => {
    const membership = getAccountCurrentMembershipPlan(state);
    const { showUpgradeModal, openUpgradeModalOptions } = state.upgradeModal;
    const isOwnerConfirmation = !!openUpgradeModalOptions?.isOwnerConfirmation;
    const openCheckout = !!openUpgradeModalOptions?.openCheckout;

    return {
        membership,
        isOpen: showUpgradeModal,
        isOwnerConfirmation,
        openCheckout,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    closeModal: () => {
        dispatch(closeUpgradeModal.create());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeModal);
