import styled, { css } from 'styled-components';

export const DividerStyled = styled.div(
    ({ theme: { spacing, palette } }) => css`
        display: flex;
        width: 100%;
        height: ${spacing[3]};
        margin-top: ${spacing[2]};
        margin-bottom: ${spacing[2]};
        align-items: center;

        &::before,
        &::after {
            content: '';
            height: 1px;
            background-color: ${palette.grey[300]};
            flex-grow: 1;
        }

        &::before {
            margin-right: 14px;
        }

        &::after {
            margin-left: 14px;
        }
    `
);
