import { all, put, take, select, takeLatest, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { eventChannel } from 'redux-saga';
import apiActions, { settings } from 'api/actions';
import { getAccountCurrentMembershipPlan } from 'api/selectors';
import { ANGULAR_EVENT_NAME } from 'hooks/useAngularBridge';
import { MembershipType } from 'api/types';
import { routes } from 'utils/routes';
import * as actions from './actions';
import { getModalType } from './selectors';

// is used to keep track of when an account GET request is being called on the Angular side
export function sitApplicationUpdateAccountStatusAngular() {
    return eventChannel((emit) => {
        const handleEvent = (e) => {
            if (e.detail.type === 'sit-application-update-account-status') {
                emit(e.detail.payload);
            }
        };

        document.addEventListener(ANGULAR_EVENT_NAME, handleEvent);

        return () => {
            document.removeEventListener(ANGULAR_EVENT_NAME, handleEvent);
        };
    });
}

// related to the above - it stores the status of the api request
// it is used for button loading/disabled states
function* watchAngularUpdateAccountStatus() {
    if (typeof document !== 'undefined') {
        const channel = sitApplicationUpdateAccountStatusAngular();

        while (true) {
            const { status, error } = yield take(channel);
            yield put(actions.updateAccountStatus.create(status));

            if (status === 'FAILURE') {
                yield put(actions.modalError.create(error));
            }
        }
    }
}

// allows for Angular to listen for when the modal is closed
const dispatchClosedSubscriptionModal = () => {
    document.dispatchEvent(
        // eslint-disable-next-line no-undef
        new CustomEvent(ANGULAR_EVENT_NAME, {
            detail: {
                type: 'outside-subscription-modal-closed',
                payload: {},
            },
        })
    );
};

export function* enableAutorenew() {
    const { isAutorenewing, hasSubscriptionId } = yield select(getAccountCurrentMembershipPlan);

    if (isAutorenewing === false) {
        // Those without a subscription id shouldn't see this modal but it's worth being defensive
        // as we see some weird account states sometimes
        if (!hasSubscriptionId) {
            // Redirect to plans select page so they can autorenew manually
            yield put(
                push({
                    pathname: routes.accounts.checkout.payment(),
                })
            );
            // Close modal
            yield put(actions.closeOutsideSubscriptionModal.create());
            return;
        }
        yield put(
            apiActions.account.enableSubscriptionAutorenew({
                forceReload: true,
                data: {
                    enabled: true,
                },
            })
        );

        const { status } = yield take(settings.account.enableSubscriptionAutorenew.DONE);

        if (status === settings.account.enableSubscriptionAutorenew.SUCCESS) {
            const modalType = yield select(getModalType);
            // we only care about using an angular dispatch on the sitter side
            if (modalType === MembershipType.SITTER) {
                // allows for Angular to listen for when autorenew is enabled
                document.dispatchEvent(
                    // eslint-disable-next-line no-undef
                    new CustomEvent(ANGULAR_EVENT_NAME, {
                        detail: {
                            type: 'outside-subscription-modal-enabled-autorenew',
                            payload: {},
                        },
                    })
                );
            }

            yield put(
                apiActions.account.loadMembership({
                    forceReload: true,
                })
            );
        }
    }

    return true;
}

export default function* sagas() {
    yield all([
        takeLatest(actions.enableAutorenew.ACTION, enableAutorenew),
        takeLatest(actions.closeOutsideSubscriptionModal.ACTION, dispatchClosedSubscriptionModal),
        fork(watchAngularUpdateAccountStatus),
    ]);
}
