import { all, put, take, takeEvery, select } from 'redux-saga/effects';
import apiActions, { settings } from 'api/actions';
import { getTermsContent } from 'shared/selectors';
import * as actions from './actions';

export function* loadTerms() {
    const terms = yield select(getTermsContent);

    if (!terms || Object.keys(terms).length === 0) {
        yield put(apiActions.policies.loadTerms());
        yield take(settings.policies.loadTerms.DONE);
    }

    return true;
}

export default function* termsAndConditionsSaga() {
    yield all([takeEvery(actions.loadTermsAndConditions.ACTION, loadTerms)]);
}
