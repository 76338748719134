import { MembershipStatus, MembershipType, PlanTiers } from 'api/types';

// eslint-disable-next-line import/prefer-default-export
export const userTypes = {
    AnonymousUser: MembershipStatus.ANONYMOUS,
    PartialUser: MembershipStatus.PARTIAL,
    PaidUser: MembershipStatus.MEMBER,
    ExpiredUser: MembershipStatus.EXPIRED,

    OwnerUser: MembershipType.OWNER,
    SitterUser: MembershipType.SITTER,
    CombinedUser: MembershipType.COMBINED,

    ClassicUser: PlanTiers.CLASSIC,
    BasicUser: PlanTiers.BASIC,
    StandardUser: PlanTiers.STANDARD,
    PremiumUser: PlanTiers.PREMIUM,

    PartialOwner: [MembershipStatus.PARTIAL, MembershipType.OWNER],
    PartialSitter: [MembershipStatus.PARTIAL, MembershipType.SITTER],
    PartialCombined: [MembershipStatus.PARTIAL, MembershipType.COMBINED],

    PaidOwner: [MembershipStatus.MEMBER, MembershipType.OWNER],
    PaidSitter: [MembershipStatus.MEMBER, MembershipType.SITTER],
    PaidCombined: [MembershipStatus.MEMBER, MembershipType.COMBINED],

    ExpiredOwner: [MembershipStatus.EXPIRED, MembershipType.OWNER],
    ExpiredSitter: [MembershipStatus.EXPIRED, MembershipType.SITTER],
    ExpiredCombined: [MembershipStatus.EXPIRED, MembershipType.COMBINED],

    NonClassicUser: [PlanTiers.BASIC, PlanTiers.STANDARD, PlanTiers.PREMIUM],
    NonClassicPartial: [
        MembershipStatus.PARTIAL,
        PlanTiers.BASIC,
        PlanTiers.STANDARD,
        PlanTiers.PREMIUM,
    ],
    NonClassicPaid: [
        MembershipStatus.MEMBER,
        PlanTiers.BASIC,
        PlanTiers.STANDARD,
        PlanTiers.PREMIUM,
    ],
    NonClassicExpired: [
        MembershipStatus.EXPIRED,
        PlanTiers.BASIC,
        PlanTiers.STANDARD,
        PlanTiers.PREMIUM,
    ],

    ClassicPartial: [PlanTiers.CLASSIC, MembershipStatus.PARTIAL],
    ClassicPartialOwner: [PlanTiers.CLASSIC, MembershipStatus.PARTIAL, MembershipType.OWNER],
    ClassicPartialSitter: [PlanTiers.CLASSIC, MembershipStatus.PARTIAL, MembershipType.SITTER],
    ClassicPartialCombined: [PlanTiers.CLASSIC, MembershipStatus.PARTIAL, MembershipType.COMBINED],
    ClassicPaid: [PlanTiers.CLASSIC, MembershipStatus.MEMBER],
    ClassicPaidOwner: [PlanTiers.CLASSIC, MembershipStatus.MEMBER, MembershipType.OWNER],
    ClassicPaidSitter: [PlanTiers.CLASSIC, MembershipStatus.MEMBER, MembershipType.SITTER],
    ClassicPaidCombined: [PlanTiers.CLASSIC, MembershipStatus.MEMBER, MembershipType.COMBINED],
    ClassicExpired: [PlanTiers.CLASSIC, MembershipStatus.EXPIRED],
    ClassicExpiredOwner: [PlanTiers.CLASSIC, MembershipStatus.EXPIRED, MembershipType.OWNER],
    ClassicExpiredSitter: [PlanTiers.CLASSIC, MembershipStatus.EXPIRED, MembershipType.SITTER],
    ClassicExpiredCombined: [PlanTiers.CLASSIC, MembershipStatus.EXPIRED, MembershipType.COMBINED],

    BasicPartial: [PlanTiers.BASIC, MembershipStatus.PARTIAL],
    BasicPartialOwner: [PlanTiers.BASIC, MembershipStatus.PARTIAL, MembershipType.OWNER],
    BasicPartialSitter: [PlanTiers.BASIC, MembershipStatus.PARTIAL, MembershipType.SITTER],
    BasicPartialCombined: [PlanTiers.BASIC, MembershipStatus.PARTIAL, MembershipType.COMBINED],
    BasicPaid: [PlanTiers.BASIC, MembershipStatus.MEMBER],
    BasicPaidOwner: [PlanTiers.BASIC, MembershipStatus.MEMBER, MembershipType.OWNER],
    BasicPaidSitter: [PlanTiers.BASIC, MembershipStatus.MEMBER, MembershipType.SITTER],
    BasicPaidCombined: [PlanTiers.BASIC, MembershipStatus.MEMBER, MembershipType.COMBINED],
    BasicExpired: [PlanTiers.BASIC, MembershipStatus.EXPIRED],
    BasicExpiredOwner: [PlanTiers.BASIC, MembershipStatus.EXPIRED, MembershipType.OWNER],
    BasicExpiredSitter: [PlanTiers.BASIC, MembershipStatus.EXPIRED, MembershipType.SITTER],
    BasicExpiredCombined: [PlanTiers.BASIC, MembershipStatus.EXPIRED, MembershipType.COMBINED],

    StandardPartial: [PlanTiers.STANDARD, MembershipStatus.PARTIAL],
    StandardPartialOwner: [PlanTiers.STANDARD, MembershipStatus.PARTIAL, MembershipType.OWNER],
    StandardPartialSitter: [PlanTiers.STANDARD, MembershipStatus.PARTIAL, MembershipType.SITTER],
    StandardPartialCombined: [
        PlanTiers.STANDARD,
        MembershipStatus.PARTIAL,
        MembershipType.COMBINED,
    ],
    StandardPaid: [PlanTiers.STANDARD, MembershipStatus.MEMBER],
    StandardPaidOwner: [PlanTiers.STANDARD, MembershipStatus.MEMBER, MembershipType.OWNER],
    StandardPaidSitter: [PlanTiers.STANDARD, MembershipStatus.MEMBER, MembershipType.SITTER],
    StandardPaidCombined: [PlanTiers.STANDARD, MembershipStatus.MEMBER, MembershipType.COMBINED],
    StandardExpired: [PlanTiers.STANDARD, MembershipStatus.EXPIRED],
    StandardExpiredOwner: [PlanTiers.STANDARD, MembershipStatus.EXPIRED, MembershipType.OWNER],
    StandardExpiredSitter: [PlanTiers.STANDARD, MembershipStatus.EXPIRED, MembershipType.SITTER],
    StandardExpiredCombined: [
        PlanTiers.STANDARD,
        MembershipStatus.EXPIRED,
        MembershipType.COMBINED,
    ],

    PremiumPartial: [PlanTiers.PREMIUM, MembershipStatus.PARTIAL],
    PremiumPartialOwner: [PlanTiers.PREMIUM, MembershipStatus.PARTIAL, MembershipType.OWNER],
    PremiumPartialSitter: [PlanTiers.PREMIUM, MembershipStatus.PARTIAL, MembershipType.SITTER],
    PremiumPartialCombined: [PlanTiers.PREMIUM, MembershipStatus.PARTIAL, MembershipType.COMBINED],
    PremiumPaid: [PlanTiers.PREMIUM, MembershipStatus.MEMBER],
    PremiumPaidOwner: [PlanTiers.PREMIUM, MembershipStatus.MEMBER, MembershipType.OWNER],
    PremiumPaidSitter: [PlanTiers.PREMIUM, MembershipStatus.MEMBER, MembershipType.SITTER],
    PremiumPaidCombined: [PlanTiers.PREMIUM, MembershipStatus.MEMBER, MembershipType.COMBINED],
    PremiumExpired: [PlanTiers.PREMIUM, MembershipStatus.EXPIRED],
    PremiumExpiredOwner: [PlanTiers.PREMIUM, MembershipStatus.EXPIRED, MembershipType.OWNER],
    PremiumExpiredSitter: [PlanTiers.PREMIUM, MembershipStatus.EXPIRED, MembershipType.SITTER],
    PremiumExpiredCombined: [PlanTiers.PREMIUM, MembershipStatus.EXPIRED, MembershipType.COMBINED],
};
