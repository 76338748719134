import { combineReducers } from 'redux';

import app from './App/reducers';
import search from './search/reducers';
import user from './user/reducers';
import dashboard from './accounts/Dashboard/reducers';
import provideReference from './ProvideReference/reducers';
import page from './Page/reducers';

export default combineReducers({
    app,
    search,
    user,
    dashboard,
    provideReference,
    page,
});
