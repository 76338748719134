import React from 'react';
import PropTypes from 'prop-types';
import { BlanketStyled } from './Blanket.style';
import { BlanketVariant } from './Blanket.constants';

const Blanket = ({ isActive, backgroundColor, onBlanketClicked, variant }) => (
    <BlanketStyled
        aria-hidden={!isActive}
        backgroundColor={backgroundColor}
        isActive={isActive}
        onClick={onBlanketClicked}
        variant={variant}
    />
);

Blanket.propTypes = {
    /** A hex value that reprsents the background color */
    backgroundColor: PropTypes.string,

    /** Whether the blanket is active or hidden */
    isActive: PropTypes.bool.isRequired,

    /** Handler function to be called when the blanket is clicked */
    onBlanketClicked: PropTypes.func,

    /** Optionally supply styles via a variant. Hopefully we can get rid of backgroundColor one day */
    variant: PropTypes.oneOf(Object.values(BlanketVariant)),
};

Blanket.defaultProps = {
    backgroundColor: 'hsla(0, 0%, 100%, 0.65)',
    onBlanketClicked: () => {},
    variant: null,
};

Blanket.Variant = BlanketVariant;

export default Blanket;
