import React from 'react';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import asPage from 'containers/Page';
import { retry } from 'utils/ssr';
import { mapStateToProps, mapDispatchToProps } from 'containers/PlansPage/connect';
import { redirectIfLoggedOut, onlyPaidMembers } from 'pages/user/helpers';
import { PAGE_IDS } from 'containers/PlansPage/constants';

const LoadablePlans = loadable(() => retry(() => import('containers/PlansPage')));

const ConnectedLoadablePlans = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(LoadablePlans));

export default connect()(
    asPage(
        (props) => <ConnectedLoadablePlans {...props} variant={PAGE_IDS.UPGRADE} />,
        PAGE_IDS.UPGRADE,
        true,
        true,
        {
            isProtectedRoute: true,
            checkAuthorised: [redirectIfLoggedOut, onlyPaidMembers],
        }
    )
);
