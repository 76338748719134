import { settings as actions } from '../actions';
import * as transform from '../helpers/transform';

import { basePaginatedInitialState } from './common';

import { uniqueArray } from '../helpers/format/objects';

export const initialState = { ...basePaginatedInitialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.notifications.load.SUCCESS: {
            const { startingAfter } = action.requestData;
            if (startingAfter) {
                return {
                    ...state,
                    hasMore: action.data.hasMore,
                    count: action.data.count,
                    results: uniqueArray([
                        ...state.results,
                        ...action.data.results.map((item) => item.id),
                    ]),
                    data: {
                        ...state.data,
                        ...action.data.results.reduce((items, item) => {
                            items[item.id] = transform.getNotification(item);
                            return items;
                        }, {}),
                    },
                };
            }
            return {
                ...state,
                hasMore: action.data.hasMore,
                count: action.data.count,
                results: uniqueArray([...action.data.results.map((item) => item.id)]),
                data: {
                    ...state.data,
                    ...action.data.results.reduce((items, item) => {
                        items[item.id] = transform.getNotification(item);
                        return items;
                    }, {}),
                },
            };
        }
        case actions.notifications.acknowledgeNotification.SUCCESS: {
            const newResults = state.results.filter(
                (notificationId) => notificationId !== action.data.id
            );
            const newData = { ...state.data };
            delete newData[action.data.id];
            return {
                ...state,
                results: newResults,
                data: newData,
            };
        }
        default:
            return state;
    }
};
