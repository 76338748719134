import { settings as actions } from '../actions';

export const initialState = {
    privacyPolicy: {},
    terms: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.policies.loadPrivacyPolicy.SUCCESS:
            return {
                ...state,
                privacyPolicy: action.data,
            };
        case actions.policies.loadTerms.SUCCESS:
            return {
                ...state,
                terms: action.data,
            };
        default:
            return state;
    }
};
