import styled from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import { Panel } from 'components/layouts';

const Header = styled.header``;

const Main = styled.main``;

const StickyWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: ${zIndex};
`;

const ContainerStyled = styled(Panel)`
    margin-bottom: ${({ theme }) => theme.spacing[8]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[12]};
    }
`;

export { Header, Main, ContainerStyled, StickyWrapper };
