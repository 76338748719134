export const SCREEN_HOW_WAS_YOUR_SIT = 'howWasYourSit';
export const SCREEN_WRITE_FEEDBACK = 'writeFeedback';
export const SCREEN_THANKS = 'thanks';
export const SCREEN_EXIT_WARNING = 'exitWarning';
export const SCREEN_REVIEW_WINDOW_CLOSED = 'reviewWindowClosed';
export const SCREEN_COMMUNICATION = 'communication';
export const SCREEN_HOSPITALITY = 'hospitality';
export const SCREEN_CLEANLINESS = 'cleanliness';
export const SCREEN_PET_BEHAVIOUR = 'petBehaviour';
export const SCREEN_LISTING_ACCURACY = 'accuracyOfListing';

export const STORAGE_KEY = 'leave-feedback-modal';

// Rating's labels values
export const COMMUNICATION_RATING_LABELS = [
    'communication_leave_feedback_modal_one_star_label',
    'communication_leave_feedback_modal_two_stars_label',
    'communication_leave_feedback_modal_three_stars_label',
    'communication_leave_feedback_modal_four_stars_label',
    'communication_leave_feedback_modal_five_stars_label',
];

export const PET_BEHAVIOR_RATING_LABELS = [
    'pet_behavior_leave_feedback_modal_one_star_label',
    'pet_behavior_leave_feedback_modal_two_stars_label',
    'pet_behavior_leave_feedback_modal_three_stars_label',
    'pet_behavior_leave_feedback_modal_four_stars_label',
    'pet_behavior_leave_feedback_modal_five_stars_label',
];

export const CLEANLINESS_RATING_LABELS = [
    'cleanliness_leave_feedback_modal_one_star_label',
    'cleanliness_leave_feedback_modal_two_stars_label',
    'cleanliness_leave_feedback_modal_three_stars_label',
    'cleanliness_leave_feedback_modal_four_stars_label',
    'cleanliness_leave_feedback_modal_five_stars_label',
];

export const LISTING_ACCURATE_RATING_LABELS = [
    'listing_accuracy_leave_feedback_modal_one_star_label',
    'listing_accuracy_leave_feedback_modal_two_stars_label',
    'listing_accuracy_leave_feedback_modal_three_stars_label',
    'listing_accuracy_leave_feedback_modal_four_stars_label',
    'listing_accuracy_leave_feedback_modal_five_stars_label',
];

export const HOSPITALITY_RATING_LABELS = [
    'hospitality_leave_feedback_modal_one_star_label',
    'hospitality_leave_feedback_modal_two_stars_label',
    'hospitality_leave_feedback_modal_three_stars_label',
    'hospitality_leave_feedback_modal_four_stars_label',
    'hospitality_leave_feedback_modal_five_stars_label',
];
