import { put, select, call } from 'redux-saga/effects';

import {
    reader,
    creator,
    updater,
    deleter,
    generateStatusKey,
    statusSelector,
    generateRequestedFieldsSymbol,
} from './helpers';
import { isOlderThan } from '../helpers/format/date';

import ApiConfig, { ACTIONS_CONFIG_CACHE } from '../config';
import { AVAILABLE_ACTION_STATUSES } from '../actions/constants';

const objectKeyGenerators = {
    searchKeyGenerator: (action, actionConfig, objectKey) => {
        const { searchType = 'default' } = action.filters || {};
        if (searchType === 'default') return objectKey;
        return searchType;
    },
    contentKeyGenerator: (action) =>
        `${JSON.stringify(action.data)}-${JSON.stringify(action.filters)}`,
};

export default function* saga(action) {
    const apiAction = ACTIONS_CONFIG_CACHE[action.type];

    let objectKey = action.data ? action.data.id : null;
    if (!objectKey) {
        objectKey = action.id || objectKey;
    }
    // Generate special object key
    const { keyGenerator: generateObjectKey } = apiAction;
    if (generateObjectKey) {
        objectKey = objectKeyGenerators[generateObjectKey](action, apiAction, objectKey);
    }
    const requestedFields = generateRequestedFieldsSymbol(action);
    const actionKey = generateStatusKey(apiAction.module, objectKey, requestedFields);
    const actionStatus = yield select(statusSelector, apiAction, actionKey);

    // De-bounce action creator
    if (actionStatus) {
        if (actionStatus.status === AVAILABLE_ACTION_STATUSES.SUCCESS && !action.forceReload) {
            const dataValidTime =
                ApiConfig.cache[apiAction.module] || apiAction.dataValidTime || null;
            if (dataValidTime && !isOlderThan({ date: actionStatus.timestamp, ...dataValidTime })) {
                return;
            }
            if (!dataValidTime) {
                // If no dataValidTime set it will always cache
                return;
            }
        }
        // Reload data if 'stuck' for more than 30 secs
        else if (
            actionStatus.status === AVAILABLE_ACTION_STATUSES.REQUESTED &&
            !action.forceReload
        ) {
            if (!isOlderThan({ date: actionStatus.timestamp, seconds: 30 })) return;
        }
    }

    yield put({
        type: apiAction.REQUESTED,
        action,
        apiAction,
        actionKey,
    });

    let data;
    switch (apiAction.verb) {
        case 'GET':
            data = yield call(reader, {
                apiAction,
                actionKey,
                ...action,
            });
            break;
        case 'POST':
            data = yield call(creator, {
                apiAction,
                actionKey,
                ...action,
            });
            break;
        case 'PUT':
            data = yield call(updater, {
                apiAction,
                actionKey,
                ...action,
            });
            break;
        case 'DELETE':
            data = yield call(deleter, {
                apiAction,
                actionKey,
                ...action,
            });
            break;
        default:
            break;
    }

    let status = apiAction.SUCCESS;
    let doneData = null;
    let statusCode = null;

    if (data.error) {
        if (data.error.response) {
            status = apiAction.FAILURE;
            doneData = data.error.response.data;
            statusCode = data.error.response.status;

            yield put({
                type: apiAction.FAILURE,
                actionKey,
                data: data.error.response.data,
                statusCode,
                action,
                apiAction,
            });
        } else {
            status = apiAction.ERROR;
            yield put({
                type: apiAction.ERROR,
                actionKey,
                error: data.error.message,
                action,
                apiAction,
            });
        }
    } else {
        doneData = data.response.data;
        statusCode = data.response.status;
        yield put({
            type: apiAction.SUCCESS,
            requestData: action.data,
            data: data.response.data,
            action,
            apiAction,
            actionKey,
            statusCode,
        });
    }

    yield put({
        type: apiAction.DONE,
        requestData: action.data,
        data: doneData,
        status,
        statusCode,
        action,
        apiAction,
        actionKey,
    });
}
