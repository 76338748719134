import { takeEvery } from 'redux-saga/effects';

import ApiConfig from '../config';
import { AVAILABLE_ACTION_STATUSES } from '../actions/constants';

import requestSaga from './common';

export default function* () {
    yield takeEvery(action => (
        action.type.startsWith(ApiConfig.actionsPrefix)
        && action.type.endsWith(`$${AVAILABLE_ACTION_STATUSES.REQUEST}$`)
    ), requestSaga);
}
