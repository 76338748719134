import SavedYourListing from './SavedYourListing';
import paths from './route-paths';

export default [
    {
        path: paths.savedYourListing,
        exact: true,
        component: SavedYourListing,
    },
];
