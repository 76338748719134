import { getState } from './common';

//
// Data selectors
//
export const getPlans = state => getState(state).plans || [];
export const getPlan = (state, membershipType, months = 12) => {
    const plans = getPlans(state);
    for (let a = 0; a < plans.length; a += 1) {
        const plan = plans[a];
        if (plan.membershipType === membershipType && plan.months === months) {
            return plan;
        }
    }

    return {};
};
